import { useState } from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { AchievementType } from './AchievementInputRow';
import { CustomerDependencyType } from './CustomerDependencyInputRow';
import { DiscussionType } from './DiscussionInputRow';
import { PlannedItemType } from './PlannedItemInputRow';
import { AgileProductMaintenanceSupportType } from './AgileProductMaintenanceSupportTypesDataInputRow';
import { DiscoveryScopeBasedType } from './DiscoveryScopeBasedTypesDataInputRow';
import { NumberFieldType, DoubleValueFormat, IntegerValueFormat } from '../../ProjectWeeklyReportService';

export enum ProjectReportType {
  WEEKLY_EXTERNAL_REPORT = 'WEEKLY_EXTERNAL_REPORT',
  INTERNAL_REPORT = 'INTERNAL_REPORT',
}
export interface InputRowProps {
  data:
    | AchievementType
    | CustomerDependencyType
    | DiscussionType
    | PlannedItemType
    | AgileProductMaintenanceSupportType
    | DiscoveryScopeBasedType;
  source: string;
  formData: FormFieldsData;
  id: number;
  updateFormData: (source: string, newFormData: any) => void;
  listSource: string;
  reportType?: ProjectReportType;
}

const useInputRow = ({
  data,
  source,
  formData,
  id,
  updateFormData,
  listSource,
  reportType = ProjectReportType.WEEKLY_EXTERNAL_REPORT,
}: InputRowProps) => {
  const [rowData, setRowData] = useState(data);

  const updateField = (newFormData: FormFieldsData, fieldName: string) => {
    setRowData({
      ...rowData,
      [`${fieldName}`]: newFormData[`${source}.${fieldName}`],
    });

    const listData =
      reportType === ProjectReportType.WEEKLY_EXTERNAL_REPORT
        ? formData.weeklyReportData[listSource]
        : formData.internalReportData[listSource];
    listData[id] = {
      ...rowData,
      [`${fieldName}`]: newFormData[`${source}.${fieldName}`],
    };

    updateFormData(listSource, listData);
  };

  const removeRow = (shouldUpdateIds?: boolean) => {
    const listData =
      reportType === ProjectReportType.WEEKLY_EXTERNAL_REPORT
        ? formData.weeklyReportData[listSource]
        : formData.internalReportData[listSource];
    listData.splice(id, 1);

    updateFormData(
      listSource,
      shouldUpdateIds
        ? [
            ...listData.map((fieldData: any, idInArray: number) => {
              return {
                ...fieldData,
                id: idInArray + 1,
              };
            }),
          ]
        : listData,
    );
  };

  const getOnChangeCallback = (fieldSource: string) => (newFormData: FormFieldsData) => {
    updateField(newFormData, fieldSource);
  };

  const getOnChangeNumberFieldCallback = (fieldType: NumberFieldType, fieldName: string) => (
    newFormData: FormFieldsData,
  ) => {
    const newValue = newFormData[`${source}.${fieldName}`] || '0';

    if (
      (fieldType === NumberFieldType.DOUBLE && !newValue.match(DoubleValueFormat)) ||
      (fieldType === NumberFieldType.INTEGER && !newValue.match(IntegerValueFormat))
    ) {
      return;
    }

    updateField(
      newValue.match(/^(0+\d+)$/)
        ? {
            [`${source}.${fieldName}`]: newValue.substring(1),
          }
        : newFormData,
      fieldName,
    );
  };

  return {
    getOnChangeCallback,
    rowData,
    removeRow,
    getOnChangeNumberFieldCallback,
  };
};

export default useInputRow;
