import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { BaseFilterProps, Filter, FilterCondition, useFilterContext } from './FilterContext';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import useDateRangeFilter from './useDateRangeFilter';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import {
  GREATER_OR_EQUAL,
  LESS_OR_EQUAL,
  DEFAULT_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from 'shared/uibuilder/dateService';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import Typography from 'uibuilder/Typography';
import { Placement } from 'shared/uibuilder/SimpleTooltip';

export interface DateRangeFilterProps extends BaseFilterProps {
  dateFormat?: string;
  dateTimeFormat?: string;
  canBeInFuture?: boolean;
  label?: string;
  popperPlacement?: Placement;
}

interface DateRangeFilterLayoutProps extends BaseFilterProps {
  label?: string;
  children: React.ReactElement[];
  leValue: string;
  geValue: string;
  onChangeCallback: (filterPair: Dictionary<string>) => void;
  onResetFilterCallback?: () => void;
  className?: string;
  popperPlacement?: Placement;
}

export type DateRangeFilterLayoutType = ReactComponent<DateRangeFilterLayoutProps>;

const DateRangeFilter = ({
  source,
  dateFormat = DEFAULT_FORMAT,
  dateTimeFormat = DEFAULT_DATE_TIME_FORMAT,
  canBeInFuture = false,
  label,
  popperPlacement,
}: DateRangeFilterProps) => {
  const { DateRangeFilterLayout } = useUiTheme<DateRangeFilterLayoutType>();
  const { filterBy, getValue, filters } = useFilterContext();
  // filterValue stores text date in API format
  const [filterValue, setFilterValue] = useState(filters[source]);
  const { formatDateForAPI } = useDateService();
  const {
    validateAndShowErrorMessages,
    getErrors,
    fromApiToFrontFormat,
    localDate,
    getMaxDate,
    getMaxGeDate,
    getMinGeDate,
    getMaxLeDate,
    getMinLeDate,
  } = useDateRangeFilter(dateFormat, dateTimeFormat, canBeInFuture, source);
  const MAX_DATE = getMaxDate();

  const hasError = !isEmpty([...getErrors(GREATER_OR_EQUAL), ...getErrors(LESS_OR_EQUAL)]);
  const invalidClass = hasError ? 'is-invalid' : '';

  /**
   * @param filterPair - object like {"ge": "2020-08-05"} means greater or equal
   */
  const onChangeCallback = (filterPair: Dictionary<string>) => {
    const newFilterValue = filterPair;
    if (filterPair) {
      const inputName = Object.keys(filterPair)[0] as FilterCondition;
      const textDate = filterPair[inputName];
      const validationResult = validateAndShowErrorMessages(inputName, textDate);
      if (textDate && !validationResult) {
        if (inputName === GREATER_OR_EQUAL) {
          newFilterValue[inputName] = formatDateForAPI(textDate);
        }
        if (inputName === LESS_OR_EQUAL) {
          newFilterValue[inputName] = formatDateForAPI(textDate);
        }
      }
      setFilterValue(prevFilterValue => ({
        ...prevFilterValue,
        ...newFilterValue,
      }));
    }
  };

  /*
   * Initial validation (for example needed when url contains filter object)
   */
  useEffect(() => {
    if (filterValue) {
      validateAndShowErrorMessages(GREATER_OR_EQUAL, fromApiToFrontFormat(filterValue[GREATER_OR_EQUAL]));
      validateAndShowErrorMessages(LESS_OR_EQUAL, fromApiToFrontFormat(filterValue[LESS_OR_EQUAL]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterValueWithoutEmpty: Filter = {};

    Object.entries(filterValue || {}).forEach(entry => {
      const [condition, value] = entry;

      if (value) {
        filterValueWithoutEmpty[condition] = value;
      }
    });

    filterBy({
      [source]: filterValueWithoutEmpty,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const leValue = getValue(source, LESS_OR_EQUAL);
  const geValue = getValue(source, GREATER_OR_EQUAL);

  return (
    <DateRangeFilterLayout
      label={label}
      leValue={leValue}
      geValue={geValue}
      onChangeCallback={onChangeCallback}
      source={source}
      className={invalidClass}
      popperPlacement={popperPlacement}
    >
      <DateInput
        toggleButton={
          <>
            <i className="fa fa-calendar mr-2" aria-hidden />
            From:
          </>
        }
        label={label}
        source={GREATER_OR_EQUAL}
        onChangeCallback={onChangeCallback}
        value={fromApiToFrontFormat(geValue)}
        dateFormat={dateFormat}
        maxDate={getMaxGeDate() || (leValue && localDate(fromApiToFrontFormat(leValue))) || MAX_DATE}
        minDate={getMinGeDate()}
        errorMessages={getErrors(GREATER_OR_EQUAL)}
      />
      <Typography variant="body1" sx={{ margin: '8px 16px 10px', alignSelf: 'center' }}>
        —
      </Typography>
      <DateInput
        toggleButton="To: "
        source={LESS_OR_EQUAL}
        onChangeCallback={onChangeCallback}
        value={fromApiToFrontFormat(leValue)}
        maxDate={getMaxLeDate() || MAX_DATE}
        label=" "
        dateFormat={dateFormat}
        minDate={getMinLeDate() || (geValue && localDate(fromApiToFrontFormat(geValue)))}
        errorMessages={getErrors(LESS_OR_EQUAL)}
      />
    </DateRangeFilterLayout>
  );
};

export default DateRangeFilter;
