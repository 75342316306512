/* istanbul ignore file */

import React from 'react';
import 'audit/shared/Manual.css';

const PermissionsAuditManual = () => (
  <div className="manual-container">
    <p>
      Current functionality allows getting the snapshots of the permissions granted to the roles at specific point in
      time. Specify the date and time at which you want to get the snapshot using the form above.
    </p>
    <p>The generated report contains the following columns:</p>
    <table className="manual-table">
      <tbody>
        <tr>
          <td>
            <b>Column</b>
          </td>
          <td>
            <b>Description</b>
          </td>
        </tr>
        <tr>
          <td>Role</td>
          <td>Role name.</td>
        </tr>
        <tr>
          <td>Entity Name</td>
          <td>The name of the entity permission is granted for.</td>
        </tr>
        <tr>
          <td>Permission</td>
          <td>The name of permission which is granted for the role in the scope of Context.</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>
      The current report includes only <b>role-permission</b> configuration and doesn&apos;t take into account other
      authorization mechanisms (like the inheritance of other user&apos;s permissions and additional conditions for
      permission granting).
      <br />
      Please contact DaVinci team if you want to troubleshoot this configuration.
    </p>
  </div>
);

export default PermissionsAuditManual;
