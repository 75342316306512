/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';

import useCandidateService, { CANDIDATE_ICON } from 'erp/candidate/shared/candidateService';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import CandidateApplicationSourceField from 'erp/candidate/shared/field/CandidateApplicationSourceField';
import CandidateStatusField from 'erp/candidate/shared/field/CandidateStatusField';
import CandidateSearchLinkField from 'erp/candidate/shared/field/CandidateSearchLinkField';
import EmailSearchField from 'erp/candidate/List/field/EmailSearchField';
import PhoneSearchField from 'erp/candidate/List/field/PhoneSearchField';
import ViewCandidateTimelineButton from 'erp/candidate/shared/button/ViewCandidateTimelineButton';
import CandidateApplicationSourceFilter from 'erp/candidate/List/filter/CandidateApplicationSourceFilter';
import CandidateStatusFilter from 'erp/candidate/List/filter/CandidateStatusFilter';
import RecruiterFilter from 'erp/candidate/List/filter/RecruiterFilter';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { EmployeeFilter } from 'erp/employee';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import CandidateSkillsFilter from 'erp/candidate/List/filter/CandidateSkillsFilter';
import CandidateSearchInput from 'erp/candidate/List/input/CandidateSearchInput';
import { BadgeField, SearchResultField } from 'shared/uibuilder/field';
import StudentBadge from 'erp/candidate/shared/StudentBadge';
import CreateCandidateButton from 'erp/candidate/List/button/CreateCandidateButton';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';

const CandidatesListPage = () => {
  const { search } = useCandidateService();

  const filters = (
    <Filters>
      <FiltersLayout>
        <CandidateApplicationSourceFilter
          source="lastCandidateApplication.source"
          label="Latest Candidate Application Source"
        />
        <CandidateStatusFilter source="status" label="Candidate Status" />
        <EmployeeFilter source="lastCandidateApplication.source.sourceEmployeeId" label="Employee" />
        <CandidateSkillsFilter source="skills.name" label="Candidate Skills" />
        <RecruiterFilter source="createdBy.alias" label="Created by Recruiter" />
        <RecruiterFilter source="updatedBy.alias" label="Updated by Recruiter" />
        <DateTimeRangeFilter label="Created Date" source="createdAt" />
        <DateTimeRangeFilter label="Last Modified Date" source="updatedAt" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="updatedAt">
      <ListPageLayout
        header={<ListHeader label="Candidates" icon={CANDIDATE_ICON} />}
        filter={filters}
        actions={[<CreateCandidateButton />]}
        search={<CandidateSearchInput label="Id, Name, Phone, Email" />}
      >
        <DataGridLayout buttons={[<ViewCandidateTimelineButton source="id" outline permissionToCheck={null} />]}>
          <SearchResultField label="Candidate ID" source="id" isSortable width={150} />
          <BadgeField
            badges={[<StudentBadge isVisible={({ isStudent }) => isStudent} />]}
            isSortable
            label="Candidate Name"
            source="name"
          >
            <CandidateSearchLinkField idSource="id" source="name" />
          </BadgeField>

          <PhoneSearchField source="personalPhoneNumber" label="Phone" />
          <EmailSearchField source="personalEmail" label="Email" />
          <CandidateStatusField source="status" isSortable />
          <CandidateApplicationSourceField
            source="lastCandidateApplication.source"
            isSortable
            sortSource="lastCandidateApplication.source.type"
            label="Latest application source"
          />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default CandidatesListPage;
