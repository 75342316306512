import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import ReferenceInput from './ReferenceInput';

const InvoiceReferenceInput = (props: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const reference: any = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback!({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ReferenceInput value={reference} referenceType={reference.referenceType} source={`${source}`} />;
};

export default InvoiceReferenceInput;
