import React, { useEffect, useState } from 'react';
import { CROP_SIZES } from 'artifact/const';
import useAuthenticatedUserService, { UserName } from 'authentication/authenticatedUserService';
import Avatar from '@mui/material/Avatar';

const AVATAR_SIZE = { width: { xs: 35, sm: 40 }, height: { xs: 35, sm: 40 } };

const getFirstLetter = (word: string) => word.toUpperCase()[0];

const getInitials = (name: UserName) => `${getFirstLetter(name.firstName)}${getFirstLetter(name.lastName)}`;

const CurrentUserAvatarField = () => {
  const [name, setName] = useState<Nullable<UserName>>(null);
  const [avatarThumbnail, setAvatarThumbnail] = useState<Nullable<string>>(null);
  const { getUserName, getAvatarUrl } = useAuthenticatedUserService();

  useEffect(() => {
    (async () => {
      const userName = await getUserName();
      const thumbnail = await getAvatarUrl(CROP_SIZES.SIZE_100);
      setName(userName);
      setAvatarThumbnail(thumbnail);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (avatarThumbnail && name) {
    return <Avatar sx={AVATAR_SIZE} src={avatarThumbnail} alt={name.firstName} />;
  } else if (name) {
    return <Avatar sx={AVATAR_SIZE}>{getInitials(name)}</Avatar>;
  } else {
    return null;
  }
};

export default CurrentUserAvatarField;
