/* istanbul ignore file */
// libs
import React from 'react';
// layout
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
// fields
import { TextField } from 'shared/uibuilder/field';
import EducationTypeField from 'erp/employee/Education/shared/field/EducationTypeField';
import EducationalDocumentField from 'erp/employee/Education/shared/field/EducationalDocumentField';
// buttons
import EditEducationButton from 'erp/employee/Education/shared/button/EditEducationButton';
import DeleteEducationButton from 'erp/employee/Education/shared/button/DeleteEducationButton';
// services
import useEducationService from 'erp/employee/Education/shared/educationService';
import { UPDATE_EDUCATION, useEducationId } from 'erp/employee/Education/EducationRouter';
import SingleEducationBreadcrumbs from 'erp/employee/Education/shared/SingleEducationBreadcrumbs';

const ViewEducation = () => {
  const educationId = useEducationId();
  const { getById } = useEducationService();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<SingleEducationBreadcrumbs />}
      buttons={[
        <EditEducationButton source="id" key="edit-education" permissionToCheck={UPDATE_EDUCATION} />,
        <DeleteEducationButton key="delete-button" permissionToCheck={UPDATE_EDUCATION} />,
      ]}
    >
      <Show getDataMethod={() => getById(educationId)}>
        <ShowSection title="View: Education">
          <SectionRow>
            <TextField source="providerName" label="Education Provider" />
            <TextField source="graduationYear" />
            <EducationTypeField source="type" />
            <TextField source="specialization" width={130} />
            <TextField source="assignedQualification" />
            <TextField source="additionalInformation" />
          </SectionRow>
          <EducationalDocumentField source="document" />
        </ShowSection>
      </Show>
    </ShowPageLayout>
  );
};

export default ViewEducation;
