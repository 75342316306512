import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useCareerDevelopmentReviewService from 'erp/careerdevelopment/shared/careerDevelopmentReviewService';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import { useEffect, useState } from 'react';
import useDateService from 'shared/uibuilder/dateService/useDateService';

const useEarlyStartedIntitialization = () => {
  const { initializeValue } = useInputHelper({ source: 'isEarlyStarted' });
  const [isEarlyStarted, setIsEarlyStarted] = useState(false);
  const [nextReviewDate, setNextReviewDate] = useState('');

  const { getToday } = useDateService();
  const { getCareerDevelopmentNextReviewDate } = useCareerDevelopmentReviewService();
  const { getUserAlias } = useAuthenticatedUserService();

  useEffect(() => {
    (async () => {
      const userAlias = await getUserAlias();
      const foundReviewDate = await getCareerDevelopmentNextReviewDate(userAlias || '');
      const earlyStarted = getToday().isBefore(foundReviewDate.date, 'day');

      initializeValue(earlyStarted);
      setIsEarlyStarted(earlyStarted);
      setNextReviewDate(foundReviewDate.date);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isEarlyStarted, nextReviewDate };
};

export default useEarlyStartedIntitialization;
