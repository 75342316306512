/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  READ_JOURNAL_ENTRY,
  JOURNAL_ENTRY_PATH,
  READ_JOURNAL_ENTRY_LIST,
  CREATE_JOURNAL_ENTRY,
} from 'financialAnalytic/journalEntry/useJournalEntryService';
import JournalEntryList from 'financialAnalytic/journalEntry/List';
import JournalEntryViewPage from 'financialAnalytic/journalEntry/Show';
import JournalEntryCreatePage from 'financialAnalytic/journalEntry/createupdate/Create';
import JournalEntryUpdatePage from 'financialAnalytic/journalEntry/createupdate/Update';
import JournalEntryReversePage from 'financialAnalytic/journalEntry/createupdate/Reverse';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import UploadJournalEntry from './uploadJournalEntry/UploadJournalEntry';

export const useJournalEntryRoute = () => {
  const routers = useRoute({ listRoute: JOURNAL_ENTRY_PATH });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
    reverseEntityRoute: `${routers.singleEntityRoute}/reverse`,
    journalEntryByTransferRoute: `${routers.listRoute}/transfer/:transferId`,
    uploadJournalEntryRoute: `${routers.listRoute}/upload-journal-entry`,
  };
};

export const useJournalEntryUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: JOURNAL_ENTRY_PATH,
  });

  return {
    ...baseUrls,
    getCopyEntityUrl: (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/copy`,
    getReverseEntityUrl: (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/reverse`,
    getJournalEntryByTransferUrl: (id?: string): string => `${baseUrls.getListUrl()}/transfer/${id}`,
  };
};

export const useJournalEntryId = () => useParams<Dictionary<string>>().id;
export const useTransferId = () => useParams<Dictionary<string>>().transferId;

const JournalEntryRouter = () => {
  const {
    listRoute,
    singleEntityRoute,
    createRoute,
    copyEntityRoute,
    reverseEntityRoute,
    updateRoute,
    uploadJournalEntryRoute,
    journalEntryByTransferRoute,
  } = useJournalEntryRoute();

  return (
    <ProtectedRouter basePath={JOURNAL_ENTRY_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_JOURNAL_ENTRY_LIST}>
          <JournalEntryList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_JOURNAL_ENTRY}>
          <JournalEntryCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={copyEntityRoute} exact globalPermissionToCheck={CREATE_JOURNAL_ENTRY}>
          <JournalEntryCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={CREATE_JOURNAL_ENTRY}>
          <JournalEntryUpdatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={uploadJournalEntryRoute} exact globalPermissionToCheck={CREATE_JOURNAL_ENTRY}>
          <UploadJournalEntry />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={reverseEntityRoute} exact globalPermissionToCheck={CREATE_JOURNAL_ENTRY}>
          <JournalEntryReversePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_JOURNAL_ENTRY}>
          <JournalEntryViewPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={journalEntryByTransferRoute} exact globalPermissionToCheck={READ_JOURNAL_ENTRY}>
          <JournalEntryViewPage shouldSearchByTransferId />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

JournalEntryRouter.getRouterPath = () => JOURNAL_ENTRY_PATH;

export default JournalEntryRouter;
