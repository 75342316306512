/* istanbul ignore file */
import React from 'react';
import {
  PERFORMANCE_CONFIDENCE_GRADES,
  PERFORMANCE_DETAILS_SECTIONS,
  PERFORMANCE_GRADES,
  PERFORMANCE_GRADES_ALIAS,
  PerformanceSectionVersions,
} from 'erp/employee/salaries/shared/salaryService';
import { EmployeePerformanceDetailsInputProps } from 'erp/employee/salaries/shared/inputs/EmployeePerformanceDetailsInput';
import FormSection from 'shared/layout/form/FormSection';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import { get } from 'lodash';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const showConfidenceInput = (formData: any, source: string) => {
  const evaluationGrade = get(formData, `${source}.evaluationGrade`);
  return evaluationGrade && evaluationGrade !== PERFORMANCE_GRADES_ALIAS.NA;
};

const showDetailsFields = (formData: any, source: string) => {
  return get(formData, `${source}.evaluationGrade`);
};

const EmployeePerformanceInput = ({ source }: EmployeePerformanceDetailsInputProps) => {
  const { data: formData = {} } = useFormContext();
  const oldModel = formData[`${source}`]?.oldModel;

  return (
    <div>
      {PERFORMANCE_DETAILS_SECTIONS.filter(
        section =>
          section.version === PerformanceSectionVersions.GENERAL ||
          (oldModel
            ? section.version === PerformanceSectionVersions.OLD
            : section.version === PerformanceSectionVersions.NEW),
      ).map(section => {
        const fieldSource = `${source}.${section.field}`;
        return (
          <div>
            <FormSection title={`${section.title}`} titleVariant="h5" key={section.field}>
              <MediumFormRow>
                <EnumDropdown
                  options={PERFORMANCE_GRADES}
                  source={`${fieldSource}.evaluationGrade`}
                  label="Evaluation Grade"
                  placeholder="Select grade"
                />
                <ClearAfterUnmountWrapper isVisible={data => showConfidenceInput(data, fieldSource)}>
                  <EnumDropdown
                    options={PERFORMANCE_CONFIDENCE_GRADES}
                    source={`${fieldSource}.confidenceGrade`}
                    label="Confidence Grade"
                    placeholder="Select grade"
                  />
                </ClearAfterUnmountWrapper>
              </MediumFormRow>
              <MediumFormRow>
                <ClearAfterUnmountWrapper isVisible={data => showDetailsFields(data, fieldSource)}>
                  <TextArea
                    source={`${fieldSource}.positiveDetails`}
                    label="Positive Details"
                    tooltip="Provide examples of positive events with an employee, if applicable."
                  />
                </ClearAfterUnmountWrapper>
                <ClearAfterUnmountWrapper isVisible={data => showDetailsFields(data, fieldSource)}>
                  <TextArea
                    source={`${fieldSource}.negativeDetails`}
                    label="Negative Details"
                    tooltip="Provide examples of negative events with an employee, if applicable."
                  />
                </ClearAfterUnmountWrapper>
              </MediumFormRow>
            </FormSection>
          </div>
        );
      })}
    </div>
  );
};

export default EmployeePerformanceInput;
