/* istanbul ignore file */
import React from 'react';
import ContactsBreadcrumbs from 'crm/contact/shared/ContactsBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateContactBreadcrumbs = () => (
  <ContactsBreadcrumbs>
    <CreateBreadcrumbItem entity="Contact" />
  </ContactsBreadcrumbs>
);

export default CreateContactBreadcrumbs;
