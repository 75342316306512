/* istanbul ignore file */
import { TextField } from 'shared/uibuilder/field';
import React from 'react';
import { ActivityType } from 'crm/shared/timeline/activity/crmActivityService';
import FieldGroup from 'shared/uibuilder/layout/FieldGroup';
import CommaListField from 'shared/uibuilder/field/CommaListFIeld';

const ActivityFields = () => {
  return (
    <>
      <FieldGroup isVisible={data => ActivityType[data.getValueBySource('activityType')] === ActivityType.EMAIL}>
        <TextField source="senderEmail" label="From" />
        <CommaListField source="receiversEmails" label="To" />
        <CommaListField source="ccEmails" label="CC" />
        <CommaListField source="bccEmails" label="BCC" />
      </FieldGroup>

      <FieldGroup isVisible={data => ActivityType[data.getValueBySource('activityType')] === ActivityType.MEETING}>
        <TextField source="location" />
      </FieldGroup>

      <FieldGroup isVisible={data => ActivityType[data.getValueBySource('activityType')] === ActivityType.CALL}>
        <TextField source="communicationChannel" />
      </FieldGroup>
    </>
  );
};

export default ActivityFields;
