/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleContractsBreadcrumbs from 'erp/employee/contracts/shared/SingleContractsBreadcrumbs';

const UpdateEmployeeContractBreadCrumbs = () => {
  return (
    <SingleContractsBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleContractsBreadcrumbs>
  );
};

export default UpdateEmployeeContractBreadCrumbs;
