/* istanbul ignore file */
import React from 'react';

import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import useCrmActivityItem from 'crm/shared/timeline/activity/crmActivityRegistry';
import useCrmNoteItem from 'crm/shared/timeline/note/crmNoteRegistry';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import CrmTimelineEntryTypeFilter from 'crm/shared/timeline/filter/CrmTimelineEntryTypeFilter';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';
import OpportunityEntityHeader from 'crm/opportunity/shared/OpportunityEntityHeader';
import OpportunityMenu from 'crm/opportunity/shared/OpportunityMenu';
import OpportunityTimelineBreadcrumbs from 'crm/opportunity/Timeline/Breadcrumbs';

const OpportunityTimelinePage = () => {
  const opportunityId = useOpportunityId();
  const {
    getById,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    getFilterValidationSchema,
  } = useOpportunityService();

  const activityRegistry = useCrmActivityItem();
  const noteRegistry = useCrmNoteItem();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <CrmTimelineEntryTypeFilter label="Entry Type" source="type" />
        <DateTimeRangeFilter label="Created date" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );
  return (
    <Show getDataMethod={() => getById(opportunityId)}>
      <Timeline
        registries={[noteRegistry, activityRegistry]}
        getDataMethod={request => searchTimelineItems(opportunityId, request)}
        createTimelineItemMethod={(itemType, request) => createTimelineItem(opportunityId, itemType, request)}
        getPageByEntryIdMethod={id => getTimelinePageById(opportunityId, id)}
      >
        <TimelinePageLayout
          menu={<OpportunityMenu />}
          breadcrumbs={<OpportunityTimelineBreadcrumbs />}
          entityHeader={<OpportunityEntityHeader />}
          filter={filters}
        >
          <TimelineLayout noEntriesMessage="No entries in this opportunity timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default OpportunityTimelinePage;
