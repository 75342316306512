import React, { useState } from 'react';
import useSyberryTodayService, { BACKLOG_CATEGORIES, getSyberryTodayError } from 'syberryToday/useSyberryTodayService';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

interface ProjectIssueContextMenuData {
  item: ListItem;
  updateInList: (entity: ListItem) => void;
  removeFromList: (entityId: number) => void;
}

const useProjectIssueContextMenu = ({ item, updateInList, removeFromList }: ProjectIssueContextMenuData) => {
  const [contextMenu, setContextMenu] = useState<Nullable<{ top: number; left: number }>>(null);
  const { backlogCategory } = item;
  const isBlocked = backlogCategory === BACKLOG_CATEGORIES.BLOCKED;
  const isReccurently = backlogCategory === BACKLOG_CATEGORIES.RECCURENTLY;

  const { updateIssueCategory } = useSyberryTodayService();
  const { addMessage } = useMessageService();
  const { setLoading = () => {} } = useSyberryTodayContext();

  const onUpdateIssueCategory = async (newCategory: BACKLOG_CATEGORIES, callback: () => void) => {
    handleClose();

    try {
      setLoading(true);

      await updateIssueCategory({
        backlogCategory: newCategory,
        id: item.id,
      });

      callback();
    } catch (e) {
      const errorMessage = await getSyberryTodayError(e, "Can't update issue category");

      addMessage(new ErrorMessage(errorMessage));
    } finally {
      setLoading(false);
    }
  };

  const onRecurrentlyBtnClick = async () =>
    onUpdateIssueCategory(isReccurently ? BACKLOG_CATEGORIES.DO_LATER : BACKLOG_CATEGORIES.RECCURENTLY, () => {
      removeFromList(item.id);
    });

  const onBlockBtnClick = async () => {
    const newCategory = isBlocked ? BACKLOG_CATEGORIES.DO_LATER : BACKLOG_CATEGORIES.BLOCKED;

    return onUpdateIssueCategory(newCategory, () => {
      updateInList({ ...item, backlogCategory: newCategory });
    });
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            left: event.clientX + 2,
            top: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return {
    handleContextMenu,
    handleClose,
    contextMenu,
    onRecurrentlyBtnClick,
    onBlockBtnClick,
  };
};

export default useProjectIssueContextMenu;
