/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const CHILD_RELATION_OPTIONS = {
  Son: 'Son',
  Daughter: 'Daughter',
};

const ChildRelationInput = props => {
  return <EnumDropdown {...props} options={CHILD_RELATION_OPTIONS} />;
};

export default ChildRelationInput;
