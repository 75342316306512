import React from 'react';
import classNames from 'classnames';
import BaseInputLayout, { COMMON_INPUT_LAYOUT_DEFAULT_PROPS, getBaseInputLayoutProps } from './BaseInputLayout';
import { RadioButtonGroupLayoutType } from 'shared/uibuilder/form/input/RadioButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './RadioButtonGroupLayout.scss';

const RadioButtonGroupLayout: RadioButtonGroupLayoutType = ({
  options,
  value,
  onChangeCallback,
  onFocusCallback,
  source,
  isColumnDirection,
  className,
  ...props
}) => {
  return (
    <BaseInputLayout
      {...getBaseInputLayoutProps({ ...props, source })}
      className={classNames(className, 'radio-control-wrapper', { 'radio-control-wrapper__column': isColumnDirection })}
    >
      <RadioGroup
        row={!isColumnDirection}
        aria-labelledby={`${source}-radios`}
        name={source}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (onFocusCallback) {
            onFocusCallback();
          }

          if (onChangeCallback) {
            onChangeCallback({
              [source]: (event.target as HTMLInputElement).value,
            });
          }
        }}
      >
        {options.map(item => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            label={item.label}
            control={<Radio sx={{ '& .MuiSvgIcon-root': { width: '20px', height: '20px' } }} />}
          />
        ))}
      </RadioGroup>
    </BaseInputLayout>
  );
};

RadioButtonGroupLayout.defaultProps = {
  ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  isColumnDirection: false,
};

export default RadioButtonGroupLayout;
