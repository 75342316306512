/* istanbul ignore file */
import React, { useEffect } from 'react';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import VacancyForm from 'erp/recruitment/vacancy/createupdate/VacancyForm';

type OneStopVacancyProps = {
  isOneStop?: boolean;
};

const VacancyFormCreate = ({ isOneStop = true }: OneStopVacancyProps) => {
  const { onChangeCallback } = useFormContext();
  const { getInitialData } = useVacancyService();

  useEffect(() => {
    (async () => {
      if (isOneStop && onChangeCallback) {
        const initData = await getInitialData(false);
        onChangeCallback(initData, true);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <VacancyForm isCreateForm isOneStop={isOneStop} />;
};

export default VacancyFormCreate;
