/* istanbul ignore file */
import React from 'react';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import { CREATE_INVOICE } from 'financialAnalytic/invoice/useInvoiceService';
import CreateButton from 'uibuilder/button/CreateButton';

const CreateInvoiceButton = () => {
  const { getCreateUrl } = useInvoiceUrl();

  return (
    <CreateButton url={getCreateUrl()} label="Add Invoice" key="create-invoice" permissionToCheck={CREATE_INVOICE} />
  );
};

export default CreateInvoiceButton;
