/* istanbul ignore file */
import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import useInvoiceService, { READ_INVOICE_LIST } from 'financialAnalytic/invoice/useInvoiceService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const DownloadInvoicesReportButton = () => {
  const { runDownloadInvoicesReport } = useInvoiceService();
  const { isGranted } = useAuthorization();
  const { filter, sort } = useListContext();
  const { formatDate } = useDateService();

  const displayMessage: any[] =
    filter === undefined || Object.keys(filter).length === 0
      ? [`Do you really want to download invoices report without filters?`]
      : [`Do you really want to download invoices report with next filters:`];
  const periodFilter =
    filter?.invoiceDate === undefined
      ? [``]
      : [
          <br />,
          `Period: `,
          filter.invoiceDate?.ge === undefined
            ? ``
            : `from ${formatDate(filter.invoiceDate.ge, DATE_FORMAT.SIMPLE)?.trim()} `,
          filter.invoiceDate?.le === undefined
            ? ``
            : `to ${formatDate(filter.invoiceDate.le, DATE_FORMAT.SIMPLE)?.trim()}`,
        ];
  const futureExpenseFilter =
    filter?.isFutureExpense?.eq === undefined ? [``] : [<br />, `Is future expense: ${filter.isFutureExpense.eq}`];
  const documentArriveLaterFilter =
    filter?.isInvoiceDocumentWillArriveLater?.eq === undefined
      ? [``]
      : [<br />, `Is can be updated: ${filter.isInvoiceDocumentWillArriveLater.eq}`];
  const officesFilter = filter?.office?.in === undefined ? [``] : [<br />, `Offices: ${filter.office.in}`];
  const statusesFilter = filter?.status?.in === undefined ? [``] : [<br />, `Statuses: ${filter.status.in}`];

  return isGranted(READ_INVOICE_LIST) ? (
    <ButtonWithConfirmation
      displayMessage={displayMessage.concat(
        periodFilter,
        futureExpenseFilter,
        documentArriveLaterFilter,
        officesFilter,
        statusesFilter,
      )}
      submitMethod={() => runDownloadInvoicesReport(filter, sort)}
      permissionToCheck={READ_INVOICE_LIST}
      afterSubmit={{
        successMessage: 'Invoices report successfully downloaded.',
        errorMessage: "Can't launch invoices report download.",
      }}
      outline
    >
      Download invoices report
    </ButtonWithConfirmation>
  ) : null;
};

export default DownloadInvoicesReportButton;
