import useRoomService from 'erp/room/useRoomService';
import { useRoomId } from 'erp/room/RoomRouter';
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import ViewRoomPageContent from 'erp/room/Show/ViewRoomPageContent';

const RoomViewPage = () => {
  const { getById } = useRoomService();
  const id = useRoomId();

  const getData = async () => {
    const { squareMetersArea, ...data } = await getById(id);

    let area = null;
    if (squareMetersArea) {
      area = `${squareMetersArea}m²`;
    }

    return {
      squareMetersArea: area,
      ...data,
    };
  };

  return (
    <Show getDataMethod={() => getData()}>
      <ViewRoomPageContent />
    </Show>
  );
};

export default RoomViewPage;
