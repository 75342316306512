/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useSkillBasedQueueService from 'erp/skillbasedqueue/shared/skillBasedQueueService';

const SkillBasedQueueContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useSkillBasedQueueService();
  const CONTEXT_NAME = 'SKILL_BASED_QUEUE_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default SkillBasedQueueContext;
