import { useAuthenticationApi } from 'api';

export const RESOURCE_URL = '/employees';

export interface Me {
  artifacts: {
    [key: string]: {
      id: string;
      title: string;
      type: string;
      thumbnails: {
        [key: string]: string;
      };
    };
  };
  info: {
    [key: string]: any;
  };
  permissions: {
    [key: string]: boolean;
  };
}

export interface MeService {
  getMe: () => Promise<Me>;
}

const useMeService = (): MeService => {
  const { sendGetRequest } = useAuthenticationApi();

  const getMe = async (): Promise<Me> => {
    const response = await sendGetRequest(`${RESOURCE_URL}/me`);
    return response.json();
  };

  return {
    getMe,
  };
};

export default useMeService;
