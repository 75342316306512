/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import FinanceBreadcrumbs from 'erp/employee/finance/shared/FinanceBreadcrumbs';

const CreateFinanceBreadcrumbs = () => {
  return (
    <FinanceBreadcrumbs>
      <CreateBreadcrumbItem entity="Compensation package" />
    </FinanceBreadcrumbs>
  );
};

export default CreateFinanceBreadcrumbs;
