/* istanbul ignore file */
// libs
import React from 'react';
import { useParams } from 'react-router-dom';
import { element, node, bool } from 'prop-types';
// components
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowAccountPageMenu from 'crm/account/Show/ShowAccountPageMenu';
import SingleAccountBreadcrumbs from 'crm/account/shared/SingleAccountBreadcrumbs';
// services
import useAccountService from 'crm/account/accountService';
import AccountEntityHeader from 'crm/account/shared/AccountEntityHeader';

const ViewAccount = ({ children, breadcrumbs, hasEntityHeader = true }) => {
  const { id } = useParams();
  const { getById } = useAccountService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        menu={<ShowAccountPageMenu />}
        breadcrumbs={breadcrumbs}
        entityHeader={hasEntityHeader ? <AccountEntityHeader /> : null}
      >
        {children}
      </ShowPageLayout>
    </Show>
  );
};

ViewAccount.propTypes = {
  children: node.isRequired,
  breadcrumbs: element,
  hasEntityHeader: bool,
};

ViewAccount.defaultProps = {
  breadcrumbs: <SingleAccountBreadcrumbs />,
  hasEntityHeader: true,
};

export default ViewAccount;
