/* istanbul ignore file */

export default {
  officeId: {
    type: 'enum',
    required: true,
  },
  number: {
    type: 'string',
    maxSize: 64,
    required: {
      value: true,
      when: {
        $officeId: {
          nin: ['3', 3],
        },
      },
    },
  },
  dateOfSignature: {
    type: 'date',
    maxDate: '$startDate',
    required: true,
  },
  position: {
    type: 'enum',
    required: true,
  },
  type: {
    type: 'enum',
    required: true,
  },
  startDate: {
    type: 'date',
    minDate: '$dateOfSignature',
    required: true,
  },
  endDate: {
    type: 'date',
    required: {
      value: true,
      when: [
        {
          $indefinite: {
            eq: false,
          },
        },
      ],
    },
    minDate: '$startDate',
  },
  paidLeaveBalance: {
    type: [
      {
        value: 'int',
        when: {
          $type: {
            eq: 'B2B',
          },
        },
      },
      {
        value: 'enum',
        when: {
          $type: {
            eq: 'EC',
          },
        },
      },
    ],
    minValue: 0,
    maxValue: 26,
    required: {
      value: true,
      when: [
        {
          $type: {
            eq: 'B2B',
          },
          $officeId: {
            in: ['3', 3],
          },
        },
        {
          $type: {
            eq: 'EC',
          },
          $officeId: {
            in: ['3', 3],
          },
        },
      ],
    },
  },
  previousPaidLeaveBalance: {
    type: 'int',
    minValue: 0,
    maxValue: 26,
  },
  assignmentType: {
    type: 'enum',
    required: true,
  },
  workRate: {
    type: 'number',
    minValue: 1,
    maxValue: 100,
    required: {
      value: true,
      when: {
        $assignmentType: {
          eq: 'PART_TIME',
        },
      },
    },
  },
  scansIds: {
    type: 'array',
    maxSize: 10,
  },
  hasProbationPeriod: {
    type: 'boolean',
    required: {
      value: true,
      when: [
        {
          $type: {
            ne: 'INTERNSHIP',
          },
        },
        {
          $type: {
            ne: 'CFS',
          },
        },
      ],
    },
  },
  probationPeriodEndedAt: {
    type: 'date',
    minDate: '$startDate',
  },
  comment: {
    required: {
      value: true,
      when: {
        $ignoreNotStrictRules: {
          eq: true,
        },
      },
    },
  },
};
