/* istanbul ignore file */
import React from 'react';
import EmployeeSalaryBreadcrumbs from 'erp/employee/salaries/shared/EmployeeSalaryBreadcrumbs';
import UpdateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateSalaryBreadcrumbs = () => {
  return (
    <EmployeeSalaryBreadcrumbs>
      <UpdateBreadcrumbItem entity="Salary" />
    </EmployeeSalaryBreadcrumbs>
  );
};

export default UpdateSalaryBreadcrumbs;
