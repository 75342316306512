import React from 'react';
import { useIntegrationTestRoutes } from 'integration-tests/Router';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { CreateForm } from 'shared/uibuilder/form/index';
import useTestingCrudService from 'integration-tests/testingCrudService';
import FormBuilder from 'shared/uibuilder/form/Builder';
import formInputs from './formInputs';

const IntegrationFormBuilderPage = () => {
  const { create } = useTestingCrudService();
  const { createRoute } = useIntegrationTestRoutes();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: createRoute,
        message: 'You are awesome!',
      }}
    >
      <CreatePageLayout title="Form Builder Create Form">
        <FormBuilder formInputs={formInputs} />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default IntegrationFormBuilderPage;
