/* istanbul ignore file */
import { Marker } from 'camunda/monitoring/facade/useBpmnJs';
import './markers.scss';
import { DATE_FORMAT, DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService';
import momentTimezone from 'moment-timezone';

class DueDateMarker implements Marker {
  activityId: string;

  html: string;

  position: {
    top: number;
    left: number;
  };

  constructor(activityId: string, value: string) {
    this.activityId = activityId;

    const caption = momentTimezone
      .utc(value)
      .tz(DEFAULT_TIMEZONE)
      .format(DATE_FORMAT.FULL);

    this.html = `<span class="due-date">${caption}</span>`;
    this.position = {
      left: -47,
      top: -25,
    };
  }
}

export default DueDateMarker;
