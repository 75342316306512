/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import HandbookPage from 'handbook/Show/HandbookPage';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const HANDBOOK_PATH = '/handbook';

export const useHandbookRoute = () => {
  return useRoute({ listRoute: HANDBOOK_PATH });
};

export const useHandbookUrl = () => {
  return useEntityUrl({
    baseLink: HANDBOOK_PATH,
  });
};

const HandbookRouter: Router = () => {
  const { listRoute } = useHandbookRoute();

  return (
    <ProtectedRouter basePath={HANDBOOK_PATH}>
      <Switch>
        <Route path={listRoute}>
          <HandbookPage />
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

HandbookRouter.getRouterPath = () => HANDBOOK_PATH;

export default HandbookRouter;
