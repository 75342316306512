/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import OwnerDropdown from 'crm/shared/input/OwnerDropdown';
import useCrmService from 'crm/crmService';
import InputLoader from '../../../shared/uibuilder/InputLoader';
import './OwnerFilter.scss';

const OwnerFilter = props => {
  const { getSalesManagers, divideSalesManagersByActiveAndDismissed } = useCrmService();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const salesManagers = await getSalesManagers();
        const { activeSales, inactiveSales } = divideSalesManagersByActiveAndDismissed(salesManagers);
        let newSales = [];
        if (activeSales.length) {
          newSales = [{ nameEn: { firstName: 'Active', lastName: 'sales' } }, ...activeSales];
        }

        if (inactiveSales.length) {
          newSales = [...newSales, { nameEn: { firstName: 'Dismissed', lastName: 'sales' } }, ...inactiveSales];
        }

        setSales(newSales);
      } catch (e) {
        setSales([]);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <InputLoader />
  ) : (
    <DropDownFilter {...props}>
      <OwnerDropdown sales={sales} className="dropdown-with-title" />
    </DropDownFilter>
  );
};

export default OwnerFilter;
