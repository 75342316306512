/* istanbul ignore file */
import React from 'react';
import './AccessLog.css';

const AccessLogManual = () => (
  <div className="access-log-container">
    <p>
      The page provides a quick access to the access log stored in DynamoDB. Currently, the system tracks only access to
      candidates database.
    </p>
    <p>The generated report contains the following columns:</p>
    <table className="access-log">
      <tbody>
        <tr>
          <td>
            <b>Column name</b>
          </td>
          <td>
            <b>Description</b>
          </td>
        </tr>
        <tr>
          <td>dateTime</td>
          <td>Date and time of event</td>
        </tr>
        <tr>
          <td>employeeId</td>
          <td>An employee who made the action</td>
        </tr>
        <tr>
          <td>employeeFullName</td>
          <td>The full name of an employee who made the action</td>
        </tr>
        <tr>
          <td>entityId</td>
          <td>The identifier of entity which was accessed</td>
        </tr>
        <tr>
          <td>actionDetails</td>
          <td>
            The identifier of specific action made with entity Currently, the following actions are tracked:
            <br />
            CANDIDATE_LIST_VIEWED,
            <br />
            CANDIDATE_PAGE_VIEWED,
            <br />
            CANDIDATE_PAGE_EDITED,
            <br />
            RESUME_PAGE_VIEWED,
            <br />
            RESUME_DOWNLOADED,
            <br />
            CANDIDATE_TIMELINE_VIEWED
            <br />
          </td>
        </tr>
        <tr>
          <td>details</td>
          <td>Any metadata related to the action</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default AccessLogManual;
