import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { ACCOUNTING_CYCLE_PATH, GET_ACCOUNTING_CYCLE } from './useAccountingCycle';
import AccountingCycleList from './List';
import ShowAccountingCycle from './Show/ShowAccountingCycle';

export const useAccountingCycleRoute = () => {
  return useRoute({
    listRoute: ACCOUNTING_CYCLE_PATH,
  });
};

export const useAccountingCycleUrl = () => {
  return useEntityUrl({
    baseLink: ACCOUNTING_CYCLE_PATH,
  });
};

export const useAccountingCycleId = () => useParams<Dictionary<string>>().id;

const AccountingCycleRouter = () => {
  const { listRoute, singleEntityRoute } = useAccountingCycleRoute();

  return (
    <ProtectedRouter basePath={ACCOUNTING_CYCLE_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={GET_ACCOUNTING_CYCLE}>
          <AccountingCycleList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={GET_ACCOUNTING_CYCLE}>
          <ShowAccountingCycle />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

AccountingCycleRouter.getRouterPath = () => ACCOUNTING_CYCLE_PATH;

export default AccountingCycleRouter;
