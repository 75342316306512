import useCorporateEventService from 'financialAnalytic/corporateEvents/useCorporateEventService';
import { useCorporateEventId } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import ViewCorporateEventBusinessUnitsPage from 'financialAnalytic/corporateEvents/Show/businessUnits/ViewCorporateEventBusinessUnitsPage';

const CorporateEventBusinessUnitsViewPage = () => {
  const { getById } = useCorporateEventService();
  const id = useCorporateEventId();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewCorporateEventBusinessUnitsPage />
    </Show>
  );
};

export default CorporateEventBusinessUnitsViewPage;
