/* istanbul ignore file */
import React from 'react';
import { BreadcrumbItem, EntityBreadcrumbItemProps } from 'shared/uibuilder/BreadCrumbs';

const UpdateBreadcrumbItem = ({ entity, ...props }: Partial<EntityBreadcrumbItemProps>) => {
  return <BreadcrumbItem {...props}>{`Edit ${entity}`}</BreadcrumbItem>;
};

UpdateBreadcrumbItem.defaultProps = {
  entity: '',
};

export default UpdateBreadcrumbItem;
