import React from 'react';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { string } from 'prop-types';

/**
 @param onBehalfSource - when employee wasn't selected, string value should be saved
 @param onBehalfOfEmployeeSource - source for saving selected employee alias
 @param onBehalfOfEmployeeNameSource - when feedback is created with selected employee, in initial state of input we need to
 display Employee Name
 */

const OnBehalfOfInput = ({ onBehalfOfSource, onBehalfOfEmployeeSource, onBehalfOfEmployeeNameSource, ...props }) => {
  const { getOnChangeCallback, getValue: getOnBehalfValue } = useInputHelper({ source: onBehalfOfSource });
  const { getOnChangeCallback: getEmployeeAliasOnChangeCallback } = useInputHelper({
    source: onBehalfOfEmployeeSource,
  });

  const onInputChange = getOnChangeCallback();
  const onEmployeeChange = getEmployeeAliasOnChangeCallback();

  // handler on suggestion selected from dropdown options
  const handleSuggestionSelected = data => {
    onEmployeeChange({ target: { value: data[onBehalfOfEmployeeSource] } });
    onInputChange({ target: { value: null } });
  };

  // handler for input change on typing (query string)
  const handleInputChange = query => {
    onEmployeeChange({ target: { value: null } });
    onInputChange({ target: { value: query } });
  };

  const decorateValue = value => value.replace(/[^a-zA-Z- ]/g, '');

  return (
    <EmployeeInput
      {...props}
      onChangeCallback={handleSuggestionSelected}
      source={onBehalfOfEmployeeSource}
      nameSource={getOnBehalfValue() ? onBehalfOfSource : onBehalfOfEmployeeNameSource}
      onInputChangeCallback={handleInputChange}
      valueDecorator={decorateValue}
    />
  );
};

OnBehalfOfInput.propTypes = {
  onBehalfOfSource: string.isRequired,
  onBehalfOfEmployeeSource: string.isRequired,
  onBehalfOfEmployeeNameSource: string.isRequired,
};

export default OnBehalfOfInput;
