import { useBusinessEngineApi } from 'api';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';

export const MODIFY_INSTANCE_TOKEN_POSITIONS = 'MODIFY_INSTANCE_TOKEN_POSITIONS';
export enum ProcessInstanceTokenAction {
  CREATE = 'Create',
  RECREATE = 'Recreate',
  MOVE = 'Move',
  KILL = 'Kill',
}

const useProcessInstanceTokenService = () => {
  const processInstanceId = useProcessInstanceId();
  const { sendPutRequest, sendPostRequest, sendDeleteRequest } = useBusinessEngineApi();

  const create = async (request: any) => {
    const response = await sendPostRequest(`/camunda/processes/${processInstanceId}/tokens/create`, request);
    return response;
  };

  const recreate = async (request: any) => {
    const response = await sendPutRequest(`/camunda/processes/${processInstanceId}/tokens/recreate`, request);
    return response;
  };

  const move = async (request: any) => {
    const response = await sendPutRequest(`/camunda/processes/${processInstanceId}/tokens/move`, request);
    return response;
  };

  const kill = async (request: any) => {
    const response = await sendDeleteRequest(`/camunda/processes/${processInstanceId}/tokens/kill`, request);
    return response;
  };

  return {
    create,
    recreate,
    move,
    kill,
  };
};

export default useProcessInstanceTokenService;
