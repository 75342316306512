/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';

export interface EditBudgetButtonProps extends EditButtonProps {}

const EditBudgetButton = (props: EditBudgetButtonProps) => {
  const { getUpdateEntityUrl } = useBudgetUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} {...props} />;
};

export default EditBudgetButton;
