import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import UploadWbsForm from '../UploadWBS/UploadWBSForm';
import { PROJECT_CONTEXT } from '../../ProjectContext';
import useAuthorization from 'shared/authorization/authorizationService';
import { CREATE_WBS } from '../useProjectsWBSDashboardService';

const UploadWbsButton = () => {
  const { isGranted: globalIsGranted } = useAuthorization();
  const isGranted = (permission: string | string[]) => globalIsGranted(permission, PROJECT_CONTEXT);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const openFeedbackForm = () => setIsFormOpen(true);

  const closeFeedbackForm = () => setIsFormOpen(false);

  if (!isGranted(CREATE_WBS)) {
    // TODO Uncomment permission check when BE is ready
    // return null;
  }

  return (
    <>
      <Button
        className="instance-feedback-button"
        onClick={openFeedbackForm}
        outline
        sx={{ display: { xs: 'none', md: 'inline-flex' }, ml: '20px' }}
      >
        <span>Upload</span>
      </Button>
      {isFormOpen && <UploadWbsForm closeModal={closeFeedbackForm} />}
    </>
  );
};

export default UploadWbsButton;
