import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import React, { useCallback, useEffect, useState } from 'react';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { FullDateField, TextField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { ActivityInstance } from 'camunda/monitoring/instance/processInstanceService';
import VariablesLinkField from 'camunda/monitoring/instance/Show/history/VariablesLinkField';
import ActivityIdField from 'camunda/monitoring/instance/Show/history/ActivityIdField';
import { BpmnDiagramService, EventType } from 'camunda/monitoring/facade/useBpmnJs';
import './ProcessHistory.scss';
import MediumHeading from 'camunda/monitoring/instance/Show/MediumHeading';
import SimpleTooltip from 'shared/uibuilder/SimpleTooltip';
import 'camunda/monitoring/instance/shared/ProcessTooltip.scss';

interface Props {
  bpmnJs?: BpmnDiagramService;
}
const ProcessHistory = ({ bpmnJs }: Props) => {
  const { data } = useShowContext();
  const activities =
    data &&
    data.getValueBySource('activities')?.sort((a1: ActivityInstance, a2: ActivityInstance) => {
      let result;
      if (a1.startDateTime > a2.startDateTime) {
        result = -1;
      } else if (a1.startDateTime < a2.startDateTime) {
        result = 1;
      } else {
        result = 0;
      }
      return result;
    });

  const [highlightedItem, setHighlightedItem] = useState<any>();
  const getRowProps = useCallback(
    (activity: ActivityInstance) => {
      return highlightedItem &&
        activity.executionId === highlightedItem.executionId &&
        activity.activityId === highlightedItem.activityId
        ? { className: 'clicked-history-item activity-row' }
        : { className: 'activity-row' };
    },
    [highlightedItem],
  );

  const isDiagramLoaded = bpmnJs?.isLoaded();

  useEffect(() => {
    if (isDiagramLoaded && activities) {
      bpmnJs?.addEventListener(e => {
        if (e.type === EventType.CLICK) {
          const activityId = e.element?.id;
          setHighlightedItem({
            activityId,
            executionId: activities.find((a: ActivityInstance) => a.activityId === activityId)?.executionId,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDiagramLoaded, activities]);

  const getDataMethod = async () => {
    return {
      totalElements: activities.length,
      totalPages: 1,
      pageNumber: 1,
      result: activities,
    };
  };

  return (
    <>
      <div className="tooltip-wrapper">
        <MediumHeading>History</MediumHeading>
        <SimpleTooltip id="history-tooltip" isBigText className="process-tooltip">
          Use double click on a diagram element to open variables. Click on a passed activity on the diagram to
          highlight it in the table.
        </SimpleTooltip>
      </div>
      <RelatedList getDataMethod={getDataMethod}>
        <RelationListLayout actions={[]}>
          <DataGridLayout showTotal={false} buttons={[]} isSmallTable rowProps={getRowProps}>
            <ActivityIdField
              activitySource="activityId"
              executionSource="executionId"
              bpmnJs={bpmnJs}
              setHighlightedItem={setHighlightedItem}
              label="Activity ID"
            />
            <TextField source="activityName" />
            <VariablesLinkField source="executionId" label="Variables link" />
            <FullDateField source="startDateTime" label="Started at" />
            <FullDateField source="endDateTime" label="Ended at" />
          </DataGridLayout>
        </RelationListLayout>
      </RelatedList>
    </>
  );
};

export default ProcessHistory;
