/* istanbul ignore file */
import React from 'react';
import FloatingButtons from 'uibuilder/form/FloatingButtons';
import DefaultFormPageLayout, { DefaultFormPageLayoutProps } from 'shared/layout/form/DefaultFormPageLayout';
import FormPageLayoutWithMenu from 'shared/layout/form/FormPageLayoutWithMenu';

export interface DavinciFormPageLayoutProps extends Omit<DefaultFormPageLayoutProps, 'buttons'> {
  shouldScrollToErrorAfterLoading?: boolean;
  isCancelButtonVisible?: boolean;
  menu?: Nullable<React.ReactElement>;
  buttons?: React.ReactElement;
}

const DavinciFormPageLayout = ({
  shouldScrollToErrorAfterLoading = false,
  isCancelButtonVisible = true,
  menu = null,
  buttons,
  ...props
}: DavinciFormPageLayoutProps) => {
  const Layout = menu ? FormPageLayoutWithMenu : DefaultFormPageLayout;

  return (
    <Layout
      buttons={
        buttons || (
          <FloatingButtons
            shouldScrollToErrorAfterLoading={shouldScrollToErrorAfterLoading}
            isCancelButtonVisible={isCancelButtonVisible}
          />
        )
      }
      menu={menu as React.ReactElement}
      {...props}
    />
  );
};

export default DavinciFormPageLayout;
