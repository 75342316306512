import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledSwitch } from 'uibuilder/layout/form/input/TogglerInputLayout';
import useProjectsSettings from './useProjectsSettings';

const ProjectsArchivedToggler = () => {
  const { setArchivedProjectsVisible, areArchivedProjectsVisible } = useProjectsSettings();
  const { loading } = useListContext();

  const onChangeCallback = (event: any) => {
    setArchivedProjectsVisible(event.target.checked);
  };

  return (
    <FormControlLabel
      className="settings__item"
      control={
        <StyledSwitch
          className="settings__switch"
          color="primary"
          onChange={onChangeCallback}
          checked={areArchivedProjectsVisible}
          dataOn="ON"
          dataOff="OFF"
          disabled={loading}
        />
      }
      label="Show archived projects"
    />
  );
};

export default ProjectsArchivedToggler;
