/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { CUSTOMER_INVOICE_STATUSES, UPDATE_CUSTOMER_INVOICE } from '../../useCustomerInvoiceService';
import { useCustomerInvoiceId, useCustomerInvoiceUrl } from '../../CustomerInvoicesRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const EditCustomerInvoiceButton = ({ statusSource, ...props }: any) => {
  const { getUpdateEntityUrl } = useCustomerInvoiceUrl();
  const id = useCustomerInvoiceId();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== CUSTOMER_INVOICE_STATUSES.PENDING) {
    return null;
  }

  return (
    <EditButton
      {...props}
      path={getUpdateEntityUrl(id)}
      key="edit-invoice"
      permissionToCheck={UPDATE_CUSTOMER_INVOICE}
      outline
      label="Edit"
    />
  );
};

export default EditCustomerInvoiceButton;
