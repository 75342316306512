/* istanbul ignore file */
import React from 'react';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import { useEmployeePerformanceUrl } from '../EmployeePerformanceRouter';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import ListEmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/List/ListEmployeePerformanceBreadcrumbs';
import List from 'shared/uibuilder/list/List';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_EMPLOYEE_PERFORMANCE } from 'erp/employee/employeeService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import DateField from 'shared/uibuilder/field/DateField';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';
import DownloadEmployeePerformanceButton from '../shared/button/DownloadEmployeePerformanceButton';

const EmployeePerformanceListPage = () => {
  const { search } = useEmployeePerformanceService();
  const { getCreateUrl, getSingleEntityUrl } = useEmployeePerformanceUrl();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<ListEmployeePerformanceBreadcrumbs />}
    >
      <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="relatedProcess.to">
        <RelationListLayout
          header="Performance Results"
          actions={[
            <CreateButton
              url={getCreateUrl}
              label="Add Performance Result"
              key="add-performance"
              permissionToCheck={CREATE_EMPLOYEE_PERFORMANCE}
            />,
          ]}
        >
          <DataGridLayout isSmallTable buttons={[<DownloadEmployeePerformanceButton />]}>
            <SearchLinkField source="id" label="ID" url={getSingleEntityUrl} isSortable />
            <EmployeeLinkField nameSource="resourceManagerName" source="resourceManagerId" label="Author" />
            <DateField source="relatedProcess.from" label="Period From" isSortable />
            <DateField source="relatedProcess.to" label="Period To" isSortable />
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default EmployeePerformanceListPage;
