/* istanbul ignore file */
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import contractFilterValidation from 'erp/contracts/List/contractFilterValidation';
import moment from 'moment/moment';

export const RESOURCE_URL = '/contracts';

export const READ_CONTRACT = 'READ';
export const READ_CONTRACTS_LIST = 'READ_CONTRACTS_LIST';
export const GET_ALL_CONTRACTS_LIST = 'GET_ALL_CONTRACTS_LIST';

const CONTRACT_REMAIN_MONTHS_WARN = 2;

export const getPreviousContract = (contracts, currentContract) => {
  const currentDate = (format = DATE_FORMAT.INPUT_VALUE) => moment.utc(moment.utc().format(format)).toString();
  const startDateOfCurrentContract = currentContract.startDate != null ? currentContract.startDate : currentDate;
  const previousContracts = contracts
    .filter(contract => startDateOfCurrentContract >= contract.endDate)
    .sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

  return previousContracts[0];
};

/**
 * Contracts service.
 */
const useContractsService = () => {
  const { sendPostRequest } = useKernelApi();
  const { getMonthsBetween } = useDateService();

  const baseCrudRequests = useBaseCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const search = async request => {
    const response = await sendPostRequest(RESOURCE_URL, request);
    const result = await response.json();
    return result;
  };

  const isContractExpired = dateValue => getMonthsBetween(dateValue) < 0;

  const isContractWillExpireSoon = dateValue => {
    const monthDiff = getMonthsBetween(dateValue);

    return monthDiff <= CONTRACT_REMAIN_MONTHS_WARN && monthDiff >= 0;
  };

  const isContractActive = (startDate, endDate) => getMonthsBetween(startDate) <= 0 && !isContractExpired(endDate);

  const getFiltersValidationSchema = () => Promise.resolve(contractFilterValidation);

  return {
    ...baseCrudRequests,
    search,
    isContractActive,
    isContractExpired,
    isContractWillExpireSoon,
    getFiltersValidationSchema,
  };
};

export default useContractsService;
