/* istanbul ignore file */
import React from 'react';
import { TextArea, TextInput } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

type CloseProcessIssueFormProps = {
  handleCancelClick: () => void;
};

const CloseProcessIssueForm = ({ handleCancelClick }: CloseProcessIssueFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  return (
    <>
      <TextInput source="issueIds" label="Issue id" isRequired disabled />
      <TextArea source="comment" label="Close reason" />
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default CloseProcessIssueForm;
