import React from 'react';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import SowForm from '../SowForm';
import { useSowUrl } from '../../SowRouter';
import useSowService from '../../useSowService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import CreateSowBreadcrumbs from './CreateSowBreadcrumbs';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';

const CreateSow = () => {
  const { data } = useShowContext();
  const sowId = data.getValueBySource('sowId');
  const projectName = data.getValueBySource('name');
  const accountId = data.getValueBySource('accountId');
  const { create, getValidationSchema } = useSowService();

  const { getListUrl } = useSowUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        projectName,
        accountId,
        id: sowId,
        currency: CURRENCY_TYPES.USD,
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The SOW has been successfully created.',
      }}
    >
      <CreatePageLayout
        title="Create: SOW"
        breadcrumbs={<CreateSowBreadcrumbs />}
        entityHeader={<ProjectEntityHeader />}
        menu={<ViewProjectPageMenu />}
      >
        <SowForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateSow;
