/* istanbul ignore file */
import { HashLink } from 'react-router-hash-link';
import React from 'react';
import * as domUtils from 'shared/uibuilder/domUtils';
import { getAnchor } from 'shared/uibuilder/helper';
import ListItem from '@mui/material/ListItem';

interface HeaderMenuSubItemProps {
  hash?: string;
  name: string;
  pathname: string;
  isVisible?: boolean;
  onClick?: () => void;
}

const HeaderMenuSubItem = ({ hash: propsHash, name, isVisible = true, pathname, onClick }: HeaderMenuSubItemProps) => {
  const hash = propsHash || getAnchor(name);

  return isVisible ? (
    <ListItem
      component={HashLink as any}
      key={hash}
      to={`${pathname}#${hash}`}
      scroll={(el: React.ReactElement) => domUtils.scrollToHash(el)}
      onClick={onClick}
    >
      {name}
    </ListItem>
  ) : null;
};

export default HeaderMenuSubItem;
