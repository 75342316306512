/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import FeedbackFlagAsInappropriateButton from 'erp/employee/Timeline/feedback/shared/button/FeedbackFlagAsInappropriateButton';

const FlagAsInappropriateTimelineRecordButton = () => {
  const { data: entity } = useShowContext();

  return (
    <MenuItem className="flag-toggler">
      <FeedbackFlagAsInappropriateButton entity={entity} />
    </MenuItem>
  );
};

export default FlagAsInappropriateTimelineRecordButton;
