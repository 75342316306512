/* istanbul ignore file */
import React from 'react';
import { ActivitiesMenuItem, SummaryMenuItem } from 'uibuilder/menuitem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_PRESALE_TIMELINE } from 'crm/opportunity/presale/presaleTimelineService';

const OpportunityMenu = () => {
  const id = useOpportunityId();
  const { getTimelineUrl, getSingleEntityUrl } = useOpportunityUrl();
  const { isGranted } = useAuthorization();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
      <ActivitiesMenuItem linkUrl={getTimelineUrl(id)} />
      <SidebarMenuItem
        linkUrl={`${getSingleEntityUrl(id)}/presale-timeline`}
        name="Operations"
        icon="bolt"
        isVisible={isGranted(READ_PRESALE_TIMELINE)}
      />
    </SidebarStickyMenu>
  );
};

export default OpportunityMenu;
