/* istanbul ignore file */
import React from 'react';
import { oneOfType, element, arrayOf } from 'prop-types';
import OneStopButtons from 'uibuilder/layout/form/OneStopButtonsLayout';
import './OneStopPageLayout.scss';

const OneStopPageLayout = ({ children }) => {
  return (
    <>
      <div className="position-relative one-stop-page">{children}</div>
      <OneStopButtons />
    </>
  );
};

OneStopPageLayout.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};

export default OneStopPageLayout;
