/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import { READ_PAYROLL_REPORTS, PAYROLL_REPORTS_PATH } from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import GeneratedPayrollListPage from 'erp/payroll/generatedPayrolls/List';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const useGeneratedPayrollsRoute = () => {
  return useRoute({ listRoute: PAYROLL_REPORTS_PATH });
};

export const useGeneratedPayrollsUrl = () => {
  return useEntityUrl({
    baseLink: PAYROLL_REPORTS_PATH,
  });
};

const GeneratedPayrollsRouter = () => {
  const { listRoute } = useGeneratedPayrollsRoute();

  return (
    <ProtectedRouter basePath={PAYROLL_REPORTS_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_PAYROLL_REPORTS}>
          <GeneratedPayrollListPage />
        </GlobalProtectedRoute>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

GeneratedPayrollsRouter.getRouterPath = () => PAYROLL_REPORTS_PATH;

export default GeneratedPayrollsRouter;
