/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import InvoiceBreadcrumbs from 'financialAnalytic/invoice/shared/InvoiceBreadcrumbs';

const SingleInvoiceBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useInvoiceUrl();
  const id = data?.getValueBySource('id');
  const invoiceNumber = data?.getValueBySource('invoiceNumber');

  return (
    <InvoiceBreadcrumbs>
      <SingleBreadcrumbItem entity={invoiceNumber || id} link={getSingleEntityUrl(id)} />
      {children}
    </InvoiceBreadcrumbs>
  );
};

export default SingleInvoiceBreadcrumbs;
