import React, { useRef, useState } from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import ReactSelect, { SingleValue, MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DefaultDropdownLayoutType } from 'shared/uibuilder/form/input/Dropdown';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { IndicatorsContainer } from './DropdownComponents';
import getDropdownLayoutCommonProps from './dropdownLayoutHelper';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import './DropdownLayout.scss';
import InputLoader from 'shared/uibuilder/InputLoader';

export const getDropdownValue = (multiple: boolean, value: string | string[], options: Option[]) => {
  if (multiple) {
    return options
      ?.filter(item => item.value && !item.disabled && value?.includes(item.value?.toString() as any))
      .sort((a, b) => value.indexOf(a.value?.toString()) - value.indexOf(b.value?.toString()));
  }

  return options?.find(item => item.value?.toString() === value?.toString()) || null;
};

const DefaultDropdownLayout: DefaultDropdownLayoutType = ({
  placeholder,
  options,
  multiple,
  source,
  filterOption,
  formatOptionLabel,
  styles,
  isLoading,
  isCreatable,
  onCreateOption,
  formatCreateLabel,
  ...defaultLayoutProps
}) => {
  const props = {
    source,
    ...defaultLayoutProps,
  };
  const selectRef = useRef<any>();
  const inputProps = getCommonInputProps(props);
  const { onChange, onBlur = () => {}, value } = inputProps;

  const { selectClassName, classNamePrefix } = getDropdownLayoutCommonProps(props);

  const selectOptions = options || [];
  const [onBlurCalled, setOnBlurCalled] = useState(false);

  useDidUpdateEffect(() => {
    onBlur();
    setOnBlurCalled(false);
  }, [onBlurCalled]);

  const { isRequired, ...baseInputLayoutProps } = getBaseInputLayoutProps(props);
  const { disabled, ...commonInputProps } = getCommonInputProps(props);

  const SelectComponent = isCreatable ? CreatableSelect : ReactSelect;

  return (
    <BaseInputLayout
      {...baseInputLayoutProps}
      isRequired={isRequired}
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <SelectComponent
        {...commonInputProps}
        ref={selectRef}
        onCreateOption={onCreateOption}
        formatCreateLabel={formatCreateLabel}
        isLoading={isLoading}
        loadingMessage={() => <InputLoader />}
        value={getDropdownValue(multiple || false, value || '', options || [])}
        className={selectClassName}
        classNamePrefix={classNamePrefix}
        isMulti={multiple}
        placeholder={placeholder}
        onBlur={() => setOnBlurCalled(true)}
        onChange={(newValue: SingleValue<Option> | MultiValue<Option>) => {
          if (multiple) {
            onChange({
              target: {
                options: (newValue as Option[])?.map((item: any) => ({ ...item, selected: true })) || [],
              },
            });
          } else {
            onChange({
              target: {
                value: (newValue as Option)?.value,
              },
            });
          }
        }}
        isSearchable
        options={selectOptions}
        isClearable
        id={`field-dropdown-${source}`}
        components={{
          IndicatorsContainer,
          ...props?.components,
        }}
        isDisabled={disabled}
        isOptionDisabled={(option: Option) => !!option.disabled}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
        styles={styles}
      />
    </BaseInputLayout>
  );
};

DefaultDropdownLayout.defaultProps = {
  ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
};

export default DefaultDropdownLayout;
