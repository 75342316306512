/* istanbul ignore file */
import React from 'react';
import { TextField, FieldProps } from 'shared/uibuilder/field';
import RbsNodeLink from 'erp/rbs/shared/field/RbsNodeLink';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { hasAccessToAnyAction } from 'erp/rbs/shared/rbsService';
import { TreeNodeProps } from 'shared/uibuilder/Tree/treeHelpers';

const getPathToRbsNodeData = (source: string = ''): string => {
  return source.replace(/\.([^.]+)$/, '');
};

const getNodeData = (source: string = '', data: any): TreeNodeProps => {
  return source.includes('.') ? data.getValueBySource(getPathToRbsNodeData(source)) : data;
};

const RbsNodeLinkField = ({ source, nameSource, permissionToCheck, userAlias, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { isGranted } = useAuthorization();
  const hasPermission = isGranted(permissionToCheck);
  const { data } = useShowContext();
  const nodeData: TreeNodeProps = getNodeData(source, data);

  const value = (
    <RbsNodeLink
      isClickable={hasAccessToAnyAction(hasPermission, nodeData, userAlias)}
      name={getName()}
      id={getId()}
      {...props}
    />
  );

  return <TextField value={value} {...props} />;
};

export default RbsNodeLinkField;
