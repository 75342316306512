import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import useAuthorization from 'shared/authorization/authorizationService';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { EmployeeCompetency } from 'erp/employee/qualification/shared/employeeQualificationService';
import ChangeCompetencyStatusForm from 'erp/employee/qualification/createupdate/Update/ChangeCompetencyStatusForm';
import EditIcon from '@mui/icons-material/EditOutlined';
import Typography from 'uibuilder/Typography';
import './EditCompetencyStatusButton.scss';

const EditCompetencyStatusButton = ({
  permissionToCheck,
  competency,
  qualificationId,
}: {
  qualificationId: StringOrNumber;
  competency: EmployeeCompetency;
  permissionToCheck?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const closeModal = () => setModalOpen(false);

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <>
      <Button outline size="small" className="edit-competency-status-btn" onClick={() => setModalOpen(true)}>
        <EditIcon />
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={
          <>
            Change the current status for the compentency:{' '}
            <Typography variant="h3" component="div" className="c-title">
              {competency.name}
            </Typography>
          </>
        }
        onToggle={closeModal}
        hasCancelButton={false}
        className="qualification-modal"
      >
        <ChangeCompetencyStatusForm
          modalOpen={modalOpen}
          closeModal={closeModal}
          competency={competency}
          qualificationId={qualificationId}
        />
      </ModalWindow>
    </>
  ) : null;
};

export default EditCompetencyStatusButton;
