/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, BaseFieldLayoutType, TextField } from 'shared/uibuilder/field';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import getLabel, { getValue } from 'shared/uibuilder/helper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import classnames from 'classnames';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import 'shared/uibuilder/field/longTextField.scss';

type LongTextFieldValue = string | number | React.ReactElement | React.ReactElement[];

const LongTextField = ({
  source = '',
  value,
  title,
  label,
  isSortable,
  className,
  isColumnDirection,
}: FieldProps<LongTextFieldValue>) => {
  const { data } = useShowContext();
  const { getValue: getValueFromFieldHelper } = useFieldHelper({ source });
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const getFieldValue = () => {
    const valueFromShowContext = getValue(value, (src: string) => data && data.getValueBySource(src), source, '');

    if (valueFromShowContext) {
      return valueFromShowContext;
    }

    return getValueFromFieldHelper();
  };
  const fieldValue = getFieldValue();

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
        className="long-text-form-btn"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
      >
        <BaseFieldLayout
          isColumnDirection={isColumnDirection}
          label={getLabel(label, source)}
          value={fieldValue ? `${fieldValue.substring(0, 100)}...` : ''}
          isSortable={isSortable}
          className={classnames(className)}
        />
      </div>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={title}
        onToggle={closeModal}
        hasCancelButton={false}
        className="form-modal"
        buttons={[
          <Button outline key="ok-modal-btn" onClick={closeModal}>
            Ok
          </Button>,
        ]}
      >
        <TextField source={source} className="long-text-field " />
      </ModalWindow>
    </>
  );
};

export default LongTextField;
