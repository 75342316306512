/* istanbul ignore file */
import React from 'react';
import { ASC } from 'shared/uibuilder/list/baseListHelper';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import CreateEmployeeContractButton from 'erp/employee/contracts/List/button/CreateEmployeeContractButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import EditEmployeeContractButton from 'erp/employee/contracts/List/button/EditEmployeeContractButton';
import ViewEmployeeContractButton from 'erp/employee/contracts/List/button/ViewEmployeeContractButton';
import { BadgeField, DateField, TextField } from 'shared/uibuilder/field';
import EmployeeContractWillExpireSoonBadge from 'erp/contracts/shared/RelatedContractsList/badge/EmployeeContractWillExpireSoonBadge';
import ActiveBadge from 'shared/uibuilder/badge/ActiveBadge';
import ExpiredBadge from 'shared/uibuilder/badge/ExpiredBadge';
import RelatedListWithSorting from 'shared/uibuilder/list/RelatedListWithSorting';
import useEmployeeContractsService from 'erp/employee/contracts/shared/employeeContractsService';
import useContractsService from 'erp/contracts/shared/contractsService';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import ContractCategoryField from 'erp/contracts/shared/field/ContractCategoryField';
import useFeatureToggle, { Features } from 'featuretoggle';
import ContractDurationField from 'erp/contracts/shared/field/ContractDurationField';
import ContractEndDateBadgeField from 'erp/contracts/shared/field/ContractEndDateBadgeField';

const RelatedContractsList = () => {
  const { search } = useEmployeeContractsService();
  const { isContractExpired, isContractWillExpireSoon, isContractActive } = useContractsService();
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  return (
    <RelatedListWithSorting getDataMethod={search} defaultSortField="dateOfSignature" defaultSortOrder={ASC}>
      <RelationListLayout actions={[<CreateEmployeeContractButton />]} header="Contracts">
        <DataGridLayout
          isSmallTable
          buttons={[
            <EditEmployeeContractButton source="id" isVisible={leave => leave.canEdit} />,
            <ViewEmployeeContractButton source="id" />,
          ]}
          showTotal={false}
        >
          <TextField isSortable source="number" label="Contract Number" width={150} />
          <DateField isSortable source="dateOfSignature" label="Date of Signature" />
          <ContractDurationField dateFromSource="startDate" dateToSource="endDate" label="Contract Duration" />
          <DateField isSortable source="startDate" label="Contract Start Date" />
          <BadgeField
            badges={[
              <EmployeeContractWillExpireSoonBadge
                id="expireSoon"
                isVisible={({ endDate }) => isContractWillExpireSoon(endDate)}
              />,
              <ActiveBadge
                id="activeContract"
                isVisible={({ startDate, endDate }) => isContractActive(startDate, endDate)}
              />,
              <ExpiredBadge id="expiredContract" isVisible={({ endDate }) => isContractExpired(endDate)} />,
            ]}
            isSortable
            source="endDate"
            label="Contract End Date"
          >
            <ContractEndDateBadgeField />
          </BadgeField>
          <DateField isSortable source="probationPeriodEndedAt" label="Probation End Date" />
          {twoContractEnabled ? <ContractCategoryField source="secondary" label="Category" /> : []}
          <EmployeeOfficeField source="officeId" label="Office" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedListWithSorting>
  );
};

export default RelatedContractsList;
