/* eslint-disable max-classes-per-file */
import { useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { dismissMessage, setMessage } from 'shared/message/messageActions';
import { useEffect } from 'react';
import { MessageContentType, MessageProps, MessageState } from 'shared/message/messageReducer';

export const SET_MESSAGE = 'SET_MESSAGE';
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';

const useMessageService = (messageContext?: string) => {
  const dispatch = useDispatch();
  const messageContainer = useSelector((state: { message: MessageState[] }) => state.message, shallowEqual);
  const history = useHistory();
  const { pathname } = useLocation();

  const addMessage = (messageObject: MessageProps) => {
    dispatch(setMessage(messageObject, pathname, messageContext));
  };

  const addMessageAndRedirect = (messageObject: MessageProps, redirectUrl: string) => {
    history.push('/');
    history.push(redirectUrl);
    dispatch(setMessage(messageObject, redirectUrl));
  };

  const getMessages = () => {
    return (
      messageContainer?.filter &&
      messageContainer.filter(m => m?.pathname === pathname && m?.messageContext === messageContext).map(m => m.message)
    );
  };

  const cleanMessage = (messageObject?: MessageProps) => {
    dispatch(dismissMessage(messageObject, pathname, messageContext));
  };

  useEffect(() => {
    if (messageContainer && messageContainer.some(m => m.pathname !== pathname)) {
      cleanMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return {
    addMessage,
    addMessageAndRedirect,
    getMessages,
    cleanMessage,
  };
};

interface Message {
  status: string;
  content: MessageContentType;
}

export class ErrorMessage implements Message {
  status: string;

  content: MessageContentType;

  constructor(content: MessageContentType) {
    this.status = 'error';
    this.content = content;
  }
}

export class SuccessMessage implements Message {
  status: string;

  content: MessageContentType;

  constructor(content: MessageContentType) {
    this.status = 'success';
    this.content = content;
  }
}

export class WarningMessage implements Message {
  status: string;

  content: MessageContentType;

  closeable: boolean;

  constructor(content: MessageContentType) {
    this.status = 'warning';
    this.content = content;
    this.closeable = false;
  }
}

export class InfoMessage implements Message {
  status: string;

  content: MessageContentType;

  constructor(content: MessageContentType) {
    this.status = 'info';
    this.content = content;
  }
}

export default useMessageService;
