/* istanbul ignore file */
import React from 'react';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import useProcessInstanceService from 'camunda/monitoring/instance/processInstanceService';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import RedmineIssueField from 'redmine/RedmineIssueField';
import { TextField, FullDateField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RedmineUserField from 'redmine/RedmineUserField';
import CloseIssueButton from 'camunda/monitoring/instance/Show/issues/shared/button/CloseIssueButton';
import CopyActivityInstanceIdButton from 'camunda/monitoring/instance/Show/issues/shared/button/CopyActivityInstanceIdButton';

const IssuesTable = () => {
  const id = useProcessInstanceId();
  const { getProcessIssues } = useProcessInstanceService();

  const getDataMethod = async () => {
    const issues = await getProcessIssues(id);
    return issues;
  };

  return (
    <RelatedList getDataMethod={getDataMethod}>
      <RelationListLayout header="User tasks" actions={[]}>
        <DataGridLayout
          showTotal={false}
          buttons={[
            <CopyActivityInstanceIdButton sourceExecutionId="activityInstanceId" />,
            <CloseIssueButton sourceIssueId="id" />,
          ]}
          isSmallTable
        >
          <RedmineIssueField source="id" />
          <TextField source="subject" />
          <RedmineUserField label="Assignee" nameSource="assignee.name" source="assignee.id" />
          <FullDateField label="Created at" source="createdAt" />
          <FullDateField label="Closed at" source="closedAt" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

export default IssuesTable;
