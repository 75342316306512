/* istanbul ignore file */
import React, { useState } from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { TextArea } from 'shared/uibuilder/form/input';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const RejectButton = ({
  displayMessage,
  deleteMethod,
  successMessage,
  errorMessage,
  permissionToCheck,
  execute,
}: {
  displayMessage: string;
  deleteMethod: (rejectReason: string) => Promise<any>;
  successMessage: string;
  errorMessage: string;
  permissionToCheck: string;
  execute?: () => void;
}) => {
  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonErrors, setRejectReasonErrors] = useState<string[]>([]);
  const { setData = () => {} } = useShowContext();

  const onReasonChange = (values: FormFieldsData) => {
    setRejectReason(values.rejectReason);
    setRejectReasonErrors(values.rejectReason ? [] : ['Field is required']);
  };

  return (
    <DeleteButton
      modalChildren={
        <TextArea
          source="rejectReason"
          value={rejectReason}
          label="Please, enter the reject reason"
          onChangeCallback={onReasonChange}
          errorMessages={rejectReasonErrors}
        />
      }
      displayMessage={displayMessage}
      deleteMethod={() => deleteMethod(rejectReason)}
      afterDelete={{
        successMessage,
        errorMessage,
        execute: (data: any) => {
          if (execute) {
            execute();
          }
          setData(data);
        },
      }}
      permissionToCheck={permissionToCheck}
      buttonText="Reject"
      confirmBtnProps={{ disabled: !rejectReason }}
      color="error"
    />
  );
};

export default RejectButton;
