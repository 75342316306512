/* istanbul ignore file */
import React from 'react';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

const OpportunityPipelineFieldLayout: BaseFieldLayoutType = ({ label, value }) => {
  return (
    <>
      <span className="field-label">{label}</span>
      {value || <span>{EMPTY_VALUE_INDICATOR}</span>}
    </>
  );
};

export default OpportunityPipelineFieldLayout;
