/* istanbul ignore file */
import { useState, useEffect } from 'react';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useSyberryTodayService, {
  updateQueueIssues,
  getQueueData,
  getSyberryTodayError,
} from 'syberryToday/useSyberryTodayService';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import { HTTP } from 'shared/api/const';

const useProjectQueue = ({
  data,
  projectId,
  queueId,
}: {
  data: any[];
  projectId: StringOrNumber;
  queueId: StringOrNumber;
}) => {
  const [items, setItems] = useState(data);
  const [shouldSaveUpdatesForItemId, setShouldSaveUpdatesForItemId] = useState<Nullable<number>>(null);
  const { selectedIssues, setLoading, unSelectIssue } = useSyberryTodayContext();
  const { updateIssuePositions } = useSyberryTodayService();
  const { data: { items: listProjects = [] } = {}, updateInList: contextUpdateInList = () => {} } = useListContext();
  const { addMessage } = useMessageService();
  const listItems = getQueueData(listProjects, queueId, items[0].project.id)?.issues || [];

  const getItemsByIssueSelection = (itemsForFiltering: any[], itemId: number) => {
    const isSelected = selectedIssues.includes(itemId);

    return itemsForFiltering.filter(({ id }: any) => selectedIssues.includes(id) === isSelected);
  };

  const getQueuePositions = (itemId: number) => {
    const oldQueuePositions = getItemsByIssueSelection(listItems, itemId).map(
      ({ customQueuePosition }: any) => customQueuePosition,
    );

    return getItemsByIssueSelection(items, itemId).reduce(
      (positions: Dictionary<Dictionary<number>>, item, index) => ({
        ...positions,
        [item.id]: {
          position: oldQueuePositions[index],
        },
      }),
      {},
    );
  };

  const getPersonalPositions = () =>
    items.reduce(
      (positions: Dictionary<Dictionary<number>>, item, position) => ({
        ...positions,
        [item.id]: {
          position,
        },
      }),
      {},
    );

  const updateProjectDataInList = (projectItems: any[]) => {
    const updatedProject = listProjects?.find((item: ListItem) => item.id === projectId);

    if (updatedProject) {
      contextUpdateInList(updateQueueIssues([updatedProject], queueId, projectItems)[0]);
    }
  };

  const getItemIndex = (itemsForSearch: any[], itemId: number) =>
    itemsForSearch.findIndex((item: any) => item.id === itemId);

  const saveUpdatesIssuePositions = async (itemId: number, callback?: () => void) => {
    try {
      if (getItemIndex(items, itemId) === getItemIndex(listItems, itemId)) {
        return;
      }

      setLoading(true);

      const positions = queueId ? getQueuePositions(itemId) : getPersonalPositions();

      await updateIssuePositions({
        id: itemId,
        positions,
        isQueuePosition: !!queueId,
      });

      updateProjectDataInList(items);

      if (callback) {
        callback();
      }
    } catch (e) {
      if ((e as any).status !== HTTP.UNAUTHORIZED) {
        const errorMessage = await getSyberryTodayError(e, "Can't update issue position");

        addMessage(new ErrorMessage(errorMessage));

        const previousData = getQueueData(listProjects, queueId, items[0].project.id);
        if (previousData) {
          setItems(previousData.issues);
        }
      }
    } finally {
      setShouldSaveUpdatesForItemId(null);
      setLoading(false);
    }
  };

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const activeItem = items[dragIndex];
    setItems((prevState: any) => {
      const newItems = prevState.filter((item: any, i: number) => i !== dragIndex);
      newItems.splice(hoverIndex, 0, activeItem);

      if (queueId) {
        const oldQueuePositions = prevState.map(({ customQueuePosition }: any) => customQueuePosition);

        return newItems.map((item: any, index: number) => ({ ...item, customQueuePosition: oldQueuePositions[index] }));
      } else {
        return newItems;
      }
    });
  };

  const moveToTop = (dragIndex: number, itemId: number) => {
    const isSelected = selectedIssues.includes(itemId);
    const moveIndex = queueId ? items.findIndex((item: any) => selectedIssues.includes(item.id) === isSelected) : 0;

    if (dragIndex !== moveIndex) {
      moveItem(dragIndex, moveIndex);
      setShouldSaveUpdatesForItemId(itemId);
    }
  };

  useEffect(() => {
    if (shouldSaveUpdatesForItemId) {
      saveUpdatesIssuePositions(shouldSaveUpdatesForItemId);
    }

    // Suppressed warnings because we only need to call useEffect callback if items and shouldSaveUpdatesForItemId were updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, shouldSaveUpdatesForItemId]);

  const updateInList = (updatedEntity: any) => {
    setItems((prev: any) => {
      const newItems = prev.map((item: any) => {
        if (updatedEntity.id === item.id) {
          return updatedEntity;
        } else {
          return item;
        }
      });

      updateProjectDataInList(newItems);
      return newItems;
    });
  };

  const removeFromList = (entityId: any) => {
    setItems((prev: any) => {
      const newItems = prev.filter((item: any) => entityId !== item.id);

      updateProjectDataInList(newItems);

      return newItems;
    });
    unSelectIssue(entityId);
  };

  return {
    updateInList,
    moveToTop,
    moveItem,
    items,
    selectedIssues,
    saveUpdatesIssuePositions,
    removeFromList,
  };
};

export default useProjectQueue;
