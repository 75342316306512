import React, { useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import OneStopModalDecorator from 'oneStop/Create/OneStopModalDecorator';
import InstantFeedbackForm from './createupdate/InstantFeedbackForm';
import InstantFeedbackLayout from './InstantFeedbackRequestLayout';
import useInstantFeedbackService from './InstantFeedbackService';
import Typography from 'uibuilder/Typography';

const MESSAGE_CONTEXT = 'feedbackForm';

type FeedbackFormProps = {
  closeFeedbackForm: () => void;
};

const InstantFeedbackModalForm = ({ closeFeedbackForm }: FeedbackFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { create, getValidationSchema } = useInstantFeedbackService();

  const closeFeedbackModal = () => setIsModalOpen(false);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Feedback about colleague
    </Typography>
  );

  return (
    <OneStopModalDecorator
      isOneStop={false}
      submitHandler={closeFeedbackModal}
      handleMessageModalClose={closeFeedbackForm}
      messageContext={MESSAGE_CONTEXT}
    >
      <CreateForm
        submitFormFunc={create}
        messageContext={MESSAGE_CONTEXT}
        getValidationSchemaFunc={getValidationSchema}
        initialData={{
          isSharedWithEmployee: false,
        }}
      >
        <ModalWindow
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          keyboard={false}
          isOpen={isModalOpen}
          hasCancelButton={false}
          title={null}
          onToggle={closeFeedbackForm}
        >
          <InstantFeedbackLayout
            handleCancelClick={closeFeedbackForm}
            headerRight={title}
            isFixedButtons={false}
            messageContext={MESSAGE_CONTEXT}
          >
            <InstantFeedbackForm />
          </InstantFeedbackLayout>
        </ModalWindow>
      </CreateForm>
    </OneStopModalDecorator>
  );
};

export default InstantFeedbackModalForm;
