import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import React, { useCallback, useState } from 'react';
import Button from 'uibuilder/button/Button';
import BaseInputLayout from 'uibuilder/layout/form/input/BaseInputLayout';
import { TextArea } from 'shared/uibuilder/form/input';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import './TextButton.scss';
import classnames from 'classnames';

interface TextButtonProps {
  source: string;
  title: string;
  value: string;
  label: string;
  disabled?: boolean;
  isRequired?: boolean;
  onChangeCallback: (value: FormFieldsData) => void;
  errors?: string[];
}

const TextButton = ({
  source,
  value,
  label,
  disabled = false,
  isRequired = false,
  title,
  onChangeCallback,
  errors = [],
}: TextButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState<FormFieldsData>({
    [source]: value,
  });

  const closeModalWithSavingChanges = useCallback(() => {
    setModalOpen(false);
    onChangeCallback(modalValue);
  }, [modalValue, onChangeCallback]);

  const closeModalWithoutSavingChanged = useCallback(() => {
    setModalOpen(false);
    setModalValue({
      [source]: value,
    });
  }, [source, value]);

  return (
    <>
      <BaseInputLayout
        key="text-btn"
        source={source}
        label={label}
        errorMessages={errors}
        className={classnames(
          'modal-text-form-btn',
          disabled ? 'disabled-btn' : 'active-btn',
          errors.length > 0 ? 'error-section' : '',
        )}
        isRequired={isRequired}
      >
        <div
          role="button"
          tabIndex={0}
          onKeyPress={() => {}}
          className="form-control"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            setModalOpen(true);
            (e.target as HTMLElement).blur();
          }}
        >
          {value?.substring(0, 150)}
        </div>
      </BaseInputLayout>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={title}
        onToggle={closeModalWithoutSavingChanged}
        hasCancelButton={false}
        className="form-modal"
        buttons={[
          disabled ? (
            <Button outline key="ok-modal-btn" onClick={closeModalWithoutSavingChanged}>
              Ok
            </Button>
          ) : (
            <Button key="submit-modal-btn" onClick={closeModalWithSavingChanges}>
              Submit
            </Button>
          ),
        ]}
      >
        <TextArea
          source={source}
          value={modalValue[source]}
          label=""
          disabled={disabled}
          onChangeCallback={useCallback(
            (values: FormFieldsData) => {
              if (!disabled) {
                setModalValue(values);
              }
            },
            [disabled],
          )}
        />
      </ModalWindow>
    </>
  );
};

export default TextButton;
