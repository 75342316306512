/* istanbul ignore file */
import React from 'react';
import useEmployeeQualificationService, {
  CompetencyStatus,
  EmployeeCompetency,
} from 'erp/employee/qualification/shared/employeeQualificationService';
import FormSection from 'shared/layout/form/FormSection';
import CompetencyStatusDropdown from 'erp/employee/qualification/shared/input/CompetencyStatusDropdown';
import JustificationAttachmentUploaderInput from 'erp/employee/qualification/List/input/JustificationAttachmentUploaderInput';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import { TextArea } from 'shared/uibuilder/form/input/index';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useQualificationId } from 'erp/employee/qualification/QualificationRouter';
import FormHelpText from 'uibuilder/layout/form/input/FormHelpText';
import useFeatureToggle, { Features } from 'featuretoggle';

interface ChangeCompetencyStatusFormProps {
  closeModal: () => void;
  competency: EmployeeCompetency;
  qualificationId: StringOrNumber;
  modalOpen: boolean;
}

const ChangeCompetencyStatusForm = ({
  competency: { id: competencyId },
  qualificationId,
  closeModal,
  modalOpen,
}: ChangeCompetencyStatusFormProps) => {
  const specializationId = useQualificationId();
  const employeeId = useEmployeeId() || '';
  const { updateCompetency, getCompetencyValidationSchema } = useEmployeeQualificationService();
  const data = useShowContext()?.data;
  const disabledDropdownValue = data.getValueBySource('rootState.status') || CompetencyStatus.NOT_CONFIRMED;
  const saveJustificationEnabled = useFeatureToggle().isFeatureEnabled(Features.SAVE_COMPETENCY_JUSTIFICATION);

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      submitFormFunc={formData =>
        updateCompetency({ employeeId, specializationId, qualificationId, competencyId, formData })
      }
      getValidationSchemaFunc={getCompetencyValidationSchema}
    >
      <FormSection>
        <CompetencyStatusDropdown label="Status" source="status" disabledValue={disabledDropdownValue} />
        <TextArea label="Justification" source="justification" isVisible={saveJustificationEnabled} />
        <JustificationAttachmentUploaderInput
          label="Attachments"
          source="attachmentsIds"
          isVisible={saveJustificationEnabled}
        />

        <FormHelpText isVisible={saveJustificationEnabled}>
          Note: Information about the status change will be added to the employee&apos;s Timeline notes
        </FormHelpText>
      </FormSection>
    </ModalForm>
  );
};

export default ChangeCompetencyStatusForm;
