/* istanbul ignore file */
import React from 'react';
import SingleBudgetBreadcrumbs from 'erp/budget/shared/SingleBudgetBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateBudgetBreadcrumbs = () => {
  return (
    <SingleBudgetBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleBudgetBreadcrumbs>
  );
};

export default UpdateBudgetBreadcrumbs;
