/* istanbul ignore file */
import React from 'react';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import SingleCandidateBreadcrumbs from 'erp/candidate/shared/SingleCandidateBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CandidateOfferBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useCandidateOfferUrl();

  return (
    <SingleCandidateBreadcrumbs>
      <ListBreadcrumbItem entity="Offers" link={getListUrl()} />
      {children}
    </SingleCandidateBreadcrumbs>
  );
};

export default CandidateOfferBreadcrumbs;
