/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_DISQUALIFICATION_REASON } from 'crm/lead/shared/leadService';

const DisqualificationReasonField = ({ value: inputValue, source }) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Disqualification Reason" value={value} options={LEAD_DISQUALIFICATION_REASON} />;
};

DisqualificationReasonField.propTypes = {
  value: string,
  source: string,
};

DisqualificationReasonField.defaultProps = {
  value: null,
  source: null,
};

export default DisqualificationReasonField;
