/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import { READ_PAYROLL_REPORT } from 'erp/payroll/shared/payrollService';
import PayrollList from 'erp/payroll/List';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const PAYROLL_PATH = '/payroll';
export const PAYROLL_REPORT_PATH = '/payroll/report';

export const usePayrollRoute = () => {
  return useRoute({ listRoute: PAYROLL_REPORT_PATH });
};

export const usePayrollUrl = () => {
  return useEntityUrl({
    baseLink: PAYROLL_REPORT_PATH,
  });
};

const PayrollRouter = () => {
  const { listRoute } = usePayrollRoute();

  return (
    <ProtectedRouter basePath={PAYROLL_REPORT_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_PAYROLL_REPORT}>
          <PayrollList />
        </GlobalProtectedRoute>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

PayrollRouter.getRouterPath = () => PAYROLL_REPORT_PATH;

export default PayrollRouter;
