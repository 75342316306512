/* istanbul ignore file */
import React from 'react';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import SearchResultImageLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/SearchResultImageLayout';
import SearchResultDocumentLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/SearchResultDocumentLayout';

interface SearchResultAttachmentsListFieldProps {
  source: string;
  value?: string[];
  label?: string;
  isDownloadAvailable?: boolean;
  width?: number;
}

const SearchResultAttachmentsListField = (props: SearchResultAttachmentsListFieldProps) => (
  <span className="search-result-file-field">
    <UiThemeOverrider
      overriddenLayouts={{
        ArtifactImageLayout: SearchResultImageLayout,
        ArtifactDocumentLayout: SearchResultDocumentLayout,
      }}
    >
      <AttachmentsListField {...props} />
    </UiThemeOverrider>
  </span>
);

SearchResultAttachmentsListField.propTypes = {
  ...AttachmentsListField.propTypes,
};

export default SearchResultAttachmentsListField;
