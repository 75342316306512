/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { WRITE_TRAINING_DOMAIN } from '../../useTrainingsService';
import { useTrainingsId, useTrainingsUrl } from '../../TrainingsRouter';

const EditTrainingButton = (props: any) => {
  const { getUpdateEntityUrl } = useTrainingsUrl();
  const id = useTrainingsId();

  return (
    <EditButton
      {...props}
      path={getUpdateEntityUrl(id)}
      key="edit-training"
      permissionToCheck={WRITE_TRAINING_DOMAIN}
      outline
      label="Edit"
    />
  );
};

export default EditTrainingButton;
