/* istanbul ignore file */
export default {
  vacancyAlias: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createApplicationOption: {
          ne: 'DO_NOT_CREATE',
        },
      },
    },
  },
  resume: {
    type: 'object',
    required: true,
    properties: {
      resumeArtifactIds: {
        type: 'array',
        maxSize: 1,
      },
    },
  },
  source: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $createApplicationOption: {
          ne: 'DO_NOT_CREATE',
        },
      },
    },
  },
  sourceType: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $createApplicationOption: {
          ne: 'DO_NOT_CREATE',
        },
      },
    },
  },
  sourceComment: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        '$candidate.source': {
          eq: 'JOB_BOARD',
        },
      },
    },
    forbidden: {
      value: true,
      when: {
        '$candidate.source': {
          eq: 'EMPLOYEE',
        },
      },
    },
  },
  sourceEmployeeId: {
    type: 'string',
    forbidden: {
      value: true,
      when: {
        $source: {
          ne: 'EMPLOYEE',
        },
      },
    },
  },
  candidateId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createNewCandidate: {
          eq: false,
        },
      },
    },
  },
  note: {
    type: 'object',
    required: {
      value: true,
      when: {
        $createApplicationOption: {
          ne: 'DO_NOT_CREATE',
        },
      },
    },
    properties: {
      noteText: {
        type: 'string',
        required: {
          value: true,
          when: {
            $createApplicationOption: {
              ne: 'DO_NOT_CREATE',
            },
          },
        },
        maxSize: 65000,
      },
    },
  },
  candidate: {
    type: 'object',
    required: {
      value: true,
      when: {
        $createNewCandidate: {
          eq: true,
        },
      },
    },
    _divisible: false,
    properties: {
      name: {
        type: 'object',
        required: {
          value: true,
          when: {
            $createNewCandidate: {
              eq: true,
            },
          },
        },
        _divisible: false,
        properties: {
          firstName: {
            type: 'string',
            maxSize: 255,
            required: {
              value: true,
              when: {
                $createNewCandidate: {
                  eq: true,
                },
              },
            },
          },
          middleName: {
            type: 'string',
            maxSize: 255,
          },
          lastName: {
            type: 'string',
            maxSize: 255,
            required: {
              value: true,
              when: {
                $createNewCandidate: {
                  eq: true,
                },
              },
            },
          },
          maidenName: {
            type: 'string',
            maxSize: 255,
          },
        },
      },
      status: {
        type: 'enum',
        required: {
          value: {
            value: true,
            when: {
              $createNewCandidate: {
                eq: true,
              },
            },
          },
          when: {
            $_IS_CREATE_REQUEST: {
              eq: false,
            },
          },
        },
      },
      personalPhoneNumber: {
        type: 'string',
        phone: {
          value: true,
          message: 'Please enter phone number in format xxxxxxxxxxxx, max. 15 characters',
        },
      },
      personalEmail: {
        type: 'string',
        email: true,
        maxSize: 255,
      },
      additionalContacts: {
        type: 'array',
        maxSize: 10,
        forEach: {
          type: 'object',
          _divisible: false,
          properties: {
            type: {
              required: true,
              maxSize: 255,
            },
            value: {
              required: true,
              maxSize: 255,
            },
          },
        },
      },
    },
  },
  createApplicationOption: {
    type: 'string',
    required: true,
  },
  communicationChanel: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createApplicationOption: {
          eq: 'CREATE_IN_PRESCREEN_STEP',
        },
      },
    },
  },
  recruiterEmployeeId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createApplicationOption: {
          eq: 'CREATE_IN_PRESCREEN_STEP',
        },
      },
    },
  },
};
