/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import Milestons from 'davinci/techreview/Milestones';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';

export const DAVINCI_TEAM_PATH = '/davinciteam';

export const useDavinciRoute = () => {
  return useRoute({ listRoute: DAVINCI_TEAM_PATH });
};

const DavinciRouter: Router = () => {
  return (
    <ProtectedRouter basePath={DAVINCI_TEAM_PATH}>
      <Switch>
        <Route path={`${DAVINCI_TEAM_PATH}/techreviewbuilder`} exact>
          <Milestons />
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

DavinciRouter.getRouterPath = () => DAVINCI_TEAM_PATH;

export default DavinciRouter;
