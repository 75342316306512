import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';

const SET_RECRUITER = 'SET_RECRUITERS';

const setRecruiters = (payload = {}) => ({
  type: SET_RECRUITER,
  payload,
});

type RecruitersState = Dictionary<string>;

const initialState: Dictionary<string>[] = [];

interface RecruitersActions extends Action {
  payload: RecruitersState[];
}

export const recruitersReducer = (state = initialState, action: RecruitersActions) => {
  if (action.type === SET_RECRUITER) {
    return action.payload;
  }

  return state;
};

export const RESOURCE_URL = '/employees/suggestions';

/**
 * Recruiters service.
 */
const useRecruitersService = () => {
  const { searchAll: searchSuggestions } = useEmployeeSuggestionsService();

  const recruiters = useSelector((state: { recruiters: RecruitersState[] }) => state?.recruiters);
  const dispatch = useDispatch();

  const getRecruiters = async () => {
    if (recruiters && recruiters.length) {
      return Promise.resolve(recruiters);
    } else {
      const request = {
        filter: {
          'specification:isRecruiter': {
            eq: true,
          },
        },
      };

      const recruitersData = await searchSuggestions(request);

      dispatch(setRecruiters(recruitersData));

      return recruitersData;
    }
  };

  return {
    getRecruiters,
  };
};

export default useRecruitersService;
