/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import InvoiceBreadcrumbs from 'financialAnalytic/invoice/shared/InvoiceBreadcrumbs';

const CreateInvoiceBreadcrumbs = () => {
  return (
    <InvoiceBreadcrumbs>
      <CreateBreadcrumbItem entity="Invoice" />
    </InvoiceBreadcrumbs>
  );
};

export default CreateInvoiceBreadcrumbs;
