import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import PeriodQuickFilter from './PeriodQuickFilter';

const firstDayOfCurrMonth = moment()
  .startOf('month')
  .format(DATE_FORMAT.API_ONLY_DATE);

const now = moment().format(DATE_FORMAT.API_ONLY_DATE);

const CurrentMonthQuickFilter = () => (
  <PeriodQuickFilter fromValue={firstDayOfCurrMonth} toValue={now} label="Current month" />
);

export default CurrentMonthQuickFilter;
