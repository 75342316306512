/* istanbul ignore file */
// libs
import React from 'react';
import { useParams, Switch } from 'react-router-dom';
// components
import Page404 from 'shared/uibuilder/Page404';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { READ_EMPLOYEE_QUALIFICATIONS } from 'erp/employee/employeeService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import QualificationsListPage from 'erp/employee/qualification/Show';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';

const QUALIFICATIONS_PATH = '/old-qualifications';
export const QUALIFICATION_ID_SOURCE = 'qualificationId';

export const useQualificationsRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    singleEntityRoute: `${employeeRoute}${QUALIFICATIONS_PATH}/:${QUALIFICATION_ID_SOURCE}?`,
    listRoute: `${employeeRoute}${QUALIFICATIONS_PATH}`,
  });
};

export const useQualificationUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${QUALIFICATIONS_PATH}`,
    idSource: QUALIFICATION_ID_SOURCE,
  });
};

export const useQualificationId = () => {
  return useParams<Dictionary<string>>()[QUALIFICATION_ID_SOURCE];
};

const QualificationRouter = () => {
  const { singleEntityRoute } = useQualificationsRoute();

  return (
    <Switch>
      <ProtectedRoute path={singleEntityRoute} permissionToCheck={READ_EMPLOYEE_QUALIFICATIONS}>
        <QualificationsListPage />
      </ProtectedRoute>
      <Route component={Page404} />
    </Switch>
  );
};

export default QualificationRouter;
