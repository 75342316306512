/* istanbul ignore file */
import React, { useContext } from 'react';
import { store } from 'shared/reducer/store';

export type AuthenticationToken = Nullable<string>;

export interface TokenContext {
  getToken: () => Promise<AuthenticationToken>;
}

export interface DefaultTokenProviderProps {
  getToken: () => Promise<AuthenticationToken>;
  children: React.ReactNode;
}

const TokenReactContext = React.createContext<TokenContext>({
  getToken: () => Promise.resolve(store.getState().authToken?.authenticationToken?.token || null),
});

export const useTokenProvider = () => useContext(TokenReactContext);

export const DefaultTokenProvider = ({ getToken, children }: DefaultTokenProviderProps) => {
  return <TokenReactContext.Provider value={{ getToken }}>{children}</TokenReactContext.Provider>;
};
