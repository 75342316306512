import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import CustomerInvoiceBreadcrumbs from '../../shared/CustomerInvoiceBreadcrumbs';

const CreateCustomerInvoiceBreadcrumbs = () => {
  return (
    <CustomerInvoiceBreadcrumbs>
      <CreateBreadcrumbItem entity="Customer invoice" />
    </CustomerInvoiceBreadcrumbs>
  );
};

export default CreateCustomerInvoiceBreadcrumbs;
