import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import SingleProjectBreadcrumbs from 'erp/project/shared/SingleProjectBreadcrumbs';
import EditProjectButton from 'erp/project/shared/button/EditProjectButton';
import DeleteProjectButton from 'erp/project/shared/button/DeleteProjectButton';
import { BooleanField, TextField } from 'shared/uibuilder/field';
import { UPDATE_PROJECT } from 'erp/project/shared/projectService';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import BudgetChildLinkField from 'erp/budget/shared/field/BudgetChildLinkField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import useFeatureToggle, { Features } from 'featuretoggle';
import ArtifactStorageFileInformationSection from 'artifactStorage/shared/ArtifactStorageFileInformationSection';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CreateCustomerInvoiceButton from 'financialAnalytic/customerInvoices/shared/buttons/CreateCustomerInvoiceButton';
import { useCustomerInvoiceUrl } from 'financialAnalytic/customerInvoices/CustomerInvoicesRouter';
import ArchiveObjects from '../shared/button/ArchiveObjects';

const ViewProjectPageContent = ({
  isSensitiveDataIsShown = false,
  toggleSensitiveDataButton = <></>,
}: {
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: React.ReactElement;
}) => {
  const viewBudgetsEnabled = useFeatureToggle().isFeatureEnabled(Features.VIEW_BUDGETS);
  const viewSowFieldEnabled = useFeatureToggle().isFeatureEnabled(Features.LINK_SOW_WITH_REDMINE_PROJECT);
  const { getValue: getTitle } = useFieldHelper({ source: isSensitiveDataIsShown ? 'realProjectName' : 'name' });
  const { getCreateEntityFormProjectUrl } = useCustomerInvoiceUrl();
  const { getValue: getSowID } = useFieldHelper({ source: 'sowId' });
  const sowId = getSowID();
  const customerInvoicesEnabled = useFeatureToggle().isFeatureEnabled(Features.CUSTOMER_INVOICES);

  return (
    <ShowPageLayout
      headerButtons={[
        toggleSensitiveDataButton,
        <EditProjectButton key="edit-project" permissionToCheck={UPDATE_PROJECT} />,
        <CreateCustomerInvoiceButton
          isVisible={!!sowId && customerInvoicesEnabled}
          url={getCreateEntityFormProjectUrl(sowId)}
          outline
        />,
        <ArchiveObjects key="archive-objects" />,
        <DeleteProjectButton key="delete-project" />,
      ]}
      menu={<ViewProjectPageMenu />}
      breadcrumbs={<SingleProjectBreadcrumbs />}
      entityHeader={<ProjectEntityHeader entityTitle={getTitle() || '-'} />}
      hasSeparateSections
    >
      <ShowSection title="Project Information">
        <SectionRow>
          <TextField source="name" isVisible={!isSensitiveDataIsShown} />
          <TextField label="Name" source="realProjectName" isVisible={isSensitiveDataIsShown} />
          <TextField source="rbsTreeNode.name" label="RBS Node" />
          <AccountLinkField label="Account" source="accountName" idSource="accountId" idValue="" isInternal={false} />
        </SectionRow>
        <SectionRow>
          <BudgetLinkField
            label="Default Budget"
            source="defaultBudgetId"
            nameSource="defaultBudget.name"
            isVisible={viewBudgetsEnabled}
          />
          <FieldListLayout source="availableBudgets" label="Available Budgets" isVisible={viewBudgetsEnabled}>
            <BudgetChildLinkField />
          </FieldListLayout>
          <BooleanField source="assignmentForbidden" label="Assignment Forbidden" />
        </SectionRow>
      </ShowSection>
      <ArtifactStorageFileInformationSection
        title="SOW Information"
        source="sow"
        prevSource="sow"
        isVisible={data => viewSowFieldEnabled && data.sow != null}
      />
    </ShowPageLayout>
  );
};

export default ViewProjectPageContent;
