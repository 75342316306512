/* istanbul ignore file */
import React from 'react';
import {
  DISQUALIFIED_DAYS_BORDER,
  LeadWillAutoDisqualifiedSoonBadgeProps,
  MIN_DAYS_BORDER,
} from 'crm/lead/shared/field/LeadWillAutoDisqualifiedSoonBadge';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';

const isStatusAllowed = (status: string, skipStates: string[]) => {
  return !skipStates.includes(status);
};

const LeadWillAutoDisqualifiedSoonNotification = ({
  source,
  statusSource,
  skipStates,
}: LeadWillAutoDisqualifiedSoonBadgeProps) => {
  const { getValue: getLastModified } = useFieldHelper({ source });
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getDaysBetween, getMomentDateObjAsUtc, getCurrentDateTimeInUtc } = useDateService();

  const status = getStatus();
  if (!isStatusAllowed(status, skipStates)) {
    return null;
  }

  const lastModified = getMomentDateObjAsUtc(getLastModified());
  const currentDateTime = getCurrentDateTimeInUtc();

  const daysBetweenDates = getDaysBetween(currentDateTime, lastModified, DATE_FORMAT.FULL);

  if (daysBetweenDates < MIN_DAYS_BORDER) {
    return null;
  }

  const daysBeforeDisqualified = DISQUALIFIED_DAYS_BORDER - daysBetweenDates;

  const daysWithUnits =
    daysBeforeDisqualified === 1 ? `${daysBeforeDisqualified} day` : `${daysBeforeDisqualified} days`;
  const title = `Lead will be disqualified by system in ${daysWithUnits}!`;

  return (
    <BigSectionRow>
      <>
        <span className="required-section">{title}</span>
        <i className="fa fa-exclamation-circle ml-2 expire-soon-badge" />
      </>
    </BigSectionRow>
  );
};

export default LeadWillAutoDisqualifiedSoonNotification;
