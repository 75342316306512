/* istanbul ignore file */
import Tooltip, { TooltipSize } from 'uibuilder/Tooltip/index';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import './SimpleTooltip.scss';

export enum Placement {
  RIGHT = 'right',
  TOP_START = 'top-start',
  TOP = 'top',
  TOP_END = 'top-end',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  BOTTOM_END = 'bottom-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  LEFT_END = 'left-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
}

export interface Props {
  id: string;
  children: any;
  isBigText?: boolean;
  className?: string;
  placement?: Placement | undefined;
}

const SimpleTooltip = ({ id, children, isBigText, className, placement }: Props) => {
  return (
    <Tooltip title={children} placement={placement} id={id} size={isBigText ? TooltipSize.BIG : TooltipSize.DEFAULT}>
      <InfoIcon id={id} role="presentation" className={`simple-tooltip ${className}`} />
    </Tooltip>
  );
};

SimpleTooltip.defaultProps = {
  placement: Placement.RIGHT,
};

export default SimpleTooltip;
