/* istanbul ignore file */
import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';
import PurchaseInput from 'financialAnalytic/purchaseOrder/shared/input/PurchaseInput';
import PurchaseListInputTotal from 'financialAnalytic/purchaseOrder/shared/input/PurchaseListInputTotal';

const PurchaseListInput = ({
  source,
  currencySource,
  ...props
}: Omit<InputListProps, 'inputTemplate' | 'addText'> & { currencySource: string }) => {
  return (
    <div className="journal-entries-transfers-input">
      <InputList
        {...props}
        source={source}
        addText="Add purchase"
        inputTemplate={(inputProps: any) => <PurchaseInput {...inputProps} currencySource={currencySource} />}
        defaultValue={{}}
        maxFields={1000}
      />
      <PurchaseListInputTotal purchasesSource={source} currencySource={currencySource} />
    </div>
  );
};

export default PurchaseListInput;
