/* istanbul ignore file */
import React, { useState } from 'react';
import Show from 'shared/uibuilder/show/Show';
import Indent from 'sendEmail/SendForm/Indent';
import useSendEmailService from 'sendEmail/sendEmailService';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import SendEmailButton from 'sendEmail/shared/buttons/SendEmailButton';
import EditEmailButton from 'sendEmail/shared/buttons/EditEmailButton';
import SendFormPageLayout from 'sendEmail/SendForm/SendFormPageLayout';
import { useSendEmailId, useSendEmailUrl } from 'sendEmail/SendEmailRouter';
import SendEmailDataFieldLayout from 'sendEmail/shared/layouts/SendEmailDataFieldLayout';
import { CommaListField, EmailField, HtmlField, TextField } from 'shared/uibuilder/field';
import AttachmentsListField from '../../artifact/shared/field/AttachmentsListField';

const SendFormPage = () => {
  const [isEditButtonVisible, setIsEditButtonVisible] = useState<boolean>(true);
  const emailId = useSendEmailId();
  const { getData } = useSendEmailService();
  const { getUpdateEntityUrl } = useSendEmailUrl();

  const buttons = [
    <SendEmailButton
      key="send-btn"
      toggleEditButton={setIsEditButtonVisible}
      isEditButtonVisible={isEditButtonVisible}
    />,
    <EditEmailButton key="edit-btn" path={getUpdateEntityUrl(emailId)} isVisible={isEditButtonVisible} />,
  ];

  return (
    <Show getDataMethod={() => getData(emailId)}>
      <UiThemeOverrider
        overriddenLayouts={{
          BaseFieldLayout: SendEmailDataFieldLayout,
        }}
      >
        <SendFormPageLayout buttons={buttons}>
          <EmailField source="recipient" label="To" />
          <TextField source="subject" label="Subject" />
          <CommaListField source="ccs" label="CC" />
          <Indent />
          <HtmlField source="content" label="" />
          <AttachmentsListField label="" source="attachmentIds" />
        </SendFormPageLayout>
      </UiThemeOverrider>
    </Show>
  );
};

export default SendFormPage;
