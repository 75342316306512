/* istanbul ignore file */
import React from 'react';
import SingleAccountBreadcrumbs from 'crm/account/shared/SingleAccountBreadcrumbs';
import { ActivitiesAndNotesBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAccountId } from 'crm/account/AccountRouter';
import useAccountUrl from '../shared/accountUrl';

const AccountTimelineBreadcrumbs = () => {
  const id = useAccountId();
  const { getTimelineUrl } = useAccountUrl();

  return (
    <SingleAccountBreadcrumbs>
      <ActivitiesAndNotesBreadcrumbItem link={getTimelineUrl(id)} />
    </SingleAccountBreadcrumbs>
  );
};

export default AccountTimelineBreadcrumbs;
