/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';
import { CREATE_ASSESSMENT } from 'erp/employee/assessment/assessmentService';

const AddAssessmentButton = () => {
  const { getCreateUrl } = useAssessmentUrl();

  return (
    <CreateButton
      variant="contained"
      label="Add Assessment"
      url={getCreateUrl()}
      permissionToCheck={CREATE_ASSESSMENT}
    />
  );
};

export default AddAssessmentButton;
