import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useHiringId, useHiringUrl } from '../../HiringRouter';

const EditHiringButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useHiringUrl();
  const id = useHiringId();

  return <EditButton {...props} path={getUpdateEntityUrl(id)} />;
};

export default EditHiringButton;
