/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';
import { arrayOf, element, oneOfType } from 'prop-types';

const EmployeeContractsBreadCrumbs = ({ children }) => {
  const { getListUrl } = useEmployeeContractUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Contracts" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

EmployeeContractsBreadCrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

EmployeeContractsBreadCrumbs.defaultProps = {
  children: null,
};

export default EmployeeContractsBreadCrumbs;
