/* istanbul ignore file */
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import React from 'react';

const useCreateGitlabResponseHandler = () => {
  const handleAccountGroupStatus = (accountGroupStatus: any, statusContext: StatusContext) => {
    if (accountGroupStatus.groupState !== 'ERROR') {
      statusContext.successes.push(
        <li key="success-creation">Successfully {accountGroupStatus.groupState} Gitlab Group for provided Account.</li>,
      );
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to create or found Account Gitlab Group due to: {accountGroupStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleProjectGroupCreationStatus = (projectGroupCreationStatus: any, statusContext: StatusContext) => {
    if (projectGroupCreationStatus.isCreated) {
      statusContext.successes.push(<li key="success-creation">Successfully created Gitlab Project Group.</li>);
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to create Gitlab Project Group due to: {projectGroupCreationStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleDefaultRepoStatuses = (defaultRepoStatuses: any, statusContext: StatusContext) => {
    defaultRepoStatuses.forEach((repoStatus: any) => {
      if (repoStatus.isCreated) {
        statusContext.successes.push(
          <li key="success-creation">Successfully created Gitlab Project Repository {repoStatus.projectName}.</li>,
        );
      } else {
        statusContext.errors.push(
          <li key="error-creation">
            Failed to create Gitlab Project Repository {repoStatus.projectName} due to: {repoStatus.errors.join(' ')}.
          </li>,
        );
      }
    });
  };

  const handleMemberAddingStatus = (memberAddingStatus: any, statusContext: StatusContext) => {
    if (memberAddingStatus.isAdded) {
      statusContext.successes.push(
        <li key="success-creation">Successfully added {memberAddingStatus.userEmail} to the Gitlab Project Group.</li>,
      );
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to add {memberAddingStatus.userEmail} to the Gitlab Project Group due to:{' '}
          {memberAddingStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleProjectUpdateLinksStatus = (projectUpdateStatus: any, statusContext: StatusContext) => {
    if (projectUpdateStatus.isUpdated) {
      statusContext.successes.push(<li key="success-creation">Successfully updated Project link to the Gitlab.</li>);
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to update Project link to the Gitlab due to {projectUpdateStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleGitlabResponse = (state: any, statusContext: StatusContext) => {
    if (state.accountGroupCreationStatus) {
      handleAccountGroupStatus(state.accountGroupCreationStatus, statusContext);
    }
    if (state.projectGroupCreationStatus) {
      handleProjectGroupCreationStatus(state.projectGroupCreationStatus, statusContext);
    }
    if (state.defaultRepoStatuses) {
      handleDefaultRepoStatuses(state.defaultRepoStatuses, statusContext);
    }
    if (state.memberAddingStatus) {
      handleMemberAddingStatus(state.memberAddingStatus, statusContext);
    }
    if (state.projectFieldUpdatedStatus) {
      handleProjectUpdateLinksStatus(state.projectFieldUpdatedStatus, statusContext);
    }
  };

  return {
    handleGitlabResponse,
  };
};

export default useCreateGitlabResponseHandler;
