/* istanbul ignore file */
import { useCandidateOfferId } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import useCandidateOfferService from 'erp/candidate/offercompensation/shared/candidateOfferService';
import DeleteButton from 'uibuilder/button/DeleteButton';
import React from 'react';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const deleteMessage = 'Do you really want to delete this Offer?';

const DeleteCandidateOfferButton = ({ ...props }) => {
  const offerId = useCandidateOfferId();
  const { deleteById } = useCandidateOfferService();
  const candidateId = useCandidateId();
  const { getTimelineUrl } = useCandidateUrl();

  return (
    <DeleteButton
      {...props}
      displayMessage={deleteMessage}
      deleteMethod={() => deleteById(offerId)}
      afterDelete={{
        redirectUrl: getTimelineUrl(candidateId),
        successMessage: 'The Offer has been successfully deleted.',
        errorMessage: `Can't delete Offer.`,
      }}
    />
  );
};

export default DeleteCandidateOfferButton;
