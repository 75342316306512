import { arrayOf, func, number } from 'prop-types';
import Lightbox from 'artifact/shared/Lightbox/index';
import React from 'react';
import useAsyncValue from 'shared/asyncHelper';

const AsyncLightbox = ({ photos: photosPromises, ...props }) => {
  const photos = useAsyncValue(() => Promise.all(photosPromises));

  return !!photos?.length && <Lightbox {...props} photos={photos} />;
};

AsyncLightbox.propTypes = {
  photos: arrayOf(func).isRequired,
  onClose: func.isRequired,
  onDownload: func,
  initial: number.isRequired,
};

AsyncLightbox.defaultProps = {
  onDownload: () => {},
};

export default AsyncLightbox;
