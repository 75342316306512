import { get } from 'lodash';
import { EmployeeCompetency, CompetencyStatus } from 'erp/employee/qualification/shared/employeeQualificationService';

export const isAcceptedStatus = (status: string) =>
  status === CompetencyStatus.CONFIRMED || status === CompetencyStatus.PROBABLE;

const useEmployeeQualificationCalculation = () => {
  const calculateWeightForApplyAllCompetencies = (competencies: EmployeeCompetency[]) =>
    competencies.reduce((result, competency) => {
      const { weight } = competency;

      const appliedStatus = get(competency, 'appliedState.status', CompetencyStatus.NOT_CONFIRMED);
      const currentStatus = get(competency, 'rootState.status', CompetencyStatus.NOT_CONFIRMED);

      if (
        appliedStatus === currentStatus ||
        (appliedStatus !== CompetencyStatus.NOT_CONFIRMED && currentStatus !== CompetencyStatus.NOT_CONFIRMED)
      ) {
        return result;
      } else if (isAcceptedStatus(currentStatus)) {
        return result + weight;
      } else {
        return result - weight;
      }
    }, 0);

  const calculateWeightOfAcceptedStatuses = (competencies: EmployeeCompetency[], source: string) =>
    competencies.reduce((result, competency) => {
      const { weight } = competency;
      const status = get(competency, source, CompetencyStatus.NOT_CONFIRMED);

      if (isAcceptedStatus(status)) {
        return result + weight;
      } else {
        return result;
      }
    }, 0);

  const hasNotAppliedStatus = (competencies: EmployeeCompetency[]) =>
    !!competencies.find(competency => {
      const appliedStatus = get(competency, 'appliedState.status', CompetencyStatus.NOT_CONFIRMED);
      const currentStatus = get(competency, 'rootState.status', CompetencyStatus.NOT_CONFIRMED);

      return (
        appliedStatus !== currentStatus &&
        appliedStatus !== CompetencyStatus.NOT_CONFIRMED &&
        currentStatus !== CompetencyStatus.NOT_CONFIRMED
      );
    });

  return {
    calculateWeightForApplyAllCompetencies,
    calculateWeightOfAcceptedStatuses,
    hasNotAppliedStatus,
  };
};

export default useEmployeeQualificationCalculation;
