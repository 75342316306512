/* istanbul ignore file */
import React from 'react';
import Button from 'uibuilder/button/Button';

const CollapseControls = ({
  isExpandButtonShown,
  isCollapseButtonShown,
  collapseAll,
  expandAll,
}: {
  isExpandButtonShown: boolean;
  isCollapseButtonShown: boolean;
  collapseAll: () => void;
  expandAll: () => void;
}) => {
  return (
    <div className="d-flex align-items-center flex-grow-0 flex-shrink-0">
      {isExpandButtonShown && (
        <Button outline onClick={expandAll}>
          Expand All
        </Button>
      )}
      {isCollapseButtonShown && (
        <Button outline sx={{ marginLeft: isExpandButtonShown ? '12px' : 0 }} onClick={collapseAll}>
          Collapse All
        </Button>
      )}
    </div>
  );
};

export default CollapseControls;
