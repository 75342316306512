/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const FEEDBACK_WAS_OBTAINED_ALIAS = {
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  TEAMS: 'TEAMS',
  ANOTHER_WAY: 'ANOTHER_WAY',
};

export const FEEDBACK_OBTAINED = {
  [FEEDBACK_WAS_OBTAINED_ALIAS.EMAIL]: 'Email',
  [FEEDBACK_WAS_OBTAINED_ALIAS.CALL]: 'Call',
  [FEEDBACK_WAS_OBTAINED_ALIAS.TEAMS]: 'Teams',
  [FEEDBACK_WAS_OBTAINED_ALIAS.ANOTHER_WAY]: 'Another way',
};

const FeedbackObtainedDropdown = (props: any) => {
  return <EnumDropdown {...props} options={FEEDBACK_OBTAINED} />;
};

export default FeedbackObtainedDropdown;
