import {
  EmployeeQualification,
  EmployeeCompetency,
} from 'erp/employee/qualification/shared/employeeQualificationService';
import { conditionalMap } from 'shared/arrayHelper';

const useEmployeeQualificationMerger = () => {
  const mergeCompetencyData = ({
    employeeQualifications = [],
    competencyData,
    qualificationId,
    competencyId,
  }: {
    employeeQualifications: EmployeeQualification[];
    competencyData: Partial<EmployeeCompetency>;
    qualificationId: StringOrNumber;
    competencyId: StringOrNumber;
  }) => {
    const mergedData = [...employeeQualifications];

    const getNewCompetencies = (competencies: EmployeeCompetency[]) => {
      const isCompetencyExist = !!competencies?.find(
        competency => String(competency.competencyId) === String(competencyId),
      );

      if (isCompetencyExist) {
        return conditionalMap(
          competencies,
          competency => String(competency.competencyId) === String(competencyId),
          competency => ({ ...competency, ...competencyData }),
        );
      } else {
        return [
          ...competencies,
          {
            id: competencyId,
            competencyId,
            ...competencyData,
          },
        ];
      }
    };

    return conditionalMap(
      mergedData,
      qualification => String(qualification.id) === String(qualificationId),
      qualification => ({
        ...qualification,
        competencies: getNewCompetencies(qualification.competencies || []),
      }),
    );
  };

  const mergeQualificationData = ({
    employeeQualifications = [],
    qualificationData,
    qualificationId,
  }: {
    employeeQualifications: EmployeeQualification[];
    qualificationData: EmployeeQualification;
    qualificationId: StringOrNumber;
  }) => {
    return conditionalMap(
      employeeQualifications,
      qualification => String(qualification.id) === String(qualificationId),
      qualification => ({ ...qualification, ...qualificationData }),
    );
  };

  return {
    mergeCompetencyData,
    mergeQualificationData,
  };
};

export default useEmployeeQualificationMerger;
