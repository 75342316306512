/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { ASSET_CATEGORIES_OPTIONS } from 'financialAnalytic/assets/useAssetService';

const AssetCategoryDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={ASSET_CATEGORIES_OPTIONS} {...props} />;
};

export default AssetCategoryDropdown;
