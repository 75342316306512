import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, LinkField } from 'shared/uibuilder/field';
import { useTrainingsUrl } from 'erp/trainings/TrainingsRouter';

const LinkTrainingField = (props: FieldProps) => {
  const { getValue } = useFieldHelper<any>(props);
  const { getSingleEntityUrl } = useTrainingsUrl();

  const training = getValue();
  const url = getSingleEntityUrl(training.id);

  return <LinkField {...props} value={url} linkText={training.name} />;
};

export default LinkTrainingField;
