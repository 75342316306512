/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import SearchResultAttachmentsListField from 'artifact/shared/field/SearchResultAttachmentsListField';

type ArtifactStorageFileFieldProps = {
  label?: string;
  source: string;
  value?: string;
  isVisible?: boolean | ((values: FormFieldsData) => boolean);
};

const ArtifactStorageFileField = (props: ArtifactStorageFileFieldProps) => {
  const { getValue } = useFieldHelper<any>(props);
  const file = getValue() || {};

  return <SearchResultAttachmentsListField {...props} key={file.id} value={[file.id]} />;
};

export default ArtifactStorageFileField;
