import React from 'react';
import { flattenDeep } from 'lodash';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import { useSyberryTodayApi } from 'api';
import useBaseCrudService from 'shared/crud/baseCrudService';
import useSingletonPromise from 'shared/useSingletonPromise';
import { MessageContentType } from '../shared/message/messageReducer';

export const calculateAllProjectIssues = (projects: ListItem[]): number =>
  projects.reduce((issuesCount: number, { customQueues, subProjects }: ListItem) => {
    return (
      issuesCount +
      (customQueues?.reduce((sum: number, { issues }: any) => sum + issues.length, 0) || 0) +
      (subProjects ? calculateAllProjectIssues(subProjects) : 0)
    );
  }, 0);

export const getAllQueues = (projects: ListItem[] = []): ListItem[] => {
  const data: ListItem[] = [];

  projects.forEach(project => {
    if (project.customQueues) {
      data.push(
        project.customQueues.map((queue: ListItem) => ({
          ...queue,
          projectName: project.name,
          projectId: project.id,
        })),
      );
    }

    if (project.subProjects) {
      getAllSubprojects(project.subProjects).forEach((subproject: ListItem) => {
        data.push(
          subproject.customQueues?.map((queue: ListItem) => ({
            ...queue,
            subProjectName: subproject.name,
            projectName: project.name,
            projectId: project.id,
          })),
        );
      });
    }
  });

  return flattenDeep(data).filter(queue => !!queue);
};

export const updateIssueData = (data: ListItem[], issueId: StringOrNumber, updatedIssue: ListItem): ListItem[] => {
  return data?.map(({ customQueues, subProjects, issues, ...queueData }: ListItem) => {
    const result = {
      ...queueData,
      customQueues: customQueues ? updateIssueData(customQueues, issueId, updatedIssue) : customQueues,
      subProjects: subProjects ? updateIssueData(subProjects, issueId, updatedIssue) : subProjects,
    };

    if (issues) {
      result.issues = issues?.map((issue: ListItem) => (issue.id === issueId ? updatedIssue : issue));
    }

    return result;
  });
};

export const removeIssueFromProject = (data: ListItem[], issueId: StringOrNumber): ListItem[] => {
  return data?.map(({ customQueues, subProjects, issues, ...queueData }: ListItem) => {
    const result = {
      ...queueData,
      customQueues: customQueues ? removeIssueFromProject(customQueues, issueId) : customQueues,
      subProjects: subProjects ? removeIssueFromProject(subProjects, issueId) : subProjects,
    };

    if (issues) {
      result.issues = issues?.filter((issue: ListItem) => issue.id !== issueId);
    }

    return result;
  });
};

export const updateQueueIssues = (
  data: ListItem[],
  queueId: StringOrNumber,
  updatedQueueIssues: ListItem,
): ListItem[] => {
  return data?.map(({ customQueues, subProjects, ...queueData }: ListItem) => {
    return {
      ...queueData,
      customQueues: customQueues
        ? customQueues.map((queue: ListItem) =>
            queue.id === queueId ? { ...queue, issues: updatedQueueIssues } : queue,
          )
        : customQueues,
      subProjects: subProjects ? updateQueueIssues(subProjects, queueId, updatedQueueIssues) : subProjects,
    };
  });
};

export const getQueueData = (
  data: ListItem[],
  queueId: StringOrNumber,
  projectId: StringOrNumber,
): Nullable<ListItem> => {
  let result = null;

  data?.find(({ id, customQueues, subProjects }: ListItem) => {
    const foundedQueue = customQueues?.find((queue: ListItem) => queue.id === queueId);

    if (foundedQueue && id === projectId) {
      result = foundedQueue;
    } else {
      result = getQueueData(subProjects, queueId, projectId);
    }

    return !!result;
  });

  return result;
};

export const getAllSubprojects = (subProjects?: any[]) => {
  const result: any[] = [];

  subProjects?.forEach(project => {
    result.push(project);

    if (project.subProjects) {
      result.push(...getAllSubprojects(project.subProjects));
    }
  });

  return result;
};

export const filterProjectQueues = ({ customQueues, subProjects, ...projectData }: any, excludedQueues: string[]) => {
  const result = projectData;

  if (customQueues) {
    result.customQueues = customQueues.filter(({ name }: any) => !excludedQueues.includes(name));
  }

  if (subProjects) {
    result.subProjects = subProjects.map((subproject: any) => filterProjectQueues(subproject, excludedQueues));
  }

  return result;
};

export const getSyberryTodayError = async (e: any, defaultError?: string) => {
  try {
    const { message } = await e.json();

    const parsedMessage: string[] = message ? JSON.parse(message) : [];
    let errors: React.ReactNode = defaultError;

    if (parsedMessage?.length) {
      errors = (
        <>
          {parsedMessage.map(error => (
            <div key={error}>{error}</div>
          ))}
        </>
      );
    }

    return errors as MessageContentType;
  } catch (error) {
    return defaultError as MessageContentType;
  }
};

export const READ_SYBERRY_TODAY = 'EMPLOYEE_READ_SYBERRY_TODAY';
export const READ_SYBERRY_TODAY_PROJECTS = 'EMPLOYEE_READ_SYBERRY_TODAY';
export const SYBERRY_TODAY_PATH = '/today';
export const SYBERRY_TODAY_PROJECTS_PATH = `${SYBERRY_TODAY_PATH}/projects`;

export enum BACKLOG_CATEGORIES {
  BLOCKED = 'blocked',
  NEW = 'inbox',
  INBOX = 'inbox',
  DO_LATER = 'all',
  TODAY = 'today',
  PARENT_TASKS = 'parent_tasks',
  RECCURENTLY = 'ongoing_activity',
}

const useSyberryTodayService = () => {
  const { sendPutRequest, sendPostRequest, sendGetRequest } = useSyberryTodayApi();

  const { search: baseSearch, getById } = useBaseCrudService({
    singleResourceUrl: '/projects/:id',
    listResourceUrl: '/projects',
    apiService: useSyberryTodayApi,
  });

  const search = async ({ pageSize, pageNumber, isCustomQueuesInclude = true, ...params }: any) =>
    baseSearch({ ...params, isCustomQueuesInclude });

  const getProjects = useSingletonPromise('getProjects', async (params: any) =>
    search({
      ...params,
      isCustomQueuesInclude: false,
    }),
  );

  const isCheckinPassed = async () => {
    const response = await sendGetRequest('/checkin');
    const { isCheckinWasPassed } = await response.json();

    return isCheckinWasPassed;
  };

  const getProjectData = async (projectId: number) => {
    const projectData = await getById(projectId);

    return { result: [projectData] };
  };

  const getProjectDataWithoutParentAndRecurrently = async (projectId: number) => {
    const excludedQueues = ['Parent tasks', 'Do recurrently'];
    const projectData = await getProjectData(projectId);

    return { result: [filterProjectQueues(projectData.result[0] || {}, excludedQueues)] };
  };

  const getPlannedTasks = async ({ filter, ...params }: any) =>
    search({
      ...params,
      issueBacklogCategory: 'TODAY',
    });

  const getParentTasks = async ({ filter, ...params }: any) =>
    search({
      ...params,
      issueBacklogCategory: 'PARENT_TASK',
    });

  const getRecurrentlyTasks = async ({ filter, ...params }: any) =>
    search({
      ...params,
      issueBacklogCategory: 'ONGOING_ACTIVITY',
    });

  const updateIssueCategory = async ({ backlogCategory, id }: { backlogCategory: BACKLOG_CATEGORIES; id: number }) =>
    sendPutRequest('/issue/category', {
      backlogCategory,
      id,
    });

  const updateIssuePositions = async ({
    id,
    positions,
    isQueuePosition,
  }: {
    id: number;
    positions: Dictionary<Dictionary<number>>;
    isQueuePosition: boolean;
  }) =>
    sendPutRequest(`/${isQueuePosition ? 'queue' : 'personal'}/issues/order`, {
      id,
      positions,
    });

  const confirmDailyCheckin = async () => {
    // We should send checkin steps that were passed
    return sendPostRequest('/checkin/confirm', { dailyCheckin: [0, 1] });
  };

  const skipDailyCheckin = () => sendPostRequest('/checkin/skip', {});

  const postponeDailyCheckin = () => sendPostRequest('/checkin/postpone', {});

  return {
    search,
    isCheckinPassed,
    getProjects,
    getProjectData,
    getPlannedTasks,
    updateIssueCategory,
    updateIssuePositions,
    confirmDailyCheckin,
    getParentTasks,
    getProjectDataWithoutParentAndRecurrently,
    getRecurrentlyTasks,
    skipDailyCheckin,
    postponeDailyCheckin,
  };
};

export default useSyberryTodayService;
