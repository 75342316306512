/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CandidateApplicationSourceDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationSourceDropdown';

const CandidateApplicationSourceFilter = props => {
  return (
    <DropDownFilter {...props}>
      <CandidateApplicationSourceDropdown />
    </DropDownFilter>
  );
};

export default CandidateApplicationSourceFilter;
