/* istanbul ignore file */
import React from 'react';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import { ButtonProps } from 'uibuilder/button/Button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useBudgetService from 'erp/budget/shared/budgetService';
import { ButtonWithConfirmation } from 'uibuilder/button';

export interface ArchiveBudgetButtonProps extends ButtonProps {
  nameSource: string;
  valueSource: string;
  permissionToCheck?: string;
}

const ArchiveBudgetButton = ({ nameSource, valueSource, permissionToCheck, ...props }: ArchiveBudgetButtonProps) => {
  const { getValue } = useFieldHelper(props);
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getValue: getIsArchived } = useFieldHelper({ source: valueSource });
  const isArchived = getIsArchived();
  const { getListUrl } = useBudgetUrl();
  const { archive } = useBudgetService();

  const archiveAction = async () => {
    return archive(getValue() as any, !isArchived);
  };

  return (
    <ButtonWithConfirmation
      afterSubmit={{
        redirectUrl: getListUrl(),
        successMessage: `${getName()} was ${isArchived ? 'restored' : 'archived'}`,
        errorMessage: `${getName()} can not ${isArchived ? 'restored' : 'archived'}`,
      }}
      modalConfirmButtonText={isArchived ? 'Restore' : 'Archive'}
      submitMethod={archiveAction}
      permissionToCheck={permissionToCheck}
      color={isArchived ? 'success' : 'secondary'}
      displayMessage={`Do you really want to ${isArchived ? 'restore' : 'archive'} the budget?`}
      outline
    >
      {isArchived ? 'Restore' : 'Archive'}
    </ButtonWithConfirmation>
  );
};

export default ArchiveBudgetButton;
