/* istanbul ignore file */
export default {
  name: {
    type: 'string',
    required: true,
  },
  lastName: {
    type: 'string',
    required: true,
  },
  email: {
    type: 'string',
    email: true,
    required: true,
  },
  phone: {
    type: 'string',
    phone: true,
  },

  address: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      country: {
        type: 'string',
      },
      addressLine1: {
        type: 'string',
        maxSize: 255,
      },
      city: {
        type: 'string',
        maxSize: 64,
      },
      state: {
        type: 'string',
      },
      zipCode: {
        type: 'string',
        maxSize: 64,
      },
    },
  },
};
