/* istanbul ignore file */
import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';
import useEmployeeService from 'erp/employee/employeeService';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import EmployeesBreadcrumbs from 'erp/employee/shared/EmployeesBreadcrumbs';

const SingleEmployeeBreadcrumbs = ({ children }) => {
  const { data } = useShowContext();
  const { getFullName } = useEmployeeService();
  const { getSummaryUrl } = useEmployeeUrl();
  const id = data.getValueBySource('alias');

  const firstName = data.getValueBySource('nameEn.firstName');
  const lastName = data.getValueBySource('nameEn.lastName');
  const maidenName = data.getValueBySource('nameEn.maidenName');

  const displayedName = getFullName({ firstName, lastName, maidenName });

  return (
    <EmployeesBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getSummaryUrl(id)} />
      {children}
    </EmployeesBreadcrumbs>
  );
};

SingleEmployeeBreadcrumbs.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
};

SingleEmployeeBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleEmployeeBreadcrumbs;
