/* istanbul ignore file */
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import {
  getBadgeColor,
  isStatusAllowed,
  getValueWithUnit,
  DEFAULT_TIME_UNITS,
  DaysBorderForColor,
  BADGE_COLOR_HEX,
} from 'crm/shared/field/LastTouchCrmField';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

interface LastTouchApplicationFieldProps extends FieldProps {
  statusSource: string;
  colorBorders: DaysBorderForColor;
  skipStates: string[];
}

const LastTouchApplicationField = ({
  source,
  statusSource,
  colorBorders,
  skipStates,
  ...props
}: LastTouchApplicationFieldProps) => {
  const { getValue: getLastModified } = useFieldHelper({ source });
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getHoursBetween, getMomentDateObjAsUtc, getCurrentDateTimeInUtc } = useDateService();

  const status = getStatus();
  const isNeedCalculate = isStatusAllowed(status, skipStates);

  const lastModified = getMomentDateObjAsUtc(getLastModified());
  const currentDateTime = getCurrentDateTimeInUtc();
  const hoursBetween = getHoursBetween(currentDateTime, lastModified, DATE_FORMAT.FULL);
  const roundHoursValue = isNeedCalculate ? Math.round(hoursBetween) : -1;
  const days = isNeedCalculate ? roundHoursValue / 24 : -1;
  const color = getBadgeColor(days, colorBorders);

  let resultValue;

  if (days === -1) {
    resultValue = EMPTY_VALUE_INDICATOR;
  } else if (days >= 0 && days < 1) {
    resultValue = getValueWithUnit(roundHoursValue, DEFAULT_TIME_UNITS.HOUR);
  } else {
    resultValue = getValueWithUnit(Math.round(days), DEFAULT_TIME_UNITS.DAY);
  }

  return (
    <Badge
      className="custom-badge ml-2"
      sx={{
        height: '2em',
        width: 70,
        backgroundColor: color,
        color: [BADGE_COLOR_HEX.GREEN, BADGE_COLOR_HEX.BRIGHT_RED].includes(color)
          ? 'var(--mui-palette-common-white)'
          : 'inherit',
      }}
      {...props}
    >
      {resultValue}
    </Badge>
  );
};

export default LastTouchApplicationField;
