/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import InvoiceBreadcrumbs from 'financialAnalytic/invoice/shared/InvoiceBreadcrumbs';

const UpdateInvoiceBreadcrumbs = () => {
  return (
    <InvoiceBreadcrumbs>
      <UpdateBreadcrumbItem entity="Invoice" />
    </InvoiceBreadcrumbs>
  );
};

export default UpdateInvoiceBreadcrumbs;
