/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { PURCHASE_ORDER_TYPES_OPTIONS } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';

const PurchaseOrderTypeField = (props: FieldProps) => {
  return <EnumField options={PURCHASE_ORDER_TYPES_OPTIONS} {...props} />;
};

export default PurchaseOrderTypeField;
