import { useEffect } from 'react';

const usePasteFromBuffer = (callback: (arg: any) => any, condition: boolean, element = window) => {
  useEffect(() => {
    if (condition && callback) {
      element.addEventListener('paste', callback);
    }

    return () => {
      if (condition && callback) {
        element.removeEventListener('paste', callback);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, element, callback]);
};

export default usePasteFromBuffer;
