import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconButton, Badge, MenuItem } from '@mui/material';
import useAsyncValue from 'shared/asyncHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import { useDateTimeService } from 'shared/uibuilder/dateService';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import useLeadService from '../leadService';
import { ASC, DESC } from 'shared/uibuilder/list/baseListHelper';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import NewLeadsNotificationButton from '../filter/NewLeadsNotificationButton';
import StaleLeadsNotificationButton from '../filter/StaleLeadsNotificationButton';
import { useHistory } from 'react-router-dom';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import { Filter } from 'shared/uibuilder/list/filter/FilterContext';

const EXCEED_DAYS = 21;
const TEN_MINUTES = 600000;

const LeadsNotificationHeaderButton = () => {
  const [staleLeadsCount, setStaleLeadsCount] = useState(0);
  const [newLeadsCount, setNewLeadsCount] = useState(0);
  const [isLoadingNewLeads, setIsLoadingNewLeads] = useState(true);
  const [isLoadingStaleLeads, setIsLoadingStaleLeads] = useState(true);

  const { search } = useLeadService() as any;
  const history = useHistory();
  const { getListUrl } = useLeadUrl();
  const { formatDateForAPI } = useDateTimeService();
  const { getCurrentDateTimeInUtc } = useDateService();
  const { getUserAlias } = useAuthenticatedUserService();

  const currentUserAlias = useAsyncValue(async () => getUserAlias());

  const currentUtcDate = getCurrentDateTimeInUtc();
  const staleDate = currentUtcDate.subtract(EXCEED_DAYS, 'days');
  const formattedStaleDate = formatDateForAPI(staleDate, 'end');

  const staleLeadsFilterState = useMemo(
    () => ({
      status: { in: ['NEW', 'ASSIGNED', 'OPEN'] },
      updatedAt: { le: formattedStaleDate },
      'owner.alias': { in: [currentUserAlias] },
    }),
    [currentUserAlias, formattedStaleDate],
  );

  const newLeadsFilterState = useMemo(
    () => ({
      status: { in: ['NEW'] },
    }),
    [],
  );

  const loadData = useCallback(async () => {
    const [responseNewLeads, responseStaleLeads] = await Promise.all([
      search({ filter: newLeadsFilterState, sort: { updatedAt: ASC }, pageSize: 1 }),
      search({ filter: staleLeadsFilterState, sort: { updatedAt: ASC }, pageSize: 1 }),
    ]);

    setNewLeadsCount(responseNewLeads.totalElements || 0);
    setStaleLeadsCount(responseStaleLeads.totalElements || 0);
  }, [newLeadsFilterState, search, staleLeadsFilterState]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (currentUserAlias) {
      loadData();
      const timer = setInterval(loadData, TEN_MINUTES);

      return () => clearInterval(timer);
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserAlias]);

  const customButtonClick = useCallback(
    (state: Filter) => {
      history.replace(`${getListUrl()}?sortBy=updatedAt&sortOrder=${DESC}&filter=${JSON.stringify(state)}`);
    },
    [getListUrl, history],
  );

  return (
    <DropdownMenu
      popupId="header-lead-notification"
      iconButton={
        <IconButton>
          <Badge invisible={!(newLeadsCount + staleLeadsCount)} variant="dot" color="error">
            <NotificationsNoneIcon fontSize="medium" color="action" />
          </Badge>
        </IconButton>
      }
      sxList={{
        padding: '6px 0',
        '& .MuiMenuItem-root': {
          padding: '2px 12px',
        },
      }}
      menuPlacement="bottom-end"
    >
      <MenuItem
        sx={{
          display: !(isLoadingNewLeads || isLoadingStaleLeads) && !(newLeadsCount || staleLeadsCount) ? 'flex' : 'none',
        }}
      >
        No leads notifications
      </MenuItem>

      <MenuItem
        sx={{
          display: isLoadingNewLeads || newLeadsCount ? 'flex' : 'none',
        }}
      >
        <NewLeadsNotificationButton
          setIsLoadingCallback={setIsLoadingNewLeads}
          loadingDataCallback={setNewLeadsCount}
          state={newLeadsFilterState}
          onClick={customButtonClick}
        />
      </MenuItem>
      <MenuItem
        sx={{
          display: isLoadingStaleLeads || staleLeadsCount ? 'flex' : 'none',
        }}
      >
        <StaleLeadsNotificationButton
          setIsLoadingCallback={setIsLoadingStaleLeads}
          loadingDataCallback={setStaleLeadsCount}
          state={staleLeadsFilterState}
          onClick={customButtonClick}
          isLoading={!currentUserAlias}
        />
      </MenuItem>
    </DropdownMenu>
  );
};

export default LeadsNotificationHeaderButton;
