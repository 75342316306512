/* istanbul ignore file */
// libraries
import React from 'react';
// components
import RelatedList from 'shared/uibuilder/list/RelatedList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { DateField, TextField } from 'shared/uibuilder/field';
// services
import { useReadBooksService } from '../shared/readBooksService';
// styles
import './RelatedReadBooksList.scss';

const RelatedReadBooksList = () => {
  const { findAllEmployeeReadBooks } = useReadBooksService();

  return (
    <RelatedList getDataMethod={findAllEmployeeReadBooks}>
      <RelationListLayout header="Read books">
        <DataGridLayout tableWrapperClassName="read-books-table" showTotal>
          <DateField source="createdAt" label="Date read" />
          <TextField source="title" label="Title" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

export default RelatedReadBooksList;
