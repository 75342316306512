/* istanbul ignore file */
import React from 'react';
import { OneStopRouter } from 'oneStop';
import { Redirect, Router, Route } from 'shared/routing';
import { ROOT_PATH } from 'ApplicationRouter';

const IS_EXACT_PATH = true;
const ONE_STOP_ROUTER_PATH = OneStopRouter.getRouterPath();
const REDIRECT_PATH = Array.isArray(ONE_STOP_ROUTER_PATH) ? ONE_STOP_ROUTER_PATH[0] : ONE_STOP_ROUTER_PATH;

const RedirectToOneStop: Router = () => (
  <Route path={ROOT_PATH}>
    <Redirect to={REDIRECT_PATH} />
  </Route>
);

RedirectToOneStop.getRouterPath = () => ROOT_PATH;
RedirectToOneStop.getIsExactPath = () => IS_EXACT_PATH;

export default RedirectToOneStop;
