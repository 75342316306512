/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { OPPORTUNITY_TYPE } from 'crm/opportunity/shared/opportunityService';

const OpportunityTypeField = props => {
  return <EnumField {...props} options={OPPORTUNITY_TYPE} />;
};

export default OpportunityTypeField;
