/* istanbul ignore file */
import React from 'react';
import OwnerFilter from 'crm/shared/filter/OwnerFilter';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import List from 'shared/uibuilder/list/List';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { DateField, FullDateField, SearchResultField } from 'shared/uibuilder/field';
import useOpportunityService, {
  CLOSED_LOST,
  CLOSED_WON,
  UPDATE_OPPORTUNITY_FROM_LIST_PAGE,
  OPPORTUNITY_STAGES,
  READ_FULL_OPPORTUNITIES_LIST,
  POSTPONED,
} from 'crm/opportunity/shared/opportunityService';
import CreateOpportunityButton from 'crm/opportunity/list/button/CreateOpportunityButton';
import EditOpportunityButton from 'crm/opportunity/shared/button/EditOpportunityButton';
import OpportunityLinkField from 'crm/opportunity/shared/field/OpportunityLinkField';
import OpportunityStageField from 'crm/opportunity/shared/field/OpportunityStageField';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import OpportunityStageFilter from 'crm/opportunity/list/filter/OpportunityStageFilter';
import AccountFilter from 'crm/shared/filter/AccountFilter';
import useAuthorization from 'shared/authorization/authorizationService';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { QuickFilterAndSort, Filters } from 'shared/uibuilder/list/filter';
import { EmployeeLinkField } from 'erp/employee';
import ExpandingTooltipField from 'shared/uibuilder/field/ExpandingTooltipField';
import LastTouchCrmField from 'crm/shared/field/LastTouchCrmField';

const ACTIVE_STAGES = Object.keys(OPPORTUNITY_STAGES)
  .filter(stage => stage !== CLOSED_LOST)
  .filter(stage => stage !== CLOSED_WON);

const DAYS_COLOR_BORDER = {
  greenBorder: 1.5,
  yellowBorder: 3.5,
  lightRedBorder: 7.5,
};

const EXCEED_DAYS = 21;

const NOT_ACTIVE_STATES = Object.keys(OPPORTUNITY_STAGES).filter(
  state => state === CLOSED_LOST || state === CLOSED_WON || state === POSTPONED,
);

const OpportunitiesListPage = () => {
  const { search, getFilterValidationSchema } = useOpportunityService();
  const { isGranted } = useAuthorization();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout
        quickFilters={[
          <QuickFilterAndSort
            label="All Active Opportunities"
            state={{ stateStep: { in: ACTIVE_STAGES } }}
            defaultSort={{ field: 'createdAt', order: DESC }}
          />,
          <QuickFilterAndSort
            label="All Won Opportunities"
            state={{ stateStep: { in: [CLOSED_WON] } }}
            defaultSort={{ field: 'closeDate', order: DESC }}
          />,
          <QuickFilterAndSort
            label="All Lost Opportunities"
            state={{ stateStep: { in: [CLOSED_LOST] } }}
            defaultSort={{ field: 'closeDate', order: DESC }}
          />,
        ]}
      >
        <AccountFilter label="Account Name" source="account.alias" />
        <OpportunityStageFilter label="State" source="stateStep" />
        <OwnerFilter label="Owner" source="owner.alias" isVisible={isGranted(READ_FULL_OPPORTUNITIES_LIST)} />
        <DateTimeRangeFilter label="Created Date" source="createdAt" />
        <DateTimeRangeFilter label="Close Date" source="closeDate" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="updatedAt">
      <ListPageLayout
        isSearchFormEnabled
        searchFormLabel="Opportunity"
        header={<ListHeader label="Opportunities" icon="fa-compass" />}
        filter={filters}
        actions={[<CreateOpportunityButton />]}
        search={<SearchInput label="Opportunity" />}
      >
        <DataGridLayout
          buttons={[
            <EditOpportunityButton
              source="id"
              outline
              permissionToCheck={UPDATE_OPPORTUNITY_FROM_LIST_PAGE}
              sx={{ height: '2em' }}
            />,
          ]}
          isSmallTable
        >
          <OpportunityLinkField label="ID" source="id" idSource="id" isSortable width={60} />
          <ExpandingTooltipField source="name" label="Opportunity" isSortable maxValueLength={25} width={200}>
            <SearchResultField />
          </ExpandingTooltipField>
          <AccountLinkField
            label="Account"
            source="accountName"
            sortSource="account.accountName"
            idSource="accountId"
            isSortable
            isInternal={false}
          />
          <LastTouchCrmField
            label="Last Touch"
            source="updatedAt"
            statusSource="stateStep"
            colorBorders={DAYS_COLOR_BORDER}
            skipStates={NOT_ACTIVE_STATES}
            exceedDaysForStale={EXCEED_DAYS}
            isSortable
            width={100}
          />
          <OpportunityStageField label="State" source="stateStep" isSortable width={130} />
          <EmployeeLinkField source="ownerId" nameSource="ownerName" label="Owner" isSortable />
          <DateField label="Close Date" source="closeDate" isSortable width={100} />
          <FullDateField label="Created Date" source="createdAt" isSortable width={165} />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default OpportunitiesListPage;
