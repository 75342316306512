import React from 'react';
import { useParams } from 'react-router-dom';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useExpenseService, { EXPENSE_STATUSES, REJECT_EXPENSE } from '../../useExpenseService';

const CancelExpenseButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { cancelExpense, invalidateCache } = useExpenseService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const status = getValue();

  if (status !== EXPENSE_STATUSES.PAID) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage="Do you really want to cancel this payment?"
      deleteMethod={() => cancelExpense(id)}
      afterDelete={{
        successMessage: 'The Payment has been successfully canceled.',
        errorMessage: "Can't cancel Payment.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={REJECT_EXPENSE}
      buttonText="Cancel Payment"
      color="error"
    />
  );
};

export default CancelExpenseButton;
