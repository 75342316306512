/* istanbul ignore file */
import React from 'react';
import { EmployeePerformanceDetailsInputProps } from 'erp/employee/salaries/shared/inputs/EmployeePerformanceDetailsInput';
import FormSection from 'shared/layout/form/FormSection';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import FormRow from 'shared/layout/form/FormRow';
import MediumSectionRow from 'shared/uibuilder/layout/MediumSectionRow';
import {
  CUSTOMER_SUCCESS_IMPACT_SECTION,
  ECOSYSTEM_IMPACT_SECTION,
  ROLE_BASED_ESSENTIAL_IMPACT_SECTION,
} from 'erp/employee/salaries/shared/EmployeePerformanceLevelCriteriaTypes';
import { PERFORMANCE_GRADES_OPTIONS } from 'erp/employee/salaries/shared/salaryService';

const criteriaSectionComponent = (source: string, section: any, sectionSource: string) => {
  const fieldSource = `${source}.${sectionSource}.${section.field}`;
  return (
    <FormSection title={`${section.title}`} titleVariant="h5" key={section.field}>
      <MediumSectionRow>
        <ClearAfterUnmountWrapper>
          <TextArea
            source={`${fieldSource}.positive`}
            label="Positive Details"
            tooltip="Provide examples of positive events with an employee, if applicable."
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper>
          <TextArea
            source={`${fieldSource}.negative`}
            label="Negative Details"
            tooltip="Provide examples of negative events with an employee, if applicable."
          />
        </ClearAfterUnmountWrapper>
      </MediumSectionRow>
    </FormSection>
  );
};

const EmployeePerformanceLevelInput = ({ source }: EmployeePerformanceDetailsInputProps) => {
  return (
    <div>
      <FormRow>
        <EnumDropdown
          options={PERFORMANCE_GRADES_OPTIONS}
          source={`${source}.helpfulnessGrade`}
          label="Helpfulness Grade"
          placeholder="Select grade"
        />
      </FormRow>
      <FormSection title="L1 Role-based essential impact">
        {ROLE_BASED_ESSENTIAL_IMPACT_SECTION.map(section =>
          criteriaSectionComponent(source, section, 'roleBasedEssentialImpact'),
        )}
      </FormSection>
      <FormSection title="L2 Customer success impact">
        {CUSTOMER_SUCCESS_IMPACT_SECTION.map(section =>
          criteriaSectionComponent(source, section, 'customerSuccessImpact'),
        )}
      </FormSection>
      <FormSection title="L3 Ecosystem impact">
        {ECOSYSTEM_IMPACT_SECTION.map(section => criteriaSectionComponent(source, section, 'ecosystemImpact'))}
      </FormSection>
      <FormSection title="Personality traits" titleVariant="h5">
        <TextArea source={`${source}.personalityTraits`} label="" />
      </FormSection>
    </div>
  );
};

export default EmployeePerformanceLevelInput;
