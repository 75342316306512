export const groupBy = (array: [], key: string) =>
  array.reduce((r, a) => {
    // eslint-disable-next-line no-param-reassign
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));

type MapArrayItem = Dictionary<any>;

export const conditionalMap = (
  array: MapArrayItem[],
  conditionFn: (arrayItem: MapArrayItem) => boolean,
  newArrayItem: (arrayItem: MapArrayItem) => MapArrayItem,
) =>
  array.map(arrayItem => {
    if (conditionFn(arrayItem)) {
      return newArrayItem(arrayItem);
    } else {
      return arrayItem;
    }
  });
