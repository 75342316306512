/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { Area } from 'erp/qualification/model/qualificationModelService';

const CompetencyAreaBadge = ({ area }: { area: Area }) => {
  return (
    <Badge
      color="neutralLight"
      className="mr-1"
      sx={{ background: theme => theme.vars.palette.warning[50], color: theme => theme.vars.palette.neutral[0] }}
    >
      {area.name}
    </Badge>
  );
};

export default CompetencyAreaBadge;
