import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import roomValidation from 'erp/room/shared/roomValidation';

export const READ_ROOM_LIST = 'ROOM_READ_LIST';
export const READ_ROOM = 'READ';
export const CREATE_ROOM = 'ROOM_CREATE';
export const UPDATE_ROOM = 'UPDATE';
export const DELETE_ROOM = 'DELETE';

export const ROOM_PATH = '/rooms';
export const RESOURCE_URL = '/rooms';

export const ROOM_TYPE = {
  WORKROOM: 'WORKROOM',
  MEETING_ROOM: 'MEETING_ROOM',
};

export const ROOM_TYPE_OPTIONS = {
  [ROOM_TYPE.WORKROOM]: 'Workroom',
  [ROOM_TYPE.MEETING_ROOM]: 'Meeting Room',
};

const useRoomService = () => {
  const { search: baseSearch, ...baseCrud } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const search = async (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.roomNumber = {
        ct: searchString,
      };
    }

    return baseSearch(searchParams);
  };

  const getValidationSchema = () => Promise.resolve(roomValidation);

  return {
    search,
    getValidationSchema,
    ...baseCrud,
  };
};

export default useRoomService;
