/* istanbul ignore file */
export default {
  subject: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  description: {
    type: 'string',
    required: true,
    maxSize: 65556,
  },
};
