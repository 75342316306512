import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface DropDownFilterInputOptionProps {
  isSelected?: boolean;
  value?: string | number;
  isMulti?: boolean;
  children?: string;
  component?: any;
  onClick?: () => void;
}

const DropDownFilterInputOption = ({
  component: Component = components.Option,
  ...optionsProps
}: DropDownFilterInputOptionProps) => {
  const icon = optionsProps.isSelected ? (
    <CheckBoxIcon className="filter-checkbox filter-checkbox--active" />
  ) : (
    <CheckBoxBlankIcon className="filter-checkbox" />
  );

  if (optionsProps.value) {
    return (
      <Component
        {...optionsProps}
        isFocused={false}
        className={classNames('button-dropdown__menu-item', { multiple: optionsProps.isMulti })}
      >
        {optionsProps.isMulti && icon}
        {optionsProps.children}
      </Component>
    );
  } else {
    return (
      <div className={classNames('button-dropdown__menu-header', { multiple: optionsProps.isMulti })}>
        {optionsProps.children}
      </div>
    );
  }
};

export default DropDownFilterInputOption;
