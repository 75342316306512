/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import UpdateCandidateBreadcrumbs from 'erp/candidate/createupdate/Update/Breadcrumbs';
import useCandidateService from 'erp/candidate/shared/candidateService';
import CandidateForm from 'erp/candidate/createupdate/CandidateForm';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const UpdateCandidatePage = () => {
  const { update, getById, getValidationSchema } = useCandidateService();
  const id = useCandidateId();
  const { getProfileUrl } = useCandidateUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getProfileUrl(id),
        message: 'You are awesome! The Candidate has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Candidate"
        breadcrumbs={<UpdateCandidateBreadcrumbs />}
        entityHeader={<CandidateEntityHeader />}
        menu={<CandidateMenu isEditForm />}
      >
        <CandidateForm hasAnchorLink hasStatusField />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateCandidatePage;
