/* istanbul ignore file */
import React from 'react';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewCandidateOfferButton = ({ ...props }) => {
  const { getSingleEntityUrl } = useEmployeeOfferUrl();

  return (
    <LinkButton url={getSingleEntityUrl} outline color="primary" {...props}>
      View
    </LinkButton>
  );
};

export default ViewCandidateOfferButton;
