/* istanbul ignore file */
import React from 'react';
import useInvoiceService, { RUN_INVOICE_SUBTOTAL_UPDATE } from 'financialAnalytic/invoice/useInvoiceService';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';

const LOCAL_STORAGE_KEY = 'invoice-migration-data';

const RunInvoiceMigrationButton = () => {
  const { runUpdateInvoiceSubTotals } = useInvoiceService();

  return (
    <RunMigrationButton
      displayMessage="Are you sure you want to update invoice sub totals? This action cannot be undone. Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={runUpdateInvoiceSubTotals}
      permissionToCheck={RUN_INVOICE_SUBTOTAL_UPDATE}
    >
      Update Sub Totals
    </RunMigrationButton>
  );
};

export default RunInvoiceMigrationButton;
