/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { ASSET_CATEGORIES_TYPES } from 'financialAnalytic/assets/useAssetService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const AssetCategoryTypeField = ({ categorySource, source, ...props }: FieldProps & { categorySource: string }) => {
  const { getValue: getCategory } = useFieldHelper({ source: categorySource });
  const category = getCategory() as any;
  const options = ASSET_CATEGORIES_TYPES[category] || {};

  return <EnumField options={options} {...props} source={source} />;
};

export default AssetCategoryTypeField;
