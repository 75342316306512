/* istanbul ignore file */
export default {
  ticketIds: {
    type: 'string',
    required: true,
  },
  budgetFromId: {
    type: 'string',
    required: true,
  },
  budgetToId: {
    type: 'string',
    required: true,
  },
  reason: {
    type: 'string',
    required: true,
    maxSize: 1024,
  },
};
