/* istanbul ignore file */

import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';
import Filters from 'shared/uibuilder/list/filter/Filters';
import StaticFiltersLayout from 'shared/uibuilder/list/filter/layout/StaticFiltersLayout';
import PayrollPeriodFilter from 'erp/payroll/List/filter/PayrollPeriodFilter';
import FileList from 'shared/uibuilder/list/FileList';
import usePayrollService from 'erp/payroll/shared/payrollService';
import usePayrollDataChangesHandler from '../shared/payrollDataChangesHandler';
import LoadingListLayout from 'shared/uibuilder/list/layout/LoadingListLayout';
import OfficeFilter from './filter/OfficeFilter';
import Grid from 'uibuilder/Grid';

const PayrollList = () => {
  const { getReport, getLatestGeneration, getMaximalAvailablePeriod } = usePayrollService();

  const filters = (
    <Filters>
      <StaticFiltersLayout actionButtonName="Generate">
        <Grid container spacing={7}>
          <Grid item xs={12} sm={6}>
            <PayrollPeriodFilter source="period" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OfficeFilter source="office" />
          </Grid>
        </Grid>
      </StaticFiltersLayout>
    </Filters>
  );

  const { modal, getDataMethod } = usePayrollDataChangesHandler(getReport, getLatestGeneration);

  return (
    <>
      {modal}
      <FileList
        getDataMethod={getDataMethod}
        defaultFilters={{
          period: {
            eq: getMaximalAvailablePeriod(),
          },
          office: {
            eq: [],
          },
        }}
      >
        <ListPageLayout showTotal={false} header={<ListHeader label="Payroll" icon="fa-money" />} filter={filters} />
        <LoadingListLayout />
      </FileList>
    </>
  );
};

export default PayrollList;
