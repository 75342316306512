// libs
import React, { useEffect, useMemo, useState } from 'react';
// components
import ToggleSensitiveDataButton from 'shared/uibuilder/sensitiveData/buttons/ToggleSensitiveDataButton';
// services
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useSensitiveDataService } from 'shared/uibuilder/sensitiveData';
import { SensitiveDataContextProvider } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';

type SensitiveData = Dictionary<any>;

const SensitiveListElementLayout = ({
  children,
  sensitiveDataIdSource,
  loadSensitiveDataMethod,
  timerId,
  getSensitiveDataIds,
  shouldShowSensitiveDataButton,
  isSensitiveDataIsShown = true,
}: {
  sensitiveDataIdSource: string;
  children: React.ReactElement;
  loadSensitiveDataMethod: (ids: string[]) => Promise<SensitiveData>;
  timerId?: string;
  getSensitiveDataIds?: (items?: Dictionary<any>[]) => string[];
  shouldShowSensitiveDataButton?: boolean;
  isSensitiveDataIsShown?: boolean;
}) => {
  const [sensitiveDataIds, setSensitiveDataIds] = useState([]);
  const { data: { items } = { items: [] } } = useListContext();

  const {
    isTimerActive,
    isShown,
    toggleSensitiveData,
    getValueByIdAndSource,
    isSensitiveDataLoading,
    loadSensitiveDataByIds,
    sensitiveData,
  } = useSensitiveDataService({
    loadSensitiveDataMethod,
    timerId,
  });

  useEffect(() => {
    if (getSensitiveDataIds) {
      setSensitiveDataIds(getSensitiveDataIds(items) as any);

      return;
    }

    if (items?.length) {
      const sensitiveIds = items?.map((item: any) => item[sensitiveDataIdSource]);

      setSensitiveDataIds(sensitiveIds as any);
    }
  }, [items, sensitiveDataIdSource, getSensitiveDataIds]);

  const hasSensitiveData = useMemo(() => sensitiveData && Object.keys(sensitiveData).length, [sensitiveData]);

  const isShownWithoutSensitiveData = useMemo(() => sensitiveDataIds?.length && !hasSensitiveData && isShown, [
    isShown,
    hasSensitiveData,
    sensitiveDataIds,
  ]);

  useEffect(() => {
    if (isShownWithoutSensitiveData) {
      loadSensitiveDataByIds(sensitiveDataIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownWithoutSensitiveData]);

  return (
    <SensitiveDataContextProvider
      value={{
        sensitiveDataIdSource,
        getValueByIdAndSource,
      }}
    >
      {React.cloneElement(children, {
        isSensitiveDataIsShown:
          (isShown && !isSensitiveDataLoading && hasSensitiveData) || (!items?.length && isSensitiveDataIsShown),
        toggleSensitiveDataButton:
          items?.length || shouldShowSensitiveDataButton ? (
            <ToggleSensitiveDataButton
              key="toggle-sensitive-data"
              isShown={isShown}
              onClick={() => toggleSensitiveData(sensitiveDataIds)}
              disabled={isSensitiveDataLoading}
              isTimerActive={isTimerActive}
            />
          ) : null,
        reloadSensitiveData: () => {
          const ids = getSensitiveDataIds
            ? getSensitiveDataIds(items)
            : items?.map((item: any) => item[sensitiveDataIdSource]);

          loadSensitiveDataByIds(ids || []);
        },
      })}
    </SensitiveDataContextProvider>
  );
};

export default SensitiveListElementLayout;
