import useUiTheme from 'shared/uibuilder/useUiTheme';
import React from 'react';
import { EmailFieldLayoutType } from 'shared/uibuilder/field/EmailField';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';

const EmailFieldLayout: EmailFieldLayoutType = ({ value, label, ...props }) => {
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const passedValue = value ? <a href={`mailto:${value}`}>{value}</a> : null;

  return <BaseFieldLayout {...props} value={passedValue} label={label} />;
};

export default EmailFieldLayout;
