/* istanbul ignore file */
// libraries
import React from 'react';
// services
import {
  useCompanyRolesService,
  ROLES_ALLOWED_TO_REMOVE,
  Role,
  SERVICE_ENGINEER_POSTFIX,
} from '../shared/companyRolesService';
// components
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import AddCompanyRoleToEmployeeButton from './buttons/AddCompanyRoleToEmployeeButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import RemoveEmployeeCompanyRoleButton from './buttons/RemoveEmployeeCompanyRoleButton';
// styles
import './RelatedCompanyRolesList.scss';

const RelatedCompanyRolesList = () => {
  const { searchRolesByAlias } = useCompanyRolesService();

  const isRemoveRoleButtonVisible = (rowData: any) => {
    const role: Role = rowData.name;
    return ROLES_ALLOWED_TO_REMOVE.has(role) || role.endsWith(SERVICE_ENGINEER_POSTFIX);
  };

  return (
    <RelatedList getDataMethod={searchRolesByAlias}>
      <RelationListLayout actions={[<AddCompanyRoleToEmployeeButton />]} header="Company roles">
        <DataGridLayout
          tableWrapperClassName="company-roles-table"
          showTotal
          buttons={[<RemoveEmployeeCompanyRoleButton isVisible={isRemoveRoleButtonVisible} />]}
        >
          <TextField label="Role name" source="name" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

export default RelatedCompanyRolesList;
