import React from 'react';
import { TogglerInput } from 'shared/uibuilder/form/input';

const CHECKED_VALUE = 100;
const UNCHECKED_VALUE = 0;

interface QualificationCompletenessTogglerProps {
  onChangeCallback: (value: number) => void;
  completeness?: number;
}

const QualificationCompletenessToggler = ({
  onChangeCallback,
  completeness = UNCHECKED_VALUE,
}: QualificationCompletenessTogglerProps) => {
  const onChange = (event: Dictionary<boolean>) => {
    onChangeCallback(event.completeness ? CHECKED_VALUE : UNCHECKED_VALUE);
  };

  return (
    <TogglerInput
      className="completeness__switch"
      source="completeness"
      color="success"
      label=" "
      onChangeCallback={onChange}
      value={completeness}
      checkedValue={`${CHECKED_VALUE}%`}
      uncheckedValue={`${UNCHECKED_VALUE}%`}
    />
  );
};

export default QualificationCompletenessToggler;
