import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { TextArea } from 'shared/uibuilder/form/input';
import useWasteService, { WASTE_REPORTING_METHOD, WASTE_TYPE } from '../useWasteService';
import useDropdownHelper from 'shared/uibuilder/form/input/dropdownHelper';
import OtherExpensesWasteRow from './shared/formRows/OtherExpensesWasteRow';
import LaborCostWasteRow from './shared/formRows/LaborCostWasteRow';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';

const WasteForm = () => {
  const { isHasWithViolations } = useWasteService();
  const { getValue } = useDropdownHelper({ source: 'type' });
  const wasteType = getValue();

  return (
    <>
      <FormSection title="Waste Details">
        <FormRow>
          <Dropdown
            isRequired
            source="type"
            label="Waste Type"
            placeholder="Choose Waste Type"
            options={[
              { value: WASTE_TYPE.LABOR_COST_WASTE, label: 'Labor Cost Waste' },
              { value: WASTE_TYPE.OTHER_EXPENSES_WASTE, label: 'Other Expenses Waste' },
            ]}
          />
          {wasteType === WASTE_TYPE.LABOR_COST_WASTE ? (
            <ClearAfterUnmountWrapper>
              <Dropdown
                isRequired
                source="laborWasteDetails.reportingMethod"
                label="Reporting Method"
                placeholder="Choose Reporting Method"
                options={[
                  { value: WASTE_REPORTING_METHOD.BY_EMPLOYEE_AND_TICKET_IDS, label: 'By Employee and Ticket ID(s)' },
                  { value: WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD, label: 'By Project and Period' },
                  { value: WASTE_REPORTING_METHOD.BY_TICKET_IDS, label: 'By Ticket ID(s)' },
                ].filter(({ value }) => isHasWithViolations || value !== WASTE_REPORTING_METHOD.BY_TICKET_IDS)}
              />
            </ClearAfterUnmountWrapper>
          ) : (
            <></>
          )}
        </FormRow>

        {wasteType === WASTE_TYPE.LABOR_COST_WASTE ? <LaborCostWasteRow /> : <></>}
        {wasteType === WASTE_TYPE.OTHER_EXPENSES_WASTE ? <OtherExpensesWasteRow /> : <></>}

        <BigFormRow>
          <TextArea source="reason" />
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default WasteForm;
