/* istanbul ignore file */
// libs
import React from 'react';
import { element } from 'prop-types';
// components
import Show from 'shared/uibuilder/show/Show';
// services
import useContactService from 'crm/contact/shared/contactService';
import { useContactId } from 'crm/contact/ContactRouter';

const ShowContact = ({ children }) => {
  const id = useContactId();
  const { getById } = useContactService();

  return <Show getDataMethod={() => getById(id)}>{children}</Show>;
};

ShowContact.propTypes = {
  children: element.isRequired,
};

export default ShowContact;
