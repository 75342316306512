import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import FormRow from 'shared/layout/form/FormRow';
import {
  HIRING_REQUEST_FORECAST_UPDATE,
  NECESSITY_STATUS_OPTIONS,
  POSITION_STATUS,
  POSITION_STATUS_OPTIONS,
} from '../../useHiringService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import useAuthorization from 'shared/authorization/authorizationService';
import DateInput from 'shared/uibuilder/form/input/DateInput';

type PositionsInputsProps = {
  source: string;
  value: any;
};

const PositionsInputs = ({ ...props }: PositionsInputsProps) => {
  const { getSource } = useInputHelper(props);
  const source = getSource();
  const { isGranted } = useAuthorization();

  const { getValue } = useInputHelper({ source: `${source}.positionStatus` });
  const positionStatus = getValue();

  const isEditForeCastDate = isGranted(HIRING_REQUEST_FORECAST_UPDATE);

  return (
    <FormRow>
      <EnumDropdown options={NECESSITY_STATUS_OPTIONS} source={`${source}.necessityStatus`} label="Type" />
      <EnumDropdown options={POSITION_STATUS_OPTIONS} source={`${source}.positionStatus`} label="Status" />
      {isEditForeCastDate ? (
        <DateInput
          isVisible={POSITION_STATUS.OPEN === positionStatus}
          source={`${source}.forecastDate`}
          label="Forecast date"
        />
      ) : (
        <></>
      )}
    </FormRow>
  );
};

export default PositionsInputs;
