/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { TextField, FieldProps } from 'shared/uibuilder/field';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

const InvoiceTotalField = ({
  source,
  currencySource,
  formatAmount,
  formatCurrency,
  ...props
}: FieldProps & {
  isVisible?: boolean | ((data: any) => boolean);
  currencySource: string;
  formatAmount?: (data: any) => StringOrNumber;
  formatCurrency?: (data: any) => string;
}) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getCurrency } = useFieldHelper({ source: currencySource });
  const amount = formatAmount ? formatAmount(getValue()) : (getValue() as any);
  const currency = formatCurrency ? formatCurrency(getCurrency()) : (getCurrency() as any);

  return (
    <TextField
      {...props}
      source={source}
      value={`${getWithThousandsSeparator(amount || 0)}${currency ? CURRENCIES[currency] : ''}`}
    />
  );
};

export default InvoiceTotalField;
