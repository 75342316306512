/* istanbul ignore file */
// libs
import React from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { AddressInput, DateInput, PhoneInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import LeadStatusDropdown from 'crm/lead/shared/input/LeadStatusDropdown';
import BigFormRow from 'shared/layout/form/BigFormRow';
import IndustryDropdown from 'crm/shared/input/IndustryDropdown';
import LeadSourceDropdown from 'crm/lead/shared/input/LeadSourceDropdown';
import LeadMediumDropdown from 'crm/lead/shared/input/LeadMediumDropdown';
import DisqualificationReasonDropdown from 'crm/lead/shared/input/DisqualifiactionReasonDropdown';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import LeadOwnerNotificationCheckbox from 'crm/lead/shared/input/LeadOwnerNotificationCheckbox';
import TimezoneDropdown from '../../../user/Settings/shared/input/TimezoneDropdown';
import LeadGradeDropdownWithDetails from 'crm/lead/shared/input/LeadGradeDropdownWithDetails';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import PostponedReasonDropdown from 'crm/lead/shared/input/PostponedReasonDropdown';
import CrmOwnerDropdownInput from 'crm/shared/input/CrmOwnerDropdownInput';
import useAuthorization from 'shared/authorization/authorizationService';
import { CHANGE_OWNER } from 'crm/crmService';
import { READ_FULL_LEADS_LIST } from 'crm/lead/shared/leadService';

const LeadForm = () => {
  const { isGranted } = useAuthorization();
  return (
    <>
      <FormSection title="Lead information">
        <FormRow>
          <TextInput source="title" />
          <TextInput source="firstName" />
          <TextInput source="middleName" />
          <TextInput source="lastName" />
        </FormRow>

        <FormRow>
          <TextInput source="email" />
          <TextInput source="additionalEmail" />
          <PhoneInput label="Phone" source="phoneNumber" hasExtension />
          <TextInput label="LinkedIn" source="linkedInLink" />
        </FormRow>

        <FormRow>
          <LeadStatusDropdown source="status" />
          <DisqualificationReasonDropdown
            source="disqualificationReason"
            isVisible={data => data.status === 'DISQUALIFIED'}
          />
          <ClearAfterUnmountWrapper isVisible={data => data.status === 'POSTPONED'}>
            <PostponedReasonDropdown source="postponedReason" />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={data => data.status === 'POSTPONED'}>
            <DateInput source="postponedReviewDate" />
          </ClearAfterUnmountWrapper>
        </FormRow>

        <FormRow>
          <LeadGradeDropdownWithDetails source="grade" />
          <TextArea source="gradingDetails" rows={8} isVisible={data => data.grade} />
        </FormRow>

        <FormRow>
          <CrmOwnerDropdownInput
            source="ownerAlias"
            canUserChange={isGranted(CHANGE_OWNER) || isGranted(READ_FULL_LEADS_LIST)}
            dropdownPlaceholder="Choose Lead Owner"
            label="Lead Owner"
          />
          <LeadOwnerNotificationCheckbox source="ownerNotificationEnabled" label="Notify new Owner via Teams" />
        </FormRow>

        <FormRow>
          <TextInput source="companyName" label="Company" />
          <IndustryDropdown source="industry" />
          <TextInput source="websiteAddress" label="Website" />
          <TextInput source="employeesCount" label="No. of Employees" />
        </FormRow>

        <FormRow>
          <LeadSourceDropdown source="source" />
          <LeadMediumDropdown source="medium" />
          <TextInput source="campaign" label="Marketing Campaign" />
        </FormRow>
      </FormSection>

      <FormSection title="Address information">
        <AddressInput source="address" addressLine1Label="Street" zipLabel="Zip/Postal Code">
          <TimezoneDropdown source="addressTimezone" placeholder="Select timezone" />
        </AddressInput>
      </FormSection>

      <FormSection title="Description">
        <BigFormRow>
          <TextArea source="description" rows={8} />
        </BigFormRow>
        <BigFormRow>
          <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
        </BigFormRow>
      </FormSection>

      <FormSection title="Visitor Details">
        <FormRow>
          <TextInput source="ip" label="IP Address" />
          <TextInput source="userAgent" label="User-Agent" />
          <TextInput source="httpRefer" label="HTTP Referer" />
          <TextInput source="googleAnalystClientId" label="Google Analytics Client Id" />
          <TextInput source="googleAdsClickId" label="Google Click ID" />
          <TextInput source="requestId" label="Lead Autocreation Request Id" />
        </FormRow>
      </FormSection>
    </>
  );
};

export default LeadForm;
