/* istanbul ignore file */
import React, { useMemo } from 'react';
import Grid from 'uibuilder/Grid';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import TextButton from 'uibuilder/button/TextButton';
import useInputRowWithSpecificId from './useInputRowWithSpecificId';

export type MilestoneType = {
  id: number;
  description: string;
  fromPreviousReport: boolean;
  plannedDate: string;
  currentlyPlannedDate: string;
  comments: string;
  endDate: string;
  completed: boolean;
};

interface MilestoneInputRowProps {
  data: MilestoneType;
  id: number;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

export const getMilestonesSource = (id: number) => {
  return `weeklyReportData.milestones[${id}]`;
};

const MilestoneInputRow = ({ data, id, formData, updateFormData, getErrorMessages }: MilestoneInputRowProps) => {
  const source = getMilestonesSource(id);
  const { getOnChangeCallback, removeRow } = useInputRowWithSpecificId({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'milestones',
  });

  const getAdditionalFields = (newFormData: FormFieldsData, fieldName: string) => {
    return { completed: !!newFormData[`${source}.${fieldName}`] };
  };

  const isDisabledSpecific = useMemo(() => {
    return data.fromPreviousReport && data.completed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.fromPreviousReport, data.id]);

  return (
    <Grid container spacing={2} style={id === 0 ? { alignItems: 'flex-end' } : {}}>
      <Grid item xs={12} md={1}>
        <TextInput
          source={`${source}.id`}
          label={id === 0 ? 'ID' : ''}
          value={data.id.toString()}
          disabled={id !== 0 || !formData.firstReport}
          onChangeCallback={getOnChangeCallback('id')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextButton
          source={`${source}.description`}
          label={id === 0 ? 'Description' : ''}
          value={data.description}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('description')}
          isRequired
          title="Description"
          errors={getErrorMessages(`${source}.description`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.plannedDate`}
          label={id === 0 ? 'Planned date' : ''}
          value={data.plannedDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('plannedDate')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '16%' }}>
        <DateInput
          source={`${source}.currentlyPlannedDate`}
          label={id === 0 ? 'Currently planned date' : ''}
          value={data.currentlyPlannedDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('currentlyPlannedDate')}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextButton
          source={`${source}.comments`}
          value={data.comments}
          label={id === 0 ? 'Comments' : ''}
          title="Comments"
          onChangeCallback={getOnChangeCallback('comments')}
          disabled={isDisabledSpecific}
          errors={getErrorMessages(`${source}.comments`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.endDate`}
          label={id === 0 ? 'End date' : ''}
          value={data.endDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('endDate', getAdditionalFields)}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <RemoveListItemButton
          isVisible={!data.fromPreviousReport}
          onClickCallback={removeRow}
          isFirstRow={id === 0}
          sx={{ marginTop: id ? '6px !important' : 0, marginBottom: id ? 0 : '27px' }}
        />
      </Grid>
    </Grid>
  );
};

export default MilestoneInputRow;
