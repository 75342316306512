/* istanbul ignore file */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthorization from 'shared/authorization/authorizationService';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { READ_TIMELINE } from 'erp/candidate/shared/candidateService';

const CandidateIdPage = () => {
  const { isGranted } = useAuthorization();
  const history = useHistory();
  const id = useCandidateId();
  const { getTimelineUrl, getProfileUrl } = useCandidateUrl();

  useEffect(() => {
    if (isGranted(READ_TIMELINE)) {
      history.replace(getTimelineUrl(id));
    } else {
      history.replace(getProfileUrl(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CandidateIdPage;
