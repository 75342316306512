/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EmployeeAssignmentsBreadCrumbs from 'erp/employee/assignments/shared/EmployeeAssignmentsBreadcrumbs';

const UpdateEmployeeAssignmentBreadCrumbs = () => {
  return (
    <EmployeeAssignmentsBreadCrumbs>
      <UpdateBreadcrumbItem />
    </EmployeeAssignmentsBreadCrumbs>
  );
};

export default UpdateEmployeeAssignmentBreadCrumbs;
