import React from 'react';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TextField from 'shared/uibuilder/field/TextField';
import { MILESTONES_STATUS_OPTIONS } from '../../useSowService';
import EnumField from 'shared/uibuilder/field/EnumField';
import CompletionRateField from './CompletionRateField';
import AchieveMilestoneButton from '../buttons/AchieveMilestoneButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const MilestonesListFields = ({ source }: { source: string }) => {
  const { data, setData = () => {} } = useShowContext();

  const sowId = data.getValueBySource('id');
  const milestones = data.getValueBySource(source);

  return (
    <ControlledList data={{ items: milestones, totalPages: 1, totalElements: milestones.length }}>
      <DataGridLayout isSmallTable buttons={[<AchieveMilestoneButton sowId={sowId} parentShowSetData={setData} />]}>
        <TextField source="name" label="Name" width="20%" />
        <TextField source="amount" label="Amount" width="20%" />
        <EnumField options={MILESTONES_STATUS_OPTIONS} source="status" label="Status" />
        <CompletionRateField source="completionRate" label="Completion Rate" width="20%" />
      </DataGridLayout>
    </ControlledList>
  );
};

export default MilestonesListFields;
