import useUiTheme from 'shared/uibuilder/useUiTheme';
import React from 'react';
import { PhoneFieldLayoutType } from 'shared/uibuilder/field/PhoneField';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';

const PhoneFieldLayout: PhoneFieldLayoutType = ({ phone, phoneExtension, label }) => {
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const displayValue = phone ? (
    <>
      <a href={`tel:${phone}`}>{phone}</a>
      {phoneExtension && <span className="extension">ext {phoneExtension}</span>}
    </>
  ) : null;

  return <BaseFieldLayout value={displayValue} label={label} />;
};

export default PhoneFieldLayout;
