import React, { useMemo } from 'react';
import { TextInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const AzureIdInput = props => {
  const { getValue } = useInputHelper(props);

  const isDisabled = useMemo(() => {
    return !!getValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TextInput {...props} disabled={isDisabled} />;
};

export default AzureIdInput;
