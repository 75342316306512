import { ResultResourceData } from 'shared/crud/baseCrudService';
import { isEqual } from 'lodash';

export const isPromise = (value: any) => {
  return value && value.then && typeof value.then === 'function';
};

type FunctionParams = any;
type PendingPromise = Nullable<Promise<any>>;

const pendingPromises: Dictionary<Map<FunctionParams, PendingPromise>> = {};

/**
 * This hook save active promises to avoid duplicate requests
 * @param {string} name - name of the promise for saving
 * @param {(params?: functionParams) => Promise<any>} fn
 * @return {(params?: functionParams) => Promise<any>}
 */
const useSingletonPromise = (name: string, fn: (params?: FunctionParams) => Promise<any>) => {
  const loadData = async (params?: FunctionParams) => {
    let response;
    const keyForParams = params || 'default';

    const pendingPromise = pendingPromises[name] || new Map();
    let dataPromise;

    pendingPromise?.forEach((value, key) => {
      if (isEqual(keyForParams, key)) {
        dataPromise = value;
      }
    });

    if (dataPromise && isPromise(dataPromise)) {
      response = dataPromise;
    } else {
      response = fn(params);
      pendingPromises[name] = pendingPromise.set(keyForParams, response);
    }

    return response?.then((res: ResultResourceData) => {
      pendingPromises[name] = pendingPromise.set(keyForParams, null);

      return res;
    });
  };

  return loadData;
};

export default useSingletonPromise;
