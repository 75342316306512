/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import {
  PERFORMANCE_CONFIDENCE_GRADES,
  PERFORMANCE_DETAILS_SECTIONS,
  PERFORMANCE_GRADES,
  PERFORMANCE_GRADES_ALIAS,
  PERFORMANCE_GRADES_OPTIONS,
  PerformanceSectionVersions,
} from 'erp/employee/salaries/shared/salaryService';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import FormSection from 'shared/layout/form/FormSection';
import { get } from 'lodash';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

export interface EmployeePerformanceDetailsInputProps {
  source: string;
  isCreateForm?: boolean;
}

const showConfidenceInput = (formData: any, source: string) => {
  const evaluationGrade = get(formData, `${source}.evaluationGrade`);
  return evaluationGrade && evaluationGrade !== PERFORMANCE_GRADES_ALIAS.NA;
};

const EmployeePerformanceDetailsInput = ({ source, isCreateForm }: EmployeePerformanceDetailsInputProps) => {
  const { data: formData = {} } = useFormContext();
  const displayHelpfulnessGrade = isCreateForm || formData?.helpfulnessGrade;
  const displayPerformanceDetails = formData[`${source}`]?.oldModel != null;
  const oldModel = formData[`${source}`]?.oldModel;

  return displayHelpfulnessGrade && !displayPerformanceDetails ? (
    <MediumFormRow>
      <EnumDropdown
        options={PERFORMANCE_GRADES_OPTIONS}
        source="helpfulnessGrade"
        label="Helpfulness Grade"
        placeholder="Select grade"
      />
    </MediumFormRow>
  ) : (
    <div>
      {PERFORMANCE_DETAILS_SECTIONS.filter(
        section =>
          section.version === PerformanceSectionVersions.GENERAL ||
          (oldModel
            ? section.version === PerformanceSectionVersions.OLD
            : section.version === PerformanceSectionVersions.NEW),
      ).map(section => {
        const fieldSource = `${source}.${section.field}`;
        return (
          <FormSection title={`${section.title}`} titleVariant="h5" key={section.field}>
            <MediumFormRow>
              <EnumDropdown
                options={PERFORMANCE_GRADES}
                source={`${fieldSource}.evaluationGrade`}
                label="Evaluation Grade"
                placeholder="Select grade"
              />
              <ClearAfterUnmountWrapper isVisible={data => showConfidenceInput(data, fieldSource)}>
                <EnumDropdown
                  options={PERFORMANCE_CONFIDENCE_GRADES}
                  source={`${fieldSource}.confidenceGrade`}
                  label="Confidence Grade"
                  placeholder="Select grade"
                />
              </ClearAfterUnmountWrapper>
            </MediumFormRow>
          </FormSection>
        );
      })}
    </div>
  );
};

export default EmployeePerformanceDetailsInput;
