import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useTrainingInstancesId, useTrainingInstancesUrl } from '../../TrainingInstancesRouter';
import useTrainingInstancesService from '../../useTrainingInstancesService';
import { WRITE_TRAINING_DOMAIN } from 'erp/trainings/useTrainingsService';

const DeleteTrainingInstancesButton = () => {
  const { deleteById } = useTrainingInstancesService();
  const { getListUrl } = useTrainingInstancesUrl();
  const id = useTrainingInstancesId();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(id)}
      displayMessage="Do you really want to delete this Training instance?"
      permissionToCheck={WRITE_TRAINING_DOMAIN}
      color="error"
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Training instance has been successfully deleted.',
        errorMessage: `Can't delete Training instance`,
      }}
    />
  );
};

export default DeleteTrainingInstancesButton;
