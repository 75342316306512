/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { ACCOUNT_TYPES } from 'crm/account/accountService';

const AccountTypeDropdown = props => {
  return <EnumDropdown options={ACCOUNT_TYPES} placeholder="Choose Account Type" {...props} />;
};

export default AccountTypeDropdown;
