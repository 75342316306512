/* istanbul ignore file */
import useDateService from 'shared/uibuilder/dateService/useDateService';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';
import { WASTE_REPORTING_METHOD, WASTE_TYPE } from 'erp/costaccounting/waste/useWasteService';

const useWasteValidation = isHasWithViolations => {
  const { getCurrentDateTime } = useDateService();
  const { getCurrentTimezoneDateInUtc } = useDateTimeService();
  const rescheduleMinDate = getCurrentTimezoneDateInUtc(getCurrentDateTime().subtract(2, 'weeks'));

  return {
    reason: {
      type: 'string',
      required: true,
      maxSize: 1024,
    },
    type: {
      required: true,
      type: 'string',
    },
    // Other expense waste
    otherWasteDetails: {
      type: 'object',
      properties: {
        issueDate: {
          type: 'date',
          required: {
            value: true,
            when: {
              $type: {
                eq: WASTE_TYPE.OTHER_EXPENSES_WASTE,
              },
            },
          },
          maxDate: {
            value: '$NOW',
            message: 'Cannot be in future',
          },
          minDate: {
            value: isHasWithViolations ? null : rescheduleMinDate,
            message: 'Dates are available within 2-week period',
          },
        },
        cost: {
          type: 'object',
          _divisible: false,
          properties: {
            amount: {
              type: 'string',
              required: {
                value: true,
                when: {
                  $type: {
                    eq: WASTE_TYPE.OTHER_EXPENSES_WASTE,
                  },
                },
              },
            },
            currency: {
              type: 'string',
              required: {
                value: true,
                when: {
                  $type: {
                    eq: WASTE_TYPE.OTHER_EXPENSES_WASTE,
                  },
                },
              },
            },
          },
        },
      },
    },
    // Labor Coast Waste
    laborWasteDetails: {
      type: 'object',
      properties: {
        reportingMethod: {
          type: 'string',
          required: {
            value: true,
            when: {
              $type: {
                eq: WASTE_TYPE.LABOR_COST_WASTE,
              },
            },
          },
        },
        employeeAlias: {
          type: 'string',
          required: {
            value: true,
            when: {
              $type: {
                eq: WASTE_TYPE.LABOR_COST_WASTE,
              },
              '$laborWasteDetails.reportingMethod': {
                ne: WASTE_REPORTING_METHOD.BY_TICKET_IDS,
              },
            },
          },
        },

        ticketsIds: {
          type: 'array',
          required: {
            value: true,
            when: {
              $type: {
                eq: WASTE_TYPE.LABOR_COST_WASTE,
              },
              '$laborWasteDetails.reportingMethod': {
                ne: WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD,
              },
            },
          },
        },

        projectId: {
          type: 'string',
          required: {
            value: true,
            when: {
              '$laborWasteDetails.reportingMethod': {
                eq: WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD,
              },
            },
          },
        },
        period: {
          type: 'object',
          _divisible: true,
          properties: {
            startDate: {
              type: 'date',
              required: {
                value: true,
                when: {
                  '$laborWasteDetails.reportingMethod': {
                    eq: WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD,
                  },
                },
              },
              maxDate: {
                value: '$laborWasteDetails.period.endDate',
                message: "Start date can't be later than end date",
              },
              minDate: {
                value: isHasWithViolations ? null : rescheduleMinDate,
                message: 'Dates are available within 2-week period',
              },
            },
            endDate: {
              type: 'date',
              required: {
                value: true,
                when: {
                  '$laborWasteDetails.reportingMethod': {
                    eq: WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD,
                  },
                },
              },
              minDate: {
                value: '$laborWasteDetails.period.startDate',
                message: "End date can't be earlier than start date",
              },
              maxDate: {
                value: '$NOW',
                message: 'Cannot be in future',
              },
            },
          },
        },
      },
    },
  };
};

export default useWasteValidation;
