import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import DocumentTypeField from 'erp/employee/Education/shared/field/DocumentTypeField';
import { TextField } from 'shared/uibuilder/field';
import DateField from 'shared/uibuilder/field/DateField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';

const isDocumentEmpty = document => {
  return !document || Object.keys(document).length === 0;
};

export const NO_DOCUMENT_TEXT = 'No document is provided.';

const EducationalDocumentField = props => {
  const { getValue } = useFieldHelper(props);
  const document = getValue();

  return isDocumentEmpty(document) ? (
    <SectionRow>{NO_DOCUMENT_TEXT}</SectionRow>
  ) : (
    <>
      <SectionRow>
        <DocumentTypeField label="Educational Document Type" value={document.type} />
        <TextField label="Document Number" value={document.number} />
        <DateField label="Date of Issue" value={document.dateOfIssue} />
      </SectionRow>
      <BigSectionRow>
        <AttachmentsListField value={document.scansIds} label="Document scans" />
      </BigSectionRow>
    </>
  );
};

export default EducationalDocumentField;
