import React, { useEffect, useMemo } from 'react';
import './ProcessDiagram.scss';
import useProcessDiagramHelper from 'camunda/monitoring/facade/diagram/useProcessDiagramHelper';
import { BpmnDiagramService } from 'camunda/monitoring/facade/useBpmnJs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { ProcessInstance } from 'camunda/monitoring/instance/processInstanceService';

interface ProcessDiagramProps {
  instanceId: string;
  bpmnJs: BpmnDiagramService;
}

const ProcessDiagram = ({ bpmnJs }: ProcessDiagramProps) => {
  const { Diagram, loadXml } = bpmnJs;
  const { highlightPassedActivities, addTokens, addDueDates, addEventListeners } = useProcessDiagramHelper(bpmnJs);

  const { data: instance } = useShowContext();

  useEffect(() => {
    if (instance.getValueBySource('xml')) {
      loadXml(instance.getValueBySource('xml')).then(() => {
        highlightPassedActivities(instance.getData() as ProcessInstance);
        addTokens(instance.getData() as ProcessInstance);
        addDueDates(instance.getData() as ProcessInstance);
        addEventListeners();
      });
    }
  }, [instance.getValueBySource('xml')]); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => <Diagram className="process-diagram" />, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default ProcessDiagram;
