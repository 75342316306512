import React, { useMemo } from 'react';
import { get } from 'lodash';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { FieldProps, BaseFieldLayoutType } from 'shared/uibuilder/field';
import { ListItem, useListContext } from 'shared/uibuilder/list/ListContext';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const calculateTotalValue = (items: ListItem[], source: string) => {
  return items.reduce((result, item) => {
    return result + (+get(item, source) || 0);
  }, 0);
};

const ListColumnTotalField = ({
  source = '',
  value,
  ...props
}: FieldProps<(totalValue: number) => React.ReactElement>) => {
  const { data: { items = [] } = {} } = useListContext();
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();
  const { getLabel } = useFieldHelper({ ...props, source });

  const total = useMemo(() => calculateTotalValue(items, source), [items, source]);
  const displayedValue = value ? value(total) : null;

  return <BaseFieldLayout {...props} label={getLabel()} value={displayedValue} />;
};

export default ListColumnTotalField;
