import React, { useCallback, useMemo, useState } from 'react';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { DateInput } from 'shared/uibuilder/form/input';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import useExpenseService, { RUN_EXPENSES_MIGRATION } from '../../useExpenseService';
import { snakeCase } from 'lodash';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';

const MarkPayrollExpensesPaidButton = () => {
  const { markPayrollExpensesPaid } = useExpenseService();
  const [errors, setErrors] = useState<Dictionary<string[]>>({});
  const [values, setValues] = useState<any>({});
  const { getToday } = useDateService();
  const currentMonth = getToday().endOf('month');

  const minPaymentDate = useMemo(() => {
    return values.period
      ? moment(values.period, DATE_FORMAT.API_ONLY_MONTH, true)
          .startOf('month')
          .format(DATE_FORMAT.API_ONLY_DATE)
      : null;
  }, [values.period]);

  const maxPaymentDate = useMemo(() => {
    return values.period
      ? moment(values.period, DATE_FORMAT.API_ONLY_MONTH, true)
          .add(1, 'M')
          .endOf('month')
          .format(DATE_FORMAT.API_ONLY_DATE)
      : null;
  }, [values.period]);

  const onDatePeriodChange = useCallback(
    (value: FormFieldsData) => {
      setValues((prevValues: any) => ({ ...prevValues, period: value.period, payoutDate: null }));
      const isValid = moment(value.period, DATE_FORMAT.API_ONLY_MONTH, true).isValid();

      if (!value.period) {
        setErrors(prevErrors => ({ ...prevErrors, period: ['Field is required'] }));
      } else if (!isValid) {
        setErrors(prevErrors => ({ ...prevErrors, period: ['Please, choose the correct date'] }));
      } else if (moment(value.period).isAfter(currentMonth)) {
        setErrors(prevErrors => ({ ...prevErrors, period: ["Please, choose a date before or today's date"] }));
      } else {
        setErrors(prevErrors => ({ ...prevErrors, period: [] }));
      }
    },
    [currentMonth],
  );

  const onDateChange = useCallback(
    (value: FormFieldsData) => {
      setValues((prevValues: any) => ({ ...prevValues, payoutDate: value.payoutDate }));
      const momentData = moment(value.payoutDate, DATE_FORMAT.API_ONLY_DATE, true);
      const isValid = momentData.isValid();

      if (!value.payoutDate) {
        setErrors(prevErrors => ({ ...prevErrors, payoutDate: ['Field is required'] }));
      } else if (!isValid) {
        setErrors(prevErrors => ({ ...prevErrors, payoutDate: ['Please, choose the correct date'] }));
      } else if (!momentData.isBetween(minPaymentDate, maxPaymentDate, undefined, '[]')) {
        setErrors(prevErrors => ({ ...prevErrors, payoutDate: ['Please, choose the correct date in the period'] }));
      } else {
        setErrors(prevErrors => ({ ...prevErrors, payoutDate: [] }));
      }
    },
    [maxPaymentDate, minPaymentDate],
  );

  const onChangeOffice = useCallback(({ office }: { office: string }) => {
    setValues((prevValues: any) => ({ ...prevValues, office }));
  }, []);

  const onSubmit = useCallback(async () => {
    return markPayrollExpensesPaid(values);
  }, [markPayrollExpensesPaid, values]);

  return (
    <ButtonWithConfirmation
      displayMessage="Are you sure you want to mark payroll expenses as paid? This action cannot be undone. Please, Fill out inputs below."
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="period"
            value={values.period as any}
            label="Period"
            isRequired
            dateFormat={DATE_FORMAT.API_ONLY_MONTH}
            onChangeCallback={onDatePeriodChange}
            errorMessages={errors.period || []}
            maxDate={currentMonth}
          />
          <DateInput
            source="payoutDate"
            value={values.payoutDate as any}
            label="Payout Date"
            onChangeCallback={onDateChange}
            errorMessages={errors.payoutDate || []}
            isRequired
            minDate={minPaymentDate}
            maxDate={maxPaymentDate}
            disabled={!values.period}
          />
          <OfficeDropdown
            mapResults={(office: any) => ({
              value: snakeCase(office.name).toUpperCase(),
              label: OFFICE_NAMES[office.name],
            })}
            value={values.office}
            onChangeCallback={onChangeOffice}
            source="office"
            label="Office"
          />
        </div>
      }
      submitMethod={onSubmit}
      afterSubmit={{
        successMessage: 'The payroll expenses was mark as paid successful.',
        errorMessage: "Can't mark payroll expenses paid.",
      }}
      permissionToCheck={RUN_EXPENSES_MIGRATION}
      variant="outlined"
      confirmBtnProps={{
        disabled:
          !values.period || !values.office || !values.payoutDate || Object.values(errors).some(error => error.length),
      }}
    >
      Mark Payroll Expenses Paid
    </ButtonWithConfirmation>
  );
};

export default MarkPayrollExpensesPaidButton;
