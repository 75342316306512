// services
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useKernelApi } from 'api';

// constants
export const READ_BOOKS_PATH = 'books';
export const VIEW_EMPLOYEE_BOOKS = 'VIEW_EMPLOYEE_BOOKS';

export const useReadBooksService = () => {
  const { sendPostRequest } = useKernelApi();
  const currentAlias = useEmployeeId();

  const findAllEmployeeReadBooks = async () => {
    const url = `/employees/${currentAlias}/${READ_BOOKS_PATH}/search`;
    const response = await sendPostRequest(url, {});
    const bookListResponse = await response.json();

    return bookListResponse;
  };

  return {
    findAllEmployeeReadBooks,
  };
};
