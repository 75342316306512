export default {
  period: {
    type: 'string',
    required: true,
  },
  office: {
    type: 'string',
    required: true,
  },
  file: {
    type: 'array',
    required: true,
    maxSize: 1,
  },
};
