import { useFinanceApi } from 'api';
import useCrudService, { CrudService } from 'shared/crud';

export const ACCOUNTING_CYCLE_PATH = '/accounting-cycles';
export const ACCOUNTING_CYCLE_RESOURCE_URL = '/accounting-cycles';

export const GET_ACCOUNTING_CYCLE = 'COMPANY_GET_ACCOUNTING_CYCLE';
export const CLOSE_ACCOUNTING_CYCLE = 'COMPANY_CLOSE_ACCOUNTING_CYCLE';

export const ACCOUNTING_CYCLE_EVENTS = {
  COST_PRORATING: 'COST_PRORATING',
  PAYROLL_DISTRIBUTION: 'PAYROLL_DISTRIBUTION',
  ACCRUE_REVENUE: 'ACCRUE_REVENUE',
  RECONCILIATION: 'RECONCILIATION',
  COMPUTE_PERFORMANCE_BASED_BONUS: 'COMPUTE_PERFORMANCE_BASED_BONUS',
};

export const ACCOUNTING_CYCLE_EVENTS_OPTIONS = {
  [ACCOUNTING_CYCLE_EVENTS.COST_PRORATING]: 'Cost prorating',
  [ACCOUNTING_CYCLE_EVENTS.PAYROLL_DISTRIBUTION]: 'Payroll distribution',
  [ACCOUNTING_CYCLE_EVENTS.ACCRUE_REVENUE]: 'Accrue revenue',
  [ACCOUNTING_CYCLE_EVENTS.RECONCILIATION]: 'Reconciliation',
  [ACCOUNTING_CYCLE_EVENTS.COMPUTE_PERFORMANCE_BASED_BONUS]: 'Performance based bonus',
} as const;

export const ACCOUNTING_CYCLE_STATUS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
} as const;

export const ACCOUNTING_CYCLE_OPTIONS = {
  [ACCOUNTING_CYCLE_STATUS.OPEN]: 'Open',
  [ACCOUNTING_CYCLE_STATUS.CLOSE]: 'Close',
} as const;

export type AccountingCycleEventType = {
  id: number;
  cycleId: string;
  eventType: keyof typeof ACCOUNTING_CYCLE_EVENTS;
  triggeredBy: string;
  createdAt: string;
};

export type AccountingCycleType = {
  id: string;
  events: AccountingCycleEventType[];
  status: keyof typeof ACCOUNTING_CYCLE_STATUS;
  closedBy: string | null;
  closedAt: string | null;
};

const useAccountingCycle = () => {
  const { sendPostRequest } = useFinanceApi();

  const { ...baseCrud }: CrudService = useCrudService({
    singleResourceUrl: `${ACCOUNTING_CYCLE_RESOURCE_URL}/:id`,
    listResourceUrl: ACCOUNTING_CYCLE_RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const closeAccountingCycle = async (id: string) => {
    await sendPostRequest(`${ACCOUNTING_CYCLE_RESOURCE_URL}/${id}/close`);
  };

  const accrueRevenue = async (period: string) => {
    return sendPostRequest('/revenue/accrue', { period });
  };

  return {
    ...baseCrud,
    closeAccountingCycle,
    accrueRevenue,
  };
};

export default useAccountingCycle;
