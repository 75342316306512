import { useEffect, useState } from 'react';

export const MAX_SM_WIDTH = 600;
export const MAX_MD_WIDTH = 991;
export const MAX_LG_WIDTH = 1194;

const useDeviceDetectorService = ({ maxWidth = MAX_MD_WIDTH, defaultValue = true } = {}) => {
  const [isDesktop, setIsDesktop] = useState(defaultValue);

  const updateScreenType = () => {
    const isDesktopScreenSize = window.innerWidth > maxWidth;

    setIsDesktop(isDesktopScreenSize);
  };

  useEffect(() => {
    updateScreenType();
    window.addEventListener('resize', updateScreenType);

    return () => {
      window.removeEventListener('resize', updateScreenType);
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isDesktop,
  };
};

export default useDeviceDetectorService;
