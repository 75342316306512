import React from 'react';
import useProjectService from 'erp/project/shared/projectService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';

export interface CustomerInvoiceSowIdDropdownProps {
  source: string;
  label?: string;
  showLoader?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isVisible?: boolean | ((data: any) => boolean);
  loadDataMethod?: () => ResultResourceData;
  mapResults?: (data: any) => Option;
}

const CustomerInvoiceSowIdDropdown = ({
  loadDataMethod,
  mapResults: mapResultsFromProps,
  ...props
}: CustomerInvoiceSowIdDropdownProps) => {
  const { searchAllSuggestions } = useProjectService();
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  const mapResults = (project: ResultResourceData) => ({
    value: project.sowId,
    label: `${project.projectName} (SOW Number: ${project.sowId})`,
  });

  const loadProjects = async () => {
    const projects = await searchAllSuggestions({});

    return {
      result: projects,
    };
  };

  const onChangeCallback = (values: any) => {
    const sowId = values[props.source];

    if (rawOnChangeCallback) {
      rawOnChangeCallback({ sowId });
    }
  };

  return (
    <LoadDropdown
      loadDataMethod={loadProjects}
      mapResults={mapResultsFromProps || mapResults}
      onChangeCallback={onChangeCallback}
      placeholder="Select SOW ID"
      {...props}
    />
  );
};

export default CustomerInvoiceSowIdDropdown;
