/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useServiceUrl } from 'erp/service/ServiceRouter';

const EditServiceButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useServiceUrl();
  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditServiceButton;
