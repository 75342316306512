/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import HandbookPageLayout from 'handbook/Show/HandbookPage/HandbookPageLayout';
import useHandbookService from 'handbook/handbookService';

const HandbookPage = () => {
  const { search } = useHandbookService();

  return (
    <List getDataMethod={search}>
      <HandbookPageLayout />
    </List>
  );
};

export default HandbookPage;
