import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import useRoomService from 'erp/room/useRoomService';
import List from 'shared/uibuilder/list/List';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import RoomLink from 'erp/room/shared/field/RoomLink';
import React from 'react';
import ViewRoomButton from 'erp/room/shared/buttons/ViewRoomButton';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import CreateRoomButton from 'erp/room/shared/buttons/CreateRoomButton';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import RoomTypeField from 'erp/room/shared/field/RoomTypeField';
import OfficeDropdownFilter from 'erp/contracts/List/filter/OfficeDropdownFilter';

const filter = (
  <Filters>
    <FiltersLayout
      quickFilters={[
        <QuickFilter key="austin-rooms" label="Austin" state={{ 'office.id': { eq: '1' } }} />,
        <QuickFilter key="krakow-rooms" label="Krakow" state={{ 'office.id': { eq: '3' } }} />,
        <QuickFilter key="minsk-rooms" label="Minsk" state={{ 'office.id': { eq: '2' } }} />,
        <QuickFilter key="tbilisi-rooms" label="Tbilisi" state={{ 'office.id': { eq: '4' } }} />,
      ]}
    >
      <OfficeDropdownFilter source="office.id" label="Office" />
    </FiltersLayout>
  </Filters>
);
const RoomListPage = () => {
  const { search } = useRoomService();

  return (
    <List
      getDataMethod={search}
      defaultSortField="roomNumber"
      defaultSortOrder="ASC"
      defaultFilter={{ 'office.id': { eq: '2' } }}
    >
      <ListPageLayout header={<ListHeader label="Rooms" />} filter={filter} actions={[<CreateRoomButton />]}>
        <DataGridLayout buttons={[<ViewRoomButton source="id" />]}>
          <OfficeField source="office.name" label="Office" isSortable />
          <RoomLink source="id" nameSource="roomNumber" label="Room" isSortable isClickable />
          <RoomTypeField source="type" label="Type" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default RoomListPage;
