/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import { element } from 'prop-types';
import { useProjectId } from 'erp/project/ProjectRouter';
import useProjectService from 'erp/project/shared/projectService';

const ShowProject = ({ children }) => {
  const { getById } = useProjectService();
  const currentProjectId = useProjectId();

  return <Show getDataMethod={() => getById(currentProjectId)}>{children}</Show>;
};

ShowProject.propTypes = {
  children: element.isRequired,
};

export default ShowProject;
