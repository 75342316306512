/* istanbul ignore file */
import React, { useState } from 'react';
import {
  MARK_GOODS_AND_SERVICES_DELIVERED,
  MARK_GOODS_AND_SERVICES_DELIVERED_PARTIALLY,
  GOODS_AND_SERVICES_DELIVERY_STATUS,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { AfterSubmitProps } from 'shared/uibuilder/form/formHelper';
import { Checkbox, DateInput } from 'shared/uibuilder/form/input';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useInvoiceService, { INVOICE_STATUSES } from '../../useInvoiceService';

const MarkAsDeliveredGoodsAndServicesButton = ({
  afterSubmit,
  invoiceIdSource,
  deliveryStatusSource,
  invoiceStatusSource,
  futureExpenseSource,
  ...props
}: {
  invoiceIdSource: string;
  deliveryStatusSource: string;
  invoiceStatusSource: string;
  futureExpenseSource: string;
  isVisible?: boolean | ((data: any) => boolean);
  afterSubmit?: AfterSubmitProps<(response: object) => void>;
}) => {
  const { getToday, formatDateForAPI } = useDateService();
  const [goodsAndServicesDeliveryDate, setGoodsAndServicesDeliveryDate] = useState(getToday());
  const [isPartialDelivery, setPartialDelivery] = useState(false);
  const [partialDeliverySum, setPartialDeliverySum] = useState<number>();
  const [partialDeliverySumErrors, setPartialDeliverySumErrors] = useState<string[]>([]);
  const [dateDateErrors, setDateDateErrors] = useState<string[]>([]);
  const { markAsDeliveredGoodsAndServices } = useInvoiceService();
  const { getValue: getLinkedInvoiceId } = useFieldHelper({ source: invoiceIdSource });
  const { getValue: getDeliveryStatus } = useFieldHelper({ source: deliveryStatusSource });
  const { getValue: getInvoiceStatus } = useFieldHelper({ source: invoiceStatusSource });
  const { getValue: getFutureExpense } = useFieldHelper({ source: futureExpenseSource });
  const deliveryStatus = getDeliveryStatus();
  const linkedInvoiceId = getLinkedInvoiceId();
  const invoiceStatus = getInvoiceStatus();
  const isFutureExpense = getFutureExpense();

  if (
    !isFutureExpense ||
    deliveryStatus === GOODS_AND_SERVICES_DELIVERY_STATUS.DELIVERED ||
    (invoiceStatus !== INVOICE_STATUSES.PAID && invoiceStatus !== INVOICE_STATUSES.PARTIALLY_PAID)
  ) {
    return null;
  }

  const onDateChange = (values: FormFieldsData) => {
    setGoodsAndServicesDeliveryDate(values.goodsAndServicesDeliveryDate);
    const isValid = moment(values.goodsAndServicesDeliveryDate, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.goodsAndServicesDeliveryDate) {
      setDateDateErrors(['Field is required']);
    } else if (!isValid) {
      setDateDateErrors(['Please, choose the correct date']);
    } else {
      setDateDateErrors([]);
    }
  };

  const onCheckboxChange = (values: FormFieldsData) => {
    if (!values.isPartialDelivery) {
      setPartialDeliverySum(undefined);
      setPartialDeliverySumErrors([]);
    }
    setPartialDelivery(values.isPartialDelivery);
  };

  const onPartialDeliverySum = (values: FormFieldsData) => {
    setPartialDeliverySum(values.partialDeliverySum);

    if (values.partialPaymentSum <= 0) {
      setPartialDeliverySumErrors(['The payment sum must be greater than 0.']);
    } else {
      setPartialDeliverySumErrors([]);
    }
  };

  const clearFieldData = () => {
    setGoodsAndServicesDeliveryDate(getToday());
    setPartialDelivery(false);
    setPartialDeliverySum(undefined);
    setPartialDeliverySumErrors([]);
    setDateDateErrors([]);
  };

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to mark this invoice as delivered? Please fill out the inputs below."
      submitMethod={() =>
        markAsDeliveredGoodsAndServices(linkedInvoiceId, {
          partialDeliverySum,
          goodsAndServicesDeliveryDate: formatDateForAPI(goodsAndServicesDeliveryDate),
          isPartialDelivery,
        })
      }
      afterSubmit={{
        successMessage: 'The Invoice has been successfully marked as delivered.',
        errorMessage: "Can't mark invoice as delivered.",
        execute: (response: any) => {
          clearFieldData();
          if (afterSubmit?.execute) {
            afterSubmit?.execute(response);
          }
        },
      }}
      inputLabel="Delivery date"
      permissionToCheck={[MARK_GOODS_AND_SERVICES_DELIVERED, MARK_GOODS_AND_SERVICES_DELIVERED_PARTIALLY]}
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="goodsAndServicesDeliveryDate"
            value={goodsAndServicesDeliveryDate as any}
            label="Delivery date"
            onChangeCallback={onDateChange}
            errorMessages={dateDateErrors}
            isRequired
          />
          <>
            <Checkbox
              source="isPartialDelivery"
              value={isPartialDelivery}
              label="Partial Delivery"
              onChangeCallback={onCheckboxChange}
            />

            <NumberInput
              label="Partial Delivery Sum"
              source="partialDeliverySum"
              value={partialDeliverySum}
              onChangeCallback={onPartialDeliverySum}
              isVisible={isPartialDelivery}
              errorMessages={partialDeliverySumErrors}
              isRequired
            />
          </>
        </div>
      }
      confirmBtnProps={{
        disabled:
          !goodsAndServicesDeliveryDate ||
          (isPartialDelivery && !partialDeliverySum) ||
          partialDeliverySumErrors.length > 0 ||
          dateDateErrors.length > 0,
      }}
      {...props}
    >
      Mark as delivered
    </ButtonWithConfirmation>
  );
};

export default MarkAsDeliveredGoodsAndServicesButton;
