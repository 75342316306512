/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useAssetUrl } from 'financialAnalytic/assets/AssetRouter';
import AssetBreadcrumbs from 'financialAnalytic/assets/shared/AssetBreadcrumbs';

const SingleAssetBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useAssetUrl();
  const id = data?.getValueBySource('inventoryNumber');
  const assetNumber = data?.getValueBySource('inventoryNumber');

  return (
    <AssetBreadcrumbs>
      <SingleBreadcrumbItem entity={assetNumber || id} link={getSingleEntityUrl(id)} />
      {children}
    </AssetBreadcrumbs>
  );
};

export default SingleAssetBreadcrumbs;
