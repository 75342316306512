/* istanbul ignore file */
import React from 'react';
import FormInputFactory, { FormInputType } from 'shared/uibuilder/form/Builder/FormInputFactory';
import FormRowFactory, { FormRowType } from 'shared/uibuilder/form/Builder/FormRowFactory';
import FormSectionFactory, { FormSectionType } from 'shared/uibuilder/form/Builder/FormSectionFactory';

interface FormInput {
  type?: FormInputType;
  label?: string;
  source?: string;
}

interface FormRow {
  type: FormRowType;
  fields: Array<FormInput>;
}

interface FormSection {
  type: FormSectionType;
  title?: string;
  rows: Array<FormRow>;
}

export interface FormInputs {
  sections: Array<FormSection>;
}

type FormBuilderProps = {
  formInputs: FormInputs;
};

const FormBuilder = ({ formInputs }: FormBuilderProps) => {
  return (
    <>
      {formInputs.sections.map((section, sectionIndex) => {
        const { type, rows, ...props } = section;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <FormSectionFactory key={sectionIndex} type={type} props={props}>
            <>
              {rows.map((row, rowIndex) => {
                const { type: rowType, fields, ...rowProps } = row;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <FormRowFactory key={rowIndex} type={rowType} props={rowProps}>
                    {fields.map(field => {
                      const { type: fieldType, ...fieldProps } = field;
                      return <FormInputFactory key={fieldProps.source} type={fieldType} props={fieldProps} />;
                    })}
                  </FormRowFactory>
                );
              })}
            </>
          </FormSectionFactory>
        );
      })}
    </>
  );
};

export default FormBuilder;
