/* istanbul ignore file */
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import React, { useMemo } from 'react';
import CandidatesBreadcrumbs from 'erp/candidate/shared/CandidatesBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { arrayOf, element, oneOfType } from 'prop-types';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const SingleCandidateBreadcrumbs = ({ children }) => {
  const { data } = useShowContext();
  const { getProfileUrl } = useCandidateUrl();
  const id = useCandidateId();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedName = useMemo(() => data.getValueBySource('name.fullName'), []);

  return (
    <CandidatesBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getProfileUrl(id)} />
      {children}
    </CandidatesBreadcrumbs>
  );
};

SingleCandidateBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleCandidateBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleCandidateBreadcrumbs;
