/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { useSensitiveDataContext } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';

const BankTransactionsField = ({
  isSensitiveDataIsShown,
  ...props
}: FieldProps & { isSensitiveDataIsShown?: boolean }) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const transfers = getValue() as any;
  const sensitiveDataContext = useSensitiveDataContext();

  const displayedContacts = transfers?.map((transfer: Dictionary<any>) => {
    const { id, title, vendor, date } = transfer;
    const amount = sensitiveDataContext.getValueByIdAndSource('bankTransactionSubTotals', id) || 0;

    return (
      <div key={id}>
        {date ? (
          <>
            <ShowDate dateUTC={date} format={DATE_FORMAT.SIMPLE} isDateTime={false} />
            {isSensitiveDataIsShown ? ', ' : ' - '}
          </>
        ) : null}
        {isSensitiveDataIsShown ? `${getWithThousandsSeparator(amount)}$ - ` : null}
        {[title, vendor].filter(Boolean).join(', ')}
      </div>
    );
  });

  return <TextField value={displayedContacts} label={getLabel()} isColumnDirection={transfers?.length > 1} />;
};

export default BankTransactionsField;
