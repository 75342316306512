import { useKernelApi } from 'api';

const useBudgetReportService = () => {
  const { sendPostRequest } = useKernelApi();

  const getReport = async (request: any): Promise<any> => {
    return sendPostRequest('/budgets/report', request);
  };

  return {
    getReport,
  };
};

export default useBudgetReportService;
