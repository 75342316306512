/* istanbul ignore file */
import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import useAuthorization from 'shared/authorization/authorizationService';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import EditIcon from '@mui/icons-material/EditOutlined';
import 'erp/employee/newQualification/shared/button/EditCompetencyStatusButton.scss';
import ChangeCompetencyStatusForm from 'erp/employee/newQualification/createupdate/Update/ChangeCompetencyStatusForm';

const EditCompetencyStatusButton = ({
  permissionToCheck,
  competencyLevel,
  levelId,
  levelTitle,
}: {
  levelId: StringOrNumber;
  competencyLevel: any;
  levelTitle: string;
  permissionToCheck?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const closeModal = () => setModalOpen(false);

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <>
      <Button outline size="small" className="edit-competency-status-btn" onClick={() => setModalOpen(true)}>
        <EditIcon />
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={
          <>
            Change the current status for the competency &quot;{levelTitle}&quot; L{competencyLevel.levelNumber}
          </>
        }
        onToggle={closeModal}
        hasCancelButton={false}
        className="qualification-modal"
      >
        <ChangeCompetencyStatusForm
          modalOpen={modalOpen}
          closeModal={closeModal}
          competencyLevel={competencyLevel}
          levelId={levelId}
        />
      </ModalWindow>
    </>
  ) : null;
};

export default EditCompetencyStatusButton;
