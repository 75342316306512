/* istanbul ignore file */
import React from 'react';
import { useEmployeeOfferId, useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import useEmployeeOfferService from 'erp/employee/offers/shared/employeeOfferService';
import SensitiveShowPageLayout from 'shared/uibuilder/sensitiveData/SensitiveShowPageLayout';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import ShowEmployeeOfferBreadcrumbs from 'erp/employee/offers/Show/ShowEmployeeOfferBreadcrumbs';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import OfferCompensationDetailsField from 'erp/candidate/offercompensation/shared/field/OfferCompensationDetailsField';
import Show from 'shared/uibuilder/show/Show';
import EditEmployeeOfferButton from 'erp/employee/offers/shared/buttons/EditEmployeeOfferButton';
import { DELETE, UPDATE } from 'crm/crmService';
import DeleteEmployeeOfferButton from 'erp/employee/offers/shared/buttons/DeleteEmployeeOfferButton';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import BooleanField from 'shared/uibuilder/field/BooleanField';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import DateField from 'shared/uibuilder/field/DateField';

const ViewEmployeeOfferPage = () => {
  const employeeOfferId = useEmployeeOfferId();
  const { getById } = useEmployeeOfferService();
  const { getListUrl } = useEmployeeOfferUrl();

  return (
    <SensitiveShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<ShowEmployeeOfferBreadcrumbs />}
      buttons={[
        <EditEmployeeOfferButton
          key="edit-employee-offer"
          icon={<i className="fa fa-edit" />}
          permissionToCheck={UPDATE}
        />,
        <DeleteEmployeeOfferButton key="delete-employee-offer" permissionToCheck={DELETE} />,
      ]}
      timerId={getListUrl()}
    >
      <Show getDataMethod={() => getById(employeeOfferId)}>
        <ShowSection title="Offer Information">
          <SectionRow>
            <BooleanField source="isAccepted" label="Offer Accepted" />
            <DateField source="createdAt" label="Created At" />
            <DateField source="updatedAt" label="Last Modified" />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Offer Compensation Details">
          <FieldListLayout source="offerCompensations" hasSeparator>
            <OfferCompensationDetailsField />
          </FieldListLayout>
        </ShowSection>
        <BigSectionRow>
          <AttachmentsListField source="attachmentIds" label="Attachments" />
        </BigSectionRow>
      </Show>
    </SensitiveShowPageLayout>
  );
};

export default ViewEmployeeOfferPage;
