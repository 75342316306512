import getAzureDomain from 'erp/config/emailConfiguration';

const domainName = getAzureDomain();

export const EMPLOYEE_FORM_VALIDATION = {
  firstNameEn: {
    type: 'string',
    required: true,
    regex: {
      value: '^[a-zA-Z-]+$',
      message: 'Only latin characters and hyphen',
    },
  },
  lastNameEn: {
    type: 'string',
    required: true,
    regex: {
      value: '^[a-zA-Z-]+$',
      message: 'Only latin characters and hyphen',
    },
  },
  workingEmail: {
    type: 'string',
    email: true,
    maxSize: 255,
    required: true,
    regex: {
      value: `^.+@${domainName}$`,
      message: `Email must be within ${domainName} domain`,
    },
  },
  positionId: {
    type: 'string',
    required: true,
  },
  privateNotePassword: {
    type: 'string',
    required: true,
  },
};

export const INTERN_FORM_VALIDATION = {
  firstNameEn: {
    type: 'string',
    required: true,
    regex: {
      value: '^[a-zA-Z-]+$',
      message: 'Only latin characters and hyphen',
    },
  },
  lastNameEn: {
    type: 'string',
    required: true,
    regex: {
      value: '^[a-zA-Z-]+$',
      message: 'Only latin characters and hyphen',
    },
  },
  workingEmail: {
    type: 'string',
    email: true,
    maxSize: 255,
    required: true,
    regex: {
      value: `^.+@${domainName}$`,
      message: `Email must be within ${domainName} domain`,
    },
  },
  privateNotePassword: {
    type: 'string',
    required: true,
  },
};
