/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { UpdateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import { useEmployeeDomainsService } from 'erp/employee/domains/shared/employeeDomainsService';
import EmployeeDomainForm from 'erp/employee/domains/shared/EmployeeDomainForm';

const MESSAGE_CONTEXT = 'editEmployeeDomainForm';

interface EditEmployeeDomainModalFormProps {
  closeModal: () => void;
  id: string;
}

const EditEmployeeDomainModalForm = ({ id, closeModal }: EditEmployeeDomainModalFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { editEmployeeDomain, getById } = useEmployeeDomainsService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Edit domain experience
    </Typography>
  );

  return (
    <UpdateForm
      entityId={id}
      submitFormFunc={editEmployeeDomain}
      messageContext={MESSAGE_CONTEXT}
      afterSubmit={{
        execute: useCallback(() => {
          closeForm();
        }, [closeForm]),
      }}
      getDataFunc={getById}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <EmployeeDomainForm handleCancelClick={closeForm} />
      </ModalWindow>
    </UpdateForm>
  );
};

export default EditEmployeeDomainModalForm;
