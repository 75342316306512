/* istanbul ignore file */
import React from 'react';
import useExpenseService, { COMPUTE_PERFORMANCE_BASED_BONUS } from 'financialAnalytic/expenses/useExpenseService';
import ButtonWithConfirmationDateInput from 'financialAnalytic/shared/button/ButtonWithConfirmationDateInput';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const ComputeBonusesButton = ({ ...props }) => {
  const { computeBonuses } = useExpenseService();
  const { reloadData } = useListContext();

  return (
    <ButtonWithConfirmationDateInput
      displayMessage="Do you really want to compute bonuses? Please fill out the inputs below."
      submitMethod={computeBonuses}
      afterSubmit={{
        successMessage: 'The Bonuses have been successfully computed.',
        errorMessage: "Can't compute bonuses.",
        execute: () => {
          if (reloadData) {
            reloadData();
          }
        },
      }}
      inputLabel="Compute Date"
      permissionToCheck={COMPUTE_PERFORMANCE_BASED_BONUS}
      {...props}
    >
      Compute Bonuses
    </ButtonWithConfirmationDateInput>
  );
};

export default ComputeBonusesButton;
