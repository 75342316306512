/* istanbul ignore file */

export default {
  instanceId: {
    type: 'string',
    required: true,
  },
  reason: {
    type: 'string',
    required: true,
  },
};
