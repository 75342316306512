/* istanbul ignore file */
import React from 'react';
import useSyberryTodayProjectService from 'syberryToday/projects/useSyberryTodayProjectService';
import SyberryTodayProjectQueues from 'syberryToday/projects/List/SyberryTodayProjectQueues';
import DndProvider from 'shared/uibuilder/DndProvider';
import SyberryTodayProvider from 'syberryToday/SyberryTodayProvider';
import Loading from 'shared/uibuilder/Loading';

const SyberryTodayProject = () => {
  const { projects } = useSyberryTodayProjectService();

  if (projects === null) {
    return <Loading />;
  }

  return (
    <DndProvider>
      <SyberryTodayProvider projects={projects}>
        <SyberryTodayProjectQueues />
      </SyberryTodayProvider>
    </DndProvider>
  );
};

export default SyberryTodayProject;
