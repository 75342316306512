/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { EDIT_EMPLOYEE_PAGE_PERMISSIONS } from 'erp/employee/employeeService';
import useAuthorization from 'shared/authorization/authorizationService';

const EditEmployeeButton = props => {
  const { getUpdateEntityUrl } = useEmployeeUrl();
  const { isGranted } = useAuthorization();

  return isGranted(EDIT_EMPLOYEE_PAGE_PERMISSIONS) ? (
    <EditButton path={getUpdateEntityUrl} key="edit-employee" {...props} />
  ) : null;
};

export default EditEmployeeButton;
