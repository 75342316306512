/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import AssetStatusDropdown from 'financialAnalytic/assets/shared/input/AssetStatusDropdown';

const AssetStatusDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <AssetStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default AssetStatusDropdownFilter;
