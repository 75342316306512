/* istanbul ignore file */
import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import Box from 'uibuilder/Box';
import { Button, SubmitButton } from 'uibuilder/button';

const ModificationTokenButtonLayout = ({ handleCleanClick }) => {
  const { submitForm = () => {}, isSubmitEnabled } = useFormContext();

  return (
    <>
      <Box className="form-buttons-wrapper mt-5">
        <Box className="btn-area" sx={{ minWidth: '200px', '&:not(:last-child)': { marginBottom: '10px' } }}>
          <SubmitButton key="submit-modal-btn" onClick={submitForm} disabled={!isSubmitEnabled} />
          <Button outline key="cancel-modal-btn" onClick={handleCleanClick}>
            Clean
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ModificationTokenButtonLayout;
