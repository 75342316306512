/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EmployeeLinkField } from 'erp/employee';
import useVacancyService, {
  COMPETENCY_LEVEL,
  VACANCY_DUE_DATE,
  VACANCY_POSITIONS,
  VACANCY_STATUSES,
  VACANCY_TYPES,
} from 'erp/recruitment/vacancy/shared/vacancyService';
import { CommaListField, EnumField, HtmlField, TextField } from 'shared/uibuilder/field';
import { useVacancyId } from 'erp/recruitment/vacancy/VacancyRouter';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import ExpectedCompensationField from '../shared/field/ExpectedCompensationField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import NameDescriptionField from 'erp/recruitment/vacancy/shared/field/NameDescriptionField';
import VacancyDetailsField from 'erp/recruitment/vacancy/shared/field/VacancyDetailsField';
import VacancyPublicationListLinkField from 'erp/recruitment/vacancy/publication/shared/field/VacancyPublicationListLinkField';
import ShowVacancyPageLayout from 'erp/recruitment/vacancy/Show/ShowVacancyPageLayout';

const ViewVacancy = () => {
  const id = useVacancyId();
  const { getById } = useVacancyService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowVacancyPageLayout>
        <ShowSection title="Vacancy Information">
          <SectionRow>
            <TextField source="alias" label="Vacancy ID" />
            <TextField source="name" label="Position" />
            <EnumField options={COMPETENCY_LEVEL} source="competencyLevel" label="Competency level" />
            <EnumField source="status" label="Status" options={VACANCY_STATUSES} />
          </SectionRow>
          <SectionRow>
            <EmployeeLinkField source="managerAlias" nameSource="managerName" label="Manager" />
            <EmployeeLinkField source="requestedById" nameSource="requestedByName" label="Requested By" />
            <EnumField source="dueDate" label="Due Date" options={VACANCY_DUE_DATE} />
            <EnumField source="type" options={VACANCY_TYPES} />
            <EnumField
              isVisible={(data: any) => !!data.openPositions}
              source="openPositions"
              options={VACANCY_POSITIONS}
            />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Vacancy Details">
          <FieldListLayout source="vacancyDetails" hasSeparator>
            <VacancyDetailsField />
          </FieldListLayout>
        </ShowSection>
        <ShowSection title="Expected Compensation">
          <ExpectedCompensationField source="expectedCompensation" />
        </ShowSection>
        <ShowSection title="Project Description">
          <BigSectionRow>
            <div className="column-field__wrapper">
              <HtmlField source="description.projectText" />
            </div>
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Responsibilities">
          <BigSectionRow>
            <FieldListLayout source="description.responsibilities">
              <NameDescriptionField />
            </FieldListLayout>
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Mandatory Requirements">
          <BigSectionRow>
            <FieldListLayout source="description.requirements">
              <NameDescriptionField />
            </FieldListLayout>
            <CommaListField source="description.mandatorySkills" />
            <TextField source="minExperience" label="Minimum Experience (years)" />
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Optional Requirements">
          <BigSectionRow>
            <FieldListLayout source="description.optionalRequirements">
              <NameDescriptionField />
            </FieldListLayout>
            <CommaListField source="description.optionalSkills" />
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Personality">
          <BigSectionRow>
            <FieldListLayout source="description.personality">
              <NameDescriptionField />
            </FieldListLayout>
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Publications">
          <FieldListLayout source="publications">
            <VacancyPublicationListLinkField />
          </FieldListLayout>
        </ShowSection>
      </ShowVacancyPageLayout>
    </Show>
  );
};

export default ViewVacancy;
