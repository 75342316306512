import React, { useEffect } from 'react';
import Show from 'shared/uibuilder/show/Show';
import useTrainingInstancesService from '../useTrainingInstancesService';
import ViewTrainingInstancesContent from './ViewTrainingInstancesContent';
import { useTrainingInstancesId } from '../TrainingInstancesRouter';

const ViewTrainingInstancesPage = () => {
  const { getById, invalidateCache } = useTrainingInstancesService();
  const id = useTrainingInstancesId();

  useEffect(() => {
    return () => {
      invalidateCache();
    };

    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewTrainingInstancesContent />
    </Show>
  );
};

export default ViewTrainingInstancesPage;
