/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import CompetencyBreadcrumbs from './CompetencyBreacrumbs';
import { useCompetencyId, useCompetencyUrl } from '../CompetencyRouter';

const SingleCompetencyBreadcrumbs = () => {
  const { getSingleEntityUrl } = useCompetencyUrl();
  const urlId = useCompetencyId();

  return (
    <CompetencyBreadcrumbs>
      <SingleBreadcrumbItem entity={urlId} link={getSingleEntityUrl(urlId)} />
    </CompetencyBreadcrumbs>
  );
};

export default SingleCompetencyBreadcrumbs;
