/* istanbul ignore file */
// libs
import React, { ReactElement } from 'react';
import Paper from 'uibuilder/Paper';
import Fade from 'uibuilder/Fade';
import Box from 'uibuilder/Box';
// components
import Typography from 'uibuilder/Typography';
import Message from 'shared/message/Message';
import BreadcrumbsWithMenu from 'shared/layout/BreadcrumbsWithMenu';
// styles
import { useListContext } from 'shared/uibuilder/list/ListContext';
import AddTimelineItemInput from 'shared/uibuilder/timeline/AddTimelineItemInput';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { ResourceData } from 'shared/crud/baseCrudService';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const DEFAULT_FUNCTION = () => {};

interface TimelinePageLayoutProps {
  breadcrumbs?: React.ReactNode;
  menu?: React.ReactNode;
  children?: React.ReactNode;
  entityHeader?: React.ReactNode;
  filter?: React.ReactNode;
  title?: String;
  hasAddForm?: boolean;
  additionalButtons?: ReactElement[];
}

const TimelinePageLayout = ({
  breadcrumbs,
  menu,
  children,
  entityHeader,
  filter,
  title,
  hasAddForm = true,
  additionalButtons,
}: TimelinePageLayoutProps) => {
  const {
    registries = [],
    addForm,
    setAddForm = DEFAULT_FUNCTION,
    createTimelineItemMethod = DEFAULT_FUNCTION,
    addToList = DEFAULT_FUNCTION,
    isEntryViewMode,
    wrapItem = () => ({}),
  }: TimelineContextData = useListContext();
  const { data: parentContext } = useShowContext();

  const closeAddForm = () => setAddForm(null);

  return (
    <Paper elevation={0} className="page-content view-single-entry__wrapper">
      <BreadcrumbsWithMenu breadcrumbs={breadcrumbs} menu={menu} />
      {entityHeader && (
        <div className="show-page__header-wrapper">
          <div className="show-page__entity-header-wrapper entity-header__wrapper">{entityHeader}</div>
        </div>
      )}
      <div className="show-page__content-wrapper">
        <Box className="show-page__menu-wrapper" sx={{ display: { xs: 'none', sm: 'block' } }}>
          {menu}
        </Box>

        <div className="show-page__content-inner">
          <div className="list-page-top-block">
            <div className={`timeline__page_top_block ${filter ? '' : 'mb-9'}`}>
              <Typography variant={entityHeader ? 'h2' : 'h1'} component="h2" className="c-title">
                {title || 'Timeline'}
              </Typography>

              <div className="top-btns">
                {hasAddForm && !addForm && (
                  <AddTimelineItemInput
                    registries={registries}
                    selectItemCallback={registry => {
                      const { AddForm, source, urlPostfix, additionalAfterAddSubmit } = registry;

                      const form = (
                        <AddForm
                          onCancel={closeAddForm}
                          submitFormFunc={(data: ResourceData) => createTimelineItemMethod(urlPostfix, data)}
                          afterSubmit={{
                            execute: (data: TimelineItem) => {
                              addToList(wrapItem(data, source));
                              closeAddForm();
                              if (additionalAfterAddSubmit) {
                                additionalAfterAddSubmit(data, parentContext);
                              }
                            },
                          }}
                        />
                      );
                      setAddForm(form);
                    }}
                  />
                )}
                {additionalButtons}
              </div>
            </div>
            <Fade in={!!filter} timeout={20} className="mb-2">
              <div>{isEntryViewMode ? null : filter}</div>
            </Fade>
          </div>
          <Message />
          <div className="page-content">{children}</div>
        </div>
      </div>
    </Paper>
  );
};

export default TimelinePageLayout;
