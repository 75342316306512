/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

interface FilterActionsProps {
  onChangeCallback: () => void;
  onResetCallback: () => void;
}

const FilterActions = ({ onChangeCallback, onResetCallback }: FilterActionsProps) => {
  const { apply } = useFilterContext();

  return (
    <Box className="filter-actions" sx={{ padding: '16px 16px 13px' }}>
      <Button
        outline
        onClick={() => {
          onChangeCallback();
          apply();
        }}
        sx={{ fontSize: '14px', marginRight: '7px' }}
        className="update-filter-item-button"
      >
        Update
      </Button>
      <Button variant="text" onClick={onResetCallback} sx={{ fontSize: '14px' }} className="reset-filter-item-button">
        Cancel
      </Button>
    </Box>
  );
};

export default FilterActions;
