/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import JournalEntryAttributesKey from 'financialAnalytic/journalEntry/shared/field/JournalEntryAttributesKey';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import 'financialAnalytic/journalEntry/List/JournalEntryListPage.scss';
import { isObject } from 'lodash';

const EXCLUDED_ATTRIBUTES = [
  'employeeName',
  'employeeAlias',
  'businessObjectType',
  'invoiceId',
  'revertedOriginalJournalEntryId',
  'leaveId',
  'office',
  'projectId',
  'accountId',
  'sowId',
];

const JournalEntryAttributesList = ({ source }: { source: string }) => {
  const { getValue } = useFieldHelper({ source });
  const attributes = (getValue() as any) || {};
  const attributesItems = Object.entries(attributes).reduce(
    (acc: { key: string; value: string }[], [key, value]: any) => {
      if (EXCLUDED_ATTRIBUTES.includes(key) || (key === 'businessObjectId' && attributes.businessObjectType)) {
        return acc;
      }

      return [...acc, { key, value: isObject(value) ? JSON.stringify(value, null, 2) : String(value) }];
    },
    [],
  );

  return (
    <ControlledList data={{ items: attributesItems, totalPages: 1, totalElements: attributesItems.length }}>
      <DataGridLayout tableWrapperClassName="journal-entry-table-wrapper">
        <JournalEntryAttributesKey source="key" label="Key" width="50%" />
        <TextField source="value" label="Value" width="50%" />
      </DataGridLayout>
    </ControlledList>
  );
};

export default JournalEntryAttributesList;
