export const editFormValidationSchema = {
  name: {
    type: 'string',
    required: true,
  },
  description: {
    type: 'string',
    required: true,
  },
  status: {
    type: 'string',
    required: true,
  },
  location: {
    type: 'string',
    required: true,
  },
  type: {
    type: 'string',
    required: true,
  },
  date: {
    type: 'date',
    required: true,
  },
  participants: {
    type: 'object',
  },
};

export default {
  ...editFormValidationSchema,
};
