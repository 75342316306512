/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { ASSET_CONDITIONS } from 'financialAnalytic/assets/useAssetService';

const AssetConditionField = (props: FieldProps) => {
  return <EnumField options={ASSET_CONDITIONS} {...props} />;
};

export default AssetConditionField;
