/* istanbul ignore file */
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import useValidation from 'shared/validation/validationService';

/**
 * Hook which provides form validation components.
 * @param fieldSource - source of the field that needs to be validated
 */
const useFilterValidation = (fieldSource = '') => {
  const { validationSchema, filters } = useFilterContext();

  const validationMethods = useValidation({
    data: filters,
    validationSchema,
    fieldSource,
  });

  return {
    ...validationMethods,
  };
};

export default useFilterValidation;
