import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import useInputList, { InputListHelperProps } from 'shared/uibuilder/form/input/InputList/inputListHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import {
  DefaultInputLayoutProps,
  InputLayoutHintProps,
  InputLayoutStateProps,
  ValidationLayoutProps,
  DefaultInputPropTypes,
} from 'shared/uibuilder/form/input';

export interface BaseInputListProps {
  inputTemplate: React.FC<DefaultInputPropTypes<any>>;
  addText?: string;
  deleteText?: string;
}

interface InputListLayoutProps
  extends BaseInputListProps,
    DefaultInputLayoutProps<string | object>,
    InputLayoutHintProps,
    InputLayoutStateProps,
    ValidationLayoutProps {
  fields: (string | object)[];
  addMethod: () => void;
  removeMethod: (index: number) => void;
  onChangeCallback: (index: number, value: FormFieldsData) => void;
  maxFields: number;
  minFields: number;
  simpleLayout?: boolean;
  inputProps?: DefaultInputPropTypes<string | object>;
  isAddable?: boolean;
  isDeletable?: boolean;
}

export type InputListLayoutType = ReactComponent<InputListLayoutProps>;

export interface InputListProps extends BaseInputListProps, InputListHelperProps {
  label?: string;
  isAddable?: boolean;
  isDeletable?: boolean;
}

const InputList: React.FC<InputListProps> = ({
  inputTemplate,
  addText,
  deleteText = '',
  maxFields: initialMaxFields = null,
  minFields: initialMinFields = null,
  defaultValue = '',
  ...props
}: InputListProps) => {
  const { InputListLayout } = useUiTheme<InputListLayoutType>();
  const inputHelper = useInputHelper(props);
  const value = inputHelper.getValue() || '';
  const source = inputHelper.getSource();
  const validationCallback = inputHelper.getValidationCallback();
  const { getMaxElements, getMinElements } = useFormValidation(source);
  const maxFields = initialMaxFields || getMaxElements();
  const minFields = initialMinFields || getMinElements();

  const { onChangeCallback, addInput, removeInput } = useInputList({
    maxFields: initialMaxFields,
    minFields: initialMinFields,
    defaultValue,
    ...props,
  });

  useEffect(() => {
    validationCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(value as object[]).length]);

  return (
    <InputListLayout
      {...props}
      value={inputHelper.getValue()}
      className={inputHelper.getClassName()}
      isVisible={inputHelper.isVisible()}
      maxFields={maxFields}
      minFields={minFields}
      addMethod={addInput}
      removeMethod={removeInput}
      inputTemplate={inputTemplate}
      fields={(value as object[]) || []}
      onChangeCallback={onChangeCallback}
      addText={addText}
      deleteText={deleteText}
      isRequired={inputHelper.getIsRequired()}
      errorMessages={inputHelper.getErrorMessages()}
      disabled={!!inputHelper.getIsDisabled()}
    />
  );
};

export default InputList;
