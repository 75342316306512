/* istanbul ignore file */
// libs
import React from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import { TextArea } from 'shared/uibuilder/form/input';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CareerDevelopmentReviewAttachmentUploaderInput from 'artifact/shared/input/CareerDevelopmentReviewAttachmentUploaderInput';
import useEarlyStartedIntitialization from 'erp/careerdevelopment/createupdate/earlyStartedInitialization';
import EarlyStartedHandler from 'erp/careerdevelopment/createupdate/EarlyStartedHandler';

const CareerDevelopmentReviewForm = () => {
  const { isEarlyStarted, nextReviewDate } = useEarlyStartedIntitialization();

  return (
    <>
      <EarlyStartedHandler nextReviewDate={nextReviewDate} isEarlyStarted={isEarlyStarted} />
      <FormSection title="Career development review information">
        <MediumFormRow>
          <TextArea
            label="Completed assessments or examples of work"
            source="completedAssessments"
            tooltip="Provide links to the tickets containing the results of your qualification assessments or examples of work."
          />
        </MediumFormRow>

        <MediumFormRow>
          <TextArea
            label="Reason for early process start"
            source="earlyStartReason"
            isVisible={data => data.isEarlyStarted}
          />
        </MediumFormRow>
        <BigFormRow>
          <CareerDevelopmentReviewAttachmentUploaderInput label="Attachment" source="attachmentsIds" />
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default CareerDevelopmentReviewForm;
