import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleHiringRequestBreadcrumbs from '../../shared/SingleHiringRequestBreadcrumbs';

const UpdateHiringBreadcrumbs = () => {
  return (
    <SingleHiringRequestBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleHiringRequestBreadcrumbs>
  );
};

export default UpdateHiringBreadcrumbs;
