/* istanbul ignore file */
import React from 'react';
import FormTemplate, { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';

const CreateForm = ({ children, ...props }: FormTemplateProps) => {
  return <FormTemplate {...props}>{children}</FormTemplate>;
};

CreateForm.defaultProps = {
  initialData: {},
};

export default CreateForm;
