/* istanbul ignore file */
import React from 'react';
import { EmployeePerformanceDetailsFieldsProps } from 'erp/employee/salaries/shared/fields/EmployeePerformanceDetailsFields';
import {
  PERFORMANCE_CONFIDENCE_GRADES_OPTIONS,
  PERFORMANCE_DETAILS_SECTIONS,
  PERFORMANCE_GRADES_OPTIONS,
  PerformanceSectionVersions,
} from 'erp/employee/salaries/shared/salaryService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import MediumSectionRow from 'shared/uibuilder/layout/MediumSectionRow';
import { EnumField, HtmlField } from 'shared/uibuilder/field';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const EmployeePerformanceField = ({ source }: EmployeePerformanceDetailsFieldsProps) => {
  const { data } = useShowContext();
  const isVisible = data.getValueBySource(`${source}`);
  const oldModel = data.getValueBySource(`${source}.oldModel`);

  return (
    isVisible && (
      <div>
        {PERFORMANCE_DETAILS_SECTIONS.filter(
          section =>
            section.version === PerformanceSectionVersions.GENERAL ||
            (oldModel
              ? section.version === PerformanceSectionVersions.OLD
              : section.version === PerformanceSectionVersions.NEW),
        ).map(section => {
          const fieldSource = `${source}.${section.field}`;
          return (
            <ShowSection title={section.title} titleVariant="h5">
              <MediumSectionRow>
                <EnumField options={PERFORMANCE_GRADES_OPTIONS} source={`${fieldSource}.evaluationGrade`} />
                <EnumField options={PERFORMANCE_CONFIDENCE_GRADES_OPTIONS} source={`${fieldSource}.confidenceGrade`} />
              </MediumSectionRow>
              <MediumSectionRow>
                <HtmlField source={`${fieldSource}.positiveDetails`} label="Positive Details" />
                <HtmlField source={`${fieldSource}.negativeDetails`} label="Negative Details" />
              </MediumSectionRow>
            </ShowSection>
          );
        })}
      </div>
    )
  );
};

export default EmployeePerformanceField;
