/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';

const EditEducationButton = props => {
  const { getUpdateEntityUrl } = useEducationUrl();
  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditEducationButton;
