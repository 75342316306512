import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField } from 'shared/uibuilder/field';
import { oneOfType, string, func } from 'prop-types';
import useEmployeeService, { EMPLOYEE_WORKPLACE_LOCATION } from '../../employeeService';

const appendToName = (current, append) => (append ? `${current}, ${append}` : current);

const EmployeeWorkplaceField = ({ value: initialValue, ...props }) => {
  const { getValue, getData } = useFieldHelper({ ...props, value: initialValue });
  const { isWorkplaceLocationNotRemote } = useEmployeeService();

  const value = getValue();
  let displayedValue = null;

  if (value) {
    if (initialValue) {
      displayedValue = typeof value === 'function' ? value(getData()) : initialValue;
    } else {
      const { workplaceLocation, roomNumber, deskLocation } = value;
      displayedValue = EMPLOYEE_WORKPLACE_LOCATION[workplaceLocation];
      if (isWorkplaceLocationNotRemote(workplaceLocation)) {
        displayedValue = appendToName(displayedValue, roomNumber);
        displayedValue = appendToName(displayedValue, deskLocation);
      }
    }
  }

  return <TextField {...props} value={displayedValue} />;
};

EmployeeWorkplaceField.propTypes = {
  value: oneOfType([string, func]),
};

EmployeeWorkplaceField.defaultProps = {
  value: undefined,
};

export default EmployeeWorkplaceField;
