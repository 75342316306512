import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useCustomerInvoiceService from '../../useCustomerInvoiceService';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';

export interface CorrectedInvoiceIdDropdownProps {
  source: string;
  label?: string;
  showLoader?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isVisible?: boolean | ((data: any) => boolean);
  loadDataMethod?: () => ResultResourceData;
  mapResults?: (data: any) => Option;
  sourceAmount: string;
  sourceCurrency: string;
}

const CorrectedInvoiceIdDropdown = ({
  loadDataMethod,
  mapResults: mapResultsFromProps,
  sourceAmount,
  sourceCurrency,
  ...props
}: CorrectedInvoiceIdDropdownProps) => {
  const { searchAll } = useCustomerInvoiceService();
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  const mapResults = (project: ResultResourceData) => ({
    value: project.id,
    label: project.invoiceNumber,
  });

  const load = async () => {
    const projects = await searchAll({});

    return {
      result: projects,
    };
  };

  const onChangeCallback = (values: any) => {
    const correctedInvoiceId = values[props.source];

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        correctedInvoiceId,
      });
    }
  };

  return (
    <LoadDropdown
      loadDataMethod={load}
      mapResults={mapResultsFromProps || mapResults}
      onChangeCallback={onChangeCallback}
      placeholder="Select Invoice"
      {...props}
    />
  );
};

export default CorrectedInvoiceIdDropdown;
