/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { CandidateApplicationSourceType } from 'erp/candidate/applications/shared/candidateApplicationService';

const CandidateApplicationSourceTypeDropdown = (props: any) => {
  return (
    <Dropdown
      placeholder="Choose Candidate Application Source Name"
      options={Object.values(CandidateApplicationSourceType).map(value => ({
        value,
        label: value,
      }))}
      label="Candidate Application Source Type"
      {...props}
    />
  );
};

export default CandidateApplicationSourceTypeDropdown;
