import EF1Validation from 'externalform/ef1/EF1Validation';
import useBaseExternalFormService from 'externalform/baseExternalFormService';

const useEF1Service = ({ hideForm }: { hideForm?: () => void } = {}) => {
  const { findAll, getById, getDataById, setData } = useBaseExternalFormService({ hideForm });

  const getValidationSchema = () => Promise.resolve(EF1Validation);

  const validateAlias = (alias: string) => /^EF_1\.[A-Z0-9]{4,6}$/i.test(alias);

  return {
    findAll,
    getById,
    setData,
    getDataById,
    validateAlias,
    getValidationSchema,
  };
};

export default useEF1Service;
