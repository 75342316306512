/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import DomainForm from 'erp/employee/domains/domain/DomainForm';
import { useDomainsService } from 'erp/employee/domains/domain/shared/domainService';

const MESSAGE_CONTEXT = 'addDomainForm';

interface AddDomainModalFormProps {
  closeModal: () => void;
}

const AddDomainModalForm = ({ closeModal }: AddDomainModalFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { createDomain } = useDomainsService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Add domain
    </Typography>
  );

  return (
    <CreateForm
      submitFormFunc={createDomain}
      messageContext={MESSAGE_CONTEXT}
      initialData={{}}
      afterSubmit={{
        execute: useCallback(() => {
          closeForm();
        }, [closeForm]),
      }}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <DomainForm handleCancelClick={closeForm} />
      </ModalWindow>
    </CreateForm>
  );
};

export default AddDomainModalForm;
