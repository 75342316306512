/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import ArtifactStorageFolderField from 'artifactStorage/shared/field/ArtifactStorageFolderField';
import ArtifactStorageTypeIcon from 'artifactStorage/shared/field/ArtifactStorageTypeIcon';
import { ArtifactType } from 'artifactStorage/shared/artifactStorageService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const ArtifactStorageFoldersListLayout = () => {
  return (
    <DataGridLayout>
      <ArtifactStorageTypeIcon label="" source="type" width={45} />
      <ArtifactStorageFolderField source="folder" label="Name" isVisible={data => data.type === ArtifactType.FOLDER} />
      <TextField
        source="folder.totalItemCount"
        label="Number of files"
        isVisible={(data: FormFieldsData) => data.type === ArtifactType.FOLDER}
      />
    </DataGridLayout>
  );
};

export default ArtifactStorageFoldersListLayout;
