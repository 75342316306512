/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import useAssetService from '../useAssetService';
import { NotDismissedEmployeeInput } from 'erp/employee';
import { DateInput } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/MediumFormRow';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';

interface AssignAssetOwnerFormProps {
  closeModal: () => void;
  modalOpen: boolean;
}

const getValidationSchema = () =>
  Promise.resolve({
    assignmentDetails: {
      type: 'object',
      _divisible: false,
      properties: {
        currentOwner: {
          type: 'string',
          required: true,
        },
        provisionDate: {
          type: 'date',
          required: true,
          maxDate: {
            value: '$TODAY',
            message: 'Please choose date not earlier than today',
          },
        },
      },
    },
  });

const AssignAssetOwnerForm = ({ closeModal, modalOpen }: AssignAssetOwnerFormProps) => {
  const { data: asset, setData = () => {} } = useShowContext();
  const { addMessage } = useMessageService();
  const assetId = asset.getValueBySource('inventoryNumber');
  const { updateAssignmentDetails } = useAssetService();
  const { getEmployeeNameDataById } = useEmployeeNamesService();

  const getDataFunc = async () => {
    const assignmentDetails = asset.getValueBySource('assignmentDetails');

    if (assignmentDetails?.currentOwner) {
      try {
        const employeeName = (await getEmployeeNameDataById(assignmentDetails.currentOwner)) || {};
        const currentOwnerName = employeeName.nameEn;
        assignmentDetails.currentOwnerFullName = `${assignmentDetails.currentOwner} ${currentOwnerName?.firstName ||
          ''} ${currentOwnerName?.lastName || ''}`.trim();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    return {
      assignmentDetails,
    };
  };

  return (
    <ModalForm
      closeModal={closeModal}
      modalOpen={modalOpen}
      submitFormFunc={data => updateAssignmentDetails(assetId, data)}
      afterSubmit={{
        execute: response => {
          setData(response);
          addMessage(new SuccessMessage('Current Owner has been successfully updated.'));
        },
      }}
      getDataFunc={getDataFunc}
      getValidationSchemaFunc={getValidationSchema}
    >
      <FormSection>
        <FormRow>
          <NotDismissedEmployeeInput
            source="assignmentDetails.currentOwner"
            nameSource="assignmentDetails.currentOwnerFullName"
            label="Current Owner"
            includeContractors
          />
          <DateInput source="assignmentDetails.provisionDate" label="Provision Date" />
        </FormRow>
      </FormSection>
    </ModalForm>
  );
};

export default AssignAssetOwnerForm;
