/* istanbul ignore file */
import React from 'react';
import AccountsBreadcrumbs from 'crm/account/shared/AccountsBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAccountUrl } from 'crm/account/index';

const CreateAccountBreadcrumbs = () => {
  const { getCreateUrl } = useAccountUrl();

  return (
    <AccountsBreadcrumbs>
      <CreateBreadcrumbItem entity="Account" link={getCreateUrl()} />
    </AccountsBreadcrumbs>
  );
};

export default CreateAccountBreadcrumbs;
