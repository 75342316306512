import { SET_MESSAGE, DISMISS_MESSAGE } from 'shared/message/messageService';
import { MessageProps } from './messageReducer';

export const setMessage = (message: MessageProps, pathname: string, messageContext?: string) => ({
  type: SET_MESSAGE,
  payload: {
    message,
    pathname,
    messageContext,
  },
});

export const dismissMessage = (message?: MessageProps, pathname?: string, messageContext?: string) => ({
  type: DISMISS_MESSAGE,
  payload: {
    message,
    pathname,
    messageContext,
  },
});
