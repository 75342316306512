import { useArtifactStorageApi, useFinanceApi } from 'api';

type Thumbnails = Dictionary<string>;

export interface ArtifactThumbnailService {
  getThumbnails: (id: string) => Promise<Dictionary<string>>;
  getInvoiceThumbnails: (id: string) => Promise<Dictionary<string>>;
  getArtifactsInfo: (id: string) => Promise<{ artifacts: Dictionary<Thumbnails> }>;
  getInvoiceArtifactsInfo: (id: string) => Promise<{ artifacts: Dictionary<Thumbnails> }>;
}

const useArtifactThumbnailService = (): ArtifactThumbnailService => {
  const { sendGetRequest } = useArtifactStorageApi();
  const { sendGetRequest: sendFinanceGetRequest } = useFinanceApi();

  const getArtifactsInfo = async (id: string) => {
    const response = await sendGetRequest(`/artifacts/${id}/thumbnails`);

    return response.json();
  };

  const getInvoiceArtifactsInfo = async (id: string) => {
    const response = await sendFinanceGetRequest(`/invoices/${id}/artifacts/thumbnails`);

    return response.json();
  };

  const getThumbnails = async (id: string) => {
    const { artifacts = {} } = await getArtifactsInfo(id);

    return artifacts[id]?.thumbnails || {};
  };

  const getInvoiceThumbnails = async (id: string) => {
    const { artifacts = {} } = await getInvoiceArtifactsInfo(id);

    return artifacts[id]?.thumbnails || {};
  };

  return {
    getThumbnails,
    getInvoiceThumbnails,
    getArtifactsInfo,
    getInvoiceArtifactsInfo,
  };
};

export default useArtifactThumbnailService;
