import React from 'react';
import classnames from 'classnames';
import { ButtonDropdown } from 'shared/uibuilder/form/input';
import { ButtonDropdownProps } from 'shared/uibuilder/form/input/ButtonDropdown';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

interface DefaultFilterInputLayoutProps extends ButtonDropdownProps {
  onResetFilterCallback?: () => void;
}

const DefaultFilterInputLayout = ({
  children,
  className,
  source = '',
  onResetFilterCallback,
  displayedValue,
  ...props
}: DefaultFilterInputLayoutProps) => {
  const { clearAndApplyFilter, isStatic } = useFilterContext();

  const resetFilter = () => {
    if (onResetFilterCallback) {
      onResetFilterCallback();
    } else {
      clearAndApplyFilter(source);
    }
  };

  return (
    <ButtonDropdown
      {...props}
      displayedValue={displayedValue}
      className={classnames('filter-input', className)}
      onRemoveCallback={!isStatic || displayedValue ? resetFilter : null}
      shouldCloseMenuOnReset={isStatic}
    >
      {children}
    </ButtonDropdown>
  );
};

export default DefaultFilterInputLayout;
