/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { Dropdown } from 'shared/uibuilder/form/input';
import { EXPENSE_TYPES_OPTIONS, EXPENSE_SUBTYPES } from 'financialAnalytic/expenses/useExpenseService';

const DROPDOWN_OPTIONS = Object.entries(EXPENSE_SUBTYPES).reduce(
  (acc: { value?: string; label: string }[], [key, options]) => {
    acc.push(
      {
        label: EXPENSE_TYPES_OPTIONS[key],
      },
      ...Object.entries(options).map(([value, label]) => ({
        value,
        label,
      })),
    );

    return acc;
  },
  [],
);

const ExpensesSubTypeDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <Dropdown options={DROPDOWN_OPTIONS || []} {...props} />
    </DropDownFilter>
  );
};

export default ExpensesSubTypeDropdownFilter;
