import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { number, string, arrayOf } from 'prop-types';

import './LeadAgeField.scss';
import { TextField } from 'shared/uibuilder/field';
import { isStatusAllowed } from 'crm/shared/field/LastTouchCrmField';

const OLD_LEAD_AGE = 60;

export const NOT_AVAILABLE_LEAD_AGE = 2147483647;
// If lead has this value as a lead age, that means its age is unavailable.
// The point of that is to sort leads with unavailable age to the end of the list.

const LeadAgeField = ({ value: inputValue, status: inputStatus, source, skipStates }) => {
  const { data } = useShowContext();
  const value = inputValue || (data && data.getValueBySource(source));
  const status = inputStatus || (data && data.getValueBySource('status'));

  let text;
  if (value < NOT_AVAILABLE_LEAD_AGE) {
    text = `${value} d`;
  } else {
    text = 'Not Available';
  }
  const className =
    value >= OLD_LEAD_AGE &&
    value < NOT_AVAILABLE_LEAD_AGE &&
    (status === 'NEW' || status === 'OPEN' || status === 'ASSIGNED')
      ? 'old-lead'
      : '';
  let element = <span className={className}>{text}</span>;

  if (skipStates && !isStatusAllowed(status, skipStates)) {
    element = null;
  }

  return <TextField value={element} label="Lead Age" />;
};

LeadAgeField.propTypes = {
  value: number,
  status: string,
  source: string,
  skipStates: arrayOf(string),
};

LeadAgeField.defaultProps = {
  value: null,
  status: null,
  source: null,
  skipStates: null,
};

export default LeadAgeField;
