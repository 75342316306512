import useSecurityReactContext, { SecurityContext } from 'shared/authorization/react-context/SecurityReactContext';
import useGlobalSecurityReactContext from 'shared/authorization/react-context/GlobalSecurityReactContext';

export type Permission = string;
export type PermissionsMap = {
  [key: string]: boolean;
};

const hasPermission = (userPermissions: PermissionsMap, permission: Permission | Permission[]): boolean => {
  if (permission === null) {
    return true;
  }

  let result = false;

  if (userPermissions) {
    if (Array.isArray(permission)) {
      result = permission.some(item => userPermissions[item]);
    } else {
      result = !!userPermissions[permission];
    }
  }

  return result;
};

const getContextPermissions = (context: SecurityContext) => context?.permissions;

function extractLocalPermissions(localContextPermissions: SecurityContext, contextName?: string) {
  return (
    // @ts-ignore
    (localContextPermissions && localContextPermissions[contextName || localContextPermissions.currentContext]) || {}
  );
}

/**
 * The hook returns the set of functions which may be used for authorization checks.
 *
 * @returns {{isGranted: *}} the function receives permission as a parameter and returns
 * true is permission has been successfully set.
 */
const useAuthorization = () => {
  const localContext = useSecurityReactContext();
  const globalContext = useGlobalSecurityReactContext() || {};

  const globalContextPermissions = getContextPermissions(globalContext);

  const isGranted = (permission: Permission | Permission[], contextName?: string) => {
    const localContextPermissions = extractLocalPermissions(localContext, contextName);

    return hasPermission(
      {
        ...localContextPermissions,
        ...globalContextPermissions,
      },
      permission,
    );
  };

  const getPermissions = (contextName?: string) => {
    const permissions = {
      ...globalContext.permissions,
      ...extractLocalPermissions(localContext, contextName),
    };

    return Object.keys(permissions);
  };

  return {
    isGranted,
    getPermissions,
  };
};

export default useAuthorization;
