/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs/index';
import SkillsBreadcrumbs from 'erp/employee/skills/skillsList/SkillsBreadcrumbs';

const CreateSkillsBreadcrumbs = () => (
  <SkillsBreadcrumbs>
    <CreateBreadcrumbItem entity="Skills" />
  </SkillsBreadcrumbs>
);

export default CreateSkillsBreadcrumbs;
