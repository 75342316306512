/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field/index';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

interface CurrencyFieldProps extends FieldProps {
  currencySource: string;
  amountSource: string;
  emptyValue?: string;
  purchaseSubTotals?: Dictionary<any>;
}

const PurchaseAmountField = ({
  source,
  currencySource,
  amountSource,
  emptyValue,
  purchaseSubTotals = {},
  ...props
}: CurrencyFieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const subTotals = (purchaseSubTotals[getValue()] as any) || {};
  const value = subTotals[amountSource] || 0;
  const currency = subTotals[currencySource];

  return (
    <TextField
      {...props}
      source={source}
      value={value || !emptyValue ? `${getWithThousandsSeparator(value)}${CURRENCIES[currency] || ''}` : emptyValue}
    />
  );
};

export default PurchaseAmountField;
