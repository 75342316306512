/* istanbul ignore file */
import React from 'react';

const DayOnDemandsBalanceField = ({ useTooltip = false, dayOnDemandField = 0 }) => {
  return useTooltip ? (
    <sub className="single-entry-info-block description-info">including {dayOnDemandField} days on demand</sub>
  ) : (
    <sub />
  );
};

export default DayOnDemandsBalanceField;
