/* istanbul ignore file */
import React from 'react';
import { VACANCY_STATUSES } from 'erp/recruitment/vacancy/shared/vacancyService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const VacancyStatusDropdown = (props: any) => {
  const options = { ...VACANCY_STATUSES };

  return <EnumDropdown options={options} label="Vacancy Status" placeholder="Choose Vacancy Status" {...props} />;
};

export default VacancyStatusDropdown;
