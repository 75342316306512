import React, { useEffect, useState } from 'react';
import CheckboxGroupFilter from 'shared/uibuilder/list/filter/CheckboxGroupFilter';
import useOfficeService from 'erp/employee/office/officeService';
import './OfficeFilter.scss';

const OfficeFilter = ({ source }: any) => {
  const { findAll } = useOfficeService();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const offices = await findAll();
      const data = offices.map((office: any) => ({
        value: office.id.toString(),
        label: office.name,
      }));
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckboxGroupFilter
      options={options}
      source={source}
      isColumnDirection
      className="office-checkbox-buttons-group"
    />
  );
};

export default OfficeFilter;
