/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import { func } from 'prop-types';
import React from 'react';
import useActivityService from 'crm/shared/timeline/activity/crmActivityService';
import ActivityForm from 'crm/shared/timeline/activity/form/ActivityForm';

const CreateActivityForm = ({ submitFormFunc, onCancel, ...props }) => {
  const { getValidationSchema } = useActivityService();
  return (
    <CreateForm
      submitFormFunc={submitFormFunc}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        activityType: 'EMAIL',
      }}
      {...props}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <ActivityForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

CreateActivityForm.propTypes = {
  submitFormFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default CreateActivityForm;
