/* istanbul ignore file */
import React, { useCallback } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { Button } from 'uibuilder/button';
import { REQUEST_STATUS } from 'crm/opportunity/presale/request/shared/presaleRequestService';
import ModalWindow from 'shared/uibuilder/ModalWindow';

export interface BeforeSubmitModalProps {
  modalOpen: boolean;
  setModalOpen: any;
}

const BeforeSubmitModal = ({ modalOpen, setModalOpen }: BeforeSubmitModalProps) => {
  const { data = {}, submitForm = () => {} } = useFormContext();

  const onSave = useCallback(
    (status: any) => {
      data.requestStatus = status;
      submitForm();
      setModalOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, submitForm],
  );

  const onClickSubmit = useCallback(() => {
    onSave(REQUEST_STATUS.SUBMITTED);
  }, [onSave]);

  const saveSubmittedButton = (
    <Button data-testid="btn-success" key="modal-submit-button" onClick={onClickSubmit}>
      Submit
    </Button>
  );

  const onClickDraft = useCallback(() => {
    onSave(REQUEST_STATUS.DRAFT);
  }, [onSave]);

  const saveAsDraftButton = (
    <Button data-testid="modal-save-draft-button" key="modal-save-draft-button" onClick={onClickDraft}>
      Save as draft
    </Button>
  );

  return (
    <>
      <ModalWindow
        isOpen={modalOpen}
        title="After you submit the request, the ticket will be assigned to the presale manager"
        hasCancelButton={false}
        buttons={[saveSubmittedButton, saveAsDraftButton]}
        onToggle={() => {
          setModalOpen(false);
        }}
      >
        <span>
          Are you sure you want to submit this request? You can also save the request as a draft and send the presale
          request later.
        </span>
      </ModalWindow>
    </>
  );
};

export default BeforeSubmitModal;
