import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import useStorageService from 'shared/uibuilder/storageService';

export const RESOURCE_URL = '/qualification-models';
export const READ_QUALIFICATION_MODEL = 'FE_READ_QUALIFICATION_MODEL';

export type Area = {
  id: number;
  name: string;
};

export interface LevelCompetency {
  id: number;
  name: string;
  weight: number;
  areas: Area[];
  competencyId: number;
  optional: boolean;
}

export type SpecializationLevel = {
  id: number;
  title: string;
  competencies: LevelCompetency[];
};

export type Specialization = {
  id: number;
  title: string;
  specializationLevels: SpecializationLevel[];
};

const useQualificationModelService = () => {
  const { sendGetRequest } = useKernelApi();
  const baseCrudRequests = useCrudService({
    singleResourceUrl: '/qualification-models/:id',
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const { getDataFromStorage, setDataToStorage } = useStorageService(
    'qualificationModel',
    async () => {
      const data = await loadModelByVersion('latest');

      return {
        latest: data,
      };
    },
    '1.4',
  );

  const getByVersion = async (version: string) => {
    const models = await getDataFromStorage();

    if (models[version]) {
      return models[version];
    } else {
      const loadedModel = await loadModelByVersion(version);

      setDataToStorage({
        ...models,
        [version]: loadedModel,
      });

      return loadedModel;
    }
  };

  const loadModelByVersion = async (modelVersion: StringOrNumber) => {
    const response = await sendGetRequest(`${RESOURCE_URL}/${modelVersion}`);
    return response.json();
  };

  return {
    getByVersion,
    ...baseCrudRequests,
  };
};

export default useQualificationModelService;
