import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useSkillsUrl } from 'erp/employee/skills/SkillsRouter';
import { CREATE_EMPLOYEE_SKILLS } from 'erp/employee/skills/EmployeeSkillsService';

const AddSkillButton = () => {
  const { getCreateUrl } = useSkillsUrl();

  return (
    <CreateButton
      variant="contained"
      label="Add Skills"
      url={getCreateUrl()}
      permissionToCheck={CREATE_EMPLOYEE_SKILLS}
    />
  );
};

export default AddSkillButton;
