/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form/index';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import useAssessmentService from 'erp/employee/assessment/assessmentService';
import AssessmentForm from 'erp/employee/assessment/createupdate/AssessmentForm';
import { useAssessmentId, useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';
import UpdateAssessmentBreadcrumbs from 'erp/employee/assessment/createupdate/Update/Breadcrumbs';

const UpdateAssessment = () => {
  const { update, getById, getValidationSchema } = useAssessmentService();
  const { getListUrl } = useAssessmentUrl();
  const assessmentId = useAssessmentId();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      entityId={assessmentId}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Assessment has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Assessment"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
        breadcrumbs={<UpdateAssessmentBreadcrumbs />}
      >
        <AssessmentForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateAssessment;
