/* istanbul ignore file */

import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';
import Filters from 'shared/uibuilder/list/filter/Filters';
import StaticFiltersLayout from 'shared/uibuilder/list/filter/layout/StaticFiltersLayout';
import FileList from 'shared/uibuilder/list/FileList';
import AuditDateFilter from 'audit/List/filter/AuditDateFilter';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import useAuditService from 'audit/shared/auditService';
import PermissionsAuditManual from './manual';

const PermissionsConfigurationList = () => {
  const { getPermissionsConfigurationReport } = useAuditService();
  const { getToday } = useDateService();

  const filters = (
    <Filters>
      <StaticFiltersLayout actionButtonName="Download">
        <AuditDateFilter source="dateTime" />
      </StaticFiltersLayout>
    </Filters>
  );

  return (
    <FileList
      getDataMethod={getPermissionsConfigurationReport}
      defaultFilters={{
        dateTime: {
          eq: getToday().format(DATE_FORMAT.API),
        },
      }}
    >
      <ListPageLayout
        showTotal={false}
        header={<ListHeader label="Permissions Configuration" icon="fa-lock" />}
        filter={filters}
      >
        <PermissionsAuditManual />
      </ListPageLayout>
    </FileList>
  );
};

export default PermissionsConfigurationList;
