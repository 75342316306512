import React from 'react';
import useTrainingsService, { WRITE_TRAINING_DOMAIN } from '../../useTrainingsService';
import { useTrainingsId, useTrainingsUrl } from '../../TrainingsRouter';
import DeleteButton from 'uibuilder/button/DeleteButton';

const DeleteTrainingButton = () => {
  const { deleteById } = useTrainingsService();
  const { getListUrl } = useTrainingsUrl();
  const id = useTrainingsId();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(id)}
      displayMessage="Do you really want to delete this Training?"
      permissionToCheck={WRITE_TRAINING_DOMAIN}
      color="error"
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Training has been successfully deleted.',
        errorMessage: `Can't delete Training`,
      }}
    />
  );
};

export default DeleteTrainingButton;
