/* istanbul ignore file */
import React, { useState } from 'react';
import moment from 'moment';
import ButtonWithConfirmation, { ButtonWithConfirmationProps } from 'uibuilder/button/ButtonWithConfirmation';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DateInput } from 'shared/uibuilder/form/input';

const ButtonWithConfirmationDateInput = ({
  inputLabel,
  submitMethod,
  modalChildren,
  disabledInput,
  ...props
}: ButtonWithConfirmationProps & { inputLabel: string }) => {
  const [dateErrors, setDateErrors] = useState<string[]>([]);
  const { getToday, formatDateForAPI } = useDateService();
  const [date, setDate] = useState(getToday());

  const onDateChange = (values: FormFieldsData) => {
    setDate(values.date);
    const isValid = moment(values.date, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.date) {
      setDateErrors(['Field is required']);
    } else if (!isValid) {
      setDateErrors(['Please, choose the correct date']);
    } else {
      setDateErrors([]);
    }
  };

  return (
    <ButtonWithConfirmation
      modalChildren={
        <>
          {modalChildren}
          <div className="mt-5">
            <DateInput
              source="date"
              value={date as any}
              label={inputLabel}
              onChangeCallback={onDateChange}
              errorMessages={dateErrors}
              isRequired
              disabled={disabledInput}
            />
          </div>
        </>
      }
      submitMethod={() => submitMethod(formatDateForAPI(date))}
      variant="outlined"
      confirmBtnProps={{ disabled: !!dateErrors.length }}
      {...props}
    />
  );
};

export default ButtonWithConfirmationDateInput;
