import React from 'react';
// services
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, BaseFieldLayoutType } from 'shared/uibuilder/field';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';

const AmountField = (props: FieldProps<number>) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const initialValue = getValue();

  const amount = initialValue && getWithThousandsSeparator(initialValue);

  return <BaseFieldLayout label={getLabel()} value={amount} />;
};

export default AmountField;
