/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import WasteBreadcrumbs from '../../shared/WasteBreadcrumbs';

const CreateWasteBreadcrumbs = () => {
  return (
    <WasteBreadcrumbs>
      <CreateBreadcrumbItem entity="Waste" />
    </WasteBreadcrumbs>
  );
};

export default CreateWasteBreadcrumbs;
