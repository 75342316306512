/* istanbul ignore file */
import React from 'react';
import SingleAssetBreadcrumbs from 'financialAnalytic/assets/shared/SingleAssetBreadcrumbs';
import { NotesBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAssetId, useAssetUrl } from 'financialAnalytic/assets/AssetRouter';

const AssetTimelineBreadcrumbs = () => {
  const id = useAssetId();
  const { getTimelineUrl } = useAssetUrl();

  return (
    <SingleAssetBreadcrumbs>
      <NotesBreadcrumbItem link={getTimelineUrl(id)} />
    </SingleAssetBreadcrumbs>
  );
};

export default AssetTimelineBreadcrumbs;
