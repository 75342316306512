import React from 'react';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useWorkplaceService from 'erp/room/workplace/useWorkplaceService';
import { useListContext } from 'shared/uibuilder/list/ListContext';

interface AssignEmployeeToWorkplaceFormProps {
  closeModal: () => void;
  modalOpen: boolean;
}

const AssignEmployeeToWorkplaceForm = ({ closeModal, modalOpen }: AssignEmployeeToWorkplaceFormProps) => {
  const { assignWorkplace } = useWorkplaceService();
  const { reloadData = () => {} } = useListContext();
  const { getValue: getWorkplaceId } = useFieldHelper<string>({ source: 'id' });

  const submit = async ({ employee }: any) => {
    if (employee) {
      return assignWorkplace(getWorkplaceId(), employee);
    } else {
      return null;
    }
  };

  return (
    <ModalForm
      closeModal={closeModal}
      modalOpen={modalOpen}
      submitFormFunc={submit}
      afterSubmit={{
        execute: () => {
          reloadData();
        },
        message: 'You are awesome! Workplace was assigned successfully.',
      }}
    >
      <EmployeeInput source="employee" />
    </ModalForm>
  );
};

export default AssignEmployeeToWorkplaceForm;
