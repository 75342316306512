import React from 'react';
import isEqual from 'lodash/isEqual';
import { Button } from 'uibuilder/button';
import { Filter, useFilterContext } from './FilterContext';
import { SortParam, useListContext } from 'shared/uibuilder/list/ListContext';
import { getActiveFilters } from 'shared/uibuilder/list/filter/useFilter';
import assert from 'assert';

interface QuickFilterAndSortProps {
  label: string;
  state: Filter;
  defaultSort: SortParam;
  className?: string;
}

const QuickFilterAndSort = ({ label, state, defaultSort, className }: QuickFilterAndSortProps) => {
  const { quickFilter, filters } = useFilterContext();
  const { sortBy, sort } = useListContext();

  const onClick = () => {
    assert(!!quickFilter && !!sortBy, 'quickfilters and sortBy should not be undefined');

    sortBy(defaultSort.field, defaultSort.order);
    quickFilter(state);
  };

  return (
    <Button
      disableFocusRipple
      onClick={onClick}
      variant="text"
      type="button"
      disabled={isEqual(getActiveFilters(filters), state) && isEqual(sort, defaultSort)}
      className={className}
    >
      {label}
    </Button>
  );
};

export default QuickFilterAndSort;
