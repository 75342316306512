/* istanbul ignore file */
import Badge from 'shared/uibuilder/badge/Badge';
import React from 'react';

const IsOptionalBadge = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <Badge color="neutralLight" isVisible={isVisible}>
      Optional
    </Badge>
  );
};

export default IsOptionalBadge;
