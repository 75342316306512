import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { HtmlField, TextField } from 'shared/uibuilder/field';
import './AdditionalContactsField.scss';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import { ADDITIONAL_CONTACTS_OPTIONS } from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

const AdditionalContactsField = props => {
  const { getValue, getLabel } = useFieldHelper(props);

  const contacts = getValue();

  const displayedContacts =
    contacts && contacts.length
      ? contacts.map((contact, index) => {
          const { type, value } = contact;

          const displayValue = (
            <ReplaceUrlWithLink>
              <HtmlField value={value} />
            </ReplaceUrlWithLink>
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="form-inline additional-contact-field" key={index} title={value}>
              {ADDITIONAL_CONTACTS_OPTIONS[type] || type}:&nbsp;{displayValue}
            </div>
          );
        })
      : null;

  return <TextField value={displayedContacts} label={getLabel()} isColumnDirection={contacts?.length > 1} />;
};

AdditionalContactsField.propTypes = {
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  label: string,
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  value: arrayOf(
    shape({
      type: string.isRequired,
      value: string.isRequired,
    }),
  ),
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  source: string,
};

AdditionalContactsField.defaultProps = {
  label: null,
  source: null,
  value: null,
};

export default AdditionalContactsField;
