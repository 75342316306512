/* istanbul ignore file */
import React from 'react';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import JournalEntryBreadcrumbs from 'financialAnalytic/journalEntry/shared/JournalEntryBreadcrumbs';

const ReverseLedgerBreadcrumbs = () => {
  return (
    <JournalEntryBreadcrumbs>
      <BreadcrumbItem>Storno Journal Entry</BreadcrumbItem>
    </JournalEntryBreadcrumbs>
  );
};

export default ReverseLedgerBreadcrumbs;
