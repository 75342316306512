/* istanbul ignore file */
import AbstractTokenMarker from './AbstractTokenMarker';

const CLASS_NAME = 'activeToken';

const POSITION = {
  top: 65,
  left: -7,
};

class ActiveTokenMarker extends AbstractTokenMarker {
  constructor(activityId: string, tokensCount: number) {
    super(activityId, tokensCount, CLASS_NAME, POSITION);
  }
}

export default ActiveTokenMarker;
