/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { RequestType } from '../oneStopService';

export type ModalData = {
  isOpen: boolean;
  title: string | undefined;
  content: string | React.ReactElement;
  requestId?: string | null;
  requestType?: RequestType | null;
};

type OneStopModalLayoutProps = {
  modalConfirmHandler: () => void;
  modalData: Nullable<ModalData>;
};

const OneStopModalLayout = ({ modalConfirmHandler, modalData }: OneStopModalLayoutProps) => {
  const { isOpen = false, title, content } = modalData || {};

  return (
    <ModalWindow
      isOpen={isOpen}
      onToggle={modalConfirmHandler}
      title={title}
      hasCancelButton={false}
      buttons={[<Button onClick={modalConfirmHandler}>OK</Button>]}
      className="onestop-modal-window"
      modalSize="sm"
      hasCloseIcon
    >
      <div className="onestop-popup-content">{content}</div>
    </ModalWindow>
  );
};

export default OneStopModalLayout;
