import React from 'react';
import Filters from 'shared/uibuilder/list/filter/Filters';
import invoiceFilterValidation from '../../invoice/List/invoiceFilterValidation';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListPageHeaderWrapper from '../../shared/ListPageHeaderWrapper';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import EnumField from 'shared/uibuilder/field/EnumField';
import { DateField } from 'shared/uibuilder/field';
import TextField from 'shared/uibuilder/field/TextField';
import ViewCustomerInvoiceButton from '../shared/buttons/ViewCustomerInvoiceButton';
import { CUSTOMER_INVOICE_STATUSES, CUSTOMER_INVOICE_STATUSES_OPTIONS } from '../useCustomerInvoiceService';
import CreateCustomerInvoiceButton from '../shared/buttons/CreateCustomerInvoiceButton';
import SowIdFilter from '../shared/filter/SowIDFilter';
import CustomerInvoiceStatusFilter from '../shared/filter/CustomerInvoiceStatusFilter';

const CustomerInvoiceListContent = () => {
  const filters = (
    <Filters getValidationSchema={() => Promise.resolve(invoiceFilterValidation)}>
      <FiltersLayout
        quickFilters={[
          <QuickFilter label="All Pending Invoices" state={{ status: { in: [CUSTOMER_INVOICE_STATUSES.PENDING] } }} />,
        ]}
      >
        <SowIdFilter source="sowId" label="SOW ID" />
        <CustomerInvoiceStatusFilter label="Status" source="status" />
        <DateRangeFilter source="invoiceDate" label="Period" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ListPageLayout
      header={<ListPageHeaderWrapper header={<ListHeader label="Customer Invoices" />} />}
      actions={[<CreateCustomerInvoiceButton isVisible />]}
      filter={filters}
    >
      <DataGridLayout buttons={[<ViewCustomerInvoiceButton source="id" />]}>
        <TextField source="invoiceNumber" width="25%" />
        <TextField label="Name" source="name" />
        <TextField label="SOW ID" source="sowId" />
        <EnumField label="Status" source="status" options={CUSTOMER_INVOICE_STATUSES_OPTIONS} width="10%" isSortable />
        <DateField source="invoiceDate" label="Date" width="10%" isSortable />
        <TextField source="description" width="25%" />
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default CustomerInvoiceListContent;
