/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import FinanceForm from 'erp/employee/finance/createupdate/FinanceForm';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import useFinanceService from 'erp/employee/finance/shared/financeService';
import { useFinanceId, useFinanceUrl } from 'erp/employee/finance/FinanceRouter';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import UpdateFinanceBreadcrumbs from 'erp/employee/finance/createupdate/Update/Breadcrumbs';
import SensitiveUpdatePageLayout from 'shared/uibuilder/sensitiveData/SensitiveUpdatePageLayout';

const UpdateFinance = () => {
  const { update, getById, getValidationSchema } = useFinanceService();
  const financeId = useFinanceId();
  const { getListUrl } = useFinanceUrl();
  const listUrl = getListUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      idSource="compensationId"
      getDataFunc={() => getById(financeId)}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: listUrl,
        message: 'You are awesome! The Compensation package has been successfully saved.',
      }}
    >
      <SensitiveUpdatePageLayout
        title="Edit: Compensation package"
        breadcrumbs={<UpdateFinanceBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
        timerId={listUrl}
      >
        <FinanceForm />
      </SensitiveUpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateFinance;
