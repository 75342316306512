/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import {
  EXPENSE_TYPES,
  EXPENSE_SUBTYPES,
  SUBTYPE_FIELD_LABELS,
  EXPENSE_TAX_COUNTRIES,
} from 'financialAnalytic/expenses/useExpenseService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const ExpenseTaxSubTypeDropdown = ({
  officeSource,
  source,
  ...props
}: DefaultInputPropTypes<string> & { officeSource: string }) => {
  const { getRawOnChangeCallback } = useInputHelper({ source });
  const rawOnChangeCallback = getRawOnChangeCallback();
  const label = SUBTYPE_FIELD_LABELS.TAX;

  const onChange = (values: Dictionary<any>) => {
    const newValue = values[source];

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [source]: newValue,
        [officeSource]: newValue ? EXPENSE_TAX_COUNTRIES[newValue] : null,
      });
    }
  };

  return (
    <EnumDropdown
      options={EXPENSE_SUBTYPES[EXPENSE_TYPES.TAX] || {}}
      {...props}
      source={source}
      label={label}
      placeholder={`Select ${label}`}
      onChangeCallback={onChange}
    />
  );
};

export default ExpenseTaxSubTypeDropdown;
