/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';

const BudgetLink = ({ id, name }: { id: StringOrNumber; name: string }) => {
  const { getSingleEntityUrl } = useBudgetUrl();

  return (
    <Link to={getSingleEntityUrl(id)} target="_blank">
      {name}
    </Link>
  );
};

export default BudgetLink;
