/* istanbul ignore file */
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';

const AnalyticsClientIdTooltip = () => {
  const { data } = useShowContext();
  const { formatDate, subtractDaysFromStartDate } = useDateService();

  const createdAtDate = data.getValueBySource('createdAt');
  const endFilterRangeDate = formatDate(createdAtDate, DATE_FORMAT.FULL_DATE);
  const startFilterRangeDate = subtractDaysFromStartDate(createdAtDate, 90, DATE_FORMAT.FULL_DATE);
  const tooltipMessage = (
    <div>
      If you receive a <b>User Not Found</b> error, you may try changing the date range in the top right corner of the
      report as such: <b>{startFilterRangeDate} -</b>
      <b>{endFilterRangeDate}</b>.
    </div>
  );

  return <InputTooltip source="" message={tooltipMessage} />;
};

export default AnalyticsClientIdTooltip;
