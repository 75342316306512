/* istanbul ignore file */
import React from 'react';
import { LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { bool, func, oneOfType, string } from 'prop-types';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useContactUrl } from 'crm/contact/ContactRouter';

const ContactLinkField = ({ idSource, idValue, linkText: initialLinkText, isInternal, ...props }) => {
  const { getSingleEntityUrl } = useContactUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { data } = useShowContext();
  const linkText = typeof initialLinkText === 'function' ? initialLinkText(data) : initialLinkText;
  const id = getId();

  return <LinkField {...props} isInternal={isInternal} linkText={linkText} value={id && getSingleEntityUrl(id)} />;
};

ContactLinkField.propTypes = {
  idSource: string,
  idValue: oneOfType([string, func]),
  linkText: oneOfType([string, func]),
  isInternal: bool,
};

ContactLinkField.defaultProps = {
  idSource: undefined,
  idValue: '',
  linkText: undefined,
  isInternal: true,
};

export default ContactLinkField;
