/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useProjectUrl } from 'erp/project/ProjectRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const ProjectBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useProjectUrl();
  const projectsListLink = getListUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Project" link={projectsListLink} />
      {children}
    </Breadcrumbs>
  );
};

export default ProjectBreadcrumbs;
