import React from 'react';
import RoomBreadcrumbs from 'erp/room/shared/RoomBreadcrumbs';
import CreateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/CreateBreadcrumbItem';

const CreateRoomBreadcrumbs = () => {
  return (
    <RoomBreadcrumbs>
      <CreateBreadcrumbItem entity="Room" />
    </RoomBreadcrumbs>
  );
};

export default CreateRoomBreadcrumbs;
