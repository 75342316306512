/* istanbul ignore file */
import React, { useState } from 'react';
import { Button, IconButton } from 'uibuilder/button';
import StatusChangeReasonModalForm from 'erp/candidate/applications/status/StatusChangeReasonModalForm';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { CandidateApplicationStatus } from 'erp/candidate/applications/shared/candidateApplicationService';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

type SetApplicationStatusButtonProps = {
  candidateIdSource: string;
  candidateApplicationIdSource: string;
  statusSource: string;
  sx?: SxProps<Theme>;
};

const SetApplicationStatusButton = ({
  candidateIdSource,
  candidateApplicationIdSource,
  statusSource,
  sx = {},
}: SetApplicationStatusButtonProps) => {
  const { getValue: getCandidateId } = useFieldHelper({ source: candidateIdSource });
  const { getValue: getCandidateApplicationId } = useFieldHelper({ source: candidateApplicationIdSource });
  const { getValue: getCandidateApplicationStatus } = useFieldHelper({ source: statusSource });
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => setIsFormOpen(true);

  const closeForm = () => setIsFormOpen(false);

  const candidateId = getCandidateId();
  const candidateApplicationId = getCandidateApplicationId();
  const isStatusInProcessing = getCandidateApplicationStatus() === CandidateApplicationStatus.IN_PROCESSING;
  return (
    <>
      <Button
        className="set-processed-status-button"
        onClick={openForm}
        sx={{ ...sx, display: { xs: 'none', md: 'inline-flex' } }}
      >
        <span>{isStatusInProcessing ? 'Complete' : 'To In Progress'}</span>
      </Button>
      <IconButton className="set-processed-status-button" onClick={openForm} sx={{ display: { md: 'none' } }}>
        <TaskOutlinedIcon className="set-processed-status-icon" />
      </IconButton>
      {isFormOpen && (
        <StatusChangeReasonModalForm
          candidateId={candidateId}
          candidateApplicationId={candidateApplicationId}
          isModalOpen={isFormOpen}
          closeFeedbackForm={closeForm}
          status={
            isStatusInProcessing ? CandidateApplicationStatus.PROCESSED : CandidateApplicationStatus.IN_PROCESSING
          }
        />
      )}
    </>
  );
};

export default SetApplicationStatusButton;
