/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useCustomerInvoiceUrl } from '../CustomerInvoicesRouter';

const CustomerInvoiceBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useCustomerInvoiceUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Customer Invoices" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default CustomerInvoiceBreadcrumbs;
