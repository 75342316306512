import { getDifferenceBetweenTwoStrings } from 'shared/uibuilder/helper';
import { isEmpty } from 'lodash';

export const ADDITIONAL_CONTACTS_OPTIONS = {
  LinkedIn: 'LinkedIn',
  Skype: 'Skype',
  Telegram: 'Telegram',
  Whatsapp: 'Whatsapp',
  Viber: 'Viber',
  Github: 'Github',
  'Dev.by': 'Dev.by',
  'Jobs.tut.by': 'Rabota.by',
  Stackoverflow: 'Stackoverflow',
  Other: 'Other',
};

export const ADDITIONAL_CONTACTS_OPTIONS_ALIAS = Object.keys(ADDITIONAL_CONTACTS_OPTIONS);
export const DEFAULT_ADDITIONAL_CONTACTS_OPTIONS_ALIAS = 'Other';

const minMatchLimit = 0.75;

const useAdditionalContactsHelper = () => {
  const getPreparedAdditionalContacts = contacts => {
    if (!contacts || !Array.isArray(contacts)) {
      return [];
    }

    return contacts.map(contact => {
      let newContact = {};
      ADDITIONAL_CONTACTS_OPTIONS_ALIAS.some(type => {
        const diff = getDifferenceBetweenTwoStrings(contact.type.replace(' ', ''), type);

        if (contact.type === type || diff >= minMatchLimit) {
          newContact = { ...contact, type };
          return true;
        }

        return false;
      });

      if (isEmpty(newContact)) {
        return { ...contact, type: DEFAULT_ADDITIONAL_CONTACTS_OPTIONS_ALIAS };
      } else {
        return newContact;
      }
    });
  };

  return {
    getPreparedAdditionalContacts,
  };
};

export default useAdditionalContactsHelper;
