/* istanbul ignore file */
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { LEAVE_RULES_VIOLATION_REASON } from 'erp/leave/shared/leaveService';
import { DEFAULT_DROPDOWN_PROPTYPES } from 'shared/uibuilder/form/input/dropdownHelper';

const RulesViolationReasonDropdown = props => {
  return (
    <EnumDropdown
      placeholder="Choose reason for business rules violation"
      options={LEAVE_RULES_VIOLATION_REASON}
      {...props}
    />
  );
};

RulesViolationReasonDropdown.propTypes = {
  ...DEFAULT_DROPDOWN_PROPTYPES,
};

export default RulesViolationReasonDropdown;
