/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs/index';
import AssessmentsBreadcrumbs from 'erp/employee/assessment/shared/AssessmentsBreadcrumbs';

const CreateAssessmentBreadcrumbs = () => (
  <AssessmentsBreadcrumbs>
    <CreateBreadcrumbItem entity="Assessment" />
  </AssessmentsBreadcrumbs>
);

export default CreateAssessmentBreadcrumbs;
