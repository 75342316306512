import { usePrivilegedAccessApi } from 'api';
import useCreationResponseHandler from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import useDeprovisionResponseHandler from 'privileged/externalaccounts/deprovision/accountsDeprovisionResponseHandler';
import useEmployeeService from 'erp/employee/employeeService';
import getAzureDomain from 'erp/config/emailConfiguration';
import usePositionService from 'erp/position/positionService';
import { EMPLOYEE_FORM_VALIDATION, INTERN_FORM_VALIDATION } from './externalAccountValidation';
import useCandidateService from '../../erp/candidate/shared/candidateService';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

export interface CreateAccountsRequest {
  firstNameEn: string;
  lastNameEn: string;
  workingEmail: string;
  positionAlias: string;
  employeeId?: string; // to update profile (like E1908001)
  candidateId?: number; // to update profile
  employeeType?: string; // INTERN, CONTRACTOR, EMPLOYEE etc.
  privateNotePassword: string;
  azureId?: string;
}

export interface DeprovisionEntityRequest {
  employeeId?: string; // to deprovision (like 'E1908001')
  candidateId?: number; // to deprovision (like 123)
}

export interface DeprovisionAccountsRequest extends DeprovisionEntityRequest {
  deprovisionAzureAccount?: boolean;
  deprovisionRedmineAccount?: boolean;
  deprovisionGitlabAccount?: boolean;
  deprovisionDavinciAccount?: boolean;
}

export interface CreateRedmineUserAccountRequest {
  firstNameEn: string;
  lastNameEn: string;
  workingEmail: string;
  positionAlias?: string;
  employeeType?: string;
  azureId?: string;
}

export interface RedmineAccountCreationStatus {
  userId?: number;
  email?: string;
  url?: string;
  errors: string[];
}

export interface RedmineGroupsAssignmentStatus {
  attemptedToModifyGroups: boolean;
  currentJoinedGroups: string[];
  errors: string[];
}

export interface AzureGroupsAssignmentStatus {
  attemptedToModifyGroups: boolean;
  currentJoinedGroups: string[];
  errors: string[];
}

export interface DaVinciAccountModificationStatus {
  attemptedModifyAccount: boolean;
  errors: string[];
}

export enum DeprovisionStatus {
  NOT_PERFORMED = 'NOT_PERFORMED',
  OK = 'OK',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

export interface AccountDeprovisionStatus {
  status: DeprovisionStatus;
  errors: string[];
}

export interface DeprovisionAccountsResponse {
  azureDeprovisionStatus: AccountDeprovisionStatus;
  redmineDeprovisionStatus: AccountDeprovisionStatus;
  gitlabDeprovisionStatus: AccountDeprovisionStatus;
  employeeDeprovisionStatus: AccountDeprovisionStatus;
  candidateDeprovisionStatus: AccountDeprovisionStatus;
}

export interface CreateRedmineUserAccountResponse {
  accountCreation: RedmineAccountCreationStatus;
  groupsAssignment: RedmineGroupsAssignmentStatus;
}

export interface CreateAzureUserAccountRequest {
  firstNameEn: string;
  lastNameEn: string;
  workingEmail: string;
  employeeId?: string; // to update in DaVinci
  candidateId?: number; // to update in DaVinci
  employeeType?: string; // INTERN, CONTRACTOR, EMPLOYEE etc.
  azureId?: string;
  privateNotePassword: string;
}

export interface AzureAccountCreationResponse {
  userId?: string;
  firstNameEn?: string;
  lastNameEn?: string;
  positionAlias?: string;
  workingEmail?: string;
  errors: string[];
}

export interface LicenseAssignmentResponse {
  attemptedToAssignLicense?: boolean;
  errors: string[];
}

export interface CreateAzureUserAccountResponse {
  accountCreation: AzureAccountCreationResponse;
  licenseAssignment: LicenseAssignmentResponse;
  groupsAssignment: AzureGroupsAssignmentStatus;
  davinciAccountModification: DaVinciAccountModificationStatus;
  privateNoteUrl: string;
}

export interface AccountsCreationResponse {
  createAzureAccountResponse?: CreateAzureUserAccountResponse;
  createRedmineAccountResponse?: CreateRedmineUserAccountResponse;
}

export interface DaVinciProfileTarget {
  employeeId?: string;
  candidateId?: number;
}

const PRIVATE_NOTE_PASSWORD_LENGTH = 4;

export const RUN_COMMAND_PERMISSION = 'RUN_COMMAND';

/**
 * Creates Azure and Redmine accounts for an employee.
 */
export const useExternalAccountsService = () => {
  const { sendPostRequest, sendGetRequest } = usePrivilegedAccessApi();
  const { handleCreationResponse } = useCreationResponseHandler();
  const { handleDeprovisionResponse } = useDeprovisionResponseHandler();
  const { getById: getEmployeeById, getIdentityDocumentNumber } = useEmployeeService();
  const { getById: getCandidateById } = useCandidateService();
  const { findPositionById } = usePositionService();

  const domainName = getAzureDomain();

  const createExternalAccounts = async (request: CreateAccountsRequest) => {
    const state: AccountsCreationResponse = {
      createAzureAccountResponse: undefined,
      createRedmineAccountResponse: undefined,
    };

    // Create Redmine account before Azure one.
    // Historically, Redmine accounts were locked, not deleted as Azure ones was, so there higher
    // possibility for collision by email (present in some locked account) for Redmine account.
    try {
      state.createRedmineAccountResponse = await createRedmineAccount({
        firstNameEn: request.firstNameEn,
        lastNameEn: request.lastNameEn,
        workingEmail: request.workingEmail,
        positionAlias: request.positionAlias,
        azureId: request.azureId,
        employeeType: request.employeeType,
      });
    } catch (e) {
      let message = 'Failed to create Redmine account.';
      const details = (e as any).message;
      if (details) {
        message += ` ${details}`;
      }

      state.createRedmineAccountResponse = {
        accountCreation: {
          errors: [message],
        },
        groupsAssignment: {
          attemptedToModifyGroups: false,
          currentJoinedGroups: [],
          errors: [],
        },
      };
    }

    // Because of above do not attempt to create Azure account if Redmine one is failed.
    // Most likely Azure account will be created successfully as there will be free email.
    if (state.createRedmineAccountResponse.accountCreation.userId) {
      try {
        state.createAzureAccountResponse = await createAzureAccount({
          firstNameEn: request.firstNameEn,
          lastNameEn: request.lastNameEn,
          workingEmail: request.workingEmail,
          employeeType: request.employeeType,
          azureId: request.azureId,
          employeeId: request.employeeId,
          candidateId: request.candidateId,
          privateNotePassword: request.privateNotePassword,
        });
      } catch (e) {
        let message = 'Failed to create Azure account.';
        const details = (e as any).message;
        if (details) {
          message += ` ${details}`;
        }

        state.createAzureAccountResponse = {
          licenseAssignment: {
            errors: [],
          },
          privateNoteUrl: '',
          accountCreation: {
            errors: [message],
          },
          davinciAccountModification: {
            attemptedModifyAccount: false,
            errors: [],
          },
          groupsAssignment: {
            attemptedToModifyGroups: false,
            currentJoinedGroups: [],
            errors: [],
          },
        };
      }
    }

    handleCreationResponse(state, {
      candidateId: request.candidateId,
      employeeId: request.employeeId,
    });
  };

  const deprovisionExternalAccounts = async (request: DeprovisionEntityRequest) => {
    let response: DeprovisionAccountsResponse;
    try {
      response = await deprovisionAccounts({
        candidateId: request.candidateId,
        employeeId: request.employeeId,
        deprovisionAzureAccount: true,
        deprovisionRedmineAccount: true,
        deprovisionGitlabAccount: true,
        deprovisionDavinciAccount: true,
      });
    } catch (e) {
      response = {
        azureDeprovisionStatus: {
          status: DeprovisionStatus.NOT_PERFORMED,
          errors: [],
        },
        redmineDeprovisionStatus: {
          status: DeprovisionStatus.NOT_PERFORMED,
          errors: [],
        },
        gitlabDeprovisionStatus: {
          status: DeprovisionStatus.NOT_PERFORMED,
          errors: [],
        },
        employeeDeprovisionStatus: {
          status: DeprovisionStatus.NOT_PERFORMED,
          errors: [],
        },
        candidateDeprovisionStatus: {
          status: DeprovisionStatus.NOT_PERFORMED,
          errors: [],
        },
      };
    }

    handleDeprovisionResponse(response);
  };

  const getPermissions = async () => {
    const url = `/azure/accounts/permissions`;
    const response = await sendGetRequest(url);
    return response.json();
  };

  const getEmployeeDataForForm = async (employeeAlias: string) => {
    const employeeData = await getEmployeeById(employeeAlias);

    const identityDocumentNumber = getIdentityDocumentNumber(employeeData);

    const privateNotePassword =
      identityDocumentNumber && identityDocumentNumber.length >= PRIVATE_NOTE_PASSWORD_LENGTH
        ? identityDocumentNumber.substring(identityDocumentNumber.length - PRIVATE_NOTE_PASSWORD_LENGTH)
        : identityDocumentNumber;

    const firstNameEn = employeeData.nameEn?.firstName;
    const lastNameEn = employeeData.nameEn?.lastName;
    const suggestedEmailAddress =
      employeeData.workingEmail || // may be present for INTERN or for configured EMPLOYEE
      (firstNameEn &&
        lastNameEn &&
        `${firstNameEn.substring(0, 1).toLowerCase()}.${lastNameEn.toLowerCase()}@${domainName}`);

    const position = await findPositionById(employeeData.positionId);

    return {
      firstNameEn,
      lastNameEn,
      workingEmail: suggestedEmailAddress,
      privateNotePassword,
      positionId: employeeData.positionId,
      positionAlias: position.alias,
      employeeType: employeeData.employeeType,
      azureId: employeeData.azureId,
    };
  };

  const getCandidateDataForForm = async (candidateId: number) => {
    const candidateData = await getCandidateById(candidateId);

    const privateNotePassword = getNumericPassword(PRIVATE_NOTE_PASSWORD_LENGTH);

    const translit = CyrillicToTranslit();
    const firstNameEn = translit.transform(candidateData.name?.firstName);
    const lastNameEn = translit.transform(candidateData.name?.lastName);
    const suggestedEmailAddress =
      candidateData.syberryEmail ||
      (firstNameEn &&
        lastNameEn &&
        `${firstNameEn.substring(0, 1).toLowerCase()}.${lastNameEn.toLowerCase()}@${domainName}`);

    return {
      firstNameEn,
      lastNameEn,
      workingEmail: suggestedEmailAddress,
      privateNotePassword,
      employeeType: 'INTERN',
    };
  };

  const createAzureAccount = async (
    createRequest: CreateAzureUserAccountRequest,
  ): Promise<CreateAzureUserAccountResponse> => {
    try {
      const response = await sendPostRequest('/azure/accounts', createRequest);
      return await response.json();
    } catch (e) {
      const response = await (e as any).json();
      const errorMessage = response.message || `Failed to create Azure account for '${createRequest.workingEmail}'`;
      throw new Error(errorMessage);
    }
  };

  const createRedmineAccount = async (
    createRequest: CreateRedmineUserAccountRequest,
  ): Promise<CreateRedmineUserAccountResponse> => {
    try {
      const response = await sendPostRequest('/redmine/accounts', createRequest);
      return await response.json();
    } catch (e) {
      const response = await (e as any).json();
      const errorMessage = response.message || `Failed to create Redmine account for '${createRequest.workingEmail}'`;
      throw new Error(errorMessage);
    }
  };

  const deprovisionAccounts = async (request: DeprovisionAccountsRequest): Promise<DeprovisionAccountsResponse> => {
    try {
      const response = await sendPostRequest('/accounts/deprovision', request);
      return await response.json();
    } catch (e) {
      const response = await (e as any).json();
      const errorMessage =
        response.message ||
        `Failed to deprovision accounts for ` +
          `'${request.employeeId ? `employee '${request.employeeId}'` : `candidate '${request.candidateId}'`}'`;
      throw new Error(errorMessage);
    }
  };

  const getNumericPassword = (length: number): string => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    let password = '';
    for (let i = 0; i < array.length; i += 1) {
      const digit = Math.floor((array[i] / 256) * 10); // 0 to 9
      password += digit;
    }
    return password;
  };

  const getEmployeeValidationSchema = () => Promise.resolve(EMPLOYEE_FORM_VALIDATION);
  const getInternValidationSchema = () => Promise.resolve(INTERN_FORM_VALIDATION);

  return {
    createExternalAccounts,
    deprovisionExternalAccounts,
    getEmployeeDataForForm,
    getCandidateDataForForm,
    getEmployeeValidationSchema,
    getInternValidationSchema,
    getPermissions,
  };
};
