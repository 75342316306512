import React from 'react';
import CopyToClipboardButton from 'shared/uibuilder/CopyToClipboardButton';
import './ProcessInstanceEntityHeader.scss';

interface Props {
  businessKey: string | null | undefined;
}

const ProcessInstanceEntityTitle = ({ businessKey }: Props) => {
  const keyCaption = <span className="business-key">{businessKey || 'null (Business Key)'}</span>;

  const copyKeyButton = businessKey && (
    <CopyToClipboardButton className="ml-1" id="copyBusinessKey" value={businessKey}>
      Copy Business Key
    </CopyToClipboardButton>
  );

  return (
    <>
      {keyCaption}
      {copyKeyButton}
    </>
  );
};

export default ProcessInstanceEntityTitle;
