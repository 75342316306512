/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';
import { CREATE_EMPLOYEE_CONTRACT } from 'erp/employee/contracts/shared/employeeContractsService';

const CreateEmployeeContractButton = () => {
  const { getCreateUrl } = useEmployeeContractUrl();

  return (
    <CreateButton
      variant="contained"
      label="Add Contract"
      url={getCreateUrl()}
      permissionToCheck={CREATE_EMPLOYEE_CONTRACT}
    />
  );
};

export default CreateEmployeeContractButton;
