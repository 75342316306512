import React from 'react';

export const achievementsTooltipMessage = (
  <>
    <p>Summarize a list of work items that have been complete during the reported weekly cycle.</p>
    <p>Do not include a big list of minor activities and rather focus on complete work that have been produced.</p>
  </>
);

export const plannedItemsTooltipMessage = (
  <>
    <p>Summarize a list major activities that you plan to complete during the next weekly cycle.</p>
    <p>Include the items that you plan to finish rather than do.</p>
  </>
);

export const milestonesTooltipMessage = (
  <>
    <p>Provide a list of key project milestones with the following information for each milestone:</p>
    <ul>
      <li>
        <b>ID: </b>generated automatically by the system. For the first initiated report in the system, it can be
        changed manually.
      </li>
      <li>
        <b>Description: </b>a one-sentence description outlining the nature of the milestone.
      </li>
      <li>
        <b>Planned date: </b>the date that was originally assigned for a milestone. This date may never change once
        assigned.
      </li>
      <li>
        <b>Currently planned date: </b>the date for which the completion of the milestone is currently planned. If
        milestone is being delayed for any reason, the planned date must be updated, it cannot be in the past and must
        always be in the future.
      </li>
      <li>
        <b>Comments: </b>any additional information or clarifications for the milestone.
      </li>
      <li>
        <b>End date: </b>for milestones that are achieved this is the date when it was actually achieved.
      </li>
    </ul>
    <p>
      Keep the list of milestones detailed enough to have at least a single milestone for every month of the project
      duration.
    </p>
    <p>For long-running non-scope-based project keep a planning horizon of at least 2 months ahead.</p>
    <p>
      Do no produce too granular milestones (weekly or daily), these are needed for high-level project progress
      tracking.
    </p>
  </>
);

export const discussionsTooltipMessage = (
  <>
    <p>
      Provide a list of potential change request that were initiated by any party (the team, the client, or 3rd party)
      in any form (by sending email, raising a concern in a voice call, etc.).
    </p>
    <p>
      This list should not include requests that were opened using a pre-defined channel of communication as part of the
      standard work (for example opening a ticket in helpdesk for a maintenance project).
    </p>
    <p>
      This list is necessary to make sure that no client’s requests are lost and are addressed even if they do not make
      it to a formal change request.
    </p>
    <p>The section includes the following fields:</p>
    <ul>
      <li>
        <b>Description: </b>a 1-5 sentence description of the discussion topic.
      </li>
      <li>
        <b>Initiated date: </b>a date when the discussion was first opened.
      </li>
      <li>
        <b>Comments: </b>any additional information or clarifications for the potential change request.
      </li>
      <li>
        <b>Closed date: </b>a date when discussion was closed either by creating a change requests or just closing it.
      </li>
    </ul>
  </>
);

export const changeRequestsTooltipMessage = (
  <>
    <p>Provide a list of all change requests that have been formally accepted by the client.</p>
    <p>The status is necessary to keep track of change requests that were not accepted as well.</p>
    <p>
      The description may optionally include summary of the specific changes (for example, for budget, timeline, or team
      structure).
    </p>
    <p>The section includes the following fields:</p>
    <ul>
      <li>
        <b>ID: </b>generated automatically by the system. For the first initiated report in the system, it can be
        changed manually.
      </li>
      <li>
        <b>Description: </b>a one-sentence description outlining the nature of the change request.
      </li>
      <li>
        <b>Created date: </b>a date when the change request was formally approved.
      </li>
      <li>
        <b>Comments: </b>any additional information or clarifications for the change request.
      </li>
      <li>
        <b>Status: </b>current status of the change request - `New`, `Accepted` or `Rejected`.
      </li>
    </ul>
  </>
);

export const risksTooltipMessage = (
  <>
    <p>Provide a list of risks that must be shared with the client.</p>
    <p>The section includes the following fields:</p>
    <ul>
      <li>
        <b>ID: </b>generated automatically by the system. For the first initiated report in the system, it can be
        changed manually.
      </li>
      <li>
        <b>Description: </b>a one-sentence description outlining the nature of the risk.
      </li>
      <li>
        <b>Created date: </b>the date when the risk was identified and reported.
      </li>
      <li>
        <b>Probability: </b>expert estimate of the probability of the risk occurring (low, medium, or high).
      </li>
      <li>
        <b>Impact: </b>expert estimate of the amplitude of impact of the risk occurrence on the budget, timeline or
        other project parameters (low, medium, or high).
      </li>
      <li>
        <b>Strategy: </b>description of risk mitigation in case of its occurrence.
      </li>
      <li>
        <b>Closed date: </b>a date when risk was closed.
      </li>
    </ul>
  </>
);

export const customerDependenciesTooltipMessage = (
  <>
    <p>Summarize a list of all activities that you expect from the client.</p>
    <p>The section includes the following fields:</p>
    <ul>
      <li>
        <b>Description: </b>a one-sentence description outlining the nature of the customer dependencies.{' '}
      </li>
      <li>
        <b>Name: </b>stakeholder name upon whom there is a dependency.
      </li>
      <li>
        <b>Create date: </b>the date when the dependency was identified and reported.{' '}
      </li>
      <li>
        <b>Waiting date: </b>the date when expecting the response from the stakeholder.
      </li>
      <li>
        <b>Closed date: </b>a date when dependency was closed.
      </li>
    </ul>
  </>
);

export const executiveSummaryTooltipMessage = (
  <>
    <p>
      The section is designed to give stakeholders a quick snapshot of the report’s contents, including key highlights,
      accomplishments, challenges, and goals for the upcoming period.
    </p>
  </>
);

export const customerExpectationsTooltipMessage = (
  <>
    <p>Optional section for adding any client expectations that were not covered in the main report sections.</p>
  </>
);

export const otherNotesTooltipMessage = (
  <>
    <p>Optional section for adding any information by the project.</p>
  </>
);

export const internalReportDataTooltipMessage = (
  <>
    <p>
      The purpose of internal data is to keep track of project progress, share information with relevant stakeholders,
      and take corrective actions if necessary. Please note that this data is not included in the client report.
    </p>
  </>
);
