import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import CorporateEventBreadCrumbs from 'financialAnalytic/corporateEvents/shared/CorporateEventBreadCrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import React from 'react';

const SingleCorporateEventBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useCorporateEventUrl();

  const id = data?.getValueBySource('id');
  const name = data?.getValueBySource('name');

  return (
    <CorporateEventBreadCrumbs>
      <SingleBreadcrumbItem entity={name || id} link={getSingleEntityUrl(id)} />
      {children}
    </CorporateEventBreadCrumbs>
  );
};

export default SingleCorporateEventBreadcrumbs;
