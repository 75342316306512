import { useResourceManagementApi } from 'api';
import useCrudService from 'shared/crud';
import { useCallback } from 'react';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import trainingInstancesValidation from './createupdate/trainingInstancesValidation';

const RESOURCE_URL = '/training-instances';

export const TRAINING_INSTANCE_STATUSES = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  STARTED: 'STARTED',
  TERMINATED: 'TERMINATED',
  COMPLETED: 'COMPLETED',
};

export const TRAINING_INSTANCE_STATUS_OPTIONS = {
  [TRAINING_INSTANCE_STATUSES.DRAFT]: 'Draft',
  [TRAINING_INSTANCE_STATUSES.SCHEDULED]: 'Scheduled',
  [TRAINING_INSTANCE_STATUSES.STARTED]: 'Started',
  [TRAINING_INSTANCE_STATUSES.TERMINATED]: 'Terminated',
  [TRAINING_INSTANCE_STATUSES.COMPLETED]: 'Completed',
};

const useTrainingInstancesService = () => {
  const { sendPutRequest } = useResourceManagementApi();

  const { search: baseSearch, create: initialCreate, update: initialUpdate, ...baseCrud } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useResourceManagementApi,
  });

  const mappingRequestData = useCallback((data: ResourceData) => {
    return {
      ...data,
      mentors: data.mentors.map((value: { value: string }) => ({ alias: value.value })),
      students: data.students.map((value: { value: string }) => ({ alias: value.value })),
      trainingProgram: {
        id: data.trainingProgram,
      },
    };
  }, []);

  const search = useCallback(
    (request: ResourceData) => {
      const params = cloneDeep(request);

      const searchString = params.filter?.['specification:search']?.eq || '';

      if (searchString && params.filter) {
        delete params.filter?.['specification:search'];
        params.filter['trainingProgram.name'] = {
          ct: searchString,
        };
      }

      ['mentors', 'students'].forEach(source => {
        const filterValue = params.filter?.[source]?.eq || '';

        if (filterValue && params.filter) {
          params.filter[source] = {
            ct: filterValue,
          };
        }
      });

      return baseSearch(params);
    },
    [baseSearch],
  );

  const create = useCallback(
    (data: ResourceData) => {
      return initialCreate(mappingRequestData(data));
    },
    [initialCreate, mappingRequestData],
  );

  const update = useCallback(
    (_id: string, data: ResourceData) => {
      return sendPutRequest(RESOURCE_URL, mappingRequestData(data));
    },
    [mappingRequestData, sendPutRequest],
  );

  const getValidationSchema = () => Promise.resolve(trainingInstancesValidation);

  return {
    ...baseCrud,
    update,
    search,
    create,
    getValidationSchema,
  };
};

export default useTrainingInstancesService;
