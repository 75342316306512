/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import AssignmentBreadcrumbs from 'erp/assignment/shared/AssignmentBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const SingleAssignmentBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getSingleEntityUrl } = useAssignmentUrl();
  const { id } = useParams<Dictionary<string>>();

  return (
    <AssignmentBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </AssignmentBreadcrumbs>
  );
};

export default SingleAssignmentBreadcrumbs;
