/* istanbul ignore file */
import React from 'react';
import useFieldHelper from '../../../../shared/uibuilder/field/fieldHelper';
import { TextField } from '../../../../shared/uibuilder/field';

const PaidLeaveBalanceField = props => {
  const { getValue } = useFieldHelper(props);
  const displayedValue = `${getValue()} working days`;

  return <TextField {...props} value={displayedValue} />;
};

export default PaidLeaveBalanceField;
