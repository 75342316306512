/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useQualificationModelUrl } from 'erp/qualification/model/QualificationModelRouter';

const CompetencyBreadcrumbs = ({ children }: { children: React.ReactElement }) => {
  const { getSingleEntityUrl } = useQualificationModelUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Competencies" link={getSingleEntityUrl('latest')} />
      {children}
    </Breadcrumbs>
  );
};

export default CompetencyBreadcrumbs;
