/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';

const EditEmailButton = ({ path, isVisible, ...props }: { isVisible?: boolean; path: string; [key: string]: any }) => {
  return isVisible ? (
    <LinkButton outline url={path} {...props}>
      Edit
    </LinkButton>
  ) : (
    <></>
  );
};

EditEmailButton.defaultProps = {
  isVisible: true,
};

export default EditEmailButton;
