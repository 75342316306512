import { useEffect, useState } from 'react';
import { useBusinessEngineApi } from 'api';
import useStorageService from 'shared/uibuilder/storageService';

const STORAGE_KEY = 'configuration';
const VERSION = '1.0';

const useConfiguration = (): any => {
  const [parameters, setParameters] = useState({});
  const { sendGetRequest } = useBusinessEngineApi();

  const getConfiguration = async () => {
    const response = await sendGetRequest('/parameters');
    return response.json();
  };

  const { getDataFromStorage } = useStorageService(STORAGE_KEY, getConfiguration, VERSION);
  useEffect(() => {
    getDataFromStorage().then(storageParameters => setParameters(storageParameters));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ...parameters,
  };
};

export default useConfiguration;
