/* istanbul ignore file */
import React, { useState } from 'react';
import ChangeCareerDevelopmentEvaluationModeButton from 'erp/employee/qualification/List/button/ChangeCareerDevelopmentEvaluationModeButton';
import { FieldProps } from 'shared/uibuilder/field';
import { careerDevelopmentModeLabels } from 'erp/employee/qualification/shared/employeeQualificationService';
import { UPDATE_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION } from 'erp/employee/employeeService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import 'erp/employee/qualification/Show/CareerDevelopmentReviewSection.scss';

const EvaluationModeField = ({ label, source = '', isVisible }: FieldProps) => {
  const data = useShowContext()?.data;
  const initialValue = data.getValueBySource(source) || '';
  const [value, setValue] = useState(initialValue);

  return isVisible ? (
    <div className="d-flex align-items-end career-label__wrapper">
      <span className="career-label">{label}</span>
      <span>{careerDevelopmentModeLabels[value] || 'Not defined'}</span>
      <ChangeCareerDevelopmentEvaluationModeButton
        permissionToCheck={UPDATE_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION}
        currentCareerDevelopmentMode={value}
        afterSubmit={{
          execute: (response: object) => {
            setValue((response as Dictionary<string>).careerDevelopmentEvaluationMode);
          },
        }}
      />
    </div>
  ) : null;
};

export default EvaluationModeField;
