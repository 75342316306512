/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import useEmailCommunicationService from 'erp/candidate/communication/email/useEmailCommunicationService';
import EmailForm from 'erp/candidate/communication/email/form/EmailForm';

const CreateEmailForm = ({ submitFormFunc, onCancel, ...props }: any) => {
  const { getValidationSchema } = useEmailCommunicationService();

  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <EmailForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateEmailForm;
