/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import SkillBasedQueueSearchLinkField from 'erp/skillbasedqueue/shared/SkillBasedQueueSearchLinkField';
import EditSkillBasedQueueButton from 'erp/skillbasedqueue/shared/button/EditSkillBasedQueueButton';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import CopySkillBasedQueueButton from 'erp/skillbasedqueue/shared/button/CopySkillBasedQueueButton';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import { CREATE_SKILL_BASED_QUEUE } from 'erp/skillbasedqueue/shared/skillBasedQueueService';

const SkillBasedQueueListLayout = () => {
  return (
    <DataGridLayout
      tableWrapperClassName="sbq-table-wrapper"
      buttons={[
        <CopySkillBasedQueueButton source="id" permissionToCheck={CREATE_SKILL_BASED_QUEUE} outline />,
        <EditSkillBasedQueueButton
          key="edit-skill-based-queue"
          source="id"
          outline
          isVisible={(data: ListItem) => data.canEdit}
          permissionToCheck=""
        />,
      ]}
    >
      <SkillBasedQueueSearchLinkField source="id" idSource="id" label="ID" isSortable />
      <TextField label="Skill Name" source="skillName" nameSource="skillName" isSortable />
      <BudgetLinkField label="Default Budget" source="defaultBudgetId" nameSource="defaultBudgetName" />
    </DataGridLayout>
  );
};

export default SkillBasedQueueListLayout;
