/* istanbul ignore file */
import React, { useState } from 'react';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import { Button } from 'uibuilder/button';
import { saveFile } from 'shared/fileHelper';
import InputLoader from 'shared/uibuilder/InputLoader';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

const DownloadEmployeePerformanceButton = (props: any) => {
  const { downloadPerformanceReport } = useEmployeePerformanceService();
  const [loading, setLoading] = useState(false);
  const { addMessage } = useMessageService();

  const { data } = useShowContext();
  const performanceData = data.getData();

  const { id } = performanceData;
  const isVisible =
    performanceData &&
    performanceData.performanceLevelCriteria &&
    performanceData.performanceLevelCriteria.helpfulnessGrade;

  const getFile = async () => {
    try {
      setLoading(true);
      await downloadPerformanceReport(id).then(async response => {
        if (response) {
          const contentDisposition = response.headers.get('content-disposition') || '';
          const filename = decodeURI((/filename="(.*)"/g.exec(contentDisposition) || [])[1]);

          await response.blob().then(async (blob: Blob) => {
            saveFile(blob, filename);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });
    } catch (e) {
      addMessage(new ErrorMessage('Something went wrong'));
      setLoading(false);
    }
  };

  const onClick = async () => {
    await getFile();
  };

  return isVisible ? (
    <Button {...props} onClick={onClick} outline>
      Download
      {loading && <InputLoader className="files-list__files-loader ml-2" />}
    </Button>
  ) : null;
};

export default DownloadEmployeePerformanceButton;
