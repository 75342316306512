export default {
  performedAt: {
    type: 'date',
    maxDate: {
      value: '$NOW',
      message: 'Cannot be in future',
    },
    required: true,
  },
  result: {
    type: 'enum',
    required: true,
  },
  attachmentsIds: {
    type: 'array',
    maxSize: 10,
    required: true,
  },
};
