/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import { useAssetId } from 'financialAnalytic/assets/AssetRouter';
import useAssetService from 'financialAnalytic/assets/useAssetService';

const ShowAsset = ({ children }: { children: React.ReactNode }) => {
  const { getById } = useAssetService();
  const id = useAssetId();

  return <Show getDataMethod={() => getById(id)}>{children}</Show>;
};

export default ShowAsset;
