/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { TimelineBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const EmployeeTimelineBreadcrumbs = () => {
  const { getTimelineUrl } = useEmployeeUrl();
  const id = useEmployeeId();

  return (
    <SingleEmployeeBreadcrumbs>
      <TimelineBreadcrumbItem link={getTimelineUrl(id)} />
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeTimelineBreadcrumbs;
