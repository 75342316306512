/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import {
  READ_PAYROLL_DISTRIBUTION,
  PAYROLL_DISTRIBUTION_PATH,
} from 'erp/payroll/payrollDistribution/usePayrollDistributionService';
import PayrollDistributionListPage from 'erp/payroll/payrollDistribution/List';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const usePayrollDistributionRoute = () => {
  return useRoute({ listRoute: PAYROLL_DISTRIBUTION_PATH });
};

export const usePayrollDistributionUrl = () => {
  return useEntityUrl({
    baseLink: PAYROLL_DISTRIBUTION_PATH,
  });
};

const PayrollDistributionRouter = () => {
  const { listRoute } = usePayrollDistributionRoute();

  return (
    <ProtectedRouter basePath={PAYROLL_DISTRIBUTION_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_PAYROLL_DISTRIBUTION}>
          <PayrollDistributionListPage />
        </GlobalProtectedRoute>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

PayrollDistributionRouter.getRouterPath = () => PAYROLL_DISTRIBUTION_PATH;

export default PayrollDistributionRouter;
