/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useAssetService from 'financialAnalytic/assets/useAssetService';
import { useAssetId, useAssetUrl } from 'financialAnalytic/assets/AssetRouter';
import ViewAssetPageContent from './ViewAssetPageContent';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';

const AssetViewPage = () => {
  const { getById } = useAssetService();
  const id = useAssetId();
  const { getListUrl } = useAssetUrl();

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout
        // TODO: Update after updating BE
        loadSensitiveDataMethod={() => Promise.resolve({})}
        sensitiveDataId={id}
        sensitiveDataIdSource="id"
        timerId={getListUrl()}
      >
        <ViewAssetPageContent />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default AssetViewPage;
