import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Grow from '@mui/material/Grow';

const Alert = (props: AlertProps) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setShowAlert(true);
  }, []);

  return showAlert ? (
    <Grow timeout={300} in={showAlert}>
      <MuiAlert {...props} sx={{ marginBottom: '1rem' }} />
    </Grow>
  ) : null;
};

export default Alert;
