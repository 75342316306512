/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';
import ExpenseBreadcrumbs from 'financialAnalytic/expenses/shared/ExpenseBreadcrumbs';

const SingleExpenseBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useExpenseUrl();
  const id = data?.getValueBySource('id');
  const expenseNumber = data?.getValueBySource('id');

  return (
    <ExpenseBreadcrumbs>
      <SingleBreadcrumbItem entity={expenseNumber || id} link={getSingleEntityUrl(id)} />
      {children}
    </ExpenseBreadcrumbs>
  );
};

export default SingleExpenseBreadcrumbs;
