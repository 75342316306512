/* istanbul ignore file */
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Link } from 'react-router-dom';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import { string } from 'prop-types';

const LeadLinkField = ({ idSource, idValue, nameValue, nameSource, ...props }) => {
  const { getValue: getIdValue } = useFieldHelper({ value: idValue, source: idSource });
  const { getValue: getNameValue } = useFieldHelper({ value: nameValue, source: nameSource });
  const { getSingleEntityUrl } = useLeadUrl();

  const url = getSingleEntityUrl(getIdValue());
  const name = getNameValue();

  return (
    <TextField
      {...props}
      value={
        <Link to={url} target="_blank">
          {name}
        </Link>
      }
    />
  );
};

LeadLinkField.propTypes = {
  idSource: string,
  idValue: string,
  nameValue: string,
  nameSource: string,
};

LeadLinkField.defaultProps = {
  idSource: undefined,
  idValue: undefined,
  nameValue: undefined,
  nameSource: undefined,
};

export default LeadLinkField;
