/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const AssignmentBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useAssignmentUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Assignment" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default AssignmentBreadcrumbs;
