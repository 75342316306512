/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { LEAD_SOURCE } from 'crm/lead/shared/leadService';

const LeadSourceDropdown = props => {
  return (
    <Dropdown
      placeholder="Choose Lead Source"
      options={Object.entries(LEAD_SOURCE).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label="Lead Source"
      {...props}
    />
  );
};

export default LeadSourceDropdown;
