/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import shortid from 'shortid';
import { PROGRESS_STATUSES } from 'crm/projectsDashboard/useProjectsDashboardService';
import ProgressIndicator from 'crm/projectsDashboard/shared/field/ProgressIndicator';
import './ProjectDashboardLegend.scss';

export const WORKING_STATUSES_LEGEND = [
  {
    label: 'Not started yet',
    value: PROGRESS_STATUSES.NOT_STARTED,
  },
  {
    label: 'In progress',
    value: PROGRESS_STATUSES.IN_PROGRESS,
  },
  {
    label: 'In progress with delay',
    value: PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY,
  },
];

export const COMPLETED_STATUSES_LEGEND = [
  {
    label: 'Completed without delay',
    value: PROGRESS_STATUSES.COMPLETED,
  },
  {
    label: 'Completed with delay',
    value: PROGRESS_STATUSES.COMPLETED_WITH_DELAY,
  },
];

const ProjectDashboardLegend = ({
  data: blocks = [WORKING_STATUSES_LEGEND, COMPLETED_STATUSES_LEGEND],
  rowDirection,
}: {
  data?: any[];
  rowDirection?: boolean;
}) => {
  const getItems = (
    data: {
      label: string;
      value: PROGRESS_STATUSES;
    }[],
  ) =>
    data.map(({ label, value }) => (
      <div key={label} className="projects-dashboard__legend-item">
        <ProgressIndicator status={value} />

        <span>{label}</span>
      </div>
    ));

  return (
    <div className="projects-dashboard__legend">
      {blocks.map(block => (
        <div
          key={shortid.generate()}
          className={classnames('projects-dashboard__legend-inner', {
            'projects-dashboard__legend-inner--row': rowDirection,
          })}
        >
          {getItems(block)}
        </div>
      ))}
    </div>
  );
};

export default ProjectDashboardLegend;
