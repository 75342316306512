/* istanbul ignore file */
import { Switch } from 'react-router-dom';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import React from 'react';
import EmployeeSkillsList from './skillsList';
import AddSkillsPage from './createupdate/Create';
import { CREATE_EMPLOYEE_SKILLS, READ_EMPLOYEE_SKILLS } from './EmployeeSkillsService';

const SKILLS_PATH = 'skills';

export const useSkillsRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}/${SKILLS_PATH}`,
  });
};

export const useSkillsUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();
  const listUrl = `${employeeLink}/${SKILLS_PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${employeeLink}/${SKILLS_PATH}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

const SkillsRouter = () => {
  const { listRoute, createRoute } = useSkillsRoute();

  return (
    <Switch>
      <ProtectedRoute path={`${listRoute}`} permissionToCheck={READ_EMPLOYEE_SKILLS} exact>
        <EmployeeSkillsList />
      </ProtectedRoute>

      <ProtectedRoute path={createRoute} exact permissionToCheck={CREATE_EMPLOYEE_SKILLS}>
        <AddSkillsPage />
      </ProtectedRoute>

      <Route component={Page404} />
    </Switch>
  );
};

export default SkillsRouter;
