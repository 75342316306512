import React, { useEffect } from 'react';
import useTrainingsService from '../useTrainingsService';
import Show from 'shared/uibuilder/show/Show';
import ViewTrainingContent from './ViewTrainingContent';
import { useTrainingsId } from '../TrainingsRouter';

const ViewTrainingPage = () => {
  const { getById, invalidateCache } = useTrainingsService();
  const id = useTrainingsId();

  useEffect(() => {
    return () => {
      invalidateCache();
    };

    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewTrainingContent />
    </Show>
  );
};

export default ViewTrainingPage;
