/* istanbul ignore file */
import React, { memo } from 'react';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import BaseProgressField from 'shared/uibuilder/field/ProgressField';
import { TextField, FullDateField } from 'shared/uibuilder/field';
import {
  APPLICATION_STAGE_STATUS,
  APPLICATIONS_STAGES,
  APPLICATIONS_STAGES_ORDER,
  APPLICATIONS_STAGES_ALIASES,
} from 'erp/recruitment/applications/shared/applicationService';
import Box from 'uibuilder/Box';

const ProgressFieldData = ({ status = '', duration = 0, stageData = {}, field }: any) => {
  const { formatDate } = useDateService();

  const { stagesEndDates } = stageData;
  const stageEndDate = stagesEndDates ? stagesEndDates[`${field}_END_TIME`] : null;

  return (
    <>
      <h3>{APPLICATIONS_STAGES[field]}</h3>
      <p>{stageEndDate ? formatDate(stageEndDate, DATE_FORMAT.FULL_DATE) : '-'}</p>
    </>
  );
};

const ProgressAdditionalFieldData = ({ field, stageData, status }: any) => {
  if (status === APPLICATION_STAGE_STATUS.NEW) {
    return null;
  }

  const {
    numberOfFollowups,
    generalInterviewDate,
    roleRelatedInterviewDate,
    interviewResult,
    prescreenDate,
  } = stageData;

  if (field === APPLICATIONS_STAGES_ALIASES.CANDIDATE_CONTACTED) {
    return (
      <TextField source="stageData.numberOfFollowups" label="Number of followups" value={numberOfFollowups || '0'} />
    );
  }

  if (field === APPLICATIONS_STAGES_ALIASES.INTERVIEW_PASSED) {
    return (
      <>
        <FullDateField
          source="stageData.generalInterviewDate"
          label="General Interview date"
          value={generalInterviewDate}
        />
        <FullDateField
          source="stageData.roleRelatedInterviewDate"
          label="Role Related Interview date"
          value={roleRelatedInterviewDate}
        />
        <TextField source="stageData.interviewResult" label="Interviews result" value={interviewResult} />
      </>
    );
  }

  if (field === APPLICATIONS_STAGES_ALIASES.PRE_SCREEN_SCHEDULED) {
    return <FullDateField source="stageData.prescreenDate" label="Pre-screen date" value={prescreenDate} />;
  }

  return null;
};

const ProgressField = memo(({ source }: any) => {
  return (
    <Box
      sx={{
        '& .project-dashboard-timeline__item .project-dashboard-timeline__item-text': {
          minWidth: '125px',
          width: '125px',
          height: 64,
          flexGrow: 0,
        },
      }}
    >
      <BaseProgressField
        source={source}
        steps={APPLICATIONS_STAGES_ORDER.filter(value => value !== APPLICATIONS_STAGES_ALIASES.UNKNOWN)}
        additionalData={<ProgressAdditionalFieldData />}
      >
        <ProgressFieldData />
      </BaseProgressField>
    </Box>
  );
});

export default ProgressField;
