/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';

const JournalEntryBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useJournalEntryUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Journal Entries" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default JournalEntryBreadcrumbs;
