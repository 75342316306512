/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useJournalEntryService from 'financialAnalytic/journalEntry/useJournalEntryService';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import UpdateLedgerBreadcrumbs from 'financialAnalytic/journalEntry/createupdate/Update/Breadcrumbs';
import JournalEntryForm from 'financialAnalytic/journalEntry/createupdate/JournalEntryForm';

const UpdateLedgerPage = () => {
  const { getById, getValidationSchema, update } = useJournalEntryService();
  const { getListUrl } = useJournalEntryUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Journal Entry has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        totals: 0,
        transfers: [{}, {}],
      }}
    >
      <CreatePageLayout breadcrumbs={<UpdateLedgerBreadcrumbs />} title="Edit: Journal Entry">
        <JournalEntryForm />
      </CreatePageLayout>
    </UpdateForm>
  );
};

export default UpdateLedgerPage;
