import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'uibuilder/button';
import { useSendEmailId } from 'sendEmail/SendEmailRouter';
import useSendEmailService from 'sendEmail/sendEmailService';
import SendEmailModalLayout from 'sendEmail/shared/buttons/SendEmailModalLayout';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';

const DEFAULT_COUNTDOWN_TIME = 15; // in seconds
const COUNTDOWN_DELAY = 1000; // in milliseconds

type SendEmailButtonProps = {
  isEditButtonVisible: boolean;
  toggleEditButton: (isVisible: boolean) => void;
  [key: string]: any;
};

const SendEmailButton = ({ isEditButtonVisible, toggleEditButton, ...props }: SendEmailButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [intervalId, setIntervalId] = useState<Nullable<number>>(null);
  const [isSendingFailed, setIsSendingFailed] = useState<boolean>(false);
  const [countdownTime, setCountdownTime] = useState<number>(DEFAULT_COUNTDOWN_TIME);
  const emailId = useSendEmailId();
  const { addMessage } = useMessageService();
  const { sendEmail, undoSending, clearData } = useSendEmailService();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const finishSending = useCallback(
    (isCancellation: boolean = false) => {
      closeModal();

      if (intervalId) {
        window.clearInterval(intervalId);
        setIntervalId(null);
      }

      setCountdownTime(isCancellation ? DEFAULT_COUNTDOWN_TIME : 0);

      if (!isCancellation) {
        clearData(emailId);
        addMessage(new SuccessMessage('You are awesome! The Email has been successfully sent.'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervalId],
  );

  useEffect(() => {
    if (countdownTime <= 0 && intervalId) {
      finishSending();
    }

    const isEditingAllowed = !intervalId && countdownTime > 0;

    if (isEditingAllowed !== isEditButtonVisible) {
      toggleEditButton(isEditingAllowed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishSending, countdownTime, intervalId]);

  useEffect(() => {
    if (isSendingFailed) {
      finishSending(true);
      setIsSendingFailed(false);
    }
  }, [finishSending, isSendingFailed]);

  const countdown = () => {
    setCountdownTime(prevState => {
      return prevState - 1;
    });
  };

  const openModal = () => {
    setIsModalOpen(true);

    if (countdownTime === DEFAULT_COUNTDOWN_TIME) {
      const id = window.setInterval(countdown, COUNTDOWN_DELAY);
      setIntervalId(id);
    }
  };

  const handleSendEmail = async () => {
    openModal();

    if (countdownTime === DEFAULT_COUNTDOWN_TIME) {
      try {
        await sendEmail(emailId);
      } catch (error) {
        setIsSendingFailed(true);
      }
    }
  };

  const undoEmailSending = () => {
    undoSending(emailId);
    finishSending(true);
  };

  return (
    <>
      <Button
        type="submit"
        sx={{ marginRight: '12px' }}
        disabled={countdownTime <= 0}
        onClick={handleSendEmail}
        {...props}
      >
        Send
      </Button>
      <SendEmailModalLayout
        isOpen={isModalOpen}
        closeModal={closeModal}
        countdownTime={countdownTime}
        undoEmailSending={undoEmailSending}
      />
    </>
  );
};

export default SendEmailButton;
