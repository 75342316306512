/* istanbul ignore file */
import React from 'react';
import Tooltip from 'uibuilder/Tooltip';
import { Button } from 'uibuilder/button';
import { copyToClipboard } from 'shared/clipboard';

type OneStopErrorMessageProps = {
  message: string;
  processId: string;
};

const OneStopErrorMessage = ({ message, processId }: OneStopErrorMessageProps) => {
  const containerClassName = 'one-stop-error__wrapper';
  const getSupportLink = () =>
    `${process.env.REACT_APP_DAVINCI_FRONTEND_REDMINE_SUPPORT_URL}`.replace(/{%processId%}/i, processId);

  return (
    <div className={containerClassName}>
      <p>{message}</p>
      <p>
        Please create a ticket in Project Process Support following&nbsp;
        <a href={getSupportLink()} target="_blank" rel="noopener noreferrer">
          this link
        </a>
      </p>
      <p>or contact your supervising manager</p>
      <p>
        your reference request ID is&nbsp;
        <Button
          link
          onClick={() => {
            copyToClipboard(processId, `.${containerClassName}`);
          }}
          id="copy-main"
          className="copy-anchor p-0 one-stop-error__copy-anchor"
        >
          <Tooltip title="Copy Request ID" placement="right" id="copy-main">
            <span>{`#${processId}`}</span>
          </Tooltip>
        </Button>
      </p>
    </div>
  );
};

export default OneStopErrorMessage;
