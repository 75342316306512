import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';

const WorkplaceForm = () => {
  return (
    <>
      <FormRow>
        <TextInput source="workplaceNumber" label="Workplace No." />
      </FormRow>
    </>
  );
};

export default WorkplaceForm;
