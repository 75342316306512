/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { TextField } from 'shared/uibuilder/field';
import ReconciliationTransfersList from 'erp/costaccounting/reconcilation/shared/ReconciliationTransfersList';
import ReconcileButton from 'erp/costaccounting/reconcilation/shared/button/ReconcileButton';
import Box from 'uibuilder/Box';
import BankTransactionDescriptionField from 'erp/costaccounting/reconcilation/shared/field/BankTransactionDescriptionField';
import { uniq } from 'lodash';
import ViewJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/ViewJournalEntryButton';
import { ReconciliationListPageContentProps } from './ReconciliationListPageContent';

const NotReconciliatedTransfersLists = ({
  bankData,
  bankLoading,
  davinciData,
  davinciLoading,
  isSensitiveDataIsShown,
  updateListDataAndReloadGroups,
}: ReconciliationListPageContentProps) => {
  const [selectedTransfers, setSelectedTransfers] = useState<Dictionary<string[]>>({
    bankTransactionIds: [],
    journalEntryIds: [],
    davinciTransferIds: [],
  });
  const [totalSelectedBankTransaction, setTotalSelectedBankTransaction] = useState<number>();

  const executeReconciliation = useCallback(() => {
    updateListDataAndReloadGroups?.({
      newBankData: {
        items: bankData.items?.filter(
          (item: Dictionary<any>) => !selectedTransfers.bankTransactionIds?.includes(item.id),
        ),
        totalPages: 0,
        totalElements: 0,
      },
      newDavinciData: {
        items: davinciData.items?.filter(
          (item: Dictionary<any>) => !selectedTransfers.davinciTransferIds?.includes(item.id),
        ),
        totalPages: 0,
        totalElements: 0,
      },
    });

    setSelectedTransfers({
      bankTransactionIds: [],
      journalEntryIds: [],
      davinciTransferIds: [],
    });
  }, [bankData, davinciData, selectedTransfers, updateListDataAndReloadGroups]);

  return (
    <Box
      sx={{
        mt: 2,
        mb: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          '@media (max-width: 1193px)': {
            flexDirection: 'column',
            rowGap: 5,
            mb: selectedTransfers.journalEntryIds.length && selectedTransfers.bankTransactionIds.length ? 2 : 0,
          },
          '@media (min-width: 1194px)': {
            justifyContent: 'space-between',
            columnGap: 5,
            alignItems: 'flex-start',
          },
          '& table.MuiTable-root': {
            minWidth: '420px',
          },
        }}
      >
        <ReconciliationTransfersList
          label="Not reconciled bank transactions"
          onRowSelection={bankTransactionIds => setSelectedTransfers(prev => ({ ...prev, bankTransactionIds }))}
          items={bankData.items}
          loading={bankLoading}
          dateSource="date"
          isSensitiveDataIsShown={isSensitiveDataIsShown}
          amountSource="bankTransactionSubTotals"
          idSource="id"
          setTotalSelectedBankTransaction={setTotalSelectedBankTransaction}
        >
          <BankTransactionDescriptionField label="Description" source="title" vendorSource="vendor" />
        </ReconciliationTransfersList>
        <ReconciliationTransfersList
          label="Not reconciled davinci transfers"
          onRowSelection={davinciTransferIds =>
            setSelectedTransfers(prev => ({
              ...prev,
              davinciTransferIds,
              journalEntryIds: uniq(
                davinciData.items.reduce(
                  (acc: string[], item: any) =>
                    davinciTransferIds.includes(item.id) ? [...acc, item.journalEntryId] : acc,
                  [],
                ),
              ),
            }))
          }
          items={davinciData.items}
          loading={davinciLoading}
          dateSource="asDateOf"
          isSensitiveDataIsShown={isSensitiveDataIsShown}
          amountSource="davinciTransfersSubTotal"
          idSource="id"
          totalSelectedBankTransaction={totalSelectedBankTransaction}
        >
          <TextField source="description" label="Description" />
          <ViewJournalEntryButton label=" " source="journalEntryId" target="_blank" width={80} />
        </ReconciliationTransfersList>
      </Box>
      {selectedTransfers.bankTransactionIds.length ? (
        <ReconcileButton
          selectedTransfers={selectedTransfers}
          bankItems={bankData.items}
          davinciItems={davinciData.items}
          execute={executeReconciliation}
          isSensitiveDataIsShown={isSensitiveDataIsShown}
        />
      ) : null}
    </Box>
  );
};

export default NotReconciliatedTransfersLists;
