/* istanbul ignore file */
import React from 'react';
import './ApplicationsCollapseHeader.scss';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Badge from 'shared/uibuilder/badge/Badge';

interface ApplicationsCollapseHeaderProps {
  title: string;
}

const ApplicationsCollapseHeader = ({ title }: ApplicationsCollapseHeaderProps) => {
  const { data, loading } = useListContext();
  const { totalElements = 0 } = data || {};

  return (
    <div className="d-flex align-items-center collapse-title-wrapper applications-collapse-title-wrapper">
      <h3 className="mr-2 collapse-title">{title}</h3>
      {loading ? null : <Badge>{totalElements}</Badge>}
    </div>
  );
};

export default ApplicationsCollapseHeader;
