import ShowDate from 'shared/uibuilder/ShowDate';
import React from 'react';
import { EmployeeLink } from 'erp/employee';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { TextField } from 'shared/uibuilder/field';
import { ProcessStarter } from 'camunda/monitoring/instance/processInstanceService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { getValue } from 'shared/uibuilder/helper';

interface Props {
  startDateTimeSource?: string;
  startDateTimeValue?: string;

  processStarterSource?: string;
  processStarterValue?: ProcessStarter;
}
const ProcessStartInfoField = ({
  startDateTimeSource,
  startDateTimeValue,
  processStarterSource,
  processStarterValue,
}: Props) => {
  const { data } = useShowContext();

  const startDateTime = getValue(
    startDateTimeValue,
    (src: any) => data && data.getValueBySource(src),
    startDateTimeSource,
    '',
  );
  const processStarter = getValue(
    processStarterValue,
    (src: any) => data && data.getValueBySource(src),
    processStarterSource,
    '',
  );

  return (
    <TextField
      label={<span className="font-italic">Started</span>}
      value={
        <>
          <ShowDate dateUTC={startDateTime} format={DATE_FORMAT.FULL} />
          {processStarter?.name && (
            <>
              <span> by </span>
              <EmployeeLink id={processStarter.alias} name={processStarter.name} />
            </>
          )}
        </>
      }
    />
  );
};

export default ProcessStartInfoField;
