import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useSowService, { WRITE_SOW } from '../../useSowService';
import { useSowUrl } from '../../SowRouter';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const DeleteSowButton = () => {
  const { data } = useShowContext();
  const sowId = data.getValueBySource('id');
  const { deleteById, invalidateCache } = useSowService();
  const { getEmptyEntityUrl } = useSowUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(sowId)}
      displayMessage="Do you really want to delete SOW?"
      permissionToCheck={WRITE_SOW}
      color="error"
      afterDelete={{
        execute: () => {
          invalidateCache();
        },
        redirectUrl: getEmptyEntityUrl(),
        successMessage: 'The SOW has been successfully deleted.',
        errorMessage: `Can't delete SOW.`,
      }}
    />
  );
};

export default DeleteSowButton;
