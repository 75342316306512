/* istanbul ignore file */
import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DateInput } from 'shared/uibuilder/form/input';
import { CommonDateInputProps } from 'shared/uibuilder/form/input/dateHelper';
import moment, { Moment } from 'moment';
import useDateService from 'shared/uibuilder/dateService/useDateService';

export const DATE_FORMAT = 'yyyy-MM';

const ExpenseStartEndDateInput = ({ source, isEndDate, ...props }: CommonDateInputProps & { isEndDate?: boolean }) => {
  const { formatDateForAPI } = useDateService();
  const { getRawOnChangeCallback, getValue } = useInputHelper({ ...props, source });
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});
  const value = getValue();

  const getFormattedValue = (date: Moment) => (date ? formatDateForAPI(date) : '');

  const onChange = (values: any) => {
    const newValue = values[source];
    const momentDate = moment(newValue, DATE_FORMAT);

    if (!momentDate.isValid()) {
      rawOnChangeCallback({
        [source]: newValue,
      });

      return;
    }

    if (isEndDate) {
      rawOnChangeCallback({
        [source]: getFormattedValue(momentDate.endOf('month')),
      });
      return;
    }

    rawOnChangeCallback({
      [source]: getFormattedValue(momentDate.startOf('month')),
    });
  };

  return (
    <DateInput
      dateFormat={DATE_FORMAT}
      source={source}
      {...props}
      onChangeCallback={onChange}
      value={value && moment(value).isValid() ? moment(value).format(DATE_FORMAT) : value}
    />
  );
};

export default ExpenseStartEndDateInput;
