/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useProjectUrl } from 'erp/project/ProjectRouter';

export interface EditProjectButtonProps extends EditButtonProps {}

const EditProjectButton = (props: EditProjectButtonProps) => {
  const { getUpdateEntityUrl } = useProjectUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} {...props} />;
};

export default EditProjectButton;
