import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useHiringUrl } from '../../HiringRouter';

const ViewHiringButton = ({ ...props }) => {
  const { getSingleEntityUrl } = useHiringUrl();

  return (
    <LinkButton outline url={id => getSingleEntityUrl(id)} key="view-hiring" {...props}>
      View
    </LinkButton>
  );
};

export default ViewHiringButton;
