/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DateField, FieldProps, TextField } from 'shared/uibuilder/field';

interface ContractEndDateBadgeFieldProps extends FieldProps {}

const ContractEndDateBadgeField = (props: ContractEndDateBadgeFieldProps) => {
  const { getValue: getIsIndefiniteValue } = useFieldHelper({ source: 'indefinite', value: false });
  const indefinite = getIsIndefiniteValue();

  return indefinite ? <TextField value="∞" /> : <DateField {...props} />;
};

export default ContractEndDateBadgeField;
