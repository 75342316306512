/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useProjectService from 'erp/project/shared/projectService';
import { useProjectId } from 'erp/project/ProjectRouter';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';
import ViewProjectPageContent from './ViewProjectPageContent';

const ViewProject = () => {
  const id = useProjectId();
  const { getById, getName } = useProjectService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout loadSensitiveDataMethod={getName} sensitiveDataId={id} sensitiveDataIdSource="id">
        <ViewProjectPageContent />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default ViewProject;
