/* istanbul ignore file */
import React from 'react';
import CandidatesBreadcrumbs from 'erp/candidate/shared/CandidatesBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateCandidateBreadcrumbs = () => {
  return (
    <CandidatesBreadcrumbs>
      <CreateBreadcrumbItem entity="Candidate" />
    </CandidatesBreadcrumbs>
  );
};

export default CreateCandidateBreadcrumbs;
