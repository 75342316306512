/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import ModalWindow from 'shared/uibuilder/ModalWindow';

type SendEmailModalLayoutProps = {
  isOpen: boolean;
  countdownTime: number;
  closeModal: () => void;
  undoEmailSending: () => void;
};

const SendEmailModalLayout = ({ closeModal, undoEmailSending, isOpen, countdownTime }: SendEmailModalLayoutProps) => {
  return (
    <ModalWindow
      isOpen={isOpen}
      onToggle={closeModal}
      title="Email is sent"
      hasCancelButton={false}
      buttons={
        countdownTime > 0 ? (
          <Button color="primary" outline onClick={undoEmailSending}>
            Undo
          </Button>
        ) : (
          []
        )
      }
    >
      <p>This e-mail has been sent successfully!</p>
      {countdownTime > 0 && <p>{`In case you need it you have ${countdownTime} seconds to undo sending.`}</p>}
    </ModalWindow>
  );
};

export default SendEmailModalLayout;
