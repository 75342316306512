import { useState } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useArtifactService from 'artifact/artifactService';
import useArtifactInputHelper from 'artifact/shared/input/ArtifactUploaderInput/artifactsInputHelper';
import useArtifactValidationService from 'artifact/shared/input/ArtifactUploaderInput/artifactValidationService';
import useUploadingQueueService from 'artifact/shared/input/ArtifactUploaderInput/FileUploader/uploadingQueueService';
import { CROP_SIZES } from 'artifact/const';

const useArtifactHelper = ({ artifactTypeId, artifactsSource } = {}) => {
  const [loadingArtifact, setLoadingArtifact] = useState(null);

  const { setSubmitEnabled = () => {} } = useFormContext();

  const { uploadArtifact, getArtifactsDataByIds, getThumbnail } = useArtifactService();
  const {
    addArtifactToContext,
    getAcceptExtensions,
    getMaxMb,
    getSource,
    getValue: getArtifacts,
  } = useArtifactInputHelper({
    source: artifactsSource,
    artifactTypeId,
  });

  const { validate: validateArtifact } = useArtifactValidationService({
    previewsCount: getArtifacts() && getArtifacts().length,
    maxMb: getMaxMb(),
    source: getSource(),
    fileTypes: getAcceptExtensions(),
  });

  const { addToUploadingQueue, removeFromUploadingQueue, modifyLoadingProgress } = useUploadingQueueService();

  const handleUploading = async file => {
    const { temporaryId, signal } = await addToUploadingQueue(file);
    setLoadingArtifact(temporaryId);
    setSubmitEnabled(false);

    const artifactId = await uploadArtifact({
      file,
      artifactTypeId,
      signal,
      onProgress: loadingProgress => {
        modifyLoadingProgress(temporaryId, loadingProgress);
      },
    });

    removeFromUploadingQueue(temporaryId);

    const src = await getThumbnail(artifactId, CROP_SIZES.SIZE_100);

    addArtifactToContext(artifactId);
    setLoadingArtifact(null);
    setSubmitEnabled(true);

    return [artifactId, src];
  };

  const cancel = () => {
    if (loadingArtifact) {
      removeFromUploadingQueue(loadingArtifact);
      setLoadingArtifact(null);
      setSubmitEnabled(true);
    }
  };

  const validate = files => {
    return validateArtifact(files);
  };

  return {
    isLoading: loadingArtifact != null,
    uploadArtifact: handleUploading,
    getArtifactsDataByIds,
    getThumbnail,
    cancel,
    validate,
    getArtifacts,
  };
};

export default useArtifactHelper;
