import React from 'react';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';

import './index.scss';

type RowGroupInputsWrapperProps = {
  wrapperLabel: string;
  isRequired: boolean;
  children: React.ReactNode;
};

const RowGroupInputsWrapper = ({ isRequired, wrapperLabel, children }: RowGroupInputsWrapperProps) => {
  return (
    <Box>
      <Typography
        sx={{
          mb: '3px',
        }}
      >
        {wrapperLabel}
        {isRequired ? ' *' : ''}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',

          '& > div:not(:last-child)': {
            mr: '10px',
          },

          '& > div': {
            flexWrap: 'wrap',
          },

          '& > div > .label__wrapper': {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: 'auto',
          },
          '& > div > input': {
            flex: '1 1 0',
          },
          '& > div > .Mui-error': {
            flexBasis: '100%',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RowGroupInputsWrapper;
