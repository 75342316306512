import React, { useEffect } from 'react';
import classnames from 'classnames';
import TimelineRecordHeader from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordHeader';
import TimelineRecordFooter from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordFooter';
import TimelineRecordActions from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordActions';
import { arrayOf, bool, element, func, oneOfType, string } from 'prop-types';
import 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/index.scss';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import useAuthorization from 'shared/authorization/authorizationService';
import TimelineRecordFieldLayout from 'uibuilder/layout/timeline/layout/field/TimelineRecordFieldLayout';
import { scrollToHash } from 'shared/uibuilder/domUtils';
import HtmlField from 'shared/uibuilder/field/HtmlField';
import useTimelineLayoutHelper from './timelineLayoutHelper';
import { Collapsible } from 'shared/uibuilder/field';
import { EVALUATION_CRITERION, EVALUATION_CRITERION_ALIAS } from 'instantFeedback/input/EvaluationCriterionFrom';

const SCROLL_OFFSET = 100;

const BaseTimelineRecordLayout = ({
  fields,
  actionName,
  actions,
  buttons,
  updateComponent,
  isEditing,
  isHighlighted,
  artifactsSource,
  permissionToCheck,
  basePathname,
  timelineHeader: TimeLineHeader,
  timelineFooter: TimeLineFooter,
  timelineActions: TimelineActions,
  timelineType: initialTimelineType = 'note',
}) => {
  const { data: entity } = useShowContext();
  const subject = entity.getValueBySource('subject');
  const description = entity.getValueBySource('description');
  const feedbackGrades = entity.getValueBySource('feedbackGrades');
  const timelineId = entity.getValueBySource('timelineId');
  const timelineType = typeof initialTimelineType === 'function' ? initialTimelineType(entity) : initialTimelineType;

  const { isGranted } = useAuthorization();

  const { getButtons } = useTimelineLayoutHelper();

  const attachmentsIds = entity.getValueBySource(artifactsSource);

  const entryRef = React.createRef();

  useEffect(() => {
    if (isHighlighted) {
      scrollToHash(entryRef.current, SCROLL_OFFSET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHighlighted]);

  return permissionToCheck == null || isGranted(permissionToCheck) ? (
    <UiThemeOverrider
      overriddenLayouts={{
        BaseFieldLayout: TimelineRecordFieldLayout,
        TextFieldLayout: TimelineRecordFieldLayout,
      }}
    >
      <li
        className={classnames('timeline__entry', timelineType?.toLowerCase())}
        id={`entry${timelineId}`}
        ref={entryRef}
      >
        {isEditing && updateComponent ? (
          updateComponent
        ) : (
          <>
            <div
              color={isHighlighted ? 'primary' : ''}
              className={classnames('card', { ' timeline-highlighted-entry': isHighlighted })}
            >
              <div className="card-body">
                <div className="timeline__type" title={timelineType} />
                <div className="d-flex justify-content-between">
                  <TimeLineHeader actionName={actionName} timelineId={timelineId} entity={entity} />
                  <TimelineActions
                    entity={entity}
                    buttons={getButtons(buttons)}
                    actions={actions}
                    timelineId={timelineId}
                    basePathname={basePathname}
                  />
                </div>
                <div className="timeline-text-container">
                  {subject?.trim() || fields ? (
                    <div className="mb-2">
                      {subject ? (
                        <h3 className="timeline__entry-heading">
                          <HtmlField value={subject} />
                        </h3>
                      ) : null}
                      {fields}
                    </div>
                  ) : null}
                  {description && (
                    <div className="mb-2">
                      <Collapsible>
                        <HtmlField value={description} />
                      </Collapsible>
                    </div>
                  )}
                </div>
                {attachmentsIds && attachmentsIds.length ? <AttachmentsListField value={attachmentsIds} /> : null}
                {feedbackGrades ? (
                  <div className="timeline__feedback-grades">
                    {Object.entries(feedbackGrades).map(([key, value]) => {
                      const evaluationCriteria = EVALUATION_CRITERION.find(
                        item => item.value === key && item.value !== EVALUATION_CRITERION_ALIAS.NOT_ABOUT_CRITERION,
                      );
                      return value.description ? (
                        <div className="timeline__feedback-grade">
                          <b>{evaluationCriteria?.label}</b>
                          {value.description ? <HtmlField label="" value={value.description} /> : null}
                          {value.attachmentsIds && value.attachmentsIds.length ? (
                            <AttachmentsListField value={value.attachmentsIds} />
                          ) : null}
                        </div>
                      ) : null;
                    })}
                  </div>
                ) : null}
                <TimeLineFooter entity={entity} noTopIndent={!attachmentsIds || !attachmentsIds.length} />
              </div>
            </div>
          </>
        )}
      </li>
    </UiThemeOverrider>
  ) : null;
};

BaseTimelineRecordLayout.propTypes = {
  fields: oneOfType([element, arrayOf(element)]),
  buttons: oneOfType([element, arrayOf(element)]),
  actions: oneOfType([element, arrayOf(element)]),
  actionName: string.isRequired,
  updateComponent: element,
  isEditing: bool,
  isHighlighted: bool,
  artifactsSource: string,
  basePathname: string,
  timelineHeader: func,
  timelineFooter: func,
  timelineActions: func,
  timelineType: oneOfType([func, string]).isRequired,
};

BaseTimelineRecordLayout.defaultProps = {
  fields: null,
  buttons: null,
  actions: null,
  isEditing: false,
  isHighlighted: false,
  updateComponent: null,
  artifactsSource: 'attachmentsIds',
  basePathname: '',
  timelineHeader: TimelineRecordHeader,
  timelineFooter: TimelineRecordFooter,
  timelineActions: TimelineRecordActions,
};

export default BaseTimelineRecordLayout;
