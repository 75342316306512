/* istanbul ignore file */
import React from 'react';
import EmployeeDropdown from 'shared/uibuilder/form/input/EmployeeDropdown';

const RequestedByDropdown = (props: any) => {
  const filterGroup = {
    filterType: 'FILTER_GROUP',
    condition: 'AND',
    filters: [
      {
        filterType: 'FILTER_GROUP',
        condition: 'OR',
        filters: [
          {
            filterType: 'FILTER',
            property: 'specification:isResourceManager',
            value: true,
            operator: 'EQ',
          },
          {
            filterType: 'FILTER',
            property: 'specification:isCLevelManager',
            value: true,
            operator: 'EQ',
          },
        ],
      },
      {
        filterType: 'FILTER_GROUP',
        condition: 'AND',
        filters: [
          {
            filterType: 'FILTER',
            property: 'archivedStatus',
            value: 'ACTIVE',
            operator: 'EQ',
          },
          {
            filterType: 'FILTER',
            property: 'employeeType',
            value: 'CONTRACTOR',
            operator: 'NE',
          },
        ],
      },
    ],
  };

  return <EmployeeDropdown showLoader filter={filterGroup} isSimple={false} {...props} />;
};

export default RequestedByDropdown;
