/* istanbul ignore file */
import React from 'react';
import { element } from 'prop-types';
import './PassportNumberLayout.scss';

const PassportNumberLayout = ({ passportNumberInput, passportSeriesInput }) => {
  return (
    <>
      <div className="passport-series">{passportSeriesInput}</div>
      <div className="passport-number">{passportNumberInput}</div>
    </>
  );
};

PassportNumberLayout.propTypes = {
  passportNumberInput: element.isRequired,
  passportSeriesInput: element.isRequired,
};

export default PassportNumberLayout;
