/* istanbul ignore file */
import React from 'react';
import useEmployeeGroupService from '../employeeGroupService';
import LoadDropdown from '../../../../shared/uibuilder/form/input/LoadDropdown';

const EmployeeGroupDropdown = (props: any) => {
  const { getAll } = useEmployeeGroupService();

  return (
    <LoadDropdown
      loadDataMethod={getAll}
      mapResults={group => ({
        value: group.id,
        label: group.name,
      })}
      showLoader
      {...props}
    />
  );
};

export default EmployeeGroupDropdown;
