import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import React from 'react';

const CorporateEventBreadCrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useCorporateEventUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Corporate Events" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default CorporateEventBreadCrumbs;
