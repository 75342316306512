import React from 'react';
import Alert from 'uibuilder/Alert';
import Table from '@mui/material/Table';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Pagination from 'shared/uibuilder/list/Pagination';
import TableHead, { TableHeadProps } from './TableHead';
import TableBody, { TableBodyProps } from './TableBody';
import { TableBodyWithGroupingProps } from './TableBodyWithGrouping';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import ListFieldLayout from 'uibuilder/layout/field/ListFieldLayout';
import TotalRecords from 'shared/uibuilder/list/layout/TotalRecords';
import Box from 'uibuilder/Box';
import ListLoadingDecorator from 'shared/uibuilder/list/layout/ListLoadingDecorator';
import './index.scss';

interface DataGridLayoutProps extends TableHeadProps, TableBodyProps {
  isSmallTable?: boolean;
  tableWrapperClassName?: string;
  isSmallLoader?: boolean;
  rowKeySource?: string;
  tableBodyComponent?: React.FC<TableBodyProps | TableBodyWithGroupingProps>;
  emptyListMessageComponent?: React.ReactNode;
  showTotal?: boolean;
  showPagination?: boolean;
}

/**
 * Layout to display list as table.
 * @param children - fields
 * @param buttons - action buttons for rows
 * @param rowProps - property that each row will have. Can be either object like {className: 'test'} or function that
 * accept data and return object like data => data.highlighted ? {className: 'highlighted'} : {}.
 * It allows to highlight some row according to some rule.
 * @param isSmallTable - will add class table-sm for table container
 * @param tableWrapperClassName - className for table wrapper
 * @param isSmallLoader - if is indicated, SmallLoader will be used instead of Loading
 * @param rowKeySource - source in data for row key value
 * @returns {*}
 * @constructor
 */
const DataGridLayout = ({
  children,
  buttons,
  rowProps = {},
  isSmallTable = false,
  isSmallLoader = false,
  tableWrapperClassName = '',
  rowKeySource,
  tableBodyComponent: TableBodyComponent = TableBody,
  tableRowComponent,
  emptyListMessageComponent = '',
  showTotal = true,
  showPagination = true,
}: DataGridLayoutProps) => {
  const { data: { items = [], totalElements } = {}, loading } = useListContext();

  const emptyListComponent = () => {
    if (emptyListMessageComponent) {
      return emptyListMessageComponent;
    }

    return (
      <Alert color="info" className="mt-3">
        No records found
      </Alert>
    );
  };

  return (
    <ListLoadingDecorator items={items} loading={loading} isSmallLoader={isSmallLoader}>
      <UiThemeOverrider
        overriddenLayouts={{
          BaseFieldLayout: ListFieldLayout,
        }}
      >
        <div className={isSmallTable ? 'table-responsive list-general list-general--sm' : 'table-responsive'}>
          <div className={`table-wrapper ${tableWrapperClassName}`}>
            {items.length ? (
              <Table
                size={isSmallTable ? 'small' : 'medium'}
                className={`${isSmallTable ? 'table table-hover' : 'table-hover list-general'}`}
              >
                <TableHead buttons={buttons}>{children}</TableHead>
                <TableBodyComponent
                  rowProps={rowProps}
                  buttons={buttons}
                  rowKeySource={rowKeySource}
                  tableRowComponent={tableRowComponent}
                >
                  {children}
                </TableBodyComponent>
              </Table>
            ) : (
              emptyListComponent()
            )}
          </div>
        </div>
        {showPagination && !!totalElements && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginTop: '1px',
              marginBottom: '10px',
              minHeight: '32px',
              paddingTop: '25px',
              '.MuiPagination-root': { marginRight: '20px' },
            }}
            className="table-pagination-wrapper"
          >
            <Pagination />
            {showTotal && <TotalRecords />}
          </Box>
        )}
      </UiThemeOverrider>
    </ListLoadingDecorator>
  );
};

export default DataGridLayout;
