/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import useActivityService, {
  DELETE_CRM_ACTIVITY_PERMISSION,
  UPDATE_CRM_ACTIVITY_PERMISSION,
} from 'crm/shared/timeline/activity/crmActivityService';
import ActivityFields from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout/ActivityFields';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import PastDueMark from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout/PastDueMark';
import { func } from 'prop-types';
import { Collapsible } from 'shared/uibuilder/field';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';

const ActivityTimelineRecordLayout = ({ onEdit, ...props }) => {
  const { data } = useShowContext();
  const { deleteById } = useActivityService();

  const buttons = [
    <EditTimelineRecordButton permissionToCheck={UPDATE_CRM_ACTIVITY_PERMISSION} key="edit" onEdit={onEdit} />,
    <DeleteTimelineRecordButton
      permissionToCheck={DELETE_CRM_ACTIVITY_PERMISSION}
      deleteMethod={deleteById}
      key="add"
      id={data.getValueBySource('id')}
      timelineId={data.getValueBySource('timelineId')}
      entityName="note"
    />,
  ];

  const actions = [<PastDueMark />];

  const type = data.getValueBySource('activityType');

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <ActivityFields entity={data} />
        </Collapsible>
      }
      entity={data}
      buttons={buttons}
      actions={actions}
      timelineType={TimelineType[type]}
      {...props}
    />
  );
};

ActivityTimelineRecordLayout.propTypes = {
  onEdit: func.isRequired,
};

export default ActivityTimelineRecordLayout;
