import React from 'react';
import PrimaryBadge from 'erp/employee/qualification/shared/field/PrimaryBadge';
import RevokeButton from 'erp/employee/qualification/List/button/DeleteQualificationButton';
import useEmployeeSpecializationService, {
  EmployeeSpecialization,
} from 'erp/employee/qualification/shared/employeeSpecializationService';
import { SET_PRIMARY_EMPLOYEE_SPECIALIZATION } from 'erp/employee/employeeService';
import SetPrimaryQualificationButton from 'erp/employee/qualification/List/button/SetPrimaryQualificationButton';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useQualificationId } from 'erp/employee/qualification/QualificationRouter';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';

const QualificationListHeader = ({ data }: { data: Nullable<EmployeeSpecialization> }) => {
  const employeeId = useEmployeeId() || '';
  const specializationId = useQualificationId();
  const { updatePrimaryQualification } = useEmployeeSpecializationService();
  const { id = '', title, primary = false } = data || {};

  return (
    <Box
      className="align-items-center justify-content-between flex-grow-1"
      sx={{ display: { sm: 'flex' }, marginBottom: { xs: '0.5rem', sm: '1rem' } }}
    >
      <div className="d-flex align-items-center">
        <Typography variant="h4">{title}</Typography>
        {primary && <PrimaryBadge />}
      </div>
      {!primary && (
        <div className="qualification__controls flex-grow-0 flex-shrink-0 d-flex align-items-center">
          <SetPrimaryQualificationButton
            permissionToCheck={SET_PRIMARY_EMPLOYEE_SPECIALIZATION}
            submitMethod={() => updatePrimaryQualification(employeeId, specializationId)}
          />
          <RevokeButton qualificationId={id} />
        </div>
      )}
    </Box>
  );
};

export default QualificationListHeader;
