/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import ArtifactStorageUploaderInput from 'artifact/shared/input/ArtifactStorageUploaderInput';
import AccountNameInput from 'crm/account/shared/input/AccountNameInput';
import ArtifactStorageTypeDropdown from 'artifactStorage/shared/input/ArtifactStorageTypeDropdown';
import ArtifactSowInput from 'artifactStorage/shared/input/ArtifactSowInput';
import { ArtifactFileType, ArtifactFileTypeLabel, ATTACH_ARTIFACT } from '../shared/artifactStorageService';
import useAuthorization from 'shared/authorization/authorizationService';
import ArtifactStorageFileInput from 'artifactStorage/shared/input/ArtifactStorageFileInput';

const ArtifactStorageForm = () => {
  const { isGranted } = useAuthorization();

  const getOptions = () => {
    return Object.entries(ArtifactFileTypeLabel)
      .filter(([value, label]: string[]) => isGranted(`ARTIFACT_STORAGE_FILE_UPLOAD_${value}`))
      .map(([value, label]: string[]) => ({ label, value }));
  };

  return (
    <FormSection title="Artifact information">
      <FormRow>
        <ArtifactStorageTypeDropdown source="type" options={getOptions()} />
        <AccountNameInput
          source="relatedAccount"
          label="Related Account"
          accountNameValue=""
          accountNameSource="accountName"
          isVisible={isGranted(ATTACH_ARTIFACT)}
        />
      </FormRow>
      <FormRow>
        <TextInput
          source="sowNumber"
          label="SOW Number"
          isVisible={data => data.type === ArtifactFileType.STATEMENT_OF_WORK}
        />
        <TextInput
          source="changeRequestNumber"
          label="Change Request Number"
          isVisible={data => data.type === ArtifactFileType.CHANGE_REQUEST}
        />
        <TextInput source="msaNumber" label="MSA Number" isVisible={data => data.type === ArtifactFileType.MSA} />
        <TextInput
          source="amendmentNumber"
          label="Amendment Number"
          isVisible={data => data.type === ArtifactFileType.AMENDMENT}
        />
        <DateInput
          source="acceptanceFromDate"
          label="Acceptance From Date"
          isVisible={data => data.type === ArtifactFileType.ACCEPTANCE}
        />
        <DateInput
          source="acceptanceToDate"
          label="Acceptance To Date"
          isVisible={data => data.type === ArtifactFileType.ACCEPTANCE}
        />
        <ArtifactSowInput
          source="sow"
          label="SOW"
          isVisible={data =>
            isGranted(ATTACH_ARTIFACT) &&
            (data.type === ArtifactFileType.CHANGE_REQUEST ||
              data.type === ArtifactFileType.ACCEPTANCE ||
              data.type === ArtifactFileType.AMENDMENT)
          }
          showMetaInfo
          errorSource="artifactRelation"
        />
        <ArtifactStorageFileInput
          source="sow"
          label="SOW"
          customPlaceholder="Select SOW"
          relationClass="ArtifactStorageFile"
          showMetaInfo
          showRelationToClass="Account"
          searchType={ArtifactFileType.STATEMENT_OF_WORK}
          isVisible={data =>
            !isGranted(ATTACH_ARTIFACT) &&
            (data.type === ArtifactFileType.CHANGE_REQUEST || data.type === ArtifactFileType.ACCEPTANCE)
          }
        />
      </FormRow>
      <FormRow>
        <ArtifactStorageUploaderInput source="artifactId" label="File" />
      </FormRow>
    </FormSection>
  );
};

export default ArtifactStorageForm;
