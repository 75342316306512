const useUpdateHiringValidationSchema = () => {
  return {
    requestPositions: {
      type: 'array',
      forEach: {
        type: 'object',
        _divisible: false,
        properties: {
          necessityStatus: {
            type: 'string',
            required: true,
          },
          positionStatus: {
            type: 'string',
            required: true,
          },
          forecastDate: {
            type: 'date',
            required: false,
            minDate: {
              value: '$TODAY',
              message: 'Please choose date in future.',
            },
          },
        },
      },
    },
  };
};

export default useUpdateHiringValidationSchema;
