import { useRef, useEffect } from 'react';

/**
 * Stores the previous value
 *
 * @param {object} value
 *
 * @example
 * State value and setter for our example
 * const [count, setCount] = useState(0);
 * Get the previous value (was passed into hook on last render)
 * const prevCount = usePrevious(count);
 *
 */
const usePrevious = (value: any) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
