import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import { unFlattenObject } from 'shared/uibuilder/form/formHelper';

export interface InputListHelperProps extends Omit<DefaultInputPropTypes<(object | string)[]>, 'defaultValue'> {
  maxFields?: Nullable<number>;
  minFields?: Nullable<number>;
  defaultValue?: string | object;
  shouldUseFlatObject?: boolean;
}

interface InputListHelperData {
  addInput: () => void;
  removeInput: (index: number) => void;
  onChangeCallback: (index: number, inputValue: Dictionary<any>) => void;
}

// TODO: get rid of `shouldUseFlatObject` when forms stop working with flat objects
const useInputList = ({
  maxFields: initialMaxFields,
  defaultValue,
  shouldUseFlatObject = true,
  ...props
}: InputListHelperProps): InputListHelperData => {
  const inputHelper = useInputHelper(props);
  const value = inputHelper.getValue();
  const source = inputHelper.getSource();
  const { getMaxElements } = useFormValidation(source);
  const maxFields = initialMaxFields || getMaxElements();
  const rawOnChangeCallback = inputHelper.getRawOnChangeCallback();

  const setFields = (fields: object[]) => {
    let data: object = {
      [source]: [...fields],
    };

    if (!shouldUseFlatObject) {
      data = unFlattenObject(data);
    }

    if (rawOnChangeCallback) {
      rawOnChangeCallback(data);
    }
  };

  const addInput = () => {
    const fields = [...(value as object[])];
    if (fields.length < maxFields) {
      setFields([...fields, defaultValue as object]);
    }
  };

  const removeInput = (index: number) => {
    const fields = [...(value as object[])];

    const newFields = [...fields];
    newFields.splice(index, 1);

    setFields(newFields);
  };

  /**
   * Layout must call this method with 2 params:
   * @param index -- index of input
   * @param inputValue -- value from index'th input onChangeCallback
   * child input might be composite, in this case values in [fields] array
   * will be objects, therefore onChangeCallback must handle this case
   * in this case keys in @inputValue will have the following structure:
   * {
   *  ${source}.${index}.${sub-source},
   *  ...
   * }
   * and result value must be:
   * {
   *   [sub-source]: value,
   *   ...
   * }
   */

  const onChangeCallback = (index: number, inputValue: Dictionary<any>) => {
    const keys = Object.keys(inputValue);
    let newValue;

    if (keys.length > 1) {
      // child input is composite
      const parentKey = `${source}.${index}.`;
      newValue = keys.reduce((result, key) => {
        const subKey = key.substring(parentKey.length);

        return {
          ...result,
          [subKey]: inputValue[key],
        };
      }, {});
    } else {
      newValue = inputValue[keys[0]];
    }

    const newFields = [...(value as object[])];
    newFields[index] = shouldUseFlatObject ? newValue : newValue[index];

    setFields(newFields);
  };

  return { addInput, removeInput, onChangeCallback };
};

export default useInputList;
