/* istanbul ignore file */
import React from 'react';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import useProcessInstanceService from 'camunda/monitoring/instance/processInstanceService';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { TextField, FullDateField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ProcessInstanceLinkField from '../../shared/ProcessInstanceLinkField';
import IncidentCountField from '../../shared/IncidentCountField';
import ProcessDefinitionField from '../../shared/ProcessDefinitionField';
import ProcessStateField from '../../shared/ProcessStateField';

const Subprocesses = () => {
  const id = useProcessInstanceId();
  const { getSubprocesses } = useProcessInstanceService();

  const getDataMethod = async () => {
    const issues = await getSubprocesses(id);
    return issues;
  };

  return (
    <RelatedList getDataMethod={getDataMethod}>
      <RelationListLayout header="Subprocesses" actions={[]}>
        <DataGridLayout showTotal={false} buttons={[]} isSmallTable>
          <ProcessInstanceLinkField source="id" />
          <IncidentCountField source="incidentCount" label="Incidents" />
          <ProcessDefinitionField label="Process Key / Version" keySource="processKey" versionSource="processVersion" />
          <TextField source="businessKey" />
          <ProcessStateField source="state" />
          <FullDateField source="startDateTime" label="Start Date" isSortable />
          <FullDateField source="endDateTime" label="End Date" isSortable />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

export default Subprocesses;
