import React from 'react';
import moment, { Moment } from 'moment';
import Box from 'uibuilder/Box';
import shortid from 'shortid';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { DAYS_IN_WEEK, SUNDAY } from 'erp/employee/performanceReport/usePerformanceReportService';

const PerformanceHeatmapDaysHeader = ({ currentMonth }: { currentMonth: string }) => {
  const startOfMonth = moment(currentMonth).startOf('month');

  const getHeaders = () => {
    const result = [];
    const monday = moment(currentMonth)
      .startOf('month')
      .add(6, 'day')
      .startOf('isoWeek');
    const month = moment(currentMonth).month();

    if (monday.date() > DAYS_IN_WEEK) monday.add(DAYS_IN_WEEK, 'd');

    while (month === monday.month()) {
      result.push(monday.clone());
      monday.add(DAYS_IN_WEEK, 'd');
    }

    if (
      startOfMonth.format(DATE_FORMAT.INPUT_VALUE) !== result[0]?.format(DATE_FORMAT.INPUT_VALUE) &&
      startOfMonth.day() !== SUNDAY
    ) {
      result.unshift(startOfMonth.clone());
    }

    return result;
  };

  const getGridTemplateColumns = (headers: Moment[]) =>
    headers
      .map((header, i) => {
        if (i === headers.length - 1) {
          return 'auto';
        }

        if (!i) {
          return `${startOfMonth.day() === SUNDAY ? 'var(--week-width)' : ''} calc(var(--week-width) * ${DAYS_IN_WEEK -
            header.day() +
            1})`;
        }

        return `calc(var(--week-width) * ${DAYS_IN_WEEK})`;
      })
      ?.join(' ');

  const heatmapHeaders = getHeaders();

  return (
    <Box
      component="ul"
      className="performance-heatmap__months"
      sx={{
        gridTemplateColumns: getGridTemplateColumns(heatmapHeaders),
      }}
    >
      {startOfMonth.day() === SUNDAY ? <li key={shortid.generate()} /> : null}
      {heatmapHeaders.map(header => (
        <li key={shortid.generate()}>{header.format('D MMM')}</li>
      ))}
    </Box>
  );
};

export default PerformanceHeatmapDaysHeader;
