import useEntityUrl from 'shared/routing/useEntityUrl';
import { WASTE_PATH } from './useWasteService';

const useWasteUrl = () => {
  return useEntityUrl({
    baseLink: WASTE_PATH,
  });
};

export default useWasteUrl;
