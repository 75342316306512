export interface BaseRouteConfig {
  createPostfix?: string;
  editPostfix?: string;
  timelinePostfix?: string;
}

interface RouteHelperParams extends BaseRouteConfig {
  singleEntityRoute?: string;
  listRoute: string;
}

const useRoute = ({
  singleEntityRoute,
  listRoute,
  createPostfix = 'add',
  editPostfix = 'edit',
  timelinePostfix = 'timeline',
}: RouteHelperParams) => {
  const actualSingleEntityRoute = singleEntityRoute || `${listRoute}/:id`;
  return {
    singleEntityRoute: actualSingleEntityRoute,
    listRoute,
    createRoute: `${listRoute}/${createPostfix}`,
    updateRoute: `${actualSingleEntityRoute}/${editPostfix}`,
    timelineRoute: `${actualSingleEntityRoute}/${timelinePostfix}/:timelineEntryId?`,
  };
};

export default useRoute;
