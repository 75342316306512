import React from 'react';
import { useCorporateEventId, useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import SummaryMenuItem from 'uibuilder/menuitem/SummaryMenuItem';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const CorporateEventMenu = () => {
  const id = useCorporateEventId();
  const { getSingleEntityUrl, getBusinessUnitsUrl, getCandidateApplicationsUrl } = useCorporateEventUrl();
  const { data } = useShowContext();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
      <SidebarMenuItem
        linkUrl={getBusinessUnitsUrl(id)}
        name="Business Units"
        icon="user"
        isVisible={data.getValueBySource('type') === 'TEAM_EVENT'}
      />
      <SidebarMenuItem
        linkUrl={getCandidateApplicationsUrl(id)}
        name="Candidate Applications"
        icon="user"
        isVisible={data.getValueBySource('type') === 'RECRUITING_EVENT'}
      />
    </SidebarStickyMenu>
  );
};

export default CorporateEventMenu;
