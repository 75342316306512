import { API_FILE_TYPE } from 'artifact/const';
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import withLightbox from 'artifact/shared/Lightbox/withLightbox';
import { arrayOf, func, string, bool, shape } from 'prop-types';
import AttachmentFieldLayout from 'artifact/shared/field/layout/AttachmentFieldLayout';

import './ArtifactsLayout.scss';

const AttachmentsListLayout = ({
  attachments,
  label,
  openLightbox,
  downloadFile,
  getThumbnail,
  openPreview,
  isDownloadAvailable,
  isPhotos,
}) => {
  const { BaseFieldLayout } = useUiTheme();

  const images = attachments && attachments.filter(file => file.type === API_FILE_TYPE.image.alias);

  const files = attachments && attachments.filter(file => file.type !== API_FILE_TYPE.image.alias);

  const isPhotosLabel = isPhotos || label === 'Photos';

  return (
    <BaseFieldLayout
      isColumnDirection={!!attachments.length}
      label={label}
      value={
        attachments &&
        !!attachments.length && (
          <>
            <ul className={isPhotosLabel ? `files-list__photos` : `files-list__files`}>
              {!!images?.length &&
                images.map(img => (
                  <li key={img.id} className="files-item">
                    <AttachmentFieldLayout
                      value={img}
                      getThumbnail={getThumbnail}
                      handleDownload={() => downloadFile(img.id, img.title)}
                      handleClick={() =>
                        openLightbox(
                          images,
                          images.indexOf(img),
                          (id, title) => downloadFile(id || img.id, title || img.title),
                          isDownloadAvailable,
                        )
                      }
                    />
                  </li>
                ))}
              {!!files?.length &&
                files.map(
                  file =>
                    file && (
                      <li key={file.id} className="files-item">
                        <AttachmentFieldLayout
                          value={file}
                          handleClick={() => downloadFile(file.id, file.title)}
                          handleOpenPreview={() => openPreview(file.id)}
                          isDownloadAvailable={isDownloadAvailable}
                        />
                      </li>
                    ),
                )}
            </ul>
          </>
        )
      }
    />
  );
};

AttachmentsListLayout.propTypes = {
  attachments: arrayOf(shape({})),
  label: string,
  openLightbox: func.isRequired,
  downloadFile: func.isRequired,
  openPreview: func.isRequired,
  getThumbnail: func.isRequired,
  isDownloadAvailable: bool,
  isPhotos: bool,
};

AttachmentsListLayout.defaultProps = {
  attachments: [],
  label: null,
  isDownloadAvailable: true,
  isPhotos: false,
};

export default withLightbox(AttachmentsListLayout);
