/* istanbul ignore file */
import React from 'react';
import { DndProvider as BaseDndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import useDeviceDetectorService from 'oneStop/deviceDetectorService';

const DndProvider = ({ children }: { children: React.ReactNode }) => {
  const { isDesktop } = useDeviceDetectorService();

  return (
    <BaseDndProvider
      backend={isDesktop ? HTML5Backend : TouchBackend}
      options={{ enableMouseEvents: true, delayTouchStart: 100 }}
    >
      {children}
    </BaseDndProvider>
  );
};

export default DndProvider;
