import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import React from 'react';
import useRoomService from 'erp/room/useRoomService';

export const ROOM_CONTEXT = 'Room';

const RoomContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useRoomService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={ROOM_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default RoomContext;
