import React from 'react';
import { DefaultTokenProvider, useTokenProvider } from '../authentication/DefaultTokenProvider';
import { useSearchParameter } from '../shared/routing/helper';
import useIdentityService from './identityService';
import * as PropTypes from 'prop-types';

const UrlTokenProvider = ({ children }: { children: React.ReactNode }) => {
  const token = useSearchParameter('token');
  const { getToken: parentGetToken } = useTokenProvider();

  const { exchangeToken } = useIdentityService();
  const getToken = !token ? parentGetToken : () => exchangeToken(token);

  return <DefaultTokenProvider getToken={getToken}>{children}</DefaultTokenProvider>;
};

UrlTokenProvider.propTypes = {
  children: PropTypes.element,
};

UrlTokenProvider.defaultProps = {
  children: null,
};

export default UrlTokenProvider;
