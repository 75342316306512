/* istanbul ignore file */
import React from 'react';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewPurchaseOrderButton = (props: any) => {
  const { getSingleEntityUrl } = usePurchaseOrderUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-journal-entry" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewPurchaseOrderButton;
