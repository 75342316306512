import { ListProvider } from 'shared/uibuilder/list/ListContext';
import React, { useState } from 'react';
import { saveFile } from 'shared/fileHelper';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { Filter } from './filter/FilterContext';

interface FileListProps {
  children: React.ReactElement;
  getDataMethod: (params: { filters: Filter }) => Promise<Response>;
  defaultFilters?: Filter;
}

const FileList = ({ getDataMethod, children, defaultFilters }: FileListProps) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilters);
  const { addMessage } = useMessageService();

  const filterBy = async (newFilter: Filter) => {
    try {
      setLoading(true);

      await getDataMethod({ filters: newFilter }).then(async response => {
        if (response) {
          const contentDisposition = response.headers.get('content-disposition') || '';
          const filename = decodeURI((/filename="(.*)"/g.exec(contentDisposition) || [])[1]);

          await response.blob().then(async (blob: Blob) => {
            saveFile(blob, filename);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });

      setFilter(prev => ({
        ...prev,
        ...newFilter,
      }));
    } catch (e) {
      addMessage(new ErrorMessage('Something went wrong'));
      setLoading(false);
    }
  };

  const contextValue = {
    filterBy,
    filter,
    loading,
    setLoading,
  };

  return <ListProvider value={contextValue}>{children}</ListProvider>;
};

FileList.defaultProps = {
  defaultFilters: {},
};

export default FileList;
