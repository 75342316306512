/* istanbul ignore file */
/* eslint-disable */

const React = require('react');

const createClass = require('create-react-class');

const DaysView = require('shared/lib/DateTime/src/DaysView');

const MonthsView = require('shared/lib/DateTime/src/MonthsView');

const YearsView = require('shared/lib/DateTime/src/YearsView');

const TimeView = require('shared/lib/DateTime/src/TimeView');

const CalendarContainer = createClass({
  viewComponents: {
    days: DaysView,
    months: MonthsView,
    years: YearsView,
    time: TimeView,
  },

  render() {
    return React.createElement(this.viewComponents[this.props.view], this.props.viewProps);
  },
});

module.exports = CalendarContainer;
