/* istanbul ignore file */
import React from 'react';
import { useAssetUrl } from 'financialAnalytic/assets/AssetRouter';
import { CREATE_ASSET } from 'financialAnalytic/assets/useAssetService';
import CreateButton from 'uibuilder/button/CreateButton';

const CreateAssetButton = () => {
  const { getCreateUrl } = useAssetUrl();

  return <CreateButton url={getCreateUrl()} label="Add Asset" key="create-asset" permissionToCheck={CREATE_ASSET} />;
};

export default CreateAssetButton;
