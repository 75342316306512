/* istanbul ignore file */
import React, { ReactNode } from 'react';
import BaseBreadcrumbs from 'uibuilder/Breadcrumbs';
import { ProcessDefinitionBreadcrumbs } from 'camunda/monitoring/definition';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';

interface Props {
  children?: ReactNode | undefined | null;
}

const ProcessInstanceBreadcrumbs = ({ children }: Props) => {
  const id = useProcessInstanceId();

  return (
    <ProcessDefinitionBreadcrumbs source="processKey">
      <BaseBreadcrumbs>
        <SingleBreadcrumbItem entity={id} />
        {children}
      </BaseBreadcrumbs>
    </ProcessDefinitionBreadcrumbs>
  );
};

export default ProcessInstanceBreadcrumbs;
