/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import OneStopHistoryPage from 'myActivity/history/List';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import { IsMyActivityEnabled, MY_ACTIVITY_ROOT_PATH, useMyActivityRoute } from 'myActivity/MyActivityRouterHelper';
import SentFeedbackPage from './feedback/List';

const MyActivityRouter: Router = () => {
  const { historyRoute, listRoute } = useMyActivityRoute();

  return (
    <ProtectedRouter basePath={MY_ACTIVITY_ROOT_PATH}>
      <Switch>
        <Route path={historyRoute} exact>
          <OneStopHistoryPage />
        </Route>

        {IsMyActivityEnabled() ? (
          <Route path={listRoute} exact>
            <SentFeedbackPage />
          </Route>
        ) : null}

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

MyActivityRouter.getRouterPath = () => MY_ACTIVITY_ROOT_PATH;

export default MyActivityRouter;
