/* istanbul ignore file */
import React from 'react';
import useInvoiceService, { REJECT_INVOICE, INVOICE_STATUSES } from 'financialAnalytic/invoice/useInvoiceService';
import { useParams } from 'react-router-dom';
import RejectButton from 'financialAnalytic/shared/button/RejectButton';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const deleteMessage = 'Do you really want to reject this invoice?';

const RejectInvoiceButton = ({
  statusSource,
  isVisible,
}: {
  statusSource: string;
  isVisible?: (data: any) => boolean;
}) => {
  const { id } = useParams<Dictionary<string>>();
  const { rejectInvoice, invalidateCache } = useInvoiceService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== INVOICE_STATUSES.PENDING && status !== INVOICE_STATUSES.APPROVED && status !== INVOICE_STATUSES.PAID) {
    return null;
  }

  return (
    <RejectButton
      displayMessage={deleteMessage}
      deleteMethod={rejectReason => rejectInvoice(id, rejectReason)}
      successMessage="The Invoice has been successfully rejected."
      errorMessage="Can't reject invoice."
      permissionToCheck={REJECT_INVOICE}
      execute={() => {
        invalidateCache();
      }}
    />
  );
};

export default RejectInvoiceButton;
