import { EnumField } from 'shared/uibuilder/field';
import React from 'react';
import { LEAVE_RULES_VIOLATION_REASON } from 'erp/leave/shared/leaveService';

const RulesViolationReasonField = props => {
  return <EnumField {...props} options={LEAVE_RULES_VIOLATION_REASON} />;
};

RulesViolationReasonField.propTypes = EnumField.propTypes;

export default RulesViolationReasonField;
