/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import useInvoiceService from 'financialAnalytic/invoice/useInvoiceService';
import { SensitiveListElementLayout } from 'shared/uibuilder/sensitiveData';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import InvoiceListPageContent from 'financialAnalytic/invoice/List/InvoiceListPageContent';

const InvoiceListPage = () => {
  const { search, getSensitiveDataByIds } = useInvoiceService();
  const { getListUrl } = useInvoiceUrl();

  return (
    <List getDataMethod={search} defaultFilter={{}}>
      <SensitiveListElementLayout
        sensitiveDataIdSource="id"
        loadSensitiveDataMethod={getSensitiveDataByIds}
        timerId={getListUrl()}
      >
        <InvoiceListPageContent />
      </SensitiveListElementLayout>
    </List>
  );
};

export default InvoiceListPage;
