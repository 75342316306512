import React from 'react';
import NextReviewDateField from 'erp/employee/qualification/List/field/NextReviewDateField';
import EvaluationModeField from 'erp/employee/qualification/List/field/EvaluationModeField';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Show from 'shared/uibuilder/show/Show';
import useCareerReviewService from './careerReviewService';
import {
  READ_CAREER_DEVELOPMENT_NEXT_REVIEW_DATE,
  READ_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION,
} from 'erp/employee/employeeService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import './CareerDevelopmentReviewSection.scss';
import useFeatureToggle, { Features } from 'featuretoggle';

const CareerDevelopmentReviewSection = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const { getCareerReviewData } = useCareerReviewService();
  const { isGranted } = useAuthorization();
  const canReadNextReviewDate = isGranted(READ_CAREER_DEVELOPMENT_NEXT_REVIEW_DATE);
  const canReadEvaluationMode = isGranted(READ_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION);
  const isSectionVisible =
    isFeatureEnabled(Features.VIEW_CAREER_REVIEW_INFORMATION) && (canReadNextReviewDate || canReadEvaluationMode);
  const employeeId = useEmployeeId() || '';

  return (
    <>
      <ShowSection title="Career review information" titleVariant="h2" isVisible={isSectionVisible}>
        <div className="mb-4 pt-1">
          <Show getDataMethod={() => getCareerReviewData(employeeId)} isSmallLoader>
            <>
              <NextReviewDateField
                label="Next review date:"
                source="nextReviewDate"
                isVisible={canReadNextReviewDate}
              />
              <EvaluationModeField
                label="Evaluation mode:"
                source="careerDevelopmentEvaluationMode"
                isVisible={canReadEvaluationMode}
              />
            </>
          </Show>
        </div>
      </ShowSection>
    </>
  );
};

export default CareerDevelopmentReviewSection;
