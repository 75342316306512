import ShowPageLayout from 'shared/layout/ShowPageLayout';
import CorporateEventHeader from 'financialAnalytic/corporateEvents/shared/CorporateEventHeader';
import SingleCorporateEventBreadcrumbs from 'financialAnalytic/corporateEvents/shared/SingleCorporateEventBreadcrumbs';
import EditCorporateEventButton from 'financialAnalytic/corporateEvents/shared/button/EditCorporateEventButton';
import useCorporateEventService, {
  UPDATE_CORPORATE_EVENT,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';
import CorporateEventMenu from 'financialAnalytic/corporateEvents/shared/CorporateEventMenu';
import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ProjectLinkField from 'erp/project/shared/field/ProjectLinkField';
import { READ_PROJECT } from 'erp/project/shared/projectService';
import { READ_SERVICE } from 'erp/service/shared/serviceService';
import ServiceLinkField from 'erp/service/shared/field/ServiceLinkField';

const ViewCorporateEventBusinessUnitsPage = () => {
  const { isGranted } = useAuthorization();
  const { searchReferencedServices, searchReferencedProjects } = useCorporateEventService();

  return (
    <ShowPageLayout
      entityHeader={<CorporateEventHeader />}
      breadcrumbs={<SingleCorporateEventBreadcrumbs />}
      headerButtons={[<EditCorporateEventButton isVisible={isGranted(UPDATE_CORPORATE_EVENT)} />]}
      menu={<CorporateEventMenu />}
      hasSeparateSections
    >
      <ShowSection title="Projects">
        <List getDataMethod={searchReferencedProjects} defaultSortField="name" defaultSortOrder="ASC">
          <DataGridLayout>
            <ProjectLinkField label="ID" source="id" nameSource="id" permissionToCheck={READ_PROJECT} />
            <ProjectLinkField label="Name" source="id" nameSource="name" permissionToCheck={READ_PROJECT} />
          </DataGridLayout>
        </List>
      </ShowSection>

      <ShowSection title="Services">
        <List getDataMethod={searchReferencedServices} defaultSortField="name" defaultSortOrder="ASC">
          <DataGridLayout>
            <ServiceLinkField label="ID" source="id" nameSource="id" permissionToCheck={READ_SERVICE} />
            <ServiceLinkField label="Name" source="id" nameSource="name" permissionToCheck={READ_SERVICE} />
          </DataGridLayout>
        </List>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewCorporateEventBusinessUnitsPage;
