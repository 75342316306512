/* istanbul ignore file */
import React from 'react';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import usePurchaseOrderService from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';

const PurchaseOrderDropdown = ({ officeData, ...props }: DefaultInputPropTypes<string> & { officeData: string }) => {
  const { getAllAvailableToLinkPurchaseOrders } = usePurchaseOrderService();

  const loadPurchaseOrders = async () => {
    if (officeData) {
      const files = await getAllAvailableToLinkPurchaseOrders({
        filter: {
          office: { in: [officeData] },
        },
      });
      return Promise.resolve({ result: files });
    }

    return Promise.resolve({ result: [] });
  };

  return (
    <LoadDropdown
      loadDataMethod={loadPurchaseOrders}
      mapResults={(item: any) => ({ value: item.id, label: `${item.title} (PO ID: ${item.id})` })}
      placeholder="Select Purchase Order"
      showLoader
      deps={[officeData]}
      isClearValueByUpdateDeps
      {...props}
    />
  );
};

export default PurchaseOrderDropdown;
