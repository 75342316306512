/* istanbul ignore file */
import React from 'react';
import { SummaryMenuItem, NotesMenuItem } from 'uibuilder/menuitem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { OpportunitiesMenuItem } from 'crm/opportunity';
import { useContactId, useContactUrl } from 'crm/contact/ContactRouter';

const ContactsMenu = () => {
  const id = useContactId();
  const { getSingleEntityUrl, getRelatedOpportunitiesUrl, getNotesUrl } = useContactUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
      <OpportunitiesMenuItem linkUrl={getRelatedOpportunitiesUrl(id)} />
      <NotesMenuItem linkUrl={getNotesUrl(id)} />
    </SidebarStickyMenu>
  );
};

export default ContactsMenu;
