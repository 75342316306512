/* istanbul ignore file */
import React from 'react';
import { useEmployeeAssignmentUrl } from 'erp/employee/assignments/EmployeeAssignmentsRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import LinkButton from 'uibuilder/button/LinkButton';
import { CREATE_ASSIGNMENT } from 'erp/assignment/shared/assignmentService';

const CopyEmployeeAssignmentButton = props => {
  const { getCopyEntityUrl } = useEmployeeAssignmentUrl();
  const { getValue: getId } = useFieldHelper(props);
  const id = getId();
  const { isGranted } = useAuthorization();

  return isGranted(CREATE_ASSIGNMENT) ? (
    <LinkButton permissionToCheck={CREATE_ASSIGNMENT} outline {...props} url={getCopyEntityUrl(id)}>
      Copy
    </LinkButton>
  ) : null;
};

export default CopyEmployeeAssignmentButton;
