import moment from 'moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const useMetricsHelper = () => {
  const calculateReversePercentage = (actualValue: number, totalValue: number, decimalPoints?: number) => {
    const actualPercentage = calculatePercentage(actualValue, totalValue, decimalPoints);
    const multiplier = decimalPoints ? 10 ** decimalPoints : 1;
    return (100 * multiplier - actualPercentage * multiplier) / multiplier;
  };

  const calculatePercentage = (actualValue: number, totalValue: number, decimalPoints?: number) => {
    const percentage = Math.min((actualValue / totalValue) * 100, 100);
    return decimalPoints ? formatPercentage(percentage, decimalPoints) : percentage;
  };

  const formatPercentage = (percentage: number, decimalPoints: number) => {
    const multiplier = 10 ** decimalPoints;
    return Math.floor(percentage * multiplier) / multiplier;
  };

  const subtractNumbers = (minuend: number, subtrahend: number) => {
    const minuendDecimalPartLength = getDecimalPartLength(minuend);
    const subtrahendDecimalPartLength = getDecimalPartLength(subtrahend);
    const dirtyResult = minuend - subtrahend;
    const multiplier = 10 ** Math.max(minuendDecimalPartLength, subtrahendDecimalPartLength);
    return Math.round(dirtyResult * multiplier) / multiplier;
  };

  const getDecimalPartLength = (number: number) => {
    const numberLength = number.toString().length;
    const integerPartLength = Math.floor(number).toString().length;
    if (numberLength === integerPartLength) {
      return 0;
    }
    return numberLength - integerPartLength - 1;
  };

  const isDateValid = (date: string) => {
    return moment(date, DATE_FORMAT.API_ONLY_DATE, true).isValid();
  };

  return {
    calculateReversePercentage,
    calculatePercentage,
    formatPercentage,
    subtractNumbers,
    isDateValid,
  };
};

export default useMetricsHelper;
