/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import BudgetBreadcrumbs from 'erp/budget/shared/BudgetBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const SingleBudgetBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getSingleEntityUrl } = useBudgetUrl();
  const { id } = useParams<Dictionary<string>>();

  return (
    <BudgetBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </BudgetBreadcrumbs>
  );
};

export default SingleBudgetBreadcrumbs;
