import React, { useCallback, useMemo, useState } from 'react';
import { TextInput } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const convertToHours = (minutes: number): number => {
  return minutes / 60;
};

const convertToMinutes = (hours: number): number => {
  return hours * 60;
};

interface TrainingDurationInputPropTypes {
  source: string;
}

const TrainingDurationInput: React.FC<TrainingDurationInputPropTypes> = ({
  source,
}: TrainingDurationInputPropTypes) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper({ source });
  const initialDuration = parseFloat(convertToHours((getValue() as number) || 0).toFixed(2)).toString();
  const [duration, setDuration] = useState(initialDuration);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const parentOnChange = useMemo(() => getRawOnChangeCallback() || (() => {}), [getRawOnChangeCallback]);

  const setErrorMessage = (newDuration: any) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    if (Number.isNaN(newDuration) || newDuration < 0 || !regex.test(newDuration)) {
      setErrors(prevErrors => ({ ...prevErrors, durationInMinutes: ['Invalid time format. '] }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, durationInMinutes: [] }));
    }
  };

  const onChange = useCallback(
    (callBackValue: FormFieldsData) => {
      const newDuration = callBackValue.durationInMinutes;
      setDuration(newDuration);

      setErrorMessage(newDuration);

      parentOnChange({
        [`${getSource()}`]: convertToMinutes(newDuration),
      });
    },
    [parentOnChange, getSource],
  );

  return (
    <TextInput
      label="Duration (hours)"
      placeholder="e.g., 1.5"
      value={duration}
      onChangeCallback={onChange}
      source={getSource()}
      errorMessages={errors.durationInMinutes || []}
    />
  );
};

export default TrainingDurationInput;
