import React from 'react';
import AccountingCycleBreadcrumbs from '../shared/AccountingCycleBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useAccountingCycleUrl } from '../AccountingCycleRouter';

const AccountingCycleBreadcrumbsItem = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useAccountingCycleUrl();
  const id = data?.getValueBySource('id');

  return (
    <AccountingCycleBreadcrumbs>
      <SingleBreadcrumbItem entity="Accounting Cycle" link={getSingleEntityUrl(id)} />
      {children}
    </AccountingCycleBreadcrumbs>
  );
};

export default AccountingCycleBreadcrumbsItem;
