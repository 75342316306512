/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useExpenseService from 'financialAnalytic/expenses/useExpenseService';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';
import CreateExpenseBreadcrumbs from 'financialAnalytic/expenses/createupdate/Create/Breadcrumbs';
import ExpenseForm from 'financialAnalytic/expenses/createupdate/ExpenseForm';
import { useSearchParameters } from 'shared/routing/helper';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const CreateExpensePage = () => {
  const { create, getValidationSchema } = useExpenseService();
  const { getSingleEntityUrl } = useExpenseUrl();
  const { employeeAlias, employeeName, leaveId, subtype, type, title, registrationDate } = useSearchParameters();
  const { formatDate, getToday } = useDateService();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: ({ id }) => getSingleEntityUrl(id),
        message: 'You are awesome! The Employee Expense has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        compensation: { currency: 'USD' },
        employeeAlias,
        employeeName: employeeName ? `${employeeAlias} ${employeeName}` : null,
        leaveId,
        subtype,
        type,
        title,
        registrationDate: registrationDate || formatDate(getToday(), DATE_FORMAT.INPUT_VALUE)?.trim(),
      }}
    >
      <CreatePageLayout breadcrumbs={<CreateExpenseBreadcrumbs />} title="Create: Employee Expense">
        <ExpenseForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateExpensePage;
