/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeEmployeeTypeDropdown from 'erp/employee/shared/input/EmployeeTypeDropdown';

const EmployeeTypeFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <EmployeeEmployeeTypeDropdown />
    </DropDownFilter>
  );
};

export default EmployeeTypeFilter;
