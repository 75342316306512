import React from 'react';
import useSanitizeService from './sanitizeService';

interface HtmlSanitizerProps {
  html: string;
  className?: string;
  wrapperTag?: React.ElementType;
}

/**
 * This component sanitizes HTML and returns a tag with sanitized data.
 * It was created to prevent XSS attacks and it locates dangerouslySetInnerHTML in one place.
 * @this  {SanitizedHtml}
 * @param {HtmlSanitizerProps}
 * html - html that we need to sanitize;
 * wrapperTag - a tag what we need to return (div by default);
 * props - props to be passed to tag;
 * @return a tag with sanitized html
 */
const SanitizedHtml = ({ html, wrapperTag: Tag = 'div', ...props }: HtmlSanitizerProps) => {
  const { getSanitizedData } = useSanitizeService();
  const cleanData = getSanitizedData(html.replace(/<table/g, '<table border="1"'));

  return (
    <Tag
      {...props}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: cleanData,
      }}
    />
  );
};

export default SanitizedHtml;
