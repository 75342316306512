import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import { CORPORATE_EVENT_TYPES_OPTIONS } from 'financialAnalytic/corporateEvents/useCorporateEventService';
import EnumField from 'shared/uibuilder/field/EnumField';

const CorporateEventTypeField = (props: FieldProps) => {
  return <EnumField options={CORPORATE_EVENT_TYPES_OPTIONS} {...props} />;
};

export default CorporateEventTypeField;
