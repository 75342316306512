import React, { useEffect } from 'react';
import { get, set } from 'lodash';
import FormRow from 'shared/layout/form/FormRow';
import { RadioButtonGroup } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import FamilyMemberListInput from 'erp/employee/shared/input/FamilyMemberListInput';
import ChildInput from 'erp/employee/shared/input/ChildrenInput/ChildInput';
import { CHILD_RELATION_OPTIONS } from 'erp/employee/shared/input/ChildrenInput/ChildRelationInput';

export const OPTION_YES = 'YES';
export const OPTION_NO = 'NO';

export const CHILDREN_OPTIONS = [
  {
    label: 'Yes',
    value: OPTION_YES,
  },
  {
    label: 'No',
    value: OPTION_NO,
  },
];

export const CHILDREN_SOURCE = 'haveChildren';

export const INITIAL_CHILDREN_DATA = [
  {
    name: {
      firstName: '',
      middleName: null,
      lastName: '',
    },
    dateOfBirth: '',
    relation: '',
  },
];

const ChildrenInput = props => {
  const { getRawOnChangeCallback, getSource } = useInputHelper(props);
  const { initializeValue } = useInputHelper({
    source: CHILDREN_SOURCE,
  });

  const { data } = useFormContext();

  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();
  const haveChildren = get(data, CHILDREN_SOURCE) === OPTION_YES;

  const switchChildren = value => {
    const newValue = get(value, CHILDREN_SOURCE);
    const params = {
      ...data,
      ...value,
    };
    const childrenValue = newValue === OPTION_YES ? INITIAL_CHILDREN_DATA : [];

    set(params, source, childrenValue);

    rawOnChangeCallback(params);
  };

  useEffect(() => {
    let initialType = OPTION_NO;
    const initialData = get(data, source);

    if (initialData && initialData.length) {
      initialType = OPTION_YES;
    }

    initializeValue(initialType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormRow>
        <RadioButtonGroup
          options={CHILDREN_OPTIONS}
          source={CHILDREN_SOURCE}
          label="Do you have children?"
          onChangeCallback={switchChildren}
        />
      </FormRow>
      {haveChildren && (
        <FamilyMemberListInput
          inputTemplate={ChildInput}
          addText="Add child"
          defaultValue={{
            relation: CHILD_RELATION_OPTIONS[0],
            name: {},
          }}
          {...props}
        />
      )}
    </>
  );
};

export default ChildrenInput;
