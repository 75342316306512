/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useExpenseService from 'financialAnalytic/expenses/useExpenseService';
import { useExpenseId, useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';
import ViewExpensePageContent from './ViewExpensePageContent';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';

const ExpenseViewPage = () => {
  const { getById } = useExpenseService();
  const id = useExpenseId();
  const { getListUrl } = useExpenseUrl();

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout
        // TODO: Update after updating BE
        loadSensitiveDataMethod={() => Promise.resolve({})}
        sensitiveDataId={id}
        sensitiveDataIdSource="id"
        timerId={getListUrl()}
      >
        <ViewExpensePageContent />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default ExpenseViewPage;
