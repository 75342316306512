import React, { useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Button } from 'uibuilder/button';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ShowDate from 'shared/uibuilder/ShowDate';

export interface EarlyStartedHandlerProps {
  nextReviewDate: string;
  isEarlyStarted: boolean;
}

const EarlyStartedHandler = ({ nextReviewDate, isEarlyStarted }: EarlyStartedHandlerProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  const continueButton = (
    <Button
      onClick={() => {
        closeModal();
      }}
      key="btn-success"
    >
      Continue
    </Button>
  );

  return isEarlyStarted ? (
    <ModalWindow
      isOpen={isOpen}
      title="Business rules violated"
      buttons={[continueButton]}
      onToggle={() => closeModal()}
      key="modal-not-strict"
      hasCancelButton={false}
    >
      <p>
        Your next career review date is defined as{' '}
        <ShowDate dateUTC={nextReviewDate} format={DATE_FORMAT.NATURAL} isDateTime={false} />. In a normal case, you
        should request your career review not earlier than this date.
      </p>
      <p>
        If you want the review to be started earlier, please provide the reasons for the early process start in the
        corresponding field and submit the request. A task will be assigned to your resource manager to approve the
        violation.
      </p>
    </ModalWindow>
  ) : null;
};

export default EarlyStartedHandler;
