/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import useWasteUrl from '../useWasteUrl';

const WasteBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useWasteUrl();
  const wasteListLink = getListUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Waste" link={wasteListLink} />
      {children}
    </Breadcrumbs>
  );
};

export default WasteBreadcrumbs;
