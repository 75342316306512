import React, { useState, memo } from 'react';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';
import IconButton from 'uibuilder/button/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableCell from '@mui/material/TableCell';
import { RowProps, renderCell } from './Row';

/**
 * @param data -- data for row
 * @param template -- An array of Feild-like react elements
 * @param buttons -- An array of action buttons to be added to row
 * @param rowProps - either props for <tr> tag or function that accept data and return props or <tr> tag.
 * Allows to highlight the fields somehow.
 * @param rowKeySource - source in data for row key value
 * @returns {*} -- table row build based on template and buttons
 * @constructor
 */
const RowWithDetailsPanel = ({ data, template, buttons = [], rowProps = {}, rowKeySource = 'id' }: RowProps) => {
  const [visibleDetails, setVisibleDetails] = useState<Nullable<string>>(null);
  const dataInstance = new ShowData(data);
  const props = typeof rowProps === 'function' ? rowProps(dataInstance) : rowProps;

  return (
    <ShowContextProvider
      value={{
        data: dataInstance,
      }}
    >
      <MuiTableRow
        {...props}
        key={dataInstance.getValueBySource(rowKeySource)}
        sx={visibleDetails ? {} : { '& > *': { borderBottomColor: 'transparent !important' } }}
      >
        {React.Children.map(template, item => {
          if (item.props.isDetailsPanel) {
            return (
              <MuiTableCell key={`${dataInstance.getValueBySource(rowKeySource)}-details-btn-${item.props.source}`}>
                <IconButton
                  size="small"
                  onClick={() =>
                    setVisibleDetails(prevState => (prevState === item.props.source ? null : item.props.source))
                  }
                >
                  {visibleDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </MuiTableCell>
            );
          }
          return renderCell(dataInstance, item, '');
        })}
        {React.Children.map(buttons, button => {
          return renderCell(dataInstance, button, 'list-btn-container');
        })}
      </MuiTableRow>

      <MuiTableRow key={`${dataInstance.getValueBySource(rowKeySource)}-details`}>
        <MuiTableCell style={{ padding: 0 }} colSpan={React.Children.toArray(template).length + buttons.length}>
          {React.Children.map(template, item => {
            return item.props.isDetailsPanel ? (
              <Collapse
                key={`${dataInstance.getValueBySource(rowKeySource)}-detail-${item.props.source}`}
                in={visibleDetails === item.props.source}
                timeout="auto"
              >
                <Box
                  sx={{
                    background: '#fff',
                    p: 2,
                  }}
                >
                  <Paper
                    sx={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '100%',
                    }}
                  >
                    {item}
                  </Paper>
                </Box>
              </Collapse>
            ) : null;
          })}
        </MuiTableCell>
      </MuiTableRow>
    </ShowContextProvider>
  );
};

export default memo(RowWithDetailsPanel);
