import React, { useEffect } from 'react';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import FormTemplate, { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import Message from 'shared/message/Message';
import useMessageService from 'shared/message/messageService';

interface ModalFormProps extends FormTemplateProps {
  closeModal: () => void;
  modalOpen: boolean;
}

const MESSAGE_CONTEXT = 'modalForm';

const ModalForm = ({ children, closeModal, afterSubmit = {}, modalOpen, ...props }: ModalFormProps) => {
  const { cleanMessage } = useMessageService(MESSAGE_CONTEXT);
  const { execute = () => {} } = afterSubmit;

  useEffect(() => {
    if (modalOpen) {
      cleanMessage();
    }

    // Suppressed warnings because we only need to call useEffect callback after
    // the modal has been opened with no calls after the messages have changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  return (
    <FormTemplate
      {...props}
      afterSubmit={{
        ...afterSubmit,
        execute: response => {
          if (execute) {
            execute(response);
          }
          closeModal();
        },
      }}
      messageContext={MESSAGE_CONTEXT}
    >
      <>
        <Message messageContext={MESSAGE_CONTEXT} />
        {children}
        <FormButtonsLayout onCancelAction={closeModal} />
      </>
    </FormTemplate>
  );
};

export default ModalForm;
