import useStorageService from 'shared/uibuilder/storageService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

const useResourcePoolService = () => {
  const { findAll: findAllFromApi } = useBaseCrudService({
    listResourceUrl: '/resource-pools',
    apiService: useKernelApi,
  });

  const { getDataFromStorage } = useStorageService('resourcePools', findAllFromApi, '1.3');

  const findResourcePoolById = async id => {
    const pools = await getDataFromStorage();
    return pools.find(resourcePool => resourcePool.id === id) || {};
  };

  return {
    findAll: getDataFromStorage,
    findResourcePoolById,
  };
};

export default useResourcePoolService;
