/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import useIncidentService from 'camunda/monitoring/incident/incidentService';
import useAsyncValue from 'shared/asyncHelper';

const IncidentsCount = () => {
  const { getTotalCount } = useIncidentService();

  // @ts-ignore
  const count: number | false = useAsyncValue(getTotalCount);
  const color = count ? 'error' : 'success';

  return count || count === 0 ? (
    <div className="d-flex align-items-center mb-2">
      <span>Incidents: </span>
      <Badge color={color}>{count}</Badge>
    </div>
  ) : null;
};

export default IncidentsCount;
