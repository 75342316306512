import useEntityUrl from 'shared/routing/useEntityUrl';
import { ACCOUNT_PATH } from '../AccountRouter';

const useAccountUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: ACCOUNT_PATH,
    timelinePostfix: 'activities',
  });

  const { getSingleEntityUrl } = entityUrl;

  return {
    ...entityUrl,
    getRelatedOpportunitiesUrl: (id: string) => `${getSingleEntityUrl(id)}/related-opportunities`,
    getRelatedContactsUrl: (id: string) => `${getSingleEntityUrl(id)}/related-contacts`,
  };
};

export default useAccountUrl;
