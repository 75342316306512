/* istanbul ignore file */
import EntityHeader from 'shared/layout/EntityHeader';
import React, { useMemo } from 'react';
import CandidateStatusField from 'erp/candidate/shared/field/CandidateStatusField';
import ConvertedCandidateBadge from 'erp/candidate/shared/ConvertedCandidateBadge';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import StudentBadge from './StudentBadge';
import ShowData from 'shared/uibuilder/show/ShowData';

const CandidateEntityHeader = () => {
  const badges = candidate => {
    const result = [];

    const employeeId = candidate.getValueBySource('convertedToEmployeeId');
    if (employeeId) {
      result.push(<ConvertedCandidateBadge id={employeeId} />);
    }

    if (candidate.getValueBySource('isStudent')) {
      result.push(<StudentBadge />);
    }

    return result;
  };

  const formContext = useFormContext();
  const showContext = useShowContext();

  const data = (showContext?.data.isNotEmpty() && showContext.data) || new ShowData(formContext?.data);

  const value = useMemo(() => {
    return data.getValueBySource('status');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityHeader entityTitleSource="name.fullName" badges={badges}>
      <CandidateStatusField value={value} source="status" metaInformation />
    </EntityHeader>
  );
};

export default CandidateEntityHeader;
