/* istanbul ignore file */
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { RECONCILIATION_STATUS } from 'erp/costaccounting/reconcilation/useReconciliationService';

const ReconciliationStatusDropdown = (props: any) => {
  return <EnumDropdown placeholder="Choose Status" options={RECONCILIATION_STATUS} {...props} />;
};

export default ReconciliationStatusDropdown;
