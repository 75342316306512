/* istanbul ignore file */
import React from 'react';
import { useEmployeeSalaryId, useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import useEmployeeSalaryService from 'erp/employee/salaries/shared/salaryService';
import { DeleteButton } from 'uibuilder/button';

const DELETE_MESSAGE = 'Do you really want to delete this Salary?';

const DeleteSalaryButton = ({ ...props }) => {
  const salaryId = useEmployeeSalaryId();
  const { deleteById } = useEmployeeSalaryService();
  const { getListUrl } = useEmployeeSalaryUrl();

  return (
    <DeleteButton
      {...props}
      deleteMethod={() => deleteById(salaryId)}
      displayMessage={DELETE_MESSAGE}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Salary has been successfully deleted.',
        errorMessage: `Can't delete the Salary.`,
      }}
    />
  );
};

export default DeleteSalaryButton;
