/* istanbul ignore file */
import React from 'react';
import ButtonsSelectInput, { ButtonsSelectInputProps } from 'shared/uibuilder/form/input/ButtonsSelectInput';
import { MappingMetricsTypeLabels } from 'erp/costaccounting/shared/costAccountingService';

const MappingMetricsTypeInput = (props: ButtonsSelectInputProps) => {
  return (
    <ButtonsSelectInput
      {...props}
      options={Object.entries(MappingMetricsTypeLabels).map(entry => {
        const [value, label] = entry;

        return {
          label,
          value,
        };
      })}
    />
  );
};

export default MappingMetricsTypeInput;
