/* istanbul ignore file */
import React, { useEffect } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { DateInput, NumberInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import BudgetInput from 'erp/budget/shared/input/BudgetInput';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useFeatureToggle, { Features } from 'featuretoggle';
import { useDateService } from 'shared/uibuilder/dateService';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';

const BudgetForm = () => {
  const { id: budgetId } = useParams<Dictionary<string>>();
  const { data, onChangeCallback, setFieldErrors } = useFormContext();
  const { formatDateForAPI } = useDateService();
  const { isFeatureEnabled } = useFeatureToggle();
  const hasChildren = data?.childBudgets?.length;

  const isCommercialBudget = () => {
    return !!(data && data.parentId && data.parentName && data.parentName === 'Commercial efforts');
  };

  useEffect(() => {
    if (data && data.softPeriodTo) {
      if (onChangeCallback) {
        const periodToDate = new Date(data.softPeriodTo);
        periodToDate.setDate(periodToDate.getDate() + 14);
        const periodTo = formatDateForAPI(periodToDate.toDateString());
        if (setFieldErrors && (!data.periodFrom || (data.softPeriodTo && data.periodFrom <= data.softPeriodTo))) {
          setFieldErrors('softPeriodTo', []);
        }
        onChangeCallback(
          {
            softPeriodTo: data.softPeriodTo,
            periodTo,
          },
          false,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.softPeriodTo]);

  return (
    <FormSection title="Budget information">
      <FormRow>
        <TextInput source="name" />
        <NotDismissedEmployeeInput source="ownerId" label="Owner" nameSource="ownerName" />
      </FormRow>
      <FormRow>
        <BudgetInput
          mapResults={(budget: any) => {
            return {
              id: budget.id,
              text: budget.name,
              parentName: budget.name,
            };
          }}
          source="parentId"
          label="Parent Budget"
          nameSource="parentName"
          searchString="name"
          filter={{ excludedId: budgetId, notArchived: true }}
        />
        <NumberInput
          source="maxFte"
          label="Max FTE, people"
          isVisible={isFeatureEnabled(Features.VIEW_BUDGETS_FTE) && !hasChildren}
        />
      </FormRow>
      <FormRow>
        <DateInput source="periodFrom" label="Start Date" isVisible={!hasChildren} />
        <DateInput source="periodTo" label="End Date" isVisible={!hasChildren} />
      </FormRow>
      <FormRow>
        <ClearAfterUnmountWrapper isVisible={isCommercialBudget() && !hasChildren}>
          <DateInput source="softPeriodFrom" label="Soft Start Date" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={isCommercialBudget() && !hasChildren}>
          <DateInput source="softPeriodTo" label="Soft End Date" />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow colMd={8} colXl={6}>
        <TextArea source="purpose" label="Notes" />
      </FormRow>
    </FormSection>
  );
};

export default BudgetForm;
