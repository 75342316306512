/* istanbul ignore file */
import React, { useCallback } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import { DateInput, Dropdown, TextInput } from 'shared/uibuilder/form/input';
import useProjectStatusesService, {
  ProjectStatusWorkItemType,
  WasDemoSkipped,
  WasDemoSkippedLabels,
} from '../../ProjectStatusesService';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import DeliveredWorkItemInputRow, { DeliveredWorkItemType } from '../../shared/input/DeliveredWorkItemInputRow';
import AddListItemButton from 'erp/project/weeklyreports/shared/button/AddListItemButton';

const ProjectDemoStatusForm = () => {
  const { data: formData = {}, setFormData = (value: FormFieldsData) => {}, formErrors = {} } = useFormContext();
  const { checkIsEditable } = useProjectStatusesService();

  const updateTickets = useCallback(
    (newValue: any) => {
      setFormData({
        ...formData,
        tickets: [...newValue],
      });
    },
    [formData, setFormData],
  );

  const addTicket = useCallback(() => {
    const { tickets } = formData;

    tickets.push({
      id: null,
      ticketName: '',
      assigneeAlias: null,
      assigneeName: null,
      projectStatusTicketType: ProjectStatusWorkItemType.DELIVERED,
      customerFeedback: '',
      comments: '',
      status: null,
      wasDemoed: null,
      availableOnProd: null,
      fromPlanning: false,
    });

    updateTickets(tickets);
  }, [updateTickets, formData]);

  const onChangeDemoSkippedField = useCallback(
    (newFormData: FormFieldsData) => {
      const newValue = newFormData.wasDemoSkipped;

      if (newValue === WasDemoSkipped.NO) {
        setFormData({
          ...formData,
          wasDemoSkipped: WasDemoSkipped.NO,
          skipReason: '',
        });
      } else {
        setFormData({
          ...formData,
          wasDemoSkipped: WasDemoSkipped.YES,
          tickets: [],
          deliveryFrequency: '',
          recordLink: '',
        });
      }
    },
    [formData, setFormData],
  );

  const getErrorMessages = useCallback(
    (fieldSource: string) => {
      return formErrors[fieldSource];
    },
    [formErrors],
  );

  const isEditable = checkIsEditable(new Date(formData.to), 7);

  const tooltipForWorkItems = (
    <>
      <p>
        The list of delivered work items is generated based on the planned tickets of Avaliblent weekly cycle. To
        confirm that a ticket has been delivered in the current cycle, check the checkbox <q>Available on prod</q>. If
        there were work items completed outside the scope during the weekly cycle, please add them.
      </p>
      <p>
        <u>
          IMPORTANT: These work items will be included in the weekly report to the client after submitting the demo
          record.
        </u>
      </p>
    </>
  );

  const isHighlightSection = () => {
    return !!getErrorMessages('tickets') || formData.tickets.length === 0;
  };

  return (
    <FormSection>
      <FormRow>
        <DateInput source="from" label="Cycle period from" disabled />
        <DateInput source="to" label="Cycle period to" disabled />
      </FormRow>
      <MediumFormRow>
        <Dropdown
          source="wasDemoSkipped"
          label="Was the demo skipped?"
          value={formData.wasDemoSkipped}
          isRequired
          disabled={!isEditable}
          onChangeCallback={onChangeDemoSkippedField}
          options={Object.entries(WasDemoSkippedLabels).map(entry => {
            const [value, label] = entry;
            return {
              value,
              label,
            };
          })}
        />
      </MediumFormRow>

      {formData.wasDemoSkipped === WasDemoSkipped.NO ? (
        <>
          <FormSection
            title="Delivered work items"
            titleVariant="h6"
            isLargeTooltip
            titleStyle={{ display: 'flex' }}
            tooltipMessage={tooltipForWorkItems}
            subtitle="Should be at least one delivered work item"
            subtitleClass={isHighlightSection() ? 'required-section' : ''}
          >
            {formData.tickets.map((ticket: DeliveredWorkItemType, id: number) => {
              return (
                <DeliveredWorkItemInputRow
                  data={ticket}
                  id={id}
                  formData={formData}
                  disabled={!isEditable}
                  getErrorMessages={getErrorMessages}
                  updateTickets={updateTickets}
                />
              );
            })}
            {isEditable ? <AddListItemButton onClickCallback={addTicket} /> : <></>}
          </FormSection>

          <MediumFormRow>
            <TextInput
              source="recordLink"
              label="Link to the demo record"
              disabled={!isEditable}
              tooltip="The demo meeting must be recorded and a link added to the demo status."
            />
          </MediumFormRow>
          <MediumFormRow>
            <TextInput
              source="deliveryFrequency"
              label="Delivery frequency"
              isRequired
              tooltip="Number of prod deliveries per weekly cycle."
            />
          </MediumFormRow>
        </>
      ) : (
        <MediumFormRow>
          <TextInput
            source="skipReason"
            label="Skip reason"
            isRequired
            disabled={!isEditable}
            tooltip="Please indicate the reason why the demo was not conducted."
          />
        </MediumFormRow>
      )}
    </FormSection>
  );
};

export default ProjectDemoStatusForm;
