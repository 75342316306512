/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import AccountPageLayout from './AccountPageLayout';
import { RelatedOpportunitiesList } from 'crm/opportunity';
import AccountRelatedOpportunitiesBreadcrumbs from 'crm/account/Show/AccountRelatedOpportunitiesBreadcrumbs';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';

const AccountRelatedOpportunities = () => {
  const { search } = useOpportunityService();
  const { id } = useParams();

  const getDataMethod = async params => {
    return search({
      filter: {
        'account.alias': { eq: id },
      },
      ...params,
    });
  };

  return (
    <AccountPageLayout breadcrumbs={<AccountRelatedOpportunitiesBreadcrumbs />}>
      <RelatedOpportunitiesList getDataMethod={getDataMethod} isActionsVisible />
    </AccountPageLayout>
  );
};

export default AccountRelatedOpportunities;
