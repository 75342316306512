/* istanbul ignore file */
import React, { useEffect } from 'react';
import Show from 'shared/uibuilder/show/Show';
import useInvoiceService from 'financialAnalytic/invoice/useInvoiceService';
import { useInvoiceId, useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';
import ViewInvoicePageContent from './ViewInvoicePageContent';

const InvoiceViewPage = () => {
  const { getById, getSensitiveDataByIds, invalidateCache } = useInvoiceService();
  const id = useInvoiceId();
  const { getListUrl } = useInvoiceUrl();

  useEffect(() => {
    return () => {
      invalidateCache();
    };

    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout
        loadSensitiveDataMethod={() => getSensitiveDataByIds([id])}
        sensitiveDataId={id}
        sensitiveDataIdSource="id"
        timerId={getListUrl()}
      >
        <ViewInvoicePageContent />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default InvoiceViewPage;
