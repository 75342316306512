import useAuthorization from 'shared/authorization/authorizationService';
import { FormData } from './FormContext';

export type PermissionsConfig = Dictionary<string[]>;

/**
 * @param config - form fields configuration in form of:
 * {
 *   field: [list of permissions],
 *   ...
 * }
 */
const useSecurityFormMapper = (config: PermissionsConfig) => {
  const { isGranted } = useAuthorization();

  const filterForm = (form: FormData, permissionsConfig: PermissionsConfig) => {
    const filteredForm: FormData = {};

    Object.keys(permissionsConfig).forEach((field: string) => {
      const permissions = permissionsConfig[field];

      if (Array.isArray(permissions)) {
        if (isGranted(permissions)) {
          filteredForm[field] = form[field];
        }
      } else if (typeof permissions === 'object' && form[field]) {
        // @ts-ignore
        const data: FormData = form[field];
        filteredForm[field] = filterForm(data, permissions);
      }
    });

    return filteredForm;
  };

  return {
    filterForm: (form: FormData) => filterForm(form, config),
  };
};

export default useSecurityFormMapper;
