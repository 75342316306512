/* istanbul ignore file */
import React from 'react';
import EmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/shared/EmployeePerformanceBreadcrumbs';
import CreateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/CreateBreadcrumbItem';

const CreateEmployeePerformanceBreadcrumbs = () => {
  return (
    <EmployeePerformanceBreadcrumbs>
      <CreateBreadcrumbItem entity="Performance Result" />
    </EmployeePerformanceBreadcrumbs>
  );
};

export default CreateEmployeePerformanceBreadcrumbs;
