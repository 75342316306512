import React from 'react';
import Select from 'react-select';
import { getCommonInputProps } from 'uibuilder/layout/form/input/BaseInputLayout';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
import DropDownFilterInputOption from '../DropDownFilterInputOption';
import { DefaultDropdownLayoutProps } from 'shared/uibuilder/form/input/Dropdown';

const OPTION_BG_COLOR = '#fff';
const SELECTED_OPTION_BG_COLOR = 'var(--mui-palette-common-surface4) !important';

export const selectStyles = {
  control: () => ({ display: 'none' }),
  menu: () => ({}),
  menuList: (provided: CSSStyleSheet) => ({ ...provided, padding: 0 }),
  option: (provided: CSSStyleSheet, state: Dictionary<boolean>) => ({
    ...provided,
    background: state.isSelected ? SELECTED_OPTION_BG_COLOR : OPTION_BG_COLOR,
  }),
};

interface SelectLayoutProps extends DefaultDropdownLayoutProps {
  selectedValues?: OptionType[];
}

const SelectLayout = ({
  label,
  options = [],
  errorMessages,
  multiple = false,
  onChangeCallback = () => {},
  value: initialValue = '',
  source,
  selectedValues,
  ...defaultLayoutProps
}: SelectLayoutProps) => {
  /**
   * Dropdown onChangeCallback accepts different value format depending on if it's `multiple` or not.
   * @params {array | object} if `multiple` set to true, newValue has object type otherwise it is array of objects
   */
  const onChange = (newValues: OptionType | OptionType[]) => {
    let target;

    if (multiple) {
      target = {
        options: (newValues as OptionType[]).map(option => ({
          ...option,
          selected: true,
        })),
      };
    } else {
      target = {
        value: (newValues as OptionType).value,
      };
    }

    onChangeCallback({ target });
  };

  return (
    <Select
      {...getCommonInputProps({ ...defaultLayoutProps, source }, false)}
      autoFocus
      backspaceRemovesValue={false}
      components={{ DropdownIndicator: null, IndicatorSeparator: null, Option: DropDownFilterInputOption as any }}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      styles={selectStyles as any}
      tabSelectsValue={false}
      isMulti={multiple}
      value={selectedValues}
      onChange={onChange as any}
      options={options}
      menuIsOpen
    />
  );
};

export default SelectLayout;
