/* istanbul ignore file */
import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';

interface VacancyDetailsListInputProps extends InputListProps {}

const VacancyDetailsListInput = ({ inputTemplate, ...props }: VacancyDetailsListInputProps) => {
  return <InputList {...props} inputTemplate={inputTemplate} defaultValue={{}} />;
};

export default VacancyDetailsListInput;
