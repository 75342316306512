/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import SingleProjectBreadcrumbs from 'erp/project/shared/SingleProjectBreadcrumbs';
import { useSowUrl } from '../SowRouter';

const SowBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useSowUrl();
  const dashboardUrl = getListUrl();

  return (
    <SingleProjectBreadcrumbs>
      <ListBreadcrumbItem entity="SOW" link={dashboardUrl} />
      {children}
    </SingleProjectBreadcrumbs>
  );
};

export default SowBreadcrumbs;
