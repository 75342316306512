/* istanbul ignore file */
import React, { useContext } from 'react';

const defaultContext = {
  data: {},
  forms: [],
  loadForm: () => {},
  loading: false,
};

export interface PrepopulationForm {
  archivedStatus: string;
  camundaProcessInstanceId: string;
  createdAt: string;
  employeeId: string;
  id: number;
  name: string;
  submittedAt: string;
  type: string;
  updatedAt: string;
}

export interface PrepopulationContextType {
  data: object;
  forms: PrepopulationForm[];
  loadForm: (formId: Nullable<number | string>) => void;
  loading: boolean;
}

const PrepopulationContext = React.createContext<PrepopulationContextType>(defaultContext);

export const PrepopulationProvider = PrepopulationContext.Provider;

export const usePrepopulationContext = () => useContext(PrepopulationContext);

export default PrepopulationContext;
