/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';

const ExpenseBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useExpenseUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Employee Expenses" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default ExpenseBreadcrumbs;
