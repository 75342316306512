/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import useServiceService from 'erp/service/shared/serviceService';
import { useServiceUrl } from 'erp/service/ServiceRouter';
import UpdateServiceBreadcrumbs from 'erp/service/createupdate/Update/Breadcrumbs';
import ServiceEntityHeader from 'erp/service/shared/ServiceEntityHeader';
import ServiceForm from 'erp/service/createupdate/ServiceForm';

const UpdateService = () => {
  const { update, getById, getValidationSchema } = useServiceService();
  const { getSingleEntityUrl } = useServiceUrl();

  const getDataMethod = async (id: string) => {
    const { rbsTreeNode, ...data } = await getById(id);

    return {
      ...data,
      name: data.name,
      rbsTreeNodeName: rbsTreeNode?.name,
      rbsTreeNodeId: rbsTreeNode?.id,
      redmineAlias: rbsTreeNode?.attributes.alias,
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataMethod}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Service has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Service"
        breadcrumbs={<UpdateServiceBreadcrumbs />}
        entityHeader={<ServiceEntityHeader />}
      >
        <ServiceForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateService;
