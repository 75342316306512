/* istanbul ignore file */
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const VacancyPublicationOptionField = (props: any) => {
  const { getValue } = useFieldHelper(props);
  const data: any = getValue();

  return data.type === 'NON_RELEVANT' ? <TextField value={data.value} /> : null;
};

export default VacancyPublicationOptionField;
