/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import TrainingInstancesBreadcrumbs from '../../shared/TrainingInstancesBreadcrumbs';

const CreateTrainingInstanceBreadcrumbs = () => {
  return (
    <TrainingInstancesBreadcrumbs>
      <CreateBreadcrumbItem entity="Training instance" />
    </TrainingInstancesBreadcrumbs>
  );
};

export default CreateTrainingInstanceBreadcrumbs;
