/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { NewCompetencyType } from 'erp/qualification/newCompetency/newCompetencyService';
import useEmployeeNewCompetencyService from 'erp/employee/newQualification/shared/employeeNewCompetencyService';

const useEmployeeNewQualificationShowHelper = () => {
  const { getEmployeeCompetencies } = useEmployeeNewCompetencyService();
  const [data, setData] = useState([]);
  const employeeId = useEmployeeId() || '';
  const [currentType, setCurrentType] = useState(NewCompetencyType.ORGANIZATIONAL);
  const currentData = data?.filter((comp: any) => String(comp.type) === String(currentType));

  useEffect(() => {
    getEmployeeCompetencies().then((result: any) => {
      setData(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, currentType, getEmployeeCompetencies]);

  return {
    currentData,
    currentType,
    setCurrentType,
  };
};

export default useEmployeeNewQualificationShowHelper;
