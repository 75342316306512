/* istanbul ignore file */
import React from 'react';
// layout
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import FormSection from 'shared/layout/form/FormSection';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
// input
import EducationInput from 'erp/employee/Education/shared/input/EducationInput';
// services
import useEducationService from 'erp/employee/Education/shared/educationService';
import { useEducationId, EDUCATION_ID, useEducationUrl } from 'erp/employee/Education/EducationRouter';
import UpdateEducationBreadcrumbs from 'erp/employee/Education/createupdate/Update/Breadcrumbs';

const UpdateEducation = () => {
  const { update, get, getValidationSchema } = useEducationService();
  const { getSingleEntityUrl } = useEducationUrl();
  const educationId = useEducationId();

  return (
    <UpdateForm
      idSource={EDUCATION_ID}
      submitFormFunc={update}
      getDataFunc={() => get(educationId)}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Education has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Education"
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
        breadcrumbs={<UpdateEducationBreadcrumbs />}
      >
        <FormSection title="Education data" hasAnchorLink>
          <EducationInput isUpdateForm />
        </FormSection>
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateEducation;
