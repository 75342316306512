/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';

export const OFFICE = {
  AUSTIN_1: 'AUSTIN_1',
  MINSK_1: 'MINSK_1',
  KRAKOW_1: 'KRAKOW_1',
  TBILISI_1: 'TBILISI_1',
};

export const OFFICE_LABEL = {
  [OFFICE.AUSTIN_1]: 'Austin',
  [OFFICE.MINSK_1]: 'Minsk',
  [OFFICE.KRAKOW_1]: 'Krakow',
  [OFFICE.TBILISI_1]: 'Tbilisi',
};

const OfficeField = (props: any) => {
  return (
    <EnumField
      {...props}
      options={{
        ...OFFICE_LABEL,
        'Austin-1': 'Austin',
        'Minsk-1': 'Minsk',
        'Krakow-1': 'Krakow',
        'Tbilisi-1': 'Tbilisi',
      }}
    />
  );
};

export default OfficeField;
