/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import useProcessIncidentService from 'camunda/monitoring/instance/Show/incident/processIncidentService';
import ResolveIncidentForm from 'camunda/monitoring/instance/Show/incident/shared/ResolveIncidentForm';

const MESSAGE_CONTEXT = 'ResolveIncidentModalForm';

interface ResolveIncidentModalFormProps {
  closeModal: () => void;
  incidentId: string;
  updateIncidentList: () => void;
}

const ResolveIncidentModalForm = ({ closeModal, incidentId, updateIncidentList }: ResolveIncidentModalFormProps) => {
  const processInstanceId = useProcessInstanceId();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { resolveIncident } = useProcessIncidentService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Resolve process incident
    </Typography>
  );

  return (
    <CreateForm
      submitFormFunc={resolveIncident}
      messageContext={MESSAGE_CONTEXT}
      initialData={{
        processInstanceId,
        incidentId,
      }}
      afterSubmit={{
        execute: useCallback(() => {
          updateIncidentList();
          closeForm();
        }, [updateIncidentList, closeForm]),
        message: 'You are awesome! The Process Incident has been successfully resolved.',
      }}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <ResolveIncidentForm handleCancelClick={closeForm} />
      </ModalWindow>
    </CreateForm>
  );
};

export default ResolveIncidentModalForm;
