/* istanbul ignore file */
import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

enum ATTRIBUTES_LABElS {
  invoiceId = 'Invoice ID',
  leaveId = 'Leave ID',
  employeeAlias = 'Employee',
  projectId = 'Project ID',
  businessObjectId = 'Business Object ID',
}

const JournalEntryAttributesKey = ({ source }: FieldProps) => {
  const { getValue: getKey } = useFieldHelper({ source });
  const key = getKey() as any;

  return <TextField source={source} value={ATTRIBUTES_LABElS[key as keyof typeof ATTRIBUTES_LABElS] || key} />;
};

export default JournalEntryAttributesKey;
