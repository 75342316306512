import { useKernelApi } from 'api';

export const READ_NEW_COMPETENCY_LIST_URL = '/qualification-models';
export const READ_NEW_COMPETENCY_LIST = 'NEW_COMPETENCY_READ_LIST';

export enum NewCompetencyType {
  ORGANIZATIONAL = 'ORGANIZATIONAL',
  SOCIAL = 'SOCIAL',
  ENGINEERING = 'ENGINEERING',
  MANAGEMENT = 'MANAGEMENT',
  BUSINESS_ANALYSIS = 'BUSINESS_ANALYSIS',
}

export const NewCompetencyTypeLabels = {
  [NewCompetencyType.ORGANIZATIONAL]: 'Organizational competencies',
  [NewCompetencyType.SOCIAL]: 'Social competencies',
  [NewCompetencyType.ENGINEERING]: 'Engineering competencies',
  [NewCompetencyType.MANAGEMENT]: 'Management competencies',
  [NewCompetencyType.BUSINESS_ANALYSIS]: 'Business analysis competencies',
};

export interface NewCompetency {
  id: number;
  name: string;
  type: NewCompetencyType;
  levels: NewCompetencyLevel[];
}

export interface NewCompetencyLevel {
  id: number;
  levelNumber: number;
  indicator: string;
  assessmentGuidelines: string;
}

const useNewCompetencyService = () => {
  const { sendGetRequest } = useKernelApi();

  const getCompetencies = async () => {
    const response = await sendGetRequest('/new-competencies/search');
    const json = await response.json();
    return json.result;
  };

  return {
    getCompetencies,
  };
};

export default useNewCompetencyService;
