/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';

const VacancyContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useVacancyService();
  const CONTEXT_NAME = 'VACANCY_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default VacancyContext;
