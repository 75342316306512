/* istanbul ignore file */
import React from 'react';
import SingleProjectBreadcrumbs from 'erp/project/shared/SingleProjectBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateProjectBreadcrumbs = () => {
  return (
    <SingleProjectBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleProjectBreadcrumbs>
  );
};

export default UpdateProjectBreadcrumbs;
