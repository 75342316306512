import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { TextInput } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/FormRow';
import AccountNameInput from 'crm/account/shared/input/AccountNameInput';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import InvoiceTotalInput from 'financialAnalytic/invoice/shared/input/InvoiceTotalInput';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import { BILLING_TYPE, BILLING_TYPE_OPTIONS } from '../useSowService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import DefaultMilestonesTemplate from '../shared/buttons/DefaultMilestonesTemplate';
import MilestonesListWrapper from '../shared/inputs/MilestonesListWrapper';

const SowForm = () => {
  return (
    <>
      <FormSection title="SOW details">
        <FormRow>
          <TextInput disabled source="id" label="SOW ID" />
          <TextInput source="projectName" label="Project Name" />

          <EnumDropdown options={BILLING_TYPE_OPTIONS} source="billingType" label="Billing Type" />

          <AccountNameInput
            source="accountId"
            label="Account"
            accountNameSource="accountId"
            customMapResponse={(account: { accountId: string; accountName: string }) => {
              return {
                id: account.accountId,
                text: account.accountName,
              };
            }}
            accountNameValue={undefined}
          />
        </FormRow>

        <FormRow>
          <NumberInput
            isVisible={data => data.billingType === BILLING_TYPE.MILESTONES}
            source="estimatedHours"
            label="Estimated Hours"
          />
          <InvoiceTotalInput allowNegative source="totalAmount" currencySource="currency" label="Total Amount" />
          <CurrencyDropdown label="Currency" source="currency" options={{ USD: CURRENCY_TYPES.USD }} />
        </FormRow>

        <FormRow>
          <DateInput source="startDate" label="Start Date" />
          <DateInput source="endDate" label="End Date" />
        </FormRow>
      </FormSection>

      <FormSection
        title="Milestones"
        isVisible={data => data.billingType === BILLING_TYPE.MILESTONES}
        actions={[<DefaultMilestonesTemplate />]}
      >
        <BigFormRow>
          <ClearAfterUnmountWrapper>
            <MilestonesListWrapper />
          </ClearAfterUnmountWrapper>
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default SowForm;
