/* istanbul ignore file */
import React, { useEffect } from 'react';
import List from 'shared/uibuilder/list/List';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { FullDateField, TextField } from 'shared/uibuilder/field';
import useProcessInstanceService, { ProcessState } from 'camunda/monitoring/instance/processInstanceService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import ProcessInstanceLinkField from 'camunda/monitoring/instance/shared/ProcessInstanceLinkField';
import IncidentCountField from 'camunda/monitoring/instance/shared/IncidentCountField';
import './ProcessInstanceListPage.scss';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import ProcessKeyFilter from 'camunda/monitoring/instance/List/ProcessKeyFilter';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import ProcessStateField from 'camunda/monitoring/instance/shared/ProcessStateField';
import ProcessDefinitionField from '../shared/ProcessDefinitionField';
import ProcessStateFilter from 'camunda/monitoring/instance/List/ProcessStateFilter';

const ProcessInstanceListPage = () => {
  const { search, cacheProcessKeys } = useProcessInstanceService();

  useEffect(() => {
    cacheProcessKeys();
  }, [cacheProcessKeys]);

  return (
    <List
      getDataMethod={search}
      defaultSortField="startDateTime"
      defaultSortOrder={DESC}
      defaultFilter={{
        state: { eq: ProcessState.ACTIVE },
      }}
    >
      <ListPageLayout
        header={<ListHeader label="Process instances" icon="fa-tasks" />}
        search={
          <SearchInput
            label="Business Key or ID"
            tooltipMessage="Note, that search by business key is case-sensitive."
          />
        }
        filter={
          <Filters>
            <FiltersLayout>
              <ProcessKeyFilter source="processKey" label="Process Key" />
              <ProcessStateFilter source="state" label="Process State" />
            </FiltersLayout>
          </Filters>
        }
      >
        <DataGridLayout
          showTotal={false}
          isSmallTable
          rowProps={(instance: Dictionary<string>) => ({
            className: instance.incidentCount ? 'instance-with-incidents' : '',
          })}
        >
          <ProcessInstanceLinkField source="id" />
          <IncidentCountField source="incidentCount" label="Incidents" />
          <ProcessDefinitionField label="Process Key / Version" keySource="processKey" versionSource="processVersion" />
          <TextField source="businessKey" />
          <ProcessStateField source="state" />
          <FullDateField source="startDateTime" label="Start Date" isSortable />
          <FullDateField source="endDateTime" label="End Date" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ProcessInstanceListPage;
