import { useKernelApi } from 'api';
import { ResourceData } from 'shared/crud/baseCrudService';

export enum AssignmentTargetType {
  PROJECT = 'PROJECT',
  SERVICE = 'SERVICE',
}

const useAssignmentTargetService = () => {
  const { sendPostRequest } = useKernelApi();

  const search = async (request: ResourceData) => {
    const response = await sendPostRequest('/resourceAssignments/targets', request);
    return response.json();
  };

  return {
    search,
  };
};

export default useAssignmentTargetService;
