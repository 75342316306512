import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import BaseInputErrors from 'uibuilder/layout/form/input/BaseInputErrors';
import { MultiSelectInputLayoutType } from 'shared/uibuilder/form/input/MultiSelectInput';
import DefaultFilterInputLayout from '../DefaultFilterInputLayout';
import FilterActions from '../FilterActions';
import useDropDownFilterHelper from '../useDropDownFilterHelper';
import MultipleDropdownListLayout from './MultipleDropdownListLayout';
import MultipleDropdownSearchLayout from './MultipleDropdownSearchLayout';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import './MultipleDropdownFilterLayout.scss';

const MultipleDropdownFilterInputLayout: MultiSelectInputLayoutType = props => {
  const { label, errorMessages, onChangeCallback = () => {}, value: selectedValue = [], source } = props;
  const hasError = errorMessages && errorMessages.length > 0;
  const invalidClass = hasError ? 'is-invalid' : '';
  const [isLoading, setIsLoading] = useState(false);

  const { isMenuOpened, getDisplayedValue, toggleMenu } = useDropDownFilterHelper({ source });
  const displayedValue = getDisplayedValue(selectedValue || []);

  useEffect(() => {
    setIsLoading(false);
  }, [isMenuOpened]);

  return (
    <DefaultFilterInputLayout
      label={label}
      isOpen={isMenuOpened}
      toggleMenu={toggleMenu}
      displayedValue={displayedValue}
      source={source}
      className={classNames('multiselect-button-dropdown', invalidClass)}
    >
      <div className="search-filter-control-wrapper">
        <MultipleDropdownSearchLayout {...props} setIsLoading={setIsLoading} onChangeCallback={onChangeCallback} />

        {!isLoading && !!selectedValue?.length && (
          <>
            <MultipleDropdownListLayout selectedValues={selectedValue} onChangeCallback={onChangeCallback} />
            <BaseInputErrors errorMessages={errorMessages} />
            <div className="mb-1">
              <FilterActions onChangeCallback={toggleMenu} onResetCallback={toggleMenu} />
            </div>
          </>
        )}

        <SearchIcon className="search-filter-control-icon" />
      </div>
    </DefaultFilterInputLayout>
  );
};

export default MultipleDropdownFilterInputLayout;
