/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { INVOICE_CLASSIFICATION_OPTIONS } from 'financialAnalytic/invoice/useInvoiceService';

const InvoiceClassificationDropdown = (props: DefaultInputPropTypes<string>) => {
  return (
    <EnumDropdown
      options={INVOICE_CLASSIFICATION_OPTIONS}
      label="Purchase Classification"
      placeholder="Select Purchase Classification"
      {...props}
    />
  );
};

export default InvoiceClassificationDropdown;
