import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field/index';
import ShowDate from 'shared/uibuilder/ShowDate';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

export interface FullDateFieldProps extends FieldProps {
  showSeconds?: boolean;
}

const FullDateField = ({ showSeconds, ...props }: FullDateFieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  const format = showSeconds ? DATE_FORMAT.FULL_WITH_SECONDS : DATE_FORMAT.FULL;

  const value = getValue() && <ShowDate dateUTC={getValue()} format={format} />;

  return <TextField label={getLabel()} value={value} />;
};

export default FullDateField;
