/* istanbul ignore file */
import React from 'react';
import ViewModeToggle from 'shared/uibuilder/ViewModeToggle/ViewModeToggle';
import AccountIcon from '@mui/icons-material/AccountTreeOutlined';
import TableChartIcon from '@mui/icons-material/TableChartOutlined';

const CACHE_NAME = 'view-mode';

export enum RbsViewMode {
  TREE = 'Tree',
  TABLE = 'Table',
}

const RbsViewModeIcon = {
  [RbsViewMode.TREE]: <AccountIcon />,
  [RbsViewMode.TABLE]: <TableChartIcon />,
};

interface RbsListViewModeProps {
  onModeChange?: (mode: RbsViewMode) => void;
}

const RbsListViewMode = ({ onModeChange = () => {} }: RbsListViewModeProps) => {
  return (
    <ViewModeToggle
      className="responsibilities-view-mode"
      cacheName={CACHE_NAME}
      modes={RbsViewMode}
      icons={RbsViewModeIcon}
      defaultMode={RbsViewMode.TREE}
      onModeChange={onModeChange}
    />
  );
};

export default RbsListViewMode;
