/* istanbul ignore file */
import React from 'react';
import { LIST_DEFAULT_PROPS } from 'shared/uibuilder/list/List';
import SimpleListWithSorting, { SimpleListWithSortingProps } from 'shared/uibuilder/list/SimpleListWithSorting';

const PAGE_SIZE = 30;

const RelatedListWithSorting = ({ children, ...props }: SimpleListWithSortingProps) => {
  return (
    <SimpleListWithSorting {...props} pageSize={PAGE_SIZE}>
      {children}
    </SimpleListWithSorting>
  );
};

RelatedListWithSorting.defaultProps = LIST_DEFAULT_PROPS;

export default RelatedListWithSorting;
