/* istanbul ignore file */
import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import classnames from 'classnames';
import useCountDown from './useCountDown';
import './CountDownTimer.scss';

const CountDownTimer = ({
  targetDate,
  hasExpiredNotice,
  shouldCountFromTargetDate = true,
  isExpired: isExpiredFromProps,
  showMonths = true,
  stopDatetime,
}: {
  targetDate: string;
  hasExpiredNotice?: boolean;
  shouldCountFromTargetDate?: boolean;
  isExpired?: boolean;
  showMonths?: boolean;
  stopDatetime?: string;
}) => {
  const countDown = useCountDown({ targetDate, shouldCountFromTargetDate, stopDatetime });

  if (!countDown) {
    return null;
  }

  const { months, days, hours, minutes, seconds, isExpired: isExpiredFromTimer } = countDown;
  const isExpired = isExpiredFromProps !== undefined ? isExpiredFromProps : isExpiredFromTimer;

  if (isExpired && hasExpiredNotice) {
    return <div className="expired-notice">Expired!!!</div>;
  } else {
    return (
      <>
        <div
          className={classnames('countdown-timer', {
            'countdown-timer__expired': isExpired,
            'countdown-timer--short': !showMonths && !months,
          })}
        >
          {showMonths || !!months ? (
            <>
              <DateTimeDisplay type="Mths" value={months} hasMinus={isExpired} />
              <p>:</p>
            </>
          ) : null}
          <DateTimeDisplay type="Days" value={days} />
          <p>:</p>
          <DateTimeDisplay type="Hrs" value={hours} />
          <p>:</p>
          <DateTimeDisplay type="Mins" value={minutes} />
          <p>:</p>
          <DateTimeDisplay type="Secs" value={seconds} />
        </div>
      </>
    );
  }
};

export default CountDownTimer;
