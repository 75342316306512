/* istanbul ignore file */
// libs
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import LeaveEntityHeader from 'erp/leave/shared/LeaveEntityHeader';
import UpdateLeaveBreadcrumbs from 'erp/leave/createupdate/Update/Breadcrumbs';
import LeaveForm from 'erp/leave/createupdate/LeaveForm';
import useLeaveService from 'erp/leave/shared/leaveService';
import useLeavesErrorMap from 'erp/leave/createupdate/errorHandlers';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import LeaveNotStrictRulesHandler from '../LeaveNotStictRulesHandler';

const UpdateLeave = () => {
  const { update, getById, getValidationSchema } = useLeaveService();
  const errorsMap = useLeavesErrorMap();
  const { getSingleEntityUrl } = useLeaveUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Leave has been successfully saved.',
      }}
      errorsMap={errorsMap}
    >
      <LeaveNotStrictRulesHandler errorMap={errorsMap} />
      <UpdatePageLayout
        title="Edit: Leave"
        breadcrumbs={<UpdateLeaveBreadcrumbs />}
        entityHeader={<LeaveEntityHeader />}
      >
        <LeaveForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateLeave;
