import React from 'react';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import { Switch, useParams } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import RecruitingDashboardListPage from './List';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { HIRING_REQUEST_DASHBOARD } from './useRecruitingDashboardService';

export const RECRUITING_DASHBOARD_PATH = '/recruiting-dashboard';

export const useRecruitingDashboardRoute = () => {
  const routers = useRoute({ listRoute: RECRUITING_DASHBOARD_PATH });

  return {
    ...routers,
  };
};

export const useRecruitingDashboardUrl = () => {
  const { ...restUrls } = useEntityUrl({
    baseLink: RECRUITING_DASHBOARD_PATH,
  });

  return {
    ...restUrls,
  };
};

export const useRecruitingDashboardId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id;
};

const RecruitingDashboardRouter = () => {
  const { listRoute } = useRecruitingDashboardRoute();

  return (
    <ProtectedRouter basePath={RECRUITING_DASHBOARD_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={HIRING_REQUEST_DASHBOARD}>
          <RecruitingDashboardListPage />
        </GlobalProtectedRoute>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

RecruitingDashboardRouter.getRouterPath = () => RECRUITING_DASHBOARD_PATH;

export default RecruitingDashboardRouter;
