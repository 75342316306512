import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useSowUrl } from '../../SowRouter';
import { WRITE_SOW } from '../../useSowService';

const CreateSowButton = () => {
  const { getCreateUrl } = useSowUrl();

  return (
    <CreateButton url={getCreateUrl()} label="Create SOW" key="create-sow" outline permissionToCheck={WRITE_SOW} />
  );
};

export default CreateSowButton;
