/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import OneClick from 'authentication/OneClick';
import { Router, Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import { APPLICATION_URL } from 'ApplicationRouter';
import isOneClickEnabled from 'authentication/OneClick/isOneClickEnabled';
import AzureCallbackPage from 'authentication/azure/AzureCallbackPage';
import { useSearchParameter } from 'shared/routing/helper';

export const AZURE_ACCESS_CODE = 'code';
export const AZURE_ACCESS_ERROR = 'error';
export const ONE_CLICK_PATH = '/signin/oneclick';
export const OAUTH_AZURE_PATH = '/oauth/azure';
export const AZURE_LOGOUT_PATH = `${OAUTH_AZURE_PATH}/logout`;
export const AZURE_CALLBACK_PATH = `${OAUTH_AZURE_PATH}/callback`;
export const AZURE_CALLBACK_URL = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_CALLBACK;
export const AZURE_TENANT_ID = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_TENANT_ID;
export const AZURE_LOGOUT_URL = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_LOGOUT_URL;
export const AZURE_CLIENT_ID = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_CLIENT_ID;

export const useAuthenticationUrl = () => {
  const getAzureAuthUrl = () => {
    return (
      'https://login.microsoftonline.com/' +
      `${AZURE_TENANT_ID}/` +
      'oauth2/authorize?' +
      'response_type=code&' +
      `redirect_uri=${encodeURIComponent(APPLICATION_URL || '')}` +
      `${encodeURIComponent(AZURE_CALLBACK_URL || '')}&` +
      'response_mode=query&' +
      `client_id=${AZURE_CLIENT_ID}`
    );
  };

  const getAzureLogoutUrl = () => {
    return `${AZURE_LOGOUT_URL}?post_logout_redirect_uri=${encodeURIComponent(APPLICATION_URL || '')}`;
  };

  return {
    getAzureAuthUrl,
    getAzureLogoutUrl,
  };
};

export const useAzureAccessCode = () => {
  return useSearchParameter(AZURE_ACCESS_CODE);
};

export const useAzureAccessError = () => {
  return useSearchParameter(AZURE_ACCESS_ERROR);
};

export const useAuthenticationRoute = () => ({
  oneClickRoute: ONE_CLICK_PATH,
  azureLogoutRoute: AZURE_LOGOUT_PATH,
  azureCallbackRoute: AZURE_CALLBACK_PATH,
});

const AUTHENTICATION_PATHS = [ONE_CLICK_PATH, AZURE_LOGOUT_PATH, AZURE_CALLBACK_PATH];

const AuthenticationRouter: Router = () => {
  const { oneClickRoute, azureLogoutRoute, azureCallbackRoute } = useAuthenticationRoute();

  return (
    <Route path={AUTHENTICATION_PATHS}>
      <Switch>
        {isOneClickEnabled() ? <Route path={oneClickRoute} component={OneClick} /> : null}

        <Route path={azureLogoutRoute} component={() => null} exact />

        <Route path={azureCallbackRoute} component={AzureCallbackPage} />

        <Route component={Page404} />
      </Switch>
    </Route>
  );
};

AuthenticationRouter.getRouterPath = () => AUTHENTICATION_PATHS;

export default AuthenticationRouter;
