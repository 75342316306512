/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useServiceService from 'erp/service/shared/serviceService';
import { useServiceId } from 'erp/service/ServiceRouter';
import ViewProjectPageContent from 'erp/service/Show/ViewServicePageContent';

const ViewService = () => {
  const id = useServiceId();
  const { getById } = useServiceService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewProjectPageContent />
    </Show>
  );
};

export default ViewService;
