/* istanbul ignore file */
import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import { useCommonSkillService } from 'erp/employee/commonSkills/commonSkill/shared/commonSkillService';

export interface CommonSkillInputProps {
  source: string;
  errorSource?: string;
  label?: string;
  isRequired?: boolean;
  showLoader?: boolean;
  placeholder?: string;
  [key: string]: any;
}

const CommonSkillInput = ({ source, ...props }: CommonSkillInputProps) => {
  const { findAllCommonSkills } = useCommonSkillService();

  const mapResults = (commonSkill: any) => ({
    id: commonSkill.id.toString(),
    text: commonSkill.name,
    commonSkillId: commonSkill.id,
  });

  const buildCommonSkillAutoSuggestLabel = (commonSkill: any) => {
    return commonSkill ? commonSkill.name : '';
  };

  return (
    <SearchInput
      searchString="name"
      nameSource="commonSkill.name"
      searchRequest={findAllCommonSkills}
      noResultMessage="No Common Skills are found."
      source={source}
      mapResults={mapResults}
      // @ts-ignore
      buildAutoSuggestLabel={buildCommonSkillAutoSuggestLabel}
      {...props}
    />
  );
};

export default CommonSkillInput;
