/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LEAVE_PAYOUT_OPTIONS } from '../leaveService';

const LeavePayoutOptionField = ({ value: inputValue, source, label }) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || (getValue && getValue(source));

  return <EnumField label={label} value={value} options={LEAVE_PAYOUT_OPTIONS} />;
};

LeavePayoutOptionField.propTypes = {
  value: string,
  source: string,
  label: string,
};

LeavePayoutOptionField.defaultProps = {
  value: null,
  source: null,
  label: null,
};

export default LeavePayoutOptionField;
