import List from 'shared/uibuilder/list/List';
import useOfficeService from 'erp/office/useOfficeService';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TextField from 'shared/uibuilder/field/TextField';
import { SearchLinkField } from 'shared/uibuilder/field';
import { useOfficeUrl } from 'erp/office/OfficeRouter';
import ViewOfficeButton from 'erp/office/shared/ViewOfficeButton';
import ViewOfficeRoomsButton from 'erp/office/shared/ViewOfficeRoomsButton';

const OfficeListPage = () => {
  const { listAll } = useOfficeService();
  const { getSingleEntityUrl } = useOfficeUrl();

  return (
    <List getDataMethod={listAll} defaultSortField="name" defaultSortOrder="ASC">
      <ListPageLayout header={<ListHeader label="Offices" />}>
        <DataGridLayout buttons={[<ViewOfficeButton source="id" />, <ViewOfficeRoomsButton />]}>
          <SearchLinkField source="id" url={getSingleEntityUrl} label="ID" isSortable />
          <TextField source="name" label="Name" />
          <TextField source="address.country" label="Country" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default OfficeListPage;
