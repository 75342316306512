/* istanbul ignore file */
// libs
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateContactBreadcrumbs from 'crm/contact/createupdate/Update/Breadcrumbs';
import useContactService from 'crm/contact/shared/contactService';
import ContactForm from 'crm/contact/createupdate/ContactForm';
import useErrorMap from 'crm/contact/createupdate/errorHandlers';
import { useContactUrl } from 'crm/contact/ContactRouter';

const UpdateContact = () => {
  const { update, getById, getValidationSchema } = useContactService();
  const { errorsMap } = useErrorMap();
  const { getSingleEntityUrl } = useContactUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Contact has been successfully saved.',
      }}
      errorsMap={errorsMap}
    >
      <UpdatePageLayout title="Edit: Contact" breadcrumbs={<UpdateContactBreadcrumbs />}>
        <ContactForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateContact;
