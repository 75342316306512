/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import useAssetService, { READ_ASSET } from 'financialAnalytic/assets/useAssetService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateAssetButton from 'financialAnalytic/assets/shared/button/CreateAssetButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import AssetLink from 'financialAnalytic/assets/shared/field/AssetLink';
import ViewAssetButton from 'financialAnalytic/assets/shared/button/ViewAssetButton';
import { TextField, ExpandingField, DateField, SearchResultField } from 'shared/uibuilder/field';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import AssetCategoryField from 'financialAnalytic/assets/shared/field/AssetCategoryField';
import AssetCategoryTypeField from 'financialAnalytic/assets/shared/field/AssetCategoryTypeField';
import AssetConditionField from 'financialAnalytic/assets/shared/field/AssetConditionField';
import PerformAssetCostProratingButton from 'financialAnalytic/assets/shared/button/PerformAssetCostProratingButton';
import useAuthorization from 'shared/authorization/authorizationService';
import Box from 'uibuilder/Box';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import AssetCategoryDropdownFilter from 'financialAnalytic/assets/shared/filter/AssetCategoryDropdownFilter';
import AssetTypeDropdownFilter from 'financialAnalytic/assets/shared/filter/AssetTypeDropdownFilter';
import AssetAssignmentFilter from 'financialAnalytic/assets/shared/filter/AssetAssignmentFilter';
import AssetStatusDropdownFilter from 'financialAnalytic/assets/shared/filter/AssetStatusDropdownFilter';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import { EmployeeFilter, EmployeeLinkFieldWithNameLoading } from 'erp/employee';
import OfficeFilter from 'financialAnalytic/shared/filter/OfficeFilter';

const filter = (
  <Filters>
    <FiltersLayout>
      <AssetCategoryDropdownFilter label="Category" source="category" />
      <EmployeeFilter source="specification:currentAssignmentOwner" label="Current Owner" />
      <AssetTypeDropdownFilter label="Type" source="type" />
      <AssetAssignmentFilter label="Has owner" source="currentAssignmentStatus" />
      <AssetStatusDropdownFilter label="Status" source="status" />
      <OfficeFilter label="Office" source="office" />
    </FiltersLayout>
  </Filters>
);

const AssetListPage = () => {
  const { search } = useAssetService();
  const { isGranted } = useAuthorization();

  return (
    <List getDataMethod={search} defaultSortField="latestAccountingCycle" defaultSortOrder="DESC">
      <ListPageLayout
        header={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              h1: {
                mr: 'auto',
              },
              button: {
                marginTop: '10px',
                marginBottom: '20px',
                ml: 1,
              },
            }}
          >
            <ListHeader label="Assets" />
            <PerformAssetCostProratingButton />
          </Box>
        }
        filter={filter}
        search={<SearchInput label="Name" />}
        actions={[<CreateAssetButton />]}
      >
        <DataGridLayout buttons={[<ViewAssetButton source="inventoryNumber" isVisible={isGranted(READ_ASSET)} />]}>
          <AssetLink
            source="inventoryNumber"
            nameSource="inventoryNumber"
            label="Inventory Number"
            width="150px"
            isClickable={isGranted(READ_ASSET)}
            isSortable
          />
          <OfficeField source="office" label="Office" width="125px" isSortable />
          <AssetCategoryField source="category" label="Category" isSortable />
          <AssetCategoryTypeField source="type" label="Type" categorySource="category" isSortable />
          <SearchResultField source="name" label="Name" isSortable />
          <EmployeeLinkFieldWithNameLoading source="assignmentDetails.currentOwner" label="Current Owner" />
          <AssetConditionField source="condition" label="Condition" isSortable />
          <DateField
            dateFormat={DATE_FORMAT.SIMPLE_MONTH}
            source="latestAccountingCycle"
            label="Latest Amortization Cycle"
            isSortable
            width={180}
          />

          <ExpandingField source="characteristics" width={260} maxValueLength={124}>
            <TextField source="characteristics" label="Characteristics" />
          </ExpandingField>
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default AssetListPage;
