/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { TextField } from 'shared/uibuilder/field';
import useJournalEntryService from 'financialAnalytic/journalEntry/useJournalEntryService';
import JournalEntryHeader from 'financialAnalytic/journalEntry/shared/JournalEntryHeader';
import CopyJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/CopyJournalEntryButton';
import EditJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/EditJournalEntryButton';
import ReverseJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/ReverseJournalEntryButton';
import SingleJournalEntryBreadcrumbs from 'financialAnalytic/journalEntry/shared/SingleJournalEntryBreadcrumbs';
import { useJournalEntryId, useTransferId } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import JournalEntryTransfersList from '../shared/field/JournalEntryTransfersList';
import JournalEntryLink from '../shared/field/JournalEntryLink';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import ProjectLinkField from 'erp/project/shared/field/ProjectLinkField';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import { READ_PROJECTS_LIST } from 'erp/project/shared/projectService';
import JournalEntryAttributesList from 'financialAnalytic/journalEntry/shared/field/JournalEntryAttributesList';
import JournalEntryBussinessObjectField from 'financialAnalytic/journalEntry/shared/field/JournalEntryBussinessObjectField';
import InvoiceLink from 'financialAnalytic/invoice/shared/field/InvoiceLink';
import LeaveLink from 'erp/leave/shared/field/LeaveLink';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';

const JournalEntryViewPage = ({ shouldSearchByTransferId }: { shouldSearchByTransferId?: boolean }) => {
  const { getById, getJournalEntryByTransferId } = useJournalEntryService();
  const id = useJournalEntryId();
  const transferId = useTransferId();

  return (
    <Show
      key={`journal-entry-${id}`}
      getDataMethod={() => (shouldSearchByTransferId ? getJournalEntryByTransferId(transferId) : getById(id))}
    >
      <ShowPageLayout
        entityHeader={<JournalEntryHeader />}
        breadcrumbs={<SingleJournalEntryBreadcrumbs />}
        headerButtons={[<EditJournalEntryButton />, <CopyJournalEntryButton />, <ReverseJournalEntryButton />]}
        hasSeparateSections
      >
        <ShowSection>
          <SectionRow>
            <TextField label="Date" source="asDateOf" />
            <OfficeField source="attributes.office" />
            <JournalEntryLink
              label="Original Journal Entry"
              source="attributes.revertedOriginalJournalEntryId"
              isVisible={(data: any) => !!data.getValueBySource('attributes')?.revertedOriginalJournalEntryId}
            />
            <JournalEntryBussinessObjectField
              source="attributes.businessObjectId"
              typeSource="attributes.businessObjectType"
              isVisible={(data: any) => !!data.getValueBySource('attributes')?.businessObjectType}
            />
            <InvoiceLink
              label="Invoice"
              source="attributes.invoiceId"
              nameSource="attributes.invoiceId"
              isVisible={(data: any) => {
                const attributes = data.getValueBySource('attributes') || {};

                return attributes.invoiceId && attributes.businessObjectType !== 'INVOICE';
              }}
            />
            <LeaveLink
              label="Leave"
              source="attributes.leaveId"
              nameSource="attributes.leaveId"
              isVisible={(data: any) => data.getValueBySource('attributes')?.leaveId}
            />
            <EmployeeLinkField
              label="Employee"
              source="attributes.employeeAlias"
              nameSource="attributes.employeeName"
              isVisible={(data: any) => data.getValueBySource('attributes')?.employeeAlias}
            />
            <ProjectLinkField
              source="attributes.projectId"
              nameSource="attributes.projectId"
              label="Project"
              isVisible={(data: any) => data.getValueBySource('attributes')?.projectId}
              permissionToCheck={READ_PROJECTS_LIST}
            />
            <AccountLinkField
              label="Account"
              source="attributes.accountId"
              idSource="attributes.accountId"
              isInternal
              idValue={undefined}
              isVisible={(data: any) => data.getValueBySource('attributes')?.accountId}
            />
            <TextField
              source="attributes.sowId"
              label="SOW Number"
              isVisible={(data: any) => data.getValueBySource('attributes')?.sowId}
            />
          </SectionRow>
          <BigSectionRow>
            <TextField label="Description" source="description" />
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Transfers">
          <JournalEntryTransfersList source="transfers" />
        </ShowSection>
        <ShowSection title="Attributes">
          <JournalEntryAttributesList source="attributes" />
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default JournalEntryViewPage;
