/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useContractsUrl } from 'erp/contracts/ContractsRouter';

const ViewContractButton = props => {
  const { getSingleEntityUrl } = useContractsUrl();

  return (
    <LinkButton url={id => getSingleEntityUrl(id)} key="view-contract" {...props}>
      View
    </LinkButton>
  );
};

export default ViewContractButton;
