/* istanbul ignore file */
import React from 'react';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import PresaleOutputForm from 'crm/opportunity/presale/output/form/PresaleOutputForm';

const CreatePresaleOutputForm = ({ submitFormFunc, onCancel, ...props }: any) => {
  const { getValidationSchema } = usePresaleOutputService();

  return (
    <CreateForm
      submitFormFunc={submitFormFunc}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        requiredResources: [],
      }}
      {...props}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <PresaleOutputForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreatePresaleOutputForm;
