/* istanbul ignore file */
import React, { useCallback, useEffect } from 'react';
import { Dropdown, TextInput } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import {
  VARIABLE_OBJECT_TYPES,
  VARIABLE_OBJECT_TYPES_LABELS,
  VARIABLE_TYPES,
  VARIABLE_TYPES_LABELS,
} from 'camunda/monitoring/instance/Show/variables/processVariablesService';
import VariableValueInput from 'camunda/monitoring/instance/Show/variables/shared/input/VariableValueInput';

type AddProcessVariableFormProps = {
  handleCancelClick: () => void;
};
export const DateValueFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
export const DateTimeValueFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/;

const AddProcessVariableForm = ({ handleCancelClick }: AddProcessVariableFormProps) => {
  const {
    data = {},
    setFormData = () => {},
    submitForm = () => {},
    addFormError = (source: string, error: string) => {},
    formErrors = {},
    isSubmitEnabled,
    isLoadingArtifact,
  } = useFormContext();

  const updateValueField = useCallback(
    (value: any) => {
      setFormData({
        ...data,
        value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormData],
  );

  useEffect(() => {
    setFormData({
      ...data,
      value: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type]);

  const valueInput = useCallback(() => {
    return (
      <VariableValueInput
        source="value"
        title="Variable value"
        isRequired
        type={data.type}
        objectTypeName={data.objectTypeName}
        updateValueField={updateValueField}
      />
    );
  }, [data.type, data.objectTypeName, updateValueField]);

  const submitFormFunc = useCallback(() => {
    if (
      (data.type === VARIABLE_TYPES.DATE && !data.value.match(DateValueFormat)) ||
      (data.type === VARIABLE_TYPES.OBJECT &&
        data.objectTypeName === VARIABLE_OBJECT_TYPES.CAMUNDA_DATE &&
        !data.value.match(DateValueFormat)) ||
      (data.type === VARIABLE_TYPES.OBJECT &&
        data.objectTypeName === VARIABLE_OBJECT_TYPES.CAMUNDA_DATETIME &&
        !data.value.match(DateTimeValueFormat))
    ) {
      const { value: valueErrors } = formErrors;
      if (!valueErrors || valueErrors.length === 0) {
        addFormError('value', 'Invalid date format');
      }
    } else {
      submitForm();
    }
  }, [addFormError, data.objectTypeName, data.type, data.value, formErrors, submitForm]);

  return (
    <>
      <TextInput source="instanceId" label="Process instance id" isRequired disabled />
      <TextInput source="name" label="Variable name" isRequired />
      <Dropdown
        source="type"
        label="Variable type"
        isRequired
        options={Object.entries(VARIABLE_TYPES_LABELS).map(entry => {
          const [value, label] = entry;
          return {
            value,
            label,
          };
        })}
      />
      <Dropdown
        source="objectTypeName"
        label="Variable Object Type"
        isVisible={data.type === VARIABLE_TYPES.OBJECT}
        isRequired={data.type === VARIABLE_TYPES.OBJECT}
        options={Object.entries(VARIABLE_OBJECT_TYPES_LABELS).map(entry => {
          const [value, label] = entry;
          return {
            value,
            label,
          };
        })}
      />
      {valueInput()}
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitFormFunc}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default AddProcessVariableForm;
