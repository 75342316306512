/* istanbul ignore file */
import React, { useContext } from 'react';
import { store } from 'shared/reducer/store';
import { AuthenticationToken } from 'authentication/DefaultTokenProvider';

export interface AuthenticationContext {
  getUserAlias: () => Nullable<string>;
  isAuthenticated: boolean;
  getToken: () => AuthenticationToken;
}

export interface AuthenticationProviderProps {
  userAlias: Nullable<string>;
  isAuthenticated: boolean;
  getToken: () => AuthenticationToken;
  children: React.ReactNode;
}

const AuthenticationReactContext = React.createContext<AuthenticationContext>({
  getUserAlias: () => null,
  isAuthenticated: false,
  getToken: () => store.getState().authToken?.authenticationToken?.token || null,
});

export const useAuthenticationProvider = () => useContext(AuthenticationReactContext);

export const AuthenticationProvider = ({
  getToken,
  isAuthenticated,
  userAlias,
  children,
}: AuthenticationProviderProps) => {
  const getUserAlias = () => userAlias;

  return (
    <AuthenticationReactContext.Provider value={{ getToken, isAuthenticated, getUserAlias }}>
      {children}
    </AuthenticationReactContext.Provider>
  );
};
