import React, { useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import AssignEmployeeToWorkplaceForm from 'erp/room/shared/input/AssignEmployeeToWorkplaceForm';
import Button from 'uibuilder/button/Button';

const AssignEmployeeToWorkplaceButton = (props: any) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Button {...props} onClick={openModal}>
        Assign
      </Button>

      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        onToggle={closeModal}
        title="Assign Employee to Workplace"
        hasCancelButton
      >
        <AssignEmployeeToWorkplaceForm closeModal={closeModal} modalOpen={modalOpen} />
      </ModalWindow>
    </>
  );
};

export default AssignEmployeeToWorkplaceButton;
