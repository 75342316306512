import React from 'react';
import { Button } from 'uibuilder/button';
import useAuthorization from 'shared/authorization/authorizationService';
import { TimelineRegistry } from './timelineRegistryHelper';
import AddIcon from '@mui/icons-material/AddOutlined';
import './index.scss';

type AddTimelineItemInputProps = {
  registries: TimelineRegistry[];
  selectItemCallback: (registry: TimelineRegistry) => void;
};

type RegistriesMap = Dictionary<TimelineRegistry>;

const AddTimelineItemInput = ({ registries, selectItemCallback }: AddTimelineItemInputProps) => {
  const { isGranted } = useAuthorization();
  const registriesMap: RegistriesMap = {};

  registries.forEach(registry => {
    registriesMap[registry.name] = registry;
  });

  const onButtonClick = (registryName: string) => {
    selectItemCallback(registriesMap[registryName]);
  };

  const getButtonLayout = ({ name, source }: TimelineRegistry) => (
    <Button
      onClick={() => {
        onButtonClick(name);
      }}
      key={`btn-${source}`}
      startIcon={<AddIcon />}
    >
      {name}
    </Button>
  );

  const buttons = registries
    .filter(registry => !registry.permissionToCheck || isGranted(registry.permissionToCheck))
    .filter(registry => !registry.withoutAddFunctionality)
    .map(registry => getButtonLayout(registry));

  return <>{buttons.length ? buttons : null}</>;
};

export default AddTimelineItemInput;
