/* istanbul ignore file */
import React from 'react';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import {
  PERFORMANCE_CONFIDENCE_GRADES_OPTIONS,
  PERFORMANCE_DETAILS_SECTIONS,
  PERFORMANCE_GRADES_OPTIONS,
  PerformanceSectionVersions,
} from 'erp/employee/salaries/shared/salaryService';
import { EnumField } from 'shared/uibuilder/field';
import MediumSectionRow from 'shared/uibuilder/layout/MediumSectionRow';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

export interface EmployeePerformanceDetailsFieldsProps {
  source: string;
}

const EmployeePerformanceDetailsFields = ({ source }: EmployeePerformanceDetailsFieldsProps) => {
  const { data } = useShowContext();
  const displayHelpfulnessGrade = data.getValueBySource(`helpfulnessGrade`);
  const oldModel = data.getValueBySource(`${source}.oldModel`);

  return displayHelpfulnessGrade ? (
    <ShowSection>
      <EnumField options={PERFORMANCE_GRADES_OPTIONS} source="helpfulnessGrade" label="Helpfulness Grade" />
    </ShowSection>
  ) : (
    <div>
      {PERFORMANCE_DETAILS_SECTIONS.filter(
        section =>
          section.version === PerformanceSectionVersions.GENERAL ||
          (oldModel
            ? section.version === PerformanceSectionVersions.OLD
            : section.version === PerformanceSectionVersions.NEW),
      ).map(section => {
        const fieldSource = `${source}.${section.field}`;
        return (
          <ShowSection title={section.title} titleVariant="h5">
            <MediumSectionRow>
              <EnumField options={PERFORMANCE_GRADES_OPTIONS} source={`${fieldSource}.evaluationGrade`} />
              <EnumField options={PERFORMANCE_CONFIDENCE_GRADES_OPTIONS} source={`${fieldSource}.confidenceGrade`} />
            </MediumSectionRow>
          </ShowSection>
        );
      })}
    </div>
  );
};

export default EmployeePerformanceDetailsFields;
