/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useProjectService from 'erp/project/shared/projectService';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { ProjectDropdownProps } from 'erp/project/shared/input/ProjectDropdown';

const ExpenseProjectDropdown = ({
  loadDataMethod,
  mapResults: mapResultsFromProps,
  ...props
}: ProjectDropdownProps) => {
  const { searchAllSuggestions } = useProjectService();
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();
  const [loadedProjects, setLoadedProjects] = useState<any[]>([]);

  const mapResults = (project: ResultResourceData) => ({
    value: project.id,
    label: `${project.projectName} (SOW Number: ${project.sowId})`,
    accountId: project.accountId,
    sowId: project.sowId,
  });

  const loadProjects = async () => {
    const projects = await searchAllSuggestions({});
    setLoadedProjects(projects);

    return {
      result: projects,
    };
  };

  const onChangeCallback = (values: any) => {
    const projectId = values[props.source];
    const projectData = projectId ? loadedProjects.find(data => data.id === projectId) : {};

    if (rawOnChangeCallback) {
      rawOnChangeCallback({ project: { projectId, accountId: projectData.accountId, sowId: projectData.sowId } });
    }
  };

  useEffect(() => {
    return () => {
      if (rawOnChangeCallback) {
        rawOnChangeCallback({ project: null });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadDropdown
      loadDataMethod={loadProjects}
      mapResults={mapResultsFromProps || mapResults}
      showLoader
      onChangeCallback={onChangeCallback}
      placeholder="Select Project"
      {...props}
    />
  );
};

export default ExpenseProjectDropdown;
