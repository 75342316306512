/* istanbul ignore file */
import React from 'react';
import CreateJournalEntryButton from './CreateJournalEntryButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const CreateJournalEntryWithParamsButton = () => {
  const { data } = useShowContext();

  return (
    <CreateJournalEntryButton
      urlParams={`?accountId=${data.getValueBySource('id')}&currency=${data.getValueBySource('currency')}`}
      variant="outlined"
    />
  );
};

export default CreateJournalEntryWithParamsButton;
