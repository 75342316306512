/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import { CurrencyInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

interface ProjectAllocationInputProps {
  label?: string;
  source: string;
  placeholder?: string;
  className?: string;
  onChangeCallback: (data: Dictionary<any>) => void;
  value: number;
}

const ProjectAllocationInput = ({ label, ...props }: ProjectAllocationInputProps) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper<Object>(props);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const onChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  const onChange = (obj: any) => {
    if (onChangeCallback) {
      onChangeCallback({ [source]: +obj[source] });
    }
  };

  const validate = () => {
    const value = getValue() as number;

    if ((!value && value !== 0) || value > 12) {
      setErrorMessages(['Allocation must be between 0 and 12, decimals are allowed']);
    }
  };

  const clearValidationCallback = () => {
    setErrorMessages([]);
  };

  useEffect(() => {
    if (getValue()) {
      validate();
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrencyInput
      {...props}
      label={label}
      onChangeCallback={onChange}
      validationCallback={validate}
      clearValidationCallback={clearValidationCallback}
      errorMessages={errorMessages}
      maxDecimal={2}
      maxInteger={2}
      formatInitialValue={(value?: StringOrNumber) => value as string}
      autocomplete="off"
    />
  );
};

export default ProjectAllocationInput;
