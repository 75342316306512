/* istanbul ignore file */
// @ts-nocheck // Remove after refactoring TextInput file with TS
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { TextInput } from 'shared/uibuilder/form/input';
import TimezoneDropdown from 'user/Settings/shared/input/TimezoneDropdown';

const EXCHANGE_ID_TOOLTIP = 'The identification string used to authenticate the user on MS Exchange server.';

const SettingsForm = () => (
  <FormSection>
    <FormRow>
      <TextInput source="exchangeId" label="Microsoft Exchange ID" tooltip={EXCHANGE_ID_TOOLTIP} />
    </FormRow>

    <FormRow>
      <TimezoneDropdown source="timezone" />
    </FormRow>
  </FormSection>
);

export default SettingsForm;
