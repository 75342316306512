/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import AssignmentForm from 'erp/assignment/createupdate/AssignmentForm';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateAssignmentBreadcrumbs from 'erp/assignment/createupdate/Update/Breadcrumbs';
import useAssignmentService from 'erp/assignment/shared/assignmentService';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';

const UpdateAssignment = () => {
  const { update, getAssignmentById, getValidationSchema } = useAssignmentService();
  const { getSingleEntityUrl } = useAssignmentUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getAssignmentById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Assignment has been successfully saved.',
      }}
    >
      <UpdatePageLayout title="Edit: Assignment" breadcrumbs={<UpdateAssignmentBreadcrumbs />}>
        <AssignmentForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateAssignment;
