import React from "react";
import { SearchInput } from "shared/uibuilder/form/input";
import useOpportunityService from "crm/opportunity/shared/opportunityService";

export const mapResponse = (opportunity: any) => ({
  id: opportunity.id,
  text: buildOpportunityAutoSuggestLabel(opportunity)
});

export const buildOpportunityAutoSuggestLabel = (opportunity: any) =>
  !!opportunity && `${opportunity.name} (id: ${opportunity.id})`;

interface OpportunityInputProps {
  mapResults?: (opportunity: any) => void;
  props?: any;
}

const OpportunityInput = ({ mapResults, ...props }: OpportunityInputProps) => {
  // @ts-ignore
  const { search } = useOpportunityService();

  return (
    <SearchInput
      // @ts-ignore
      buildAutoSuggestLabel={buildOpportunityAutoSuggestLabel}
      searchRequest={search}
      noResultMessage="No Opportunities are found."
      mapResults={mapResponse}
      {...props}
    />
  );
};

OpportunityInput.defaultProps = {
  mapResults: mapResponse
};

export default OpportunityInput;