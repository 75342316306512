/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { METRICS, METRICS_CONFIG } from 'erp/employee/performanceReport/usePerformanceReportService';

const PerformanceMetricDropdown = (props: any) => {
  const aggregatableMetrics = {
    [METRICS.TIMELY_CHECKIN]: METRICS_CONFIG[METRICS.TIMELY_CHECKIN].label,
    [METRICS.SAME_DAY_TIME_REPORT]: METRICS_CONFIG[METRICS.SAME_DAY_TIME_REPORT].label,
    [METRICS.EFFECTIVE_CAPACITY]: METRICS_CONFIG[METRICS.EFFECTIVE_CAPACITY].label,
  };

  return <EnumDropdown {...props} placeholder="Choose Metric" options={aggregatableMetrics} />;
};

export default PerformanceMetricDropdown;
