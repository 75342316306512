import React, { useState } from 'react';
import { any, func, objectOf, oneOfType, string, bool } from 'prop-types';
// styles
import './DocumentLayout.scss';
import ArtifactPreviewWrapper from './ArtifactPreviewWrapper';
import DownloadButton from './DownloadButton';

const DocumentLayout = ({ value, handleDownload, handleOpenPreview, isDownloadAvailable }) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    await handleDownload();
    setLoading(false);
  };

  return (
    value && [
      <ArtifactPreviewWrapper
        key="artifact-preview-wrapper"
        artifact={value}
        handleOpenPreview={handleOpenPreview}
        handleDownload={onClick}
      />,
      isDownloadAvailable && <DownloadButton key="download-btn" onClick={onClick} loading={loading} />,
    ]
  );
};

DocumentLayout.propTypes = {
  value: oneOfType([objectOf(any), string]).isRequired,
  handleDownload: func,
  handleOpenPreview: func,
  isDownloadAvailable: bool,
};

DocumentLayout.defaultProps = {
  handleDownload: () => {},
  handleOpenPreview: () => {},
  isDownloadAvailable: true,
};

export default DocumentLayout;
