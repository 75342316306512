/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import UpdateProjectBreadcrumbs from 'erp/project/createupdate/Update/Breadcrumbs';
import ProjectForm from 'erp/project/createupdate/ProjectForm';
import useProjectService from 'erp/project/shared/projectService';
import { useProjectUrl } from 'erp/project/ProjectRouter';

const UpdateProject = () => {
  const { update, getById, getValidationSchema, getName } = useProjectService();
  const { getSingleEntityUrl } = useProjectUrl();

  const getDataMethod = async (id: string) => {
    const { rbsTreeNode, ...data } = await getById(id);
    const realNameData = await getName(id);

    return {
      ...data,
      name: data.name,
      originalName: realNameData[id].realProjectName,
      rbsTreeNodeName: rbsTreeNode?.name,
      rbsTreeNodeId: rbsTreeNode?.id,
      redmineAlias: rbsTreeNode?.attributes.alias,
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataMethod}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Project has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Project"
        breadcrumbs={<UpdateProjectBreadcrumbs />}
        entityHeader={<ProjectEntityHeader />}
      >
        <ProjectForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateProject;
