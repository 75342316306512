/* istanbul ignore file */
import React from 'react';
import SingleCandidateBreadcrumbs from 'erp/candidate/shared/SingleCandidateBreadcrumbs';
import { TimelineBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidateTimelineBreadcrumbs = () => {
  const id = useCandidateId();
  const { getTimelineUrl } = useCandidateUrl();

  return (
    <SingleCandidateBreadcrumbs>
      <TimelineBreadcrumbItem link={getTimelineUrl(id)} />
    </SingleCandidateBreadcrumbs>
  );
};

export default CandidateTimelineBreadcrumbs;
