/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import FinanceBreadcrumbs from 'erp/employee/finance/shared/FinanceBreadcrumbs';

const UpdateFinanceBreadcrumbs = () => {
  return (
    <FinanceBreadcrumbs>
      <UpdateBreadcrumbItem entity="Compensation package" />
    </FinanceBreadcrumbs>
  );
};

export default UpdateFinanceBreadcrumbs;
