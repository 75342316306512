import React from 'react';
import Grid from 'uibuilder/Grid';
import { TextArea, TextInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

interface NameDescriptionInputProps {
  nameLabel?: string;
  descriptionLabel?: string;
  source: string;
}

const NameDescriptionInput = ({
  nameLabel = 'Name',
  descriptionLabel = 'Description',
  ...props
}: NameDescriptionInputProps) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper<Object>(props);
  const onChangeCallback = getRawOnChangeCallback();
  const source = getSource();
  const rowId = source.substring(source.lastIndexOf('.') + 1);
  const listSource = source.substring(0, source.lastIndexOf('.'));

  const requirement = getValue() || {};

  const nameSource = `${source}.name`;
  const descriptionSource = `${source}.description`;

  const nameOnChangeCallback = (obj: any) => {
    if (onChangeCallback) {
      onChangeCallback({
        [source]: {
          ...requirement,
          name: obj[nameSource],
        },
      });
    }
  };

  const descriptionOnChangeCallback = (obj: any) => {
    if (onChangeCallback) {
      onChangeCallback({
        [source]: {
          ...requirement,
          description: obj[descriptionSource],
        },
      });
    }
  };

  return (
    <Grid container spacing={{ xs: 0, sm: 2 }} className="vacancy-details-field__wrapper">
      <Grid item xs={12} sm={4}>
        <TextInput
          source={nameSource}
          errorSource={`${listSource}[${rowId}].name`}
          label={nameLabel}
          onChangeCallback={nameOnChangeCallback}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextArea
          rows={2}
          source={descriptionSource}
          errorSource={`${listSource}[${rowId}].description`}
          label={descriptionLabel}
          onChangeCallback={descriptionOnChangeCallback}
        />
      </Grid>
    </Grid>
  );
};

export default NameDescriptionInput;
