import React from 'react';
import HandbookSearchResults from 'handbook/shared/input/HandbookSearchResults';
import SearchInput from 'shared/uibuilder/list/search/SearchWithKeyboardNavigation';
import './HandbookSearchInput.scss';
import { useListContext } from 'shared/uibuilder/list/ListContext';

type HandbookSearchInputProps = {
  isSearchMode: boolean;
};

const HandbookSearchInput = (props: HandbookSearchInputProps) => {
  const { isSearchMode } = props;
  const { searchBy = () => {} } = useListContext();

  const onFocus = (searchInputRef: React.RefObject<HTMLInputElement>) => {
    const searchValue = searchInputRef?.current?.value;

    if (!isSearchMode && searchValue) {
      searchBy(searchValue);
    }
  };

  return (
    <SearchInput
      {...props}
      className="handbook-search-wrapper mb-3"
      searchResultsLayout={HandbookSearchResults}
      placeholder="Search"
      onFocusCallback={onFocus}
    />
  );
};

export default HandbookSearchInput;
