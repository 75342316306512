import { EmployeeQualification } from 'erp/employee/qualification/shared/employeeQualificationService';
import { conditionalMap } from 'shared/arrayHelper';
import { EmployeeSpecialization } from './employeeSpecializationService';

const useEmployeeSpecializationMerger = () => {
  const mergeSpecializationData = ({
    employeeSpecializations = [],
    specializationData,
    qualificationId,
    specializationId,
  }: {
    employeeSpecializations: EmployeeSpecialization[];
    specializationData: Partial<EmployeeSpecialization>;
    qualificationId: StringOrNumber;
    specializationId: StringOrNumber;
  }) => {
    const getNewQualifications = (qualifications: EmployeeQualification[]) =>
      conditionalMap(
        qualifications,
        qualification => String(qualification.id) === String(qualificationId),
        qualification => ({ ...qualification, ...specializationData }),
      );

    return conditionalMap(
      employeeSpecializations,
      specialization => String(specialization.id) === String(specializationId),
      specialization => ({
        ...specialization,
        qualifications: getNewQualifications(specialization.qualifications),
      }),
    );
  };

  return {
    mergeSpecializationData,
  };
};

export default useEmployeeSpecializationMerger;
