/* istanbul ignore file */
import React from 'react';
import useCodeNameService from 'erp/codename/shared/codeNameService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { Button } from 'uibuilder/button';

interface GenerateCodeNameButtonProps {
  source: string;
  isVisible: boolean;
  setCodeNameMethod: (codeName: string) => void;
}

const GenerateCodeNameButton = ({ source, isVisible = false, setCodeNameMethod }: GenerateCodeNameButtonProps) => {
  const { isGrantedToGenerateCodeName, generateCodeName } = useCodeNameService();
  const { data = {} } = useFormContext();
  const name = data[source];

  const isGranted = isGrantedToGenerateCodeName();
  const buttonText = !name ? 'Generate code name' : 'Regenerate code name';

  const onClick = async () => {
    const newCodeName = await generateCodeName(name);
    setCodeNameMethod(newCodeName.name);
  };

  return isGranted && isVisible ? (
    <Button onClick={onClick} key="btn-generate-code-name" color="secondary">
      {buttonText}
    </Button>
  ) : null;
};

export default GenerateCodeNameButton;
