/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewSkillBasedQueuePageMenu from 'erp/skillbasedqueue/Show/ViewSkillBasedQueuePageMenu';
import SkillBasedQueueEntityHeader from 'erp/skillbasedqueue/shared/SkillBasedQueueEntityHeader';
import SingleSkillBasedQueueBreadcrumbs from 'erp/skillbasedqueue/shared/SingleSkillBasedQueueBreadcrumbs';
import { TextField } from 'shared/uibuilder/field';
import useSkillBasedQueueService from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import { useSkillBasedQueueId } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import DeleteSkillBasedQueueButton from 'erp/skillbasedqueue/shared/button/DeleteSkillBasedQueueButton';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import { ListItem } from '../../../shared/uibuilder/list/ListContext';
import EditSkillBasedQueueButton from '../shared/button/EditSkillBasedQueueButton';

const ViewSkillBasedQueue = () => {
  const id = useSkillBasedQueueId();
  const { getById } = useSkillBasedQueueService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        headerButtons={[
          <EditSkillBasedQueueButton
            key="edit-skill-based-queue"
            source="id"
            icon={<i className="fa fa-edit" />}
            isVisible={(data: ListItem) => data.canEdit}
            permissionToCheck=""
          />,
          <DeleteSkillBasedQueueButton key="delete-skill-based-queue" />,
        ]}
        menu={<ViewSkillBasedQueuePageMenu />}
        breadcrumbs={<SingleSkillBasedQueueBreadcrumbs />}
        entityHeader={<SkillBasedQueueEntityHeader />}
        hasSeparateSections
      >
        <ShowSection title="Skill Based Queue Information">
          <SectionRow>
            <TextField label="Skill name" source="skillName" />
            <BudgetLinkField label="Default Budget" source="defaultBudgetId" nameSource="defaultBudgetName" />
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewSkillBasedQueue;
