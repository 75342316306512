/* istanbul ignore file */
import React from 'react';
import MultiSelectInputFilter from 'shared/uibuilder/list/filter/MultiSelectInputFilter';
import VacancyInput from 'erp/candidate/shared/input/VacancyInput';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';

const SearchVacancyFilter = ({ label, ...props }: Omit<ConditionProps, 'children'> & { label: string }) => {
  return (
    <MultiSelectInputFilter {...props}>
      <VacancyInput {...props} label={label} />
    </MultiSelectInputFilter>
  );
};

export default SearchVacancyFilter;
