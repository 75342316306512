import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { InputLayoutType } from 'shared/uibuilder/form/input/TextInput';

const DefaultInputLayout: InputLayoutType = ({ value, ...props }) => {
  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <input {...getCommonInputProps(props)} value={value || ''} type="" />
    </BaseInputLayout>
  );
};

DefaultInputLayout.defaultProps = COMMON_INPUT_LAYOUT_DEFAULT_PROPS;

export default DefaultInputLayout;
