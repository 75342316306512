/* istanbul ignore file */
import React, { useEffect } from 'react';
import moment from 'moment';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import List from 'shared/uibuilder/list/List';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
// layout
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import PerformanceReportListBreadcrumbs from 'erp/employee/performanceReport/List/Breadcrumbs';
// services
import usePerformanceReportService, {
  METRICS,
  DIVIDER,
} from 'erp/employee/performanceReport/usePerformanceReportService';
import PerformanceHeatmap from 'erp/employee/performanceReport/shared/PerformanceHeatmap/index';
import { useLocation } from 'react-router-dom';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import MonthFilter from 'shared/uibuilder/list/filter/MonthFilter';
import useListLocalStorageStoring from 'shared/uibuilder/list/builder/useListLocalStorageStoring';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const PerformanceReportsListPage = () => {
  const { search } = usePerformanceReportService();
  const location = useLocation();
  const localStorageKey = location.pathname;
  const { updateParamsInLocalStorage } = useListLocalStorageStoring({ localStorageKey });
  const currentMonth = moment().format(DATE_FORMAT.API_ONLY_MONTH);

  useEffect(() => {
    return () => {
      updateParamsInLocalStorage({
        filter: {},
      });
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter = (
    <Filters>
      <FiltersLayout>
        <MonthFilter label="" source="period" isRequired hasTodayButton todayButtonLabel="Current month" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<PerformanceReportListBreadcrumbs />}
    >
      <List
        localStorageKey={localStorageKey}
        getDataMethod={search}
        defaultFilter={{
          period: {
            eq: currentMonth,
          },
        }}
      >
        <RelationListLayout header="Performance report">
          <PerformanceHeatmap
            labels={[
              METRICS.TIMELY_CHECKIN,
              METRICS.SAME_DAY_TIME_REPORT,
              METRICS.LOCATION_TIMEZONE,
              DIVIDER,
              METRICS.MICRO_AVAILABILITY,
              METRICS.EFFECTIVE_CAPACITY,
              DIVIDER,
              METRICS.TICKETS_PLANNED,
              METRICS.RESOLVED_TICKETS,
              METRICS.ASSIGNED_TICKETS,
              METRICS.OVERDUE_PROCESSES_TICKETS,
              METRICS.BLOCKED_TICKETS,
              METRICS.REASSIGNED_TICKETS,
              METRICS.REJECTED_TICKETS,
            ]}
            filter={filter}
          />
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default PerformanceReportsListPage;
