import React from 'react';
import { useRoomUrl } from 'erp/room/RoomRouter';
import Breadcrumbs from 'uibuilder/Breadcrumbs';
import ListBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/ListBreadcrumbItem';

const RoomBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useRoomUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Rooms" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default RoomBreadcrumbs;
