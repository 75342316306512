/* istanbul ignore file */
import React from 'react';
import { RoutingSwitch, Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import WysiwygInputTestingRouter, {
  WYSIWYG_ROUTES,
} from 'shared/uibuilder/form/input/__integration-tests__/WysiwygInputTestingRouter';
import useRoute from 'shared/routing/useRoute';
import IntegrationCrudRouter from 'integration-tests/IntegrationCrudRouter';
import FormBuilderTestingRouter, {
  FORM_BUILDER_ROUTES,
} from 'shared/uibuilder/form/Builder/__integration-tests__/FormBuilderTestingRouter';
import OneStopTestingRouter, { ONE_STOP_ROUTES } from 'oneStop/__integration-tests__/OneStopTestingRouter';

export const useIntegrationTestRoutes = () => useRoute({ listRoute: '/crud' });

const IntegrationTestsRouter = () => {
  const { listRoute } = useIntegrationTestRoutes();

  return (
    <RoutingSwitch>
      <Route path={WYSIWYG_ROUTES.main} component={WysiwygInputTestingRouter} />
      <Route path={listRoute} component={IntegrationCrudRouter} />
      <Route path={FORM_BUILDER_ROUTES.main} exact component={FormBuilderTestingRouter} />
      <Route path={ONE_STOP_ROUTES.listRoute} component={OneStopTestingRouter} />
      <Route component={Page404} />
    </RoutingSwitch>
  );
};

export default IntegrationTestsRouter;
