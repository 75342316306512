/* istanbul ignore file */
import React from 'react';
import useGeneratedPayrollsService, {
  REJECT_PAYROLL_REPORT,
  PAYROLL_REPORT_STATUSES,
} from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const RejectPayrollReportButton = ({ statusSource }: { statusSource: string }) => {
  const { rejectPayrollReport } = useGeneratedPayrollsService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { updateInList = () => {} } = useListContext();
  const status = getValue();
  const { data } = useShowContext();
  const contextData = data.getData() || {};

  if (status !== PAYROLL_REPORT_STATUSES.APPROVED) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage="Do you really want to reject this report?"
      deleteMethod={() => rejectPayrollReport(contextData.id)}
      afterDelete={{
        successMessage: 'The Report has been successfully rejected',
        errorMessage: "Can't reject this report.",
        execute: () => {
          updateInList({ ...contextData, status: PAYROLL_REPORT_STATUSES.PENDING } as ListItem);
        },
      }}
      permissionToCheck={REJECT_PAYROLL_REPORT}
      buttonText="Reject"
      color="error"
    />
  );
};

export default RejectPayrollReportButton;
