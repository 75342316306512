import React, { useEffect, useState, useMemo, useCallback } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Data from 'shared/uibuilder/Data';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

export interface CollapsibleProps {
  children: React.ReactElement;
  isExpandable?: (data: Data) => boolean;
  maxHeight?: number;
  getFullText?: Nullable<(data: Data) => string>;
}

interface CollapsibleHelperProps extends CollapsibleProps {
  height: number;
}

interface CollapsibleParams {
  isCollapsible: boolean;
  isExpanded: boolean;
  displayedValue: string;
  isLoading: boolean;
  toggleBlock: (e: any) => void;
  retryUploadData: () => void;
  error: Nullable<string>;
  isExpandableField?: boolean;
}

export const DEFAULT_MAX_HEIGHT = 208;

const useCollapsibleHelper = ({
  children,
  isExpandable,
  maxHeight = DEFAULT_MAX_HEIGHT,
  getFullText,
  height,
}: CollapsibleHelperProps): CollapsibleParams => {
  const { getValue } = useFieldHelper(children.props);
  const { data } = useShowContext();
  const inputValue = getValue();
  const [fullInputValue, setFullInputValue] = useState<Nullable<string>>(null);
  const [displayedValue, setDisplayedValue] = useState(inputValue);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [isCollapsible, setIsCollapsible] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isExpandableField = useMemo(() => isExpandable && isExpandable(data), [data, isExpandable]);

  useEffect(() => {
    if (isExpandableField) {
      setIsCollapsible(true);
    } else {
      setIsCollapsible(height >= maxHeight);
    }
  }, [height, maxHeight, isExpandableField]);

  const uploadFullText = useCallback(async () => {
    if (getFullText) {
      try {
        setIsLoading(true);

        const fullText = await getFullText(data);

        setFullInputValue(fullText);
        setDisplayedValue(fullText);
      } catch (e) {
        setError('Something went wrong.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [getFullText, data]);

  const retryUploadData = useCallback(() => {
    uploadFullText();
  }, [uploadFullText]);

  const updateValue = useCallback(async () => {
    if (!getFullText || isExpanded) {
      setDisplayedValue(inputValue);
    } else if (fullInputValue) {
      setDisplayedValue(fullInputValue);
    } else {
      uploadFullText();
    }
  }, [getFullText, fullInputValue, isExpanded, inputValue, uploadFullText]);

  const toggleBlock = useCallback(
    async (e: any) => {
      e.target.blur();
      updateValue();
      setIsExpanded(prevValue => !prevValue);
      setError(null);
    },
    [updateValue],
  );

  return {
    isCollapsible,
    isExpanded,
    displayedValue,
    isLoading,
    toggleBlock,
    error,
    isExpandableField,
    retryUploadData,
  };
};

export default useCollapsibleHelper;
