/* istanbul ignore file */
import React, { useState } from 'react';
import useSyberryTodayService from 'syberryToday/useSyberryTodayService';
import DailyCheckin from 'syberryToday/List/DailyCheckin';
import DndProvider from 'shared/uibuilder/DndProvider';
import SyberryTodayProvider from 'syberryToday/SyberryTodayProvider';
import useAsyncValue from 'shared/asyncHelper';
import Loading from 'shared/uibuilder/Loading';
import './SyberryToday.scss';
import AfterDailyCheckin from 'syberryToday/List/AfterDailyCheckin';
import useSyberryTodayProjectService from 'syberryToday/projects/useSyberryTodayProjectService';

const SyberryToday = () => {
  const { projects } = useSyberryTodayProjectService();
  const { isCheckinPassed } = useSyberryTodayService();
  const isCheckinWasPassed = useAsyncValue(isCheckinPassed, []);
  const [isCheckinConfirmed, setIsCheckinConfirmed] = useState<boolean>(false);

  if (isCheckinWasPassed === null || (!isCheckinWasPassed && projects === null)) {
    return <Loading />;
  }

  return (
    <DndProvider>
      {isCheckinWasPassed || isCheckinConfirmed ? (
        <AfterDailyCheckin />
      ) : (
        <SyberryTodayProvider
          projects={projects}
          isCheckinPassed={isCheckinConfirmed}
          setIsCheckinPassed={setIsCheckinConfirmed}
        >
          <DailyCheckin />
        </SyberryTodayProvider>
      )}
    </DndProvider>
  );
};

export default SyberryToday;
