/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { OneStopRequestStatus } from 'oneStop/oneStopService';

const OneStopRequestStatusFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <EnumDropdown options={OneStopRequestStatus} {...props} />
    </DropDownFilter>
  );
};

export default OneStopRequestStatusFilter;
