/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import ExpenseStatusDropdown from 'financialAnalytic/expenses/shared/input/ExpenseStatusDropdown';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';

const ExpenseStatusFilter = ({ source, ...props }: BaseFilterProps & { label: string }) => {
  return (
    <DropDownFilter {...props} source={source}>
      <ExpenseStatusDropdown source={source} />
    </DropDownFilter>
  );
};

export default ExpenseStatusFilter;
