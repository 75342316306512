/* istanbul ignore file */
import React from 'react';
import EditCompetencyStatusButton from 'erp/employee/qualification/List/button/EditCompetencyStatusButton';
import CompetencyStatusField from 'erp/employee/qualification/shared/field/CompetencyStatusField';
import ColoredCompetencyStatusField from 'erp/employee/qualification/List/field/ColoredCompetencyStatusField';
import { UPDATE_EMPLOYEE_COMPETENCY } from 'erp/employee/employeeService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { EmployeeCompetency } from 'erp/employee/qualification/shared/employeeQualificationService';
import { FieldProps } from 'shared/uibuilder/field';

interface EditableCompetencyStatusFieldprops extends FieldProps {
  qualificationId: StringOrNumber;
}

const EditableCompetencyStatusField = ({ qualificationId, ...props }: EditableCompetencyStatusFieldprops) => {
  const competency = useShowContext()?.data?.getData();

  return (
    <div className="d-flex align-items-center">
      <ColoredCompetencyStatusField className="current-status" {...props}>
        <CompetencyStatusField />
      </ColoredCompetencyStatusField>
      <EditCompetencyStatusButton
        competency={competency as EmployeeCompetency}
        permissionToCheck={UPDATE_EMPLOYEE_COMPETENCY}
        qualificationId={qualificationId}
      />
    </div>
  );
};

export default EditableCompetencyStatusField;
