/* istanbul ignore file */
import { Switch } from 'react-router-dom';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import React from 'react';
import { READ_LEAVES_BALANCES } from 'erp/employee/leavebalance/shared/balancesService';
import EmployeePaidLeavesBalancesList from 'erp/employee/leavebalance/BalancesList';

const BALANCES_PATH = 'leaves';

export const useLeavesBalancesRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}/leaves`,
  });
};

export const useLeavesBalancesUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();
  return useEntityUrl({
    baseLink: `${employeeLink}/${BALANCES_PATH}`,
  });
};

const LeaveBalanceRouter = () => {
  const { listRoute } = useLeavesBalancesRoute();

  return (
    <Switch>
      <ProtectedRoute path={`${listRoute}`} permissionToCheck={READ_LEAVES_BALANCES} exact>
        <EmployeePaidLeavesBalancesList />
      </ProtectedRoute>

      <Route component={Page404} />
    </Switch>
  );
};

export default LeaveBalanceRouter;
