/* istanbul ignore file */
// libraries
import React from 'react';
// services
import { useCompanyRolesService, useCompanyRolesUrl } from '../shared/companyRolesService';
// components
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import AddCompanyRoleBreadCrumbs from './AddCompanyRoleBreadCrumbs';
import CompanyRolesForm from './CompanyRolesForm';

const AddEmployeeCompanyRole = () => {
  const { addRoleToEmployee, getValidationSchema } = useCompanyRolesService();
  const { getListUrl } = useCompanyRolesUrl();

  return (
    <CreateForm
      getValidationSchemaFunc={getValidationSchema}
      submitFormFunc={addRoleToEmployee}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The role has been successfully added.',
      }}
    >
      <CreatePageLayout
        breadcrumbs={<AddCompanyRoleBreadCrumbs />}
        title="Add: Company role"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <CompanyRolesForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default AddEmployeeCompanyRole;
