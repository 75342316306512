/* istanbul ignore file */
import { Marker } from 'camunda/monitoring/facade/useBpmnJs';
import './markers.scss';

const OFFSET = 10;

class HighlightActivityMarker implements Marker {
  activityId: string;

  html: string;

  position: {
    top: number;
    left: number;
  };

  constructor(activityId: string, activityHeight: number, activityWidth: number) {
    this.activityId = activityId;

    const height = activityHeight + OFFSET * 2;
    const width = activityWidth + OFFSET * 2;
    this.html = `<div class="highlightActivity" style="width:${width}px;height: ${height}px;" />`;
    this.position = {
      left: 0 - OFFSET,
      top: 0 - OFFSET,
    };
  }
}

export default HighlightActivityMarker;
