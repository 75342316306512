/* istanbul ignore file */
import React from 'react';
import AssignmentBreadcrumbs from 'erp/assignment/shared/AssignmentBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateAssignmentBreadcrumbs = () => {
  return (
    <AssignmentBreadcrumbs>
      <CreateBreadcrumbItem entity="Assignment" />
    </AssignmentBreadcrumbs>
  );
};

export default CreateAssignmentBreadcrumbs;
