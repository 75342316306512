import React from 'react';
import { arrayOf, string, bool, number } from 'prop-types';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useArtifactService from 'artifact/artifactService';
import getLabel from 'shared/uibuilder/helper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';
import useArtifactDownloadHelper from 'artifact/shared/artifactDownloadHelper';
import useArtifactPreviewHelper from 'artifact/shared/artifactPreviewHelper';

const InvoiceAttachmentsListField = ({
  source,
  value: inputValue,
  label: propsLabel,
  isDownloadAvailable,
  isOneArtifact = false,
}) => {
  const { AttachmentsListLayout, BaseFieldLayout } = useUiTheme();

  const { data } = useShowContext();
  const label = getLabel(propsLabel, source);
  const { getInvoiceArtifactsDataByIds, getInvoiceThumbnail } = useArtifactService();
  const attachmentsIds = inputValue || (data && data.getValueBySource(source)) || [];

  const attachments = useAsyncValue(async () =>
    getInvoiceArtifactsDataByIds(data.id, isOneArtifact ? [attachmentsIds] : attachmentsIds),
  );

  const { downloadForInvoice } = useArtifactDownloadHelper();
  const { openPreviewForInvoice } = useArtifactPreviewHelper();

  return attachments ? (
    <AttachmentsListLayout
      attachments={attachments}
      label={label}
      downloadFile={() => downloadForInvoice(data.id)}
      getThumbnail={(id, cropSize) => getInvoiceThumbnail(data.id, id, cropSize)}
      openPreview={() => openPreviewForInvoice(data.id)}
      isDownloadAvailable={isDownloadAvailable}
      className="mb-6"
    />
  ) : (
    <BaseFieldLayout label={label} value={<InputLoader />} />
  );
};

InvoiceAttachmentsListField.propTypes = {
  source: string,
  value: arrayOf(string),
  label: string,
  isDownloadAvailable: bool,
  // eslint-disable-next-line react/no-unused-prop-types
  width: number,
};

InvoiceAttachmentsListField.defaultProps = {
  source: null,
  value: null,
  label: null,
  isDownloadAvailable: true,
  width: undefined,
};

export default InvoiceAttachmentsListField;
