/* istanbul ignore file */
// libs
import React, { useEffect, useState } from 'react';
// services
import useLeadService from 'crm/lead/shared/leadService';
import { useLocation } from 'react-router-dom';
import { useLeadUrl } from 'crm/lead/LeadRouter';
// components
import LeadForm from 'crm/lead/createupdate/LeadForm';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import Breadcrumbs from 'crm/lead/createupdate/Update/Breadcrumbs';

/*
  To open edit form to fill the fields required for QUALIFIED - CONVERTED status
  but not required for OPEN - CONTACTED, you need to pass state {status: "CONVERT"}
  to the router

  for example:

   <Link to={{
         pathname:  `/crm/leads/${lead.id}/edit`,
         state: {
            status: "CONVERT",
         }
   }}>EDIT BEFORE CONVERT</Link>
 */
const UpdateLead = () => {
  const location = useLocation();
  const { update, getById, getValidationSchema } = useLeadService();
  const { getSingleEntityUrl } = useLeadUrl();

  const [fieldsExceptions, setFieldsExceptions] = useState({});

  const isEditDuringConvert = location.state && location.state.status === 'CONVERT';

  useEffect(() => {
    if (isEditDuringConvert) {
      setFieldsExceptions({
        status: 'QUALIFIED',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      fieldsExceptions={fieldsExceptions}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Lead has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Lead"
        breadcrumbs={<Breadcrumbs />}
        shouldScrollToErrorAfterLoading={isEditDuringConvert}
      >
        <LeadForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateLead;
