import React, { useEffect, useState } from 'react';
import Fade from 'uibuilder/Fade';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import Loading from 'shared/uibuilder/Loading';
import SmallLoader from 'shared/uibuilder/SmallLoader';

interface ListLoadingDecoratorProps {
  children: React.ReactNode;
  isSmallLoader?: boolean;
  loading?: boolean;
  items?: any[];
}

const ListLoadingDecorator = ({
  children,
  isSmallLoader = false,
  loading = false,
  items = [],
}: ListLoadingDecoratorProps) => {
  const [shouldRender, setShouldRender] = useState(true);

  useDidUpdateEffect(() => {
    setShouldRender(true);
  }, [items]);

  useEffect(() => {
    if (loading) {
      setShouldRender(false);
    }
  }, [loading]);

  if (loading) {
    return isSmallLoader ? <SmallLoader /> : <Loading />;
  }

  return (
    <Fade in={shouldRender} timeout={0}>
      <div>{children}</div>
    </Fade>
  );
};

export default ListLoadingDecorator;
