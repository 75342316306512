/* istanbul ignore file */
import React from 'react';
import { Dropdown, TextArea } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import {
  EmployeeCommonSkillExperienceDurationLabels,
  EmployeeCommonSkillExperienceTypeLabels,
  EmployeeCommonSkillLevelLabels,
} from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';
import CommonSkillInput from 'erp/employee/commonSkills/commonSkill/field/CommonSkillInput';

type EmployeeCommonSkillFormProps = {
  handleCancelClick: () => void;
};

const EmployeeCommonSkillForm = ({ handleCancelClick }: EmployeeCommonSkillFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  const getDropDownField = ({
    source,
    label: fieldLabel,
    enumOptions,
  }: {
    source: string;
    label: string;
    enumOptions: Dictionary<string>;
  }) => (
    <Dropdown
      source={source}
      isRequired
      placeholder={`Choose ${fieldLabel}`}
      options={Object.entries(enumOptions).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label={fieldLabel}
    />
  );

  return (
    <>
      <CommonSkillInput
        isRequired
        placeholder="Choose Skill"
        source="commonSkillId"
        errorSource="commonSkill"
        label="Skill"
      />
      {getDropDownField({ source: 'level', label: 'Level', enumOptions: EmployeeCommonSkillLevelLabels })}
      {getDropDownField({
        source: 'experience',
        label: 'Experience',
        enumOptions: EmployeeCommonSkillExperienceTypeLabels,
      })}
      {getDropDownField({
        source: 'experienceDuration',
        label: 'Experience Duration',
        enumOptions: EmployeeCommonSkillExperienceDurationLabels,
      })}
      <TextArea source="comments" label="Comments" />
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default EmployeeCommonSkillForm;
