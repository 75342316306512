/* istanbul ignore file */
import React, { useMemo } from 'react';
import Grid from 'uibuilder/Grid';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { ChangeRequestStatus, ChangeRequestStatusLabel } from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import TextButton from 'uibuilder/button/TextButton';
import useInputRowWithSpecificId from './useInputRowWithSpecificId';

export type ChangeRequestType = {
  id: number;
  description: string;
  fromPreviousReport: boolean;
  createdDate: string;
  status: string;
  comments: string;
};

interface ChangeRequestInputRowProps {
  data: ChangeRequestType;
  id: number;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

export const getChangeRequestSource = (id: number) => {
  return `weeklyReportData.changeRequests[${id}]`;
};

const ChangeRequestInputRow = ({
  data,
  id,
  formData,
  updateFormData,
  getErrorMessages,
}: ChangeRequestInputRowProps) => {
  const source = getChangeRequestSource(id);
  const { getOnChangeCallback, removeRow } = useInputRowWithSpecificId({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'changeRequests',
  });

  const isDisabledSpecific = useMemo(() => {
    return data.fromPreviousReport && data.status !== ChangeRequestStatus.NEW;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.fromPreviousReport, data.id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={1}>
        <TextInput
          source={`${source}.id`}
          value={data.id.toString()}
          label={id === 0 ? 'ID' : ''}
          disabled={id !== 0 || !formData.firstReport}
          onChangeCallback={getOnChangeCallback('id')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextButton
          source={`${source}.description`}
          label={id === 0 ? 'Description' : ''}
          value={data.description}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('description')}
          isRequired
          title="Description"
          errors={getErrorMessages(`${source}.description`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.createdDate`}
          label={id === 0 ? 'Created date' : ''}
          value={data.createdDate}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('createdDate')}
          isRequired
          minDate={formData.firstReport ? null : formData.from}
          maxDate={formData.firstReport ? null : formData.to}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextButton
          source={`${source}.comments`}
          label={id === 0 ? 'Comments' : ''}
          value={data.comments}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('comments')}
          title="Comments"
          errors={getErrorMessages(`${source}.comments`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <EnumDropdown
          source={`${source}.status`}
          label={id === 0 ? 'Status' : ''}
          value={data.status}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('status')}
          options={ChangeRequestStatusLabel}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <RemoveListItemButton isVisible={!data.fromPreviousReport} onClickCallback={removeRow} isFirstRow={id === 0} />
      </Grid>
    </Grid>
  );
};

export default ChangeRequestInputRow;
