/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EducationBreadcrumbs from 'erp/employee/Education/shared/EducationBreadcrumbs';

const CreateEducationBreadcrumbs = () => {
  return (
    <EducationBreadcrumbs>
      <CreateBreadcrumbItem entity="Education" />
    </EducationBreadcrumbs>
  );
};

export default CreateEducationBreadcrumbs;
