/* istanbul ignore file */
import React from 'react';
import { DateRangeFilterLayoutType } from 'shared/uibuilder/list/filter/DateRangeFilter';

const DateRangeFilterLayout: DateRangeFilterLayoutType = ({ children }: any) => {
  return (
    <div className="daterange-inputs">
      <div className="d-flex flex-nowrap">{children}</div>
    </div>
  );
};

export default DateRangeFilterLayout;
