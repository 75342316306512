/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';

const ListPageHeaderWrapper = ({
  header,
  actions,
}: {
  header: Nullable<React.ReactElement>;
  actions?: React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        '& .timer__wrapper, & > button, & > a': { mt: '12px', ml: 1 },
        h1: { mr: 'auto' },
      }}
    >
      {header} {actions}
    </Box>
  );
};

export default ListPageHeaderWrapper;
