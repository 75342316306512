/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useServiceUrl } from 'erp/service/ServiceRouter';

export interface EditServiceButtonProps extends EditButtonProps {}

const EditServiceButton = (props: EditServiceButtonProps) => {
  const { getUpdateEntityUrl } = useServiceUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} {...props} />;
};

export default EditServiceButton;
