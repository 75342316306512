/* istanbul ignore file */
const leaveFilterValidation = {
  startDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: [
          {
            value: '$startDate.le',
            when: [
              {
                '$startDate.le': {
                  notEmpty: true,
                },
              },
            ],
            message: 'Please choose date before or at "to" date',
          },
          {
            value: '$actualEndDate.ge',
            when: [
              {
                '$actualEndDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$actualEndDate.le',
            when: [
              {
                '$actualEndDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
      le: {
        type: 'date',
        minDate: { value: '$startDate.ge', message: 'Please choose date after or at "from" date' },
        maxDate: [
          {
            value: '$actualEndDate.le',
            when: [
              {
                '$actualEndDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$actualEndDate.ge',
            when: [
              {
                '$actualEndDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
    },
  },
  actualEndDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: { value: '$actualEndDate.le', message: 'Please choose date before or at "to" date' },
        minDate: [
          {
            value: '$startDate.ge',
            when: [
              {
                '$startDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$startDate.le',
            when: [
              {
                '$startDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
      le: {
        type: 'date',
        minDate: [
          {
            value: '$actualEndDate.ge',
            when: [
              {
                '$actualEndDate.ge': {
                  notEmpty: true,
                },
              },
            ],
            message: 'Please choose date after or at "from" date',
          },
          {
            value: '$startDate.le',
            when: [
              {
                '$startDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$startDate.ge',
            when: [
              {
                '$startDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
    },
  },
};

export default leaveFilterValidation;
