/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';

const ShowOpportunityContext = ({ children }: { children: React.ReactNode }) => {
  const { getById } = useOpportunityService() as any;
  const oppId = useOpportunityId();

  return <Show getDataMethod={() => getById(oppId)}>{children}</Show>;
};

export default ShowOpportunityContext;
