/* istanbul ignore file */

import React from 'react';
import EntityHeader, { HEADER_SIZES } from 'shared/layout/EntityHeader';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { FullDateField } from 'shared/uibuilder/field';
import ProcessStateBadge from 'camunda/monitoring/instance/shared/ProcessStateBadge';
import ProcessStartInfoField from 'camunda/monitoring/instance/shared/ProcessStartInfoField';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import './ProcessInstanceEntityHeader.scss';
import ProcessInstanceEntityTitle from 'camunda/monitoring/instance/shared/header/ProcessInstanceEntityTitle';
import ProcessInstanceIdField from 'camunda/monitoring/instance/shared/header/ProcessInstanceIdField';
import ProcessInstanceLinkField from '../ProcessInstanceLinkField';

const ProcessInstanceEntityHeader = () => {
  const { data } = useShowContext();
  const businessKey = data.getValueBySource('businessKey');
  const id = useProcessInstanceId();
  const endDateTime = data.getValueBySource('endDateTime');
  const state = data.getValueBySource('state');
  const parentProcessId = data.getValueBySource('parentProcessId');

  return (
    // @ts-ignore
    <EntityHeader
      entityTitle={<ProcessInstanceEntityTitle businessKey={businessKey} />}
      size={HEADER_SIZES.SMALL}
      badges={[<ProcessStateBadge state={state} />]}
    >
      <>
        <ProcessInstanceIdField value={id} />
        <ProcessStartInfoField processStarterSource="processStarter" startDateTimeSource="startDateTime" />
        {endDateTime && <FullDateField label={<span className="font-italic">Ended</span>} value={endDateTime} />}
        {parentProcessId && (
          <ProcessInstanceLinkField label={<span>Parent process</span>} source="parentProcessId" isInternal={false} />
        )}
      </>
    </EntityHeader>
  );
};

export default ProcessInstanceEntityHeader;
