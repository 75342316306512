/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import LeadStatusDropdown from 'crm/lead/shared/input/LeadStatusDropdown';

const LeadStatusFilter = props => {
  return (
    <DropDownFilter {...props}>
      <LeadStatusDropdown />
    </DropDownFilter>
  );
};

export default LeadStatusFilter;
