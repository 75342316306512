/* istanbul ignore file */
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import AssessmentContext from 'erp/employee/assessment/AssessmentContext';
import Page404 from 'shared/uibuilder/Page404';
import { CreateAssessmentPage, UpdateAssessmentPage } from 'erp/employee/assessment/createupdate';
import AssessmentsListPage from 'erp/employee/assessment/List';
import useRoute from 'shared/routing/useRoute';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import useEntityUrl, { UrlParams } from 'shared/routing/useEntityUrl';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { CREATE_ASSESSMENT, UPDATE_ASSESSMENT } from 'erp/employee/assessment/assessmentService';
import { READ_EMPLOYEE_COMMUNICATIONS } from 'erp/employee/employeeService';

export const ASSESSMENTS_PATH = 'assessments';
export const ASSESSMENTS_ID_SOURCE = 'assessmentId';

export const useAssessmentRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();
  const assessmentListRoute = `${employeeRoute}/${ASSESSMENTS_PATH}`;

  const baseRoutes = useRoute({
    singleEntityRoute: `${assessmentListRoute}/${ASSESSMENTS_PATH}/:${ASSESSMENTS_ID_SOURCE}`,
    listRoute: `${assessmentListRoute}/${ASSESSMENTS_PATH}`,
  });

  return {
    ...baseRoutes,
    listRoute: assessmentListRoute,
  };
};

export const useAssessmentUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const listUrl = `${getSingleEntityUrl()}/${ASSESSMENTS_PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${listUrl}/${ASSESSMENTS_PATH}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

export const useAssessmentId = () => {
  const urlParams: UrlParams = useParams();

  return urlParams[ASSESSMENTS_ID_SOURCE];
};

const AssessmentRouter = () => {
  const { createRoute, updateRoute, listRoute } = useAssessmentRoute();

  return (
    <Switch>
      <ProtectedRoute path={listRoute} permissionToCheck={READ_EMPLOYEE_COMMUNICATIONS} exact>
        <AssessmentsListPage />
      </ProtectedRoute>

      <ProtectedRoute path={createRoute} exact permissionToCheck={CREATE_ASSESSMENT}>
        <CreateAssessmentPage />
      </ProtectedRoute>

      <Route path={updateRoute} exact>
        <AssessmentContext permissionToCheck={UPDATE_ASSESSMENT}>
          <UpdateAssessmentPage />
        </AssessmentContext>
      </Route>

      <Route component={Page404} />
    </Switch>
  );
};

export default AssessmentRouter;
