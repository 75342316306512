/* istanbul ignore file */
import React from 'react';
import useAsyncValue from 'shared/asyncHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

const SignatureOptionTooltip = () => {
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());

  return (
    <>
      Legal service engineer will contact you when documents are ready for signing. You can also view the status of your
      request on the{' '}
      <a href={`/employees/${userAlias}/leaves`} target="_blank" rel="noopener noreferrer">
        Leave page
      </a>
    </>
  );
};

export default SignatureOptionTooltip;
