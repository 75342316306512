/* istanbul ignore file */
import React from 'react';
import { LEAVE_TYPES, LEAVE_TYPES_ALIASES } from 'erp/leave/shared/leaveService';
import { bool } from 'prop-types';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import useEmployeeService from 'erp/employee/employeeService';
import useAsyncValue from 'shared/asyncHelper';
import { isPolishOffice } from 'erp/employee/office/officeService';
import { isB2BContract } from '../../../employee/contracts/shared/employeeContractsService';

const LeaveTypeDropdown = ({ isOneStop, ...props }) => {
  const options = { ...LEAVE_TYPES };
  const { getCurrentEmployee } = useEmployeeService();
  const employee = useAsyncValue(async () => getCurrentEmployee(), []);

  if (isOneStop) {
    delete options[LEAVE_TYPES_ALIASES.COMPENSATION];

    if (employee && isPolishOffice(String(employee.officeId))) {
      delete options[LEAVE_TYPES_ALIASES.MILITARY_LEAVE];
    }

    if (employee && isPolishOffice(String(employee.officeId)) && !isB2BContract(String(employee.contractType))) {
      delete options[LEAVE_TYPES_ALIASES.SICK_DAY];
    } else {
      delete options[LEAVE_TYPES_ALIASES.DAY_ON_DEMAND];
    }
  }

  return <EnumDropdown options={options} label="Leave Type" placeholder="Choose Leave Type" {...props} />;
};

LeaveTypeDropdown.propTypes = {
  isOneStop: bool,
};

LeaveTypeDropdown.defaultProps = {
  isOneStop: false,
};

export default LeaveTypeDropdown;
