/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/ListWithInfiniteScroll';
import useProjectsDashboardService from 'crm/projectsDashboard/useProjectsDashboardService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ProjectsDashboardsDataGrid from 'crm/projectsDashboard/List/ProjectsDashboardDataGrid';

const ProjectsDashboardsListPage = () => {
  const { search } = useProjectsDashboardService();

  return (
    <List getDataMethod={search}>
      <ListPageLayout header={<ListHeader label="Projects dashboard" />}>
        <ProjectsDashboardsDataGrid />
      </ListPageLayout>
    </List>
  );
};

export default ProjectsDashboardsListPage;
