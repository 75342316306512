/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import List from 'shared/uibuilder/list/List';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
// layout
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EducationListBreadcrumbs from 'erp/employee/Education/List/Breadcrumbs';
// fields
import { TextField } from 'shared/uibuilder/field';
import EducationTypeField from 'erp/employee/Education/shared/field/EducationTypeField';
import DocumentTypeField from 'erp/employee/Education/shared/field/DocumentTypeField';
// buttons
import CreateButton from 'uibuilder/button/CreateButton';
import EditEducationButton from 'erp/employee/Education/shared/button/EditEducationButton';
import ViewEducationButton from 'erp/employee/Education/shared/button/ViewEducationButton';
// services
import useEducationService from 'erp/employee/Education/shared/educationService';
import { CREATE_EDUCATION, useEducationUrl } from 'erp/employee/Education/EducationRouter';
import { ASC } from 'shared/uibuilder/list/baseListHelper';

import './index.scss';

const EducationsList = () => {
  const { search } = useEducationService();
  const { getCreateUrl } = useEducationUrl();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<EducationListBreadcrumbs />}
    >
      <List getDataMethod={search} defaultSortField="graduationYear" defaultSortOrder={ASC}>
        <RelationListLayout
          header="Educational Information"
          actions={[
            <CreateButton
              url={getCreateUrl()}
              variant="contained"
              label="Add Education"
              key="add-education"
              permissionToCheck={CREATE_EDUCATION}
            />,
          ]}
        >
          <DataGridLayout
            showTotal={false}
            isSmallTable
            buttons={[
              <EditEducationButton source="id" key="edit-education" outline permissionToCheck={CREATE_EDUCATION} />,
              <ViewEducationButton source="id" key="view-education" />,
            ]}
            tableWrapperClassName="education-list"
          >
            <TextField source="specialization" isSortable width={150} />
            <EducationTypeField source="type" isSortable />
            <TextField source="providerName" label="Education Provider" isSortable />
            <TextField source="graduationYear" isSortable />
            <DocumentTypeField source="document.type" label="Type of Document" isSortable />
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default EducationsList;
