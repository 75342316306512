/* istanbul ignore file */
import React from 'react';
import AmountField from 'shared/uibuilder/field/AmountField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { PROBABILITY_MAP } from 'crm/opportunity/shared/opportunityService';
import { string } from 'prop-types';

const OpportunityExpectedRevenueField = ({ amountSource, amountValue, stageSource, stageValue, ...props }) => {
  const { getValue: getAmountValue } = useFieldHelper({ source: amountSource, value: amountValue });
  const { getValue: getStageValue } = useFieldHelper({ source: stageSource, value: stageValue });

  const expectedRevenue = +((PROBABILITY_MAP[getStageValue()] * getAmountValue()) / 100).toFixed(2);

  return <AmountField {...props} value={expectedRevenue} />;
};

OpportunityExpectedRevenueField.propTypes = {
  amountSource: string,
  amountValue: string,
  stageSource: string,
  stageValue: string,
};

OpportunityExpectedRevenueField.defaultProps = {
  amountSource: undefined,
  amountValue: undefined,
  stageSource: undefined,
  stageValue: undefined,
};

export default OpportunityExpectedRevenueField;
