import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useRoomService, { DELETE_ROOM } from 'erp/room/useRoomService';
import { useRoomId, useRoomUrl } from 'erp/room/RoomRouter';

const DeleteRoomButton = () => {
  const id = useRoomId();
  const { getListUrl } = useRoomUrl();
  const { deleteById } = useRoomService();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(id)}
      displayMessage="Do you really want to delete this room?"
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Room has been successfully deleted.',
        errorMessage: `Can't delete room`,
      }}
      permissionToCheck={DELETE_ROOM}
    />
  );
};

export default DeleteRoomButton;
