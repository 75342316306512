/* istanbul ignore file */
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import IntegrationTestsApp from 'integration-tests/App';
import initSentry from 'sentry';

initSentry();

const root = createRoot(document.getElementById('root'));

if (process.env.REACT_APP_E2E_TESTS === 'true') {
  root.render(React.createElement(IntegrationTestsApp));
} else {
  root.render(React.createElement(App));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
