/* istanbul ignore file */
/* This is temporary component made to highlight phone in list, must be replaced with regular phone field when global search implemented */
import React from 'react';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import PhoneField from 'shared/uibuilder/field/PhoneField';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { string } from 'prop-types';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const PhoneFieldLayout = ({ phone, phoneExtension, label }) => {
  const { BaseFieldLayout } = useUiTheme();
  const { search } = useListContext();

  const displayValue = phone ? (
    <>
      <a href={`tel:${phone}`}>
        <SearchResultField value={search ? phone.replace(/[ +)(-]/g, '') : phone} />
      </a>
      {phoneExtension && <span className="extension">ext {phoneExtension}</span>}
    </>
  ) : null;

  return <BaseFieldLayout value={displayValue} label={label} />;
};

PhoneFieldLayout.propTypes = {
  label: string,
  phone: string,
  phoneExtension: string,
};

PhoneFieldLayout.defaultProps = {
  label: undefined,
  phone: undefined,
  phoneExtension: undefined,
};

const PhoneSearchField = props => {
  return (
    <UiThemeOverrider
      overriddenLayouts={{
        PhoneFieldLayout,
      }}
    >
      <PhoneField {...props} />
    </UiThemeOverrider>
  );
};

export default PhoneSearchField;
