/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const ActivitiesMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Activities & Notes" icon="history" {...props} />
);

ActivitiesMenuItem.displayName = 'Activities & Notes';

ActivitiesMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default ActivitiesMenuItem;
