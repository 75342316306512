import React from 'react';
import { EmployeeLink } from 'erp/employee';
import useAuthorization from 'shared/authorization/authorizationService';
import { GET_EMPLOYEES_LIST } from 'erp/employee/employeeService';
import ShortNameField from '../../../../shared/uibuilder/field/ShortNameField';

const TeamEmployeeLinkField = ({ value = {} }: { value?: any }) => {
  const { isGranted } = useAuthorization();
  const canViewEmployeeDetails = isGranted(GET_EMPLOYEES_LIST);

  return (
    <EmployeeLink id={canViewEmployeeDetails ? value.alias : null} name={<ShortNameField value={value.nameEn} />} />
  );
};

export default TeamEmployeeLinkField;
