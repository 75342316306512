/* istanbul ignore file */
export default {
  homeAddress: {
    type: 'object',
    properties: {
      country: {
        type: 'enum',
        required: true,
      },
      city: {
        type: 'string',
        required: true,
        maxSize: 64,
      },
      zipCode: {
        type: 'string',
        required: true,
        maxSize: 64,
      },
      addressLine1: {
        type: 'string',
        required: true,
        maxSize: 64,
      },
      addressLine2: {
        type: 'string',
        maxSize: 64,
      },
    },
  },
  maritalStatus: {
    type: 'enum',
    required: true,
  },
  familyMembers: {
    type: 'array',
    maxSize: 10,
    required: false,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        dateOfBirth: {
          type: 'date',
          required: true,
          maxDate: {
            value: '$NOW',
            message: 'Must be in the past',
          },
        },
        relation: {
          type: 'string',
          required: true,
          maxSize: 24,
        },
        name: {
          type: 'object',
          _divisible: false,
          properties: {
            firstName: {
              type: 'string',
              required: true,
              maxSize: 64,
            },
            lastName: {
              type: 'string',
              required: true,
              maxSize: 64,
            },
            middleName: {
              type: 'string',
              maxSize: 64,
            },
          },
        },
      },
    },
  },
  familyChildren: {
    type: 'array',
    maxSize: 10,
    required: false,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        dateOfBirth: {
          type: 'date',
          required: true,
          maxDate: {
            value: '$NOW',
            message: 'Must be in the past',
          },
        },
        relation: {
          type: 'string',
          required: true,
          maxSize: 24,
        },
        name: {
          type: 'object',
          _divisible: false,
          properties: {
            firstName: {
              type: 'string',
              required: true,
              maxSize: 64,
            },
            lastName: {
              type: 'string',
              required: true,
              maxSize: 64,
            },
            middleName: {
              type: 'string',
              maxSize: 64,
            },
          },
        },
      },
    },
  },
  militaryRecord: {
    type: 'object',
    _divisible: false,
    properties: {
      registrationGroup: {
        type: 'string',
        maxSize: 24,
      },
      registrationCategory: {
        type: 'enum',
      },
      militaryContingent: {
        type: 'enum',
      },
      title: {
        type: 'string',
        maxSize: 24,
      },
      specialization: {
        type: 'string',
        maxSize: 24,
      },
      militarySuitability: {
        type: 'string',
        maxSize: 24,
      },
      militaryCommissariat: {
        type: 'string',
        maxSize: 64,
      },
      registrationNumber: {
        type: 'string',
        maxSize: 64,
      },
    },
  },
  educations: {
    type: 'array',
    maxSize: 5,
    required: {
      value: true,
      message: 'You must add at least one education item',
    },
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        type: {
          type: 'enum',
          required: true,
        },
        providerName: {
          type: 'string',
          required: true,
          maxSize: 128,
        },
        graduationYear: {
          type: 'string',
          required: true,
          regex: {
            value: /^\d{4}$/i,
            message: 'Please enter a year in the format YYYY',
          },
        },
        specialization: {
          type: 'string',
          required: true,
          maxSize: 128,
        },
        assignedQualification: {
          type: 'string',
          maxSize: 128,
          required: true,
        },
        document: {
          type: 'object',
          properties: {
            type: {
              type: 'enum',
              required: {
                value: true,
                when: {
                  '$.haveDocument': {
                    eq: true,
                  },
                },
              },
            },
            number: {
              type: 'string',
              maxSize: {
                value: 24,
                when: {
                  '$.haveDocument': {
                    eq: true,
                  },
                },
              },
              required: {
                value: true,
                when: {
                  '$.haveDocument': {
                    eq: true,
                  },
                },
              },
            },
            dateOfIssue: {
              required: {
                value: true,
                when: {
                  '$.haveDocument': {
                    eq: true,
                  },
                },
              },
              type: 'date',
              maxDate: {
                value: '$NOW',
                message: 'Must be in the past',
              },
            },
            scansIds: {
              type: 'array',
              maxSize: 5,
              when: {
                '$.haveDocument': {
                  eq: true,
                },
              },
            },
          },
        },
      },
    },
  },
};
