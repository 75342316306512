import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField } from 'shared/uibuilder/field';
import React from 'react';
import { PROCESS_STATE_CAPTIONS, ProcessState } from 'camunda/monitoring/instance/processInstanceService';

// @ts-ignore
const ProcessStateField = ({ source, ...props }) => {
  const { getValue } = useFieldHelper<ProcessState>({ source });
  const state: ProcessState = getValue();
  return <TextField {...props} value={PROCESS_STATE_CAPTIONS[state]} />;
};

export default ProcessStateField;
