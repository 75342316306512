import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const TotalRecords = ({ totalElements = null }: { totalElements?: Nullable<number> }) => {
  const { loading, data = {} } = useListContext();

  const displayedTotalElements = totalElements === null ? !loading && data.totalElements : totalElements;

  return loading !== true && displayedTotalElements ? <div>Total records: {displayedTotalElements}</div> : null;
};

export default TotalRecords;
