/* istanbul ignore file */
import React from 'react';
import { string, node } from 'prop-types';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useProjectService from './shared/projectService';

export const PROJECT_CONTEXT = 'Project';

const ProjectContext = ({ permissionToCheck, children }) => {
  const { getPermissions } = useProjectService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={PROJECT_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

ProjectContext.propTypes = {
  permissionToCheck: string.isRequired,
  children: node.isRequired,
};

export default ProjectContext;
