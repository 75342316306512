import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import { READ_CORPORATE_EVENT } from 'financialAnalytic/corporateEvents/useCorporateEventService';

const EditCorporateEventButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useCorporateEventUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} permissionToCheck={READ_CORPORATE_EVENT} />;
};

export default EditCorporateEventButton;
