/* istanbul ignore file */
import React from 'react';

export type CamundaInputProps<T = any> = T & {
  camundaType: string;
  source?: string;
};

const CamundaInput = <T,>(InputComponent: (props: T) => JSX.Element) => (props: CamundaInputProps<T>) => {
  return <InputComponent {...props} />;
};

export default CamundaInput;
