/* istanbul ignore file */
import React from 'react';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import './index.scss';

export enum TooltipSize {
  BIG = 'tooltip-lg',
  DEFAULT = 'tooltip-default',
}

interface TooltipProps extends MuiTooltipProps {
  size?: TooltipSize;
}

const Tooltip = ({ size = TooltipSize.DEFAULT, sx, ...props }: TooltipProps) => {
  return (
    <MuiTooltip
      arrow
      {...props}
      classes={{ popper: size }}
      enterTouchDelay={0}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['left', 'bottom', 'top'],
                boundary: 'window',
              },
            },
          ],
        },
      }}
    />
  );
};

export default Tooltip;
