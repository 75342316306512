/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps, Option } from 'shared/uibuilder/form/input/dropdownHelper';

export enum BOOLEAN_VALUE {
  YES = 'YES',
  NO = 'NO',
}

export const BOOLEAN_OPTIONS: Option[] = [
  { value: BOOLEAN_VALUE.YES, label: 'Yes' },
  { value: BOOLEAN_VALUE.NO, label: 'No' },
];

/**
 * Boolean dropdown input that allows null values. Should be used with BooleanOptions in the BE part.
 * @param source - source
 * @param props - props
 */
const BooleanDropdownOptionInput = ({ source, ...props }: DefaultDropdownProps) => {
  return <Dropdown {...props} source={source} options={BOOLEAN_OPTIONS} />;
};

export default BooleanDropdownOptionInput;
