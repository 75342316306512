import React from 'react';
import classnames from 'classnames';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { BaseFieldLayoutType, FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface BulletedListFieldProps extends FieldProps {
  listClassName?: string;
}

/**
 * This component displays a bulleted list from a string value after dividing it by dots.
 * @param {string} listClassName
 * @param {{source?: string; value?: Nullable<ValueType>; label?: string | React.ReactElement} | any} props
 * @return {any}
 * @constructor
 */
const BulletedListField = ({ listClassName, ...props }: BulletedListFieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const listData: string[] =
    getValue()
      ?.replace('. ', '.')
      .split('.') || [];

  const displayedValue = (
    <ul className={classnames('bulleted-list-field', listClassName)}>
      {listData.map((value: string) => !!value && <li key={value}>{value}</li>)}
    </ul>
  );

  return <BaseFieldLayout label={getLabel()} value={displayedValue} />;
};

export default BulletedListField;
