/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import React from 'react';
import BusinessRulesListField from './BusinessRulesListField';
import { NON_STRICT_RULES } from '../leaveService';

const CategorizedBusinessRulesListField = props => {
  const { getValue } = useFieldHelper(props);
  const { errorMap } = props;

  const violatedRules = getValue() || [];
  const uniqueViolatedRules = [...new Set(violatedRules)];
  const nonStrictRules = uniqueViolatedRules.filter(rule => NON_STRICT_RULES.includes(rule));
  const strictRules = uniqueViolatedRules.filter(rule => !NON_STRICT_RULES.includes(rule));

  return (
    <>
      {strictRules.length > 0 && [
        'The following strict rules were violated: ',
        <BusinessRulesListField value={strictRules} errorMap={errorMap} />,
      ]}
      {nonStrictRules.length > 0 && [
        'The following non strict rules were violated: ',
        <BusinessRulesListField value={nonStrictRules} errorMap={errorMap} />,
      ]}
    </>
  );
};

export default CategorizedBusinessRulesListField;
