/* istanbul ignore file */
// libs
import React from 'react';
import { Link } from 'react-router-dom';
// services
import useAuthenticationService from 'authentication/authenticationService';
import useAsyncValue from 'shared/asyncHelper';
import SanitizedHtml from '../security/SanitizedHtml';
import Container from '@mui/material/Container';
import Typography from 'uibuilder/Typography';

export interface Page403Props {
  location?: {
    state?: {
      htmlMessage?: string;
    };
  };
}

const Page403 = (props: Page403Props) => {
  const { location } = props;
  const { isAuthenticated } = useAuthenticationService();
  const isUserAuthenticated = useAsyncValue(isAuthenticated);

  const getDefaultPageMessage = () => {
    return (
      <>
        You do not have a permission to do it.
        {isUserAuthenticated && (
          <>
            <br />
            You can go back to&nbsp;
            <Link to="/">One Stop</Link>
          </>
        )}
      </>
    );
  };

  const getPageMessage = () => {
    if (location?.state?.htmlMessage) {
      return <SanitizedHtml html={location.state.htmlMessage} />;
    } else {
      return getDefaultPageMessage();
    }
  };

  return (
    <Container
      sx={{
        justifyContent: 'center',
        minHeight: 'calc(100vh - 90px)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="display0" sx={{ float: 'left', marginRight: '30px' }} className="c-title">
        403
      </Typography>
      <div>
        <Typography variant="h4" sx={{ marginBottom: '0.5rem', paddingTop: '0.75rem' }}>
          Access denied.
        </Typography>
        <Typography color="neutral.main">{getPageMessage()}</Typography>
      </div>
    </Container>
  );
};

export default Page403;
