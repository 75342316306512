/* istanbul ignore file */
// All logic is located in helpers
import React, { useMemo } from 'react';
import { ListProvider } from './ListContext';
import {
  useListCrud,
  useListParams,
  useListDataLoading,
  useListUrlStoring,
  useListLocalStorageStoring,
  useListDataUpdating,
  useListParamsConcatenation,
} from 'shared/uibuilder/list/builder';
import { Filter } from './filter/FilterContext';
import { SimpleListProps } from './SimpleList';
import useTimelinePaging from '../timeline/paging/timelinePagingHelper';
import getLoadingParams from './builder/listBuilderHelper';

export const LIST_PAGE_SIZE = 30;

export interface ListProps extends SimpleListProps {
  defaultSortOrder?: string;
  defaultSortField?: string;
  defaultFilter?: Filter;
  pageSize?: number;
  onRemove?: (params: StringOrNumber) => void;
  localStorageKey?: string;
  uncontrolledFilters?: string[];
}

const ListWithInfiniteScroll = ({
  getDataMethod,
  children,
  pageSize = LIST_PAGE_SIZE,
  defaultSortOrder,
  defaultSortField,
  defaultFilter,
  onRemove,
  localStorageKey,
  uncontrolledFilters,
}: ListProps) => {
  const { params: urlParams, updateParamsInUrl } = useListUrlStoring();
  const { params: storageParams, updateParamsInLocalStorage } = useListLocalStorageStoring({
    localStorageKey,
    uncontrolledFilters,
    defaultFilter,
  });
  const { params: initialParams } = useListParamsConcatenation({ urlParams, localStorageParams: storageParams });
  const { params, sortBy, searchBy, filterBy, goToPage, resetParamsToDefault } = useListParams({
    initialParams,
    defaultSortOrder,
    defaultSortField,
    defaultFilter,
    onParamsChangeCallbacks: [updateParamsInUrl, updateParamsInLocalStorage],
  });

  const { loading, loadData, setLoading } = useListDataLoading({ getDataMethod, resetParamsToDefault });

  const { data, reloadData } = useListDataUpdating({ loadData, params, pageSize });
  const listCrud = useListCrud({ onRemove, inputData: data });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadingParams = useMemo(() => getLoadingParams(params, pageSize), [params.filter, params.sort, params.search]);

  const pagingMethods = useTimelinePaging({
    currentPage: params.page || 0,
    loadData: (pageNumber: number) => loadData({ ...loadingParams, pageNumber }, false),
    setData: listCrud.setData,
    data: listCrud.data,
  });

  const contextData = {
    ...params,
    filterBy,
    sortBy,
    goToPage,
    searchBy,
    loading,
    setLoading,
    reloadData,
    uncontrolledFilters,
    ...listCrud,
    ...pagingMethods,
  };

  return <ListProvider value={contextData}>{children}</ListProvider>;
};

export const LIST_DEFAULT_PROPS = {
  pageSize: LIST_PAGE_SIZE,
  defaultSortOrder: '',
  defaultSortField: '',
  defaultFilter: {},
  onRemove: () => {},
};

ListWithInfiniteScroll.defaultProps = LIST_DEFAULT_PROPS;

export default ListWithInfiniteScroll;
