import React from 'react';
import { TextField } from 'shared/uibuilder/field';

const VacancyPublicationField = (props: any) => {
  const { value } = props;

  return <TextField value={value} />;
};

export default VacancyPublicationField;
