import React from 'react';
import Alert from 'uibuilder/Alert';

type AlertErrorsForFormListProps = {
  isShow: boolean;
  onClose: () => void;
  textContent: string;
};

const AlertErrorsForFormList = ({ isShow, onClose, textContent }: AlertErrorsForFormListProps) => {
  if (!isShow) return null;
  return (
    <Alert onClose={onClose} severity="error">
      {textContent}
    </Alert>
  );
};

export default AlertErrorsForFormList;
