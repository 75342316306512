import React, { useEffect } from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import TransferListInputTotal from 'financialAnalytic/journalEntry/shared/input/TransferListInputTotal';
import { get, cloneDeep, capitalize } from 'lodash';
import { convertCurrency } from 'shared/uibuilder/amountUtils';
import {
  isRequiredField,
  officeRequiredAccounts,
  employeeNotRequiredAccounts,
  employeeRequiredAccounts,
  projectRequiredAccounts,
} from 'financialAnalytic/journalEntry/useJournalEntryService';
import './TransferListInput.scss';

type TransferData = any;

const convertToUSD = (value: number, exchangeRate: number, shouldConvert: boolean) =>
  shouldConvert ? +convertCurrency(+value, exchangeRate) : +value;

const TransferListInput = ({ inputTemplate, ...props }: InputListProps & { inputProps?: any }) => {
  const { getValue, getRawOnChangeCallback, getSource } = useInputHelper(props);
  const { getValue: getExchangeRates } = useInputHelper({ source: 'exchangeRates' });
  const data = (getValue() as any) || [];
  const rawOnChangeCallback = getRawOnChangeCallback() as any;
  const source = getSource();
  const exchangeRates = getExchangeRates() as any;

  const isEqualCurrencies = (transfer1: TransferData, transfer2: TransferData) =>
    (!transfer1.currency && !transfer2.currency) || transfer1.currency === transfer2.currency;

  // Check if values are different
  const isControlledValue = (
    transfer: TransferData,
    comparedTransfer: TransferData,
    targetSource: string,
    oppositeValueSource: string,
  ) => {
    const transferValue = transfer[`${targetSource}InUSD`] || transfer[targetSource];
    const comparedValue = comparedTransfer[`${oppositeValueSource}InUSD`] || comparedTransfer[oppositeValueSource];

    return transferValue === comparedValue || (!transferValue && !comparedValue);
  };

  const updateTransfersAmountsInUSD = (transfers: any[]) =>
    transfers.map((transfer: any) => {
      const getConvertedValue = (value?: number) =>
        convertToUSD(
          value || 0,
          exchangeRates?.[transfer.currency || 'USD']?.USD || 1,
          transfer?.currency && transfer?.currency !== 'USD',
        );

      return {
        ...transfer,
        debitInUSD: getConvertedValue(transfer.debit || 0),
        creditInUSD: getConvertedValue(transfer.credit || 0),
      };
    });

  const onChange = (values: any) => {
    const transfers = updateTransfersAmountsInUSD(cloneDeep(get(values, source, [])));
    const controlledTransfer = transfers[1];
    const hasEqualCurrencies = isEqualCurrencies(controlledTransfer, transfers[0]);
    const shouldConvert = controlledTransfer?.currency && controlledTransfer?.currency !== 'USD';
    const exchangeRate = (shouldConvert && exchangeRates?.[controlledTransfer.currency]?.USD) || 1;

    const updateTransferAmount = (targetSource: string, oppositeValueSource: string) => {
      if (
        controlledTransfer[`is${capitalize(targetSource)}Changed`] ||
        // if there is a value in the 'opposite' cell (credit for debit and debit for credit)
        controlledTransfer[oppositeValueSource] ||
        !isControlledValue(data[1], data[0], targetSource, oppositeValueSource)
      ) {
        return;
      }

      const oppositeValueInUSD = transfers[0][`${oppositeValueSource}InUSD`];
      controlledTransfer[`${targetSource}InUSD`] = oppositeValueInUSD;

      if (hasEqualCurrencies) {
        controlledTransfer[targetSource] = transfers[0][oppositeValueSource];
        return;
      }

      controlledTransfer[targetSource] =
        shouldConvert && oppositeValueInUSD
          ? convertCurrency(oppositeValueInUSD, exchangeRate, false)
          : oppositeValueInUSD;
    };

    updateTransferAmount('debit', 'credit');
    updateTransferAmount('credit', 'debit');

    rawOnChangeCallback({
      [source]: transfers,
      officeIsRequired: isRequiredField(transfers, officeRequiredAccounts),
      employeeAliasIsRequired:
        isRequiredField(transfers, employeeRequiredAccounts) &&
        !isRequiredField(transfers, employeeNotRequiredAccounts),
      projectIsRequired: isRequiredField(transfers, projectRequiredAccounts),
    });
  };

  useEffect(() => {
    if (exchangeRates.date) {
      onChange({ [source]: data });
    }
    // Suppressed warnings because we only need to call useEffect callback only if exchangeRates.date is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRates.date]);

  return (
    <div className="journal-entries-transfers-input">
      <InputList
        {...props}
        inputTemplate={inputTemplate}
        onChangeCallback={onChange}
        maxFields={100}
        defaultValue={{}}
      />
      <TransferListInputTotal transfersSource={source} exchangeRatesSource="exchangeRates" />
    </div>
  );
};

export default TransferListInput;
