/* istanbul ignore file */
import React from 'react';
import { Dropdown } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { EmployeeDomainStatusLabels } from 'erp/employee/domains/shared/employeeDomainsService';
import DomainInput from 'erp/employee/domains/domain/field/DomainInput';

type EmployeeDomainFormProps = {
  handleCancelClick: () => void;
};

const EmployeeDomainForm = ({ handleCancelClick }: EmployeeDomainFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  const getDropDownField = ({ source, label: fieldLabel }: { source: string; label: string }) => (
    <Dropdown
      source={source}
      isRequired={source === 'knowledge'}
      placeholder={`Choose ${fieldLabel}`}
      options={Object.entries(EmployeeDomainStatusLabels).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label={fieldLabel}
    />
  );

  return (
    <>
      <DomainInput
        isRequired
        errorSource="domain"
        source="domainId"
        placeholder="Choose Domain area"
        label="Domain area"
      />
      {getDropDownField({ source: 'knowledge', label: 'Knowledge' })}
      {getDropDownField({ source: 'comprehension', label: 'Comprehension' })}
      {getDropDownField({ source: 'application', label: 'Application' })}
      {getDropDownField({ source: 'analysis', label: 'Analysis' })}
      {getDropDownField({ source: 'synthesis', label: 'Synthesis' })}
      {getDropDownField({ source: 'evaluation', label: 'Evaluation' })}
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default EmployeeDomainForm;
