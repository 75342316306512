/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField from 'shared/uibuilder/field/LinkField';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import { FieldProps } from 'shared/uibuilder/field';

const CompanyLinkField = ({ source, ...props }: FieldProps) => {
  const { getValue: getOpportunityId } = useFieldHelper({ source: 'opportunityId' });
  const { getValue: getLeadId } = useFieldHelper({ source: 'leadId' });
  const { getSingleEntityUrl: getLeadUrl } = useLeadUrl();
  const { getSingleEntityUrl: getOpportunityUrl } = useOpportunityUrl();

  const opportunityId = getOpportunityId();
  const leadId = getLeadId();

  return (
    <LinkField
      linkText={opportunityId || leadId}
      isInternal
      value={opportunityId ? getOpportunityUrl(opportunityId) : getLeadUrl(leadId)}
      {...props}
    />
  );
};

export default CompanyLinkField;
