import React from 'react';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { WASTE_STATUSES_OPTIONS } from '../../useWasteService';

const WasteStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={WASTE_STATUSES_OPTIONS} label="Status" {...props} />;
};

export default WasteStatusDropdown;
