/* istanbul ignore file */
// removed from test coverage because it will be need to refactor

// libs
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
// services
import {
  CREATE_VACANCY,
  READ_VACANCIES_LIST,
  READ_VACANCY,
  UPDATE_VACANCY,
  VACANCY_PATH,
} from 'erp/recruitment/vacancy/shared/vacancyService';
import VacanciesList from 'erp/recruitment/vacancy/List';
import VacancyContext from 'erp/recruitment/vacancy/VacancyContext';
import { CreateVacancy, UpdateVacancy } from 'erp/recruitment/vacancy/createupdate';
import ViewVacancy from 'erp/recruitment/vacancy/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import ViewVacancyHiringRequests from 'erp/recruitment/vacancy/Show/ViewVacancyHiringRequests';

export const useVacancyRoute = () => {
  const routers = useRoute({ listRoute: VACANCY_PATH });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
    hiringRequestsRoute: `${routers.singleEntityRoute}/requests`,
  };
};

export const useVacancyUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: VACANCY_PATH,
  });

  const getCopyEntityUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/copy`;
  const getHiringRequestsUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/requests`;

  return {
    ...restUrls,
    getSingleEntityUrl,
    getCopyEntityUrl,
    getHiringRequestsUrl,
  };
};

export const useVacancyId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id;
};

const VacancyRouter = () => {
  const {
    listRoute,
    singleEntityRoute,
    hiringRequestsRoute,
    createRoute,
    updateRoute,
    copyEntityRoute,
  } = useVacancyRoute();

  return (
    <ProtectedRouter basePath={VACANCY_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_VACANCIES_LIST}>
          <VacanciesList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_VACANCY}>
          <CreateVacancy />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={copyEntityRoute} exact globalPermissionToCheck={CREATE_VACANCY}>
          <CreateVacancy />
        </GlobalProtectedRoute>
        <Route path={singleEntityRoute} exact>
          <VacancyContext permissionToCheck={READ_VACANCY}>
            <ViewVacancy />
          </VacancyContext>
        </Route>
        <Route path={hiringRequestsRoute} exact>
          <VacancyContext permissionToCheck={READ_VACANCY}>
            <ViewVacancyHiringRequests />
          </VacancyContext>
        </Route>
        <Route path={updateRoute} exact>
          <VacancyContext permissionToCheck={[UPDATE_VACANCY]}>
            <UpdateVacancy />
          </VacancyContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

VacancyRouter.getRouterPath = () => VACANCY_PATH;

export default VacancyRouter;
