/* istanbul ignore file */
import React from 'react';
import { REQUEST_STATUS } from 'crm/opportunity/presale/request/shared/presaleRequestService';
import Badge from 'shared/uibuilder/badge/Badge';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const PresaleStatusBadge = ({ statusSource }: { statusSource: string }) => {
  const { data } = useShowContext();

  const status = data.getValueBySource(statusSource);

  const color = REQUEST_STATUS.SUBMITTED === status ? 'success' : 'neutral';

  return <Badge color={color}>{status}</Badge>;
};

export default PresaleStatusBadge;
