/* istanbul ignore file */
export default {
  feedbackText: {
    type: 'string',
    required: true,
    maxSize: 65000,
  },
  relatedPeriodStart: {
    type: 'date',
    maxDate: '$NOW',
    required: {
      value: true,
      when: {
        $relatedPeriodEnd: {
          notEmpty: true,
        },
      },
    },
  },
  relatedPeriodEnd: {
    type: 'date',
    maxDate: '$NOW',
    minDate: '$relatedPeriodStart',
    required: {
      value: true,
      when: {
        $relatedPeriodStart: {
          notEmpty: true,
        },
      },
    },
  },
  attachmentsIds: {
    type: 'array',
  },
  onBehalfOf: {
    type: 'string',
    maxSize: 128,
  },
  onBehalfOfEmployee: {
    type: 'string',
    maxSize: 128,
  },
  relatedClient: {
    type: 'string',
    maxSize: 128,
  },
  relatedProject: {
    type: 'string',
    maxSize: 128,
  },
};
