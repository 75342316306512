import React, { useState } from 'react';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

const useDropDownFilterHelper = ({ source }: { source: string }) => {
  const { resetFilter } = useFilterContext();
  const [isMenuOpened, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    if (isMenuOpened) {
      resetFilter(source);
    }

    setIsMenuOpen(prevValue => !prevValue);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const getDisplayedValue = (selectedValues?: OptionType[]) => {
    let dropdownValue;

    if (selectedValues) {
      dropdownValue =
        selectedValues.length > 1 ? (
          <>
            {selectedValues[0]?.label}
            {selectedValues.length > 1 && (
              <span className="filter-dropdown-counter">{`+${selectedValues.length - 1}`}</span>
            )}
          </>
        ) : (
          selectedValues[0]?.label
        );
    }

    return dropdownValue;
  };

  return {
    isMenuOpened,
    toggleMenu,
    getDisplayedValue,
    closeMenu,
  };
};

export default useDropDownFilterHelper;
