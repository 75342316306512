/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { arrayOf, element, oneOfType } from 'prop-types';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import ContractsBreadcrumbs from 'erp/contracts/shared/ContractsBreadcrumbs';
import { useContractsUrl } from 'erp/contracts/ContractsRouter';

const SingleContractsBreadcrumbs = ({ children }) => {
  const { getSingleEntityUrl } = useContractsUrl();
  const { id } = useParams();

  return (
    <ContractsBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </ContractsBreadcrumbs>
  );
};

SingleContractsBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleContractsBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleContractsBreadcrumbs;
