/* istanbul ignore file */
import React from 'react';
import Button from 'uibuilder/button/Button';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import useAuthorization from 'shared/authorization/authorizationService';
import { GROUP_APPLICATION } from 'erp/recruitment/applications/shared/applicationService';

const ApplicationsGroupButton = ({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => {
  const { isGranted } = useAuthorization();

  return isGranted(GROUP_APPLICATION) ? (
    <Button
      onClick={onClick}
      variant={isActive ? 'contained' : 'outlined'}
      sx={{
        b: { marginLeft: '5px' },
        marginRight: isActive ? '12px' : 0,
      }}
    >
      Group by stage
      {isActive && (
        <CloseIcon
          sx={{
            fontSize: '20px',
            marginLeft: '8px',
            marginRight: '-4px',
            color: theme => theme.vars.palette.common.white,
          }}
        />
      )}
    </Button>
  ) : null;
};

export default ApplicationsGroupButton;
