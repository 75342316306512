/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import BigFormRow from 'shared/layout/form/BigFormRow';
import OfferCompensationDetailsListInput from 'erp/candidate/offercompensation/shared/input/OfferCompensationDetailsListInput';
import OfferCompensationDetailsInput from 'erp/candidate/offercompensation/shared/input/OfferCompensationDetailsInput';
import DocumentScanUploaderInput from '../../../../artifact/shared/input/DocumentScanUploaderInput';
import useAuthorization from 'shared/authorization/authorizationService';
import { CANDIDATE_CONTEXT } from 'erp/candidate/CandidateContext';
import {
  INTERACTION_BASE_FINANCE_DATA,
  INTERACTION_CALCULATED_FINANCE_DATA,
  INTERACTION_PROBATION_FINANCE_DATA,
} from 'erp/candidate/shared/candidateService';

const CandidateOfferForm = () => {
  const { isGranted } = useAuthorization();
  return (
    <FormSection title="Compensation Details">
      <BigFormRow>
        <OfferCompensationDetailsListInput
          source="offerCompensations"
          maxFields={4}
          addText="Add Compensation Details"
          inputTemplate={(props: any) => (
            <OfferCompensationDetailsInput
              canViewBaseFinanceFields={isGranted(INTERACTION_BASE_FINANCE_DATA, CANDIDATE_CONTEXT)}
              canViewProbationField={isGranted(INTERACTION_PROBATION_FINANCE_DATA, CANDIDATE_CONTEXT)}
              canViewCalculatedGrossField={isGranted(INTERACTION_CALCULATED_FINANCE_DATA, CANDIDATE_CONTEXT)}
              {...props}
            />
          )}
        />
      </BigFormRow>
      <BigFormRow>
        <DocumentScanUploaderInput label="Attachments" source="attachmentIds" />
      </BigFormRow>
    </FormSection>
  );
};

export default CandidateOfferForm;
