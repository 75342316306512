/* istanbul ignore file */
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useAppSidebarContext } from './AppSidebarNavContext';

interface SidebarItemProps {
  name: string;
  url: string;
  children?: SidebarItemProps[];
  icon?: React.ReactNode;
  divider?: boolean;
  isActive: boolean;
  clickHandler?: (name: string, isOpen: boolean) => void;
  getIsActiveLink?: (match: any, location: any, currentLinkUrl?: string) => boolean;
  location: { pathname: string };
  collapsible?: boolean;
}

const MIN_COLLAPSED_ITEMS_LENGTH = 5;

const SidebarItem = ({
  name,
  children,
  icon,
  url,
  divider,
  isActive,
  clickHandler = () => {},
  getIsActiveLink = () => false,
  location,
  collapsible = false,
}: SidebarItemProps) => {
  const { handleDrawerToggle = () => {} } = useAppSidebarContext();
  const [isOpen, setIsOpen] = useState(isActive);
  const [isMoreItemsShown, setIsMoreItemsShown] = useState(false);
  const isCollapsible = !!children?.length;

  const handleClick = () => {
    if (isCollapsible) {
      setIsOpen(!isOpen);
    } else {
      handleDrawerToggle();
    }

    clickHandler(name, isOpen);
  };

  if (divider) {
    return <Divider />;
  }

  const renderItems = (items: SidebarItemProps[]) =>
    items.map((child: any) => (
      <SidebarItem
        key={`${child.url}-${child.name}`}
        {...child}
        clickHandler={clickHandler}
        getIsActiveLink={getIsActiveLink}
        location={location}
      />
    ));

  if (isCollapsible) {
    return (
      <>
        <ListItem key={name} disablePadding sx={{ marginTop: isOpen ? '12px' : '', transition: 'margin 0.1s' }}>
          <ListItemButton onClick={handleClick}>
            {icon ? <ListItemIcon>{typeof icon === 'string' ? <i className={icon} /> : icon}</ListItemIcon> : null}
            <ListItemText primary={name} sx={{ marginRight: '4px' }} />
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </ListItemButton>
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit sx={{ marginBottom: isOpen ? '12px' : '' }}>
          <List key={name} component="div" disablePadding>
            {collapsible && children.length > MIN_COLLAPSED_ITEMS_LENGTH ? (
              <>
                {renderItems(children.slice(0, MIN_COLLAPSED_ITEMS_LENGTH))}
                <Collapse
                  in={isMoreItemsShown}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    '& li.MuiListItem-root': { paddingLeft: '29px !important' },
                  }}
                >
                  {renderItems(children.slice(MIN_COLLAPSED_ITEMS_LENGTH))}
                </Collapse>
                <ListItem
                  key={`${name}-collapse-button`}
                  disablePadding
                  sx={{ marginTop: isOpen ? '12px' : '', transition: 'margin 0.1s' }}
                >
                  <ListItemButton
                    onClick={() => setIsMoreItemsShown(prevState => !prevState)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: 'var(--mui-palette-primary-80)',
                        marginLeft: '-5px',
                        marginRight: '3px',
                      },
                      '&:hover': {
                        background: 'transparent !important',
                        '.MuiListItemText-primary': { textDecoration: 'underline !important' },
                      },
                    }}
                  >
                    {isMoreItemsShown ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    <ListItemText
                      primary={`See ${isMoreItemsShown ? 'less' : 'more'}`}
                      sx={{
                        marginRight: '4px',
                        '& > .MuiListItemText-primary': { color: 'var(--mui-palette-primary-80) !important' },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              renderItems(children)
            )}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItem key={name} disablePadding>
      <ListItemButton
        component={NavLink as any}
        to={url}
        isActive={(match: any, currentLocation?: string) => getIsActiveLink(match, currentLocation, url)}
        onClick={handleClick}
      >
        <ListItemIcon>{typeof icon === 'string' ? <i className={icon} /> : icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarItem;
