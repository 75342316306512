import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { flushSync } from 'react-dom';
import useStorageService from 'shared/uibuilder/storageService';
import { ListItem } from 'shared/uibuilder/list/ListContext';

const CACHE_NAME = 'collapsible-data-grid';

export interface CollapsibleRowStorageProps {
  cacheName?: string;
  storageKey?: string;
  saveExpandedRows?: boolean;
}

export interface CollapsibleRowServiceProps {
  data: ListItem;
  rowKeySource?: string;
  collapsibleRowStorageProps?: CollapsibleRowStorageProps;
}

export interface CollapsibleRowServiceData {
  handleToggleClick: () => void;
  collapsed: boolean;
}

const useCollapsibleRowService = ({
  data,
  rowKeySource = 'id',
  collapsibleRowStorageProps: { cacheName = CACHE_NAME, storageKey = 'expandedRows', saveExpandedRows = true } = {},
}: CollapsibleRowServiceProps): CollapsibleRowServiceData => {
  const { pathname } = useLocation();

  const getCacheId = () => `${pathname}-${cacheName}`;

  const { getDataFromStorage, setDataToStorage } = useStorageService(
    getCacheId(),
    () => ({
      [storageKey]: [],
    }),
    '1.0',
  );

  const [collapsed, setCollapsed] = useState(true);
  const id = data[rowKeySource];

  useEffect(() => {
    (async () => {
      const rowsData = (await getDataFromStorage())[storageKey];
      const isContainId = rowsData?.includes(id);

      // Fixed issue with table rendering freezing
      setTimeout(() => flushSync(() => setCollapsed(saveExpandedRows ? !isContainId : isContainId)), 0);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleClick = async () => {
    const rowsData = (await getDataFromStorage())[storageKey];
    let newExpandedRows: string[] = [...(rowsData || [])];

    if (rowsData?.includes(id)) {
      newExpandedRows = newExpandedRows.filter((rowId: string) => id !== rowId);
    } else {
      newExpandedRows.push(id);
    }

    setCollapsed(prev => !prev);
    setDataToStorage({ [storageKey]: newExpandedRows });
  };

  return {
    handleToggleClick,
    collapsed,
  };
};

export default useCollapsibleRowService;
