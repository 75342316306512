/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useOpportunityService, {
  CONTRACT,
  SIGNING,
  UPDATE_OPPORTUNITY,
} from 'crm/opportunity/shared/opportunityService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import PresaleOutputInputs from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton/PresaleOutputInputs';

const MarkAsCurrentStateButton = ({ activeStep }: { activeStep: string }) => {
  const id = useOpportunityId();
  const { update } = useOpportunityService() as any;
  const { getLastPresaleOutput, updateOutput } = usePresaleOutputService();
  const { data, setData = () => {} } = useShowContext();
  const { addMessage } = useMessageService();
  const [outputFormErrors, setOutputFormErrors] = useState<Dictionary<string[]>>({});
  const [outputFormData, setOutputFormData] = useState<Nullable<Dictionary<any>>>(null);

  const isPresaleOutputChangeVisible = activeStep === CONTRACT || activeStep === SIGNING;

  useEffect(() => {
    (async () => {
      if (isPresaleOutputChangeVisible) {
        const lastOutput = await getLastPresaleOutput(id);
        setOutputFormData(lastOutput);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleMarkAsCurrentStep = async () => {
    if (outputFormData) {
      try {
        await updateOutput(id, outputFormData.id, outputFormData);
      } catch (e) {
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
        return;
      }
    }
    try {
      const response = await update(id, { ...data.getData(), stateStep: activeStep });
      setData(response);
    } catch (e) {
      const result = await (e as any).json();
      addMessage(new ErrorMessage(result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
    }
  };

  const getModalChildren = () => {
    return isPresaleOutputChangeVisible && outputFormData ? (
      <div className="mt-5">
        <PresaleOutputInputs
          setFormErrors={setOutputFormErrors}
          setFormData={setOutputFormData}
          formData={outputFormData}
          formErrors={outputFormErrors}
        />
      </div>
    ) : (
      <></>
    );
  };

  const getModalAdditionalMessage = () => {
    return isPresaleOutputChangeVisible && outputFormData
      ? 'Please, verify that Team structure and Expected Project start provided in this form are actual. If not, please, change.'
      : '';
  };

  return (
    <ButtonWithConfirmation
      displayMessage={`Do you really want to mark this state as completed? ${getModalAdditionalMessage()}`}
      submitMethod={handleMarkAsCurrentStep}
      className="step-button"
      permissionToCheck={UPDATE_OPPORTUNITY}
      modalChildren={getModalChildren()}
      modalSize={isPresaleOutputChangeVisible && outputFormData ? 'lg' : 'sm'}
      confirmBtnProps={{
        disabled: isPresaleOutputChangeVisible && Object.values(outputFormErrors).some(error => error.length),
      }}
    >
      Mark as current state
    </ButtonWithConfirmation>
  );
};

export default MarkAsCurrentStateButton;
