/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { Button } from 'uibuilder/button';
import AddEmployeeDomainModalForm from 'erp/employee/domains/shared/AddEmployeeDomainModalForm';
import useAuthorization from 'shared/authorization/authorizationService';
import { CREATE_EMPLOYEE_DOMAIN } from 'erp/employee/domains/shared/employeeDomainsService';

const AddEmployeeDomainButton = () => {
  const { isGranted } = useAuthorization();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  if (!isGranted(CREATE_EMPLOYEE_DOMAIN)) {
    return null;
  }

  return (
    <>
      <Button className="instance-feedback-button" onClick={openCloseIssueForm}>
        <span>Add Employee Domain</span>
      </Button>
      {isFormOpen && <AddEmployeeDomainModalForm closeModal={closeModal} />}
    </>
  );
};

export default AddEmployeeDomainButton;
