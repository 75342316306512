/* istanbul ignore file */
export default {
  from: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$to',
      message: "Start date can't be later than end date",
    },
  },
  to: {
    type: 'date',
    required: true,
    minDate: {
      value: '$from',
      message: "End date can't be earlier than start date",
    },
  },
};
