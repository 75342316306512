import React from 'react';
import { EmployeeLinkField } from 'erp/employee';
import { TextField } from 'shared/uibuilder/field';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { RbsNodeType } from '../rbsService';
import SimpleFieldLayout from 'uibuilder/layout/field/SimpleFieldLayout';

const RbsNodeOwnersField = ({ canViewEmployeeDetails, ...props }) => {
  const { data } = useShowContext();

  let secondOwnerData = <></>;
  if (data.nodeType === RbsNodeType.PROJECT && data.attributes.secondOwner) {
    secondOwnerData = canViewEmployeeDetails ? (
      <EmployeeLinkField source="attributes.secondOwner.alias" nameSource="attributes.secondOwner.fullName" />
    ) : (
      <TextField source="attributes.secondOwner.fullName" />
    );
  }

  let ownerData = <></>;
  if (data.attributes.owner || data.nodeType === RbsNodeType.QUEUE || !data.attributes.secondOwner) {
    ownerData = canViewEmployeeDetails ? (
      <EmployeeLinkField source="attributes.owner.alias" nameSource="attributes.owner.fullName" label="Owners" />
    ) : (
      <TextField source="attributes.owner.fullName" label="Owners" />
    );
  }

  return (
    <SimpleFieldLayout
      {...props}
      label=""
      value={
        <div className="row-list-field">
          <p className="field__item-wrapper">{ownerData}</p>
          <p className="field__item-wrapper">{secondOwnerData}</p>
        </div>
      }
    />
  );
};

export default RbsNodeOwnersField;
