import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DATE_FORMAT } from 'shared/uibuilder/dateService/constants';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService';
// temporary solution
import { store } from 'shared/reducer/store';

const getMomentDateObj = (...props) => moment(...props);

/**
 * Method for getting date as utc moment object
 * @param date - utc date in the string format
 * @returns {moment.Moment} = moment object
 */
const getMomentDateObjAsUtc = date => {
  return moment.utc(date);
};

/**
 * @param date - text date
 * @param format - date format like YYYY-MM-DD
 * @returns {string|null} - text date converted to passed format
 */
const formatDate = (date, format) => {
  if (!date) return null;

  return ` ${getMomentDateObj(date).format(format)}`;
};

/**
 * @param dateFrom - text date in format 'YYYY-MM-DD HH:mm:ss'
 * @param dateTo - text date in format 'YYYY-MM-DD HH:mm:ss'
 * @returns {string|null} - string duration between two month in format `${year} year, ${month} month, ${day} days`
 */
const getStringDurationBetweenTwoDates = (dateFrom, dateTo) => {
  if (!dateFrom || !dateTo) {
    return null;
  }

  const start = getMomentDateObj(dateFrom, DATE_FORMAT.API).startOf('day');
  const end = getMomentDateObj(dateTo, DATE_FORMAT.API)
    .endOf('day')
    .add(1, 'minute');

  const years = end.diff(start, 'year');
  start.add(years, 'years');
  const months = end.diff(start, 'months');
  start.add(months, 'months');
  const days = end.diff(start, 'days');
  start.add(days, 'days');

  const durationValues = {
    years,
    months,
    days,
  };

  let result = '';
  Object.keys(durationValues).forEach(key => {
    if (durationValues[key]) {
      const durationName = durationValues[key] === 1 ? key.replace('s', '') : key;
      result += result.length ? `, ${durationValues[key]} ${durationName}` : `${durationValues[key]} ${durationName}`;
    }
  });

  return result;
};

/**
 * @param format - date format like YYYY-MM-DD, 'YYYY-MM-DD' by default
 * @returns moment.Moment - moment object with today date converted to passed format
 */
const getCurrentDate = (format = DATE_FORMAT.INPUT_VALUE) => moment.utc(moment.utc().format(format));

/**
 * @param dateTo - text date
 * @param dateFrom - text date, today date by default
 * @param format - date format like YYYY-MM-DD, 'YYYY-MM-DD' by default
 * @returns {number|null} - month diff between dates
 */
const getMonthsBetween = (dateTo, dateFrom = getCurrentDate(), format = DATE_FORMAT.INPUT_VALUE) => {
  if (!dateTo) {
    return null;
  }

  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'months', true);
};

const getDaysBetween = (dateTo, dateFrom, format = DATE_FORMAT.INPUT_VALUE) => {
  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'days');
};

const getHoursBetween = (dateTo, dateFrom, format = DATE_FORMAT.API) => {
  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'hours');
};

const getMinutesBetween = (dateTo, dateFrom, format = DATE_FORMAT.API) => {
  const comparedDate = moment.utc(moment.utc(dateTo).format(format));

  return comparedDate.diff(moment.utc(moment.utc(dateFrom).format(format)), 'minutes');
};

const getCurrentDateTime = () => moment();

/**
 * Method for getting current date in the UTC (GMT +0)
 * @returns {moment.Moment}
 */
const getCurrentDateTimeInUtc = () => moment.utc();

const getTimeDifference = (startDate, endDate = getCurrentDateTime()) => endDate.diff(startDate);

const isCurrentTimeInPeriod = (startDate, endDate) => moment().isBetween(startDate, endDate);

const getUserTimezone = () => store.getState().auth?.user?.timezone || DEFAULT_TIMEZONE;

const getToday = () => momentTimezone.tz(moment(), getUserTimezone()) || moment();

const formatDateForAPI = dateText => {
  return moment(dateText).format(DATE_FORMAT.API_ONLY_DATE);
};

const getMaxDate = dates => moment.max(dates);

const getMinDate = dates => moment.min(dates);

const subtractDaysFromStartDate = (startDate, daysToSubtract, dateFormat = DATE_FORMAT.SIMPLE) => {
  const momentStartDate = getMomentDateObj(startDate);
  const newDate = momentStartDate.subtract(daysToSubtract, 'days');

  return newDate.format(dateFormat);
};

export default () => ({
  formatDateForAPI,
  getMonthsBetween,
  formatDate,
  getToday,
  getCurrentDate,
  getCurrentDateTime,
  getCurrentDateTimeInUtc,
  getTimeDifference,
  getStringDurationBetweenTwoDates,
  getMaxDate,
  getMinDate,
  getMomentDateObj,
  getMomentDateObjAsUtc,
  getDaysBetween,
  getHoursBetween,
  getMinutesBetween,
  getUserTimezone,
  subtractDaysFromStartDate,
  isCurrentTimeInPeriod,
});
