import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { ListParams } from '../ListContext';
import { Filter } from '../filter/FilterContext';

const getParamValue = (urlValue: any, storageValue: any) => urlValue || storageValue;

const getObjectParamValue = (urlValue: object, storageValue?: object) => (!isEmpty(urlValue) ? urlValue : storageValue);

type ConcatenationParams = {
  urlParams: ListParams;
  localStorageParams: Partial<ListParams>;
};

const useListParamsConcatenation = ({ urlParams, localStorageParams = {} }: ConcatenationParams) => {
  // @ts-ignore
  const filter: Filter = getObjectParamValue(urlParams.filter, localStorageParams?.filter);
  const params = useMemo(
    () => ({
      filter,
      sort: {
        field: getParamValue(urlParams.sort?.field, localStorageParams?.sort?.field),
        order: getParamValue(urlParams.sort?.order, localStorageParams?.sort?.order),
      },
      page: urlParams.page || localStorageParams.page || 0,
    }),
    [filter, urlParams, localStorageParams],
  );

  return {
    params,
  };
};

export default useListParamsConcatenation;
