/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import TimeUnitsDropdown from 'shared/uibuilder/form/input/TimeUnitsDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import Box from 'uibuilder/Box';
import MultiInputWrapperLayout from 'uibuilder/layout/form/input/MultiInputWrapperLayout';

const AssetBillingCycleInput = ({ source, label, ...props }: DefaultInputPropTypes<any>) => {
  return (
    <MultiInputWrapperLayout
      label={label as any}
      inputSources={[`${source}.amount`, `${source}.timeUnit`]}
      gridTemplateColumns="37px minmax(110px, 1fr) 130px"
      isRequired
    >
      <Box sx={{ paddingTop: '9px' }}>Every</Box>
      <Dropdown
        {...props}
        source={`${source}.amount`}
        options={Array(40)
          .fill(0)
          .map((_, index) => {
            const value = (index + 1).toString();

            return { value, label: value };
          })}
        placeholder=""
      />
      <TimeUnitsDropdown source={`${source}.timeUnit`} />
    </MultiInputWrapperLayout>
  );
};

export default AssetBillingCycleInput;
