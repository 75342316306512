import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { DefaultDropdownLayoutProps } from 'shared/uibuilder/form/input/Dropdown';
import { IN_CONDITION_TYPE } from './condition/InCondition';
import { Filter, useFilterContext, BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';

export interface DropDownFilterProps extends BaseFilterProps {
  children: React.ReactElement;
  isSingle?: boolean;
  onChangeCallback?: (filter: Filter) => void;
}

interface DropDownFilterLayoutProps extends DefaultDropdownLayoutProps, DropDownFilterProps {
  label?: string;
  children: React.ReactElement;
  value?: string;
  getDropdownValue: (multiple: boolean) => string | string[];
  onChangeCallback: (filter: Filter) => void;
}

export type DropDownFilterLayoutType = ReactComponent<DropDownFilterLayoutProps>;

const DropDownFilter = ({
  children,
  source,
  onChangeCallback: parentOnChangeCallback,
  ...props
}: DropDownFilterProps) => {
  const { DropDownFilterLayout } = useUiTheme<DropDownFilterLayoutType>();
  const { filterBy, getValue } = useFilterContext();

  const onChangeCallback = (filter: Filter) => {
    filterBy(filter);
  };

  /**
   * Dropdown requires different value format depending on
   * if it's `multiple` or not. This method insures that
   * dropdown receives correct format regardless of stored value format
   *
   * @returns array if `multiple` set to true, plain object otherwise
   */
  const getDropdownValue = (multiple: boolean) => {
    const value = getValue(source, IN_CONDITION_TYPE);
    if (multiple && !Array.isArray(value)) {
      return [value];
    }

    if (!multiple && Array.isArray(value)) {
      return value[0];
    }

    return value;
  };

  return (
    <DropDownFilterLayout
      {...props}
      source={source}
      getDropdownValue={getDropdownValue}
      onChangeCallback={parentOnChangeCallback || onChangeCallback}
    >
      {children}
    </DropDownFilterLayout>
  );
};

export default DropDownFilter;
