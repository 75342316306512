/* istanbul ignore file */
import React from 'react';
import DropDownFilter from '../../../../../shared/uibuilder/list/filter/DropDownFilter';
import CandidateNoteTagDropdown from '../input/CandidateNoteTagDropdown';

const CandidateTimelineNoteTagFilter = (props: any) => {
  return (
    <DropDownFilter {...props} isSingle>
      <CandidateNoteTagDropdown />
    </DropDownFilter>
  );
};

export default CandidateTimelineNoteTagFilter;
