import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';

type ResourceName = string;

// eslint-disable-next-line
enum CacheActions {
  SET_CACHE = 'SET_CACHE',
  REMOVE_CACHE = 'REMOVE_CACHE',
}

export interface CacheAction extends Action {
  payload: {
    entity: ResourceName;
    id?: ResourceId;
    entityData?: ResourceData;
  };
}

interface CacheState {
  [entity: string]: {
    id: ResourceId;
    entityData: ResourceData;
  };
}

// Actions
export const setCache = (entity: ResourceName, id: ResourceId, entityData: ResourceData) => ({
  type: CacheActions.SET_CACHE,
  payload: {
    entity,
    id,
    entityData,
  },
});

export const removeCache = (entity: ResourceName) => ({
  type: CacheActions.REMOVE_CACHE,
  payload: {
    entity,
  },
});

export const cacheReducer = (state: CacheState = {}, action: CacheAction) => {
  const { payload } = action;

  if (action.type === CacheActions.SET_CACHE) {
    return {
      ...state,
      [payload.entity]: {
        id: payload.id,
        entityData: payload.entityData,
      },
    };
  }

  if (action.type === CacheActions.REMOVE_CACHE) {
    const newState = { ...state };
    delete newState[payload.entity];
    return newState;
  }

  return state;
};

const useCacheService = (cacheName: string) => {
  const dispatch = useDispatch();

  const cache = useSelector((state: Dictionary<any>) => state.cache && state.cache[cacheName]);

  const addToCache = (id: ResourceId, entityData: ResourceData) => dispatch(setCache(cacheName, id, entityData));

  const replaceCache = (id: ResourceId, entityData: ResourceData) => dispatch(setCache(cacheName, id, entityData));

  const invalidateCache = () => dispatch(removeCache(cacheName));

  const getValue = (id: ResourceId) => {
    return cache && cache.id === id ? cache.entityData : null;
  };

  return {
    cache,
    addToCache,
    replaceCache,
    invalidateCache,
    getValue,
  };
};

export default useCacheService;
