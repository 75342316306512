/* istanbul ignore file */
import React, { useEffect } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { TextArea, TextInput, PhoneInput } from 'shared/uibuilder/form/input/index';
import EmployeePhotoUploaderInput from 'artifact/shared/input/EmployeePhotoUploaderInput';
import AdditionalContactListInput from 'erp/shared/input/AdditionalContactListInput/index';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { getCandidateFormData, CandidateData } from 'erp/candidate/extension/candidateFromExtensionService';
import useSkillsService from 'erp/candidate/skills/skillsService';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';

const CandidateForm = ({
  isParsingFinished,
  parsedProfileData,
  isSubmitDisabled,
}: {
  isParsingFinished?: boolean;
  isSubmitDisabled?: boolean;
  parsedProfileData: CandidateData;
}) => {
  const { searchByName } = useSkillsService();
  const { formErrors = {}, isSubmitEnabled, submitForm = () => {}, setFormData = () => {}, data } = useFormContext();

  useEffect(() => {
    if (isParsingFinished) {
      setFormData(getCandidateFormData(parsedProfileData, data as CandidateData));
    }
    // Suppressed warnings because we only need to call useEffect callback after parsing data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParsingFinished]);

  return (
    <>
      <Typography variant="h2" className="c-title mt-2 mb-2">
        Create Candidate
      </Typography>
      <p>
        Please, fill up the fields below and push &quot;Submit&quot; button to complete a candidate DaVinci profile
        creation.
      </p>
      <TextInput source="name.firstName" isVisible={!!formErrors['name.firstName']} />
      <TextInput source="name.lastName" isVisible={!!formErrors['name.lastName']} />

      <BigFormRow>
        <EmployeePhotoUploaderInput label="Photos" source="photosIds" isVisible={!!formErrors?.photosIds} />
      </BigFormRow>
      <FormRow>
        <PhoneInput source="personalPhoneNumber" isVisible={!!formErrors?.personalPhoneNumber} hasExtension={false} />
        <TextInput source="personalEmail" label="Personal Email Address" isVisible={!!formErrors.personalEmail} />
      </FormRow>

      <AdditionalContactListInput source="additionalContacts" isVisible={!!formErrors?.additionalContacts?.length} />
      <BigFormRow>
        <SkillsInput source="skills" loadSuggestionsMethod={searchByName} />
      </BigFormRow>
      <BigFormRow>
        <CandidateResumeUploaderInput
          label="Candidate resume"
          source="resume.resumeArtifactIds"
          isVisible={!parsedProfileData?.resume?.resumeArtifactIds?.length || !!formErrors['resume.resumeArtifactIds']}
        />
      </BigFormRow>
      <BigFormRow>
        <TextArea
          label="Timeline comment (Will be added to candidate timeline along with resume)"
          source="resume.description"
        />
      </BigFormRow>

      <Box sx={{ alignItems: 'center' }} className="d-flex">
        <div style={{ flexShrink: 0 }}>
          <Button
            type="submit"
            onClick={submitForm}
            disabled={!isSubmitEnabled || !isParsingFinished || isSubmitDisabled}
          >
            Submit
          </Button>
          <Button
            type="button"
            onClick={() => {
              window.parent.window.close();
            }}
            sx={{ mx: '10px' }}
          >
            Cancel
          </Button>
        </div>
        {!isParsingFinished && (
          <p className="m-0" style={{ fontSize: 11 }}>
            Please wait while we collect the candidate&apos;s profile data.
          </p>
        )}
      </Box>
    </>
  );
};

export default CandidateForm;
