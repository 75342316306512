import { EMPLOYEES_PATH, useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useKernelApi } from 'api';

export const EMPLOYEE_NEXT_REVIEW_DATE_PATH = '/careerDevelopmentReviews/nextReviews';

const useEmployeeCareerNextReviewDateService = () => {
  const employeeId = useEmployeeId() || '';
  const parentResourceUrl = `${EMPLOYEES_PATH}/${employeeId}`;
  const { sendPutRequest } = useKernelApi();

  const update = async (request: any) => {
    const response = await sendPutRequest(`${parentResourceUrl}${EMPLOYEE_NEXT_REVIEW_DATE_PATH}`, request);
    return response.json();
  };

  return {
    update,
  };
};

export default useEmployeeCareerNextReviewDateService;
