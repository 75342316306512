import moment, { Moment } from 'moment';
import useInputHelper, { InputHelperData } from './inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { useDateTimeService } from 'shared/uibuilder/dateService';

import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import { FormFieldsData } from '../FormContext';
import React from 'react';
import { ErrorMessage } from 'uibuilder/layout/form/input/BaseInputErrors';

const DATE_FORMAT = 'YYYY-MM-DD';

const cyrillicDateRegex = /^\d{1,2} ([а-яА-Я]+) \d{4}$/;

const monthsMap: Dictionary<string> = {
  января: 'january',
  февраля: 'february',
  марта: 'march',
  апреля: 'april',
  мая: 'may',
  июня: 'june',
  июля: 'july',
  августа: 'august',
  сентября: 'september',
  октября: 'october',
  ноября: 'november',
  декабря: 'december',
};

const VALID_FORMATS = ['DD.MM.YYYY', 'D MMM YYYY', 'D MMMM YYYY'];

export interface CommonDateInputProps extends DefaultInputPropTypes<string> {
  disabled?: boolean | ((values?: FormFieldsData) => boolean);
  dateFormat?: string;
  minDate?: Nullable<moment.MomentInput>;
  maxDate?: Nullable<moment.MomentInput>;
  timeFormat?: Nullable<string>;
  formatSourceDate?: Nullable<(textDate: string) => string>;
  toggleButton?: Nullable<string | React.ReactElement>;
  afterBlurCallback?: () => void;
  errorMessageMapper?: (error: ErrorMessage) => string | string[];
  readOnly?: boolean;
}

interface DateHelperData extends InputHelperData<string> {
  getDateFormat: () => string;
  getTimezone?: () => Nullable<string>;
  getTimeFormat: () => Nullable<string>;
  getMinDate: () => Nullable<moment.Moment>;
  getMaxDate: () => Nullable<moment.Moment>;
  normalizeTextDate: (dateText: string) => string;
  formatSourceDate?: Nullable<(textDate: string) => string>;
}

const useDateHelper = ({
  source,
  dateFormat,
  minDate: propsMinDate,
  maxDate: propsMaxDate,
  timeFormat = null,
  formatSourceDate,
  ...dateProps
}: CommonDateInputProps): DateHelperData => {
  const { getTimezone } = useDateTimeService();
  const inputHelper = useInputHelper({ ...dateProps, source });
  const { minDate, maxDate } = useFormValidation(source);

  const toMoment = (date?: moment.MomentInput) => {
    return date ? moment(date) : null;
  };

  const getMinDate = () => {
    return toMoment(propsMinDate || minDate());
  };

  const getMaxDate = () => {
    return toMoment(propsMaxDate || maxDate());
  };

  const getDateFormat = () => {
    return dateFormat || DATE_FORMAT;
  };

  const getTimeFormat = () => {
    return timeFormat;
  };

  const normalizeTextDate = (dateText: string) => {
    let formattedTextDate: Moment | string = dateText;
    const matched = cyrillicDateRegex.exec(dateText);

    if (matched) {
      const month = matched[1];
      formattedTextDate = formattedTextDate.replace(month, monthsMap[month.toLowerCase()]);
    }

    formattedTextDate = moment(formattedTextDate, VALID_FORMATS, true);

    if (formattedTextDate.isValid()) {
      return formattedTextDate.format(DATE_FORMAT);
    } else {
      return dateText;
    }
  };

  return {
    ...inputHelper,
    getDateFormat,
    getTimezone,
    getTimeFormat,
    getMinDate,
    getMaxDate,
    normalizeTextDate,
    formatSourceDate,
  };
};

export const COMMON_DATE_INPUT_DEFAULT_PROPS = {
  disabled: false,
  dateFormat: null,
  minDate: null,
  maxDate: null,
  timeFormat: null,
};

export default useDateHelper;
