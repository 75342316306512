/* istanbul ignore file */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  isCurrentOpportunityStage,
  isFailedOpportunityStage,
  POSTPONED,
} from 'crm/opportunity/shared/opportunityService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { sortBy } from 'lodash';
import OpportunityPipelineDescription from 'crm/opportunity/shared/OpportunityPipeline/OpportunityPipelineDescription';
import OpportunityPipelineStepper from 'crm/opportunity/shared/OpportunityPipeline/OpportunityPipelineStepper';
import './OpportunityPipeline.scss';

const OpportunityPipeline = () => {
  const { data } = useShowContext();
  const crmPipeline = data.getValueBySource('crmPipeline');
  const steps = sortBy(crmPipeline?.steps || [], 'orderPosition');
  const currentStep =
    steps.find(({ stateStatus }) => isCurrentOpportunityStage(stateStatus))?.stepName || steps[0]?.stepName;

  const isPipelineFailed = steps.some(({ stateStatus }: any) => isFailedOpportunityStage(stateStatus));

  const [activeStep, setActiveStep] = useState(currentStep);
  const activeStepData = steps.find(step => step.stepName === activeStep);
  const isPostponedNow = data.getValueBySource('stateStep') === POSTPONED;

  const handleStep = (step: string) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <OpportunityPipelineStepper
        activeStep={activeStep}
        handleStep={handleStep}
        steps={steps}
        isPostponed={isPostponedNow}
      />

      <OpportunityPipelineDescription
        currentStep={currentStep}
        activeStepData={activeStepData}
        handleStep={handleStep}
        isPipelineFailed={isPipelineFailed}
        isPostponed={isPostponedNow}
      />
    </Box>
  );
};

export default OpportunityPipeline;
