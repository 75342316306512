/* istanbul ignore file */
// utils to interact with dom. It's hard to test via unit test.
import smoothscroll from 'smoothscroll-polyfill';

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

const BODY = document.body;
const LEFT_SIDEBAR_TRANSITION_DURATION = 400;

// eslint-disable-next-line import/prefer-default-export
export const scrollToHash = async (element, offset = 95) => {
  // TODO find better way to get coordinates after transition end
  await timeout(LEFT_SIDEBAR_TRANSITION_DURATION);
  const bodyRect = BODY.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  smoothscroll.polyfill();

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
