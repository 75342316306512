import React from 'react';
import { func, bool } from 'prop-types';
import Sticky from 'react-sticky-el';
import { Button, SubmitButton } from 'uibuilder/button';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const OneStopButtonsLayout = ({ onCancelAction, isFixed }) => {
  const history = useHistory();
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  const onCancel = onCancelAction || (() => history.goBack());

  const buttons = (
    <div className="btn-area">
      <SubmitButton isLoading={!isSubmitEnabled} onClick={submitForm} disabled={isLoadingArtifact} />
      <Button
        data-testid="cancel-btn"
        outline
        type="button"
        onClick={onCancel}
        disabled={!isSubmitEnabled || isLoadingArtifact}
      >
        Cancel
      </Button>
    </div>
  );

  if (isFixed) {
    return (
      <Sticky mode="bottom" className="sticky-buttons-element">
        <div className="floating-btns">{buttons}</div>
      </Sticky>
    );
  } else {
    return <>{buttons}</>;
  }
};

OneStopButtonsLayout.propTypes = {
  isFixed: bool,
  onCancelAction: func,
};

OneStopButtonsLayout.defaultProps = {
  isFixed: true,
  onCancelAction: null,
};

export default OneStopButtonsLayout;
