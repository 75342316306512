/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService/constants';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';

interface OneStopRequestProps {
  isDescriptionVisible: boolean;
  description: string;
  id: string;
  shortDescription: Nullable<string>;
  title: string;
  lastUsedAt?: string;
}

const OneStopRequest = ({
  isDescriptionVisible,
  description,
  id,
  shortDescription,
  title,
  lastUsedAt,
}: OneStopRequestProps) => {
  const { getSingleEntityUrl } = useOneStopRequestsUrl();

  return (
    <li key={id} className="onestop-request">
      <div className="flex-grow-1">
        <Link to={getSingleEntityUrl(id)} className="c-title onestop-request__header">
          {`${id}. ${title}`}
        </Link>
        {isDescriptionVisible && (shortDescription || description) && (
          <p className="onestop-request__desc flex-grow-1 w-100">{shortDescription || description}</p>
        )}
      </div>
      {lastUsedAt && (
        <span className="onestop-request__date ml-auto flex-shrink-0">
          <ShowDate dateUTC={lastUsedAt} format={DATE_FORMAT.FULL} />
        </span>
      )}
    </li>
  );
};

export default OneStopRequest;
