import React, { useMemo } from 'react';
import List from 'shared/uibuilder/list/List';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import CreateButton from 'uibuilder/button/CreateButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { DateField, SearchLinkField, TextField } from 'shared/uibuilder/field';
import useWasteService, {
  CREATE_WASTE,
  WASTE_STATUSES_OPTIONS,
  WASTE_TYPE_OPTIONS,
  WASTE_STATUSES,
} from '../useWasteService';
import useWasteUrl from '../useWasteUrl';
import EnumField from 'shared/uibuilder/field/EnumField';
import ViewWasteButton from '../shared/button/ViewWasteButton';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import WasteStatusFilter from '../shared/filter/WasteStatusFilter';
import WasteTypeFilter from '../shared/filter/WasteTypeFilter';

const WasteList = () => {
  const { search } = useWasteService();
  const { getCreateUrl, getSingleEntityUrl } = useWasteUrl();

  const filters = useMemo(
    () => (
      <Filters>
        <FiltersLayout
          quickFilters={[
            <QuickFilter
              key="registered"
              label="All Registered Waste"
              state={{ status: { in: [WASTE_STATUSES.REGISTERED] } }}
            />,
            <QuickFilter
              key="approved"
              label="All Approved Waste"
              state={{ status: { in: [WASTE_STATUSES.APPROVED] } }}
            />,
          ]}
        >
          <WasteStatusFilter label="Status" source="status" />
          <WasteTypeFilter label="Type" source="type" />
        </FiltersLayout>
      </Filters>
    ),
    [],
  );

  return (
    <List getDataMethod={search}>
      <ListPageLayout
        header={<ListHeader label="Waste" icon="fa-folder-o" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Report Waste"
            key="create-waste"
            permissionToCheck={CREATE_WASTE}
          />,
        ]}
        filter={filters}
      >
        <DataGridLayout buttons={[<ViewWasteButton source="id" />]}>
          <SearchLinkField source="id" label="ID" url={getSingleEntityUrl} isSortable width={140} />
          <EnumField label="Type" source="type" options={WASTE_TYPE_OPTIONS} width="17%" isSortable />
          <EnumField label="Status" source="status" options={WASTE_STATUSES_OPTIONS} width="17%" isSortable />
          <DateField source="createdAt" label="Created at" width="17%" isSortable />
          <TextField source="reason" label="Reason" isSortable width="30%" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default WasteList;
