/* istanbul ignore file */
import React from 'react';
import './index.scss';

interface TreeTooltipAttributeProps {
  isVisible?: boolean;
  label: string;
  children: React.ReactNode;
}

const TreeTooltipAttribute: React.FC<TreeTooltipAttributeProps> = ({ isVisible = true, label, children }) => {
  if (isVisible) {
    return (
      <div className="rbs-tooltip-content-wrapper">
        <span className="rbs-node-label">{label}</span>
        <span className="rbs-node-value">{children}</span>
      </div>
    );
  }

  return null;
};

export default TreeTooltipAttribute;
