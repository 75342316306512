/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import {
  useEmployeePerformanceId,
  useEmployeePerformanceUrl,
} from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';

const deleteMessage = 'Do you really want to delete this Performance Result?';

const DeleteEmployeePerformanceButton = (props: any) => {
  const id = useEmployeePerformanceId();
  const { deleteById } = useEmployeePerformanceService();
  const { getListUrl } = useEmployeePerformanceUrl();

  return (
    <DeleteButton
      {...props}
      deleteMethod={() => deleteById(id)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Performance Result has been successfully deleted.',
        errorMessage: `Can't delete Performance Result.`,
      }}
    />
  );
};

export default DeleteEmployeePerformanceButton;
