/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import List from 'shared/uibuilder/list/List';
import useDistributionService from 'erp/payroll/payrollDistribution/usePayrollDistributionService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import { DATE_FORMAT } from 'shared/uibuilder/dateService/index';
import PayrollDistributionStaticFilters from 'erp/payroll/payrollDistribution/List/PayrollDistributionStaticFilters';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout/index';
import PayrollDistributionStatusField from 'erp/payroll/payrollDistribution/shared/field/PayrollDistributionStatusField';
import TextField from 'shared/uibuilder/field/TextField';
import ExpandingField from 'shared/uibuilder/field/ExpandingField';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import JournalEntryLink from 'financialAnalytic/journalEntry/shared/field/JournalEntryLink';

const PayrollDistributionListPage = () => {
  const { search } = useDistributionService();
  const currentMonth = moment().format(DATE_FORMAT.API_ONLY_MONTH);

  return (
    <List
      getDataMethod={search}
      defaultFilter={{
        period: {
          eq: currentMonth,
        },
      }}
    >
      <ListPageLayout header={<PayrollDistributionStaticFilters title={<ListHeader label="Payroll Distribution" />} />}>
        <DataGridLayout>
          <TextField source="period" />
          <EmployeeLinkFieldWithNameLoading source="employeeAlias" />
          <TextField source="sourceTransferId" label="Source Transfer ID" />
          <TextField source="costCenterReportId" label="Cost Center Report ID" />
          <JournalEntryLink source="journalEntryId" label="Journal Entry ID" />
          <PayrollDistributionStatusField source="status" width={140} />
          <ExpandingField source="skippedReason" label="Skipped Reason" width={260} maxValueLength={124}>
            <TextField source="skippedReason" />
          </ExpandingField>
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default PayrollDistributionListPage;
