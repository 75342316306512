/* istanbul ignore file */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useProjectRoute, useProjectUrl } from 'erp/project/ProjectRouter';
import ViewProjectDashboard from './list';
import { READ_PROJECT_DASHBOARD } from './ProjectDashboardService';

export const PATH = '/dashboard';

export const useProjectDashboardRoute = () => {
  const { singleEntityRoute: projectRoute } = useProjectRoute();
  return useRoute({
    listRoute: `${projectRoute}${PATH}`,
  });
};

export const useProjectDashboardUrl = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const projectLink = getSingleEntityUrl();
  const listUrl = `${projectLink}${PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${projectLink}${PATH}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

const ProjectDashboardRouter = () => {
  const { listRoute } = useProjectDashboardRoute();

  return (
    <Switch>
      <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_PROJECT_DASHBOARD}>
        <ViewProjectDashboard />
      </GlobalProtectedRoute>
      <Route component={Page404} />
    </Switch>
  );
};

export default ProjectDashboardRouter;
