import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledSwitch } from 'uibuilder/layout/form/input/TogglerInputLayout';
import usePurchaseOrderSettings from 'financialAnalytic/purchaseOrder/shared/Settings/usePurchaseOrderSettings';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const PurchaseOrderDeliveredInvoicesToggler = () => {
  const { areDeliversInvoicesVisible, setDeliversInvoicesVisible } = usePurchaseOrderSettings();
  const { loading } = useListContext();

  const onChangeCallback = (event: any) => {
    setDeliversInvoicesVisible(event.target.checked);
  };

  return (
    <FormControlLabel
      className="settings__item"
      control={
        <StyledSwitch
          className="settings__switch"
          color="primary"
          onChange={onChangeCallback}
          checked={areDeliversInvoicesVisible}
          dataOn="ON"
          dataOff="OFF"
          disabled={loading}
        />
      }
      label="Show delivered invoices"
    />
  );
};

export default PurchaseOrderDeliveredInvoicesToggler;
