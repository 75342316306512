/* istanbul ignore file */
import React, { useEffect } from 'react';
import useReconciliationService from 'erp/costaccounting/reconcilation/useReconciliationService';
import { SensitiveListElementLayout } from 'shared/uibuilder/sensitiveData';
import { useReconcilationUrl } from 'erp/costaccounting/reconcilation/ReconciliationRouter';
import ReconciliationListPageContent from 'erp/costaccounting/reconcilation/List/ReconciliationListPageContent';
import useListDataLoading from 'shared/uibuilder/list/builder/useListDataLoading';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { InputData } from 'shared/uibuilder/list/builder/useListCrud';

export type UpdateListDataAndReloadGroupsProps = {
  newBankData: InputData;
  newDavinciData: InputData;
};

const ReconciliationListPage = () => {
  const {
    getSensitiveDataByIds,
    searchAvailableBankTransactions,
    searchAvailableJournalEntries,
  } = useReconciliationService();
  const { getListUrl } = useReconcilationUrl();
  const { filter, reloadData = () => {} } = useListContext();
  const {
    data: davinciData,
    loading: davinciLoading,
    handleLoad: handleDavinciLoad = () => {},
    handleSetData: handleSetDavinciData = () => {},
  } = useListDataLoading({
    getDataMethod: searchAvailableJournalEntries,
    params: { filter },
  });
  const {
    data: bankData,
    loading: bankLoading,
    handleLoad: handleBankLoad = () => {},
    handleSetData: handleSetBankData = () => {},
  } = useListDataLoading({
    getDataMethod: searchAvailableBankTransactions,
    params: { filter },
  });

  const updateListDataAndReloadGroups = ({ newBankData, newDavinciData }: UpdateListDataAndReloadGroupsProps) => {
    handleSetDavinciData(newDavinciData);
    handleSetBankData(newBankData);
    reloadData();
  };

  const loadNotReconciliatedItemsData = () => {
    handleDavinciLoad();
    handleBankLoad();
  };

  useEffect(() => {
    loadNotReconciliatedItemsData();
    // Suppressed warnings because we only need to call useEffect callback if filters are changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.period, filter?.office]);

  return (
    <SensitiveListElementLayout
      isSensitiveDataIsShown={!davinciData.items && !bankData.items}
      shouldShowSensitiveDataButton={!!davinciData.items?.length || !!bankData.items?.length}
      sensitiveDataIdSource="id"
      loadSensitiveDataMethod={getSensitiveDataByIds as any}
      timerId={getListUrl()}
      getSensitiveDataIds={(items: any) =>
        items?.reduce(
          ([bankTransactionIds, davinciTransferIds]: string[][], item: Dictionary<any>) => [
            [...bankTransactionIds, ...item.bankTransactions?.map(({ id }: any) => id)],
            [...davinciTransferIds, ...item.davinciTransfers?.map(({ id }: any) => id)],
          ],
          [bankData.items?.map(item => item.id) || [], davinciData.items?.map(item => item.id) || []],
        ) as any
      }
    >
      <ReconciliationListPageContent
        bankData={bankData}
        bankLoading={bankLoading}
        davinciData={davinciData}
        davinciLoading={davinciLoading}
        reloadData={() => {
          loadNotReconciliatedItemsData();
          reloadData();
        }}
        updateListDataAndReloadGroups={updateListDataAndReloadGroups}
      />
    </SensitiveListElementLayout>
  );
};

export default ReconciliationListPage;
