/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useProcessDefinitionUrl } from 'camunda/monitoring/definition/ProcessDefinitionRouter';
import { LinkField } from 'shared/uibuilder/field';

// @ts-ignore
const ProcessDefinitionLinkField = ({ source, ...props }) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getSingleEntityUrl } = useProcessDefinitionUrl();
  const id = getId();
  const url = getSingleEntityUrl(id);

  return <LinkField {...props} linkText={id} value={url} isInternal />;
};

export default ProcessDefinitionLinkField;
