/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const FEEDBACK_ON_BEHALF_OF_ALIAS = {
  MYSELF: 'MYSELF',
  ANOTHER_EMPLOYEE: 'ANOTHER_EMPLOYEE',
  EXTERNAL_USER: 'EXTERNAL_USER',
};

export const FEEDBACK_ON_BEHALF_OF = {
  [FEEDBACK_ON_BEHALF_OF_ALIAS.MYSELF]: 'Myself',
  [FEEDBACK_ON_BEHALF_OF_ALIAS.ANOTHER_EMPLOYEE]: 'Another colleague',
  [FEEDBACK_ON_BEHALF_OF_ALIAS.EXTERNAL_USER]: 'External user',
};

const FeedbackOnBehalfOfDropdown = (props: any) => {
  return <EnumDropdown {...props} options={FEEDBACK_ON_BEHALF_OF} />;
};

export default FeedbackOnBehalfOfDropdown;
