import React from 'react';
import ExpenseBreadcrumbs from '../shared/ExpenseBreadcrumbs';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UploadExpenseBreadcrumbs = () => {
  return (
    <ExpenseBreadcrumbs>
      <BreadcrumbItem>Upload Expense</BreadcrumbItem>
    </ExpenseBreadcrumbs>
  );
};

export default UploadExpenseBreadcrumbs;
