/* istanbul ignore file */
import React from 'react';
import { TextInput } from 'shared/uibuilder/form/input';
import RelationInput from 'erp/employee/shared/input/RelationInput';

const FamilyMemberInput = props => {
  return <RelationInput relationInput={TextInput} {...props} />;
};

export default FamilyMemberInput;
