/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import VacancyPublicationForm from 'erp/recruitment/vacancy/publication/createupdate/VacancyPublicationForm';
import useVacancyPublicationService from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import CreateVacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/createupdate/Create/Breadcrumbs';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';

const CreateVacancyPublication = () => {
  const { createVacancy, getValidationSchema } = useVacancyPublicationService();
  const { getListUrl } = useVacancyPublicationUrl();

  return (
    <CreateForm
      submitFormFunc={createVacancy}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Vacancy Publication has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Vacancy Publication" breadcrumbs={<CreateVacancyPublicationBreadcrumbs />}>
        <VacancyPublicationForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateVacancyPublication;
