import ShowPageLayout from 'shared/layout/ShowPageLayout';
import CorporateEventHeader from 'financialAnalytic/corporateEvents/shared/CorporateEventHeader';
import SingleCorporateEventBreadcrumbs from 'financialAnalytic/corporateEvents/shared/SingleCorporateEventBreadcrumbs';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import TextField from 'shared/uibuilder/field/TextField';
import CorporateEventStatusField from 'financialAnalytic/corporateEvents/shared/field/CorporateEventStatusField';
import CorporateEventLocationField from 'financialAnalytic/corporateEvents/shared/field/CorporateEventLocationField';
import CorporateEventTypeField from 'financialAnalytic/corporateEvents/shared/field/CorporateEventTypeField';
import DateField from 'shared/uibuilder/field/DateField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import List from 'shared/uibuilder/list/List';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import NameField from 'shared/uibuilder/field/NameField';
import EmailField from 'shared/uibuilder/field/EmailField';
import useCorporateEventService, {
  UPDATE_CORPORATE_EVENT,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';
import EditCorporateEventButton from 'financialAnalytic/corporateEvents/shared/button/EditCorporateEventButton';
import useAuthorization from 'shared/authorization/authorizationService';
import CorporateEventMenu from 'financialAnalytic/corporateEvents/shared/CorporateEventMenu';

const ViewCorporateEventPageContent = () => {
  const { searchParticipants } = useCorporateEventService();
  const { isGranted } = useAuthorization();

  return (
    <ShowPageLayout
      entityHeader={<CorporateEventHeader />}
      breadcrumbs={<SingleCorporateEventBreadcrumbs />}
      headerButtons={[<EditCorporateEventButton isVisible={isGranted(UPDATE_CORPORATE_EVENT)} />]}
      menu={<CorporateEventMenu />}
      hasSeparateSections
    >
      <ShowSection title="Corporate Event Details">
        <SectionRow>
          <TextField source="name" label="Name" />
          <TextField source="description" label="Description" />
          <CorporateEventStatusField source="status" label="Status" />
          <CorporateEventLocationField source="location" label="Location" />
          <CorporateEventTypeField source="type" label="Type" />
          <DateField dateFormat={DATE_FORMAT.FULL_DATE} source="date" label="Event date" />
          <FullDateField source="createdAt" label="Created Date" />
          <FullDateField source="updatedAt" label="Last Modified Date" />
        </SectionRow>
      </ShowSection>

      <ShowSection title="Participants">
        <List getDataMethod={searchParticipants} defaultSortField="nameEn.lastName" defaultSortOrder="ASC">
          <DataGridLayout>
            <EmployeeLinkField nameSource="alias" source="alias" isSortable />
            <NameField source="nameEn" label="Employee name" isSortable />
            <EmailField source="workingEmail" label="Email" isSortable />
          </DataGridLayout>
        </List>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewCorporateEventPageContent;
