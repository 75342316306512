import React from 'react';
import useTrainingsService from '../../useTrainingsService';
import { useTrainingsUrl } from '../../TrainingsRouter';
import TrainingForm from '../TrainingForm';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateTrainingBreadcrumbs from './UpdateTrainingBreadcrumbs';

const UpdateTrainingPage = () => {
  const { getValidationSchema, update, getById } = useTrainingsService();
  const { getSingleEntityUrl } = useTrainingsUrl();

  const getDataFunc = async (id: string) => {
    const data = await getById(id);

    return {
      ...data,
      trainingId: data.id,
      id: undefined,
      competencies: data.competencies.map((competency: { name: any }) => competency.name),
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      afterSubmit={{
        redirect: () => getSingleEntityUrl(),
        message: 'You are awesome! The Training has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      getDataFunc={getDataFunc}
    >
      <UpdatePageLayout title="Edit: Training" breadcrumbs={<UpdateTrainingBreadcrumbs />}>
        <TrainingForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateTrainingPage;
