/* istanbul ignore file */
import React from 'react';
import LeadsBreadcrumbs from 'crm/lead/shared/LeadsBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

/**
 * Returns lead create pages breadcrumbs.
 * @returns {*}
 * @constructor
 */
const CreateLeadBreadcrumbs = () => (
  <LeadsBreadcrumbs>
    <CreateBreadcrumbItem entity="Lead" />
  </LeadsBreadcrumbs>
);

export default CreateLeadBreadcrumbs;
