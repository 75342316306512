/* istanbul ignore file */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useBudgetService, { DELETE_BUDGET } from 'erp/budget/shared/budgetService';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import { Checkbox } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { HTTP } from 'shared/api/const';
import 'erp/budget/shared/button/DeleteBudgetButtonCheckbox.scss';

const deleteMessage = 'Do you really want to delete this budget?';

const DeleteBudgetButton = () => {
  const { id: budgetId } = useParams<Dictionary<string>>();
  const { deleteBudget } = useBudgetService();
  const { getListUrl } = useBudgetUrl();
  const [withChildBudgets, setWithChildBudgets] = useState(false);

  const onCheckboxChange = (values: FormFieldsData) => {
    setWithChildBudgets(values.withChildBudgets);
  };

  return (
    <DeleteButton
      modalChildren={
        <div className="mt-3 budget-delete-checkbox">
          <Checkbox
            source="withChildBudgets"
            value={withChildBudgets}
            label="Remove child budgets too"
            onChangeCallback={onCheckboxChange}
          />
        </div>
      }
      deleteMethod={() => deleteBudget(budgetId, withChildBudgets)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Budget has been successfully deleted.',
        errorMessage: `Can't delete budget`,
        errorStatusesMessages: {
          [HTTP.BAD_REQUEST]: 'Budget assigned to a Project cannot be deleted.',
        },
      }}
      permissionToCheck={DELETE_BUDGET}
    />
  );
};

export default DeleteBudgetButton;
