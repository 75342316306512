/* istanbul ignore file */
import React from 'react';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import { UpdateForm } from 'shared/uibuilder/form';
import useEmployeeContractsService from 'erp/employee/contracts/shared/employeeContractsService';
import { useEmployeeContractId, useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';
import EmployeeContractForm from 'erp/employee/contracts/createupdate/EmployeeContractForm';
import UpdateEmployeeContractBreadCrumbs from 'erp/employee/contracts/createupdate/Update/Breadcrumbs';
import useContractsErrorMap from '../../errorHandlers';
import ContractNotStrictRulesHandler from '../../shared/ContractNotStictRulesHandler';
import { useShowContext } from '../../../../../shared/uibuilder/show/ShowContext';
import useCacheService from 'shared/cache/cacheService';
import { EMPLOYEES_PATH } from 'erp/employee/EmployeesRouter';

const UpdateEmployeeContract = () => {
  const { update, getById, getValidationSchema } = useEmployeeContractsService();
  const { getListUrl } = useEmployeeContractUrl();
  const id = useEmployeeContractId();
  const errorsMap = useContractsErrorMap();
  const { data } = useShowContext();
  const { invalidateCache } = useCacheService(EMPLOYEES_PATH);

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      entityId={id}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The contract has been successfully saved.',
        execute: () => {
          invalidateCache();
        },
      }}
      getValidationSchemaFunc={getValidationSchema}
      errorsMap={errorsMap}
    >
      <ContractNotStrictRulesHandler errorMap={errorsMap} />
      <UpdatePageLayout
        title="Edit: Contract"
        breadcrumbs={<UpdateEmployeeContractBreadCrumbs />}
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <EmployeeContractForm hasAnchorLinks employeeAlias={data.alias} />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateEmployeeContract;
