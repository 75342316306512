/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useRbsUrl } from 'erp/rbs/RbsRouter';

const RbsNodeLink = ({ id, name, isClickable = true }: { id: StringOrNumber; name: string; isClickable?: boolean }) => {
  const { getSingleEntityUrl } = useRbsUrl();

  return isClickable ? (
    <Link to={getSingleEntityUrl(id)} target="_blank">
      {name}
    </Link>
  ) : (
    <span>{name}</span>
  );
};

export default RbsNodeLink;
