/* istanbul ignore file */
import React from 'react';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { ResourceData } from 'shared/crud/baseCrudService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { IconButton } from 'uibuilder/button';
import ReplyIcon from '@mui/icons-material/ReplyOutlined';
import { scrollToHash } from 'shared/uibuilder/domUtils';
import emailCommunicationRegistry from 'erp/candidate/communication/email/emailCommunicationRegistry';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const SCROLL_OFFSET = 100;
const DEFAULT_FUNCTION = () => {};

const ReplyButton = () => {
  const {
    addForm,
    setAddForm = DEFAULT_FUNCTION,
    createTimelineItemMethod = DEFAULT_FUNCTION,
    addToList = DEFAULT_FUNCTION,
    wrapItem = () => ({}),
  }: TimelineContextData = useListContext();
  const { data: entity } = useShowContext();
  const canReply = entity.getValueBySource('canReply');
  const inReplyToId = entity.getValueBySource('id');

  const closeAddForm = () => setAddForm(null);

  const reply = () => {
    const { AddForm, source, urlPostfix } = emailCommunicationRegistry() as any;
    const pageTop = document.querySelector('.show-page__content-inner .page-content');

    scrollToHash(pageTop, SCROLL_OFFSET);

    const form = (
      <AddForm
        onCancel={closeAddForm}
        submitFormFunc={(data: ResourceData) => createTimelineItemMethod(urlPostfix, data)}
        afterSubmit={{
          execute: (data: TimelineItem) => {
            addToList(wrapItem(data, source));
            closeAddForm();
          },
        }}
        initialData={{
          inReplyToId,
        }}
      />
    );

    setAddForm(form);
  };

  if (addForm || !canReply) {
    return null;
  }

  return (
    <IconButton title="Reply" onClick={reply} sx={{ p: '3px' }}>
      <ReplyIcon sx={{ fontSize: '1.4rem' }} />
    </IconButton>
  );
};

export default ReplyButton;
