/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField from 'shared/uibuilder/field/LinkField';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import { FieldProps } from 'shared/uibuilder/field/index';

const JournalEntryLink = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getSingleEntityUrl } = useJournalEntryUrl();
  const id = getValue();

  return <LinkField linkText={id} isInternal value={id ? getSingleEntityUrl(id) : null} {...props} />;
};

export default JournalEntryLink;
