/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Show from 'shared/uibuilder/show/Show';
import useEmployeeContractsService from 'erp/employee/contracts/shared/employeeContractsService';
import { useEmployeeContractId } from 'erp/employee/contracts/EmployeeContractsRouter';
import EditEmployeeContractButton from 'erp/employee/contracts/shared/button/EditEmployeeContractButton';
import DeleteEmployeeContractButton from 'erp/employee/contracts/shared/button/DeleteEmployeeContractButton';
import SingleContractsBreadcrumbs from 'erp/employee/contracts/shared/SingleContractsBreadcrumbs';
import { ContractsViewFields } from 'erp/contracts';

const ViewEmployeeContract = () => {
  const { getById } = useEmployeeContractsService();
  const id = useEmployeeContractId();

  return (
    <ShowPageLayout
      buttons={[<EditEmployeeContractButton />, <DeleteEmployeeContractButton />]}
      menu={<EmployeeMenu />}
      entityHeader={<EmployeeEntityHeader />}
      breadcrumbs={<SingleContractsBreadcrumbs />}
    >
      <Show getDataMethod={() => getById(id)}>
        <ShowSection title="View: Contract">
          <ContractsViewFields />
        </ShowSection>
      </Show>
    </ShowPageLayout>
  );
};

export default ViewEmployeeContract;
