/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field';

const BankTransactionDescriptionField = ({ source, vendorSource, ...props }: FieldProps & { vendorSource: string }) => {
  const { getValue: getTitle } = useFieldHelper({ source });
  const { getValue: getVendor } = useFieldHelper({ source: vendorSource });
  const title = getTitle() as any;
  const vendor = getVendor() as any;

  return <TextField {...props} source={source} value={[title, vendor].filter(Boolean).join(', ')} />;
};

export default BankTransactionDescriptionField;
