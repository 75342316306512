/* istanbul ignore file */
import React from 'react';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const CandidateAccessLogBreadCrumbs = () => {
  return (
    <SingleEmployeeBreadcrumbs>
      <BreadcrumbItem tag="span" active exact>
        Candidate Access Log
      </BreadcrumbItem>
    </SingleEmployeeBreadcrumbs>
  );
};

export default CandidateAccessLogBreadCrumbs;
