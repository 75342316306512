/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { Button } from 'uibuilder/button';
import useAuthorization from 'shared/authorization/authorizationService';
import { CREATE_EMPLOYEE_COMMON_SKILL } from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';
import AddEmployeeCommonSkillModalForm from 'erp/employee/commonSkills/shared/AddEmployeeCommonSkillModalForm';

const AddEmployeeCommonSkillButton = () => {
  const { isGranted } = useAuthorization();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  if (!isGranted(CREATE_EMPLOYEE_COMMON_SKILL)) {
    return null;
  }

  return (
    <>
      <Button className="instance-feedback-button" onClick={openCloseIssueForm}>
        <span>Add Employee Common Skill</span>
      </Button>
      {isFormOpen && <AddEmployeeCommonSkillModalForm closeModal={closeModal} />}
    </>
  );
};

export default AddEmployeeCommonSkillButton;
