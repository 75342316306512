/* istanbul ignore file */
import React from 'react';
import EmployeeNoteTagDropdown from '../input/EmployeeNoteTagDropdown';
import DropDownFilter from '../../../../../shared/uibuilder/list/filter/DropDownFilter';

const EmployeeTimelineNoteTagFilter = props => {
  return (
    <DropDownFilter {...props} isSingle>
      <EmployeeNoteTagDropdown />
    </DropDownFilter>
  );
};

export default EmployeeTimelineNoteTagFilter;
