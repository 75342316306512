/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { string } from 'prop-types';
import { CountryDropdown, DateInput, TextInput } from 'shared/uibuilder/form/input';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';
import PassportNumberInput from 'erp/employee/shared/input/PassportNumberInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { DOCUMENTS_ALIAS } from '../../employeeService';
import DocumentsDropdown from './DocumentsDropdown';
import './DocumentInput.scss';

const DocumentInput = ({ source, value }) => {
  const isPesel = value.type === DOCUMENTS_ALIAS.PESEL;
  return (
    <div className="document-input-wrapper">
      <FormRow>
        <DocumentsDropdown source={`${source}.type`} label="Document type" />
      </FormRow>
      {value.type ? (
        <>
          <FormRow>
            <PassportNumberInput source={source} isPesel={isPesel} />
            <TextInput
              source={`${source}.documentDetails.personalIdNumber`}
              label="Identification Number"
              isVisible={!isPesel}
            />
            <CountryDropdown source={`${source}.documentDetails.citizenship`} label="Citizenship" />
            <DateInput source={`${source}.documentDetails.issuedAt`} label="Date of Issue" isVisible={!isPesel} />
            <DateInput source={`${source}.expiredAt`} label="Expiry Date" isVisible={!isPesel} />
            <TextInput source={`${source}.documentDetails.issuedBy`} label="Issued by" isVisible={!isPesel} />
            <TextInput source={`${source}.documentDetails.surname`} label="Surname" isVisible={!isPesel} />
            <TextInput source={`${source}.documentDetails.givenName`} label="Given Name" isVisible={!isPesel} />
          </FormRow>
          <BigFormRow hidden={isPesel}>
            <DocumentScanUploaderInput label="Document Scans" source={`${source}.scansIds`} />
          </BigFormRow>
        </>
      ) : null}
    </div>
  );
};

DocumentInput.propTypes = {
  source: string.isRequired,
  type: string.isRequired,
};

export default DocumentInput;
