import React from 'react';
import TextInput from './TextInput';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import CountryDropdown from 'shared/uibuilder/form/input/CountryDropdown';

export interface LocationInputProps {
  source: string;
  countryLabel?: string;
  cityLabel?: string;
  className?: TypeOrFunction<string>;
  children?: React.ReactNode;
}

type AddressInputLayoutType = ReactComponent<any>;

/**
 * Component is used to display the controls for the form for location entering.
 *
 * @param source
 * @param countryLabel custom country label
 * @param cityLabel custom city label
 * @param className className
 * @param children
 * @returns {*}
 * @constructor
 */
const LocationInput = ({ source, countryLabel, cityLabel, className = '', children }: LocationInputProps) => {
  const { AddressInputLayout } = useUiTheme<AddressInputLayoutType>();
  const citySource = `${source}.city`;
  const countrySource = `${source}.country`;

  return (
    <AddressInputLayout>
      <CountryDropdown data-testid="country" label={countryLabel} source={countrySource} className={className} />
      <TextInput data-testid="city" label={cityLabel} source={citySource} className={className} />
      {children}
    </AddressInputLayout>
  );
};

export default LocationInput;
