import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { MAX_MD_WIDTH, MAX_SM_WIDTH } from 'oneStop/deviceDetectorService';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import Box from 'uibuilder/Box';
import ProjectsSettings from './ProjectsSettingsFilter/ProjectsSettings';
import useProjectsSettings from './ProjectsSettingsFilter/useProjectsSettings';

const ProjectsStaticFilters = ({
  title,
  sx = {},
  isInListHeaderWrapper = false,
}: {
  title?: React.ReactNode;
  sx?: SxProps<Theme>;
  isInListHeaderWrapper?: boolean;
}) => {
  const { goToPage } = useListContext();
  const { areArchivedProjectsVisible } = useProjectsSettings();

  useDidUpdateEffect(() => {
    if (goToPage) {
      goToPage(0);
    }
  }, [areArchivedProjectsVisible]);

  return (
    <Box
      sx={{
        [`@media (min-width: ${isInListHeaderWrapper ? MAX_MD_WIDTH : MAX_SM_WIDTH}px)`]: {
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        },
        '& [data-testId="remove-btn"], & .filters-reset-btn': {
          display: 'none',
        },
        '.button-dropdown__toggle': {
          minHeight: '36px',
          padding: '5px 12px',
        },
        '.filter-item-wrapper': {
          marginRight: 0,
        },
        '.dropdown-toggle': {
          marginLeft: '8px',
          transform: 'translateY(-2px)',
        },
        ...sx,
      }}
    >
      {title}
      <Box
        sx={
          isInListHeaderWrapper
            ? {
                [`@media (min-width: ${MAX_MD_WIDTH}px)`]: {
                  marginLeft: '20px',
                },
              }
            : undefined
        }
      >
        <ProjectsSettings />
      </Box>
    </Box>
  );
};

export default ProjectsStaticFilters;
