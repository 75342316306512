import Autolinker from 'autolinker';

// eslint-disable-next-line import/prefer-default-export
export const replaceUrlWithLink = (urlString: Nullable<string>) => {
  if (typeof urlString !== 'string') {
    return null;
  }

  return Autolinker.link(urlString, { stripPrefix: false });
};
