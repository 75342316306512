/* istanbul ignore file */
import React from 'react';
import SingleAccountBreadcrumbs from 'crm/account/shared/SingleAccountBreadcrumbs';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const AccountRelatedOpportunitiesBreadcrumbs = () => {
  return (
    <SingleAccountBreadcrumbs>
      <BreadcrumbItem>Related Opportunities</BreadcrumbItem>
    </SingleAccountBreadcrumbs>
  );
};

export default AccountRelatedOpportunitiesBreadcrumbs;
