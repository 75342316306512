import momentTimezone from 'moment-timezone';
import { FilterCondition, useFilterContext } from './FilterContext';
import moment from 'moment';
import { DEFAULT_TIMEZONE, useDateTimeService } from 'shared/uibuilder/dateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService/constants';
import useAsyncValue from 'shared/asyncHelper';

export const FILTER_DATE_ERROR = 'Please choose valid date in past';
export const FILTER_DATE_REQUIRED_ERROR = 'Field is required';

const useMonthPickerFilter = (dateFormat: string, canBeInFuture: boolean, source: string) => {
  const { getTimezone, getDateInCurrentTimezone } = useDateTimeService();
  const timezone = useAsyncValue<string>(getTimezone as any) || DEFAULT_TIMEZONE;
  const { getFilterErrors, setFilterErrors } = useFilterContext();

  const getMaxDate = () => {
    return canBeInFuture ? null : momentTimezone.tz(moment(), timezone).endOf('month');
  };

  const validateAndShowErrorMessages = (inputName: FilterCondition, inputValue: string, isRequired?: boolean) => {
    if (isRequired && !inputValue) {
      setFilterErrors(source, inputName, [FILTER_DATE_REQUIRED_ERROR]);
      return true;
    }

    const date = momentTimezone.tz(inputValue, dateFormat, timezone);
    const isValid =
      !inputValue ||
      (date.isValid() &&
        (canBeInFuture || date.isSameOrBefore(getMaxDate())) &&
        moment(inputValue, dateFormat, true).isValid());
    const showErrors = !isValid;
    if (showErrors) {
      setFilterErrors(source, inputName, [FILTER_DATE_ERROR]);
    } else {
      setFilterErrors(source, inputName, []);
    }
    return showErrors;
  };

  const fromApiToFrontFormat = (apiTextDate: string) => {
    const isValidDateTimeFormat = momentTimezone(apiTextDate, DATE_FORMAT.API_ONLY_MONTH, true).isValid();

    if (isValidDateTimeFormat) {
      return getDateInCurrentTimezone(apiTextDate).format(dateFormat);
    }

    return apiTextDate;
  };

  const getErrors = (key: FilterCondition) => getFilterErrors(source, key);

  const formatDateForAPI = (dateText: string) => {
    return moment(dateText, dateFormat).format(DATE_FORMAT.API_ONLY_MONTH);
  };

  return {
    validateAndShowErrorMessages,
    fromApiToFrontFormat,
    getErrors,
    getMaxDate,
    formatDateForAPI,
  };
};

export default useMonthPickerFilter;
