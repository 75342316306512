/* istanbul ignore file */
import React from 'react';
import { CandidateDuplicate } from 'erp/candidate/shared/candidateService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { Dropdown } from 'shared/uibuilder/form/input';

interface CandidateDuplicateDropdownProps extends DefaultDropdownProps {
  duplicates: CandidateDuplicate[];
}

const CandidateDuplicateDropdown = ({ duplicates, ...props }: CandidateDuplicateDropdownProps) => {
  return (
    <Dropdown
      placeholder="Choose Candidate from found duplicates"
      options={duplicates.map(duplicate => ({
        value: duplicate.candidate.id,
        label: `${duplicate.candidate.name.fullName} (id: ${duplicate.candidate.id})`,
      }))}
      {...props}
    />
  );
};

export default CandidateDuplicateDropdown;
