/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAccountUrl } from 'crm/account/index';
import { element } from 'prop-types';

const AccountsBreadcrumbs = ({ children }) => {
  const { getListUrl } = useAccountUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Accounts" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

AccountsBreadcrumbs.propTypes = {
  children: element.isRequired,
};

export default AccountsBreadcrumbs;
