/* istanbul ignore file */
import React from 'react';
import {LEAD_GRADE} from 'crm/lead/shared/leadService';
import useDropdownHelper from 'shared/uibuilder/form/input/dropdownHelper';
import Dropdown from "shared/uibuilder/form/input/Dropdown";

const LeadGradeDropdownWithDetails = props => {
    const dropdownHelper = useDropdownHelper(props);

    return (
        <div>
            <Dropdown
                placeholder="Choose Lead Grade"
                options={Object.entries(LEAD_GRADE).map(entry => {
                    const [value, gradeObject] = entry;
                    const { name, shorthand } = gradeObject;

                    return {
                        value,
                        label: `${shorthand} (${name})`,
                    };
                })}
                label="Lead Grade"
                {...props}
            />
            {dropdownHelper.getValue() ? (
                <div>
                    <ls><b>The following items must comply with the selected option:</b>
                        {LEAD_GRADE[dropdownHelper.getValue()].criterias.map(entry => {
                            return <li><i>{entry}</i></li>
                        })}
                    </ls>
                    <br/>
                </div>
            ) : null}
        </div>
    );
};

export default LeadGradeDropdownWithDetails;
