/* istanbul ignore file */
import React from 'react';
import BudgetBreadcrumbs from 'erp/budget/shared/BudgetBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateBudgetBreadcrumbs = () => {
  return (
    <BudgetBreadcrumbs>
      <CreateBreadcrumbItem entity="Budget" />
    </BudgetBreadcrumbs>
  );
};

export default CreateBudgetBreadcrumbs;
