import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import 'uibuilder/CircularProgressWithLabel/CircularProgressWithLabel.scss';
import { OverridableStringUnion } from '@mui/types';
import { CircularProgressPropsColorOverrides } from '@mui/material/CircularProgress/CircularProgress';

interface CircularProgressWithLabelProps extends Omit<CircularProgressProps, 'value'> {
  value: number;
  getColorFunc: (
    value: number,
  ) => OverridableStringUnion<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
    CircularProgressPropsColorOverrides
  >;
}

const CircularProgressWithLabel = ({ value, getColorFunc, ...props }: CircularProgressWithLabelProps) => {
  const progressColor = getColorFunc(value);
  const label = `${value}%`;

  return (
    <div className="circular-progress-with-label">
      <CircularProgress
        className="circular-progress-with-label__background"
        variant="determinate"
        size="100%"
        value={100}
        {...props}
      />
      <CircularProgress
        className="circular-progress-with-label__progress"
        variant="determinate"
        size="100%"
        value={value}
        color={progressColor}
        {...props}
      />
      <div className="circular-progress-with-label__label">
        <Typography variant="h2">{label}</Typography>
      </div>
    </div>
  );
};

export default CircularProgressWithLabel;
