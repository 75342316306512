import { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import useHeaderMenuService, { HeaderMenuItemProps } from 'shared/uibuilder/menu/HeaderMenu/headerMenuService';

export const MENU_PADDING = 40;
export const MENU_MARGIN = 40;

const useDesktopHeaderMenuItemsService = () => {
  const { getMenuData } = useHeaderMenuService();
  const data: HeaderMenuItemProps[] = useMemo(() => getMenuData() || [], [getMenuData]);
  const [visibleTabIndices, setVisibleTabIndices] = useState<string[]>([]);
  const [measuringRender, setMeasuringRender] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    setMeasuringRender(true);
  }, []);

  useLayoutEffect(() => {
    if (measuringRender && !visibleTabIndices.length) {
      const tabElements: HTMLElement[] = Array.from((containerRef?.current as any)?.children || []);
      let stopWidth = MENU_PADDING;
      const visible: string[] = [];
      const activeTab = tabElements.find(tab => tab?.querySelector('a.active'));
      const inactiveItems = tabElements.filter(tab => tab.id !== activeTab?.id);

      if (activeTab) {
        stopWidth += activeTab.getBoundingClientRect().width;
        visible.push(activeTab.id);
      }

      inactiveItems.forEach(tab => {
        stopWidth += tab.getBoundingClientRect().width;
        if ((containerRef.current as any)?.parentNode.getBoundingClientRect().width - MENU_MARGIN >= stopWidth) {
          visible.push(tab.id);
        }
      });

      setVisibleTabIndices(visible);
      setMeasuringRender(false);
    }
  }, [measuringRender, visibleTabIndices]);

  useEffect(() => {
    function handleResize() {
      setVisibleTabIndices([]);
      setMeasuringRender(true);
    }

    window.addEventListener('resize', handleResize);

    return function cleanUp() {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (data.length) {
      setVisibleTabIndices([]);
      setMeasuringRender(true);
    }
  }, [data]);

  const overflowTabs = visibleTabIndices.length ? data.filter(({ url }) => !visibleTabIndices.includes(url)) : [];
  const visibleTabs = visibleTabIndices.length ? data.filter(({ url }) => visibleTabIndices.includes(url)) : data;

  return {
    overflowTabs,
    visibleTabs,
    containerRef,
  };
};

export default useDesktopHeaderMenuItemsService;
