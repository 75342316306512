/* istanbul ignore file */
import React from 'react';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button/index';
import PayrollReportStateToggler from 'erp/payroll/generatedPayrolls/shared/Settings/PayrollReportStateToggler';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';

const PayrollReportSettings = () => {
  return (
    <DropdownMenu
      popupId="payroll-reports-settings"
      iconButton={
        <IconButton className="dropdown-toggle">
          <SettingsIcon />
        </IconButton>
      }
    >
      <PayrollReportStateToggler />
    </DropdownMenu>
  );
};

export default PayrollReportSettings;
