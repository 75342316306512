import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';

export const PAYROLL_DISTRIBUTION_PATH = '/payroll-distribution';
export const READ_PAYROLL_DISTRIBUTION = 'EMPLOYEE_READ_FINANCE_UNSENSITIVE_METRICS';
export const PERFORM_EXPENSE_DISTRIBUTION = 'COMPANY_PERFORM_EXPENSE_DISTRIBUTION';
export const PAYROLL_DISTRIBUTION_URL = '/accounting-cycle/distribute-expenses';

export const PAYROLL_DISTRIBUTION_STATUSES = {
  COMPLETED: 'COMPLETED',
  SKIPPED: 'SKIPPED',
};

export const PAYROLL_DISTRIBUTION_STATUSES_OPTIONS = {
  [PAYROLL_DISTRIBUTION_STATUSES.COMPLETED]: 'Completed',
  [PAYROLL_DISTRIBUTION_STATUSES.SKIPPED]: 'Skipped',
};

const usePayrollDistributionService = () => {
  const { sendPostRequest } = useFinanceApi();
  const baseCrud = useCrudService({
    apiService: useFinanceApi,
    listResourceUrl: PAYROLL_DISTRIBUTION_URL,
    singleResourceUrl: `${PAYROLL_DISTRIBUTION_URL}/:id`,
  });

  const performExpenseDistribution = async (period: string) => {
    const result = await sendPostRequest('/accounting-cycle/distribute-expenses', { period });
    return result.json();
  };

  return {
    ...baseCrud,
    performExpenseDistribution,
  };
};

export default usePayrollDistributionService;
