/* istanbul ignore file */
import React from 'react';
import TrainingsBreadcrumbs from '../../shared/TrainingsBreadcrumbs';
import UpdateBreadcrumbItem from '../../../../shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateTrainingBreadcrumbs = () => {
  return (
    <TrainingsBreadcrumbs>
      <UpdateBreadcrumbItem entity="Training" />
    </TrainingsBreadcrumbs>
  );
};

export default UpdateTrainingBreadcrumbs;
