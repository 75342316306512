/* istanbul ignore file */
import React, { useEffect } from 'react';
import { Route } from 'shared/routing';
import { useDispatch } from 'react-redux';
import { READ, UPDATE } from 'crm/crmService';
import useRoute from 'shared/routing/useRoute';
import { Switch, useParams } from 'react-router-dom';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { clearTimer } from 'shared/uibuilder/DataTimer';
import EmployeeSalaryContext from 'erp/employee/salaries/SalaryContext';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CREATE_EMPLOYEE_SALARY, READ_LIST_EMPLOYEE_SALARY } from 'erp/employee/employeeService';
import ViewEmployeeSalary from 'erp/employee/salaries/Show/Show';
import EmployeeSalariesList from 'erp/employee/salaries/List';
import CreateSalaryPage from 'erp/employee/salaries/createupdate/Create';
import UpdateSalaryPage from 'erp/employee/salaries/createupdate/Update';

export const EMPLOYEE_SALARY_ID = 'salaryId';
export const PATH = '/salaries';

export const useEmployeeSalaryRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}${PATH}`,
    singleEntityRoute: `${employeeRoute}${PATH}/:${EMPLOYEE_SALARY_ID}`,
  });
};

export const useEmployeeSalaryUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeUrl = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeUrl}${PATH}`,
    idSource: EMPLOYEE_SALARY_ID,
  });
};

export const useEmployeeSalaryId = () => {
  const params: Dictionary<string> = useParams();

  return params.salaryId;
};

const EmployeeSalaryRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useEmployeeSalaryRoute();
  const { getListUrl } = useEmployeeSalaryUrl();
  const listUrl = getListUrl();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearTimer(listUrl));
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_LIST_EMPLOYEE_SALARY}>
        <EmployeeSalariesList />
      </GlobalProtectedRoute>

      <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_EMPLOYEE_SALARY}>
        <CreateSalaryPage />
      </GlobalProtectedRoute>

      <Route path={updateRoute} exact>
        <EmployeeSalaryContext permissionToCheck={UPDATE}>
          <UpdateSalaryPage />
        </EmployeeSalaryContext>
      </Route>

      <Route path={singleEntityRoute} exact>
        <EmployeeSalaryContext permissionToCheck={READ}>
          <ViewEmployeeSalary />
        </EmployeeSalaryContext>
      </Route>

      <Route component={Page404} />
    </Switch>
  );
};

export default EmployeeSalaryRouter;
