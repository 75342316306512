import React, { FC } from 'react';
import { DefaultInputPropTypes, InputList } from 'shared/uibuilder/form/input';

interface InvoiceReferenceListInputProps {
  source: string;
  inputTemplate: FC<DefaultInputPropTypes<any>>;
  addText: string;
}

const InvoiceReferenceListInput: React.FC<InvoiceReferenceListInputProps> = ({ inputTemplate, ...props }) => {
  return (
    <div>
      <InputList {...props} maxFields={10} inputTemplate={inputTemplate} />
    </div>
  );
};

export default InvoiceReferenceListInput;
