import { Action } from 'redux';

export const PAGE_LOAD_START = 'PAGE_LOAD_START';
export const PAGE_LOAD_ADD_REQUEST = 'PAGE_LOAD_ADD_REQUEST';
export const PAGE_LOAD_RESET_COUNTER = 'PAGE_LOAD_RESET_COUNTER';

export const MAX_REQUESTS_GAP_ON_ONE_PAGE = 500;

export type RequestType = {
  responseEnd: number;
  startTime: number;
  name: string;
};

export interface PageLoadState {
  startTime: number;
  endTime: number;
  globalStart: boolean;
  requests: RequestType[];
  counterActive: boolean;
}

interface PageLoadAction extends Action {
  globalStart: boolean;
  request: RequestType;
}

export const initialState: PageLoadState = {
  startTime: 0,
  endTime: 0,
  globalStart: false,
  requests: [],
  counterActive: true,
};

export const getPageStart = (requests: RequestType[], newRequest: RequestType) => {
  if (requests.length === 0) return null;

  const prevRequest = requests[requests.length - 1];
  const gap = newRequest.startTime - prevRequest.responseEnd;

  if (gap > MAX_REQUESTS_GAP_ON_ONE_PAGE) return newRequest.startTime;

  return null;
};

export const reducer = (state = initialState, action: PageLoadAction) => {
  switch (action.type) {
    case PAGE_LOAD_START:
      return {
        ...state,
        startTime: action.globalStart ? window.performance.timing.navigationStart : new Date(Date.now()).getTime(),
        endTime: 0,
        globalStart: action.globalStart || false,
      };
    case PAGE_LOAD_ADD_REQUEST:
      return {
        ...state,
        startTime: getPageStart(state.requests, action.request) || state.startTime,
        endTime: action.request.responseEnd,
        requests: [...state.requests, action.request],
        globalStart: getPageStart(state.requests, action.request) ? false : state.globalStart,
        counterActive: true,
      };
    case PAGE_LOAD_RESET_COUNTER:
      return {
        ...state,
        counterActive: false,
      };
    default:
      return { ...state };
  }
};
