/* istanbul ignore file */
import React from 'react';
import SingleOpportunityBreadcrumbs from 'crm/opportunity/shared/SingleOpportunityBreadcrumbs';
import { ActivitiesAndNotesBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const OpportunityTimelineBreadcrumbs = () => {
  const id = useOpportunityId();
  const { getTimelineUrl } = useOpportunityUrl();

  return (
    <SingleOpportunityBreadcrumbs>
      <ActivitiesAndNotesBreadcrumbItem link={getTimelineUrl(id)} />
    </SingleOpportunityBreadcrumbs>
  );
};

export default OpportunityTimelineBreadcrumbs;
