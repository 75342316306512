import React from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Button } from 'uibuilder/button';
import { useDateTimeService } from 'shared/uibuilder/dateService';

type PreviousPayrollGenerationModalProps = {
  isOpened: boolean;
  isChanged: boolean;
  close: (res: boolean) => any;
  generationDate: string;
};
const PreviousPayrollGenerationModal = ({
  isOpened,
  isChanged,
  generationDate,
  close,
}: PreviousPayrollGenerationModalProps) => {
  const { formatDateWithFullFormat } = useDateTimeService();

  const yes = (
    <Button color="primary" key="disqualifyBtn" onClick={() => close(true)}>
      Yes
    </Button>
  );

  const no = (
    <Button color="secondary" key="disqualifyBtn" onClick={() => close(false)}>
      No
    </Button>
  );

  return (
    <ModalWindow
      hasCancelButton={false}
      isOpen={isOpened}
      title={`The report data has${isChanged ? '' : ' not'} been changed`}
      onToggle={() => close(false)}
      buttons={[yes, no]}
    >
      <p>
        The report data <b>has{isChanged ? '' : ' not'} been</b> changed since the last generation
        {formatDateWithFullFormat(generationDate)}.
      </p>
      <p>Do you still want to generate the report?</p>
    </ModalWindow>
  );
};

export default PreviousPayrollGenerationModal;
