/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CorporateEventStatusDropdown from 'financialAnalytic/corporateEvents/shared/input/CorporateEventStatusDropdown';

const CorporateEventStatusDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <CorporateEventStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default CorporateEventStatusDropdownFilter;
