/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useContactUrl } from 'crm/contact/ContactRouter';

const EditContactButton = props => {
  const { getUpdateEntityUrl } = useContactUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} {...props} />;
};

export default EditContactButton;
