import React from 'react';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { CreateForm } from 'shared/uibuilder/form';
import CustomerInvoiceForm from '../CustomerInvoiceForm';
import { useCustomerInvoiceSowId, useCustomerInvoiceUrl } from '../../CustomerInvoicesRouter';
import useCustomerInvoiceService from '../../useCustomerInvoiceService';
import CreateCustomerInvoiceBreadcrumbs from './CreateCustomerInvoiceBreadcrumbs';
import useProjectService from 'erp/project/shared/projectService';

const CreateCustomerInvoicePage = () => {
  const { create, getValidationSchema } = useCustomerInvoiceService();
  const { getSingleEntityUrl } = useCustomerInvoiceUrl();
  const { searchAllSuggestions } = useProjectService();
  const sowIdParams = useCustomerInvoiceSowId();
  let getDataFunc;

  if (sowIdParams) {
    getDataFunc = async () => {
      const projects = await searchAllSuggestions({});
      const project = projects.find((value: { sowId: string }) => value.sowId === sowIdParams) || {};

      return {
        sowId: project.sowId,
      };
    };
  }

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: data => getSingleEntityUrl(data.id),
        message: 'You are awesome! The Customer invoice has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        invoiceSum: {
          currency: 'USD',
        },
      }}
    >
      <CreatePageLayout breadcrumbs={<CreateCustomerInvoiceBreadcrumbs />} title="Create: Customer invoice">
        <CustomerInvoiceForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateCustomerInvoicePage;
