import React from 'react';
import { HtmlField, TextField } from 'shared/uibuilder/field';
import Grid from 'uibuilder/Grid';

interface NameDescriptionFieldProps {
  value?: any;
}

const NameDescriptionField = ({ value }: NameDescriptionFieldProps) => {
  const { name, description } = value;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} xl={3}>
        <TextField value={name} />
      </Grid>
      <Grid item xs={12} md={12} xl={9}>
        <HtmlField value={description} />
      </Grid>
    </Grid>
  );
};

export default NameDescriptionField;
