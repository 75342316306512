/* istanbul ignore file */
import React from 'react';
import SingleVacanciesBreadcrumbs from 'erp/recruitment/vacancy/shared/SingleVacanciesBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateVacancyBreadcrumbs = () => {
  return (
    <SingleVacanciesBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleVacanciesBreadcrumbs>
  );
};

export default UpdateVacancyBreadcrumbs;
