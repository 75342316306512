/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { TextField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import 'camunda/monitoring/instance/Show/variables/ProcessVariables.scss';
import useProcessVariablesHelper from 'camunda/monitoring/instance/Show/variables/processVariablesHelper';
import VariableContextPanel from 'camunda/monitoring/instance/Show/variables/VariableContextPanel';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import MediumHeading from 'camunda/monitoring/instance/Show/MediumHeading';
import { useSearchParameter } from 'shared/routing/helper';
import { useHistory } from 'react-router-dom';
import { BpmnDiagramService } from 'camunda/monitoring/facade/useBpmnJs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { ActivityInstance } from 'camunda/monitoring/instance/processInstanceService';
import SimpleTooltip from 'shared/uibuilder/SimpleTooltip';
import AddProcessVariableButton from 'camunda/monitoring/instance/Show/variables/shared/button/AddProcessVariableButton';

interface Props {
  bpmnJs?: BpmnDiagramService;
}

const ProcessVariables = ({ bpmnJs }: Props) => {
  const processId = useProcessInstanceId();
  const contextFromParams = useSearchParameter('context');
  const [activeContext, setActiveContext] = useState(processId);

  useEffect(() => {
    if (contextFromParams) {
      setActiveContext(contextFromParams);
    }
  }, [contextFromParams]);

  const { data } = useShowContext();
  const activities = data && data.getValueBySource('activities');
  const isDiagramLoaded = bpmnJs?.isLoaded();
  useEffect(() => {
    if (isDiagramLoaded && activities && activeContext !== processId) {
      const { activityId } = activities.find((a: ActivityInstance) => a.executionId === activeContext);
      bpmnJs?.zoomToElement(activityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContext, isDiagramLoaded]);

  const { getExecutionVariables } = useProcessVariablesHelper();
  const VariablesList = () => {
    const getDataMethod = async () => getExecutionVariables(activeContext);
    return (
      <RelatedList getDataMethod={getDataMethod}>
        <RelationListLayout actions={[]}>
          <DataGridLayout showTotal={false} buttons={[]} isSmallTable>
            <TextField source="name" />
            <TextField source="type" />
            <TextField source="value" />
          </DataGridLayout>
        </RelationListLayout>
      </RelatedList>
    );
  };

  const { push } = useHistory();
  const { getVariablesUrl } = useProcessInstanceUrl();
  const onClick = (context: string) => {
    push(getVariablesUrl(processId, context));
  };
  return (
    <div className="variables-wrapper">
      <div className="header-wrapper">
        <div className="tooltip-wrapper">
          <MediumHeading>Variables</MediumHeading>
          <SimpleTooltip id="history-tooltip" isBigText className="process-tooltip">
            The variables are accessible in different scopes.
            <br />
            <br />
            The top-most scope is global, meaning that the variable is globally accessible and has the same value within
            the entire instance.
            <br />
            <br />
            The tab also contains a node for each activity that has already finished or is currently active in the
            process instance. It allows you to select activity instances (single enactments or activities within a
            process, including their associated data) to explore their details.
          </SimpleTooltip>
        </div>
        <AddProcessVariableButton />
      </div>
      <VariableContextPanel activeContext={activeContext} setActiveContext={onClick} />
      <VariablesList />
    </div>
  );
};

export default ProcessVariables;
