import React from 'react';
import InCondition from 'shared/uibuilder/list/filter/condition/InCondition';
import { DropDownFilterLayoutType } from 'shared/uibuilder/list/filter/DropDownFilter';

const DropDownFilterLayout: DropDownFilterLayoutType = ({
  onChangeCallback,
  source,
  getDropdownValue,
  children,
  isSingle = false,
  label,
}) => {
  return (
    <InCondition
      source={source}
      onChangeCallback={onChangeCallback}
      value={isSingle ? getDropdownValue(true) : (getDropdownValue(true) as string[]).map(value => value?.toString())}
    >
      {React.cloneElement(children, {
        multiple: !isSingle,
        placeholder: 'Select...',
        label,
      })}
    </InCondition>
  );
};

export default DropDownFilterLayout;
