import React from 'react';
import { FieldProps, LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useCompetencyUrl } from 'erp/qualification/competency/CompetencyRouter';
import { LevelCompetency } from 'erp/qualification/model/qualificationModelService';

const CompetencyLinkField = ({ source, ...props }: FieldProps) => {
  const { getSingleEntityUrl } = useCompetencyUrl();
  const { getData } = useFieldHelper({ source });
  const { competencyId, name } = getData() as LevelCompetency;
  const url = getSingleEntityUrl(competencyId);

  return (
    <span className="mr-2">
      <LinkField {...props} linkText={name} value={url} />
    </span>
  );
};

export default CompetencyLinkField;
