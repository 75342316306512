/* istanbul ignore file */
import React from 'react';
import { TextField, FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { ArtifactType } from '../artifactStorageService';
import useArtifactService from 'artifact/artifactService';
import AttachmentFieldLayout from 'artifact/shared/field/layout/AttachmentFieldLayout';
import './ArtifactStorageTypeIcon.scss';

const ArtifactStorageTypeIcon = ({ fileSource, valueType, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const { getValue: getFile } = useFieldHelper({ source: fileSource || 'file.artifact' });
  const artifact = getFile() as any;
  const type = valueType || getValue();
  const { getThumbnail } = useArtifactService();

  const icon =
    type === ArtifactType.FOLDER ? (
      <i className="storage-folder-icon fa fa-folder-o" />
    ) : (
      <AttachmentFieldLayout
        value={{ ...artifact, hasPreview: true, id: artifact.type === 'image' ? artifact.id : `file-${artifact.id}` }}
        getThumbnail={getThumbnail}
        isDownloadAvailable={false}
      />
    );

  return (
    <div className={`artifact-storage-type ${type === ArtifactType.FOLDER ? '' : 'file'}`}>
      <TextField {...props} value={icon} />
    </div>
  );
};

export default ArtifactStorageTypeIcon;
