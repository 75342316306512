/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';

export enum FormSectionType {
  DEFAULT = 'default',
}

type FormSectionProps = {
  type: FormSectionType;
  children: any;
  props: any;
};

const FormSectionFactory = ({ type, props, children }: FormSectionProps) => {
  switch (type) {
    case FormSectionType.DEFAULT:
      return <FormSection {...props}>{children}</FormSection>;
    default:
      return <></>;
  }
};

export default FormSectionFactory;
