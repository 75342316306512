import React from 'react';
import { DefaultDropdownProps, Option } from 'shared/uibuilder/form/input/dropdownHelper';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export enum BOOLEAN_VALUE {
  YES = 'YES',
  NO = 'NO',
}

export const BOOLEAN_OPTIONS: Option[] = [
  { value: BOOLEAN_VALUE.YES, label: 'Yes' },
  { value: BOOLEAN_VALUE.NO, label: 'No' },
];

/**
 * Boolean dropdown that save a Boolean value using YES or NO options.
 * @param source - source
 * @param props - props
 */
const DropdownInputOnlyBoolean = ({ source, ...props }: DefaultDropdownProps) => {
  const { getRawOnChangeCallback, getValue } = useInputHelper({ source, ...props });
  const onChangeCallback = getRawOnChangeCallback() || (() => {});
  const inputValue = getValue();

  const mappedDisplayValue = (value: string | boolean) => {
    if (typeof value === 'boolean') {
      return value ? BOOLEAN_VALUE.YES : BOOLEAN_VALUE.NO;
    }

    return null;
  };

  const mappedValue = (value: string) => {
    if (value) {
      return value === BOOLEAN_VALUE.YES;
    }

    return null;
  };

  const handleChange = (data: FormFieldsData) => {
    onChangeCallback({
      [source]: mappedValue(data[source]),
    });
  };

  return (
    <Dropdown
      {...props}
      value={mappedDisplayValue(inputValue)}
      onChangeCallback={handleChange}
      source={source}
      options={BOOLEAN_OPTIONS}
    />
  );
};

export default DropdownInputOnlyBoolean;
