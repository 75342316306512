/* istanbul ignore file */
import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { copyToClipboard } from 'shared/clipboard';
import { IconButton } from 'uibuilder/button';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Tooltip } from '@mui/material';
import { MODIFY_INSTANCE_TOKEN_POSITIONS } from 'camunda/monitoring/instance/Show/token/processInstanceTokenService';

interface CopyActivityInstanceIdButtonProps {
  sourceExecutionId: string;
}

const CopyActivityInstanceIdButton = ({ sourceExecutionId }: CopyActivityInstanceIdButtonProps) => {
  const { getValue: getExecutionId } = useFieldHelper<any>({ source: sourceExecutionId });
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted(MODIFY_INSTANCE_TOKEN_POSITIONS);
  const executionId = getExecutionId();

  const onClick = async () => {
    if (executionId) {
      copyToClipboard(executionId);
    }
  };

  if (!hasPermissions || !executionId) {
    return null;
  }

  return (
    <Tooltip title="Copy Activity Instance ID">
      <IconButton onClick={onClick} sx={{ display: { md: 'inline-flex' }, ml: '12px' }}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyActivityInstanceIdButton;
