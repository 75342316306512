import LinkButton from 'uibuilder/button/LinkButton';
import React from 'react';
import { useRoomUrl } from 'erp/room/RoomRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const ViewOfficeRoomsButton = (props: any) => {
  const { getRoomsByOfficeUrl: baseGetRoomsUrl } = useRoomUrl();
  const { getValue: getId } = useFieldHelper<string>({ source: 'id' });

  const getRoomsUrl = () => baseGetRoomsUrl(getId());

  return (
    <LinkButton url={getRoomsUrl} key="view-office-rooms" variant="text" {...props}>
      Rooms
    </LinkButton>
  );
};

export default ViewOfficeRoomsButton;
