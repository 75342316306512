/* istanbul ignore file */
export default {
  accountName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  originalName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  accountType: {
    type: 'enum',
  },
  website: {
    type: 'string',
    maxSize: 255,
  },
  phoneNumber: {
    type: 'string',
    phone: true,
  },
  industry: {
    type: 'enum',
  },
  employeesNumber: {
    type: 'int',
    maxSize: 6,
  },
  ownerId: {
    type: 'enum',
    required: true,
  },

  address: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      country: {
        type: 'string',
      },
      addressLine1: {
        type: 'string',
        maxSize: 255,
      },
      city: {
        type: 'string',
        maxSize: 64,
      },
      state: {
        type: 'string',
      },
      zipCode: {
        type: 'string',
        maxSize: 64,
      },
    },
  },

  accountDescription: {
    type: 'string',
    maxSize: 60000,
  },

  attachmentsIds: {
    type: 'array',
    maxSize: 15,
  },
};
