/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import useLedgerService from 'financialAnalytic/ledger/useLedgerService';
import LedgerHeader from 'financialAnalytic/ledger/shared/LedgerHeader';
import CreateJournalEntryWithParamsButton from 'financialAnalytic/journalEntry/shared/button/CreateJournalEntryWithParamsButton';
import SingleLedgerBreadcrumbs from 'financialAnalytic/ledger/shared/SingleLedgerBreadcrumbs';
import { useLedgerId } from 'financialAnalytic/ledger/LedgerRouter';
import LedgerTransfersList from 'financialAnalytic/ledger/shared/field/LedgerTransfersList';
import BalanceField from 'financialAnalytic/ledger/shared/field/BalanceField';

const LedgerViewPage = () => {
  const accountId = useLedgerId().replace(/[_]/g, '/');
  const { getById } = useLedgerService();

  return (
    <Show getDataMethod={() => getById(accountId)}>
      <ShowPageLayout
        entityHeader={<LedgerHeader />}
        breadcrumbs={<SingleLedgerBreadcrumbs />}
        headerButtons={[<CreateJournalEntryWithParamsButton />]}
        hasSeparateSections
      >
        <ShowSection>
          <SectionRow colMd={2} colXl={4}>
            <BalanceField label="Balance" source="balance" nameSource="name" isShownOneValue />
          </SectionRow>
        </ShowSection>

        <ShowSection>
          <LedgerTransfersList title="Transfers" filter={{ accountsIds: [accountId] }} />
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default LedgerViewPage;
