/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import usePurchaseOrderService from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { SensitiveListElementLayout } from 'shared/uibuilder/sensitiveData';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import PurchaseOrderListPageContent from 'financialAnalytic/purchaseOrder/List/PurchaseOrderListPageContent';

const PurchaseOrderListPage = () => {
  const { search, getSensitiveDataByIds } = usePurchaseOrderService();
  const { getListUrl } = usePurchaseOrderUrl();

  return (
    <List getDataMethod={search} defaultFilter={{}}>
      <SensitiveListElementLayout
        sensitiveDataIdSource="id"
        loadSensitiveDataMethod={getSensitiveDataByIds}
        timerId={getListUrl()}
      >
        <PurchaseOrderListPageContent />
      </SensitiveListElementLayout>
    </List>
  );
};

export default PurchaseOrderListPage;
