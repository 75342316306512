/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EmployeeBackgroundCheckBreadCrumbs from 'erp/employee/backgroundcheck/shared/EmployeeBackgroundCheckBreadcrumbs';

const CreateEmployeeBackgroundCheckBreadCrumbs = () => {
  return (
    <EmployeeBackgroundCheckBreadCrumbs>
      <CreateBreadcrumbItem entity="Background Check" />
    </EmployeeBackgroundCheckBreadCrumbs>
  );
};

export default CreateEmployeeBackgroundCheckBreadCrumbs;
