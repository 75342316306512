/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import {
  useEmployeeBackgroundCheckId,
  useEmployeeBackgroundChecksUrl,
} from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import useEmployeeBackgroundCheckService, {
  DELETE_BACKGROUND_CHECK,
} from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

const deleteMessage = 'Do you really want to delete this Background Check?';

const DeleteAssignmentButton = () => {
  const { getListUrl } = useEmployeeBackgroundChecksUrl();
  const { deleteById } = useEmployeeBackgroundCheckService();
  const id = useEmployeeBackgroundCheckId();

  const handleDelete = () => deleteById(id);

  return (
    <DeleteButton
      deleteMethod={handleDelete}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Background Check has been successfully deleted.',
        errorMessage: `Can't delete Background Check`,
      }}
      permissionToCheck={DELETE_BACKGROUND_CHECK}
      key="delete-background-check"
    />
  );
};

export default DeleteAssignmentButton;
