/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import useAssignmentService, {
  CREATE_ASSIGNMENT,
  ACTIVE_STATUS,
  FUTURE_STATUS,
} from 'erp/assignment/shared/assignmentService';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import AssignmentListLayout from './AssignmentListLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { EmployeeFilter } from 'erp/employee';
import EmployeePositionFilter from 'erp/employee/List/filter/EmployeePositionFilter';
import ProjectFilter from 'erp/project/List/filter/ProjectFilter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import AssignmentStatusFilter from 'erp/assignment/shared/filter/AssignmentStatusFilter';
import QuickFilterAndSort from 'shared/uibuilder/list/filter/QuickFilterAndSort';
import { ASC } from 'shared/uibuilder/list/baseListHelper';
import EmployeeGroupFilter from '../shared/filter/EmployeeGroupFilter';
import useFeatureToggle, { Features } from '../../../featuretoggle';

const AssignmentList = () => {
  const { search } = useAssignmentService();
  const { getCreateUrl } = useAssignmentUrl();
  const { isFeatureEnabled } = useFeatureToggle();
  const isGroupAssignmentsEnabled = isFeatureEnabled(Features.GROUP_ASSIGNMENTS);

  const filters = (
    <Filters>
      <FiltersLayout
        quickFilters={[
          <QuickFilterAndSort
            label="All Active Assignments"
            state={{ status: { in: [ACTIVE_STATUS] } }}
            defaultSort={{ field: 'to', order: ASC }}
          />,
          <QuickFilterAndSort
            label="All Future Assignments"
            state={{ status: { in: [FUTURE_STATUS] } }}
            defaultSort={{ field: 'from', order: ASC }}
          />,
        ]}
      >
        <AssignmentStatusFilter source="status" label="Status" />
        <EmployeeFilter source="owner.alias" label="Owner" />
        <EmployeeFilter source="specification:individualAssignmentsByEmployeeAlias" label="Employee" />
        <EmployeeFilter source="specification:backupForEmployeeAlias" label="Backup For Employee" />
        <EmployeeGroupFilter
          source="specification:groupAssignmentsByGroupId"
          label="Public Service"
          isVisible={isGroupAssignmentsEnabled}
        />
        <EmployeeFilter source="createdBy.alias" label="Created by" />
        <EmployeePositionFilter source="specification:assignmentsByPositionIds" label="Position" />
        <ProjectFilter source="project.id" label="Project" />
        <DateRangeFilter canBeInFuture source="from" label="Start Date" />
        <DateRangeFilter canBeInFuture source="to" label="End Date" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List
      getDataMethod={search}
      defaultSortOrder="DESC"
      defaultSortField="updatedAt"
      defaultFilter={{
        status: { in: [ACTIVE_STATUS] },
      }}
    >
      <ListPageLayout
        header={<ListHeader label="Assignments" icon="fa-file" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Assignment"
            key="create-assignment"
            permissionToCheck={CREATE_ASSIGNMENT}
          />,
        ]}
        filter={filters}
      >
        <AssignmentListLayout />
      </ListPageLayout>
    </List>
  );
};

export default AssignmentList;
