/* istanbul ignore file */
import React, { useCallback, useEffect } from 'react';
import { ProcessInstanceTokenAction } from 'camunda/monitoring/instance/Show/token/processInstanceTokenService';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import ModificationTokenButtonLayout from 'camunda/monitoring/instance/Show/token/shared/ModificationTokenButtonLayout';
import 'camunda/monitoring/instance/Show/token/TokenModification.scss';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import useMessageService from 'shared/message/messageService';

interface ModificationTokenPositionFormProps {
  action: ProcessInstanceTokenAction;
  processInstanceId: string;
}

const ModificationTokenPositionForm = ({ action, processInstanceId }: ModificationTokenPositionFormProps) => {
  const {
    setFormData = (newData: FormFieldsData) => {},
    setFormErrors = (errors: FormFieldsData) => {},
  } = useFormContext();
  const { cleanMessage } = useMessageService();

  const cleanData = useCallback(() => {
    setFormData({
      instanceId: processInstanceId,
    });
    setFormErrors({});
    cleanMessage();
  }, [cleanMessage, processInstanceId, setFormData, setFormErrors]);

  useEffect(() => {
    cleanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const fields = useCallback(() => {
    switch (action) {
      case ProcessInstanceTokenAction.CREATE:
        return CreateTokenFields;
      case ProcessInstanceTokenAction.RECREATE:
        return RecreateTokenFields;
      case ProcessInstanceTokenAction.MOVE:
        return MoveTokenFields;
      case ProcessInstanceTokenAction.KILL:
        return KillTokenFields;
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const CreateTokenFields = (
    <>
      <TextInput source="instanceId" label="Process Instance ID" isRequired disabled />
      <TextInput source="activityId" label="Activity ID" isRequired />
    </>
  );
  const RecreateTokenFields = (
    <>
      <TextInput source="instanceId" label="Process Instance ID" value={processInstanceId} isRequired disabled />
      <TextInput source="activityId" label="Activity ID" isRequired />
    </>
  );
  const MoveTokenFields = (
    <>
      <TextInput source="instanceId" label="Process Instance ID" value={processInstanceId} isRequired disabled />
      <TextInput source="fromActivityId" label="From Activity ID" isRequired />
      <TextInput source="toActivityId" label="To Activity ID" isRequired />
    </>
  );
  const KillTokenFields = (
    <>
      <TextInput source="instanceId" label="Process Instance ID" value={processInstanceId} isRequired disabled />
      <TextInput source="activityInstanceId" label="Activity Instance ID" isRequired />
    </>
  );

  return (
    <CreatePageLayout title=" " buttons={<ModificationTokenButtonLayout handleCleanClick={cleanData} />}>
      {fields()}
    </CreatePageLayout>
  );
};

export default ModificationTokenPositionForm;
