import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import React from 'react';
import { CommonInputLayoutProps, DefaultInputPropTypes, Pastable } from 'shared/uibuilder/form/input';

const useBase64Helper = () => {
  const regex = new RegExp('<img.+src="data:image/([a-zA-Z]*);base64,([^"]*)"[^>]+/>');

  const hasBase64Images = (text: string) => regex.test(text);

  const replaceBase64Images = (text: string) => text.replace(regex, '');

  return {
    hasBase64Images,
    replaceBase64Images,
  };
};

const COPY_PAST_WITH_IMAGE_ERROR = 'Copy-paste image with the text not supported';

interface WysiwygInputProps extends DefaultInputPropTypes<string>, Pastable<any> {
  setRefreshDataCallback?: (data: any) => void;
  editorConfig?: string[];
}

export type CKEditorEventType = Dictionary<any>;

interface WysiwygInputLayoutProps extends CommonInputLayoutProps<object> {
  onPasteCallback?: (e: CKEditorEventType) => Promise<object>;
  height?: Nullable<number>;
  editorConfig?: string[];
  setRefreshDataCallback?: (data: (newData: string) => void) => void;
}

export type WysiwygInputLayoutType = ReactComponent<WysiwygInputLayoutProps>;
/**
 * Wysiwyg Input component
 * @param setRefreshDataCallback Wysiwyg component has it's inner state
 * with input's content (to prevent issues with the performance). setRefreshDataCallback
 * allows getting the function which can force component's state updating.
 * @param isVisible {boolean}
 * @param disabled {boolean}
 * @param propEditorConfig
 * @param props other props.
 */
const WysiwygInput: React.FC<WysiwygInputProps> = ({
  setRefreshDataCallback,
  isVisible = true,
  disabled = false,
  editorConfig: propEditorConfig,
  ...props
}: WysiwygInputProps) => {
  const inputHelper = useInputHelper({ ...props, isVisible, disabled });
  const { WysiwygInputLayout } = useUiTheme<WysiwygInputLayoutType>();
  const { replaceBase64Images, hasBase64Images } = useBase64Helper();
  const editorConfig = propEditorConfig;
  const parentOnPaste = inputHelper.getOnPasteCallback();

  const onPasteCallback = async (e: any) => {
    let content = parentOnPaste ? await parentOnPaste(e) : e.content;

    if (content && hasBase64Images(content)) {
      content = replaceBase64Images(content);
      inputHelper.setFormFieldErrors([COPY_PAST_WITH_IMAGE_ERROR]);
    }

    return Promise.resolve(content);
  };

  return (
    <WysiwygInputLayout
      isVisible={inputHelper.isVisible()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      tooltip={inputHelper.getTooltip()}
      errorMessages={inputHelper.getErrorMessages()}
      isRequired={inputHelper.getIsRequired()}
      disabled={inputHelper.getIsDisabled()}
      onPasteCallback={onPasteCallback}
      helpText={inputHelper.getHelpText()}
      setRefreshDataCallback={setRefreshDataCallback}
      editorConfig={editorConfig}
      height={inputHelper.getHeight()}
      labelHint={inputHelper.getLabelHint()}
    />
  );
};

export default WysiwygInput;
