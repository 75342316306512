/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const ListEmployeePerformanceBreadcrumbs = () => {
  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Performance Results" />
    </SingleEmployeeBreadcrumbs>
  );
};

export default ListEmployeePerformanceBreadcrumbs;
