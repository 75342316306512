import React from 'react';
import { Switch } from 'react-router-dom';
import { Router } from 'shared/routing';

interface RouterChild extends React.ReactElement {
  type: Router;
}

const RoutingSwitch = ({ children }: { children: RouterChild[] }) => (
  <Switch>
    {React.Children.map(children, (child: RouterChild) =>
      React.cloneElement(child, {
        ...(child.type?.getRouterPath ? { path: child.type?.getRouterPath() } : {}),
        ...(child.type?.getIsExactPath ? { exact: child.type?.getIsExactPath() } : {}),
      }),
    )}
  </Switch>
);

export default RoutingSwitch;
