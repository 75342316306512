/* istanbul ignore file */
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { BaseFieldLayoutType, FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Area } from 'erp/qualification/model/qualificationModelService';

const CompetencyAreasField = (props: FieldProps<Area[]>) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const areas: Area[] = getValue() || [];
  const displayedAreas = (
    <ul>
      {areas.map((area: Area) => (
        <li key={area.id}>{area.name}</li>
      ))}
    </ul>
  );
  return <BaseFieldLayout label={getLabel()} value={displayedAreas} />;
};

export default CompetencyAreasField;
