/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import BigFormRow from 'shared/layout/form/BigFormRow';
import OfferCompensationDetailsListInput from 'erp/candidate/offercompensation/shared/input/OfferCompensationDetailsListInput';
import OfferCompensationDetailsInput from 'erp/candidate/offercompensation/shared/input/OfferCompensationDetailsInput';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';

const EmployeeOfferForm = () => {
  return (
    <FormSection title="Compensation Details">
      <BigFormRow>
        <OfferCompensationDetailsListInput
          source="offerCompensations"
          maxFields={4}
          addText="Add Compensation Details"
          inputTemplate={OfferCompensationDetailsInput}
        />
      </BigFormRow>
      <BigFormRow>
        <DocumentScanUploaderInput label="Attachments" source="attachmentIds" />
      </BigFormRow>
    </FormSection>
  );
};

export default EmployeeOfferForm;
