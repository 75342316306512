/* eslint max-classes-per-file: ["error", 2] */
// services
import makeXhrRequest from './xhr';
// helper
import { handleResponse, initializeRequest, createHeaders } from './apiHelper';
// constants
import { CONTENT_TYPE, HTTP, HTTP_METHOD } from './const';
import { ResourceData, ResultResourceData } from 'shared/crud/baseCrudService';

type RequestResponse = Promise<ResultResourceData>;
type HandleError = (error: Response) => void;

export interface AuthHeader {
  name: string;
  value: string;
}

export interface ApiAuthenticationProvider {
  getAuthHeader: () => Promise<AuthHeader>;
}

export interface UnauthenticatedRequestHandler {
  handleUnauthenticated: (
    error: any,
    handleError: (error: any) => void,
    callRequest: () => void,
  ) => Promise<Nullable<void>>;
}

interface BaseRequestProps {
  baseUrl: string;
  unauthenticatedRequestHandler: UnauthenticatedRequestHandler;
  handleError: HandleError;
  authProvider: Nullable<ApiAuthenticationProvider>;
}

interface RequestProps extends BaseRequestProps {
  method: HTTP_METHOD;
}

interface RawRequestProps {
  url: string;
  method?: HTTP_METHOD;
  data: ResourceData;
  contentType?: CONTENT_TYPE;
  eventTarget: Nullable<AbortSignal>;
  headers?: AuthHeader[];
}

interface ArtifactsAPIRequestProps extends BaseRequestProps, RawRequestProps {
  onProgress?: Nullable<(ev: ProgressEvent) => void>;
}

interface APIRequestProps extends BaseRequestProps, RawRequestProps {}

interface ApiServices {
  authProvider: Nullable<ApiAuthenticationProvider>;
  errorHandler: {
    handleError: HandleError;
  };
  unauthenticatedRequestHandler: UnauthenticatedRequestHandler;
}

export class RequestError extends Error {
  status?: number | string;

  constructor(status: string | number = '', message: string = 'Something went wrong') {
    super();

    this.message = message;
    this.status = status;
    this.name = 'RequestError';
  }
}

export class ResourceNotFoundError extends RequestError {
  constructor(errorMessage?: string) {
    super(HTTP.NOT_FOUND, errorMessage);
  }
}

/**
 * Method for making API requests (any), should handle authorization rejection
 * @param method -- GET, POST, PUT, etc.
 * @param url -- request url, relative to backend base
 * @param data -- data to be sent
 * @param contentType -- Content-Type header value
 * @param eventTarget -- event
 * @param headers -- custom request headers, if needed
 * @returns {Promise}
 */

const makeRawRequest = ({
  url,
  method = HTTP_METHOD.GET,
  data,
  contentType = CONTENT_TYPE.JSON,
  eventTarget,
  headers = [],
}: RawRequestProps): RequestResponse => {
  const requestHeaders = createHeaders(headers, contentType);
  const params = {
    method,
    data,
    contentType,
    eventTarget,
  };
  const init = initializeRequest(requestHeaders, params);

  return fetch(url, init)
    .then(handleResponse)
    .catch(err => Promise.reject(err));
};

/**
 *
 * Method for making API requests, should handle authorization rejection
 * @param baseUrl - base url
 * @param method -- GET, POST, PUT, etc.
 * @param url -- request url, relative to backend base
 * @param data -- data to be sent
 * @param contentType -- Content-Type header value
 * @param eventTarget -- event
 * @param headers -- custom request headers, if needed
 * @param authProvider -- authentication provider component
 * @returns {Promise}
 */

const makeAPIRequest = async ({
  authProvider,
  unauthenticatedRequestHandler,
  baseUrl,
  url,
  method = HTTP_METHOD.GET,
  data,
  contentType,
  eventTarget,
  headers = [],
  handleError,
}: APIRequestProps): Promise<ResultResourceData> => {
  const requestUrl = `${baseUrl}${url}`;
  const requestHeaders = headers;

  if (authProvider) {
    const header = await authProvider.getAuthHeader();
    requestHeaders.push(header);
  }

  const result = await makeRawRequest({
    url: requestUrl,
    method,
    data,
    contentType,
    eventTarget,
    headers: requestHeaders,
  })
    .then(resp => resp)
    .catch(async error => {
      if (error.status === HTTP.UNAUTHORIZED && unauthenticatedRequestHandler) {
        return unauthenticatedRequestHandler.handleUnauthenticated(error, handleError, async () => {
          const newToken = await authProvider?.getAuthHeader();
          const response = await makeRawRequest({
            url: requestUrl,
            method,
            data,
            contentType,
            eventTarget,
            headers: [[...headers], newToken] as AuthHeader[],
          });

          return response;
        });
      }

      return handleError(error);
    });
  return result;
};

const buildSendRequest = ({
  baseUrl,
  authProvider,
  unauthenticatedRequestHandler,
  method,
  handleError,
}: RequestProps) => (url: string, data?: ResourceData, contentType = CONTENT_TYPE.JSON) => {
  return makeAPIRequest({
    authProvider,
    unauthenticatedRequestHandler,
    baseUrl,
    url,
    method,
    data,
    contentType,
    eventTarget: null,
    headers: [],
    handleError,
  });
};

const buildSendGetRequest = ({
  baseUrl,
  authProvider,
  unauthenticatedRequestHandler,
  handleError,
}: BaseRequestProps) => (url: string, contentType = CONTENT_TYPE.JSON) =>
  buildSendRequest({
    baseUrl,
    authProvider,
    unauthenticatedRequestHandler,
    method: HTTP_METHOD.GET,
    handleError,
  })(url, undefined, contentType);

const makeUploadFileRequest = async ({
  authProvider,
  unauthenticatedRequestHandler,
  baseUrl,
  url = '/artifacts',
  method = HTTP_METHOD.POST,
  data,
  eventTarget,
  onProgress,
  headers = [],
  handleError,
}: ArtifactsAPIRequestProps): Promise<ResultResourceData> => {
  const requestUrl = `${baseUrl}${url}`;
  const contentType = CONTENT_TYPE.MULTIPART;

  if (authProvider) {
    headers.push(await authProvider.getAuthHeader());
  }

  const requestHeaders = createHeaders(headers, contentType);

  const params = {
    method,
    data,
    contentType,
    eventTarget,
  };
  const init = initializeRequest(requestHeaders, params);

  return makeXhrRequest({
    url: requestUrl,
    opts: init,
    onProgress,
  })
    .then(handleResponse)
    .catch(async error => {
      if (error.status === HTTP.UNAUTHORIZED && unauthenticatedRequestHandler) {
        return unauthenticatedRequestHandler.handleUnauthenticated(error, handleError, () =>
          makeUploadFileRequest({
            authProvider,
            unauthenticatedRequestHandler,
            baseUrl,
            url,
            onProgress,
            method,
            data,
            contentType,
            eventTarget,
            handleError,
          }),
        );
      }

      return handleError(error);
    });
};

const buildSendUploadFile = ({
  baseUrl,
  authProvider,
  unauthenticatedRequestHandler,
  handleError,
}: BaseRequestProps) => (requestData: ArtifactsAPIRequestProps): RequestResponse =>
  makeUploadFileRequest({
    ...requestData,
    baseUrl,
    authProvider,
    unauthenticatedRequestHandler,
    handleError,
  });

const useApi = (baseUrl: string, services: ApiServices) => {
  const { authProvider, errorHandler, unauthenticatedRequestHandler } = services;
  const { handleError } = errorHandler;

  return {
    sendGetRequest: buildSendGetRequest({ baseUrl, authProvider, unauthenticatedRequestHandler, handleError }),
    sendPostRequest: buildSendRequest({
      baseUrl,
      authProvider,
      unauthenticatedRequestHandler,
      method: HTTP_METHOD.POST,
      handleError,
    }),
    sendPutRequest: buildSendRequest({
      baseUrl,
      authProvider,
      unauthenticatedRequestHandler,
      method: HTTP_METHOD.PUT,
      handleError,
    }),
    sendPatchRequest: buildSendRequest({
      baseUrl,
      authProvider,
      unauthenticatedRequestHandler,
      method: HTTP_METHOD.PATCH,
      handleError,
    }),
    sendDeleteRequest: buildSendRequest({
      baseUrl,
      authProvider,
      unauthenticatedRequestHandler,
      method: HTTP_METHOD.DELETE,
      handleError,
    }),
    uploadFile: buildSendUploadFile({
      baseUrl,
      authProvider,
      unauthenticatedRequestHandler,
      handleError,
    }),
  };
};

export default useApi;
