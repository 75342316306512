import useMessageService, { ErrorMessage, SuccessMessage } from 'shared/message/messageService';
import { COMMON_ERROR_SOURCE } from './uibuilder/form/formHelper';
import { useState } from 'react';

type SubmitParams = any;

export interface SubmitProps {
  submitMethod: (params?: SubmitParams) => Promise<void> | Promise<object>;
  afterSubmit?: {
    redirectUrl?: string;
    successMessage?: string;
    errorMessage?: string;
    errorStatusesMessages?: Dictionary<string>;
    execute?: (data?: any) => void;
    executeOnError?: () => void;
    raiseError?: boolean;
  };
}

const useSubmit = ({ submitMethod, afterSubmit }: SubmitProps) => {
  const { addMessage, addMessageAndRedirect } = useMessageService();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (params?: SubmitParams) => {
    try {
      setIsLoading(true);

      const data = await submitMethod(params);
      if (!afterSubmit) return;
      const { successMessage, execute, redirectUrl } = afterSubmit;

      if (execute) {
        execute(data);
      }

      if (successMessage) {
        if (redirectUrl) {
          addMessageAndRedirect(new SuccessMessage(successMessage), redirectUrl);
        } else {
          addMessage(new SuccessMessage(successMessage));
        }
      }
    } catch (error) {
      if (!afterSubmit) return;

      const { errorMessage, errorStatusesMessages = {}, executeOnError, raiseError } = afterSubmit;

      if (executeOnError) {
        executeOnError();
      }

      // @ts-ignore
      const statusErrorMessage = errorStatusesMessages[error.status];

      if (statusErrorMessage) {
        addMessage(new ErrorMessage(statusErrorMessage));
      }

      let messageAddedFromResponse = false;
      // @ts-ignore
      if (error.json) {
        // @ts-ignore
        const json = await error.json();
        const { errors: errorsObject = {}, message } = json;

        const errors = {};

        Object.entries(errorsObject).forEach(entry => {
          const [field, errorMessages] = entry;

          if (field === COMMON_ERROR_SOURCE) {
            addMessage(new ErrorMessage(errorMessages as string));
            messageAddedFromResponse = true;
          }

          if (Array.isArray(errorMessages)) {
            // @ts-ignore
            errors[field] = errorMessages;
          } else {
            // @ts-ignore
            errors[field] = [errorMessages];
          }
        });

        if (message && !messageAddedFromResponse) {
          addMessage(new ErrorMessage(message));
          messageAddedFromResponse = true;
        }
      }

      if (errorMessage && !messageAddedFromResponse) {
        addMessage(new ErrorMessage(errorMessage));
      }

      if (raiseError) {
        throw error;
      }
    }

    setIsLoading(false);
  };

  return {
    onSubmit,
    isLoading,
  };
};

export default useSubmit;
