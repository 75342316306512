/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Button } from 'uibuilder/button';
import React from 'react';
import { BpmnDiagramService } from 'camunda/monitoring/facade/useBpmnJs';
import './ProcessHistory.scss';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';
import CopyToClipboardButton from 'shared/uibuilder/CopyToClipboardButton';

interface Props {
  label?: string;
  activitySource: string;
  executionSource: string;
  bpmnJs?: BpmnDiagramService;
  setHighlightedItem: (item: { executionId: string; activityId: string }) => any;
}
const ActivityIdField = ({ activitySource, executionSource, bpmnJs, setHighlightedItem, label }: Props) => {
  const { getValue: getActivityId } = useFieldHelper({ source: activitySource });
  const { getValue: getExecutionId } = useFieldHelper({ source: executionSource });
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const activityId = getActivityId();
  const executionId = getExecutionId();
  const onClick = () => {
    const item = {
      executionId,
      activityId,
    };
    setHighlightedItem(item);
    bpmnJs?.zoomToElement(activityId);
    bpmnJs?.getDiagramElement().scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  const button = (
    <>
      <Button className="activity-link" link outline onClick={onClick}>
        {activityId}
      </Button>
      <CopyToClipboardButton value={activityId} id={activityId} className="activity-copy-link">
        Copy Activity ID
      </CopyToClipboardButton>
    </>
  );

  return <BaseFieldLayout label={label} value={button} />;
};

export default ActivityIdField;
