/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';

const EditVacancyButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useVacancyPublicationUrl();

  return <EditButton {...props} path={id => getUpdateEntityUrl(id)} />;
};

export default EditVacancyButton;
