/* istanbul ignore file */
export default {
  trainingProgram: {
    type: 'string',
    required: true,
  },
  status: {
    type: 'string',
    required: true,
  },
  mentors: {
    type: 'array',
    required: false,
  },
  students: {
    type: 'array',
    required: true,
  },
  startDate: {
    type: 'date',
    required: false,
    maxDate: {
      value: '$endDate',
      message: 'Please choose date before or at "End Date" date',
    },
  },
  endDate: {
    type: 'date',
    required: false,
    minDate: {
      value: '$startDate',
      message: 'Please choose date after or at "Start Date" date',
    },
  },
};
