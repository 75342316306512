/* istanbul ignore file */
import BaseTimelineItemFormLayout, {
  BaseTimelineItemFormLayoutProps,
} from 'shared/uibuilder/timeline/layout/form/BaseTimelineItemFormLayout';
import React from 'react';

const CreateTimelineItemLayout = ({ children, onSubmitAction, onCancel }: BaseTimelineItemFormLayoutProps) => (
  <li className="timeline__entry">
    <BaseTimelineItemFormLayout isCreateForm onCancel={onCancel} onSubmitAction={onSubmitAction}>
      {children}
    </BaseTimelineItemFormLayout>
  </li>
);

export default CreateTimelineItemLayout;
