/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import LinkField from 'shared/uibuilder/field/LinkField';
import { FieldProps } from 'shared/uibuilder/field';
import { copyToClipboard } from 'shared/clipboard';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { Button } from 'uibuilder/button';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';

const JournalEntryTransferField = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const id = getValue();
  const { getJournalEntryByTransferUrl } = useJournalEntryUrl();

  return (
    <TextField
      {...props}
      source={source}
      value={
        <>
          <LinkField linkText={id} isInternal value={id ? getJournalEntryByTransferUrl(id) : null} {...props} />
          <Button
            variant="text"
            onClick={() => {
              copyToClipboard(id);
            }}
            id="copy-main"
            className="copy-anchor"
            sx={{
              minWidth: '10px',
              marginLeft: '5px',
              marginBottom: '-6px',
              marginTop: '-8px',
              p: '4px 5px',
              '& .MuiSvgIcon-root': {
                width: '18px',
                height: '18px',
              },
            }}
          >
            <CopyIcon />
          </Button>
        </>
      }
    />
  );
};

export default JournalEntryTransferField;
