import React from 'react';
import { useAccountingCycleId } from '../AccountingCycleRouter';
import useAccountingCycle from '../useAccountingCycle';
import Show from 'shared/uibuilder/show/Show';
import ViewAccountingCycleContent from './ViewAccountingCycleContent';

const ShowAccountingCycle = () => {
  const { getById } = useAccountingCycle();
  const id = useAccountingCycleId();
  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewAccountingCycleContent />
    </Show>
  );
};

export default ShowAccountingCycle;
