/* istanbul ignore file */
import React from 'react';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useContractRequestService from 'crm/opportunity/presale/contract/shared/contractRequestService';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import ContractRequestForm from 'crm/opportunity/presale/contract/form/ContractRequestForm';

const UpdateContractRequestForm = ({ getDataFunc, onCancel, ...props }: any) => {
  const opportunityId = useOpportunityId();
  const { update, getValidationSchema } = useContractRequestService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={(id, values) => update(opportunityId, id, values)}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ContractRequestForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateContractRequestForm;
