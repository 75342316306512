/* istanbul ignore file */
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';

const ApplicationCandidateScoreBadge = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const value = getValue() as any;

  const getValueText = () => (!value ? 'N/A' : value);

  const getColor = () => {
    if (!value) {
      return 'neutralLight';
    }

    if (value < 30) {
      return 'error';
    }

    if (value > 70) {
      return 'success';
    }

    return 'warning';
  };

  return <CustomBadge text={getValueText()} color={getColor()} {...props} />;
};

export default ApplicationCandidateScoreBadge;
