/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const options = {
  I: 'I',
  II: 'II',
};

const MilitaryRegistrationCategoryDropdown = props => {
  return <EnumDropdown {...props} options={options} />;
};

export default MilitaryRegistrationCategoryDropdown;
