import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAccountingCycleUrl } from '../AccountingCycleRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const AccountingCycleBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useAccountingCycleUrl();
  const wasteListLink = getListUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Accounting Cycles" link={wasteListLink} />
      {children}
    </Breadcrumbs>
  );
};

export default AccountingCycleBreadcrumbs;
