/* istanbul ignore file */
import useSecurityFormMapper from 'shared/uibuilder/form/securityFormMapper';
import { updateEmployeePermissions, EMPLOYEE_TYPES_ALIAS } from './employeeService';

export default () => {
  const { filterForm } = useSecurityFormMapper(updateEmployeePermissions);

  const checkRequisitesInformation = formData => {
    if (
      formData.employeeType !== EMPLOYEE_TYPES_ALIAS.CONTRACTOR &&
      formData.extendedInformation?.requisitesInformation
    ) {
      delete formData.extendedInformation.requisitesInformation; // eslint-disable-line no-param-reassign
    }
  };

  const createMapper = employee => {
    const preparedData = { ...employee };

    checkRequisitesInformation(preparedData);

    return preparedData;
  };

  // set optional family member middleName to null if field is empty
  const normalizeFamily = (members = []) => {
    if (members != null) {
      return members.map(item =>
        item.name.middleName
          ? item
          : {
              ...item,
              name: {
                ...item.name,
                middleName: null,
              },
            },
      );
    }
    return [];
  };

  const mapIdentityDocument = (document = {}) => {
    return {
      ...document,
      scansIds: document.scansIds ? document.scansIds.map(file => file.artifactId || file) : [],
    };
  };

  const mapForUpdate = data => {
    const { extendedInformation } = data;
    const { photosIds, identityDocuments, locationUpdateDate, other, children } = extendedInformation || {};

    return {
      ...data,
      ...(extendedInformation
        ? {
            extendedInformation: {
              ...extendedInformation,
              photosIds: photosIds && photosIds.map(file => file.artifactId || file),
              identityDocuments: identityDocuments.map(identityDocument => {
                return mapIdentityDocument(identityDocument);
              }),
              familyMembers: [...normalizeFamily(other), ...normalizeFamily(children)],
              locationUpdateDate: locationUpdateDate === null || locationUpdateDate === '' ? ' ' : locationUpdateDate,
            },
          }
        : {}),
    };
  };

  const updateMapper = data => {
    checkRequisitesInformation(data);
    return filterForm(mapForUpdate(data));
  };

  return {
    createMapper,
    updateMapper,
    normalizeFamily,
  };
};
