import React from 'react';
import SowBreadcrumbs from '../../shared/SowBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateSowBreadcrumbs = () => {
  return (
    <SowBreadcrumbs>
      <CreateBreadcrumbItem entity="SOW" />
    </SowBreadcrumbs>
  );
};

export default CreateSowBreadcrumbs;
