/* istanbul ignore file */
import React from 'react';
import Grid from 'uibuilder/Grid';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import useInputRow, { ProjectReportType } from './useInputRow';
import { NumberFieldType } from '../../ProjectWeeklyReportService';
import TextButton from 'uibuilder/button/TextButton';

export type AgileProductMaintenanceSupportType = {
  periodFrom: string;
  periodTo: string;
  fromPreviousReport: boolean;
  provisioned: number;
  actual: number;
  billable: number;
  ba: number;
  waste: number;
  comments: string;
};

interface AgileProductMaintenanceSupportTypesDataInputRowProps {
  data: AgileProductMaintenanceSupportType;
  id: number;
  isPeriodEditable: boolean;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

export const getAgileProductMaintenanceSupportTypesItemSource = (id: number) => {
  return `internalReportData.agileProductMaintenanceSupportTypesData[${id}]`;
};

const AgileProductMaintenanceSupportTypesDataInputRow = ({
  data,
  id,
  formData,
  isPeriodEditable,
  updateFormData,
  getErrorMessages,
}: AgileProductMaintenanceSupportTypesDataInputRowProps) => {
  const source = getAgileProductMaintenanceSupportTypesItemSource(id);
  const { getOnChangeCallback, removeRow, getOnChangeNumberFieldCallback } = useInputRow({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'agileProductMaintenanceSupportTypesData',
    reportType: ProjectReportType.INTERNAL_REPORT,
  });

  return (
    <Grid container spacing={2} style={id === 0 ? { alignItems: 'flex-end' } : {}}>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.periodFrom`}
          label={id === 0 ? 'Period from' : ''}
          value={data.periodFrom}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('periodFrom')}
          isRequired
          minDate={formData.firstInternalReport ? null : formData.from}
          maxDate={formData.to}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.periodTo`}
          label={id === 0 ? 'Period to' : ''}
          value={data.periodTo}
          disabled={data.fromPreviousReport && !isPeriodEditable}
          onChangeCallback={getOnChangeCallback('periodTo')}
          isRequired
          minDate={data.fromPreviousReport ? data.periodTo : data.periodFrom}
          maxDate={formData.to}
        />
      </Grid>
      <Grid item className="col">
        <TextInput
          source={`${source}.provisioned`}
          label={id === 0 ? 'Provisioned, h' : ''}
          value={data.provisioned ? data.provisioned.toString() : '0'}
          onChangeCallback={getOnChangeNumberFieldCallback(NumberFieldType.DOUBLE, 'provisioned')}
          isRequired
        />
      </Grid>
      <Grid item className="col">
        <TextInput
          source={`${source}.actual`}
          label={id === 0 ? 'Actual, h' : ''}
          value={data.actual ? data.actual.toString() : '0'}
          onChangeCallback={getOnChangeNumberFieldCallback(NumberFieldType.DOUBLE, 'actual')}
          isRequired
        />
      </Grid>
      <Grid item className="col">
        <TextInput
          source={`${source}.billable`}
          label={id === 0 ? 'Billable, h' : ''}
          value={data.billable ? data.billable.toString() : '0'}
          onChangeCallback={getOnChangeNumberFieldCallback(NumberFieldType.DOUBLE, 'billable')}
          isRequired
        />
      </Grid>
      <Grid item className="col">
        <TextInput
          source={`${source}.waste`}
          label={id === 0 ? 'Waste, h' : ''}
          value={data.waste ? data.waste.toString() : '0'}
          onChangeCallback={getOnChangeNumberFieldCallback(NumberFieldType.DOUBLE, 'waste')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextButton
          source={`${source}.comments`}
          value={data.comments}
          label={id === 0 ? 'Comments' : ''}
          title="Comments"
          onChangeCallback={getOnChangeCallback('comments')}
          errors={getErrorMessages(`${source}.comments`)}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={1}
        sx={{
          alignSelf: id === 0 ? 'center' : '',
        }}
      >
        <RemoveListItemButton isVisible={!data.fromPreviousReport} onClickCallback={removeRow} />
      </Grid>
    </Grid>
  );
};

export default AgileProductMaintenanceSupportTypesDataInputRow;
