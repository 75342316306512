import {
  APPLICATION_TYPE_ALIAS,
  PRESALE_REQUEST_TYPE_ALIAS,
} from 'crm/opportunity/presale/request/shared/presaleRequestService';
import { BOOLEAN_VALUE } from 'shared/uibuilder/form/input/BooleanDropdownOptionInput';
import { get } from 'lodash';

export const showSystemCompletionField = (data: any) => {
  return data?.requestType === PRESALE_REQUEST_TYPE_ALIAS.EXISTING_SYSTEM_DEVELOPMENT_COMPLETION;
};

export const showTeamInvolvementField = (data: any) => {
  const applicableTypes = [
    PRESALE_REQUEST_TYPE_ALIAS.EXISTING_SYSTEM_DEVELOPMENT_COMPLETION,
    PRESALE_REQUEST_TYPE_ALIAS.MODERNIZATION_OF_OUTDATED,
  ];

  return applicableTypes.includes(data?.requestType);
};

export const showCollaborationExpectationFiled = (data: any) => {
  const hasPreviousTeamInvolvement = get(data, 'hasPreviousTeamInvolvement');
  return hasPreviousTeamInvolvement === BOOLEAN_VALUE.YES;
};

export const showSystemHostingLocationField = (data: any) => {
  const applicableTypes = [
    PRESALE_REQUEST_TYPE_ALIAS.EXISTING_SYSTEM_DEVELOPMENT_COMPLETION,
    PRESALE_REQUEST_TYPE_ALIAS.MODERNIZATION_OF_OUTDATED,
    PRESALE_REQUEST_TYPE_ALIAS.DEFECT_FIXING,
  ];

  return applicableTypes.includes(data?.requestType);
};

export const showHasAutomationTestingField = (data: any) => {
  const applicableTypes = [
    PRESALE_REQUEST_TYPE_ALIAS.EXISTING_SYSTEM_DEVELOPMENT_COMPLETION,
    PRESALE_REQUEST_TYPE_ALIAS.EXPANSION_OF_EXISTING_SYSTEM,
    PRESALE_REQUEST_TYPE_ALIAS.DEFECT_FIXING,
  ];

  return applicableTypes.includes(data?.requestType);
};

export const showPercentTestCoverageField = (data: any) => {
  const hasAutomatedTesting = get(data, 'hasAutomatedTesting');

  return hasAutomatedTesting === BOOLEAN_VALUE.YES;
};

export const showMaterialsField = (data: any) => {
  const requestType = data?.requestType;

  return requestType && requestType !== PRESALE_REQUEST_TYPE_ALIAS.CONTRACT_EXTENSION;
};

export const showApplicationTypeField = (data: any) => {
  return data?.requestType === PRESALE_REQUEST_TYPE_ALIAS.NEW_CREATION;
};

export const showMobileOSField = (data: any) => {
  const applicationTypes: string[] = get(data, 'applicationTypes');

  return applicationTypes?.includes(APPLICATION_TYPE_ALIAS.MOBILE);
};

export const showThirdPartyField = (data: any) => {
  return data?.requestType === PRESALE_REQUEST_TYPE_ALIAS.NEW_CREATION;
};

export const showReplacementReasonField = (data: any) => {
  return data?.requestType === PRESALE_REQUEST_TYPE_ALIAS.MODERNIZATION_OF_OUTDATED;
};

export const showRequiredFteField = (data: any) => {
  return data?.requestType === PRESALE_REQUEST_TYPE_ALIAS.CONTRACT_EXTENSION;
};

export const showScopeOfWorkField = (data: any) => {
  return data?.requestType === PRESALE_REQUEST_TYPE_ALIAS.CONTRACT_EXTENSION;
};
