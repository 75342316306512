/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import JournalEntryBreadcrumbs from 'financialAnalytic/journalEntry/shared/JournalEntryBreadcrumbs';

const SingleJournalEntryBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useJournalEntryUrl();
  const id = data.getValueBySource('id');
  const displayedName = data.getValueBySource('id');

  return (
    <JournalEntryBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getSingleEntityUrl(id)} />
      {children}
    </JournalEntryBreadcrumbs>
  );
};

export default SingleJournalEntryBreadcrumbs;
