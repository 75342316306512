/* istanbul ignore file */
import React from 'react';
import AsyncSelect from 'react-select/async';
import { MultiValue, SingleValue } from 'react-select';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { MultiSelectInputLayoutType } from 'shared/uibuilder/form/input/MultiSelectInput';
import { IndicatorsContainer } from './DropdownComponents';
import getDropdownLayoutCommonProps from './dropdownLayoutHelper';
import './DropdownLayout.scss';

const MultiSelectInputLayout: MultiSelectInputLayoutType = ({
  onLoadOptions,
  placeholder,
  onChangeCallback,
  noOptionsMessage,
  value: initialValue,
  menuPortalTarget = document.body,
  isMulti = true,
  ...props
}) => {
  const { selectClassName, styles, classNamePrefix } = getDropdownLayoutCommonProps(props);

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <AsyncSelect
        {...getCommonInputProps(props)}
        value={initialValue}
        className={selectClassName}
        classNamePrefix={classNamePrefix}
        isMulti={isMulti}
        loadOptions={onLoadOptions as any}
        placeholder={placeholder}
        onChange={(value: MultiValue<Option> | SingleValue<Option>) => {
          if (onChangeCallback) {
            onChangeCallback({ target: { value } });
          }
        }}
        noOptionsMessage={noOptionsMessage}
        styles={styles}
        menuPortalTarget={menuPortalTarget}
        components={{
          IndicatorsContainer,
        }}
      />
    </BaseInputLayout>
  );
};

MultiSelectInputLayout.defaultProps = {
  ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  placeholder: undefined,
};

export default MultiSelectInputLayout;
