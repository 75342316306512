/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import ArtifactUploaderInput from 'artifact/shared/input/ArtifactUploaderInput';

const JustificationAttachmentUploaderInput = (props: any) => {
  const { isVisible } = props;

  return isVisible ? <ArtifactUploaderInput {...props} artifactTypeId={ARTIFACT_ID.NOTE_ATTACHMENT} /> : null;
};

export default JustificationAttachmentUploaderInput;
