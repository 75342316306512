/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import usePurchaseOrderService, { PURCHASE_ORDER_TYPES } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { usePurchaseOrderUrl, usePurchaseOrderId } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import CreatePurchaseOrderBreadcrumbs from 'financialAnalytic/purchaseOrder/createupdate/Create/Breadcrumbs';
import PurchaseOrderForm from 'financialAnalytic/purchaseOrder/createupdate/PurchaseOrderForm';

const CreatePurchaseOrderPage = () => {
  const { create, getValidationSchema, getById } = usePurchaseOrderService();
  const { getSingleEntityUrl } = usePurchaseOrderUrl();
  let getDataFunc;
  const id = usePurchaseOrderId();

  if (id) {
    getDataFunc = async () => {
      const data = await getById(id);

      return {
        type: data.type,
        vendor: data.vendor,
        title: data.title,
        office: data.office,
        account: data.account,
        purchasePurpose: data.purchasePurpose,
        requiredDeliveryDate: data.requiredDeliveryDate,
        expirationDate: data.expirationDate,
        requestor: data.requestor,
        requestorName: `${data.requestor} ${data.requestorFullName}`,
        currency: data.purchases[0].cost?.currency,
        purchases: data.purchases.map(({ name }: any) => ({ name })),
      };
    };
  }

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: data => getSingleEntityUrl(data.id),
        message: 'You are awesome! The Purchase Order has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        currency: 'USD',
        purchases: [{}],
        type: PURCHASE_ORDER_TYPES.STANDARD,
      }}
    >
      <CreatePageLayout breadcrumbs={<CreatePurchaseOrderBreadcrumbs />} title="Create: Purchase Order">
        <PurchaseOrderForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreatePurchaseOrderPage;
