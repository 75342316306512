/* istanbul ignore file */
import React from 'react';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import { OfferCompensationDetailsFieldProps } from 'erp/candidate/offercompensation/shared/field/OfferCompensationDetailsField';

const OfferOfficeListField = ({ value }: OfferCompensationDetailsFieldProps) => {
  return <EmployeeOfficeField value={value?.officeId} />;
};

export default OfferOfficeListField;
