/* istanbul ignore file */
import React from 'react';
import { ListProvider } from 'shared/uibuilder/list/ListContext';
import useListCrud from 'shared/uibuilder/list/builder/useListCrud';
import { LIST_PAGE_SIZE } from 'shared/uibuilder/list/List';
import { useListDataLoading } from 'shared/uibuilder/list/builder';
import { GetDataMethodType } from './builder/useListDataLoading';

export interface SimpleListProps {
  getDataMethod: GetDataMethodType;
  children: React.ReactElement;
  pageSize?: number;
  onRemove?: (params: StringOrNumber) => void;
}

const SimpleList = ({ getDataMethod, children, pageSize = LIST_PAGE_SIZE, onRemove }: SimpleListProps) => {
  const { loading, data, setLoading } = useListDataLoading({ getDataMethod, loadDataOnInit: true, pageSize });
  const listCrud = useListCrud({ onRemove, inputData: data });
  const contextValue = {
    ...listCrud,
    loading,
    setLoading,
  };

  return <ListProvider value={contextValue}>{children}</ListProvider>;
};

export default SimpleList;
