/* istanbul ignore file */
import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import DocumentTypeField from 'erp/employee/shared/field/DocumentTypeField';
import { DateField, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { string } from 'prop-types';
import { DOCUMENTS_ALIAS } from '../../../employeeService';

const DocumentField = ({ source, ...props }) => {
  const { getValue } = useFieldHelper({ ...props, source });
  const data = getValue();

  const isPesel = data.type === DOCUMENTS_ALIAS.PESEL;

  return (
    <SectionRow>
      <DocumentTypeField value={data.type} />
      {isPesel ? (
        <TextField label="Number" value={`${data.number}`} />
      ) : (
        <TextField label="Series and Number" value={`${data.documentDetails.series} ${data.number}`} />
      )}
      <DateField label="Date of Issue" value={data.documentDetails.issuedAt} isVisible={!isPesel} />
      <DateField label="Expiry Date" value={data.expiredAt} isVisible={!isPesel} />
      <TextField label="Issued by" value={data.documentDetails.issuedBy} isVisible={!isPesel} />
      <TextField label="Identification Number" value={data.documentDetails.personalIdNumber} isVisible={!isPesel} />
      <TextField label="Citizenship" value={data.documentDetails.citizenship} />
      <TextField label="Surname" value={data.documentDetails.surname} isVisible={!isPesel} />
      <TextField label="Given Name" value={data.documentDetails.givenName} isVisible={!isPesel} />
      <AttachmentsListField label="Document Scans" value={data.scansIds} isVisible={!isPesel} />
    </SectionRow>
  );
};

DocumentField.propTypes = {
  source: string,
};

DocumentField.defaultProps = {
  source: undefined,
};

export default DocumentField;
