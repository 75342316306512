import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { READ_TRAINING_DOMAIN, WRITE_TRAINING_DOMAIN } from './useTrainingsService';
import TrainingsList from './List/TrainingsList';
import CreateTraining from './createupdate/create';
import ViewTrainingPage from './Show';
import UpdateTrainingPage from './createupdate/update';

export const TRAININGS_PATH = '/trainings';

export const useTrainingsRoute = () => {
  const routes = useRoute({ listRoute: TRAININGS_PATH });

  return {
    ...routes,
  };
};

export const useTrainingsUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: TRAININGS_PATH,
  });

  return {
    ...entityUrl,
  };
};

export const useTrainingsId = () => {
  const params: Dictionary<string> = useParams();

  return params.id;
};

const TrainingsRouter = () => {
  const { listRoute, createRoute, singleEntityRoute, updateRoute } = useTrainingsRoute();

  return (
    <ProtectedRouter basePath={TRAININGS_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={[READ_TRAINING_DOMAIN]}>
          <TrainingsList />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={[WRITE_TRAINING_DOMAIN]}>
          <CreateTraining />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={[WRITE_TRAINING_DOMAIN]}>
          <UpdateTrainingPage />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={[READ_TRAINING_DOMAIN]}>
          <ViewTrainingPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

TrainingsRouter.getRouterPath = () => TRAININGS_PATH;

export default TrainingsRouter;
