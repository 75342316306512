import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { get } from 'lodash';

const TRAININGS_ACCOUNT_ID = 'Expenses/Talent acquisition and retention/Employee training/USD';
const COMPANY_EVENT_ACCOUNT_ID = 'Expenses/Talent acquisition and retention/Corporate events/Company event/USD';
const TEAM_EVENT_ACCOUNT_ID = 'Expenses/Talent acquisition and retention/Corporate events/Team event/USD';
const RECRUITING_EVENT_ACCOUNT_ID = 'Expenses/Talent acquisition and retention/Corporate events/Recruiting event/USD';

export enum ReferenceTypes {
  TRAINING = 'Training',
  COMPANY_EVENT = 'Company Event',
  TEAM_EVENT = 'Team Event',
  RECRUITING_EVENT = 'Recruiting Event',
}

const accountTypeMap: Record<string, keyof typeof ReferenceTypes> = {
  [TRAININGS_ACCOUNT_ID]: 'TRAINING',
  [COMPANY_EVENT_ACCOUNT_ID]: 'COMPANY_EVENT',
  [TEAM_EVENT_ACCOUNT_ID]: 'TEAM_EVENT',
  [RECRUITING_EVENT_ACCOUNT_ID]: 'RECRUITING_EVENT',
};

const ReferenceDropdown = (props: any) => {
  const { data } = useFormContext();
  const accountId = get(data, 'account') as string | undefined;

  const selectedKey = accountId ? accountTypeMap[accountId] : undefined;

  const options = selectedKey ? { [selectedKey]: ReferenceTypes[selectedKey] } : {};

  return <EnumDropdown placeholder="Select type" options={options} {...props} />;
};

export default ReferenceDropdown;
