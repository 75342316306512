import useEmployeeService, { DISMISSED_STATUS } from 'erp/employee/employeeService';
import { useDispatch, useSelector } from 'react-redux';

export const CRM_PATH = '/crm';

export const UPDATE = 'UPDATE';
export const READ = 'READ';
export const DELETE = 'DELETE';

export const CHANGE_OWNER = 'CHANGE_OWNER';
export const CREATE_LEAD = 'CREATE_LEAD';
export const READ_LEADS_LIST = 'READ_LEADS_LIST';
export const READ_TIMELINE = 'READ_TIMELINE';
export const CONVERT_LEAD = 'CONVERT_LEAD';
// accounts
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const READ_ACCOUNTS_LIST = 'READ_ACCOUNTS_LIST';
// opportunities
export const CREATE_OPPORTUNITY = 'CREATE_OPPORTUNITY';
export const READ_OPPORTUNITIES_LIST = 'READ_OPPORTUNITIES_LIST';
export const READ_RELATED_OPPORTUNITIES = 'READ_RELATED_OPPORTUNITIES';

// contacts
export const CREATE_CONTACT = 'CREATE_ACCOUNT';
export const READ_CONTACTS_LIST = 'READ_CONTACTS_LIST';
export const READ_RELATED_CONTACTS = 'READ_RELATED_CONTACTS';

export const INDUSTRIES = {
  ADVERTISING: 'Advertising',
  AEROSPACE: 'Aerospace',
  AGRICULTURE: 'Agriculture',
  APPAREL: 'Apparel',
  ARCHITECTURAL_SERVICE: 'Architectural service',
  ATTORNEYS: 'Attorneys/law Firm',
  AUTOMOTIVE: 'Automotive',
  BANKING: 'Banking',
  BIOTECHNOLOGY: 'Biotechnology',
  CHEMICALS: 'Chemicals',
  COMMUNICATIONS: 'Communications',
  CONSTRUCTION: 'Construction',
  CONSULTING: 'Consulting',
  ECOMMERCE: 'E-commerce service (non-retail)',
  EDUCATION: 'Education',
  ELECTRONICS: 'Electronics',
  ENERGY: 'Energy',
  ENGINEERING: 'Engineering',
  ENTERTAINMENT: 'Entertainment',
  ENVIRONMENTAL: 'Environmental',
  EXECUTIVE_SEARCH: 'Executive search',
  FINANCE: 'Finance',
  SHIPPING: 'Shipping',
  FOOD_BEVERAGE: 'Food & Beverage',
  GOVERNMENT: 'Government',
  HEALTHCARE: 'Healthcare',
  HOSPITALITY: 'Hospitality',
  INSURANCE: 'Insurance',
  MACHINERY: 'Machinery',
  MANUFACTURING: 'Manufacturing',
  MARKETING: 'Marketing',
  MEDIA: 'Media',
  NOT_FOR_PROFIT: 'Not For Profit',
  PEST_CONTROL: 'Pest control',
  PRINTING: 'Printing and Publishing',
  SPORTS: 'Professional sports, related services',
  REAL_ESTATE: 'Real estate',
  RECREATION: 'Recreation',
  RETAIL: 'Retail',
  SOFTWARE: 'Software Development',
  STAFFING: 'Staffing',
  TECHNOLOGY: 'Technology',
  TELECOMMUNICATIONS: 'Telecommunications',
  TRANSPORTATION: 'Transportation',
  UTILITIES: 'Utilities',
  OTHER: 'Other',
};

const SET_SALES = 'SET_SALES';

const setCrmManagers = (payload = {}) => ({
  type: SET_SALES,
  payload,
});

const initialState = [];

export const salesReducer = (state = initialState, action) => {
  if (action.type === SET_SALES) {
    return action.payload;
  }

  return state;
};

const useCrmService = () => {
  const { searchAll: searchEmployee } = useEmployeeService();

  const salesManagers = useSelector(state => state && state.sales);
  const dispatch = useDispatch();

  const getSalesManagers = async () => {
    if (salesManagers && salesManagers.length) {
      return Promise.resolve(salesManagers);
    } else {
      const managers = await searchEmployee({
        filter: { 'specification:salesManagersSpecification': { eq: true } },
      });

      dispatch(setCrmManagers(managers));

      return managers;
    }
  };

  const sortByLastName = (a, b) => (a.nameEn.lastName > b.nameEn.lastName ? 1 : -1);

  const getActiveSalesManagers = async () => {
    const sales = await getSalesManagers();
    const activeSmList = sales.filter(activeSM => activeSM.workingStatus !== DISMISSED_STATUS);
    return activeSmList.sort(sortByLastName);
  };

  const getDismissedSalesManagers = async () => {
    const sales = await getSalesManagers();
    const activeSmList = sales.filter(activeSM => activeSM.workingStatus === DISMISSED_STATUS);
    return activeSmList.sort(sortByLastName);
  };

  const divideSalesManagersByActiveAndDismissed = (sales = []) => {
    const sortedSalesManagers = [...sales].sort(sortByLastName);

    return sortedSalesManagers.reduce(
      (acc, manager) => {
        if (manager.workingStatus === DISMISSED_STATUS) {
          return { ...acc, inactiveSales: [...acc.inactiveSales, manager] };
        }

        return { ...acc, activeSales: [...acc.activeSales, manager] };
      },
      {
        activeSales: [],
        inactiveSales: [],
      },
    );
  };

  const getPresaleCoordinators = async () => {
    const coordinators = await searchEmployee({
      filter: { 'specification:presaleCoordinatorsSpecification': { eq: true } },
    });
    return coordinators;
  };

  const getActivePresaleCoordinators = async () => {
    const coordinators = await getPresaleCoordinators();
    const activeCoordinators = coordinators.filter(coordinator => coordinator.workingStatus !== DISMISSED_STATUS);
    return activeCoordinators.sort(sortByLastName);
  };

  return {
    getSalesManagers,
    divideSalesManagersByActiveAndDismissed,
    getActiveSalesManagers,
    getDismissedSalesManagers,
    getActivePresaleCoordinators,
  };
};

export default useCrmService;
