// libs
import React, { useCallback, useEffect, useMemo } from 'react';
// components
import ToggleSensitiveDataButton from 'shared/uibuilder/sensitiveData/buttons/ToggleSensitiveDataButton';
// services
import { useSensitiveDataService } from 'shared/uibuilder/sensitiveData';
import { SensitiveDataContextProvider } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';

type SensitiveData = Dictionary<any>;

const SensitiveShowElementLayout = ({
  sensitiveDataId,
  sensitiveDataIdSource,
  children,
  loadSensitiveDataMethod,
  timerId,
}: {
  sensitiveDataId: string;
  sensitiveDataIdSource: string;
  children: React.ReactElement;
  loadSensitiveDataMethod: (id: string) => Promise<SensitiveData>;
  timerId?: string;
}) => {
  const {
    isTimerActive,
    isShown,
    toggleSensitiveData,
    isSensitiveDataLoading,
    getValueByIdAndSource,
    sensitiveData,
    loadSensitiveDataByIds,
  } = useSensitiveDataService({ loadSensitiveDataMethod, timerId });

  const hasSensitiveData = useMemo(() => sensitiveData && Object.keys(sensitiveData).length, [sensitiveData]);

  const isShownWithoutSensitiveData = useMemo(() => sensitiveDataId && !hasSensitiveData && isShown, [
    isShown,
    hasSensitiveData,
    sensitiveDataId,
  ]);

  const reloadSensitiveData = useCallback(() => {
    loadSensitiveDataByIds([sensitiveDataId]);
  }, [loadSensitiveDataByIds, sensitiveDataId]);

  useEffect(() => {
    if (isShownWithoutSensitiveData) {
      loadSensitiveDataByIds([sensitiveDataId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownWithoutSensitiveData]);

  return (
    <SensitiveDataContextProvider
      value={{
        sensitiveDataIdSource,
        getValueByIdAndSource,
        reloadSensitiveData,
      }}
    >
      {React.cloneElement(children, {
        isSensitiveDataIsShown: isShown && sensitiveData !== null,
        toggleSensitiveDataButton: (
          <ToggleSensitiveDataButton
            key="toggle-sensitive-data"
            isShown={isShown}
            onClick={() => toggleSensitiveData([sensitiveDataId])}
            disabled={isSensitiveDataLoading}
            isTimerActive={isTimerActive}
          />
        ),
        reloadSensitiveData,
      })}
    </SensitiveDataContextProvider>
  );
};

export default SensitiveShowElementLayout;
