export default {
  id: {
    type: 'string',
    required: true,
  },
  accountId: {
    type: 'string',
    required: true,
  },
  projectName: {
    type: 'string',
    required: true,
  },
  totalAmount: {
    type: 'string',
    required: true,
  },
  currency: {
    type: 'string',
    required: true,
  },
  estimatedHours: {
    type: 'string',
    required: {
      value: true,
      when: {
        $billingType: {
          eq: 'MILESTONES',
        },
      },
    },
  },
  startDate: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$endDate',
      message: 'Please choose date earlier than End Date',
    },
  },
  endDate: {
    type: 'date',
    required: true,
    minDate: {
      value: '$startDate',
      message: 'Please choose date further than Start Date',
    },
  },
  milestones: {
    type: 'array',
    maxSize: 10,
    required: {
      value: true,
      when: {
        $billingType: {
          eq: 'MILESTONES',
        },
      },
    },
    forEach: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          required: true,
        },
        name: {
          type: 'string',
          required: true,
        },
        amount: {
          type: 'string',
          required: true,
        },
        completionRate: {
          type: 'number',
          minValue: 0,
          maxValue: 100,
          required: true,
        },
      },
    },
  },
  billingType: {
    type: 'string',
    required: true,
  },
};
