import { useBusinessEngineApi } from 'api';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';

export const RESOLVE_PROCESS_INCIDENT = 'RESOLVE_PROCESS_INCIDENT';

export enum ProcessIncidentType {
  FAILED_JOB_HANDLER_TYPE = 'failedJob',
  EXTERNAL_TASK_HANDLER_TYPE = 'failedExternalTask',
  ACTIVITY_CREATED_EVENT_ERROR = 'ACTIVITY_CREATED_EVENT_ERROR',
  ACTIVITY_EXECUTION_EVENT_ERROR = 'ACTIVITY_EXECUTION_EVENT_ERROR',
  ACTIVITY_PROCESSING_ERROR = 'ACTIVITY_PROCESSING_ERROR',
  BUSINESS_INCIDENT = 'BUSINESS_INCIDENT',
  CLOSE_TASK_EVENT_ERROR = 'CLOSE_TASK_EVENT_ERROR',
  CREATE_TASK_EVENT_ERROR = 'CREATE_TASK_EVENT_ERROR',
  CREATE_PIS_EVENT_ERROR = 'CREATE_PROCESS_TASK_EVENT_ERROR',
  EXTERNAL_FORM_SUBMITTED_EVENT_ERROR = 'EXTERNAL_FORM_SUBMITTED_EVENT_ERROR',
  ISSUE_CREATION_EVENT_ERROR = 'ISSUE_CREATION_EVENT_ERROR',
  ISSUE_RESOLVED_EVENT_ERROR = 'ISSUE_RESOLVED_EVENT_ERROR',
  ISSUE_CLOSED_EVENT_ERROR = 'ISSUE_CLOSED_EVENT_ERROR',
  ISSUE_REMOVED_EVENT_ERROR = 'ISSUE_REMOVED_EVENT_ERROR',
  PROCESS_STUCK = 'PROCESS_STUCK',
  REOPEN_ISSUE_EVENT_ERROR = 'REOPEN_ISSUE_EVENT_ERROR',
  RESOLVE_INCIDENT_ERROR = 'RESOLVE_INCIDENT_ERROR',
  SET_VARIABLE_ERROR = 'SET_VARIABLE_ERROR',
  SIGNAL_SENDING_ERROR = 'SIGNAL_SENDING_ERROR',
  SUPPORT_TASK_EVENT_ERROR = 'CREATE_SUPPORT_TASK_EVENT_ERROR',
  TECHNICAL_INCIDENT = 'TECHNICAL_INCIDENT',
  UNCLASSIFIED_TECHNICAL_INCIDENT = 'UNCLASSIFIED_TECHNICAL_INCIDENT',
}

export const ProcessIncidentTypeLabels = {
  [ProcessIncidentType.FAILED_JOB_HANDLER_TYPE]: 'Failed job',
  [ProcessIncidentType.EXTERNAL_TASK_HANDLER_TYPE]: 'Failed external task',
  [ProcessIncidentType.ACTIVITY_CREATED_EVENT_ERROR]: 'Activity created event error',
  [ProcessIncidentType.ACTIVITY_EXECUTION_EVENT_ERROR]: 'Activity execution event error',
  [ProcessIncidentType.ACTIVITY_PROCESSING_ERROR]: 'Activity processing error',
  [ProcessIncidentType.BUSINESS_INCIDENT]: 'Business incident',
  [ProcessIncidentType.CLOSE_TASK_EVENT_ERROR]: 'Close task event error',
  [ProcessIncidentType.CREATE_TASK_EVENT_ERROR]: 'Create task event error',
  [ProcessIncidentType.CREATE_PIS_EVENT_ERROR]: 'Create process instance event error',
  [ProcessIncidentType.EXTERNAL_FORM_SUBMITTED_EVENT_ERROR]: 'External form submitted event error',
  [ProcessIncidentType.ISSUE_CREATION_EVENT_ERROR]: 'Issue creation event error',
  [ProcessIncidentType.ISSUE_RESOLVED_EVENT_ERROR]: 'Issue resolved event error',
  [ProcessIncidentType.ISSUE_CLOSED_EVENT_ERROR]: 'Issue closed event error',
  [ProcessIncidentType.ISSUE_REMOVED_EVENT_ERROR]: 'Issue removed event error',
  [ProcessIncidentType.PROCESS_STUCK]: 'Process stuck',
  [ProcessIncidentType.REOPEN_ISSUE_EVENT_ERROR]: 'Reopen issue event error',
  [ProcessIncidentType.RESOLVE_INCIDENT_ERROR]: 'Resolve incident error',
  [ProcessIncidentType.SET_VARIABLE_ERROR]: 'Set variable error',
  [ProcessIncidentType.SIGNAL_SENDING_ERROR]: 'Signal sending error',
  [ProcessIncidentType.SUPPORT_TASK_EVENT_ERROR]: 'Support task event error',
  [ProcessIncidentType.TECHNICAL_INCIDENT]: 'Technical incident',
  [ProcessIncidentType.UNCLASSIFIED_TECHNICAL_INCIDENT]: 'Unclassified technical incident',
};

const useProcessIncidentService = () => {
  const processInstanceId = useProcessInstanceId();
  const { sendPutRequest, sendGetRequest } = useBusinessEngineApi();

  const getIncidents = async () => {
    const response = await sendGetRequest(`/camunda/process/${processInstanceId}/incidents`);
    const incidents = await response.json();
    return {
      totalPages: 1,
      pageNumber: 1,
      result: incidents,
    };
  };

  const resolveIncident = async (request: any) => {
    const response = await sendPutRequest(`/camunda/process/${processInstanceId}/incidents/resolve`, request);
    return response;
  };

  return {
    getIncidents,
    resolveIncident,
  };
};

export default useProcessIncidentService;
