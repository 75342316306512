/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Box from 'uibuilder/Box';
import TextField from 'shared/uibuilder/field/TextField';
import { getAmount } from 'financialAnalytic/ledger/shared/field/BalanceField';

const TotalFieldDecorator = ({ children, ...props }: any) => {
  const { getValue } = useFieldHelper(props);
  const value = getValue() as any;

  if (value) {
    if (props.source === 'totalGrossSalary') {
      return (
        <TextField
          {...props}
          value={
            <Box
              sx={{
                display: 'flex',
                div: {
                  marginRight: '16px',
                },
              }}
            >
              {!!value.USD && <div>{getAmount(value, 'USD')}</div>}
              {!!value.PLN && <div>{getAmount(value, 'PLN')}</div>}
              {!!value.BYR && <div>{getAmount(value, 'BYR')}</div>}
            </Box>
          }
        />
      );
    }

    return <TextField {...props} />;
  }

  return children;
};

export default TotalFieldDecorator;
