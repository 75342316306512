import React from 'react';
import { Dropdown } from 'shared/uibuilder/form/input';
import { DefaultDropdownProps, Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { EUROPEAN_COUNTRIES_NAMES } from 'shared/uibuilder/form/input/CountryDropdown';

export interface EnumDropdownProps extends Omit<DefaultDropdownProps, 'options'> {
  options: Dictionary<string>;
}

const CountyOfResidencesEnumDropdown = ({ options, ...props }: EnumDropdownProps) => {
  const { data: formData = {} } = useFormContext();
  let countryOptions: Option[];

  if (formData.countryOfResidences?.some((option: string) => option === 'ALL')) {
    countryOptions = [
      {
        value: 'ALL',
        label: 'ALL',
      },
    ];
  } else if (formData.countryOfResidences?.some((option: string) => option === 'EU')) {
    countryOptions = Object.entries(options).reduce((acc: Option[], [value, label]) => {
      if (!EUROPEAN_COUNTRIES_NAMES.includes(label)) {
        acc.push({ value, label });
      }
      return acc;
    }, []);
  } else {
    countryOptions = Object.entries(options).map(([value, label]) => ({ value, label }));
  }

  return <Dropdown {...props} options={countryOptions} />;
};

export default CountyOfResidencesEnumDropdown;
