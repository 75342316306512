/* istanbul ignore file */
import React, { useState } from 'react';
import useReconciliationService, {
  RUN_MANUAL_RECONCILIATION,
} from 'erp/costaccounting/reconcilation/useReconciliationService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { calculateTotal } from 'erp/costaccounting/reconcilation/shared/ReconciliationTransfersList';
import { useSensitiveDataContext } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';
import Checkbox from '../../../../../shared/uibuilder/form/input/Checkbox';

const ReconcileButton = ({
  selectedTransfers: { bankTransactionIds, davinciTransferIds, journalEntryIds } = {
    bankTransactionIds: [],
    davinciTransferIds: [],
    journalEntryIds: [],
  },
  bankItems,
  davinciItems,
  execute,
  isSensitiveDataIsShown,
}: {
  selectedTransfers: Dictionary<string[]>;
  bankItems: Dictionary<any>[];
  davinciItems: Dictionary<any>[];
  execute: () => void;
  isSensitiveDataIsShown?: boolean;
}) => {
  const { reconciliate } = useReconciliationService();
  const [errors, setErrors] = useState<Dictionary<string[]>>({});
  const [groupAlias, setGroupAlias] = useState('');
  const [isForcedReconciliation, setForcedReconciliation] = useState(false);
  const [forcedReconciliationReason, setForcedReconciliationReason] = useState('');
  const sensitiveDataContext = useSensitiveDataContext();

  const getAmounts = (data: Dictionary<any>[], amountSource: string, idSource: string) =>
    data.map(item => sensitiveDataContext.getValueByIdAndSource(amountSource, item[idSource]) || 0) || [];

  const totalSelectedBank = calculateTotal(
    getAmounts(
      bankItems?.filter((item: Dictionary<any>) => bankTransactionIds?.includes(item.id)),
      'bankTransactionSubTotals',
      'id',
    ),
  );

  const totalSelectedDavinci = calculateTotal(
    getAmounts(
      davinciItems?.filter((item: Dictionary<any>) => davinciTransferIds?.includes(item.id)),
      'davinciTransfersSubTotal',
      'id',
    ),
  );

  const onChangeIsForceCallback = (values: any) => {
    setForcedReconciliation(values.isForcedReconciliation);
  };

  const onChangeForceReasonCallback = (values: any) => {
    setForcedReconciliationReason(values.forcedReconciliationReason);
    if (!values.forcedReconciliationReason) {
      setErrors({ ...errors, forcedReconciliationReason: ['Field is required'] });
    } else {
      setErrors({ ...errors, forcedReconciliationReason: [] });
    }
  };

  const onChangeCallback = (values: any) => {
    setGroupAlias(values.groupAlias);
  };

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to reconcile selected transfers?"
      submitMethod={() =>
        reconciliate({
          groupAlias,
          journalEntryIds,
          bankTransactionIds,
          isForcedReconciliation,
          forcedReconciliationReason,
        })
      }
      permissionToCheck={RUN_MANUAL_RECONCILIATION}
      afterSubmit={{
        successMessage: 'Items have been successfully reconciled.',
        errorMessage: "Can't reconcile items.",
        execute: () => {
          setGroupAlias('');
          execute();
        },
      }}
      modalChildren={
        <>
          {isSensitiveDataIsShown && (
            <>
              <div className="mt-2">
                <b>Total bank transactions: {getWithThousandsSeparator(totalSelectedBank)}$</b>
              </div>
              <div>
                <b>Total davinci transfers: {getWithThousandsSeparator(totalSelectedDavinci)}$</b>
              </div>
            </>
          )}
          <div className="mt-2">
            <TextInput
              source="groupAlias"
              label="You can enter group alias"
              onChangeCallback={onChangeCallback}
              value={groupAlias}
            />
            <Checkbox
              label="Force reconciliation"
              source="isForcedReconciliation"
              onChangeCallback={onChangeIsForceCallback}
              value={isForcedReconciliation}
            />
            <TextInput
              source="forcedReconciliationReason"
              label="Forse reconciliation reason"
              onChangeCallback={onChangeForceReasonCallback}
              value={forcedReconciliationReason}
              isVisible={isForcedReconciliation}
              errorMessages={errors.forcedReconciliationReason || []}
              isRequired
            />
          </div>
        </>
      }
      className="mt-2"
      confirmBtnProps={{
        disabled:
          (isForcedReconciliation && !forcedReconciliationReason) || Object.values(errors).some(error => error.length),
      }}
    >
      Reconcile
    </ButtonWithConfirmation>
  );
};

export default ReconcileButton;
