/* istanbul ignore file */
import React from 'react';

import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import VacancyFormCreate from 'erp/recruitment/vacancy/createupdate/Create/VacancyFormCreate';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateVacancyBreadcrumbs from 'erp/recruitment/vacancy/createupdate/Create/Breadcrumbs';
import { useVacancyId, useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const CreateVacancy = () => {
  const { create, getValidationSchema, getById } = useVacancyService();
  const { getListUrl } = useVacancyUrl();
  const id = useVacancyId();
  let getDataFunc;

  if (id) {
    getDataFunc = () => getById(id);
  }

  return (
    <CreateForm
      getDataFunc={getDataFunc}
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Vacancy has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Vacancy" breadcrumbs={<CreateVacancyBreadcrumbs />}>
        <VacancyFormCreate isOneStop={false} />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateVacancy;
