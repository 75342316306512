/* istanbul ignore file */
// libraries
import React from 'react';
// components
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { useCompanyRolesService } from '../companyRolesService';
// styles
import './CompanyRolesInput.scss';

const CompanyRolesInput = (props: any) => {
  const { searchAll } = useCompanyRolesService();

  const mapRoleData = (roleName: any) => ({
    value: roleName,
    label: roleName,
  });

  return (
    <LoadDropdown
      loadDataMethod={searchAll}
      mapResults={mapRoleData}
      placeholder="Select role"
      className="load-dropdown"
      {...props}
    />
  );
};

export default CompanyRolesInput;
