import React from 'react';
import VerticalAddressInput from 'shared/uibuilder/form/input/VerticalAddressInput';
import './index.scss';
import { AddressInputProps } from 'shared/uibuilder/form/input/AddressInput';
import CopyableInput from 'shared/uibuilder/form/input/CopyableInput/index';

const COPY_SOURCES = ['addressLine1', 'addressLine2', 'city', 'country', 'state', 'zipCode'];

interface EmployeeAddressInputProps extends AddressInputProps {
  buttonText?: string;
  copySource?: any;
}

const CopyableEmployeeAddressInput = ({ copySource, source, ...props }: EmployeeAddressInputProps) => {
  return (
    <CopyableInput source={source} copySource={copySource} copySourceList={COPY_SOURCES} {...props}>
      <VerticalAddressInput source={source} {...props} hasAddressLine2 />
    </CopyableInput>
  );
};

export default CopyableEmployeeAddressInput;
