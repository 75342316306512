import useApplicationSettings, { ApplicationSettingsProvider } from 'shared/uibuilder/applicationSettingsContext';
import React from 'react';

interface UiThemeOverriderProps {
  overriddenLayouts: Dictionary<ReactComponent<any>>;
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Allows to override some layouts in UiTheme context.
 */
const UiThemeOverrider = ({ overriddenLayouts, children }: UiThemeOverriderProps) => {
  const currentUiThemeContext = useApplicationSettings();
  const { theme } = currentUiThemeContext;

  return (
    <ApplicationSettingsProvider
      value={{
        ...currentUiThemeContext,
        theme: {
          ...theme,
          ...overriddenLayouts,
        },
      }}
    >
      {children}
    </ApplicationSettingsProvider>
  );
};

export default UiThemeOverrider;
