import { useKernelApi } from 'api';
import leadValidation from 'crm/lead/shared/leadValidation';
import useTimelineService from 'shared/crud/timelineService';
import useCrudService from 'shared/crud';
import leadFilterValidation from 'crm/lead/List/filter/leadFilterValidation';

export const RESOURCE_URL = '/crm/leads';

export const CREATE_LEAD = 'CREATE_LEAD';
export const UPDATE_LEAD_FROM_LIST_PAGE = 'CREATE_LEAD';
export const CONVERT_LEAD = 'CONVERT_LEAD';
export const READ_LEADS_LIST = 'READ_LEADS_LIST';
export const READ_LEAD_NOTIFICATION_FE = 'LEAD_LEAD_NOTIFICATION_FE';

export const READ_FULL_LEADS_LIST = 'READ_FULL_LEADS_LIST';

export const DISQUALIFIED = 'DISQUALIFIED';
export const QUALIFIED = 'QUALIFIED';
export const POSTPONED = 'POSTPONED';

export const LEAD_STATUS = {
  NEW: 'New',
  ASSIGNED: 'Assigned',
  OPEN: 'Open',
  [DISQUALIFIED]: 'Disqualified',
  [QUALIFIED]: 'Qualified',
  [POSTPONED]: 'Postponed',
};

export const NOT_ACTIVE_STATUSES = Object.keys(LEAD_STATUS).filter(
  status => status === DISQUALIFIED || status === QUALIFIED || status === POSTPONED,
);

export const FINALLY_NOT_ACTIVE_STATUSES = Object.keys(LEAD_STATUS).filter(
  status => status === DISQUALIFIED || status === QUALIFIED,
);

export const LEAD_SOURCE = {
  ORGANIC_SEARCH: 'Organic Search',
  PERSONAL_REFERRAL: 'Personal Referral',
  ONLINE_REFERRAL: 'Online Referral',
  ONLINE_ADS: 'Online Ads',
  LINKEDIN_ADS: 'Linkedin',
  OFFLINE_EVENT: 'Offline Events',
  OFFLINE_ADS: 'Offline Ads',
};

export const LEAD_MEDIUM = {
  WEB_FORM: 'Web form',
  EMAIL: 'Email',
  PHONE_CALL: 'Phone call',
  LIVE_CHAT: 'Live chat or IM',
  OTHER: 'Other',
};

export const LEAD_DISQUALIFICATION_REASON = {
  UNREACHABLE: 'Lead is unreachable',
  CANCELLED_THE_PROJECT: 'Lead has cancelled the project',
  NOT_ENOUGH_MONEY: 'Lead has not enough money',
  IRRELEVANT_TECHNOLOGY_OR_SERVICE: 'Irrelevant technology and/or service',
  UNABLE_TO_QUALIFY: 'Unable to formally qualify for the project',
  TIMEFRAME_TOO_SHORT: 'Project timeframe is too short',
  NOT_VIABLE_ECONOMICALLY: 'Project is not viable economically',
  UNABLE_TO_WIN: 'Unable to win',
  LACK_OF_RESOURCES: 'Lack of available resources',
  PROJECT_ILLEGAL_UNETHICAL: 'Project is illegal and/or unethical',
  CONFLICT_OF_INTEREST: 'Conflict of interest',
  STRATEGIC_RISK: 'Project has substantial strategic risk(s)',
  ANOTHER_VENDOR: 'Another vendor selected',
  SPAM: 'SPAM',
  TEST_SUBMISSION: 'Test submission',
  DUPLICATE: 'Duplicate',
  AUTO_CLOSED: 'No updates have been made for more than 180 days',
};

export const LEAD_CONVERSION_STATUS = {
  NEW: 'NEW',
  READY_FOR_CONVERSION: 'READY_FOR_CONVERSION',
  CONVERSION_FAILED: 'CONVERSION_FAILED',
  CONVERSION_DONE: 'CONVERSION_DONE',
};

export const LEAD_GRADE = {
  A_PLUS: {
    shorthand: 'A+',
    name: 'Excellent quality',
    cssModificator: 'aplus',
    alias: 'A_PLUS',
    criterias: [
      'The person holds a C-level or similar position',
      'The job is matches our expertise',
      'The company is large and well-funded',
      'The lead is very responsive and cooperative',
      'There are documented requirements',
      'The lead appreciates our expertise and trusts our judgement',
    ],
  },
  A: {
    shorthand: 'A',
    name: 'Great quality',
    cssModificator: 'a',
    alias: 'A',
    criterias: [
      'The requested work is specific and described technical terms',
      'The person uses corporate email and represents a large company or a well-known brand',
      'The person has a high-level position and significant influence on the vendor choice and budgeting',
      'May publish a formal request for proposal (RFP)',
    ],
  },
  B_PLUS: {
    shorthand: 'B+',
    name: 'Good quality',
    cssModificator: 'bplus',
    alias: 'B_PLUS',
    criterias: [
      'The lead holds a position in a medium company',
      'The company has the ability to to spend money on IT activities',
      'The requirements are clear and often documented',
    ],
  },
  B: {
    shorthand: 'B',
    name: 'Normal quality',
    cssModificator: 'b',
    alias: 'B',
    criterias: [
      'The lead may use a non-corporate email',
      'The lead has influence on selecting a vendor',
      'The lead has a reasonable budget',
      'The lead has a clear business goal but may lack documented requirements',
    ],
  },
  B_MINUS: {
    shorthand: 'B-',
    name: 'Ok quality',
    cssModificator: 'bminus',
    alias: 'B_MINUS',
    criterias: [
      'A small project with low cost expectations',
      'The position of the lead is low and she collects information for the management',
      'The person is ready to help us dig deeper and outline requirements',
    ],
  },
  C: {
    shorthand: 'C',
    name: 'Low quality',
    cssModificator: 'c',
    alias: 'C',
    criterias: [
      'No clear requirements',
      'Very limited budget and lead avoids disclosing it',
      'Not willing to spend money on discovery, may suggest partnership schemes',
      'Asks generic questions without intent to buy',
      'May disappear once the salesperson reveal typical budgets',
    ],
  },
  D: {
    shorthand: 'D',
    name: 'Junk quality',
    cssModificator: 'd',
    alias: 'D',
    criterias: ['Irrelevant services', 'SPAM', 'Unrealistic expectations', 'Unethical or illegal offers'],
  },
};

export const LEAD_POSTPONED_REASON = {
  INSUFFICIENT_INTEREST: 'Lead shows insufficient interest',
  FINANCES_CHANGED: "Lead's finances changed",
  PRIORITIES_CHANGED: "Lead's priorities changed",
};

/**
 * Lead service.
 *
 * @returns {{getById: (function(*=): *), deleteById: ((function(*=): *)|Promise<any>), create: (function(*=): *), update: (function(*=, *=): *), getPermissions: (function(*=): *)}}
 */

const useLeadService = () => {
  const { sendPostRequest } = useKernelApi();

  const { getById: initialGetById, ...baseCrudRequests } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const { search: searchTimelineItems, create: createTimelineItem, getTimelinePageById } = useTimelineService({
    parentEntityUrl: `${RESOURCE_URL}/:id`,
    apiService: useKernelApi,
  });

  const visit = async id => {
    const url = `${RESOURCE_URL}/${id}/visit`;
    return sendPostRequest(url, id);
  };

  const getById = async params => {
    const leadData = await initialGetById(params);
    const data = JSON.parse(JSON.stringify(leadData));

    Object.entries(data).forEach(([key, value]) => {
      if (key === 'address' && value && value.country === 'United States of America') {
        data.address.country = 'United States';
      }
    });

    return data;
  };

  const getValidationSchema = () => Promise.resolve(leadValidation);

  const getFilterValidationSchema = () => Promise.resolve(leadFilterValidation);

  return {
    visit,
    getValidationSchema,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    ...baseCrudRequests,
    getById,
    getFilterValidationSchema,
  };
};

export default useLeadService;
