/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { BooleanField, TextField } from 'shared/uibuilder/field';
import { UPDATE_SERVICE } from 'erp/service/shared/serviceService';
import EditServiceButton from 'erp/service/shared/button/EditServiceButton';
import DeleteServiceButton from 'erp/service/shared/button/DeleteServiceButton';
import ViewServicePageMenu from 'erp/service/Show/ViewServicePageMenu';
import SingleServiceBreadcrumbs from 'erp/service/shared/SingleServiceBreadcrumbs';
import ServiceEntityHeader from 'erp/service/shared/ServiceEntityHeader';
import { EmployeeLinkField } from 'erp/employee';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import BudgetChildLinkField from 'erp/budget/shared/field/BudgetChildLinkField';

const ViewServicePageContent = () => {
  return (
    <ShowPageLayout
      headerButtons={[
        <EditServiceButton key="edit-service" permissionToCheck={UPDATE_SERVICE} />,
        <DeleteServiceButton key="delete-service" />,
      ]}
      menu={<ViewServicePageMenu />}
      breadcrumbs={<SingleServiceBreadcrumbs />}
      entityHeader={<ServiceEntityHeader />}
      hasSeparateSections
    >
      <ShowSection title="Service Information">
        <SectionRow>
          <TextField source="name" label="Name" />
          <EmployeeLinkField source="ownerId" nameSource="ownerName" label="Owner" />
          <TextField source="rbsTreeNode.name" label="RBS Node" />
        </SectionRow>
        <SectionRow>
          <BudgetLinkField label="Default Budget" source="defaultBudgetId" nameSource="defaultBudget.name" />
          <FieldListLayout source="availableBudgets" label="Available Budgets">
            <BudgetChildLinkField />
          </FieldListLayout>
          <BooleanField source="assignmentForbidden" label="Assignment Forbidden" />
        </SectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewServicePageContent;
