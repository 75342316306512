/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useProjectWeeklyReportsUrl } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';

const EditProjectWeeklyReportButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useProjectWeeklyReportsUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditProjectWeeklyReportButton;
