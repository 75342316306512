/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { SICK_DAY_REASONS } from '../leaveService';

const SickDayReasonField = props => {
  const { getValue, getLabel } = useFieldHelper(props);

  return <EnumField label={getLabel()} value={getValue()} options={SICK_DAY_REASONS} />;
};

export default SickDayReasonField;
