import React from 'react';
import { useIntegrationTestRoutes } from 'integration-tests/Router';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { CreateForm } from 'shared/uibuilder/form/index';
import useTestingCrudService  from 'integration-tests/testingCrudService';
// eslint-disable-next-line import/no-named-as-default
import EntityForm, { EntityHeader } from './EntityForm';
import info from './testData';

const { createdAt, updatedAt, createdByName, updatedByName, fullName } = info;
const breadcrumbs = null;

const IntegrationCreateFormPage = () => {
  const { create, getValidationSchema } = useTestingCrudService();
  const { createRoute } = useIntegrationTestRoutes();

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        name: 'John',
        fullName,
        createdAt,
        updatedAt,
        createdByName,
        updatedByName,
      }}
      afterSubmit={{
        redirect: createRoute,
        message: 'You are awesome!',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        breadcrumbs={breadcrumbs}
        entityHeader={<EntityHeader />}
        title="Create: Entity"
      >
        <EntityForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default IntegrationCreateFormPage;
