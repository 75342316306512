import momentTimezone from 'moment-timezone';
import { orderBy } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getTimezonesOptions = () => {
  const timeZonesNames = momentTimezone.tz
    .names()
    .filter(zone => zone.indexOf('/') >= 0)
    .filter(zone => !zone.includes('Etc'));

  let mappedTimeZones = timeZonesNames
    .map(zone => ({
      id: zone,
      city: zone
        .split('/')
        .slice(-1)[0]
        .replace(/_/i, ' '),
      utc: `(UTC${momentTimezone.tz(zone).format('Z')})`,
    }))
    .filter(
      (outerItem, index, currentArray) =>
        currentArray.findIndex(innerItem => innerItem.city === outerItem.city && innerItem.utc === outerItem.utc) ===
        index,
    );
  mappedTimeZones = orderBy(mappedTimeZones, ['utc'], ['asc']);

  const options = mappedTimeZones.map(zone => ({
    value: zone.id,
    label: `${zone.utc} ${zone.city}`,
  }));

  return options;
};
