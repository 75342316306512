/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';
import useAssessmentService from 'erp/employee/assessment/assessmentService';

const DELETE_MESSAGE = 'Do you really want to delete this assessment?';

const DeleteAssessmentButton = (props: any) => {
  const { getValue } = useFieldHelper(props);
  const { getListUrl } = useAssessmentUrl();
  const { deleteById } = useAssessmentService();
  const { deleteFromList = () => {} } = useListContext();
  const id = getValue();

  return (
    <DeleteButton
      displayMessage={DELETE_MESSAGE}
      deleteMethod={async () => {
        await deleteById(id);
        deleteFromList(id);
      }}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Assessment has been successfully deleted.',
        failureMessage: `Can't delete assessment.`,
      }}
      {...props}
    />
  );
};

export default DeleteAssessmentButton;
