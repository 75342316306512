/* istanbul ignore file */
import React, { memo } from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import CandidateApplicationSourceField from 'erp/candidate/shared/field/CandidateApplicationSourceField';
import CandidateSearchLinkField from 'erp/candidate/shared/field/CandidateSearchLinkField';
import ApplicationStageField from 'erp/recruitment/applications/shared/field/ApplicationStageField';
import { DateField, TextField, BadgeField } from 'shared/uibuilder/field';
import CandidateApplicationStatusBadge from 'erp/candidate/applications/shared/badge/CandidateApplicationStatusBadge';
import ApplicationCandidateScoreBadge from 'erp/recruitment/applications/shared/badge/ApplicationCandidateScoreBadge';
import CandidateApplicationSourceTypeField from 'erp/candidate/applications/shared/field/CandidateApplicationSourceTypeField';
import SetApplicationStatusButton from 'erp/candidate/applications/status/SetApplicationStatusButton';
import useAuthorization from 'shared/authorization/authorizationService';
import { GROUP_APPLICATION, CHANGE_APPLICATION_STATUS } from 'erp/recruitment/applications/shared/applicationService';
import RowWithDetailsPanel from 'shared/uibuilder/list/layout/DataGridLayout/RowWithDetailsPanel';
import ProgressField from 'erp/recruitment/applications/shared/field/ProgressField';
import TimeInProgressField from 'erp/recruitment/applications/shared/field/TimeInProgressField';
import LastTouchApplicationField from 'erp/recruitment/applications/shared/field/LastTouchApplicationField';
import { CandidateApplicationStatus } from 'erp/candidate/applications/shared/candidateApplicationService';
import { EmployeeLinkField } from 'erp/employee';

export const DAYS_COLOR_BORDER = {
  greenBorder: 1.5,
  yellowBorder: 3.5,
  lightRedBorder: 7.5,
};

const ApplicationsDataGrid = ({
  showTotal,
  hasStageField = true,
  isSmallTable,
}: {
  showTotal?: boolean;
  hasStageField?: boolean;
  isSmallTable?: boolean;
}) => {
  const { isGranted } = useAuthorization();
  const canGroupApplications = isGranted(GROUP_APPLICATION);

  return (
    <DataGridLayout
      isSmallTable
      showTotal={showTotal}
      tableRowComponent={RowWithDetailsPanel}
      tableWrapperClassName="applications-table-wrapper"
    >
      {
        [
          canGroupApplications ? <ProgressField source="progress" label=" " isDetailsPanel width={40} /> : null,
          <TextField source="candidateApplication.id" label="ID" isSortable />,
          <CandidateSearchLinkField
            idSource="candidateApplication.candidate.id"
            source="candidateApplication.candidate.name"
            label="Candidate"
          />,
          <VacancyLinkField
            source="candidateApplication.vacancyAlias"
            nameSource="candidateApplication.vacancyName"
            label="Vacancy"
            getLinkText={({ id, name }: { id: string; name?: string }) => `${id} ${name}`}
          />,
          canGroupApplications ? (
            <BadgeField
              badges={[<ApplicationCandidateScoreBadge source="score" label="Match score" isSortable />]}
              label="Match score"
            />
          ) : null,
          <BadgeField
            badges={[
              <CandidateApplicationStatusBadge source="candidateApplication.status" label="Status" isSortable />,
            ]}
            label="Status"
            width={95}
          />,
          <DateField source="candidateApplication.createdAt" label="Created At" isSortable />,
          <EmployeeLinkField
            source="candidateApplication.createdById"
            nameSource="candidateApplication.createdByName"
            label="Author"
            isSortable
          />,
          <TimeInProgressField
            elapsedTimeSource="candidateApplication.createdAt"
            completedAtSource="completedAt"
            label="Time to hire"
          />,
          <LastTouchApplicationField
            label="Last Touch"
            source="candidateApplication.lastTouchAt"
            statusSource="candidateApplication.status"
            colorBorders={DAYS_COLOR_BORDER}
            skipStates={[CandidateApplicationStatus.PROCESSED]}
          />,
          <DateField source="completedAt" label="Completed At" isSortable />,
          <CandidateApplicationSourceTypeField
            source="candidateApplication.sourceType"
            label="Source type"
            isSortable
          />,
          <CandidateApplicationSourceField source="candidateApplication.source" isSortable />,
          hasStageField && canGroupApplications ? (
            <ApplicationStageField source="stage" label="Stage" width={160} />
          ) : null,
          isGranted(CHANGE_APPLICATION_STATUS) ? (
            <SetApplicationStatusButton
              candidateIdSource="candidateApplication.candidate.id"
              candidateApplicationIdSource="candidateApplication.id"
              statusSource="candidateApplication.status"
              sx={isSmallTable ? { height: '2em', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}
            />
          ) : null,
        ].filter(Boolean) as React.ReactElement[]
      }
    </DataGridLayout>
  );
};

export default memo(ApplicationsDataGrid);
