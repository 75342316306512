/* istanbul ignore file */
export default {
  isInternal: {
    type: 'boolean',
    required: true,
  },
  name: {
    type: 'string',
    required: true,
    maxLength: 255,
  },
  durationInMinutes: {
    type: 'number',
    minValue: 0,
  },
  competencies: {
    type: 'array',
    required: true,
  },
  location: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      id: {
        type: 'id',
        required: true,
      },
      name: {
        type: 'name',
        required: true,
      },
    },
  },
  description: {
    type: 'string',
  },
};
