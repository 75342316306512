import React from 'react';
import DropDownFilterInputOption from 'shared/uibuilder/list/filter/layout/ListFilterLayout/filter/DropDownFilterInputOption';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';

interface DropdownListLayoutProps {
  selectedValues?: Option[];
  onChangeCallback: any;
}

const MultipleDropdownListLayout = ({ selectedValues, onChangeCallback }: DropdownListLayoutProps) => {
  const handleClickSelectedItem = (optionValue: string | number) => {
    const newFilterValues = selectedValues?.filter(({ value }) => value !== optionValue);

    onChangeCallback({ target: { value: newFilterValues } });
  };

  return (
    <>
      {selectedValues?.map(({ value: optionValue, label: optionLabel }) => (
        <DropDownFilterInputOption
          value={optionValue}
          isMulti
          isSelected
          component="div"
          onClick={() => handleClickSelectedItem(optionValue)}
          key={optionValue}
        >
          {optionLabel}
        </DropDownFilterInputOption>
      ))}
    </>
  );
};

export default MultipleDropdownListLayout;
