/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import OneStopListPage from 'oneStop/List';
import OneStopCreatePage from 'oneStop/Create';
import OneStopHistoryPage from 'myActivity/history/List';
import { ProtectedRouter } from 'routing';
import { Router, Route, Redirect } from 'shared/routing';
import {
  IsOneStopHistoryEnabled,
  useOneStopRoute,
  ONE_STOP_PATH,
  ONE_STOP_ROOT_PATH,
} from 'oneStop/OneStopRouterHelper';
import { IsMyActivityEnabled, ONE_STOP_HISTORY_PATH, useMyActivityRoute } from 'myActivity/MyActivityRouterHelper';
import OneStopRequestRedirect from 'oneStop/Create/OneStopRequestRedirect';
import OneStopHistoryRedirect from 'myActivity/history/OneStopHistoryRedirect';

const OneStopRouter: Router = () => {
  const { listRoute, singleEntityRoute, oldSingleEntityRoute, oldSingleHistoryRoute } = useOneStopRoute();
  const { historyRoute } = useMyActivityRoute();

  return (
    <ProtectedRouter basePath={ONE_STOP_ROOT_PATH}>
      <Switch>
        {IsOneStopHistoryEnabled() ? <Redirect from={ONE_STOP_ROOT_PATH} to={ONE_STOP_PATH} exact /> : null}
        {IsMyActivityEnabled() ? (
          <Redirect from={`${ONE_STOP_ROOT_PATH}/history`} to={ONE_STOP_HISTORY_PATH} exact />
        ) : (
          <Route path={historyRoute} exact>
            <OneStopHistoryPage />
          </Route>
        )}

        <Route path={listRoute} exact>
          <OneStopListPage />
        </Route>

        <Route path={singleEntityRoute} exact>
          <OneStopCreatePage />
        </Route>

        <Route path={oldSingleEntityRoute} exact>
          <OneStopRequestRedirect />
        </Route>

        {IsMyActivityEnabled() ? (
          <Route path={oldSingleHistoryRoute} exact>
            <OneStopHistoryRedirect />
          </Route>
        ) : null}

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

OneStopRouter.getRouterPath = () => ONE_STOP_ROOT_PATH;

export default OneStopRouter;
