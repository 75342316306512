/* istanbul ignore file */
import React from 'react';
import SingleCandidateBreadcrumbs from 'erp/candidate/shared/SingleCandidateBreadcrumbs';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidateCommunicationBreadcrumbs = () => {
  const id = useCandidateId();
  const { getCommunicationUrl } = useCandidateUrl();

  return (
    <SingleCandidateBreadcrumbs>
      <BreadcrumbItem link={getCommunicationUrl(id)}>Communication</BreadcrumbItem>
    </SingleCandidateBreadcrumbs>
  );
};

export default CandidateCommunicationBreadcrumbs;
