/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const ASSIGNMENT_LANGUAGE = {
  ENGLISH: 'English',
};

const AssignmentLanguageDropdown = (props: any) => {
  return <EnumDropdown {...props} options={ASSIGNMENT_LANGUAGE} placeholder="Select Language" />;
};

export default AssignmentLanguageDropdown;
