/* istanbul ignore file */
import React from 'react';
import { useQualificationUrl } from 'erp/employee/qualification/QualificationRouter';
import QualificationMenuItem from 'erp/employee/qualification/shared/menuitem/QualificationMenuItem';
import HorizontalMenu from 'shared/uibuilder/menu/HorizontalMenu';
import { EmployeeSpecialization } from 'erp/employee/qualification/shared/employeeSpecializationService';
import CreateQualificationButton from '../List/button/CreateQualificationButton';
import { CREATE_EMPLOYEE_QUALIFICATIONS } from 'erp/employee/employeeService';
import './QualificationMenu.scss';

const QualificationMenu = ({ specializations }: { specializations: Nullable<EmployeeSpecialization[]> }) => {
  const { getSingleEntityUrl } = useQualificationUrl();

  return (
    <HorizontalMenu className={specializations?.length ? 'qualification-menu__wrapper' : 'mb-1'}>
      {specializations?.map(specialization => (
        <QualificationMenuItem
          key={`horizontal-menu-item-${specialization.id}`}
          specialization={specialization}
          linkUrl={getSingleEntityUrl(specialization.id)}
        />
      ))}
      <CreateQualificationButton permissionToCheck={CREATE_EMPLOYEE_QUALIFICATIONS} />
    </HorizontalMenu>
  );
};

export default QualificationMenu;
