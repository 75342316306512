/* istanbul ignore file */
const opportunityFilterValidation = {
  createdAt: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: {
          value: '$createdAt.le',
          message: 'Please choose date before or at "to" date',
        },
      },
      le: {
        type: 'date',
        minDate: {
          value: '$createdAt.ge',
          message: 'Please choose date after or at "from" date',
        },
      },
    },
  },

  closeDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: {
          value: '$closeDate.le',
          message: 'Please choose date before or at "to" date',
        },
      },
      le: {
        type: 'date',
        minDate: {
          value: '$closeDate.ge',
          message: 'Please choose date after or at "from" date',
        },
      },
    },
  },
};

export default opportunityFilterValidation;
