/* istanbul ignore file */
import React from 'react';
import { EMPLOYEE_WORKING_STATUSES } from 'erp/employee/employeeService';
import { EnumField } from 'shared/uibuilder/field';

const EmployeeWorkingStatusField = props => {
  return <EnumField {...props} options={EMPLOYEE_WORKING_STATUSES} />;
};

export default EmployeeWorkingStatusField;
