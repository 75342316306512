import useWasteService from '../../useWasteService';
import useWasteUrl from '../../useWasteUrl';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import React from 'react';
import WasteForm from '../WasteForm';
import CreateWasteBreadcrumbs from './Breadcrumbs';
import { FormFieldsData } from '../../../../../shared/uibuilder/form/FormContext';

const CreateWastePage = () => {
  const { create, getValidationSchema } = useWasteService();
  const { getListUrl } = useWasteUrl();

  return (
    <CreateForm
      submitFormFunc={create as (values: FormFieldsData) => Promise<object | void>}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Waste has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Waste" breadcrumbs={<CreateWasteBreadcrumbs />}>
        <WasteForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateWastePage;
