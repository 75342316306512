import { useEffect } from 'react';
import useSyberryTodayService, { BACKLOG_CATEGORIES, getSyberryTodayError } from 'syberryToday/useSyberryTodayService';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

interface ProjectIssueContextMenuData {
  item: ListItem;
  updateInList: (entity: ListItem) => void;
}

const useProjectIssue = ({ item, updateInList }: ProjectIssueContextMenuData) => {
  const { id, backlogCategory } = item;
  const { selectIssue, unSelectIssue, selectedIssues = [], setLoading = () => {} } = useSyberryTodayContext();
  const isSelected = backlogCategory === BACKLOG_CATEGORIES.TODAY;
  const savedIsSelected = selectedIssues.includes(id);

  const { updateIssueCategory } = useSyberryTodayService();
  const { addMessage } = useMessageService();

  const saveCategoryChanges = async (newBacklogCategory: BACKLOG_CATEGORIES) => {
    try {
      setLoading(true);
      await updateIssueCategory({
        backlogCategory: newBacklogCategory,
        id: item.id,
      });

      updateInList({ ...item, backlogCategory: newBacklogCategory });
    } catch (e) {
      const errorMessage = await getSyberryTodayError(e, "Can't update issue category");

      addMessage(new ErrorMessage(errorMessage));
    } finally {
      setLoading(false);
    }
  };

  const onPlanTask = async () => saveCategoryChanges(BACKLOG_CATEGORIES.TODAY);

  const unSelectIssueBtnClick = async () => saveCategoryChanges(BACKLOG_CATEGORIES.DO_LATER);

  useEffect(() => {
    if (savedIsSelected === isSelected) {
      return;
    }

    if (savedIsSelected) {
      unSelectIssue(id);
    } else {
      selectIssue(id);
    }
    // Suppressed warnings because we only need to call useCallback callback if savedIsSelected, isSelected or id are updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedIsSelected, isSelected, id]);

  return {
    isSelected,
    onPlanTask,
    unSelectIssueBtnClick,
  };
};

export default useProjectIssue;
