/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import useEmployeeQualificationCalculation from 'erp/employee/qualification/shared/employeeQualificationCalculation';
import useEmployeeQualificationService, {
  EmployeeQualification,
} from 'erp/employee/qualification/shared/employeeQualificationService';
import { UPDATE_EMPLOYEE_QUALIFICATIONS } from 'erp/employee/employeeService';
import ChangeQualificationCompletenessButton from 'erp/employee/qualification/List/button/ChangeQualificationCompletenessButton';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useQualificationId } from 'erp/employee/qualification/QualificationRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import './QualificationCollapseHeader.scss';

interface QualificationsHeaderProps {
  widthOfApplyButtonColumn?: number;
  qualification: EmployeeQualification;
}

const QualificationsHeader = ({
  qualification: { id: qualificationId, title, completeness, competencies = [] },
  widthOfApplyButtonColumn,
}: QualificationsHeaderProps) => {
  const { isGranted } = useAuthorization();
  const employeeId = useEmployeeId() || '';
  const specializationId = useQualificationId();
  const { updateQualificationCompleteness } = useEmployeeQualificationService();
  const { calculateWeightOfAcceptedStatuses } = useEmployeeQualificationCalculation();
  const weightOfCurrentStatuses = calculateWeightOfAcceptedStatuses(competencies, 'rootState.status');
  const isEmptyCompetencies = !competencies.length;

  return (
    <div className="d-flex align-items-center collapse-title-wrapper">
      <h3 className="mr-auto collapse-title">{title}</h3>
      <span className="applied-status-weight d-flex align-items-center">
        Applied:
        {isEmptyCompetencies && isGranted(UPDATE_EMPLOYEE_QUALIFICATIONS) ? (
          <span
            role="button"
            tabIndex={0}
            onKeyPress={() => {}}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
            }}
          >
            <ChangeQualificationCompletenessButton
              completeness={completeness}
              permissionToCheck={UPDATE_EMPLOYEE_QUALIFICATIONS}
              submitMethod={(newCompletenessValue: number) =>
                updateQualificationCompleteness({
                  employeeId,
                  specializationId,
                  qualificationId,
                  completeness: newCompletenessValue,
                })
              }
            />
          </span>
        ) : (
          <span className={classnames('ml-1 weight-value', { 'empty-value': !completeness })}>{completeness}%</span>
        )}
      </span>
      <span className="apply-column" style={{ width: widthOfApplyButtonColumn }} />
      <span className="current-status-weight">
        {!isEmptyCompetencies && (
          <>
            Current:
            <span className={classnames('ml-1 weight-value', { 'empty-value': !weightOfCurrentStatuses })}>
              {weightOfCurrentStatuses}%
            </span>
          </>
        )}
      </span>
    </div>
  );
};

export default QualificationsHeader;
