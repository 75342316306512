/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';
import './RemoveListItemButton.scss';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface RemoveListItemButtonProps {
  isVisible?: boolean;
  onClickCallback: () => void;
  isFirstRow?: boolean;
  specificStyle?: React.CSSProperties | undefined;
  sx?: SxProps<Theme>;
}

const RemoveListItemButton = ({
  isVisible,
  onClickCallback,
  isFirstRow = false,
  specificStyle,
  sx,
}: RemoveListItemButtonProps) => {
  return isVisible ? (
    <Button
      style={specificStyle}
      size="small"
      className={`remove-list-item ${isFirstRow ? 'first-button' : ''}`}
      onClick={onClickCallback}
      sx={{
        marginBottom: isFirstRow ? '25px!important' : '',
        marginTop: isFirstRow ? '30px!important' : '6px !important',
        ...(sx || {}),
      }}
    >
      <RemoveIcon />
    </Button>
  ) : null;
};

export default RemoveListItemButton;
