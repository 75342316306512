/* istanbul ignore file */
import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import SubmitFormButton from 'erp/employee/AccessLog/SubmitFormButton';
import LoadingFormLayout from 'uibuilder/layout/form/input/LoadingFormLayout';
import useCostAccountingService from 'erp/costaccounting/shared/costAccountingService';
import costAccountingDashboardValidation from 'erp/costaccounting/dashboard/costAccountingDashboardValidation';
import MappingMetricsWrapper from 'erp/costaccounting/dashboard/MappingMetricsWrapper';
import FormSection from 'shared/layout/form/FormSection';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const CostAccountingDashboard = () => {
  const { getCostReport } = useCostAccountingService();

  const submitForm = async (data: any) => {
    return getCostReport(data);
  };

  const getValidationSchema = () => {
    return Promise.resolve(costAccountingDashboardValidation);
  };

  return (
    <ListPageLayout header={<ListHeader label="Cost Accounting" icon="fa-clock-o" />}>
      <FormTemplate submitFormFunc={submitForm} getValidationSchemaFunc={getValidationSchema} handleDirty={false}>
        <FormRow colXl={2}>
          <DateInput label="Period from" source="from" dateFormat={DATE_FORMAT.API_ONLY_DATE} />
          <DateInput label="To" source="to" dateFormat={DATE_FORMAT.API_ONLY_DATE} />
        </FormRow>

        <FormSection title="Mapping Metrics">
          <MappingMetricsWrapper fromDateSource="from" toDateSource="to" />
        </FormSection>

        <SubmitFormButton className="mr-2">Download Cost Report</SubmitFormButton>

        <LoadingFormLayout />
      </FormTemplate>
    </ListPageLayout>
  );
};

export default CostAccountingDashboard;
