/* istanbul ignore file */
import React from 'react';
import { element, node } from 'prop-types';

const EmployeeContractEndDateLayout = ({ dateField, notification }) => {
  return (
    <div className="d-flex align-items-center">
      {dateField}
      {notification}
    </div>
  );
};

EmployeeContractEndDateLayout.propTypes = {
  dateField: element,
  notification: node,
};

EmployeeContractEndDateLayout.defaultProps = {
  dateField: null,
  notification: null,
};

export default EmployeeContractEndDateLayout;
