/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import { ONE_STOP_PATH } from 'oneStop/OneStopRouterHelper';
import Logo from 'images/logo-white.svg';
import { styled } from '@mui/material/styles';
import SidebarItem from './SidebarItem';
import { useAppSidebarContext } from './AppSidebarNavContext';
import HeaderMenu from 'shared/uibuilder/menu/HeaderMenu';
import useDeviceDetectorService, { MAX_LG_WIDTH } from 'oneStop/deviceDetectorService';

export const DRAWER_WIDTH = '226px';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: '90vw',
    maxWidth: 290,
    border: 0,
    padding: '20px',
    background: theme.vars.palette.common.sidebarMenuBg,
    [theme.breakpoints.between('lg', 'xxxl')]: {
      padding: '12px  10px',
    },
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH,
    },
  },
  '& .MuiListItemIcon-root': {
    color: theme.vars.palette.common.sidebarMenuText,
    opacity: 0.65,
    minWidth: '31px',
    '& .MuiSvgIcon-root': {
      width: '0.85em',
      height: '0.85em',
    },
  },
  '& .MuiListItemButton-root': {
    padding: '6px 6px 6px 7px',
    '&:hover': {
      background: theme.vars.palette.primary.main,
    },
    '&.active': {
      backgroundColor: theme.vars.palette.common.sidebarMenuActive,
    },
  },
  '& .MuiListItemText-primary': {
    lineHeight: '1.45',
    color: theme.vars.palette.common.sidebarMenuText,
  },
  '& .MuiDivider-root': {
    border: 0,
    margin: '12px 0',
  },
  '& .MuiCollapse-root': {
    '& .MuiListItemIcon-root': { '&:empty': { display: 'none' } },
    '& .MuiListItem-root': {
      position: 'relative',
      paddingLeft: '29px',
      marginTop: 0,
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '2px',
        height: '100%',
        background: theme.vars.palette.common.sidebarMenuText,
        opacity: 0.35,
        left: '16px',
        top: '0',
      },
      '& + .MuiCollapse-root': {
        marginBottom: 0,
        '& .MuiListItem-root': {
          paddingLeft: '55px',
        },
      },
    },
    '& .MuiListItemButton-root': {
      paddingLeft: '10px',
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.vars.palette.common.sidebarMenuText,
    opacity: 0.65,
  },
}));

const AppSidebarNav = (props: any) => {
  const {
    navConfig: { sidebarItems = [], headerMenuData = [], clickHandler },
    getIsActiveLink,
    location,
  } = props;
  const { mobileOpen = false, handleDrawerToggle = () => {} } = useAppSidebarContext();
  const { isDesktop } = useDeviceDetectorService({ maxWidth: MAX_LG_WIDTH, defaultValue: false });

  const drawer = (
    <>
      <Box sx={{ paddingTop: '7px', marginBottom: '10px', marginLeft: '6px' }}>
        <Link to={ONE_STOP_PATH}>
          <img src={Logo} alt="Syberry logo" width={103} />
        </Link>
      </Box>
      <List>
        {sidebarItems?.map((item: any) => (
          <SidebarItem
            key={`${item.url}-${item.name}`}
            {...item}
            clickHandler={clickHandler}
            getIsActiveLink={getIsActiveLink}
            location={location}
          />
        ))}
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { lg: DRAWER_WIDTH },
        flexShrink: { lg: 0 },
        zIndex: { xs: 10000, lg: 1 },
      }}
      aria-label="mailbox folders"
    >
      {!isDesktop ? (
        <StyledDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </StyledDrawer>
      ) : (
        <>
          <HeaderMenu menuData={headerMenuData} />

          <StyledDrawer
            variant="permanent"
            sx={{
              height: '100%',
              '& .MuiDrawer-paper': { position: 'static', minHeight: 'calc(100vh - 25px)' },
            }}
            open
          >
            {drawer}
          </StyledDrawer>
        </>
      )}
    </Box>
  );
};

export default AppSidebarNav;
