/* istanbul ignore file */
import React from 'react';
import { oneOfType, string, func } from 'prop-types';
import { TextInput } from 'shared/uibuilder/form/input';
import MilitaryRegistrationCategoryDropdown from 'erp/employee/shared/input/MilitaryRegistrationCategoryDropdown';
import MilitaryContingentDropdown from 'erp/employee/shared/input/MilitaryContingentDropdown';
import FormRow from 'shared/layout/form/FormRow';

const TOOLTIPS = {
  registrationGroup: 'Группа учета (для офицеров запаса не заполняется, для призывников - "призывник")',
  registrationCategory:
    'Категория учета (п. 7 военного билета для офицеров запаса, п.23 военного билета для остальных)',
  militaryContingent: 'Состав (п. 25 военного билета или п. 6 для офицеров запаса)',
  title: 'Воинское звание (п. 20 военного билета)',
  specialization: 'Военно-учетная специальность (п. 26 военного билета или п. 5 для офицеров запаса)',
  militarySuitability:
    'Годность к военной службе (на основании разделов V и IX военного билета, для офицеров запаса не заполняется, для офицеров запаса, снятых с воинского учета — п. 20 военного билета)',
  militaryCommissariat:
    'Название военного комиссариата по месту жительства (Наименование военкомата, в котором военнообязанный состоит на воинском учете)',
  registrationNumber:
    'Состоит на специальном учете № (серия и номер удостоверения об отсрочке, дата оформления отсрочки военным комиссариатом)',
};

const MilitaryInput = ({ source, className }) => {
  return (
    <FormRow>
      <TextInput
        source={`${source}.registrationGroup`}
        label="Registration Group"
        tooltip={TOOLTIPS.registrationGroup}
        className={className}
      />
      <MilitaryRegistrationCategoryDropdown
        source={`${source}.registrationCategory`}
        label="Registration Category"
        tooltip={TOOLTIPS.registrationCategory}
        className={className}
        placeholder="Registration Category"
      />
      <MilitaryContingentDropdown
        source={`${source}.militaryContingent`}
        label="Contingent"
        tooltip={TOOLTIPS.militaryContingent}
        className={className}
        placeholder="Contingent"
      />
      <TextInput source={`${source}.title`} label="Title" tooltip={TOOLTIPS.title} className={className} />
      <TextInput
        source={`${source}.specialization`}
        label="Military Specialization"
        tooltip={TOOLTIPS.specialization}
        className={className}
      />
      <TextInput
        source={`${source}.militarySuitability`}
        label="Suitability"
        tooltip={TOOLTIPS.militarySuitability}
        className={className}
      />
      <TextInput
        source={`${source}.militaryCommissariat`}
        label="Military Commissariat"
        tooltip={TOOLTIPS.militaryCommissariat}
        className={className}
      />
      <TextInput
        source={`${source}.registrationNumber`}
        label="Special Registration Number"
        tooltip={TOOLTIPS.registrationNumber}
        className={className}
      />
    </FormRow>
  );
};

MilitaryInput.propTypes = {
  source: string.isRequired,
  className: oneOfType([string, func]),
};

MilitaryInput.defaultProps = {
  className: '',
};

export default MilitaryInput;
