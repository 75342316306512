/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import useOpportunityService, {
  OPPORTUNITY_STAGES_ALLOWED_TRANSITIONS,
  CLOSED_LOST,
  CLOSED_WON,
  UPDATE_OPPORTUNITY,
  CONTRACT,
  SIGNING,
} from 'crm/opportunity/shared/opportunityService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import ClosedReasonInputs from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton/CloseReasonInputs';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import PresaleOutputInputs from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton/PresaleOutputInputs';

const MarkStateAsCompletedButton = ({
  activeStep,
  handleStep,
}: {
  activeStep: string;
  handleStep: (step: string) => void;
}) => {
  const id = useOpportunityId();
  const { update } = useOpportunityService() as any;
  const { getLastPresaleOutput, updateOutput } = usePresaleOutputService();
  const { data, setData = () => {} } = useShowContext();
  const { addMessage } = useMessageService();
  const stepAllowedTransitions = OPPORTUNITY_STAGES_ALLOWED_TRANSITIONS[activeStep] || [];
  const nextStep = stepAllowedTransitions[0];
  const [formErrors, setFormErrors] = useState<Dictionary<string[]>>({});
  const [formData, setFormData] = useState<Dictionary<any>>({ stateStep: nextStep });
  const [outputFormErrors, setOutputFormErrors] = useState<Dictionary<string[]>>({});
  const [outputFormData, setOutputFormData] = useState<Nullable<Dictionary<any>>>(null);

  const isClosedReasonInputsVisible = nextStep === CLOSED_WON || nextStep === CLOSED_LOST;

  const isPresaleOutputChangeVisible = nextStep === CONTRACT || nextStep === SIGNING;

  useEffect(() => {
    setFormData({ stateStep: nextStep });
  }, [nextStep]);

  useEffect(() => {
    (async () => {
      if (isPresaleOutputChangeVisible) {
        const lastOutput = await getLastPresaleOutput(id);
        setOutputFormData(lastOutput);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  const handleComplete = async () => {
    if (outputFormData) {
      try {
        await updateOutput(id, outputFormData.id, outputFormData);
      } catch (e) {
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
        return;
      }
    }
    try {
      const response = await update(id, {
        ...data.getData(),
        ...formData,
        stateStep: nextStep,
      });
      handleStep(nextStep);
      setData(response);
    } catch (e) {
      const result = await (e as any).json();
      addMessage(new ErrorMessage(result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
    }
  };

  if (!nextStep) {
    return null;
  }

  const getModalChildren = () => {
    if (isClosedReasonInputsVisible) {
      return (
        <div className="mt-5">
          <ClosedReasonInputs
            setFormErrors={setFormErrors}
            setFormData={setFormData}
            formData={formData}
            formErrors={formErrors}
          />
        </div>
      );
    }
    if (isPresaleOutputChangeVisible && outputFormData) {
      return (
        <div className="mt-5">
          <PresaleOutputInputs
            setFormErrors={setOutputFormErrors}
            setFormData={setOutputFormData}
            formData={outputFormData}
            formErrors={outputFormErrors}
          />
        </div>
      );
    }
    return <></>;
  };

  const getModalAdditionalMessage = () => {
    if (isClosedReasonInputsVisible) {
      return 'Please, fill out the inputs below.';
    }
    if (isPresaleOutputChangeVisible && outputFormData) {
      return 'Please, verify that Team structure and Expected Project start provided in this form are actual. If not, please, change.';
    }
    return '';
  };

  return (
    <ButtonWithConfirmation
      key={activeStep}
      displayMessage={`Do you really want to mark this state as completed? ${getModalAdditionalMessage()}`}
      submitMethod={handleComplete}
      className="step-button"
      modalChildren={getModalChildren()}
      modalSize={isPresaleOutputChangeVisible && outputFormData ? 'lg' : 'sm'}
      permissionToCheck={UPDATE_OPPORTUNITY}
      confirmBtnProps={{
        disabled:
          (isClosedReasonInputsVisible &&
            (Object.values(formErrors).some(error => error.length) ||
              !formData.closedReason?.primaryReason ||
              !formData.closedReason?.summary)) ||
          (isPresaleOutputChangeVisible && Object.values(outputFormErrors).some(error => error.length)),
      }}
    >
      Mark state as completed
    </ButtonWithConfirmation>
  );
};

export default MarkStateAsCompletedButton;
