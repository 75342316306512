/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useEducationService from 'erp/employee/Education/shared/educationService';
import { useEducationId } from 'erp/employee/Education/EducationRouter';

const EducationContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useEducationService();
  const CONTEXT_NAME = 'EDUCATION_CONTEXT';
  const educationId = useEducationId();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={() => getPermissions(educationId)}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default EducationContext;
