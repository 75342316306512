/* istanbul ignore file */
import React, { useMemo, useState } from 'react';
import Grid from 'uibuilder/Grid';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import { AvailableOnProdLabels, WasDemoedLabels, WorkItemStatusLabels } from '../../ProjectStatusesService';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import TextButton from 'uibuilder/button/TextButton';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import TicketAssigneeSearchField from './TicketAssigneeSearchField';

export type DeliveredWorkItemType = {
  id: number;
  ticketName: string;
  fromPlanning: boolean;
  assigneeName: string;
  assigneeAlias: string;
  customerFeedback: string;
  comments: string;
  wasDemoed: string;
  availableOnProd: string;
  status: string;
};

interface DeliveredWorkItemInputRowProps {
  data: DeliveredWorkItemType;
  id: number;
  formData: any;
  updateTickets: (newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
  disabled?: boolean;
}

const DeliveredWorkItemInputRow = ({
  data,
  id,
  formData,
  getErrorMessages,
  updateTickets,
  disabled = false,
}: DeliveredWorkItemInputRowProps) => {
  const [rowData, setRowData] = useState(data);
  const source = `tickets[${id}]`;
  const enumErrorSource = `tickets.${id}`;
  const projectName = formData.project?.name;

  const onChangeFieldCallback = (fieldName: string) => (newFormData: FormFieldsData) => {
    const newData = {
      ...rowData,
      assigneeAlias: data.assigneeAlias,
      assigneeName: data.assigneeName,
      [`${fieldName}`]: newFormData[`${source}.${fieldName}`],
    };

    setRowData({ ...newData });
    const listData = formData.tickets;
    listData[id] = { ...newData };

    updateTickets(listData);
  };

  const removeRow = () => {
    const listData = formData.tickets;
    listData.splice(id, 1);

    updateTickets(listData);
  };

  const isDisabledSpecific = useMemo(() => {
    return rowData.fromPlanning;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData.fromPlanning, rowData.id]);

  return (
    <Grid container spacing={2} style={id === 0 ? { alignItems: 'flex-end' } : {}}>
      <Grid item xs={12} md={2}>
        <TextButton
          source={`${source}.ticketName`}
          label={id === 0 ? 'Work item (name or link)' : ''}
          value={rowData.ticketName}
          disabled={isDisabledSpecific || disabled}
          onChangeCallback={onChangeFieldCallback('ticketName')}
          isRequired
          title="Work item (name or link)"
          errors={getErrorMessages(`${source}.ticketName`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TicketAssigneeSearchField
          isRequired
          isDisabled={(rowData.assigneeAlias === null && rowData.assigneeName !== null) || disabled}
          label={id === 0 ? 'Assignee' : ''}
          sourceAlias={`${enumErrorSource}.assigneeAlias`}
          nameSource={`${enumErrorSource}.assigneeName`}
          projectName={projectName}
          errors={getErrorMessages(`${source}.assignee`)}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '10%' }}>
        <TextButton
          source={`${source}.customerFeedback`}
          label={id === 0 ? 'Customer feedback' : ''}
          value={rowData.customerFeedback}
          onChangeCallback={onChangeFieldCallback('customerFeedback')}
          title="Customer feedback"
          errors={getErrorMessages(`${source}.customerFeedback`)}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '10%' }}>
        <TextButton
          source={`${source}.comments`}
          label={id === 0 ? 'Comments' : ''}
          value={rowData.comments}
          onChangeCallback={onChangeFieldCallback('comments')}
          errors={getErrorMessages(`${source}.comments`)}
          title="Comments"
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '14%' }}>
        <EnumDropdown
          disabled={disabled}
          source={`${source}.wasDemoed`}
          errorSource={`${enumErrorSource}.wasDemoed`}
          label={id === 0 ? 'Was demoed?' : ''}
          value={rowData.wasDemoed}
          isRequired
          onChangeCallback={onChangeFieldCallback('wasDemoed')}
          options={WasDemoedLabels}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '14%' }}>
        <EnumDropdown
          disabled={disabled}
          source={`${source}.availableOnProd`}
          label={id === 0 ? 'Available on prod' : ''}
          value={rowData.availableOnProd}
          isRequired
          errorSource={`${enumErrorSource}.availableOnProd`}
          onChangeCallback={onChangeFieldCallback('availableOnProd')}
          options={AvailableOnProdLabels}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '15%' }}>
        <EnumDropdown
          disabled={disabled}
          source={`${source}.status`}
          errorSource={`${enumErrorSource}.status`}
          label={id === 0 ? 'Status' : ''}
          value={rowData.status}
          isRequired
          onChangeCallback={onChangeFieldCallback('status')}
          options={WorkItemStatusLabels}
        />
      </Grid>
      {disabled ? (
        <> </>
      ) : (
        <Grid item xs={12} md={1} style={{ maxWidth: '1%' }}>
          <RemoveListItemButton isVisible={!rowData.fromPlanning} onClickCallback={removeRow} isFirstRow={id === 0} />
        </Grid>
      )}
    </Grid>
  );
};

export default DeliveredWorkItemInputRow;
