import useCrudService from 'shared/crud';
import { usePeopleManagementApi } from 'api';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import corporateEventValidation, {
  editFormValidationSchema,
} from 'financialAnalytic/corporateEvents/createupdate/corporateEventValidation';
import useProjectService from 'erp/project/shared/projectService';
import useServiceService from 'erp/service/shared/serviceService';
import useApplicationService from 'erp/recruitment/applications/shared/applicationService';

export const READ_CORPORATE_EVENT_LIST = 'COMPANY_READ_CORPORATE_EVENT_LIST';
export const READ_CORPORATE_EVENT = 'COMPANY_READ_CORPORATE_EVENT';
export const CREATE_CORPORATE_EVENT = 'COMPANY_CREATE_CORPORATE_EVENT';
export const UPDATE_CORPORATE_EVENT = 'COMPANY_UPDATE_CORPORATE_EVENT';
export const CORPORATE_EVENT_PATH = '/corporate-events';
export const RESOURCE_URL = '/corporate-events';

export const CORPORATE_EVENT_LOCATIONS = {
  MINSK_1: 'MINSK_1',
  AUSTIN_1: 'AUSTIN_1',
  KRAKOW_1: 'KRAKOW_1',
  TBILISI_1: 'TBILISI_1',
  GLOBAL: 'GLOBAL',
};

export const CORPORATE_EVENT_LOCATIONS_OPTIONS = {
  [CORPORATE_EVENT_LOCATIONS.MINSK_1]: 'Minsk',
  [CORPORATE_EVENT_LOCATIONS.AUSTIN_1]: 'Austin',
  [CORPORATE_EVENT_LOCATIONS.KRAKOW_1]: 'Krakow',
  [CORPORATE_EVENT_LOCATIONS.TBILISI_1]: 'Tbilisi',
  [CORPORATE_EVENT_LOCATIONS.GLOBAL]: 'Global',
};

export const CORPORATE_EVENT_TYPES = {
  COMPANY_EVENT: 'COMPANY_EVENT',
  TEAM_EVENT: 'TEAM_EVENT',
  RECRUITING_EVENT: 'RECRUITING_EVENT',
};

export const CORPORATE_EVENT_TYPES_OPTIONS = {
  [CORPORATE_EVENT_TYPES.COMPANY_EVENT]: 'Company Event',
  [CORPORATE_EVENT_TYPES.TEAM_EVENT]: 'Team Event',
  [CORPORATE_EVENT_TYPES.RECRUITING_EVENT]: 'Recruiting Event',
};

export const CORPORATE_EVENT_STATUSES = {
  UPCOMING: 'UPCOMING',
  FINISHED: 'FINISHED',
};

export const CORPORATE_EVENT_STATUSES_OPTIONS = {
  [CORPORATE_EVENT_STATUSES.UPCOMING]: 'Upcoming',
  [CORPORATE_EVENT_STATUSES.FINISHED]: 'Finished',
};

export const REFERENCE_TYPES = {
  PROJECT: 'PROJECT',
  COMPANY_SERVICE: 'COMPANY_SERVICE',
  CANDIDATE_APPLICATION: 'CANDIDATE_APPLICATION',
};

const useCorporateEventService = () => {
  const {
    search: baseSearch,
    create: initialCreate,
    getById: initialGetById,
    update: initialUpdate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: usePeopleManagementApi,
  });

  const { search: baseSearchEmployees } = useEmployeeSuggestionsService();
  const { search: baseSearchProjects } = useProjectService();
  const { search: baseSearchServices } = useServiceService();
  const { search: baseSearchApplications } = useApplicationService();

  const { data: showData } = useShowContext();

  const convertParticipantsToAliasList = (participants: any[]) =>
    participants?.map((p: { value: any }) => p.value) || [];

  const convertToReferences = (businessUnits: any[], applications: any[], type: string) => {
    if (type === CORPORATE_EVENT_TYPES.TEAM_EVENT) {
      return businessUnits.map(bu => ({
        type: bu.value[0] === 'P' ? 'PROJECT' : 'COMPANY_SERVICE',
        reference: bu.value.substring(1),
      }));
    }

    if (type === CORPORATE_EVENT_TYPES.RECRUITING_EVENT) {
      return applications.map(a => ({
        type: 'CANDIDATE_APPLICATION',
        reference: a.value,
      }));
    }

    return [];
  };

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.name = {
        ct: searchString,
      };
    }

    return baseSearch(searchParams);
  };

  const searchParticipants = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    searchParams.filter.alias = {
      in: showData.getValueBySource('participants'),
    };
    return baseSearchEmployees(searchParams);
  };

  const searchReferencedProjects = async (request: ResourceData) => {
    const searchParams = cloneDeep(request);

    searchParams.filter.id = {
      in: showData
        .getValueBySource('references')
        .filter((ref: any) => ref.type === 'PROJECT')
        .map((ref: any) => `${ref.reference}`),
    };

    return baseSearchProjects(searchParams);
  };

  const searchReferencedServices = async (request: ResourceData) => {
    const searchParams = cloneDeep(request);

    searchParams.filter.id = {
      in: showData
        .getValueBySource('references')
        .filter((ref: any) => ref.type === 'COMPANY_SERVICE')
        .map((ref: any) => `${ref.reference}`),
    };

    return baseSearchServices(searchParams);
  };

  const searchReferencedApplications = async (request: ResourceData) => {
    const searchParams = cloneDeep(request);

    searchParams.filter.id = {
      in: showData
        .getValueBySource('references')
        .filter((ref: any) => ref.type === 'CANDIDATE_APPLICATION')
        .map((ref: any) => `${ref.reference}`),
    };

    return baseSearchApplications(searchParams);
  };

  const getById = async (id: string) => {
    return initialGetById(id);
  };

  const create = async ({ candidateApplications, businessUnits, participants, ...data }: any) => {
    return initialCreate({
      ...data,
      participants: convertParticipantsToAliasList(participants),
      references: convertToReferences(businessUnits, candidateApplications, data.type),
    });
  };

  const update = async (id: StringOrNumber, { candidateApplications, businessUnits, participants, ...data }: any) => {
    return initialUpdate(id, {
      ...data,
      participants: convertParticipantsToAliasList(participants),
      references: convertToReferences(businessUnits, candidateApplications, data.type),
    });
  };

  const getValidationSchema = () => Promise.resolve(corporateEventValidation);

  const getUpdateValidationSchema = () => Promise.resolve(editFormValidationSchema);

  return {
    ...baseCrud,
    search,
    searchParticipants,
    searchReferencedServices,
    searchReferencedProjects,
    searchReferencedApplications,
    getById,
    create,
    update,
    getValidationSchema,
    getUpdateValidationSchema,
  };
};

export default useCorporateEventService;
