/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { PhoneField, TextField } from 'shared/uibuilder/field';
import { string } from 'prop-types';

const OpportunityPrimaryContactPhoneField = ({
  phoneNumberSource,
  phoneNumberValue,
  mobilePhoneNumberSource,
  mobilePhoneNumberValue,
  ...props
}) => {
  const { getValue: getPhoneNumber } = useFieldHelper({ value: phoneNumberValue, source: phoneNumberSource });
  const { getValue: getMobilePhoneNumber } = useFieldHelper({
    value: mobilePhoneNumberValue,
    source: mobilePhoneNumberSource,
  });

  const phone = getPhoneNumber();
  const mobile = getMobilePhoneNumber();

  return (
    <TextField
      {...props}
      value={
        phone || mobile ? (
          <>
            {phone && <PhoneField value={phone} />}
            {mobile && <PhoneField value={mobile} />}
          </>
        ) : null
      }
    />
  );
};

OpportunityPrimaryContactPhoneField.propTypes = {
  phoneNumberSource: string,
  phoneNumberValue: string,
  mobilePhoneNumberSource: string,
  mobilePhoneNumberValue: string,
};

OpportunityPrimaryContactPhoneField.defaultProps = {
  phoneNumberSource: undefined,
  phoneNumberValue: undefined,
  mobilePhoneNumberSource: undefined,
  mobilePhoneNumberValue: undefined,
};

export default OpportunityPrimaryContactPhoneField;
