/* istanbul ignore file */
import React from 'react';
import { HorizontalMenu } from 'shared/uibuilder/menu';
import { NewCompetencyTypeLabels } from 'erp/qualification/newCompetency/newCompetencyService';
import { Button } from 'uibuilder/button';
import 'shared/uibuilder/menu/HorizontalMenu/HorizontalMenu.scss';

const NewQualificationMenu = ({ currentType, setCurrentType }: { currentType: string; setCurrentType: any }) => {
  return (
    <HorizontalMenu className="mt-4 pt-0">
      {Object.entries(NewCompetencyTypeLabels).map(entry => {
        const [value, label] = entry;
        return (
          <Button
            key={`horizontal-menu-item-${value}`}
            color="neutralLight"
            className={`horizontal-menu-btn ${value === currentType ? 'horizontal-menu-btn--active' : ''}`}
            onClick={() => setCurrentType(value)}
          >
            {label}
          </Button>
        );
      })}
    </HorizontalMenu>
  );
};

export default NewQualificationMenu;
