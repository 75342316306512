/* istanbul ignore file */
// libs
import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Pagination from '@mui/material/Pagination';

const PaginationComponent = () => {
  const { data: { totalPages = 0 } = {}, page: curPage = 0, goToPage } = useListContext();

  return totalPages > 1 ? (
    <Pagination
      count={totalPages}
      color="primary"
      defaultPage={curPage + 1}
      boundaryCount={2}
      onChange={(event: React.ChangeEvent<unknown>, page: number) => {
        if (goToPage) {
          goToPage(page - 1);
        }
      }}
      sx={{
        '& .MuiPaginationItem-root.Mui-selected': {
          pointerEvents: 'none',
        },
      }}
    />
  ) : null;
};

export default PaginationComponent;
