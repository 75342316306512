/* istanbul ignore file */
// libs
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// components
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { AddressField, EmailField, HtmlField, LinkField, PhoneField, TextField } from 'shared/uibuilder/field';
import IndustryField from 'crm/shared/field/IndustryField';
import LeadMediumField from 'crm/lead/shared/field/LeadMediumField';
import LeadStatusField from 'crm/lead/shared/field/LeadStatusField';
import LeadSourceField from 'crm/lead/shared/field/LeadSourceField';
import LeadGradeField from 'crm/lead/shared/field/LeadGradeField';
import DisqualificationReasonField from 'crm/lead/shared/field/DisqualificationReasonField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import DeleteLeadButton from 'crm/lead/shared/button/DeleteLeadButton';
import ConvertButton from 'crm/lead/shared/button/ConvertButton';
import MarkAsSpamButton from 'crm/lead/shared/button/MarkAsSpamButton';
import EditLeadButton from 'crm/lead/shared/button/EditLeadButton';
import ShowLeadPageMenu from 'crm/lead/ShowLeadPageMenu';
import SingleLeadBreadcrumbs from 'crm/lead/shared/SingleLeadBreadcrumbs';
import ConversionField from 'crm/lead/shared/field/ConversionField';
// services
import useLeadService, { NOT_ACTIVE_STATUSES } from 'crm/lead/shared/leadService';
import LeadEntityHeader from 'crm/lead/LeadEntityHeader';
import AnalyticsClientIdField from 'crm/lead/shared/field/AnalyticsClientIdField';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import LeadAgeField from 'crm/lead/shared/field/LeadAgeField';
import useLeadDuplicateService, { READ_LEADS_DUPLICATES } from 'crm/lead/Show/leadDuplicateService';
import getDuplicatesWarning from 'crm/lead/Show/leadDuplicateWarningBuilder';
import useMessageService from 'shared/message/messageService';
import useAuthorization from 'shared/authorization/authorizationService';
import TimezoneField from 'user/Settings/shared/field/TimezoneField';
import useFeatureToggle, { Features } from 'featuretoggle';
import PostponedReasonField from 'crm/lead/shared/field/PostponedReasonField';
import DateField from 'shared/uibuilder/field/DateField';
import AssignLeadOwnerButton from 'crm/lead/shared/button/AssignLeadOwnerButton';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import LeadWillAutoDisqualifiedSoonNotification from 'crm/lead/shared/field/LeadWillAutoDisqualifiedSoonNotification';

const ViewLead = () => {
  const { id } = useParams();
  const { getUpdateEntityUrl } = useLeadUrl();
  const { getById, visit } = useLeadService();
  const { getDuplicates } = useLeadDuplicateService();
  const { addMessage } = useMessageService();
  const { isGranted } = useAuthorization();

  useEffect(() => {
    if (isGranted(READ_LEADS_DUPLICATES)) {
      getDuplicates(id).then(duplicates => {
        const warning = getDuplicatesWarning(duplicates);
        if (warning) {
          addMessage(warning);
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isFeatureEnabled } = useFeatureToggle();

  const buttons = [
    <EditLeadButton
      path={getUpdateEntityUrl(id)}
      key="editButton"
      isVisible={data => data.getValueBySource('status') !== 'QUALIFIED'}
    />,
    <ConvertButton key="convertButton" />,
    <DeleteLeadButton key="deleteButton" />,
    <AssignLeadOwnerButton key="assignLeadOwnerButton" />,
    <MarkAsSpamButton key="markAsSpamButton" />,
  ];

  return (
    <Show
      getDataMethod={() => {
        visit(id);
        return getById(id);
      }}
    >
      <ShowPageLayout
        headerButtons={buttons}
        menu={<ShowLeadPageMenu />}
        breadcrumbs={<SingleLeadBreadcrumbs />}
        entityHeader={<LeadEntityHeader />}
        hasSeparateSections
      >
        <>
          <ShowSection title="Lead Information">
            <LeadWillAutoDisqualifiedSoonNotification
              source="updatedAt"
              statusSource="status"
              skipStates={NOT_ACTIVE_STATUSES}
            />
            <SectionRow>
              <LeadAgeField source="leadAge.days" label="Company" />
              <EmailField source="email" />
              <EmailField source="additionalEmail" />
              <PhoneField source="phoneNumber" />
              <LinkField source="linkedInLink" label="LinkedIn" />
            </SectionRow>
            <SectionRow>
              <TextField source="companyName" label="Company" />
              <LinkField source="websiteAddress" />
              <IndustryField source="industry" />
              <TextField source="employeesCount" label="No. of Employees" />
              <LeadStatusField source="status" />
              <DisqualificationReasonField source="disqualificationReason" />
              <PostponedReasonField
                source="postponedReason"
                isVisible={data => data.getValueBySource('status') === 'POSTPONED'}
              />
              <DateField
                source="postponedReviewDate"
                label="Postponed Review Date"
                isVisible={data => data.getValueBySource('status') === 'POSTPONED'}
              />
              <LeadSourceField source="source" />
              <LeadMediumField source="medium" />
              <TextField label="Marketing Campaign" source="campaign" />
              <TextField label="Lead Owner" source="ownerName" />
              <FullDateField label="First Visit By Owner At" source="ownerFirstVisitedAt" showSeconds={false} />
            </SectionRow>
          </ShowSection>
          <ShowSection title="Grade">
            <BigSectionRow>
              <LeadGradeField source="grade" isFull />
              <ReplaceUrlWithLink>
                <HtmlField source="gradingDetails" label="Grading details" />
              </ReplaceUrlWithLink>
            </BigSectionRow>
          </ShowSection>
          <ShowSection title="Address Information">
            <AddressField source="address">
              <TimezoneField source="addressTimezone" />
            </AddressField>
          </ShowSection>
          <ShowSection title="Description">
            <BigSectionRow>
              <ReplaceUrlWithLink>
                <HtmlField source="description" label="" />
              </ReplaceUrlWithLink>
            </BigSectionRow>
          </ShowSection>
          <ShowSection title="Visitor Details">
            <SectionRow>
              <TextField source="ip" label="IP Address" />
              <TextField source="userAgent" label="User-Agent" />
              <LinkField source="httpRefer" label="HTTP Referer" />
              <AnalyticsClientIdField source="googleAnalystClientId" label="Google Analytics Client Id" />
              <TextField source="googleAdsClickId" label="Google Click ID" />
              <TextField
                isVisible={isFeatureEnabled(Features.REQUEST_ID)}
                source="requestId"
                label="Lead autocreation request id"
              />
              <ConversionField />
            </SectionRow>
          </ShowSection>
          <ShowSection title="Attachments">
            <AttachmentsListField source="attachmentsIds" label="" />
          </ShowSection>
        </>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewLead;
