/* istanbul ignore file */
// libs
import React from 'react';
import { arrayOf, shape, func } from 'prop-types';
// components
import LoadingFilePreviewLayout from 'artifact/shared/input/layout/preview/LoadingFilePreviewLayout';

const UploadingQueueLayout = ({ loadingArtifactObjects, onRemove }) => {
  return loadingArtifactObjects.map(loadingArtifactObject => (
    <LoadingFilePreviewLayout
      key={loadingArtifactObject.id}
      loadingArtifactObject={loadingArtifactObject}
      onRemove={() => onRemove(loadingArtifactObject)}
    />
  ));
};

UploadingQueueLayout.propTypes = {
  loadingArtifactObjects: arrayOf(shape()).isRequired,
  onRemove: func.isRequired,
};

export default UploadingQueueLayout;
