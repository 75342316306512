/* istanbul ignore file */
import React from 'react';
import { LEAVE_TYPES_ALIASES } from 'erp/leave/shared/leaveService';
import { TextField } from 'shared/uibuilder/field';
import { shape } from 'prop-types';

const PaidLeaveBalanceField = ({ value }) => {
  let result = '0';
  const { type, leaveBalance } = value;

  if (
    type === LEAVE_TYPES_ALIASES.PAID_LEAVE ||
    type === LEAVE_TYPES_ALIASES.COMPENSATION ||
    type === LEAVE_TYPES_ALIASES.DAY_ON_DEMAND
  ) {
    result = leaveBalance;
  }

  return <TextField value={result} />;
};

PaidLeaveBalanceField.propTypes = {
  value: shape(),
};

PaidLeaveBalanceField.defaultProps = {
  value: {},
};

export default PaidLeaveBalanceField;
