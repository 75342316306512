/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { Button } from 'uibuilder/button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CloseProcessIssueModalForm from 'camunda/monitoring/instance/Show/issues/shared/CloseProcessIssueModalForm';
import useAuthorization from 'shared/authorization/authorizationService';
import { ListItem, useListContext } from 'shared/uibuilder/list/ListContext';

interface CloseIssueButtonProps {
  sourceIssueId: string;
}

export const CLOSE_UNUSED_PROCESS_TASKS = 'CLOSE_UNUSED_PROCESS_TASKS';

const CloseIssueButton = ({ sourceIssueId }: CloseIssueButtonProps) => {
  const { data: { items = [] } = {}, updateInList = (entity: ListItem) => {} } = useListContext();

  const { getValue: getIssueId } = useFieldHelper<any>({ source: sourceIssueId });
  const { isGranted } = useAuthorization();

  const issueId = getIssueId();
  const issueData: any = items.filter((item: any) => item.id === issueId)[0];

  const updateIssueInList = useCallback(() => {
    updateInList({
      ...issueData,
      closedAt: new Date(),
    });
  }, [issueData, updateInList]);

  const isButtonHidden = issueData.hasActiveToken || issueData.closedAt || !isGranted(CLOSE_UNUSED_PROCESS_TASKS);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  return !isButtonHidden ? (
    <>
      <Button className="instance-feedback-button" onClick={openCloseIssueForm}>
        <span>Close</span>
      </Button>
      {isFormOpen && (
        <CloseProcessIssueModalForm issueId={issueId} updateIssue={updateIssueInList} closeModal={closeModal} />
      )}
    </>
  ) : null;
};

export default CloseIssueButton;
