/* istanbul ignore file */
// All logic is located in helpers
import React from 'react';
import { FilterProvider } from './FilterContext';
import useFilter from './useFilter';
import { ValidationSchema } from 'validation-schema-library';

const Filters = ({
  children,
  getValidationSchema,
}: {
  children: React.ReactNode;
  getValidationSchema?: () => Promise<ValidationSchema>;
}) => {
  const contextValue = useFilter({
    getValidationSchemaFunc: getValidationSchema,
  });

  return <FilterProvider value={contextValue}>{children}</FilterProvider>;
};

export default Filters;
