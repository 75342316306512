import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const useContextHelper = () => {
  const { data: formData } = useFormContext();
  const { data: showData } = useShowContext();

  return {
    getFromAnyContext: source => {
      return (formData && formData[source]) || (showData && showData.getValueBySource(source));
    },
  };
};

export default useContextHelper;
