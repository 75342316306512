/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const NotesMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Notes" icon="sticky-note" {...props} />
);

NotesMenuItem.displayName = 'Notes';

NotesMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default NotesMenuItem;
