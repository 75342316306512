import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field/index';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const DateField = ({ dateFormat, className, ...props }: FieldProps & { dateFormat?: string }) => {
  const { getValue, getLabel } = useFieldHelper(props);

  return (
    <TextField
      label={getLabel()}
      value={
        getValue() ? (
          <ShowDate dateUTC={getValue()} format={dateFormat || DATE_FORMAT.SIMPLE} isDateTime={false} />
        ) : null
      }
      className={className}
    />
  );
};

export default DateField;
