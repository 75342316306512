/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { arrayOf, element, oneOfType } from 'prop-types';

const OpportunitiesBreadcrumbs = ({ children }) => {
  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Opportunities" link="/crm/opportunities" />
      {children}
    </Breadcrumbs>
  );
};

OpportunitiesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

OpportunitiesBreadcrumbs.defaultProps = {
  children: null,
};

export default OpportunitiesBreadcrumbs;
