/* istanbul ignore file */
import React from 'react';
import { element } from 'prop-types';

const OfficeLeavesBalanceColumnLayout = ({ totalField }) => {
  return <>{totalField}</>;
};

OfficeLeavesBalanceColumnLayout.propTypes = {
  totalField: element.isRequired,
};

export default OfficeLeavesBalanceColumnLayout;
