import React, { useRef } from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import useFocusHelperService from 'shared/uibuilder/list/search/focusHelperService';

const ARROW_DOWN_KEY = 40;
const ARROW_UP_KEY = 38;

export interface SearchResultsLayoutProps {
  setFocusToResults: () => void;
  focusedResultIndex: Nullable<Number>;
  searchResultsRefs: HTMLAnchorElement[];
  onKeyUp: (event: any, index: number) => void;
}

type KeyboardNavigationWrapperProps = {
  isSearchMode: boolean;
  searchResultsLayout: React.FC<SearchResultsLayoutProps>;
  className?: string;
  placeholder?: string;
  onFocusCallback?: (searchInputRef: React.RefObject<HTMLInputElement>) => void;
};

const SearchWithKeyboardNavigation = ({
  isSearchMode,
  searchResultsLayout: SearchResultsLayout,
  className,
  placeholder,
  onFocusCallback,
}: KeyboardNavigationWrapperProps) => {
  const { search = '' } = useListContext();
  const { data: { items = [] } = {} } = useListContext();
  const searchResultsRefs: HTMLAnchorElement[] = [];
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    setFocusToItem,
    blurResultsItem,
    focusedResultIndex,
    setFocusedResultIndex,
    setFocusToSearchInput,
    setFocusToSearchResults,
  } = useFocusHelperService({ search, searchInputRef, searchResultsRefs, onFocusCallback });

  const getIsUpPressed = (keyCode: number) => keyCode === ARROW_UP_KEY;

  const getIsDownPressed = (keyCode: number) => keyCode === ARROW_DOWN_KEY;

  const getIsSupportedKey = (keyCode: number) => [ARROW_UP_KEY, ARROW_DOWN_KEY].includes(keyCode);

  const getCanChangeFocusedElement = (keyCode: number, index: number) => {
    const isPressedDownOnLastItem = index >= items.length - 1 && getIsDownPressed(keyCode);
    return !isPressedDownOnLastItem;
  };

  const moveUp = (index: number) => {
    const previousItemIndex = index - 1;
    setFocusToItem(previousItemIndex);
  };

  const moveDown = (index: number) => {
    const nextItemIndex = index + 1;
    setFocusToItem(nextItemIndex);
  };

  const onKeyUpSearchResults = (event: any, index: number) => {
    const { keyCode } = event;
    const isFirstItem = index === 0;

    if (getIsSupportedKey(keyCode) && getCanChangeFocusedElement(keyCode, index)) {
      if (isFirstItem && getIsUpPressed(keyCode)) {
        setFocusToSearchInput();
        setFocusedResultIndex(null);
      } else {
        blurResultsItem(index);

        if (getIsDownPressed(keyCode)) {
          moveDown(index);
        } else if (getIsUpPressed(keyCode)) {
          moveUp(index);
        }
      }
    }
  };

  const onKeyUpSearchInput = (event: any) => {
    if (searchInputRef.current && event.keyCode === ARROW_DOWN_KEY) {
      event.preventDefault();
      searchInputRef.current.blur();
      setFocusToSearchResults();
    }
  };

  return (
    <>
      <div className={className}>
        <SearchInput
          inputRef={searchInputRef}
          isAutoCompleteEnabled={false}
          onKeyUpCallback={onKeyUpSearchInput}
          onFocusCallback={setFocusToSearchInput}
          placeholder={placeholder}
          className="d-block"
        />
      </div>
      {isSearchMode && (
        <SearchResultsLayout
          focusedResultIndex={focusedResultIndex}
          onKeyUp={onKeyUpSearchResults}
          searchResultsRefs={searchResultsRefs}
          setFocusToResults={setFocusToSearchResults}
        />
      )}
    </>
  );
};

export default SearchWithKeyboardNavigation;
