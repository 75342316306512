/* istanbul ignore file */
import React from 'react';

import useLeaveService from 'erp/leave/shared/leaveService';
import LeaveForm from 'erp/leave/createupdate/LeaveForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateLeaveBreadcrumbs from 'erp/leave/createupdate/Create/Breadcrumbs';
import useLeavesErrorMap from 'erp/leave/createupdate/errorHandlers';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import LeaveNotStrictRulesHandler from '../LeaveNotStictRulesHandler';

const CreateLeave = () => {
  const { create, getValidationSchema } = useLeaveService();

  const errorsMap = useLeavesErrorMap();
  const { getListUrl } = useLeaveUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Leave has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      errorsMap={errorsMap}
    >
      <LeaveNotStrictRulesHandler errorMap={errorsMap} />
      <CreatePageLayout title="Create: Leave" breadcrumbs={<CreateLeaveBreadcrumbs />}>
        <LeaveForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateLeave;
