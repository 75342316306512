/* istanbul ignore file */
import React from 'react';
import { Filter } from 'shared/uibuilder/list/filter/FilterContext';
import LeadNotificationButton from 'crm/lead/shared/filter/LeadNotificationButton';

const NOTIFICATION_COLOR = '#5187ed';

const StaleLeadsNotificationButton = (props: {
  isLoading?: boolean;
  loadingDataCallback: (count: number) => void;
  onClick: (state: Filter) => void;
  state: Filter;
  setIsLoadingCallback: (value: boolean) => void;
}) => {
  const getLabel = ({ totalElements }: { totalElements: number }) => {
    const hasSeveralLeads = totalElements > 1;

    return `${totalElements} of yours ${hasSeveralLeads ? 'are' : 'is'} STALE (21+ days without touch)`;
  };

  return (
    <LeadNotificationButton
      getLabel={getLabel}
      color="primary"
      styles={{
        backgroundColor: NOTIFICATION_COLOR,
        '&:hover': {
          backgroundColor: 'var(--mui-palette-primary-dark)',
        },
        '&.Mui-disabled': {
          backgroundColor: NOTIFICATION_COLOR,
        },
      }}
      {...props}
    />
  );
};

export default StaleLeadsNotificationButton;
