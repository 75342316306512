/* istanbul ignore file */
import React from 'react';
import { NameFieldValue } from 'shared/uibuilder/field/NameField';
import TreeTooltipAttribute from './TreeTooltipAttribute';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import { HtmlField } from 'shared/uibuilder/field';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import TeamEmployeeLinkField from 'erp/rbs/shared/field/TeamEmployeeLinkField';
import './index.scss';

type TooltipNameData = {
  alias?: string;
  nameEn?: NameFieldValue;
};

export interface TreeTooltipMessageProps {
  owner?: TooltipNameData;
  team?: TooltipNameData[];
  goal?: string;
}

const TreeTooltipMessage: React.FC<TreeTooltipMessageProps> = ({ goal, owner, team }) => {
  return (
    <div className="rbs-tooltip-content">
      <TreeTooltipAttribute isVisible={!!goal} label="Goals">
        <ReplaceUrlWithLink>
          <HtmlField value={goal} />
        </ReplaceUrlWithLink>
      </TreeTooltipAttribute>
      <TreeTooltipAttribute isVisible={!!team?.length} label="Team">
        <FieldListLayout source="attributes.team" value={team} label="" isRowDirection>
          <TeamEmployeeLinkField />
        </FieldListLayout>
      </TreeTooltipAttribute>
    </div>
  );
};

export default TreeTooltipMessage;
