/* istanbul ignore file */
import React from 'react';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { Dropdown } from 'shared/uibuilder/form/input';
import { ACTIVE_STATUS, FUTURE_STATUS } from 'erp/assignment/shared/assignmentService';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';

interface AssignmentStatusFilterProps extends DefaultDropdownProps {
  value?: string;
}

const AssignmentStatusFilter = (props: AssignmentStatusFilterProps) => {
  return (
    <DropDownFilter {...props}>
      <Dropdown
        {...props}
        placeholder="Select status"
        options={[
          { value: ACTIVE_STATUS, label: 'Active' },
          { value: FUTURE_STATUS, label: 'Future' },
        ]}
      />
    </DropDownFilter>
  );
};

export default AssignmentStatusFilter;
