import React, { ReactElement, ReactNode } from 'react';
import BaseBreadcrumbs from 'uibuilder/Breadcrumbs';
import useAuthorization from 'shared/authorization/authorizationService';
import { Link } from 'react-router-dom';
import DynamicPageTitleBreadcrumbs from 'shared/uibuilder/BreadCrumbs/DynamicPageTitleBreadcrumbs';

export interface BreadcrumbsProps {
  children?: ReactNode | ReactNode[];
}

const Breadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { isGranted } = useAuthorization();

  const breadCrumbItems = (React.Children.toArray(children) as ReactElement[]).filter(
    child => child && (!child.props.permissionToCheck || isGranted(child.props.permissionToCheck)),
  );
  const lastChildIndex = breadCrumbItems.length - 1;

  return (
    <DynamicPageTitleBreadcrumbs>
      <BaseBreadcrumbs aria-label="breadcrumb">
        {breadCrumbItems.map((child, index) => {
          const isLastChild = index === lastChildIndex;

          return React.cloneElement(child, {
            active: isLastChild,
            tag: isLastChild ? 'span' : Link,
          });
        })}
      </BaseBreadcrumbs>
    </DynamicPageTitleBreadcrumbs>
  );
};

export default Breadcrumbs;
