import React, { useEffect, useState } from 'react';
import useHandbookService, { HandbookTableOfContentItem } from 'handbook/handbookService';
import useHandbookMapper from 'handbook/handbookMapper';
import { useLocation } from 'react-router-dom';
import { useHandbookUrl } from '../HandbookRouter';
import * as domUtils from 'shared/uibuilder/domUtils';
import { HashLink } from 'react-router-hash-link';

const useHandbookMenuService = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [indexHtml, setIndexHtml] = useState<string>('');
  const [content, setContent] = useState<React.ReactNode>('');
  const [tableContent, setTableContent] = useState<HandbookTableOfContentItem[]>([]);
  const { getIndexPage } = useHandbookService();
  const { parseHtmlToReactElements } = useHandbookMapper();
  const { pathname } = useLocation();
  const { getListUrl } = useHandbookUrl();
  const pagePathname = pathname.replace(getListUrl(), '');

  const highlightActiveLink = (attribs: Dictionary<string>) => {
    if (attribs?.href?.startsWith('/') && pagePathname === attribs.href) {
      return {
        className: 'active',
      };
    } else {
      return {};
    }
  };

  const renderTableOfContent = (data: HandbookTableOfContentItem[]) => {
    return (data || []).map(({ hash, children, title }) => (
      <React.Fragment key={hash}>
        <li>
          <HashLink
            className="nav-link"
            to={`${pathname}#${hash
              .replace(/[.,!:?&'()’|$+;=@#<>^*%]/g, '')
              .replace(/\/-/g, '')
              .replace(/-—/g, '')}`}
            scroll={(el: HTMLElement) => domUtils.scrollToHash(el)}
          >
            {title}
          </HashLink>

          {children ? <ul>{renderTableOfContent(children)}</ul> : null}
        </li>
      </React.Fragment>
    ));
  };

  const addTableOfContent = (attribs: Dictionary<string>) => {
    if (attribs?.href?.startsWith('/') && pagePathname === attribs.href && tableContent.length) {
      return (
        <React.Fragment key={`list-${pagePathname}`}>
          <ul>{renderTableOfContent((tableContent[0] && tableContent[0].children) || [])}</ul>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  const getLinkProps = (attribs: Dictionary<string>) => {
    return {
      sibling: addTableOfContent(attribs),
      ...highlightActiveLink(attribs),
    };
  };

  const loadData = async () => {
    setLoading(true);
    const res = await getIndexPage();

    if (res) {
      setIndexHtml(res.html);
    }
    setLoading(false);
  };

  const updateContent = () => {
    if (indexHtml) {
      setContent(parseHtmlToReactElements(indexHtml, getLinkProps));
    }
  };

  useEffect(() => {
    setTableContent([]);
    // Suppressed warnings because we only need to call useEffect callback after updating path.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePathname]);

  useEffect(() => {
    updateContent();
    // Suppressed warnings because we only need to call useEffect callback after updating path.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent, indexHtml]);

  useEffect(() => {
    loadData();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    content,
    indexHtml,
    pagePathname,
    setTableContent,
  };
};

export default useHandbookMenuService;
