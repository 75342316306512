/* istanbul ignore file */
import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const EducationBreadcrumbs = ({ children }) => {
  const { getListUrl } = useEducationUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Educations" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

EducationBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

EducationBreadcrumbs.defaultProps = {
  children: null,
};

export default EducationBreadcrumbs;
