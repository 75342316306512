/* istanbul ignore file */
export default {
  resumeArtifactIds: {
    required: true,
    type: 'array',
    maxSize: 5,
  },
  description: {
    type: 'string',
    required: false,
    maxSize: 65000,
  },
};
