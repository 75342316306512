/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import { string } from 'prop-types';
import React from 'react';
import useRedmineUrl from 'redmine/useRedmineUrl';

// @ts-ignore
const RedmineIssueField = ({ source, ...props }) => {
  const { getValue: getId } = useFieldHelper<number>({ source });
  const { getIssueUrl } = useRedmineUrl();

  const id = getId();
  const value = id && getIssueUrl(id);

  return <LinkField {...props} linkText={id} value={value as string} isInternal={false} />;
};

RedmineIssueField.propTypes = {
  source: string.isRequired,
};

export default RedmineIssueField;
