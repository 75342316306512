import React from 'react';
import ViewSowProjectContent from './ViewSowProjectContent';
import ProjectEntityHeader from '../../shared/ProjectEntityHeader';
import ViewProjectPageMenu from '../../Show/ViewProjectPageMenu';
import SowBreadcrumbs from '../shared/SowBreadcrumbs';
import ShowPageLayout from '../../../../shared/layout/ShowPageLayout';
import ShowProject from '../../statuses/shared/ShowProject';

const ViewSowProject = () => {
  return (
    <ShowProject>
      <ShowPageLayout
        entityHeader={<ProjectEntityHeader />}
        menu={<ViewProjectPageMenu />}
        breadcrumbs={<SowBreadcrumbs />}
      >
        <ViewSowProjectContent />
      </ShowPageLayout>
    </ShowProject>
  );
};

export default ViewSowProject;
