/* istanbul ignore file */
export default {
  invoiceNumber: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isFinalChanges: {
          eq: true,
        },
      },
    },
  },
  invoiceDocumentId: {
    type: 'array',
    required: {
      value: true,
      when: [
        {
          $hasInvoiceDocument: {
            eq: true,
          },
        },
        {
          $isFinalChanges: {
            eq: true,
          },
        },
      ],
    },
    maxSize: 1,
  },
};
