/* istanbul ignore file */

import NoteTimelineRecordLayout from 'erp/candidate/Timeline/note/shared/NoteTimelineRecordLayout';
import { CREATE_CANDIDATE_NOTE_PERMISSION } from 'erp/candidate/Timeline/note/note/candidateNoteService';
import CreateNoteForm from './form/CreateNoteForm';
import UpdateNoteForm from './form/UpdateNoteForm';

const useCandidateNoteItem = () => ({
  AddForm: CreateNoteForm,
  UpdateForm: UpdateNoteForm,
  isApplicable: (item: any) => item.note,
  source: 'note',
  urlPostfix: 'notes',
  RecordLayout: NoteTimelineRecordLayout,
  name: 'Note',
  permissionToCheck: CREATE_CANDIDATE_NOTE_PERMISSION,
});

export default useCandidateNoteItem;
