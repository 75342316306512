/* istanbul ignore file */
import React from 'react';
import PrepopulateButton from 'erp/employee/prepopulation/PrepopulateButton';

const CreateTitle = () => {
  return (
    <div className="edit-employee-title">
      Create: Education
      <PrepopulateButton />
    </div>
  );
};

export default CreateTitle;
