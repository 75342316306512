/* istanbul ignore file */
// eslint-disable-next-line import/prefer-default-export
import useCrudService from 'shared/crud';
import { useBusinessEngineApi } from 'api';

export const READ_DEFINITIONS_LIST = 'READ_DEFINITIONS_LIST';

const RESOURCE_URL = '/camunda/davinci-api/definitions';

const useProcessDefinitionService = () => {
  const { sendGetRequest } = useBusinessEngineApi();

  const getProcessKeys = async (): Promise<List<string>> => {
    const response = await sendGetRequest(`${RESOURCE_URL}/keys`);
    return response.json();
  };

  const crudRequests = useCrudService({
    listResourceUrl: RESOURCE_URL,
    singleResourceUrl: `${RESOURCE_URL}/:id`,
  });

  return {
    ...crudRequests,
    getProcessKeys,
  };
};

export default useProcessDefinitionService;
