/* istanbul ignore file */
// libs
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ContractsHeader from 'erp/contracts/shared/ContractsHeader';
import useContractsService from 'erp/contracts/shared/contractsService';
import SingleContractsBreadcrumbs from 'erp/contracts/Show/Breadcrumbs';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import ContractsMenu from 'erp/contracts/shared/ContractsMenu';
import { useContractsId } from 'erp/contracts/ContractsRouter';
import { ContractsViewFields } from 'erp/contracts/index';

const ViewContracts = () => {
  const { getById } = useContractsService();
  const id = useContractsId();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        menu={<ContractsMenu />}
        entityHeader={<ContractsHeader />}
        breadcrumbs={<SingleContractsBreadcrumbs />}
      >
        <ShowSection title="Contracts details">
          <ContractsViewFields />
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewContracts;
