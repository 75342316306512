/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import ProgressIndicator from 'crm/projectsDashboard/shared/field/ProgressIndicator';
import { Tooltip } from '@mui/material';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import { FieldProps } from 'shared/uibuilder/field';
import './ProgressField.scss';

const ProgressFieldLayout = ({ value, label, className = '' }: FieldProps) => {
  return (
    <div className={className}>
      <b>{label}:</b> {value || EMPTY_VALUE_INDICATOR}
    </div>
  );
};

const ProgressField = ({
  source,
  steps,
  children,
  additionalData,
  getTooltipTitle,
}: {
  source: string;
  steps: string[];
  children: any;
  additionalData?: any;
  getTooltipTitle?: (data: any) => React.ReactNode;
}) => {
  const { getValue } = useFieldHelper({ source });
  const progressData = (getValue() || {}) as Dictionary<any>;

  const mappedProgressData = Array.isArray(progressData)
    ? progressData.reduce((result, value) => {
        return { ...result, [value.stepName]: value };
      }, {})
    : {};

  return (
    <div>
      <div className="project-dashboard-timeline">
        {steps.map((field: string, index: number) => {
          const { status = '' } = mappedProgressData[field] || {};

          if (!mappedProgressData[field]) {
            return null;
          }

          const Item = React.forwardRef((props: Dictionary<any>, ref: any) => (
            <div {...props} ref={ref} className="project-dashboard-timeline__item" data-status={status.toUpperCase()}>
              <div className="project-dashboard-timeline__item-text">
                {React.cloneElement(children, { ...(mappedProgressData[field] || {}), field, index })}
              </div>

              <ProgressIndicator status={status} />

              {additionalData ? (
                <UiThemeOverrider overriddenLayouts={{ BaseFieldLayout: ProgressFieldLayout }}>
                  <div className="project-dashboard-timeline__item-additional-text">
                    {React.cloneElement(additionalData, { ...(mappedProgressData[field] || {}), field, index })}
                  </div>
                </UiThemeOverrider>
              ) : null}
            </div>
          ));

          const tooltipTitle = getTooltipTitle
            ? getTooltipTitle({ ...(mappedProgressData[field] || {}), field, index })
            : null;

          return tooltipTitle ? (
            <Tooltip title={tooltipTitle} arrow>
              <Item />
            </Tooltip>
          ) : (
            <Item />
          );
        })}
      </div>
    </div>
  );
};

export default ProgressField;
