/* istanbul ignore file */

export default {
  comment: {
    type: 'string',
    required: true,
  },
  issueIds: {
    type: 'array',
    maxSize: 1,
    required: true,
  },
};
