/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import {
  useEmployeeBackgroundCheckId,
  useEmployeeBackgroundChecksUrl,
} from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import { UPDATE_BACKGROUND_CHECK } from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

const EditEmployeeBackgroundCheckButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useEmployeeBackgroundChecksUrl();
  const id = useEmployeeBackgroundCheckId();

  return (
    <EditButton
      path={getUpdateEntityUrl(id)}
      permissionToCheck={UPDATE_BACKGROUND_CHECK}
      key="edit-background-check"
      icon={<i className="fa fa-edit" />}
      {...props}
    />
  );
};

export default EditEmployeeBackgroundCheckButton;
