/* istanbul ignore file */
import React from 'react';
import { FieldProps, TextField } from '../../../../shared/uibuilder/field';
import useFieldHelper from '../../../../shared/uibuilder/field/fieldHelper';
import { AssignmentType } from '../assignmentService';

const PositionInAssignmentField = ({ source, ...props }: FieldProps) => {
  const { getValue: getAssignmentType } = useFieldHelper({ source });

  return getAssignmentType() === AssignmentType.INDIVIDUAL_ASSIGNMENT ? (
    <TextField source="position.name" {...props} />
  ) : (
    <TextField value="Public Service" {...props} />
  );
};

export default PositionInAssignmentField;
