import { useCallback, useMemo } from 'react';
import { useFinanceApi } from 'api';
import useCrudService, { CrudService } from 'shared/crud';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import useSingletonPromise from 'shared/useSingletonPromise';
import useWasteValidation from 'erp/costaccounting/waste/createupdate/wasteValidation';
import useAuthorization from 'shared/authorization/authorizationService';
import { CONTENT_TYPE } from 'shared/api/const';

export const READ_WASTE = 'COMPANY_READ_WASTE';
export const APPROVE_WASTE = 'COMPANY_APPROVE_WASTE';
export const READ_WASTE_LIST = 'COMPANY_READ_WASTE_LIST';
export const CREATE_WASTE_WITH_VIOLATIONS = 'COMPANY_CREATE_WASTE_WITH_VIOLATIONS';
export const CREATE_WASTE = 'COMPANY_CREATE_WASTE';
export const REJECT_WASTE = 'COMPANY_REJECT_WASTE';

export const WASTE_PATH = '/waste';
export const RESOURCE_URL = '/accounting-cycle/waste';

export const WASTE_STATUSES = {
  REGISTERED: 'REGISTERED',
  APPROVED: 'APPROVED',
  PROCESSED: 'PROCESSED',
  REJECTED: 'REJECTED',
} as const;

export const WASTE_TYPE = {
  LABOR_COST_WASTE: 'LABOR',
  OTHER_EXPENSES_WASTE: 'OTHER',
};

export const WASTE_TYPE_OPTIONS = {
  [WASTE_TYPE.LABOR_COST_WASTE]: 'Labor',
  [WASTE_TYPE.OTHER_EXPENSES_WASTE]: 'Other',
} as const;

export const WASTE_REPORTING_METHOD = {
  BY_TICKET_IDS: 'BY_TICKET_IDS',
  BY_EMPLOYEE_AND_TICKET_IDS: 'BY_EMPLOYEE_AND_TICKET_IDS',
  BY_PROJECT_PERIOD: 'BY_PROJECT_AND_PERIOD',
};

export const WASTE_REPORTING_METHOD_OPTIONS = {
  [WASTE_REPORTING_METHOD.BY_EMPLOYEE_AND_TICKET_IDS]: 'By Employee and Ticket ID (s)',
  [WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD]: 'By Project and Period',
  [WASTE_REPORTING_METHOD.BY_TICKET_IDS]: 'By Ticket ID (s)',
} as const;

export const WASTE_STATUSES_OPTIONS = {
  [WASTE_STATUSES.REGISTERED]: 'Registered',
  [WASTE_STATUSES.APPROVED]: 'Approved',
  [WASTE_STATUSES.PROCESSED]: 'Processed',
  [WASTE_STATUSES.REJECTED]: 'Rejected',
} as const;

type Waste = {
  type: keyof typeof WASTE_TYPE;
  laborWasteDetails?: {
    employeeAlias?: string;
    reportingMethod: keyof typeof WASTE_REPORTING_METHOD;
    period?: any;
    projectId?: string;
    ticketsIds?: string[];
    inputTicketsIds?: string;
  };
  otherWasteDetails?: {
    cost: {
      amount: string;
      currency: string;
    };
    issueDate: string;
  };
};

const useWasteService = () => {
  const { sendGetRequest, sendPostRequest } = useFinanceApi();
  const { isGranted } = useAuthorization();

  const { search: baseSearch, create: initialCreate, ...baseCrud }: CrudService = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const isHasWithViolations = useMemo(() => isGranted(CREATE_WASTE_WITH_VIOLATIONS), [isGranted]);

  const search = useCallback(
    (request: ResourceData) => {
      const searchParams = cloneDeep(request);
      const searchString = searchParams.filter?.['specification:search']?.eq || '';

      if (searchString && searchParams.filter) {
        delete searchParams.filter?.['specification:search'];
        searchParams.filter.name = {
          ct: searchString,
        };
      }

      return baseSearch(searchParams);
    },
    [baseSearch],
  );

  const create = useCallback(
    async (waste: Waste) => {
      const mappedData: Waste = { ...waste };

      if (mappedData.type === WASTE_TYPE.LABOR_COST_WASTE) {
        const reportingMethod = mappedData.laborWasteDetails?.reportingMethod;

        delete mappedData.otherWasteDetails;

        if (reportingMethod === WASTE_REPORTING_METHOD.BY_EMPLOYEE_AND_TICKET_IDS) {
          delete mappedData?.laborWasteDetails?.period;
          delete mappedData?.laborWasteDetails?.projectId;
        }

        if (reportingMethod === WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD) {
          delete mappedData?.laborWasteDetails?.ticketsIds;
        }

        if (reportingMethod === WASTE_REPORTING_METHOD.BY_TICKET_IDS) {
          delete mappedData?.laborWasteDetails?.period;
          delete mappedData?.laborWasteDetails?.projectId;
          delete mappedData?.laborWasteDetails?.employeeAlias;
        }
      }

      if (mappedData.type === WASTE_TYPE.OTHER_EXPENSES_WASTE) {
        delete mappedData.laborWasteDetails;
      }

      return initialCreate(mappedData);
    },
    [initialCreate],
  );

  const getAccounts = useSingletonPromise(
    'search-waste-accounts',
    useCallback(async () => {
      const response = await sendGetRequest('/accounting-cycle/waste/accounts');
      const result = await response.json();

      return {
        result,
        totalElements: result.length,
        totalPages: 1,
      };
    }, [sendGetRequest]),
  );

  const getRedmineTickets = useSingletonPromise(
    'search-waste-redmine-tickets',
    useCallback(
      async (data: any) => {
        const response = await sendPostRequest('/accounting-cycle/waste/tickets', data);
        const result = await response.json();

        return {
          result,
          totalElements: result.length,
          totalPages: 1,
        };
      },
      [sendPostRequest],
    ),
  );

  const getProjects = useSingletonPromise(
    'search-waste-redmine-projects',
    useCallback(
      async (data: any) => {
        const response = await sendPostRequest('/accounting-cycle/waste/projects', data);
        const result = await response.json();

        return {
          result,
          totalElements: result.length,
          totalPages: 1,
        };
      },
      [sendPostRequest],
    ),
  );

  const approveWaste = useCallback(
    async (id: string, data: any) => {
      const result = await sendPostRequest(`${RESOURCE_URL}/${id}/approve`, data);
      return result.json();
    },
    [sendPostRequest],
  );

  const rejectWaste = useCallback(
    async (id: string, rejectReason: any) => {
      const result = await sendPostRequest(`${RESOURCE_URL}/${id}/reject`, rejectReason, CONTENT_TYPE.MULTIPART);
      return result.json();
    },
    [sendPostRequest],
  );

  const wasteValidation = useWasteValidation(isHasWithViolations);

  const getValidationSchema = useCallback(() => Promise.resolve(wasteValidation), [wasteValidation]);

  return {
    ...baseCrud,
    create,
    search,
    getAccounts,
    getRedmineTickets,
    approveWaste,
    rejectWaste,
    getProjects,
    getValidationSchema,
    isHasWithViolations,
  };
};

export default useWasteService;
