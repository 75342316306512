/* istanbul ignore file */
// libraries
import React from 'react';
// components
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { EnumField, TextField } from 'shared/uibuilder/field';
// services
import {
  EmployeeCommonSkillExperienceDurationLabels,
  EmployeeCommonSkillExperienceTypeLabels,
  EmployeeCommonSkillLevelLabels,
} from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';
import useEmployeeCommonSkillsShowHelper from 'erp/employee/commonSkills/shared/employeeCommonSkillsShowHelper';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import AddEmployeeCommonSkillButton from 'erp/employee/commonSkills/shared/button/AddEmployeeCommonSkillButton';
import EditEmployeeCommonSkillButton from 'erp/employee/commonSkills/shared/button/EditEmployeeCommonSkillButton';
import DeleteEmployeeCommonSKillButton from 'erp/employee/commonSkills/shared/button/DeleteEmployeeCommonSKillButton';
import AddCommonSkillButton from 'erp/employee/commonSkills/commonSkill/button/AddCommonSkillButton';
// styles;

const EmployeeCommonSkillsList = () => {
  const { currentData } = useEmployeeCommonSkillsShowHelper();

  return (
    <ControlledList data={currentData}>
      <RelationListLayout header="Common Skills" actions={[<AddCommonSkillButton />, <AddEmployeeCommonSkillButton />]}>
        <DataGridLayout
          showTotal={false}
          buttons={[<EditEmployeeCommonSkillButton source="id" />, <DeleteEmployeeCommonSKillButton source="id" />]}
        >
          <TextField source="commonSkill.name" label="Skill" width={150} />
          <EnumField source="level" label="Level" options={EmployeeCommonSkillLevelLabels} />
          <EnumField source="experience" label="Experience" options={EmployeeCommonSkillExperienceTypeLabels} />
          <EnumField
            source="experienceDuration"
            label="Experience Duration"
            options={EmployeeCommonSkillExperienceDurationLabels}
          />
          <TextField source="comments" label="Comments" />
        </DataGridLayout>
      </RelationListLayout>
    </ControlledList>
  );
};

export default EmployeeCommonSkillsList;
