import React from 'react';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

interface FilterInputDecoratorProps {
  children: React.ReactElement;
  onResetCallback?: () => void;
  cleanableFilters: string[];
}

const FilterInputDecorator = ({
  cleanableFilters,
  onResetCallback: parentOnResetCallback,
  children,
  ...props
}: FilterInputDecoratorProps) => {
  const { clearAndApplyFilter } = useFilterContext();

  const onResetCallback = () => {
    if (parentOnResetCallback) {
      parentOnResetCallback();
    }

    if (cleanableFilters.length) {
      clearAndApplyFilter(cleanableFilters);
    }
  };

  return React.cloneElement(children, { ...props, onResetCallback });
};

export default FilterInputDecorator;
