/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import { useProjectWeeklyReportsUrl } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';

interface ProjectReportLinkFieldProps {
  source: string;
  sourceDateFrom: string;
  sourceDateTo: string;
}

const ProjectWeeklyReportLinkField = ({
  source,
  sourceDateFrom,
  sourceDateTo,
  ...props
}: ProjectReportLinkFieldProps) => {
  const { formatDate } = useDateService();
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getDateFrom } = useFieldHelper({ source: sourceDateFrom });
  const { getValue: getDateTo } = useFieldHelper({ source: sourceDateTo });
  const { getSingleEntityUrl } = useProjectWeeklyReportsUrl();
  const id = getId();
  const url = getSingleEntityUrl(id);
  const linkText = `${formatDate(getDateFrom(), DATE_FORMAT.FULL_DATE)} - ${formatDate(
    getDateTo(),
    DATE_FORMAT.FULL_DATE,
  )}`;

  return <LinkField {...props} linkText={linkText} value={url} isInternal />;
};

export default ProjectWeeklyReportLinkField;
