/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { InputList, TextArea, TextInput } from 'shared/uibuilder/form/input';
import BudgetInput from 'erp/budget/shared/input/BudgetInput';
import AvailableBudgetsDropdown from 'erp/budget/shared/input/AvailableBudgetsDropdown';

const BudgetReallocationForm = () => {
  return (
    <FormSection title="Budget Reallocation information">
      <FormRow>
        <BudgetInput
          filter={{ notArchived: true, budgetOwner: true, hasActivePeriod: true }}
          source="budgetFromId"
          label="Source Budget"
          searchString="name"
        />
        <AvailableBudgetsDropdown
          filter={{ notArchived: true }}
          placeholder="Choose budget"
          budgetFromSource="budgetFromId"
          source="budgetToId"
          label="Destination Budget"
        />
      </FormRow>
      <FormRow>
        <InputList
          source="ticketIds"
          label="Redmine Ticket Id"
          maxFields={1000}
          addText="Add Redmine ticket id"
          inputTemplate={TextInput}
        />
      </FormRow>
      <FormRow>
        <TextArea source="reason" />
      </FormRow>
    </FormSection>
  );
};

export default BudgetReallocationForm;
