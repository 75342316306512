/* istanbul ignore file */
import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { BooleanField, CapitalizedField, TextField } from 'shared/uibuilder/field';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import ContractTypeField from 'erp/contracts/shared/field/ContractTypeField';

export interface OfferCompensationDetailsFieldProps {
  value?: {
    baseAmountType?: string;
    currency?: string;
    calculatedGrossAmount?: string;
    grossAmount?: string;
    grossProbationAmount?: string;
    officeId?: string;
    contractType?: string;
    isAccepted?: boolean;
  };
  canViewBaseFinanceFields?: boolean;
  canViewCalculatedGrossField?: boolean;
  canViewProbationField?: boolean;
}

const OfferCompensationDetailsField = ({
  value,
  canViewBaseFinanceFields = true,
  canViewCalculatedGrossField = true,
  canViewProbationField = true,
}: OfferCompensationDetailsFieldProps) => {
  return (
    <SectionRow>
      <CapitalizedField value={value?.baseAmountType} label="Base Amount Type" isVisible={canViewBaseFinanceFields} />
      <TextField value={value?.currency} label="Currency" isVisible={canViewBaseFinanceFields} />
      <TextField value={value?.grossAmount} label="Final Amount Gross" isVisible={canViewBaseFinanceFields} />
      <TextField
        value={value?.calculatedGrossAmount}
        label="Calculated Amount Gross"
        isVisible={canViewCalculatedGrossField}
      />
      <TextField value={value?.grossProbationAmount} label="Probation Amount Gross" isVisible={canViewProbationField} />
      <EmployeeOfficeField value={value?.officeId} label="Office" />
      <ContractTypeField value={value?.contractType} label="Contract Type" />
      <BooleanField value={value?.isAccepted} label="Compensation Accepted" />
    </SectionRow>
  );
};

export default OfferCompensationDetailsField;
