import React from 'react';
import { LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useConfiguration from 'configurationService';
import AnalyticsClientIdTooltip from 'crm/lead/shared/field/AnalyticsClientIdTooltip';

const AnalyticsClientIdField = props => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { googleAnalyticsUserLink } = useConfiguration();

  const clientId = getValue();

  const getLink = () => {
    return clientId ? `${googleAnalyticsUserLink}${clientId}` : null;
  };

  return (
    <LinkField
      linkText={clientId}
      label={
        <>
          {getLabel()}
          <AnalyticsClientIdTooltip />
        </>
      }
      value={getLink()}
    />
  );
};

export default AnalyticsClientIdField;
