/* istanbul ignore file */
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ShowDataInstance } from 'shared/uibuilder/show/Show';
import Data from 'shared/uibuilder/Data';
import ShowData from 'shared/uibuilder/show/ShowData';

interface ShowContextData {
  data: Data;
  setData?: Dispatch<SetStateAction<ShowDataInstance>>;
}

const ShowContext = createContext<ShowContextData>({
  data: new ShowData({}),
  setData: () => null,
});
const ShowContextProvider = ShowContext.Provider;
const ShowContextConsumer = ShowContext.Consumer;

const useShowContext = () => {
  const context = useContext(ShowContext);
  return context || {};
};
export { ShowContext, useShowContext, ShowContextProvider, ShowContextConsumer };
