import { AuthenticationTokenAction, AuthenticationTokenState } from './authenticationTokenRepository';

export enum AuthenticationTokenActions {
  SAVE_TOKEN = 'SAVE_TOKEN',
  CLEAR_TOKEN = 'CLEAR_TOKEN',
}

const initialState: AuthenticationTokenState = {
  authenticationToken: null,
};

const authenticationTokenReducer = (
  state: AuthenticationTokenState = initialState,
  action: AuthenticationTokenAction,
) => {
  const { authenticationToken } = action;

  if (action.type === AuthenticationTokenActions.SAVE_TOKEN) {
    return {
      ...state,
      authenticationToken,
    };
  }

  if (action.type === AuthenticationTokenActions.CLEAR_TOKEN) {
    return {
      ...initialState,
    };
  }

  return state;
};

export default authenticationTokenReducer;
