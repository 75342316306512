/* istanbul ignore file */
import React from 'react';
import securityImage from 'shared/uibuilder/sensitiveData/images/security.png';
import './index.scss';

const SensitiveDataPlug = () => {
  return (
    <section className="sensitive-data-plug">
      <img className="security-image" src={securityImage} alt="SensitiveData" />
    </section>
  );
};

export default SensitiveDataPlug;
