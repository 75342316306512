import React, { useContext } from 'react';
import { ErrorListener, FieldsExceptions, ValidationSchema } from 'validation-schema-library';

export type FormData = Dictionary<string | object>;
export type FormFieldsData = Dictionary<any>;

export interface FormContextData {
  addFormError: (source: string, error: string) => void;
  addValidationErrorListener: (callback: ErrorListener) => void;
  collectionOnChangeCallback: (source: string, func: (values: any[]) => any[]) => void;
  data: FormFieldsData;
  fieldsExceptions: FieldsExceptions;
  formErrors: FormFieldsData;
  isCreateRequest: boolean;
  isDirty: boolean;
  isSubmitEnabled: boolean;
  isLoadingArtifact: boolean;
  onChangeCallback: (values: FormFieldsData, isFieldInitializing?: boolean) => void;
  removeValidationErrorListener: (callback: ErrorListener) => void;
  setFieldErrors: (source: string, errors: object) => void;
  setFormData: (data: FormFieldsData) => void;
  setFormErrors: (errors: FormFieldsData) => void;
  setSubmitEnabled: (flag: boolean) => void;
  submitForm: () => void;
  validationSchema: Nullable<ValidationSchema>;
  setIsLoadingArtifact: (flag: boolean) => void;
  validateForm: (validatedData?: FormFieldsData) => FormFieldsData;
}

const FormContext = React.createContext<Partial<FormContextData>>({});

export const FormProvider = FormContext.Provider;

export const useFormContext = () => useContext(FormContext);

export default FormContext;
