/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Underline,
  Strikethrough,
  RemoveFormat,
  List,
  BlockQuote,
  Link,
  Table,
  TableToolbar,
  Heading,
  EventInfo,
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { replaceUrlWithLink } from 'shared/uibuilder/ReplaceUrlWithLink/autoLinkerHelper';
import { WysiwygInputLayoutType, CKEditorEventType } from 'shared/uibuilder/form/input/WysiwygInput';
import ResizableHeight from './CKEditorPluggins/ResizableHeightPlugin';

// eslint-disable-next-line import/no-unresolved
import 'ckeditor5/ckeditor5.css';
import './WysiwygInputLayout.scss';

const defaultOnPaste = async ({ content }: CKEditorEventType) => Promise.resolve(content);

const randomInteger = (min: number, max: number) => {
  const rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
};

const DEFAULT_TOOLBAR = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'removeFormat',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'blockQuote',
  '|',
  'link',
  '|',
  'insertTable',
  '|',
  'heading',
];

const WysiwygInputLayout: WysiwygInputLayoutType = ({
  onChangeCallback: originalOnChangeCallback,
  onPasteCallback = defaultOnPaste,
  setRefreshDataCallback,
  editorConfig = DEFAULT_TOOLBAR,
  ...props
}) => {
  const { value, onBlur, onFocus, className, disabled } = getCommonInputProps(props, false);

  const [data, setData] = useState(value);

  const onChangeCallback = (_event: EventInfo, editor: { getData: () => string }) => {
    const editorValue = editor.getData().replace(/(\r\n|\n|\r)/gm, '');

    if (originalOnChangeCallback) {
      originalOnChangeCallback({
        target: {
          value: replaceUrlWithLink(editorValue),
        },
      });
    }
  };

  const handlePaste = (_event: EventInfo, args: any) => {
    const { files } = args.dataTransfer;
    const textContent = args.content;
    onPasteCallback({ files, content: textContent });
  };

  useEffect(() => {
    // CKEditor verifies that previous state != new state. We should add few spaces to force
    // updating
    const refreshData = (newData: string) => setData(newData + ' '.repeat(randomInteger(1, 30)));

    if (setRefreshDataCallback) {
      setRefreshDataCallback(refreshData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <div className={`wysiwyg-container ${className}`}>
        <CKEditor
          disabled={disabled}
          data={data}
          editor={ClassicEditor}
          config={{
            initialData: data,
            toolbar: {
              items: editorConfig,
            },
            extraPlugins: [ResizableHeight],
            plugins: [
              Table,
              TableToolbar,
              Heading,
              Bold,
              Essentials,
              Italic,
              Mention,
              Paragraph,
              Undo,
              Underline,
              Strikethrough,
              RemoveFormat,
              List,
              BlockQuote,
              Link,
            ],
          }}
          onChange={onChangeCallback}
          onBlur={onBlur}
          onFocus={onFocus}
          onReady={editor => {
            const editingView = editor.editing.view;
            // Past handler
            editingView.document.on('paste', handlePaste);
          }}
        />
      </div>
    </BaseInputLayout>
  );
};

WysiwygInputLayout.defaultProps = COMMON_INPUT_LAYOUT_DEFAULT_PROPS;

export default WysiwygInputLayout;
