/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useEmployeeService, { DELETE_EMPLOYEE } from 'erp/employee/employeeService';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { HTTP } from '../../../../shared/api/const';

const DeleteEmployeeButton = props => {
  const { getListUrl } = useEmployeeUrl();
  const { deleteById } = useEmployeeService();
  const id = useEmployeeId();

  const handleDelete = () => deleteById(id);

  return (
    <DeleteButton
      {...props}
      displayMessage="Do you really want to delete this employee?"
      deleteMethod={handleDelete}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Employee has been successfully deleted.',
        failureMessage: `Can't delete Employee.`,
        errorStatusesMessages: {
          [HTTP.BAD_REQUEST]: 'Employees with the Employee or Owner roles cannot be deleted.',
        },
      }}
      permissionToCheck={DELETE_EMPLOYEE}
      sx={{ marginLeft: '12px' }}
    />
  );
};

export default DeleteEmployeeButton;
