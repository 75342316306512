/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { CANDIDATE_APPLICATION_SOURCE_NAMES } from 'erp/candidate/applications/shared/candidateApplicationService';

const CandidateApplicationSourceNamesDropdown = (props: any) => {
  return (
    <Dropdown
      placeholder="Choose Source Name"
      options={Object.values(CANDIDATE_APPLICATION_SOURCE_NAMES).map(value => ({
        value,
        label: value,
      }))}
      label="Candidate Application Source Name"
      {...props}
    />
  );
};

export default CandidateApplicationSourceNamesDropdown;
