/* istanbul ignore file */
import React from 'react';
import Select, { MultiValue } from 'react-select';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { LoadMultiSelectInputLayoutType } from 'shared/uibuilder/form/input/LoadMultiSelectInput';
import { IndicatorsContainer } from './DropdownComponents';
import getDropdownLayoutCommonProps from './dropdownLayoutHelper';
import './DropdownLayout.scss';

const LoadMultiSelectInputLayout: LoadMultiSelectInputLayoutType = ({
  placeholder,
  onChangeCallback = () => {},
  noOptionsMessage,
  value: initialValue,
  options = [],
  menuPortalTarget = document.body,
  ...props
}) => {
  const { selectClassName, styles, classNamePrefix } = getDropdownLayoutCommonProps(props);

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <Select
        {...getCommonInputProps(props)}
        value={initialValue}
        className={selectClassName}
        classNamePrefix={classNamePrefix}
        isMulti
        menuPortalTarget={menuPortalTarget}
        placeholder={placeholder}
        onChange={(value: MultiValue<Option>) => {
          onChangeCallback({ target: { value } });
        }}
        noOptionsMessage={noOptionsMessage}
        isClearable
        styles={styles}
        options={options}
        components={{
          IndicatorsContainer,
          ...props?.components,
        }}
      />
    </BaseInputLayout>
  );
};

LoadMultiSelectInputLayout.defaultProps = {
  ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  placeholder: undefined,
};

export default LoadMultiSelectInputLayout;
