import { Filter, useFilterContext, FilterCondition } from 'shared/uibuilder/list/filter/FilterContext';

export interface ConditionHelperParams {
  conditionType?: FilterCondition;
  source: string;
  value?: string | string[];
}

const useConditionHelper = ({ conditionType = FilterCondition.EQ, ...props }: ConditionHelperParams) => {
  const { source } = props;
  const { getValue } = useFilterContext();

  const getRawValue = () => {
    if (!getValue) return null;

    const { value } = props;

    return value === undefined || value === null ? getValue(source, conditionType) : value;
  };

  const buildValue = (rawValue: Nullable<any> = null) => {
    const value = rawValue || getRawValue();

    return {
      [source]: {
        [conditionType]: value,
      },
    };
  };

  const getValueFromCondition = (value: Filter) => {
    return value && value[source] ? value[source][conditionType] : null;
  };

  return {
    getRawValue,
    buildValue,
    getValueFromCondition,
  };
};

export default useConditionHelper;
