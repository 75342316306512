/* istanbul ignore file */
// libs
import React from 'react';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import './Loading.scss';

export enum LoaderSize {
  Default = '40px',
  Medium = '24px',
  Small = '17px',
}

const Loading = ({
  style,
  size = LoaderSize.Default,
  hasBackground = false,
  hasPositionFixed = false,
}: {
  style?: React.CSSProperties;
  size?: LoaderSize;
  hasBackground?: boolean;
  hasPositionFixed?: boolean;
}) => (
  <div
    style={style}
    className={classnames('loading', { 'loading--bg': hasBackground, 'loading--fixed': hasPositionFixed })}
  >
    <CircularProgress size={size} />
  </div>
);

export default Loading;
