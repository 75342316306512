/* istanbul ignore file */
import React, { useEffect } from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useCandidateOfferService from 'erp/candidate/offercompensation/shared/candidateOfferService';
import { useCandidateOfferId, useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import { useDispatch } from 'react-redux';
import { clearTimer } from 'shared/uibuilder/DataTimer';

const CandidateOfferContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useCandidateOfferService();
  const CONTEXT_NAME = 'CandidateOffer_CONTEXT';
  const offerCompensationId = useCandidateOfferId();
  const { getListUrl } = useCandidateOfferUrl();
  const listUrl = getListUrl();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearTimer(listUrl));
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SecurityContextTemplate
      getPermissionsFunc={() => getPermissions(offerCompensationId)}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default CandidateOfferContext;
