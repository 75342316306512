import useJournalEntryService from '../useJournalEntryService';
import { useState } from 'react';
import { OFFICE } from '../../shared/field/OfficeField';

type Transfer = {
  id: string | null;
  accountId: string;
  debit: number;
  credit: number;
  currency: string;
  asDateOf: string;
  attributes: {
    office: string;
  };
  description: string;
};

type JournalEntry = {
  vendorId: string;
  description: string;
  transfers: Transfer[];
  asDateOf: string;
  attributes: {
    office: string;
  };
  id: string;
  idempotenceTokenId: Nullable<string>;
};

const useUploadJournalEntry = () => {
  const [initialFormsListData, setInitialFormsListData] = useState<JournalEntry[]>([]);

  const {
    getValidationSchema,
    uploadJournalEntry,
    submitListOfJournalEntry,
    mappingRequestData,
  } = useJournalEntryService();

  const getListJournalEntry = async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file[0].file);
    formData.append('office', data.office);
    formData.append('period', data.period);
    const response: JournalEntry[] = await uploadJournalEntry(formData);

    const formattedResponse = response.map(form => {
      return {
        ...form,
        totals: 0,
        attributes: {
          ...form.attributes,
          office: OFFICE.AUSTIN_1,
        },
      };
    });

    // TODO Now only AUSTIN_1. Office for expense. Same as in upload form.
    setInitialFormsListData(formattedResponse);

    return response;
  };

  const uploadListOfJournalEntry = async (data: JournalEntry[]) => {
    const mappingData = data.map(item => {
      return {
        ...mappingRequestData(item),
        idempotenceTokenId: item.idempotenceTokenId,
      };
    });

    return submitListOfJournalEntry(mappingData);
  };

  const additionalInfoCallback = (formsData: any[]) => {
    let totalDebit = 0;
    let totalCredit = 0;

    formsData.forEach(formData => {
      formData.data.transfers.forEach((transfer: { debit: number; credit: number }) => {
        totalDebit += +transfer.debit;
        totalCredit += +transfer.credit;
      });
    });

    return `Total Debit: ${totalDebit} / Total Credit: ${totalCredit}`;
  };

  return {
    additionalInfoCallback,
    getListJournalEntry,
    uploadListOfJournalEntry,
    initialFormsListData,
    getValidationSchema,
  };
};

export default useUploadJournalEntry;
