/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { IS_PHYSICAL_OPTION } from '../leaveService';

import './SignatureOptionDropdown.scss';

const SignatureOptionDropdown = props => {
  return (
    <div className="signature-option-dropdown">
      <EnumDropdown options={IS_PHYSICAL_OPTION} placeholder="" {...props} />
    </div>
  );
};

export default SignatureOptionDropdown;
