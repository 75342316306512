/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { SummaryMenuItem, ActivitiesMenuItem } from 'uibuilder/menuitem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import useAccountUrl from '../shared/accountUrl';
import { ContactsMenuItem } from 'crm/contact';
import { OpportunitiesMenuItem } from 'crm/opportunity';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_RELATED_CONTACTS, READ_RELATED_OPPORTUNITIES, READ_TIMELINE } from 'crm/crmService';

const ShowAccountPageMenu = () => {
  const { id } = useParams();
  const { getSingleEntityUrl, getTimelineUrl, getRelatedOpportunitiesUrl, getRelatedContactsUrl } = useAccountUrl();
  const { isGranted } = useAuthorization();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
      <ActivitiesMenuItem linkUrl={getTimelineUrl(id)} isVisible={isGranted(READ_TIMELINE)} />
      <OpportunitiesMenuItem
        linkUrl={getRelatedOpportunitiesUrl(id)}
        isVisible={isGranted(READ_RELATED_OPPORTUNITIES)}
      />
      <ContactsMenuItem linkUrl={getRelatedContactsUrl(id)} isVisible={isGranted(READ_RELATED_CONTACTS)} />
    </SidebarStickyMenu>
  );
};

export default ShowAccountPageMenu;
