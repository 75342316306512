import { ErrorsMap, FormValidationError } from 'validation-schema-library';

const LIST_ERROR_KEY_PATTERN = /\[(\d+)]/;

const useErrorMapping = (errorsMap: ErrorsMap = {}) => {
  const getErrorCode = (error: FormValidationError) => {
    const [errorCode] = error.split(':');

    return errorCode;
  };

  const mapErrorMessage = (error: FormValidationError) => {
    let result: string = error;

    Object.entries(errorsMap).forEach(entry => {
      const [key, handler] = entry;
      const [errorKey, params] = error.split(':');

      if (key === errorKey && typeof handler === 'function') {
        result = handler(...params.split(',').map(p => p.trim()));
      } else if (key === errorKey) {
        result = handler;
      }
    });

    return result;
  };

  /**
   * Processes each error using errorsMap object
   * Object format:
   * {
   *   key: handler,
   *   ...
   * }
   * each key is a prefix of an error
   * expected error value:
   * `${key}:${param1,param2, ... paramN}`
   * matched error`s from @errors param will be replaces
   * with result of handler function.
   */
  const mapFormErrors = (errors: ErrorsMap) => {
    const mappedErrors = { ...errors };

    Object.keys(errors).forEach(source => {
      const resultSource = getResultSource(source);
      const errorList = errors[source];
      mappedErrors[resultSource] = errorList.map(mapErrorMessage);
    });
    return mappedErrors;
  };

  /**
   * Map error source for correct mapping error message in the form.
   * For case, then error key from backend contains list value (like as "error[0]": "error message"),
   * return new error key like as "error.0".
   * For case, then error key not contains list value, return source.
   */
  const getResultSource = (source: string) => {
    return LIST_ERROR_KEY_PATTERN.test(source) ? source.replace(LIST_ERROR_KEY_PATTERN, '.$1') : source;
  };

  return {
    mapFormErrors,
    getErrorCode,
    mapErrorMessage,
  };
};

export default useErrorMapping;
