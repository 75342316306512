/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { oneOfType, string, number } from 'prop-types';
import { EmployeeLink } from 'erp/employee';
import './index.scss';

const ConvertedCandidateBadge = ({ id }) => {
  return (
    <Badge className="ml-2 converted-badge" color="success">
      <EmployeeLink id={id} name="Converted to Employee" />
    </Badge>
  );
};

ConvertedCandidateBadge.propTypes = {
  id: oneOfType([string, number]).isRequired,
};

export default ConvertedCandidateBadge;
