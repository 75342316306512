import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useWorkplaceService from 'erp/room/workplace/useWorkplaceService';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const DeleteWorkspaceButton = () => {
  const { getValue: getId } = useFieldHelper<string>({ source: 'id' });
  const { deleteById } = useWorkplaceService();
  const { reloadData } = useListContext();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(getId())}
      displayMessage="Do you really want to delete this workplace?"
      afterDelete={{
        execute: reloadData,
        successMessage: 'The Workplace has been successfully deleted.',
        errorMessage: `Can't delete workplace`,
      }}
    />
  );
};

export default DeleteWorkspaceButton;
