/* istanbul ignore file */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useFormContext } from 'shared/uibuilder/form/FormContext';
import ContactsBreadcrumbs from 'crm/contact/shared/ContactsBreadcrumbs';
import { SingleBreadcrumbItem, UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useContactUrl } from 'crm/contact/ContactRouter';

const UpdateContactBreadcrumbs = () => {
  const { id } = useParams();
  const { data } = useFormContext();
  const { firstName, middleName, lastName } = data;
  const { getSingleEntityUrl } = useContactUrl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedName = useMemo(() => `${firstName} ${middleName} ${lastName}`, []);

  return (
    <ContactsBreadcrumbs>
      <SingleBreadcrumbItem link={getSingleEntityUrl(id)} entity={displayedName} />
      <UpdateBreadcrumbItem />
    </ContactsBreadcrumbs>
  );
};

export default UpdateContactBreadcrumbs;
