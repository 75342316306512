import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import React from 'react';
import { useOfficeUrl } from 'erp/office/OfficeRouter';
import OfficeBreadcrumbs from 'erp/office/shared/OfficeBreadcrumbs';

const SingleOfficeBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useOfficeUrl();
  const id = data?.getValueBySource('id');
  const name = `${data?.getValueBySource('name')}`;

  return (
    <OfficeBreadcrumbs>
      <SingleBreadcrumbItem entity={name} link={getSingleEntityUrl(id)} />
      {children}
    </OfficeBreadcrumbs>
  );
};

export default SingleOfficeBreadcrumbs;
