import { useKernelApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';
import { CANDIDATE_RESOURCE_URL } from 'erp/candidate/shared/candidateService';

/**
 * Candidate communication service.
 */
const useCandidateCommunicationService = () => {
  const { sendPostRequest } = useKernelApi();

  const searchCommunicationItems = async (candidateId: ResourceId, request: any) => {
    const response = await sendPostRequest(`${CANDIDATE_RESOURCE_URL}/${candidateId}/emails/search`, request);

    return response.json();
  };

  const getCommunicationPageById = async (candidateId: ResourceId) => searchCommunicationItems(candidateId, {});

  const createEmail = async (candidateId: ResourceId, request: any) => {
    const response = await sendPostRequest(`${CANDIDATE_RESOURCE_URL}/${candidateId}/emails`, request);

    return response.json();
  };

  return {
    searchCommunicationItems,
    getCommunicationPageById,
    createEmail,
  };
};

export default useCandidateCommunicationService;
