/* istanbul ignore file */
import React from 'react';

import ShowPageLayout from 'shared/layout/ShowPageLayout';
import { DateField, TextField } from 'shared/uibuilder/field';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Show from 'shared/uibuilder/show/Show';
import SingleBackgroundCheckBreadcrumbs from 'erp/employee/backgroundcheck/shared/SingleBackgroundCheckBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { EmployeeLinkField } from 'erp/employee';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { useEmployeeBackgroundCheckId } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import DeleteEmployeeBackgroundCheckButton from 'erp/employee/backgroundcheck/shared/button/DeleteEmployeeBackgroundCheckButton';
import EditEmployeeBackgroundCheckButton from 'erp/employee/backgroundcheck/shared/button/EditEmployeeBackgroundCheckButton';
import useEmployeeBackgroundCheckService from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

const ViewEmployeeBackgroundCheck = () => {
  const { getById } = useEmployeeBackgroundCheckService();
  const id = useEmployeeBackgroundCheckId();

  return (
    <ShowPageLayout
      buttons={[<EditEmployeeBackgroundCheckButton />, <DeleteEmployeeBackgroundCheckButton />]}
      menu={<EmployeeMenu />}
      entityHeader={<EmployeeEntityHeader />}
      breadcrumbs={<SingleBackgroundCheckBreadcrumbs />}
    >
      <Show getDataMethod={() => getById(id)}>
        <ShowSection title="View: Background Check">
          <SectionRow>
            <DateField source="performedAt" label="Check Date" />
            <TextField source="result" label="Result" />
            <EmployeeLinkField source="reviewerId" nameSource="reviewerName" label="Reviewer" />
          </SectionRow>
          <BigSectionRow>
            <AttachmentsListField source="attachmentsIds" label="Check Reports" />
          </BigSectionRow>
        </ShowSection>
      </Show>
    </ShowPageLayout>
  );
};

export default ViewEmployeeBackgroundCheck;
