/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import { isStatusAllowed } from 'crm/shared/field/LastTouchCrmField';

interface ReadByOwnerCheckboxFieldProps extends FieldProps {
  ownerSource?: string;
  statusSource: string;
  skipStates: string[];
  revertValue?: boolean;
}

const ReadByOwnerCheckboxField = ({
  source,
  ownerSource = 'ownerAlias',
  statusSource,
  skipStates,
  revertValue = false,
}: ReadByOwnerCheckboxFieldProps) => {
  const { getValue: getIsRead } = useFieldHelper({ source });
  const { getValue: getOwnerAlias } = useFieldHelper({ source: ownerSource });
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const ownerAlias = getOwnerAlias();
  const status = getStatus();
  const isStatusApplicable = isStatusAllowed(status, skipStates);
  if (!ownerAlias || !isStatusApplicable) {
    return <TextField value={null} />;
  }
  const isRead = getIsRead();
  const resultValue = revertValue ? !isRead : isRead;

  return resultValue ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />;
};

export default ReadByOwnerCheckboxField;
