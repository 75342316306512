import React, { useContext } from 'react';
import { ValidationSchema } from 'validation-schema-library';

export interface BaseFilterProps {
  source: string;
  label?: any;
}

export interface Filter {
  [index: string]: Dictionary<any>;
}

export type FilterError = string[];
export type FilterErrors = Dictionary<Dictionary<FilterError>>;

export enum FilterCondition {
  EQ = 'eq',
  CT = 'ct',
  IN = 'in',
  LE = 'le',
  GE = 'ge',
}

export type ApplyOptions = {
  saveEmptyFilters?: boolean;
};

interface FilterContextData {
  clear: () => void;
  apply: (applyOptions?: ApplyOptions) => void;
  getValue: (source: string, condition: FilterCondition) => string;
  filterBy: (filter: Filter) => void;
  filters: Filter;
  clearFilter: (source: string) => void;
  clearAndApplyFilter: (source: string | string[]) => void;
  quickFilter: (filter: Filter) => void;
  errors: FilterErrors;
  getFilterErrors: (source: string, condition: FilterCondition) => FilterError;
  setFilterErrors: (source: string, condition: FilterCondition, filterErrors: FilterError) => void;
  resetFilter: (source: string) => void;
  resetAllFilter: (defaultFilters: Filter) => void;
  validationSchema: Nullable<ValidationSchema>;
  isStatic: boolean;
}

const DEFAULT_FUNCTION = () => {};

const defaultContext = {
  clear: DEFAULT_FUNCTION,
  apply: DEFAULT_FUNCTION,
  getValue: () => '',
  filterBy: DEFAULT_FUNCTION,
  filters: {},
  clearFilter: DEFAULT_FUNCTION,
  clearAndApplyFilter: DEFAULT_FUNCTION,
  quickFilter: DEFAULT_FUNCTION,
  errors: {},
  getFilterErrors: () => [],
  setFilterErrors: DEFAULT_FUNCTION,
  resetFilter: DEFAULT_FUNCTION,
  resetAllFilter: DEFAULT_FUNCTION,
  validationSchema: null,
  isStatic: false,
};
const FilterContext = React.createContext<FilterContextData>(defaultContext);

export const FilterProvider = FilterContext.Provider;

export const useFilterContext = () => useContext(FilterContext);

export default FilterContext;
