/* istanbul ignore file */
import React from 'react';
import { InputList, TextInput } from 'shared/uibuilder/form/input/index';
import { InputListProps } from './InputList/InputList';

const EmailListInput = (props: InputListProps) => {
  return <InputList {...props} inputTemplate={TextInput} addText="Add email" />;
};

export default EmailListInput;
