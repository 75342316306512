/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import Filters from 'shared/uibuilder/list/filter/Filters';
import 'crm/lead/List/LeadListPage.scss';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import LeadsListContent from 'crm/lead/List/LeadsListContent';
import useLeadService from 'crm/lead/shared/leadService';

const LeadsList = () => {
  const { search, getFilterValidationSchema } = useLeadService();

  return (
    <List getDataMethod={search} defaultSortField="updatedAt" defaultSortOrder={DESC}>
      <Filters getValidationSchema={getFilterValidationSchema}>
        <LeadsListContent />
      </Filters>
    </List>
  );
};

export default LeadsList;
