/* istanbul ignore file */
import React from 'react';
import Scrollbars from 'uibuilder/Scrollbars';
import ProjectQueue from 'syberryToday/shared/ProjectQueue';
import ScrollStepper from 'uibuilder/Scrollbars/ScrollStepper';
import ProjectAllocation from 'syberryToday/shared/ProjectAllocation';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import { calculateAllProjectIssues, getAllSubprojects } from 'syberryToday/useSyberryTodayService';
import Alert from 'uibuilder/Alert';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import './ProjectQueues.scss';

const ProjectQueues = ({
  canAddIssues,
  canEnterAllocations,
}: {
  canAddIssues?: boolean;
  canEnterAllocations?: boolean;
}) => {
  const { loading: updatingData } = useSyberryTodayContext();
  const { data, loading } = useListContext();
  const { items = [] } = data || {};
  const activeProjectData = items[0] || {};
  const { customQueues, subProjects, id, name } = activeProjectData;
  const allSubProjects = getAllSubprojects(subProjects);
  const isEmpty = !calculateAllProjectIssues([activeProjectData]);

  const getQueues = (queues: any[], subtitle: string) =>
    queues?.map(queue => {
      const isProcessProject = name === 'Individual tasks';

      return (
        <ProjectQueue
          key={`queue-${queue.id}`}
          data={queue.issues}
          title={queue.name}
          queueId={queue.id}
          subtitle={subtitle}
          projectId={id}
          sortable={!String(queue.issues[0]?.customQueue?.davinciQueueId).startsWith('s') && !isProcessProject}
          canAddIssues={canAddIssues && !isProcessProject}
        />
      );
    });

  return (
    <LoadingDecorator
      key={`project-queues-${id}`}
      deps={[updatingData, data]}
      loading={loading || updatingData}
      saveRenderedContent={updatingData}
    >
      <div className="daily-chickin-project-allocation__wrapper">
        <ProjectAllocation
          key={activeProjectData.id}
          projectId={activeProjectData.id}
          projectName={activeProjectData.name}
          allocation={activeProjectData.allocation}
          canEnterAllocations={canEnterAllocations}
        />
      </div>
      {isEmpty ? (
        <Alert color="info" className="mt-3">
          No queues found
        </Alert>
      ) : (
        <ScrollStepper
          className="daily-chickin__scroll-stepper"
          columnWidth={420}
          columnGap={40}
          scrollOffset={3}
          containerSelector=".simplebar-content-wrapper"
          columnSelector=".project-queue__wrapper"
        >
          <Scrollbars className="queues-wrapper" maxHeight="calc(100vh - 240px)">
            <div className="project-issues__wrapper">
              <div>
                {getQueues(customQueues, name)}
                {allSubProjects?.map((subproject: any) =>
                  getQueues(subproject.customQueues, `${name} - ${subproject.name}`),
                )}
              </div>
            </div>
          </Scrollbars>
        </ScrollStepper>
      )}
    </LoadingDecorator>
  );
};

export default ProjectQueues;
