/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { EXPENSE_SUBTYPES, SUBTYPE_FIELD_LABELS } from 'financialAnalytic/expenses/useExpenseService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const ExpenseSubTypeField = ({ typeSource, source, ...props }: FieldProps & { typeSource: string }) => {
  const { getValue: getType } = useFieldHelper({ source: typeSource });
  const type = getType() as any;
  const options = EXPENSE_SUBTYPES[type];
  const label = SUBTYPE_FIELD_LABELS[type];

  return <EnumField options={options || {}} {...props} source={source} label={label} />;
};

export default ExpenseSubTypeField;
