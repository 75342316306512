/* istanbul ignore file */
import React from 'react';
import Stepper, { StepperProps } from 'uibuilder/Stepper';
import BaseInputErrors from 'uibuilder/layout/form/input/BaseInputErrors';
import SkipDailyCheckinModal from 'syberryToday/shared/SkipDailyCheckinModal';
import './DailyCheckinStepperLayout.scss';

type Step = {
  label: string;
  buttonLabel: string;
  isButtonDisabled?: boolean;
  description?: React.ReactNode;
};

type PlannedInfo = {
  label: string;
  value: React.ReactNode;
  errorMessages?: string[];
};

interface DailyCheckinStepperLayoutProps extends StepperProps {
  steps: Step[];
  plannedInfo: PlannedInfo[];
}

const DailyCheckinStepperLayout = ({
  steps,
  activeStep,
  handleNext,
  handleBack,
  plannedInfo,
}: DailyCheckinStepperLayoutProps) => {
  return (
    <div className="daily-checkin-stepper__wrapper">
      <Stepper activeStep={activeStep} steps={steps} handleBack={handleBack} handleNext={handleNext} />

      <div className="daily-checkin-stepper__planned_info">
        {plannedInfo.map(({ label, value, errorMessages }) => (
          <React.Fragment key={label}>
            <div>
              {label}: <span className={errorMessages?.length ? 'invalid' : ''}>{value}</span>
            </div>
            {errorMessages?.length ? <BaseInputErrors errorMessages={errorMessages} /> : null}
          </React.Fragment>
        ))}
      </div>
      <SkipDailyCheckinModal />
    </div>
  );
};

export default DailyCheckinStepperLayout;
