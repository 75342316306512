/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface ProjectInternalReportCpiFieldProps {
  source: string;
  label: string;
}

const ProjectInternalReportCpiField = ({ source, label }: ProjectInternalReportCpiFieldProps) => {
  const { getValue: getData } = useFieldHelper({ source });
  const data = +getData();
  const color = data <= 1 ? 'green' : 'red';

  return <p style={{ color: `${color}` }}> {data} </p>;
};

export default ProjectInternalReportCpiField;
