import React from 'react';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import useBudgetService, { BudgetSearchFilterProps } from 'erp/budget/shared/budgetService';
import BudgetDropdown from 'erp/budget/shared/input/BudgetDropdown';
import useInputHelper from '../../../../shared/uibuilder/form/input/inputHelper';

interface AvailableBudgetsDropdownProps extends DefaultDropdownProps {
  budgetFromSource: string;
  filter?: BudgetSearchFilterProps;
  deps?: any[];
}

const AvailableBudgetsDropdown = ({ budgetFromSource, filter = {}, ...props }: AvailableBudgetsDropdownProps) => {
  const { getValue: getBudgetFromId } = useInputHelper({ source: budgetFromSource });
  const budgetFromId = getBudgetFromId() as string;
  const { searchAvailable, getSearchFilter } = useBudgetService();

  const loadAvailableBudget = async () => {
    if (!budgetFromId) {
      return {
        result: [],
      };
    }

    return searchAvailable(budgetFromId, { filter: getSearchFilter(filter) });
  };

  return <BudgetDropdown {...props} disabled={!budgetFromId} loadDataMethod={loadAvailableBudget} showLoader />;
};

export default AvailableBudgetsDropdown;
