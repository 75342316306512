/* istanbul ignore file */
import { SingletonHooksContainer } from 'react-singleton-hook';
import React from 'react';
import { arrayOf, func } from 'prop-types';

const HookContainer = ({ useHook }) => {
  useHook();
  return <></>;
};

HookContainer.propTypes = {
  useHook: func.isRequired,
};

const EagerInitializer = ({ hooksForEagerInitialization }) => {
  return hooksForEagerInitialization.map(useHook => <HookContainer useHook={useHook} />);
};

EagerInitializer.propTypes = {
  hooksForEagerInitialization: arrayOf(func).isRequired,
};

/**
 * By default, react-singleton-hook component uses lazy initialization for hooks.
 * So, it returns some static object when it's called for the first time.
 *
 * It's not always a correct behavior. So, with this decorator
 * developer can specify hooks which should use eager initialization.
 */
const SingletonHooksProvider = ({ hooksForEagerInitialization }) => {
  return (
    <>
      <SingletonHooksContainer />
      <EagerInitializer hooksForEagerInitialization={hooksForEagerInitialization} />
    </>
  );
};

SingletonHooksProvider.propTypes = {
  hooksForEagerInitialization: arrayOf(func).isRequired,
};

export default SingletonHooksProvider;
