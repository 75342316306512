import { useEffect, useState } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useCacheService from 'shared/cache/cacheService';
import { updateCurrencyName } from 'shared/uibuilder/amountUtils';

interface UseAccountInputProps extends DefaultInputPropTypes<string> {
  source: string;
  loadDataMethod: () => Promise<any>;
  cacheKey: string;
  shouldDisableParentAccounts?: boolean;
}

export const getOptions = (data: any[], shouldDisableParentAccounts = true) => {
  const options: any[] = [];

  const getChildrenValues = (children: any[]): string[] => {
    if (children?.length) {
      return children.flatMap(child => [child.id, ...getChildrenValues(child.children)]);
    }

    return [];
  };

  const addOptions = (accounts: any[], level = 0) => {
    accounts?.forEach(account => {
      options.push({
        value: account.id,
        label: updateCurrencyName(account.id.replace(/[/]/g, ' / ')),
        currency: account.currency,
        level,
        disabled: shouldDisableParentAccounts && !!account.children?.length,
        name: updateCurrencyName(account.name),
        childrenValues: getChildrenValues(account.children),
      });

      if (account.children?.length) {
        addOptions(account.children, level + 1);
      }
    });
  };

  addOptions(data || []);

  return options;
};

const CACHE_KEY = 'options';

const useAccountInput = ({ loadDataMethod, cacheKey, shouldDisableParentAccounts, ...props }: UseAccountInputProps) => {
  const { addToCache, getValue } = useCacheService(cacheKey);
  const { getSource, getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback() as any;
  const source = getSource();

  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const search = async () => {
    const cachedValue = getValue(CACHE_KEY);
    if (cachedValue) {
      return cachedValue;
    } else {
      const { result } = await loadDataMethod();
      const loadedOptions = getOptions(result, shouldDisableParentAccounts);

      addToCache(CACHE_KEY, loadedOptions);

      return loadedOptions;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await search();

        setOptions(result);
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onChangeCallback = (value: any) => {
    const selectedOption = options.find(option => option.value === value[source]);

    rawOnChangeCallback({ ...value, currency: selectedOption?.currency });
  };

  const filterOption = (option: any, inputValue: string) =>
    updateCurrencyName(option.value)
      .toLowerCase()
      .includes(inputValue.toLowerCase()) ||
    option.data.childrenValues?.some((value: string) =>
      updateCurrencyName(value)
        .toLowerCase()
        .includes(inputValue.toLowerCase()),
    );

  return {
    loading,
    options,
    onChangeCallback,
    filterOption,
  };
};

export default useAccountInput;
