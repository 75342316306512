/* istanbul ignore file */
import CreateActivityForm from 'crm/shared/timeline/activity/form/CreateActivityForm';
import UpdateActivityForm from 'crm/shared/timeline/activity/form/UpdateActivityForm';
import ActivityTimelineRecordLayout from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout';
import { UPDATE_CRM_ACTIVITY_PERMISSION } from './crmActivityService';

const useCrmActivityItem = () => ({
  AddForm: CreateActivityForm,
  UpdateForm: UpdateActivityForm,
  isApplicable: item => item.activity,
  source: 'activity',
  urlPostfix: 'activities',
  RecordLayout: ActivityTimelineRecordLayout,
  name: 'Activity',
  permissionToCheck: UPDATE_CRM_ACTIVITY_PERMISSION,
});

export default useCrmActivityItem;
