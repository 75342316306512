import React, { useEffect } from 'react';
import { oneOfType, node, func, string } from 'prop-types';
import { TextInput, DateInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import LongFormRow from 'shared/layout/form/FormRow';
import './index.scss';

const RelationInput = ({ relationInput: RelationComponent, className, ...props }) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);

  const rawOnChangeCallback = getRawOnChangeCallback();
  const familyMember = getValue() || {
    name: {},
  };
  const source = getSource();

  const nameOnChangeCallback = value => {
    rawOnChangeCallback({
      [source]: {
        ...familyMember,
        name: {
          ...familyMember.name,
          ...getLastSource(value),
        },
      },
    });
  };

  const onChangeCallback = value => {
    rawOnChangeCallback({
      [source]: {
        ...familyMember,
        ...getLastSource(value),
      },
    });
  };

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: { name: {} },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LongFormRow className="full-width">
      <TextInput
        source={`${source}.name.firstName`}
        value={familyMember.name.firstName}
        onChangeCallback={nameOnChangeCallback}
        className={className}
      />
      <TextInput
        source={`${source}.name.middleName`}
        value={familyMember.name.middleName}
        onChangeCallback={nameOnChangeCallback}
        className={className}
      />
      <TextInput
        source={`${source}.name.lastName`}
        value={familyMember.name.lastName}
        onChangeCallback={nameOnChangeCallback}
        className={className}
      />
      <RelationComponent
        source={`${source}.relation`}
        label="Relation Degree"
        value={familyMember.relation}
        onChangeCallback={onChangeCallback}
        className={className}
      />
      <DateInput
        source={`${source}.dateOfBirth`}
        value={familyMember.dateOfBirth}
        label="Date of Birth"
        onChangeCallback={onChangeCallback}
        className={className}
      />
    </LongFormRow>
  );
};

RelationInput.propTypes = {
  relationInput: oneOfType([func, node]).isRequired,
  className: oneOfType([func, string]),
};

RelationInput.defaultProps = {
  className: '',
};

export default RelationInput;
