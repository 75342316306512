/* istanbul ignore file */
const contractFilterValidation = {
  startDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: [
          {
            value: '$startDate.le',
            when: [
              {
                '$startDate.le': {
                  notEmpty: true,
                },
              },
            ],
            message: 'Please choose date before or at "to" date',
          },
          {
            value: '$endDate.ge',
            when: [
              {
                '$endDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$endDate.le',
            when: [
              {
                '$endDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
      le: {
        type: 'date',
        minDate: {
          value: '$startDate.ge',
          message: 'Please choose date after or at "from" date',
        },
        maxDate: [
          {
            value: '$endDate.le',
            when: [
              {
                '$endDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$endDate.ge',
            when: [
              {
                '$endDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
    },
  },
  endDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: { value: '$endDate.le', message: 'Please choose date before or at "to" date' },
        minDate: [
          {
            value: '$startDate.ge',
            when: [
              {
                '$startDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$startDate.le',
            when: [
              {
                '$startDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
      le: {
        type: 'date',
        minDate: [
          {
            value: '$endDate.ge',
            when: [
              {
                '$endDate.ge': {
                  notEmpty: true,
                },
              },
            ],
            message: 'Please choose date after or at "from" date',
          },
          {
            value: '$startDate.le',
            when: [
              {
                '$startDate.le': {
                  notEmpty: true,
                },
              },
            ],
          },
          {
            value: '$startDate.ge',
            when: [
              {
                '$startDate.ge': {
                  notEmpty: true,
                },
              },
            ],
          },
        ],
      },
    },
  },

  dateOfSignature: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: {
          value: '$dateOfSignature.le',
          message: 'Please choose date before or at "to" date',
        },
      },
      le: {
        type: 'date',
        minDate: {
          value: '$dateOfSignature.ge',
          message: 'Please choose date after or at "from" date',
        },
      },
    },
  },
};

export default contractFilterValidation;
