import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Badge from 'shared/uibuilder/badge/Badge';

const IncidentCountField = (props: any) => {
  const { getValue } = useFieldHelper(props);
  const count = getValue();
  const badgeColor = count ? 'error' : 'success';

  return <Badge color={badgeColor}>{count}</Badge>;
};

export default IncidentCountField;
