/* istanbul ignore file */
export default {
  assignmentLanguage: {
    type: 'enum',
    required: true,
  },
  assessmentDate: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$NOW',
      message: 'Assessment date cannot be in future',
    },
  },
  levelAssignment: {
    type: 'string',
    maxSize: 64,
    required: true,
  },
  assessorName: {
    type: 'string',
    maxSize: 128,
    required: true,
  },
  feedbackDetails: {
    type: 'string',
    maxSize: 1024,
    required: true,
  },
  filesIds: {
    type: 'array',
    maxSize: 10,
  },
};
