/* istanbul ignore file */
import React from 'react';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button/index';
import JournalEntryStornoToggler from 'financialAnalytic/journalEntry/shared/Settings/JournalEntryStornoToggler';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';

const JournalEntrySettings = () => {
  return (
    <DropdownMenu
      popupId="journal-entry-settings"
      iconButton={
        <IconButton className="dropdown-toggle">
          <SettingsIcon />
        </IconButton>
      }
    >
      <JournalEntryStornoToggler />
    </DropdownMenu>
  );
};

export default JournalEntrySettings;
