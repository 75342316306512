/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { TextInput } from 'shared/uibuilder/form/input';
import BudgetInput from 'erp/budget/shared/input/BudgetInput';

interface SkillBasedQueueFormProps {
  isCreateForm?: boolean;
}

const SkillBasedQueueForm = ({ isCreateForm }: SkillBasedQueueFormProps) => {
  return (
    <FormSection title="Skill Based Queue information">
      <TextInput label="Skill" source="skillName" errorSource="skillId" disabled={!isCreateForm} />
      <BudgetInput
        source="defaultBudgetId"
        nameSource="defaultBudgetName"
        label="Default Budget"
        searchString="name"
        filter={{ defaultableBudget: true, notArchived: true }}
      />
    </FormSection>
  );
};

export default SkillBasedQueueForm;
