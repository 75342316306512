import React, { RefObject, useEffect, useState } from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import SearchInputLayout, { DEFAULT_SEARCH_INPUT_ID } from 'shared/uibuilder/list/search/SearchInputLayout';
import { useLocation } from 'react-router-dom';

const TYPING_TIMEOUT = 600;
const MAX_SUGGESTED_QUERIES = 5;

interface BaseSearchInputProps {
  id?: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  onKeyUpCallback?: (event: React.KeyboardEvent<HTMLElement>) => void;
  onFocusCallback?: () => void;
  isAutoCompleteEnabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  className?: string;
  width?: string;
}

export interface SearchInputLayoutProps extends BaseSearchInputProps {
  value: string;
  suggestions: string[];
  onChangeCallback: (event: CustomFormEvent<string>) => void;
  tooltipMessage?: string;
}

interface SearchInputProps extends BaseSearchInputProps {
  typingTimeout?: number;
  maxSuggestedQueries?: number;
  valueDecorator?: (value: string) => string;
  tooltipMessage?: string;
  cleanUpValueWhenChangedFilters?: boolean;
}

/**
 * Search Input
 * @param typingTimeout interval for user typing (within this time, search won't be applied).
 * @param maxSuggestedQueries - max count of saved suggestions.
 * @param valueDecorator - decorator for search value
 * @param tooltipMessage - tooltip message for search input
 * @param id - input id
 */
const SearchInput = ({
  label,
  placeholder,
  typingTimeout,
  maxSuggestedQueries,
  valueDecorator,
  tooltipMessage,
  inputRef,
  isAutoCompleteEnabled,
  onKeyUpCallback,
  onFocusCallback,
  id,
  className,
  width,
  cleanUpValueWhenChangedFilters = false,
}: SearchInputProps) => {
  const { searchBy: applySearch, search = '' } = useListContext();
  const [searchText, setSearchText] = useState(search);
  const [applyingTimeout, setApplyingTimeout] = useState<number>();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    if (cleanUpValueWhenChangedFilters && !search && searchText) {
      setSearchText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanUpValueWhenChangedFilters, search]);

  const localStorageKey = `${pathname}_suggestions`;

  const addSuggestion = (suggestion: string) => {
    if (!suggestions.includes(suggestion) && suggestion !== '') {
      setSuggestions(prev => [suggestion, ...prev].slice(0, maxSuggestedQueries));
    }
  };

  /**
   * Load suggestions from local storage
   */
  useEffect(() => {
    setSuggestions(JSON.parse(localStorage.getItem(localStorageKey) as string) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update suggestions in local storage
   */
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(suggestions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  const onChangeCallback = (event: CustomFormEvent<string>) => {
    const inputValue = event.target.value || '';
    const value = valueDecorator ? valueDecorator(inputValue) : inputValue;
    setSearchText(value);

    if (applyingTimeout) clearTimeout(applyingTimeout);
    const timeout = setTimeout(() => {
      if (applySearch) {
        applySearch(value.trim());
      }
      addSuggestion(value.trim());
    }, typingTimeout);

    setApplyingTimeout(timeout as any);
  };

  return (
    <SearchInputLayout
      onChangeCallback={onChangeCallback}
      value={searchText}
      label={label}
      placeholder={placeholder}
      suggestions={suggestions}
      tooltipMessage={tooltipMessage}
      onKeyUpCallback={onKeyUpCallback}
      onFocusCallback={onFocusCallback}
      inputRef={inputRef}
      isAutoCompleteEnabled={isAutoCompleteEnabled}
      id={id}
      className={className}
      width={width}
    />
  );
};

SearchInput.defaultProps = {
  label: null,
  inputRef: {},
  placeholder: '',
  isAutoCompleteEnabled: true,
  onKeyUpCallback: null,
  onFocusCallback: null,
  typingTimeout: TYPING_TIMEOUT,
  maxSuggestedQueries: MAX_SUGGESTED_QUERIES,
  valueDecorator: undefined,
  tooltipMessage: undefined,
  id: DEFAULT_SEARCH_INPUT_ID,
};

export default SearchInput;
