/* istanbul ignore file */
import React from 'react';
import Page404 from 'shared/uibuilder/Page404';
import { LeadRouter } from 'crm/lead';
import { AccountRouter } from 'crm/account';
import { ContactRouter } from 'crm/contact';
import { OpportunityRouter } from 'crm/opportunity';
import { ProjectsDashboardRouter } from 'crm/projectsDashboard';
import { RoutingSwitch, Router, Route } from 'shared/routing';

const CRM_PATH = '/crm';

const CrmRouter: Router = () => (
  <RoutingSwitch>
    <LeadRouter />
    <AccountRouter />
    <ContactRouter />
    <OpportunityRouter />
    <ProjectsDashboardRouter />
    <Route component={Page404} />
  </RoutingSwitch>
);

CrmRouter.getRouterPath = () => CRM_PATH;

export default CrmRouter;
