/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import {
  useEmployeeBackgroundCheckId,
  useEmployeeBackgroundChecksUrl,
} from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import EmployeeBackgroundCheckBreadCrumbs from 'erp/employee/backgroundcheck/shared/EmployeeBackgroundCheckBreadcrumbs';

const SingleBackgroundCheckBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const id = useEmployeeBackgroundCheckId();
  const { getSingleEntityUrl } = useEmployeeBackgroundChecksUrl();

  return (
    <EmployeeBackgroundCheckBreadCrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </EmployeeBackgroundCheckBreadCrumbs>
  );
};

export default SingleBackgroundCheckBreadcrumbs;
