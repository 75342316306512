/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useRbsService from 'erp/rbs/shared/rbsService';

export const RBS_CONTEXT_NAME = 'RBS_CONTEXT';

const RbsContext = ({ permissionToCheck, children, id }: SecurityContextCommonProps) => {
  const { getPermissions } = useRbsService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={RBS_CONTEXT_NAME}
      id={id}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default RbsContext;
