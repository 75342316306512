/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const EditVacancyButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useVacancyUrl();

  return <EditButton {...props} path={id => getUpdateEntityUrl(id)} />;
};

export default EditVacancyButton;
