import React from 'react';
import useEmployeeSpecializationService, {
  EmployeeSpecialization,
} from 'erp/employee/qualification/shared/employeeSpecializationService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';

const NewSpecializationDropdown = (props: DefaultDropdownProps) => {
  const employeeId = useEmployeeId() || '';
  const { getNotAppliedSpecializations } = useEmployeeSpecializationService();

  const loadSpecializations = async () => {
    return getNotAppliedSpecializations(employeeId).then(result => ({ result }));
  };

  return (
    <LoadDropdown
      loadDataMethod={loadSpecializations}
      mapResults={({ id, title }: EmployeeSpecialization) => ({
        value: id,
        label: title,
      })}
      {...props}
    />
  );
};

export default NewSpecializationDropdown;
