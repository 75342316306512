import { createStore, compose, applyMiddleware, Dispatch, Middleware } from 'redux';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from 'shared/reducer/rootReducer';
import { migrations, lastVersion } from 'shared/reducer/storeMigrations';

const middleware: Middleware = ({
  dispatch,
  getState,
}: {
  dispatch: Dispatch;
  getState: () => unknown;
}) => next => action => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }

  return next(action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'authToken',
    'oneStopSettings',
    'syberryTodaySettings',
    'journalEntriesSettings',
    'purchaseOrderSettings',
    'payrollReportsSettings',
    'employeeNames',
  ],
  version: +lastVersion,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(middleware)));
const persistor = persistStore(store);

export { store, persistor };
