/* istanbul ignore file */
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import { CheckboxGroupProps } from 'shared/uibuilder/form/input/CheckboxGroup';

export type CheckboxGroupLayoutType = ReactComponent<CheckboxGroupProps>;

const CheckboxGroupFilter = ({ source, ...props }: CheckboxGroupProps) => {
  const { CheckboxGroupFilterLayout } = useUiTheme<CheckboxGroupLayoutType>();

  return (
    <EqCondition source={source}>
      <CheckboxGroupFilterLayout {...props} source={source} />
    </EqCondition>
  );
};

export default CheckboxGroupFilter;
