/* istanbul ignore file */
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field/index';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';

const PurchaseOrderTotalField = ({ source, ...props }: FieldProps & { isVisible?: boolean }) => {
  return (
    <InvoiceTotalField
      source={source}
      currencySource={source as string}
      formatAmount={subTotal => subTotal?.amount}
      formatCurrency={subTotal => subTotal?.currency}
      {...props}
    />
  );
};

export default PurchaseOrderTotalField;
