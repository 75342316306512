import useRoomService from 'erp/room/useRoomService';
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateRoomBreadcrumbs from 'erp/room/createupdate/Update/Breadcrumbs';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import RoomForm from 'erp/room/createupdate/RoomForm';
import { useRoomUrl } from 'erp/room/RoomRouter';
import { snakeCase } from 'lodash';

const UpdateRoomPage = () => {
  const { update, getById, getValidationSchema } = useRoomService();
  const { getSingleEntityUrl } = useRoomUrl();

  const getDataFunc = async (id: string) => {
    const { office, ...data } = await getById(id);
    return {
      office: snakeCase(office.name).toUpperCase(),
      ...data,
    };
  };

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      afterSubmit={{
        redirect: ({ id }: any) => getSingleEntityUrl(id),
        message: 'You are awesome! The Room has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <UpdatePageLayout breadcrumbs={<UpdateRoomBreadcrumbs />} title="Edit: Room">
        <RoomForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateRoomPage;
