import { useEffect, useState, useMemo } from 'react';
import getLoadingParams from 'shared/uibuilder/list/builder/listBuilderHelper';
import { LoadingParams, SearchParams } from '../ListContext';

export interface ListUpdatingHelperParams {
  loadData: (params: LoadingParams) => Promise<any>;
  params: SearchParams;
  pageSize?: number;
}

const useListDataUpdating = ({ loadData, params, pageSize }: ListUpdatingHelperParams) => {
  const [data, setData] = useState({
    items: [],
    totalPages: 0,
    totalElements: 0,
  });

  const loadingParams = useMemo(() => getLoadingParams(params, pageSize), [pageSize, params]);

  const handleLoad = async () => {
    const response = await loadData(loadingParams);
    setData({
      items: response.result || [],
      totalPages: response.totalPages || 0,
      totalElements: response.totalElements || 0,
    });
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingParams]);

  return {
    data,
    reloadData: handleLoad,
  };
};

export default useListDataUpdating;
