/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import EditEmployeeCommonSkillModalForm from 'erp/employee/commonSkills/shared/EditEmployeeCommonSkillModalForm';
import { UPDATE_EMPLOYEE_COMMON_SKILL } from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';

const EditEmployeeCommonSkillButton = ({ source, ...props }: any) => {
  const { isGranted } = useAuthorization();
  const { getValue } = useFieldHelper({ source });
  const id = getValue();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  if (!isGranted(UPDATE_EMPLOYEE_COMMON_SKILL)) {
    return null;
  }

  return (
    <>
      <Button onClick={openCloseIssueForm}>Edit</Button>
      {isFormOpen && <EditEmployeeCommonSkillModalForm id={id} closeModal={closeModal} />}
    </>
  );
};

export default EditEmployeeCommonSkillButton;
