/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import ListBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/ListBreadcrumbItem';

const ListEmployeeOfferBreadcrumbs = () => {
  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Offers" />
    </SingleEmployeeBreadcrumbs>
  );
};

export default ListEmployeeOfferBreadcrumbs;
