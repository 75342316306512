/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import AddIcon from '@mui/icons-material/AddOutlined';

interface AddListItemButtonProps {
  onClickCallback: () => void;
}

const AddListItemButton = ({ onClickCallback }: AddListItemButtonProps) => {
  return (
    <Button
      value="Add item"
      size="small"
      outline
      onClick={onClickCallback}
      startIcon={<AddIcon />}
      sx={{ marginBottom: '20px' }}
    >
      Add item
    </Button>
  );
};

export default AddListItemButton;
