import { useBusinessEngineApi } from 'api';
import TERMINATE_PROCESS_INSTANCE_VALIDATION from 'camunda/monitoring/instance/Show/shared/TerminateProcessInstanceValidation';

export const TERMINATE_PROCESS = 'TERMINATE_PROCESS';

const useProcessInstanceUpdatingService = () => {
  const { sendDeleteRequest } = useBusinessEngineApi();

  const terminate = async (request: any) => {
    const response = await sendDeleteRequest(`/camunda/process/terminate`, request);
    return response;
  };

  const getValidationSchemaToTerminate = () => Promise.resolve(TERMINATE_PROCESS_INSTANCE_VALIDATION);

  return {
    terminate,
    getValidationSchemaToTerminate,
  };
};

export default useProcessInstanceUpdatingService;
