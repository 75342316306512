import { useKernelApi } from 'api';

export const RESOURCE_URL = '/rbs';

const useTeamService = () => {
  const { sendGetRequest } = useKernelApi();

  const search = async (parentNodeId: string) => {
    if (parentNodeId) {
      const response = await sendGetRequest(`${RESOURCE_URL}/${parentNodeId}/team/suggestions`);
      return response.json();
    }

    return [];
  };

  return {
    search,
  };
};

export default useTeamService;
