// libs

import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { childrenAsNodesType } from 'shared/prop-types';
// components
import SensitiveDataPlug from 'shared/uibuilder/sensitiveData/SensitiveDataPlug';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import ToggleSensitiveDataButton from 'shared/uibuilder/sensitiveData/buttons/ToggleSensitiveDataButton';
// services
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useSensitiveDataService } from 'shared/uibuilder/sensitiveData';
import { SensitiveDataContextProvider } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';
// styles
import './SensitiveRelationListPageLayout.scss';

const SensitiveRelationListPageLayout = ({
  actions,
  children,
  sensitiveDataIdSource,
  loadSensitiveDataMethod,
  timerId,
  ...other
}) => {
  const [sensitiveDataIds, setSensitiveDataIds] = useState([]);
  const {
    data: { items },
  } = useListContext();

  const {
    isTimerActive,
    isShown,
    toggleSensitiveData,
    getValueByIdAndSource,
    isSensitiveDataLoading,
    loadSensitiveDataByIds,
    sensitiveData,
  } = useSensitiveDataService({
    loadSensitiveDataMethod,
    timerId,
  });

  useEffect(() => {
    if (items.length) {
      const sensitiveIds = items.map(compensation => compensation[sensitiveDataIdSource]);

      setSensitiveDataIds(sensitiveIds);
    }
  }, [items, sensitiveDataIdSource]);

  const hasSensitiveData = useMemo(() => sensitiveData && Object.keys(sensitiveData).length, [sensitiveData]);

  const isShownWithoutSensitiveData = useMemo(() => sensitiveDataIds?.length && !hasSensitiveData && isShown, [
    isShown,
    hasSensitiveData,
    sensitiveDataIds,
  ]);

  useEffect(() => {
    if (isShownWithoutSensitiveData) {
      loadSensitiveDataByIds(sensitiveDataIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownWithoutSensitiveData]);

  const getActions = () => {
    const buttons = [...actions];
    const toggleButton = (
      <ToggleSensitiveDataButton
        key="toggle-sensitive-data"
        isShown={isShown}
        onClick={() => toggleSensitiveData(sensitiveDataIds)}
        disabled={isSensitiveDataLoading}
        isTimerActive={isTimerActive}
      />
    );

    if (items.length) {
      buttons.unshift(toggleButton);
    }

    return buttons;
  };

  return (
    <SensitiveDataContextProvider
      value={{
        sensitiveDataIdSource,
        getValueByIdAndSource,
      }}
    >
      <RelationListLayout actions={getActions()} {...other}>
        {(isShown && !isSensitiveDataLoading && hasSensitiveData) || !items.length ? children : <SensitiveDataPlug />}
      </RelationListLayout>
    </SensitiveDataContextProvider>
  );
};

SensitiveRelationListPageLayout.propTypes = {
  actions: arrayOf(childrenAsNodesType),
  children: childrenAsNodesType,
  sensitiveDataIdSource: string.isRequired,
  loadSensitiveDataMethod: func.isRequired,
  timerId: string,
};

SensitiveRelationListPageLayout.defaultProps = {
  actions: [],
  children: null,
  timerId: undefined,
};

export default SensitiveRelationListPageLayout;
