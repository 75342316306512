/* istanbul ignore file */
import React from 'react';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import {
  useEmployeePerformanceId,
  useEmployeePerformanceUrl,
} from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import UpdateEmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/Update/UpdateBreadcrumbs';
import EmployeePerformanceForm from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/EmployeePerformanceForm';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';

const UpdateEmployeePerformancePage = () => {
  const { update, getById, getValidationSchema } = useEmployeePerformanceService();
  const performanceId = useEmployeePerformanceId();
  const { getSingleEntityUrl } = useEmployeePerformanceUrl();
  const updatedEntityUrl = getSingleEntityUrl(performanceId);

  return (
    <UpdateForm
      getDataFunc={() => getById(performanceId)}
      submitFormFunc={update}
      afterSubmit={{
        redirect: updatedEntityUrl,
        message: 'You are awesome! The Performance Result has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      entityId={performanceId}
    >
      <UpdatePageLayout title="Edit: Performance Result" breadcrumbs={<UpdateEmployeePerformanceBreadcrumbs />}>
        <EmployeePerformanceForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateEmployeePerformancePage;
