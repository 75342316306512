import React, { useEffect, useState } from 'react';
import { useResourceManagementApi } from 'api';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps, Option } from 'shared/uibuilder/form/input/dropdownHelper';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { flushSync } from 'react-dom';

type CompetenciesDropdownProps = {
  mapResults?: (location: any) => any;
  filterOptions?: (location: any) => boolean;
} & DefaultDropdownProps;

const CompetenciesDropdown = ({ ...props }: CompetenciesDropdownProps) => {
  const { sendGetRequest } = useResourceManagementApi();
  const { getRawOnChangeCallback, getValue } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();
  const currentValue = getValue() as any;
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const loadCompetencies = async () => {
      const data = await sendGetRequest('/trainings/competencies');
      const mapResults = await data.json();
      setOptions(mapResults.map((c: { name: string }) => ({ value: c.name, label: c.name })));
    };

    loadCompetencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newOption: { value: string; label: string }) => {
    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [props.source]: [...currentValue, newOption.value],
      });
    }
  };

  const handleCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };

    const newOptions = [...options, newOption];
    setOptions(newOptions);

    flushSync(() => handleChange(newOption));
  };

  return (
    <Dropdown
      {...props}
      placeholder="Select or Create Competencies"
      isCreatable
      multiple
      onCreateOption={handleCreate}
      formatCreateLabel={(inputValue: string) => `Create new competency "${inputValue}"`}
      options={options}
    />
  );
};

export default CompetenciesDropdown;
