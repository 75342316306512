/* istanbul ignore file */
import React from 'react';
import useEmployeeSalaryService from 'erp/employee/salaries/shared/salaryService';
import { useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import List from 'shared/uibuilder/list/List';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import { SensitiveRelationListPageLayout } from 'shared/uibuilder/sensitiveData';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_EMPLOYEE_SALARY } from 'erp/employee/employeeService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ViewSalaryButton from 'erp/employee/salaries/shared/buttons/ViewSalaryButton';
import EditSalaryButton from 'erp/employee/salaries/shared/buttons/EditSalaryButton';
import TextField from 'shared/uibuilder/field/TextField';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeSalariesListBreadcrumbs from 'erp/employee/salaries/List/ListBreadcrumbs';
import { BooleanField, CapitalizedField } from 'shared/uibuilder/field';
import DateField from '../../../../shared/uibuilder/field/DateField';

const EmployeeSalariesList = () => {
  const { search, getSensitiveDataByIds } = useEmployeeSalaryService();
  const { getCreateUrl, getListUrl } = useEmployeeSalaryUrl();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<EmployeeSalariesListBreadcrumbs />}
    >
      <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="relatedProcess.periodEndDate">
        <SensitiveRelationListPageLayout
          header="Salaries"
          sensitiveDataIdSource="sensitiveDataId"
          loadSensitiveDataMethod={getSensitiveDataByIds}
          actions={[
            <CreateButton
              url={getCreateUrl()}
              label="Add Salary"
              key="add-salary"
              variant="contained"
              permissionToCheck={CREATE_EMPLOYEE_SALARY}
            />,
          ]}
          timerId={getListUrl()}
        >
          <DataGridLayout
            showTotal={false}
            isSmallTable
            buttons={[
              <EditSalaryButton source="id" key="edit-salary" outline permissionToCheck={CREATE_EMPLOYEE_SALARY} />,
              <ViewSalaryButton source="id" key="view-salary" />,
            ]}
          >
            <TextField source="id" label="ID" />
            <CapitalizedField source="type" label="Salary Type" />
            <CapitalizedField source="baseAmountType" label="Base Amount Type" />
            <TextField source="grossAmount" label="Base Amount Gross" />
            <TextField source="currency" label="Currency" />
            <BooleanField source="modelBased" label="Model Based" />
            <DateField source="relatedProcess.periodStartDate" label="Review Period From" isSortable />
            <DateField source="relatedProcess.periodEndDate" label="Review Period To" isSortable />
          </DataGridLayout>
        </SensitiveRelationListPageLayout>
      </List>
    </ShowPageLayout>
  );
};

export default EmployeeSalariesList;
