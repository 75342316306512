/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  READ_ASSET,
  ASSET_PATH,
  READ_ASSET_LIST,
  CREATE_ASSET,
  UPDATE_ASSET,
  READ_TIMELINE,
} from 'financialAnalytic/assets/useAssetService';
import AssetList from 'financialAnalytic/assets/List';
import AssetViewPage from 'financialAnalytic/assets/Show';
import AssetCreatePage from 'financialAnalytic/assets/createupdate/Create';
import AssetUpdatePage from 'financialAnalytic/assets/createupdate/Update';
import ShowAsset from 'financialAnalytic/assets/shared/ShowAsset';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import AssetTimelinePage from 'financialAnalytic/assets/Timeline';

export const useAssetRoute = () => {
  const routers = useRoute({ listRoute: ASSET_PATH });

  return { ...routers, copyEntityRoute: `${routers.singleEntityRoute}/copy` };
};

export const useAssetUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: ASSET_PATH,
  });

  return { ...baseUrls, getCopyEntityUrl: (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/copy` };
};

export const useAssetId = () => useParams<Dictionary<string>>().id;

const AssetRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, copyEntityRoute, updateRoute, timelineRoute } = useAssetRoute();

  return (
    <ProtectedRouter basePath={ASSET_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_ASSET_LIST}>
          <AssetList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_ASSET}>
          <AssetCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={copyEntityRoute} exact globalPermissionToCheck={CREATE_ASSET}>
          <AssetCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={UPDATE_ASSET}>
          <ShowAsset>
            <AssetUpdatePage />
          </ShowAsset>
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={timelineRoute} exact globalPermissionToCheck={READ_TIMELINE}>
          <AssetTimelinePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_ASSET}>
          <AssetViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

AssetRouter.getRouterPath = () => ASSET_PATH;

export default AssetRouter;
