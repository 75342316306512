/* istanbul ignore file */
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import { shape, string } from 'prop-types';

const BalancesLeavesField = ({ value, source }) => {
  return <TextField value={value[source]} />;
};

BalancesLeavesField.propTypes = {
  value: shape(),
  source: string,
};

BalancesLeavesField.defaultProps = {
  value: {},
  source: undefined,
};

export default BalancesLeavesField;
