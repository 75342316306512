/* istanbul ignore file */
import contactValidation from 'crm/contact/createupdate/contactValidation';
import useTimelineService from 'shared/crud/timelineService';
import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';
import contactFilterValidation from 'crm/contact/list/contactFilterValidation';

export const RESOURCE_URL = '/crm/contacts';

export const CREATE_CONTACT = 'CREATE_ACCOUNT';
export const UPDATE_CONTACT_FROM_LIST_PAGE = 'CREATE_ACCOUNT';
export const READ_CONTACTS_LIST = 'READ_CONTACTS_LIST';
export const READ_FULL_CONTACTS_LIST = 'READ_FULL_CONTACTS_LIST';

/**
 * Contact service.
 *
 * @returns {{getById: (function(*=): *), deleteById: ((function(*=): *)|Promise<any>), create: (function(*=): *), update: (function(*=, *=): *)}}
 */

const useContactService = () => {
  const mapContact = contact => {
    const { emails = [] } = contact;
    return {
      ...contact,
      emails: emails.filter(e => e),
    };
  };

  const baseCrudRequests = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const getValidationSchema = () => Promise.resolve(contactValidation);

  const { create, update } = baseCrudRequests;

  const { search: searchTimelineItems, create: createTimelineItem, getTimelinePageById } = useTimelineService({
    parentEntityUrl: `${RESOURCE_URL}/:id`,
    apiService: useKernelApi,
  });

  const getFilterValidationSchema = () => Promise.resolve(contactFilterValidation);

  return {
    getValidationSchema,
    ...baseCrudRequests,
    create: async contact => {
      return create(mapContact(contact));
    },
    update: async (id, contact) => {
      return update(id, mapContact(contact));
    },
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    getFilterValidationSchema,
  };
};

export default useContactService;
