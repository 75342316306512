/* istanbul ignore file */
const generatedPayrollsFilterValidation = {
  period: {
    type: 'object',
    required: true,
    properties: {
      ge: {
        type: 'date',
        required: true,
        maxDate: {
          value: '$period.le',
          message: 'Please choose date before or at "to" date',
        },
      },
      le: {
        type: 'date',
        required: true,
        minDate: {
          value: '$period.ge',
          message: 'Please choose date after or at "from" date',
        },
      },
    },
  },
};

export default generatedPayrollsFilterValidation;
