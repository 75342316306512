/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';

const VacancyPublicationBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useVacancyPublicationUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Vacancy Publication" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default VacancyPublicationBreadcrumbs;
