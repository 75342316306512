/* istanbul ignore file */
import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface ButtonProps extends Omit<MuiButtonProps<any>, 'color'> {
  component?: React.ReactNode | ReactComponent<any>;
  outline?: boolean;
  link?: boolean;
  color?: BasicColors;
  sx?: SxProps<Theme>;
}

const Button = ({
  link = false,
  outline,
  variant,
  color = 'primary',
  sx = {},
  disableRipple,
  ...props
}: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      disableRipple={link || disableRipple}
      variant={link || outline ? 'outlined' : variant}
      color={color as any}
      sx={
        link
          ? {
              ...sx,
              padding: '0 !important',
              background: 'transparent !important',
              border: '0 !important',
              '&:hover': { textDecoration: 'underline' },
            }
          : sx
      }
    />
  );
};

export default Button;
