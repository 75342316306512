/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import AssetBreadcrumbs from 'financialAnalytic/assets/shared/AssetBreadcrumbs';

const CreateAssetBreadcrumbs = () => {
  return (
    <AssetBreadcrumbs>
      <CreateBreadcrumbItem entity="Asset" />
    </AssetBreadcrumbs>
  );
};

export default CreateAssetBreadcrumbs;
