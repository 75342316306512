import { useBusinessEngineApi } from 'api';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import UPDATE_PROCESS_VARIABLES_VALIDATION from 'camunda/monitoring/instance/Show/variables/shared/ProcessVariableValidation';
import { BOOLEAN_VALUE } from 'camunda/monitoring/instance/Show/variables/shared/input/VariableValueInput';

export const UPDATE_PROCESS_VARIABLES_LIST = 'UPDATE_VARIABLES_LIST';

export enum VARIABLE_TYPES {
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  LONG = 'LONG',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  STRING = 'STRING',
  OBJECT = 'OBJECT',
}
export const VARIABLE_TYPES_LABELS = {
  [VARIABLE_TYPES.BOOLEAN]: 'Boolean',
  [VARIABLE_TYPES.INTEGER]: 'Integer',
  [VARIABLE_TYPES.LONG]: 'Long',
  [VARIABLE_TYPES.DOUBLE]: 'Double',
  [VARIABLE_TYPES.DATE]: 'Date',
  [VARIABLE_TYPES.STRING]: 'String',
  [VARIABLE_TYPES.OBJECT]: 'Object',
};
export enum VARIABLE_OBJECT_TYPES {
  CAMUNDA_DATE = 'CAMUNDA_DATE',
  CAMUNDA_DATETIME = 'CAMUNDA_DATETIME',
  CANDIDATE = 'CANDIDATE',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEE_CONTRACT = 'EMPLOYEE_CONTRACT',
  LEAVE = 'LEAVE',
  OTHER = 'OTHER',
  NULL = 'NULL',
  NULL_ARTIFACT = 'NULL_ARTIFACT',
}
export const VARIABLE_OBJECT_TYPES_LABELS = {
  [VARIABLE_OBJECT_TYPES.CAMUNDA_DATE]: 'Camunda Date',
  [VARIABLE_OBJECT_TYPES.CAMUNDA_DATETIME]: 'Camunda Date Time',
  [VARIABLE_OBJECT_TYPES.CANDIDATE]: 'Candidate',
  [VARIABLE_OBJECT_TYPES.EMPLOYEE]: 'Employee',
  [VARIABLE_OBJECT_TYPES.EMPLOYEE_CONTRACT]: 'Employee Contract',
  [VARIABLE_OBJECT_TYPES.LEAVE]: 'Leave',
  [VARIABLE_OBJECT_TYPES.NULL_ARTIFACT]: 'Null Artifact',
  [VARIABLE_OBJECT_TYPES.NULL]: 'Null',
  [VARIABLE_OBJECT_TYPES.OTHER]: 'Other',
};

const useProcessVariableService = () => {
  const processInstanceId = useProcessInstanceId();
  const { sendPostRequest } = useBusinessEngineApi();

  const create = async (request: any) => {
    const mappedRequest = { ...request };
    if (request.type === VARIABLE_TYPES.OBJECT) {
      mappedRequest.value =
        request.objectTypeName === VARIABLE_OBJECT_TYPES.OTHER ? JSON.parse(request.value) : request.value;
    } else {
      mappedRequest.objectTypeName = null;
      mappedRequest.value =
        request.type === VARIABLE_TYPES.BOOLEAN ? request.value === BOOLEAN_VALUE.TRUE : request.value;
    }
    const response = await sendPostRequest(`/camunda/processes/${processInstanceId}/variables/create`, mappedRequest);
    return response;
  };

  const getValidationSchema = () => Promise.resolve(UPDATE_PROCESS_VARIABLES_VALIDATION);

  return {
    create,
    getValidationSchema,
  };
};

export default useProcessVariableService;
