/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import FieldBadgeProps from 'shared/uibuilder/badge/FieldBadgeProps';

const StudentBadge = (props: FieldBadgeProps) => {
  return (
    <Badge className="ml-2" color="success">
      Student
    </Badge>
  );
};

export default StudentBadge;
