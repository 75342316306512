/* istanbul ignore file */
export default {
  employeeAlias: {
    type: 'string',
    required: true,
  },
  dateFrom: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$NOW',
      message: 'Cannot be in future',
    },
  },
  dateTo: {
    type: 'date',
    required: true,
    minDate: {
      value: '$dateFrom',
      message: 'Please choose date after or at start date',
    },
    maxDate: {
      value: '$NOW',
      message: 'Cannot be in future',
    },
  },
};
