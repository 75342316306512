import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useRoomUrl } from 'erp/room/RoomRouter';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import React from 'react';
import RoomBreadcrumbs from 'erp/room/shared/RoomBreadcrumbs';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';

const SingleRoomBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useRoomUrl();
  const id = data?.getValueBySource('id');

  const label = OFFICE_NAMES[data?.getValueBySource('office.name')];
  const name = `${data?.getValueBySource('roomNumber')} (${label})`;

  return (
    <RoomBreadcrumbs>
      <SingleBreadcrumbItem entity={name} link={getSingleEntityUrl(id)} />
      {children}
    </RoomBreadcrumbs>
  );
};

export default SingleRoomBreadcrumbs;
