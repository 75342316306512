import React from 'react';
import MessageEventButton from 'camunda/shared/MessageEventButton';
import CandidateSearchLinkField from 'erp/candidate/shared/field/CandidateSearchLinkField';
import StudentBadge from 'erp/candidate/shared/StudentBadge';
import EmployeeSearchLinkField from 'erp/employee/shared/field/EmployeeSearchLinkField';
import useInterviewService, {
  CANCEL_INTERVIEW,
  INTERVIEW_PATH,
  RESCHEDULE_INTERVIEW,
} from 'erp/interview/shared/interviewService';
import { BadgeField, EnumField, FullDateField } from 'shared/uibuilder/field';
import CamundaInput from 'shared/uibuilder/form/input/CamundaInput';
import ZoneDateTimeInput from 'shared/uibuilder/form/input/ZoneDateTimeInput';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import List from 'shared/uibuilder/list/List';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';

const CamundaTextArea = CamundaInput(TextArea);
const CamundaNotDismissedEmployeeInput = CamundaInput(NotDismissedEmployeeInput);
const CamundaDateTimeInput = CamundaInput(ZoneDateTimeInput);

const INTERVIEW_OPTIONS = {
  roleRelated: 'Role-related',
  general: 'General',
};

const InterviewsList = () => {
  const {
    search,
    canUpdateInterview,
    getCancelInterviewValidation,
    getRescheduleInterviewValidation,
  } = useInterviewService();
  const { getCurrentDateTime, formatDateForAPI } = useDateService();
  const { getCurrentTimezoneDateInUtc } = useDateTimeService();
  const rescheduleMinDate = getCurrentTimezoneDateInUtc(getCurrentDateTime().subtract(1, 'day'));
  const getRescheduleInterviewValidationFunc = async () => {
    const schema = await getRescheduleInterviewValidation();
    schema.interviewDateTime.minDate = formatDateForAPI(rescheduleMinDate);
    return schema;
  };
  return (
    <List getDataMethod={search}>
      <ListPageLayout header={<ListHeader label="Interviews" />} search={<SearchInput label="Candidate name" />}>
        <DataGridLayout
          tableWrapperClassName="interview-table-wrapper"
          rowKeySource="processInstanceId"
          buttons={[
            <MessageEventButton
              getValidationSchemaFunc={getRescheduleInterviewValidationFunc}
              key="reschedule-interview"
              label="Reschedule"
              afterSubmit={{
                message: 'You are awesome! The Interview has been successfully rescheduled.',
                redirect: INTERVIEW_PATH,
              }}
              messageEventName={RESCHEDULE_INTERVIEW}
              formFields={[
                <CamundaDateTimeInput
                  key="interviewDateTime"
                  source="interviewDateTime"
                  label="Interview Date Time"
                  isRequired
                  minDate={rescheduleMinDate}
                  camundaType="camunda_date_time"
                  helpText="New date time of interview"
                />,
                <CamundaNotDismissedEmployeeInput
                  key="interviewer"
                  source="interviewerId"
                  label="Interviewer"
                  camundaType="string"
                />,
              ]}
              isVisible={canUpdateInterview}
            />,
            <MessageEventButton
              key="cancel-interview"
              getValidationSchemaFunc={getCancelInterviewValidation}
              label="Cancel"
              afterSubmit={{
                message: 'You are awesome! The Interview has been successfully canceled.',
                redirect: INTERVIEW_PATH,
              }}
              messageEventName={CANCEL_INTERVIEW}
              initialData={{
                interviewCancelledAt: async () => ({
                  type: 'camunda_date_time',
                  value: async () => formatDateForAPI(getCurrentTimezoneDateInUtc(getCurrentDateTime())),
                }),
              }}
              formFields={[
                <CamundaTextArea
                  key="reason"
                  source="reason"
                  camundaType="string"
                  label="Reason"
                  helpText="Interview cancel reason"
                  isRequired
                />,
              ]}
              isVisible={canUpdateInterview}
            />,
          ]}
        >
          <BadgeField
            badges={[<StudentBadge isVisible={({ isStudent }: any) => isStudent} />]}
            width={300}
            label="Candidate"
            source="candidate.name"
          >
            <CandidateSearchLinkField idSource="candidate.id" source="candidate.name" />
          </BadgeField>
          <EmployeeSearchLinkField label="Interviewer" idSource="interviewer.alias" source="interviewer.nameEn" />
          <FullDateField source="interviewDateTime" label="Interview Date Time" />
          <EnumField options={INTERVIEW_OPTIONS} source="interviewType" label="Interview Type" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default InterviewsList;
