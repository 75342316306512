/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField, { LinkFieldProps } from 'shared/uibuilder/field/LinkField';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';

type ExpenseLinkProps = LinkFieldProps & {
  nameSource: string;
};

const LeaveLink = ({ source, nameSource, ...props }: ExpenseLinkProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useLeaveUrl();
  const id = getValue();
  const name = getName();

  return <LinkField linkText={name || id} isInternal value={getSingleEntityUrl(id)} {...props} />;
};

export default LeaveLink;
