import React from 'react';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';
import BreadcrumbsWithMenu from 'shared/layout/BreadcrumbsWithMenu';

interface FormPageLayoutWithMenuProps {
  menu: React.ReactElement;
  title: string;
  entityHeader?: React.ReactElement;
  breadcrumbs?: React.ReactElement;
  buttons: React.ReactElement;
  actions?: React.ReactElement | React.ReactElement[];
  children: React.ReactElement | React.ReactElement[];
}

const FormPageLayoutWithMenu = ({
  children,
  title,
  breadcrumbs,
  entityHeader,
  buttons,
  menu,
  actions,
}: FormPageLayoutWithMenuProps) => {
  return (
    <>
      <Paper className="page-content view-single-entry__wrapper">
        <BreadcrumbsWithMenu breadcrumbs={breadcrumbs} menu={menu} />
        <div className="show-page__header-wrapper">
          <Box
            className="show-page__entity-header-wrapper"
            sx={{
              marginBottom: entityHeader ? '36px' : 0,
            }}
          >
            {entityHeader}
          </Box>
        </div>

        <div className="show-page__content-wrapper">
          <div className="show-page__menu-wrapper show-page__menu-wrapper--desktop">{menu}</div>

          <div className="show-page__content-inner">
            <Message />
            <Box
              className="show-page__header-wrapper"
              sx={{
                marginBottom: title || actions ? '25px' : 0,
              }}
            >
              <Typography variant="h2" color="primary.title" sx={{ marginRight: '20px' }}>
                {title}
              </Typography>
              {actions && <div className="top-btns">{actions}</div>}
            </Box>

            {children}
            {buttons}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default FormPageLayoutWithMenu;
