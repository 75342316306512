/* istanbul ignore file */
// TODO: Add tests after integration with BE
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SyberryTodayProvider } from 'syberryToday/SyberryTodayContext';
import { useLocation } from 'react-router-dom';
import useStorageService from 'shared/uibuilder/storageService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const SyberryToday = ({
  storageId,
  children,
  projects = [],
  isCheckinPassed = false,
  setIsCheckinPassed = () => {},
}: {
  storageId?: string;
  children: React.ReactNode;
  projects?: any[];
  isCheckinPassed?: boolean;
  setIsCheckinPassed?: (state: boolean) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIssues, setSelectedIssues] = useState<number[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [plannedAllocations, setPlannedAllocations] = useState<Dictionary<number>>({});
  const { pathname } = useLocation();

  const getCacheId = () => `${pathname}-daily-checkin${storageId ? `-${storageId}` : ''}`;

  const { getDataFromStorage, setDataToStorage } = useStorageService(
    getCacheId(),
    () => ({
      activeStep: 0,
      selectedIssues: [],
      plannedAllocations: {},
      lastUpdatedAt: moment().format(DATE_FORMAT.API_ONLY_DATE),
    }),
    '1.0',
  );

  useEffect(() => {
    (async () => {
      const {
        activeStep: storageActiveStep,
        selectedIssues: storageSelectedIssues,
        plannedAllocations: storagePlannedAllocations,
        lastUpdatedAt,
      } = await getDataFromStorage();

      if (lastUpdatedAt === moment().format(DATE_FORMAT.API_ONLY_DATE)) {
        setActiveStep(storageActiveStep);
        setSelectedIssues(storageSelectedIssues);
        setPlannedAllocations(storagePlannedAllocations);
      } else {
        setActiveStep(0);
        setSelectedIssues([]);
        setPlannedAllocations({});
      }
      setIsDataLoaded(true);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      setDataToStorage({
        activeStep,
        selectedIssues,
        plannedAllocations,
        lastUpdatedAt: moment().format(DATE_FORMAT.API_ONLY_DATE),
      });
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating activeSTep and selectedIssues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, selectedIssues, plannedAllocations]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const selectIssue = (id: number) => {
    setSelectedIssues(prevSelectedIssues => [...prevSelectedIssues, id]);
  };

  const unSelectIssue = (id: number) => {
    setSelectedIssues(prevSelectedIssues => prevSelectedIssues.filter(i => i !== id));
  };

  const updatePlannedAllocation = (id: number, value: number) => {
    setPlannedAllocations(prevAllocations => ({ ...prevAllocations, [id]: value }));
  };

  const contextData = {
    selectIssue,
    unSelectIssue,
    selectedIssues,
    handleNext,
    activeStep,
    handleBack,
    isDataLoaded,
    updatePlannedAllocation,
    plannedAllocations,
    projects,
    isCheckinPassed,
    setIsCheckinPassed,
    loading,
    setLoading,
  };

  return <SyberryTodayProvider value={contextData}>{children}</SyberryTodayProvider>;
};

export default SyberryToday;
