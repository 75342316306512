/* istanbul ignore file */
import React from 'react';
import SingleLeadBreadcrumbs from 'crm/lead/shared/SingleLeadBreadcrumbs';
import { ActivitiesAndNotesBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useLeadId, useLeadUrl } from 'crm/lead/LeadRouter';

const LeadTimelineBreadcrumbs = () => {
  const { getTimelineUrl } = useLeadUrl();
  const id = useLeadId();

  return (
    <SingleLeadBreadcrumbs>
      <ActivitiesAndNotesBreadcrumbItem link={getTimelineUrl(id)} />
    </SingleLeadBreadcrumbs>
  );
};

export default LeadTimelineBreadcrumbs;
