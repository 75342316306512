/* istanbul ignore file */
import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import { SearchInputProps } from 'shared/uibuilder/form/input/SearchInput';
import useAssignmentTargetService from 'erp/assignment/target/shared/assignmentTargetService';

export interface AssignmentTargetInputProps extends Omit<SearchInputProps, 'searchRequest'> {
  source: string;
  label?: string;
  initialLabel?: string;
  searchString?: string;
  nameSource?: string;
  mapResults?: (service: any) => void;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;
  props?: any;
}

const AssignmentTargetInput = ({ mapResults, source, ...props }: AssignmentTargetInputProps) => {
  const { search } = useAssignmentTargetService();
  const { data, onChangeCallback } = useFormContext();

  const mapResponse = (target: any) => ({
    id: target.id,
    text: target.displayName,
  });

  const onTargetChange = (value: any) => {
    const budgetIdToSet = value?.target?.budgetInfo?.defaultBudget?.id;

    if (onChangeCallback) {
      onChangeCallback({
        ...data,
        budgetId: budgetIdToSet,
        target: value?.target,
      });
    }
  };

  return (
    <SearchInput
      {...props}
      onChangeCallback={onTargetChange}
      source={source}
      searchRequest={search}
      noResultMessage="No Targets are found."
      mapResults={mapResults || mapResponse}
    />
  );
};

export default AssignmentTargetInput;
