/* istanbul ignore file */
export default {
  title: {
    type: 'string',
    maxSize: 255,
  },
  firstName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  lastName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  middleName: {
    type: 'string',
    required: false,
    maxSize: 255,
  },
  accountId: {
    type: 'string',
    required: {
      value: true,
      message: 'Please choose an account from the list',
    },
  },
  phoneNumber: {
    type: 'string',
    phone: {
      value: true,
      message: 'Please enter phone number in format xxxxxxxxxxxx, max. 15 characters',
    },
  },
  linkedInLink: {
    type: 'string',
    required: false,
    maxSize: 128,
  },
  mobilePhoneNumber: {
    type: 'string',
    phone: {
      value: true,
      message: 'Please enter phone number in format xxxxxxxxxxxx, max. 15 characters',
    },
  },
  emails: {
    type: 'array',
    uniqueItems: {
      value: true,
      message: 'Please enter unique email',
    },
    maxSize: 3,
    forEach: {
      email: true,
      maxSize: 255,
    },
  },

  address: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      country: {
        type: 'string',
      },
      addressLine1: {
        type: 'string',
        maxSize: 255,
        required: {
          value: true,
          when: {
            $status: {
              eq: 'QUALIFIED',
            },
          },
        },
      },
      city: {
        type: 'string',
        maxSize: 64,

        required: {
          value: true,
          when: {
            $status: {
              eq: 'QUALIFIED',
            },
          },
        },
      },
      state: {
        type: 'string',
      },
      zipCode: {
        type: 'string',
        maxSize: 64,

        required: {
          value: true,
          when: {
            $status: {
              eq: 'QUALIFIED',
            },
          },
        },
      },
    },
  },

  description: {
    type: 'text',
    maxSize: 60000,
  },
  attachmentsIds: {
    type: 'array',
    maxSize: 15,
  },
};
