import React from 'react';
import EntityHeader from 'shared/layout/EntityHeader';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';

const RoomHeader = () => {
  const { data } = useShowContext();
  const label = OFFICE_NAMES[data.getValueBySource('office.name')];
  const name = `${data?.getValueBySource('roomNumber')} (${label})`;

  return <EntityHeader entityTitle={name} />;
};

export default RoomHeader;
