/* istanbul ignore file */
import useApi from 'shared/api';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { HTTP } from 'shared/api/const';
import useApiAuthenticationProvider from 'api/apiAuthenticationProvider';
import useUnauthenticatedRequestHandler from 'api/unauthenticatedRequestHandler';
import { useAuthenticationProvider } from 'authentication/AuthenticationProvider';

export const BASE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_API_URL;

export const KERNEL_URL = process.env.REACT_APP_DAVINCI_FRONTEND_KERNEL_URL;

export const PM_URL = process.env.REACT_APP_DAVINCI_FRONTEND_PM_URL;

export const FINANCE_SERVICE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_FINANCE_SERVICE_URL;

export const PRIVILEGED_ACCESS_SERVICE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_PRIVILEGED_ACCESS_SERVICE_URL;

export const SCS_SERVICE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_SCS_URL;

export const ST_SERVICE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_ST_URL;

export const RM_SERVICE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_RM_URL;

export const PPLM_SERVICE_URL = process.env.REACT_APP_DAVINCI_FRONTEND_PPLM_URL;

export const useHandleError = () => {
  const { addMessage } = useMessageService();

  const handleError = error => {
    switch (error.status) {
      case HTTP.SERVICE_UNAVAILABLE:
        addMessage(new ErrorMessage('Service is temporarily unavailable. Please refresh the page and try again later'));
        break;
      default:
        throw error;
    }
  };

  return { handleError };
};

export const useBusinessEngineApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(BASE_URL, services);
};

export const useKernelApi = (pathPrefix = '') => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(`${KERNEL_URL}${pathPrefix}`, services);
};

export const usePMApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(PM_URL, services);
};

export const useFinanceApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(FINANCE_SERVICE_URL, services);
};

export const useResourceManagementApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(RM_SERVICE_URL, services);
};

export const usePeopleManagementApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(PPLM_SERVICE_URL, services);
};

export const useAuthenticationApi = baseUrl => {
  const { getToken } = useAuthenticationProvider();
  const token = getToken();
  const errorHandler = useHandleError();

  const services = {
    errorHandler,
    authProvider: {
      getAuthHeader: () => ({
        name: 'Authorization',
        value: `Bearer ${token}`,
      }),
    },
    unauthenticatedRequestHandler: null,
  };

  return useApi(baseUrl || BASE_URL, services);
};

export const usePrivilegedAccessApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(PRIVILEGED_ACCESS_SERVICE_URL, services);
};

export const useScsApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(SCS_SERVICE_URL, services);
};

export const useSyberryTodayApi = () => {
  const { isAuthenticated } = useAuthenticationProvider();
  const errorHandler = useHandleError();
  const authenticationProvider = useApiAuthenticationProvider();
  const authProvider = isAuthenticated ? authenticationProvider : null;
  const unauthenticatedRequestHandler = useUnauthenticatedRequestHandler();

  const services = {
    errorHandler,
    authProvider,
    unauthenticatedRequestHandler,
  };

  return useApi(ST_SERVICE_URL, services);
};

export const useArtifactStorageApi = () => {
  return useKernelApi('/new');
};
