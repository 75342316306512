/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useCustomerInvoiceUrl } from '../CustomerInvoicesRouter';
import CustomerInvoiceBreadcrumbs from './CustomerInvoiceBreadcrumbs';

const SingleCustomerInvoiceBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useCustomerInvoiceUrl();
  const id = data?.getValueBySource('id');
  const invoiceNumber = data?.getValueBySource('invoiceNumber');

  return (
    <CustomerInvoiceBreadcrumbs>
      <SingleBreadcrumbItem entity={invoiceNumber || id} link={getSingleEntityUrl(id)} />
      {children}
    </CustomerInvoiceBreadcrumbs>
  );
};

export default SingleCustomerInvoiceBreadcrumbs;
