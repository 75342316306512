import React, { FC, MutableRefObject } from 'react';
import Alert from 'uibuilder/Alert';
import Button from 'uibuilder/button/Button';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import ArrowUpIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined';
import classnames from 'classnames';
import useHeight from 'shared/useHeight';
import Loading from 'shared/uibuilder/Loading/index';
import useCollapsibleHelper, { CollapsibleProps, DEFAULT_MAX_HEIGHT } from './collapsibleHelper';
import './Collapsible.scss';

const Collapsible: FC<CollapsibleProps> = (props: CollapsibleProps) => {
  const { children, maxHeight = DEFAULT_MAX_HEIGHT } = props;
  const { ref, height } = useHeight();
  const {
    isCollapsible,
    isExpandableField,
    isExpanded,
    displayedValue,
    isLoading,
    toggleBlock,
    retryUploadData,
    error,
  } = useCollapsibleHelper({
    ...props,
    height,
  });
  const isBlockExpanded = isExpanded || !isCollapsible;

  const renderText = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Alert className="mb-0" severity="error">
          <div>{error}</div>
          <Button variant="text" onClick={retryUploadData} startIcon={<RefreshIcon />}>
            Try again
          </Button>
        </Alert>
      );
    }

    return (
      <div ref={ref as MutableRefObject<Nullable<HTMLDivElement>>}>
        {React.cloneElement(children, {
          value: displayedValue,
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={classnames('collapsible', { 'show-blur': !isBlockExpanded })}
        style={{
          maxHeight: isBlockExpanded || isExpandableField ? height : maxHeight,
          height: isLoading ? maxHeight : 'inherit',
        }}
      >
        {renderText()}
      </div>
      {isCollapsible && (
        <Button
          type="button"
          color="neutralLight"
          className={classnames('mb-3 d-flex w-100', {
            'mt-3': isBlockExpanded && !isLoading,
            'mt-2': !isBlockExpanded || isLoading,
          })}
          size="small"
          onClick={toggleBlock}
          disabled={isLoading}
          endIcon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </>
  );
};

export default Collapsible;
