/* istanbul ignore file */
import React from 'react';

const FormHelpText = (props: { children: React.ReactNode; isVisible: boolean }) => {
  const { isVisible } = props;
  const { children } = props;

  return isVisible ? <span className="d-inline-block mt-2 mb-4">{children}</span> : null;
};

export default FormHelpText;
