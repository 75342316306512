/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const VacancySpecializationDropdown = (props: any) => {
  const [options, setOptions] = useState([]);
  const { getPositionOptions } = useVacancyService();

  useEffect(() => {
    (async () => {
      const positions = await getPositionOptions();

      setOptions(positions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EnumDropdown options={options} label="Specialization" placeholder="Select Specialization" {...props} />;
};

export default VacancySpecializationDropdown;
