import React from 'react';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import { HIRING_PATH, HIRING_REQUEST_READ, HIRING_REQUEST_UPDATE } from './useHiringService';
import ProtectedRouter from 'routing/ProtectedRouter';
import { Switch, useParams } from 'react-router-dom';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import HiringListPage from './List';
import ViewHiringRequest from './show/ViewHiringRequest';
import UpdateHiringPage from './createupdate/update/UpdateHiringPage';

export const useHiringRoute = () => {
  const routers = useRoute({ listRoute: HIRING_PATH });

  return {
    ...routers,
  };
};

export const useHiringUrl = () => {
  const { ...restUrls } = useEntityUrl({
    baseLink: HIRING_PATH,
  });

  return {
    ...restUrls,
  };
};

export const useHiringId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id;
};

const HiringRouter = () => {
  const { listRoute, singleEntityRoute, updateRoute } = useHiringRoute();

  return (
    <ProtectedRouter basePath={HIRING_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={HIRING_REQUEST_READ}>
          <HiringListPage />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={HIRING_REQUEST_UPDATE}>
          <UpdateHiringPage />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={HIRING_REQUEST_READ}>
          <ViewHiringRequest />
        </GlobalProtectedRoute>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

HiringRouter.getRouterPath = () => HIRING_PATH;

export default HiringRouter;
