/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import { useExternalAccountsService } from './externalAccountsService';

export const ACCOUNT_CREATION_CONTEXT = 'AccountCreation';

const AccountCreationContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useExternalAccountsService();

  return (
    <SecurityContextTemplate
      // @ts-ignore
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={ACCOUNT_CREATION_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default AccountCreationContext;
