import { useRef, useEffect } from 'react';

/**
 * This hook is used to imitate componentDidUpdate logic
 * unlike gegular useEffect this one won't run on component initialization
 * @param effect
 * @param dependencies
 */
export default (effect: () => any, dependencies: any[]) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
