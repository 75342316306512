/* istanbul ignore file */
import React from 'react';
import useContextHelper from 'shared/uibuilder/contextHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import List from 'shared/uibuilder/list/List';
import 'erp/project/statuses/shared/field/TicketsListField.scss';

const TicketsListField = ({ label, source, isDemoTickets = false, className, ...props }: FieldProps<string[]>) => {
  const { getFromAnyContext } = useContextHelper();
  const tickets = getFromAnyContext(source);
  const getWorkItemsData = async () => {
    return Promise.resolve({ result: tickets });
  };

  const listOfTickets = isDemoTickets ? (
    <>
      <div className={className}>
        <TextField source="" value=" " label={label} />
      </div>
      <List getDataMethod={getWorkItemsData}>
        <DataGridLayout isSmallTable tableWrapperClassName="formatted-text-list">
          <TextField source="ticketName" label="Work item (name or link)" width={200} />
          <TextField source="assigneeName" label="Assignee" width={150} />
          <TextField source="customerFeedback" label="Customer feedback" width={450} />
          <TextField source="comments" label="Comments" />
        </DataGridLayout>
      </List>
    </>
  ) : (
    <>
      <TextField source="" value=" " label={label} />
      <List getDataMethod={getWorkItemsData}>
        <DataGridLayout isSmallTable tableWrapperClassName="formatted-text-list">
          <TextField source="ticketName" label="Work item (name or link)" width={400} />
          <TextField source="assigneeName" label="Assignee" />
        </DataGridLayout>
      </List>
    </>
  );

  return tickets.length > 0 ? listOfTickets : <></>;
};

export default TicketsListField;
