/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import useProcessIssueService from 'camunda/monitoring/instance/Show/issues/shared/ProcessIssuesService';
import CloseProcessIssueForm from 'camunda/monitoring/instance/Show/issues/CloseProcessIssueForm';

const MESSAGE_CONTEXT = 'closeIssueForm';

interface CloseProcessIssueModalFormProps {
  issueId: string;
  updateIssue: () => void;
  closeModal: () => void;
}

const CloseProcessIssueModalForm = ({ issueId, updateIssue, closeModal }: CloseProcessIssueModalFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { closeTicket, getValidationSchema } = useProcessIssueService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Close ticket: {issueId}
    </Typography>
  );

  return (
    <CreateForm
      submitFormFunc={closeTicket}
      messageContext={MESSAGE_CONTEXT}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        issueIds: [issueId],
        comment: '',
      }}
      afterSubmit={{
        execute: useCallback(() => {
          updateIssue();
          closeForm();
        }, [closeForm, updateIssue]),
      }}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <CloseProcessIssueForm handleCancelClick={closeForm} />
      </ModalWindow>
    </CreateForm>
  );
};

export default CloseProcessIssueModalForm;
