/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { INDUSTRIES } from 'crm/crmService';

const IndustryDropdown = props => {
  return (
    <Dropdown
      placeholder="Choose Industry"
      options={Object.entries(INDUSTRIES).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      {...props}
    />
  );
};

export default IndustryDropdown;
