import React from 'react';
import List from 'shared/uibuilder/list/List';
import useRecruitingDashboardService from '../useRecruitingDashboardService';
import RecruitingDashboardContent from './RecruitingDashboardContent';

const RecruitingDashboardListPage = () => {
  const { search } = useRecruitingDashboardService();

  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="createdAt">
      <RecruitingDashboardContent />
    </List>
  );
};

export default RecruitingDashboardListPage;
