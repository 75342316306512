import React from 'react';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import UploadExpenseBreadcrumbs from './UploadExpenseBreadcrumbs';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import UploadInputWithoutArtifactID from 'artifact/shared/input/UploadInputWithoutArtifactID';
import UploadExpensesValidation from './UploadExpensesValidation';
import useUploadExpenses from './useUploadExpenses';
import { snakeCase } from 'lodash';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';
import FormListTemplate from 'shared/uibuilder/form/formList/FormListTemplate';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import ExpenseForm from '../createupdate/ExpenseForm';
import { OFFICE } from 'financialAnalytic/shared/field/OfficeField';
import { useExpenseRoute } from '../ExpenseRouter';

const UploadExpensesPage = () => {
  const { listRoute } = useExpenseRoute();
  const {
    uploadExpensesForms,
    additionalInfoCallback,
    getListExpenses,
    initialFormsListData,
    getValidationSchema: getEditValidationSchema,
  } = useUploadExpenses();

  const getValidationSchema = () => {
    return Promise.resolve(UploadExpensesValidation);
  };

  return initialFormsListData.length > 0 ? (
    <CreatePageLayout buttons={<></>} breadcrumbs={<UploadExpenseBreadcrumbs />} title="Upload employee expenses">
      <FormListTemplate
        // TODO use batch submit after backend is ready
        isBatchUpload={false}
        isBatchUploadBySize={false}
        isShowAdditionalInformation
        submitFormFunc={uploadExpensesForms}
        additionalInfoFunc={additionalInfoCallback}
        getValidationSchemaFunc={getEditValidationSchema}
        initialListData={initialFormsListData}
        afterSubmit={{
          message: 'You are awesome! Employee expenses have been successfully uploaded.',
          redirect: listRoute,
        }}
      >
        <ExpenseForm disabledOffice isNeedToUploadOfficeByEmployee={false} />
      </FormListTemplate>
    </CreatePageLayout>
  ) : (
    <FormTemplate
      initialData={{
        // TODO Now only KRAKOW_1
        office: OFFICE.KRAKOW_1,
      }}
      getValidationSchemaFunc={getValidationSchema}
      submitFormFunc={getListExpenses}
    >
      <CreatePageLayout breadcrumbs={<UploadExpenseBreadcrumbs />} title="Upload employee expenses">
        <FormSection>
          <FormRow>
            <DateInput label="Period" source="period" dateFormat={DATE_FORMAT.API_ONLY_MONTH} />
            <OfficeDropdown
              mapResults={(office: any) => ({
                value: snakeCase(office.name).toUpperCase(),
                label: OFFICE_NAMES[office.name],
              })}
              source="office"
              // TODO Now only KRAKOW_1
              disabled
            />
          </FormRow>

          <BigFormRow>
            <UploadInputWithoutArtifactID
              maxElements={1}
              label="File"
              source="file"
              formats="xls,xlsx"
              canAddFromClipboard={false}
            />
          </BigFormRow>
        </FormSection>
      </CreatePageLayout>
    </FormTemplate>
  );
};

export default UploadExpensesPage;
