/* istanbul ignore file */
import React from 'react';
import Button from 'uibuilder/button/Button';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/AddOutlined';

interface PlanProjectIssueButtonProps {
  checked: boolean;
  disabled?: boolean;
  onSelect: () => void;
  onUnSelect: () => void;
}

const PlanProjectIssueButton = ({ checked, onSelect, onUnSelect, disabled }: PlanProjectIssueButtonProps) => {
  const handleChange = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (checked) {
      onUnSelect();
    } else {
      onSelect();
    }
  };

  if (checked) {
    return (
      <Button onClick={handleChange} className="plan-project-issue-button" disabled={disabled}>
        <CloseIcon />
      </Button>
    );
  }

  return (
    <Button onClick={handleChange} outline className="plan-project-issue-button" disabled={disabled}>
      <AddIcon />
    </Button>
  );
};

export default PlanProjectIssueButton;
