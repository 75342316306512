/* istanbul ignore file */
import React from 'react';
import {
  useQualificationModelUrl,
  useSingleQualificationModelUrl,
  useSpecializationId,
} from 'erp/qualification/model/QualificationModelRouter';
import { HorizontalMenu, HorizontalMenuItem } from 'shared/uibuilder/menu';
import { Specialization } from 'erp/qualification/model/qualificationModelService';

const QualificationMenu = ({ specializations }: { specializations: Specialization[] }) => {
  const { getSingleEntityUrl: getQualificationModelUrl } = useQualificationModelUrl();
  const { getSingleEntityUrl: getSingleQualificationModelUrl } = useSingleQualificationModelUrl();
  const specializationId = useSpecializationId();

  return (
    <HorizontalMenu className="mt-4 pt-0">
      {specializations?.map((specialization, index) => (
        <HorizontalMenuItem
          key={`horizontal-menu-item-${specialization.id}`}
          name={specialization.title}
          linkUrl={
            index === 0 && !specializationId
              ? getQualificationModelUrl()
              : getSingleQualificationModelUrl(specialization.id)
          }
          exact
        />
      ))}
    </HorizontalMenu>
  );
};

export default QualificationMenu;
