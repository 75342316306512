import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useStorageService from 'shared/uibuilder/storageService';

const useViewModeToggle = ({
  onModeChange = () => {},
  defaultMode,
  cacheName,
  initialMode,
}: {
  initialMode?: Nullable<string>;
  cacheName: string;
  defaultMode: string;
  onModeChange?: (mode: string) => void;
}) => {
  const [activeMode, setActiveMode] = useState(initialMode || defaultMode);
  const { pathname } = useLocation();

  const getCacheId = () => `${pathname}-${cacheName}`;

  const { getDataFromStorage, setDataToStorage } = useStorageService(
    getCacheId(),
    () => ({
      activeMode: initialMode || defaultMode,
    }),
    '1.0',
  );

  const updateActiveMode = (newMode: string) => {
    setDataToStorage({
      activeMode: newMode,
    });
    setActiveMode(newMode);
    onModeChange(newMode);
  };

  useEffect(() => {
    (async () => {
      const { activeMode: storageActiveMode } = await getDataFromStorage();

      setActiveMode(storageActiveMode);
      onModeChange(storageActiveMode);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activeMode,
    updateActiveMode,
  };
};

export default useViewModeToggle;
