/* istanbul ignore file */
import React from 'react';
import CandidateLink from 'erp/candidate/shared/CandidateLink';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField } from 'shared/uibuilder/field';

const CandidateLinkField = props => {
  const { getLabel, getValue: getId } = useFieldHelper(props);

  return <TextField label={getLabel()} value={<CandidateLink id={getId()} />} />;
};

export default CandidateLinkField;
