import { mapValues } from 'lodash';
import { resolveSchema } from 'validation-schema-library';

const useOneStopRequestMapper = () => {
  const mapToApiFormat = async (requestId: string, formData: any, request: any) => {
    const resolvedSchema = resolveSchema(formData, request.validationSchema || {});
    return mapValues(formData, (value, key) => {
      let result = true;
      const { hidden, visible } = resolvedSchema[key] || {};
      if (hidden === true || visible === false) {
        result = false;
      }
      return result ? value : null;
    });
  };

  return {
    mapToApiFormat,
  };
};

export default useOneStopRequestMapper;
