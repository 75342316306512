/* istanbul ignore file */
import React from 'react';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { BooleanField, FormattedDateField, FormattedTextField, NumberField, TextField } from 'shared/uibuilder/field';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import List from 'shared/uibuilder/list/List';
import {
  ChangeRequestStatusLabel,
  ProbabilityLabel,
  ImpactLabel,
} from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import EnumField from 'shared/uibuilder/field/EnumField';
import ArtifactStorageOneFileLink from 'artifactStorage/shared/field/ArtifactStorageOneFileLink';
import { PROJECT_MANAGEMENT_FOLDER, ArtifactFileType } from 'artifactStorage/shared/artifactStorageService';
import ProjectInternalReportCpiField from '../shared/field/ProjectInternalReportCpiField';
import './ProjectReportTable.scss';

const ShowProjectWeeklyReport = () => {
  const { data } = useShowContext();

  const getAchievements = async () => {
    return Promise.resolve({ result: data.weeklyReportData.achievements });
  };
  const getPlannedItems = async () => {
    return Promise.resolve({ result: data.weeklyReportData.plannedItems });
  };
  const getMilestones = async () => {
    return Promise.resolve({ result: data.weeklyReportData.milestones });
  };
  const getDiscussions = async () => {
    return Promise.resolve({ result: data.weeklyReportData.discussions });
  };
  const getChangedRequests = async () => {
    return Promise.resolve({ result: data.weeklyReportData.changeRequests });
  };
  const getRisks = async () => {
    return Promise.resolve({ result: data.weeklyReportData.risks });
  };
  const getCustomerDependencies = async () => {
    return Promise.resolve({ result: data.weeklyReportData.customerDependencies });
  };
  const getDiscoveryScopeBasedTypesData = async () => {
    return Promise.resolve({ result: data.internalReportData.discoveryScopeBasedTypesData });
  };
  const getAgileProductMaintenanceSupportTypesData = async () => {
    return Promise.resolve({ result: data.internalReportData.agileProductMaintenanceSupportTypesData });
  };

  const emptyListMessageComponent = <TextField source="" label="" value="—" />;

  const customExternalReportSection =
    data.report != null ? (
      <>
        <TextField source="" value=" " label="External Report" />
        <BigSectionRow>
          <span>A custom report was uploaded. You can find it in the Artifacts Storage.</span>
          <ArtifactStorageOneFileLink
            id={data.report.id}
            linkText="View report"
            url={`${PROJECT_MANAGEMENT_FOLDER}/project:${data.project.id}/type:${ArtifactFileType.PROJECT_WEEKLY_REPORT}`}
          />
        </BigSectionRow>
      </>
    ) : (
      <>
        <TextField source="" value=" " label="External Report" />
        <BigSectionRow>
          <span>
            The option to upload a custom report was selected to fill in the external report. The external report has
            not been uploaded to the system yet.
          </span>
        </BigSectionRow>
      </>
    );

  const externalReportSection =
    data.corporateModel === 'YES' ? (
      <>
        <BigSectionRow>
          <div className="formatted-text-field">
            <TextField source="weeklyReportData.executiveSummary" label="Executive summary" />
          </div>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="List of achievements for the last week" />
          <List getDataMethod={getAchievements}>
            <DataGridLayout isSmallTable emptyListMessageComponent={emptyListMessageComponent}>
              <TextField source="id" label="ID" />
              <TextField source="description" label="Description" />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="List of items planned for the next week" />
          <List getDataMethod={getPlannedItems}>
            <DataGridLayout isSmallTable emptyListMessageComponent={emptyListMessageComponent}>
              <TextField source="id" label="ID" />
              <TextField source="description" label="Description" />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="Key project milestones" />
          <List getDataMethod={getMilestones}>
            <DataGridLayout
              isSmallTable
              emptyListMessageComponent={emptyListMessageComponent}
              tableWrapperClassName="formatted-text-list"
            >
              <FormattedTextField source="id" label="ID" format={str => `M${str}`} />
              <TextField source="description" label="Description" />
              <FormattedDateField source="plannedDate" label="Planned date" format={DATE_FORMAT.REDMINE_DATE} />
              <FormattedDateField
                source="currentlyPlannedDate"
                label="Currently planned date"
                format={DATE_FORMAT.REDMINE_DATE}
              />
              <TextField source="comments" label="Comments" />
              <FormattedDateField source="endDate" label="End date" format={DATE_FORMAT.REDMINE_DATE} />
              <BooleanField source="completed" label="Completed" />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="Potential change requests" />
          <List getDataMethod={getDiscussions}>
            <DataGridLayout
              isSmallTable
              emptyListMessageComponent={emptyListMessageComponent}
              tableWrapperClassName="formatted-text-list"
            >
              <TextField source="description" label="Description" width={600} />
              <FormattedDateField source="initiatedDate" label="Initiated date" format={DATE_FORMAT.REDMINE_DATE} />
              <TextField source="comments" label="Comments" />
              <FormattedDateField source="closedDate" label="Closed date" format={DATE_FORMAT.REDMINE_DATE} />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="Change requests" />
          <List getDataMethod={getChangedRequests}>
            <DataGridLayout
              isSmallTable
              emptyListMessageComponent={emptyListMessageComponent}
              tableWrapperClassName="formatted-text-list"
            >
              <FormattedTextField source="id" label="ID" format={str => `C${str}`} />
              <TextField source="description" label="Description" />
              <FormattedDateField source="createdDate" label="Created date" format={DATE_FORMAT.REDMINE_DATE} />
              <EnumField label="Status" source="status" options={ChangeRequestStatusLabel} />
              <TextField source="comments" label="Comments" />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="Risks" />
          <List getDataMethod={getRisks}>
            <DataGridLayout
              isSmallTable
              emptyListMessageComponent={emptyListMessageComponent}
              tableWrapperClassName="formatted-text-list"
            >
              <FormattedTextField source="id" label="ID" format={str => `R${str}`} />
              <TextField source="description" label="Description" />
              <FormattedDateField source="createdDate" label="Created date" format={DATE_FORMAT.REDMINE_DATE} />
              <EnumField label="Probability" source="probability" options={ProbabilityLabel} />
              <EnumField label="Impact" source="impact" options={ImpactLabel} />
              <TextField source="comments" label="Strategy" />
              <FormattedDateField source="closedDate" label="Closed date" format={DATE_FORMAT.REDMINE_DATE} />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <TextField source="" value=" " label="Customer's dependencies" />
          <List getDataMethod={getCustomerDependencies}>
            <DataGridLayout
              isSmallTable
              emptyListMessageComponent={emptyListMessageComponent}
              tableWrapperClassName="formatted-text-list"
            >
              <TextField source="description" label="Description" width={600} />
              <TextField source="name" label="Name" />
              <FormattedDateField source="createdDate" label="Created date" format={DATE_FORMAT.REDMINE_DATE} />
              <FormattedDateField source="waitingDate" label="Waiting date" format={DATE_FORMAT.REDMINE_DATE} />
              <FormattedDateField source="closedDate" label="Closed date" format={DATE_FORMAT.REDMINE_DATE} />
            </DataGridLayout>
          </List>
        </BigSectionRow>
        <BigSectionRow>
          <div className="formatted-text-field">
            <TextField source="weeklyReportData.customerExpectation" label="Customer's expectations" />
          </div>
        </BigSectionRow>
        <BigSectionRow>
          <div className="formatted-text-field">
            <TextField source="weeklyReportData.otherNotes" label="Other notes" />
          </div>
        </BigSectionRow>
      </>
    ) : (
      customExternalReportSection
    );

  return (
    <ShowSection title="Weekly Report">
      <SectionRow>
        <FormattedDateField source="from" label="Cycle period from" format={DATE_FORMAT.FULL_DATE} />
        <FormattedDateField source="to" label="Cycle period to" format={DATE_FORMAT.FULL_DATE} />
      </SectionRow>
      {externalReportSection}
      {data?.internalReportData?.projectServiceModel && (
        <BigSectionRow>
          <TextField source="" value=" " label="Internal Report" />
          {data?.internalReportData?.discoveryScopeBasedTypesData.length > 0 ? (
            <List getDataMethod={getDiscoveryScopeBasedTypesData}>
              <DataGridLayout
                isSmallTable
                emptyListMessageComponent={<></>}
                tableWrapperClassName="formatted-text-list comments-column-width"
              >
                <FormattedDateField source="periodFrom" label="Period from" format={DATE_FORMAT.REDMINE_DATE} noWrap />
                <FormattedDateField source="periodTo" label="Period to" format={DATE_FORMAT.REDMINE_DATE} noWrap />
                <FormattedTextField source="scopeCompletion" label="Scope Completion, %" format={str => `${str}%`} />
                <NumberField source="actualBudget" label="Actual budget, h" displayZeroValue />
                <NumberField source="totalBudget" label="Total budget, h" displayZeroValue />
                <FormattedTextField source="budgetUsage" label="Budget usage, %" format={str => `${str}%`} />
                <ProjectInternalReportCpiField source="budgetCpi" label="Budget CPI" />
                <NumberField source="actualWorkingDays" label="Actual working days" displayZeroValue />
                <NumberField source="totalWorkingDays" label="Total working days" displayZeroValue />
                <FormattedTextField source="timelineUsage" label="Timeline usage, %" format={str => `${str}%`} />
                <ProjectInternalReportCpiField source="timelineCpi" label="Timeline CPI" />
                <TextField source="comments" label="Comments" />
              </DataGridLayout>
            </List>
          ) : (
            <List getDataMethod={getAgileProductMaintenanceSupportTypesData}>
              <DataGridLayout
                isSmallTable
                emptyListMessageComponent={<></>}
                tableWrapperClassName="formatted-text-list comments-column-width"
              >
                <FormattedDateField source="periodFrom" label="Period from" format={DATE_FORMAT.REDMINE_DATE} noWrap />
                <FormattedDateField source="periodTo" label="Period to" format={DATE_FORMAT.REDMINE_DATE} noWrap />
                <NumberField source="provisioned" label="Provisioned, h" displayZeroValue />
                <NumberField source="actual" label="Actual, h" displayZeroValue />
                <NumberField source="billable" label="Billable, h" displayZeroValue />
                <NumberField source="ba" label="B/A" displayZeroValue />
                <NumberField source="waste" label="Waste, h" displayZeroValue />
                <TextField source="comments" label="Comments" />
              </DataGridLayout>
            </List>
          )}
        </BigSectionRow>
      )}
    </ShowSection>
  );
};

export default ShowProjectWeeklyReport;
