import React from 'react';
import LinearProgress, { calculateProgress, MAX_VALUE, normalise } from 'uibuilder/LinearProgress';
import Box from 'uibuilder/Box';

const ProjectWeeklyAllocationsProgress = ({
  maxValue,
  middleValue,
  currentValue,
}: {
  maxValue: number;
  middleValue: number;
  currentValue: number;
}) => {
  const progress = calculateProgress(currentValue, maxValue);
  const minProgress = calculateProgress(middleValue, maxValue);
  let barColor = 'green';

  if (progress > minProgress) {
    barColor = 'orange';
  }

  if (progress > MAX_VALUE) {
    barColor = 'red';
  }

  return (
    <div className="weekly-allocations-progress__wrapper">
      <LinearProgress
        maxValue={maxValue}
        currentValue={currentValue}
        className={`weekly-allocations-progress ${barColor}`}
      />
      {maxValue !== middleValue && middleValue ? (
        <Box
          className="weekly-allocations-progress__separator"
          sx={{
            left: `${normalise(middleValue, maxValue)}%`,
          }}
        />
      ) : null}
    </div>
  );
};

export default ProjectWeeklyAllocationsProgress;
