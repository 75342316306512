/* istanbul ignore file */
import React from 'react';
import ProjectQueues from 'syberryToday/shared/ProjectQueues';
import PlannedIssues from 'syberryToday/shared/PlannedIssues';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import useSyberryTodayService from 'syberryToday/useSyberryTodayService';
import DailyCheckinListLayout from './DailyCheckinListLayout';

const DailyCheckin = () => {
  const { activeStep = 0, isDataLoaded, projects } = useSyberryTodayContext();
  const { getProjectDataWithoutParentAndRecurrently, getPlannedTasks } = useSyberryTodayService();

  if (!isDataLoaded) {
    return null;
  }

  if (activeStep) {
    return (
      <DailyCheckinListLayout key="planned-daily-chekin" getDataMethod={getPlannedTasks}>
        <PlannedIssues emptyAlertMessage="There are no tasks for today" />
      </DailyCheckinListLayout>
    );
  }

  return (
    <DailyCheckinListLayout
      getDataMethod={({ filter = {} }: any) => getProjectDataWithoutParentAndRecurrently(filter.projectId.eq)}
      defaultFilter={{
        projectId: {
          eq: projects[0]?.id,
        },
      }}
    >
      <ProjectQueues />
    </DailyCheckinListLayout>
  );
};

export default DailyCheckin;
