/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';
import Typography from 'uibuilder/Typography';
import Button from 'uibuilder/button/Button';
import FloatingButtons from 'uibuilder/form/FloatingButtons';
import logoIcon from 'images/logo-icon.svg';

const EF1CreatePageLayout = ({
  children,
  isFormVisible,
  isErrorButtonVisible,
  returnToForm,
}: {
  children: React.ReactNode;
  isFormVisible: boolean;
  isErrorButtonVisible: boolean;
  returnToForm: () => void;
}) => (
  <Paper className="page-content">
    <Typography variant="h1" className="c-title d-flex align-items-center" sx={{ marginBotton: '34px' }}>
      <img src={logoIcon} alt="Syberry logo" width="30" className="mr-2" />
      EF-1 Employee profile information
    </Typography>
    <div>
      <Message />
      {isFormVisible && [<p>Please fill in the form:</p>, children, <FloatingButtons isCancelButtonVisible={false} />]}
      {isErrorButtonVisible && (
        <Button type="button" onClick={returnToForm}>
          Click here to return to the form
        </Button>
      )}
    </div>
  </Paper>
);

export default EF1CreatePageLayout;
