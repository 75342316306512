/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import RbsBreadcrumbs from 'erp/rbs/shared/RbsBreadcrumbs';

const UpdateResponsibilityBreadcrumbs = () => {
  return (
    <RbsBreadcrumbs>
      <UpdateBreadcrumbItem />
    </RbsBreadcrumbs>
  );
};

export default UpdateResponsibilityBreadcrumbs;
