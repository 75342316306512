/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';

const EditEmployeeOfferButton = ({ ...props }) => {
  const { getUpdateEntityUrl } = useEmployeeOfferUrl();

  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditEmployeeOfferButton;
