import React from 'react';
import { ButtonProps } from 'uibuilder/button/Button';
import { Button, ButtonWithConfirmation } from 'uibuilder/button';
import ClearIcon from '@mui/icons-material/ClearOutlined';

const DEFAULT_PERMISSION_TO_CHECK = 'DELETE';

export interface DeleteButtonProps extends ButtonProps {
  deleteMethod: () => Promise<void>;
  displayMessage: string;
  permissionToCheck?: string;
  afterDelete?: {
    successMessage?: string;
    errorMessage?: string;
    redirectUrl?: string;
    errorStatusesMessages?: Dictionary<string>;
    execute?: (data?: any) => void;
    executeOnError?: () => void;
  };
  showIcon?: boolean;
  buttonTag?: ReactComponent<ButtonProps> | typeof Button;
  buttonText?: string;
  className?: string;
  outline?: boolean;
  color?: BasicColors;
  modalChildren?: React.ReactElement | React.ReactElement[];
}

const DeleteButton = ({
  deleteMethod,
  permissionToCheck = DEFAULT_PERMISSION_TO_CHECK,
  afterDelete,
  showIcon = false,
  buttonText = 'Delete',
  ...props
}: DeleteButtonProps) => {
  return (
    <ButtonWithConfirmation
      {...props}
      afterSubmit={afterDelete}
      submitMethod={deleteMethod}
      permissionToCheck={permissionToCheck}
      modalConfirmButtonText={buttonText}
      startIcon={showIcon ? <ClearIcon fontSize="small" /> : null}
    >
      <span>{buttonText}</span>
    </ButtonWithConfirmation>
  );
};

DeleteButton.defaultProps = {
  permissionToCheck: DEFAULT_PERMISSION_TO_CHECK,
  afterDelete: null,
  showIcon: false,
  buttonTag: Button,
  outline: true,
  color: 'primary',
};

export default DeleteButton;
