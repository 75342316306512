/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import PurchaseOrderStatusDropdown from 'financialAnalytic/purchaseOrder/shared/input/PurchaseOrderStatusDropdown';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';

const PurchaseOrderStatusFilter = ({ source, ...props }: BaseFilterProps & { label: string }) => {
  return (
    <DropDownFilter {...props} source={source}>
      <PurchaseOrderStatusDropdown source={source} />
    </DropDownFilter>
  );
};

export default PurchaseOrderStatusFilter;
