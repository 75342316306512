import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { InputList } from 'shared/uibuilder/form/input';
import PositionsInputsListWrapper from '../../shared/inputs/PositionsInputsListWrapper';
import VacanciesInput from '../../shared/inputs/HiringVacanciesInput';

const UpdateHiringContent = () => {
  return (
    <>
      <FormSection title="">
        <FormRow>
          <EmployeeInput source="owner" nameSource="owner" label="DRI" />
        </FormRow>

        <VacanciesInput />
      </FormSection>

      <FormSection title="Positions">
        <BigFormRow>
          <InputList
            isAddable={false}
            isDeletable={false}
            source="requestPositions"
            inputTemplate={PositionsInputsListWrapper}
          />
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default UpdateHiringContent;
