/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import { useCommonSkillService } from 'erp/employee/commonSkills/commonSkill/shared/commonSkillService';
import CommonSkillForm from 'erp/employee/commonSkills/commonSkill/CommonSkillForm';

const MESSAGE_CONTEXT = 'commonSkillForm';

interface AddCommonSkillModalFormProps {
  closeModal: () => void;
}

const AddCommonSkillModalForm = ({ closeModal }: AddCommonSkillModalFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { createCommonSkill } = useCommonSkillService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Add common skill
    </Typography>
  );

  return (
    <CreateForm
      submitFormFunc={createCommonSkill}
      messageContext={MESSAGE_CONTEXT}
      initialData={{}}
      afterSubmit={{
        execute: useCallback(() => {
          closeForm();
        }, [closeForm]),
      }}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <CommonSkillForm handleCancelClick={closeForm} />
      </ModalWindow>
    </CreateForm>
  );
};

export default AddCommonSkillModalForm;
