// libs
import React from 'react';
// components
import { Route } from 'shared/routing';
import Page403 from 'shared/authorization//Page403';
// services
import useAuthorization, { Permission } from 'shared/authorization/authorizationService';

type GlobalProtectedRouteProps = {
  path?: string;
  exact?: boolean;
  globalPermissionToCheck: Permission | Permission[];
  children: React.ReactNode;
};

const GlobalProtectedRoute = (props: GlobalProtectedRouteProps) => {
  const { isGranted } = useAuthorization();
  const { globalPermissionToCheck, children } = props;

  return <Route {...props}>{isGranted(globalPermissionToCheck) ? children : <Page403 />}</Route>;
};

GlobalProtectedRoute.defaultProps = {
  exact: false,
  path: undefined,
};

export default GlobalProtectedRoute;
