import React from 'react';
import { EmployeeLink, EmployeeLinkWithNameLoading } from 'erp/employee';
import Box from 'uibuilder/Box';
import { oneOfType, number, string } from 'prop-types';

const DEFAULT_NAME = 'Leonardo DaVinci';

const TimelineRecordAuthor = ({ name, id }) => {
  if (name) {
    return <EmployeeLink id={id} name={name} />;
  }

  return id ? (
    <Box sx={{ display: ' inline-flex', '.input-loader': { transform: 'translateY(3px)', marginTop: '-2px' } }}>
      <EmployeeLinkWithNameLoading id={id} defaultValue={DEFAULT_NAME} />
    </Box>
  ) : (
    <span>{DEFAULT_NAME}</span>
  );
};

TimelineRecordAuthor.propTypes = {
  name: string,
  id: oneOfType([number, string]),
};

TimelineRecordAuthor.defaultProps = {
  name: null,
  id: null,
};

export default TimelineRecordAuthor;
