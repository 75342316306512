/* istanbul ignore file */
import React from 'react';
import { ErpRouter } from 'erp';
import { CrmRouter } from 'crm';
import { AuditRouter } from 'audit';
import { DavinciRouter } from 'davinci';
import { OneStopRouter, RedirectToOneStop } from 'oneStop';
import { MyActivityRouter } from 'myActivity';
import { HandbookRouter } from 'handbook';
import { ArtifactsRouter } from 'artifact';
import { CamundaRouter } from 'camunda';
import { ExternalFormRouter } from 'externalform';
import { SendEmailRouter } from 'sendEmail';
import { UserSettingsRouter } from 'user/Settings';
import ExtensionRouter from 'erp/candidate/extension/ExtensionRouter';
import ArtifactStorageRouter from 'artifactStorage/ArtifactStorageRouter';
import { AuthenticationRouter } from 'authentication';
import { RoutingSwitch, Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import Page403 from 'shared/authorization/Page403';
import Page from 'layout/Default';
import { ROUTE403, ROUTE404 } from 'shared/api/const';
import { PrivilegedRouter } from './privileged';
import { InstantFeedbackRouter } from 'instantFeedback';
import { SyberryTodayRouter } from 'syberryToday';
import SidepanelExtensionRouter from './erp/candidate/applications/SidepanelExtensionRouter';

export const ROOT_PATH = '/';
export const APPLICATION_URL = process.env.REACT_APP_DAVINCI_FRONTEND_BASE_URL;

const ApplicationRouter = () => (
  <RoutingSwitch>
    <ExternalFormRouter />
    <Route path={ROUTE403} component={Page403} exact />
    <Route path={ROUTE404} component={Page404} />
    <ExtensionRouter />
    <SidepanelExtensionRouter />
    <Page>
      <RoutingSwitch>
        <AuthenticationRouter />
        <RedirectToOneStop />
        <ErpRouter />
        <CrmRouter />
        <AuditRouter />
        <PrivilegedRouter />
        <OneStopRouter />
        <MyActivityRouter />
        <DavinciRouter />
        <CamundaRouter />
        <ArtifactsRouter />
        <SendEmailRouter />
        <UserSettingsRouter />
        <HandbookRouter />
        <ArtifactStorageRouter />
        <InstantFeedbackRouter />
        <SyberryTodayRouter />
        <Route component={Page404} />
      </RoutingSwitch>
    </Page>
  </RoutingSwitch>
);

export default ApplicationRouter;
