import React from 'react';
import { useParams } from 'react-router-dom';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import { ResourceData } from 'shared/crud/baseCrudService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { AfterSubmitProps, CommonFormProps } from 'shared/uibuilder/form/formHelper';

export interface UpdateFormProps extends CommonFormProps {
  children: React.ReactElement | React.ReactElement[];
  submitFormFunc: (id: string, values: FormFieldsData) => Promise<object>;
  getDataFunc: (id: string) => Promise<ResourceData>;
  idSource?: Nullable<string>;
  entityId?: string;
  afterSubmit?: AfterSubmitProps<(id: string, data: object) => any, string | ((data: object) => any)>;
}
/**
 * Update form
 * @param submitFormFunc -- function which accepts id and sends updating request
 * @param getDataFunc -- function which accepts id and returns entity's data
 * @param children -- children
 * @param idSource -- name of routing parameter where id of entity is stored
 * @param props -- other props
 * @param afterSubmit -- object with:
 *    - redirect (url to redirect after successful form submission. You can use :id which will be replaced with actual entity id)
 *    - message - message which will be shown at the page after redirect
 * @returns {*}
 * @constructor
 */
const UpdateForm = ({
  submitFormFunc,
  getDataFunc,
  children,
  entityId,
  idSource,
  afterSubmit,
  ...props
}: UpdateFormProps) => {
  const params: Dictionary<string> = useParams();
  const id = entityId || params[idSource || 'id'];

  return (
    <FormTemplate
      {...props}
      getDataFunc={() => getDataFunc(id)}
      data-testId={id}
      afterSubmit={
        afterSubmit && {
          message: afterSubmit.message,
          redirect:
            typeof afterSubmit.redirect === 'function'
              ? afterSubmit.redirect
              : afterSubmit.redirect && afterSubmit.redirect.replace(':id', id),
          execute: afterSubmit.execute && (data => afterSubmit.execute && afterSubmit.execute(id, data)),
        }
      }
      submitFormFunc={data => submitFormFunc(id, data)}
      isCreateRequest={false}
    >
      {children}
    </FormTemplate>
  );
};

UpdateForm.defaultProps = {
  idSource: null,
};

export default UpdateForm;
