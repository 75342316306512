/* istanbul ignore file */
// libs
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
// components
import OpportunityContext from 'crm/opportunity/OpportunityContext';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import OpportunityViewPage from 'crm/opportunity/show';
import OpportunityTimelinePage from 'crm/opportunity/Timeline';
import OpportunitiesListPage from 'crm/opportunity/list';
import { CreateOpportunity, UpdateOpportunity } from 'crm/opportunity/createupdate';
// services
import { CREATE_OPPORTUNITY, READ_TIMELINE } from 'crm/crmService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { READ_OPPORTUNITIES_LIST, READ_OPPORTUNITY } from 'crm/opportunity/shared/opportunityService';
import ShowOpportunityContext from 'crm/opportunity/show/ShowOpportunityContext';
import PresaleOpportunityTimelinePage from 'crm/opportunity/presale';
import { READ_PRESALE_TIMELINE } from 'crm/opportunity/presale/presaleTimelineService';

export const OPPORTUNITY_PATH = '/crm/opportunities';

export const useOpportunityRoute = () => {
  const routes = useRoute({ listRoute: OPPORTUNITY_PATH, timelinePostfix: 'activities' });
  return {
    ...routes,
    presaleTimelineRoute: `${routes.singleEntityRoute}/presale-timeline/:timelineEntryId?`,
  };
};

export const useOpportunityUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: OPPORTUNITY_PATH,
    timelinePostfix: 'activities',
  });

  const { getSingleEntityUrl } = baseUrls;

  const getCreateUrl = (params: Dictionary<string>) => {
    const urlParameters = new URLSearchParams(params).toString();

    return `${baseUrls.getCreateUrl()}${urlParameters ? `?${urlParameters}` : ''}`;
  };

  return {
    ...baseUrls,
    getCreateUrl,
    getPresaleTimelineUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/presale-timeline`,
  };
};

export const useOpportunityId = () => useParams<Dictionary<string>>().id;

const OpportunityRouter = () => {
  const {
    createRoute,
    updateRoute,
    listRoute,
    singleEntityRoute,
    timelineRoute,
    presaleTimelineRoute,
  } = useOpportunityRoute();

  return (
    <ProtectedRouter basePath={OPPORTUNITY_PATH}>
      <Switch>
        <GlobalProtectedRoute globalPermissionToCheck={READ_OPPORTUNITIES_LIST} path={listRoute} exact>
          <OpportunitiesListPage />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_OPPORTUNITY}>
          <CreateOpportunity />
        </GlobalProtectedRoute>

        <Route path={singleEntityRoute} exact>
          <OpportunityContext permissionToCheck={READ_OPPORTUNITY}>
            <OpportunityViewPage />
          </OpportunityContext>
        </Route>

        <Route path={updateRoute} exact>
          <OpportunityContext permissionToCheck="UPDATE">
            <UpdateOpportunity />
          </OpportunityContext>
        </Route>

        <Route path={timelineRoute} exact>
          <OpportunityContext permissionToCheck={READ_TIMELINE}>
            <OpportunityTimelinePage />
          </OpportunityContext>
        </Route>

        <Route path={presaleTimelineRoute} exact>
          <OpportunityContext permissionToCheck={READ_PRESALE_TIMELINE}>
            <ShowOpportunityContext>
              <PresaleOpportunityTimelinePage />
            </ShowOpportunityContext>
          </OpportunityContext>
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

OpportunityRouter.getRouterPath = () => OPPORTUNITY_PATH;

export default OpportunityRouter;
