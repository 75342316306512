/* istanbul ignore file */
import educationValidation from './educationValidation';

export default {
  request: {
    type: 'array',
    maxSize: 5,
    forEach: {
      type: 'object',
      properties: {
        ...educationValidation,
      },
    },
  },
};
