export default {
  templateType: {
    type: 'enum',
    required: true,
  },
  descriptionText: {
    type: 'string',
    required: true,
  },
  attachmentIds: {
    type: 'array',
    required: {
      value: true,
      when: {
        _or_: [
          {
            $templateType: {
              eq: 'SYBERRY',
            },
            $isCustomerDetailsUnchanged: {
              in: [false, null, undefined],
            },
          },
          {
            $templateType: {
              in: ['CUSTOMER', undefined],
            },
          },
        ],
      },
    },
    maxSize: 15,
  },
};
