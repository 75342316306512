import React, { useEffect } from 'react';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import useArtifactStorageService, { ArtifactFileType } from 'artifactStorage/shared/artifactStorageService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { mapArtifactMetaInfo, mapArtifactRelationInfo } from '../mapArtifactInformationFunctions';

interface ArtifactStorageFileInputProps {
  source: string;
  label: string;
  nameSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;

  customPlaceholder: string;
  searchType?: ArtifactFileType;
  relationClass?: string;
  path?: string[];
  dependsOn?: string;
  checkDepending?: boolean;
  showMetaInfo?: boolean;
  showRelationToClass?: string;

  props?: any;
}

const ArtifactStorageFileInput = ({
  nameSource = 'fullName',
  relationClass = '',
  customPlaceholder,
  path = [],
  searchType,
  dependsOn,
  checkDepending,
  showMetaInfo = true,
  showRelationToClass,
  ...props
}: ArtifactStorageFileInputProps) => {
  const { data = {}, isDirty } = useFormContext();
  const { searchAll } = useArtifactStorageService();
  const { getSource, getRawOnChangeCallback } = useInputHelper(props);
  const source = getSource();

  const parentOnChange = getRawOnChangeCallback() || (() => {});
  const dependsOnField = dependsOn ? data[dependsOn] : undefined;

  const loadFiles = async () => {
    if (checkDepending && !dependsOnField) {
      return Promise.resolve({ result: [] });
    }

    const files = await searchAll({
      filter: {
        path,
        searchType,
        relationClass,
        'specification:search': dependsOnField && {
          eq: dependsOnField || '',
        },
      },
    });

    return Promise.resolve({ result: files });
  };

  useEffect(() => {
    if (isDirty && checkDepending) {
      parentOnChange({
        [source]: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependsOnField]);

  const mapResult = (item: any) => {
    return {
      value: item.file.id,
      label: `${item.file.artifact.title} ${showMetaInfo ? mapArtifactMetaInfo(item.file.metaInformation) : ''} ${
        showRelationToClass ? mapArtifactRelationInfo(item.file.artifactRelation, showRelationToClass) : ''
      }`,
    };
  };

  return (
    <LoadDropdown
      {...props}
      placeholder={customPlaceholder}
      loadDataMethod={loadFiles}
      mapResults={mapResult}
      deps={[dependsOnField]}
      showLoader
    />
  );
};

export default ArtifactStorageFileInput;
