/* istanbul ignore file */
import React from 'react';
import SelectInput, { SelectInputProps } from 'shared/uibuilder/form/input/SelectInput';
import { getTimezonesOptions } from 'shared/timezone';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';

const MOST_USABLE_TIMESONES = ['Eastern', 'Central', 'Mountain', 'Pacific'];

const TimezoneDropdown = (props: SelectInputProps) => {
  const timezonesOptions = getTimezonesOptions();

  const popularTimezones = timezonesOptions.filter(({ label }: Option) =>
    MOST_USABLE_TIMESONES.find((timezone: string) => label.endsWith(timezone)),
  );

  const groupedOptions = [
    {
      label: 'Populars',
      options: popularTimezones,
    },
    {
      label: 'All',
      options: timezonesOptions.map((option: Option) => ({ ...option, isSearchable: true })),
    },
  ];

  return (
    <SelectInput
      {...props}
      options={groupedOptions}
      label="Time zone"
      filterOption={(option: { data: Option }, rawInput: string): boolean =>
        (option.data.label.toLowerCase().includes(rawInput.toLowerCase()) && option.data.isSearchable) || !rawInput
      }
      noOptionsMessage={() => 'Time zone not found'}
    />
  );
};

export default TimezoneDropdown;
