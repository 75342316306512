/* istanbul ignore file */
import AbstractTokenMarker from './AbstractTokenMarker';

const CLASS_NAME = 'incidentToken';

const POSITION = {
  top: 65,
  left: 19,
};

class IncidentTokenMarker extends AbstractTokenMarker {
  constructor(activityId: string, tokensCount: number) {
    super(activityId, tokensCount, CLASS_NAME, POSITION);
  }
}

export default IncidentTokenMarker;
