/* istanbul ignore file */
import React from 'react';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import useAssetNoteItem from 'financialAnalytic/assets/Timeline/note/assetNoteRegistry';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import { useAssetId } from 'financialAnalytic/assets/AssetRouter';
import AssetHeader from 'financialAnalytic/assets/shared/AssetHeader';
import AssetMenu from 'financialAnalytic/assets/shared/AssetMenu';
import AssetTimelineBreadcrumbs from 'financialAnalytic/assets/Timeline/Breadcrumbs';
import useAssetService from 'financialAnalytic/assets/useAssetService';
import { ResourceData } from 'shared/crud/baseCrudService';

const AssetTimelinePage = () => {
  const assetId = useAssetId();
  const { getById, createTimelineItem, searchTimelineItems, invalidateCache } = useAssetService();

  const noteRegistry = useAssetNoteItem() as any;

  const createTimelineItemMethod = async (itemType: string, request: ResourceData) => {
    const response = (await createTimelineItem(assetId, itemType, request)) as any;

    invalidateCache();

    return response;
  };

  return (
    <Show getDataMethod={() => getById(assetId)}>
      <Timeline
        registries={[noteRegistry]}
        getDataMethod={request => searchTimelineItems(assetId, request)}
        createTimelineItemMethod={createTimelineItemMethod}
        getPageByEntryIdMethod={id =>
          searchTimelineItems(assetId, { pageSize: 30, filter: {}, sort: { createdAt: 'DESC' } })
        }
      >
        <TimelinePageLayout
          menu={<AssetMenu />}
          breadcrumbs={<AssetTimelineBreadcrumbs />}
          entityHeader={<AssetHeader />}
        >
          <TimelineLayout noEntriesMessage="No entries in this asset timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default AssetTimelinePage;
