/* istanbul ignore file */
import React from 'react';
import useServiceService from 'erp/service/shared/serviceService';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { useServiceUrl } from 'erp/service/ServiceRouter';
import CreateServiceBreadcrumbs from 'erp/service/createupdate/Create/Breadcrumbs';
import ServiceForm from 'erp/service/createupdate/ServiceForm';

const CreateProject = () => {
  const { createService, getValidationSchema } = useServiceService();
  const { getListUrl } = useServiceUrl();

  return (
    <CreateForm
      submitFormFunc={createService}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Service has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Service" breadcrumbs={<CreateServiceBreadcrumbs />}>
        <ServiceForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateProject;
