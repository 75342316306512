/* istanbul ignore file */
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import React, { useCallback, useMemo } from 'react';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import { DateInput, Dropdown, TextArea } from 'shared/uibuilder/form/input';
import {
  ChangeRequestStatus,
  Impact,
  Probability,
  ProjectServiceModel,
  ProjectServiceModelLabels,
  ProjectWeeklyReportStatus,
  UseCorporateModel,
  UseCorporateModelLabel,
} from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import AddListItemButton from 'erp/project/weeklyreports/shared/button/AddListItemButton';
import ProjectWeeklyReportFormButtonsLayout from 'erp/project/weeklyreports/shared/button/ProjectWeeklyReportFormButtonsLayout';
import AchievementInputRow, {
  AchievementType,
  getAchievementSource,
} from 'erp/project/weeklyreports/shared/input/AchievementInputRow';
import ChangeRequestInputRow, {
  ChangeRequestType,
  getChangeRequestSource,
} from 'erp/project/weeklyreports/shared/input/ChangeRequestInputRow';
import CustomerDependencyInputRow, {
  CustomerDependencyType,
  getCustomerDependencySource,
} from 'erp/project/weeklyreports/shared/input/CustomerDependencyInputRow';
import DiscussionInputRow, {
  DiscussionType,
  getDiscussionSource,
} from 'erp/project/weeklyreports/shared/input/DiscussionInputRow';
import MilestoneInputRow, {
  getMilestonesSource,
  MilestoneType,
} from 'erp/project/weeklyreports/shared/input/MilestoneInputRow';
import PlannedItemInputRow, {
  getPlannedItemsSource,
  PlannedItemType,
} from 'erp/project/weeklyreports/shared/input/PlannedItemInputRow';
import RiskInputRow, { getRiskSource, RiskType } from 'erp/project/weeklyreports/shared/input/RiskInputRow';
import UpdateProjectWeeklyReportBreadcrumbs from 'erp/project/weeklyreports/update/UpdateProjectWeeklyReportBreadcrumbs';
import {
  achievementsTooltipMessage,
  changeRequestsTooltipMessage,
  customerDependenciesTooltipMessage,
  customerExpectationsTooltipMessage,
  discussionsTooltipMessage,
  executiveSummaryTooltipMessage,
  internalReportDataTooltipMessage,
  milestonesTooltipMessage,
  otherNotesTooltipMessage,
  plannedItemsTooltipMessage,
  risksTooltipMessage,
} from '../shared/projectWeeklyReportSectionTooltipMessages';
import AgileProductMaintenanceSupportTypesDataInputRow, {
  AgileProductMaintenanceSupportType,
  getAgileProductMaintenanceSupportTypesItemSource,
} from '../shared/input/AgileProductMaintenanceSupportTypesDataInputRow';
import DiscoveryScopeBasedTypesDataInputRow, {
  DiscoveryScopeBasedType,
  getDiscoveryScopeBasedTypesItemSource,
} from '../shared/input/DiscoveryScopeBasedTypesDataInputRow';
import { ProjectReportType } from '../shared/input/useInputRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import ArtifactStorageUploaderInput from 'artifact/shared/input/ArtifactStorageUploaderInput';

const ProjectWeeklyReportForm = () => {
  const {
    data: formData = {},
    setFormData = (value: FormFieldsData) => {},
    formErrors = {},
    setFormErrors = (errors: FormFieldsData) => {},
    isSubmitEnabled,
  } = useFormContext();

  const updateWeeklyReportData = (source: string, newValue: any) => {
    setFormData({
      ...formData,
      weeklyReportData: {
        ...formData.weeklyReportData,
        [source]: newValue,
      },
    });
  };

  const updateInternalReportData = (source: string, newValue: any) => {
    setFormData({
      ...formData,
      internalReportData: {
        ...formData.internalReportData,
        [source]: newValue,
      },
    });
  };

  const getErrorMessages = useCallback(
    (fieldSource: string) => {
      return formErrors[fieldSource];
    },
    [formErrors],
  );

  const isHighlightSection = (listSource: string, reportType?: ProjectReportType) => {
    return reportType === ProjectReportType.INTERNAL_REPORT
      ? !!getErrorMessages(`internalReportData`)
      : !!getErrorMessages(`weeklyReportData.${listSource}`) && formData.weeklyReportData[listSource].length === 0;
  };

  const addAchievement = useCallback(() => {
    const { achievements } = formData.weeklyReportData;
    const lastId = achievements.length === 0 ? 0 : achievements[achievements.length - 1]?.id;

    achievements.push({
      id: lastId + 1,
      description: '',
    });

    updateWeeklyReportData('achievements', achievements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addPlannedItem = useCallback(() => {
    const { plannedItems } = formData.weeklyReportData;
    const lastId = plannedItems.length === 0 ? 0 : plannedItems[plannedItems.length - 1]?.id;

    plannedItems.push({
      id: lastId + 1,
      description: '',
    });

    updateWeeklyReportData('plannedItems', plannedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addMilestone = useCallback(() => {
    const { milestones } = formData.weeklyReportData;
    const lastId = milestones.length === 0 ? -1 : milestones[milestones.length - 1]?.id;

    milestones.push({
      id: lastId + 1,
      description: '',
      fromPreviousReport: false,
      plannedDate: null,
      currentlyPlannedDate: null,
      comments: '',
      endDate: null,
      completed: false,
    });

    updateWeeklyReportData('milestones', milestones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addDiscussion = useCallback(() => {
    const { discussions } = formData.weeklyReportData;

    discussions.push({
      description: '',
      closedDate: null,
      initiatedDate: null,
      fromPreviousReport: false,
    });

    updateWeeklyReportData('discussions', discussions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addChangeRequest = useCallback(() => {
    const { changeRequests } = formData.weeklyReportData;
    const lastId = changeRequests.length === 0 ? -1 : changeRequests[changeRequests.length - 1]?.id;

    changeRequests.push({
      id: lastId + 1,
      description: '',
      status: ChangeRequestStatus.NEW,
      closedDate: null,
      createdDate: null,
      fromPreviousReport: false,
    });

    updateWeeklyReportData('changeRequests', changeRequests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addRisk = useCallback(() => {
    const { risks } = formData.weeklyReportData;
    const lastId = risks.length === 0 ? -1 : risks[risks.length - 1]?.id;

    risks.push({
      id: lastId + 1,
      description: '',
      impact: Impact.LOW,
      comments: '',
      createdDate: null,
      probability: Probability.LOW,
      fromPreviousReport: false,
    });

    updateWeeklyReportData('risks', risks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addCustomerDependency = useCallback(() => {
    const { customerDependencies } = formData.weeklyReportData;

    customerDependencies.push({
      description: '',
      name: '',
      closedDate: null,
      createdDate: null,
      waitingDate: null,
      fromPreviousReport: false,
    });

    updateWeeklyReportData('customerDependencies', customerDependencies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addDiscoveryScopeBasedTypesDataItem = useCallback(() => {
    const { discoveryScopeBasedTypesData } = formData.internalReportData;

    discoveryScopeBasedTypesData.push({
      periodFrom: null,
      periodTo: null,
      scopeCompletion: 0,
      actualBudget: 0,
      totalBudget: 0,
      budgetUsage: 0,
      budgetCpi: 0,
      actualWorkingDays: 0,
      totalWorkingDays: 0,
      timelineUsage: 0,
      timelineCpi: 0,
      comments: '',
      fromPreviousReport: false,
    });

    updateInternalReportData('discoveryScopeBasedTypesData', discoveryScopeBasedTypesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addAgileProductMaintenanceSupportTypesDataItem = useCallback(() => {
    const { agileProductMaintenanceSupportTypesData } = formData.internalReportData;

    agileProductMaintenanceSupportTypesData.push({
      periodFrom: null,
      periodTo: null,
      provisioned: 0,
      actual: 0,
      billable: 0,
      ba: 0,
      waste: 0,
      comments: '',
      fromPreviousReport: false,
    });

    updateInternalReportData('agileProductMaintenanceSupportTypesData', agileProductMaintenanceSupportTypesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const lastEditablePreviousInternalReportItemId = useMemo(() => {
    const { discoveryScopeBasedTypesData, agileProductMaintenanceSupportTypesData } = formData.internalReportData;

    let lastIndex = 0;
    if (discoveryScopeBasedTypesData.length > 0) {
      discoveryScopeBasedTypesData.forEach((item: AgileProductMaintenanceSupportType, id: number) => {
        if (item.fromPreviousReport) {
          lastIndex = id;
        }
      });
    } else if (agileProductMaintenanceSupportTypesData.length > 0) {
      agileProductMaintenanceSupportTypesData.forEach((item: AgileProductMaintenanceSupportType, id: number) => {
        if (item.fromPreviousReport) {
          lastIndex = id;
        }
      });
    }

    return lastIndex;
  }, [formData]);

  const onChangeProjectServiceModel = useCallback(
    (newFormData: FormFieldsData) => {
      const newValue = newFormData['internalReportData.projectServiceModel'];

      setFormData({
        ...formData,
        internalReportData: {
          ...formData.internalReportData,
          projectServiceModel: newValue,
          discoveryScopeBasedTypesData: [
            ProjectServiceModel.DISCOVERY,
            ProjectServiceModel.SCOPE_BASED_CUSTOM_SOFTWARE_DEVELOPMENT,
          ].includes(newValue)
            ? formData.internalReportData.discoveryScopeBasedTypesData
            : [],
          agileProductMaintenanceSupportTypesData: [
            ProjectServiceModel.AGILE_PRODUCT_DEVELOPMENT,
            ProjectServiceModel.MAINTENANCE_AND_SUPPORT,
          ].includes(newValue)
            ? formData.internalReportData.agileProductMaintenanceSupportTypesData
            : [],
        },
      });

      const newErrors: FormFieldsData = {};

      Object.keys(formErrors)
        .filter((key: string) => {
          return !key.includes('internalReportData');
        })
        .forEach((key: string) => {
          newErrors[key] = formErrors[key];
        });
      setFormErrors(newErrors);
    },
    [setFormData, formData, setFormErrors, formErrors],
  );

  return (
    <UpdatePageLayout
      title="Edit: Weekly Report"
      entityHeader={<ProjectEntityHeader />}
      menu={<ViewProjectPageMenu />}
      breadcrumbs={<UpdateProjectWeeklyReportBreadcrumbs />}
      buttons={<ProjectWeeklyReportFormButtonsLayout isSubmitEnabled={isSubmitEnabled} />}
    >
      <FormSection>
        <FormRow>
          <DateInput source="from" label="Cycle period from" maxDate={formData.to} disabled />
          <DateInput source="to" label="Cycle period to" minDate={formData.from} disabled />
        </FormRow>
      </FormSection>

      <FormSection isVisible={formData.status === ProjectWeeklyReportStatus.NEW}>
        <MediumFormRow>
          <Dropdown
            source="corporateModel"
            label="Do you use Syberry template for external weekly report?"
            isRequired
            options={[
              { value: UseCorporateModel.YES, label: UseCorporateModelLabel.YES },
              { value: UseCorporateModel.NO, label: UseCorporateModelLabel.NO },
            ]}
          />
        </MediumFormRow>
      </FormSection>

      {formData.corporateModel === UseCorporateModel.YES ? (
        <>
          <FormSection
            title="Executive summary"
            tooltipSource="weeklyReportData.executiveSummary"
            tooltipMessage={executiveSummaryTooltipMessage}
          >
            <BigFormRow>
              <TextArea
                source="weeklyReportData.executiveSummary"
                value={formData.weeklyReportData.executiveSummary}
                label=""
              />
            </BigFormRow>
          </FormSection>
          <FormSection
            title="List of achievements for the last week"
            subtitle="Should be at least one achievement"
            subtitleClass={isHighlightSection('achievements') ? 'required-section' : ''}
            tooltipSource="weeklyReportData.achievements"
            tooltipMessage={achievementsTooltipMessage}
          >
            {formData.weeklyReportData?.achievements?.map((achievement: AchievementType, id: number) => (
              <AchievementInputRow
                key={getAchievementSource(id)}
                data={achievement}
                id={id}
                formData={formData}
                updateFormData={updateWeeklyReportData}
              />
            ))}
            <AddListItemButton onClickCallback={addAchievement} />
          </FormSection>
          <FormSection
            title="List of items planned for the next week"
            subtitle="Should be at least one planned item"
            subtitleClass={isHighlightSection('plannedItems') ? 'required-section' : ''}
            tooltipSource="weeklyReportData.plannedItems"
            tooltipMessage={plannedItemsTooltipMessage}
          >
            {formData.weeklyReportData?.plannedItems?.map((plannedItem: PlannedItemType, id: number) => (
              <PlannedItemInputRow
                key={getPlannedItemsSource(id)}
                data={plannedItem}
                id={id}
                formData={formData}
                updateFormData={updateWeeklyReportData}
              />
            ))}
            <AddListItemButton onClickCallback={addPlannedItem} />
          </FormSection>
          <FormSection
            title="Key project milestones"
            subtitle="Should be at least one milestone"
            subtitleClass={isHighlightSection('milestones') ? 'required-section' : ''}
            tooltipSource="weeklyReportData.milestones"
            tooltipMessage={milestonesTooltipMessage}
            isLargeTooltip
          >
            {formData.weeklyReportData?.milestones?.map((milestone: MilestoneType, id: number) => {
              return (
                <MilestoneInputRow
                  key={getMilestonesSource(id)}
                  data={milestone}
                  id={id}
                  formData={formData}
                  updateFormData={updateWeeklyReportData}
                  getErrorMessages={getErrorMessages}
                />
              );
            })}
            <AddListItemButton onClickCallback={addMilestone} />
          </FormSection>
          <FormSection
            title="Potential change requests"
            tooltipSource="weeklyReportData.discussions"
            tooltipMessage={discussionsTooltipMessage}
            isLargeTooltip
          >
            {formData.weeklyReportData?.discussions?.map((discussion: DiscussionType, id: number) => {
              return (
                <DiscussionInputRow
                  key={getDiscussionSource(id)}
                  data={discussion}
                  id={id}
                  formData={formData}
                  updateFormData={updateWeeklyReportData}
                  getErrorMessages={getErrorMessages}
                />
              );
            })}
            <AddListItemButton onClickCallback={addDiscussion} />
          </FormSection>
          <FormSection
            title="Change requests"
            tooltipSource="weeklyReportData.changeRequests"
            tooltipMessage={changeRequestsTooltipMessage}
            isLargeTooltip
          >
            {formData.weeklyReportData?.changeRequests?.map((changeRequest: ChangeRequestType, id: number) => {
              return (
                <ChangeRequestInputRow
                  key={getChangeRequestSource(id)}
                  data={changeRequest}
                  id={id}
                  formData={formData}
                  updateFormData={updateWeeklyReportData}
                  getErrorMessages={getErrorMessages}
                />
              );
            })}
            <AddListItemButton onClickCallback={addChangeRequest} />
          </FormSection>
          <FormSection
            title="Risks"
            tooltipSource="weeklyReportData.risks"
            tooltipMessage={risksTooltipMessage}
            isLargeTooltip
          >
            {formData.weeklyReportData?.risks?.map((risk: RiskType, id: number) => {
              return (
                <RiskInputRow
                  key={getRiskSource(id)}
                  data={risk}
                  id={id}
                  formData={formData}
                  updateFormData={updateWeeklyReportData}
                  getErrorMessages={getErrorMessages}
                />
              );
            })}
            <AddListItemButton onClickCallback={addRisk} />
          </FormSection>
          <FormSection
            title="Customer's dependencies"
            tooltipSource="weeklyReportData.customerDependencies"
            tooltipMessage={customerDependenciesTooltipMessage}
            isLargeTooltip
          >
            {formData.weeklyReportData?.customerDependencies?.map(
              (customerDependency: CustomerDependencyType, id: number) => {
                return (
                  <CustomerDependencyInputRow
                    key={getCustomerDependencySource(id)}
                    data={customerDependency}
                    id={id}
                    formData={formData}
                    updateFormData={updateWeeklyReportData}
                    getErrorMessages={getErrorMessages}
                  />
                );
              },
            )}
            <AddListItemButton onClickCallback={addCustomerDependency} />
          </FormSection>
          <FormSection
            title="Customer's expectations"
            tooltipSource="weeklyReportData.customerExpectation"
            tooltipMessage={customerExpectationsTooltipMessage}
          >
            <BigFormRow>
              <TextArea source="weeklyReportData.customerExpectation" label="" />
            </BigFormRow>
          </FormSection>
          <FormSection
            title="Other notes"
            tooltipSource="weeklyReportData.otherNotes"
            tooltipMessage={otherNotesTooltipMessage}
          >
            <BigFormRow>
              <TextArea source="weeklyReportData.otherNotes" label="" />
            </BigFormRow>
          </FormSection>
        </>
      ) : (
        <FormSection
          title="Upload external weekly report"
          isVisible={formData.report === null}
          subtitle="Use this option only if you use custom report template. If you are using the Syberry template, fill out the digital form instead."
        >
          <FormRow>
            <ArtifactStorageUploaderInput
              source="reportArtifactId"
              label="Report"
              errorSource="reportFile"
              isRequired={formData.newStatus === ProjectWeeklyReportStatus.SUBMITTED}
            />
          </FormRow>
        </FormSection>
      )}
      <FormSection
        title="Internal data"
        tooltipSource="internalReportData"
        subtitle={getErrorMessages('internalReportData') || 'Required section'}
        subtitleClass={isHighlightSection('', ProjectReportType.INTERNAL_REPORT) ? 'required-section' : ''}
        tooltipMessage={internalReportDataTooltipMessage}
        isLargeTooltip
      >
        <MediumFormRow>
          <Dropdown
            placeholder="Choose the project service model"
            isRequired
            isVisible={formData.firstInternalReport}
            source="internalReportData.projectServiceModel"
            options={Object.entries(ProjectServiceModelLabels).map(entry => {
              const [value, label] = entry;
              return {
                value,
                label,
              };
            })}
            onChangeCallback={onChangeProjectServiceModel}
          />
        </MediumFormRow>
        {formData.internalReportData?.projectServiceModel === ProjectServiceModel.DISCOVERY ||
        formData.internalReportData?.projectServiceModel === ProjectServiceModel.SCOPE_BASED_CUSTOM_SOFTWARE_DEVELOPMENT
          ? formData.internalReportData?.discoveryScopeBasedTypesData?.map(
              (dataItem: DiscoveryScopeBasedType, id: number) => {
                return (
                  <DiscoveryScopeBasedTypesDataInputRow
                    key={getDiscoveryScopeBasedTypesItemSource(id)}
                    data={dataItem}
                    id={id}
                    isPeriodEditable={id === lastEditablePreviousInternalReportItemId}
                    formData={formData}
                    updateFormData={updateInternalReportData}
                    getErrorMessages={getErrorMessages}
                  />
                );
              },
            )
          : formData.internalReportData?.agileProductMaintenanceSupportTypesData?.map(
              (dataItem: AgileProductMaintenanceSupportType, id: number) => {
                return (
                  <AgileProductMaintenanceSupportTypesDataInputRow
                    key={getAgileProductMaintenanceSupportTypesItemSource(id)}
                    data={dataItem}
                    id={id}
                    isPeriodEditable={id === lastEditablePreviousInternalReportItemId}
                    formData={formData}
                    updateFormData={updateInternalReportData}
                    getErrorMessages={getErrorMessages}
                  />
                );
              },
            )}
        {formData.internalReportData?.projectServiceModel && (
          <AddListItemButton
            onClickCallback={
              formData.internalReportData?.projectServiceModel === ProjectServiceModel.DISCOVERY ||
              formData.internalReportData?.projectServiceModel ===
                ProjectServiceModel.SCOPE_BASED_CUSTOM_SOFTWARE_DEVELOPMENT
                ? addDiscoveryScopeBasedTypesDataItem
                : addAgileProductMaintenanceSupportTypesDataItem
            }
          />
        )}
      </FormSection>
    </UpdatePageLayout>
  );
};

export default ProjectWeeklyReportForm;
