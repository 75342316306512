import React from 'react';
import NameDescriptionInput from 'erp/recruitment/vacancy/shared/input/NameDescriptionInput';

interface VacancyPersonalityInputProps {
  source: string;
}

const VacancyPersonalityInput = ({ source, ...props }: VacancyPersonalityInputProps) => {
  return <NameDescriptionInput nameLabel="Trait" source={source} {...props} />;
};

export default VacancyPersonalityInput;
