import React from 'react';
import { BadgeField, FieldProps, TextField } from 'shared/uibuilder/field';
import IsOptionalBadge from './IsOptionalBadge';
import CompetencyAreaBadge from './CompetencyAreaBadge';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Area, LevelCompetency } from 'erp/qualification/model/qualificationModelService';
import { CompetencyLinkField } from 'erp/qualification/competency';

const QualificationModelCompetencyField = (props: FieldProps) => {
  const { source } = props;
  const { getData } = useFieldHelper({ source });
  const { areas, optional } = getData() as LevelCompetency;

  const value = <CompetencyLinkField {...props} source={source} />;

  const areaBadges = areas.map((area: Area) => <CompetencyAreaBadge area={area} />);

  return (
    <BadgeField badges={[...areaBadges, <IsOptionalBadge isVisible={optional} />]}>
      <TextField {...props} value={value} />
    </BadgeField>
  );
};

export default QualificationModelCompetencyField;
