import { useKernelApi } from 'api';

const useIdentityService = () => {
  const { sendPostRequest } = useKernelApi();

  const exchangeToken = async (token: string) => {
    const result = await sendPostRequest('/identity/tokens/exchange', {
      temporaryToken: token,
    });
    const json = await result.json();
    return json.token;
  };

  return {
    exchangeToken,
  };
};

export default useIdentityService;
