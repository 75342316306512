/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';
import OpportunityMenu from 'crm/opportunity/shared/OpportunityMenu';
import PresaleOpportunityTimelineBreadcrumbs from 'crm/opportunity/presale/Breadcrumbs';
import OpportunityEntityHeader from 'crm/opportunity/shared/OpportunityEntityHeader';
import usePresaleRequestItem from 'crm/opportunity/presale/request/presaleRequestRegistry';
import usePresaleTimelineService from 'crm/opportunity/presale/presaleTimelineService';
import usePresaleOutputItem from 'crm/opportunity/presale/output/presaleOutputRegistry';
import useContractRequestItem from './contract/contractRequestRegistry';

const PresaleOpportunityTimelinePage = () => {
  const opportunityId = useOpportunityId();
  const { getById } = useOpportunityService() as any;
  const { searchPresaleItems, getPresalePageById, createPresaleTimelineItem } = usePresaleTimelineService();
  const presaleRequestRegistry = usePresaleRequestItem() as any;
  const presaleOutputRegistry = usePresaleOutputItem() as any;
  const contractRequestRegistry = useContractRequestItem() as any;

  return (
    <Show getDataMethod={() => getById(opportunityId)}>
      <Timeline
        createTimelineItemMethod={(itemType, request) => createPresaleTimelineItem(itemType, opportunityId, request)}
        registries={[presaleRequestRegistry, presaleOutputRegistry, contractRequestRegistry]}
        getDataMethod={request => searchPresaleItems(opportunityId, request)}
        getPageByEntryIdMethod={id => getPresalePageById(opportunityId, id)}
      >
        <TimelinePageLayout
          menu={<OpportunityMenu />}
          breadcrumbs={<PresaleOpportunityTimelineBreadcrumbs />}
          entityHeader={<OpportunityEntityHeader />}
          title="Operations"
        >
          <TimelineLayout noEntriesMessage="No entries in this opportunity operations yet" />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default PresaleOpportunityTimelinePage;
