/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleVacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/shared/SingleVacancyPublicationBreadcrumbs';

const UpdateVacancyPublicationBreadcrumbs = () => {
  return (
    <SingleVacancyPublicationBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleVacancyPublicationBreadcrumbs>
  );
};

export default UpdateVacancyPublicationBreadcrumbs;
