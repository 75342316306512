/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField from 'shared/uibuilder/field/LinkField';
import { useLedgerUrl } from 'financialAnalytic/ledger/LedgerRouter';
import { FieldProps } from 'shared/uibuilder/field/index';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { updateCurrencyName } from 'shared/uibuilder/amountUtils';

const LedgerLink = ({
  source,
  nameSource,
  dateSource,
  ...props
}: FieldProps & { nameSource: string; dateSource?: string }) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getValue: getDate } = useFieldHelper({ source: dateSource });
  const { getSingleEntityUrl } = useLedgerUrl();
  const id = getValue()?.replace(/[/]/g, '_');
  const name = updateCurrencyName((getName() || getValue() || '').replace(/[/]/g, ' / '));
  const date = getDate();
  let filter = '';

  if (date) {
    const dateMonthStart = moment(date)
      .startOf('month')
      .format(DATE_FORMAT.API_ONLY_DATE);
    const dateMonthEnd = moment(date)
      .endOf('month')
      .format(DATE_FORMAT.API_ONLY_DATE);

    filter = `?filter={"period":{"ge":"${dateMonthStart}","le":"${dateMonthEnd}"}}`;
  }

  return <LinkField linkText={name} isInternal value={id ? `${getSingleEntityUrl(id)}${filter}` : null} {...props} />;
};

export default LedgerLink;
