// libs
import React from 'react';
import { string } from 'prop-types';
// components
import { TextField } from 'shared/uibuilder/field';
// services
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { getProbationReduction } from 'erp/employee/finance/shared/financeService';

const FinanceProbationReductionField = ({ probationAmountSource, baseAmountSource, ...props }) => {
  const { getValue: getProbationAmountValue } = useFieldHelper({ source: probationAmountSource });
  const { getValue: getBaseAmountValue } = useFieldHelper({ source: baseAmountSource });

  const getFieldValue = () => {
    const probationAmount = getProbationAmountValue();
    const baseAmount = getBaseAmountValue();

    return `${getProbationReduction(probationAmount, baseAmount)}%`;
  };

  return <TextField value={getFieldValue()} {...props} />;
};

FinanceProbationReductionField.propTypes = {
  baseAmountSource: string.isRequired,
  probationAmountSource: string.isRequired,
};

export default FinanceProbationReductionField;
