/* istanbul ignore file */
import React from 'react';
import { CurrencyInput } from 'shared/uibuilder/form/input';
import { CurrencyInputProps } from 'shared/uibuilder/form/input/currencyInputHelper';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getWithThousandsSeparator, convertCurrency } from 'shared/uibuilder/amountUtils';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { ErrorMessage } from 'uibuilder/layout/form/input/BaseInputErrors';
import { capitalize } from 'lodash';

interface TransferCurrencyInputProps extends Omit<CurrencyInputProps, 'onChangeCallback'> {
  currency: string;
  onChangeCallback: (values: any, additionalValues?: Dictionary<any>) => void;
}

const TransferCurrencyInput = ({
  source,
  currency,
  disabled,
  onChangeCallback,
  ...props
}: TransferCurrencyInputProps) => {
  const { formatDate, getToday } = useDateService();
  const today = getToday();
  const { getValue, getErrorMessages } = useInputHelper({
    source,
  });
  const key = source.substring(source.lastIndexOf('.') + 1);
  const { getValue: getExchangeRates } = useInputHelper({
    source: 'exchangeRates',
  });
  const exchangeRates = getExchangeRates() as any;
  const exchangeRate = exchangeRates?.[currency]?.USD || 1;
  const { date } = exchangeRates;
  const formattedDate = formatDate(date || today, DATE_FORMAT.FULL_DATE);

  const value = (getValue() as number) || 0;
  let helpText = '';

  if (value && currency && currency !== 'USD')
    helpText = `${getWithThousandsSeparator(value)}${CURRENCIES[currency] || ''} = ${getWithThousandsSeparator(
      convertCurrency(value, exchangeRate),
    )}$ (on ${formattedDate})`;

  if (value && currency && currency === 'USD')
    helpText = `${getWithThousandsSeparator(value)}$ = ${getWithThousandsSeparator(
      convertCurrency(value, exchangeRates?.PLN?.USD || 1, false),
    )}${CURRENCIES.PLN} or ${getWithThousandsSeparator(
      convertCurrency(value, exchangeRates?.BYN?.USD || exchangeRates?.BYR?.USD || 1, false),
    )}${CURRENCIES.BYN} (on ${formattedDate})`;

  const onChange = (values: any) => {
    onChangeCallback(values, {
      [`is${capitalize(key)}Changed`]: true,
    });
  };

  return (
    <CurrencyInput
      {...props}
      onChangeCallback={onChange}
      disabled={disabled}
      source={source}
      value={value}
      suffix={CURRENCIES[currency]}
      maxDecimal={2}
      helpText={helpText}
      isControlledValue
      isRequired
      errorMessages={disabled ? [] : (getErrorMessages() as ErrorMessage)}
    />
  );
};

export default TransferCurrencyInput;
