import { useResourceManagementApi } from 'api';
import useStorageService from 'shared/uibuilder/storageService';

export type LocationOptionsType = {
  id: string;
  name: string;
};

const useLocationService = () => {
  const { sendGetRequest } = useResourceManagementApi();

  const getLocations = async (): Promise<LocationOptionsType[]> => {
    const response = await sendGetRequest('/locations');
    return response.json();
  };

  const { getDataFromStorage } = useStorageService('locations', getLocations, '1.2');

  return {
    findAll: getDataFromStorage,
  };
};

export default useLocationService;
