/* istanbul ignore file */
import React, { useMemo } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import LeadsBreadcrumbs from 'crm/lead/shared/LeadsBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useLeadId, useLeadUrl } from 'crm/lead/LeadRouter';
import { element } from 'prop-types';

/**
 * Returns lead create pages breadcrumbs.
 * @returns {*}
 * @constructor
 */
const SingleLeadBreadcrumbs = ({ children }) => {
  const { data } = useShowContext();
  const firstName = data.getValueBySource('firstName');
  const lastName = data.getValueBySource('lastName');
  const middleName = data.getValueBySource('middleName');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedName = useMemo(() => `${firstName} ${middleName} ${lastName}`, []);

  const { getSingleEntityUrl } = useLeadUrl();
  const id = useLeadId();

  return (
    <LeadsBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getSingleEntityUrl(id)} />
      {children}
    </LeadsBreadcrumbs>
  );
};

SingleLeadBreadcrumbs.propTypes = {
  children: element,
};

SingleLeadBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleLeadBreadcrumbs;
