import { camelCase } from 'lodash';
import React from 'react';

export const EMPTY_VALUE_INDICATOR = '—';
export const NULL_AS_STRING = 'null_as_string';

const toWords = input => {
  let result = input.replace(/([A-Z])/g, ' $1');
  result = result.replace(/([0-9]+)/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
};

const isDefined = value => typeof value !== 'undefined' && value !== null;

const getLabel = (label, source) => {
  const parts = source && source.replace(/^_/, '').split('.');
  const isLabelDefined = isDefined(label);

  if (isLabelDefined) {
    return label;
  }
  if (source) {
    return toWords(parts[parts.length - 1]);
  }
  return null;
};

export const getValue = (value, getFieldValue, source, emptyValueIndicator = EMPTY_VALUE_INDICATOR) => {
  if (isDefined(value)) {
    return value;
  }
  const valueFromSource = getFieldValue && getFieldValue && getFieldValue(source);
  return valueFromSource || valueFromSource === 0 || valueFromSource === false ? valueFromSource : emptyValueIndicator;
};

export const isNotNullValue = value => !!value || value === 0;

export const getAnchor = label => {
  return camelCase(label);
};

export const getVisibility = (data = {}, initialValue) => {
  if (initialValue === null || initialValue === undefined) {
    return true;
  }

  if (typeof initialValue === 'function') {
    return initialValue(data);
  }

  return initialValue;
};

export default getLabel;

export const isMobile = () =>
  navigator.userAgent.match(/Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i);

export const isIOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i);

export const joinElements = (array, delimiter) => {
  return array.map((element, index) => {
    return (
      <React.Fragment key={element.key}>
        {element}
        {index < array.length - 1 ? delimiter : null}
      </React.Fragment>
    );
  });
};

export const isUrl = string => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(string);
};

export const mapExtensions = extensions =>
  extensions &&
  extensions
    .split(',')
    .map(ext => `.${ext}`)
    .join(',');

export const getDifferenceBetweenTwoStrings = (string1, string2) => {
  if (typeof string1 !== 'string' || typeof string2 !== 'string') {
    return null;
  }

  const editDistance = (s1Input, s2Input) => {
    const s1 = s1Input.toLowerCase();
    const s2 = s2Input.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i += 1) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j += 1) {
        if (i === 0) {
          costs[j] = j;
        } else if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  };

  const similarity = (s1, s2) => {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }

    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }

    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  };

  return similarity(string1, string2);
};

/**
 * Takes object with key that consists of multiple parts
 * e.g. one.two.three, and returns object:
 * {
 *   three: value
 * }
 * @param value
 * @returns {{}}
 */
export const getLastSource = value => {
  const fullSource = Object.keys(value)[0];
  const key = fullSource.substring(fullSource.lastIndexOf('.') + 1);

  return {
    [key]: value[fullSource],
  };
};

export const isSafari = () => !!navigator.userAgent.match(/Version\/[\d]+.*Safari/);

export const getCapitalizedString = string => string.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
