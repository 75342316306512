/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { UpdateForm } from 'shared/uibuilder/form/index';
import SettingsForm from 'user/Settings/createupdate/Update/SettingsForm';
import UpdateSettingsLayout from 'user/Settings/createupdate/Update/UpdateSettingsLayout';
import useSettingsService from 'user/Settings/settingsService';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

const UserSettings = () => {
  const [userAlias, setUserAlias] = useState<Nullable<string>>(null);
  const { getUserAlias } = useAuthenticatedUserService();
  const { getSettings, updateSettings, getValidationSchema } = useSettingsService();

  useEffect(() => {
    (async () => {
      const alias = await getUserAlias();
      setUserAlias(alias);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UpdateForm
      getDataFunc={() => getSettings(userAlias)}
      submitFormFunc={(id: any, data: any) => updateSettings(userAlias, data)}
      getValidationSchemaFunc={getValidationSchema}
      isNeedToHandleErrorsOnSubmit={false}
      afterSubmit={{
        message: 'You are awesome! The Settings data has been successfully saved.',
      }}
    >
      <UpdateSettingsLayout>
        <SettingsForm />
      </UpdateSettingsLayout>
    </UpdateForm>
  );
};

export default UserSettings;
