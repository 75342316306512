/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import JournalEntryBreadcrumbs from 'financialAnalytic/journalEntry/shared/JournalEntryBreadcrumbs';

const CreateLedgerBreadcrumbs = () => {
  return (
    <JournalEntryBreadcrumbs>
      <CreateBreadcrumbItem entity="Journal Entry" />
    </JournalEntryBreadcrumbs>
  );
};

export default CreateLedgerBreadcrumbs;
