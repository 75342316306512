/* istanbul ignore file */
// libs
import React from 'react';
import { element } from 'prop-types';
// components
import Show from 'shared/uibuilder/show/Show';
// services
import { useLeadId } from 'crm/lead/LeadRouter';
import useLeadService from 'crm/lead/shared/leadService';

const ShowLead = ({ children }) => {
  const id = useLeadId();
  const { getById, visit } = useLeadService();

  return (
    <Show
      getDataMethod={() => {
        visit(id);
        return getById(id);
      }}
    >
      {children}
    </Show>
  );
};

ShowLead.propTypes = {
  children: element.isRequired,
};

export default ShowLead;
