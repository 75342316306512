/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import OneStopPageLayout from 'uibuilder/layout/form/OneStopPageLayout';
import BudgetReallocationForm from 'erp/budgetreallocation/createupdate/BudgetReallocationForm';
import useBudgetReallocationService from 'erp/budgetreallocation/createupdate/budgetReallocationService';

export interface OneStopBudgetReallocationProps {
  openModal: (message: string) => void;
}

const OneStopBudgetReallocation = ({ openModal }: OneStopBudgetReallocationProps) => {
  const { getOneStopValidationSchema, createBudgetReallocation } = useBudgetReallocationService();

  return (
    <CreateForm
      submitFormFunc={createBudgetReallocation}
      afterSubmit={{
        execute: response => {
          openModal(response.message);
        },
      }}
      getValidationSchemaFunc={getOneStopValidationSchema}
    >
      <OneStopPageLayout>
        <BudgetReallocationForm />
      </OneStopPageLayout>
    </CreateForm>
  );
};

export default OneStopBudgetReallocation;
