/* istanbul ignore file */
import React from 'react';
import { useEmployeeSalaryId, useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import useEmployeeSalaryService, { SALARY_TYPE_OPTIONS } from 'erp/employee/salaries/shared/salaryService';
import { SensitiveShowPageLayout } from 'shared/uibuilder/sensitiveData';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import ShowSingleBreadcrumbs from 'erp/employee/salaries/Show/ShowSingleBreadcrumbs';
import { DELETE, UPDATE } from 'crm/crmService';
import EditSalaryButton from 'erp/employee/salaries/shared/buttons/EditSalaryButton';
import DeleteSalaryButton from 'erp/employee/salaries/shared/buttons/DeleteSalaryButton';
import Show from 'shared/uibuilder/show/Show';
import TextField from 'shared/uibuilder/field/TextField';
import { CapitalizedField, EnumField, HtmlField } from 'shared/uibuilder/field';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import EmployeeQualificationDetailsListField from 'erp/employee/salaries/shared/fields/EmployeeQualificationDetailsListField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import EmployeePerformanceDetailsFields from 'erp/employee/salaries/shared/fields/EmployeePerformanceDetailsFields';
import DateField from 'shared/uibuilder/field/DateField';
import EmployeeSalaryLinkField from 'erp/employee/salaries/shared/fields/EmployeeSalaryLinkField';
import CopyToClipboardButton from 'shared/uibuilder/CopyToClipboardButton';

const ViewEmployeeSalary = () => {
  const employeeSalaryId = useEmployeeSalaryId();
  const { getById } = useEmployeeSalaryService();
  const { getListUrl, getSingleEntityUrl } = useEmployeeSalaryUrl();
  const { href } = window.location;

  return (
    <SensitiveShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<ShowSingleBreadcrumbs />}
      buttons={[
        <EditSalaryButton key="edit-salary" icon={<i className="fa fa-edit" />} permissionToCheck={UPDATE} />,
        <DeleteSalaryButton key="delete-salary" permissionToCheck={DELETE} />,
        <CopyToClipboardButton className="ml-2" id={href} value={href} isIcon={false}>
          Copy link
        </CopyToClipboardButton>,
      ]}
      timerId={getListUrl()}
    >
      <Show getDataMethod={() => getById(employeeSalaryId)}>
        <ShowSection title="View: Employee Salary">
          <SectionRow>
            <EnumField options={SALARY_TYPE_OPTIONS} source="type" label="Type" />
            <CapitalizedField source="baseAmountType" label="Base Amount Type" />
            <TextField source="currency" label="Currency" />
            <TextField source="grossAmount" label="Base Amount Gross" />
            <DateField source="relatedProcess.periodStartDate" label="Review Period From" />
            <DateField source="relatedProcess.periodEndDate" label="Review Period To" />
            <EmployeeSalaryLinkField
              label="Related Salaries"
              source="relatedSalaryIds"
              urlFunction={getSingleEntityUrl}
            />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Employee Qualification" isVisible={data => !data.getValueBySource('modelBased')}>
          <BigSectionRow>
            <HtmlField source="qualificationDescription" label="Achievement and Goal Summary" />
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Employee Qualification" isVisible={data => data.getValueBySource('modelBased')}>
          <TextField source="specializationDetails.title" label="Specialization" />
          <FieldListLayout source="specializationDetails.qualifications" hasSeparator>
            <EmployeeQualificationDetailsListField />
          </FieldListLayout>
        </ShowSection>
        <ShowSection title="Employee Performance">
          <EmployeePerformanceDetailsFields source="performanceDetails" />
        </ShowSection>
      </Show>
    </SensitiveShowPageLayout>
  );
};

export default ViewEmployeeSalary;
