/* istanbul ignore file */
// TODO: Add tests after integration with BE
import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import './ScrollStepper.scss';

interface ScrollStepperProps {
  children: React.ReactNode;
  columnWidth: number;
  columnGap: number;
  containerSelector: string;
  columnSelector: string;
  scrollOffset?: number;
  className?: string;
}

const ScrollStepper = ({
  children,
  columnWidth,
  columnGap,
  containerSelector,
  columnSelector,
  scrollOffset = 0,
  className,
}: ScrollStepperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [stepWidth, setStepWidth] = useState(0);
  const [numberOfSteps, setNumberOfSteps] = useState(0);
  const [scrollableContainer, setScrollableContainer] = useState<Nullable<Element>>(null);

  const updateStepperData = () => {
    const container = ref?.current?.querySelector(containerSelector);
    const columns = ref?.current?.querySelectorAll(columnSelector);

    if (!container || !ref?.current || !columns?.length) {
      return;
    }

    const containerWidth = ref.current.getBoundingClientRect().width;
    const columnsInStep = columnWidth > containerWidth ? 1 : Math.floor((containerWidth + columnGap) / columnWidth);
    const step = columnsInStep * columnWidth;

    setStepWidth(step);
    setNumberOfSteps(Math.ceil(columns.length / columnsInStep));
    setScrollableContainer(container);
  };

  useEffect(() => {
    updateStepperData();

    if (!ref?.current) {
      return () => {};
    }

    const resizeObserver = new ResizeObserver(updateStepperData);
    resizeObserver.observe(ref?.current);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current]);

  const updateActiveStep = () => {
    if (!scrollableContainer || !ref?.current) {
      return;
    }

    let currentStep = Math.floor((scrollableContainer.scrollLeft + columnGap - scrollOffset) / stepWidth);
    const lastStepOffset = scrollableContainer.scrollWidth - ref.current.getBoundingClientRect().width;

    if (scrollableContainer.scrollLeft + columnGap - scrollOffset >= lastStepOffset) {
      currentStep = numberOfSteps - 1;
    }

    setActiveStep(currentStep);
  };

  useEffect(() => {
    scrollableContainer?.addEventListener('scroll', updateActiveStep);

    return () => {
      scrollableContainer?.removeEventListener('scroll', updateActiveStep);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableContainer, stepWidth, numberOfSteps]);

  useEffect(() => {
    updateActiveStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSteps]);

  const scrollContainer = (offset: number) => {
    scrollableContainer?.scrollTo({
      left: offset,
    });
  };

  const handleNext = () => {
    scrollContainer((activeStep + 1) * stepWidth);
  };

  const handleBack = () => {
    scrollContainer((activeStep - 1) * stepWidth);
  };

  return (
    <div ref={ref} className={classnames('scroll-stepper__wrapper', className)}>
      {numberOfSteps > 1 ? (
        <MobileStepper
          className="scroll-stepper__control"
          variant="text"
          steps={numberOfSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === numberOfSteps - 1}>
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </Button>
          }
        />
      ) : null}
      {children}
    </div>
  );
};

export default ScrollStepper;
