/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Box from 'uibuilder/Box';
import Message from 'shared/message/Message';
import Loading from 'shared/uibuilder/Loading';
import OneStopSettings from 'oneStop/List/Settings';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useDeviceDetectorService from 'oneStop/deviceDetectorService';
import SearchWithKeyboardNavigation from 'oneStop/List/SearchWithKeyboardNavigation';

interface OneStopListPageLayoutProps {
  header: React.ReactChild;
  filter: React.ReactChild;
  requestsList: React.ReactChild;
}

export const MIN_THRESHOLD = 1;

const OneStopListPageLayout = ({ header, filter, requestsList }: OneStopListPageLayoutProps) => {
  const { loading, search: searchString = '' } = useListContext();
  const { isDesktop } = useDeviceDetectorService();
  const isSearchMode = searchString.length >= MIN_THRESHOLD;

  return (
    <Paper className="page-content" elevation={0}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {header}
        <OneStopSettings />
      </Box>
      <div className="alerts__wrapper">
        <Message />
      </div>

      <SearchWithKeyboardNavigation isSearchMode={isSearchMode} />
      {loading ? (
        <Loading />
      ) : (
        !isSearchMode && (
          <div className="onestop-categories-container">
            {filter}
            {isDesktop && requestsList}
          </div>
        )
      )}
    </Paper>
  );
};

export default OneStopListPageLayout;
