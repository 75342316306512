/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import useProcessInstanceUpdatingService from 'camunda/monitoring/instance/Show/shared/processInstanceUpdatingService';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import TerminateProcessForm from 'camunda/monitoring/instance/Show/shared/TerminateProcessInstanceForm';

const MESSAGE_CONTEXT = 'TerminateProcessModalForm';

interface TerminateProcessModalFormProps {
  closeModal: () => void;
}

const TerminateProcessModalForm = ({ closeModal }: TerminateProcessModalFormProps) => {
  const processInstanceId = useProcessInstanceId();
  const { getSingleEntityUrl } = useProcessInstanceUrl();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { terminate, getValidationSchemaToTerminate } = useProcessInstanceUpdatingService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Terminate process: {processInstanceId}
    </Typography>
  );

  return (
    <CreateForm
      submitFormFunc={terminate}
      messageContext={MESSAGE_CONTEXT}
      getValidationSchemaFunc={getValidationSchemaToTerminate}
      initialData={{
        instanceId: processInstanceId,
        reason: null,
      }}
      afterSubmit={{
        redirect: getSingleEntityUrl(processInstanceId),
        message: 'You are awesome! The Process Instance ID has been successfully terminated.',
      }}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <TerminateProcessForm handleCancelClick={closeForm} />
      </ModalWindow>
    </CreateForm>
  );
};

export default TerminateProcessModalForm;
