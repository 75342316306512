/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useVacancyPublicationService from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';

const VacancyPublicationContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useVacancyPublicationService();
  const CONTEXT_NAME = 'VACANCY_PUBLICATION_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default VacancyPublicationContext;
