/* istanbul ignore file */
export default {
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  ownerId: {
    type: 'string',
    required: true,
  },
  stateStep: {
    type: 'enum',
    required: true,
    allowedTransitions: {
      value: {
        '': ['NEW'],
        NEW: ['PRESALE', 'CLOSED_LOST'],
        PRESALE: ['PROPOSAL', 'POSTPONED', 'CLOSED_LOST'],
        PROPOSAL: ['CONTRACT', 'POSTPONED', 'CLOSED_LOST'],
        CONTRACT: ['SIGNING', 'POSTPONED', 'CLOSED_LOST'],
        SIGNING: ['STARTING', 'POSTPONED', 'CLOSED_LOST'],
        STARTING: ['CLOSED_WON', 'POSTPONED', 'CLOSED_LOST'],
        CLOSED_WON: [],
        CLOSED_LOST: [],
        POSTPONED: [],
      },
    },
  },
  closeDate: {
    type: 'date',
    required: true,
    minDate: {
      value: '$YESTERDAY',
      message: 'Please choose date not earlier than yesterday',
      when: {
        $stateStep: {
          in: ['NEW', 'PRESALE', 'PROPOSAL', 'CONTRACT', 'SIGNING', 'STARTING', 'POSTPONED'],
        },
      },
    },
  },
  postponedUntil: {
    type: 'date',
    required: {
      value: true,
      when: {
        $stateStep: {
          eq: 'POSTPONED',
        },
      },
    },
    minDate: {
      value: '$TODAY',
      message: 'Must be in future',
    },
    maxDate: {
      value: '$closeDate',
      message: "Postponed date can't be later than close date",
    },
  },
  accountName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'EXISTING',
        },
        $hasAccountCodeName: {
          eq: false,
        },
      },
    },
  },
  originalAccountName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $accountType: {
          ne: 'EXISTING',
        },
      },
    },
  },
  accountId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'EXISTING',
        },
      },
      message: 'Please select value from result list',
    },
  },
  amount: {
    type: 'string',
    required: true,
  },
  nextStep: {
    type: 'string',
    maxSize: 255,
  },
  nextStepDeadline: {
    type: 'date',
  },
  description: {
    type: 'text',
    maxSize: 65000,
  },
  attachmentsIds: {
    type: 'array',
    maxSize: 15,
  },
  preSaleInputAttachmentsIds: {
    type: 'array',
    maxSize: 15,
  },
  preSaleOutputAttachmentsIds: {
    type: 'array',
    maxSize: 15,
  },
  isContractRenewal: {
    type: 'enum',
    required: true,
  },
  isAfterDiscovery: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $_IS_CREATE_REQUEST: {
          eq: true,
        },
        $isContractRenewal: {
          eq: 'NO',
        },
        $accountType: {
          eq: 'EXISTING',
        },
        $accountId: {
          ne: null,
        },
      },
    },
  },
  discoveryOpportunityId: {
    type: 'int',
    required: {
      value: true,
      when: {
        $_IS_CREATE_REQUEST: {
          eq: true,
        },
        $isAfterDiscovery: {
          eq: 'YES',
        },
      },
    },
  },

  closedReason: {
    type: 'object',
    required: {
      value: true,
      when: {
        $stateStep: {
          in: ['CLOSED_WON', 'CLOSED_LOST'],
        },
        '$_FEATURE[opportunityClosedReason]': {
          eq: true,
        },
      },
    },
    _divisible: false,
    properties: {
      primaryReason: {
        type: 'enum',
        required: {
          value: true,
          when: {
            '$_FEATURE[opportunityClosedReason]': {
              eq: true,
            },
            $stateStep: {
              in: ['CLOSED_WON', 'CLOSED_LOST'],
            },
          },
        },
      },
      secondaryReason: {
        type: 'enum',
        required: {
          value: true,
          when: {
            '$_FEATURE[opportunityClosedReason]': {
              eq: true,
            },
            $stateStep: {
              in: ['CLOSED_WON', 'CLOSED_LOST'],
            },
            '$closedReason.primaryReason': {
              in: ['IN_HOUSE_IMPLEMENTATION', 'ANOTHER_VENDOR'],
            },
          },
        },
      },
      summary: {
        type: 'text',
        maxSize: 60000,
        required: {
          value: true,
          when: {
            '$_FEATURE[opportunityClosedReason]': {
              eq: true,
            },
            $stateStep: {
              in: ['CLOSED_WON', 'CLOSED_LOST'],
            },
          },
        },
      },
    },
  },
};
