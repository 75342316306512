import React from 'react';
import { CREATE_WASTE, READ_WASTE, READ_WASTE_LIST, WASTE_PATH } from 'erp/costaccounting/waste/useWasteService';
import { Switch, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import WasteList from './List';
import CreateWastePage from './createupdate/Create';
import WasteViewPage from './show/WasteViewPage';

export const useWasteRoute = () => {
  return useRoute({
    listRoute: WASTE_PATH,
  });
};

export const useWasteId = () => useParams<Dictionary<string>>().id;

const WasteRouter = () => {
  const { listRoute, createRoute, singleEntityRoute } = useWasteRoute();

  return (
    <ProtectedRouter basePath={WASTE_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_WASTE_LIST}>
          <WasteList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_WASTE}>
          <CreateWastePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_WASTE}>
          <WasteViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

WasteRouter.getRouterPath = () => WASTE_PATH;

export default WasteRouter;
