/* istanbul ignore file */
import React, { useEffect } from 'react';
import useForm from 'shared/uibuilder/form/formHelper';
import presaleOutputValidation from 'crm/opportunity/presale/output/form/presaleOutputValidation';
import { isEqual } from 'lodash';
import { FormProvider } from 'shared/uibuilder/form/FormContext';
import BigFormRow from 'shared/layout/form/BigFormRow';
import RequiredResourcesListInput from 'crm/opportunity/presale/output/shared/input/RequiredResourcesListInput';
import RequiredResourcesInputTemplate from 'crm/opportunity/presale/output/shared/input/RequiredResourcesInputTemplate';
import FormSection from 'shared/layout/form/FormSection';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import FormRow from 'shared/layout/form/FormRow';

const PresaleOutputInputs = ({
  setFormErrors,
  setFormData,
  formData,
  formErrors,
}: {
  setFormErrors: (errors: Dictionary<string[]>) => void;
  setFormData: (data: Dictionary<any>) => void;
  formData: Nullable<Dictionary<any>>;
  formErrors: Dictionary<string[]>;
}) => {
  const contextValue = useForm({
    initialData: formData === null ? undefined : formData,
    submitFormFunc: () => Promise.resolve({}),
    getValidationSchemaFunc: () => Promise.resolve(presaleOutputValidation),
  });

  useEffect(() => {
    if (!isEqual(contextValue.formErrors, formErrors)) {
      setFormErrors(contextValue.formErrors);
    }
    // Suppressed warnings because we only need to call useEffect callback if contextValue.formErrors is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue.formErrors]);

  useEffect(() => {
    if (!isEqual(contextValue.data, formData)) {
      setFormData(contextValue.data);
    }
    // Suppressed warnings because we only need to call useEffect callback if contextValue.data is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue.data]);

  return (
    <FormProvider value={contextValue}>
      <FormSection title="Last Presale Output Info">
        <FormRow>
          <DateInput source="expectedProjectStart" label="Expected Start" />
        </FormRow>
      </FormSection>
      <FormSection title="Team structure">
        <BigFormRow>
          <RequiredResourcesListInput
            source="requiredResources"
            addText="Add Member"
            maxFields={30}
            inputTemplate={RequiredResourcesInputTemplate}
          />
        </BigFormRow>
      </FormSection>
    </FormProvider>
  );
};

export default PresaleOutputInputs;
