/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form/index';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import useInvoiceService from 'financialAnalytic/invoice/useInvoiceService';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import UpdateInvoiceBreadcrumbs from 'financialAnalytic/invoice/createupdate/Update/Breadcrumbs';
import FormSection from 'shared/layout/form/FormSection';
import { TextInput } from 'shared/uibuilder/form/input';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormRow from 'shared/layout/form/FormRow';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import IsFinalChangesToggle from 'financialAnalytic/invoice/shared/input/IsFinalChanfesToggle';

const UpdateInvoicePage = () => {
  const { getUpdateValidationSchema, getById, update } = useInvoiceService();
  const { getSingleEntityUrl } = useInvoiceUrl();

  const getDataFunc = async (id: string) => {
    const data = await getById(id);
    const hasInvoiceDocument = !!data.invoiceDocumentId;

    return {
      invoiceDocumentId: hasInvoiceDocument ? [data.invoiceDocumentId] : null,
      invoiceNumber: data.invoiceNumber,
      isFinalChanges: data.isFinalChanges,
      hasInvoiceDocument,
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Invoice has been successfully updated.',
      }}
      getValidationSchemaFunc={getUpdateValidationSchema}
    >
      <UpdatePageLayout breadcrumbs={<UpdateInvoiceBreadcrumbs />} title="Edit: Invoice Document">
        <FormSection>
          <FormRow>
            <TextInput source="invoiceNumber" label="Invoice Number" />
          </FormRow>
          <BigFormRow>
            <NoteAttachmentUploaderInput label="Invoice Document" source="invoiceDocumentId" />
            <IsFinalChangesToggle source="isFinalChanges" />
          </BigFormRow>
        </FormSection>
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateInvoicePage;
