/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import { useTrainingInstancesUrl } from '../TrainingInstancesRouter';

const TrainingInstancesBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useTrainingInstancesUrl();
  const trainingsListLink = getListUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Training instances" link={trainingsListLink} />
      {children}
    </Breadcrumbs>
  );
};

export default TrainingInstancesBreadcrumbs;
