import React, { useEffect, useState } from 'react';
import { isMobile } from 'shared/uibuilder/helper';

export enum FocusTarget {
  INPUT = 'input',
  RESULTS = 'results',
}

type FocusHelperProps = {
  search: string;
  searchResultsRefs: HTMLAnchorElement[];
  searchInputRef: React.RefObject<HTMLInputElement>;
  onFocusCallback?: (searchInputRef: React.RefObject<HTMLInputElement>) => void;
};

const useFocusHelperService = ({ search, searchInputRef, searchResultsRefs, onFocusCallback }: FocusHelperProps) => {
  const [searchFocusTarget, setSearchFocusTarget] = useState<Nullable<FocusTarget>>(null);
  const [focusedResultIndex, setFocusedResultIndex] = useState<Nullable<Number>>(null);

  const setFocusToItem = (index: number) => {
    searchResultsRefs[index].focus();
    setFocusedResultIndex(index);
  };

  useEffect(() => {
    const isInputTarget = searchFocusTarget === FocusTarget.INPUT;
    const isResultsTarget = searchFocusTarget === FocusTarget.RESULTS;

    if (isInputTarget) {
      setFocusedResultIndex(null);

      if (!isMobile() && search && searchInputRef.current) {
        searchInputRef.current.focus();
      }

      if (onFocusCallback) {
        onFocusCallback(searchInputRef);
      }
    }

    if (isResultsTarget && searchResultsRefs && searchResultsRefs[0]) {
      setFocusToItem(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFocusTarget]);

  const blurResultsItem = (index: number) => searchResultsRefs[index].blur();

  const setFocusToSearchInput = () => {
    const isSearchInputTarget = searchFocusTarget === FocusTarget.INPUT;

    if (!isSearchInputTarget) setSearchFocusTarget(FocusTarget.INPUT);
  };

  const setFocusToSearchResults = () => {
    const isResultsTarget = searchFocusTarget === FocusTarget.RESULTS;

    if (!isResultsTarget) setSearchFocusTarget(FocusTarget.RESULTS);
  };

  return {
    setFocusToItem,
    blurResultsItem,
    focusedResultIndex,
    setFocusedResultIndex,
    setFocusToSearchInput,
    setFocusToSearchResults,
  };
};

export default useFocusHelperService;
