/* istanbul ignore file */
import React from 'react';
import BigFormRow from 'shared/layout/form/BigFormRow';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import TimelineNoteWysiwygInput from 'erp/shared/input/TimelineNoteWysiwygInput';

const NoteForm = () => (
  <>
    <BigFormRow>
      <TimelineNoteWysiwygInput artifactsSource="attachmentsIds" source="noteText" />
    </BigFormRow>
    <BigFormRow>
      <NoteAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
    </BigFormRow>
  </>
);

export default NoteForm;
