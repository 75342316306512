/* istanbul ignore file */
// removed from test coverage because it will be need to refactor

// libs
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
// components
import LeadContext from 'crm/lead/LeadContext';
import ViewLead from 'crm/lead/Show';
import { CreateLead, UpdateLead } from 'crm/lead/createupdate';
import Page404 from 'shared/uibuilder/Page404';
import LeadListPage from 'crm/lead/List';
import TimelinePage from 'crm/lead/Timeline';
import ShowLead from 'crm/lead/shared/ShowLead';
import ConvertLeadPage from 'crm/lead/Convert';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
// services
import { READ_TIMELINE, UPDATE, READ } from 'crm/crmService';
import { CREATE_LEAD, READ_LEADS_LIST, CONVERT_LEAD } from 'crm/lead/shared/leadService';
import useEntityUrl from 'shared/routing/useEntityUrl';
import useRoute from 'shared/routing/useRoute';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';

export const LEADS_PATH = '/crm/leads';

export const useLeadRoute = () => {
  const baseRoutes = useRoute({ listRoute: LEADS_PATH });

  return {
    ...baseRoutes,
    convertRoute: `${baseRoutes.singleEntityRoute}/convert`,
    timelineRoute: `${baseRoutes.singleEntityRoute}/activities/:timelineEntryId?`,
  };
};

export const useLeadUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: LEADS_PATH,
    timelinePostfix: 'activities',
  });

  const getConvertUrl = (id: string) => `${baseUrls.getSingleEntityUrl(id)}/convert`;

  return {
    ...baseUrls,
    getConvertUrl,
  };
};

export const useLeadId = () => useParams<Dictionary<string>>().id;

const LeadRouter = () => {
  const { listRoute, createRoute, updateRoute, convertRoute, timelineRoute, singleEntityRoute } = useLeadRoute();

  return (
    <ProtectedRouter basePath={LEADS_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_LEADS_LIST}>
          <LeadListPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_LEAD}>
          <CreateLead />
        </GlobalProtectedRoute>
        <Route path={singleEntityRoute} exact>
          <LeadContext permissionToCheck={READ}>
            <ViewLead />
          </LeadContext>
        </Route>
        <Route path={updateRoute} exact>
          <LeadContext permissionToCheck={UPDATE}>
            <UpdateLead />
          </LeadContext>
        </Route>
        <Route path={timelineRoute} exact>
          <LeadContext permissionToCheck={READ_TIMELINE}>
            <TimelinePage />
          </LeadContext>
        </Route>
        <Route path={convertRoute} exact>
          <LeadContext permissionToCheck={CONVERT_LEAD}>
            <ShowLead>
              <ConvertLeadPage />
            </ShowLead>
          </LeadContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

LeadRouter.getRouterPath = () => LEADS_PATH;

export default LeadRouter;
