import React, { useCallback, useState } from 'react';
import Tooltip from 'uibuilder/Tooltip';
import useArtifactPreviewHelper from 'artifact/shared/artifactPreviewHelper';
import { API_FILE_TYPE } from 'artifact/const';
import { func, shape, bool, element } from 'prop-types';
import InputLoader from 'shared/uibuilder/InputLoader';
import FileName from './FileName';

export const getArtifactsIcon = type => {
  const artifactType = type.toLowerCase();

  return API_FILE_TYPE[artifactType] ? API_FILE_TYPE[artifactType].category.icon : API_FILE_TYPE.default.category.icon;
};

const ArtifactPreviewWrapper = ({ artifact, handleOpenPreview, handleDownload, hasIcon = true, customFileName }) => {
  const { id, hasPreview, type, title } = artifact;
  const { isPreviewAvailable } = useArtifactPreviewHelper();
  const previewAvailable = isPreviewAvailable(type);
  const elementId = `view-preview-button-${id}`;
  const isShowTooltip = previewAvailable && !hasPreview;
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    if (previewAvailable) {
      if (hasPreview && !loading) {
        setLoading(true);
        await handleOpenPreview();
        setLoading(false);
      }
    } else {
      handleDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, hasPreview, previewAvailable]);

  return (
    <Tooltip title={isShowTooltip ? 'Preview is not yet ready, please wait' : ''} placement="right" id={elementId}>
      <button key={id} type="button" onClick={handleClick} id={elementId} data-testid="artifact-open-preview">
        {hasIcon && (
          <span className="files-list__files-icon">
            <img src={getArtifactsIcon(type)} alt="" title={title} data-testid="artifact-doc" />
          </span>
        )}
        {customFileName || <FileName title={title} />}
        {loading ? <InputLoader className="files-list__files-loader" /> : null}
      </button>
    </Tooltip>
  );
};

ArtifactPreviewWrapper.propTypes = {
  artifact: shape().isRequired,
  handleOpenPreview: func.isRequired,
  handleDownload: func.isRequired,
  hasIcon: bool,
  customFileName: element,
};

ArtifactPreviewWrapper.defaultProps = {
  hasIcon: true,
  customFileName: undefined,
};

export default ArtifactPreviewWrapper;
