/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { DELETE_EMPLOYEE_DOMAIN, useEmployeeDomainsService } from 'erp/employee/domains/shared/employeeDomainsService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';

const DeleteEmployeeDomainButton = ({ source, ...props }: any) => {
  const { isGranted } = useAuthorization();
  const { deleteEmployeeDomain } = useEmployeeDomainsService();
  const { getValue } = useFieldHelper({ source });
  const id = getValue();

  if (!isGranted(DELETE_EMPLOYEE_DOMAIN)) {
    return null;
  }

  return (
    <DeleteButton
      deleteMethod={() => deleteEmployeeDomain(id)}
      displayMessage="Do you really want to delete this employee domain experience?"
      afterDelete={{
        successMessage: 'The Employee Domain experience has been successfully deleted.',
      }}
      permissionToCheck={DELETE_EMPLOYEE_DOMAIN}
    />
  );
};

export default DeleteEmployeeDomainButton;
