/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { DEFAULT_INPUT_PROP_TYPES } from 'shared/uibuilder/form/input/inputHelper';
import { CANDIDATE_STATUS } from 'erp/candidate/shared/field/CandidateStatusField';

const CandidateStatusDropdown = props => {
  return (
    <Dropdown
      placeholder="Choose Candidate Status"
      options={Object.entries(CANDIDATE_STATUS).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label="Candidate Status"
      {...props}
    />
  );
};

CandidateStatusDropdown.propTypes = DEFAULT_INPUT_PROP_TYPES;

export default CandidateStatusDropdown;
