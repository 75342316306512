import React from 'react';
import CustomerInvoiceBreadcrumbs from '../../shared/CustomerInvoiceBreadcrumbs';
import UpdateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateCustomerInvoiceBreadcrumbs = () => {
  return (
    <CustomerInvoiceBreadcrumbs>
      <UpdateBreadcrumbItem entity="Customer invoice" />
    </CustomerInvoiceBreadcrumbs>
  );
};

export default UpdateCustomerInvoiceBreadcrumbs;
