import React from 'react';
import { useRoomUrl } from 'erp/room/RoomRouter';
import EditButton from 'uibuilder/button/EditButton';
import { UPDATE_ROOM } from 'erp/room/useRoomService';

const EditRoomButton = (props: any) => {
  const { getUpdateEntityUrl } = useRoomUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} permissionToCheck={UPDATE_ROOM} />;
};

export default EditRoomButton;
