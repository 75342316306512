/* istanbul ignore file */
import React from 'react';
import { LEAD_POSTPONED_REASON } from 'crm/lead/shared/leadService';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';

interface PostponedReasonDropdownProps {
  source: string;
}

const PostponedReasonDropdown = ({ source, ...props }: PostponedReasonDropdownProps) => {
  return (
    <Dropdown
      source={source}
      placeholder="Choose Postponed Reason"
      options={Object.entries(LEAD_POSTPONED_REASON).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label="Postponed Reason"
      {...props}
    />
  );
};

export default PostponedReasonDropdown;
