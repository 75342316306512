/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  CREATE_PROJECT,
  PROJECT_PATH,
  READ_PROJECT,
  READ_PROJECTS_LIST,
  UPDATE_PROJECT,
} from 'erp/project/shared/projectService';
import ProjectList from 'erp/project/List';
import { CreateProject, UpdateProject } from 'erp/project/createupdate';
import ViewProject from 'erp/project/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import ProjectStatusesList from './statuses/statusList';
import { READ_PROJECT_STATUS_LIST } from './statuses/ProjectStatusesService';
import ProjectContext from './ProjectContext';
import { READ_PROJECT_REPORTS_LIST } from './weeklyreports/ProjectWeeklyReportService';
import ShowProject from './statuses/shared/ShowProject';
import ProjectWeeklyReportRouter from './weeklyreports/ProjectWeeklyReportRouter';
import { READ_PROJECT_DASHBOARD } from './dashboard/ProjectDashboardService';
import ProjectDashboardRouter from './dashboard/ProjectDashboardRouter';
import { CREATE_REDMINE_PROJECT } from './redmineproject/redmineProjectService';
import CreateRedmineProject from './redmineproject/createupdate/Create';
import ProjectsWBSDashboardList from './ProjectsWBSDashboard/ProjectsWBSDashboardList';
import { READ_SOW } from './sow/useSowService';
import SowRouter from './sow/SowRouter';

export const useProjectRoute = () => {
  const routers = useRoute({ listRoute: PROJECT_PATH, timelinePostfix: 'statuses' });
  const getSingleProjectStatusUrl = (projectId: string, statusId: string) =>
    `projects/${projectId}/statuses/${statusId}`;

  return {
    ...routers,
    weeklyReportRoute: `${routers.singleEntityRoute}/reports`,
    dashboardRoute: `${routers.singleEntityRoute}/dashboard`,
    redmineProjectRoute: `${routers.singleEntityRoute}/redmine-project`,
    wbsDashboardProjectRoute: `${routers.singleEntityRoute}/wbs-dashboard`,
    sowRoute: `${routers.singleEntityRoute}/sow`,
    getSingleProjectStatusUrl,
  };
};

export const useProjectUrl = () => {
  return useEntityUrl({
    baseLink: PROJECT_PATH,
  });
};

export const useProjectId = () => {
  const { id } = useParams<Dictionary<string>>();

  return id;
};

const ProjectRouter = () => {
  const {
    listRoute,
    singleEntityRoute,
    createRoute,
    updateRoute,
    weeklyReportRoute,
    dashboardRoute,
    timelineRoute,
    redmineProjectRoute,
    wbsDashboardProjectRoute,
    sowRoute,
  } = useProjectRoute();

  return (
    <ProtectedRouter basePath={PROJECT_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_PROJECTS_LIST}>
          <ProjectList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_PROJECT}>
          <CreateProject />
        </GlobalProtectedRoute>
        <Route path={singleEntityRoute} exact>
          <ProjectContext permissionToCheck={READ_PROJECT}>
            <ViewProject />
          </ProjectContext>
        </Route>
        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={UPDATE_PROJECT}>
          <UpdateProject />
        </GlobalProtectedRoute>
        <Route path={timelineRoute} exact>
          <ProjectContext permissionToCheck={READ_PROJECT_STATUS_LIST}>
            <ProjectStatusesList />
          </ProjectContext>
        </Route>
        <Route path={weeklyReportRoute}>
          <ProjectContext permissionToCheck={READ_PROJECT_REPORTS_LIST}>
            <ShowProject>
              <ProjectWeeklyReportRouter />
            </ShowProject>
          </ProjectContext>
        </Route>
        <Route path={dashboardRoute}>
          <ProjectContext permissionToCheck={READ_PROJECT_DASHBOARD}>
            <ShowProject>
              <ProjectDashboardRouter />
            </ShowProject>
          </ProjectContext>
        </Route>
        <Route path={redmineProjectRoute}>
          <ProjectContext permissionToCheck={CREATE_REDMINE_PROJECT}>
            <CreateRedmineProject />
          </ProjectContext>
        </Route>

        {/* TODO Use READ_WBS permission when BE is ready */}
        <Route path={wbsDashboardProjectRoute}>
          <ProjectContext permissionToCheck={READ_PROJECT_DASHBOARD}>
            <ProjectsWBSDashboardList />
          </ProjectContext>
        </Route>

        <Route path={sowRoute}>
          <ProjectContext permissionToCheck={READ_SOW}>
            <SowRouter />
          </ProjectContext>
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

ProjectRouter.getRouterPath = () => PROJECT_PATH;

export default ProjectRouter;
