import React from 'react';
import { DefaultInputPropTypes, SearchInput } from 'shared/uibuilder/form/input';
import useProjectService from 'erp/project/shared/projectService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { SearchInputProps } from 'shared/uibuilder/form/input/SearchInput';

export interface ProjectInputProps extends Omit<SearchInputProps, 'searchRequest'>, DefaultInputPropTypes<string> {
  source: string;
  label?: string;
  initialLabel?: string;
  searchString?: string;
  nameSource?: string;
  mapResults?: (project: any) => void;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;
  props?: any;
}

const ProjectInput = ({ mapResults, source, ...props }: ProjectInputProps) => {
  const { search } = useProjectService();

  const mapResponse = (project: any) => ({
    id: project.id,
    text: project.name,
  });

  return (
    <SearchInput
      {...props}
      source={source}
      searchRequest={search}
      noResultMessage="No Projects are found."
      mapResults={mapResults || mapResponse}
    />
  );
};

export default ProjectInput;
