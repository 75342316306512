import { useState } from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { ChangeRequestType } from './ChangeRequestInputRow';
import { MilestoneType } from './MilestoneInputRow';
import { RiskType } from './RiskInputRow';

export interface InputRowWithSpecificIdProps {
  data: ChangeRequestType | MilestoneType | RiskType;
  source: string;
  formData: FormFieldsData;
  id: number;
  updateFormData: (source: string, newFormData: any) => void;
  listSource: string;
}

const useInputRowWithSpecificId = ({
  data,
  source,
  formData,
  id,
  updateFormData,
  listSource,
}: InputRowWithSpecificIdProps) => {
  const [rowData, setRowData] = useState(data);

  const updateField = (
    newFormData: FormFieldsData,
    fieldName: string,
    getAdditionalFields: (newFormData: FormFieldsData, fieldName: string) => Dictionary<any>,
  ) => {
    if (fieldName === 'id' && !validateIdUpdate(newFormData[`${source}.${fieldName}`])) {
      return;
    }

    const additionalFields = getAdditionalFields(newFormData, fieldName);

    setRowData({
      ...rowData,
      [`${fieldName}`]: newFormData[`${source}.${fieldName}`],
      ...additionalFields,
    });

    let listData = formData.weeklyReportData[listSource];
    listData[id] = {
      ...rowData,
      [`${fieldName}`]: newFormData[`${source}.${fieldName}`],
      ...additionalFields,
    };

    if (formData.firstReport) {
      let firstId: number = listData.length === 0 ? -1 : +listData[0].id - 1;

      listData = [
        ...listData.map((fieldData: any) => {
          firstId += 1;
          return {
            ...fieldData,
            id: firstId,
          };
        }),
      ];
    }

    updateFormData(listSource, listData);
  };

  const validateIdUpdate = (idData: string) => {
    return idData.match(/^([0-9]\d*)$/);
  };

  const removeRow = () => {
    const listData = formData.weeklyReportData[listSource];
    listData.splice(id, 1);

    let firstId: number = -1;
    if (formData.firstReport && id === 0) {
      firstId = rowData.id - 1;
    } else if (listData.length === 0) {
      firstId = -1;
    } else {
      firstId = listData[0].id - 1;
    }

    updateFormData(listSource, [
      ...listData.map((fieldData: any) => {
        if (fieldData.fromPreviousReport) {
          firstId = fieldData.id;
          return fieldData;
        }

        firstId += 1;
        return {
          ...fieldData,
          id: firstId,
        };
      }),
    ]);
  };

  const getOnChangeCallback = (
    fieldSource: string,
    getAdditionalFields?: (newFormData: FormFieldsData, fieldName: string) => Dictionary<any>,
  ) => (newFormData: FormFieldsData) => {
    updateField(newFormData, fieldSource, getAdditionalFields || (() => ({})));
  };

  return {
    getOnChangeCallback,
    rowData,
    removeRow,
  };
};

export default useInputRowWithSpecificId;
