/* istanbul ignore file */
import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import useExpenseService, {
  MARK_PERFORMANCE_BASED_BONUS_AS_PAID,
  formatBonusData,
} from 'financialAnalytic/expenses/useExpenseService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { DateInput, TextInput, CurrencyInput } from 'shared/uibuilder/form/input';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { getVisibility } from 'shared/uibuilder/helper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useExpenseId } from 'financialAnalytic/expenses/ExpenseRouter';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

export type MarkAsPaidExpenseButtonProps = {
  isVisible: boolean | ((data: any) => boolean);
};

const MarkAsPaidBonusButton = ({ isVisible }: MarkAsPaidExpenseButtonProps) => {
  const id = useExpenseId();
  const { markBonusAsPaid, invalidateCache } = useExpenseService();
  const [payoutDateErrors, setPayoutDateErrors] = useState<string[]>([]);
  const [actualBonusSizeErrors, setActualBonusSizeErrors] = useState<string[]>([]);
  const { getToday, formatDateForAPI } = useDateService();
  const [payoutDate, setPayoutDate] = useState(getToday());
  const [bankTransactionId, setBankTransactionId] = useState<string>('');
  const [actualBonusSize, setActualBonusSize] = useState<string | number>('');
  const { data, setData = () => {} } = useShowContext();
  const compensationAmount = get(data, 'compensation.amount', 0);
  const compensationCurrency = get(data, 'compensation.currency', 0);

  if (!getVisibility(data, isVisible)) {
    return null;
  }

  const onPayoutDateChange = (values: FormFieldsData) => {
    setPayoutDate(values.payoutDate);
    const isValid = moment(values.payoutDate, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.payoutDate) {
      setPayoutDateErrors(['Field is required']);
    } else if (!isValid) {
      setPayoutDateErrors(['Please, choose the correct date']);
    } else {
      setPayoutDateErrors([]);
    }
  };

  const onActualBonusSizeChange = (values: FormFieldsData) => {
    setActualBonusSize(values.actualBonusSize);

    if (!values.actualBonusSize) {
      setActualBonusSizeErrors(['Field is required']);
    } else if (+values.actualBonusSize > compensationAmount) {
      setActualBonusSizeErrors(['Please, enter value less that compensation amount']);
    } else {
      setActualBonusSizeErrors([]);
    }
  };

  const onBankTransactionIdCallback = (values: FormFieldsData) => {
    setBankTransactionId(values.bankTransactionId);
  };

  return (
    <ButtonWithConfirmation
      displayMessage={`Do you really want to mark this expense (id: ${id}) as paid? Please fill out the inputs below.`}
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="payoutDate"
            value={payoutDate as any}
            label="Payout date"
            onChangeCallback={onPayoutDateChange}
            errorMessages={payoutDateErrors}
            isRequired
          />
          <CurrencyInput
            source="actualBonusSize"
            label="Actual Bonus Size"
            value={actualBonusSize}
            onChangeCallback={onActualBonusSizeChange}
            maxDecimal={2}
            suffix={compensationCurrency ? CURRENCIES[compensationCurrency] : ''}
            errorMessages={actualBonusSizeErrors}
            isRequired
          />
          <TextInput
            source="bankTransactionId"
            label="Bank Transaction ID"
            value={bankTransactionId}
            onChangeCallback={onBankTransactionIdCallback}
          />
        </div>
      }
      submitMethod={() => markBonusAsPaid(id, formatDateForAPI(payoutDate), actualBonusSize, bankTransactionId)}
      afterSubmit={{
        successMessage: `The Expense has been successfully marked as paid.`,
        errorMessage: `Can't mark expense as paid.`,
        execute: (newExpenseData: any) => {
          invalidateCache();

          if (newExpenseData) {
            setData(formatBonusData(newExpenseData));
          }
        },
      }}
      permissionToCheck={MARK_PERFORMANCE_BASED_BONUS_AS_PAID}
      variant="outlined"
      confirmBtnProps={{
        disabled: !!payoutDateErrors.length || !payoutDate || !!actualBonusSizeErrors.length || !actualBonusSize,
      }}
    >
      Mark as paid
    </ButtonWithConfirmation>
  );
};

export default MarkAsPaidBonusButton;
