/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import {
  CandidateApplicationStatus,
  CandidateApplicationStatusOptions,
} from 'erp/candidate/applications/shared/candidateApplicationService';
import { FieldProps } from 'shared/uibuilder/field/index';

const CandidateApplicationStatusBadge = ({ value: inputValue, source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || getValue();

  const getColor = () => {
    return value === CandidateApplicationStatus.IN_PROCESSING ? 'success' : 'neutralLight';
  };

  return <CustomBadge text={CandidateApplicationStatusOptions[value]} color={getColor()} {...props} />;
};

export default CandidateApplicationStatusBadge;
