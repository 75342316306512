/* istanbul ignore file */
import React from 'react';
import useCareerDevelopmentReviewService from 'erp/careerdevelopment/shared/careerDevelopmentReviewService';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';

interface CareerDevelopmentReviewData {
  periodStartDate: string;
  periodEndDate: string;
}

const CareerDevelopmentReviewDropdown = (props: any) => {
  const { search } = useCareerDevelopmentReviewService();
  const { formatDate } = useDateService();

  const loadReviews = async () => {
    return search({
      sort: {
        periodEndDate: 'DESC',
      },
      pageSize: 10,
      pageNumber: 0,
    });
  };

  const getLabel = (reviewData: CareerDevelopmentReviewData) => {
    const periodStart = formatDate(reviewData?.periodStartDate, DATE_FORMAT.SIMPLE);
    const periodEnd = formatDate(reviewData?.periodEndDate, DATE_FORMAT.SIMPLE);

    return `${periodStart} - ${periodEnd}`;
  };

  return (
    <LoadDropdown
      loadDataMethod={loadReviews}
      mapResults={review => ({ value: review.id, label: getLabel(review) })}
      showLoader
      placeholder="Select period"
      {...props}
    />
  );
};

export default CareerDevelopmentReviewDropdown;
