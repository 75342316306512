import React from 'react';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useVacancyService, { VACANCY_STATUSES } from 'erp/recruitment/vacancy/shared/vacancyService';

const divideVacanciesByStatus = (vacancies: any[] = []) => {
  return Object.entries(VACANCY_STATUSES).reduce(
    (acc, [key, label]) => ({ ...acc, [label]: vacancies.filter(vacancy => vacancy.status === key) }),
    {},
  );
};

export const formatDataForMultiSelectInput = (data?: any[]) => {
  if (!data) {
    return [];
  }

  return data.map(item => ({
    value: item.alias ? `${item.alias} ${item.name}` : null,
    label: item.alias ? `${item.alias} ${item.name}` : item.name,
    isDisabled: !item.alias,
  }));
};

const VacancyInput = ({ additionalMapData, ...props }: any) => {
  const { searchByNameAndId } = useVacancyService();
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);

  const parentOnChange = getRawOnChangeCallback() || (() => {});

  const loadSuggestionsMethod = async (params: any) => {
    let data = await searchByNameAndId(params);
    if (additionalMapData) {
      data = additionalMapData(data);
    }

    const groups: any = divideVacanciesByStatus(data);

    return [VACANCY_STATUSES.OPEN, VACANCY_STATUSES.DRAFT, VACANCY_STATUSES.CLOSED, VACANCY_STATUSES.REJECTED].reduce(
      (acc: any[], status) => {
        if (groups[status].length) {
          acc.push({ name: status }, ...groups[status]);
        }

        return acc;
      },
      [],
    );
  };

  const onChangeCallback = (obj: Dictionary<any>) => {
    const mappedArray = obj[getSource()] || [];

    parentOnChange({
      [getSource()]: mappedArray.map((item: any) => item.value),
    });
  };

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) =>
    inputValue.length ? 'Required vacancy is not found.' : 'Start typing the name or alias of the vacancy.';

  const value = (getValue() as any) || [];

  const mappedValue = value.map((item: any) => ({ value: item, label: item }));

  return (
    <MultiSelectInput
      {...props}
      onChangeCallback={onChangeCallback}
      mapResults={formatDataForMultiSelectInput}
      value={mappedValue}
      noOptionsMessage={noOptionsMessage}
      loadSuggestionsMethod={loadSuggestionsMethod}
    />
  );
};

export default VacancyInput;
