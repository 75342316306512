import React from 'react';
import PropTypes from 'prop-types';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { AddressInput, PhoneInput, TextInput } from '../input';
import { selectors } from 'shared/uibuilder/form/__integration-tests__/selectors';
import EntityHeaderTmpl from 'shared/layout/EntityHeader';

export const EntityHeader = () => {
  return <EntityHeaderTmpl entityTitleSource="fullName" hasAuthor />;
};

export const EntityForm = ({ hasAnchorLink }) => {
  return (
    <>
      <FormSection title="Main info" hasAnchorLink={hasAnchorLink}>
        <FormRow>
          <TextInput source="name" />
          <TextInput source="lastName" />
          <TextInput source="email" />
          <PhoneInput source="phone" />
        </FormRow>
      </FormSection>

      <FormSection title="Address" hasAnchorLink={hasAnchorLink}>
        <AddressInput source="address" />
      </FormSection>

      <FormSection title="User Private Info" isVisible={data => data?.address?.country === 'Afghanistan'}>
        <FormRow id={selectors.formRowId}>
          <TextInput source="password" isVisible={data => data?.address?.city === 'Minsk'} />
          <TextInput source="secret" />
        </FormRow>
      </FormSection>
    </>
  );
};

EntityForm.propTypes = {
  hasAnchorLink: PropTypes.bool,
};

EntityForm.defaultProps = {
  hasAnchorLink: false,
};

export default EntityForm;
