/* istanbul ignore file */
import React, { useContext } from 'react';

export interface AppSidebarContextData {
  mobileOpen?: boolean;
  handleDrawerToggle?: () => void;
}

const AppSidebarContext = React.createContext<Partial<AppSidebarContextData>>({});

export const AppSidebarProvider = AppSidebarContext.Provider;

export const useAppSidebarContext = () => useContext(AppSidebarContext);

export default AppSidebarContext;
