// services
import { useKernelApi } from 'api';

// constants
export const DOMAIN_CREATE = 'DOMAIN_CREATE';
export const DOMAIN_READ_LIST = 'DOMAIN_READ_LIST';

export const useDomainsService = () => {
  const { sendPostRequest } = useKernelApi();

  const findAllDomains = async (request: any) => {
    const response = await sendPostRequest('/domains/search', request);
    const json = await response.json();

    return json;
  };

  const createDomain = async (request: any) => {
    const response = await sendPostRequest('/domains', request);
    const json = await response.json();

    return json;
  };

  return {
    createDomain,
    findAllDomains,
  };
};
