/* istanbul ignore file */
import React from 'react';
// styles
import './EvaluationCriterionInput.scss';
import FormRow from 'shared/layout/form/FormRow';
import TimelineFeedbackWysiwygInput from 'erp/employee/shared/input/TimelineFeedbackWysiwygInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import FeedbackAttachmentUploaderInput from 'artifact/shared/input/FeedbackAttachmentUploaderInput';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import Checkbox from 'shared/uibuilder/form/input/Checkbox';

export interface EvaluationCriteriaProps {
  value: string;
  label: string;
  isPageForm?: boolean;
  index: number;
  lastIndex: number;
}

const FIELD_SOURCE = 'feedbackGrades';

const Div = ({ children, className }: { children: any; className?: string }) => (
  <div className={className}>{children}</div>
);

const ShortFormRow = ({ children, className }: { children: any; className?: string }) => (
  <FormRow colMd={6} colXl={4} className={className}>
    {children}
  </FormRow>
);

const EvaluationCriterionInput = ({ value, label, isPageForm, index, lastIndex }: EvaluationCriteriaProps) => {
  const sourceName = `${FIELD_SOURCE}.${value}`;
  const data = useInputHelper({ source: sourceName });
  const DropdownWrapper = isPageForm ? ShortFormRow : Div;
  const TimelineFeedbackWysiwygInputWrapper = isPageForm ? MediumFormRow : Div;
  const grade = data.getValue() as any;
  const isScaleSelected = !!grade?.scale;
  const showSeparator = index < lastIndex;
  const separatorStyle = isScaleSelected
    ? 'evaluation-criterion-form-separator-expanded'
    : 'evaluation-criterion-form-separator';

  const getDescriptionTooltip = () => {
    switch (value) {
      case 'ADHERING_COMPANY_CULTURE':
        return "Syberry's culture is to be helpful: choosing the project's priorities over personal benefit, critically reviewing the inputs, and being respectful and interested in the project.";
      case 'CONTRIBUTING_PROCESS_IMPROVEMENT':
        return 'Contributing to process improvement means contributing towards fixing any process issue on the project or company level, for example, reporting the issue to the Tech Lead or DaVinci.';
      case 'FOLLOWING_PROCESS':
        return 'Following the processes means following the time reporting policy, task management policy, security policy, and all project policies.';
      case 'PRODUCTIVITY':
        return 'Productivity is high when the colleague is predictable, follows team agreements, and quickly responds to messages and tickets.';
      case 'WORK_QUALITY':
        return "The work quality is high when, for example, the developer's code has few defects, they document their work, and their communication is clear and timely.";
      default:
        return "Tell us about your colleague's work in detail. Refer to any evidence you have";
    }
  };

  return (
    <div className={isScaleSelected ? 'mb-4' : ''}>
      <DropdownWrapper className="evaluation-criterion-form">
        <Checkbox
          errorSource="employeeFeedbackGrades"
          label={`${label}`}
          source={`${sourceName}.scale`}
          tooltip={getDescriptionTooltip()}
          defaultValue={false}
          disableLabelClick
        />
      </DropdownWrapper>
      {isScaleSelected ? (
        <div>
          <TimelineFeedbackWysiwygInputWrapper>
            <ClearAfterUnmountWrapper>
              <TimelineFeedbackWysiwygInput
                artifactsSource={`${sourceName}.attachmentsIds`}
                source={`${sourceName}.description`}
                label="Feedback and references"
                tooltip="Write down detailed feedback about your colleague's work. The feedback will be shared with the Resource Manager, so please give them context and objective references, e.g. documents and ticket numbers."
              />
            </ClearAfterUnmountWrapper>
          </TimelineFeedbackWysiwygInputWrapper>
          <ClearAfterUnmountWrapper>
            <FeedbackAttachmentUploaderInput
              initializeValue={[]}
              label="Attach evidence for your feedback"
              source={`${sourceName}.attachmentsIds`}
            />
          </ClearAfterUnmountWrapper>
        </div>
      ) : null}
      {showSeparator ? <hr className={separatorStyle} /> : null}
    </div>
  );
};
export default EvaluationCriterionInput;
