/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { EXPENSE_SUBTYPES, SUBTYPE_FIELD_LABELS } from 'financialAnalytic/expenses/useExpenseService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';

const ExpenseSubTypeDropdown = ({
  typeSource,
  source,
  ...props
}: DefaultInputPropTypes<string> & { typeSource: string }) => {
  const { getRawOnChangeCallback, getValue } = useInputHelper({ source });
  const { getValue: getType } = useInputHelper({ source: typeSource });
  const type = getType() as any;
  const options = EXPENSE_SUBTYPES[type];
  const rawOnChangeCallback = getRawOnChangeCallback();
  const value = getValue();
  const label = SUBTYPE_FIELD_LABELS[type];

  useDidUpdateEffect(() => {
    if (value && rawOnChangeCallback) {
      rawOnChangeCallback({ [source]: null });
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <EnumDropdown options={options || {}} {...props} source={source} label={label} placeholder={`Select ${label}`} />
  );
};

export default ExpenseSubTypeDropdown;
