import React from 'react';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { WASTE_TYPE_OPTIONS } from '../../useWasteService';

const WasteTypeDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={WASTE_TYPE_OPTIONS} label="Type" {...props} />;
};

export default WasteTypeDropdown;
