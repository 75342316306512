import React from 'react';
import TextField from './TextField';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useShowContext } from '../show/ShowContext';
import SanitizedHtml from '../../security/SanitizedHtml';
import { FieldProps } from 'shared/uibuilder/field';
import { get } from 'lodash';

export const escapeRegex = (str: string) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export const highlightSearchString: (rawText: string, searchString: string, strict?: boolean) => string = (
  rawText = '',
  searchString,
  strict = false,
) => {
  const text = `${rawText}`;
  try {
    const search = strict ? [searchString] : searchString.split(' ');
    const searchWords = search.map(str => `${escapeRegex(str)}`);
    const regex = new RegExp(searchWords.join('|'), 'ig');
    return text.replace(regex, '<b>$&</b>');
  } catch (error) {
    return text;
  }
};

type SearchValue = string | number | ((data: Dictionary<string>) => string);

export const getValue = (
  data: Dictionary<string | ((data: Dictionary<string>) => string)>,
  key: string,
  initialValue?: SearchValue,
) => {
  let value = initialValue || get(data, key);

  if (typeof value === 'function') {
    value = value(data as Dictionary<string>);
  }

  return value as string;
};

export interface SearchResultFieldProps extends FieldProps<SearchValue> {
  value?: SearchValue;
  source?: string;
  strict?: boolean;
}

const SearchResultField = ({ source, value: initialValue, strict, ...props }: SearchResultFieldProps) => {
  const { data } = useShowContext();
  const { search } = useListContext();

  const value = getValue(data.getData(), source || '', initialValue);

  let result: string | number | React.ReactElement = value;

  if (search) {
    result = <SanitizedHtml html={highlightSearchString(value, search, strict)} wrapperTag="span" />;
  }

  return <TextField {...props} value={result} />;
};

SearchResultField.defaultProps = {
  source: null,
  strict: false,
};

export default SearchResultField;
