/* istanbul ignore file */
import React, { useRef, useState, useEffect } from 'react';
import { Button, IconButton } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import {
  OPPORTUNITY_STAGES,
  isCurrentOpportunityStage,
  isCompletedOpportunityStage,
  isFailedOpportunityStage,
} from 'crm/opportunity/shared/opportunityService';
import classnames from 'classnames';
import Scrollbars from 'uibuilder/Scrollbars';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import './OpportunityPipelineStepper.scss';

const SCROLL_OFFSET = 135;

const OpportunityPipelineStepper = ({
  activeStep,
  handleStep,
  steps,
  isPostponed,
}: {
  activeStep: string;
  handleStep: (step: string) => void;
  steps: Dictionary<any>[];
  isPostponed: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollableContainer, setScrollableContainer] = useState<any>(null);

  const updateContainerData = () => {
    const newScrollableContainer = ref.current?.querySelector('.opportunity-steps .simplebar-content-wrapper');

    setScrollableContainer(newScrollableContainer);
    setIsScrollable(
      (newScrollableContainer?.scrollWidth || 0) > (newScrollableContainer?.getBoundingClientRect()?.width || 0),
    );
  };

  useEffect(() => {
    updateContainerData();

    window.addEventListener('resize', updateContainerData);

    return () => {
      window.removeEventListener('resize', updateContainerData);
    };
  }, [ref]);

  useEffect(() => {
    ref.current
      ?.querySelector('.stepper_step--current')
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }, []);

  const scrollToLeft = () => {
    scrollableContainer?.scrollTo({
      left: (scrollableContainer?.scrollLeft || 0) - SCROLL_OFFSET,
    });
  };

  const scrollToRight = () => {
    scrollableContainer?.scrollTo({
      left: (scrollableContainer?.scrollLeft || 0) + SCROLL_OFFSET,
    });
  };

  return (
    <div ref={ref} className="opportunity-stepper">
      {isScrollable ? (
        <IconButton className="opportunity-steps__control" onClick={scrollToLeft} disableRipple>
          <ChevronLeftIcon />
        </IconButton>
      ) : null}
      <Box sx={{ width: '100%', maxWidth: isScrollable ? 'calc(100% - 50px)' : '100%' }}>
        <Scrollbars className="opportunity-steps">
          <div className="steps__wrapper">
            {steps.map(({ stepName, stateStatus }) => (
              <Button
                onClick={() => handleStep(stepName)}
                className={classnames('stepper_step', {
                  'stepper_step--completed': isCompletedOpportunityStage(stateStatus),
                  'stepper_step--failed': isFailedOpportunityStage(stateStatus),
                  'stepper_step--current': isCurrentOpportunityStage(stateStatus) && !isPostponed,
                  'stepper_step--active': stepName === activeStep,
                  'stepper_step--postponed': isCurrentOpportunityStage(stateStatus) && isPostponed,
                })}
                disableRipple
                sx={{
                  width: `calc(100% / ${steps.length})`,
                }}
              >
                <span className="stepper_step-label">
                  {OPPORTUNITY_STAGES[stepName as keyof typeof OPPORTUNITY_STAGES]}
                  {isCompletedOpportunityStage(stateStatus) ? <CheckIcon /> : null}
                  {isCurrentOpportunityStage(stateStatus) && isPostponed ? <PauseCircleOutlineIcon /> : null}
                </span>
              </Button>
            ))}
          </div>
        </Scrollbars>
      </Box>
      {isScrollable ? (
        <IconButton className="opportunity-steps__control" onClick={scrollToRight} disableRipple>
          <ChevronLeftIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export default OpportunityPipelineStepper;
