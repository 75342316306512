/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { PURCHASE_ORDER_TYPES_OPTIONS } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';

const PurchaseOrderTypeDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={PURCHASE_ORDER_TYPES_OPTIONS} label="Type" placeholder="Select type" {...props} />;
};

export default PurchaseOrderTypeDropdown;
