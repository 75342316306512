/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import { Button } from 'uibuilder/button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useViewModeToggle from 'shared/uibuilder/ViewModeToggle/useViewModeToggle';
import './index.scss';

interface ViewModeToggleProps {
  icons: Dictionary<React.ReactNode>;
  modes: Dictionary<string>;
  onModeChange?: (mode: any) => void;
  cacheName: string;
  defaultMode: string;
  className?: string;
  initialMode?: string;
}

const ViewModeToggle = ({ icons, modes, className, ...props }: ViewModeToggleProps) => {
  const { activeMode, updateActiveMode } = useViewModeToggle(props);

  return (
    <ButtonGroup className={classnames('view-mode-toggle', className)}>
      {Object.entries(modes).map(([key, value]) => (
        <Button
          key={key}
          color="primary"
          className={activeMode === value ? 'active' : ''}
          onClick={(e: any) => {
            e.target.blur();

            updateActiveMode(value);
          }}
          startIcon={icons[value]}
        >
          {value}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ViewModeToggle;
