/* istanbul ignore file */
import React, { useEffect } from 'react';
import Show from 'shared/uibuilder/show/Show';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';
import ViewCustomerInvoicePageContent from './ViewCustomerInvoicePageContent';
import useCustomerInvoiceService from '../useCustomerInvoiceService';
import { useCustomerInvoiceId, useCustomerInvoiceUrl } from '../CustomerInvoicesRouter';

const CustomerInvoiceViewPage = () => {
  const { getById, getSensitiveDataByIds, invalidateCache } = useCustomerInvoiceService();
  const id = useCustomerInvoiceId();
  const { getListUrl } = useCustomerInvoiceUrl();

  useEffect(() => {
    return () => {
      invalidateCache();
    };

    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout
        loadSensitiveDataMethod={() => getSensitiveDataByIds([id])}
        sensitiveDataId={id}
        sensitiveDataIdSource="id"
        timerId={getListUrl()}
      >
        <ViewCustomerInvoicePageContent />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default CustomerInvoiceViewPage;
