import { EMPLOYEES_PATH, useEmployeeId } from 'erp/employee/EmployeesRouter';
import employeePerformanceValidation from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/employeePerformanceValidation';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

export const EMPLOYEE_PERFORMANCE_URL = '/performanceData';

const mapToSimpleDetails = (data: any) => {
  return data.performanceCriteria ? data.performanceCriteria : data.performanceLevelCriteria;
};

const useEmployeePerformanceService = () => {
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_PATH}/${employeeId}`;
  const getValidationSchema = () => Promise.resolve(employeePerformanceValidation);
  const { sendGetRequest } = useKernelApi();

  const { ...baseCrudRequests } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${EMPLOYEE_PERFORMANCE_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${EMPLOYEE_PERFORMANCE_URL}`,
    apiService: useKernelApi,
  });

  const getPerformanceCriteriaByCareerReview = async (relatedCareerReviewId: number) => {
    const { search } = baseCrudRequests;

    const response = await search({
      filter: {
        'specification:employeePerformanceByCareerReviewProcessIdSpecification': { eq: relatedCareerReviewId },
      },
    });
    // specification provides single element
    const relatedPerformance = response?.result?.length > 0 ? (response.result as Array<any>)[0] : null;
    return relatedPerformance ? mapToSimpleDetails(relatedPerformance) : null;
  };

  const downloadPerformanceReport = async (id: string) => {
    return sendGetRequest(`${parentResourceUrl}${EMPLOYEE_PERFORMANCE_URL}/${id}/download`);
  };

  return {
    ...baseCrudRequests,
    getValidationSchema,
    getPerformanceCriteriaByCareerReview,
    downloadPerformanceReport,
  };
};

export default useEmployeePerformanceService;
