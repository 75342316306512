/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleProjectWeeklyReportBreadcrumbs from 'erp/project/weeklyreports/shared/SingleProjectWeeklyReportBreadcrumbs';

const UpdateProjectWeeklyReportBreadcrumbs = () => {
  return (
    <SingleProjectWeeklyReportBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleProjectWeeklyReportBreadcrumbs>
  );
};

export default UpdateProjectWeeklyReportBreadcrumbs;
