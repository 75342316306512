/* istanbul ignore file */
import React from 'react';
import EmployeeOfferBreadcrumbs from 'erp/employee/offers/shared/EmployeeOfferBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateEmployeeOfferBreadcrumbs = () => {
  return (
    <EmployeeOfferBreadcrumbs>
      <UpdateBreadcrumbItem entity="Offer" />
    </EmployeeOfferBreadcrumbs>
  );
};

export default UpdateEmployeeOfferBreadcrumbs;
