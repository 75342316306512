/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeGroupDropdown from '../../../group/shared/input/EmployeeGroupDropdown';

const EmployeeGroupFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <EmployeeGroupDropdown />
    </DropDownFilter>
  );
};

export default EmployeeGroupFilter;
