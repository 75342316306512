/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import LinkField from 'shared/uibuilder/field/LinkField';

interface EmployeeSalaryLinkFieldProps extends FieldProps {
  urlFunction: ((value?: string | number) => string) | ((value?: string) => string);
}

const EmployeeSalaryLinkField = ({ source, urlFunction, ...props }: EmployeeSalaryLinkFieldProps) => {
  const { getValue: getLinkTarget } = useFieldHelper({ source });
  const relatedSalaryIds = getLinkTarget();
  const value =
    relatedSalaryIds && relatedSalaryIds.length !== 0
      ? Array.from(relatedSalaryIds).map(id => <LinkField value={urlFunction(id)} linkText={id} />)
      : null;

  return <TextField value={value} {...props} />;
};

export default EmployeeSalaryLinkField;
