/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';

type BudgetSearchLinkFieldProps = {
  label?: string;
  idValue?: string;
  idSource?: string;
  source?: string;
  isSortable?: boolean;
  width?: number;
};

const BudgetSearchLinkField = ({ idValue, idSource, ...props }: BudgetSearchLinkFieldProps) => {
  const { getSingleEntityUrl } = useBudgetUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue } = useFieldHelper<any>(props);

  const id = getId();
  const name = getValue();

  return <SearchLinkField {...props} value={name} url={getSingleEntityUrl(id)} isRowClickable={false} />;
};

export default BudgetSearchLinkField;
