/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import RbsEntityHeader from 'erp/rbs/shared/RbsEntityHeader';
import UpdateRbsBreadcrumbs from 'erp/rbs/createupdate/Update/Breadcrumbs';
import RbsForm from 'erp/rbs/createupdate/RbsForm';
import useRbsService, { RbsNodeType, CREATE_RBS_TREE_NODE } from 'erp/rbs/shared/rbsService';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import { TreeNodeProps } from 'shared/uibuilder/Tree/treeHelpers';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import FormFloatButtons from 'erp/rbs/createupdate/FormFloatButtons';

const UpdateRbsNode = () => {
  const { update, getById, getValidationSchema } = useRbsService();
  const { getSingleEntityUrl, getListUrl } = useRbsUrl();
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());
  const { isGranted } = useAuthorization();
  const canEditFullData = isGranted(CREATE_RBS_TREE_NODE);

  const getFormData = async (id: string) => {
    const { name, parentNode, attributes = {}, nodeType, isPublicService } =
      ((await getById(id)) as TreeNodeProps) || {};

    return {
      id,
      name,
      attributes,
      nodeType,
      parentNodeName: (parentNode as TreeNodeProps)?.name,
      parentNodeId: (parentNode as TreeNodeProps)?.id,
      ownerId: (attributes.owner as Dictionary<string>)?.alias,
      ownerName: `${(attributes.owner as Dictionary<string>)?.alias || ''} ${(attributes.owner as Dictionary<string>)
        ?.fullName || ''}`.trim(),
      secondOwnerId: (attributes.secondOwner as Dictionary<string>)?.alias,
      secondOwnerName: `${(attributes.secondOwner as Dictionary<string>)?.alias ||
        ''} ${(attributes.secondOwner as Dictionary<string>)?.fullName || ''}`.trim(),
      sowId: (attributes.project as Dictionary<string>)?.sowId,
      projectName: (attributes.project as Dictionary<string>)?.name,
      projectId: (attributes.project as Dictionary<string>)?.id,
      isPublicService,
      parentNodeOwnerAlias: (parentNode?.attributes?.owner as Dictionary<string>)?.alias || '',
    };
  };

  const getRedirectUrl = (data: any) => {
    const { nodeType, attributes = {} } = data.result[0] || {};

    return !canEditFullData && nodeType === RbsNodeType.QUEUE && attributes.owner?.alias === userAlias
      ? getListUrl()
      : getSingleEntityUrl();
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getFormData}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getRedirectUrl,
        message: 'You are awesome! The Rbs has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Responsibility"
        breadcrumbs={<UpdateRbsBreadcrumbs />}
        entityHeader={<RbsEntityHeader />}
        buttons={<FormFloatButtons />}
      >
        <RbsForm isTypeInputHidden />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateRbsNode;
