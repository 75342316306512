/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import { useEmployeeBackgroundChecksUrl } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import { UPDATE_BACKGROUND_CHECK } from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

interface EditBackgroundCheckButtonProps extends EditButtonProps {
  isVisible?: (data: ListItem) => boolean;
}

const EditEmployeeBackgroundCheckButton = (props: EditBackgroundCheckButtonProps) => {
  const { getUpdateEntityUrl } = useEmployeeBackgroundChecksUrl();

  return <EditButton {...props} permissionToCheck={UPDATE_BACKGROUND_CHECK} path={getUpdateEntityUrl} outline />;
};

export default EditEmployeeBackgroundCheckButton;
