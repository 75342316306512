/* istanbul ignore file */
import React from 'react';
import { useInvoiceUrl, useInvoiceId } from 'financialAnalytic/invoice/InvoiceRouter';
import { UPDATE_INVOICE } from 'financialAnalytic/invoice/useInvoiceService';
import EditButton from 'uibuilder/button/EditButton';

const EditInvoiceButton = (props: any) => {
  const { getUpdateEntityUrl } = useInvoiceUrl();
  const id = useInvoiceId();

  return (
    <EditButton
      {...props}
      path={getUpdateEntityUrl(id)}
      key="edit-invoice"
      permissionToCheck={UPDATE_INVOICE}
      outline
      label="Edit document"
    />
  );
};

export default EditInvoiceButton;
