/* istanbul ignore file */
import React from 'react';
import ControlledShow from 'shared/uibuilder/show/ControlledShow';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import QualificationList from 'erp/employee/qualification/List';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import QualificationsListBreadcrumbs from 'erp/employee/qualification/List/shared/QualificationsBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import QualificationMenu from 'erp/employee/qualification/shared/QualificationMenu';
import Loading from 'shared/uibuilder/Loading';
import useEmployeeQualificationShowHelper from 'erp/employee/qualification/Show/employeeQualificationShowHelper';
import CareerDevelopmentReviewSection from 'erp/employee/qualification/Show/CareerDevelopmentReviewSection';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import useAuthorization from 'shared/authorization/authorizationService';
import { VIEW_EMPLOYEE_BOOKS } from 'erp/employee/books/shared/readBooksService';
import RelatedReadBooksList from 'erp/employee/books/list/RelatedReadBooksList';

const EmployeeQualificationListPage = () => {
  const { loading, employeeQualifications, specializations } = useEmployeeQualificationShowHelper();
  const { isGranted } = useAuthorization();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<QualificationsListBreadcrumbs />}
    >
      <CareerDevelopmentReviewSection />

      <ShowSection title="Qualifications" titleVariant="h2">
        <ControlledShow data={{ result: employeeQualifications }}>
          <div>
            {!!employeeQualifications && <QualificationMenu specializations={specializations} />}
            {loading && <Loading />}
            <QualificationList wrapperClassName={loading ? 'visually-hidden' : ''} data={employeeQualifications} />
          </div>
        </ControlledShow>
      </ShowSection>

      <ShowSection isVisible={isGranted(VIEW_EMPLOYEE_BOOKS)}>
        <RelatedReadBooksList />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default EmployeeQualificationListPage;
