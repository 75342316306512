import React from 'react';
import { useCustomerInvoiceUrl } from '../../CustomerInvoicesRouter';
import LinkButton from '../../../../uibuilder/button/LinkButton';

const ViewCustomerInvoiceButton = (props: any) => {
  const { getSingleEntityUrl } = useCustomerInvoiceUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-customer-invoicey" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewCustomerInvoiceButton;
