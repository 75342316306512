import { useIntegrationTestRoutes } from 'integration-tests/Router';
import { useParams } from 'react-router-dom';
import React from 'react';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { UpdateForm } from 'shared/uibuilder/form/index';
import useTestingCrudService from 'integration-tests/testingCrudService';
// eslint-disable-next-line import/no-named-as-default
import EntityForm, { EntityHeader } from './EntityForm';

const IntegrationUpdateFormPage = () => {
  const { getValidationSchema, getById, update } = useTestingCrudService();
  const { updateRoute } = useIntegrationTestRoutes();
  const { id } = useParams();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: updateRoute.replace(':id', id),
        message: 'You are awesome!',
      }}
    >
      <UpdatePageLayout entityHeader={<EntityHeader />} title="Edit: Entity">
        <EntityForm hasAnchorLink />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default IntegrationUpdateFormPage;
