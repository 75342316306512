import { useBusinessEngineApi } from 'api';
import useAuthorization from 'shared/authorization/authorizationService';
import { useAuthenticationProvider } from 'authentication/AuthenticationProvider';
import rescheduleInterviewValidation from './rescheduleInterviewValidation';
import cancelInterviewValidation from './cancelInterviewValidation';
import { ValidationSchema } from 'validation-schema-library';

export const INTERVIEW_PATH = '/interviews';

export const READ_INTERVIEWS_LIST = 'READ_INTERVIEWS_LIST';
export const UPDATE_INTERVIEW = 'UPDATE_INTERVIEW';
export const UPDATE_ALL_INTERVIEWS = 'UPDATE_ALL_INTERVIEWS';
export const RESCHEDULE_INTERVIEW = 'RESCHEDULE_INTERVIEW';
export const CANCEL_INTERVIEW = 'CANCEL_INTERVIEW';

interface InterviewService {
  search: (params: { pageNumber?: number; filter?: any }) => Promise<any>;
  canUpdateInterview: (interview: any) => boolean;
  getRescheduleInterviewValidation: () => Promise<ValidationSchema>;
  getCancelInterviewValidation: () => Promise<ValidationSchema>;
}

const useInterviewService = (): InterviewService => {
  const { sendPostRequest } = useBusinessEngineApi();
  const { isGranted } = useAuthorization();
  const { getUserAlias } = useAuthenticationProvider();

  const search = async ({ pageNumber = 0, filter = {} } = {}) => {
    const request = {
      filter: {
        ...filter,
      },
      pageSize: 30,
      pageNumber,
    };

    const response = await sendPostRequest(`${INTERVIEW_PATH}/search`, request);
    return response.json();
  };

  const canUpdateInterview = (interview: any) => {
    const userAlias = getUserAlias();
    return (
      interview.canBeUpdated &&
      (isGranted(UPDATE_ALL_INTERVIEWS) || (isGranted(UPDATE_INTERVIEW) && interview.recruiter.alias === userAlias))
    );
  };

  const getRescheduleInterviewValidation = async () => rescheduleInterviewValidation;

  const getCancelInterviewValidation = async () => cancelInterviewValidation;

  return {
    search,
    canUpdateInterview,
    getRescheduleInterviewValidation,
    getCancelInterviewValidation,
  };
};

export default useInterviewService;
