/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import {
  REPORT_ID,
  useProjectWeeklyReportId,
  useProjectWeeklyReportsUrl,
} from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';
import useProjectWeeklyReportsService from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import ProjectWeeklyReportForm from 'erp/project/weeklyreports/update/ProjectWeeklyReportForm';

const UpdateProjectWeeklyReport = () => {
  const { update, getById, getValidationSchema } = useProjectWeeklyReportsService();
  const { getSingleEntityUrl } = useProjectWeeklyReportsUrl();
  const reportId = useProjectWeeklyReportId();

  return (
    <UpdateForm
      idSource={REPORT_ID}
      submitFormFunc={update}
      getDataFunc={() => getById(reportId)}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        message: 'You are awesome! The Weekly Report has been successfully saved.',
        redirect: getSingleEntityUrl(),
      }}
    >
      <ProjectWeeklyReportForm />
    </UpdateForm>
  );
};

export default UpdateProjectWeeklyReport;
