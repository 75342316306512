/* istanbul ignore file */
import React from 'react';
import LeavesBreadcrumbs from 'erp/leave/shared/LeavesBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import { useParams } from 'react-router-dom';
import { arrayOf, element, oneOfType } from 'prop-types';

const SingleLeavesBreadcrumbs = ({ children }) => {
  const { getSingleEntityUrl } = useLeaveUrl();
  const { id } = useParams();

  return (
    <LeavesBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </LeavesBreadcrumbs>
  );
};

SingleLeavesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleLeavesBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleLeavesBreadcrumbs;
