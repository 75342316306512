/* istanbul ignore file */
import React from 'react';
import { LinkField } from 'shared/uibuilder/field';
import useAccountUrl from '../accountUrl';
import { string, bool } from 'prop-types';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const AccountLinkField = ({ idValue, idSource, isInternal, ...props }) => {
  const { getSingleEntityUrl } = useAccountUrl();
  const { getValue: getIdValue } = useFieldHelper({ value: idValue, source: idSource });
  const { getValue: getName } = useFieldHelper(props);

  const id = getIdValue();
  const url = id && getSingleEntityUrl(id);
  const name = getName();

  return <LinkField {...props} linkText={name} value={url} isInternal={isInternal} />;
};

AccountLinkField.propTypes = {
  idSource: string,
  idValue: string,
  isInternal: bool,
};

AccountLinkField.defaultProps = {
  idSource: undefined,
  idValue: undefined,
  isInternal: true,
};

export default AccountLinkField;
