import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';

export const COMPETENCIES_URL = '/competencies';

export type Competency = {
  id: number;
  name: string;
};

const useCompetencyService = () => {
  const baseCrudRequests = useCrudService({
    singleResourceUrl: `${COMPETENCIES_URL}/:id`,
    listResourceUrl: COMPETENCIES_URL,
    apiService: useKernelApi,
  });

  return {
    ...baseCrudRequests,
  };
};

export default useCompetencyService;
