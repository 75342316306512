/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { Button } from 'uibuilder/button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import {
  ProcessIncidentType,
  RESOLVE_PROCESS_INCIDENT,
} from 'camunda/monitoring/instance/Show/incident/processIncidentService';
import ResolveIncidentModalForm from 'camunda/monitoring/instance/Show/incident/shared/ResolveIncidentModalForm';

interface ResolveBusinessIncidentButtonProps {
  sourceId: string;
}

const ResolveBusinessIncidentButton = ({ sourceId }: ResolveBusinessIncidentButtonProps) => {
  const { data: { items = [] } = {}, deleteFromList = (id: StringOrNumber) => {} } = useListContext();

  const { getValue: getIncident } = useFieldHelper<any>({ source: sourceId });
  const { isGranted } = useAuthorization();

  const incidentId = getIncident();

  const incidentData: any = items.filter((item: any) => item.id === incidentId)[0];
  const isButtonHidden =
    incidentData.incidentType !== ProcessIncidentType.BUSINESS_INCIDENT || !isGranted(RESOLVE_PROCESS_INCIDENT);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const openResolveIncidentForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  const updateIncidentsList = useCallback(() => {
    deleteFromList(incidentData.id);
  }, [deleteFromList, incidentData]);

  return !isButtonHidden ? (
    <>
      <Button onClick={openResolveIncidentForm}>Resolve</Button>
      {isFormOpen && (
        <ResolveIncidentModalForm
          closeModal={closeModal}
          incidentId={incidentId}
          updateIncidentList={updateIncidentsList}
        />
      )}
    </>
  ) : null;
};

export default ResolveBusinessIncidentButton;
