/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { EmployeeNewCompetencyLevelStatusLabels } from 'erp/employee/newQualification/shared/employeeNewCompetencyService';

interface NewCompetencyStatusDropdownProps extends DefaultDropdownProps {}
const NewCompetencyStatusDropdown = ({ ...props }: NewCompetencyStatusDropdownProps) => {
  return (
    <Dropdown
      {...props}
      options={Object.entries(EmployeeNewCompetencyLevelStatusLabels).map(([value, label]) => ({
        value,
        label,
      }))}
      placeholder="Select status"
    />
  );
};

export default NewCompetencyStatusDropdown;
