import { FormRowProps } from '../Create/formConfigurationMapper';

const DEFAULT_SKIPPED_FIELD_LENGTH = 0;

export const groupFormRowMapper = (
  row: FormRowProps,
  groupLength?: number,
  skippedFieldLength = DEFAULT_SKIPPED_FIELD_LENGTH,
) => {
  if (!groupLength) {
    return [row];
  }

  const rows = [];
  const groups = [];

  for (let i = skippedFieldLength; i < row.fields.length; i += groupLength) {
    groups.push(row.fields.slice(i, i + groupLength));
  }

  if (skippedFieldLength) {
    rows.push({ ...row, fields: row.fields.slice(0, skippedFieldLength) });
  }

  groups.forEach(group => {
    rows.push({ ...row, fields: group });
  });

  return rows;
};

export default {
  groupFormRowMapper,
};
