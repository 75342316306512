/* istanbul ignore file */
import React from 'react';
import LeavesBreadcrumbs from 'erp/leave/shared/LeavesBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateLeaveBreadcrumbs = () => {
  return (
    <LeavesBreadcrumbs>
      <CreateBreadcrumbItem entity="Leave" />
    </LeavesBreadcrumbs>
  );
};

export default CreateLeaveBreadcrumbs;
