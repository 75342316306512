import React, { useEffect, useMemo, useState } from 'react';
import { bool, string } from 'prop-types';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import EditableFieldLayout from 'uibuilder/layout/form/input/EditableFieldLayout';
import OwnerDropdown from 'crm/shared/input/OwnerDropdown';
import { OWNER_SOURCE, getFullName } from './ownerHelper';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useCrmService from 'crm/crmService';

const OWNER_NAME = 'ownerName';

/**
 * Input for editable field of crm entity owner.
 * @param isUserCanChange - boolean variable that shows if user can edit the field (e.g. by permissions)
 * @param label - label
 * @param dropdownPlaceholder - placeholder that will be displayed for dropdown.
 */
const OwnerInput = ({ isUserCanChange, label, dropdownPlaceholder, ...props }) => {
  const { getIsRequired, getRawOnChangeCallback, initializeValue, getOnChangeCallback, getSource } = useInputHelper(
    props,
  );

  const rawOnChangeCallback = getRawOnChangeCallback();

  const { getUserAlias, getUserName } = useAuthenticatedUserService();
  const { data } = useFormContext();
  const { getActiveSalesManagers } = useCrmService();
  const [userAlias, setUserAlias] = useState(null);
  const [userName, setUserName] = useState({});
  const [sales, setSales] = useState([]);

  useEffect(() => {
    (async () => {
      const alias = await getUserAlias();
      setUserAlias(alias);
      const name = await getUserName();
      setUserName(name);

      try {
        const sms = await getActiveSalesManagers();
        setSales(sms);
      } catch (e) {
        setSales([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save initial lead owner
  const initialOwner = useMemo(
    () => (data && data[getSource()]) || userAlias,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userAlias],
  );

  useEffect(() => {
    if (initialOwner) {
      initializeValue(initialOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOwner]);

  const onCancel = () => {
    // when user click "cancel change"
    // => it imitate onchange callback with initial owner for ownerAlias field.
    rawOnChangeCallback({
      [getSource()]: initialOwner,
    });
  };

  const fieldComponent = <b>{(data && data[OWNER_NAME]) || getFullName(userName)}</b>;

  const editFieldInput = (
    <OwnerDropdown
      label={label}
      source={getSource()}
      placeholder={dropdownPlaceholder}
      sales={sales}
      onChangeCallback={rawOnChangeCallback}
    />
  );

  return (
    <EditableFieldLayout
      fieldComponent={fieldComponent}
      editFieldInput={editFieldInput}
      isUserCanChange={isUserCanChange}
      source={getSource()}
      isRequired={getIsRequired()}
      label={label}
      onChangeCallback={getOnChangeCallback()}
      onCancel={onCancel}
    />
  );
};

OwnerInput.propTypes = {
  source: string,
  label: string.isRequired,
  isUserCanChange: bool.isRequired,
  dropdownPlaceholder: string,
};

OwnerInput.defaultProps = {
  source: OWNER_SOURCE,
  dropdownPlaceholder: undefined,
};

export default OwnerInput;
