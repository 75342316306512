/* istanbul ignore file */
import React from 'react';
import useReconciliationService, {
  REJECT_RECONCILIATION,
  RECONCILIATION_STATUS_ALIASES,
} from 'erp/costaccounting/reconcilation/useReconciliationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';

const RejectReconciliationButton = ({
  statusSource,
  idSource,
  execute,
}: {
  statusSource: string;
  idSource: string;
  execute: () => void;
}) => {
  const { rejectReconciliation } = useReconciliationService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { getValue: getId } = useFieldHelper({ source: idSource });
  const status = getValue();
  const id = getId();

  if (status === RECONCILIATION_STATUS_ALIASES.NOT_RECONCILED) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to reject this reconciliation?"
      submitMethod={() => rejectReconciliation(id)}
      permissionToCheck={REJECT_RECONCILIATION}
      afterSubmit={{
        successMessage: 'The reconciliation has been successfully rejected.',
        errorMessage: "Can't reject reconciliation.",
        execute,
      }}
      outline
      color="error"
    >
      Reject
    </ButtonWithConfirmation>
  );
};

export default RejectReconciliationButton;
