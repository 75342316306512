import useCacheService from 'shared/cache/cacheService';
import { useBusinessEngineApi } from 'api';
import useSingletonPromise from 'shared/useSingletonPromise';

export const RESOURCE_URL = '/handbook';
const CACHE_NAME = 'handbookStructure';
const CACHE_ID = 'handbookStructureData';

export const HANDBOOK_INDEX_FILE_PATH = 'index.md';
const AST_API_URL = '/handbook/ast';

export interface HandbookItemStructure {
  pageTitle: string;
  pagePathname: string;
  subPages?: HandbookItemStructure[] | null;
}

interface HandbookAstItem {
  children: HandbookAstItem[];
  type: string;
  raw: string;
}

const useHandbookStructureService = () => {
  const { getValue: getCachedStructureValue, addToCache: addToHandbookStructureCache } = useCacheService(CACHE_NAME);

  const { sendGetRequest } = useBusinessEngineApi();

  const parseHandbook = (pageAstItems: HandbookAstItem[] = []) => {
    return pageAstItems.reduce((parsedData: HandbookItemStructure[], { children, type }: HandbookAstItem) => {
      if (type === 'List') {
        const result: HandbookItemStructure[] = children.map(({ children: subChildren, raw }: HandbookAstItem) => {
          const pageTitle = raw.match(/\[([^\](]+)/);
          // eslint-disable-next-line no-useless-escape
          const pagePathname = raw.match(/\(([^\)]+)/);

          return {
            pageTitle: pageTitle ? pageTitle[1] : '',
            pagePathname: pagePathname ? pagePathname[1] : '',
            subPages: parseHandbook(subChildren),
          };
        });

        parsedData.push(...result);
      }

      return parsedData;
    }, []);
  };

  const getHandbookStructure = useSingletonPromise('getHandbookStructure', async () => {
    try {
      const cachedValue = getCachedStructureValue(CACHE_ID);

      if (cachedValue) {
        return cachedValue;
      } else {
        const res = await sendGetRequest(`${AST_API_URL}?filePath=${HANDBOOK_INDEX_FILE_PATH}`);
        const indexPageAst = await res.json();
        const structureData = parseHandbook(indexPageAst?.children);

        addToHandbookStructureCache(CACHE_ID, structureData);

        return structureData;
      }
    } catch {
      return null;
    }
  });

  const getItemByPath = async (path: string): Promise<Nullable<HandbookItemStructure>> => {
    const handbookStructure = await getHandbookStructure();

    let foundedItem: Nullable<HandbookItemStructure> = null;

    const find = (data: HandbookItemStructure[]): Nullable<HandbookItemStructure> => {
      return (
        data.find((item: HandbookItemStructure) => {
          const { pagePathname, subPages } = item;
          if (pagePathname.includes(path)) {
            foundedItem = item;
            return true;
          }

          if (subPages?.length) {
            return find(subPages);
          }

          return false;
        }) || null
      );
    };

    find(handbookStructure);

    return foundedItem;
  };

  const findPathToSubpage = (
    data: HandbookItemStructure[],
    path: string,
    currentPath: HandbookItemStructure[] = [],
  ): Nullable<HandbookItemStructure[]> => {
    let matchingPath: Nullable<HandbookItemStructure[]> = null;

    for (let i = 0; i < data.length; i += 1) {
      const { pagePathname, pageTitle } = data[i];

      if (pagePathname === path) {
        matchingPath = [...currentPath, data[i]];
      } else {
        matchingPath = findPathToSubpage(data[i].subPages || [], path, [...currentPath, { pageTitle, pagePathname }]);
      }

      if (matchingPath) break;
    }

    return matchingPath;
  };

  const getPathToSubpage = async (path: string) => {
    const handbookStructure = await getHandbookStructure();

    return findPathToSubpage(handbookStructure, path);
  };

  return {
    getHandbookStructure,
    getItemByPath,
    getPathToSubpage,
  };
};

export default useHandbookStructureService;
