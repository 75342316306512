/* istanbul ignore file */
import React from 'react';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

export const { EUR, ...DROPDOWN_CURRENCY_TYPES } = CURRENCY_TYPES || {};

const CurrencyDropdownWithoutEur = (props: Omit<EnumDropdownProps, 'options'> & { options?: Dictionary<string> }) => {
  return <CurrencyDropdown options={DROPDOWN_CURRENCY_TYPES} {...props} />;
};

export default CurrencyDropdownWithoutEur;
