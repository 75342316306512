/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import useAuthorization from 'shared/authorization/authorizationService';
import { func, string } from 'prop-types';

const EditTimelineRecordButton = ({ onEdit, permissionToCheck }) => {
  const { isGranted } = useAuthorization();

  return isGranted(permissionToCheck) ? <MenuItem onClick={onEdit}>Edit</MenuItem> : null;
};

EditTimelineRecordButton.propTypes = {
  onEdit: func.isRequired,
  permissionToCheck: string.isRequired,
};

export default EditTimelineRecordButton;
