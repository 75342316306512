/* istanbul ignore file */
import React from 'react';
import ProjectIssue from 'syberryToday/shared/ProjectIssue';
import { updateIssueData, removeIssueFromProject, getAllQueues } from 'syberryToday/useSyberryTodayService';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import ProjectQueueLayout from 'syberryToday/shared/layout/ProjectQueueLayout';
import Alert from 'uibuilder/Alert';
import { useSyberryTodayContext } from '../SyberryTodayContext';

const PlannedIssues = ({
  title,
  canPlan,
  emptyAlertMessage,
}: {
  title?: string;
  canPlan?: boolean;
  emptyAlertMessage?: string;
}) => {
  const { loading: updatingData, unSelectIssue } = useSyberryTodayContext();
  const {
    data: { items = [] } = { items: [] },
    loading,
    updateInList: contextUpdateInList = () => {},
  } = useListContext();
  const queues = getAllQueues(items) || [];
  const isEmpty = !queues.reduce((sum: number, { issues }: any) => sum + (issues?.length || 0), 0);

  const updateInList = (updatedEntity: ListItem, projectId: number) => {
    const updatedProject = items?.find((item: ListItem) => item.id === projectId);

    if (updatedProject) {
      const updatedData = updateIssueData([updatedProject], updatedEntity.id, updatedEntity)[0];
      contextUpdateInList(updatedData);
    }
  };

  const removeFromList = (entityId: ListItem, projectId: number) => {
    const updatedProject = items?.find((item: ListItem) => item.id === projectId);

    if (updatedProject) {
      const updatedData = removeIssueFromProject([updatedProject], entityId)[0];
      contextUpdateInList(updatedData);
      unSelectIssue(entityId);
    }
  };

  const emptyMessage =
    !loading && !updatingData ? (
      <Alert color="info" className="mt-3 w-100">
        {emptyAlertMessage || 'No issues found'}
      </Alert>
    ) : null;

  return (
    <LoadingDecorator loading={loading || updatingData} deps={[items, loading, updatingData]}>
      <div className="project-issues__wrapper project-issues__wrapper--planned">
        <ProjectQueueLayout title={title || 'My Tasks'}>
          {isEmpty
            ? emptyMessage
            : queues.map(({ issues, projectName, subProjectName, projectId }: ListItem) =>
                issues?.map((issue: ListItem, i: number) => (
                  <ProjectIssue
                    key={issue.id}
                    item={issue}
                    index={i}
                    showProjectName
                    sortable={false}
                    projectName={projectName}
                    subProjectName={subProjectName}
                    updateInList={(updatedEntity: ListItem) => updateInList(updatedEntity, projectId)}
                    removeFromList={(entityId: number) => removeFromList(entityId, projectId)}
                    canPlan={canPlan}
                  />
                )),
              )}
        </ProjectQueueLayout>
      </div>
    </LoadingDecorator>
  );
};

export default PlannedIssues;
