/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import useRoute from 'shared/routing/useRoute';
import Page404 from 'shared/uibuilder/Page404';
import SendFormPage from 'sendEmail/SendForm';
import UpdateSendEmail from 'sendEmail/createupdate/Update';
import useEntityUrl, { UrlParams } from 'shared/routing/useEntityUrl';

export const SEND_EMAIL_PATH = '/send-email';

export const useSendEmailId = () => {
  const urlParams: UrlParams = useParams();

  return urlParams.id;
};

export const useSendEmailUrl = () => {
  return useEntityUrl({
    baseLink: SEND_EMAIL_PATH,
  });
};

export const useSendEmailRoute = () => {
  return useRoute({ listRoute: SEND_EMAIL_PATH });
};

const SendEmailRouter: Router = () => {
  const { singleEntityRoute, updateRoute } = useSendEmailRoute();

  return (
    <ProtectedRouter basePath={SEND_EMAIL_PATH}>
      <Switch>
        <Route path={singleEntityRoute} exact>
          <SendFormPage />
        </Route>

        <Route path={updateRoute} exact>
          <UpdateSendEmail />
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

SendEmailRouter.getRouterPath = () => SEND_EMAIL_PATH;

export default SendEmailRouter;
