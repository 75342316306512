import { PROCESS_STATE_CAPTIONS, ProcessState } from 'camunda/monitoring/instance/processInstanceService';
import Badge from 'shared/uibuilder/badge/Badge';
import React from 'react';

const COLORS = {
  [ProcessState.ACTIVE]: 'success',
  [ProcessState.COMPLETED]: 'primary',
  [ProcessState.EXTERNALLY_TERMINATED]: 'warning',
  [ProcessState.INTERNALLY_TERMINATED]: 'warning',
  [ProcessState.SUSPENDED]: 'secondary',
};

const ProcessStateBadge = ({ state }: { state: ProcessState }) => {
  const color = COLORS[state];
  const caption = PROCESS_STATE_CAPTIONS[state];
  return (
    <Badge className="ml-2" color={color as any}>
      {caption}
    </Badge>
  );
};

export default ProcessStateBadge;
