/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import ArtifactUploaderInput from 'artifact/shared/input/ArtifactUploaderInput/index';

const ArtifactStorageUploaderInput = (props: any) => {
  return <ArtifactUploaderInput {...props} artifactTypeId={ARTIFACT_ID.ARTIFACT_STORAGE_FILE} />;
};

export default ArtifactStorageUploaderInput;
