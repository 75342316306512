import React from 'react';
import { RoutingSwitch, Route } from 'shared/routing';
import FormBuilderPage from 'shared/uibuilder/form/Builder/__integration-tests__/FormBuilderPage';

export const FORM_BUILDER_ROUTES = {
  main: '/form-builder',
};

export default () => (
  <RoutingSwitch>
    <Route path={FORM_BUILDER_ROUTES.main} exact component={FormBuilderPage} />
  </RoutingSwitch>
);
