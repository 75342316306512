import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import './IdentityDocumentListInput.scss';

const IdentityDocumentListInput = ({ inputTemplate, ...props }) => {
  return (
    <div className="document-list-input-wrapper">
      <InputList {...props} maxFields={10} minFields={1} inputTemplate={inputTemplate} />
    </div>
  );
};

export default IdentityDocumentListInput;
