import React from 'react';
import List from 'shared/uibuilder/list/List';
import HiringListContent from './HiringListContent';
import useHiringService from '../useHiringService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';

const HiringListPage = () => {
  const { search } = useHiringService();

  return (
    <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="createdAt">
      <HiringListContent />
    </List>
  );
};

export default HiringListPage;
