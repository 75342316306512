/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import CalendarRightDateInput from 'erp/employee/Timeline/feedback/shared/input/CalendarRightDateInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import FeedbackOnBehalfOfDropdown, { FEEDBACK_ON_BEHALF_OF_ALIAS } from 'instantFeedback/input/OnBehalfOfDropdown';
import FeedbackObtainedDropdown from 'instantFeedback/input/ObtainedDropdown';
import ShareInstantFeedbackToggle from 'instantFeedback/input/ShareInstantFeedbackToggle';
import FormSection from 'shared/layout/form/FormSection';
import EvaluationCriterionForm from 'instantFeedback/input/EvaluationCriterionFrom';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import { Checkbox } from 'shared/uibuilder/form/input';

interface InstantFeedbackFormProps {
  isPageForm?: boolean;
}

const InstantFeedbackForm = ({ isPageForm }: InstantFeedbackFormProps) => {
  const periodInputs = [
    <DateInput key="date-from" source="relatedPeriodStart" label="Related period from" />,
    <CalendarRightDateInput key="date-to" source="relatedPeriodEnd" label="Related period to" />,
  ];

  return (
    <>
      <p>
        Fill the form to share feedback about any person at Syberry.
        <br />
        You can praise your colleague or suggest what they can improve.
      </p>
      <p>Keep feedback objective and based on facts. We will use the feedback to evaluate colleague’s performance. </p>
      <FormSection hasEqualPadding title="Feedback Details">
        <FormRow colMd={12} colXl={isPageForm ? 3 : 12}>
          <NotDismissedEmployeeInput
            source="employeeId"
            label="Colleague's name"
            nameSource="sourceEmployeeName"
            includeContractors
            includeInterns
          />
          {isPageForm ? (
            (periodInputs as any)
          ) : (
            <FormRow colMd={6} colXl={6}>
              {periodInputs}
            </FormRow>
          )}
        </FormRow>
        <FormRow colMd={12} colXl={isPageForm ? 3 : 12}>
          <TextInput source="relatedProject" label="What project(s) did colleague work on?" />
          <FeedbackOnBehalfOfDropdown source="onBehalfOf" label="On behalf of" />
          <ClearAfterUnmountWrapper
            isVisible={data => data.onBehalfOf === FEEDBACK_ON_BEHALF_OF_ALIAS.ANOTHER_EMPLOYEE}
          >
            <NotDismissedEmployeeInput
              source="onBehalfOfEmployee"
              label="Another colleague"
              nameSource="onBehalfOfEmployee"
              includeContractors
              includeInterns
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={data => data.onBehalfOf === FEEDBACK_ON_BEHALF_OF_ALIAS.EXTERNAL_USER}>
            <TextInput source="externalEmployee" label="External user" />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper
            isVisible={data =>
              data.onBehalfOf === FEEDBACK_ON_BEHALF_OF_ALIAS.EXTERNAL_USER ||
              data.onBehalfOf === FEEDBACK_ON_BEHALF_OF_ALIAS.ANOTHER_EMPLOYEE
            }
          >
            <FeedbackObtainedDropdown source="obtainedBy" label="How the feedback was obtained?" />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>
      <FormSection
        hasEqualPadding
        title="What Performance Evaluation criterion is your feedback about?"
        subtitle="At least one criterion should be selected"
        isLargeTooltip
        tooltipMessage="If the criterion is applicable to your feedback, select it and fill in the necessary information."
      >
        <EvaluationCriterionForm isPageForm={isPageForm} />
      </FormSection>
      <FormSection hasEqualPadding>
        <ShareInstantFeedbackToggle source="isSharedWithEmployee" />
      </FormSection>
      <BigFormRow>
        <Checkbox
          className="instance-feedback__task-checkbox"
          source="notCreateLogTimeTicket"
          label="Do not send me a ticket for time tracking after I submit my feedback"
        />
      </BigFormRow>
      <BigFormRow>
        <Checkbox className="instance-feedback__task-checkbox" source="notifyRM" label="Notify Resource Manager" />
      </BigFormRow>
    </>
  );
};

export default InstantFeedbackForm;
