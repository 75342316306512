import React from 'react';
import isEqual from 'lodash/isEqual';
import { Button } from 'uibuilder/button';
import { Filter, useFilterContext } from './FilterContext';
import { getActiveFilters } from 'shared/uibuilder/list/filter/useFilter';

const QuickFilter = ({ label, state, className }: { label: string; state: Filter; className?: string }) => {
  const { quickFilter, filters } = useFilterContext();

  const onClick = () => {
    quickFilter(state);
  };

  return (
    <Button
      disableFocusRipple
      onClick={onClick}
      variant="text"
      type="button"
      disabled={isEqual(getActiveFilters(filters), state)}
      className={className}
    >
      {label}
    </Button>
  );
};

export default QuickFilter;
