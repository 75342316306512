import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import HiringBreadcrumbs from './HiringBreadcrumbs';

const CreateHiringBreadcrumbs = () => {
  return (
    <HiringBreadcrumbs>
      <CreateBreadcrumbItem entity="Hiring request" />
    </HiringBreadcrumbs>
  );
};

export default CreateHiringBreadcrumbs;
