/* istanbul ignore file */
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import React from 'react';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { DateField, TextField } from 'shared/uibuilder/field';
import useLeaveBalanceService from 'erp/employee/leavebalance/shared/balancesService';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import BalancesListBreadcrumbs from 'erp/employee/leavebalance/BalancesList/BalancesListBreadcrumbs';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import './index.scss';
import BalancesLeavesTypeField from 'erp/employee/leavebalance/BalancesList/field/BalancesLeavesTypeField';
import BalancesLeavesRowColumnLayout from 'erp/employee/leavebalance/BalancesList/layout/BalancesLeavesRowColumnLayout';
import BalancesLeavesField from 'erp/employee/leavebalance/BalancesList/field/BalancesLeavesField';
import BalancesLeavesStatusField from 'erp/employee/leavebalance/BalancesList/field/BalancesLeavesStatusField';
import NumberOfCalendarDaysField from 'erp/employee/leavebalance/BalancesList/field/NumberOfCalendarDaysField';
import PaidLeaveBalanceField from 'erp/employee/leavebalance/BalancesList/field/PaidLeaveBalanceField';
import SickDaysBalanceField from 'erp/employee/leavebalance/BalancesList/field/SickDaysBalanceField';
import TotalLeavesBalanceColumnLayout from 'erp/employee/leavebalance/BalancesList/layout/TotalLeavesBalanceColumnLayout';
import OfficeLeavesBalanceColumnLayout from 'erp/employee/leavebalance/BalancesList/layout/OfficeLeavesBalanceColumnLayout';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

const EmployeePaidLeavesBalancesList = () => {
  const { getLeavesBalances } = useLeaveBalanceService();
  const id = useEmployeeId();

  const getDataMethod = async () => {
    const data = await getLeavesBalances(id);

    return {
      result: data,
    };
  };

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<BalancesListBreadcrumbs />}
    >
      <RelatedList getDataMethod={getDataMethod}>
        <RelationListLayout header="Leaves">
          <DataGridLayout isSmallTable tableWrapperClassName="balances-list">
            <DateField source="startDate" label="Working Year Start" width={110} />
            <DateField source="endDate" label="Working Year End" width={110} />
            <OfficeLeavesBalanceColumnLayout label="Office" width={90} totalField={<TextField source="officeName" />} />
            <BalancesLeavesRowColumnLayout source="leaves" label="Leave Type" width={135}>
              <BalancesLeavesTypeField />
            </BalancesLeavesRowColumnLayout>
            <BalancesLeavesRowColumnLayout source="leaves" label="Leave Start" width={135}>
              <BalancesLeavesField source="startDate" />
            </BalancesLeavesRowColumnLayout>
            <BalancesLeavesRowColumnLayout source="leaves" label="Leave End" width={135}>
              <BalancesLeavesField source="actualEndDate" />
            </BalancesLeavesRowColumnLayout>
            <BalancesLeavesRowColumnLayout source="leaves" label="Status" width={150}>
              <BalancesLeavesStatusField />
            </BalancesLeavesRowColumnLayout>
            <BalancesLeavesRowColumnLayout source="leaves" label="Number of Days" width={70}>
              <NumberOfCalendarDaysField />
            </BalancesLeavesRowColumnLayout>
            <TotalLeavesBalanceColumnLayout
              label="Paid Leave Balance"
              width={90}
              useTooltip
              dayOnDemandField={<TextField source="dayOnDemandsRemainedBalance" />}
              totalField={<TextField source="paidLeavesRemainedBalance" />}
              leavesField={
                <FieldListLayout source="leaves">
                  <PaidLeaveBalanceField />
                </FieldListLayout>
              }
            />
            <TotalLeavesBalanceColumnLayout
              label="Sick Days Balance"
              width={75}
              totalField={<TextField source="sickDaysRemainedBalance" />}
              leavesField={
                <FieldListLayout source="leaves">
                  <SickDaysBalanceField />
                </FieldListLayout>
              }
            />
          </DataGridLayout>
        </RelationListLayout>
      </RelatedList>
    </ShowPageLayout>
  );
};

export default EmployeePaidLeavesBalancesList;
