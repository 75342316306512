/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { careerDevelopmentModeLabels } from 'erp/employee/qualification/shared/employeeQualificationService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

interface CareerDevelopmentModeDropdownProps extends DefaultDropdownProps {
  disabledValue: string;
}

const CareerDevelopmentModeDropdown = ({ disabledValue, ...props }: CareerDevelopmentModeDropdownProps) => {
  return (
    <Dropdown
      {...props}
      options={Object.entries(careerDevelopmentModeLabels).map(([value, label]) => ({
        value,
        label,
        disabled: value === disabledValue,
      }))}
    />
  );
};

export default CareerDevelopmentModeDropdown;
