/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';

const InvoiceBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useInvoiceUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Invoices" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default InvoiceBreadcrumbs;
