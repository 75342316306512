import { useKernelApi } from 'api/index';
import useCrudService from 'shared/crud';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import ASSESSMENT_VALIDATION from 'erp/employee/assessment/createupdate/assessmentValidation';

export const UPDATE_ASSESSMENT = 'UPDATE';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';

export const RESOURCE_URL = '/assessments';

const useAssessmentService = () => {
  const { sendGetRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const listResourceUrl = `${EMPLOYEES_RESOURCE_URL}/${employeeId}${RESOURCE_URL}`;

  const { ...baseAssessmentsMethods } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const { create } = useCrudService({
    singleResourceUrl: `${listResourceUrl}/:id`,
    listResourceUrl,
    apiService: useKernelApi,
  });

  const getSearchParams = (request: LoadingParams) => {
    const params = [];
    params.push(`pageSize=${encodeURIComponent(request.pageSize)}`);
    params.push(`pageNumber=${encodeURIComponent(request.pageNumber)}`);

    if (request.sort && Object.keys(request.sort).length > 0) {
      const [sortKey, sortValue = ''] = Object.entries(request.sort)[0];
      params.push(`${encodeURIComponent(`sort[${sortKey}]`)}=${encodeURIComponent(sortValue)}`);
    }

    return params.join('&');
  };

  const search = async (request: any) => {
    const url = `${listResourceUrl}?${getSearchParams(request)}`;
    const response = await sendGetRequest(url);

    return response.json();
  };

  const getValidationSchema = () => Promise.resolve(ASSESSMENT_VALIDATION);

  return {
    ...baseAssessmentsMethods,
    getValidationSchema,
    search,
    create,
  };
};

export default useAssessmentService;
