import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import {
  DefaultInputLayoutProps,
  DefaultInputPropTypes,
  InputEventProps,
  InputLayoutStateProps,
  MarginProps,
  ValidationLayoutProps,
} from 'shared/uibuilder/form/input';
import { SxProps } from '@mui/system';

interface CheckboxLayoutProps
  extends DefaultInputLayoutProps<boolean>,
    ValidationLayoutProps,
    InputEventProps<object>,
    InputLayoutStateProps,
    MarginProps {
  fullWidth?: boolean;
  tooltip?: Nullable<string | React.ReactElement>;
  disableLabelClick?: boolean;
  sxCheckbox?: Nullable<SxProps>;
}

export type CheckboxLayoutType = ReactComponent<CheckboxLayoutProps>;

export interface CheckboxProps extends DefaultInputPropTypes<boolean> {
  fullWidth?: boolean;
  defaultValue?: boolean;
  disableLabelClick?: boolean;
  sxCheckbox?: Nullable<SxProps>;
}

const Checkbox = ({ isVisible = true, ...props }: CheckboxProps) => {
  const inputHelper = useInputHelper(props);
  const { CheckboxLayout } = useUiTheme<CheckboxLayoutType>();

  useEffect(() => {
    const isChecked = inputHelper.getValue();
    if (!isChecked && isChecked !== false) {
      inputHelper.initializeValue(props.defaultValue as boolean);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isVisible ? (
    <CheckboxLayout
      {...props}
      className={inputHelper.getClassName()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getRawOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      isRequired={inputHelper.getIsRequired()}
      disabled={inputHelper.getIsDisabled()}
      errorMessages={inputHelper.getErrorMessages()}
      tooltip={inputHelper.getTooltip()}
    />
  ) : null;
};

Checkbox.defaultProps = {
  defaultValue: false,
  fullWidth: true,
};

export default Checkbox;
