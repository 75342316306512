/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useSkillsUrl } from 'erp/employee/skills/SkillsRouter';
import useEmployeeSkillsService from 'erp/employee/skills/EmployeeSkillsService';

const DELETE_MESSAGE = 'Do you really want to delete this skill?';

const RemoveSkillButton = (props: any) => {
  const { getValue } = useFieldHelper(props);
  const { getListUrl } = useSkillsUrl();
  const { deleteById } = useEmployeeSkillsService();
  const { deleteFromList = () => {} } = useListContext();
  const id = getValue();

  return (
    <DeleteButton
      displayMessage={DELETE_MESSAGE}
      deleteMethod={async () => {
        await deleteById(id);
        deleteFromList(id);
      }}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Skill has been successfully deleted.',
        failureMessage: `Can't delete skill.`,
        errorMessage: 'Something went wrong.',
      }}
      {...props}
    />
  );
};

export default RemoveSkillButton;
