/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { WRITE_TRAINING_DOMAIN } from 'erp/trainings/useTrainingsService';
import { useTrainingInstancesId, useTrainingInstancesUrl } from '../../TrainingInstancesRouter';

const EditTrainingInstancesButton = (props: any) => {
  const { getUpdateEntityUrl } = useTrainingInstancesUrl();
  const id = useTrainingInstancesId();

  return (
    <EditButton
      {...props}
      path={getUpdateEntityUrl(id)}
      key="edit-training-instances"
      permissionToCheck={WRITE_TRAINING_DOMAIN}
      outline
      label="Edit"
    />
  );
};

export default EditTrainingInstancesButton;
