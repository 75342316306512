import React from 'react';
import { ListProvider } from 'shared/uibuilder/list/ListContext';
import useListCrud, { InputData } from 'shared/uibuilder/list/builder/useListCrud';

export interface ControlledListProps {
  data: InputData;
  children: React.ReactElement;
  loading?: boolean;
}

const ControlledList = ({ data, children, loading }: ControlledListProps) => {
  const listCrud = useListCrud({ inputData: data });
  const contextValue = {
    ...listCrud,
    loading,
  };

  return <ListProvider value={contextValue}>{children}</ListProvider>;
};

export default ControlledList;
