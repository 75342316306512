import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { BaseFieldLayoutType, FieldProps } from 'shared/uibuilder/field';
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';

interface CommaListFieldProps extends FieldProps<string | string[] | any> {
  mapValue?: (obj: any) => string;
}

const CommaListField = ({ mapValue, ...props }: CommaListFieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const initialValue = getValue();

  const joined =
    initialValue &&
    Array.isArray(initialValue) &&
    (mapValue ? initialValue.map(mapValue).join(', ') : initialValue.join(', '));

  return <BaseFieldLayout label={getLabel()} value={joined} />;
};

export default CommaListField;
