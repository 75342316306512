/* istanbul ignore file */
import React from 'react';
import SingleSkillBasedQueueBreadcrumbs from 'erp/skillbasedqueue/shared/SingleSkillBasedQueueBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateSkillBasedQueueBreadcrumbs = () => {
  return (
    <SingleSkillBasedQueueBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleSkillBasedQueueBreadcrumbs>
  );
};

export default UpdateSkillBasedQueueBreadcrumbs;
