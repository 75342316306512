/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useLeadUrl } from 'crm/lead/LeadRouter';

const EditLeadButton = props => {
  const { getUpdateEntityUrl } = useLeadUrl();

  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditLeadButton;
