import React from 'react';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { Dropdown } from 'shared/uibuilder/form/input';
import { getFullName } from 'crm/shared/input/ownerHelper';

interface PresaleCoordinatorDropdownProps extends DefaultDropdownProps {
  coordinators: any[];
  initialCoordinatorAlias: string;
}

const PresaleCoordinatorDropdown = ({
  coordinators,
  initialCoordinatorAlias,
  ...props
}: PresaleCoordinatorDropdownProps) => {
  return (
    <Dropdown
      options={coordinators.map(coordinator => ({
        value: coordinator.alias,
        label: coordinator.nameEn && getFullName(coordinator.nameEn),
      }))}
      value={initialCoordinatorAlias}
      {...props}
    />
  );
};

export default PresaleCoordinatorDropdown;
