import React from 'react';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import WasteHeader from '../shared/WasteHeader';
import SingleWasteBreadcrumbsItem from './SingleWasteBreadcrumbsItem';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import { DateField, EnumField, TextField } from 'shared/uibuilder/field';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import {
  WASTE_REPORTING_METHOD,
  WASTE_REPORTING_METHOD_OPTIONS,
  WASTE_STATUSES,
  WASTE_STATUSES_OPTIONS,
  WASTE_TYPE,
  WASTE_TYPE_OPTIONS,
} from '../useWasteService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import { READ_PROJECTS_LIST } from 'erp/project/shared/projectService';
import ProjectLinkField from 'erp/project/shared/field/ProjectLinkField';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import ApproveWasteButton from '../shared/button/ApproveWasteButton';
import RedmineIssueListField from '../shared/fields/RedmineIssueListField';
import FieldGroup from 'shared/uibuilder/layout/FieldGroup';
import LabeledFieldLayout from 'uibuilder/layout/field/LabeledFieldLayout';
import RejectWasteButton from '../shared/button/RejectWasteButton';

const ViewWastePageContent = () => {
  const data = useShowContext()?.data;

  const redmineIssueIds = data?.getValueBySource('laborWasteDetails.ticketsIds') as number[];
  const isLaborCostWaste = data?.getValueBySource('type') === WASTE_TYPE.LABOR_COST_WASTE;
  const isReportingMethodByTicketAndEmployee =
    data?.getValueBySource('laborWasteDetails.reportingMethod') === WASTE_REPORTING_METHOD.BY_EMPLOYEE_AND_TICKET_IDS;
  const isReportingMethodByTicket =
    data?.getValueBySource('laborWasteDetails.reportingMethod') === WASTE_REPORTING_METHOD.BY_TICKET_IDS;

  return (
    <ShowPageLayout
      entityHeader={<WasteHeader />}
      breadcrumbs={<SingleWasteBreadcrumbsItem />}
      headerButtons={[<ApproveWasteButton statusSource="status" />, <RejectWasteButton statusSource="status" />]}
    >
      <ShowSection title="Main information">
        <SectionRow>
          <EnumField label="Type" source="type" options={WASTE_TYPE_OPTIONS} />
          <EnumField
            isVisible={isLaborCostWaste}
            label="Reporting Method"
            source="laborWasteDetails.reportingMethod"
            options={WASTE_REPORTING_METHOD_OPTIONS}
          />
          <EnumField label="Status" source="status" options={WASTE_STATUSES_OPTIONS} />
        </SectionRow>

        <SectionRow>
          <InvoiceTotalField
            label="Amount"
            source="otherWasteDetails.cost.amount"
            currencySource="otherWasteDetails.cost.currency"
            isVisible={!isLaborCostWaste}
          />
          <DateField isVisible={!isLaborCostWaste} label="Issue Date" source="otherWasteDetails.issueDate" />

          <EmployeeLinkFieldWithNameLoading
            label="Employee"
            source="laborWasteDetails.employeeAlias"
            isVisible={isLaborCostWaste && !isReportingMethodByTicket}
          />

          <FieldGroup
            isVisible={(isReportingMethodByTicket || isReportingMethodByTicketAndEmployee) && isLaborCostWaste}
          >
            <LabeledFieldLayout
              value={redmineIssueIds?.map((issueId: number) => {
                return <RedmineIssueListField key={issueId} id={issueId} />;
              })}
              label="Redmine ticket ID (s)"
            />
          </FieldGroup>

          <DateField
            isVisible={!isReportingMethodByTicket && !isReportingMethodByTicketAndEmployee && isLaborCostWaste}
            label="Period from"
            source="laborWasteDetails.period.startDate"
          />
          <DateField
            isVisible={!isReportingMethodByTicket && !isReportingMethodByTicketAndEmployee && isLaborCostWaste}
            label="Period to"
            source="laborWasteDetails.period.endDate"
          />
          <ProjectLinkField
            isVisible={!isReportingMethodByTicket && !isReportingMethodByTicketAndEmployee && isLaborCostWaste}
            source="laborWasteDetails.projectId"
            nameSource="laborWasteDetails.projectId"
            label="Project"
            permissionToCheck={READ_PROJECTS_LIST}
          />
        </SectionRow>

        <BigSectionRow>
          <TextField label="Reason" source="reason" />
        </BigSectionRow>
      </ShowSection>

      <ShowSection title="Approval Details" isVisible={data.getValueBySource('status') === WASTE_STATUSES.APPROVED}>
        <SectionRow>
          <LedgerLink
            source="approvalDetails.attribution.sourceAccountId"
            nameSource="sourceAccountId"
            label="Source Account"
          />
          <LedgerLink
            source="approvalDetails.attribution.targetAccountId"
            nameSource="targetAccountId"
            label="Target Account"
          />

          <EmployeeLinkFieldWithNameLoading label="Approved By" source="approvalDetails.approvedBy" />
        </SectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewWastePageContent;
