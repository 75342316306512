import useApplicationSettings, { ApplicationTheme } from 'shared/uibuilder/applicationSettingsContext';

/**
 * Helper return layouts that were define in context and sets to all component type T.
 */
const useUiTheme: <T extends ReactComponent<any>>() => ApplicationTheme<T> = () => {
  const { theme } = useApplicationSettings();

  return theme || {};
};

export default useUiTheme;
