/* istanbul ignore file */
import React from 'react';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import useSkipDailyCheckinModal from 'syberryToday/shared/SkipDailyCheckinModal/useSkipDailyCheckinModal';
import './SkipDailyCheckinModal.scss';

const SkipDailyCheckinModal = () => {
  const { isOpen, onClose, onOpen, onSkipCheckin, onPostponeCheckin } = useSkipDailyCheckinModal();

  return (
    <>
      <Button className="skip-checkin-button" onClick={onOpen} link>
        Skip check-in
      </Button>
      <ModalWindow isOpen={isOpen} onToggle={onClose} title="Are you sure you want to skip daily check&#8209;in?">
        <div className="skip-checkin__message">
          <p>
            You can still complete check&#8209;in at a later timer but we recommend only skipping check&#8209;in if:
          </p>
          <ul>
            <li>There’s an immediate action you have to take</li>
            <li>You are not meant to be working today</li>
          </ul>
        </div>
        <div className="skip-checkin__buttons-wrapper">
          Remind me in 1 hour
          <Button variant="contained" sx={{ ml: '12px' }} onClick={onPostponeCheckin}>
            Confirm
          </Button>
        </div>
        <div className="skip-checkin__buttons-wrapper">
          <Button link sx={{ fontSize: '12px' }} onClick={onSkipCheckin}>
            Skip Check&#8209;In Completely
          </Button>
        </div>
      </ModalWindow>
    </>
  );
};

export default SkipDailyCheckinModal;
