/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { useEmployeeBackgroundChecksUrl } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';

const EmployeeBackgroundCheckBreadCrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useEmployeeBackgroundChecksUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Background Check" link={getListUrl()} />
      {children as React.ReactElement}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeBackgroundCheckBreadCrumbs;
