/* istanbul ignore file */
import React from 'react';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { CreateForm } from 'shared/uibuilder/form';
import useAccountService from 'crm/account/accountService';
import { useAccountUrl } from 'crm/account/index';
import CreateAccountBreadcrumbs from 'crm/account/createupdate/Create/Breadcrumbs';
import AccountForm from 'crm/account/createupdate/AccountForm';

const CreateAccount = () => {
  const { create, getValidationSchema } = useAccountService();
  const { getListUrl } = useAccountUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        address: {
          country: 'United States',
        },
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Account has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Account" breadcrumbs={<CreateAccountBreadcrumbs />}>
        <AccountForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateAccount;
