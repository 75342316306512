/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import useSkillBasedQueueService, { CREATE_SKILL_BASED_QUEUE } from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import SkillBasedQueueListLayout from 'erp/skillbasedqueue/List/SkillBasedQueueListLayout';
import { ASC } from 'shared/uibuilder/list/baseListHelper';

const SkillBasedQueueList = () => {
  const { search } = useSkillBasedQueueService();
  const { getCreateUrl } = useSkillBasedQueueUrl();

  return (
    <List getDataMethod={search} defaultSortOrder={ASC} defaultSortField="skillName">
      <ListPageLayout
        header={<ListHeader label="Skill Based Queues" icon="fa-coins" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Skill Based Queue"
            key="create-skill"
            permissionToCheck={CREATE_SKILL_BASED_QUEUE}
          />,
        ]}
        search={<SearchInput label="Skill Name" />}
      >
        <SkillBasedQueueListLayout />
      </ListPageLayout>
    </List>
  );
};

export default SkillBasedQueueList;
