import { useCallback, useState } from 'react';
import { size } from 'lodash';

const useCollapse = () => {
  const [collapseStates, setCollapseStates] = useState<Dictionary<boolean>>({});

  const collapseAll = useCallback(() => {
    setCollapseStates({});
  }, []);

  const expandAll = (blocksIds: string[]) => {
    const newStates: Dictionary<boolean> = {};

    blocksIds.forEach(id => {
      newStates[id] = true;
    });

    setCollapseStates(newStates);
  };

  const toggle = (blockId: string) => {
    setCollapseStates(prevState => ({ ...prevState, [blockId]: !prevState[blockId] }));
  };

  const isExpanded = (blockId: string): boolean => collapseStates[blockId] || false;

  const areAllItemsExpanded = (blocksIds: string[]): boolean => {
    const numberOfBlocks = blocksIds.length;

    return (
      size(collapseStates) === numberOfBlocks &&
      (size(collapseStates) !== numberOfBlocks || Object.values(collapseStates).every(value => value))
    );
  };

  const areAllItemsCollapsed = (): boolean => {
    return size(collapseStates) === 0 || Object.values(collapseStates).every(value => !value);
  };

  return {
    collapseAll,
    expandAll,
    toggle,
    isExpanded,
    areAllItemsExpanded,
    areAllItemsCollapsed,
  };
};

export default useCollapse;
