/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/index';
import React from 'react';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import useFinancialAnalyticNoteService, {
  DELETE_ASSET_NOTE_PERMISSION,
} from 'financialAnalytic/assets/Timeline/note/assetNoteService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink/index';
import { Collapsible, HtmlField } from 'shared/uibuilder/field';
import { useAssetUrl, useAssetId } from 'financialAnalytic/assets/AssetRouter';
import useAssetService from 'financialAnalytic/assets/useAssetService';

const NoteTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { invalidateCache } = useAssetService();
  const { deleteById } = useFinancialAnalyticNoteService();
  const { data } = useShowContext();
  const id = data.getValueBySource('id');
  const timelineId = data.getValueBySource('timelineId');
  const { getTimelineUrl } = useAssetUrl();
  const assetId = useAssetId();

  const buttons = [
    <DeleteTimelineRecordButton
      permissionToCheck={DELETE_ASSET_NOTE_PERMISSION}
      deleteMethod={async (noteId: string) => {
        await deleteById(noteId);
        invalidateCache();
      }}
      key="add"
      id={id}
      timelineId={timelineId}
      entityName="note"
    />,
  ];

  return (
    <BaseTimelineRecordLayout
      timelineType={TimelineType.NOTE}
      fields={
        <Collapsible>
          <ReplaceUrlWithLink>
            <HtmlField source="content" label="" />
          </ReplaceUrlWithLink>
        </Collapsible>
      }
      buttons={buttons}
      timelineFooter={() => null}
      basePathname={getTimelineUrl(assetId)}
      {...props}
    />
  );
};

export default NoteTimelineRecordLayout;
