/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EmailField, ListField, PhoneField, TextField } from 'shared/uibuilder/field';
import NameField from 'shared/uibuilder/field/NameField';
import React, { useState } from 'react';
import DateField from 'shared/uibuilder/field/DateField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import useEmployeeService, {
  EMPLOYEE_TYPES_ALIAS,
  READ_EMPLOYEE_ACCOUNTS_1C_ID,
  READ_EMPLOYEE_ACCOUNTS_AZURE_ID,
  READ_EMPLOYEE_CONTACT_INFORMATION,
  READ_EMPLOYEE_FAMILY_INFORMATION,
  READ_EMPLOYEE_IDENTITY_DOCUMENTS,
  READ_EMPLOYEE_MAIN_HR_INFORMATION,
  READ_EMPLOYEE_MAIN_PERSONAL_INFO,
  READ_EMPLOYEE_MILITARY_INFO,
  READ_EMPLOYEE_REQUISITES_INFORMATION,
} from 'erp/employee/employeeService';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import EditEmployeeButton from 'erp/employee/shared/button/EditEmployeeButton';
import AdditionalContactsField from 'erp/shared/field/AdditionalContactsField';
import InlineAddressField from 'shared/uibuilder/field/InlineAddressField';
import EmployeeMaritalStatusField from 'erp/employee/shared/field/EmployeeMaritalStatusField';
import EmployeeFamilyMembersField from 'erp/employee/shared/field/EmployeeFamilyMembersField';
import EmployeeMilitaryField from 'erp/employee/shared/field/EmployeeMilitaryField';
import EmployeePositionField from 'erp/employee/shared/field/EmployeePositionField';
import EmployeeResourcePoolField from 'erp/employee/shared/field/EmployeeResourcePoolField';
import EmployeeWorkplaceField from 'erp/employee/shared/field/EmployeeWorkplaceField';
import FinalizeEmployeeButton from 'erp/employee/shared/button/FinalizeEmployeeButton';
import useAuthorization from 'shared/authorization/authorizationService';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import EmployeeProfileBreadcrumbs from 'erp/employee/shared/EmployeeProfileBreadcrumbs';
import DeleteEmployeeButton from 'erp/employee/shared/button/DeleteEmployeeButton';
import CandidateLinkField from 'erp/candidate/shared/field/CandidateLinkField';
import { isPolishOffice } from 'erp/employee/office/officeService';
import EmployeeTypeField from 'erp/employee/shared/field/EmployeeTypeField';
import DocumentField from 'erp/employee/shared/field/DocumentField';
import ProvideMailboxConsentButton from 'erp/employee/mailboxConsent/shared/ProvideMailboxConsentButton';
import RevokeMailboxConsentButton from 'erp/employee/mailboxConsent/shared/RevokeMailboxConsentButton';

const EmployeeViewProfilePage = () => {
  const [isConsentChanged, setIsConsentChanged] = useState(false);
  const { getById } = useEmployeeService();
  const id = useEmployeeId();
  const { isGranted } = useAuthorization();

  const isRequisitesSectionVisible = employee =>
    employee.getValueBySource('employeeType') === EMPLOYEE_TYPES_ALIAS.CONTRACTOR &&
    isGranted(READ_EMPLOYEE_REQUISITES_INFORMATION);

  const isMilitaryInformationVisible = employee => {
    const isGrantedToReadEmployeeMilitaryInfo = isGranted(READ_EMPLOYEE_MILITARY_INFO);
    return isGrantedToReadEmployeeMilitaryInfo && !isPolishOffice(String(employee.getValueBySource('officeId')));
  };

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        headerButtons={[
          <EditEmployeeButton />,
          <FinalizeEmployeeButton />,
          <DeleteEmployeeButton />,
          <ProvideMailboxConsentButton isConsentChanged={isConsentChanged} setIsConsentChanged={setIsConsentChanged} />,
          <RevokeMailboxConsentButton isConsentChanged={isConsentChanged} setIsConsentChanged={setIsConsentChanged} />,
        ]}
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
        breadcrumbs={<EmployeeProfileBreadcrumbs />}
        hasSeparateSections
      >
        <ShowSection title="Main Information" hasAnchorLink isVisible={isGranted(READ_EMPLOYEE_MAIN_PERSONAL_INFO)}>
          <SectionRow>
            <TextField label="Latin Name" value={<NameField source="nameEn" />} />
            <TextField label="Local Name" value={<NameField source="nameLocal" />} />
            <DateField source="extendedInformation.dateOfBirth" label="Date of Birth" />
            <TextField source="gender" />
          </SectionRow>
          <SectionRow>
            <AttachmentsListField source="extendedInformation.photosIds" label="Photos" />
            <EmailField label="Syberry Email Address" source="workingEmail" />
            <CandidateLinkField
              nameSource="linkedCandidateId"
              source="linkedCandidateId"
              label="Linked Candidate"
              isVisible={data => data.getValueBySource('linkedCandidateId')}
            />
          </SectionRow>
        </ShowSection>

        <ShowSection
          isVisible={data =>
            !!data.getValueBySource('extendedInformation.identityDocuments') &&
            isGranted(READ_EMPLOYEE_IDENTITY_DOCUMENTS)
          }
          title="Identity Documents"
          hasAnchorLink
        >
          <ListField source="extendedInformation.identityDocuments" label="">
            <DocumentField />
          </ListField>
        </ShowSection>

        <ShowSection title="Contact Information" hasAnchorLink isVisible={isGranted(READ_EMPLOYEE_CONTACT_INFORMATION)}>
          <SectionRow>
            <PhoneField source="extendedInformation.personalPhoneNumber" label="Personal Phone Number" />
            <EmailField source="extendedInformation.personalEmail" label="Personal Email Address" />
            <PhoneField source="extendedInformation.otherPhoneNumber" label="Other Phone Number" />
            <AdditionalContactsField
              label="Other Contact Information"
              source="extendedInformation.additionalContactInfo"
            />
            <InlineAddressField source="extendedInformation.registrationAddress" label="Registration Address" />
            <InlineAddressField source="extendedInformation.homeAddress" label="Current location address" />
            <InlineAddressField source="extendedInformation.plannedMidTermLocation" label="Planned mid-term location" />
            <InlineAddressField source="extendedInformation.plannedPermLocation" label="Planned permanent location" />
            <DateField source="extendedInformation.locationUpdateDate" label="Date of location update" />
          </SectionRow>
        </ShowSection>

        <ShowSection title="Family" hasAnchorLink isVisible={isGranted(READ_EMPLOYEE_FAMILY_INFORMATION)}>
          <BigSectionRow>
            <EmployeeMaritalStatusField source="extendedInformation.maritalStatus" />
            <EmployeeFamilyMembersField
              isVisible={employee => employee.getValueBySource('extendedInformation.familyMembers')?.length}
              source="extendedInformation.familyMembers"
            />
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Military Information" hasAnchorLink isVisible={isMilitaryInformationVisible}>
          <EmployeeMilitaryField source="extendedInformation.militaryRecord" />
        </ShowSection>

        <ShowSection title="HR Information" hasAnchorLink isVisible={isGranted(READ_EMPLOYEE_MAIN_HR_INFORMATION)}>
          <SectionRow>
            <EmployeeTypeField label="Type" source="employeeType" />
            <EmployeePositionField label="Position" source="positionId" />
            <EmployeeResourcePoolField label="Resource Pool" source="resourcePoolId" />
            <TextField label="Resource Manager" source="resourceManagerName" />
            <EmployeeWorkplaceField label="Workplace" source="extendedInformation.hrInformation" />
            <TextField label="Access Card ID" source="extendedInformation.hrInformation.accessCardId" />
          </SectionRow>
        </ShowSection>

        <ShowSection
          title="Accounts"
          hasAnchorLink
          isVisible={isGranted(READ_EMPLOYEE_ACCOUNTS_1C_ID) || isGranted(READ_EMPLOYEE_ACCOUNTS_AZURE_ID)}
        >
          <SectionRow>
            <TextField
              label="1C ID"
              source="extendedInformation.financialInformation.financialOneCId"
              isVisible={isGranted(READ_EMPLOYEE_ACCOUNTS_1C_ID)}
            />
            <TextField label="Azure ID" source="azureId" isVisible={isGranted(READ_EMPLOYEE_ACCOUNTS_AZURE_ID)} />
          </SectionRow>
        </ShowSection>

        <ShowSection title="Requisites" hasAnchorLink isVisible={isRequisitesSectionVisible}>
          <SectionRow>
            <TextField label="Requisites" source="extendedInformation.requisitesInformation.requisites" />
            <AttachmentsListField label="Scans" source="extendedInformation.requisitesInformation.scansIds" />
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default EmployeeViewProfilePage;
