/* istanbul ignore file */

import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import SubmitFormButton from 'erp/employee/AccessLog/SubmitFormButton';
import budgetReportFiltersValidation from 'erp/budget/report/List/budgetReportFiltersValidation';
import { downloadFile } from 'shared/fileHelper';
import LoadingFormLayout from 'uibuilder/layout/form/input/LoadingFormLayout';
import useBudgetReportService from 'erp/budget/report/shared/budgetReportService';

const BudgetReportList = () => {
  const { getReport } = useBudgetReportService();

  return (
    <ListPageLayout header={<ListHeader label="Budgets Report" icon="fa-clock-o" />}>
      <FormTemplate
        submitFormFunc={data => getReport(data).then(resp => downloadFile(resp))}
        getValidationSchemaFunc={() => Promise.resolve(budgetReportFiltersValidation)}
        handleDirty={false}
      >
        <FormRow colXl={2}>
          <DateInput label="Period from" source="from" />
          <DateInput label="To" source="to" />
        </FormRow>

        <SubmitFormButton>Generate</SubmitFormButton>

        <LoadingFormLayout />
      </FormTemplate>
    </ListPageLayout>
  );
};

export default BudgetReportList;
