import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useResourceManagementApi } from 'api';

const CompetenciesFilter = ({ ...props }: any) => {
  const { sendGetRequest } = useResourceManagementApi();
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  const mapResults = (location: ResultResourceData) => ({
    value: location.name,
    label: location.name,
  });

  const loadData = async () => {
    const data = await sendGetRequest('/trainings/competencies');
    const jsonResults = await data.json();
    return {
      result: jsonResults,
    };
  };

  const onChangeCallback = (values: any) => {
    const competencies = values[props.source];

    if (rawOnChangeCallback) {
      rawOnChangeCallback({ [props.source]: competencies });
    }
  };

  return (
    <DropDownFilter {...props}>
      <LoadDropdown
        loadDataMethod={loadData}
        mapResults={mapResults}
        onChangeCallback={onChangeCallback}
        placeholder="Select Competencies"
        {...props}
      />
    </DropDownFilter>
  );
};

export default CompetenciesFilter;
