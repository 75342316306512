import { ResourceData, ResourceId } from './baseCrudService';
import { CrudService, CrudServiceProviderDecorator } from './index';
import useCacheService, { CacheAction } from 'shared/cache/cacheService';

export interface CachedCrudService extends CrudService {
  invalidateCache: () => CacheAction;
  addToCache: (id: ResourceId, entityData: ResourceData) => CacheAction;
}

const useCachedCrudService: CrudServiceProviderDecorator<CachedCrudService> = (
  useParentCrudService,
  { listResourceUrl, singleResourceUrl, apiService = null },
) => {
  const { addToCache, replaceCache, getValue, invalidateCache } = useCacheService(listResourceUrl);

  const {
    create: parentCreate,
    getById: parentGetById,
    update: parentUpdate,
    deleteById: parentDeleteById,
    ...rest
  } = useParentCrudService({ listResourceUrl, singleResourceUrl, apiService });

  const getById = async (id: ResourceId, ignoreCache = false) => {
    const cachedValue = getValue(id);
    if (!ignoreCache && cachedValue) {
      return cachedValue;
    } else {
      const entityData = await parentGetById(id);
      addToCache(id, entityData);
      return entityData;
    }
  };

  const update = async (id: ResourceId, request: ResourceData) => {
    const entityData = await parentUpdate(id, request);
    replaceCache(id, entityData);
    return entityData;
  };

  const create = async (request: ResourceData) => {
    const entityData = await parentCreate(request);
    addToCache(entityData.id, entityData);
    return entityData;
  };

  const deleteById = async (id: ResourceId) => {
    await parentDeleteById(id);
    invalidateCache();
  };

  return {
    create,
    getById,
    update,
    deleteById,
    ...rest,
    invalidateCache,
    addToCache,
  };
};

export default useCachedCrudService;
