/* istanbul ignore file */
import React from 'react';
import { oneOfType, string, func } from 'prop-types';
import { LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const OpportunityLinkField = ({ idValue, idSource, ...props }) => {
  const { getSingleEntityUrl } = useOpportunityUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue: getName } = useFieldHelper(props);

  const id = getId();

  return <LinkField {...props} isInternal linkText={getName()} value={getSingleEntityUrl(id)} />;
};

OpportunityLinkField.propTypes = {
  idValue: oneOfType([string, func]),
  idSource: string,
};

OpportunityLinkField.defaultProps = {
  idValue: '',
  idSource: undefined,
};

export default OpportunityLinkField;
