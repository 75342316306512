import EditVacancyButton from 'erp/recruitment/vacancy/shared/button/EditVacancyButton';
import { CREATE_VACANCY, DISPLAY_UPDATE_BUTTON, UPDATE_VACANCY } from 'erp/recruitment/vacancy/shared/vacancyService';
import CopyVacancyButton from 'erp/recruitment/vacancy/shared/button/CopyVacancyButton';
import DeleteVacancyButton from 'erp/recruitment/vacancy/shared/button/DeleteVacancyButton';
import PublishVacancyButton from 'erp/recruitment/vacancy/shared/button/PublishVacancyButton';
import ViewVacancyPageMenu from 'erp/recruitment/vacancy/Show/ViewVacancyPageMenu';
import SingleVacanciesBreadcrumbs from 'erp/recruitment/vacancy/shared/SingleVacanciesBreadcrumbs';
import VacancyEntityHeader from 'erp/recruitment/vacancy/shared/VacancyEntityHeader';
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';

type ShowVacancyPageLayoutProps = {
  children: React.ReactElement[] | React.ReactElement;
};

const ShowVacancyPageLayout = ({ children }: ShowVacancyPageLayoutProps) => {
  return (
    <ShowPageLayout
      headerButtons={[
        <EditVacancyButton key="edit-vacancy" icon={<i className="fa fa-edit" />} permissionToCheck={UPDATE_VACANCY} />,
        <CopyVacancyButton key="copy-vacancy" permissionToCheck={CREATE_VACANCY} />,
        <DeleteVacancyButton key="delete-vacancy" />,
        <PublishVacancyButton
          isVisible={vacancy => vacancy.status === 'OPEN'}
          source="alias"
          key="publish-vacancy"
          permissionToCheck={DISPLAY_UPDATE_BUTTON}
        />,
      ]}
      menu={<ViewVacancyPageMenu />}
      breadcrumbs={<SingleVacanciesBreadcrumbs />}
      entityHeader={<VacancyEntityHeader />}
      hasSeparateSections
    >
      {children}
    </ShowPageLayout>
  );
};

export default ShowVacancyPageLayout;
