/* istanbul ignore file */
import React from 'react';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/index';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import MonthFilter from 'shared/uibuilder/list/filter/MonthFilter';
import Box from 'uibuilder/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { MAX_SM_WIDTH } from 'oneStop/deviceDetectorService';
import PerformPayrollDistributionButton from 'erp/payroll/payrollDistribution/shared/button/PerformPayrollDistributionButton';

const PayrollDistributionStaticFilters = ({ title, sx = {} }: { title?: React.ReactNode; sx?: SxProps<Theme> }) => {
  const filters = (
    <Filters>
      <FiltersLayout>
        <MonthFilter label="" source="period" isRequired hasTodayButton todayButtonLabel="Current month" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Box
      sx={{
        [`@media (min-width: ${MAX_SM_WIDTH}px)`]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '& [data-testId="remove-btn"], & .filters-reset-btn': {
          display: 'none',
        },
        '.filter-item-wrapper': {
          marginRight: 0,
          marginBottom: 0,
        },
        '@media (min-width: 768px)': {
          '.rdtPicker': {
            right: 0,
          },
        },
        ...sx,
      }}
    >
      {title}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          button: {
            mr: 2,
            mb: '20px',
          },
        }}
      >
        <PerformPayrollDistributionButton />
        {filters}
      </Box>
    </Box>
  );
};

export default PayrollDistributionStaticFilters;
