import React from 'react';
import Typography from '../../../../uibuilder/Typography';
import Box from '../../../../uibuilder/Box';
import DataGridLayout from '../../../../shared/uibuilder/list/layout/DataGridLayout';
import ApprovePotentialMatchGroups from '../shared/button/ApprovePotentialMatchGroups';
import RejectReconciliationButton from '../shared/button/RejectReconciliationButton';
import { BadgeField, TextField } from '../../../../shared/uibuilder/field';
import BankTransactionsField from '../shared/field/BankTransactionsField';
import DavinciTransfersField from '../shared/field/DavinciTransfersField';
import ReconciliationStatusBadge from '../shared/badge/ReconciliationStatusBadge';
import { InputData } from '../../../../shared/uibuilder/list/builder/useListCrud';
import { UpdateListDataAndReloadGroupsProps } from './ReconciliationListDataContext';

type ReconciliationGroupsContentsProps = {
  bankData: InputData;
  davinciData: InputData;
  isSensitiveDataIsShown?: boolean;
  updateListDataAndReloadGroups: (data: UpdateListDataAndReloadGroupsProps) => void;
  reloadData: () => void;
};

const ReconciliationGroupsContents = ({
  updateListDataAndReloadGroups,
  davinciData,
  reloadData,
  bankData,
  isSensitiveDataIsShown,
}: ReconciliationGroupsContentsProps) => {
  return (
    <>
      <Typography variant="h5">Reconciliation groups</Typography>
      <Box
        sx={{
          position: 'relative',
          minHeight: '50px',
          width: '100%',
          mt: 1,
          '& table.MuiTable-root': {
            minWidth: '1000px',
          },
        }}
      >
        <DataGridLayout
          buttons={[
            <ApprovePotentialMatchGroups
              bankData={bankData}
              davinciData={davinciData}
              sourceBankTransactions="bankTransactions"
              sourceDavinciTransfers="davinciTransfers"
              statusSource="status"
              idSource="id"
              execute={updateListDataAndReloadGroups}
            />,
            <RejectReconciliationButton statusSource="status" idSource="id" execute={reloadData} />,
          ]}
        >
          <TextField source="groupAlias" label="Group Alias" width="20%" />
          <BankTransactionsField
            source="bankTransactions"
            label="Bank Transactions"
            isSensitiveDataIsShown={isSensitiveDataIsShown}
          />
          <DavinciTransfersField
            source="davinciTransfers"
            label="Davinci Transfers"
            isSensitiveDataIsShown={isSensitiveDataIsShown}
          />
          <BadgeField
            badges={[<ReconciliationStatusBadge source="status" label="Status" />]}
            label="Status"
            width={160}
          />
          <TextField source="skippedReason" />
        </DataGridLayout>
      </Box>
    </>
  );
};

export default ReconciliationGroupsContents;
