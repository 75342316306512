/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import EmployeeDropdown from 'shared/uibuilder/form/input/EmployeeDropdown';

export interface EmployeeSuggestionFilterProps extends BaseFilterProps {
  filter: any;
}

const EmployeeDropdownFilter = ({ filter, ...props }: EmployeeSuggestionFilterProps) => {
  const { getLabel, getOnChangeCallback } = useInputHelper(props);

  return (
    <DropDownFilter {...props}>
      <EmployeeDropdown
        filter={filter}
        className="dropdown-with-title"
        label={getLabel() || ''}
        onChangeCallback={getOnChangeCallback()}
        showLoader
        {...props}
      />
    </DropDownFilter>
  );
};

export default EmployeeDropdownFilter;
