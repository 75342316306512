import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import { LinkField } from 'shared/uibuilder/field';
import React from 'react';

const VariablesLinkField = ({ source, bpmnJs, ...props }: any) => {
  const { getValue: getExecutionId } = useFieldHelper({ source });

  const { getVariablesUrl } = useProcessInstanceUrl();
  const processId = useProcessInstanceId();
  const variableContext = getExecutionId();
  const url = getVariablesUrl(processId, variableContext);

  return <LinkField {...props} linkText="Variables" value={url} isInternal />;
};

export default VariablesLinkField;
