/* istanbul ignore file */
import { getVisibility } from 'shared/uibuilder/helper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

interface FieldGroupProps {
  isVisible: TypeOrFunction<boolean>;
  children: React.ReactElement | React.ReactElement[];
}

const FieldGroup = ({ isVisible, children }: FieldGroupProps) => {
  const { data } = useShowContext();

  const isSectionsVisible = getVisibility(data, isVisible);

  return isSectionsVisible && children;
};

export default FieldGroup;
