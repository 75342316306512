/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { DOCUMENTS } from 'erp/employee/employeeService';

const DocumentTypeField = props => {
  return <EnumField {...props} label="Document Type" options={DOCUMENTS} />;
};

export default DocumentTypeField;
