import useContextHelper from 'shared/uibuilder/contextHelper';
import useArtifactService from 'artifact/artifactService';
import { CROP_SIZES } from 'artifact/const';
import React from 'react';
import { string } from 'prop-types';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';

export const DEFAULT_AVATAR = '/images/male.svg';

const BigAvatar = ({ source, defaultAvatar }) => {
  const { getFromAnyContext } = useContextHelper();
  const { getThumbnail } = useArtifactService();

  const photoId = getFromAnyContext(source);

  const artifactSrc = useAsyncValue(() => getThumbnail(photoId, CROP_SIZES.SIZE_500));

  const mainPhotoSrc = artifactSrc || defaultAvatar;

  return artifactSrc !== false ? (
    <img src={mainPhotoSrc} alt="Avatar" style={{ maxWidth: '199px', maxHeight: '199px' }} />
  ) : (
    <InputLoader />
  );
};

BigAvatar.propTypes = {
  source: string.isRequired,
  defaultAvatar: string,
};

BigAvatar.defaultProps = {
  defaultAvatar: DEFAULT_AVATAR,
};

export default BigAvatar;
