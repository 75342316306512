/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useEmployeeBackgroundChecksUrl } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import { CREATE_BACKGROUND_CHECK } from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

const CreateEmployeeBackgroundCheckButton = () => {
  const { getCreateUrl } = useEmployeeBackgroundChecksUrl();

  return (
    <CreateButton
      variant="contained"
      label="Add Background Check"
      url={getCreateUrl()}
      permissionToCheck={CREATE_BACKGROUND_CHECK}
    />
  );
};

export default CreateEmployeeBackgroundCheckButton;
