/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useQualificationUrl } from 'erp/employee/qualification/QualificationRouter';

const QualificationsListBreadcrumbs = ({ children }: { children?: React.ReactElement | React.ReactElement[] }) => {
  const { getListUrl } = useQualificationUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem link={getListUrl()} entity="Qualifications" />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default QualificationsListBreadcrumbs;
