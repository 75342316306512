/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field/index';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

interface CurrencyFieldProps extends FieldProps {
  currencySource: string;
  amountSource: string;
  quantitySource: string;
  emptyValue?: string;
  purchaseSubTotals?: Dictionary<any>;
}

const PurchaseTotalAmountField = ({
  source,
  currencySource,
  amountSource,
  quantitySource,
  emptyValue,
  purchaseSubTotals = {},
  ...props
}: CurrencyFieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getQuantityValue } = useFieldHelper({ source: quantitySource });
  const subTotals = (purchaseSubTotals[getValue()] as any) || {};
  const value = subTotals[amountSource] || 0;
  const currency = subTotals[currencySource];
  const quantity = getQuantityValue() || 1;

  return (
    <TextField
      {...props}
      source={source}
      value={
        value || !emptyValue
          ? `${getWithThousandsSeparator(value * +quantity)}${CURRENCIES[currency] || ''}`
          : emptyValue
      }
    />
  );
};

export default PurchaseTotalAmountField;
