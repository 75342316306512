import React, { useEffect, useState } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps, Option } from './dropdownHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import InputLoader from '../../InputLoader';
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';
import useInputHelper from './inputHelper';

interface LoadDropdownProps extends DefaultDropdownProps {
  loadDataMethod: () => ResultResourceData;
  mapResults: (data: any) => Option;
  onChangeCallback?: any;
  showLoader?: boolean;
  components?: SelectComponentsConfig<any, any, any>;
  deps?: any[];
  isClearValueByUpdateDeps?: boolean;
}

const LoadDropdown = ({
  loadDataMethod,
  mapResults,
  onChangeCallback,
  showLoader = false,
  disabled,
  deps = [],
  isClearValueByUpdateDeps = false,
  ...props
}: LoadDropdownProps) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getRawOnChangeCallback } = useInputHelper({ source: props.source });
  const parentOnChange = getRawOnChangeCallback() || (() => {});

  useEffect(() => {
    const loadData = async () => {
      try {
        const { result } = await loadDataMethod();

        setOptions(result.map(mapResults));
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, ...deps]);

  useEffect(() => {
    if (isClearValueByUpdateDeps) {
      parentOnChange({
        [props.source]: null,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearValueByUpdateDeps, ...deps]);

  if (showLoader && loading) {
    return <InputLoader />;
  } else {
    return (
      <Dropdown
        isLoading={loading}
        options={options}
        onChangeCallback={onChangeCallback}
        disabled={disabled}
        {...props}
      />
    );
  }
};

export default LoadDropdown;
