import React from 'react';
import { useHiringUrl } from '../../../hiring/HiringRouter';
import LinkField from 'shared/uibuilder/field/LinkField';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { TextField } from 'shared/uibuilder/field';

type HiringRequestFieldProps = {
  value?: { id: StringOrNumber; specialisation: string; level: string };
};

const HiringRequestField = ({ value }: HiringRequestFieldProps) => {
  const { getSingleEntityUrl } = useHiringUrl();

  if (!value) {
    return null;
  }

  return (
    <SectionRow>
      <LinkField label="Specialization" value={getSingleEntityUrl(value.id)} linkText={value.specialisation} />
      <TextField label="Level" value={value.level} />
    </SectionRow>
  );
};

export default HiringRequestField;
