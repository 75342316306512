import React from 'react';
import LinkField from 'shared/uibuilder/field/LinkField';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

type EVacanciesFiledProps = {
  value?: string;
  label?: string;
  isLast?: boolean;
};

const VacanciesFiled = ({ value, label, isLast }: EVacanciesFiledProps) => {
  const { getSingleEntityUrl } = useVacancyUrl();
  const url = getSingleEntityUrl(value);
  return <LinkField value={url} linkText={value + (isLast ? '' : ', ')} label={label} />;
};

export default VacanciesFiled;
