import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BaseFilterProps, FilterCondition, useFilterContext } from './FilterContext';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import useMonthPickerFilter from './useMonthPickerFilter';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { useListContext } from '../ListContext';

export interface MonthFilterProps extends BaseFilterProps {
  dateFormat?: string;
  canBeInFuture?: boolean;
  isRequired?: boolean;
  hasTodayButton?: boolean;
  todayButtonLabel?: string;
  label?: string;
}

const MonthFilter = ({
  source,
  dateFormat = DATE_FORMAT.MONTH,
  canBeInFuture = false,
  label,
  isRequired = false,
  ...props
}: MonthFilterProps) => {
  const { setLoading } = useListContext();
  const { filterBy, getValue, apply: applyFilters } = useFilterContext();
  const [shouldApplyFilter, setShouldApplyFilter] = useState(false);
  const {
    validateAndShowErrorMessages,
    getErrors,
    fromApiToFrontFormat,
    getMaxDate,
    formatDateForAPI,
  } = useMonthPickerFilter(dateFormat, canBeInFuture, source);
  // filterValue stores text date in FE format
  const [filterValue, setFilterValue] = useState(fromApiToFrontFormat(getValue(source, FilterCondition.EQ)));
  const MAX_DATE = getMaxDate();

  /**
   * @param filterPair - object like {"eq": "2020-08-05"} means equal
   */
  const onChangeCallback = (filterPair: Dictionary<string>) => {
    const newFilterValue = filterPair;

    if (filterPair) {
      const inputName = Object.keys(filterPair)[0] as FilterCondition;
      const textDate = filterPair[inputName];
      const isInvalid = validateAndShowErrorMessages(FilterCondition.EQ, textDate, isRequired);

      if (!isInvalid) {
        if (textDate !== filterValue) {
          setShouldApplyFilter(true);
        }

        if (textDate) {
          newFilterValue[inputName] = formatDateForAPI(textDate);
        }
      }

      setFilterValue(textDate);
      filterBy({ [source]: newFilterValue });
    }
  };

  /*
   * Initial validation (for example needed when url contains filter object)
   */
  useEffect(() => {
    if (filterValue) {
      validateAndShowErrorMessages(FilterCondition.EQ, fromApiToFrontFormat(filterValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setLoading && shouldApplyFilter) {
      setLoading(true);
      applyFilters();
      setShouldApplyFilter(false);
    }

    // Suppressed warnings because we only need to call useEffect callback if updated value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, shouldApplyFilter]);

  const formatSourceDate = (textDate: string) => {
    const formattedDate = moment(textDate, DATE_FORMAT.API_ONLY_MONTH, true);

    if (formattedDate.isValid()) {
      return formattedDate.format(dateFormat);
    }
    return textDate;
  };

  return (
    <DateInput
      {...props}
      label={label}
      source={FilterCondition.EQ}
      onChangeCallback={onChangeCallback}
      value={filterValue}
      dateFormat={DATE_FORMAT.MONTH}
      maxDate={MAX_DATE}
      errorMessages={getErrors(FilterCondition.EQ)}
      formatSourceDate={formatSourceDate}
      readOnly
    />
  );
};

export default MonthFilter;
