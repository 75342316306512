/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import AssignAssetOwnerForm from 'financialAnalytic/assets/shared/AssignAssetOwnerForm';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { UPDATE_ASSET_ASSIGNMENT } from 'financialAnalytic/assets/useAssetService';

const AssignAssetOwnerButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data } = useShowContext();
  const { isGranted } = useAuthorization();
  const assignmentDetails = data.getValueBySource('assignmentDetails');
  const hasOwner = !!assignmentDetails?.currentOwner;

  const closeModal = () => {
    setModalOpen(false);
  };

  return isGranted(UPDATE_ASSET_ASSIGNMENT) ? (
    <>
      <Button
        outline
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
      >
        {hasOwner ? 'Reassign' : 'Assign'}
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={hasOwner ? 'Reassign Asset' : 'Assign Asset'}
        onToggle={closeModal}
        hasCancelButton={false}
      >
        <AssignAssetOwnerForm closeModal={closeModal} modalOpen={modalOpen} />
      </ModalWindow>
    </>
  ) : null;
};

export default AssignAssetOwnerButton;
