/* istanbul ignore file */
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import React from 'react';
import { NameInput, PhoneInput } from 'shared/uibuilder/form/input';
import EmployeePhotoUploaderInput from 'artifact/shared/input/EmployeePhotoUploaderInput';
import CandidateAgeInput from 'erp/candidate/shared/input/CandidateAgeInput';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import AdditionalContactListInput from 'erp/shared/input/AdditionalContactListInput';
import { bool } from 'prop-types';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CandidateStatusDropdown from 'erp/candidate/shared/input/CandidateStatusDropdown';
import BooleanRadiosGroup from 'shared/uibuilder/form/input/BooleanRadiosGroup';
import { ENGLISH_LEVEL_OPTIONS } from 'erp/candidate/shared/candidateService';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import ResumeWysiwygInput from 'erp/candidate/shared/input/ResumeWysiwygInput';
import useSkillsService from 'erp/candidate/skills/skillsService';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import CountryDropdown from 'shared/uibuilder/form/input/CountryDropdown';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const CandidateForm = ({ isCreateForm, hasAnchorLink, hasStatusField }) => {
  const { searchByName } = useSkillsService();

  return (
    <>
      <FormSection title="Main Information" hasAnchorLink={hasAnchorLink}>
        <NameInput source="name" middleNameLabel="Patronymic Name" />
        <FormRow>
          <BooleanRadiosGroup source="isStudent" label="Is it a student?" />
          <ClearAfterUnmountWrapper isVisible={candidate => candidate.isStudent}>
            <TextInput source="syberryEmail" label="Syberry Email Address" />
          </ClearAfterUnmountWrapper>
        </FormRow>
        {hasStatusField && (
          <FormRow colMd={3} colXl={3}>
            <CandidateStatusDropdown source="status" />
          </FormRow>
        )}
        <BigFormRow>
          <EmployeePhotoUploaderInput label="Photos" source="photosIds" canAddFromClipboard />
        </BigFormRow>
        <FormRow>
          <DateInput source="dateOfBirth" label="Date of Birth" />
          <CandidateAgeInput source="dateOfBirth" label="Age" />

          <EnumDropdown
            source="englishLevel"
            label="English Level"
            options={ENGLISH_LEVEL_OPTIONS}
            placeholder="Choose English Level"
          />
        </FormRow>
      </FormSection>
      <FormSection title="Contact Information" hasAnchorLink={hasAnchorLink}>
        <FormRow>
          <PhoneInput source="personalPhoneNumber" />
          <TextInput source="personalEmail" label="Personal Email Address" />
          <PhoneInput source="otherPhoneNumber" />
          <CountryDropdown source="residenceCountry" label="Country of Residence" />
        </FormRow>

        <AdditionalContactListInput source="additionalContacts" />
      </FormSection>
      <FormSection title="Skills" hasAnchorLink={hasAnchorLink}>
        <BigFormRow>
          <SkillsInput source="skills" loadSuggestionsMethod={searchByName} />
        </BigFormRow>
      </FormSection>
      <FormSection title="Resume" hasAnchorLink={hasAnchorLink} isVisible={isCreateForm}>
        <BigFormRow>
          <CandidateResumeUploaderInput label="Candidate resume" source="resume.resumeArtifactIds" />
        </BigFormRow>
        <BigFormRow>
          <ResumeWysiwygInput
            label="Timeline comment"
            labelHint="(Will be added to candidate timeline along with resume)"
            artifactsSource="resume.resumeArtifactIds"
            source="resume.description"
            isVisible={data => !(data?.resume?.resumeArtifactIds.length === 0)}
          />
        </BigFormRow>
      </FormSection>
    </>
  );
};

CandidateForm.propTypes = {
  isCreateForm: bool,
  hasAnchorLink: bool,
  hasStatusField: bool,
};

CandidateForm.defaultProps = {
  isCreateForm: false,
  hasAnchorLink: false,
  hasStatusField: false,
};

export default CandidateForm;
