/* istanbul ignore file */
import React from 'react';
import ServiceBreadcrumbs from 'erp/service/shared/ServiceBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateServiceBreadcrumbs = () => {
  return (
    <ServiceBreadcrumbs>
      <CreateBreadcrumbItem entity="Service" />
    </ServiceBreadcrumbs>
  );
};

export default CreateServiceBreadcrumbs;
