import React, { useMemo } from 'react';
import Select, { SingleValue } from 'react-select';
import BaseInputLayout, { getBaseInputLayoutProps, getCommonInputProps } from './BaseInputLayout';
import { IndicatorsContainer } from './DropdownComponents';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { GroupOptionType, SelectInputLayoutType, SelectInputOptions } from 'shared/uibuilder/form/input/SelectInput';
import getDropdownLayoutCommonProps from './dropdownLayoutHelper';
import './DropdownLayout.scss';

function instanceOfGroupedOptions(object: any): object is GroupOptionType {
  return 'options' in object;
}

const getSelectedOption = (selectOptions: SelectInputOptions[], value: string): Option | undefined => {
  let allOptions: Option[] = [];

  if (instanceOfGroupedOptions(selectOptions[0] || {})) {
    allOptions = (selectOptions as GroupOptionType[]).reduce(
      (result: Option[], { options = [] }) => [...result, ...options],
      [],
    );
  } else {
    allOptions = selectOptions as Option[];
  }

  return allOptions.find(({ value: optionValue }: Option) => optionValue === value);
};

const SelectInputLayout: SelectInputLayoutType = ({
  placeholder,
  options = [],
  multiple,
  onChangeCallback,
  noOptionsMessage,
  filterOption,
  value: initialValue,
  ...props
}) => {
  const { selectClassName, styles, classNamePrefix } = getDropdownLayoutCommonProps(props);

  const selectedValue = useMemo(() => getSelectedOption(options, initialValue || ''), [options, initialValue]);

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <Select
        {...getCommonInputProps(props)}
        value={selectedValue || ''}
        className={selectClassName}
        classNamePrefix={classNamePrefix}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        onChange={(selectedOption: SingleValue<string | Option>) => {
          if (onChangeCallback) {
            onChangeCallback({ target: { value: (selectedOption as Option)?.value } });
          }
        }}
        noOptionsMessage={noOptionsMessage}
        styles={styles}
        options={options}
        filterOption={filterOption as any}
        components={{
          IndicatorsContainer,
        }}
      />
    </BaseInputLayout>
  );
};

export default SelectInputLayout;
