export interface TreeNodeProps {
  id?: string;
  name: string;
  attributes?: Dictionary<string | Dictionary<string> | Dictionary<string>[]>;
  children?: TreeNodeProps[];
  parentNode?: TreeNodeProps;
  nodeType?: string;
  isPublicService?: boolean;
}

export interface TreeProps {
  data: TreeNodeProps[];
  nodeChildren?: (nodeData: TreeNodeProps, depth: number) => JSX.Element;
  onNodeClick?: (nodeData: TreeNodeProps) => void;
  height?: number;
}

const countNodes = (data: TreeNodeProps[] = []) => {
  let counter = 0;

  data.forEach(item => {
    counter += 1;

    if (item.children?.length) {
      counter += countNodes(item.children);
    }
  });

  return counter;
};

export const getDefaultZoom = (data: TreeNodeProps[]) => {
  const nodesCount = countNodes(data);
  let factor = 1;

  if (nodesCount < 20) {
    factor = 0.85;
  } else if (nodesCount < 60) {
    factor = 0.55;
  } else {
    factor = 0.4;
  }
  return factor;
};
