import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { useAccountUrl } from 'crm/account/index';
import { useContactUrl } from 'crm/contact/ContactRouter';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import { useRbsUrl } from 'erp/rbs/RbsRouter';

const useCustomHtmlTagHelper = () => {
  const { getSingleEntityUrl: getEmployeeUrl } = useEmployeeUrl();
  const { getSingleEntityUrl: getCandidateUrl } = useCandidateUrl();
  const { getSingleEntityUrl: getLeadUrl } = useLeadUrl();
  const { getTimelineUrl: getOpportunityUrl } = useOpportunityUrl();
  const { getSingleEntityUrl: getAccountUrl } = useAccountUrl();
  const { getSingleEntityUrl: getContactUrl } = useContactUrl();
  const { getSingleEntityUrl: getEmployeeOfferUrl } = useEmployeeOfferUrl();
  const { getSingleEntityUrl: getCandidateOfferUrl } = useCandidateOfferUrl();
  const { getSingleEntityUrl: getRbsUrl } = useRbsUrl();

  const replaceTag = (text, tag, urlBuilder) => {
    const regexp = new RegExp(`<${tag}\\sid=['|"]([\\S\\d]+?)['|"]((>(.*?)</${tag}>)|(/>))`, 'mg');
    let result = text;
    let matched;

    // eslint-disable-next-line no-cond-assign
    while ((matched = regexp.exec(text))) {
      const fullTag = matched[0];
      const id = matched[1];
      const value = matched[4];

      const newTag = `<a href="${urlBuilder(id)}" target="_blank">${value || id}</a>`;

      result = result.replace(fullTag, newTag);
    }

    return result;
  };

  const mapTags = description => {
    const tagsToMap = [
      ['employee', getEmployeeUrl],
      ['candidate', getCandidateUrl],
      ['lead', getLeadUrl],
      ['opportunity', getOpportunityUrl],
      ['contact', getContactUrl],
      ['account', getAccountUrl],
      ['employeeoffer', getEmployeeOfferUrl],
      ['candidateoffer', getCandidateOfferUrl],
      ['rbsnode', getRbsUrl],
    ];

    let result = description;

    tagsToMap.forEach(config => {
      result = replaceTag(result, ...config);
    });

    return result;
  };

  return {
    mapTags,
  };
};

export default useCustomHtmlTagHelper;
