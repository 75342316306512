/* istanbul ignore file */
import oneStopCareerDevelopmentReviewValidation from 'erp/careerdevelopment/createupdate/oneStopCareerDevelopmentValidation';
import { useBusinessEngineApi, useKernelApi } from 'api';
import useBaseCrudService, { ResourceData } from 'shared/crud/baseCrudService';
import { EMPLOYEES_PATH, useEmployeeId } from 'erp/employee/EmployeesRouter';

const useCareerDevelopmentReviewService = () => {
  const { sendPostRequest } = useBusinessEngineApi();
  const { sendGetRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_PATH}/${employeeId}`;
  const { search } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}/careerDevelopmentReviews/:id`,
    listResourceUrl: `${parentResourceUrl}/careerDevelopmentReviews`,
    apiService: useKernelApi,
  });

  const getOneStopValidationSchema = () => Promise.resolve(oneStopCareerDevelopmentReviewValidation);

  const create = async (formData: ResourceData) => {
    const createRequest = {
      completedAssessments: formData.completedAssessments,
      earlyStartReason: formData.earlyStartReason,
      attachmentId: formData.attachmentsIds[0] || null,
    };

    const response = await sendPostRequest(`/me/careerDevelopmentReviews`, createRequest);
    return response.json();
  };

  const getCareerDevelopmentNextReviewDate = async (employeeAlias: string) => {
    const response = await sendGetRequest(`/employees/${employeeAlias}/careerDevelopmentReviews/nextReviews`);
    return response.json();
  };

  return {
    getOneStopValidationSchema,
    create,
    getCareerDevelopmentNextReviewDate,
    search,
  };
};

export default useCareerDevelopmentReviewService;
