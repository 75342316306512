/* istanbul ignore file */
import React from 'react';

import useCandidateNoteService from '../candidateNoteService';
import CreateForm from '../../shared/form/CreateForm';

const CreateNoteForm = props => {
  return <CreateForm service={useCandidateNoteService} {...props} />;
};

export default CreateNoteForm;
