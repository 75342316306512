/* istanbul ignore file */
import React, { useEffect } from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { NewCompetency } from 'erp/qualification/newCompetency/newCompetencyService';
import { FormattedTextField, TextField } from 'shared/uibuilder/field';
import Collapse from 'shared/uibuilder/Collapse';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import EmptyQualificationLevelMessage from 'erp/qualification/model/shared/EmptyQualificationLevelMessage';
import useCollapse from 'shared/uibuilder/Collapse/useCollapse';
import CollapseControls from 'shared/uibuilder/Collapse/CollapseControls';
import Typography from 'uibuilder/Typography';
import 'erp/qualification/newCompetency/Show/NewCompetencyQualificationModel.scss';

const NewCompetencyQualificationModel = ({ data, title }: { data: NewCompetency[]; title: string }) => {
  const { expandAll, collapseAll, toggle, isExpanded, areAllItemsExpanded, areAllItemsCollapsed } = useCollapse();
  const competencyIds = data.map(({ id }) => String(id));

  useEffect(() => {
    collapseAll();
  }, [title, collapseAll]);

  const collapseControls = (
    <CollapseControls
      isExpandButtonShown={!areAllItemsExpanded(competencyIds)}
      isCollapseButtonShown={!areAllItemsCollapsed()}
      expandAll={() => expandAll(competencyIds)}
      collapseAll={collapseAll}
    />
  );

  return (
    <div className="syberry-qualification-model table-wrapper--md">
      <div className="mt-3 d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
        <Typography variant="h4" className="mb-4">
          {title}
        </Typography>
        <div className="mb-4">{collapseControls}</div>
      </div>
      <div className="mb-3">
        {data.map(({ id, name: levelTitle, levels }) => (
          <Collapse
            key={`qualification-${id}`}
            header={
              <div className="m-0 collapse-title-wrapper">
                <h4 className="collapse-title m-0">{levelTitle}</h4>
              </div>
            }
            isExpanded={isExpanded(String(id))}
            toggle={() => {
              toggle(String(id));
            }}
          >
            {levels.length ? (
              <ControlledList data={{ items: levels, totalElements: levels.length, totalPages: 1 }}>
                <DataGridLayout showTotal={false} tableWrapperClassName="formatted-text-list">
                  <FormattedTextField source="levelNumber" label=" " width={30} format={(str: string) => `L${str}`} />
                  <TextField source="indicator" label="Indicator" width={300} />
                  <TextField source="assessmentGuidelines" label="Assessment Guidelines" width={350} />
                </DataGridLayout>
              </ControlledList>
            ) : (
              <EmptyQualificationLevelMessage />
            )}
          </Collapse>
        ))}
      </div>
      <div className="d-flex justify-content-md-end mt-4">{collapseControls}</div>
    </div>
  );
};

export default NewCompetencyQualificationModel;
