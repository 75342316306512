/* istanbul ignore file */
import Loading from 'shared/uibuilder/Loading';
import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const LoadingFormLayout = () => {
  const { isSubmitEnabled } = useFormContext();

  return !isSubmitEnabled ? <Loading /> : null;
};

export default LoadingFormLayout;
