import { useState } from 'react';
import { useKernelApi } from 'api';
import { EMPLOYEES_PATH, useEmployeeId } from 'erp/employee/EmployeesRouter';
import employeeOfferValidation from 'erp/employee/offers/createupdate/employeeOfferValidation';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useBaseCrudService, { ResourceId } from 'shared/crud/baseCrudService';
import { OFFER_COMPENSATION_URL } from 'erp/candidate/offercompensation/shared/candidateOfferService';

const useEmployeeOfferService = () => {
  const [offerData, setOfferData] = useState(null);
  const { sendPostRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_PATH}/${employeeId}`;
  const getValidationSchema = () => Promise.resolve(employeeOfferValidation);
  const { addMessage } = useMessageService();

  const { getById: initialGetById, ...baseCrudRequests } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${OFFER_COMPENSATION_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${OFFER_COMPENSATION_URL}`,
    apiService: useKernelApi,
  });

  const getSensitiveDataByIds = async (ids: []) => {
    const url = `${parentResourceUrl}${OFFER_COMPENSATION_URL}/sensitive`;

    try {
      const response = await sendPostRequest(url, ids);
      const result = await response.json();

      return result;
    } catch (err) {
      addMessage(new ErrorMessage("Can't get compensation data"));

      return null;
    }
  };

  const getById = async (id: ResourceId) => {
    if (offerData) {
      return offerData;
    }

    const data = await initialGetById(id);
    const offerCompensations = data.offerCompensations || [];
    const sensitiveData = await getSensitiveDataByIds(offerCompensations.map((offer: any) => offer.sensitiveDataId));

    if (sensitiveData === null) {
      return offerData;
    }

    const combinedData = {
      ...data,
      offerCompensations: offerCompensations.map((offer: any) => ({
        ...offer,
        ...sensitiveData[offer.sensitiveDataId],
      })),
    };

    setOfferData(combinedData);

    return combinedData;
  };

  return {
    ...baseCrudRequests,
    getValidationSchema,
    getSensitiveDataByIds,
    getById,
  };
};

export default useEmployeeOfferService;
