import React from 'react';
import RejectButton from 'financialAnalytic/shared/button/RejectButton';
import useWasteService, { REJECT_WASTE, WASTE_STATUSES } from '../../useWasteService';
import { useWasteId } from '../../WasteRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const RejectWasteButton = ({ statusSource }: { statusSource: string }) => {
  const id = useWasteId();
  const { rejectWaste } = useWasteService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== WASTE_STATUSES.REGISTERED) {
    return null;
  }

  return (
    <RejectButton
      displayMessage="Do you really want to reject Waste?"
      deleteMethod={rejectReason => rejectWaste(id, rejectReason)}
      successMessage="The Waste has been successfully rejected."
      errorMessage="Can't reject Waste."
      permissionToCheck={REJECT_WASTE}
    />
  );
};

export default RejectWasteButton;
