import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { ListField } from 'shared/uibuilder/field';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import SingleTrainingInstancesBreadcrumbsItem from './SingleTrainingInstancesBreadcrumbsItem';
import TrainingInstanceHeader from '../shared/TrainingInstanceHeader';
import { TRAINING_INSTANCE_STATUS_OPTIONS } from '../useTrainingInstancesService';
import EnumField from 'shared/uibuilder/field/EnumField';
import DateField from 'shared/uibuilder/field/DateField';
import LinkTrainingField from '../shared/fields/LinkTrainingField';
import EmployeeLinkTrainingInstancesField from '../shared/fields/EmployeeLinkTrainingInstancesField';
import EditTrainingInstancesButton from '../shared/Buttons/EditTrainingInstancesButton';
import DeleteTrainingInstancesButton from '../shared/Buttons/DeleteTrainingInstancesButton';

const ViewTrainingInstancesContent = () => {
  return (
    <ShowPageLayout
      entityHeader={<TrainingInstanceHeader />}
      breadcrumbs={<SingleTrainingInstancesBreadcrumbsItem />}
      headerButtons={[<EditTrainingInstancesButton />, <DeleteTrainingInstancesButton />]}
    >
      <ShowSection title="Main information">
        <SectionRow>
          <LinkTrainingField source="training" label="Training Program" />
          <EnumField isSortable options={TRAINING_INSTANCE_STATUS_OPTIONS} source="status" label="Status" />
        </SectionRow>

        <SectionRow>
          <ListField source="mentors">
            <EmployeeLinkTrainingInstancesField />
          </ListField>

          <ListField source="students">
            <EmployeeLinkTrainingInstancesField />
          </ListField>
        </SectionRow>

        <SectionRow>
          <DateField isSortable source="startDate" label="Start Date" />
          <DateField isSortable source="endDate" label="End Date" />
        </SectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewTrainingInstancesContent;
