/* istanbul ignore file */
export default {
  noteText: {
    type: 'string',
    required: true,
    maxSize: 65000,
  },
  attachmentsIds: {
    type: 'array',
  },
};
