/* istanbul ignore file */
import React from 'react';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import { CREATE_JOURNAL_ENTRY } from 'financialAnalytic/journalEntry/useJournalEntryService';
import EditButton from 'uibuilder/button/EditButton';

const EditJournalEntryButton = () => {
  const { getUpdateEntityUrl } = useJournalEntryUrl();

  return (
    <EditButton
      path={getUpdateEntityUrl}
      label="Edit"
      key="edit-journal-entry"
      permissionToCheck={CREATE_JOURNAL_ENTRY}
      outline
    />
  );
};

export default EditJournalEntryButton;
