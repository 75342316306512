/* istanbul ignore file */
import React from 'react';
import { snakeCase } from 'lodash';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';
import Dropdown from 'erp/employee/shared/input/OfficeDropdown';

const OfficeDropdown = (props: any) => {
  const mapResults = (office: any) => ({
    value: snakeCase(office.name).toUpperCase(),
    label: OFFICE_NAMES[office.name],
  });

  return <Dropdown {...props} mapResults={mapResults} />;
};

export default OfficeDropdown;
