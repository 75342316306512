/* istanbul ignore file */
import React from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import { getFullName } from 'crm/shared/input/ownerHelper';

export interface EmployeeDropdownProps {
  source: string;
  filter: any;
  isSimple?: boolean;
  className?: any;
  label?: any;
  onChangeCallback?: any;
  showLoader?: boolean;
  props?: any;
}

const EmployeeDropdown = ({ filter, onChangeCallback, isSimple = true, ...props }: EmployeeDropdownProps) => {
  const { search, advancedSearch } = useEmployeeSuggestionsService();

  const loadEmployees = async () => {
    return search({
      filter,
    });
  };

  const loadEmployeesAdvanced = async () => {
    return advancedSearch({
      filter,
    });
  };

  const mapResults = (employee: any) => ({
    value: employee.alias,
    label: employee.nameEn && getFullName(employee.nameEn),
    disabled: !employee.alias,
  });

  return (
    <LoadDropdown
      loadDataMethod={isSimple ? loadEmployees : loadEmployeesAdvanced}
      mapResults={mapResults}
      onChangeCallback={onChangeCallback}
      {...props}
    />
  );
};

export default EmployeeDropdown;
