/* istanbul ignore file */
// libs
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// styles
import './SidebarMenu.scss';

interface SidebarMenuItemProps {
  linkUrl: string;
  name: string;
  icon?: string | React.ReactElement;
  exact?: boolean;
  isVisible?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  staticItem?: boolean;
}

const SidebarMenuItem = ({
  linkUrl,
  icon,
  name,
  isVisible = true,
  exact = false,
  children,
  staticItem = false,
}: SidebarMenuItemProps) =>
  isVisible ? (
    <Fragment key={linkUrl}>
      <NavLink
        to={linkUrl}
        className={`tabs-btn ${staticItem ? 'static' : ''}`}
        activeClassName="tabs-btn--active"
        exact={exact}
      >
        {icon && (typeof icon === 'string' ? <i className={`fa fa-${icon}`} aria-hidden /> : icon)}
        <span>{name}</span>
      </NavLink>

      {children ? <div className="left-menu-subitems">{children}</div> : null}
    </Fragment>
  ) : null;

export default SidebarMenuItem;
