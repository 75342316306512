/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import useEmployeeNewCompetencyService, {
  EmployeeNewCompetencyLevel,
} from 'erp/employee/newQualification/shared/employeeNewCompetencyService';
import NewCompetencyStatusDropdown from 'erp/employee/newQualification/shared/input/NewCompetencyStatusDropdown';

interface ChangeCompetencyStatusFormProps {
  closeModal: () => void;
  competencyLevel: EmployeeNewCompetencyLevel;
  levelId: StringOrNumber;
  modalOpen: boolean;
}

const ChangeCompetencyStatusForm = ({
  competencyLevel,
  levelId,
  closeModal,
  modalOpen,
}: ChangeCompetencyStatusFormProps) => {
  const employeeId = useEmployeeId() || '';
  const { updateCompetencyLevel } = useEmployeeNewCompetencyService();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      submitFormFunc={formData => updateCompetencyLevel({ employeeId, competencyLevelId: levelId, formData })}
    >
      <FormSection>
        <NewCompetencyStatusDropdown label="Status" source="status" />
      </FormSection>
    </ModalForm>
  );
};

export default ChangeCompetencyStatusForm;
