/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import { METRICS, MetricsProps } from 'erp/employee/performanceReport/usePerformanceReportService';
import PerformanceHeatmapCell from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapCell';
import PerformanceHeatmapLegend from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapLegend';
import PerformanceHeatmapDaysHeader from './PerformanceHeatmapDaysHeader';
import PerformanceHeatmapGraphLayout from 'erp/employee/performanceReport/shared/PerformanceHeatmap/layout/PerformanceHeatmapGraphLayout';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

interface PerformanceHeatmapGraphProps {
  labels: Array<METRICS | 'DIVIDER'>;
  currentMonth: string;
  metrics: MetricsProps;
}

const PerformanceHeatmapGraph = (props: PerformanceHeatmapGraphProps) => {
  const { currentMonth, metrics } = props;
  const endOfMonth = moment(currentMonth).endOf('month');

  const getItems = (metric: string) => {
    const values = metrics[metric];
    if (values) {
      return values.map(value => <PerformanceHeatmapCell key={`${metric}-${value.day}`} {...value} />);
    }

    const items = [];
    const day = moment(currentMonth)
      .clone()
      .startOf('month');
    const month = day.month();

    while (month === day.month()) {
      const formattedDay = day.format(DATE_FORMAT.INPUT_VALUE);
      items.push(<PerformanceHeatmapCell key={`${metric}-${formattedDay}`} day={formattedDay} />);
      day.add(1, 'd');
    }

    return items;
  };

  return (
    <PerformanceHeatmapGraphLayout
      {...props}
      heatmapHeader={<PerformanceHeatmapDaysHeader currentMonth={currentMonth} />}
      getItems={getItems}
      gridTemplateColumns={`repeat(${endOfMonth.date()}, var(--square-size))`}
      legend={<PerformanceHeatmapLegend />}
    />
  );
};

export default PerformanceHeatmapGraph;
