/* istanbul ignore file */
import React from 'react';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import { CreateForm } from 'shared/uibuilder/form';
import { useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';
import CreateEmployeeContractBreadCrumbs from 'erp/employee/contracts/createupdate/Create/Breadcrumbs';
import useEmployeeContractsService from 'erp/employee/contracts/shared/employeeContractsService';
import EmployeeContractForm from 'erp/employee/contracts/createupdate/EmployeeContractForm';
import useContractsErrorMap from 'erp/employee/contracts/errorHandlers';
import ContractNotStrictRulesHandler from 'erp/employee/contracts/shared/ContractNotStictRulesHandler';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useCacheService from 'shared/cache/cacheService';
import { EMPLOYEES_PATH } from 'erp/employee/EmployeesRouter';

const CreateEmployeeContract = () => {
  const { getListUrl } = useEmployeeContractUrl();
  const { create, getValidationSchema } = useEmployeeContractsService();
  const errorsMap = useContractsErrorMap();
  const { data } = useShowContext();
  const { invalidateCache } = useCacheService(EMPLOYEES_PATH);

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        hasProbationPeriod: true,
        indefinite: false,
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Contract has been successfully created.',
        execute: () => {
          invalidateCache();
        },
      }}
      getValidationSchemaFunc={getValidationSchema}
      errorsMap={errorsMap}
    >
      <ContractNotStrictRulesHandler errorMap={errorsMap} />
      <CreatePageLayout
        breadcrumbs={<CreateEmployeeContractBreadCrumbs />}
        title="Create: Contract"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <EmployeeContractForm isCreateForm employeeAlias={data.alias} />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateEmployeeContract;
