/* istanbul ignore file */

import React from 'react';

import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EnumField, HtmlField, TextField } from 'shared/uibuilder/field';
import useVacancyPublicationService, {
  UPDATE_VACANCY_PUBLICATION,
  VACANCY_PUBLICATION_PLATFORMS,
  VACANCY_PUBLICATION_STATUSES,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import { useVacancyPublicationId } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';
import EditVacancyPublicationButton from 'erp/recruitment/vacancy/publication/shared/button/EditVacancyPublicationButton';
import DeleteVacancyPublicationButton from 'erp/recruitment/vacancy/publication/shared/button/DeleteVacancyPublicationButton';
import ViewVacancyPublicationPageMenu from 'erp/recruitment/vacancy/publication/Show/ViewVacancyPublicationPageMenu';
import SingleVacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/shared/SingleVacancyPublicationBreadcrumbs';
import VacancyPublicationEntityHeader from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationEntityHeader';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import VacancyPublicationDetailsField from 'erp/recruitment/vacancy/shared/field/VacancyPublicationDetailsField';
import VacancyPublicationField from 'erp/recruitment/vacancy/shared/field/VacancyPublicationField';
import useFeatureToggle, { Features } from 'featuretoggle';

const ViewVacancyPublication = () => {
  const id = useVacancyPublicationId();
  const { getById } = useVacancyPublicationService();
  const { isFeatureEnabled } = useFeatureToggle();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        buttons={[
          <EditVacancyPublicationButton
            key="edit-vacancy-publication"
            source="id"
            icon={<i className="fa fa-edit" />}
            permissionToCheck={UPDATE_VACANCY_PUBLICATION}
          />,
          <DeleteVacancyPublicationButton key="delete-vacancy-publication" />,
        ]}
        menu={<ViewVacancyPublicationPageMenu />}
        breadcrumbs={<SingleVacancyPublicationBreadcrumbs />}
        entityHeader={<VacancyPublicationEntityHeader />}
      >
        <ShowSection title="Vacancy Publication Information">
          <SectionRow>
            <VacancyLinkField source="vacancy.alias" label="Vacancy ID" />
            <TextField source="name" />
            <FieldListLayout
              source="englishLevels"
              label="Relevant English Levels"
              isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
            >
              <VacancyPublicationField />
            </FieldListLayout>
            <FieldListLayout
              source="countryOfResidences"
              label="Relevant Country Of Residences"
              isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
            >
              <VacancyPublicationField />
            </FieldListLayout>
            <EnumField source="type" label="Publication Platform" options={VACANCY_PUBLICATION_PLATFORMS} />
            <EnumField source="status" label="Status" options={VACANCY_PUBLICATION_STATUSES} />
          </SectionRow>
        </ShowSection>
        <ShowSection
          title="Additional Vacancy Publication Fields"
          isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
        >
          <FieldListLayout source="formFields" hasSeparator>
            <VacancyPublicationDetailsField />
          </FieldListLayout>
        </ShowSection>
        <ShowSection title="Public Description">
          <HtmlField source="description" label="" />
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewVacancyPublication;
