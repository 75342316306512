/* istanbul ignore file */
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import React from 'react';
import useRoute from 'shared/routing/useRoute';
import QualificationModelContext from './QualificationModelContext';
import QualificationModelViewPage from './Show';
import { READ_QUALIFICATION_MODEL } from './qualificationModelService';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';

export const QM_PATH = '/qualification-models';
export const LATEST_QM_PATH = '/qualification-models/latest';
export const SPECIALIZATION_ID_SOURCE = 'specializationId';

export const useQualificationModelRoute = () => {
  return useRoute({ listRoute: QM_PATH, singleEntityRoute: `${QM_PATH}/:version/:${SPECIALIZATION_ID_SOURCE}?` });
};

export const useQualificationModelUrl = () => {
  return useEntityUrl({
    baseLink: QM_PATH,
    idSource: 'version',
  });
};

export const useSingleQualificationModelUrl = () => {
  const { getSingleEntityUrl } = useQualificationModelUrl();

  return useEntityUrl({
    baseLink: getSingleEntityUrl(),
    idSource: SPECIALIZATION_ID_SOURCE,
  });
};

export const useQualificationModelVersion = () => {
  const params: Dictionary<string> = useParams();

  return params.version || 'latest';
};

export const useSpecializationId = () => {
  return useParams<Dictionary<string>>()[SPECIALIZATION_ID_SOURCE];
};

const QualificationModelRouter = () => {
  const { singleEntityRoute } = useQualificationModelRoute();

  return (
    <ProtectedRouter basePath={QM_PATH}>
      <Switch>
        <GlobalProtectedRoute path={singleEntityRoute} globalPermissionToCheck={READ_QUALIFICATION_MODEL} exact>
          <QualificationModelContext permissionToCheck="READ">
            <QualificationModelViewPage />
          </QualificationModelContext>
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

QualificationModelRouter.getRouterPath = () => QM_PATH;

export default QualificationModelRouter;
