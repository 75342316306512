import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FilterCondition, useFilterContext } from './FilterContext';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import {
  DAY_END,
  DAY_START,
  GREATER_OR_EQUAL,
  LESS_OR_EQUAL,
  DEFAULT_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  useDateTimeService,
} from 'shared/uibuilder/dateService';
import { DateRangeFilterProps, DateRangeFilterLayoutType } from 'shared/uibuilder/list/filter/DateRangeFilter';
import useDateRangeFilter from './useDateRangeFilter';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import Typography from 'uibuilder/Typography';
import './DateTimeRangeFilter.scss';

const DateTimeRangeFilter = ({
  source,
  dateFormat = DEFAULT_FORMAT,
  dateTimeFormat = DEFAULT_DATE_TIME_FORMAT,
  canBeInFuture = false,
  label,
}: DateRangeFilterProps) => {
  const { DateRangeFilterLayout } = useUiTheme<DateRangeFilterLayoutType>();
  const { filterBy, getValue, filters, clearAndApplyFilter } = useFilterContext();
  // filterValue stores text date in API format
  const [filterValue, setFilterValue] = useState(filters[source]);
  const [shouldUpdateFilter, setShouldUpdateFilter] = useState(true);
  const { formatDateForAPI } = useDateTimeService();
  const {
    validateAndShowErrorMessages,
    getErrors,
    fromApiToFrontFormat,
    localDate,
    getMaxDate,
    getMaxGeDate,
    getMinGeDate,
    getMaxLeDate,
    getMinLeDate,
  } = useDateRangeFilter(dateFormat, dateTimeFormat, canBeInFuture, source);
  const MAX_DATE = getMaxDate();
  const hasError = !isEmpty([...getErrors(GREATER_OR_EQUAL), ...getErrors(LESS_OR_EQUAL)]);
  const invalidClass = hasError ? 'is-invalid' : '';

  /**
   * @param filterPair - object like {"ge": "2020-08-05"} means greater or equal
   */
  const onChangeCallback = (filterPair: Dictionary<string>) => {
    const newFilterValue: Dictionary<string | undefined> = filterPair;

    setShouldUpdateFilter(true);

    if (filterPair) {
      const inputName = Object.keys(filterPair)[0] as FilterCondition;
      const textDate = filterPair[inputName];
      const validationResult = validateAndShowErrorMessages(inputName, textDate);
      if (textDate && !validationResult) {
        if (inputName === GREATER_OR_EQUAL) {
          newFilterValue[inputName] = formatDateForAPI(textDate, DAY_START);
        }
        if (inputName === LESS_OR_EQUAL) {
          newFilterValue[inputName] = formatDateForAPI(textDate, DAY_END);
        }
      }
      setFilterValue(prevFilterValue => ({
        ...prevFilterValue,
        ...newFilterValue,
      }));
    }
  };

  /*
   * Initial validation (for example needed when url contains filter object)
   */
  useEffect(() => {
    if (filterValue) {
      validateAndShowErrorMessages(GREATER_OR_EQUAL, fromApiToFrontFormat(filterValue[GREATER_OR_EQUAL]));
      validateAndShowErrorMessages(LESS_OR_EQUAL, fromApiToFrontFormat(filterValue[LESS_OR_EQUAL]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shouldUpdateFilter) {
      return;
    }

    const filterValueWithoutEmpty: Dictionary<string> = {};

    Object.entries(filterValue || {}).forEach(entry => {
      const [condition, value] = entry;

      if (value) {
        filterValueWithoutEmpty[condition] = value;
      }
    });

    filterBy({
      [source]: filterValueWithoutEmpty,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const leValue = getValue(source, LESS_OR_EQUAL);
  const geValue = getValue(source, GREATER_OR_EQUAL);

  const onResetFilterCallback = () => {
    setShouldUpdateFilter(false);
    setFilterValue({});
    clearAndApplyFilter(source);
  };

  return (
    <div className="datetimerange-input">
      <DateRangeFilterLayout
        label={label}
        leValue={leValue}
        geValue={geValue}
        onChangeCallback={onChangeCallback}
        source={source}
        className={invalidClass}
        onResetFilterCallback={onResetFilterCallback}
      >
        <DateInput
          label={`${label} From`}
          source={GREATER_OR_EQUAL}
          onChangeCallback={onChangeCallback}
          value={fromApiToFrontFormat(geValue)}
          dateFormat={dateFormat}
          maxDate={getMaxGeDate() || (leValue && localDate(fromApiToFrontFormat(leValue))) || MAX_DATE}
          minDate={getMinGeDate()}
          errorMessages={getErrors(GREATER_OR_EQUAL)}
        />
        <Typography variant="body1" sx={{ margin: '8px 16px 10px', alignSelf: 'center' }}>
          —
        </Typography>
        <DateInput
          source={LESS_OR_EQUAL}
          onChangeCallback={onChangeCallback}
          value={fromApiToFrontFormat(leValue)}
          maxDate={getMaxLeDate() || MAX_DATE}
          label={`${label} To`}
          dateFormat={dateFormat}
          minDate={getMinLeDate() || (geValue && localDate(fromApiToFrontFormat(geValue)))}
          errorMessages={getErrors(LESS_OR_EQUAL)}
        />
      </DateRangeFilterLayout>
    </div>
  );
};

export default DateTimeRangeFilter;
