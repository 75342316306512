import React, { useEffect, useState } from 'react';
import AutosuggestComponent, { RenderSuggestionsContainerParams, SuggestionSelectedEventData } from 'react-autosuggest';
import classNames from 'classnames';
import DefaultFilterInputLayout from './DefaultFilterInputLayout';
import BaseInputErrors from 'uibuilder/layout/form/input/BaseInputErrors';
import { AutosuggestionDropdownLayoutType, Suggestion } from 'shared/uibuilder/form/input/AutosuggestDropdown';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import { COMMON_INPUT_LAYOUT_DEFAULT_PROPS, getCommonInputProps } from 'uibuilder/layout/form/input/BaseInputLayout';
import {
  getSuggestionsWarning,
  renderSuggestion,
  getSuggestionValue,
} from 'uibuilder/layout/form/input/AutosuggestionDropdownLayout';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import Loader from 'shared/uibuilder/SmallLoader';
import './AutosuggestionDropdownLayout.scss';

const AutosuggestionDropdownLayout: AutosuggestionDropdownLayoutType = ({
  suggestions,
  suggestionsLoaded,
  suggestionsTotalPages,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected: parentOnSuggestionSelected,
  loading,
  noResultMessage,
  resultsPerPage,
  onChangeCallback,
  label,
  errorMessageMapper,
  errorMessages,
  ...props
}) => {
  const { apply, clearAndApplyFilter } = useFilterContext();
  const inputProps = getCommonInputProps(props);
  const [displayedValue, setDisplayedValue] = useState<string>(inputProps.value || '');
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const hasError = errorMessages && errorMessages.length > 0;
  const invalidClass = hasError ? ' is-invalid' : '';

  const toggleMenu = () => {
    setIsMenuOpened(prevValue => !prevValue);
  };

  const resetFilter = () => {
    setDisplayedValue('');
    parentOnSuggestionSelected(
      {} as any,
      { suggestion: { id: '', text: '' }, suggestionValue: '' } as SuggestionSelectedEventData<Suggestion>,
    );
    clearAndApplyFilter(inputProps.name);
  };

  useEffect(() => {
    if (isMenuOpened && displayedValue) {
      setIsMenuOpened(false);
      apply();
    }
    // Suppressed warnings because we only need to call useEffect callback is displayedValue was updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedValue]);

  const onSuggestionSelected = (
    event: React.FormEvent<object>,
    suggestionData: SuggestionSelectedEventData<Suggestion>,
  ) => {
    setDisplayedValue(suggestionData.suggestion.text);
    parentOnSuggestionSelected(event, suggestionData);
  };

  const renderSuggestionsContainer = ({ containerProps, children }: RenderSuggestionsContainerParams) => {
    return (
      <div {...containerProps} className={classNames('position-static border-0', containerProps.className)}>
        {suggestions.length === 0 && suggestionsLoaded && (
          <div className="react-autosuggest__no-result">{noResultMessage}</div>
        )}
        {suggestionsTotalPages > 1 && (
          <div className="react-autosuggest__warning-message">{getSuggestionsWarning(resultsPerPage)}</div>
        )}
        {children}
      </div>
    );
  };

  return (
    <DefaultFilterInputLayout
      source={inputProps.name}
      label={label}
      displayedValue={displayedValue}
      isOpen={isMenuOpened}
      toggleMenu={toggleMenu}
      className={classNames('autosuggest-button-dropdown', invalidClass)}
      onResetFilterCallback={resetFilter}
    >
      <div className={classNames('full-width-container', 'search-filter-control-wrapper')}>
        <AutosuggestComponent
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...inputProps,
            value: inputProps.value || '',
            onChange: onChangeCallback,
            className: classNames(inputProps.className, 'search-filter-control', {
              'search-filter-control--loading': loading,
            }),
            placeholder: inputProps.placeholder || `Search ${label}`,
            autoFocus: isMenuOpened,
          }}
        />
        <SearchIcon className="search-filter-control-icon" />
        {loading ? <Loader className="react-autosuggest-filter--loader" /> : null}
      </div>

      <BaseInputErrors errorMessages={errorMessages} errorMessageMapper={errorMessageMapper} />
    </DefaultFilterInputLayout>
  );
};

AutosuggestionDropdownLayout.defaultProps = {
  ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
};

export default AutosuggestionDropdownLayout;
