/* istanbul ignore file */
import React from 'react';
import { oneOfType, string, func } from 'prop-types';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';
import { useAccountUrl } from 'crm/account/index';

const AccountSearchLinkField = ({ idValue, idSource, ...props }) => {
  const { getSingleEntityUrl } = useAccountUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue } = useFieldHelper(props);

  const id = getId();
  const name = getValue();
  const linkText = name || id;

  return <SearchLinkField {...props} isInternal value={linkText} url={getSingleEntityUrl(id)} />;
};

AccountSearchLinkField.propTypes = {
  idValue: oneOfType([string, func]),
  idSource: string,
};

AccountSearchLinkField.defaultProps = {
  idValue: '',
  idSource: undefined,
};

export default AccountSearchLinkField;
