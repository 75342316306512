/* istanbul ignore file */
import FieldBadgeProps from 'shared/uibuilder/badge/FieldBadgeProps';
import React from 'react';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

export interface VacancyPublishedOnBadgeProps extends FieldBadgeProps {
  source: string;
  platform: string;
}

const VacancyPublishedOnBadge = ({ text, source, platform, ...props }: VacancyPublishedOnBadgeProps) => {
  const { getValue: getPublications } = useFieldHelper({ source });
  const publication = ((getPublications() as unknown) as any[]).find(p => p.type === platform);

  return (
    <CustomBadge
      text={text}
      color={publication && publication.status === 'OPEN' ? 'success' : 'neutralLight'}
      {...props}
    />
  );
};

export default VacancyPublishedOnBadge;
