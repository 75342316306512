/* istanbul ignore file */
import React from 'react';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { UpdateForm } from 'shared/uibuilder/form';
import EmployeeBackgroundCheckForm from 'erp/employee/backgroundcheck/createupdate/EmployeeBackgroundCheckForm';
import UpdateEmployeeBackgroundCheckBreadCrumbs from 'erp/employee/backgroundcheck/createupdate/Update/Breadcrumbs';
import {
  useEmployeeBackgroundCheckId,
  useEmployeeBackgroundChecksUrl,
} from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import useEmployeeBackgroundCheckService from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

const UpdateEmployeeBackgroundCheck = () => {
  const { update, getById, getValidationSchema } = useEmployeeBackgroundCheckService();
  const { getListUrl } = useEmployeeBackgroundChecksUrl();
  const id = useEmployeeBackgroundCheckId();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      entityId={id}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Background Check has been successfully saved.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <UpdatePageLayout
        title="Edit: Background Check"
        breadcrumbs={<UpdateEmployeeBackgroundCheckBreadCrumbs />}
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <EmployeeBackgroundCheckForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateEmployeeBackgroundCheck;
