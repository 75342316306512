import React, { useState } from 'react';
import { isArray } from 'lodash';
import List from 'shared/uibuilder/list/SimpleList';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import RbsTree from 'erp/rbs/shared/tree/RbsTree';
import EditRbsSubNodeButton from 'erp/rbs/shared/button/EditRbsSubNodeButton';
import { TreeNodeProps } from 'shared/uibuilder/Tree/treeHelpers';
import {
  hasAccessToCreateAction,
  hasAccessToEditAction,
  RbsNodeType,
  UPDATE_RBS_TREE_NODE,
} from 'erp/rbs/shared/rbsService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import RbsListViewMode, { RbsViewMode } from 'erp/rbs/shared/RbsListViewMode';
import RbsListLayout from 'erp/rbs/List/RbsListLayout';
import RbsNodeActionButton from 'erp/rbs/shared/button/RbsNodeActionButton';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import CreateRbsSubnodeButton from 'erp/rbs/shared/button/CreateRbsSubnodeButton';

const RbsTreeField = ({
  source,
  children,
  className = '',
}: {
  source?: string;
  children?: React.ReactNode;
  className?: string;
}) => {
  const { getValue } = useFieldHelper({ source });
  const data = useShowContext()?.data;
  const [activeMode, setActiveMode] = useState(RbsViewMode.TREE);
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(getUserAlias);
  const { isGranted } = useAuthorization();

  const getData = async () => {
    if (source) {
      let result = getValue() as any;

      if (!isArray(result)) {
        result = [result];
      }

      return {
        result,
      };
    }

    return {
      result: [data],
    };
  };

  return (
    <List getDataMethod={getData}>
      <>
        <div className={`d-flex justify-content-end ${className}`}>
          {children}
          <RbsListViewMode onModeChange={newMode => setActiveMode(newMode)} />
        </div>

        {activeMode === RbsViewMode.TREE ? (
          <RbsTree
            height={455}
            nodeChildren={(rd3tNodeProps: TreeNodeProps) => {
              const { nodeType } = rd3tNodeProps;

              return nodeType === RbsNodeType.PROJECT && !isGranted(UPDATE_RBS_TREE_NODE) ? (
                <CreateRbsSubnodeButton
                  id={rd3tNodeProps.id}
                  name={rd3tNodeProps.name}
                  isSmall
                  hasAccessToCreate={hasAccessToCreateAction(rd3tNodeProps, userAlias)}
                />
              ) : (
                <EditRbsSubNodeButton
                  id={rd3tNodeProps.id as string}
                  permissionToCheck={hasAccessToEditAction(rd3tNodeProps, userAlias) ? null : UPDATE_RBS_TREE_NODE}
                />
              );
            }}
            permissionToCheck={UPDATE_RBS_TREE_NODE}
          />
        ) : (
          <div className="responsibilities-details-list">
            <RbsListLayout
              permissionToCheck={UPDATE_RBS_TREE_NODE}
              buttons={[<RbsNodeActionButton permissionToCheck={UPDATE_RBS_TREE_NODE} hasEditButtonsOnly />]}
            />
          </div>
        )}
      </>
    </List>
  );
};

export default RbsTreeField;
