/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_APPLICATION, APPLICATION_PATH } from 'erp/recruitment/applications/shared/applicationService';
import ApplicationsList from 'erp/recruitment/applications/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useApplicationRoute = () => {
  return useRoute({ listRoute: APPLICATION_PATH });
};

export const useApplicationUrl = () => {
  return useEntityUrl({
    baseLink: APPLICATION_PATH,
  });
};

export const useApplicationId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id;
};

const ApplicationsRouter = () => {
  const { listRoute } = useApplicationRoute();

  return (
    <ProtectedRouter basePath={APPLICATION_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_APPLICATION}>
          <ApplicationsList />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

ApplicationsRouter.getRouterPath = () => APPLICATION_PATH;

export default ApplicationsRouter;
