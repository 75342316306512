/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField, DateField } from 'shared/uibuilder/field';
import { EmployeeLinkField } from 'erp/employee';
import ArtifactStorageFileField from 'artifactStorage/shared/field/ArtifactStorageFileField';
import ArtifactStorageTypeIcon from 'artifactStorage/shared/field/ArtifactStorageTypeIcon';
import ArtifactStorageAdditionalInfoField from 'artifactStorage/shared/field/ArtifactStorageAdditionalInfoField';
import ArtifactStorageHashTooltip from 'artifactStorage/shared/ArtifactStorageHashTooltip';
import ArtifactStorageSizeField from 'artifactStorage/shared/field/ArtifactStorageSizeField';
import DownloadArtifactButton from 'artifactStorage/shared/button/DownloadArtifactButton';
import ArtifactStorageOneFileLink from 'artifactStorage/shared/field/ArtifactStorageOneFileLink';

const ArtifactStorageFilesListLayout = () => {
  return (
    <DataGridLayout
      tableWrapperClassName="artifact-storage-table-wrapper"
      buttons={[<DownloadArtifactButton source="file.artifact" />]}
    >
      <ArtifactStorageOneFileLink source="file.id" label="ID" width={65} />
      <ArtifactStorageTypeIcon label="" source="type" width={45} />
      <ArtifactStorageFileField label="Name" source="file.artifact" />
      <DateField source="file.createdAt" label="Created At" />
      <EmployeeLinkField nameSource="file.createdByName" source="file.createdById" label="Created By" />
      <TextField
        source="file.artifact.hash"
        label={
          <>
            Hash <ArtifactStorageHashTooltip />
          </>
        }
      />
      <ArtifactStorageSizeField source="file.artifact.size" label="Size" />
      <ArtifactStorageAdditionalInfoField
        source="file.metaInformation"
        additionalSource="file.artifactRelation"
        label="Additional Information"
      />
    </DataGridLayout>
  );
};

export default ArtifactStorageFilesListLayout;
