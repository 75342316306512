/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field/index';
import { RbsNodeType } from '../rbsService';

export const RbsNodeTypeLabels: Dictionary<string> = {
  [RbsNodeType.PROJECT]: 'Project',
  [RbsNodeType.QUEUE]: 'Queue',
};

const RbsTypeField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);

  return <TextField {...props} value={RbsNodeTypeLabels[getValue()]} />;
};

export default RbsTypeField;
