import { Dropdown } from 'shared/uibuilder/form/input';
import React from 'react';
import { StatusTypes, StatusTypesLabels } from '../../ProjectStatusesService';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

interface ProjectStatusTypeFilterProps extends DefaultDropdownProps {
  value?: string;
}

const ProjectStatusTypeFilter = (props: ProjectStatusTypeFilterProps) => {
  return (
    <DropDownFilter {...props}>
      <Dropdown
        {...props}
        placeholder="Select status type"
        options={[
          { value: StatusTypes.PLANNING, label: StatusTypesLabels.PLANNING },
          { value: StatusTypes.DEMO, label: StatusTypesLabels.DEMO },
        ]}
      />
    </DropDownFilter>
  );
};

export default ProjectStatusTypeFilter;
