/* istanbul ignore file */
import React from 'react';
import { ButtonProps } from 'uibuilder/button/Button';
import LinkButton from 'uibuilder/button/LinkButton';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface CopyAssignmentButtonProps extends ButtonProps {
  value?: StringOrNumber;
  permissionToCheck?: string;
}

const CopyAssignmentButton = ({ icon, permissionToCheck, ...props }: CopyAssignmentButtonProps) => {
  const { getCopyEntityUrl } = useAssignmentUrl();
  const { getValue: getId } = useFieldHelper(props);
  const id = getId();
  const { isGranted } = useAuthorization();

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <LinkButton {...props} url={getCopyEntityUrl(id)} startIcon={icon}>
      Copy
    </LinkButton>
  ) : null;
};

export default CopyAssignmentButton;
