/* istanbul ignore file */
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import Message from 'shared/message/Message';
import Paper from 'uibuilder/Paper';
import Loading from 'shared/uibuilder/Loading/index';
import HandbookMenu, { MAX_SM_WIDTH } from 'handbook/shared/HandbookMenu';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import HandbookSubpagesListLayout from 'handbook/Show/HandbookPage/HandbookSubpagesListLayout';
import ControlledShow from 'shared/uibuilder/show/ControlledShow';
import useHandbookPageService from './handbookPageService';
import * as domUtils from 'shared/uibuilder/domUtils';
import HandbookSearchInput from 'handbook/shared/input/HandbookSearchInput';
import SectionWithScrollToHash from 'shared/uibuilder/layout/SectionWithScrollToHash';
import './HandbookPage.scss';
import Page404 from 'shared/uibuilder/Page404';
import { useLocation } from 'react-router-dom';

const HandbookPageLayout = () => {
  const { pageStructure, loading, content, tableContent, isSearchMode, searching } = useHandbookPageService();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const { hash } = useLocation();

  useEffect(() => {
    const hashElement = hash && ref?.current?.querySelector && ref?.current?.querySelector(hash);

    if (!loading && ref?.current && !hashElement && window.innerWidth < MAX_SM_WIDTH) {
      domUtils.scrollToHash(ref?.current);
    }
    // Suppressed warnings because we only need to call useEffect if page is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!isSearchMode && !loading && !content && !pageStructure?.subPages?.length) {
    return <Page404 />;
  }

  return (
    <ControlledShow data={pageStructure}>
      <Paper className="page-content" elevation={0}>
        <ListHeader label="Handbook" />

        <div className="handbook-content">
          <HandbookSearchInput isSearchMode={isSearchMode} />
          {isSearchMode && searching && <Loading />}
          {!isSearchMode && (
            <div className="handbook-page__wrapper">
              <div className={classnames('handbook-menu__wrapper', { 'handbook-menu__wrapper--loading': loading })}>
                <aside id="js-left-column">
                  <HandbookMenu tableContent={loading ? [] : tableContent} />
                </aside>
              </div>
              <div className="handbook-content__inner position-relative">
                <div className="alerts__wrapper">
                  <Message />
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <SectionWithScrollToHash isScrollActive={!loading}>
                    <div className="handbook-content-wrapper" ref={ref}>
                      {!content && pageStructure?.subPages?.length ? (
                        <HandbookSubpagesListLayout pageStructure={pageStructure} />
                      ) : (
                        content
                      )}
                    </div>
                  </SectionWithScrollToHash>
                )}
              </div>
            </div>
          )}
        </div>
      </Paper>
    </ControlledShow>
  );
};

export default HandbookPageLayout;
