/* istanbul ignore file */
import React from 'react';
import useContextHelper from 'shared/uibuilder/contextHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import PositionEnumField from 'crm/opportunity/presale/output/shared/field/PositionEnumField';
import HtmlField from 'shared/uibuilder/field/HtmlField';
import 'crm/opportunity/presale/output/shared/field/RequiredResourcesListField.scss';
import EnumField from 'shared/uibuilder/field/EnumField';
import { SIMPLE_QUALIFICATION } from 'crm/opportunity/presale/output/shared/presaleOutputService';

const RequiredResourcesListField = ({ label, source, ...props }: FieldProps<string[]>) => {
  const { getFromAnyContext } = useContextHelper();
  const resources = getFromAnyContext(source);

  const getRequiredResourcesData = async () => {
    return Promise.resolve({ result: resources });
  };

  const listResources = (
    <div>
      <div>
        <HtmlField source="" value=" " label={label} />
      </div>
      <div>
        <List getDataMethod={getRequiredResourcesData}>
          <DataGridLayout isSmallTable tableWrapperClassName="required-resources-formatted-text-list">
            <PositionEnumField source="positionId" label="Position" width={300} />
            <EnumField options={SIMPLE_QUALIFICATION} source="qualification" label="Qualification" />
            <TextField source="requiredFte" label="FTE, %" width={150} />
          </DataGridLayout>
        </List>
      </div>
    </div>
  );

  return resources.length > 0 ? listResources : <></>;
};

export default RequiredResourcesListField;
