import { useCallback } from 'react';
import { useKernelApi } from 'api/index';
import useQualificationModelService, {
  LevelCompetency,
  Specialization,
} from 'erp/qualification/model/qualificationModelService';
import useCacheService from 'shared/cache/cacheService';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import useEmployeeSpecializationService from './employeeSpecializationService';
import useEmployeeQualificationPresenter from 'erp/employee/qualification/shared/employeeQualificationPresenter';
import { ResourceNotFoundError } from 'shared/api';
import useSingletonPromise from 'shared/useSingletonPromise';
import { ResourceData } from 'shared/crud/baseCrudService';
import updateCompetencyValidation from '../createupdate/updateCompetencyValidation';
import useEmployeeQualificationMerger from 'erp/employee/qualification/shared/employeeQualificationMerger';

const CACHE_NAME = 'employee-qualifications';

export enum CompetencyStatus {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  PROBABLE = 'PROBABLE',
  CONFIRMED = 'CONFIRMED',
}

export const competencyStatusesLabels: Dictionary<string> = {
  [CompetencyStatus.NOT_CONFIRMED]: 'Not confirmed',
  [CompetencyStatus.PROBABLE]: 'Probable',
  [CompetencyStatus.CONFIRMED]: 'Confirmed',
};

export enum CareerDevelopmentMode {
  INITIATED_BY_EMPLOYEE = 'INITIATED_BY_EMPLOYEE',
  INITIATED_AUTOMATICALLY = 'INITIATED_AUTOMATICALLY',
}

export const careerDevelopmentModeLabels: Dictionary<string> = {
  [CareerDevelopmentMode.INITIATED_BY_EMPLOYEE]: 'Initiated by employee when they are ready',
  [CareerDevelopmentMode.INITIATED_AUTOMATICALLY]: 'Initiated automatically on the review date',
};

type EmployeeCompetencyState = {
  status: CompetencyStatus;
  justification?: string;
  attachmentsIds?: number[];
};

export interface EmployeeCompetency extends LevelCompetency {
  appliedState?: EmployeeCompetencyState;
  rootState?: EmployeeCompetencyState;
}

export type EmployeeQualification = {
  id: number;
  title: string;
  modelVersionId?: StringOrNumber;
  completeness?: number;
  specializationLevelId?: number;
  competencies?: EmployeeCompetency[];
};

const DEFAULT_QUALIFICATION_MODEL_VERSION = 'latest';

const useEmployeeQualificationService = () => {
  const { sendGetRequest, sendPutRequest, sendPatchRequest } = useKernelApi();

  const { getSingleSpecialization, updateSpecializationCompleteness } = useEmployeeSpecializationService();
  const { getByVersion: getEmployeeQualificationModel } = useQualificationModelService();
  const { mapQualifications } = useEmployeeQualificationPresenter();

  const { addToCache, getValue, cache } = useCacheService(CACHE_NAME);

  const { mergeCompetencyData, mergeQualificationData } = useEmployeeQualificationMerger();

  const getCacheId = (employeeId: StringOrNumber, specializationId: StringOrNumber) =>
    `employee-${employeeId}-specialization-${specializationId}`;

  const getResourceUrl = (specializationId: StringOrNumber) => `/specializations/${specializationId}/qualifications`;

  const loadQualificationsDataBySpecializationId = useSingletonPromise(
    'loadQualificationsDataBySpecializationId',
    async ({ employeeId, specializationId }: { employeeId: StringOrNumber; specializationId: StringOrNumber }) => {
      const url = getResourceUrl(specializationId);
      const response = await sendGetRequest(url);
      const result = await response.json();

      return result;
    },
  );

  /**
   * Checks data in cache.
   * If they exist return them or load new data from BE
   * @param {StringOrNumber} id - id of specialization
   * @return Promise<string | ResourceData | {totalElements, totalPages, pageNumber, result}>}
   */
  const getQualificationsBySpecializationId = useCallback(
    async (employeeId: StringOrNumber, specializationId: StringOrNumber) => {
      const cacheId = getCacheId(employeeId, specializationId);
      const cachedValue = getValue(cacheId);

      if (cachedValue) {
        return cachedValue;
      } else {
        const employeeQualificationData = await loadQualificationsDataBySpecializationId({
          employeeId,
          specializationId,
        });

        addToCache(cacheId, employeeQualificationData);

        return employeeQualificationData;
      }
    },

    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  /**
   * Gets qualification data for current employee and from qualification model and merges them
   * @returns {Object} - merged data for employee qualifications
   */
  const getEmployeeQualificationData = useCallback(
    async ({
      employeeId,
      specializationId,
      specializationBranchId,
      modelVersion = DEFAULT_QUALIFICATION_MODEL_VERSION,
    }: {
      employeeId: StringOrNumber;
      specializationId: StringOrNumber;
      specializationBranchId: StringOrNumber;
      modelVersion: string;
    }) => {
      const [
        { specializations: specializationsFromModel = [] },
        { result: employeeQualifications },
      ] = await Promise.all([
        getEmployeeQualificationModel(modelVersion),
        getQualificationsBySpecializationId(employeeId, specializationId),
      ]);

      const { specializationLevels: qualificationsFromModel } =
        specializationsFromModel.find(({ id }: Specialization) => String(id) === String(specializationBranchId)) || {};

      return {
        qualifications: mapQualifications(employeeQualifications, qualificationsFromModel),
      };
    },
    [mapQualifications, getQualificationsBySpecializationId, getEmployeeQualificationModel],
  );

  /**
   * Returns data for current specialization.
   * If specialization is not found it is navigate to the first specialization.
   * @returns {Object}
   */
  const getEmployeeQualifications = useCallback(
    async (employeeId: StringOrNumber, specializationId: StringOrNumber) => {
      const singleSpecializationData = await getSingleSpecialization(employeeId, specializationId);

      if (!specializationId) {
        return {};
      }

      if (!singleSpecializationData) {
        throw new ResourceNotFoundError();
      }

      const employeeQualificationData = await getEmployeeQualificationData({
        employeeId,
        specializationId,
        specializationBranchId: singleSpecializationData.specializationBranchId,
        modelVersion: singleSpecializationData.modelVersionId,
      });

      return {
        ...singleSpecializationData,
        ...employeeQualificationData,
      };
    },
    // Suppressed warnings because we only need to call useCallback callback if user navigates to another specialization or if cache is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache, getSingleSpecialization],
  );

  const updateQualificationInCache = ({
    employeeId,
    specializationId,
    ...mergeProps
  }: {
    employeeId: StringOrNumber;
    specializationId: StringOrNumber;
    qualificationId: StringOrNumber;
    qualificationData: EmployeeQualification;
  }) => {
    const CACHE_ID = getCacheId(employeeId, specializationId);

    const cachedValue = getValue(CACHE_ID) || {};

    addToCache(CACHE_ID, {
      ...cachedValue,
      result: mergeQualificationData({
        ...mergeProps,
        employeeQualifications: cachedValue?.result,
      }),
    });
  };

  const updateCompetencyInCache = ({
    employeeId,
    specializationId,
    ...mergeProps
  }: {
    employeeId: StringOrNumber;
    specializationId: StringOrNumber;
    qualificationId: StringOrNumber;
    competencyId: StringOrNumber;
    competencyData: Partial<EmployeeCompetency>;
  }) => {
    const CACHE_ID = getCacheId(employeeId, specializationId);

    const cachedValue = getValue(CACHE_ID) || {};

    addToCache(CACHE_ID, {
      ...cachedValue,
      result: mergeCompetencyData({ ...mergeProps, employeeQualifications: cachedValue?.result }),
    });
  };

  const applyCompetencies = async ({
    employeeId,
    specializationId,
    qualificationId,
    competenciesIds,
  }: {
    employeeId: StringOrNumber;
    specializationId: StringOrNumber;
    qualificationId: StringOrNumber;
    competenciesIds: StringOrNumber[];
  }) => {
    const url = `/qualifications/${qualificationId}/competencies/apply`;
    const response = await sendPutRequest(url, { competenciesIds });
    const newQualificationData = await response.json();

    updateQualificationInCache({
      employeeId,
      specializationId,
      qualificationId,
      qualificationData: newQualificationData,
    });
    updateSpecializationCompleteness({
      employeeId,
      specializationId,
      qualificationId,
      completeness: newQualificationData.completeness,
    });

    return newQualificationData;
  };

  /**
   * Updates data for competency by id and update cache
   */
  const updateCompetency = async ({
    employeeId,
    competencyId,
    specializationId,
    qualificationId,
    formData,
  }: {
    employeeId: StringOrNumber;
    specializationId: StringOrNumber;
    qualificationId: StringOrNumber;
    competencyId: StringOrNumber;
    formData: ResourceData;
  }) => {
    const url = `${EMPLOYEES_RESOURCE_URL}/${employeeId}/competencies/${competencyId}`;
    const response = await sendPutRequest(url, formData);
    const result = await response.json();

    updateCompetencyInCache({
      employeeId,
      specializationId,
      qualificationId,
      competencyId: result.competencyId,
      competencyData: {
        rootState: {
          status: result.status,
        },
      },
    });

    return result;
  };

  /**
   * Updates data for competency by id and update cache
   */
  const updateQualificationCompleteness = async ({
    employeeId,
    specializationId,
    qualificationId,
    completeness,
  }: {
    employeeId: StringOrNumber;
    specializationId: StringOrNumber;
    qualificationId: StringOrNumber;
    completeness: number;
  }) => {
    const url = `/qualifications/${qualificationId}`;
    const response = await sendPatchRequest(url, { completeness });
    const newQualificationData = await response.json();

    updateQualificationInCache({
      employeeId,
      specializationId,
      qualificationId,
      qualificationData: newQualificationData,
    });
    updateSpecializationCompleteness({
      employeeId,
      specializationId,
      qualificationId,
      completeness: newQualificationData.completeness,
    });

    return newQualificationData;
  };

  const getCompetencyValidationSchema = () => Promise.resolve(updateCompetencyValidation);

  return {
    getEmployeeQualifications,
    applyCompetencies,
    updateCompetency,
    getCompetencyValidationSchema,
    updateQualificationCompleteness,
  };
};

export default useEmployeeQualificationService;
