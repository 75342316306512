/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_SYBERRY_TODAY_PROJECTS, SYBERRY_TODAY_PROJECTS_PATH } from 'syberryToday/useSyberryTodayService';
import SyberryTodayList from 'syberryToday/projects/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useSyberryTodayProjectsRoute = () => useRoute({ listRoute: SYBERRY_TODAY_PROJECTS_PATH });

export const useSyberryTodayProjectsUrl = () =>
  useEntityUrl({
    baseLink: SYBERRY_TODAY_PROJECTS_PATH,
  });

export const useSyberryTodayProjectsId = () => useParams<Dictionary<string>>().id;

const SyberryTodayProjectProjectsRouter = () => {
  const { listRoute, singleEntityRoute } = useSyberryTodayProjectsRoute();

  return (
    <ProtectedRouter basePath={SYBERRY_TODAY_PROJECTS_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_SYBERRY_TODAY_PROJECTS}>
          <SyberryTodayList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_SYBERRY_TODAY_PROJECTS}>
          <SyberryTodayList />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

SyberryTodayProjectProjectsRouter.getRouterPath = () => SYBERRY_TODAY_PROJECTS_PATH;

export default SyberryTodayProjectProjectsRouter;
