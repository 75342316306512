import React from 'react';
import moment from 'moment';
import useDateHelper, {
  CommonDateInputProps,
  COMMON_DATE_INPUT_DEFAULT_PROPS,
} from 'shared/uibuilder/form/input/dateHelper';
import { DateInput } from './index';

const TIME_FORMAT = 'HH:mm:ss';
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DateTimeInput = (props: CommonDateInputProps) => {
  const { getTimeFormat, getDateFormat } = useDateHelper(props);

  const timeFormat = getTimeFormat() || TIME_FORMAT;

  const getDateTimeFormat = () => {
    return `${getDateFormat()} ${timeFormat}` || DATETIME_FORMAT;
  };

  const dateTimeFormat = getDateTimeFormat();

  const formatDate = (textDate: string) => {
    let result = textDate;
    if (moment(textDate, dateTimeFormat, true).isValid()) {
      result = moment(textDate).format(dateTimeFormat);
    }
    return result;
  };

  return <DateInput {...props} timeFormat={timeFormat} formatSourceDate={formatDate} />;
};

DateTimeInput.defaultProps = COMMON_DATE_INPUT_DEFAULT_PROPS;

export default DateTimeInput;
