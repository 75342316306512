/* istanbul ignore file */
import React, { useContext } from 'react';
import { SecurityContext } from './SecurityReactContext';

const GlobalSecurityReactContext = React.createContext<SecurityContext>({});

export const GlobalSecurityProvider = GlobalSecurityReactContext.Provider;

const useGlobalSecurityReactContext = () => useContext(GlobalSecurityReactContext);

export default useGlobalSecurityReactContext;
