/* istanbul ignore file */
import React from 'react';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';
import Message from 'shared/message/Message';
import { Request } from 'oneStop/oneStopService';
import OneStopButtons from 'uibuilder/layout/form/OneStopButtonsLayout';
import SanitizedHtml from '../../shared/security/SanitizedHtml';

type OneStopRequestLayoutProps = {
  request: Request;
  isFixedButtons?: boolean;
  children: React.ReactElement;
  headerRight?: React.ReactElement;
  handleCancelClick?: Nullable<() => void>;
  messageContext?: string;
};

const OneStopRequestLayout = ({
  children,
  request,
  headerRight,
  isFixedButtons,
  handleCancelClick,
  messageContext,
}: OneStopRequestLayoutProps) => {
  const { id, title, description } = request;
  const isCustomRequestForm = !request.fields;

  const Title = () => {
    return (
      <div className="d-flex justify-content-between flex-wrap w-100">
        <Typography variant="h4" className="c-title" sx={{ marginRight: '10px' }}>{`${id}. ${title}`}</Typography>
        {headerRight}
      </div>
    );
  };

  const Description = () => {
    return (
      <Box sx={{ margin: '15px 0 17px', 'br + br': { content: '""', margin: '12px', display: 'block' } }}>
        {/* eslint-disable-next-line react/no-danger */}
        <SanitizedHtml html={description} />
      </Box>
    );
  };

  return (
    <>
      <Message messageContext={messageContext} />
      <Title />
      {description && <Description />}
      {children}
      {!isCustomRequestForm && <OneStopButtons onCancelAction={handleCancelClick} isFixed={isFixedButtons} />}
    </>
  );
};

OneStopRequestLayout.defaultProps = {
  handleCancelClick: null,
  headerRight: null,
  isFixedButtons: true,
  messageContext: undefined,
};

export default OneStopRequestLayout;
