import React from 'react';

import useFieldHelper, { defaultFieldPropsDefault } from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, FieldLayoutProps } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';

export type EmailFieldLayoutType = ReactComponent<FieldLayoutProps<Nullable<string>>>;

const EmailField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { EmailFieldLayout } = useUiTheme<EmailFieldLayoutType>();
  const { className } = props;

  return <EmailFieldLayout className={className} value={getValue()} label={getLabel()} />;
};

EmailField.defaultProps = defaultFieldPropsDefault;

export default EmailField;
