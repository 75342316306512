/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import React from 'react';
import useCandidateService from 'erp/candidate/shared/candidateService';
import CandidateForm from 'erp/candidate/createupdate/CandidateForm';
import CreateCandidateBreadcrumbs from 'erp/candidate/createupdate/Create/Breadcrumbs';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CreateCandidatePage = () => {
  const { create, getValidationSchema } = useCandidateService();
  const { getTimelineUrl } = useCandidateUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        additionalContacts: [],
      }}
      afterSubmit={{
        redirect: data => getTimelineUrl(data.id),
        message: 'You are awesome! The Candidate has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout breadcrumbs={<CreateCandidateBreadcrumbs />} title="Create: Candidate">
        <CandidateForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateCandidatePage;
