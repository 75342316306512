/* istanbul ignore file */
import React, { useEffect } from 'react';
import { FormProvider } from 'shared/uibuilder/form/FormContext';
import { TextArea } from 'shared/uibuilder/form/input/index';
import ClosedReasonDropdown from 'crm/opportunity/shared/input/ClosedReasonDropdown';
import { isEqual } from 'lodash';
import useForm from 'shared/uibuilder/form/formHelper';
import opportunityValidationSchema from 'crm/opportunity/createupdate/opportunityValidation';

const ClosedReasonInputs = ({
  setFormErrors,
  setFormData,
  formData,
  formErrors,
}: {
  setFormErrors: (errors: Dictionary<string[]>) => void;
  setFormData: (data: Dictionary<any>) => void;
  formData: Dictionary<any>;
  formErrors: Dictionary<string[]>;
}) => {
  const contextValue = useForm({
    initialData: formData,
    submitFormFunc: () => Promise.resolve({}),
    getValidationSchemaFunc: () => Promise.resolve({ closedReason: opportunityValidationSchema.closedReason }),
  });

  useEffect(() => {
    if (!isEqual(contextValue.formErrors, formErrors)) {
      setFormErrors(contextValue.formErrors);
    }
    // Suppressed warnings because we only need to call useEffect callback if contextValue.formErrors is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue.formErrors]);

  useEffect(() => {
    if (!isEqual(contextValue.data, formData)) {
      setFormData(contextValue.data);
    }
    // Suppressed warnings because we only need to call useEffect callback if contextValue.data is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue.data]);

  return (
    <FormProvider value={contextValue}>
      <ClosedReasonDropdown
        type="PRIMARY"
        source="closedReason.primaryReason"
        stageSource="stateStep"
        label="Primary Reason for Closed Status"
        placeholder="Choose Primary Closed Reason"
        tooltip="Select the primary reason of why the Opportunity has been Won (Lost)."
      />
      <ClosedReasonDropdown
        type="SECONDARY"
        source="closedReason.secondaryReason"
        stageSource="stateStep"
        label="Secondary Reason for Closed Status"
        placeholder="Choose Secondary Closed Reason"
        tooltip="Select the secondary reason of why the Opportunity has been Won (Lost)."
      />
      <TextArea
        label="Closed Summary"
        source="closedReason.summary"
        tooltip="Provide a summary of why the Opportunity was Won (Lost). Quote prospect's feedback, when available."
      />
    </FormProvider>
  );
};

export default ClosedReasonInputs;
