/* istanbul ignore file */
import { useParams } from 'react-router-dom';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import React from 'react';
import useCandidateService, { CREATE_CANDIDATE_OFFER } from 'erp/candidate/shared/candidateService';
import useCandidateNoteItem from 'erp/candidate/Timeline/note/note/candidateNoteRegistry';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import useCandidateResumeItem from './resume/candidateResumeRegistry';
import CandidateTimelineBreadcrumbs from 'erp/candidate/Timeline/Breadcrumbs';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import CandidateTimelineRecordTypeFilter from 'erp/candidate/Timeline/shared/filter/CandidateTimelineRecordTypeFilter';
import CandidateTimelineNoteTagFilter from 'erp/candidate/Timeline/shared/filter/CandidateTimelineNoteTagFilter';
import { CANDIDATE_TIMELINE_RECORDS_ALIAS } from 'erp/candidate/Timeline/shared/input/CandidateTimelineRecordTypeDropdown';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import { CreateButton } from 'uibuilder/button';
import useCandidateApplicationItem from 'erp/candidate/Timeline/application/candidateApplicationRegistry';

const NOTE_TAG_SOURCE = 'note.tag';

const CandidateTimelinePage = () => {
  const { id: candidateId } = useParams();
  const {
    getById,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    getFilterValidationSchema,
  } = useCandidateService();

  const noteRegistry = useCandidateNoteItem();
  const resumeRegistry = useCandidateResumeItem();
  const applicationRegistry = useCandidateApplicationItem();
  const { getCreateUrl } = useCandidateOfferUrl();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <DateTimeRangeFilter label="Created date" source="createdAt" />
        <CandidateTimelineRecordTypeFilter cleanableFilters={[NOTE_TAG_SOURCE]} source="type" label="Entity" />
        <CandidateTimelineNoteTagFilter
          disabled={({ type = {} }) => !type.in || type.in[0] !== CANDIDATE_TIMELINE_RECORDS_ALIAS.NOTE_CREATED}
          source={NOTE_TAG_SOURCE}
          label="Tag"
          labelInDropdown="Tag (notes only)"
        />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Show getDataMethod={() => getById(candidateId)}>
      <Timeline
        registries={[noteRegistry, resumeRegistry, applicationRegistry]}
        getDataMethod={request => searchTimelineItems(candidateId, request)}
        createTimelineItemMethod={(itemType, request) => createTimelineItem(candidateId, itemType, request)}
        getPageByEntryIdMethod={id => getTimelinePageById(candidateId, id)}
      >
        <TimelinePageLayout
          menu={<CandidateMenu />}
          breadcrumbs={<CandidateTimelineBreadcrumbs />}
          entityHeader={<CandidateEntityHeader />}
          filter={filters}
          additionalButtons={[
            <CreateButton
              url={getCreateUrl}
              label="Offer"
              key="add-offer"
              permissionToCheck={CREATE_CANDIDATE_OFFER}
              variant="contained"
            />,
          ]}
        >
          <TimelineLayout noEntriesMessage="No entries in this candidate timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default CandidateTimelinePage;
