/* istanbul ignore file */
import React from 'react';
import { EmployeeQualification } from 'erp/employee/qualification/shared/employeeQualificationService';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import TextField from 'shared/uibuilder/field/TextField';

interface EmployeeQualificationDetailsListFieldProps {
  value?: EmployeeQualification;
}

const EmployeeQualificationDetailsListField = ({ value }: EmployeeQualificationDetailsListFieldProps) => {
  return (
    <SectionRow>
      <TextField value={value?.title} label="Title" />
      <TextField value={value?.completeness} label="Completeness, %" />
    </SectionRow>
  );
};

export default EmployeeQualificationDetailsListField;
