/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import useAssessmentService from 'erp/employee/assessment/assessmentService';
import AssessmentForm from 'erp/employee/assessment/createupdate/AssessmentForm';
import CreateAssessmentBreadcrumbs from 'erp/employee/assessment/createupdate/Create/Breadcrumbs';

const CreateAssessmentPage = () => {
  const { create, getValidationSchema } = useAssessmentService();
  const { getListUrl } = useAssessmentUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Assessment has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        title="Create: Assessment"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
        breadcrumbs={<CreateAssessmentBreadcrumbs />}
      >
        <AssessmentForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateAssessmentPage;
