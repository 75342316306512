/* istanbul ignore file */
import React from 'react';
import EmployeeContractsBreadCrumbs from 'erp/employee/contracts/shared/ContractsBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeContractId, useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';
import { arrayOf, element, oneOfType } from 'prop-types';

const SingleContractsBreadcrumbs = ({ children }) => {
  const id = useEmployeeContractId();
  const { getSingleEntityUrl } = useEmployeeContractUrl();

  return (
    <EmployeeContractsBreadCrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </EmployeeContractsBreadCrumbs>
  );
};

SingleContractsBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleContractsBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleContractsBreadcrumbs;
