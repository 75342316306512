import { useKernelApi } from 'api';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

export const PROVIDE_CONSENT_PERMISSION = 'PROVIDE_MAILBOX_CONSENT';

export const REVOKE_CONSENT_PERMISSION = 'REVOKE_MAILBOX_CONSENT';

const useMailboxConsentService = () => {
  const employeeId = useEmployeeId();
  const { sendPostRequest, sendDeleteRequest } = useKernelApi();

  const provideConsent = async () => {
    await sendPostRequest(`/employees/${employeeId}/mailbox-scrapper-consent/provide`);
  };

  const revokeConsent = async () => {
    await sendDeleteRequest(`/employees/${employeeId}/mailbox-scrapper-consent/revoke`);
  };

  return {
    provideConsent,
    revokeConsent,
  };
};

export default useMailboxConsentService;
