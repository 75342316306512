/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { Dropdown } from 'shared/uibuilder/form/input';

const AssetAssignmentFilter = (props: any) => {
  return (
    <DropDownFilter {...props} isSingle>
      <Dropdown
        options={[
          {
            label: 'Yes',
            value: 'YES',
          },
          {
            label: 'No',
            value: 'NO',
          },
        ]}
        {...props}
      />
    </DropDownFilter>
  );
};

export default AssetAssignmentFilter;
