import { HashLink } from 'react-router-hash-link';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { arrayOf, bool, shape } from 'prop-types';
import * as domUtils from 'shared/uibuilder/domUtils';

export const ERROR_IN_SECTION =
  'The form cannot be saved, please, check the entered information in the highlighted section(s):';
export const ERROR_WITHOUT_SECTION =
  'The form cannot be saved, please, check the entered information in the highlighted field(s)';

/**
 * Display invalid section messages
 */
const InvalidSectionsMessage = ({ needToDisplay, invalidSections }) => {
  const history = useHistory();

  if (!needToDisplay || !invalidSections || !invalidSections.length) {
    return null;
  }

  const isSingleSectionWithoutTitle = invalidSections.length === 1 && invalidSections[0].title.length === 0;
  const errorMessage = isSingleSectionWithoutTitle ? ERROR_WITHOUT_SECTION : ERROR_IN_SECTION;

  return (
    <div className="form-errors">
      <div>{errorMessage}</div>
      {invalidSections.map(value => {
        return (
          <HashLink
            key={value.anchor}
            className="error-section-link"
            to={`${history.location.pathname}${history.location.search}#${value.anchor}`}
            scroll={el => domUtils.scrollToHash(el, 100)}
          >
            {value.title}
          </HashLink>
        );
      })}
    </div>
  );
};

InvalidSectionsMessage.propTypes = {
  needToDisplay: bool,
  invalidSections: arrayOf(shape()).isRequired,
};

InvalidSectionsMessage.defaultProps = {
  needToDisplay: false,
};

export default InvalidSectionsMessage;
