/* istanbul ignore file */
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import UnlockIcon from '@mui/icons-material/NoEncryptionOutlined';
import RecurrentlyIcon from '@mui/icons-material/UpdateOutlined';
import UnReccurentlyIcon from '@mui/icons-material/UpdateDisabledOutlined';
import Divider from '@mui/material/Divider';
import useRedmineUrl from 'redmine/useRedmineUrl';
import useProjectIssueContextMenu from 'syberryToday/shared/ProjectIssueContextMenu/useProjectIssueContextMenu';
import { BACKLOG_CATEGORIES } from 'syberryToday/useSyberryTodayService';
import { ListItem } from 'shared/uibuilder/list/ListContext';

interface ProjectIssueContextMenuProps {
  children: React.ReactNode;
  item: ListItem;
  updateInList: (entity: ListItem) => void;
  removeFromList: (entityId: number) => void;
}

const ProjectIssueContextMenu = ({ children, item, updateInList, removeFromList }: ProjectIssueContextMenuProps) => {
  const { getIssueUrl } = useRedmineUrl();
  const {
    handleContextMenu,
    handleClose,
    contextMenu,
    onBlockBtnClick,
    onRecurrentlyBtnClick,
  } = useProjectIssueContextMenu({ item, updateInList, removeFromList });
  const { backlogCategory } = item;
  const isBlocked = backlogCategory === BACKLOG_CATEGORIES.BLOCKED;
  const isParent = backlogCategory === BACKLOG_CATEGORIES.PARENT_TASKS;
  const isRecurrently = backlogCategory === BACKLOG_CATEGORIES.RECCURENTLY;

  const buttons = isParent
    ? []
    : [
        <MenuItem onClick={onBlockBtnClick}>
          <ListItemIcon>{isBlocked ? <UnlockIcon fontSize="small" /> : <LockIcon fontSize="small" />}</ListItemIcon>
          Mark as {isBlocked ? 'unblocked' : 'blocked'}
        </MenuItem>,
        <MenuItem onClick={onRecurrentlyBtnClick}>
          <ListItemIcon>
            {isRecurrently ? <UnReccurentlyIcon fontSize="small" /> : <RecurrentlyIcon fontSize="small" />}
          </ListItemIcon>
          Mark as {isRecurrently ? 'a one-time' : 'a recurrent'} task
        </MenuItem>,
        <Divider />,
        <MenuItem
          component="a"
          href={`${getIssueUrl(item.id)}/reject`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          <ListItemIcon>
            <CancelIcon fontSize="small" color="error" />
          </ListItemIcon>
          Reject
        </MenuItem>,
      ];

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
      {children}
      <Menu
        open={!!contextMenu}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu || undefined}
        className="issue-context-menu"
      >
        <MenuItem
          component="a"
          href={`${getIssueUrl(item.id)}/edit`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        {buttons}
      </Menu>
    </div>
  );
};

export default ProjectIssueContextMenu;
