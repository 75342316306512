import { useBusinessEngineApi } from 'api';
import { HTTP } from '../../shared/api/const';
import useMessageService, { ErrorMessage } from '../../shared/message/messageService';

const useOneClickService = () => {
  const { addMessage } = useMessageService();
  const handleError = error => {
    switch (error.status) {
      case HTTP.UNAUTHORIZED:
        addMessage(new ErrorMessage('The password is incorrect'));
        break;
      default:
        throw error;
    }
  };

  const { sendPostRequest } = useBusinessEngineApi();

  const getUsers = async oneClickPassword => {
    try {
      const response = await sendPostRequest('/authentication/users', oneClickPassword);
      return response.json();
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const getAccessTokenForUser = async (username, oneClickPassword) => {
    try {
      const response = await sendPostRequest(`/authentication/users/${username}`, oneClickPassword);
      return response.json();
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  return {
    getUsers,
    getAccessTokenForUser,
  };
};

export default useOneClickService;
