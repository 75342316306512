/* istanbul ignore file */
// libs
import React from 'react';
import Sticky from 'react-sticky-el';
import Scrollbars from 'uibuilder/Scrollbars';
import { Button } from 'uibuilder/button';
import ArrowIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { SidebarStickyMenuProps } from 'shared/uibuilder/menu/StickySidebarMenu';
import Box from 'uibuilder/Box';
// styles
import './SidebarMenu.scss';

interface MinimizedSidebarStickyMenuProps extends SidebarStickyMenuProps {
  hasStaticSubitems?: boolean;
  isMinimized: boolean;
  setIsMinimized: (value: boolean) => void;
}

const MinimizedSidebarStickyMenu = ({
  children,
  disabled = false,
  hasStaticSubitems = true,
  isMinimized,
  setIsMinimized,
}: MinimizedSidebarStickyMenuProps) => {
  const isMobileDevice = () => {
    return window.innerWidth <= 767;
  };

  return (
    <div className={`sticky-menu__wrapper collapsible-sticky-menu__wrapper ${isMinimized ? 'minimized' : ''}`}>
      <Sticky
        disabled={isMobileDevice() || disabled}
        stickyClassName="sticky-menu"
        className="sticky-menu__inner"
        boundaryElement=".page-content"
        hideOnBoundaryHit={false}
        topOffset={-100}
        bottomOffset={40}
      >
        <Box
          className="collapsible-sticky-menu__content"
          sx={
            hasStaticSubitems
              ? {
                  '.left-menu-subitems': { display: 'block' },
                }
              : {}
          }
        >
          <Scrollbars className="sticky-sidebar-menu tabs-container">
            <ul>{children}</ul>
          </Scrollbars>
          <Button
            color="neutralLight"
            className="sidebar-toggle-btn"
            onClick={() => {
              setIsMinimized(!isMinimized);
            }}
          >
            <ArrowIcon />
          </Button>
        </Box>
      </Sticky>
    </div>
  );
};

export default MinimizedSidebarStickyMenu;
