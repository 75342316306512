/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { TEMPLATE_TYPES } from 'crm/opportunity/presale/contract/shared/contractRequestService';
import { HtmlField } from 'shared/uibuilder/field';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';

const ContractRequestFields = () => {
  return (
    <>
      <EnumField options={TEMPLATE_TYPES} source="templateType" label="Template Type" />
      <HtmlField source="descriptionText" label="Description" />
      <AttachmentsListField source="attachmentIds" label="Attachments" />
    </>
  );
};

export default ContractRequestFields;
