// services
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useKernelApi } from 'api';
import useCacheService from 'shared/cache/cacheService';
import { useCallback } from 'react';

// constants
export const READ_EMPLOYEE_DOMAIN_LIST = 'READ_EMPLOYEE_DOMAIN_LIST';
export const DELETE_EMPLOYEE_DOMAIN = 'DELETE_EMPLOYEE_DOMAIN';
export const UPDATE_EMPLOYEE_DOMAIN = 'UPDATE_EMPLOYEE_DOMAIN';
export const CREATE_EMPLOYEE_DOMAIN = 'CREATE_EMPLOYEE_DOMAIN';
const CACHE_NAME = 'employee-domains';

export enum EmployeeDomainStatus {
  STARTED = 'STARTED',
  ACHIEVED = 'ACHIEVED',
}

export const EmployeeDomainStatusLabels = {
  [EmployeeDomainStatus.STARTED]: 'Started',
  [EmployeeDomainStatus.ACHIEVED]: 'Achieved',
};

export const useEmployeeDomainsService = () => {
  const { sendPostRequest, sendPutRequest, sendDeleteRequest, sendGetRequest } = useKernelApi();
  const currentAlias = useEmployeeId();
  const { addToCache, getValue, cache, invalidateCache } = useCacheService(CACHE_NAME);
  const getCacheId = (employeeId: Nullable<string>) => `employee-${employeeId}-domains`;

  const findAllEmployeeDomains = useCallback(
    async () => {
      const cacheId = getCacheId(currentAlias);
      const cachedValue = getValue(cacheId);

      if (cachedValue) {
        return cachedValue;
      } else {
        const url = `/employees/${currentAlias}/domains/search`;
        const response = await sendPostRequest(url, {});
        const json = await response.json();

        const result = json.result?.map((item: any) => ({ domainId: item.domain?.id, ...item }));

        addToCache(cacheId, { ...json, result });

        return { ...json, result };
      }
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  const getById = async (id: string) => {
    const url = `/employees/${currentAlias}/domains/${id}`;
    const response = await sendGetRequest(url);

    return response.json();
  };

  const addEmployeeDomain = async (request: any) => {
    const url = `/employees/${currentAlias}/domains`;
    const response = await sendPostRequest(url, request);
    const json = await response.json();

    invalidateCache();

    return json;
  };

  const editEmployeeDomain = async (id: string, request: any) => {
    const url = `/employees/${currentAlias}/domains/${id}`;
    const response = await sendPutRequest(url, request);
    const json = await response.json();

    invalidateCache();

    return json;
  };

  const deleteEmployeeDomain = async (id: string) => {
    const response = await sendDeleteRequest(`/employees/${currentAlias}/domains/${id}`);

    invalidateCache();
    return response.json();
  };

  return {
    getById,
    findAllEmployeeDomains,
    addEmployeeDomain,
    editEmployeeDomain,
    deleteEmployeeDomain,
  };
};
