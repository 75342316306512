export const selectors = {
  formFields: {
    lastName: '[name="lastName"]',
    name: '[name="name"]',
    email: '[name="email"]',
    phone: '[name="phone"]',
    addressCountry: '[id="field-dropdown-address.country"]',
    addressCountryInput: '[name="address.country"]',
    addressCity: '[name="address.city"]',
    addressLine1: '[name="address.addressLine1"]',
    zipCode: '[name="address.zipCode"]',
  },
  password: '[name="password"]',
  errorSectionLink: '.error-section-link',
  content: '.page-content',
  submitButton: '[type="submit"]',
  formFooter: '.sticky-buttons-element',
  formErrors: '.form-errors',
  formRowId: 'TestId',
};

export default cy => ({
  getFormFieldSelector: key => {
    return cy.get(selectors.formFields[key]);
  },
  getContentSelector: () => {
    return cy.get(selectors.content);
  },
  getSubmitButtonSelector: () => {
    return cy.get(selectors.submitButton);
  },
  getUpdateButtonSelector: () => {
    return cy.get(selectors.formFooter).contains('Cancel');
  },
  getLastModifiedSelector: () => {
    return cy
      .get(selectors.content)
      .get('i')
      .contains('Last modified');
  },
  getCreatedSelector: () => {
    return cy
      .get(selectors.content)
      .get('i')
      .contains('Created');
  },
  getFormErrorsSelector: () => {
    return cy.get(selectors.formFooter).get(selectors.formErrors);
  },
  getTitleSelector: () => {
    return cy.get('h1');
  },
  getFormFooterSelector: () => {
    return cy.get(selectors.formFooter);
  },
  getErrorSectionLinkSelector: () => {
    return cy.get(selectors.errorSectionLink);
  },
  getSectionsSelector: () => {
    return cy.get(selectors.content).get('.long-form-section');
  },
});
