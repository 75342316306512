/* istanbul ignore file */
import React from 'react';
import arrowToBottomIcon from 'shared/images/arrow-to-bottom.svg';
import InputLoader from 'shared/uibuilder/InputLoader';

type DownloadButtonProps = {
  onClick: () => void;
  loading: boolean;
};

const DownloadButton = ({ onClick, loading }: DownloadButtonProps) => {
  return (
    <button type="button" onClick={onClick}>
      {loading ? (
        <InputLoader className="files-list__files-loader" />
      ) : (
        <span className="files-list__files-download">
          <img src={arrowToBottomIcon} alt="Download" title="Download" />
        </span>
      )}
    </button>
  );
};

export default DownloadButton;
