import FormRow, { FormRowProps } from 'shared/layout/form/FormRow';
import React from 'react';

const MediumFormRow = ({ children, ...props }: FormRowProps) => {
  return (
    <FormRow colMd={12} colXl={6} {...props}>
      {children}
    </FormRow>
  );
};

export default MediumFormRow;
