/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField from 'shared/uibuilder/field/LinkField';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import { FieldProps } from 'shared/uibuilder/field/index';

interface PurchaseOrderLinkProps extends FieldProps {
  nameSource: string;
}

const PurchaseOrderLink = ({ source, nameSource, ...props }: PurchaseOrderLinkProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = usePurchaseOrderUrl();
  const id = getValue();
  const name = getName();

  return id || name ? <LinkField linkText={name || id} isInternal value={getSingleEntityUrl(id)} {...props} /> : null;
};

export default PurchaseOrderLink;
