/* istanbul ignore file */
import React from 'react';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import {
  PROGRESS_STATUSES,
  PROGRESS_FIELDS_ORDER,
  PROGRESS_FIELDS_LABELS,
  PROGRESS_FIELDS_KEYS,
} from 'crm/projectsDashboard/useProjectsDashboardService';
import BaseProgressField from 'shared/uibuilder/field/ProgressField';
import { DateField, TextField, EnumField } from 'shared/uibuilder/field';
import Box from 'uibuilder/Box';
import PositionEnumField from 'crm/opportunity/presale/output/shared/field/PositionEnumField';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import { SIMPLE_QUALIFICATION } from 'crm/opportunity/presale/output/shared/presaleOutputService';
import Scrollbars from 'uibuilder/Scrollbars';
import './ProgressField.scss';

const ProgressFieldData = ({
  status = '',
  duration = 0,
  planDate = null,
  factDate = null,
  field,
  index,
}: Dictionary<any>) => {
  const { formatDate } = useDateService();

  const date =
    status === PROGRESS_STATUSES.COMPLETED || status === PROGRESS_STATUSES.COMPLETED_WITH_DELAY ? factDate : planDate;

  return (
    <>
      <h3>{PROGRESS_FIELDS_LABELS[field as PROGRESS_FIELDS_KEYS]}</h3>
      <p>T0 {index ? `+ ${duration || 0} DAY${duration === 1 ? '' : 'S'}` : ''}</p>
      <p>{`${
        status === PROGRESS_STATUSES.IN_PROGRESS || status === PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY ? 'Plan ' : ''
      }${date ? formatDate(date, DATE_FORMAT.FULL_DATE) : '-'}`}</p>
    </>
  );
};

const ProgressFieldAdditionalData = ({
  status = '',
  duration = 0,
  planDate = null,
  factDate = null,
  field,
  index,
  additionalInfo = {},
}: Dictionary<any>) => {
  return (
    <>
      <DateField source="planDate" value={planDate} label="Plan Date" />
      <DateField source="factDate" value={factDate} label="Actual Date" />

      {additionalInfo?.expectedProjectStart && (
        <DateField
          source="expectedProjectStart"
          value={additionalInfo?.expectedProjectStart}
          label="Expected Start Date"
          className="mt-2"
        />
      )}

      {additionalInfo?.requiredResources && (
        <TextField
          label="Team Structure"
          source="requiredResources"
          className="mt-2"
          value={
            <UiThemeOverrider
              overriddenLayouts={{
                BaseFieldLayout: ({ value }) => <span>{value}</span>,
              }}
            >
              <Box component="ul" sx={{ m: 0, pl: 2 }}>
                {additionalInfo.requiredResources?.map(
                  ({ positionId, requiredFte, qualification }: Dictionary<string>) => (
                    <li key={`${field}-${index}-required-resources`}>
                      <PositionEnumField source="positionId" value={positionId} />- {requiredFte}%,{' '}
                      <EnumField
                        options={SIMPLE_QUALIFICATION}
                        value={qualification}
                        source="qualification"
                        label="Qualification"
                      />
                    </li>
                  ),
                )}
              </Box>
            </UiThemeOverrider>
          }
        />
      )}
    </>
  );
};

const ProgressField = ({ source }: Dictionary<any>) => {
  return (
    <Scrollbars className="project-dashboard-progress">
      <BaseProgressField source={source} steps={PROGRESS_FIELDS_ORDER} additionalData={<ProgressFieldAdditionalData />}>
        <ProgressFieldData />
      </BaseProgressField>
    </Scrollbars>
  );
};

export default ProgressField;
