import useCandidateService, { CandidateDuplicate } from 'erp/candidate/shared/candidateService';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const useCandidateDuplicatesHelper = (onDuplicatesFail: () => void) => {
  const { getDuplicates, canSearchDuplicates } = useCandidateService();
  const [duplicates, setDuplicates] = useState<CandidateDuplicate[]>([]);
  const { data: formData } = useFormContext();
  const [duplicateSearchFinished, setDuplicateSearchFinished] = useState(true);

  // timeout for when the user stopped modifying form content regarding the candidate
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDuplicates = useCallback(
    debounce(1000, (candidate: any) => {
      getDuplicates(candidate)
        .then(duplicatesResponse => {
          setDuplicates(duplicatesResponse);
          setDuplicateSearchFinished(true);
        })
        .catch(() => onDuplicatesFail());
    }),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsSearchingValue = useCallback(
    debounce(200, () => {
      setDuplicateSearchFinished(false);
    }),
    [],
  );

  const candidateData = formData?.candidate;
  useEffect(() => {
    if (formData?.createNewCandidate && canSearchDuplicates(candidateData)) {
      updateIsSearchingValue();
      searchDuplicates(candidateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidateData?.name?.firstName,
    candidateData?.name?.lastName,
    candidateData?.personalPhoneNumber,
    candidateData?.personalEmail,
  ]);

  const resetDuplicates = useCallback(() => {
    setDuplicates([]);
  }, []);

  return {
    duplicateSearchFinished,
    resetDuplicates,
    duplicates,
  };
};

export default useCandidateDuplicatesHelper;
