/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { usePurchaseOrderUrl, usePurchaseOrderId } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import PurchaseOrderBreadcrumbs from 'financialAnalytic/purchaseOrder/shared/PurchaseOrderBreadcrumbs';

const SinglePurchaseOrderBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { getSingleEntityUrl } = usePurchaseOrderUrl();
  const id = usePurchaseOrderId();

  return (
    <PurchaseOrderBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </PurchaseOrderBreadcrumbs>
  );
};

export default SinglePurchaseOrderBreadcrumbs;
