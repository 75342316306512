/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';

const PrimaryBadge = () => {
  return (
    <Badge className="ml-2" color="success">
      Primary
    </Badge>
  );
};

export default PrimaryBadge;
