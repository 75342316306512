import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useLocationService from 'shared/uibuilder/form/input/LocationDropdown/locationService';

const LocationFilter = ({ ...props }: any) => {
  const { findAll } = useLocationService();
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  const mapResults = (location: ResultResourceData) => ({
    value: location.name,
    label: location.name,
  });

  const loadLocations = async () => {
    const locations = await findAll();

    return {
      result: locations,
    };
  };

  const onChangeCallback = (values: any) => {
    const location = values[props.source];

    if (rawOnChangeCallback) {
      rawOnChangeCallback({ [props.source]: location });
    }
  };

  return (
    <DropDownFilter {...props}>
      <LoadDropdown
        loadDataMethod={loadLocations}
        mapResults={mapResults}
        onChangeCallback={onChangeCallback}
        placeholder="Select Location"
        {...props}
      />
    </DropDownFilter>
  );
};

export default LocationFilter;
