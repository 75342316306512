/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useAssetService from 'financialAnalytic/assets/useAssetService';
import { useAssetUrl, useAssetId } from 'financialAnalytic/assets/AssetRouter';
import CreateAssetBreadcrumbs from 'financialAnalytic/assets/createupdate/Create/Breadcrumbs';
import AssetForm from 'financialAnalytic/assets/createupdate/AssetForm';

const CreateAssetPage = () => {
  const { create, getValidationSchema, getByIdForCopying } = useAssetService();
  const { getSingleEntityUrl } = useAssetUrl();
  let getDataFunc;
  const id = useAssetId();

  if (id) {
    getDataFunc = () => getByIdForCopying(id);
  }

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: ({ inventoryNumber }) => getSingleEntityUrl(inventoryNumber),
        message: 'You are awesome! The Asset has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        customUsefulLifePeriod: {
          timeUnit: 'MONTHS',
        },
      }}
    >
      <CreatePageLayout breadcrumbs={<CreateAssetBreadcrumbs />} title="Create: Asset">
        <AssetForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateAssetPage;
