/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import { useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';

interface ArtifactStorageOneFileLinkProps {
  id?: string;
  source?: string;
  linkText?: string;
  [key: string]: any;
}

const ArtifactStorageOneFileLink = ({ id, linkText, source, label, ...props }: ArtifactStorageOneFileLinkProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getSingleEntityUrl } = useArtifactStorageUrl();
  const fileId = id || getId();
  const url = getSingleEntityUrl(fileId);

  return fileId ? <LinkField {...props} linkText={linkText || fileId} value={url} isInternal /> : null;
};

export default ArtifactStorageOneFileLink;
