import { useKernelApi } from 'api';
import { EMPLOYEES_PATH, useEmployeeId } from 'erp/employee/EmployeesRouter';
import useBaseCrudService from 'shared/crud/baseCrudService';

export const EVALUATION_URL = '/performance/evaluation';

const usePerformanceEvaluationProcessService = () => {
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_PATH}/${employeeId}`;
  const { search } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${EVALUATION_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${EVALUATION_URL}`,
    apiService: useKernelApi,
  });

  return {
    search,
  };
};

export default usePerformanceEvaluationProcessService;
