/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField } from 'shared/uibuilder/field';

const ContractWorkRateField = props => {
  const { getValue } = useFieldHelper(props);
  const displayedValue = `${getValue()}%`;

  return <TextField {...props} value={displayedValue} />;
};

export default ContractWorkRateField;
