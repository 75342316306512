import React from 'react';
import { HorizontalMenuItem } from 'shared/uibuilder/menu';
import PrimaryBadge from 'erp/employee/qualification/shared/field/PrimaryBadge';
import { EmployeeSpecialization } from '../employeeSpecializationService';

const QualificationMenuItem = ({
  specialization: { primary = false, qualifications, title: specializationTitle },
  linkUrl,
}: {
  linkUrl: string;
  specialization: EmployeeSpecialization;
}) => {
  const getMenuTitle = (title: string, completeness?: number) =>
    title ? `${title === 'Intern' ? `${specializationTitle} ${title}` : title} ${completeness || 0}%` : '';

  const displayedName = qualifications.reduce((previousValue, { title, completeness }, index) => {
    let name = previousValue;

    if (completeness) {
      if (previousValue !== '') {
        name += ' / ';
      }

      name += getMenuTitle(title, completeness);

      return name;
    } else {
      return name;
    }
  }, '');

  return (
    <HorizontalMenuItem
      linkUrl={linkUrl}
      name={
        <>
          {displayedName || getMenuTitle(qualifications[0]?.title, qualifications[0]?.completeness)}
          {primary && <PrimaryBadge />}
        </>
      }
      exact
    />
  );
};

export default QualificationMenuItem;
