/* istanbul ignore file */
// libraries
import React from 'react';
// components
import CompanyRolesBreadCrumbs from '../shared/CompanyRolesBreadCrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const AddCompanyRoleBreadCrumbs = () => {
  return (
    <CompanyRolesBreadCrumbs>
      <SingleBreadcrumbItem entity="Add role to an employee" />
    </CompanyRolesBreadCrumbs>
  );
};

export default AddCompanyRoleBreadCrumbs;
