import React, { useCallback, useState } from 'react';
import Typography from 'uibuilder/Typography';
import UploadInputWithoutArtifactID from 'artifact/shared/input/UploadInputWithoutArtifactID';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Button } from 'uibuilder/button';
import { UPLOAD_WBS_MESSAGE_CONTEXT, UploadWbsFormProps } from './UploadWBSForm';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import Message from 'shared/message/Message';

const UploadWbsModal = ({ closeModal }: UploadWbsFormProps) => {
  const { submitForm } = useFormContext();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const confirmBtn = (
    <Button key="modal-confirm-button" onClick={submitForm}>
      Upload
    </Button>
  );

  return (
    <ModalWindow
      key="modal-not-strict"
      modalSize="sm"
      backdrop="static"
      isOpen={isModalOpen}
      title="Are you sure?"
      onToggle={closeForm}
      buttons={[confirmBtn]}
    >
      <Message messageContext={UPLOAD_WBS_MESSAGE_CONTEXT} />
      <Typography
        sx={{
          mb: '20px',
        }}
      >
        Do you really want to upload WBS?
      </Typography>

      <UploadInputWithoutArtifactID
        required
        maxElements={1}
        label="WBS file"
        source="file"
        formats="xls,xlsx"
        canAddFromClipboard={false}
      />
    </ModalWindow>
  );
};

export default UploadWbsModal;
