/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field/index';
import {
  EmployeeNewCompetencyLevelStatus,
  EmployeeNewCompetencyLevelStatusLabels,
} from 'erp/employee/newQualification/shared/employeeNewCompetencyService';

const CompetencyStatusField = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const status = getValue() ? getValue() : EmployeeNewCompetencyLevelStatus.NOT_CONFIRMED;

  return <TextField {...props} source={source} value={EmployeeNewCompetencyLevelStatusLabels[status]} />;
};

export default CompetencyStatusField;
