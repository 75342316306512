import EqCondition, { EQ_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/EqCondition';
import React, { useEffect, useState } from 'react';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import { string } from 'prop-types';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DEFAULT_TIMEZONE, useDateTimeService } from 'shared/uibuilder/dateService';
import momentTimezone from 'moment-timezone';
import DateTimeInput from 'shared/uibuilder/form/input/DateTimeInput';

const FILTER_ERROR =
  'Please, choose the correct date and time at which you want to generate the snapshot. This date and time should be in the past.';

const AuditDateFilter = ({ source }) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const { filters, getFilterErrors, setFilterErrors } = useFilterContext();
  const { getToday } = useDateService();
  const { getTimezone } = useDateTimeService();

  useEffect(() => {
    (async () => {
      const userTimezone = await getTimezone();
      setTimezone(userTimezone);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxDate = getToday();

  const validateAndShowErrorMessages = () => {
    const inputValue = filters && filters[source] && filters[source][EQ_CONDITION_TYPE];
    const date = momentTimezone.tz(inputValue, timezone);
    const isValid = inputValue && date.isValid() && date.isSameOrBefore(maxDate);
    const showErrors = !isValid;
    if (showErrors) {
      setFilterErrors(source, EQ_CONDITION_TYPE, [FILTER_ERROR]);
    } else {
      setFilterErrors(source, EQ_CONDITION_TYPE, []);
    }
    return showErrors;
  };

  const getErrors = () => {
    return getFilterErrors(source, EQ_CONDITION_TYPE);
  };

  return (
    <EqCondition source={source}>
      <DateTimeInput
        isRequired
        label="Date and Time of the snapshot"
        validationCallback={validateAndShowErrorMessages}
        errorMessages={getErrors()}
        maxDate={maxDate}
      />
    </EqCondition>
  );
};

AuditDateFilter.propTypes = {
  source: string.isRequired,
};

AuditDateFilter.defaultProps = {};

export default AuditDateFilter;
