/* istanbul ignore file */
import React, { useEffect, memo } from 'react';
import { APPLICATIONS_STAGES } from 'erp/recruitment/applications/shared/applicationService';
import Collapse from 'shared/uibuilder/Collapse';
import QualificationCollapseHeader from 'erp/recruitment/applications/List/GroupedApplicationList/ApplicationsCollapseHeader';
import Box from 'uibuilder/Box';
import ApplicationsDataGrid from 'erp/recruitment/applications/List/ApplicationsDataGrid';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { isEqual } from 'lodash';
import useApplicationsSettings from 'erp/recruitment/applications/List/GroupedApplicationList/Settings/useApplicationsSettings';

export interface GroupedApplicationListCollapseProps {
  stage: string;
  filter?: Dictionary<any>;
  search?: string;
}

const GroupedApplicationListCollapse = memo(
  ({ stage, filter: filterFromProps, search: searchFromProps = '' }: GroupedApplicationListCollapseProps) => {
    const { filterBy, filter, search, searchBy } = useListContext();
    const { toggle, collapseStates = {} } = useApplicationsSettings();

    useEffect(() => {
      if (filterFromProps && filterBy && !isEqual(filterFromProps, filter)) {
        filterBy(filterFromProps);
      }
      // Suppressed warnings because we only need to call useEffect callback only after updating filters
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFromProps]);

    useEffect(() => {
      if (searchBy && !isEqual(searchFromProps, search)) {
        searchBy(searchFromProps);
      }
      // Suppressed warnings because we only need to call useEffect callback only after updating search string
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFromProps]);

    return (
      <Collapse
        header={<QualificationCollapseHeader title={APPLICATIONS_STAGES[stage]} />}
        isExpanded={collapseStates[stage]}
        toggle={() => toggle(stage)}
      >
        <Box sx={{ minHeight: '100px', position: 'relative', pb: 2 }}>
          <ApplicationsDataGrid
            key={`applications-${stage}-collapse-table`}
            showTotal={false}
            hasStageField={false}
            isSmallTable
          />
        </Box>
      </Collapse>
    );
  },
);

export default GroupedApplicationListCollapse;
