import React from 'react';
import useSentFeedbacksService from '../useSentFeedbacksService';
import useFeedbackRegistry from '../FeedbackRegistry';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { EmployeeFilter } from 'erp/employee';

const filter = (
  <Filters>
    <FiltersLayout>
      <EmployeeFilter source="feedback.employee.alias" label="Employee" />
    </FiltersLayout>
  </Filters>
);

const SentFeedbackPage = () => {
  const { search } = useSentFeedbacksService();
  const feedbackRegistry = useFeedbackRegistry();

  return (
    <Timeline registries={[feedbackRegistry]} getDataMethod={request => search(request)}>
      <ListPageLayout
        header={<ListHeader label="Feedback I left" icon="fa-archive" />}
        showTotal={false}
        filter={filter}
      >
        <TimelineLayout noEntriesMessage="You haven't left any Instant Feedback yet" />
      </ListPageLayout>
    </Timeline>
  );
};

export default SentFeedbackPage;
