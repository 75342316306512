/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';

const InvoiceReferencesList = ({ source }: { source: string }) => {
  const { getValue } = useFieldHelper({ source });
  const references = (getValue() as any) || {};

  return (
    <ControlledList data={{ items: references, totalPages: 1, totalElements: references.length }}>
      <DataGridLayout>
        <TextField source="referenceType" label="Type" width="50%" />
        <TextField source="referenceValue" label="Value" width="50%" />
      </DataGridLayout>
    </ControlledList>
  );
};

export default InvoiceReferencesList;
