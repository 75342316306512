/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useAssessmentService from 'erp/employee/assessment/assessmentService';
import { ASSESSMENTS_ID_SOURCE } from 'erp/employee/assessment/AssessmentRouter';
import { Permission } from 'shared/authorization/authorizationService';

const CONTEXT_NAME = 'Assessments';

type AssessmentContextProps = {
  children: React.ReactNode;
  permissionToCheck: Permission | Permission[];
};

const AssessmentContext = ({ permissionToCheck, children }: AssessmentContextProps) => {
  const { getPermissions } = useAssessmentService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
      idSource={ASSESSMENTS_ID_SOURCE}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default AssessmentContext;
