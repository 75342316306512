/* istanbul ignore file */
import React from 'react';
import EmployeeContractsBreadCrumbs from 'erp/employee/contracts/shared/ContractsBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateEmployeeContractBreadCrumbs = () => {
  return (
    <EmployeeContractsBreadCrumbs>
      <CreateBreadcrumbItem entity="Contract" />
    </EmployeeContractsBreadCrumbs>
  );
};

export default CreateEmployeeContractBreadCrumbs;
