import React, { useCallback, useMemo } from 'react';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import { OptionProps, components } from 'react-select';
import Tooltip from 'uibuilder/Tooltip';
import useWasteService from '../../useWasteService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { RedmineTicket } from '../../createupdate/shared/formRows/LaborCostWasteRow';

type TypeRedmineTicketsMultiSelect = {
  source: string;
  label: string;
  loadMethod: () => ResultResourceData;
  deps?: any[];
};

const CustomOption = (props: OptionProps<any>) => {
  const { data, innerRef, innerProps } = props;
  return (
    <Tooltip
      placement="right"
      title={data.violatesBusinessRules ? `The issue has time reports earlier than 2 weeks ago!` : ``}
    >
      <div ref={innerRef} {...innerProps}>
        <components.Option {...props}>{props?.children}</components.Option>
      </div>
    </Tooltip>
  );
};

const RedmineTicketsMultiSelect: React.FC<TypeRedmineTicketsMultiSelect> = ({ source, label, loadMethod, deps }) => {
  const { isHasWithViolations } = useWasteService();

  const mapResults = useCallback(
    (result: RedmineTicket): any => {
      return {
        value: `${result.id}`,
        label: `${result.id}`,
        disabled: !isHasWithViolations && result.violatesBusinessRules,
        violatesBusinessRules: result.violatesBusinessRules,
      };
    },
    [isHasWithViolations],
  );

  const customComponents = useMemo(() => ({ Option: CustomOption }), []);

  return (
    <LoadDropdown
      mapResults={mapResults}
      source={source}
      multiple
      label={label}
      loadDataMethod={loadMethod}
      deps={deps}
      placeholder="Choose ticket ID (s)"
      components={customComponents}
      isClearValueByUpdateDeps
    />
  );
};

export default RedmineTicketsMultiSelect;
