/* istanbul ignore file */
import React from 'react';
import EntityHeader from 'shared/layout/EntityHeader';
import PublicServiceBadge from '../../../shared/uibuilder/badge/PublicServiceBadge';
import useFeatureToggle, { Features } from '../../../featuretoggle';

const RbsEntityHeader = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const showPublicServiceBadge = isFeatureEnabled(Features.EMPLOYEE_GROUP);
  return (
    <EntityHeader
      entityTitleSource="name"
      hasAuthor={false}
      badges={rbsNode => [
        showPublicServiceBadge && rbsNode.getValueBySource('isPublicService') ? [<PublicServiceBadge />] : [null],
      ]}
    />
  );
};

export default RbsEntityHeader;
