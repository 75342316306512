/* istanbul ignore file */
import Box from 'uibuilder/Box';
import React from 'react';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';

export interface BaseTimelineItemFormLayoutProps {
  onSubmitAction?: () => void;
  children?: React.ReactNode;
  onCancel?: () => void;
}

interface TimelineItemFormLayoutProps extends BaseTimelineItemFormLayoutProps {
  isCreateForm?: boolean;
}

const BaseTimelineItemFormLayout = ({
  children,
  isCreateForm = false,
  onSubmitAction,
  onCancel,
}: TimelineItemFormLayoutProps) => {
  return (
    <Box className={`card ${isCreateForm ? 'border-success' : ''}`}>
      <div className="card-body">
        {children}
        <FormButtonsLayout onCancelAction={onCancel} onSubmitAction={onSubmitAction} />
      </div>
    </Box>
  );
};

export default BaseTimelineItemFormLayout;
