import React from 'react';
import { useProjectId } from '../../ProjectRouter';
import { ButtonWithConfirmation } from 'uibuilder/button';
import useProjectService, { ARCHIVE_DAVINCI_OBJECT } from '../projectService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ArchiveObjects = () => {
  const { data } = useShowContext();
  const defaultBudgetId = data.getValueBySource('defaultBudgetId') || undefined;
  const isArchived = data.getValueBySource('archived');
  const { archiveObjects } = useProjectService();
  const id = useProjectId();

  if (isArchived) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      afterSubmit={{
        successMessage: 'Project archived successfully.',
        errorMessage: `Can't archive project.`,
      }}
      submitMethod={() => archiveObjects(id, defaultBudgetId)}
      permissionToCheck={ARCHIVE_DAVINCI_OBJECT}
      displayMessage="Do you really want to archive this project?"
      outline
    >
      Archive
    </ButtonWithConfirmation>
  );
};

export default ArchiveObjects;
