import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { FieldProps, BaseFieldLayoutType } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const PercentField = (props: FieldProps<string | number>) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const initialValue = +getValue();
  const value = initialValue || initialValue === 0 ? `${getValue()}%` : null;

  return <BaseFieldLayout {...props} label={getLabel()} value={value} />;
};

export default PercentField;
