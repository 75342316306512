/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const TimelineMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Timeline" icon="history" {...props} />
);

TimelineMenuItem.displayName = 'Timeline';

TimelineMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default TimelineMenuItem;
