/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import { CreateForm } from 'shared/uibuilder/form';
import { TextInput } from 'shared/uibuilder/form/input';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { usePrivilegedEmployeeId } from '../../PrivilegedRouter';
import PositionDropdown from 'erp/employee/shared/input/PositionDropdown';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { useExternalAccountsService } from '../externalAccountsService';
import EmployeeTypeDropDown from 'erp/employee/shared/input/EmployeeTypeDropdown';
import '../AccountsCreationForm.css';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import useEmployeeService from '../../../erp/employee/employeeService';

const EmployeeAccountsCreationForm = () => {
  const employeeAlias = usePrivilegedEmployeeId();
  const { getById } = useEmployeeService();
  const { getProfileEditUrl } = useEmployeeUrl();
  const { deprovisionExternalAccounts } = useExternalAccountsService();

  const submitFormFunc = () => {
    return deprovisionExternalAccounts({ employeeId: employeeAlias });
  };

  return (
    <CreateForm
      getDataFunc={() => getById(employeeAlias)}
      submitFormFunc={submitFormFunc}
      afterSubmit={{
        execute: () => {},
      }}
    >
      <CreatePageLayout title="Deprovision: Azure, Redmine and GitLab accounts">
        <FormSection title="Employee account information">
          <FormRow>
            <TextInput label="First name" source="nameEn.firstName" disabled />
            <TextInput label="Last name" source="nameEn.lastName" disabled />
          </FormRow>
          <FormRow>
            <EmployeeTypeDropDown label="Type" source="employeeType" disabled />
            <PositionDropdown label="Position" source="positionId" disabled isRequired />
          </FormRow>
          <FormRow>
            <TextInput label="Syberry Email" source="workingEmail" disabled />
            <TextInput label="Azure ID" source="azureId" disabled />
          </FormRow>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <a href={getProfileEditUrl(employeeAlias)} target="_blank" rel="noreferrer">
              Open employee profile
            </a>
            <InputTooltip
              message="Reload page to get profile changes"
              source=""
              size="small"
              sx={{ marginLeft: '10px' }}
            />
          </Box>
        </FormSection>
      </CreatePageLayout>
    </CreateForm>
  );
};

export default EmployeeAccountsCreationForm;
