import React from 'react';
import { RoutingSwitch, Route } from 'shared/routing';
import OneStopListPage from 'oneStop/List';
import OneStopCreatePage from 'oneStop/Create';

export const ONE_STOP_ROUTES = {
  listRoute: '/one-stop',
  createRoute: '/one-stop/:id',
};

export default () => (
  <RoutingSwitch>
    <Route path={ONE_STOP_ROUTES.createRoute} exact component={OneStopCreatePage} />
    <Route path={ONE_STOP_ROUTES.listRoute} exact component={OneStopListPage} />
  </RoutingSwitch>
);
