/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import FinanceTypeDropdown from 'erp/employee/finance/shared/inputs/FinanceTypeDropdown';
import BaseAmountTypeDropdown from 'erp/employee/finance/shared/inputs/BaseAmountTypeDropdown';
import ProbationPeriodReductionInput from 'erp/employee/finance/shared/inputs/ProbationPeriodReductionInput';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import useFeatureToggle, { Features } from 'featuretoggle';

const FinanceForm = () => {
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  return (
    <FormSection title="Compensation package information">
      <FormRow>
        <FinanceTypeDropdown source="type" label="Type" />
        <DateInput source="effectiveDateFrom" label="Effective date from" />
        <DateInput source="effectiveDateTo" label="Effective date to (including)" />
        <BaseAmountTypeDropdown source="baseAmountType" label="Base Amount Type" />
        <TextInput source="grossAmount" label="Base Amount Gross" />

        <CurrencyDropdown source="currency" label="Currency" />
        <ProbationPeriodReductionInput
          source="probationReduction"
          probationAmountSource="grossProbationAmount"
          baseAmountSource="grossAmount"
          label="Probation period reduction, %"
        />
        <TextInput source="grossProbationAmount" label="Probation period absolute value Gross" />
        <DateInput source="firstDay" label="First day of base value application" />
        <OfficeDropdown source="officeId" label="Office" isVisible={twoContractEnabled} />
      </FormRow>
      <BigFormRow>
        <TextArea label="Additional expenses compensated" source="additionalExpenses" />
      </BigFormRow>
      <BigFormRow>
        <DocumentScanUploaderInput label="Document scans" source="scansIds" />
      </BigFormRow>
    </FormSection>
  );
};

export default FinanceForm;
