/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_MEDIUM } from 'crm/lead/shared/leadService';

const LeadMediumField = ({ value: inputValue, source }) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Lead Medium" value={value} options={LEAD_MEDIUM} />;
};

LeadMediumField.propTypes = {
  value: string,
  source: string,
};

LeadMediumField.defaultProps = {
  value: null,
  source: null,
};

export default LeadMediumField;
