import { useState } from 'react';
import { Request } from 'oneStop/oneStopService';
import { FormRowType } from 'shared/uibuilder/form/Builder/FormRowFactory';
import useFormConfigurationMapper from 'oneStop/Create/formConfigurationMapper';

type OneStopConfigurationProps = {
  id: string;
  rowType?: Nullable<FormRowType>;
  getById: (id: string) => Promise<Request>;
};

const useOneStopConfiguration = ({ getById, id, rowType = null }: OneStopConfigurationProps) => {
  const [request, setRequest] = useState<Nullable<Request> | undefined>(undefined);
  const { getFormConfiguration, getValidationSchema } = useFormConfigurationMapper();

  const getRequestData = async () => {
    if (typeof request !== 'undefined') {
      return request;
    } else {
      const requestData = await getById(id);
      setRequest(requestData);

      return requestData;
    }
  };

  const getFormFieldsConfiguration = async () => {
    const data = await getRequestData();

    return getFormConfiguration(data, rowType);
  };

  const getFormValidationSchema = async () => {
    const data = await getRequestData();

    return getValidationSchema(data);
  };

  return {
    getRequestConfiguration: getRequestData,
    getValidationSchema: getFormValidationSchema,
    getFormConfiguration: getFormFieldsConfiguration,
  };
};

export default useOneStopConfiguration;
