/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_SOURCE } from 'crm/lead/shared/leadService';

const LeadSourceField = ({ value: inputValue, source }) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Lead Source" value={value} options={LEAD_SOURCE} />;
};

LeadSourceField.propTypes = {
  value: string,
  source: string,
};

LeadSourceField.defaultProps = {
  value: null,
  source: null,
};

export default LeadSourceField;
