/* istanbul ignore file */
import React from 'react';
import useEmployeeSalaryService from 'erp/employee/salaries/shared/salaryService';
import { useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateSalaryBreadcrumbs from 'erp/employee/salaries/createupdate/Create/CreateBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import SalaryForm from 'erp/employee/salaries/createupdate/SalaryForm';

const CreateSalaryPage = () => {
  const { create, getValidationSchema } = useEmployeeSalaryService();
  const { getListUrl } = useEmployeeSalaryUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Salary has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        performanceDetails: {
          helpfulnessGrade: null,
        },
        modelBased: false,
      }}
    >
      <CreatePageLayout
        title="Create: Salary"
        breadcrumbs={<CreateSalaryBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
      >
        <SalaryForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateSalaryPage;
