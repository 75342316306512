/* istanbul ignore file */
import React from 'react';
import Message from 'shared/message/Message';
import OneStopButtons from 'uibuilder/layout/form/OneStopButtonsLayout';

type InstantFeedbackLayoutProps = {
  isFixedButtons?: boolean;
  children: React.ReactElement;
  headerRight?: React.ReactElement;
  handleCancelClick?: Nullable<() => void>;
  messageContext?: string;
};

const InstantFeedbackLayout = ({
  children,
  headerRight,
  isFixedButtons,
  handleCancelClick,
  messageContext,
}: InstantFeedbackLayoutProps) => {
  const Title = () => {
    return <div>{headerRight}</div>;
  };

  return (
    <>
      <Message messageContext={messageContext} />
      <Title />
      {children}
      <OneStopButtons onCancelAction={handleCancelClick} isFixed={isFixedButtons} />
    </>
  );
};

InstantFeedbackLayout.defaultProps = {
  handleCancelClick: null,
  headerRight: null,
  isFixedButtons: true,
  messageContext: undefined,
};

export default InstantFeedbackLayout;
