import React, { useEffect, useState } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import LeaveDurationLayout from 'erp/leave/createupdate/duration/LeaveDurationLayout';
import useLeaveService from 'erp/leave/shared/leaveService';
import InputLoader from 'shared/uibuilder/InputLoader';

import 'erp/leave/createupdate/duration/LeaveDurationLayout.scss';

const LeaveDurationWidget = () => {
  const [days, setDays] = useState(null);
  const { getDuration } = useLeaveService();
  const { data } = useFormContext();
  const { type, startDate, endDate, employeeId } = data;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (type && startDate && endDate) {
      setIsLoading(true);
      getDuration(type, startDate, endDate, employeeId)
        .then(response => setDays(response.days))
        .catch(() => setDays(null))
        .finally(() => setIsLoading(false));
    }
  }, [type, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? (
    <div className="leave-duration-layout__loading">
      <InputLoader />
    </div>
  ) : (
    <LeaveDurationLayout days={days} startDate={startDate} endDate={endDate} />
  );
};

export default LeaveDurationWidget;
