/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import useProcessVariableService from 'camunda/monitoring/instance/Show/variables/processVariablesService';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import AddProcessVariableForm from 'camunda/monitoring/instance/Show/variables/shared/AddProcessVariableForm';

const MESSAGE_CONTEXT = 'AddProcessVariableModalForm';

interface AddProcessVariableModalFormProps {
  closeModal: () => void;
}

const AddProcessVariableModalForm = ({ closeModal }: AddProcessVariableModalFormProps) => {
  const processInstanceId = useProcessInstanceId();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { create, getValidationSchema } = useProcessVariableService();

  const closeForm = useCallback(() => {
    setIsModalOpen(false);
    closeModal();
  }, [setIsModalOpen, closeModal]);

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Add variable
    </Typography>
  );

  return (
    <CreateForm
      submitFormFunc={create}
      messageContext={MESSAGE_CONTEXT}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        instanceId: processInstanceId,
        objectTypeName: null,
        type: null,
      }}
      afterSubmit={{
        execute: closeForm,
      }}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeForm}
      >
        <AddProcessVariableForm handleCancelClick={closeForm} />
      </ModalWindow>
    </CreateForm>
  );
};

export default AddProcessVariableModalForm;
