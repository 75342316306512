import { cloneDeep, get, set } from 'lodash';
import budgetValidation from 'erp/budget/createupdate/budgetValidation';
import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const BUDGET_PATH = '/budgets';

export const READ_BUDGETS_LIST = 'BUDGET_READ_LIST';
export const READ_BUDGET_REPORT = 'BUDGET_READ_BUDGET_REPORT';
export const READ_BUDGET = 'READ';
export const CREATE_BUDGET = 'BUDGET_CREATE';
export const UPDATE_BUDGET = 'BUDGET_UPDATE';
export const DELETE_BUDGET = 'BUDGET_DELETE';

export const ACTIVE_STATUS = 'ACTIVE';
export const ARCHIVED_STATUS = 'ARCHIVED';
const STATUS_FILTER_KEY = 'filter.status.in';
const ARCHIVED_FILTER_KEY = 'filter.archived';

export interface BudgetSearchFilterProps {
  excludedId?: number | string;
  budgetOwner?: boolean;
  defaultableBudget?: boolean;
  notArchived?: boolean;
  hasActivePeriod?: boolean;
  name?: any;
}

const useBudgetService = () => {
  const singleResourceUrl = '/budgets/:id';
  const { formatDateForAPI } = useDateService();
  const {
    getById: baseGetById,
    create: baseCreate,
    update: baseUpdate,
    search: baseSearch,
    ...baseCrudRequests
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/budgets',
    apiService: useKernelApi,
  });

  const { sendDeleteRequest, sendPostRequest, sendPutRequest } = useKernelApi();

  const getById = async (id: ResourceId) => {
    const response = await baseGetById(id);
    if (response) {
      response.maxFte = response.maxFtePercent ? response.maxFtePercent / 100.0 : null;
    }

    return response;
  };

  const mapBudget = ({ maxFte, ...data }: any) => {
    return {
      ...data,
      maxFtePercent: maxFte ? maxFte * 100 : null,
    };
  };

  const search = async (request: any) => {
    const response = await baseSearch(request);
    if (response?.result) {
      response.result.forEach((budget: any) => {
        // eslint-disable-next-line no-param-reassign
        budget.maxFte = budget.maxFtePercent ? budget.maxFtePercent / 100.0 : null;
      });
    }

    return response;
  };

  const create = async (data: any) => {
    return baseCreate(mapBudget(data));
  };

  const update = async (id: string, values: FormFieldsData) => {
    return baseUpdate(id, mapBudget(values));
  };

  const getValidationSchema = () => Promise.resolve(budgetValidation);

  const deleteBudget = async (id: string, withChildBudgets: boolean) => {
    await sendDeleteRequest(`/budgets/${id}?withChildBudgets=${withChildBudgets}`);
  };

  const searchAvailable = async (budgetFromId: string, filter: any = {}) => {
    const response = await sendPostRequest(`${BUDGET_PATH}/${budgetFromId}/availableForReallocation`, filter);
    return response.json();
  };

  const copyForNextYear = async (id: any) => {
    const toCopy = cloneDeep(await getById(id));
    const nextYear = new Date(toCopy.periodTo).getFullYear() + 1;
    toCopy.periodFrom = formatDateForAPI(new Date(nextYear, 0, 1).toUTCString());
    toCopy.periodTo = formatDateForAPI(new Date(nextYear, 11, 31).toUTCString());
    toCopy.name = `${toCopy.name.replace(/( \(\d+\))/g, '')} (${nextYear})`;

    return create(toCopy);
  };

  const archive = async (id: any, value: boolean) => {
    return sendPutRequest(`/budgets/${id}/archive`, { archived: value });
  };

  const searchRootBudgets = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const hasFilterByActiveStatus = get(request, STATUS_FILTER_KEY)?.includes(ACTIVE_STATUS);
    const hasFilterByArchivedStatus = get(request, STATUS_FILTER_KEY)?.includes(ARCHIVED_STATUS);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (hasFilterByActiveStatus && !hasFilterByArchivedStatus) {
      set(searchParams, ARCHIVED_FILTER_KEY, { eq: false });
    }

    if (hasFilterByArchivedStatus && !hasFilterByActiveStatus) {
      set(searchParams, ARCHIVED_FILTER_KEY, { eq: true });
    }

    if (searchParams.filter?.status) {
      delete searchParams.filter.status;
    }
    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.name = {
        ct: searchString,
      };
    }

    if (searchParams.filter) {
      searchParams.filter['specification:isRootBudget'] = { eq: true };
    }

    return search(searchParams);
  };

  const getSearchFilter = ({
    excludedId,
    budgetOwner,
    defaultableBudget,
    notArchived,
    hasActivePeriod,
    name,
  }: BudgetSearchFilterProps) => {
    const filter: any = excludedId
      ? {
          id: {
            ne: excludedId,
          },
        }
      : {};

    if (budgetOwner) {
      filter['specification:isBudgetOwner'] = {
        eq: true,
      };
    }

    if (defaultableBudget) {
      filter['specification:isDefaultableBudget'] = {
        eq: true,
      };
    }

    if (notArchived) {
      filter.archived = {
        eq: false,
      };
    }

    if (hasActivePeriod) {
      filter['specification:budgetHasActivePeriod'] = {
        eq: true,
      };
    }

    if (name) {
      filter.name = {
        ct: name,
      };
    }

    return filter;
  };

  return {
    getById,
    getValidationSchema,
    searchRootBudgets,
    deleteBudget,
    ...baseCrudRequests,
    create,
    update,
    search,
    searchAvailable,
    copyForNextYear,
    archive,
    getSearchFilter,
  };
};

export default useBudgetService;
