import React from 'react';
import { TextField, FieldProps } from 'shared/uibuilder/field/index';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { AddressType } from './AddressField';

const getDisplayedValue = (value?: string, hasComma = true) => (value ? `${hasComma ? ', ' : ''}${value}` : '');

const InlineAddressField = (props: FieldProps<AddressType>) => {
  const { getValue } = useFieldHelper(props);

  const address = getValue();
  let displayedValue = null;

  if (address) {
    const { country, state, city, addressLine1, addressLine2, zipCode } = address || {};

    displayedValue = [country, state, city, addressLine1, addressLine2, zipCode].reduce(
      (value, currentValue) => `${value}${getDisplayedValue(currentValue, !!value)}`,
      '',
    );
  }

  return <TextField {...props} value={displayedValue} />;
};

export default InlineAddressField;
