/* istanbul ignore file */
import React from 'react';
import Tooltip from 'uibuilder/Tooltip';
import ProjectAllocationInput from 'syberryToday/shared/input/ProjectAllocationInput';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import ProjectWeeklyAllocationsProgress from 'syberryToday/shared/ProjectWeeklyAllocationsProgress';
import ProjectAllocationLayout from 'syberryToday/shared/layout/ProjectAllocationLayout';
import Box from 'uibuilder/Box';

const ProjectAllocation = ({
  projectId,
  projectName,
  allocation,
  canEnterAllocations = true,
}: {
  projectId: number;
  projectName: string;
  allocation: Dictionary<any>;
  canEnterAllocations?: boolean;
}) => {
  const { weekly, recommend } = allocation || {};
  const { updatePlannedAllocation, plannedAllocations = {} } = useSyberryTodayContext();
  const allocationInputSource = `allocation-${projectId}`;

  const allocations = [
    {
      label: 'Weekly time allocation',
      value: (
        <Tooltip
          title={
            <>
              <div>
                Planned: {weekly?.planned?.min} - {weekly?.planned?.max}h
              </div>
              <div>Spent time: {weekly?.spentTime}h</div>
              <div>
                Remaining: {weekly?.remaining?.min} - {weekly?.remaining?.max}h
              </div>
            </>
          }
          placement="right"
        >
          <div>
            <ProjectWeeklyAllocationsProgress
              currentValue={weekly?.spentTime}
              maxValue={weekly?.planned?.max}
              middleValue={weekly?.planned?.min}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      label: 'Recommend allocation',
      value: `${recommend || 0}h`,
    },
    {
      label: 'Today’s allocation',
      value: canEnterAllocations ? (
        <>
          <ProjectAllocationInput
            key={allocationInputSource}
            source={allocationInputSource}
            placeholder="+ Empty"
            onChangeCallback={(inputData: Dictionary<any>) => {
              updatePlannedAllocation(projectId, inputData[allocationInputSource]);
            }}
            value={plannedAllocations[projectId]}
            className="allocation-input"
            label=" "
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={allocationInputSource} className="allocation-metric">
            h
          </label>
        </>
      ) : (
        <Box sx={{ color: 'var(--mui-palette-primary-main)' }}>{`${plannedAllocations[projectId] || 0}h`}</Box>
      ),
    },
  ];

  return (
    <ProjectAllocationLayout
      projectId={projectId}
      projectName={projectName}
      allocations={allocations}
      hasAllocation={!!allocation}
    />
  );
};

export default ProjectAllocation;
