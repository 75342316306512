import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useTrainingsUrl } from '../../TrainingsRouter';

const ViewTrainingButton = (props: any) => {
  const { getSingleEntityUrl } = useTrainingsUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-training-entry" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewTrainingButton;
