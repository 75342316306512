/* istanbul ignore file */

export default {
  subject: {
    type: 'string',
    required: {
      value: true,
      when: {
        $inReplyToId: {
          empty: true,
        },
      },
    },
    maxSize: 255,
  },
  body: {
    type: 'string',
    required: true,
    maxSize: 65556,
  },
};
