/* istanbul ignore file */
import React from 'react';
import useEmployeeService from 'erp/employee/employeeService';
import useAsyncValue from 'shared/asyncHelper';
import { isPolishOffice } from 'erp/employee/office/officeService';

const useLeavesErrorMap = () => {
  const { getCurrentEmployee } = useEmployeeService();
  const employee = useAsyncValue(async () => getCurrentEmployee(), []);
  const isPolishEmployee = employee && isPolishOffice(String(employee.officeId));

  const leavePolicy = isPolishEmployee ? (
    <></>
  ) : (
    <>
      {' '}
      (
      <a href="https://davinci.syberry.com/handbook/leave-policy-belarus.md" target="_blank" rel="noopener noreferrer">
        Leave Policy for Employees
      </a>
      )
    </>
  );

  return {
    'LEAVE-BR-1': isPolishEmployee
      ? 'You must have an active contract to submit a leave.'
      : 'You must have an active contract to submit a leave (article 154 of the labor code of the Republic of Belarus).',
    'LEAVE-BR-2': available => (
      <>
        Requested paid leave contains more days than you have available: {available}
        {leavePolicy}.
      </>
    ),
    'LEAVE-BR-3': available => (
      <>
        Requested sick day contains more days than you have available:&nbsp;{available}
        {leavePolicy}.
      </>
    ),
    'LEAVE-BR-4': available => (
      <>
        Requested day on demands contains more days than you have available:&nbsp;{available}
        {leavePolicy}.
      </>
    ),
    'LEAVE-BR-5': <>Sick day cannot be longer than 1 day{leavePolicy}.</>,

    'LEAVE-BR-6': <>Paid leave duration must be between 10 and 15 calendar days{leavePolicy}.</>,

    'LEAVE-BR-7-BY': (
      <>Paid leave cannot be taken earlier than 6 months after you started working at Syberry{leavePolicy}.</>
    ),

    'LEAVE-BR-7-PL': (
      <>
        Paid leave can be taken only 1 month after an employee signed their first contract with the Polish office
        {leavePolicy}.
      </>
    ),

    'LEAVE-BR-8-BY': <>The interval between paid leaves must be at least 3 months{leavePolicy}.</>,

    'LEAVE-BR-8-PL': (
      <>
        For all paid leaves lasting more than 3 working days, the interval between the end date of one leave and the
        start date of another leave must be at least 3 calendar months{leavePolicy}.
      </>
    ),

    'LEAVE-BR-9': (
      <>
        Paid leave must be requested not earlier than 3 months and not later than 1 month before its start date
        {leavePolicy}.
      </>
    ),

    'LEAVE-BR-10': (
      <>
        Unpaid leave with duration more than 2 business days must be requested at least 1 week before its start date
        {leavePolicy}.
      </>
    ),

    'LEAVE-BR-11': (
      <>
        Paid leave must start in the current working year because you have available days for paid leave in this year
        {leavePolicy}.
      </>
    ),

    'LEAVE-BR-12': <>Dates of requested leave overlap already created leaves of the same type{leavePolicy}.</>,

    'LEAVE-BR-13-1': (
      <>Dates of requested unpaid leave overlap already created paid leaves or sick days{leavePolicy}.</>
    ),

    'LEAVE-BR-13-2': (
      <>Dates of requested paid leave overlap already created unpaid leaves or sick days{leavePolicy}.</>
    ),

    'LEAVE-BR-13-3': <>Requested sick day overlap already created paid leaves or unpaid leaves{leavePolicy}.</>,

    'LEAVE-BR-13-4': (
      <>Dates of requested leave compensation overlap already created unpaid leaves or sick days{leavePolicy}.</>
    ),

    'LEAVE-BR-13-5': <>Dates of requested leave compensation overlap already created leaves{leavePolicy}.</>,

    'LEAVE-BR-13-6': <>Dates of requested leave compensation overlap already created leaves{leavePolicy}.</>,

    'LEAVE-BR-14': (
      <>
        You don’t have days available for paid leave in the current working year. Days from the next working year are
        still available{leavePolicy}.
      </>
    ),

    'LEAVE-BR-15-1': <>Requested military leave overlaps already created leaves of other types{leavePolicy}.</>,

    'LEAVE-BR-15-2': <>Requested leave overlaps already created military leave{leavePolicy}.</>,
    LEAVES_EXTENSION_DATE_FORBIDDEN: 'Leave extension is allowed for Paid Leaves only',
  };
};

export default useLeavesErrorMap;
