/* istanbul ignore file */
import React from 'react';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import { UPDATE_PURCHASE_ORDER } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import EditButton from 'uibuilder/button/EditButton';

const EditPurchaseOrderButton = (props: any) => {
  const { getUpdateEntityUrl } = usePurchaseOrderUrl();

  return (
    <EditButton
      path={getUpdateEntityUrl}
      label="Edit"
      key="create-purchase-order"
      permissionToCheck={UPDATE_PURCHASE_ORDER}
      outline
      {...props}
    />
  );
};

export default EditPurchaseOrderButton;
