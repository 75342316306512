/* istanbul ignore file */
import { useEffect } from 'react';
import useHeaderMenuService, { HeaderMenuItemProps } from 'shared/uibuilder/menu/HeaderMenu/headerMenuService';

export interface HeaderMenuProps {
  menuData?: HeaderMenuItemProps[];
}

const HeaderMenu = ({ menuData = [] }: HeaderMenuProps) => {
  const { addMenuData } = useHeaderMenuService();

  useEffect(() => {
    addMenuData(menuData);
    // Suppressed warnings because we only need to call useCallback callback if menuData are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  return null;
};

export default HeaderMenu;
