/* istanbul ignore file */

export default {
  subject: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  description: {
    type: 'string',
    required: true,
    maxSize: 65556,
  },

  senderEmail: {
    type: 'string',
    email: {
      value: true,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
    maxSize: {
      value: 255,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
  },
  receiversEmails: {
    type: 'array',
    maxSize: {
      value: 255,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
    email: {
      value: true,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
  },
  ccEmails: {
    type: 'array',
    maxSize: {
      value: 255,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
    email: {
      value: true,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
  },
  bccEmails: {
    type: 'array',
    maxSize: {
      value: 255,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
    email: {
      value: true,
      when: {
        $activityType: {
          eq: 'EMAIL',
        },
      },
    },
  },
  communicationChannel: {
    type: 'string',

    maxSize: {
      value: 64,
      when: {
        $activityType: {
          eq: 'CALL',
        },
      },
    },
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'CALL',
        },
      },
    },
  },
  location: {
    type: 'string',
    maxSize: {
      value: 64,
      when: {
        $activityType: {
          eq: 'MEETING',
        },
      },
    },
    required: {
      value: true,
      when: {
        $activityType: {
          eq: 'MEETING',
        },
      },
    },
  },
  attachmentsIds: {
    type: 'array',
  },
};
