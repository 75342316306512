/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import { func } from 'prop-types';
import React from 'react';
import NoteForm from 'erp/employee/Timeline/note/form/NoteForm';
import useEmployeeNoteService from 'erp/employee/Timeline/note/employeeNoteService';

const CreateNoteForm = ({ submitFormFunc, onCancel, ...props }) => {
  const { getValidationSchema } = useEmployeeNoteService();
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

CreateNoteForm.propTypes = {
  submitFormFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default CreateNoteForm;
