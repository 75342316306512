/* istanbul ignore file */
import React from 'react';
import { useFinanceUrl } from 'erp/employee/finance/FinanceRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewFinanceButton = props => {
  const { getSingleEntityUrl } = useFinanceUrl();

  return (
    <LinkButton url={getSingleEntityUrl} outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewFinanceButton;
