/* istanbul ignore file */
import React from 'react';
import useAssignmentService, { AssignmentType, FE_DELETE_ASSIGNMENTS } from 'erp/assignment/shared/assignmentService';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

interface DeleteAssignmentFromListButtonProps {
  userAlias?: Nullable<string>;
}

const hasAccessToDelete = (data: any, userAlias: Nullable<string> | undefined): boolean => {
  return data?.createdById === userAlias;
};

const getDeleteMessage = (data: any): string => {
  const assignmentType = data.getValueBySource('assignmentType');
  const isIndividualAssignment = assignmentType === AssignmentType.INDIVIDUAL_ASSIGNMENT;
  const assigneeType = isIndividualAssignment ? 'employee' : 'public service';
  const assigneeSource = isIndividualAssignment ? 'employeeName' : 'employeeGroup.name';
  const assignee = data.getValueBySource(assigneeSource);
  const assigneeBody = assignee ? `for the ${assigneeType} '${assignee}'` : 'with empty assignee';
  const projectName = data.getValueBySource('project.name');

  return `Do you really want to delete the assignment ${assigneeBody} to the project '${projectName}'?`;
};

const DeleteAssignmentFromListButton = ({ userAlias }: DeleteAssignmentFromListButtonProps) => {
  const { deleteById } = useAssignmentService();
  const { getListUrl } = useAssignmentUrl();
  const { data } = useShowContext();
  const assignmentId = data.getValueBySource('id');
  const permissionToCheck = hasAccessToDelete(data, userAlias) ? '' : FE_DELETE_ASSIGNMENTS;
  const deleteMessage = getDeleteMessage(data);

  return (
    <DeleteButton
      deleteMethod={() => deleteById(assignmentId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Assignment has been successfully deleted.',
        errorMessage: `Can't delete assignment`,
      }}
      permissionToCheck={permissionToCheck}
      showIcon={false}
    />
  );
};

export default DeleteAssignmentFromListButton;
