/* istanbul ignore file */
// libs
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
// components
import AccountContext from 'crm/account/AccountContext';
import Page404 from 'shared/uibuilder/Page404';
import AccountRelatedContacts from 'crm/account/Show/AccountRelatedContacts';
import AccountRelatedOpportunities from 'crm/account/Show/AccountRelatedOpportunities';
import useRoute from 'shared/routing/useRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
// services
import { READ, READ_TIMELINE } from 'crm/crmService';
import GlobalSecurityContext from 'shared/authorization/GlobalSecurityContext';
import AccountsListPage from 'crm/account/list';
import { CREATE_ACCOUNT, READ_ACCOUNT, READ_ACCOUNTS_LIST, UPDATE_ACCOUNT } from 'crm/account/accountService';
import { CreateAccount, UpdateAccount } from 'crm/account/createupdate';
import AccountTimelinePage from 'crm/account/Timeline';
import ViewAccountPage from 'crm/account/Show/ViewAccountPage';

export const ACCOUNT_PATH = '/crm/accounts';

export const useAccountRoute = () => {
  return useRoute({ listRoute: ACCOUNT_PATH, timelinePostfix: 'activities' });
};

export const useAccountId = () => useParams<Dictionary<string>>().id;

const AccountRouter = () => {
  const { singleEntityRoute, createRoute, updateRoute, listRoute, timelineRoute } = useAccountRoute();

  return (
    <ProtectedRouter basePath={ACCOUNT_PATH}>
      <Switch>
        <Route path={listRoute} exact>
          <GlobalSecurityContext permissionToCheck={READ_ACCOUNTS_LIST}>
            <AccountsListPage />
          </GlobalSecurityContext>
        </Route>

        <Route path={createRoute} exact>
          <GlobalSecurityContext permissionToCheck={CREATE_ACCOUNT}>
            <CreateAccount />
          </GlobalSecurityContext>
        </Route>

        <Route path={updateRoute} exact>
          <AccountContext permissionToCheck={UPDATE_ACCOUNT}>
            <UpdateAccount />
          </AccountContext>
        </Route>

        <Route path={singleEntityRoute} exact>
          <AccountContext permissionToCheck={READ_ACCOUNT}>
            <ViewAccountPage />
          </AccountContext>
        </Route>

        <Route path={timelineRoute} exact>
          <AccountContext permissionToCheck={READ_TIMELINE}>
            <AccountTimelinePage />
          </AccountContext>
        </Route>

        <Route path={`${singleEntityRoute}/related-contacts`} exact>
          <AccountContext permissionToCheck={READ}>
            <AccountRelatedContacts />
          </AccountContext>
        </Route>

        <Route path={`${singleEntityRoute}/related-opportunities`} exact>
          <AccountContext permissionToCheck={READ}>
            <AccountRelatedOpportunities />
          </AccountContext>
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

AccountRouter.getRouterPath = () => ACCOUNT_PATH;

export default AccountRouter;
