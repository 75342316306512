import { useState } from 'react';
import { useKernelApi, useFinanceApi } from 'api';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import financeValidation from 'erp/employee/finance/createupdate/financeValidation';
import { useDateService } from 'shared/uibuilder/dateService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useFeatureToggle, { Features } from 'featuretoggle';

export const FINANCES_RESOURCE_URL = '/compensations';
export const FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE = 'FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE';
export const FINANCE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGE = 'FINANCE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGE';

export const FINANCE_TYPES_ALIASES = {
  PRIMARY: 'PRIMARY',
  ADDITIONAL: 'ADDITIONAL',
};

export const FINANCE_TYPES = {
  PRIMARY: 'Primary',
  ADDITIONAL: 'Additional',
};

export const BASE_AMOUNT_TYPES_ALIASES = {
  MONTHLY: 'MONTHLY',
  HOURLY: 'HOURLY',
  FIXED: 'FIXED',
  MONTHLY_NO_OVERRATES: 'MONTHLY_NO_OVERRATES',
};

export const BASE_AMOUNT_TYPES = {
  MONTHLY: 'Monthly',
  HOURLY: 'Hourly',
  FIXED: 'Fixed',
  MONTHLY_NO_OVERRATES: 'Monthly no overRates',
};

export const CURRENCY_TYPES = {
  USD: 'USD',
  EUR: 'EUR',
  BYN: 'BYN',
  PLN: 'PLN',
};

/**
 * Finance service.
 */

export const getProbationReduction = (probationAbsolute, baseAmount) => {
  const result = +((1 - probationAbsolute / baseAmount) * 100).toFixed(2);

  if (result < 0 || result > 100 || +probationAbsolute < 0 || +baseAmount < 0) {
    return '';
  }

  return result || result === 0 ? result : '';
};

const useFinanceService = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewPayrollEnabled = isFeatureEnabled(Features.PAYROLL);

  const [fullData, setFullData] = useState(null);
  const { sendGetRequest, sendPostRequest } = useKernelApi();
  const { sendGetRequest: sendFinanceGetRequest, sendPostRequest: sendFinancePostRequest } = useFinanceApi();
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_RESOURCE_URL}/${employeeId}`;
  const getValidationSchema = () => Promise.resolve(financeValidation);
  const { getToday } = useDateService();
  const { addMessage } = useMessageService();

  const { getById: initialGetById, ...baseCrudRequests } = useBaseCrudService({
    singleResourceUrl: `${FINANCES_RESOURCE_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${FINANCES_RESOURCE_URL}`,
    apiService: isNewPayrollEnabled ? useFinanceApi : useKernelApi,
  });

  const { getPermissions } = useBaseCrudService({
    singleResourceUrl: isNewPayrollEnabled
      ? `/employee/${employeeId}${FINANCES_RESOURCE_URL}`
      : `${FINANCES_RESOURCE_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${FINANCES_RESOURCE_URL}`,
    apiService: useKernelApi,
  });

  const getSearchParams = request => {
    const params = [];
    params.push(`pageNumber=${encodeURIComponent(request.pageNumber)}`);

    if (Object.keys(request.sort).length > 0) {
      const [sortKey, sortValue] = Object.entries(request.sort)[0];
      params.push(`${encodeURIComponent(`sort[${sortKey}]`)}=${encodeURIComponent(sortValue)}`);
    }

    return params.join('&');
  };

  const search = async request => {
    const url = `${parentResourceUrl}${FINANCES_RESOURCE_URL}?${getSearchParams(request)}`;
    const response = await (isNewPayrollEnabled ? sendFinanceGetRequest : sendGetRequest)(url);
    const result = await response.json();
    return result;
  };

  const getSensitiveDataByIds = async ids => {
    const url = `${parentResourceUrl}${FINANCES_RESOURCE_URL}/sensitive`;

    try {
      const response = await (isNewPayrollEnabled ? sendFinancePostRequest(url, { ids }) : sendPostRequest(url, ids));
      const result = await response.json();

      return result;
    } catch (err) {
      addMessage(new ErrorMessage("Can't get compensation data"));

      return null;
    }
  };

  const getById = async id => {
    if (fullData) {
      return fullData;
    }

    const data = await initialGetById(id);
    const sensitiveDataId = isNewPayrollEnabled ? data.compensationPackageDataId : data.sensitiveDataId;
    const sensitiveData = (await getSensitiveDataByIds([sensitiveDataId])) || {};

    if (isNewPayrollEnabled) {
      delete sensitiveData[sensitiveDataId].id;
    }

    const combinedData = {
      ...data,
      ...sensitiveData[sensitiveDataId],
    };

    setFullData(combinedData);

    return combinedData;
  };

  const getIsCurrentPackage = ({ effectiveDateFrom, effectiveDateTo }) => {
    return effectiveDateTo
      ? getToday().isBetween(effectiveDateFrom, effectiveDateTo, 'day', '[]')
      : getToday().isSameOrAfter(effectiveDateFrom, 'day');
  };

  return {
    ...baseCrudRequests,
    getValidationSchema,
    getSensitiveDataByIds,
    getIsCurrentPackage,
    search,
    getById,
    getPermissions,
  };
};

export default useFinanceService;
