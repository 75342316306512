/* istanbul ignore file */
import React from 'react';
import { CREATE_JOURNAL_ENTRY } from 'financialAnalytic/journalEntry/useJournalEntryService';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkButton from 'uibuilder/button/LinkButton';

const CopyJournalEntryButton = (props: { source?: string }) => {
  const { getCopyEntityUrl } = useJournalEntryUrl();
  const { getValue: getId } = useFieldHelper(props);
  const id = getId();
  const { isGranted } = useAuthorization();

  return isGranted(CREATE_JOURNAL_ENTRY) ? (
    <LinkButton outline {...props} url={getCopyEntityUrl(id)}>
      Copy
    </LinkButton>
  ) : null;
};

export default CopyJournalEntryButton;
