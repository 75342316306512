import { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import useCandidateService, { CREATE_CANDIDATE, CandidateDuplicate } from 'erp/candidate/shared/candidateService';
import useAuthorization from 'shared/authorization/authorizationService';
import { parseJson } from 'shared/uibuilder/list/builder/useListParams';
import removeEmptyValuesFromObject from 'shared/objectHelper';
import { replaceUrlWithLink } from 'shared/uibuilder/ReplaceUrlWithLink/autoLinkerHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { useExtensionUrl } from './ExtensionRouter';
import useCacheService from 'shared/cache/cacheService';
import useSingletonPromise from 'shared/useSingletonPromise';
import useMessageService, { WarningMessage } from 'shared/message/messageService';

export type CandidateData = {
  name?: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
  };
  dateOfBirth?: string;
  personalPhoneNumber?: string;
  otherPhoneNumber?: string;
  personalEmail?: string;
  additionalContacts?: Dictionary<string>[];
  resume?: {
    resumeArtifactIds?: string[];
    description?: string;
  };
  photosIds?: string[];
};

export const getCandidateFormData = (initialData: string | object, defaultData?: CandidateData) => {
  const data = (typeof initialData === 'string' ? parseJson(initialData) : initialData) || {};
  const defaultFirstName = get(defaultData, 'name.firstName');
  const defaultLastName = get(defaultData, 'name.lastName');
  const defaultMiddleName = get(defaultData, 'name.middleName');
  const defaultResumeArtifactIds = get(defaultData, 'resume.resumeArtifactIds');
  const defaultResumeDescription = get(defaultData, 'resume.description');

  const formData = {
    name: {
      firstName: get(data, 'name.firstName', defaultFirstName),
      lastName: get(data, 'name.lastName', defaultLastName),
      middleName: get(data, 'name.middleName', defaultMiddleName),
    },
    dateOfBirth: data.dateOfBirth || defaultData?.dateOfBirth,
    personalPhoneNumber: data.personalPhoneNumber || defaultData?.personalPhoneNumber,
    otherPhoneNumber: data.otherPhoneNumber || defaultData?.otherPhoneNumber,
    personalEmail: data.personalEmail || defaultData?.personalEmail,
    additionalContacts: data.additionalContacts || defaultData?.additionalContacts,
    resume: {
      resumeArtifactIds: get(data, 'resume.resumeArtifactIds', defaultResumeArtifactIds),
      description: get(data, 'resume.description', defaultResumeDescription),
    },
    photosIds: data.photosIds || defaultData?.photosIds,
  };

  return removeEmptyValuesFromObject(formData);
};

export interface ExtensionData {
  source: Nullable<Window>;
  origin: string;
}

export const CANDIDATE_KEY_CACHE = 'candidateData';

const useCandidateFromExtensionService = () => {
  const { create, getValidationSchema, getDuplicates: baseGetDuplicates } = useCandidateService();
  const [parsedProfileData, setParsedProfileData] = useState({});
  const [duplicates, setDuplicates] = useState<CandidateDuplicate[]>([]);
  const [isParsingFinished, setIsParsingFinished] = useState(false);
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted(CREATE_CANDIDATE);
  const [extensionData, setExtensionData] = useState<Nullable<ExtensionData>>(null);
  const { getSingleEntityUrl } = useExtensionUrl();
  const { addMessage } = useMessageService();

  const { addToCache, getValue } = useCacheService(CANDIDATE_KEY_CACHE);

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent, false);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessageEvent = async ({ data, origin, source }: MessageEvent) => {
    if (origin.startsWith('chrome-extension://')) {
      const extensionEventData = { origin, source } as ExtensionData;

      if (hasPermissions) {
        try {
          const formInitialData = getCandidateFormData(JSON.parse(data));
          const candidateDuplicates = await getDuplicates(formInitialData);

          addToCache(CANDIDATE_KEY_CACHE, {
            formInitialData,
            duplicates: candidateDuplicates,
            extensionData: extensionEventData,
          });
          setExtensionData(extensionEventData);
          setParsedProfileData(formInitialData);
          setDuplicates(candidateDuplicates);
          setIsParsingFinished(true);
          sendMessageToExtension({ hasPermissions, hasDuplicates: !!candidateDuplicates?.length }, extensionEventData);
          checkResumeLoading(formInitialData.resume as Dictionary<string | string[]>);
        } catch (e) {
          sendMessageToExtension(
            { hasPermissions, error: "Oops! We can't check duplicates. Please, try again." },
            extensionEventData,
          );
        }
      } else {
        sendMessageToExtension({ hasPermissions }, extensionEventData);
      }
    }
  };

  const sendMessageToExtension = useCallback(
    (message: object, messageEvent?: ExtensionData) => {
      const { source, origin } = extensionData || messageEvent || {};

      if (source && origin) {
        source?.postMessage(message, origin);
      }

      if (!extensionData && messageEvent) {
        setExtensionData(messageEvent);
      }
    },
    [extensionData],
  );

  const createCandidate = (data: FormFieldsData) => {
    return create({
      ...data,
      isCreatedFromExtension: true,
      resume: {
        resumeArtifactIds: data.resume?.resumeArtifactIds || [],
        description: replaceUrlWithLink(data.resume?.description || ''),
      },
    });
  };

  const getDuplicates = useSingletonPromise('getDuplicates', async (data: FormFieldsData) => {
    const candidateDuplicates = (await baseGetDuplicates(data)) as CandidateDuplicate[];

    return candidateDuplicates.map(duplicate => ({ ...duplicate, url: getSingleEntityUrl(duplicate.candidate.id) }));
  });

  const checkResumeLoading = (resume: Dictionary<string | string[]>) => {
    if (!resume.resumeArtifactIds || !resume.resumeArtifactIds.length) {
      addMessage(
        new WarningMessage("Oops! We can't get a resume file for this candidate. You can upload it in the form field."),
      );
    }
  };

  useEffect(() => {
    const candidateData = getValue(CANDIDATE_KEY_CACHE);

    if (candidateData) {
      setParsedProfileData(candidateData.formInitialData || {});
      setIsParsingFinished(true);
      setDuplicates(candidateData.duplicates || []);
      setExtensionData(candidateData.extensionData);
      checkResumeLoading(candidateData.formInitialData?.resume || {});
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hasPermissions,
    isParsingFinished,
    parsedProfileData,
    sendMessageToExtension,
    getValidationSchema,
    createCandidate,
    duplicates,
  };
};

export default useCandidateFromExtensionService;
