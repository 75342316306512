/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import EditEmployeeDomainModalForm from 'erp/employee/domains/shared/EditEmployeeDomainModalForm';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { UPDATE_EMPLOYEE_DOMAIN } from 'erp/employee/domains/shared/employeeDomainsService';

const EditEmployeeDomainButton = ({ source, ...props }: any) => {
  const { isGranted } = useAuthorization();
  const { getValue } = useFieldHelper({ source });
  const id = getValue();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  if (!isGranted(UPDATE_EMPLOYEE_DOMAIN)) {
    return null;
  }

  return (
    <>
      <Button onClick={openCloseIssueForm}>Edit</Button>
      {isFormOpen && <EditEmployeeDomainModalForm id={id} closeModal={closeModal} />}
    </>
  );
};

export default EditEmployeeDomainButton;
