/* istanbul ignore file */
import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import { useDomainsService } from 'erp/employee/domains/domain/shared/domainService';

export interface DomainInputProps {
  source: string;
  errorSource?: string;
  label?: string;
  isRequired?: boolean;
  showLoader?: boolean;
  placeholder?: string;
}

const DomainInput = ({ source, ...props }: DomainInputProps) => {
  const { findAllDomains } = useDomainsService();

  const mapResults = (domain: any) => ({
    id: domain.id.toString(),
    text: domain.name,
    domainId: domain.id,
  });

  return (
    <SearchInput
      searchString="name"
      nameSource="domain.name"
      searchRequest={findAllDomains}
      noResultMessage="No Domains are found."
      source={source}
      mapResults={mapResults}
      {...props}
    />
  );
};

export default DomainInput;
