import { useKernelApi } from 'api/index';
import { useProjectId } from 'erp/project/ProjectRouter';
import projectDemoStatusValidation from './demo/form/projectDemoStatusValidation';

export const READ_PROJECT_STATUS_LIST = 'PROJECT_STATUS_READ_LIST';
export const UPDATE_PROJECT_STATUS = 'PROJECT_STATUS_UPDATE';

export enum StatusTypes {
  DEMO = 'DEMO',
  PLANNING = 'PLANNING',
  SKIPPED = 'SKIPPED',
}

export const StatusTypesLabels = {
  [StatusTypes.DEMO]: 'Demo',
  [StatusTypes.PLANNING]: 'Planning',
  [StatusTypes.SKIPPED]: 'Skipped',
};

export enum ProjectStatusWorkItemType {
  PLANNED = 'PLANNED',
  DELIVERED = 'DELIVERED',
}

export enum WasDemoSkipped {
  YES = 'YES',
  NO = 'NO',
}

export const WasDemoSkippedLabels = {
  [WasDemoSkipped.YES]: 'Yes',
  [WasDemoSkipped.NO]: 'No',
};

export enum WasDemoed {
  YES = 'YES',
  NO = 'NO',
  NOT_NEEDED = 'NOT_NEEDED',
}

export const WasDemoedLabels = {
  [WasDemoed.YES]: 'Yes',
  [WasDemoed.NO]: 'No',
  [WasDemoed.NOT_NEEDED]: 'Not needed',
};

export enum AvailableOnProd {
  YES = 'YES',
  NO = 'NO',
  NOT_NEEDED = 'NOT_NEEDED',
}

export const AvailableOnProdLabels = {
  [AvailableOnProd.YES]: 'Yes',
  [AvailableOnProd.NO]: 'No',
  [AvailableOnProd.NOT_NEEDED]: 'Not needed',
};

export enum WorkItemStatus {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export const WorkItemStatusLabels = {
  [WorkItemStatus.COMPLETED]: 'Completed',
  [WorkItemStatus.NOT_COMPLETED]: 'Not completed',
};

const useProjectStatusesService = () => {
  const { sendPostRequest, sendPutRequest } = useKernelApi();
  const projectId = useProjectId();

  const search = async (request: any) => {
    const response = await sendPostRequest(`/project/statuses/${projectId}`, request);
    const result = await response.json();

    return mapStatusList(result);
  };

  const mapStatusList = (listResponse: any) => {
    return {
      ...listResponse,
      result: listResponse.result.map((item: any) => {
        return {
          ...item,
          wasDemoSkipped: !item.skipReason ? WasDemoSkipped.NO : WasDemoSkipped.YES,
        };
      }),
    };
  };

  const getDemoValidationSchema = () => Promise.resolve(projectDemoStatusValidation);

  const update = async (timelineId: number, request: any) => {
    const response = await sendPutRequest(`/project/status/${timelineId}`, request);
    const result = await response.json();

    return {
      ...result,
      wasDemoSkipped: !result.skipReason ? WasDemoSkipped.NO : WasDemoSkipped.YES,
    };
  };

  const checkIsEditable = (date: Date, addDays: number) => {
    date.setDate(date.getDate() + addDays);
    return date > new Date();
  };

  return {
    search,
    getDemoValidationSchema,
    update,
    checkIsEditable,
  };
};

export default useProjectStatusesService;
