/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import BudgetForm from 'erp/budget/createupdate/BudgetForm';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import BudgetEntityHeader from 'erp/budget/shared/BudgetEntityHeader';
import UpdateBudgetBreadcrumbs from 'erp/budget/createupdate/Update/Breadcrumbs';
import useBudgetService from 'erp/budget/shared/budgetService';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';

const UpdateBudget = () => {
  const { update, getById, getValidationSchema } = useBudgetService();
  const { getSingleEntityUrl } = useBudgetUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Budget has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Budget"
        breadcrumbs={<UpdateBudgetBreadcrumbs />}
        entityHeader={<BudgetEntityHeader />}
      >
        <BudgetForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateBudget;
