/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';

interface PositionDropdownProps extends Omit<DefaultDropdownProps, 'options'> {}

const PositionDropdown = ({ ...props }: PositionDropdownProps) => {
  const [options, setOptions] = useState<Dictionary<string>>({});
  const { getPositionOptions } = usePresaleOutputService();

  useEffect(() => {
    (async () => {
      const positions = await getPositionOptions();

      setOptions(positions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EnumDropdown {...props} options={options} />;
};

export default PositionDropdown;
