/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { AssignmentType } from '../assignmentService';
import EmployeeLinkField from '../../../employee/shared/field/EmployeeLinkField';
import RbsNodeLinkField from '../../../rbs/shared/field/RbsNodeLinkField';
import { CREATE_RBS_TREE_NODE } from '../../../rbs/shared/rbsService';
import { FieldProps } from '../../../../shared/uibuilder/field';

const AssigneeField = ({ source, ...props }: FieldProps) => {
  const { getValue: getAssignmentType } = useFieldHelper({ source });

  return getAssignmentType() === AssignmentType.INDIVIDUAL_ASSIGNMENT ? (
    <EmployeeLinkField source="employeeId" nameSource="employeeName" {...props} />
  ) : (
    <RbsNodeLinkField
      source="employeeGroup.rbsTreeNodeId"
      nameSource="employeeGroup.name"
      permissionToCheck={CREATE_RBS_TREE_NODE}
      {...props}
    />
  );
};

export default AssigneeField;
