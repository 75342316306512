import Filters from 'shared/uibuilder/list/filter/Filters';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import React from 'react';
import FileList from 'shared/uibuilder/list/FileList';
import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import LoadingListLayout from 'shared/uibuilder/list/layout/LoadingListLayout';
import StaticFiltersLayout from 'shared/uibuilder/list/filter/layout/StaticFiltersLayout';
import useCamundaMetricsService from 'camunda/metrics/shared/camundaMetricsService';

const CamundaMetricsList = () => {
  const { getReport } = useCamundaMetricsService();

  const filters = (
    <Filters>
      <StaticFiltersLayout actionButtonName="Generate">
        <div className="process-start-date__wrapper">
          <DateTimeRangeFilter label="Process Start Date" source="processStartDate" />
        </div>
      </StaticFiltersLayout>
    </Filters>
  );

  return (
    <FileList getDataMethod={getReport}>
      <ListPageLayout
        showTotal={false}
        header={<ListHeader label="Processes Metrics Report" icon="fa-table" />}
        filter={filters}
      />
      <LoadingListLayout />
    </FileList>
  );
};

export default CamundaMetricsList;
