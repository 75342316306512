import { useState } from 'react';
import { parseJson } from 'shared/uibuilder/list/builder/useListParams';
import { useLocation } from 'react-router-dom';
import { SearchParams, SortOrder, SortParam } from '../ListContext';
import { Filter } from '../filter/FilterContext';

const getLocalStorageValues = (localStorageKey: string) => {
  const localStorageValues = parseJson(localStorage.getItem(localStorageKey) || '');
  localStorageValues.sort = localStorageValues.sort || {};
  localStorageValues.filter = Object.keys(localStorageValues.filter || {}).length ? localStorageValues.filter : null;

  return localStorageValues;
};

type LocalStorageParams = {
  defaultSortField?: string;
  defaultSortOrder?: SortOrder;
  defaultFilter?: Filter;
  page?: number;
  localStorageKey?: Nullable<string>;
  uncontrolledFilters?: string[];
  shouldSaveUncontrolledFilters?: boolean;
};

type GetInitialParams = (props: LocalStorageParams) => SearchParams;

const getInitialParams: GetInitialParams = ({
  localStorageKey,
  defaultFilter,
  defaultSortField,
  defaultSortOrder,
  page,
}) => {
  const localStorageValues = getLocalStorageValues(localStorageKey || '');

  return {
    filter: localStorageValues.filter || defaultFilter,

    sort: {
      field: localStorageValues.sort.field || defaultSortField,
      order: localStorageValues.sort.order || defaultSortOrder,
    },
    page: localStorageValues.page || page,
  };
};

type ListLocalStorageStoringHelper = (
  params?: LocalStorageParams,
) => {
  params: SearchParams;
  updateParamsInLocalStorage: (props: { filter?: Filter; sort?: SortParam }) => void;
};

const useListLocalStorageStoring: ListLocalStorageStoringHelper = ({
  defaultSortField = '',
  defaultSortOrder = '',
  defaultFilter = {},
  page = 0,
  localStorageKey: localStorageKeyProp = null,
  uncontrolledFilters,
  shouldSaveUncontrolledFilters,
} = {}) => {
  const location = useLocation();
  const localStorageKey = localStorageKeyProp || location.pathname;

  const [params] = useState(
    getInitialParams({
      defaultSortField,
      defaultSortOrder,
      defaultFilter,
      page,
      localStorageKey,
    }),
  );

  const updateParamsInLocalStorage = ({ filter = {}, sort = {} }: { filter?: Filter; sort?: SortParam }) => {
    const filtersForSaving = { ...filter };

    if (!shouldSaveUncontrolledFilters) {
      uncontrolledFilters?.forEach(source => {
        if (defaultFilter[source]) {
          filtersForSaving[source] = defaultFilter[source];
        } else {
          delete filtersForSaving[source];
        }
      });
    }

    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        filter: filtersForSaving,
        sort: sort.field && sort.order ? sort : {},
      }),
    );
  };

  return {
    params,
    updateParamsInLocalStorage,
  };
};

export default useListLocalStorageStoring;
