import React, { useCallback, useMemo } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import useWasteService, { WASTE_REPORTING_METHOD } from '../../../useWasteService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import RedmineTicketsMultiSelect from 'erp/costaccounting/waste/shared/inputs/RedmineTicketsMultiSelect';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import TicketIdsInput from 'erp/costaccounting/waste/shared/inputs/TicketIdsInput';

type Period = {
  startDate?: string;
  endDate?: string;
};

export type RedmineTicket = {
  id: string;
  subject: string;
  violatesBusinessRules: boolean;
};

type Project = {
  id: string;
  name: string;
};

const LaborCostWasteRow: React.FC = () => {
  const { data: formData = {} } = useFormContext();
  const { getRedmineTickets, getProjects } = useWasteService();
  const { getToday } = useDateService();

  const employeeAlias: string = useMemo(() => formData?.laborWasteDetails?.employeeAlias, [formData]);
  const period: Period = useMemo(() => formData?.laborWasteDetails?.period, [formData]);

  const getRedmineTicketsCallback = useCallback(async (): Promise<{ result: RedmineTicket[] }> => {
    return employeeAlias ? getRedmineTickets({ id: employeeAlias }) : { result: [] };
  }, [employeeAlias, getRedmineTickets]);

  const getProjectCallback = useCallback(async (): Promise<{ result: Project[] }> => {
    return employeeAlias && period?.startDate && period?.endDate
      ? getProjects({ id: employeeAlias, period })
      : { result: [] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeAlias, getProjects, period?.startDate, period?.endDate]);

  return (
    <>
      <FormRow>
        <ClearAfterUnmountWrapper
          key="ticketsIds"
          isVisible={data => data?.laborWasteDetails?.reportingMethod === WASTE_REPORTING_METHOD.BY_TICKET_IDS}
        >
          <TicketIdsInput
            tooltip="Input ticket ID(s) separated by comma."
            label="Redmine ticket ID (s)"
            source="laborWasteDetails.ticketsIds"
          />
        </ClearAfterUnmountWrapper>

        <ClearAfterUnmountWrapper key="employee">
          <NotDismissedEmployeeInput
            isVisible={data =>
              data?.laborWasteDetails?.reportingMethod &&
              data?.laborWasteDetails?.reportingMethod !== WASTE_REPORTING_METHOD.BY_TICKET_IDS
            }
            includeContractors
            source="laborWasteDetails.employeeAlias"
            label="Employee"
          />
        </ClearAfterUnmountWrapper>

        <ClearAfterUnmountWrapper
          key="tickets"
          isVisible={data =>
            data?.laborWasteDetails?.reportingMethod === WASTE_REPORTING_METHOD.BY_EMPLOYEE_AND_TICKET_IDS
          }
        >
          <RedmineTicketsMultiSelect
            source="laborWasteDetails.ticketsIds"
            label="Redmine ticket ID (s)"
            loadMethod={getRedmineTicketsCallback}
            deps={[employeeAlias]}
          />
        </ClearAfterUnmountWrapper>

        <ClearAfterUnmountWrapper
          key="fromDate"
          isVisible={data => data?.laborWasteDetails?.reportingMethod === WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD}
        >
          <DateInput maxDate={getToday()} isRequired label="Period from" source="laborWasteDetails.period.startDate" />
        </ClearAfterUnmountWrapper>

        <ClearAfterUnmountWrapper
          key="toDate"
          isVisible={data => data?.laborWasteDetails?.reportingMethod === WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD}
        >
          <DateInput maxDate={getToday()} isRequired label="Period to" source="laborWasteDetails.period.endDate" />
        </ClearAfterUnmountWrapper>
      </FormRow>

      <FormRow>
        <ClearAfterUnmountWrapper
          key="projects"
          isVisible={data => data?.laborWasteDetails?.reportingMethod === WASTE_REPORTING_METHOD.BY_PROJECT_PERIOD}
        >
          <LoadDropdown
            placeholder="Choose project"
            loadDataMethod={getProjectCallback}
            mapResults={(data: Project): Option => ({ value: data.id, label: data.name })}
            deps={[employeeAlias, period?.startDate, period?.endDate]}
            source="laborWasteDetails.projectId"
            isClearValueByUpdateDeps
          />
        </ClearAfterUnmountWrapper>
      </FormRow>
    </>
  );
};

export default LaborCostWasteRow;
