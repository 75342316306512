import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import RejectExpenseButton, { RejectExpenseButtonProps } from './RejectExpenseButton';

const RejectListExpenseButton = (props: RejectExpenseButtonProps) => {
  const { reloadData } = useListContext();

  return <RejectExpenseButton {...props} onSubmit={reloadData} />;
};

export default RejectListExpenseButton;
