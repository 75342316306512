/* istanbul ignore file */
import React, { useState, useMemo } from 'react';
import List from 'shared/uibuilder/list/List';
import useExpenseService, {
  CREATE_EXPENSE,
  EXPENSE_STATUSES,
  EXPENSE_TYPES,
  READ_EXPENSE,
} from 'financialAnalytic/expenses/useExpenseService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateExpenseButton from 'financialAnalytic/expenses/shared/button/CreateExpenseButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ExpenseLink from 'financialAnalytic/expenses/shared/field/ExpenseLink';
import ViewExpenseButton from 'financialAnalytic/expenses/shared/button/ViewExpenseButton';
import { SearchResultField } from 'shared/uibuilder/field';
import ExpenseStatusField from 'financialAnalytic/expenses/shared/field/ExpenseStatusField';
import ExpenseStatusFilter from 'financialAnalytic/expenses/shared/filter/ExpenseStatusFilter';
import OfficeFilter from 'financialAnalytic/shared/filter/OfficeFilter';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import { EmployeeFilter, EmployeeLinkFieldWithNameLoading } from 'erp/employee';
import ExpenseTypeField from 'financialAnalytic/expenses/shared/field/ExpenseTypeField';
import ExpenseTypeFilter from 'financialAnalytic/expenses/shared/filter/ExpenseTypeFilter';
import ExpensesSubTypeDropdownFilter from 'financialAnalytic/expenses/shared/filter/ExpenseSubTypeDropdownFilter';
import ListRowSelectCheckbox from 'shared/uibuilder/form/input/ListRowSelectCheckbox';
import MarkAsPaidExpenseListButton from 'financialAnalytic/expenses/shared/button/MarkAsPaidExpenseListButton';
import ComputeBonusesButton from 'financialAnalytic/expenses/shared/button/ComputeBonusesButton';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useAuthorization from 'shared/authorization/authorizationService';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import UploadEmployeeExpensesButton from '../shared/button/UploadEmployeeExpensesButton';
import { useExpenseRoute } from '../ExpenseRouter';
import ListPageHeaderWrapper from '../../shared/ListPageHeaderWrapper';
import MarkPayrollExpensesPaidButton from '../shared/button/MarkPayrollExpensesPaidButton';
import RejectListExpenseButton from '../shared/button/RejectListExpenseButton';

const ExpenseListPage = () => {
  const [checkedRow, setCheckedRow] = useState<FormFieldsData[]>([]);
  const { search } = useExpenseService();
  const { isGranted } = useAuthorization();
  const { uploadExpensesRoute } = useExpenseRoute();

  const checkedRowIds = useMemo(() => {
    return checkedRow.map(item => item.id);
  }, [checkedRow]);

  const isShowMarkAsPaid = useMemo(() => {
    return !checkedRow.find(item => item.status === EXPENSE_STATUSES.REGISTERED);
  }, [checkedRow]);

  const filters = (
    <Filters>
      <FiltersLayout
        quickFilters={[
          <QuickFilter label="Not Paid Expenses" state={{ status: { in: [EXPENSE_STATUSES.PENDING] } }} />,
        ]}
      >
        <OfficeFilter label="Office" source="office" />
        <EmployeeFilter label="Employee" source="employeeAlias" />
        <ExpenseStatusFilter label="Status" source="status" />
        <ExpenseTypeFilter label="Type" source="type" />
        <ExpensesSubTypeDropdownFilter label="Subtypes" source="subtype" />
        <DateRangeFilter label="Registration Date" source="registrationDate" />
      </FiltersLayout>
    </Filters>
  );

  const handleRowSelection = ({ rowData, checked }: FormFieldsData) => {
    if (checked) {
      setCheckedRow(prev => [...prev, rowData]);
    } else {
      setCheckedRow(prev => prev.filter(item => item.id !== rowData.id));
    }
  };

  return (
    <List getDataMethod={search}>
      <ListPageLayout
        header={
          <ListPageHeaderWrapper
            header={<ListHeader label="Employee Expenses" />}
            actions={[
              <UploadEmployeeExpensesButton url={uploadExpensesRoute} permissionToCheck={CREATE_EXPENSE} />,
              <MarkPayrollExpensesPaidButton />,
              <MarkAsPaidExpenseListButton
                ids={checkedRowIds}
                isVisible={!!checkedRowIds.length && isShowMarkAsPaid}
              />,
              <ComputeBonusesButton />,
              <RejectListExpenseButton ids={checkedRowIds} isVisible={!!checkedRowIds.length} />,
            ]}
          />
        }
        actions={[<CreateExpenseButton />]}
        filter={filters}
        search={<SearchInput label="Title" />}
      >
        <DataGridLayout buttons={[<ViewExpenseButton source="id" isVisible={isGranted(READ_EXPENSE)} />]}>
          <ListRowSelectCheckbox
            source="id"
            label=""
            onChangeCallback={handleRowSelection}
            isVisible={(data: any) =>
              (data.getValueBySource('status') === EXPENSE_STATUSES.PENDING ||
                data.getValueBySource('status') === EXPENSE_STATUSES.REGISTERED) &&
              data.getValueBySource('type') !== EXPENSE_TYPES.PERFORMANCE_BASED_BONUS
            }
            width="62px"
          />
          <ExpenseLink source="id" nameSource="id" label="ID" width="150px" isClickable={isGranted(READ_EXPENSE)} />
          <ExpenseTypeField source="type" label="Type" />
          <SearchResultField source="registrationInfo.title" label="Title" />
          <EmployeeLinkFieldWithNameLoading source="employee.alias" label="Employee" />
          <ExpenseStatusField source="status" label="Status" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ExpenseListPage;
