/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import FinanceForm from 'erp/employee/finance/createupdate/FinanceForm';
import useFinanceService, {
  CURRENCY_TYPES,
  FINANCE_TYPES_ALIASES,
  BASE_AMOUNT_TYPES_ALIASES,
} from 'erp/employee/finance/shared/financeService';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import CreateFinanceBreadcrumbs from 'erp/employee/finance/createupdate/Create/Breadcrumbs';
import { useFinanceUrl } from 'erp/employee/finance/FinanceRouter';

const CreateFinancePage = () => {
  const { create, getValidationSchema } = useFinanceService();
  const { getListUrl } = useFinanceUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        type: FINANCE_TYPES_ALIASES.PRIMARY,
        baseAmountType: BASE_AMOUNT_TYPES_ALIASES.MONTHLY,
        currency: CURRENCY_TYPES.USD,
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Compensation package has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        title="Create: Compensation package"
        breadcrumbs={<CreateFinanceBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
      >
        <FinanceForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateFinancePage;
