/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayoutWithoutMenu from 'shared/layout/ShowPageLayoutWithoutMenu';
import useQualificationModelService from '../qualificationModelService';
import QualificationModel from './QualificationModel';
import { useQualificationModelVersion } from '../QualificationModelRouter';
import { LinkField } from 'shared/uibuilder/field';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import LinkIcon from '@mui/icons-material/LaunchOutlined';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';

const QualificationModelViewPage = () => {
  const { getByVersion } = useQualificationModelService();
  const version = useQualificationModelVersion();

  return (
    <Show getDataMethod={() => getByVersion(version)}>
      <ShowPageLayoutWithoutMenu>
        <Typography variant="h1" sx={{ marginBottom: '42px' }} className="c-title">
          Syberry Qualification Model
        </Typography>
        <Box sx={{ marginBottom: '42px', a: { display: 'flex', alignItems: 'center' } }}>
          <Typography variant="h2" className="c-title" sx={{ marginBottom: '18px' }}>
            Policy
          </Typography>
          <LinkField
            linkText="Syberry Qualification Policy"
            isInternal={false}
            icon={LinkIcon}
            value="https://git.syberry.com/syberry-policies/production/-/blob/master/qualification-model.md"
          />
        </Box>
        <ShowSection title="Qualification requirements" titleVariant="h2">
          <QualificationModel source="specializations" />
        </ShowSection>
      </ShowPageLayoutWithoutMenu>
    </Show>
  );
};

export default QualificationModelViewPage;
