/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useHiringUrl, useHiringId } from '../HiringRouter';
import HiringBreadcrumbs from './HiringBreadcrumbs';

const SingleHiringRequestBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useHiringUrl();
  const id = useHiringId();

  return (
    <HiringBreadcrumbs>
      <SingleBreadcrumbItem entity="Hiring request" link={getSingleEntityUrl(id)} />
      {children}
    </HiringBreadcrumbs>
  );
};

export default SingleHiringRequestBreadcrumbs;
