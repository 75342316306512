import { useKernelApi } from 'api/index';
import useCacheService from 'shared/cache/cacheService';
import { ResourceData } from 'shared/crud/baseCrudService';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import useQualificationModelService, { Specialization } from 'erp/qualification/model/qualificationModelService';
import { useCallback } from 'react';
import { EmployeeQualification } from 'erp/employee/qualification/shared/employeeQualificationService';
import useSingletonPromise from 'shared/useSingletonPromise';
import useEmployeeSpecializationMerger from 'erp/employee/qualification/shared/employeeSpecializationMerger';
import createQualificationValidation from 'erp/employee/qualification/createupdate/createQualificationValidation';

export interface EmployeeSpecialization {
  id: number;
  title: string;
  primary: boolean;
  completeness: number;
  specializationBranchId: number;
  qualifications: EmployeeQualification[];
}

const CACHE_NAME = 'employee-specializations';

export const RESOURCE_URL = '/specializations';

const useEmployeeSpecializationService = () => {
  const { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } = useKernelApi();
  const { getByVersion: getEmployeeQualificationModel } = useQualificationModelService();

  const getCacheId = (employeeId: StringOrNumber) => `employee-${employeeId}`;

  const getResourceUrl = (employeeId: StringOrNumber) => `${EMPLOYEES_RESOURCE_URL}/${employeeId}${RESOURCE_URL}`;

  const { addToCache, getValue, cache } = useCacheService(CACHE_NAME);

  const loadSpecializationsData = useSingletonPromise('loadSpecializationsData', async (employeeId: StringOrNumber) => {
    const url = getResourceUrl(employeeId);
    const response = await sendGetRequest(url);
    const result = await response.json();

    return result;
  });

  /**
   * Checks data in cache.
   * If they exist return them or load new data from BE
   * @return Promise<string | ResourceData | {totalElements, totalPages, pageNumber, result}>}
   */
  const getSpecializations = useCallback(
    async (employeeId: StringOrNumber) => {
      const cacheId = getCacheId(employeeId);
      const cachedValue = getValue(cacheId);

      if (cachedValue) {
        return cachedValue;
      } else {
        const employeeSpecializations = await loadSpecializationsData(employeeId);

        addToCache(cacheId, employeeSpecializations);

        return employeeSpecializations;
      }
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  /**
   * Returns data for one specialization by id
   * @param {StringOrNumber} employeeId
   * @param {StringOrNumber} specializationId
   * @return {Promise<any>}
   */
  const getSingleSpecialization = useCallback(
    async (employeeId: StringOrNumber, specializationId: StringOrNumber) => {
      const { result: specializations = [] } = await getSpecializations(employeeId);

      return specializations.find(({ id }: EmployeeSpecialization) => String(id) === String(specializationId));
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  /**
   * Returns data for primary specialization
   * @param {StringOrNumber} employeeId
   * @param {StringOrNumber} specializationId
   * @return {Promise<any>}
   */
  const getPrimarySpecialization = useCallback(
    async (employeeId: StringOrNumber) => {
      const { result: specializations = [] } = await getSpecializations(employeeId);

      return specializations.find(({ primary }: EmployeeSpecialization) => primary);
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  /**
   * Returns specializations that exist in the qualification model, but the user does not have
   * @return {Promise<any>}
   */
  const getNotAppliedSpecializations = useCallback(
    async (employeeId: StringOrNumber) => {
      const { specializations: specializationsFromModel } = await getEmployeeQualificationModel('latest');
      const { result: specializations } = (await getSpecializations(employeeId)) || {};

      return specializationsFromModel.filter(
        ({ id }: Specialization) =>
          !specializations.find(
            ({ specializationBranchId: employeeSpecializationId }: EmployeeSpecialization) =>
              employeeSpecializationId === id,
          ),
      );
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  /**
   * Creates new specialization and updates data in cache
   */
  const create = async (employeeId: StringOrNumber, data: ResourceData) => {
    const url = getResourceUrl(employeeId);
    const response = await sendPostRequest(url, data);
    const result = await response.json();

    const cacheId = getCacheId(employeeId);
    const cachedValue = getValue(cacheId) || { result: [] };

    addToCache(cacheId, { ...cachedValue, result: [...cachedValue?.result, result] });

    return result;
  };

  /**
   * Deletes specialization and updates data in cache
   */
  const deleteById = async (employeeId: StringOrNumber, specializationId: StringOrNumber) => {
    const url = `${RESOURCE_URL}/${specializationId}`;
    await sendDeleteRequest(url);

    const cacheId = getCacheId(employeeId);
    const cachedValue = getValue(cacheId) || { result: [] };

    addToCache(cacheId, {
      ...cachedValue,
      result: cachedValue?.result.filter(({ id }: Specialization) => id !== specializationId),
    });
  };

  const getCreateQualificationValidationSchema = () => Promise.resolve(createQualificationValidation);

  /**
   * Set qualification as primary
   */
  const updatePrimaryQualification = async (employeeId: StringOrNumber, primarySpecializationId: StringOrNumber) => {
    const url = getResourceUrl(employeeId);
    await sendPutRequest(url, { primarySpecializationId });

    const cacheId = getCacheId(employeeId);
    const cachedValue = getValue(cacheId) || { result: [] };

    addToCache(cacheId, {
      ...cachedValue,
      result: cachedValue?.result.map((specialization: Specialization) => ({
        ...specialization,
        primary: String(specialization.id) === String(primarySpecializationId),
      })),
    });
  };

  const { mergeSpecializationData } = useEmployeeSpecializationMerger();

  const updateSpecializationCompleteness = ({
    employeeId,
    completeness,
    ...mergeProps
  }: {
    employeeId: StringOrNumber;
    specializationId: StringOrNumber;
    qualificationId: StringOrNumber;
    completeness: number;
  }) => {
    const CACHE_ID = getCacheId(employeeId);
    const cachedValue = getValue(CACHE_ID) || {};

    const newCachedData = {
      ...cachedValue,
      result: mergeSpecializationData({
        ...mergeProps,
        employeeSpecializations: cachedValue?.result,
        specializationData: { completeness },
      }),
    };

    addToCache(CACHE_ID, newCachedData);
  };

  return {
    deleteById,
    updatePrimaryQualification,
    create,
    getSpecializations,
    getSingleSpecialization,
    getNotAppliedSpecializations,
    getCreateQualificationValidationSchema,
    updateSpecializationCompleteness,
    getPrimarySpecialization,
  };
};

export default useEmployeeSpecializationService;
