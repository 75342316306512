/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import AssetCategoryDropdown from 'financialAnalytic/assets/shared/input/AssetCategoryDropdown';

const AssetCategoryDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <AssetCategoryDropdown {...props} />
    </DropDownFilter>
  );
};

export default AssetCategoryDropdownFilter;
