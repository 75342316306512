import React, { useEffect, useState } from 'react';
import Button, { ButtonProps } from 'uibuilder/button/Button';
import useAuthorization from 'shared/authorization/authorizationService';
import AddQualificationForm from 'erp/employee/qualification/createupdate/Create/AddQualificationForm';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import AddIcon from '@mui/icons-material/AddOutlined';
import useEmployeeSpecializationService from 'erp/employee/qualification/shared/employeeSpecializationService';
import InputLoader from 'shared/uibuilder/InputLoader';

interface CreateQualificationButtonProps extends ButtonProps {
  permissionToCheck?: string;
}

const CreateQualificationButton = ({ permissionToCheck, ...props }: CreateQualificationButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hasNotAppliedSpecializations, setHasNotAppliedSpecializations] = useState(false);
  const { isGranted } = useAuthorization();
  const employeeId = useEmployeeId() || '';
  const hasPermissions = !permissionToCheck || isGranted(permissionToCheck);
  const [loading, setLoading] = useState(hasPermissions);
  const { getNotAppliedSpecializations } = useEmployeeSpecializationService();

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await getNotAppliedSpecializations(employeeId);
        setHasNotAppliedSpecializations(!!result.length);
      } finally {
        setLoading(false);
      }
    };

    if (hasPermissions) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotAppliedSpecializations]);

  const closeModal = () => setModalOpen(false);

  if (!hasPermissions) {
    return null;
  }

  if (loading) {
    return (
      <div className="d-flex align-items-center align-self-stretch mb-2">
        <InputLoader />
      </div>
    );
  }

  return (
    <>
      {hasNotAppliedSpecializations && (
        <Button
          {...props}
          sx={{ transform: 'translateY(-6px)', marginBottom: '10px' }}
          onClick={() => setModalOpen(true)}
          startIcon={<AddIcon />}
        >
          Add Qualification
        </Button>
      )}
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title="Add new Qualification"
        onToggle={closeModal}
        hasCancelButton={false}
        className="qualification-modal"
      >
        <AddQualificationForm modalOpen={modalOpen} closeModal={closeModal} />
      </ModalWindow>
    </>
  );
};

export default CreateQualificationButton;
