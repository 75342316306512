/* istanbul ignore file */
import React from 'react';
import Badge, { BadgeProps } from 'shared/uibuilder/badge/Badge';

const ExpiredBadge = (props: BadgeProps) => {
  return (
    <Badge className="ml-2" color="neutralLight" {...props}>
      Expired
    </Badge>
  );
};

export default ExpiredBadge;
