import React, { useEffect, useMemo, useState } from 'react';
import DefaultFilterInputLayout from '../DefaultFilterInputLayout';
import FilterActions from '../FilterActions';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import BaseInputErrors from 'uibuilder/layout/form/input/BaseInputErrors';
import { DropDownFilterLayoutType } from 'shared/uibuilder/list/filter/DropDownFilter';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
import SelectLayout from './SelectLayout';
import useDropDownFilterHelper from '../useDropDownFilterHelper';
import './DropDownFilterInputLayout.scss';

const DropDownFilterInputLayout: DropDownFilterLayoutType = props => {
  const {
    label,
    options: initialOptions = [],
    errorMessages,
    multiple = false,
    value: initialValue = '',
    source,
    onResetCallback,
    onChangeCallback,
  } = props;
  const { apply: applyFilters } = useFilterContext();
  const { isMenuOpened, getDisplayedValue, toggleMenu, closeMenu } = useDropDownFilterHelper({ source });
  const [selectedValues, setSelectedValues] = useState<OptionType[]>();
  const [shouldApplyFilter, setShouldApplyFilter] = useState(false);
  const displayedValue = getDisplayedValue(selectedValues);
  const hasError = errorMessages && errorMessages.length > 0;
  const invalidClass = hasError ? 'is-invalid' : '';
  const options = useMemo(
    () => initialOptions.map(({ value, ...optionProps }) => ({ ...optionProps, value: value ? String(value) : value })),
    [initialOptions],
  );

  const isContainValue = (value: string, allValues: string | string[]) =>
    multiple ? allValues?.includes(value) : String(allValues) === value;

  useEffect(() => {
    if (!multiple && isMenuOpened && shouldApplyFilter) {
      closeMenu();
      applyFilters();
      setShouldApplyFilter(false);
    }

    setSelectedValues(options.filter(({ value }) => isContainValue(value as string, initialValue)));
    // Suppressed warnings because we only need to call useEffect callback if updated dropdown value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, options, setShouldApplyFilter]);

  return (
    <DefaultFilterInputLayout
      label={label}
      isOpen={isMenuOpened}
      toggleMenu={toggleMenu}
      displayedValue={displayedValue}
      source={source}
      className={invalidClass}
      onResetFilterCallback={onResetCallback}
    >
      <SelectLayout
        {...props}
        options={options}
        selectedValues={selectedValues}
        onChangeCallback={(value: any) => {
          onChangeCallback(value);
          if (!multiple) {
            setShouldApplyFilter(true);
          }
        }}
      />

      <BaseInputErrors errorMessages={errorMessages} />
      {multiple && <FilterActions onChangeCallback={toggleMenu} onResetCallback={toggleMenu} />}
    </DefaultFilterInputLayout>
  );
};

export default DropDownFilterInputLayout;
