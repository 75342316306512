/* istanbul ignore file */
import React from 'react';
import InCondition from 'shared/uibuilder/list/filter/condition/InCondition';
import { DropDownFilterLayoutType } from 'shared/uibuilder/list/filter/DropDownFilter';

const DropDownFilterLayout: DropDownFilterLayoutType = ({
  source,
  onChangeCallback,
  getDropdownValue,
  children,
  isSingle = false,
  label = '',
}) => {
  return (
    <InCondition source={source} onChangeCallback={onChangeCallback} value={getDropdownValue(true)}>
      {React.cloneElement(children, { multiple: !isSingle, placeholder: 'Select...', label })}
    </InCondition>
  );
};

export default DropDownFilterLayout;
