/* istanbul ignore file */
import React from 'react';
import { useEmployeeOfferId, useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import EmployeeOfferBreadcrumbs from 'erp/employee/offers/shared/EmployeeOfferBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';

const ShowEmployeeOfferBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useEmployeeOfferUrl();
  const employeeOfferId = useEmployeeOfferId();

  return (
    <EmployeeOfferBreadcrumbs>
      <SingleBreadcrumbItem entity={employeeOfferId} link={getSingleEntityUrl(employeeOfferId)} />
      {children}
    </EmployeeOfferBreadcrumbs>
  );
};

export default ShowEmployeeOfferBreadcrumbs;
