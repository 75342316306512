import React, { useEffect, useState } from 'react';
import Message from 'shared/message/Message';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import useOneStopService, { Request } from 'oneStop/oneStopService';
import FormBuilder, { FormInputs } from 'shared/uibuilder/form/Builder';
import { useFeedbackRequestUrl } from 'oneStop/OneStopRouterHelper';
import OneStopRequestLayout from 'oneStop/Create/OneStopRequestLayout';
import OneStopModalDecorator from 'oneStop/Create/OneStopModalDecorator';
import useOneStopConfiguration from 'oneStop/Create/oneStopConfiguration';
import { FormRowType } from 'shared/uibuilder/form/Builder/FormRowFactory';

const MESSAGE_CONTEXT = 'feedbackForm';

type FeedbackFormProps = {
  formId: string;
  formTitle: string;
  closeFeedbackForm: () => void;
  setIsLoaderVisible: (isLoading: boolean) => void;
};

const FeedbackForm = ({ formId, formTitle, closeFeedbackForm, setIsLoaderVisible }: FeedbackFormProps) => {
  const [request, setRequest] = useState<Nullable<Request>>(null);
  const [formConfiguration, setFormConfiguration] = useState<Nullable<FormInputs>>(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setLoading] = useState<Boolean>(true);
  const { getById, sendRequest, getFieldSourcesForHtmlFields, getFormattedData } = useOneStopService({
    messageContext: MESSAGE_CONTEXT,
  });
  const { getRequestConfiguration, getFormConfiguration, getValidationSchema } = useOneStopConfiguration({
    getById,
    id: formId,
    rowType: FormRowType.BIG,
  });
  const { supportUrl } = useFeedbackRequestUrl(formId);

  // used <p> tag instead of \n because we pass this value to the ckeditor(wysiwyg)
  const defaultValues = {
    description: `<p></p><p></p><p></p><p></p><p></p><p></p><p>Submitted from: ${window.location.origin}${window.location.pathname}</p>`,
  };

  useEffect(() => {
    Promise.all([
      getRequestConfiguration().then(data => setRequest(data)),
      getFormConfiguration().then(data => setFormConfiguration(data)),
    ])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    setIsLoaderVisible(true);
    return null;
  } else {
    setIsLoaderVisible(false);
  }

  const closeFeedbackModal = () => setIsModalOpen(false);

  const headerRight = (
    <a className="dialog-header-right-link" href={supportUrl} target="_blank" rel="noopener noreferrer">
      Open {formTitle} form in a new tab
    </a>
  );

  const htmlFieldSources = formConfiguration ? getFieldSourcesForHtmlFields(formConfiguration) : [];

  return (
    <OneStopModalDecorator
      isOneStop={false}
      submitHandler={closeFeedbackModal}
      handleMessageModalClose={closeFeedbackForm}
      messageContext={MESSAGE_CONTEXT}
    >
      <CreateForm
        initialData={defaultValues}
        getValidationSchemaFunc={getValidationSchema}
        submitFormFunc={(data: any) => {
          const formattedData = getFormattedData(data, htmlFieldSources);
          return sendRequest(formId, formattedData);
        }}
        messageContext={MESSAGE_CONTEXT}
      >
        <ModalWindow
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          keyboard={false}
          isOpen={isModalOpen}
          hasCancelButton={false}
          title={request ? null : ' '}
          onToggle={closeFeedbackForm}
        >
          {request && formConfiguration ? (
            <OneStopRequestLayout
              request={request}
              handleCancelClick={closeFeedbackForm}
              headerRight={headerRight}
              isFixedButtons={false}
              messageContext={MESSAGE_CONTEXT}
            >
              <FormBuilder formInputs={formConfiguration} />
            </OneStopRequestLayout>
          ) : (
            <Message messageContext={MESSAGE_CONTEXT} />
          )}
        </ModalWindow>
      </CreateForm>
    </OneStopModalDecorator>
  );
};

export default FeedbackForm;
