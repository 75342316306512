/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import { ListItem } from 'shared/uibuilder/list/ListContext';

interface EditAssignmentButtonProps extends EditButtonProps {
  isVisible?: (data: ListItem) => boolean;
}

const EditAssignmentButton = (props: EditAssignmentButtonProps) => {
  const { getUpdateEntityUrl } = useAssignmentUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditAssignmentButton;
