/* istanbul ignore file */
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { DavinciFormPageLayoutProps } from 'uibuilder/layout/DavinciFormPageLayout';

const UpdatePageLayout = (props: DavinciFormPageLayoutProps) => {
  const { FormPageLayout } = useUiTheme<ReactComponent<DavinciFormPageLayoutProps>>();

  return <FormPageLayout {...props} />;
};

export default UpdatePageLayout;
