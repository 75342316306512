/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useBudgetService from 'erp/budget/shared/budgetService';

const BudgetContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useBudgetService();
  const CONTEXT_NAME = 'BUDGET_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default BudgetContext;
