import React, { useState } from 'react';
import { Button, IconButton } from 'uibuilder/button';
import './FeedbackButton.scss';
import FeedbackForm from './FeedbackForm';
import SupportIcon from '@mui/icons-material/SupportAgentOutlined';
import Loading, { LoaderSize } from 'shared/uibuilder/Loading';

type FeedbackButtonProps = {
  formId: string;
  formTitle: string;
};

const FeedbackButton = ({ formId, formTitle }: FeedbackButtonProps) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const loader = (
    <div className="feedback-spinner" role="status" aria-hidden="true">
      <Loading size={LoaderSize.Small} />
    </div>
  );

  const openFeedbackForm = () => setIsFormOpen(true);

  const closeFeedbackForm = () => setIsFormOpen(false);

  return (
    <>
      <Button
        outline
        className="feedback-button"
        onClick={openFeedbackForm}
        sx={{ display: { xs: 'none', md: 'inline-flex' } }}
      >
        {isLoaderVisible && loader}
        <span>{formTitle}</span>
      </Button>
      <IconButton className="instance-feedback-button" onClick={openFeedbackForm} sx={{ display: { md: 'none' } }}>
        {isLoaderVisible ? loader : <SupportIcon className="feedback-icon" />}
      </IconButton>
      {isFormOpen && (
        <FeedbackForm
          formId={formId}
          formTitle={formTitle}
          setIsLoaderVisible={setIsLoaderVisible}
          closeFeedbackForm={closeFeedbackForm}
        />
      )}
    </>
  );
};

export default FeedbackButton;
