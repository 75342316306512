/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { DOCUMENT_TYPES } from 'erp/employee/Education/shared/educationService';

const DocumentTypeField = props => {
  return <EnumField {...props} options={DOCUMENT_TYPES} />;
};

DocumentTypeField.propTypes = EnumField.propTypes;

export default DocumentTypeField;
