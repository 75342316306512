/* istanbul ignore file */
import React from 'react';
import EntityHeader from 'shared/layout/EntityHeader';
import { EmployeeLink } from 'erp/employee';
import { TextField } from 'shared/uibuilder/field';

const ContractsHeader = () => {
  return (
    <EntityHeader
      entityTitleSource="employeeName"
      hasAuthor={false}
      entityTitle={data => (
        <EmployeeLink id={data.getValueBySource('employeeId')} name={data.getValueBySource('employeeName')} />
      )}
    >
      <TextField metaInformation label="Employee ID:" source="employeeId" />
    </EntityHeader>
  );
};

export default ContractsHeader;
