/* istanbul ignore file */
import TextInput from 'shared/uibuilder/form/input/TextInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import React from 'react';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';

const NoteForm = () => (
  <>
    <BigFormRow>
      <TextInput source="subject" />
    </BigFormRow>
    <BigFormRow>
      <WysiwygInput source="description" />
    </BigFormRow>
    <BigFormRow>
      <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
    </BigFormRow>
  </>
);

export default NoteForm;
