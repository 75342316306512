import React, { useEffect } from 'react';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useLeaveService, { LEAVE_TYPES } from 'erp/leave/shared/leaveService';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService/index';

export interface LeaveDropdownProps {
  source: string;
  label: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  checkDepending?: boolean;
  deps?: any[];
  filter?: Dictionary<any>;
  loadDataMethod?: (params: any) => Promise<any>;
  props?: any;
}

const LeaveDropdown = ({ checkDepending, deps = [], filter, loadDataMethod, ...props }: LeaveDropdownProps) => {
  const { searchAll } = useLeaveService() as any;
  const { isDirty } = useFormContext();
  const { formatDate } = useDateService();
  const { getSource, getRawOnChangeCallback } = useInputHelper(props);
  const source = getSource();
  const parentOnChange = getRawOnChangeCallback() || (() => {});

  const loadLeaves = async () => {
    if (checkDepending && deps.some(dep => !dep)) {
      return Promise.resolve({ result: [] });
    }

    const files = await (loadDataMethod || searchAll)({
      filter,
      sort: { startDate: 'DESC' },
    });

    return Promise.resolve({ result: files });
  };

  useEffect(() => {
    if (isDirty && checkDepending) {
      parentOnChange({
        [source]: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const mapResult = (item: any) => {
    return {
      value: item.id,
      label: `${LEAVE_TYPES[item.type as keyof typeof LEAVE_TYPES]}, ${formatDate(
        item.startDate,
        DATE_FORMAT.FULL_DATE,
      )?.trim()} - ${formatDate(item.endDate, DATE_FORMAT.FULL_DATE)?.trim()}`,
    };
  };

  return (
    <LoadDropdown
      {...props}
      loadDataMethod={loadLeaves}
      mapResults={mapResult}
      deps={deps}
      showLoader
      placeholder="Select Leave"
    />
  );
};

export default LeaveDropdown;
