/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  READ_INVOICE,
  INVOICE_PATH,
  READ_INVOICE_LIST,
  CREATE_INVOICE,
  UPDATE_INVOICE,
} from 'financialAnalytic/invoice/useInvoiceService';
import InvoiceList from 'financialAnalytic/invoice/List';
import InvoiceViewPage from 'financialAnalytic/invoice/Show';
import InvoiceCreatePage from 'financialAnalytic/invoice/createupdate/Create';
import InvoiceUpdatePage from 'financialAnalytic/invoice/createupdate/Update';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useInvoiceRoute = () => {
  const routers = useRoute({ listRoute: INVOICE_PATH });

  return { ...routers, copyEntityRoute: `${routers.singleEntityRoute}/copy` };
};

export const useInvoiceUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: INVOICE_PATH,
  });

  return { ...baseUrls, getCopyEntityUrl: (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/copy` };
};

export const useInvoiceId = () => useParams<Dictionary<string>>().id;

const InvoiceRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, copyEntityRoute, updateRoute } = useInvoiceRoute();

  return (
    <ProtectedRouter basePath={INVOICE_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_INVOICE_LIST}>
          <InvoiceList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_INVOICE}>
          <InvoiceCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={copyEntityRoute} exact globalPermissionToCheck={CREATE_INVOICE}>
          <InvoiceCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={UPDATE_INVOICE}>
          <InvoiceUpdatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_INVOICE}>
          <InvoiceViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

InvoiceRouter.getRouterPath = () => INVOICE_PATH;

export default InvoiceRouter;
