import React from 'react';
import { Button } from 'uibuilder/button';
import ListFiltersListLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/ListFiltersListLayout';
import StaticFiltersListLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/StaticFiltersListLayout';
import QuickFiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/QuickFiltersLayout';
import useFiltersLayoutHelper from 'shared/uibuilder/list/filter/layout/useFiltersLayoutHelper';
import { FiltersLayoutProps } from '../index';
import './ListFiltersLayout.scss';

interface ListFiltersLayoutProps extends FiltersLayoutProps {
  isApplied?: boolean;
}

const ListFiltersLayout = ({ isApplied = false, quickFilters, children }: ListFiltersLayoutProps) => {
  const { getFilterItems, toggleElement, clear, getSelectedFilterItems, isStatic = false } = useFiltersLayoutHelper({
    children,
  });
  const ListLayout = isStatic ? StaticFiltersListLayout : ListFiltersListLayout;

  return (
    <>
      <QuickFiltersLayout>{quickFilters}</QuickFiltersLayout>
      <ListLayout selectedFilters={getSelectedFilterItems()} filters={getFilterItems()} toggleFilter={toggleElement} />

      {isApplied && (
        <div className="filters-reset-btn">
          <Button variant="text" onClick={clear}>
            Reset
          </Button>
        </div>
      )}
    </>
  );
};

export default ListFiltersLayout;
