/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import EvaluationCriterionInput from './EvaluationCriterionItemInput';
// styles
import './EvaluationCriterionInput.scss';

export const EVALUATION_CRITERION_ALIAS = {
  ADHERING_COMPANY_CULTURE: 'ADHERING_COMPANY_CULTURE',
  FOLLOWING_PROCESS: 'FOLLOWING_PROCESS',
  WORK_QUALITY: 'WORK_QUALITY',
  PRODUCTIVITY: 'PRODUCTIVITY',
  CONTRIBUTING_PROCESS_IMPROVEMENT: 'CONTRIBUTING_PROCESS_IMPROVEMENT',
  NOT_ABOUT_CRITERION: 'NOT_ABOUT_CRITERION',
  BUSINESS_OUTCOMES: 'BUSINESS_OUTCOMES',
};

export const EVALUATION_CRITERION = [
  {
    value: EVALUATION_CRITERION_ALIAS.ADHERING_COMPANY_CULTURE,
    label: 'Culture',
    isVisibleOnCreatePage: true,
  },
  {
    value: EVALUATION_CRITERION_ALIAS.FOLLOWING_PROCESS,
    label: 'Following the processes',
    isVisibleOnCreatePage: true,
  },
  { value: EVALUATION_CRITERION_ALIAS.WORK_QUALITY, label: 'Work quality', isVisibleOnCreatePage: false },
  { value: EVALUATION_CRITERION_ALIAS.PRODUCTIVITY, label: 'Productivity', isVisibleOnCreatePage: true },
  {
    value: EVALUATION_CRITERION_ALIAS.CONTRIBUTING_PROCESS_IMPROVEMENT,
    label: 'Contributing to process improvement',
    isVisibleOnCreatePage: false,
  },
  { value: EVALUATION_CRITERION_ALIAS.BUSINESS_OUTCOMES, label: 'Business outcomes', isVisibleOnCreatePage: true },
];

interface EvaluationCriterionFormProps {
  isPageForm?: boolean;
}

const EvaluationCriterionForm = (props: EvaluationCriterionFormProps) => {
  const lastIndex = EVALUATION_CRITERION.length - 1;
  return (
    <FormRow colMd={12} colXl={12}>
      {EVALUATION_CRITERION.filter(item => item.isVisibleOnCreatePage).map((item, index) => (
        <EvaluationCriterionInput
          value={item.value}
          label={item.label}
          {...props}
          index={index}
          lastIndex={lastIndex}
        />
      ))}
    </FormRow>
  );
};

export default EvaluationCriterionForm;
