import React, { useEffect, useState } from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useQualificationUrl } from 'erp/employee/qualification/QualificationRouter';
import useEmployeeSpecializationService from 'erp/employee/qualification/shared/employeeSpecializationService';
import { DELETE_EMPLOYEE_SPECIALIZATION } from 'erp/employee/employeeService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import useAuthorization from 'shared/authorization/authorizationService';

const deleteMessage = 'Do you really want to revoke this qualification?';

const DeleteQualificationButton = ({ qualificationId }: { qualificationId: StringOrNumber }) => {
  const employeeId = useEmployeeId() || '';
  const { deleteById, getPrimarySpecialization } = useEmployeeSpecializationService();
  const { getSingleEntityUrl } = useQualificationUrl();
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted(DELETE_EMPLOYEE_SPECIALIZATION);

  useEffect(() => {
    const loadData = async () => {
      const { id } = await getPrimarySpecialization(employeeId);
      setRedirectUrl(getSingleEntityUrl(id));
    };

    if (hasPermissions) {
      loadData();
    }
    // Suppressed warnings because we only need to call useEffect callback if qualificationId was updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualificationId]);

  return (
    <DeleteButton
      buttonText="Revoke"
      deleteMethod={async () => {
        await deleteById(employeeId, qualificationId);
      }}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl,
        successMessage: 'The Qualification has been successfully deleted.',
        errorMessage: `Can't delete qualification`,
      }}
      permissionToCheck={DELETE_EMPLOYEE_SPECIALIZATION}
      className="ml-2"
    />
  );
};

export default DeleteQualificationButton;
