import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledSwitch } from 'uibuilder/layout/form/input/TogglerInputLayout';
import useSyberryTodaySettings from 'syberryToday/List/AfterDailyCheckin/Settings/useSyberryTodaySettings';

const ParentTasksToggler = () => {
  const { setParentTasksVisible, areParentTasksVisible } = useSyberryTodaySettings();

  const onChangeCallback = (event: any) => {
    setParentTasksVisible(event.target.checked);
  };

  return (
    <FormControlLabel
      className="settings__item"
      control={
        <StyledSwitch
          className="settings__switch"
          color="primary"
          onChange={onChangeCallback}
          checked={areParentTasksVisible}
        />
      }
      label="Show parent tasks"
    />
  );
};

export default ParentTasksToggler;
