import { useFinanceApi, useKernelApi } from 'api';
import generatedPayrollsFilterValidation from 'erp/payroll/generatedPayrolls/List/generatedPayrollsFilterValidation';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import useFeatureToggle, { Features } from 'featuretoggle';
import usePayrollReportSettings from 'erp/payroll/generatedPayrolls/shared/Settings/usePayrollReportSettings';
import { isEqual } from 'lodash';

/* eslint-disable react-hooks/rules-of-hooks */
const payrollsEnabled = useFeatureToggle().isFeatureEnabled(Features.PAYROLL);
/* eslint-disable react-hooks/rules-of-hooks */

export const PAYROLL_REPORTS_PATH = '/payroll-reports';
export const READ_PAYROLL_REPORTS = payrollsEnabled ? 'READ_PAYROLL_REPORT' : 'READ_NEW_PAYROLL_REPORT';
export const APPROVE_PAYROLL_REPORT = 'READ_PAYROLL_REPORT';
export const MARK_AS_PAID_PAYROLL_REPORT = 'READ_PAYROLL_REPORT';
export const REJECT_PAYROLL_REPORT = 'READ_PAYROLL_REPORT';
export const CANCEL_PAYROLL_REPORT_PAYMENT = 'READ_PAYROLL_REPORT';

export const PAYROLL_REPORT_STATUSES = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  PAID: 'PAID',
};

export const PAYROLL_REPORT_STATUSES_OPTIONS = {
  [PAYROLL_REPORT_STATUSES.APPROVED]: 'Approved',
  [PAYROLL_REPORT_STATUSES.PENDING]: 'Pending',
  [PAYROLL_REPORT_STATUSES.PAID]: 'Paid',
};

const useGeneratedPayrollsService = () => {
  const { isLatestReportsVisibleOnly } = usePayrollReportSettings();
  const { sendGetRequest, sendPostRequest } = useFinanceApi();
  const { sendGetRequest: sendKernelGetRequest, sendPostRequest: sendKernelPostRequest } = useKernelApi();

  const getGeneratedReports = async ({ filter }: LoadingParams) => {
    const startDate = filter?.period?.ge;
    const endDate = filter?.period?.le;

    const response = await sendGetRequest(`/payroll/reports?startDate=${startDate}&endDate=${endDate}`);
    const result = (await response.json()) || [];

    if (isLatestReportsVisibleOnly) {
      const sortedResult = [...result].sort(
        (a: any, b: any) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any),
      );

      const filteredResult = result.filter(({ status, period, id, offices }: any) => {
        if (status === PAYROLL_REPORT_STATUSES.APPROVED || status === PAYROLL_REPORT_STATUSES.PAID) {
          return true;
        }

        const hasReport = sortedResult?.find(
          (item: any) =>
            item.status === PAYROLL_REPORT_STATUSES.PENDING && item.period === period && isEqual(item.offices, offices),
        );

        return !hasReport || hasReport.id === id;
      });

      return {
        totalElements: filteredResult.length,
        result: filteredResult,
        totalPages: 1,
      };
    }

    return {
      totalElements: result.length,
      result,
      totalPages: 1,
    };
  };

  const approvePayrollReport = async (id: string, asOfDate: string) => {
    const result = await sendPostRequest(`/payroll/${id}/approve`, { asOfDate });
    return result;
  };

  const markAsPaidPayrollReport = async (id: string, asOfDate: string) => {
    const result = await sendPostRequest(`/payroll/${id}/mark-as-paid`, { asOfDate });
    return result;
  };

  const rejectPayrollReport = async (id: string) => {
    const result = await sendPostRequest(`/payroll/${id}/reject`);
    return result;
  };

  const cancelPayrollReportPayment = async (id: string) => {
    const result = await sendPostRequest(`/payroll/${id}/cancel-payment`);
    return result;
  };

  const getGrossSalary = async (id: string) => {
    const result = await sendGetRequest(`/payroll/${id}/gross-salary`);
    return result.json();
  };

  const getFilterValidationSchema = () => Promise.resolve(generatedPayrollsFilterValidation);

  // TODO: Remove after migration
  const runMigration = () => {
    return sendKernelPostRequest('/compensations/migration/finance');
  };

  // TODO: Remove after migration
  const getFinanceCompensationPackageCount = async () => {
    const result = await sendGetRequest('/compensations/count');
    return result.json();
  };

  // TODO: Remove after migration
  const getKernelCompensationPackageCount = async () => {
    const result = await sendKernelGetRequest('/compensations/count');
    return result.json();
  };

  return {
    getGeneratedReports,
    getFilterValidationSchema,
    approvePayrollReport,
    runMigration,
    getFinanceCompensationPackageCount,
    getKernelCompensationPackageCount,
    markAsPaidPayrollReport,
    getGrossSalary,
    rejectPayrollReport,
    cancelPayrollReportPayment,
  };
};

export default useGeneratedPayrollsService;
