/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useEmployeeOfferId } from 'erp/employee/offers/EmployeeOfferRouter';
import useEmployeeOfferService from 'erp/employee/offers/shared/employeeOfferService';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const deleteMessage = 'Do you really want to delete this Offer?';

const DeleteEmployeeOfferButton = (props: any) => {
  const offerId = useEmployeeOfferId();
  const { deleteById } = useEmployeeOfferService();
  const employeeId = useEmployeeId();
  const { getTimelineUrl } = useEmployeeUrl();

  return (
    <DeleteButton
      {...props}
      displayMessage={deleteMessage}
      deleteMethod={() => deleteById(offerId)}
      afterDelete={{
        redirectUrl: getTimelineUrl(employeeId || ''),
        successMessage: 'The Offer has been successfully deleted.',
        errorMessage: `Can't delete Offer.`,
      }}
    />
  );
};

export default DeleteEmployeeOfferButton;
