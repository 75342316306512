/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useEmployeeAssignmentUrl } from 'erp/employee/assignments/EmployeeAssignmentsRouter';
import { ListItem } from 'shared/uibuilder/list/ListContext';

interface EditEmployeeAssignmentButtonProps extends EditButtonProps {
  isVisible?: (data: ListItem) => boolean;
}

const EditEmployeeAssignmentButton = (props: EditEmployeeAssignmentButtonProps) => {
  const { getUpdateEntityUrl } = useEmployeeAssignmentUrl();

  return <EditButton {...props} key="edit-employee-assignment" source="id" outline path={getUpdateEntityUrl} />;
};

export default EditEmployeeAssignmentButton;
