import React from 'react';
import { string } from 'prop-types';
import useAccountService from 'crm/account/accountService';
import { SearchInput } from 'shared/uibuilder/form/input';

const mapResponse = account => {
  return {
    id: account.accountId,
    text: account.accountName,
    accountName: account.accountName,
    hasAccountCodeName: account.hasCodeName,
    accountHasProjects: account.hasProjects,
  };
};

const AccountInput = ({ accountNameSource, accountNameValue, ...props }) => {
  const { search } = useAccountService();
  const { customMapResponse } = props;

  return (
    <SearchInput
      searchRequest={search}
      noResultMessage="No Accounts are found."
      mapResults={customMapResponse || mapResponse}
      initialLabel={accountNameValue}
      nameSource={accountNameSource}
      {...props}
    />
  );
};

AccountInput.propTypes = {
  accountNameSource: string,
  accountNameValue: string,
};

AccountInput.defaultProps = {
  accountNameSource: 'accountName',
  accountNameValue: undefined,
};

export default AccountInput;
