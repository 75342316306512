/* istanbul ignore file */
import React from 'react';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import InCondition from 'shared/uibuilder/list/filter/condition/InCondition';
import { ConditionProps } from './condition/Condition';

const MultiSelectInputFilter = ({ children, ...props }: ConditionProps) => {
  const { filterBy } = useFilterContext();

  return (
    <InCondition {...props} onChangeCallback={filterBy}>
      {React.cloneElement(children, {
        menuPortalTarget: null,
      })}
    </InCondition>
  );
};

export default MultiSelectInputFilter;
