import { useState } from 'react';
import { useKernelApi } from 'api';
import { EMPLOYEES_PATH, useEmployeeId } from 'erp/employee/EmployeesRouter';
import useBaseCrudService, { ResourceId } from 'shared/crud/baseCrudService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import salaryValidation from 'erp/employee/salaries/createupdate/salaryValidation';

export const SALARY_URL = '/salaries';

export const PERFORMANCE_GRADES = {
  A: 'A',
  B: 'B',
  C: 'C',
  NA: 'N/A',
};
export const PERFORMANCE_GRADES_ALIAS = {
  A: 'A',
  B: 'B',
  C: 'C',
  NA: 'NA',
};
export const PERFORMANCE_GRADES_OPTIONS = {
  [PERFORMANCE_GRADES_ALIAS.A]: 'A',
  [PERFORMANCE_GRADES_ALIAS.B]: 'B',
  [PERFORMANCE_GRADES_ALIAS.C]: 'C',
  [PERFORMANCE_GRADES_ALIAS.NA]: 'N/A',
};

export const PERFORMANCE_CONFIDENCE_GRADES = {
  A: 'A',
  B: 'B',
  C: 'C',
};
export const PERFORMANCE_CONFIDENCE_GRADES_ALIAS = {
  A: 'A',
  B: 'B',
  C: 'C',
};
export const PERFORMANCE_CONFIDENCE_GRADES_OPTIONS = {
  [PERFORMANCE_CONFIDENCE_GRADES_ALIAS.A]: 'A',
  [PERFORMANCE_CONFIDENCE_GRADES_ALIAS.B]: 'B',
  [PERFORMANCE_CONFIDENCE_GRADES_ALIAS.C]: 'C',
};

export const SALARY_TYPE = {
  CURRENT: 'Current Salary',
  TARGET: 'Target Salary',
};
export const SALARY_TYPE_ALIAS = {
  CURRENT: 'CURRENT',
  TARGET: 'TARGET',
};
export const SALARY_TYPE_OPTIONS = {
  [SALARY_TYPE_ALIAS.CURRENT]: 'Current Salary',
  [SALARY_TYPE_ALIAS.TARGET]: 'Target Salary',
};

export interface PerformanceDetailsGrades {
  evaluationGrade: string;
  confidenceGrade: string;
}

export interface PerformanceDetails {
  culture: PerformanceDetailsGrades;
  processFollowing: PerformanceDetailsGrades;
  productivity: PerformanceDetailsGrades;
  businessOutcomes: PerformanceDetailsGrades;
  workQuality: PerformanceDetailsGrades;
  processImprovement: PerformanceDetailsGrades;
}

export enum PerformanceSectionVersions {
  NEW,
  OLD,
  GENERAL,
}

export const PERFORMANCE_DETAILS_SECTIONS: PerformanceSection[] = [
  { field: 'culture', title: 'Culture', version: PerformanceSectionVersions.GENERAL },
  { field: 'processFollowing', title: 'Process Following', version: PerformanceSectionVersions.GENERAL },
  { field: 'productivity', title: 'Productivity', version: PerformanceSectionVersions.GENERAL },
  { field: 'businessOutcomes', title: 'Business Outcomes', version: PerformanceSectionVersions.NEW },
  { field: 'workQuality', title: 'Work Quality', version: PerformanceSectionVersions.OLD },
  { field: 'processImprovement', title: 'Process Improvement', version: PerformanceSectionVersions.OLD },
];

export interface PerformanceSection {
  field: keyof PerformanceDetails;
  title: string;
  version: PerformanceSectionVersions;
}

const useEmployeeSalaryService = () => {
  const [salaryData, setSalaryData] = useState(null);
  const { sendPostRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_PATH}/${employeeId}`;
  const getValidationSchema = () => Promise.resolve(salaryValidation);
  const { addMessage } = useMessageService();

  const { getById: initialGetById, ...baseCrudRequests } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${SALARY_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${SALARY_URL}`,
    apiService: useKernelApi,
  });

  const getSensitiveDataByIds = async (ids: any[]) => {
    const url = `${parentResourceUrl}${SALARY_URL}/sensitive`;
    try {
      const response = await sendPostRequest(url, ids);
      const result = await response.json();

      return result;
    } catch (err) {
      addMessage(new ErrorMessage("Can't get salary data"));

      return null;
    }
  };

  const getById = async (id: ResourceId) => {
    if (salaryData) {
      return salaryData;
    }

    const data = await initialGetById(id);
    const sensitiveData = (await getSensitiveDataByIds([data.sensitiveDataId])) || {};
    const combinedData = { ...data, ...sensitiveData[data.sensitiveDataId] };

    setSalaryData(combinedData);

    return combinedData;
  };

  return {
    ...baseCrudRequests,
    getValidationSchema,
    getSensitiveDataByIds,
    getById,
  };
};

export default useEmployeeSalaryService;
