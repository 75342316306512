export default {
  ownerId: {
    type: 'string',
    required: true,
  },
  bench: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $assignmentType: {
          eq: 'INDIVIDUAL_ASSIGNMENT',
        },
      },
    },
  },
  positionId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $assignmentType: {
          eq: 'INDIVIDUAL_ASSIGNMENT',
        },
      },
    },
  },
  employeeGroupId: {
    type: 'int',
    required: {
      value: true,
      when: {
        $assignmentType: {
          eq: 'GROUP_ASSIGNMENT',
        },
      },
    },
  },
  backupForEmployeeId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $backup: {
          eq: true,
        },
      },
    },
  },
  target: {
    type: 'string',
    required: true,
  },
  from: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$to',
      message: "Start date can't be later than end date",
    },
    minDate: [
      {
        value: '$budget.softPeriodFrom',
        when: [
          {
            '$budget.softPeriodFrom': {
              notEmpty: true,
            },
            '$budget.commercial': {
              eq: true,
            },
          },
        ],
      },
      {
        value: '$budget.periodFrom',
        when: [
          {
            '$budget.periodFrom': {
              notEmpty: true,
            },
            '$budget.commercial': {
              eq: true,
            },
          },
          {
            '$budget.softPeriodFrom': {
              empty: true,
            },
            '$budget.commercial': {
              eq: true,
            },
          },
        ],
        message: 'Please choose date within budget period',
      },
      {
        value: '$TODAY',
        when: [
          {
            $id: {
              empty: true,
            },
            '$_FEATURE[assignmentInThePastEnabled]': {
              eq: false,
            },
          },
        ],
        message: 'Please choose date not earlier than today',
      },
    ],
  },
  to: {
    type: 'date',
    required: true,
    minDate: {
      value: '$from',
      message: "End date can't be earlier than start date",
    },
    maxDate: {
      value: '$budget.periodTo',
      when: [
        {
          '$budget.periodTo': {
            notEmpty: true,
          },
          '$budget.commercial': {
            eq: true,
          },
        },
      ],
      message: 'Please choose date within budget period',
    },
  },
  minPercent: {
    type: 'float',
    required: true,
    maxValue: [
      {
        value: '$maxPercent',
        message: "Min percent can't be greater than max percent",
      },
      {
        value: 100,
        message: 'Allowed percent range: 0 - 100',
      },
    ],
    minValue: {
      value: 0,
      message: 'Allowed percent range: 0 - 100',
    },
    maxDecimal: 2,
  },
  maxPercent: {
    type: 'float',
    required: true,
    minValue: [
      {
        value: '$minPercent',
        message: "Max percent can't be less than min percent",
      },
      {
        value: 0,
        message: 'Allowed percent range: 0 - 100',
      },
    ],
    maxValue: {
      value: 100,
      message: 'Allowed percent range: 0 - 100',
    },
    maxDecimal: 2,
  },
};
