/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EmployeeAssignmentsBreadCrumbs from 'erp/employee/assignments/shared/EmployeeAssignmentsBreadcrumbs';

const CreateEmployeeAssignmentBreadCrumbs = () => {
  return (
    <EmployeeAssignmentsBreadCrumbs>
      <CreateBreadcrumbItem entity="Assignment" />
    </EmployeeAssignmentsBreadCrumbs>
  );
};

export default CreateEmployeeAssignmentBreadCrumbs;
