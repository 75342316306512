/* istanbul ignore file */
import React from 'react';
import SimpleTooltip from 'shared/uibuilder/SimpleTooltip';
import TreeTooltipMessage, { TreeTooltipMessageProps } from './TreeTooltipMessage';
import './index.scss';

interface RbsNodeTooltipProps {
  source: string;
  attributes?: TreeTooltipMessageProps;
}

const RbsNodeTooltip: React.FC<RbsNodeTooltipProps> = ({ source, attributes = {} }) => {
  if (!attributes.goal && !attributes.team?.length) {
    return null;
  }

  return (
    <div className="rbs-tooltip-wrapper">
      <SimpleTooltip isBigText className="rbs-tooltip" id={source}>
        <TreeTooltipMessage {...attributes} />
      </SimpleTooltip>
    </div>
  );
};

export default RbsNodeTooltip;
