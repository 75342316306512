/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { EXPENSE_TYPES_OPTIONS } from 'financialAnalytic/expenses/useExpenseService';

const ExpenseTypeField = (props: FieldProps) => {
  return <EnumField options={EXPENSE_TYPES_OPTIONS} {...props} />;
};

export default ExpenseTypeField;
