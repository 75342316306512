/* istanbul ignore file */
import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import LeaveDropdown, { LeaveDropdownProps } from 'erp/leave/shared/input/LeaveDropdown';
import useLeaveService, { LEAVE_STATUSES_ALIASES } from 'erp/leave/shared/leaveService';
import { EXPENSE_SUBTYPES_ALIASES, EXPENSE_TYPES } from 'financialAnalytic/expenses/useExpenseService';

interface ExpensesLeaveDropdownProps extends LeaveDropdownProps {
  employeeSource?: string;
  typeSource?: string;
}

const LEAVE_TYPES = EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION];

const ExpensesLeaveDropdown = ({ employeeSource, typeSource, ...props }: ExpensesLeaveDropdownProps) => {
  const { searchAllWithExpensesInfo } = useLeaveService() as any;
  const { data = {} } = useFormContext();
  const employeeAlias = employeeSource ? data[employeeSource] : undefined;
  const type = typeSource && data[typeSource] !== LEAVE_TYPES.MATERNITY_LEAVE ? data[typeSource] : undefined;
  const filter: Dictionary<any> = {
    status: { in: [LEAVE_STATUSES_ALIASES.APPROVED, LEAVE_STATUSES_ALIASES.REGISTERED] },
    'employee.alias': { eq: employeeAlias },
  };

  if (type === LEAVE_TYPES.PAID_LEAVE) {
    filter.type = { in: ['PAID_LEAVE', 'COMPENSATION', 'DAY_ON_DEMAND'] };
  } else if (type) {
    filter.type = { in: [type] };
  }

  return (
    <LeaveDropdown
      {...props}
      deps={[employeeAlias, type]}
      filter={filter}
      isVisible={!!typeSource && data[typeSource] !== LEAVE_TYPES.MATERNITY_LEAVE}
      loadDataMethod={searchAllWithExpensesInfo}
    />
  );
};

export default ExpensesLeaveDropdown;
