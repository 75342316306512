import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import { OFFICE_PATH } from 'erp/office/useOfficeService';
import ProtectedRouter from 'routing/ProtectedRouter';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import React from 'react';
import { READ_ROOM_LIST } from 'erp/room/useRoomService';
import OfficeListPage from 'erp/office/List';
import OfficeViewPage from 'erp/office/Show';

export const useOfficeRoute = () => useRoute({ listRoute: OFFICE_PATH });

export const useOfficeUrl = () => useEntityUrl({ baseLink: OFFICE_PATH });

export const useOfficeId = () => useParams<Dictionary<string>>().id;

const OfficeRouter = () => {
  const { listRoute, singleEntityRoute } = useOfficeRoute();

  return (
    <ProtectedRouter basePath={OFFICE_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_ROOM_LIST}>
          <OfficeListPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_ROOM_LIST}>
          <OfficeViewPage />
        </GlobalProtectedRoute>
      </Switch>
    </ProtectedRouter>
  );
};

OfficeRouter.getRouterPath = () => OFFICE_PATH;

export default OfficeRouter;
