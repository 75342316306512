/* istanbul ignore file */
import React from 'react';
import { func, node, oneOfType } from 'prop-types';
import { InputList } from 'shared/uibuilder/form/input';

const FamilyMemberListInput = ({ inputTemplate, ...props }) => {
  return (
    <div className="family-member-input">
      <InputList {...props} inputTemplate={inputTemplate} />
    </div>
  );
};

FamilyMemberListInput.propTypes = {
  inputTemplate: oneOfType([func, node]).isRequired,
};

export default FamilyMemberListInput;
