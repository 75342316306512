/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import Button from 'uibuilder/button/Button';
import useAuthorization from 'shared/authorization/authorizationService';
import TerminateProcessModalForm from 'camunda/monitoring/instance/Show/shared/TerminateProcessModalForm';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { TERMINATE_PROCESS } from 'camunda/monitoring/instance/Show/shared/processInstanceUpdatingService';

const TerminateProcessButton = () => {
  const { data: process } = useShowContext();
  const { isGranted } = useAuthorization();

  const isButtonHidden = !isGranted(TERMINATE_PROCESS) || process.getValueBySource('state') !== 'ACTIVE';
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  return isButtonHidden ? null : (
    <>
      <Button onClick={openCloseIssueForm}>Terminate process</Button>
      {isFormOpen && <TerminateProcessModalForm closeModal={closeModal} />}
    </>
  );
};

export default TerminateProcessButton;
