import React from 'react';
import { useProcessDefinitionKey } from 'camunda/monitoring/definition/ProcessDefinitionRouter';
import { Redirect } from 'react-router-dom';
import { useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import { ProcessState } from 'camunda/monitoring/instance/processInstanceService';

const ProcessDefinitionRedirect = () => {
  const { getListUrl } = useProcessInstanceUrl();
  const key = useProcessDefinitionKey();

  const listUrl = getListUrl();
  const filterParam = `filter={"processKey":{"in":["${key}"]}, "state": {"eq":"${ProcessState.ACTIVE}"}}`;
  const url = `${listUrl}?${filterParam}`;

  return <Redirect to={url} />;
};

export default ProcessDefinitionRedirect;
