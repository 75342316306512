/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField, ExpandingField } from 'shared/uibuilder/field';
import TableBodyWithGrouping from 'shared/uibuilder/list/layout/DataGridLayout/TableBodyWithGrouping';
import { CREATE_RBS_TREE_NODE, RbsNodeType, isBackupOwner } from 'erp/rbs/shared/rbsService';
import useAuthorization from 'shared/authorization/authorizationService';
import RbsNodeLinkField from 'erp/rbs/shared/field/RbsNodeLinkField';
import RbsTypeField from 'erp/rbs/shared/field/RbsTypeField';
import ProjectLinkField from 'erp/project/shared/field/ProjectLinkField';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useAsyncValue from 'shared/asyncHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import { GET_EMPLOYEES_LIST } from 'erp/employee/employeeService';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import TeamEmployeeLinkField from 'erp/rbs/shared/field/TeamEmployeeLinkField';
import './index.scss';
import RbsNodeOwnersField from 'erp/rbs/shared/field/RbsNodeOwnersField';
import { getMaxTreeDepth } from './rbsListHelpers';

const isParentNodeOwner = (parentNode: any, userAlias: Nullable<string>): boolean => {
  return (
    parentNode.nodeType === RbsNodeType.PROJECT &&
    (parentNode.attributes?.owner?.alias === userAlias ||
      parentNode.attributes?.secondOwner?.alias === userAlias ||
      isBackupOwner(parentNode.attributes, userAlias))
  );
};

const isOwner = (items: any[], userAlias: Nullable<string>): boolean => {
  return items.some(
    ({ attributes = {}, children = [], parentNode }: any) =>
      attributes?.owner?.alias === userAlias ||
      attributes?.secondOwner?.alias === userAlias ||
      isBackupOwner(attributes, userAlias) ||
      (children && isOwner(children, userAlias)) ||
      (parentNode && isParentNodeOwner(parentNode, userAlias)),
  );
};

const MIN_COLUMN_WIDTH = 150;
const NESTED_ROW_PADDING = 35;

const RbsListLayout = ({
  buttons,
  permissionToCheck,
}: {
  buttons: React.ReactElement[];
  permissionToCheck?: string;
}) => {
  const { data: { items = [] } = {} } = useListContext();
  const { isGranted } = useAuthorization();
  const canViewEmployeeDetails = isGranted(GET_EMPLOYEES_LIST);
  const canViewProjectDetails = isGranted(CREATE_RBS_TREE_NODE);
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(getUserAlias);
  const hasButtonsColumn = isOwner(items, userAlias) || !permissionToCheck || isGranted(permissionToCheck);
  const maxTreeDepth = getMaxTreeDepth(items);

  return (
    <DataGridLayout
      showPagination={false}
      showTotal={false}
      tableWrapperClassName="responsibilities-list"
      buttons={hasButtonsColumn ? buttons : []}
      tableBodyComponent={props => (
        <TableBodyWithGrouping
          {...props}
          childrenSource="children"
          toggleSource="name"
          toggleProp="nameSource"
          collapsibleRowStorageProps={{
            cacheName: 'tree-data',
            storageKey: 'collapsedNodes',
            saveExpandedRows: false,
          }}
        />
      )}
    >
      <TextField label="ID" source="id" width={60} />
      <RbsNodeLinkField
        source="id"
        nameSource="name"
        label="Name"
        permissionToCheck={CREATE_RBS_TREE_NODE}
        width={`${Math.max(MIN_COLUMN_WIDTH, MIN_COLUMN_WIDTH + (maxTreeDepth - 1) * NESTED_ROW_PADDING)}px`}
        userAlias={userAlias}
      />
      <RbsTypeField source="nodeType" label="Type" width={85} />
      <RbsNodeOwnersField canViewEmployeeDetails={canViewEmployeeDetails} label="Owners" width={180} />
      <ExpandingField source="attributes.team" maxValueLength={3} width={180}>
        <FieldListLayout source="attributes.team" label="" isRowDirection>
          <TeamEmployeeLinkField />
        </FieldListLayout>
      </ExpandingField>
      {canViewProjectDetails ? (
        <ProjectLinkField
          permissionToCheck={CREATE_RBS_TREE_NODE}
          label="Project"
          source="attributes.project.id"
          nameSource="attributes.project.name"
          width={MIN_COLUMN_WIDTH}
        />
      ) : (
        (null as any)
      )}
      {canViewProjectDetails ? <TextField source="attributes.project.sowId" width={MIN_COLUMN_WIDTH} /> : (null as any)}
      <ExpandingField
        source="attributes.goal"
        width={canViewProjectDetails ? 200 : 260}
        maxValueLength={canViewProjectDetails ? 78 : 130}
      >
        <TextField source="attributes.goal" className="d-block" />
      </ExpandingField>
    </DataGridLayout>
  );
};

export default RbsListLayout;
