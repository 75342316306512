/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import List from 'shared/uibuilder/list/List';
import useJournalEntryService, { CREATE_JOURNAL_ENTRY } from 'financialAnalytic/journalEntry/useJournalEntryService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/CreateJournalEntryButton';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import JournalEntryStaticFilters from 'financialAnalytic/journalEntry/shared/filter/JournalEntryStaticFilters';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import JournalEntryLink from 'financialAnalytic/journalEntry/shared/field/JournalEntryLink';
import ViewJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/ViewJournalEntryButton';
import TextField from 'shared/uibuilder/field/TextField';
import DateField from 'shared/uibuilder/field/DateField';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import { EmployeeFilter } from 'erp/employee';
import './JournalEntryListPage.scss';
import OfficeFilter from 'erp/contracts/List/filter/OfficeDropdownFilter';
import ListPageHeaderWrapper from '../../shared/ListPageHeaderWrapper';
import UploadJournalEntryButton from '../shared/button/UploadJournalEntryButton';
import { useJournalEntryRoute } from '../JournalEntryRouter';

const JournalEntryListPage = () => {
  const { search, getFilterValidationSchema } = useJournalEntryService();
  const { uploadJournalEntryRoute } = useJournalEntryRoute();
  const currentMonthStart = moment()
    .startOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);
  const currentMonthEnd = moment()
    .endOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);

  const filters = (
    <FiltersLayout>
      <EmployeeFilter label="Posted By" source="postedBy" />
      <EmployeeFilter source="employeeAlias" label="Employee" />
      <OfficeFilter source="offices" label="Office" />
    </FiltersLayout>
  );

  return (
    <List
      getDataMethod={search}
      defaultFilter={{
        period: {
          ge: currentMonthStart,
          le: currentMonthEnd,
        },
      }}
      uncontrolledFilters={['period']}
      shouldSaveUncontrolledFilters
    >
      <Filters getValidationSchema={getFilterValidationSchema}>
        <ListPageLayout
          header={
            <ListPageHeaderWrapper
              header={
                <JournalEntryStaticFilters isInListHeaderWrapper title={<ListHeader label="Journal Entries" />} />
              }
              actions={[
                <UploadJournalEntryButton url={uploadJournalEntryRoute} permissionToCheck={CREATE_JOURNAL_ENTRY} />,
              ]}
            />
          }
          actions={[<CreateJournalEntryButton />]}
          filter={filters}
        >
          <DataGridLayout
            buttons={[<ViewJournalEntryButton source="id" />]}
            tableWrapperClassName="journal-entry-table-wrapper"
            rowProps={(data: any) => ({ className: data.transfers[0]?.isStorno ? 'table-delete' : '' })}
          >
            <JournalEntryLink source="id" label="Number" width="25%" />
            <DateField source="asDateOf" label="Date" width="25%" />
            <TextField source="description" width="50%" />
          </DataGridLayout>
        </ListPageLayout>
      </Filters>
    </List>
  );
};

export default JournalEntryListPage;
