/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';

const CompetencyWeightField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const actualWeight = getValue();

  return <TextField {...props} value={`${actualWeight}%`} />;
};

export default CompetencyWeightField;
