/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import 'artifact/shared/field/HtmlWithArtifactFieldDecorator.scss';
import useArtifactService from 'artifact/artifactService';
import { CROP_SIZES } from 'artifact/const';
import { element } from 'prop-types';
import SanitizedHtml from '../../../shared/security/SanitizedHtml';

const aimAttrName = 'data-aim';

const getInitialValue = value => {
  const div = document.createElement('div');
  div.innerHTML = value;
  const artifacts = div.querySelectorAll('artifact');

  artifacts.forEach(artifact => {
    const tmp = document.createElement('div');
    tmp.innerHTML = '<i class="fa fa-spinner fa-spin" />';

    artifact.parentNode.replaceChild(tmp.firstChild, artifact);
  });

  return <SanitizedHtml html={div.innerHTML} wrapperTag="p" />;
};

const HtmlWithArtifactFieldDecorator = ({ children }) => {
  const { getValue } = useFieldHelper(children.props);
  const { getArtifactsDataByIds } = useArtifactService();
  const inputValue = getValue();

  const [displayedValue, setDisplayedValue] = useState(getInitialValue(inputValue));

  const getPreparedImage = (artifactData = { thumbnails: {} }) => {
    const image = document.createElement('img');
    image.alt = artifactData.title || 'not-found';
    image.src = artifactData.thumbnails ? artifactData.thumbnails[CROP_SIZES.SIZE_100] : '';
    image.className = 'inserted-artifact';
    image.setAttribute(aimAttrName, artifactData.id);

    return image;
  };

  const initializeDisplayedValue = async () => {
    const div = document.createElement('div');
    div.innerHTML = inputValue;
    const artifacts = div.querySelectorAll('artifact');

    const artifactsData = await getArtifactsDataByIds(Array.from(artifacts).map(artifact => artifact.id));

    artifacts.forEach(artifact => {
      const image = getPreparedImage(artifactsData.find(artifactData => artifactData.id === artifact.id));

      artifact.parentNode.replaceChild(image, artifact);
    });

    setDisplayedValue(<SanitizedHtml html={div.innerHTML} wrapperTag="p" />);
  };

  useEffect(() => {
    initializeDisplayedValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleClick = ({ target }) => {
    const aim = target.getAttribute(aimAttrName);

    if (aim) {
      const artifactPreview = document.querySelector(`img[id="${aim}"]`);

      if (artifactPreview) {
        artifactPreview.click();
      }
    }
  };

  return (
    <div onClick={handleClick} data-testid="artifact-field-decorator">
      {React.cloneElement(children, {
        value: displayedValue,
      })}
    </div>
  );
};

HtmlWithArtifactFieldDecorator.propTypes = {
  children: element.isRequired,
};

export default HtmlWithArtifactFieldDecorator;
