import { resolveSchema } from 'validation-schema-library';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { Request } from 'oneStop/oneStopService';
import { FormRowType } from 'shared/uibuilder/form/Builder/FormRowFactory';
import { FormInputType } from 'shared/uibuilder/form/Builder/FormInputFactory';
import { FormSectionType } from 'shared/uibuilder/form/Builder/FormSectionFactory';
import { formRowMap } from '../CustomRequestForm/formMap';

enum OneStopInputCustomType {
  ENUM = 'enum',
  LONG = 'long',
  STRING = 'string',
}

enum OneStopConstraintType {
  MAX = 'max',
  MIN_VALUE = 'minValue',
  VISIBLE = 'visible',
  REQUIRED = 'required',
}

type Field = {
  [key: string]: any;
};

type Option = {
  [key: string]: any;
};

export type FormRowProps = {
  type: FormRowType;
  fields: Field[];
};

const FIELDS_SPLIT_THRESHOLD = 6;

const useFormConfigurationMapper = () => {
  const mapFieldType = (fieldType: string) => {
    const isFormInputType = Object.values(FormInputType).find(inputType => inputType === fieldType);

    if (isFormInputType) {
      return fieldType;
    }

    let type;

    switch (fieldType) {
      case OneStopInputCustomType.STRING: {
        type = FormInputType.TEXT;
        break;
      }
      case OneStopInputCustomType.LONG: {
        type = FormInputType.NUMBER;
        break;
      }
      case OneStopInputCustomType.ENUM: {
        type = FormInputType.DROPDOWN;
        break;
      }
      default:
    }

    return type;
  };

  const mapFieldOptions = (value: any) => {
    return value.map((optionProps: any) => {
      const option: Option = {};

      option.value = optionProps.id;
      option.label = optionProps.name;

      return option;
    });
  };

  const mapField = (field: Field) => {
    const { id, label, type, values, properties, constraints, hideOnUi } = field;
    const mappedField: Field = {};

    mappedField.source = id;
    mappedField.label = label;
    mappedField.type = mapFieldType(type);

    if (type === FormInputType.TEXTAREA) {
      mappedField.rows = 8;
    }

    if (values?.length) {
      mappedField.options = mapFieldOptions(values);
    }

    if (properties?.filter) {
      mappedField.defaultFilter = JSON.parse(properties.filter);
    }

    if (properties?.tooltip) {
      mappedField.tooltip = properties.tooltip;
    }

    if (properties?.helpText) {
      mappedField.helpText = properties.helpText;
    }

    if (constraints?.visible !== undefined) {
      const schema = {
        [id]: {
          visible: constraints.visible,
        },
      };
      mappedField.isVisible = (data: FormFieldsData) => {
        const result = resolveSchema(data, schema)[id].visible;

        return result === undefined ? true : result;
      };
    }

    if (constraints?.hidden !== undefined || hideOnUi) {
      const schema = {
        [id]: {
          hidden: constraints.hidden || hideOnUi,
        },
      };
      mappedField.isVisible = (data: FormFieldsData) => {
        const result = resolveSchema(data, schema)[id].hidden;

        return result === undefined ? true : !result;
      };
    }

    if (constraints?.disabled !== undefined) {
      const schema = {
        [id]: {
          disabled: constraints.disabled,
        },
      };
      mappedField.disabled = (data: FormFieldsData) => {
        const result = resolveSchema(data, schema)[id].disabled;

        return result || false;
      };
    }

    return mappedField;
  };

  const mapFields = (request: Nullable<Request>, rowType: Nullable<FormRowType>) => {
    const fields = request && request.fields ? request.fields : [];
    const isOneColumnRows = fields.length < FIELDS_SPLIT_THRESHOLD;
    const type = rowType || FormRowType.MEDIUM;
    const rows = [];

    if (isOneColumnRows) {
      fields.forEach((field: Field) => {
        const mappedField: Field = mapField(field);

        const row = {
          type,
          fields: [mappedField],
        };

        rows.push(row);
      });
    } else {
      const mappedFields = fields.map((field: Field) => {
        return mapField(field);
      });

      const singleRow = {
        type,
        fields: mappedFields,
      };

      if (request && formRowMap[request.id]) {
        const mappedRows = formRowMap[request.id](singleRow);
        rows.push(...mappedRows);
      } else {
        rows.push(singleRow);
      }
    }

    return {
      sections: [
        {
          type: FormSectionType.DEFAULT,
          rows,
        },
      ],
    };
  };

  const mapConstraint = (entry: any) => {
    const [key, value] = entry;

    switch (key) {
      case OneStopConstraintType.MAX: {
        return {
          maxSize: value,
        };
      }
      case OneStopConstraintType.MIN_VALUE: {
        return {
          minValue: value,
        };
      }
      case OneStopConstraintType.REQUIRED: {
        return {
          required: value,
        };
      }
      default: {
        return {};
      }
    }
  };

  const mapConstraints = (constraints: object) => {
    let mappedConstraints: any = {};

    Object.entries(constraints).forEach(entry => {
      mappedConstraints = {
        ...mappedConstraints,
        ...mapConstraint(entry),
      };
    });

    return mappedConstraints;
  };

  const mapValidationSchema = (request: Nullable<Request>) => {
    const fields = request && request.fields ? request.fields : [];
    const validationSchema: any = request && request.validationSchema ? request.validationSchema : {};
    const mappedValidationSchema: any = {};

    fields.forEach(({ id, type, constraints, validation }: Field) => {
      if (constraints) {
        mappedValidationSchema[id] = mapConstraints(constraints);
      }

      if (validation) {
        mappedValidationSchema[id] = {
          ...mappedValidationSchema[id],
          ...validation,
        };
      }

      if (type === FormInputType.CANDIDATE_RESUME) {
        mappedValidationSchema[id] = {
          maxSize: 1,
          ...mappedValidationSchema[id],
        };
      }

      if (validationSchema && validationSchema[id]) {
        mappedValidationSchema[id] = {
          ...mappedValidationSchema[id],
          ...validationSchema[id],
        };
      }
    });

    return mappedValidationSchema;
  };

  return {
    getFormConfiguration: (request: Nullable<Request>, rowType: Nullable<FormRowType>) =>
      Promise.resolve(mapFields(request, rowType)),
    getValidationSchema: (request: Nullable<Request>) => Promise.resolve(mapValidationSchema(request)),
  };
};

export default useFormConfigurationMapper;
