/* istanbul ignore file */
import React from 'react';
import { DateField, TextField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import List from 'shared/uibuilder/list/List';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import useEmployeeBackgroundCheckService from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';
import CreateEmployeeBackgroundCheckButton from 'erp/employee/backgroundcheck/List/button/CreateEmployeeBackgroundCheckButton';
import EditEmployeeBackgroundCheckButton from 'erp/employee/backgroundcheck/List/button/EditEmployeeBackgroundCheckButton';
import ViewEmployeeBackgroundCheckButton from 'erp/employee/backgroundcheck/List/button/ViewEmployeeBackgroundCheckButton';

const BackgroundChecksList = () => {
  const { search } = useEmployeeBackgroundCheckService();

  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="performedAt">
      <RelationListLayout header="Background Checks" actions={[<CreateEmployeeBackgroundCheckButton />]}>
        <DataGridLayout
          buttons={[
            <EditEmployeeBackgroundCheckButton source="id" />,
            <ViewEmployeeBackgroundCheckButton source="id" />,
          ]}
          isSmallTable
          showTotal={false}
        >
          <DateField isSortable source="performedAt" label="Check Date" />
          <TextField isSortable source="result" label="Result" />
          <EmployeeLinkField source="reviewerId" nameSource="reviewerName" label="Reviewer" isSortable />
        </DataGridLayout>
      </RelationListLayout>
    </List>
  );
};

export default BackgroundChecksList;
