// libs
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { arrayOf, string, func, number, bool } from 'prop-types';
// components
import UploadingQueueLayout from 'artifact/shared/input/layout/list/UploadingQueueLayout';
// services
import useUploadingQueueService from './uploadingQueueService';
import useArtifactService from 'artifact/artifactService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { mapExtensions } from 'shared/uibuilder/helper';
// static
import fileUploadImg from 'artifact/images/upload.svg';
import './FileUploader.scss';
import usePasteFromBuffer from 'shared/usePasteFromBuffer';

/**
 * @param addArtifact function that adds artifact id to the form context
 * @param artifactTypeId - artifact type id
 * @param validationCallback - function that set validation error for the file upload input
 * @returns {*}
 * @constructor
 */
const FileUploader = ({
  validationCallback,
  validateFunc,
  uploadCallback,
  acceptExtensions,
  artifactTypeId,
  source,
  canAddFromClipboard,
  disabled,
  uploadFile,
}) => {
  const {
    addToUploadingQueue,
    removeFromUploadingQueue,
    modifyLoadingProgress,
    getObjectsInQueue,
  } = useUploadingQueueService();

  const { uploadArtifact } = useArtifactService({ uploadFile });
  const { setIsLoadingArtifact } = useFormContext();

  const [uploadCount, _setUploadCount] = useState(0);
  const [isDragEnter, setDragEnter] = useState(false);
  const countRef = useRef(uploadCount);

  const setUploadCount = useCallback(
    data => {
      const result = typeof data === 'function' ? data(countRef.current) : data;
      countRef.current = result;
      _setUploadCount(result);
    },
    [_setUploadCount],
  );

  const upload = async e => {
    const input = e.target;
    const { files } = e.target;

    const error = validateFunc([...files, ...getObjectsInQueue()]);

    if (error) {
      validationCallback(error);
      e.preventDefault();
      return;
    }

    const promises = Array.from(files).map(async file => {
      const { temporaryId, signal } = await addToUploadingQueue(file);

      const artifactId = await uploadArtifact({
        file,
        signal,
        artifactTypeId,
        onProgress: loadingProgress => {
          modifyLoadingProgress(temporaryId, loadingProgress);
        },
      });

      removeFromUploadingQueue(temporaryId);
      uploadCallback(artifactId, file);
    });

    setUploadCount(currentCount => currentCount + 1);

    Promise.all(promises.map(p => p.catch(er => er))).finally(() => {
      setUploadCount(currentCount => currentCount - 1);
    });

    input.value = '';
  };

  useEffect(() => {
    if (uploadCount === 0) {
      setIsLoadingArtifact(false);
    } else {
      setIsLoadingArtifact(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCount]);

  useEffect(() => {
    return () => {
      setIsLoadingArtifact(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFile = id => {
    removeFromUploadingQueue(id);
  };

  const handleChange = e => {
    setDragEnter(false);
    validationCallback(null);
    upload(e);
  };

  const handleDragEnter = () => {
    setDragEnter(true);
  };

  const handleDragLeave = () => {
    setDragEnter(false);
  };

  const handlePaste = useCallback(
    ({ clipboardData: { files } }) => {
      if (countRef.current === 0 && files.length) {
        handleChange({ target: { files } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countRef.current, validateFunc],
  );

  usePasteFromBuffer(handlePaste, canAddFromClipboard);

  const className = !disabled ? `photo-upload` : 'photo-upload-disabled';
  const classNameForDrag = isDragEnter ? 'is-enter' : '';

  return (
    <>
      <UploadingQueueLayout
        loadingArtifactObjects={getObjectsInQueue()}
        onRemove={loadingArtifactObjects => deleteFile(loadingArtifactObjects.id)}
      />
      <label
        className={`${className} ${classNameForDrag}`}
        title={canAddFromClipboard ? 'Copy screenshot to clipboard and perform hotkey paste(Ctrl/Cmd + V)' : ''}
        htmlFor={source}
      >
        <input
          id={source}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          name={source}
          disabled={disabled}
          type="file"
          onChange={handleChange}
          accept={mapExtensions(acceptExtensions)}
          multiple
        />
        <img src={fileUploadImg} alt="Upload" />
        <span>Upload or drag files</span>
      </label>
    </>
  );
};

FileUploader.propTypes = {
  acceptExtensions: arrayOf(string),
  uploadCallback: func,
  validationCallback: func,
  validateFunc: func,
  artifactTypeId: number.isRequired,
  source: string,
  canAddFromClipboard: bool,
  disabled: bool,
};

FileUploader.defaultProps = {
  acceptExtensions: null,
  uploadCallback: () => {},
  validationCallback: () => {},
  validateFunc: () => {},
  source: undefined,
  canAddFromClipboard: false,
  disabled: false,
};

export default FileUploader;
