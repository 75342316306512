/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import LeaveStatusDropdown from 'erp/leave/shared/input/LeaveStatusDropdown';

const LeaveStatusFilter = props => {
  return (
    <DropDownFilter {...props}>
      <LeaveStatusDropdown />
    </DropDownFilter>
  );
};

export default LeaveStatusFilter;
