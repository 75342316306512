/* istanbul ignore file */
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';

type EmployeeSearchLinkFieldProps = {
  label?: string;
  idValue?: string;
  idSource?: string;
  source?: string;
};

const EmployeeSearchLinkField = ({ idValue, idSource, ...props }: EmployeeSearchLinkFieldProps) => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue } = useFieldHelper<any>(props);

  const id = getId();
  const name = getValue();
  const linkText = name ? [name.firstName, name.lastName].join(' ').trim() : id;

  return <SearchLinkField {...props} isInternal value={linkText} url={getSingleEntityUrl(id)} isRowClickable={false} />;
};

export default EmployeeSearchLinkField;
