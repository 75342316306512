/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useEmployeeBackgroundChecksUrl } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';

const ViewEmployeeBackgroundCheckButton = props => {
  const { getSingleEntityUrl } = useEmployeeBackgroundChecksUrl();

  return (
    <LinkButton {...props} outline url={getSingleEntityUrl}>
      View
    </LinkButton>
  );
};

export default ViewEmployeeBackgroundCheckButton;
