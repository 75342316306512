import React from 'react';
import { Link } from 'react-router-dom';
import { useShowContext } from '../show/ShowContext';
import SearchResultField, { getValue, SearchResultFieldProps } from './SearchResultField';

interface SearchLinkFieldProps extends SearchResultFieldProps {
  isRowClickable?: boolean;
  url: string | ((value?: string | number) => string) | ((value?: string) => string);
}

/**
 * The field is used for displaying links
 *
 * @param isRowClickable{boolean} display link as block element instead of inline-block
 * @param url {string | (function(string || number): string)}
 * @param value {string}
 * @param source {string}
 */
const SearchLinkField = ({
  url: initialUrl,
  source = '',
  value: initialValue = '',
  isRowClickable = true,
  ...props
}: SearchLinkFieldProps) => {
  const { data } = useShowContext();

  const value = getValue(data.getData(), source || '', initialValue);

  let url = initialUrl as string;

  if (typeof initialUrl === 'function') {
    url = initialUrl(value);
  }

  return (
    <Link to={url} className={isRowClickable ? 'd-block' : ''}>
      <SearchResultField {...props} value={value} />
    </Link>
  );
};

export default SearchLinkField;
