import React, { useEffect, useState } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { FormFieldsData } from '../FormContext';

export const splitForApi = (emailsInStr: string[] | string) => {
  if (Array.isArray(emailsInStr)) return emailsInStr;

  return (
    typeof emailsInStr === 'string' &&
    emailsInStr
      .replace(/\s*,\s*/g, ', ')
      .split(', ')
      .filter(item => item)
  );
};

/**
 * Form context contains array of strings.
 * Component split them by comma and display.
 *
 * When user enter some new values, the component adds them to array.
 */
const CommaListInput = (props: DefaultInputPropTypes<string[]>) => {
  const inputHelper = useInputHelper(props);

  const [value, setValue] = useState('');

  useEffect(() => {
    const contextValue = inputHelper.getValue();
    setValue(contextValue ? contextValue.join(', ') : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getSource, getRawOnChangeCallback } = inputHelper;

  const onChangeCallback = (object: FormFieldsData) => {
    const source = getSource();
    const rawValue = object[source];
    const rawOnChangeCallback = getRawOnChangeCallback();
    setValue(rawValue);

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [source]: splitForApi(rawValue),
      });
    }
  };

  return <TextInput {...props} onChangeCallback={onChangeCallback} value={value} />;
};

export default CommaListInput;
