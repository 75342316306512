/* istanbul ignore file */

export default {
  employeeId: {
    type: 'string',
    required: true,
    maxSize: 128,
  },
  relatedPeriodStart: {
    type: 'date',
    maxDate: '$NOW',
    required: true,
  },
  relatedPeriodEnd: {
    type: 'date',
    maxDate: '$NOW',
    minDate: '$relatedPeriodStart',
    required: true,
  },
  onBehalfOf: {
    type: 'string',
    required: true,
    maxSize: 128,
  },
  onBehalfOfEmployee: {
    type: 'string',
    required: {
      value: true,
      when: {
        $onBehalfOf: {
          eq: 'ANOTHER_EMPLOYEE',
        },
      },
      message: 'Please fill this field',
    },
    maxSize: 128,
  },
  externalEmployee: {
    type: 'string',
    required: {
      value: true,
      when: {
        $onBehalfOf: {
          eq: 'EXTERNAL_USER',
        },
      },
      message: 'Please fill this field',
    },
    maxSize: 128,
  },
  obtainedBy: {
    type: 'string',
    required: {
      value: true,
      when: {
        $onBehalfOf: {
          ne: 'MYSELF',
        },
      },
      message: 'Please fill this field',
    },
    maxSize: 128,
  },
  relatedProject: {
    type: 'string',
    maxSize: 128,
  },
  feedbackGrades: {
    type: 'object',
    properties: {
      ADHERING_COMPANY_CULTURE: {
        type: 'object',
        properties: {
          description: {
            type: 'string',
            required: {
              value: true,
              when: {
                '$feedbackGrades.ADHERING_COMPANY_CULTURE.scale': {
                  eq: true,
                },
              },
            },
            maxSize: 10000,
          },
          attachmentsIds: {
            type: 'array',
            maxSize: 10,
          },
        },
      },
      FOLLOWING_PROCESS: {
        type: 'object',
        properties: {
          description: {
            type: 'string',
            required: {
              value: true,
              when: {
                '$feedbackGrades.FOLLOWING_PROCESS.scale': {
                  eq: true,
                },
              },
            },
            maxSize: 10000,
          },
          attachmentsIds: {
            type: 'array',
            maxSize: 10,
          },
        },
      },
      WORK_QUALITY: {
        type: 'object',
        properties: {
          description: {
            type: 'string',
            required: {
              value: true,
              when: {
                '$feedbackGrades.WORK_QUALITY.scale': {
                  eq: true,
                },
              },
            },
            maxSize: 10000,
          },
          attachmentsIds: {
            type: 'array',
            maxSize: 10,
          },
        },
      },
      PRODUCTIVITY: {
        type: 'object',
        properties: {
          description: {
            type: 'string',
            required: {
              value: true,
              when: {
                '$feedbackGrades.PRODUCTIVITY.scale': {
                  eq: true,
                },
              },
            },
            maxSize: 10000,
          },
          attachmentsIds: {
            type: 'array',
            maxSize: 10,
          },
        },
      },
      CONTRIBUTING_PROCESS_IMPROVEMENT: {
        type: 'object',
        properties: {
          description: {
            type: 'string',
            required: {
              value: true,
              when: {
                '$feedbackGrades.CONTRIBUTING_PROCESS_IMPROVEMENT.scale': {
                  eq: true,
                },
              },
            },
            maxSize: 10000,
          },
          attachmentsIds: {
            type: 'array',
            maxSize: 10,
          },
        },
      },
    },
  },
};
