/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { bool, func } from 'prop-types';
// components
import RelatedList from 'shared/uibuilder/list/RelatedList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField, FullDateField, DateField } from 'shared/uibuilder/field';
import OpportunityStageField from 'crm/opportunity/shared/field/OpportunityStageField';
import OpportunityLinkField from 'crm/opportunity/shared/field/OpportunityLinkField';
import EditOpportunityButton from 'crm/opportunity/shared/button/EditOpportunityButton';
import AmountField from 'shared/uibuilder/field/AmountField';
import CreateButton from 'uibuilder/button/CreateButton';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { CREATE_OPPORTUNITY } from '../shared/opportunityService';

const RelatedOpportunitiesList = ({ getDataMethod, isActionsVisible }) => {
  const { data } = useShowContext();
  const accountName = data.getValueBySource('accountName');
  const { id } = useParams();
  const { getCreateUrl } = useOpportunityUrl();

  const actions = isActionsVisible
    ? [
        <CreateButton
          url={`${getCreateUrl({
            accountId: id,
            accountName,
          })}`}
          variant="contained"
          label="Create Opportunity"
          key="create-opportunity"
          permissionToCheck={CREATE_OPPORTUNITY}
        />,
      ]
    : [];

  return (
    <RelatedList getDataMethod={getDataMethod}>
      <RelationListLayout header="Opportunities" actions={actions}>
        <DataGridLayout buttons={[<EditOpportunityButton outline source="id" />]} isSmallTable showTotal={false}>
          <TextField source="id" label="Opportunity ID" />
          <OpportunityLinkField source="name" idSource="id" label="Opportunity Name" />
          <OpportunityStageField source="stage" label="Opportunity Stage" />
          <AmountField source="amount" label="Amount (USD)" />
          <DateField source="closeDate" label="Close Date" />
          <FullDateField source="createdAt" label="Created Date" />
          <FullDateField source="updatedAt" label="Last Modified Date" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

RelatedOpportunitiesList.propTypes = {
  getDataMethod: func.isRequired,
  isActionsVisible: bool,
};

RelatedOpportunitiesList.defaultProps = {
  isActionsVisible: false,
};

export default RelatedOpportunitiesList;
