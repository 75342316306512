/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import useLedgerService from 'financialAnalytic/ledger/useLedgerService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateJournalEntryButton from 'financialAnalytic/journalEntry/shared/button/CreateJournalEntryButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TableBodyWithGrouping from 'shared/uibuilder/list/layout/DataGridLayout/TableBodyWithGrouping';
import ViewLedgerButton from 'financialAnalytic/ledger/shared/button/ViewLedgerButton';
import BalanceField from 'financialAnalytic/ledger/shared/field/BalanceField';
import LedgerAccountField from 'financialAnalytic/ledger/shared/field/LedgerAccountField';
import './LedgerListPage.scss';
import ListPageHeaderWrapper from '../../shared/ListPageHeaderWrapper';

const LedgerListPage = () => {
  const { search } = useLedgerService();

  return (
    <List getDataMethod={search}>
      <ListPageLayout
        header={<ListPageHeaderWrapper header={<ListHeader label="Ledger" />} />}
        actions={[<CreateJournalEntryButton />]}
      >
        <DataGridLayout
          tableWrapperClassName="ledger-table-wrapper"
          isSmallTable
          buttons={[<ViewLedgerButton source="id" isVisible={(data: any) => !data.children?.length} />]}
          tableBodyComponent={props => (
            <TableBodyWithGrouping {...props} childrenSource="children" toggleSource="name" />
          )}
        >
          <LedgerAccountField source="name" label="Name" width="60%" />
          <BalanceField source="balance" nameSource="name" label="Balance" width="35%" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default LedgerListPage;
