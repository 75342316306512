/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps } from './dropdownHelper';

export const TIME_UNITS = [
  { value: 'DAYS', label: 'Days' },
  { value: 'WEEKS', label: 'Weeks' },
  { value: 'MONTHS', label: 'Months' },
  { value: 'YEARS', label: 'Years' },
];

const TimeUnitsDropdown = ({ excludedUnits, ...props }: DefaultDropdownProps & { excludedUnits?: string[] }) => {
  return (
    <Dropdown
      placeholder=""
      {...props}
      options={excludedUnits ? TIME_UNITS.filter(({ value }) => !excludedUnits.includes(value)) : TIME_UNITS}
    />
  );
};

export default TimeUnitsDropdown;
