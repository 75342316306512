import { useSelector, useDispatch } from 'react-redux';

export const SET_SETTING = 'SET_TODAY_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_SETTING,
  payload,
});

export interface SyberryTodaySettingState {
  areParentTasksVisible: boolean;
  areRecurrentlyTasksVisible: boolean;
  isSidebarMenuMinimized: boolean;
}

const initialState = {
  areParentTasksVisible: true,
  areRecurrentlyTasksVisible: true,
  isSidebarMenuMinimized: false,
};

export const syberryTodaySettingsReducer = (state = initialState, action: any) => {
  if (action.type === SET_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const useSyberryTodaySettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { syberryTodaySettings: SyberryTodaySettingState }) => state?.syberryTodaySettings,
  );
  const { areParentTasksVisible, areRecurrentlyTasksVisible, isSidebarMenuMinimized }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const setParentTasksVisible = (isChecked: boolean) => {
    updateSettings({
      areParentTasksVisible: isChecked,
    });
  };

  const setRecurrentlyTasksVisible = (isChecked: boolean) => {
    updateSettings({
      areRecurrentlyTasksVisible: isChecked,
    });
  };

  const setIsSidebarMenuMinimized = (isMinimized: boolean) => {
    updateSettings({
      isSidebarMenuMinimized: isMinimized,
    });
  };

  return {
    setParentTasksVisible,
    setRecurrentlyTasksVisible,
    areParentTasksVisible,
    areRecurrentlyTasksVisible,
    isSidebarMenuMinimized,
    setIsSidebarMenuMinimized,
  };
};

export default useSyberryTodaySettings;
