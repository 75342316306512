/* istanbul ignore file */
import React from 'react';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import EmployeeDropdownFilter from 'erp/employee/List/filter/EmployeeDropdownFilter';

const ResourceManagerFilter = (props: BaseFilterProps) => {
  const filter = {
    'specification:isResourceManager': {
      eq: true,
    },
  };

  return <EmployeeDropdownFilter filter={filter} {...props} />;
};

export default ResourceManagerFilter;
