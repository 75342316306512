/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateHiringBreadcrumbs from 'erp/recruitment/hiring/shared/CreateHiringBreadcrumbs';
import CreateHiringForm from 'erp/recruitment/hiring/createupdate/CreateHiringForm';
import { useHiringUrl } from 'erp/recruitment/hiring/HiringRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import { HIRING_REQUEST_READ } from 'erp/recruitment//hiring/useHiringService';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const OneStopHiringForm = () => {
  const { getListUrl: getListHiringUrl } = useHiringUrl();
  const { getListUrl: getVacancyListUrl } = useVacancyUrl();
  const { createOneStop, getValidationSchemaOneStop, getInitialData } = useVacancyService();
  const { isGranted } = useAuthorization();

  const isHasReadHiringRequest = isGranted(HIRING_REQUEST_READ);

  return (
    <CreateForm
      submitFormFunc={createOneStop}
      afterSubmit={{
        redirect: isHasReadHiringRequest ? getListHiringUrl() : getVacancyListUrl(),
        message: 'You are awesome! The Hiring request has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchemaOneStop}
      getDataFunc={() => getInitialData(true)}
    >
      <CreatePageLayout title="Create: Hiring Request" breadcrumbs={<CreateHiringBreadcrumbs />}>
        <CreateHiringForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default OneStopHiringForm;
