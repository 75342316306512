/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_LEDGER, LEDGER_PATH, READ_LEDGER_LIST } from 'financialAnalytic/ledger/useLedgerService';
import LedgerList from 'financialAnalytic/ledger/List';
import LedgerViewPage from 'financialAnalytic/ledger/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useLedgerRoute = () => useRoute({ listRoute: LEDGER_PATH });

export const useLedgerUrl = () =>
  useEntityUrl({
    baseLink: LEDGER_PATH,
  });

export const useLedgerId = () => useParams<Dictionary<string>>().id;

const LedgerRouter = () => {
  const { listRoute, singleEntityRoute } = useLedgerRoute();

  return (
    <ProtectedRouter basePath={LEDGER_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_LEDGER_LIST}>
          <LedgerList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_LEDGER}>
          <LedgerViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

LedgerRouter.getRouterPath = () => LEDGER_PATH;

export default LedgerRouter;
