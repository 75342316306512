import React, { useEffect, useState } from 'react';
import { Button } from 'uibuilder/button';
import ArrowIcon from '@mui/icons-material/ExpandLessOutlined';
import classnames from 'classnames';

import './ScrollToTop.scss';

const ScrollToTop = () => {
  const [isShown, setIsShown] = useState(false);

  const scrollHandler = () => {
    const scrollPosition: number = window.pageYOffset;
    const windowHeight: number = window.innerHeight;
    const shouldDisplayToTopButton = scrollPosition > windowHeight * 1.2;

    setIsShown(shouldDisplayToTopButton);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={classnames('to-top-btn', { 'to-top-btn--hidden': !isShown })}>
      <Button aria-label="Scroll to top" type="button" color="primary" size="small" onClick={scrollToTop}>
        <ArrowIcon />
      </Button>
    </div>
  );
};

export default ScrollToTop;
