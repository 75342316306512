/* istanbul ignore file */
import React from 'react';
import RadioButtonGroup, { RadioButtonGroupProps } from 'shared/uibuilder/form/input/RadioButtonGroup';

const GenderRadioButtonGroup = (props: RadioButtonGroupProps) => {
  return (
    <RadioButtonGroup
      {...props}
      options={[
        { value: 'MALE', label: 'Male' },
        { value: 'FEMALE', label: 'Female' },
        { value: 'OTHER', label: 'Unspecified' },
      ]}
    />
  );
};

export default GenderRadioButtonGroup;
