import { useKernelApi } from 'api';
import useAuthorization from 'shared/authorization/authorizationService';

export const GENERATE_CODE_NAME = 'CODE_NAME_GENERATE_CODE_NAME';

const useCodeNameService = () => {
  const { isGranted } = useAuthorization();
  const { sendPostRequest } = useKernelApi();

  const isGrantedToGenerateCodeName = () => isGranted(GENERATE_CODE_NAME);

  const generateCodeName = async (prevName: string) => {
    const response = await sendPostRequest(`/code-name/generate`, {
      name: prevName,
    });
    return response.json();
  };

  return {
    isGrantedToGenerateCodeName,
    generateCodeName,
  };
};

export default useCodeNameService;
