import React from 'react';
import { FiltersListLayoutProps } from '../index';
import './ListFiltersListLayout.scss';

const StaticFiltersListLayout = ({ filters, toggleFilter }: FiltersListLayoutProps) => {
  return (
    <>
      {filters
        .filter(item => !item.disabled)
        .map(({ label, element, id, source }) => {
          return (
            <div className="filter-item-wrapper" key={`filter${source}`}>
              {React.cloneElement(element, {
                onRemoveCallback: () => toggleFilter(id),
                label,
                source,
                key: source,
              })}
            </div>
          );
        })}
    </>
  );
};

export default StaticFiltersListLayout;
