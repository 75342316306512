/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { ASSET_CATEGORIES_TYPES } from 'financialAnalytic/assets/useAssetService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';

const AssetCategoryTypeDropdown = ({
  categorySource,
  source,
  ...props
}: DefaultInputPropTypes<string> & { categorySource: string }) => {
  const { getRawOnChangeCallback, getValue } = useInputHelper({ source });
  const { getValue: getCategory } = useInputHelper({ source: categorySource });
  const category = getCategory() as any;
  const options = ASSET_CATEGORIES_TYPES[category];
  const rawOnChangeCallback = getRawOnChangeCallback();
  const value = getValue();

  useDidUpdateEffect(() => {
    if (value && rawOnChangeCallback) {
      rawOnChangeCallback({ [source]: null });
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return <EnumDropdown options={options || {}} {...props} source={source} />;
};

export default AssetCategoryTypeDropdown;
