import React, { useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { Button } from 'uibuilder/button';
import './Layouts.scss';

interface EditableFieldLayoutProps {
  editFieldInput: React.ReactElement;
  fieldComponent: React.ReactElement;
  isUserCanChange: boolean;
  label: string;
  isRequired?: boolean;
  isEditing?: boolean;
  onCancel: () => void;
  onChangeCallback?: (e: object) => void;
  helpText?: string;
  needToClearAfterModeSwitch?: boolean;
}

const EditableFieldLayout = ({
  editFieldInput,
  fieldComponent,
  isUserCanChange,
  onChangeCallback,
  label,
  isRequired,
  onCancel,
  helpText,
  needToClearAfterModeSwitch,
  isEditing: initialIsEditing,
}: EditableFieldLayoutProps) => {
  const [isEditing, setEditing] = useState(initialIsEditing);

  const input = (
    <>
      <Button
        type="button"
        variant="text"
        onClick={() => {
          setEditing(false);
          if (onCancel) onCancel();
        }}
        size="small"
        className="editable-field-cancel"
      >
        cancel change
      </Button>
      {editFieldInput}
    </>
  );

  const field = (
    <>
      <FormLabel required={isRequired}>{label}</FormLabel>
      <div className="editable-field__field-wrapper">
        {fieldComponent}
        {isUserCanChange && (
          <Button
            type="button"
            variant="text"
            size="small"
            className="editable-field__change-button"
            onClick={() => {
              setEditing(true);

              if (needToClearAfterModeSwitch && onChangeCallback) {
                onChangeCallback({
                  target: {
                    value: null,
                  },
                });
              }
            }}
          >
            change
          </Button>
        )}
      </div>
    </>
  );

  const displayedComponent = isEditing ? input : field;

  return (
    <div className="editable-field__wrapper">
      {displayedComponent}
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </div>
  );
};

EditableFieldLayout.defaultProps = {
  isRequired: false,
  isEditing: false,
  onChangeCallback: () => {},
  needToClearAfterModeSwitch: true,
};

export default EditableFieldLayout;
