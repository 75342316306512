/* istanbul ignore file */
import React from 'react';
import VacanciesBreadcrumbs from 'erp/recruitment/vacancy/shared/VacanciesBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import { useParams } from 'react-router-dom';
import { arrayOf, element, oneOfType } from 'prop-types';

const SingleVacanciesBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useVacancyUrl();
  // @ts-ignore
  const { id } = useParams();

  return (
    <VacanciesBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </VacanciesBreadcrumbs>
  );
};

SingleVacanciesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleVacanciesBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleVacanciesBreadcrumbs;
