/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { OPPORTUNITY_STAGES } from '../opportunityService';

const OpportunityStageField = ({ value: inputValue, source, label }) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || (getValue && getValue(source));

  return <EnumField label={label} value={value} options={OPPORTUNITY_STAGES} />;
};

OpportunityStageField.propTypes = {
  value: string,
  source: string,
  label: string,
};

OpportunityStageField.defaultProps = {
  value: null,
  source: null,
  label: null,
};

export default OpportunityStageField;
