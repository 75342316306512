import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledSwitch } from 'uibuilder/layout/form/input/TogglerInputLayout';
import usePayrollReportSettings from 'erp/payroll/generatedPayrolls/shared/Settings/usePayrollReportSettings';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const PayrollReportStateToggler = () => {
  const { setIsLatestReportsVisibleOnly, isLatestReportsVisibleOnly } = usePayrollReportSettings();
  const { loading } = useListContext();

  const onChangeCallback = (event: any) => {
    setIsLatestReportsVisibleOnly(event.target.checked);
  };

  return (
    <FormControlLabel
      className="settings__item"
      control={
        <StyledSwitch
          className="settings__switch"
          color="primary"
          onChange={onChangeCallback}
          checked={isLatestReportsVisibleOnly}
          dataOn="Yes"
          dataOff="No"
          disabled={loading}
        />
      }
      label="Show only the latest payroll reports"
    />
  );
};

export default PayrollReportStateToggler;
