import React from 'react';
import { useRoomUrl } from 'erp/room/RoomRouter';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_ROOM } from 'erp/room/useRoomService';

const CreateRoomButton = () => {
  const { getCreateUrl } = useRoomUrl();

  return <CreateButton url={getCreateUrl()} label="Create Room" permissionToCheck={CREATE_ROOM} />;
};

export default CreateRoomButton;
