/* istanbul ignore file */
import React from 'react';
import useEmployeeOfferService from 'erp/employee/offers/shared/employeeOfferService';
import { useEmployeeOfferId, useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import SensitiveUpdatePageLayout from 'shared/uibuilder/sensitiveData/SensitiveUpdatePageLayout';
import EmployeeOfferForm from 'erp/employee/offers/createupdate/EmployeeOfferForm';
import UpdateEmployeeOfferBreadcrumbs from 'erp/employee/offers/createupdate/Update/UpdateBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';

const UpdateEmployeeOffer = () => {
  const { update, getById, getValidationSchema } = useEmployeeOfferService();
  const offerId = useEmployeeOfferId();
  const { getListUrl, getSingleEntityUrl } = useEmployeeOfferUrl();
  const listUrl = getListUrl();
  const updatedEntityUrl = getSingleEntityUrl(offerId);

  return (
    <UpdateForm
      getDataFunc={() => getById(offerId)}
      submitFormFunc={update}
      afterSubmit={{
        redirect: updatedEntityUrl,
        message: 'You are awesome! The Offer has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      entityId={offerId}
    >
      <SensitiveUpdatePageLayout
        title="Edit: Offer"
        breadcrumbs={<UpdateEmployeeOfferBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
        timerId={listUrl}
        actions={[]}
      >
        <EmployeeOfferForm />
      </SensitiveUpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateEmployeeOffer;
