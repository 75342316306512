/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { InputData } from 'shared/uibuilder/list/builder/useListCrud';
import { useEmployeeCommonSkillsService } from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';

const useEmployeeCommonSkillsShowHelper = () => {
  const { findAllEmployeeCommSkills } = useEmployeeCommonSkillsService();
  const [data, setData] = useState({} as InputData);
  const employeeId = useEmployeeId() || '';

  useEffect(() => {
    findAllEmployeeCommSkills().then((result: any) => {
      setData({ ...result, items: result.result });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, findAllEmployeeCommSkills]);

  return {
    currentData: data,
  };
};

export default useEmployeeCommonSkillsShowHelper;
