/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { PAYROLL_REPORT_STATUSES_OPTIONS } from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';

const PayrollReportsStatusField = (props: FieldProps) => {
  return <EnumField options={PAYROLL_REPORT_STATUSES_OPTIONS} {...props} />;
};

export default PayrollReportsStatusField;
