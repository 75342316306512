/* istanbul ignore file */
import React from 'react';

const DateTimeDisplay = ({ value, type, hasMinus }: { value: number; type: string; hasMinus?: boolean }) => {
  return (
    <div className="countdown-item">
      <p className="countdown-item__value">
        {hasMinus && <span>-</span>}
        {value < 10 ? `0${value}` : value}
      </p>
      <span className="countdown-item__label">{type}</span>
    </div>
  );
};

export default DateTimeDisplay;
