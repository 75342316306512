/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeAssignmentsBreadCrumbs from 'erp/employee/assignments/shared/EmployeeAssignmentsBreadcrumbs';
import EmployeeAssignmentsList from 'erp/employee/assignments/List/EmployeeAssignmentsList';

const EmployeeAssignmentsListPage = () => {
  return (
    <ShowPageLayout
      menu={<EmployeeMenu />}
      entityHeader={<EmployeeEntityHeader />}
      breadcrumbs={<EmployeeAssignmentsBreadCrumbs />}
    >
      <EmployeeAssignmentsList />
    </ShowPageLayout>
  );
};

export default EmployeeAssignmentsListPage;
