/* istanbul ignore file */
// libs
import React from 'react';
import { string, bool } from 'prop-types';
// components
import TextField from 'shared/uibuilder/field/TextField';
// services
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_GRADE } from 'crm/lead/shared/leadService';
// styles
import './LeadGradeField.scss';

const LeadGradeField = ({ source, value: inputValue, isFull }) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  const displayValue = value && (
    <span className={`lead-grade lead-grade--${LEAD_GRADE[value].cssModificator}`}>
      {isFull ? `${LEAD_GRADE[value].shorthand} (${LEAD_GRADE[value].name})` : LEAD_GRADE[value].shorthand}
    </span>
  );

  return <TextField value={displayValue} label="Lead Grade" />;
};

LeadGradeField.propTypes = {
  source: string,
  value: string,
  isFull: bool,
};

LeadGradeField.defaultProps = {
  value: null,
  source: null,
  isFull: false,
};

export default LeadGradeField;
