import React from 'react';
import useCorporateEventService from 'financialAnalytic/corporateEvents/useCorporateEventService';
import { useCorporateEventId } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import Show from 'shared/uibuilder/show/Show';
import ViewCorporateEventApplicationsPage from 'financialAnalytic/corporateEvents/Show/applications/ViewCorporateEventApplicationsPage';

const CorporateEventApplicationsViewPage = () => {
  const { getById } = useCorporateEventService();
  const id = useCorporateEventId();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewCorporateEventApplicationsPage />
    </Show>
  );
};

export default CorporateEventApplicationsViewPage;
