/* istanbul ignore file */
import React from 'react';
import SendEmailHeader from 'sendEmail/shared/SendEmailHeader';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const UpdateSendEmailPageLayout = ({ children }: { children: React.ReactElement }) => {
  const { data } = useFormContext();
  const { taskName } = data || {};

  return (
    <UpdatePageLayout title="Edit: Email" breadcrumbs={<SendEmailHeader taskName={taskName} />}>
      {children}
    </UpdatePageLayout>
  );
};

export default UpdateSendEmailPageLayout;
