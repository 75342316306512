const getCriteriaValidation = (
  performanceCriteriaSource: string,
  evaluationGradeSource: string,
  oldModelSource?: string,
  oldModelValue?: boolean,
) => ({
  type: 'object',
  required: oldModelSource
    ? {
        value: true,
        when: {
          [performanceCriteriaSource]: {
            ne: null,
          },
          [oldModelSource]: {
            eq: oldModelValue,
          },
        },
      }
    : {
        value: true,
        when: {
          [performanceCriteriaSource]: {
            ne: null,
          },
        },
      },
  properties: {
    evaluationGrade: {
      type: 'enum',
      required: oldModelSource
        ? {
            value: true,
            when: {
              [performanceCriteriaSource]: {
                ne: null,
              },
              [oldModelSource]: {
                eq: oldModelValue,
              },
            },
          }
        : {
            value: true,
            when: {
              [performanceCriteriaSource]: {
                ne: null,
              },
            },
          },
    },
    confidenceGrade: {
      type: 'enum',
      required: {
        value: true,
        when: {
          [evaluationGradeSource]: {
            in: ['A', 'B', 'C'],
          },
        },
      },
    },
    positiveDetails: {
      type: 'string',
      required: false,
    },
    negativeDetails: {
      type: 'string',
      required: false,
    },
  },
});

const isPerformanceCriteriaRequired = (performanceCriteriaSource: string) => {
  return performanceCriteriaSource
    ? {
        value: true,
        when: {
          [performanceCriteriaSource]: {
            ne: null,
          },
        },
      }
    : false;
};

const isPerformanceLevelCriteriaRequired = (performanceLevelCriteriaSource: string) => {
  return performanceLevelCriteriaSource
    ? {
        value: true,
        when: {
          [performanceLevelCriteriaSource]: {
            ne: null,
          },
        },
      }
    : false;
};

export default {
  relatedProcessId: {
    type: 'enum',
    required: true,
  },
  performanceLevelCriteria: {
    type: 'object',
    required: isPerformanceLevelCriteriaRequired('$performanceLevelCriteria'),
    properties: {
      helpfulnessGrade: {
        type: 'string',
        required: isPerformanceLevelCriteriaRequired('$performanceLevelCriteria'),
      },
    },
  },
  performanceCriteria: {
    type: 'object',
    required: isPerformanceCriteriaRequired('$performanceCriteria'),
    properties: {
      culture: getCriteriaValidation('$performanceCriteria', '$performanceCriteria.culture.evaluationGrade'),
      processFollowing: getCriteriaValidation(
        '$performanceCriteria',
        '$performanceCriteria.processFollowing.evaluationGrade',
      ),
      productivity: getCriteriaValidation('$performanceCriteria', '$performanceCriteria.productivity.evaluationGrade'),
      businessOutcomes: getCriteriaValidation(
        '$performanceCriteria',
        '$performanceCriteria.businessOutcomes.evaluationGrade',
        '$performanceCriteria.oldModel',
        false,
      ),
      workQuality: getCriteriaValidation(
        '$performanceCriteria',
        '$performanceCriteria.workQuality.evaluationGrade',
        '$performanceCriteria.oldModel',
        true,
      ),
      processImprovement: getCriteriaValidation(
        '$performanceCriteria',
        '$performanceCriteria.processImprovement.evaluationGrade',
        '$performanceCriteria.oldModel',
        true,
      ),
    },
  },
};
