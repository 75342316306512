/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import DailyCheckinStepper from 'syberryToday/shared/DailyCheckinStepper';
import List from 'shared/uibuilder/list/List';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import { GetDataMethodType } from 'shared/uibuilder/list/builder/useListDataLoading';
import { Filter } from 'shared/uibuilder/list/filter/FilterContext';

const DailyCheckinListLayout = ({
  getDataMethod,
  defaultFilter,
  children,
  hasStepper = true,
  className,
  actions,
}: {
  children: React.ReactNode;
  getDataMethod: GetDataMethodType;
  defaultFilter?: Filter;
  hasStepper?: boolean;
  className?: string;
  actions?: React.ReactElement[];
}) => {
  return (
    <List getDataMethod={getDataMethod} uncontrolledFilters={['projectId']} defaultFilter={defaultFilter}>
      <ListPageLayout actions={actions}>
        <div className="daily-checkin__wrapper">
          {hasStepper && <DailyCheckinStepper />}
          <div className={classnames('daily-checkin__content', className)}>{children}</div>
        </div>
      </ListPageLayout>
    </List>
  );
};

export default DailyCheckinListLayout;
