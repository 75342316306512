/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useEmployeePerformanceUrl } from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';

const EditEmployeePerformanceButton = (props: any) => {
  const { getUpdateEntityUrl } = useEmployeePerformanceUrl();

  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditEmployeePerformanceButton;
