/* istanbul ignore file */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useCustomerInvoiceService, {
  CUSTOMER_INVOICE_STATUSES,
  MARK_AS_PAID_CUSTOMER_INVOICE,
} from '../../useCustomerInvoiceService';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';

const MarkAsPaidCustomerInvoiceButton = ({
  statusSource,
  isVisible,
}: {
  statusSource: string;
  isVisible?: (data: any) => boolean;
}) => {
  const { id } = useParams<Dictionary<string>>();
  const { maskAsPaidCustomerInvoice, invalidateCache } = useCustomerInvoiceService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();
  const [payoutDateErrors, setPayoutDateErrors] = useState<string[]>([]);
  const { getToday, formatDateForAPI } = useDateService();
  const [payoutDate, setPayoutDate] = useState(getToday());

  const [bankTransactionId, setBankTransactionId] = useState<string>('');
  const { setData = () => {} } = useShowContext();

  if (status !== CUSTOMER_INVOICE_STATUSES.PENDING) {
    return null;
  }

  const onPayoutDateChange = (values: FormFieldsData) => {
    setPayoutDate(values.payoutDate);
    const isValid = moment(values.payoutDate, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.payoutDate) {
      setPayoutDateErrors(['Field is required']);
    } else if (!isValid) {
      setPayoutDateErrors(['Please, choose the correct date']);
    } else {
      setPayoutDateErrors([]);
    }
  };

  const onBankTransactionIdCallback = (values: FormFieldsData) => {
    setBankTransactionId(values.bankTransactionId);
  };

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to mark this Customer invoice as paid? Please fill out the inputs below."
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="payoutDate"
            value={payoutDate as any}
            label="Payout date"
            onChangeCallback={onPayoutDateChange}
            errorMessages={payoutDateErrors}
            isRequired
          />
          <TextInput
            source="bankTransactionId"
            label="Bank Transaction ID"
            value={bankTransactionId}
            onChangeCallback={onBankTransactionIdCallback}
          />
        </div>
      }
      submitMethod={() =>
        maskAsPaidCustomerInvoice(id, {
          invoicePaidDate: formatDateForAPI(payoutDate),
          bankTransactionId,
        })
      }
      afterSubmit={{
        successMessage: 'The Customer invoice has been successfully marked as paid.',
        errorMessage: "Can't mark Customer invoice as paid.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={[MARK_AS_PAID_CUSTOMER_INVOICE]}
      variant="outlined"
      confirmBtnProps={{
        disabled: !payoutDate || payoutDateErrors.length > 0,
      }}
    >
      Mark as paid
    </ButtonWithConfirmation>
  );
};

export default MarkAsPaidCustomerInvoiceButton;
