import React from 'react';
import RoomBreadcrumbs from 'erp/room/shared/RoomBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateRoomBreadcrumbs = () => {
  return (
    <RoomBreadcrumbs>
      <UpdateBreadcrumbItem entity="Room" />
    </RoomBreadcrumbs>
  );
};

export default UpdateRoomBreadcrumbs;
