/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import {
  OPPORTUNITY_STAGES_ALLOWED_TRANSITIONS,
  isCurrentOpportunityStage,
  isCompletedOpportunityStage,
} from 'crm/opportunity/shared/opportunityService';
import DateField from 'shared/uibuilder/field/DateField';
import CountDownTimer from 'shared/uibuilder/CountDownTimer';
import TextField from 'shared/uibuilder/field/TextField';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import GuidanceForSuccessField from 'crm/opportunity/shared/OpportunityPipeline/GuidanceForSuccessField';
import MarkStateAsCompletedButton from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton';
import MarkAsCurrentStateButton from 'crm/opportunity/shared/OpportunityPipeline/button/MarkAsCurrentStateButton';
import OpportunityPipelineFieldLayout from 'crm/opportunity/shared/OpportunityPipeline/OpportunityPipelineFieldLayout';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

const OpportunityPipelineDescription = ({
  currentStep,
  activeStepData,
  handleStep,
  isPipelineFailed,
  isPostponed,
}: {
  currentStep: string;
  activeStepData: Dictionary<any>;
  handleStep: (step: string) => void;
  isPipelineFailed: boolean;
  isPostponed: boolean;
}) => {
  const { stepName: activeStep, startedAt, plannedFinishedAt, actualFinishedAt, stateStatus } = activeStepData;
  const currentStepAllowedTransitions = OPPORTUNITY_STAGES_ALLOWED_TRANSITIONS[currentStep] || [];
  const getInProgressValue = () => {
    if (isPostponed && currentStep === activeStep) {
      return <PauseCircleOutlineIcon color="warning" />;
    }
    return startedAt ? (
      <CountDownTimer
        targetDate={startedAt}
        shouldCountFromTargetDate
        isExpired={!moment(plannedFinishedAt).isAfter(moment())}
        showMonths={false}
        stopDatetime={actualFinishedAt}
      />
    ) : null;
  };

  return (
    <UiThemeOverrider
      overriddenLayouts={{
        BaseFieldLayout: OpportunityPipelineFieldLayout,
      }}
    >
      <div className="step-description__wrapper">
        <GuidanceForSuccessField activeStep={activeStep} />
        <div className="step-description__attributes">
          <div className="step-description__item">
            <DateField source="startedAt" value={startedAt} label="Start date" />
          </div>
          <div className="step-description__item">
            <DateField
              source="plannedFinishedAt"
              value={plannedFinishedAt}
              label="Planned date of transition to the next stage"
            />
          </div>
          <div className="step-description__item">
            <TextField label="In Progress" source="startedAt" value={getInProgressValue()} />
          </div>
          {isCurrentOpportunityStage(stateStatus) && !isPostponed ? (
            <MarkStateAsCompletedButton activeStep={activeStep} handleStep={handleStep} />
          ) : null}
          {!isCompletedOpportunityStage(stateStatus) &&
          !isPostponed &&
          !isPipelineFailed &&
          currentStepAllowedTransitions.includes(activeStep) ? (
            <MarkAsCurrentStateButton activeStep={activeStep} />
          ) : null}
        </div>
      </div>
    </UiThemeOverrider>
  );
};

export default OpportunityPipelineDescription;
