/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useEmployeeDomainsService } from 'erp/employee/domains/shared/employeeDomainsService';
import { InputData } from 'shared/uibuilder/list/builder/useListCrud';

const useEmployeeDomainsShowHelper = () => {
  const { findAllEmployeeDomains } = useEmployeeDomainsService();
  const [data, setData] = useState({} as InputData);
  const employeeId = useEmployeeId() || '';

  useEffect(() => {
    findAllEmployeeDomains().then((result: any) => {
      setData({ ...result, items: result.result });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, findAllEmployeeDomains]);

  return {
    currentData: data,
  };
};

export default useEmployeeDomainsShowHelper;
