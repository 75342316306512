import React from 'react';
import Select from 'react-select/async';
import DropDownFilterInputOption from '../DropDownFilterInputOption';
import { getCommonInputProps } from 'uibuilder/layout/form/input/BaseInputLayout';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import { selectStyles as dropDownSelectStyles } from 'shared/uibuilder/list/filter/layout/ListFilterLayout/filter/DropDownFilterInputLayout/SelectLayout';
import { MultiSelectInputLayoutProps } from 'shared/uibuilder/form/input/MultiSelectInput';

interface SearchDropdownInputLayoutProps extends MultiSelectInputLayoutProps {
  setIsLoading: (value: boolean) => void;
}

const MultipleDropdownSearchLayout = ({
  label,
  onChangeCallback = () => {},
  noOptionsMessage: parentNoOptionsMessage = () => null,
  value: selectedValue = [],
  source,
  onLoadOptions,
  setIsLoading,
  ...defaultLayoutProps
}: SearchDropdownInputLayoutProps) => {
  const selectStyles = {
    ...dropDownSelectStyles,
    noOptionsMessage: (provided: CSSStyleSheet) => ({ ...provided, padding: '0 13px 10px' }),
    loadingMessage: (provided: CSSStyleSheet) => ({ ...provided, padding: '0 13px 10px' }),
    control: (provided: CSSStyleSheet) => ({ ...provided, border: '0', boxShadow: 'none', paddingLeft: '42px' }),
    valueContainer: (provided: CSSStyleSheet) => ({ ...provided, paddingLeft: '0', paddingRight: '15px' }),
  };

  const getNoOptionsMessage = ({ inputValue }: Dictionary<string>) =>
    inputValue.length ? parentNoOptionsMessage({ inputValue }) : null;

  const handleLoadOptions = (inputValue: object, callback: (options: Nullable<Option[]>) => void) => {
    setIsLoading(true);
    onLoadOptions(inputValue, callback);
  };

  const handleChangeValue = (value: Option[]) => {
    onChangeCallback({ target: { value } });
    setIsLoading(false);
  };

  return (
    <Select
      {...getCommonInputProps({ ...defaultLayoutProps, source }, false)}
      autoFocus
      backspaceRemovesValue={false}
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Option: (optionProps: any) => <DropDownFilterInputOption {...optionProps} isMulti={false} />,
      }}
      controlShouldRenderValue={false}
      isClearable={false}
      styles={selectStyles as any}
      tabSelectsValue={false}
      isMulti
      value={selectedValue}
      loadOptions={handleLoadOptions as any}
      onChange={handleChangeValue as any}
      onInputChange={(value: string) => {
        setIsLoading(!!value);
      }}
      noOptionsMessage={getNoOptionsMessage}
      menuIsOpen
      placeholder={`Search ${label}`}
    />
  );
};

export default MultipleDropdownSearchLayout;
