/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import azureIcon from 'authentication/OneClick/images/azure.png';
import { useAuthenticationUrl } from 'authentication/AuthenticationRouter';

const RedirectButton = ({ className }: { className: string }) => {
  const { getAzureAuthUrl } = useAuthenticationUrl();

  const redirectToAzureLoginUrl = () => {
    window.location.assign(getAzureAuthUrl());
  };

  return (
    <Button
      key="oneclick-button"
      outline
      onClick={redirectToAzureLoginUrl}
      className={className}
      sx={{ paddingRight: '20px', fontSize: '14px' }}
      endIcon={<img src={azureIcon} alt="" width={20} height={20} />}
    >
      Enter credentials instead
    </Button>
  );
};

export default RedirectButton;
