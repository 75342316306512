import React from 'react';
import List from '../../../shared/uibuilder/list/List';
import useCustomerInvoiceService from '../useCustomerInvoiceService';
import CustomerInvoiceListContent from './CustomerInvoiceListContent';

const CustomerInvoiceListPage = () => {
  const { search } = useCustomerInvoiceService();

  return (
    <List getDataMethod={search} defaultFilter={{}}>
      <CustomerInvoiceListContent />
    </List>
  );
};

export default CustomerInvoiceListPage;
