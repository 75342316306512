import { useLocation } from 'react-router';
import { useListLocalStorageStoring, useListUrlStoring } from 'shared/uibuilder/list/builder';
import { ListParams } from 'shared/uibuilder/list/ListContext';

interface ListMigrationVersion {
  version: string;
  migration: ListParams;
}

const useListParamsMigration = (migrationsArr: ListMigrationVersion[], customPath?: string) => {
  const { pathname } = useLocation();
  const { updateParamsInLocalStorage } = useListLocalStorageStoring({ localStorageKey: customPath });
  const { updateParamsInUrl } = useListUrlStoring();

  const listParamsKey = customPath || pathname;
  const migrationKey = 'listParamsMigration';

  let isMigrated = false;

  const migrate = () => {
    const migrations = JSON.parse(localStorage.getItem(migrationKey) ?? '{}');
    const currentListMigrations = migrations[listParamsKey] || [];
    let isParamsUpdated = false;

    migrationsArr.forEach(({ version, migration: newParams }) => {
      if (!currentListMigrations.includes(version)) {
        updateParamsInLocalStorage(newParams);
        updateParamsInUrl({ ...newParams, page: 0 });
        isParamsUpdated = true;
      }
    });

    if (isParamsUpdated) {
      localStorage.setItem(
        migrationKey,
        JSON.stringify({
          ...migrations,
          [listParamsKey]: migrationsArr.map(({ version }) => version),
        }),
      );
    }

    isMigrated = true;
  };

  if (!isMigrated) migrate();
};

export default useListParamsMigration;
