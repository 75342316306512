/* istanbul ignore file */
import React from 'react';
import TrainingInstancesBreadcrumbs from '../../shared/TrainingInstancesBreadcrumbs';
import UpdateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateTrainingInstanceBreadcrumbs = () => {
  return (
    <TrainingInstancesBreadcrumbs>
      <UpdateBreadcrumbItem entity="Training instance" />
    </TrainingInstancesBreadcrumbs>
  );
};

export default UpdateTrainingInstanceBreadcrumbs;
