/* istanbul ignore file */
import React from 'react';
import useEmployeeSalaryService from 'erp/employee/salaries/shared/salaryService';
import { useEmployeeSalaryId, useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import { SensitiveUpdatePageLayout } from 'shared/uibuilder/sensitiveData';
import UpdateSalaryBreadcrumbs from 'erp/employee/salaries/createupdate/Update/UpdateBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import SalaryForm from 'erp/employee/salaries/createupdate/SalaryForm';

const UpdateSalaryPage = () => {
  const { update, getById, getValidationSchema } = useEmployeeSalaryService();
  const salaryId = useEmployeeSalaryId();
  const { getListUrl, getSingleEntityUrl } = useEmployeeSalaryUrl();
  const listUrl = getListUrl();
  const updatedEntityUrl = getSingleEntityUrl(salaryId);

  return (
    <UpdateForm
      getDataFunc={() => getById(salaryId)}
      submitFormFunc={update}
      afterSubmit={{
        redirect: updatedEntityUrl,
        message: 'You are awesome! The Salary has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      entityId={salaryId}
    >
      <SensitiveUpdatePageLayout
        title="Edit: Salary"
        breadcrumbs={<UpdateSalaryBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
        timerId={listUrl}
        actions={[]}
      >
        <SalaryForm />
      </SensitiveUpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateSalaryPage;
