/* istanbul ignore file */
import React, { useEffect } from 'react';
import Sticky from 'react-sticky-el';
import useHandbookMenuService from './handbookMenuService';
import Scrollbars from 'uibuilder/Scrollbars';
import 'shared/uibuilder/menu/SidebarMenu.scss';
import './HandbookMenu.scss';

export const MAX_SM_WIDTH = 992;

const HandbookMenu = ({ tableContent }: any) => {
  const { content, loading, setTableContent } = useHandbookMenuService();

  useEffect(() => {
    setTableContent(tableContent);
    // Suppressed warnings because we only need to call useEffect callback after updating tableContent.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  return loading ? null : (
    <div className="handbook-pages nav-tabs mt-0 pt-0">
      <Sticky
        stickyClassName="sticky-menu"
        disabled={window.innerWidth < MAX_SM_WIDTH}
        hideOnBoundaryHit={false}
        boundaryElement=".handbook-content"
        bottomOffset={100}
        topOffset={-103}
      >
        {content ? (
          <Scrollbars maxHeight="" className="handbook-scrollbars">
            <div className="tabs-container">{content}</div>
          </Scrollbars>
        ) : null}
      </Sticky>
    </div>
  );
};

export default HandbookMenu;
