/* istanbul ignore file */
import React from 'react';
import WysiwygInputPasteArtifactDecorator from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import { ARTIFACT_ID } from 'artifact/const';
import { string } from 'prop-types';

const TimelineNoteWysiwygInput = ({ artifactsSource, ...props }) => {
  return (
    <WysiwygInputPasteArtifactDecorator artifactsSource={artifactsSource} artifactTypeId={ARTIFACT_ID.NOTE_ATTACHMENT}>
      <WysiwygInput {...props} />
    </WysiwygInputPasteArtifactDecorator>
  );
};

TimelineNoteWysiwygInput.propTypes = {
  artifactsSource: string.isRequired,
};

export default TimelineNoteWysiwygInput;
