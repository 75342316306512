/* istanbul ignore file */
import React from 'react';
import SingleCandidateBreadcrumbs from 'erp/candidate/shared/SingleCandidateBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CandidateApplicationListBreadcrumbs = () => {
  return (
    <SingleCandidateBreadcrumbs>
      <ListBreadcrumbItem entity="Applications" />
    </SingleCandidateBreadcrumbs>
  );
};

export default CandidateApplicationListBreadcrumbs;
