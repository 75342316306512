import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field/index';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import './formattedDateField.scss';

const FormattedDateField = ({ format = DATE_FORMAT.SIMPLE, noWrap = false, ...props }: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  return (
    <TextField
      label={getLabel()}
      value={
        getValue() ? (
          <ShowDate
            dateUTC={getValue()}
            format={format}
            isDateTime={false}
            className={noWrap ? 'formatted-date-field' : ''}
          />
        ) : null
      }
    />
  );
};

export default FormattedDateField;
