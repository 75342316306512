// services
import { useKernelApi } from 'api';

// constants
export const COMMON_SKILL_CREATE = 'COMMON_SKILL_CREATE';

export const useCommonSkillService = () => {
  const { sendPostRequest } = useKernelApi();

  const findAllCommonSkills = async (request: any) => {
    const response = await sendPostRequest('/common-skills/search', request);
    const json = await response.json();

    return json;
  };

  const createCommonSkill = async (request: any) => {
    const response = await sendPostRequest('/common-skills', request);
    const json = await response.json();

    return json;
  };

  return {
    findAllCommonSkills,
    createCommonSkill,
  };
};
