/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { getSourceOptions } from 'erp/candidate/applications/shared/candidateApplicationService';
import { DEFAULT_INPUT_PROP_TYPES } from 'shared/uibuilder/form/input/inputHelper';
import { string } from 'prop-types';

const CandidateApplicationSourceDropdown = ({ label: labelInput, ...props }) => {
  return (
    <Dropdown
      placeholder="Choose Candidate Application Source"
      options={Object.entries(getSourceOptions()).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label={labelInput}
      {...props}
    />
  );
};

CandidateApplicationSourceDropdown.propTypes = {
  ...DEFAULT_INPUT_PROP_TYPES,
  label: string,
};

CandidateApplicationSourceDropdown.defaultProps = {
  label: 'Candidate Application Source',
};

export default CandidateApplicationSourceDropdown;
