/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button';
import useAuthorization from 'shared/authorization/authorizationService';
import classnames from 'classnames';
import { isSafari, isMobile } from 'shared/uibuilder/helper';
import MoreIcon from '@mui/icons-material/MoreVertOutlined';
import './TreeActions.scss';

interface TreeActionsProps {
  buttons?: React.ReactNode;
  permissionToCheck?: Nullable<string | string[]>;
  isVisible?: boolean;
}

const TreeActions = ({ buttons, permissionToCheck, isVisible = true }: TreeActionsProps) => {
  const { isGranted } = useAuthorization();
  const hasPermission = !permissionToCheck || isGranted(permissionToCheck);

  if (hasPermission && isVisible && buttons) {
    return (
      <div
        className={classnames('tree-actions-wrapper', { 'tree-actions-wrapper--chrome': !isMobile() && !isSafari() })}
      >
        <DropdownMenu
          popupId="tree-actions"
          iconButton={
            <IconButton
              disableRipple
              onClick={e => e.stopPropagation()}
              size="small"
              className={classnames('actions-dropdown', { 'actions-dropdown--safari': isSafari() })}
            >
              <MoreIcon />
            </IconButton>
          }
          mouseEvent="onPointerDown"
          touchEvent="onTouchStart"
          disablePortal={!isMobile() && !isSafari()}
          className="tree-actions-menu"
          menuPlacement="bottom-end"
        >
          {
            React.Children.map(buttons, button => (
              <MenuItem onClick={e => e.stopPropagation()}>{button}</MenuItem>
            )) as any
          }
        </DropdownMenu>
      </div>
    );
  }

  return null;
};

export default TreeActions;
