/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EnumField from 'shared/uibuilder/field/EnumField';
import { LEAD_POSTPONED_REASON } from 'crm/lead/shared/leadService';

interface PostponedReasonFieldProps {
  inputValue?: string;
  source: string;
}

const PostponedReasonField = ({ inputValue, source }: PostponedReasonFieldProps) => {
  const { data } = useShowContext();
  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Postponed Reason" options={LEAD_POSTPONED_REASON} value={value} />;
};

export default PostponedReasonField;
