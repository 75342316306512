import React from 'react';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListPageHeaderWrapper from 'financialAnalytic/shared/ListPageHeaderWrapper';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TextField from 'shared/uibuilder/field/TextField';
import { BadgeField } from 'shared/uibuilder/field';
import HiringStatusField from '../shared/fields/HiringStatusField';
import DateField from 'shared/uibuilder/field/DateField';
import EnumField from 'shared/uibuilder/field/EnumField';
import useHiringService, { HIRING_MODE } from '../useHiringService';
import ViewHiringButton from '../shared/button/ViewHiringButton';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import BooleanFilter from 'shared/uibuilder/list/filter/BooleanFilter';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import AsapTargetField from '../shared/fields/AsapTargetField';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import PositionsListFields from '../shared/fields/PositionsListFields';
import TableBodyWithGrouping from 'shared/uibuilder/list/layout/DataGridLayout/TableBodyWithGrouping';
import VacanciesFiled from '../shared/fields/VacanciesFiled';
import ListField from 'shared/uibuilder/field/ListField';
import Typography from 'uibuilder/Typography';

const COUNT_TABLE_TD = 9;

const HiringListContent = () => {
  const { getFilterValidationSchema } = useHiringService();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout quickFilters={[<QuickFilter label="All Asap" state={{ isAsap: { eq: 'YES' } }} />]}>
        <DateRangeFilter canBeInFuture label="Target date" source="targetDate" />
        <DateRangeFilter canBeInFuture label="Expiration Date" source="expirationDate" />
        <BooleanFilter source="isAsap" label="Asap" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ListPageLayout
      filter={filters}
      search={<SearchInput label="specialisation" />}
      header={<ListPageHeaderWrapper header={<ListHeader label="Hiring requests" />} />}
    >
      <DataGridLayout
        buttons={[<ViewHiringButton source="id" />]}
        tableBodyComponent={props => (
          <TableBodyWithGrouping
            {...props}
            childrenSource="requestPositions"
            toggleSource="specialisation"
            CustomChildTemplate={
              <tr>
                <td colSpan={COUNT_TABLE_TD}>
                  <Typography variant="h3" className="c-title" sx={{ mb: '20px', mt: '10px' }}>
                    Positions
                  </Typography>
                  <PositionsListFields isShowButton={false} source="requestPositions" />
                </td>
              </tr>
            }
          />
        )}
      >
        <TextField label="Specialisation" source="specialisation" width="15%" />
        <EmployeeLinkFieldWithNameLoading source="createdBy" label="Author" width="10%" />
        <EmployeeLinkFieldWithNameLoading source="owner" label="DRI" width="10%" />
        <BadgeField
          badges={[<HiringStatusField source="status" label="Status" isSortable />]}
          label="Status"
          width="10%"
        />
        <AsapTargetField label="Asap/Target date" asapSource="isAsap" targetSource="targetDate" width="10%" />
        <DateField label="Expiration date" isSortable source="expirationDate" width="10%" />
        <EnumField label="Hiring mode" source="hiringMode" options={HIRING_MODE} width="10%" />
        <ListField source="vacancies" label="Vacancies" width="15%">
          <VacanciesFiled />
        </ListField>
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default HiringListContent;
