/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import NewSpecializationDropdown from 'erp/employee/qualification/List/input/NewSpecializationDropdown';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import useEmployeeSpecializationService from 'erp/employee/qualification/shared/employeeSpecializationService';

interface AddQualificationFormProps {
  closeModal: () => void;
  modalOpen: boolean;
}

const AddQualificationForm = ({ closeModal, modalOpen }: AddQualificationFormProps) => {
  const employeeId = useEmployeeId() || '';
  const { create, getCreateQualificationValidationSchema } = useEmployeeSpecializationService();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      submitFormFunc={data => create(employeeId, data)}
      getValidationSchemaFunc={getCreateQualificationValidationSchema}
    >
      <FormSection>
        <NewSpecializationDropdown source="specializationBranchId" label="Specialization" className="mb-6" />
      </FormSection>
    </ModalForm>
  );
};

export default AddQualificationForm;
