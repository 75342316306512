/* istanbul ignore file */
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import React, { useEffect } from 'react';
import Grid from 'uibuilder/Grid';
import { TextInput } from 'shared/uibuilder/form/input';
import AdditionalVacancyQuestionOptionList from './AdditionalVacancyQuestionOptionList';
import AdditionalVacancyQuestionOption from './AdditionalVacancyQuestionOption';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import VacancyQuestionTypeInput from 'erp/recruitment/vacancy/publication/shared/field/VacancyQuestionTypeInput';
import { transformPathForLodash } from 'validation-schema-library';
import { get, unset } from 'lodash';
import { VACANCY_QUESTION_TYPES_ALIASES } from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';

const AdditionalVacancyQuestion = (props: any) => {
  const { data } = useFormContext();
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const onChangeCallback = getRawOnChangeCallback() || (() => {});

  const valueSource = `${getSource()}.value`;
  const typeSource = `${getSource()}.type`;
  const optionsSource = `${getSource()}.options`;

  const isOptionListVisible = get(data, transformPathForLodash(typeSource)) === VACANCY_QUESTION_TYPES_ALIASES.OPTIONS;

  useEffect(() => {
    if (!getValue()) {
      onChangeCallback({
        [getSource()]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && !isOptionListVisible) {
      unset(data, transformPathForLodash(optionsSource));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionListVisible]);

  return (
    <Grid container className="additional-contact-row" spacing={2}>
      <Grid item xs={12} md={12}>
        <TextInput source={valueSource} label="Vacancy question" />
      </Grid>
      <Grid item xs={6} md={6}>
        <VacancyQuestionTypeInput source={typeSource} label="Question type" />
      </Grid>
      {isOptionListVisible ? (
        <Grid item xs={12} md={12}>
          <AdditionalVacancyQuestionOptionList
            source={optionsSource}
            inputTemplate={AdditionalVacancyQuestionOption}
            addText="Add option"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default AdditionalVacancyQuestion;
