/* istanbul ignore file */
import { HTTP_METHOD, XHR_STATE_READY } from './const';
import { ResultResourceData } from 'shared/crud/baseCrudService';

export interface RequestOptions extends RequestInit {
  headers: Headers;
}

interface XhrRequestProps {
  url: string;
  opts: RequestOptions;
  onProgress?: Nullable<(ev: ProgressEvent) => void>;
}

const makeXhrRequest = ({ url, opts, onProgress }: XhrRequestProps): Promise<ResultResourceData> => {
  return new Promise((resolve, reject) => {
    if (opts.signal && opts.signal.aborted) {
      return reject;
    }

    const method = opts.method || HTTP_METHOD.GET;
    const xhr = new XMLHttpRequest();

    function abort() {
      xhr.abort();
    }

    xhr.open(method, url);
    const passedHeaders = Array.from(opts.headers.entries());

    if (passedHeaders.length) {
      passedHeaders.forEach(([name, value]) => {
        xhr.setRequestHeader(name, value);
      });
    }

    xhr.onload = e => resolve(e.target);
    xhr.onabort = e => resolve(e.target);

    if (opts.signal) {
      opts.signal.addEventListener('abort', abort);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XHR_STATE_READY && opts.signal) {
          opts.signal.removeEventListener('abort', abort);
        }
      };
    }

    xhr.onerror = reject;
    xhr.ontimeout = reject;

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.send(opts.body as XMLHttpRequestBodyInit);

    return resolve;
  });
};

export default makeXhrRequest;
