/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field/index';
import {
  competencyStatusesLabels,
  CompetencyStatus,
} from 'erp/employee/qualification/shared/employeeQualificationService';

const CompetencyStatusField = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const status = getValue() || CompetencyStatus.NOT_CONFIRMED;

  return <TextField {...props} source={source} value={competencyStatusesLabels[status]} />;
};

export default CompetencyStatusField;
