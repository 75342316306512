/* istanbul ignore file */
import React from 'react';
import { DateInput } from 'shared/uibuilder/form/input';

const CalendarRightDateInput = props => {
  return (
    <div className="calendar-right">
      <DateInput {...props} />
    </div>
  );
};

export default CalendarRightDateInput;
