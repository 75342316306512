/* istanbul ignore file */
import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import NumberField from 'shared/uibuilder/field/NumberField';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import PurchaseAmountField from 'financialAnalytic/purchaseOrder/shared/field/PurchaseAmountField';
import PurchaseTotalAmountField from 'financialAnalytic/purchaseOrder/shared/field/PurchaseTotalAmountField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const PurchasesListField = ({
  source,
  isSensitiveDataIsShown,
}: {
  source: string;
  isSensitiveDataIsShown?: boolean;
}) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getPurchaseSubTotals } = useFieldHelper({ source: 'purchaseSubTotals' });
  const transfers = (getValue() as any) || [];
  const purchaseSubTotals = getPurchaseSubTotals() as any;

  return (
    <ControlledList data={{ items: transfers, totalPages: 1, totalElements: transfers.length }}>
      <DataGridLayout tableWrapperClassName="journal-entry-table-wrapper">
        {
          [
            <TextField source="name" label="Name" width="50%" />,
            isSensitiveDataIsShown && (
              <PurchaseAmountField
                source="id"
                amountSource="amount"
                currencySource="currency"
                label="Unit Price"
                width="50%"
                purchaseSubTotals={purchaseSubTotals}
              />
            ),
            <NumberField source="quantity" label="Quantity" width="50%" displayZeroValue={false} />,
            isSensitiveDataIsShown && (
              <PurchaseTotalAmountField
                source="id"
                amountSource="amount"
                currencySource="currency"
                quantitySource="quantity"
                label="Total Price"
                width="50%"
                purchaseSubTotals={purchaseSubTotals}
              />
            ),
          ].filter(Boolean) as React.ReactElement[]
        }
      </DataGridLayout>
    </ControlledList>
  );
};

export default PurchasesListField;
