import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { TextAreaLayoutType } from 'shared/uibuilder/form/input/TextArea';

const DefaultTextAreaLayout: TextAreaLayoutType = props => {
  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <textarea {...getCommonInputProps(props)} />
    </BaseInputLayout>
  );
};

DefaultTextAreaLayout.defaultProps = COMMON_INPUT_LAYOUT_DEFAULT_PROPS;

export default DefaultTextAreaLayout;
