/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

import { EDUCATION_OPTIONS } from 'erp/employee/Education/shared/educationService';

const EducationTypeInput = props => {
  return <EnumDropdown {...props} options={EDUCATION_OPTIONS} />;
};

export default EducationTypeInput;
