/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  ASSIGNMENT_PATH,
  READ_ASSIGNMENTS_LIST,
  CREATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  READ_ASSIGNMENT,
} from 'erp/assignment/shared/assignmentService';
import AssignmentList from 'erp/assignment/List';
import { CreateAssignment, UpdateAssignment } from 'erp/assignment/createupdate';
import ViewAssignment from 'erp/assignment/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import AssignmentContext from 'erp/assignment/AssignmentContext';

export const useAssignmentRoute = () => {
  const routers = useRoute({ listRoute: ASSIGNMENT_PATH });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
  };
};

export const useAssignmentUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: ASSIGNMENT_PATH,
  });
  const getCopyEntityUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/copy`;

  return {
    ...restUrls,
    getSingleEntityUrl,
    getCopyEntityUrl,
  };
};

export const useAssignmentId = () => {
  const { id } = useParams<Dictionary<string>>();

  return id;
};

const AssignmentRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute, copyEntityRoute } = useAssignmentRoute();

  return (
    <ProtectedRouter basePath={ASSIGNMENT_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_ASSIGNMENTS_LIST}>
          <AssignmentList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_ASSIGNMENT}>
          <CreateAssignment />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={copyEntityRoute} exact globalPermissionToCheck={CREATE_ASSIGNMENT}>
          <CreateAssignment />
        </GlobalProtectedRoute>
        <Route path={singleEntityRoute} exact>
          <AssignmentContext permissionToCheck={READ_ASSIGNMENT}>
            <ViewAssignment />
          </AssignmentContext>
        </Route>
        <Route path={updateRoute} exact>
          <AssignmentContext permissionToCheck={UPDATE_ASSIGNMENT}>
            <UpdateAssignment />
          </AssignmentContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

AssignmentRouter.getRouterPath = () => ASSIGNMENT_PATH;

export default AssignmentRouter;
