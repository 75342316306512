import React from 'react';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import JournalEntryBreadcrumbs from '../shared/JournalEntryBreadcrumbs';

const UploadJournalEntryBreadcrumbs = () => {
  return (
    <JournalEntryBreadcrumbs>
      <BreadcrumbItem>Upload Journal Entries</BreadcrumbItem>
    </JournalEntryBreadcrumbs>
  );
};

export default UploadJournalEntryBreadcrumbs;
