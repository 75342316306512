/* istanbul ignore file */
import React from 'react';
import { ButtonProps } from 'uibuilder/button/Button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import useBudgetService from 'erp/budget/shared/budgetService';
import { ButtonWithConfirmation } from 'uibuilder/button';

interface CopyBudgetButtonProps extends ButtonProps {
  value?: StringOrNumber;
  nameSource?: string;
  permissionToCheck?: string;
}

const CopyBudgetForNextYearButton = ({ icon, permissionToCheck, nameSource, ...props }: CopyBudgetButtonProps) => {
  const { getValue } = useFieldHelper(props);
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getListUrl } = useBudgetUrl();
  const { copyForNextYear } = useBudgetService();

  const copyForNextYearAction = async () => {
    return copyForNextYear(getValue());
  };

  return (
    <ButtonWithConfirmation
      afterSubmit={{
        redirectUrl: getListUrl(),
        successMessage: `${getName()} was copied for the next year`,
        errorMessage: `${getName()} can not be copied for the next year`,
      }}
      modalConfirmButtonText="Copy"
      submitMethod={copyForNextYearAction}
      permissionToCheck={permissionToCheck}
      displayMessage="Do you really want to copy this budget?"
      title="Copy budget for the next year"
      color="secondary"
      outline
    >
      Copy
    </ButtonWithConfirmation>
  );
};

export default CopyBudgetForNextYearButton;
