/* istanbul ignore file */
import React, { useState } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import AccountTypeDropdown from 'crm/account/shared/input/AccountTypeDropdown';
import { AddressInput, PhoneInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import IndustryDropdown from 'crm/shared/input/IndustryDropdown';
import AccountInput from 'crm/account/shared/input/AccountNameInput';
import OwnerInput from 'crm/shared/input/OwnerInput';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import useAuthorization from 'shared/authorization/authorizationService';
import { CHANGE_ACCOUNT_OWNER, READ_FULL_ACCOUNTS_LIST } from 'crm/account/accountService';
import GenerateCodeNameButton from 'erp/codename/button/GenerateCodeNameButton';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const AccountForm = () => {
  const { isGranted } = useAuthorization();
  const { data = {}, setFormData } = useFormContext();
  const [isButtonDisplayed] = useState(!data.accountName);

  const setNewAccountName = newName => {
    setFormData({
      ...data,
      accountName: newName,
    });
  };

  return (
    <>
      <FormSection title="Account information">
        <FormRow>
          <TextInput label="Original Name" source="originalName" />
          <TextInput label="Account Name" source="accountName" disabled />
          <div style={{ marginTop: '23px' }}>
            <GenerateCodeNameButton
              source="accountName"
              isVisible={isButtonDisplayed}
              setCodeNameMethod={setNewAccountName}
            />
          </div>
        </FormRow>
        <FormRow>
          <AccountTypeDropdown label="Account Type" source="accountType" />
          <TextInput label="Website" source="website" />
          <PhoneInput label="Phone" source="phoneNumber" hasExtension />
          <IndustryDropdown label="Industry" source="industry" />
          <TextInput label="No. of Employees" source="employeesNumber" />
          <AccountInput label="Parent Account" source="parentAccountId" accountNameSource="parentAccountName" />
          <OwnerInput
            label="Account Owner"
            source="ownerId"
            dropdownPlaceholder="Choose Account Owner"
            isUserCanChange={isGranted(CHANGE_ACCOUNT_OWNER) || isGranted(READ_FULL_ACCOUNTS_LIST)}
          />
        </FormRow>
      </FormSection>

      <FormSection title="Address information">
        <AddressInput source="address" addressLine1Label="Street" zipLabel="Zip/Postal Code" />
      </FormSection>

      <FormSection title="Description information">
        <MediumFormRow>
          <TextArea source="accountDescription" />
        </MediumFormRow>
        <BigFormRow>
          <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default AccountForm;
