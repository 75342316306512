/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import LeadSourceDropdown from 'crm/lead/shared/input/LeadSourceDropdown';

const LeadSourceFilter = props => {
  return (
    <DropDownFilter {...props}>
      <LeadSourceDropdown />
    </DropDownFilter>
  );
};

export default LeadSourceFilter;
