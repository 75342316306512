import { useKernelApi } from 'api';
import { useCandidateId } from 'erp/candidate/CandidateRouter';
import { CANDIDATE_RESOURCE_URL } from 'erp/candidate/shared/candidateService';
import useBaseCrudService, { ResourceData } from 'shared/crud/baseCrudService';
import candidateApplicationFromJobBoardValidation from 'erp/candidate/applications/createupdate/candidateApplicationFromJobBoardValidation';
import Data from 'shared/uibuilder/Data';
import { SearchParams } from 'shared/uibuilder/list/ListContext';
import useSingletonPromise from 'shared/useSingletonPromise';
import { addStage } from 'erp/recruitment/applications/shared/applicationService';

export const APPLICATIONS_URL = '/applications';

export const CANDIDATE_APPLICATION_CREATE = 'CANDIDATE_APPLICATION_CREATE';

export const CANDIDATE_APPLICATION_SOURCE_NAMES = [
  'rabota.by',
  'dev.by',
  'pracuj.pl',
  'linkedin.com',
  'rocketjobs.pl',
  'hellohr.pl',
  'justjoin.it',
];

export enum CandidateApplicationSource {
  JOB_BOARD = 'JOB_BOARD',
  LINKED_IN = 'LINKED_IN',
  EMAIL = 'EMAIL',
  CONFERENCE = 'CONFERENCE',
  HR_BASE = 'HR_BASE',
  EDUCATIONAL_INSTITUTIONS = 'EDUCATIONAL_INSTITUTIONS',
  EMPLOYEE = 'EMPLOYEE',
  SYBERRY_COM = 'SYBERRY_COM',
  AMAZING_HIRING = 'AMAZING_HIRING',
  EXTERNAL_RECRUITING = 'EXTERNAL_RECRUITING',
  OTHER = 'OTHER',
}

export enum CandidateApplicationSourceType {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export const CandidateApplicationSourceTypeOptions = {
  [CandidateApplicationSourceType.ACTIVE]: 'Active',
  [CandidateApplicationSourceType.PASSIVE]: 'Passive',
};

export enum CandidateApplicationStatus {
  IN_PROCESSING = 'IN_PROCESSING',
  PROCESSED = 'PROCESSED',
}

export const CandidateApplicationStatusOptions: Dictionary<string> = {
  [CandidateApplicationStatus.IN_PROCESSING]: 'In Progress',
  [CandidateApplicationStatus.PROCESSED]: 'Completed',
};

export enum CreateCandidateApplicationOptions {
  DO_NOT_CREATE = 'DO_NOT_CREATE',
  CREATE_IN_COMPLETED_STATUS = 'CREATE_IN_COMPLETED_STATUS',
  CREATE_IN_SOURCE_STEP = 'CREATE_IN_SOURCE_STEP',
  CREATE_IN_PRESCREEN_STEP = 'CREATE_IN_PRESCREEN_STEP',
}

export const CreateCandidateApplicationOptionsLabels: Dictionary<string> = {
  [CreateCandidateApplicationOptions.DO_NOT_CREATE]: 'Do not create',
  [CreateCandidateApplicationOptions.CREATE_IN_SOURCE_STEP]: 'Create at "Application created" stage',
  [CreateCandidateApplicationOptions.CREATE_IN_PRESCREEN_STEP]: 'Create at "Pre-screen scheduled" stage',
  [CreateCandidateApplicationOptions.CREATE_IN_COMPLETED_STATUS]: 'Create in "Completed" status',
};

export const isEmployeeSource = (application: any): boolean => {
  if ((application as Data)?.getValueBySource !== undefined) {
    return (
      application.getValueBySource('source') &&
      application.getValueBySource('source') === CandidateApplicationSource.EMPLOYEE
    );
  } else {
    return application?.source && application?.source === CandidateApplicationSource.EMPLOYEE;
  }
};

export const getSourceOptions = () => {
  return {
    [CandidateApplicationSource.JOB_BOARD]: 'Job board',
    [CandidateApplicationSource.LINKED_IN]: 'LinkedIn',
    [CandidateApplicationSource.EMAIL]: 'Email',
    [CandidateApplicationSource.CONFERENCE]: 'Conference',
    [CandidateApplicationSource.HR_BASE]: 'HR Base',
    [CandidateApplicationSource.EDUCATIONAL_INSTITUTIONS]: 'Educational institutions',
    [CandidateApplicationSource.EMPLOYEE]: 'Employee',
    [CandidateApplicationSource.SYBERRY_COM]: 'syberry.com',
    [CandidateApplicationSource.AMAZING_HIRING]: 'Amazing Hiring',
    [CandidateApplicationSource.EXTERNAL_RECRUITING]: 'External recruiting',
    [CandidateApplicationSource.OTHER]: 'Other',
  };
};

export const getParentResourceUrl = (candidateId: string) => {
  return `${CANDIDATE_RESOURCE_URL}/${candidateId}`;
};

const useCandidateApplicationService = () => {
  const parentCandidateId = useCandidateId();
  const parentResourceUrl = getParentResourceUrl(parentCandidateId);
  const { sendPostRequest, sendPatchRequest } = useKernelApi();

  const { search: baseSearch, create: createForCandidate, ...baseCrudRequests } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${APPLICATIONS_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${APPLICATIONS_URL}`,
    apiService: useKernelApi,
  });

  const create = async (data: ResourceData) => {
    let requestUrl: string;
    if (data?.candidateId) {
      const parentUrl = getParentResourceUrl(data.candidateId);
      requestUrl = `${parentUrl}/applications`;
    } else {
      requestUrl = `/candidates/applications`;
    }

    const response = await sendPostRequest(requestUrl, data);
    const result = await response.json();
    return result.info || result;
  };

  // TODO: Update this function and UI testing and adding stages to BE. Now it uses random stages and mock data
  const search = useSingletonPromise('search-candidate-applications', async (params: any) => {
    const result = await baseSearch(params);

    return {
      ...result,
      result: addStage(result.result),
    };
  });

  const searchByCandidateIdAndVacancyAlias = async (candidateId: string, vacancyAlias: string) => {
    const url = `/candidates/${candidateId}/applications/search`;
    const searchRequest = { filter: { 'vacancy.alias': { eq: vacancyAlias } } };
    const response = await sendPostRequest(url, searchRequest);
    return response.json();
  };

  const searchAllForCandidateId = async (candidateId: string, params: SearchParams) => {
    let data: any;
    if (candidateId) {
      const url = `/candidates/${candidateId}/applications/search`;
      const response = await sendPostRequest(url, params);
      data = await response.json();
    }
    return data?.result || [];
  };

  const partialUpdate = async (id: string, data: any) => {
    const response = await sendPatchRequest(`/candidates/applications/${id}`, data);
    const result = await response.json();
    return result.info || result;
  };

  const getValidationSchema = () => Promise.resolve(candidateApplicationFromJobBoardValidation);

  return {
    getValidationSchema,
    createForCandidate,
    create,
    searchByCandidateIdAndVacancyAlias,
    searchAllForCandidateId,
    partialUpdate,
    ...baseCrudRequests,
    search,
  };
};

export default useCandidateApplicationService;
