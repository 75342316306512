/* istanbul ignore file */
import React, { useState } from 'react';
import moment from 'moment';
import useExpenseService, { MARK_AS_PAID_EXPENSE } from 'financialAnalytic/expenses/useExpenseService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { getVisibility } from 'shared/uibuilder/helper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

export type MarkAsPaidExpenseButtonProps = {
  isVisible: boolean | ((data: any) => boolean);
  ids: string[];
  onSubmit?: () => void;
};

const MarkAsPaidExpenseButton = ({ isVisible, ids, onSubmit }: MarkAsPaidExpenseButtonProps) => {
  const { markAsPaidExpense, invalidateCache } = useExpenseService();
  const [payoutDateErrors, setPayoutDateErrors] = useState<string[]>([]);
  const { getToday, formatDateForAPI } = useDateService();
  const [payoutDate, setPayoutDate] = useState(getToday());
  const [bankTransactionId, setBankTransactionId] = useState<string>('');
  const { data, setData = () => {} } = useShowContext();

  if (!getVisibility(data, isVisible)) {
    return null;
  }

  const onPayoutDateChange = (values: FormFieldsData) => {
    setPayoutDate(values.payoutDate);
    const isValid = moment(values.payoutDate, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.payoutDate) {
      setPayoutDateErrors(['Field is required']);
    } else if (!isValid) {
      setPayoutDateErrors(['Please, choose the correct date']);
    } else {
      setPayoutDateErrors([]);
    }
  };

  const onBankTransactionIdCallback = (values: FormFieldsData) => {
    setBankTransactionId(values.bankTransactionId);
  };

  const execute = (newExpenseData?: any, shouldUpdateData?: boolean) => {
    invalidateCache();
    if (onSubmit) {
      onSubmit();
    } else if (shouldUpdateData && newExpenseData[0]) {
      setData(newExpenseData[0]);
    }
  };

  return (
    <ButtonWithConfirmation
      displayMessage={`Do you really want to mark ${
        ids.length > 1 ? `these expenses (ids: ${ids.join(', ')})` : `this expense (id: ${ids[0]})`
      } as paid? Please fill out the inputs below.`}
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="payoutDate"
            value={payoutDate as any}
            label="Payout date"
            onChangeCallback={onPayoutDateChange}
            errorMessages={payoutDateErrors}
            isRequired
          />
          <TextInput
            source="bankTransactionId"
            label="Bank Transaction ID"
            value={bankTransactionId}
            onChangeCallback={onBankTransactionIdCallback}
          />
        </div>
      }
      submitMethod={() => markAsPaidExpense(ids, formatDateForAPI(payoutDate), bankTransactionId)}
      afterSubmit={{
        successMessage: `The Expense${ids.length > 1 ? 's have' : ' has'} been successfully marked as paid.`,
        errorMessage: `Can't mark expense${ids.length > 1 ? 's' : ''} as paid.`,
        execute: (newExpenseData: any) => execute(newExpenseData, true),
        executeOnError: execute,
      }}
      permissionToCheck={MARK_AS_PAID_EXPENSE}
      variant="outlined"
      confirmBtnProps={{ disabled: !!payoutDateErrors.length || !payoutDate }}
      closeOnSubmit={false}
    >
      Mark as paid
    </ButtonWithConfirmation>
  );
};

export default MarkAsPaidExpenseButton;
