import { useSelector, useDispatch } from 'react-redux';

export const SET_SETTING = 'SET_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_SETTING,
  payload,
});

export interface OneStopSettingState {
  isOneStopDescriptionsVisible: boolean;
}

const initialState = {
  isOneStopDescriptionsVisible: true,
};

export const oneStopSettingsReducer = (state = initialState, action: any) => {
  if (action.type === SET_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const useOneStopSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state: { oneStopSettings: OneStopSettingState }) => state?.oneStopSettings);
  const { isOneStopDescriptionsVisible }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const setDescriptionsVisible = (isChecked: boolean) => {
    updateSettings({
      isOneStopDescriptionsVisible: isChecked,
    });
  };

  return {
    setDescriptionsVisible,
    areDescriptionsVisible: isOneStopDescriptionsVisible,
  };
};

export default useOneStopSettings;
