import { useKernelApi } from 'api';
import candidateValidation from 'erp/candidate/createupdate/candidateValidation';
import candidateFilterValidation from 'erp/candidate/List/filter/candidateFilterValidation';
import useTimelineService from 'shared/crud/timelineService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import useCrudService from 'shared/crud';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

export const READ_CANDIDATES_LIST = 'FE_READ_CANDIDATES_LIST';

export const CREATE_CANDIDATE = 'CREATE_CANDIDATE';

export const READ_TIMELINE = 'READ_TIMELINE';

export const READ_SKILLS = 'READ_SKILLS';

export const START_REC1_PROCESS = 'START_REC1_PROCESS';

export const READ_LIST_CANDIDATE_OFFER = 'READ_LIST_CANDIDATE_OFFER';

export const CREATE_CANDIDATE_OFFER = 'CREATE_CANDIDATE_OFFER';

export const INTERACTION_BASE_FINANCE_DATA = 'INTERACTION_BASE_FINANCE_DATA';

export const INTERACTION_PROBATION_FINANCE_DATA = 'INTERACTION_PROBATION_FINANCE_DATA';

export const INTERACTION_CALCULATED_FINANCE_DATA = 'INTERACTION_CALCULATED_FINANCE_DATA';

export const READ_COMMUNICATION = `READ_COMMUNICATION`;

export const SEND_EMAIL = 'SEND_EMAIL';

export const CANDIDATE_ICON = 'fa-address-book';

export const CREATE_CANDIDATE_APPLICATION = 'CREATE_CANDIDATE_APPLICATION';

export const READ_LIST_CANDIDATE_APPLICATIONS = 'READ_LIST_CANDIDATE_APPLICATIONS';

export enum CandidateStatus {
  DEFAULT = 'DEFAULT',
  TEMPORARY = 'TEMPORARY',
  CANDIDATE = 'CANDIDATE',
  INTERVIEW = 'INTERVIEW',
  OFFER = 'OFFER',
  ACCEPTED = 'ACCEPTED',
}

export const ENGLISH_LEVEL_ALIAS = {
  BEGINNER: 'Beginner',
  ELEMENTARY: 'Elementary',
  INTERMEDIATE: 'Intermediate',
  UPPER_INTERMEDIATE: 'Upper Intermediate',
  ADVANCED: 'Advanced',
  PROFICIENCY: 'Proficiency',
};

export const ENGLISH_LEVEL_OPTIONS = {
  [ENGLISH_LEVEL_ALIAS.BEGINNER]: 'Beginner',
  [ENGLISH_LEVEL_ALIAS.ELEMENTARY]: 'Elementary',
  [ENGLISH_LEVEL_ALIAS.INTERMEDIATE]: 'Intermediate',
  [ENGLISH_LEVEL_ALIAS.UPPER_INTERMEDIATE]: 'Upper Intermediate',
  [ENGLISH_LEVEL_ALIAS.ADVANCED]: 'Advanced',
  [ENGLISH_LEVEL_ALIAS.PROFICIENCY]: 'Proficiency',
};

export type CandidateDuplicate = {
  url: string;
  candidate: ResourceData;
  // TODO: need add types for LinkedIn, Skype, Telegram, Whatsapp and Viber after implementing them on BE
  score: {
    name: number;
    personalPhoneNumber: number;
    personalEmail: number;
    total?: number;
  };
};

export const CANDIDATE_RESOURCE_URL = '/candidates';

/**
 * Candidate service.
 */
const useCandidateService = () => {
  const { sendPostRequest } = useKernelApi();
  const { getProfileUrl } = useCandidateUrl();

  const { search: baseTimelineSearch, create: createTimelineItem, getTimelinePageById } = useTimelineService({
    parentEntityUrl: `/candidates/:id`,
    apiService: useKernelApi,
  });

  const searchTimelineItems = async (candidateId: ResourceId, request: any) => {
    return baseTimelineSearch(candidateId, {
      ...request,
      sort: {
        ...request.sort,
        id: DESC,
      },
    });
  };

  const baseCrudRequests = useCrudService({
    singleResourceUrl: '/candidates/:id',
    listResourceUrl: CANDIDATE_RESOURCE_URL,
    apiService: useKernelApi,
  });

  const visit = async (id: ResourceId) => {
    const url = `/candidates/${id}/visit`;
    return sendPostRequest(url, id);
  };

  const getValidationSchema = () => Promise.resolve(candidateValidation);

  const getFilterValidationSchema = () => Promise.resolve(candidateFilterValidation);

  const getDuplicates = async (data: FormFieldsData) => {
    const response = await sendPostRequest(`/candidates/matches`, data);
    const duplicates = (await response.json()) || [];

    return duplicates.map((duplicate: CandidateDuplicate) => ({
      ...duplicate,
      url: getProfileUrl(duplicate.candidate.id),
    }));
  };

  const canSearchDuplicates = (data: FormFieldsData) => {
    return data?.name?.firstName && data?.name?.lastName;
  };

  const createCandidateAndLeftNote = async (data: any) => {
    const { create: createBaseCandidate } = baseCrudRequests;
    const response = await createBaseCandidate(data);

    if (data.timelineNote) {
      const id = await response.id;
      await createTimelineItem(id, 'notes', {
        noteText: data.timelineNote,
      });
    }

    return response;
  };

  return {
    visit,
    getValidationSchema,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    ...baseCrudRequests,
    getFilterValidationSchema,
    getDuplicates,
    canSearchDuplicates,
    createCandidateAndLeftNote,
  };
};

export default useCandidateService;
