/* istanbul ignore file */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useFormContext } from 'shared/uibuilder/form/FormContext';
import LeadsBreadcrumbs from 'crm/lead/shared/LeadsBreadcrumbs';
import { SingleBreadcrumbItem, UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useLeadUrl } from 'crm/lead/LeadRouter';

/**
 * Returns lead create pages breadcrumbs.
 * @returns {*}
 * @constructor
 */
const Breadcrumbs = () => {
  const { id } = useParams();
  const { data } = useFormContext();
  const { firstName, middleName, lastName } = data;
  const { getSingleEntityUrl } = useLeadUrl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedName = useMemo(() => `${firstName} ${middleName || ''} ${lastName}`, []);

  return (
    <LeadsBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getSingleEntityUrl(id)} />
      <UpdateBreadcrumbItem />
    </LeadsBreadcrumbs>
  );
};

export default Breadcrumbs;
