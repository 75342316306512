/* istanbul ignore file */

import React from 'react';
import 'audit/shared/Manual.css';

const RolesAuditManual = () => (
  <div className="manual-container">
    <p>
      Current functionality allows getting the snapshots of the roles assignments at a specific point in time. Specify
      the date and time at which you want to get the snapshot using the form above.
    </p>
    <p>The generated report contains the following columns:</p>
    <table className="manual-table">
      <tbody>
        <tr>
          <td>
            <b>Column</b>
          </td>
          <td>
            <b>Description</b>
          </td>
        </tr>
        <tr>
          <td>Employee Id</td>
          <td>Identifier of the assigned employee.</td>
        </tr>
        <tr>
          <td>Employee Name</td>
          <td>Full name of the assigned employee.</td>
        </tr>
        <tr>
          <td>Context Entity</td>
          <td>
            Authorization context entity. It&apos;s specified in the format <b>contextName:entityId</b>.
          </td>
        </tr>
        <tr>
          <td>Role</td>
          <td>Assigned role.</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default RolesAuditManual;
