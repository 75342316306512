import React from 'react';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

interface ListFieldLayoutProps {
  value?: string | number | React.ReactElement;
  className?: string;
}

const ListFieldLayout = ({ value, className }: ListFieldLayoutProps) => {
  return <span className={className}>{value || EMPTY_VALUE_INDICATOR}</span>;
};

export default ListFieldLayout;
