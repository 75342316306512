/* istanbul ignore file */
import React from 'react';
import Badge, { BadgeProps } from 'shared/uibuilder/badge/Badge';

const ActiveBadge = (props: BadgeProps) => {
  return (
    <Badge className="ml-2" color="success" {...props}>
      Active
    </Badge>
  );
};

export default ActiveBadge;
