/* istanbul ignore file */
import React from 'react';
import ContactsBreadcrumbs from 'crm/contact/shared/ContactsBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useContactId, useContactUrl } from 'crm/contact/ContactRouter';
import { element } from 'prop-types';

const SingleContactsBreadcrumbs = ({ children }) => {
  const { data } = useShowContext();
  const id = useContactId();
  const { getSingleEntityUrl } = useContactUrl();

  return (
    <ContactsBreadcrumbs>
      <SingleBreadcrumbItem entity={data.getValueBySource('fullName')} link={getSingleEntityUrl(id)} />
      {children}
    </ContactsBreadcrumbs>
  );
};

SingleContactsBreadcrumbs.propTypes = {
  children: element,
};

SingleContactsBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleContactsBreadcrumbs;
