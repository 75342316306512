/* istanbul ignore file */
import React from 'react';
import { TextInput, WysiwygInput } from 'shared/uibuilder/form/input';
import FieldGroup from 'shared/uibuilder/layout/FieldGroup';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const EmailForm = () => {
  const { data } = useFormContext();

  return (
    <>
      <FieldGroup isVisible={!data?.inReplyToId}>
        <BigFormRow id="timeline-create-form">
          <TextInput source="subject" />
        </BigFormRow>
      </FieldGroup>
      <BigFormRow>
        <WysiwygInput source="body" label="Message" />
      </BigFormRow>
    </>
  );
};

export default EmailForm;
