/* istanbul ignore file */
import React from 'react';
import PassportNumberLayout from 'erp/employee/shared/layout/PassportNumberLayout';
import { TextInput } from 'shared/uibuilder/form/input';

const PassportNumberInput = ({ source, isPesel, ...props }) => {
  const passportNumberInput = <TextInput {...props} source={`${source}.number`} />;

  const passportSeriesInput = <TextInput {...props} source={`${source}.documentDetails.series`} />;

  return isPesel ? (
    passportNumberInput
  ) : (
    <PassportNumberLayout passportNumberInput={passportNumberInput} passportSeriesInput={passportSeriesInput} />
  );
};

PassportNumberInput.propTypes = {
  ...TextInput.defaultProps,
};

export default PassportNumberInput;
