/* istanbul ignore file */
import React from 'react';
import { CREATE_INVOICE } from 'financialAnalytic/invoice/useInvoiceService';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkButton from 'uibuilder/button/LinkButton';

const CopyInvoiceButton = (props: { source?: string }) => {
  const { getCopyEntityUrl } = useInvoiceUrl();
  const { getValue: getId } = useFieldHelper(props);
  const id = getId();
  const { isGranted } = useAuthorization();

  return isGranted(CREATE_INVOICE) ? (
    <LinkButton outline {...props} url={getCopyEntityUrl(id)}>
      Copy
    </LinkButton>
  ) : null;
};

export default CopyInvoiceButton;
