/* istanbul ignore file */
import CreatePresaleRequestForm from 'crm/opportunity/presale/request/form/CreatePresaleRequestForm';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import PresaleRequestTimelineRecordLayout from 'crm/opportunity/presale/request/PresaleRequestTimelineRecordLayout';
import UpdatePresaleRequestForm from 'crm/opportunity/presale/request/form/UpdatePresaleRequestForm';
import { CREATE_PRESALE_REQUEST } from 'crm/opportunity/shared/opportunityService';
import Data from 'shared/uibuilder/Data';
import { get } from 'lodash';
import { PRESALE_ITEMS_TYPE } from 'crm/opportunity/presale/presaleTimelineService';

const usePresaleRequestItem = () => ({
  AddForm: CreatePresaleRequestForm,
  UpdateForm: UpdatePresaleRequestForm,
  isApplicable: (item: any) => item?.presaleRequest,
  source: 'presaleRequest',
  urlPostfix: PRESALE_ITEMS_TYPE.PRESALE_REQUEST,
  RecordLayout: PresaleRequestTimelineRecordLayout,
  name: 'Presale Request',
  permissionToCheck: CREATE_PRESALE_REQUEST,
  additionalAfterAddSubmit: (item: TimelineItem, parentContext?: Data) => {
    const oppState = parentContext?.getValueBySource('stateStep');
    const requestStatus = get(item, 'requestStatus');
    if (oppState === 'NEW' && requestStatus === 'SUBMITTED') {
      window.location.reload(); // reload page for fetch actual state
    }
  },
  additionalAfterUpdateSubmit: (item: TimelineItem, parentContext?: Data) => {
    const oppState = parentContext?.getValueBySource('stateStep');
    const requestStatus = get(item, 'requestStatus');
    if (oppState === 'NEW' && requestStatus === 'SUBMITTED') {
      window.location.reload(); // reload page for fetch actual state
    }
  },
});

export default usePresaleRequestItem;
