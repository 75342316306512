import React, { useState } from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import FlagAsInappropriateRadioButtonGroup, {
  FEEDBACK_FLAGS,
} from 'erp/employee/Timeline/feedback/shared/input/FlagAsInappropriateRadioButtonGroup';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import useEmployeeFeedbackService from 'erp/employee/Timeline/feedback/employeeFeedbackService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import 'erp/employee/Timeline/feedback/shared/button/index.scss';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { shape } from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

export const FEEDBACK_INAPPROPRIATE_DEFAULT_FLAG = 'NONE';
export const INAPPROPRIATE_FLAGGING_LABEL = 'Flag as inappropriate';
export const CANCEL_INAPPROPRIATE_FLAGGING_LABEL = 'Cancel inappropriate flagging';

const FeedbackFlagAsInappropriateButton = ({ entity }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const flag = entity.getValueBySource('inappropriateContentType');
  const displayedFlag = FEEDBACK_FLAGS[flag];

  const { updateFlag, getFlagValidationSchema } = useEmployeeFeedbackService();
  const { updateInList, wrapItem } = useListContext();
  const { addMessage } = useMessageService();

  const updateRecordInList = data => {
    updateInList(data.timelineId, wrapItem(data, 'feedback'));
  };

  const toggleDropdownState = async () => {
    if (displayedFlag && !isDropdownOpen) {
      try {
        setIsSubmitting(true);
        const response = await updateFlag(entity.getValueBySource('id'), {
          ...entity.getData(),
          inappropriateContentType: FEEDBACK_INAPPROPRIATE_DEFAULT_FLAG,
        });
        updateRecordInList(response);
      } catch {
        addMessage(new ErrorMessage('Sorry, feedback cannot be flagged'));
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsDropdownOpen(prev => !prev);
    }
  };

  if (isSubmitting && displayedFlag) {
    return <SmallLoader />;
  }

  return (
    <>
      <MenuItem
        onClick={e => {
          setAnchorEl(e.currentTarget);
          toggleDropdownState(e);
        }}
        className="flag-button"
      >
        {displayedFlag ? CANCEL_INAPPROPRIATE_FLAGGING_LABEL : INAPPROPRIATE_FLAGGING_LABEL}
      </MenuItem>
      <Popper
        open={isDropdownOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 1200, transform: 'none !important' }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={3} className="dropdown-menu__wrapper flag-dropdown">
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuList autoFocusItem={false}>
                  <UpdateForm
                    getDataFunc={() => Promise.resolve({ inappropriateContentType: flag })}
                    getValidationSchemaFunc={getFlagValidationSchema}
                    submitFormFunc={(id, data) => updateFlag(entity.getValueBySource('id'), data)}
                    afterSubmit={{
                      execute: (id, data) => {
                        updateRecordInList(data);
                      },
                    }}
                  >
                    <div onClick={e => e.stopPropagation()} role="button" tabIndex={0} onKeyPress={() => {}}>
                      <FlagAsInappropriateRadioButtonGroup
                        source="inappropriateContentType"
                        label={INAPPROPRIATE_FLAGGING_LABEL}
                      />
                    </div>
                    <FormButtonsLayout onCancelAction={toggleDropdownState} />
                  </UpdateForm>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

FeedbackFlagAsInappropriateButton.propTypes = {
  entity: shape().isRequired,
};

export default FeedbackFlagAsInappropriateButton;
