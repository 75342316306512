import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { TRAINING_INSTANCE_STATUS_OPTIONS } from '../useTrainingInstancesService';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import TrainingProgramDropdown from '../shared/inputs/TrainingProgramDropdown';
import MentorsInput from 'erp/employee/mentors/shared/input/MentorsInput';

const TrainingInstanceForm = () => {
  return (
    <FormSection title="Main information">
      <FormRow>
        <TrainingProgramDropdown source="trainingProgram" label="Training Program" />
        <EnumDropdown source="status" label="Status" options={TRAINING_INSTANCE_STATUS_OPTIONS} />
      </FormRow>
      <FormRow>
        <MentorsInput
          noOptionsMessage={() => 'Nothing was found. Please refine your search query. '}
          source="mentors"
          label="Mentors"
        />

        <MentorsInput
          noOptionsMessage={() => 'Nothing was found. Please refine your search query. '}
          source="students"
          label="Students"
        />
      </FormRow>
      <FormRow>
        <DateInput source="startDate" label="Start Date" />
        <DateInput source="endDate" label="End Date" />
      </FormRow>
    </FormSection>
  );
};

export default TrainingInstanceForm;
