/* istanbul ignore file */
import React from 'react';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import EditButton from 'uibuilder/button/EditButton';

const EditCandidateOfferButton = ({ ...props }) => {
  const { getUpdateEntityUrl } = useCandidateOfferUrl();

  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditCandidateOfferButton;
