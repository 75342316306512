/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import VacancyStatusDropdown from 'erp/recruitment/vacancy/shared/input/VacancyStatusDropdown';
import RequestedByDropdown from 'erp/recruitment/vacancy/shared/input/RequestedByDropdown';
import { Checkbox, InputList, NumberInput, TextInput } from 'shared/uibuilder/form/input';
import {
  COMPETENCY_LEVEL,
  ONETIME_VACANCY_TYPE,
  RECURRING_VACANCY_TYPE,
  VACANCY_DUE_DATE,
  VACANCY_POSITIONS,
  VACANCY_TYPES,
} from 'erp/recruitment/vacancy/shared/vacancyService';
import VacancySpecializationDropdown from 'erp/recruitment/vacancy/shared/input/VacancySpecializationDropdown';
import VacancyWysiwygInput from 'erp/recruitment/vacancy/shared/input/VacancyWysiwygInput';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import ExpectedCompensationListInput from '../shared/input/ExpectedCompensationListInput';
import ExpectedCompensationInput from '../shared/input/ExpectedCompensationInput';
import VacancyRequirementInput from 'erp/recruitment/vacancy/shared/input/VacancyRequirementInput';
import VacancyPersonalityInput from 'erp/recruitment/vacancy/shared/input/VacancyPersonalityInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import VacancyResponsibilityInput from 'erp/recruitment/vacancy/shared/input/VacancyResponsibilityInput';
import VacancyDetailsListInput from 'erp/recruitment/vacancy/shared/input/VacancyDetailsListInput';
import VacancyDetailsInput from 'erp/recruitment/vacancy/shared/input/VacancyDetailsInput';
import './VacancyForm.scss';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import useCommonSkillsService from 'erp/candidate/commonskills/commonSkillsService';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import useAuthorization from 'shared/authorization/authorizationService';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';

interface VacancyFormProps {
  isCreateForm?: boolean;
  isOneStop?: boolean;
}

const VacancyForm = ({ isCreateForm = false, isOneStop = false }: VacancyFormProps) => {
  const titleStyle = { fontSize: 'large' };
  const nameDescriptionInputDefaultValue = { name: null };
  const { searchByName } = useCommonSkillsService();
  const { isGranted } = useAuthorization();

  return (
    <div className="vacancy-form__wrapper">
      <FormSection title="Main Information">
        <FormRow>
          <VacancySpecializationDropdown
            disabled={!isCreateForm}
            source="aliasPrefix"
            tooltip={
              isCreateForm
                ? 'Select "Other" and enter the name of the desired position if it is not listed.'
                : undefined
            }
          />
          <TextInput source="name" label="Specialization name" isVisible={vacancy => vacancy.aliasPrefix === 'OTH'} />
          <EnumDropdown
            source="competencyLevel"
            options={COMPETENCY_LEVEL}
            label="Competency level"
            placeholder="Select Competency level"
          />
          <EnumDropdown source="dueDate" label="Due Date" options={VACANCY_DUE_DATE} placeholder="Choose Option" />
          <VacancyStatusDropdown source="status" label="Vacancy Status" />
        </FormRow>
        <FormRow>
          <EmployeeInput
            source="managerAlias"
            nameSource="managerName"
            disabled={!isGranted('VACANCY_UPDATE_VACANCY_MANAGER')}
            label="Manager"
          />
          <RequestedByDropdown source="requestedById" placeholder="Choose Employee" label="Requested By" />
          <EnumDropdown source="type" label="Vacancy Type" options={VACANCY_TYPES} placeholder="Choose Type" />
          <EnumDropdown
            source="openPositions"
            label="Number of People to be Hired"
            options={VACANCY_POSITIONS}
            placeholder="Choose number"
            isVisible={vacancy => vacancy.type === ONETIME_VACANCY_TYPE}
          />
          <EnumDropdown
            source="openPositions"
            label="Number of People to be Hired Per Month"
            options={VACANCY_POSITIONS}
            placeholder="Choose number"
            isVisible={vacancy => vacancy.type === RECURRING_VACANCY_TYPE}
          />
        </FormRow>
      </FormSection>
      <FormSection title="Office Details" subtitle={!isOneStop ? 'Should be at least one office details' : undefined}>
        <Checkbox source="vacancyDetailsToDefine" label="I don't know office details" isVisible={isOneStop} />
        <FormRow colMd={12} colXl={12}>
          <ClearAfterUnmountWrapper isVisible={vacancy => !vacancy.vacancyDetailsToDefine}>
            <VacancyDetailsListInput
              source="vacancyDetails"
              maxFields={10}
              addText="Add Office Details"
              inputTemplate={VacancyDetailsInput}
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>
      <FormSection title="Expected Compensation">
        <Checkbox source="compensationToDefine" label="I don't know compensation details" isVisible={isOneStop} />
        <FormRow colMd={12} colXl={12}>
          <ClearAfterUnmountWrapper isVisible={vacancy => !vacancy.compensationToDefine}>
            <ExpectedCompensationListInput
              source="expectedCompensation"
              inputTemplate={ExpectedCompensationInput}
              addText="Add Expected Compensation"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>
      <FormSection title="Project Description">
        <VacancyWysiwygInput source="description.projectText" label="" />
      </FormSection>
      <FormSection title="Responsibilities" titleStyle={titleStyle} subtitle="Should be at least one responsibility">
        <InputList
          source="description.responsibilities"
          maxFields={100}
          addText="Add Responsibility"
          inputTemplate={VacancyResponsibilityInput}
          defaultValue={nameDescriptionInputDefaultValue}
        />
      </FormSection>
      <FormSection title="Mandatory Requirements" titleStyle={titleStyle} subtitle="Should be at least one requirement">
        <InputList
          source="description.requirements"
          maxFields={100}
          addText="Add Mandatory Requirements"
          inputTemplate={VacancyRequirementInput}
          defaultValue={nameDescriptionInputDefaultValue}
          helpText="Mandatory requirenments."
        />
        <MediumFormRow>
          <SkillsInput
            source="description.mandatorySkills"
            loadSuggestionsMethod={searchByName}
            label="Skills:"
            helpText="Required skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
          />
          <NumberInput source="minExperience" label="Required Experience" helpText="Required experience in years." />
        </MediumFormRow>
      </FormSection>
      <FormSection title="Optional Requirements" titleStyle={titleStyle}>
        <InputList
          source="description.optionalRequirements"
          maxFields={100}
          addText="Add Optional Requirements"
          inputTemplate={VacancyRequirementInput}
          defaultValue={nameDescriptionInputDefaultValue}
          helpText="Optional requirenments."
        />
        <SkillsInput
          source="description.optionalSkills"
          loadSuggestionsMethod={searchByName}
          label="Skills:"
          helpText="Optional skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
        />
      </FormSection>
      <FormSection title="Personality" titleStyle={titleStyle}>
        <InputList
          source="description.personality"
          maxFields={100}
          addText="Add Trait"
          inputTemplate={VacancyPersonalityInput}
          defaultValue={nameDescriptionInputDefaultValue}
        />
      </FormSection>
    </div>
  );
};

export default VacancyForm;
