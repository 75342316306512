import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { NumberInputLayoutType } from 'shared/uibuilder/form/input/NumberInput';

const NumberInputLayout: NumberInputLayoutType = ({ value, ...props }) => {
  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <input {...getCommonInputProps(props)} value={value} type="number" />
    </BaseInputLayout>
  );
};

NumberInputLayout.defaultProps = COMMON_INPUT_LAYOUT_DEFAULT_PROPS;

export default NumberInputLayout;
