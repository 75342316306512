/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { GET_EMPLOYEES_LIST } from 'erp/employee/employeeService';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { arrayOf, element, oneOfType } from 'prop-types';

const EmployeesBreadcrumbs = ({ children }) => {
  const { getListUrl } = useEmployeeUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Employees" link={getListUrl()} permissionToCheck={GET_EMPLOYEES_LIST} />
      {children}
    </Breadcrumbs>
  );
};

EmployeesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

EmployeesBreadcrumbs.defaultProps = {
  children: null,
};

export default EmployeesBreadcrumbs;
