/* istanbul ignore file */
import React from 'react';
import { LIST_DEFAULT_PROPS } from 'shared/uibuilder/list/List';
import SimpleList, { SimpleListProps } from 'shared/uibuilder/list/SimpleList';

const PAGE_SIZE = 30;

const RelatedList = ({ children, ...props }: SimpleListProps) => {
  return (
    <SimpleList {...props} pageSize={PAGE_SIZE}>
      {children}
    </SimpleList>
  );
};

RelatedList.defaultProps = LIST_DEFAULT_PROPS;

export default RelatedList;
