/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { arrayOf, element, oneOfType } from 'prop-types';
import { useEmployeeAssignmentUrl } from '../EmployeeAssignmentsRouter';

const EmployeeAssignmentsBreadCrumbs = ({ children }) => {
  const { getListUrl } = useEmployeeAssignmentUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Assignments" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

EmployeeAssignmentsBreadCrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

EmployeeAssignmentsBreadCrumbs.defaultProps = {
  children: null,
};

export default EmployeeAssignmentsBreadCrumbs;
