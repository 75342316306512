import React from 'react';
import ProjectEntityHeader from '../shared/ProjectEntityHeader';
import ViewProjectPageMenu from '../Show/ViewProjectPageMenu';
import useProjectService from '../shared/projectService';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import { useProjectId } from '../ProjectRouter';
import ProjectsWbsDashboardBreadcrumbs from './ProjectsWBSDashboardBreadcrumbs';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import List from 'shared/uibuilder/list/List';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import useProjectsWBSDashboardService from './useProjectsWBSDashboardService';
import { TextField } from 'shared/uibuilder/field';
import UploadWbsButton from './buttons/UploadWBSButton';

const ProjectsWBSDashboardList = () => {
  const projectId = useProjectId();
  const { getById } = useProjectService();
  const { getWBSDashboardList } = useProjectsWBSDashboardService();

  return (
    <Show getDataMethod={() => getById(projectId)}>
      <ShowPageLayout
        entityHeader={<ProjectEntityHeader />}
        menu={<ViewProjectPageMenu />}
        breadcrumbs={<ProjectsWbsDashboardBreadcrumbs />}
      >
        <List getDataMethod={getWBSDashboardList}>
          <RelationListLayout header="WBS Dashboard" actions={[<UploadWbsButton />]}>
            <DataGridLayout>
              <TextField label="ID" source="id" width={80} />
              <TextField label="Estimation" source="estimation" width={15} />
              <TextField label="Actual Spent Time" source="actualSpentTime" width={15} />
            </DataGridLayout>
          </RelationListLayout>
        </List>
      </ShowPageLayout>
    </Show>
  );
};

export default ProjectsWBSDashboardList;
