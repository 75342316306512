import { useKernelApi } from 'api';

export const RESOURCE_URL = '/rbs/suggestions';

/**
 * Employee suggestions service.
 *
 * */
const useRbsSuggestionsService = () => {
  const { sendPostRequest } = useKernelApi();

  const search = async ({ pageNumber = 0, filter = {} } = {}) => {
    const request = {
      filter,
      pageSize: 30,
      pageNumber,
    };

    const response = await sendPostRequest(`${RESOURCE_URL}/search`, request);
    return response.json();
  };
  return {
    search,
  };
};

export default useRbsSuggestionsService;
