import React, { useState } from 'react';
import Box from 'uibuilder/Box';
import { Tab, Tabs } from '@mui/material';

type TabItem = {
  index: number;
  label: string;
  content: React.ReactNode;
};

type TabsWrapperProps = {
  tabs: TabItem[];
};

const TabsWrapper: React.FC<TabsWrapperProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        sx={{
          flexWrap: 'wrap',
        }}
        value={activeTab}
        onChange={handleTabChange}
        aria-label="TabsWrapper"
      >
        {tabs.map(tab => (
          <Tab
            sx={{
              textTransform: 'none',
            }}
            key={tab.index}
            label={tab.label}
          />
        ))}
      </Tabs>

      <Box sx={{ padding: 2 }}>
        {tabs.map(tab =>
          tab.index === activeTab ? (
            <Box key={tab.index} sx={{ paddingTop: 2 }}>
              {tab.content}
            </Box>
          ) : null,
        )}
      </Box>
    </Box>
  );
};

export default TabsWrapper;
