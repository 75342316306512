import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import PeriodQuickFilter from './PeriodQuickFilter';

const firstDayOfPrevMonth = moment()
  .subtract(1, 'months')
  .startOf('month')
  .format(DATE_FORMAT.API_ONLY_DATE);

const lastDayOfPrevMonth = moment()
  .subtract(1, 'months')
  .endOf('month')
  .format(DATE_FORMAT.API_ONLY_DATE);

const PreviousMonthQuickFilter = () => (
  <PeriodQuickFilter fromValue={firstDayOfPrevMonth} toValue={lastDayOfPrevMonth} label="Previous month" />
);

export default PreviousMonthQuickFilter;
