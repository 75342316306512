/* istanbul ignore file */
import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useFinanceUrl } from 'erp/employee/finance/FinanceRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const FinancesBreadcrumbs = ({ children }) => {
  const { getListUrl } = useFinanceUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Finances" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

FinancesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

FinancesBreadcrumbs.defaultProps = {
  children: null,
};

export default FinancesBreadcrumbs;
