import React from 'react';
import { SingleLineNameFieldLayoutType } from 'shared/uibuilder/field/NameField';

const removeUselessSpaces = (str: string) => {
  return str.replace(/  +/g, ' ').trim();
};

const SingleLineNameFieldLayout: SingleLineNameFieldLayoutType = ({
  firstName = '',
  lastName = '',
  middleName = '',
  maidenName = '',
}) => {
  const name = `${firstName || ''} ${middleName || ''} ${lastName || ''} ${maidenName ? `(${maidenName})` : ''}`;
  return <span>{removeUselessSpaces(name)}</span>;
};

export default SingleLineNameFieldLayout;
