/* istanbul ignore file */
import React, { useState } from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import RbsTree from 'erp/rbs/shared/tree/RbsTree';
import CreateRbsNodeButton from 'erp/rbs/shared/button/CreateRbsNodeButton';
import CreateRbsSubnodeButton from 'erp/rbs/shared/button/CreateRbsSubnodeButton';
import useRbsService, { CREATE_RBS_TREE_NODE, hasAccessToCreateAction, RbsNodeType } from '../shared/rbsService';
import EditRbsSubNodeButton from 'erp/rbs/shared/button/EditRbsSubNodeButton';
import RbsListLayout from 'erp/rbs/List/RbsListLayout';
import RbsListViewMode, { RbsViewMode } from 'erp/rbs/shared/RbsListViewMode';
import RbsNodeActionButton from 'erp/rbs/shared/button/RbsNodeActionButton';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import ProjectsStaticFilters from 'erp/project/List/filter/ProjectsStaticFilters';
import ListPageHeaderWrapper from 'financialAnalytic/shared/ListPageHeaderWrapper';

const RbsList = () => {
  const { getRootNodes } = useRbsService();
  const [activeMode, setActiveMode] = useState(RbsViewMode.TREE);
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(getUserAlias);

  return (
    <List getDataMethod={getRootNodes}>
      <ListPageLayout
        header={
          <ListPageHeaderWrapper
            header={
              <ProjectsStaticFilters
                title={
                  <div className="d-flex justify-content-between align-items-start flex-wrap">
                    <ListHeader label="Responsibilities" />
                  </div>
                }
              />
            }
            actions={[<RbsListViewMode onModeChange={newMode => setActiveMode(newMode)} />]}
          />
        }
        actions={[<CreateRbsNodeButton />]}
      >
        {activeMode === RbsViewMode.TREE ? (
          <RbsTree
            nodeChildren={nodeDatum => {
              const { nodeType, id } = nodeDatum;

              return nodeType === RbsNodeType.PROJECT ? (
                <CreateRbsSubnodeButton
                  id={nodeDatum.id}
                  name={nodeDatum.name}
                  isSmall
                  hasAccessToCreate={hasAccessToCreateAction(nodeDatum, userAlias)}
                />
              ) : (
                <EditRbsSubNodeButton id={id as string} />
              );
            }}
            permissionToCheck={CREATE_RBS_TREE_NODE}
          />
        ) : (
          <RbsListLayout
            permissionToCheck={CREATE_RBS_TREE_NODE}
            buttons={[<RbsNodeActionButton permissionToCheck={CREATE_RBS_TREE_NODE} />]}
          />
        )}
      </ListPageLayout>
    </List>
  );
};

export default RbsList;
