/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import SingleProjectBreadcrumbs from 'erp/project/shared/SingleProjectBreadcrumbs';

const StatusesBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  return (
    <SingleProjectBreadcrumbs>
      <SingleBreadcrumbItem entity="Status" />
      {children}
    </SingleProjectBreadcrumbs>
  );
};

export default StatusesBreadcrumbs;
