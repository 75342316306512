import React from 'react';
import { useShowContext } from '../../../../shared/uibuilder/show/ShowContext';
import EnumField from '../../../../shared/uibuilder/field/EnumField';
import { string } from 'prop-types';
import { getTimezonesOptions } from '../../../../shared/timezone';

const TimezoneField = ({ value: inputValue, source }: { value: string; source: string }) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));
  const timezonesOptions = getTimezonesOptions();
  const options = timezonesOptions.reduce((o, { value: key, label }) => ({ ...o, [key]: label }), {});

  return <EnumField label="Timezone" value={value} options={options} />;
};

TimezoneField.propTypes = {
  value: string,
  source: string,
};

TimezoneField.defaultProps = {
  value: null,
  source: null,
};

export default TimezoneField;
