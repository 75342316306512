import React from 'react';
import EntityHeader from 'shared/layout/EntityHeader';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const OfficeHeader = () => {
  const { data } = useShowContext();
  const name = `${data?.getValueBySource('name')}`;

  return <EntityHeader entityTitle={name} />;
};

export default OfficeHeader;
