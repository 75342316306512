/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import ExpenseCurrencyDropdown from 'financialAnalytic/expenses/shared/input/ExpenseCurrencyDropdown';
import InvoiceTotalInput from 'financialAnalytic/invoice/shared/input/InvoiceTotalInput';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import ExpenseSubTypeDropdown from 'financialAnalytic/expenses/shared/input/ExpenseSubTypeDropdown';
import ExpenseTaxSubTypeDropdown from 'financialAnalytic/expenses/shared/input/ExpenseTaxSubTypeDropdown';
import ExpenseTypeDropdown from 'financialAnalytic/expenses/shared/input/ExpenseTypeDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { EXPENSE_TYPES, EXPENSE_SUBTYPES_ALIASES } from 'financialAnalytic/expenses/useExpenseService';
import { EmployeeInput } from 'erp/employee';
import ExpensesLeaveDropdown from 'financialAnalytic/expenses/shared/input/ExpensesLeaveDropdown';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import useLedgerService from 'financialAnalytic/ledger/useLedgerService';
import ExpenseProjectDropdown from 'financialAnalytic/expenses/shared/input/ExpenseProjectDropdown';
import ExpenseOfficeDropdown from 'financialAnalytic/expenses/shared/input/ExpenseOfficeDropdown';
import { buildEmployeeAutoSuggestLabel } from 'erp/employee/shared/input/EmployeeInput';
import { snakeCase } from 'lodash';
import ExpenseStartEndDateInput from 'financialAnalytic/expenses/shared/input/ExpenseStartEndDateInput';

const isPerformanceBonus = (data: any) => data.type === EXPENSE_TYPES.PERFORMANCE_BASED_BONUS;

type ExpenseFormProps = {
  disabledOffice?: boolean;
  isNeedToUploadOfficeByEmployee?: boolean;
};

const ExpenseForm = ({ disabledOffice, isNeedToUploadOfficeByEmployee }: ExpenseFormProps) => {
  const { searchEmployeeExpensesAccount } = useLedgerService();

  return (
    <FormSection>
      <FormRow>
        <ExpenseTypeDropdown source="type" label="Type" />
        <ClearAfterUnmountWrapper
          isVisible={(data: any) =>
            [
              EXPENSE_TYPES.LEAVE_COMPENSATION,
              EXPENSE_TYPES.ONE_TIME_PAYMENT,
              EXPENSE_TYPES.BENEFITS_EXPENSE,
              EXPENSE_TYPES.DEDUCTION,
            ].includes(data.type)
          }
        >
          <ExpenseSubTypeDropdown source="subtype" typeSource="type" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={(data: any) => EXPENSE_TYPES.TAX === data.type}>
          <ExpenseTaxSubTypeDropdown source="subtype" officeSource="office" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={(data: any) => EXPENSE_TYPES.TAX !== data.type}>
          <EmployeeInput
            source="employeeAlias"
            label="Employee"
            nameSource="employeeName"
            mapResults={(employee: any) => ({
              id: employee.alias,
              text: buildEmployeeAutoSuggestLabel(employee),
              office: employee.office ? snakeCase(employee.office).toUpperCase() : null,
            })}
            withContractors
          />
        </ClearAfterUnmountWrapper>
        <ExpenseOfficeDropdown
          employeeSource="employeeAlias"
          source="office"
          placeholder=""
          isNeedToUploadOfficeByEmployee={isNeedToUploadOfficeByEmployee}
          disabled={(data: any) => data.type === EXPENSE_TYPES.TAX || !!disabledOffice}
        />
      </FormRow>

      <FormRow>
        <TextInput source="title" label="Title" />
        <InvoiceTotalInput source="compensation.amount" currencySource="compensation.currency" label="Monetary value" />
        <ExpenseCurrencyDropdown
          disabled={disabledOffice}
          source="compensation.currency"
          label="Currency"
          typeSource="subtype"
        />
        <ClearAfterUnmountWrapper isVisible={(data: any) => data.type === EXPENSE_TYPES.LEAVE_COMPENSATION}>
          <ExpensesLeaveDropdown
            source="leaveId"
            label="Leave"
            employeeSource="employeeAlias"
            typeSource="subtype"
            checkDepending
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper
          isVisible={(data: any) =>
            data.type === EXPENSE_TYPES.DEDUCTION ||
            data.subtype === EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].REIMBURSEMENTS
          }
        >
          <AccountInput
            source="transferAccount"
            label="Account to Debit"
            loadDataMethod={searchEmployeeExpensesAccount}
            cacheKey="employee-expenses-accounts"
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper
          isVisible={(data: any) =>
            data.subtype === EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].SALES_BONUSES_AND_COMMISSIONS ||
            data.subtype ===
              EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].ACCOUNT_MANAGER_BONUSES_AND_COMMISSIONS ||
            data.subtype === EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].CUSTOMER_EXCELLENCE_BONUS
          }
        >
          <ExpenseProjectDropdown source="project.projectId" label="Project" />
        </ClearAfterUnmountWrapper>
      </FormRow>

      <FormRow>
        <DateInput source="registrationDate" />
        <ClearAfterUnmountWrapper isVisible={isPerformanceBonus}>
          <ExpenseStartEndDateInput source="startDate" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={isPerformanceBonus}>
          <ExpenseStartEndDateInput source="endDate" isEndDate />
        </ClearAfterUnmountWrapper>
      </FormRow>

      <BigFormRow>
        <TextArea source="description" />
      </BigFormRow>
    </FormSection>
  );
};

export default ExpenseForm;
