/* istanbul ignore file */
import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Collapse from '@mui/material/Collapse';
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import UpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import IconButton from '@mui/material/IconButton';
import Button from 'uibuilder/button/Button';
import LockIcon from '@mui/icons-material/LockOutlined';
import PlanProjectIssueButton from 'syberryToday/shared/button/PlanProjectIssueButton';
import AddIcon from '@mui/icons-material/AddOutlined';
import ArrowUpIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import useRedmineUrl from 'redmine/useRedmineUrl';
import LinearProgress from 'uibuilder/LinearProgress';
import { BACKLOG_CATEGORIES } from 'syberryToday/useSyberryTodayService';
import './ProjectIssue.scss';

export interface ProjectIssueLayoutProps {
  item: any;
  index: number;
  moveToTop?: Nullable<(dragIndex: number, id: number) => void>;
  showProjectName?: boolean;
  canPlan?: boolean;
  sortable?: boolean;
  isSelected?: boolean;
  projectName?: string;
  subProjectName?: string;
  onPlanTask: () => void;
  unSelectIssue: () => void;
  updateInList: (entity: any) => void;
}

const ProjectIssueLayout = ({
  item,
  index,
  canPlan = true,
  sortable = true,
  projectName,
  subProjectName,
  moveToTop = () => {},
  unSelectIssue,
  onPlanTask,
  isSelected = false,
  showProjectName,
  updateInList,
}: ProjectIssueLayoutProps) => {
  const { getIssueUrl } = useRedmineUrl();
  const { getStringDurationBetweenTwoDates } = useDateService();
  const {
    id,
    priority: { name: priority = '' } = {},
    subject,
    customQueue,
    description,
    dueDate,
    totalEstimatedHours,
    totalSpentHours,
    backlogCategory,
  } = item;
  const isNew = backlogCategory === BACKLOG_CATEGORIES.NEW;
  const isBlocked = backlogCategory === BACKLOG_CATEGORIES.BLOCKED;
  const [checked, setChecked] = useState(false);
  const today = moment();
  const momentDueDate = dueDate ? moment(dueDate).subtract(2, 'days') : null;
  const leftDays = momentDueDate?.isValid()
    ? getStringDurationBetweenTwoDates(today, momentDueDate)?.replace(/-/g, '') || '0 days'
    : null;

  const handleChange = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setChecked(prev => !prev);
  };

  return (
    <div className="project-issue" data-testId={id}>
      <LinearProgress
        maxValue={totalEstimatedHours || 4}
        currentValue={totalSpentHours || 0}
        className="project-issue__progress"
      />

      <div className={classnames('project-issue-title__wrapper', { 'project-issue__wrapper--new': isNew })}>
        <div className={`project-issue__priority ${priority?.toLowerCase() || ''}`} title={priority} />
        <PlanProjectIssueButton
          checked={isSelected}
          onSelect={onPlanTask}
          onUnSelect={unSelectIssue}
          disabled={!canPlan}
        />
        <p className="project-issue__title">
          {subject}{' '}
          {showProjectName ? (
            <b className="project-issue__project-name">{`- ${customQueue?.name || 'No queue'} (${projectName}${
              subProjectName ? ` / ${subProjectName}` : ''
            })`}</b>
          ) : null}
        </p>
        {isBlocked && <LockIcon sx={{ ml: '16px', fontSize: '21px' }} />}
        {!!index && sortable && moveToTop && (
          <IconButton
            onClick={() => {
              moveToTop(index, id);
            }}
            className="move-to-top-button"
            title="Move to top"
            sx={{ ml: isBlocked ? '5px' : '16px', mr: isBlocked ? '-8px' : 0 }}
          >
            <ArrowUpIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        )}
        <IconButton className="project-issue__details-toggle" onClick={handleChange} sx={{ ml: isBlocked ? '5px' : 0 }}>
          {checked ? <UpIcon /> : <DownIcon />}
        </IconButton>
        {isNew && <span className="project-issue__new-label" title="New task" />}
      </div>

      <Collapse in={checked}>
        <div className="project-issue-details__wrapper">
          <div className="project-issue__details">
            <div className="project-issue__description">{description || '—'}</div>
            {leftDays && (
              <div className="project-issue__days-left">
                {leftDays.replace(/(\d) +(?=)/g, '$1\u00A0')}{' '}
                {dueDate === today.format(DATE_FORMAT.API_ONLY_DATE) || momentDueDate?.isAfter(today) ? 'left' : 'late'}
              </div>
            )}
          </div>
          <div className="project-issue__details">
            <div className="project-issue__job-number">#{id}</div>
          </div>
        </div>
        <Button
          component="a"
          href={getIssueUrl(id)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            if (backlogCategory === BACKLOG_CATEGORIES.NEW) {
              updateInList({ ...item, backlogCategory: BACKLOG_CATEGORIES.DO_LATER });
            }
          }}
          className="project-issue__more-button"
          startIcon={<AddIcon />}
        >
          More
        </Button>
      </Collapse>
    </div>
  );
};

export default ProjectIssueLayout;
