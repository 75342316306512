import React from 'react';
import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_CORPORATE_EVENT } from 'financialAnalytic/corporateEvents/useCorporateEventService';

const CreateCorporateEventButton = () => {
  const { getCreateUrl } = useCorporateEventUrl();

  return (
    <CreateButton
      url={getCreateUrl}
      label="Add Corporate Event"
      key="create-event"
      permissionToCheck={CREATE_CORPORATE_EVENT}
    />
  );
};

export default CreateCorporateEventButton;
