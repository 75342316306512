import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { isArray } from 'lodash';

export interface ExpandingFieldProps extends FieldProps {
  maxValueLength?: number;
  children: React.ReactElement;
}

const ExpandingField = ({ maxValueLength = 78, children, ...props }: ExpandingFieldProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { getValue } = useFieldHelper(props);
  const value = getValue();
  const shortValue = isArray(value) ? value?.slice(0, maxValueLength) : `${value?.slice(0, maxValueLength).trim()}...`;

  const toggleVisibility = (e: any) => {
    e.target.blur();
    setIsVisible(prev => !prev);
  };

  if (!value || value?.length <= maxValueLength) {
    return children;
  }

  return (
    <>
      {React.cloneElement(children, {
        ...props,
        value: isVisible ? value : shortValue,
      })}
      <Button color="neutralLight" size="small" sx={{ lineHeight: 1 }} onClick={toggleVisibility}>
        {isVisible ? 'Hide' : 'Show'}
      </Button>
    </>
  );
};

export default ExpandingField;
