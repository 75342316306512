import { useRef, useState, useLayoutEffect, MutableRefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type HeightParams = {
  ref: MutableRefObject<Nullable<HTMLElement>>;
  height: number;
};

/**
 * Method that getting height of element, that have ref
 *
 * @returns {{ref - ref that must be set to element, {number} height - height of element}}
 */
const useHeight = (): HeightParams => {
  const ref = useRef<Nullable<HTMLElement>>(null);
  const [height, setHeight] = useState(0);

  const [resizeObserver] = useState(
    () =>
      new ResizeObserver(() => {
        if (!ref.current) {
          return;
        }

        const { offsetHeight } = ref.current;

        if (height !== offsetHeight) {
          setHeight(offsetHeight);
        }
      }),
  );

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, [resizeObserver]);

  return { ref, height };
};

export default useHeight;
