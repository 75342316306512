/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import { func } from 'prop-types';
import React from 'react';
import NoteForm from 'erp/candidate/Timeline/note/shared/form/NoteForm';

const CreateNoteForm = ({ service, submitFormFunc, onCancel, ...props }) => {
  const { getValidationSchema } = service();
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

CreateNoteForm.propTypes = {
  service: func.isRequired,
  submitFormFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default CreateNoteForm;
