/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import { ListItem } from 'shared/uibuilder/list/ListContext';

interface EditBudgetButtonProps extends EditButtonProps {
  isVisible?: (data: ListItem) => boolean;
}

const EditBudgetButton = (props: EditBudgetButtonProps) => {
  const { getUpdateEntityUrl } = useBudgetUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditBudgetButton;
