/* istanbul ignore file */
const positiveNumberRegEx = /^((?!^0+$|0+[.]0+$)0|[1-9]\d{0,5})(?:[.]\d{1,2})?$/;
const getPerformanceDetailsValidation = (
  helpfulnessGradeSource: string,
  evaluationGradeSource: string,
  oldModelSource?: string,
  oldModelValue?: boolean,
) => ({
  type: 'object',
  required: oldModelSource
    ? {
        value: true,
        when: {
          [helpfulnessGradeSource]: {
            eq: null,
          },
          [oldModelSource]: {
            eq: oldModelValue,
          },
        },
      }
    : {
        value: true,
        when: {
          [helpfulnessGradeSource]: {
            eq: null,
          },
        },
      },
  properties: {
    evaluationGrade: {
      type: 'enum',
      required: oldModelSource
        ? {
            value: true,
            when: {
              [helpfulnessGradeSource]: {
                eq: null,
              },
              [oldModelSource]: {
                eq: oldModelValue,
              },
            },
          }
        : {
            value: true,
            when: {
              [helpfulnessGradeSource]: {
                eq: null,
              },
            },
          },
    },
    confidenceGrade: {
      type: 'enum',
      required: {
        value: true,
        when: {
          [evaluationGradeSource]: {
            in: ['A', 'B', 'C'],
          },
        },
      },
    },
  },
});

export default {
  relatedProcessId: {
    type: 'enum',
    required: true,
  },
  type: {
    type: 'enum',
    required: true,
  },
  modelBased: {
    type: 'boolean',
    required: true,
  },
  currency: {
    type: 'enum',
    required: false,
  },
  baseAmountType: {
    type: 'enum',
    required: false,
  },
  grossAmount: {
    type: 'string',
    regex: {
      value: positiveNumberRegEx,
      message: 'Must be a positive number (max 6 digits and 2 decimals)',
    },
  },
  qualificationDescription: {
    type: 'string',
    required: false,
  },
  helpfulnessGrade: {
    type: 'string',
    required: {
      value: true,
      when: {
        $performanceDetails: {
          eq: null,
        },
      },
    },
  },
  performanceDetails: {
    type: 'object',
    required: {
      value: true,
      when: {
        $helpfulnessGrade: {
          eq: null,
        },
      },
    },
    properties: {
      culture: getPerformanceDetailsValidation('$helpfulnessGrade', '$performanceDetails.culture.evaluationGrade'),
      processFollowing: getPerformanceDetailsValidation(
        '$helpfulnessGrade',
        '$performanceDetails.processFollowing.evaluationGrade',
      ),
      productivity: getPerformanceDetailsValidation(
        '$helpfulnessGrade',
        '$performanceDetails.productivity.evaluationGrade',
      ),
      businessOutcomes: getPerformanceDetailsValidation(
        '$helpfulnessGrade',
        '$performanceDetails.businessOutcomes.evaluationGrade',
        '$performanceDetails.oldModel',
        false,
      ),
      workQuality: getPerformanceDetailsValidation(
        '$helpfulnessGrade',
        '$performanceDetails.workQuality.evaluationGrade',
        '$performanceDetails.oldModel',
        true,
      ),
      processImprovement: getPerformanceDetailsValidation(
        '$helpfulnessGrade',
        '$performanceDetails.processImprovement.evaluationGrade',
        '$performanceDetails.oldModel',
        true,
      ),
    },
  },
  specializationDetails: {
    type: 'object',
    required: {
      value: true,
      when: {
        $modelBased: {
          eq: true,
        },
      },
    },
    properties: {
      specializationBranchId: {
        type: 'int',
        required: {
          value: true,
          when: {
            $modelBased: {
              eq: true,
            },
          },
        },
      },
      title: {
        type: 'string',
        required: false,
      },
      qualifications: {
        type: 'array',
        required: {
          value: true,
          when: {
            $modelBased: {
              eq: true,
            },
          },
        },
        forEach: {
          type: 'object',
          _divisible: false,
          properties: {
            id: {
              type: 'int',
              required: {
                value: true,
                when: {
                  '$specializationDetails.specializationBranchId': {
                    notEmpty: true,
                  },
                },
              },
            },
            title: {
              type: 'string',
              required: {
                value: true,
                when: {
                  '$specializationDetails.specializationBranchId': {
                    notEmpty: true,
                  },
                },
              },
            },
            specializationLevelId: {
              type: 'int',
              required: {
                value: true,
                when: {
                  '$specializationDetails.specializationBranchId': {
                    notEmpty: true,
                  },
                },
              },
            },
            completeness: {
              type: 'int',
              required: {
                value: true,
                when: {
                  '$specializationDetails.specializationBranchId': {
                    notEmpty: true,
                  },
                },
              },
              maxValue: {
                value: 100,
                message: 'Allowed percent range: 0 - 100',
              },
              minValue: {
                value: 0,
                message: 'Allowed percent range: 0 - 100',
              },
            },
          },
        },
      },
    },
  },
};
