import React from 'react';
import TrainingHeader from '../shared/TrainingHeader';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import SingleTrainingBreadcrumbsItem from './SingleTrainingBreadcrumbsItem';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { BooleanField, ListField, TextField } from 'shared/uibuilder/field';
import CompetenciesField from '../shared/fileds/CompetenciesField';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import DeleteTrainingButton from '../shared/buttons/DeleteTrainingButton';
import EditTrainingButton from '../shared/buttons/EditTrainingButton';
import TrainingDurationField from './TrainingDurationField';

const ViewTrainingContent = () => {
  return (
    <ShowPageLayout
      entityHeader={<TrainingHeader />}
      breadcrumbs={<SingleTrainingBreadcrumbsItem />}
      headerButtons={[<EditTrainingButton />, <DeleteTrainingButton />]}
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField source="name" label="Name" />

          <BooleanField source="isInternal" label="Internal" />

          <TrainingDurationField source="durationInMinutes" />
        </SectionRow>

        <SectionRow>
          <TextField source="location.name" label="Location" isSortable />

          <ListField source="competencies">
            <CompetenciesField />
          </ListField>
        </SectionRow>

        <BigSectionRow>
          <TextField label="description" source="Description" />
        </BigSectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewTrainingContent;
