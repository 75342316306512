import LinkButton from 'uibuilder/button/LinkButton';
import React from 'react';
import { useOfficeUrl } from 'erp/office/OfficeRouter';

const ViewOfficeButton = (props: any) => {
  const { getSingleEntityUrl } = useOfficeUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-room" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewOfficeButton;
