/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useRbsUrl } from 'erp/rbs/RbsRouter';

interface RbsBreadcrumbsProps {
  children: React.ReactNode | React.ReactNode[];
}

const RbsBreadcrumbs = ({ children }: RbsBreadcrumbsProps) => {
  const { getListUrl } = useRbsUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Responsibilities" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default RbsBreadcrumbs;
