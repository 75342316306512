/* istanbul ignore file */
export default {
  from: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$NOW',
      message: 'Cost Accounting Report cannot be in future',
    },
  },
  to: {
    type: 'date',
    required: true,
    minDate: {
      value: '$from',
      message: 'Please choose date after or at start date',
    },
    maxDate: {
      value: '$NOW',
      message: 'Cost Center Report cannot be in future',
    },
  },
};
