/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import ExpenseBreadcrumbs from 'financialAnalytic/expenses/shared/ExpenseBreadcrumbs';

const CreateExpenseBreadcrumbs = () => {
  return (
    <ExpenseBreadcrumbs>
      <CreateBreadcrumbItem entity="Expense" />
    </ExpenseBreadcrumbs>
  );
};

export default CreateExpenseBreadcrumbs;
