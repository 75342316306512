import React from 'react';
import { FEEDBACK_FLAGS } from 'erp/employee/Timeline/feedback/shared/input/FlagAsInappropriateRadioButtonGroup';
import Data from 'shared/uibuilder/Data';

import './index.scss';

type FeedbackInappropriateReasonProps = {
  entity: Data;
};

const FeedbackInappropriateReason = ({ entity }: FeedbackInappropriateReasonProps) => {
  const flag = entity.getValueBySource('inappropriateContentType');
  // @ts-ignore
  const displayedFlag = FEEDBACK_FLAGS[flag];

  if (!displayedFlag) {
    return null;
  }

  return (
    <div className="inappropriate-reason">
      <i className="fa fa-flag mr-1" aria-hidden />
      {displayedFlag}
    </div>
  );
};

export default FeedbackInappropriateReason;
