/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import ButtonWithConfirmation, { ButtonWithConfirmationProps } from 'uibuilder/button/ButtonWithConfirmation';

const ApplyCompetencyButton = ({ children, outline, ...props }: ButtonWithConfirmationProps) => {
  return (
    <ButtonWithConfirmation
      {...props}
      outline={outline}
      className={classnames('mr-0 w-100 apply-competency-status-btn', { 'btn-outline': outline })}
      modalConfirmButtonClassName="apply-competency-status-btn"
      modalConfirmButtonText={children}
    >
      <i className="fa fa-angle-double-left mr-2" aria-hidden="true" />
      {children}
      <i className="fa fa-angle-double-left ml-2" aria-hidden="true" />
    </ButtonWithConfirmation>
  );
};

export default ApplyCompetencyButton;
