import React from 'react';
import { useRoomUrl } from 'erp/room/RoomRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewRoomButton = (props: any) => {
  const { getSingleEntityUrl } = useRoomUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-room" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewRoomButton;
