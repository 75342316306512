/* istanbul ignore file */
import React from 'react';
import { string, node, oneOfType, arrayOf } from 'prop-types';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useCandidateService from 'erp/candidate/shared/candidateService';

export const CANDIDATE_CONTEXT = 'Candidate';

const CandidateContext = ({ permissionToCheck, children }) => {
  const { getPermissions } = useCandidateService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CANDIDATE_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

CandidateContext.propTypes = {
  permissionToCheck: oneOfType([string, arrayOf(string)]).isRequired,
  children: node.isRequired,
};

export default CandidateContext;
