/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import RbsNodeInput from 'erp/rbs/shared/input/RbsNodeInput';
import { TextInput, TextArea, Checkbox } from 'shared/uibuilder/form/input';
import TeamInput from 'erp/rbs/shared/input/TeamInput';
import RbsTypeInput from 'erp/rbs/shared/input/RbsTypeInput';
import { CREATE_RBS_TREE_NODE, isNodeOwner, RbsNodeType } from 'erp/rbs/shared/rbsService';
import useFeatureToggle, { Features } from 'featuretoggle';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAuthorization from 'shared/authorization/authorizationService';
import useAsyncValue from 'shared/asyncHelper';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';

const RbsForm = ({ isTypeInputHidden, isCreateForm }: { isTypeInputHidden?: boolean; isCreateForm?: boolean }) => {
  const { isFeatureEnabled } = useFeatureToggle();
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());
  const { isGranted } = useAuthorization();
  const canEditFullData = isGranted(CREATE_RBS_TREE_NODE);

  const disableOwnerFields = (data: any) => {
    const isParentNodeOwner = isNodeOwner(data.parentNodeOwnerAlias, userAlias);
    const isCurrentNodeOwner = isNodeOwner(data.ownerId, userAlias);

    return !canEditFullData && data.nodeType === RbsNodeType.QUEUE && !isParentNodeOwner && isCurrentNodeOwner;
  };

  const showPublicServiceCheckbox = (data: any) => {
    return isFeatureEnabled(Features.EMPLOYEE_GROUP) && data.nodeType === RbsNodeType.PROJECT;
  };

  return (
    <>
      <FormSection title="Responsibility information">
        <FormRow>
          <RbsTypeInput
            source="nodeType"
            isVisible={!isTypeInputHidden && isFeatureEnabled(Features.CREATE_QUEUE) && canEditFullData}
          />
        </FormRow>
        <FormRow>
          <TextInput source="name" label="Name" />
          <RbsNodeInput
            source="parentNodeId"
            label="Parent responsibility"
            nameSource="parentNodeName"
            isVisible={data => data.hasParentNode || data.parentNodeName}
            disabled={!canEditFullData}
          />
        </FormRow>
        <FormRow>
          <NotDismissedEmployeeInput
            source="attributes.owner.alias"
            label="Owner"
            nameSource="ownerName"
            disabled={disableOwnerFields}
          />
          <NotDismissedEmployeeInput
            source="attributes.secondOwner.alias"
            label="Second Owner"
            nameSource="secondOwnerName"
            errorSource="secondOwner"
            disabled={disableOwnerFields}
            isVisible={data => data.nodeType === RbsNodeType.PROJECT}
          />
          <TextInput
            source="attributes.alias"
            label="Alias"
            errorSource="projectAlias"
            isVisible={data => data.nodeType === RbsNodeType.PROJECT}
          />
          <TeamInput
            source="attributes.team"
            label="Team"
            nameSource="fullName"
            isVisible={data => data.nodeType === RbsNodeType.QUEUE}
            isCreateForm={isCreateForm}
          />
        </FormRow>
        <FormRow colXl={6} colMd={12}>
          <TextArea source="attributes.goal" />
        </FormRow>
        <FormRow colXl={6} colMd={12}>
          <ClearAfterUnmountWrapper isVisible={showPublicServiceCheckbox}>
            <Checkbox
              source="isPublicService"
              label="Would you like to create a Public Service based on this RbsNode?"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>
    </>
  );
};

RbsForm.defaultProps = {
  isCreateForm: false,
};

export default RbsForm;
