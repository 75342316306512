/* istanbul ignore file */
import React from 'react';
import { UPDATE_EMPLOYEE_COMPETENCY } from 'erp/employee/employeeService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { FieldProps } from 'shared/uibuilder/field';
import ColoredCompetencyStatusField from 'erp/employee/newQualification/shared/field/ColoredCompetencyStatusField';
import CompetencyStatusField from 'erp/employee/newQualification/shared/field/CompetencyStatusField';
import EditCompetencyStatusButton from 'erp/employee/newQualification/shared/button/EditCompetencyStatusButton';
import { EmployeeNewCompetencyLevel } from 'erp/employee/newQualification/shared/employeeNewCompetencyService';

interface EditableCompetencyStatusFieldProps extends FieldProps {
  levelTitle: string;
  idSource: string;
}

const EditableCompetencyStatusField = ({ idSource, levelTitle, ...props }: EditableCompetencyStatusFieldProps) => {
  const competencyLevel = useShowContext()?.data?.getData();

  return (
    <div className="d-flex align-items-center">
      <ColoredCompetencyStatusField className="current-status" {...props}>
        <CompetencyStatusField source="status" />
      </ColoredCompetencyStatusField>
      <EditCompetencyStatusButton
        competencyLevel={competencyLevel as EmployeeNewCompetencyLevel}
        permissionToCheck={UPDATE_EMPLOYEE_COMPETENCY}
        levelTitle={levelTitle}
        levelId={competencyLevel.id}
      />
    </div>
  );
};

export default EditableCompetencyStatusField;
