/* istanbul ignore file */
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import EmployeeMilitaryContingentField from 'erp/employee/shared/field/EmployeeMilitaryContingentField';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { string } from 'prop-types';

const EmployeeMilitaryField = ({ source }) => {
  return (
    <SectionRow>
      <TextField label="Registration Group" source={`${source}.registrationGroup`} />
      <TextField label="Registration Category" source={`${source}.registrationCategory`} />
      <EmployeeMilitaryContingentField label="Contingent" source={`${source}.militaryContingent`} />
      <TextField label="Military Specialization" source={`${source}.specialization`} />
      <TextField label="Suitability" source={`${source}.militarySuitability`} />
      <TextField label="Title" source={`${source}.title`} />
      <TextField label="Special Registration Number" source={`${source}.registrationNumber`} />
      <TextField label="Military Commissariat" source={`${source}.militaryCommissariat`} />
    </SectionRow>
  );
};

EmployeeMilitaryField.propTypes = {
  source: string,
};

EmployeeMilitaryField.defaultProps = {
  source: undefined,
};

export default EmployeeMilitaryField;
