/* istanbul ignore file */
import React from 'react';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import {
  ProcessInstanceShowPage,
  ProcessInstanceRedirect,
  IssuesTable,
  ProcessVariables,
} from 'camunda/monitoring/instance/Show';
import { READ_INSTANCES_LIST } from 'camunda/monitoring/instance/processInstanceService';
import { ProcessInstanceListPage } from 'camunda/monitoring/instance/List';
import ProcessHistory from 'camunda/monitoring/instance/Show/history/ProcessHistory';
import Subprocesses from 'camunda/monitoring/instance/Show/subprocesses/Subprocesses';
import SupportIssuesTable from 'camunda/monitoring/instance/Show/support/SupportIssuesTable';
import { MODIFY_INSTANCE_TOKEN_POSITIONS } from 'camunda/monitoring/instance/Show/token/processInstanceTokenService';
import ModificationTokenPositions from 'camunda/monitoring/instance/Show/token/ModificationTokenPositions';
import { RESOLVE_PROCESS_INCIDENT } from 'camunda/monitoring/instance/Show/incident/processIncidentService';
import IncidentsTable from 'camunda/monitoring/instance/Show/incident/IncidentsTable';

export const PROCESS_INSTANCE_PATH = '/processes/instances';

export const useProcessInstanceRoute = () => {
  const routers = useRoute({ listRoute: PROCESS_INSTANCE_PATH });
  return {
    ...routers,
    historyRoute: `${routers.singleEntityRoute}/history`,
    issuesRoute: `${routers.singleEntityRoute}/issues`,
    variablesRoute: `${routers.singleEntityRoute}/variables`,
    subprocessesRoute: `${routers.singleEntityRoute}/subprocesses`,
    supportIssuesRoute: `${routers.singleEntityRoute}/support`,
    tokenModificationsRoute: `${routers.singleEntityRoute}/token-modifications`,
    incidentsRoute: `${routers.singleEntityRoute}/incidents`,
  };
};

export const useProcessInstanceUrl = () => {
  const { getSingleEntityUrl, ...rest } = useEntityUrl({ baseLink: PROCESS_INSTANCE_PATH });
  const getHistoryUrl = (id: string): string => `${getSingleEntityUrl(id)}/history`;
  const getIssuesUrl = (id: string): string => `${getSingleEntityUrl(id)}/issues`;
  const getSubprocessesUrl = (id: string): string => `${getSingleEntityUrl(id)}/subprocesses`;
  const getSupportIssuesUrl = (id: string): string => `${getSingleEntityUrl(id)}/support`;
  const getVariablesUrl = (id: string, context?: string): string => {
    return `${getSingleEntityUrl(id)}/variables${context ? `?context=${context}` : ''}`;
  };
  const getTokenModificationsUrl = (id: string): string => `${getSingleEntityUrl(id)}/token-modifications`;
  const getIncidentsUrl = (id: string): string => `${getSingleEntityUrl(id)}/incidents`;

  return {
    getSingleEntityUrl,
    ...rest,
    getHistoryUrl,
    getIssuesUrl,
    getVariablesUrl,
    getSubprocessesUrl,
    getSupportIssuesUrl,
    getTokenModificationsUrl,
    getIncidentsUrl,
  };
};

export const useProcessInstanceId = () => useParams<Dictionary<string>>().id;

const ProcessInstanceRouter: Router = () => {
  const {
    listRoute,
    singleEntityRoute,
    historyRoute,
    issuesRoute,
    variablesRoute,
    subprocessesRoute,
    supportIssuesRoute,
    tokenModificationsRoute,
    incidentsRoute,
  } = useProcessInstanceRoute();

  return (
    <ProtectedRouter basePath={PROCESS_INSTANCE_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceListPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceRedirect />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={historyRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceShowPage>
            <ProcessHistory />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={issuesRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceShowPage>
            <IssuesTable />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={variablesRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceShowPage>
            <ProcessVariables />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={subprocessesRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceShowPage>
            <Subprocesses />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={supportIssuesRoute} exact globalPermissionToCheck={READ_INSTANCES_LIST}>
          <ProcessInstanceShowPage>
            <SupportIssuesTable />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <GlobalProtectedRoute
          path={tokenModificationsRoute}
          exact
          globalPermissionToCheck={MODIFY_INSTANCE_TOKEN_POSITIONS}
        >
          <ProcessInstanceShowPage>
            <ModificationTokenPositions />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={incidentsRoute} exact globalPermissionToCheck={RESOLVE_PROCESS_INCIDENT}>
          <ProcessInstanceShowPage>
            <IncidentsTable />
          </ProcessInstanceShowPage>
        </GlobalProtectedRoute>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

ProcessInstanceRouter.getRouterPath = () => PROCESS_INSTANCE_PATH;

export default ProcessInstanceRouter;
