import React from 'react';
import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewCorporateEventButton = (props: any) => {
  const { getSingleEntityUrl } = useCorporateEventUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-corporate-event" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewCorporateEventButton;
