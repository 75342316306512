import React from 'react';
import OneStopSearchResultsLayout from 'oneStop/List/OneStopSearchResultsLayout';
import SearchInput from 'shared/uibuilder/list/search/SearchWithKeyboardNavigation';

type KeyboardNavigationWrapperProps = {
  isSearchMode: boolean;
};

const SearchWithKeyboardNavigation = ({ isSearchMode }: KeyboardNavigationWrapperProps) => {
  return (
    <SearchInput
      className="onestop-search-wrapper mb-3"
      searchResultsLayout={OneStopSearchResultsLayout}
      isSearchMode={isSearchMode}
      placeholder="Search by your request type, process or solution"
    />
  );
};

export default SearchWithKeyboardNavigation;
