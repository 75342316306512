/* istanbul ignore file */
import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { BooleanField, DateField, TextField } from 'shared/uibuilder/field';
import {
  ContractPositionField,
  ContractEndDateField,
  ContractAssignmentTypeField,
  ContractWorkRateField,
} from 'erp/contracts';
import { EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import ContractTypeField from './field/ContractTypeField';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import { isPolishOfficeForm } from 'erp/employee/contracts/createupdate/EmployeeContractForm';
import PaidLeaveBalanceField from './field/PaidLeaveBalanceField';
import BusinessRulesViolatedField from 'erp/leave/shared/input/BusinessRulesViolatedField';
import BusinessRulesListField from 'erp/leave/shared/field/BusinessRulesListField';
import useContractsErrorMap from 'erp/employee/contracts/errorHandlers';
import PreviousPaidLeaveBalanceField from 'erp/contracts/shared/field/PreviousPaidLeaveBalanceField';
import { isEmployeeContract } from 'erp/employee/contracts/shared/employeeContractsService';
import useFeatureToggle, { Features } from 'featuretoggle';
import ContractDurationField from 'erp/contracts/shared/field/ContractDurationField';

const isPreviousPaidLeaveBalanceExist = data => {
  return data.previousPaidLeaveBalance !== null;
};

const ContractsViewFields = () => {
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  return (
    <>
      <SectionRow>
        <EmployeeOfficeField source="officeId" label="Registered Office" />
        <TextField source="number" label="Contract Number" />
        <ContractPositionField source="position" label="Contract Position" />
        <ContractTypeField source="type" label="Contract Type" />
        <ContractDurationField dateFromSource="startDate" dateToSource="endDate" label="Contract Duration" />
        <PaidLeaveBalanceField source="paidLeaveBalance" label="Paid Leave Balance" isVisible={isPolishOfficeForm} />
        <PreviousPaidLeaveBalanceField
          source="previousPaidLeaveBalance"
          label="Start Paid Leave Balance"
          tooltip="Paid Leave Balance for the first calendar year"
          isVisible={isPolishOfficeForm && isEmployeeContract && isPreviousPaidLeaveBalanceExist}
        />
        <DateField source="dateOfSignature" label="Date of Signature" />
        <DateField source="startDate" label="Contract Start Date" />
        <ContractEndDateField source="endDate" label="Contract End Date" />
        <ContractAssignmentTypeField source="assignmentType" label="Assignment Type" />
        <ContractWorkRateField
          source="workRate"
          label="Work Rate"
          isVisible={data => data.getValueBySource('assignmentType') === EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS.PART_TIME}
        />
        <BooleanField source="hasProbationPeriod" label="Probation Period" />
        <DateField
          source="probationPeriodEndedAt"
          label="Probation Period End Date"
          isVisible={data => data.getValueBySource('hasProbationPeriod')}
        />
      </SectionRow>
      <SectionRow>
        <BusinessRulesViolatedField source="violatedBusinessRules" label="Business Rules Violated" />
        <BusinessRulesListField
          source="violatedBusinessRules"
          label="Violated Business Rules"
          isVisible={data =>
            !!data.getValueBySource('violatedBusinessRules') && !!data.getValueBySource('violatedBusinessRules').length
          }
          errorMap={useContractsErrorMap(true)}
        />
        <TextField source="comment" label="Rules Violation Reason" />
        <BooleanField source="secondary" isVisible={twoContractEnabled} />
      </SectionRow>
      <BigSectionRow>
        <AttachmentsListField source="scansIds" label="Contract Scans" />
      </BigSectionRow>
    </>
  );
};

export default ContractsViewFields;
