/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import {
  VACANCY_QUESTION_TYPES,
  VACANCY_QUESTION_TYPES_ALIASES,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import useFeatureToggle, { Features } from '../../../../../../featuretoggle';

const VacancyQuestionTypeInput = ({ source, label }: { source: string; label: string }) => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isFreeFormEnabled = isFeatureEnabled(Features.FREE_FORM_VACANCY_FORM_FIELD);
  const options = isFreeFormEnabled
    ? VACANCY_QUESTION_TYPES
    : Object.fromEntries(
        Object.entries(VACANCY_QUESTION_TYPES).filter(
          ([key, value]) => key !== VACANCY_QUESTION_TYPES_ALIASES.FREE_FORM,
        ),
      );

  return <EnumDropdown options={options} source={source} label={label} placeholder="Select..." />;
};

export default VacancyQuestionTypeInput;
