import useRoomService from 'erp/room/useRoomService';
import React from 'react';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateRoomBreadcrumbs from 'erp/room/createupdate/Create/Breadcrumbs';
import RoomForm from 'erp/room/createupdate/RoomForm';
import { useRoomUrl } from 'erp/room/RoomRouter';

const CreateRoomPage = () => {
  const { create, getValidationSchema } = useRoomService();
  const { getSingleEntityUrl } = useRoomUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: ({ id }) => getSingleEntityUrl(id),
        message: 'You are awesome! The Room has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{}}
    >
      <CreatePageLayout breadcrumbs={<CreateRoomBreadcrumbs />} title="Create: Room">
        <RoomForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateRoomPage;
