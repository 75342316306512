/* istanbul ignore file */
import React from 'react';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import { useEmployeePerformanceUrl } from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateEmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/Create/CreateBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeePerformanceForm from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/EmployeePerformanceForm';

const CreateEmployeePerformancePage = () => {
  const { create, getValidationSchema } = useEmployeePerformanceService();
  const { getListUrl } = useEmployeePerformanceUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Performance Result has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        performanceCriteria: null,
        performanceLevelCriteria: {
          helpfulnessGrade: null,
        },
      }}
    >
      <CreatePageLayout
        title="Create: Performance Result"
        breadcrumbs={<CreateEmployeePerformanceBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
      >
        <EmployeePerformanceForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateEmployeePerformancePage;
