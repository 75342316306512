/* istanbul ignore file */
import React from 'react';
import CountDownTimer from 'shared/uibuilder/CountDownTimer';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';

interface TimeInProgressFieldProps {
  elapsedTimeSource: string;
  completedAtSource: string;
  [key: string]: any;
}

const TimeInProgressField = ({ elapsedTimeSource, completedAtSource }: TimeInProgressFieldProps) => {
  const { getValue: getElapsedTime } = useFieldHelper({ source: elapsedTimeSource });
  const { getValue: getCompletedAt } = useFieldHelper({ source: completedAtSource });
  const elapsedTime = getElapsedTime();
  const completedAt = getCompletedAt();
  const { getMomentDateObjAsUtc } = useDateService();

  return (
    <CountDownTimer
      targetDate={getMomentDateObjAsUtc(elapsedTime).format(DATE_FORMAT.FULL_WITH_SECONDS_AND_TIMEZONE)}
      stopDatetime={
        completedAt ? getMomentDateObjAsUtc(completedAt).format(DATE_FORMAT.FULL_WITH_SECONDS_AND_TIMEZONE) : ''
      }
      shouldCountFromTargetDate
      showMonths={false}
      isExpired={false}
    />
  );
};

export default TimeInProgressField;
