/* istanbul ignore file */
import React from 'react';
import { DOCUMENTS } from 'erp/employee/employeeService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const DocumentsDropdown = props => {
  return <EnumDropdown placeholder="Select type" options={DOCUMENTS} {...props} />;
};

export default DocumentsDropdown;
