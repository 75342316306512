/* istanbul ignore file */
import React from 'react';
import SingleBackgroundCheckBreadcrumbs from 'erp/employee/backgroundcheck/shared/SingleBackgroundCheckBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateEmployeeBackgroundCheckBreadCrumbs = () => {
  return (
    <SingleBackgroundCheckBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleBackgroundCheckBreadcrumbs>
  );
};

export default UpdateEmployeeBackgroundCheckBreadCrumbs;
