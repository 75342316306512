/* istanbul ignore file */
import React from 'react';
import { string, node } from 'prop-types';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useAuthorization from 'shared/authorization/authorizationService';
import { useArtifactStorageId } from 'artifactStorage/ArtifactStorageRouter';

export const ARTIFACT_STORAGE_FILE_CONTEXT = 'ArtifactStorageFile';

const ArtifactStorageFileContext = ({ permissionToCheck, children }) => {
  const { getPermissions } = useAuthorization();
  const fileId = useArtifactStorageId();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={ARTIFACT_STORAGE_FILE_CONTEXT}
      id={fileId}
    >
      {children}
    </SecurityContextTemplate>
  );
};

ArtifactStorageFileContext.propTypes = {
  permissionToCheck: string.isRequired,
  children: node.isRequired,
};

export default ArtifactStorageFileContext;
