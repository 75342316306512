import React from 'react';
import { has } from 'lodash';
import { usePrepopulationContext } from 'erp/employee/prepopulation/PrepopulationContext';
import './index.scss';

export const CLASS_NAME = 'input-prepopulated';

const PrepopulationWrapper = ({ children }) => {
  const { data } = usePrepopulationContext();

  const className = source => {
    const prepopulatedValue = has(data, source);

    if (prepopulatedValue) {
      return CLASS_NAME;
    }

    return '';
  };

  return React.cloneElement(children, {
    className,
  });
};

export default PrepopulationWrapper;
