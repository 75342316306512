/* istanbul ignore file */
import React from 'react';
import SectionRow, { SectionRowProps } from 'shared/uibuilder/layout/SectionRow';

const BigSectionRow = ({ children, ...props }: SectionRowProps) => {
  return (
    <SectionRow colMd={12} colXl={12} {...props}>
      {children}
    </SectionRow>
  );
};

export default BigSectionRow;
