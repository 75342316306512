/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { snakeCase } from 'lodash';
import useEmployeeService from 'erp/employee/employeeService';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import InputLoader from 'shared/uibuilder/InputLoader';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const ExpenseOfficeDropdown = ({
  employeeSource,
  isNeedToUploadOfficeByEmployee = true,
  ...props
}: DefaultDropdownProps & { employeeSource: string; isNeedToUploadOfficeByEmployee?: boolean }) => {
  const { searchWithContractors } = useEmployeeService();
  const { getRawOnChangeCallback, getValue } = useInputHelper({ source: employeeSource });
  const rawOnChangeCallback = getRawOnChangeCallback();
  const employeeAlias = getValue();
  const [loading, setLoading] = useState(!!employeeAlias);

  useEffect(() => {
    const loadData = async () => {
      if (!employeeAlias || !isNeedToUploadOfficeByEmployee) {
        setLoading(false);
        return;
      }

      const { result } = (await searchWithContractors({
        filter: {
          alias: { in: [employeeAlias] },
        },
      })) || { result: [] };
      const office = result[0]?.office;

      if (rawOnChangeCallback) {
        rawOnChangeCallback({
          office: office ? snakeCase(office).toUpperCase() : null,
        });
      }

      setLoading(false);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <InputLoader /> : <OfficeDropdown {...props} />;
};

export default ExpenseOfficeDropdown;
