/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import CandidateOfferBreadcrumbs from 'erp/candidate/offercompensation/shared/CandidateOfferBreadcrumbs';

const CreateCandidateOfferBreadcrumbs = () => {
  return (
    <CandidateOfferBreadcrumbs>
      <CreateBreadcrumbItem entity="Offer" />
    </CandidateOfferBreadcrumbs>
  );
};

export default CreateCandidateOfferBreadcrumbs;
