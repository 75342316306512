/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import { useTrainingsUrl } from '../TrainingsRouter';

const TrainingsBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useTrainingsUrl();
  const trainingsListLink = getListUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Trainings" link={trainingsListLink} />
      {children}
    </Breadcrumbs>
  );
};

export default TrainingsBreadcrumbs;
