/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EmployeeProfileBreadcrumbs from 'erp/employee/shared/EmployeeProfileBreadcrumbs';

const UpdateEmployeeBreadcrumbs = () => {
  return (
    <EmployeeProfileBreadcrumbs>
      <UpdateBreadcrumbItem />
    </EmployeeProfileBreadcrumbs>
  );
};

export default UpdateEmployeeBreadcrumbs;
