/* istanbul ignore file */
import CommonSkillInput from 'erp/employee/commonSkills/commonSkill/field/CommonSkillInput';
import { EmployeeCommonSkillExperienceDurationLabels } from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';
import React, { useEffect, useState } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

const EmployeeCommonSkillExperienceFilter = ({ source, ...props }: any) => {
  const { filters, clearFilter } = useFilterContext();
  const [isDurationVisible, setDurationVisible] = useState(false);
  const experienceDurationSource = `${source}.experienceDuration`;

  useEffect(() => {
    setDurationVisible(filters[`${source}`]?.in?.length);
    if (!filters[`${source}`]?.in?.length && filters[experienceDurationSource]) {
      clearFilter(experienceDurationSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, source]);

  return (
    <>
      <div style={{ marginRight: 12 }}>
        <DropDownFilter {...props} source={source} label="Common Skill">
          <CommonSkillInput
            source={`${source}.in`}
            label="Common Skill"
            initialLabel={filters[`${source}`]?.in && filters[`${source}`]?.in[0]}
          />
        </DropDownFilter>
      </div>
      {isDurationVisible && (
        <DropDownFilter {...props} source={experienceDurationSource} label="Experience Duration">
          <Dropdown
            source=""
            placeholder="Choose Experience Duration"
            options={Object.entries(EmployeeCommonSkillExperienceDurationLabels).map(entry => {
              const [value, label] = entry;
              return {
                value,
                label,
              };
            })}
            label="Experience Duration"
          />
        </DropDownFilter>
      )}
    </>
  );
};

export default EmployeeCommonSkillExperienceFilter;
