/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useServiceUrl } from 'erp/service/ServiceRouter';

const ServiceLink = ({ id, name, isClickable = true }: { id: StringOrNumber; name: string; isClickable?: boolean }) => {
  const { getSingleEntityUrl } = useServiceUrl();

  return (
    <>
      {isClickable ? (
        <Link to={getSingleEntityUrl(id)} target="_blank">
          {name}
        </Link>
      ) : (
        <span>{name}</span>
      )}
    </>
  );
};

export default ServiceLink;
