/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import { useEmployeePerformanceId } from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';

const EmployeePerformanceContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useEmployeePerformanceService();
  const CONTEXT_NAME = 'EmployeePerformance_CONTEXT';
  const employeePerformanceId = useEmployeePerformanceId();

  return (
    <SecurityContextTemplate
      contextName={CONTEXT_NAME}
      getPermissionsFunc={() => getPermissions(employeePerformanceId)}
      permissionToCheck={permissionToCheck}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default EmployeePerformanceContext;
