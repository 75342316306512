/* istanbul ignore file */
import React from 'react';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { SummaryMenuItem } from 'uibuilder/menuitem';
import { useVacancyId, useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';

const ViewVacancyPageMenu = () => {
  const id = useVacancyId();
  const { getSingleEntityUrl, getHiringRequestsUrl } = useVacancyUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl()} exact />
      <SidebarMenuItem linkUrl={getHiringRequestsUrl(id)} name="Hiring Requests" icon="paperclip" />
    </SidebarStickyMenu>
  );
};

export default ViewVacancyPageMenu;
