/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import FieldBadgeProps from 'shared/uibuilder/badge/FieldBadgeProps';

const NewBadge = (props: FieldBadgeProps) => {
  return (
    <Badge className="ml-2" color="primary" {...props}>
      New
    </Badge>
  );
};

export default NewBadge;
