/* istanbul ignore file */
import React from 'react';
import Grid from 'uibuilder/Grid';
import { NumberInput, TextInput } from 'shared/uibuilder/form/input';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import useInputRow from './useInputRow';

export type AchievementType = {
  id: number;
  description: string;
};

interface AchievementInputRowProps {
  data: AchievementType;
  id: number;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
}

export const getAchievementSource = (id: number) => {
  return `weeklyReportData.achievements[${id}]`;
};

const AchievementInputRow = ({ data, formData, id, updateFormData }: AchievementInputRowProps) => {
  const source = getAchievementSource(id);
  const { getOnChangeCallback, removeRow } = useInputRow({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'achievements',
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={1}>
        <NumberInput source={`${source}.id`} value={data.id} label={id === 0 ? 'ID' : ''} disabled isRequired />
      </Grid>
      <Grid item xs={12} md={10}>
        <TextInput
          source={`${source}.description`}
          value={data.description}
          label={id === 0 ? 'Description' : ''}
          onChangeCallback={getOnChangeCallback('description')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <RemoveListItemButton isVisible onClickCallback={removeRow} isFirstRow={id === 0} />
      </Grid>
    </Grid>
  );
};

export default AchievementInputRow;
