/* istanbul ignore file */
import { CREATE_CANDIDATE } from '../../shared/candidateService';
import CreateButton from 'uibuilder/button/CreateButton';
import React from 'react';
import { useCandidateUrl } from '../../CandidateRouter';

const CreateCandidateButton = () => {
  const { getCreateUrl } = useCandidateUrl();

  return (
    <CreateButton
      url={getCreateUrl()}
      label="Create Candidate"
      key="create-lead"
      permissionToCheck={CREATE_CANDIDATE}
    />
  );
};

export default CreateCandidateButton;
