import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import useOfficeService from 'erp/office/useOfficeService';
import { useOfficeId } from 'erp/office/OfficeRouter';
import ViewOfficePageContent from 'erp/office/Show/ViewOfficePageContent';

const OfficeViewPage = () => {
  const { getById } = useOfficeService();
  const id = useOfficeId();

  const getData = async () => {
    const { squareMetersArea, ...data } = await getById(id);

    let area = null;
    if (squareMetersArea) {
      area = `${squareMetersArea}m²`;
    }

    return {
      squareMetersArea: area,
      ...data,
    };
  };

  return (
    <Show getDataMethod={() => getData()}>
      <ViewOfficePageContent />
    </Show>
  );
};

export default OfficeViewPage;
