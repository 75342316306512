import { useEffect, useState } from 'react';
import { ASC, DESC } from 'shared/uibuilder/list/baseListHelper';
import { SearchParams, SortOrder, SortParam } from '../ListContext';
import { isEmpty } from 'lodash';
import { Filter } from '../filter/FilterContext';

export const parseJson = (json: string) => {
  try {
    return JSON.parse(json) || {};
  } catch (e) {
    return {};
  }
};

interface InitialParamsProps {
  initialParams?: SearchParams;
  defaultSortOrder?: SortOrder;
  defaultSortField?: string;
  defaultFilter?: Filter;
}

const getInitialParams = ({
  initialParams = { sort: {}, filter: {} },
  defaultSortOrder = '',
  defaultSortField = '',
  defaultFilter = {},
}: InitialParamsProps): SearchParams => {
  const filter = initialParams?.filter && !isEmpty(initialParams.filter) ? initialParams.filter : defaultFilter || {};

  return {
    filter,
    sort: {
      field: initialParams?.sort?.field || defaultSortField,
      order: initialParams?.sort?.order || defaultSortOrder,
    },
    search: initialParams.search || '',
    page: initialParams.page || 0,
  };
};

interface ListParamsProps extends InitialParamsProps {
  onParamsChangeCallbacks?: Array<(params: SearchParams) => void>;
}

const useListParams = ({
  initialParams,
  onParamsChangeCallbacks = [],
  defaultSortOrder = '',
  defaultSortField = '',
  defaultFilter = {},
}: ListParamsProps = {}) => {
  const [params, setParams] = useState<SearchParams>(
    getInitialParams({
      initialParams,
      defaultSortOrder,
      defaultSortField,
      defaultFilter,
    }),
  );

  useEffect(() => {
    onParamsChangeCallbacks.forEach(callback => {
      callback(params);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const updateParams = (newParams: SearchParams) => {
    setParams((prev: SearchParams) => ({
      ...prev,
      ...newParams,
    }));
  };

  const searchBy = (query: string) => {
    updateParams({
      search: query,
      page: 0,
    });
  };

  const filterBy = (newFilter: Filter) => {
    updateParams({
      filter: newFilter,
      page: 0,
    });
  };

  const goToPage = (newPage: number) => {
    updateParams({
      page: newPage,
    });
  };

  const sortBy = (key?: string, order?: SortOrder) => {
    const result: SortParam = {
      field: key,
    };
    const { sort = {} } = params;

    if (!order) {
      if (key === sort.field) {
        result.order = sort.order === ASC ? DESC : ASC;
      } else {
        result.order = ASC;
      }
    } else {
      result.order = order;
    }

    updateParams({
      sort: result,
    });
  };

  const isDefaultParamsApplied = () => {
    const isDefaultFilter = params.filter === defaultFilter;
    const isDefaultSortOrder = params.sort?.order === defaultSortOrder;
    const isDefaultSortField = params.sort?.field === defaultSortField;

    return isDefaultFilter && isDefaultSortOrder && isDefaultSortField;
  };

  const resetParamsToDefault = () => {
    const isDefaultParams = isDefaultParamsApplied();

    if (!isDefaultParams) {
      updateParams({
        filter: defaultFilter,
        sort: {
          order: defaultSortOrder,
          field: defaultSortField,
        },
      });
    }
  };

  return {
    params,
    sortBy,
    filterBy,
    goToPage,
    searchBy,
    resetParamsToDefault,
  };
};

export default useListParams;
