/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';
import { ReactComponent as Logo } from 'images/logo.svg';

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper className="extension-layout">
      <Logo width={100} className="mb-3 pb-2" />
      <Message />
      {children}
    </Paper>
  );
};

export default PageLayout;
