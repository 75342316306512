/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useFinanceService from 'erp/employee/finance/shared/financeService';
import { useFinanceId } from 'erp/employee/finance/FinanceRouter';

const FinanceContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useFinanceService();
  const CONTEXT_NAME = 'Finance_CONTEXT';
  const financeId = useFinanceId();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={() => getPermissions(financeId)}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default FinanceContext;
