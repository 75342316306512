import React from 'react';
import useTrainingInstancesService from '../../useTrainingInstancesService';
import { useTrainingInstancesUrl } from '../../TrainingInstancesRouter';
import TrainingInstanceForm from '../TrainingInstanceForm';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import UpdateTrainingInstanceBreadcrumbs from './UpdateTrainingInstanceBreadcrumbs';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';

const UpdateTrainingInstance = () => {
  const { getValidationSchema, getById, update } = useTrainingInstancesService();
  const { getSingleEntityUrl } = useTrainingInstancesUrl();
  const { getEmployeeNamesByAliases } = useEmployeeNamesService();

  const getDataFunc = async (id: string) => {
    const data = await getById(id);
    const mentors = await getEmployeeNamesByAliases(data.mentors.map((mentor: { alias: string }) => mentor.alias));
    const students = await getEmployeeNamesByAliases(data.students.map((student: { alias: string }) => student.alias));

    return {
      ...data,
      trainingInstanceId: data.id,
      id: undefined,
      trainingProgram: data.training.id,
      mentors,
      students,
    };
  };

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      afterSubmit={{
        redirect: () => getSingleEntityUrl(),
        message: 'You are awesome! The Training instance has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <UpdatePageLayout title="Edit: Training Instance" breadcrumbs={<UpdateTrainingInstanceBreadcrumbs />}>
        <TrainingInstanceForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateTrainingInstance;
