/* istanbul ignore file */
import React, { memo } from 'react';
import ListWithoutStoringParams from 'shared/uibuilder/list/ListWithoutStoringParams';
import useApplicationService from 'erp/recruitment/applications/shared/applicationService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import GroupedApplicationListCollapse, {
  GroupedApplicationListCollapseProps,
} from 'erp/recruitment/applications/List/GroupedApplicationList/GroupedApplicationListCollapse';

interface GroupedApplicationsListGroupProps extends GroupedApplicationListCollapseProps {
  index: number;
}

const GroupedApplicationsListGroup = memo(({ stage, index, filter, search }: GroupedApplicationsListGroupProps) => {
  const { searchWithFormattedFilters } = useApplicationService();

  return (
    <ListWithoutStoringParams
      getDataMethod={params => searchWithFormattedFilters({ ...params, groupedBy: index })}
      defaultSortOrder={DESC}
      defaultSortField="candidateApplication.createdAt"
      pageSize={15}
    >
      <GroupedApplicationListCollapse filter={filter} stage={stage} search={search} />
    </ListWithoutStoringParams>
  );
});

export default GroupedApplicationsListGroup;
