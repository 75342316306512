/* istanbul ignore file */
import React from 'react';
import FloatingButtons from 'uibuilder/form/FloatingButtons';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import { useHistory } from 'react-router-dom';

const FormFloatButtons = () => {
  const history = useHistory();
  const { getListUrl } = useRbsUrl();

  const onCancelAction = () => {
    if (history.length > 1) {
      history.go(-1);
    } else {
      history.push(getListUrl());
    }
  };

  return <FloatingButtons isCancelButtonVisible onCancelAction={onCancelAction} />;
};

export default FormFloatButtons;
