import React, { useEffect } from 'react';
import useConversionRouteHelper from 'erp/employee/conversionRouteHelper';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { EMPLOYEE_TYPES } from 'erp/employee/employeeService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const EmployeeTypeDropdown = props => {
  const { candidateId } = useConversionRouteHelper();
  const roles = { ...EMPLOYEE_TYPES };
  if (candidateId) {
    delete roles.CONTRACTOR;
  }

  const { getValue } = useInputHelper(props);
  const { initializeValue } = useInputHelper({ source: 'initialEmployeeType' });
  const { isCreateRequest } = useFormContext();
  useEffect(() => {
    if (isCreateRequest === false) {
      initializeValue(getValue());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <EnumDropdown {...props} placeholder="Choose Type" options={roles} />;
};

export default EmployeeTypeDropdown;
