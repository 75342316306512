import { useSelector, useDispatch } from 'react-redux';

export const SET_SETTING = 'SET_JE_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_SETTING,
  payload,
});

export interface JournalEntriesSettingState {
  isStornedJournalEntriesVisible: boolean;
}

const initialState = {
  isStornedJournalEntriesVisible: false,
};

export const journalEntriesSettingsReducer = (state = initialState, action: any) => {
  if (action.type === SET_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const useJournalEntriesSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { journalEntriesSettings: JournalEntriesSettingState }) => state?.journalEntriesSettings,
  );
  const { isStornedJournalEntriesVisible }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const setStornedJournalEntriesVisible = (isChecked: boolean) => {
    updateSettings({
      isStornedJournalEntriesVisible: isChecked,
    });
  };

  return {
    setStornedJournalEntriesVisible,
    areStornedJournalEntriesVisible: isStornedJournalEntriesVisible,
  };
};

export default useJournalEntriesSettings;
