/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_RECONCILIATION, RECONCILIATION_PATH } from 'erp/costaccounting/reconcilation/useReconciliationService';
import ReconcilationList from 'erp/costaccounting/reconcilation/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useReconcilationRoute = () => {
  return useRoute({ listRoute: RECONCILIATION_PATH });
};

export const useReconcilationUrl = () => {
  return useEntityUrl({
    baseLink: RECONCILIATION_PATH,
  });
};

const ReconciliationRouter = () => {
  const { listRoute } = useReconcilationRoute();

  return (
    <ProtectedRouter basePath={RECONCILIATION_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} globalPermissionToCheck={READ_RECONCILIATION}>
          <ReconcilationList />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

ReconciliationRouter.getRouterPath = () => RECONCILIATION_PATH;

export default ReconciliationRouter;
