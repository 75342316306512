import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as domUtils from '../domUtils';

interface SectionWithScrollToHashProps {
  isScrollActive?: boolean;
  children?: React.ReactNode;
}

const SectionWithScrollToHash: React.FC<SectionWithScrollToHashProps> = ({ isScrollActive = true, children }) => {
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (isScrollActive && ref?.current && hash) {
      const hashElement = ref.current.querySelector(hash);

      if (hashElement) {
        domUtils.scrollToHash(hashElement);
      }
    }
  }, [isScrollActive, hash]);

  return <div ref={ref}>{children}</div>;
};

export default SectionWithScrollToHash;
