import projectValidation from 'erp/project/createupdate/projectValidation';
import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import useProjectsSettings from '../List/filter/ProjectsSettingsFilter/useProjectsSettings';

export const PROJECT_PATH = '/projects';

export const READ_PROJECTS_LIST = 'PROJECT_READ_LIST';
export const READ_PROJECT = 'READ';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const ARCHIVE_DAVINCI_OBJECT = 'COMPANY_ARCHIVE_DAVINCI_OBJECT';

const useProjectService = () => {
  const { areArchivedProjectsVisible } = useProjectsSettings();
  const singleResourceUrl = '/projects/:id';
  const { search: baseSearch, update: initialUpdate, ...baseCrudRequests } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/projects',
    apiService: useKernelApi,
  });
  const { searchAll: searchAllSuggestions } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/projects/suggestions',
    apiService: useKernelApi,
  });

  const { sendPostRequest } = useKernelApi();

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter['specification:realNameOrCodeName'] = {
        ct: searchString,
      };
    }

    if (searchParams.filter && searchParams.filter?.name) {
      searchParams.filter['specification:realNameOrCodeName'] = searchParams.filter.name;
      delete searchParams.filter?.name;
    }

    searchParams.filter.archived = {
      eq: areArchivedProjectsVisible,
    };

    return baseSearch(searchParams);
  };

  const getValidationSchema = () => {
    return Promise.resolve(projectValidation);
  };

  const mapProject = ({ availableBudgets, rbsTreeNodeName, rbsTreeNodeId, ...project }: any) => {
    return {
      ...project,
      rbsTreeNode: {
        id: rbsTreeNodeId,
      },
      availableBudgetsIds: availableBudgets ? availableBudgets.map((budget: any) => budget.value) : [],
      sow: {
        id: project?.sow?.id,
      },
    };
  };

  const createProject = async (project: any) => {
    const response = await sendPostRequest('/projects', mapProject(project));
    return response.json();
  };

  const update = async (id: ResourceId, project: any) => {
    return initialUpdate(id, mapProject(project));
  };

  const getName = async (id: ResourceId) => {
    const response = await sendPostRequest(`/projects/${id}/name/sensitive`);
    return response.json();
  };

  const archiveObjects = async (projectId: ResourceId, budgetId?: ResourceId) => {
    return sendPostRequest(`/archive/objects`, {
      projectId,
      budgetId,
    });
  };

  return {
    getValidationSchema,
    createProject,
    ...baseCrudRequests,
    search,
    update,
    getName,
    archiveObjects,
    searchAllSuggestions,
  };
};

export default useProjectService;
