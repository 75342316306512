/* istanbul ignore file */
import React from 'react';
import CopyToClipboardButton from 'shared/uibuilder/CopyToClipboardButton';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const ProcessInstanceIdField = (props: any) => {
  const { getValue } = useFieldHelper(props);
  const id = getValue();

  const value = (
    <>
      <span>{id}</span>
      <CopyToClipboardButton className="ml-1" id="copyProcessInstanceId" value={id}>
        Copy process instance id
      </CopyToClipboardButton>
    </>
  );

  return <TextField {...props} value={value} />;
};

export default ProcessInstanceIdField;
