import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'uibuilder/button';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import useAuthorization from 'shared/authorization/authorizationService';
import useLeadService, { CONVERT_LEAD } from 'crm/lead/shared/leadService';
import { validate } from 'validation-schema-library';
import { useLeadUrl } from 'crm/lead/LeadRouter';

const ConvertButton = () => {
  const [isConvertModalOpen, setIsConvertModalOpen] = useState(false);
  const history = useHistory();
  const { isGranted } = useAuthorization();
  const { getUpdateEntityUrl, getConvertUrl } = useLeadUrl();

  const { data: lead } = useShowContext();
  const { getValidationSchema } = useLeadService();
  const [validationSchema, setValidationSchema] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getValidationSchema().then(schema => {
      setValidationSchema(schema);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = data => {
    const errors = validate({
      schema: validationSchema,
      fullObject: data.getData(),
      isCreateRequest: false,
      exceptions: { status: 'QUALIFIED' },
    });
    return Object.keys(errors).length === 0;
  };

  const editLeadBeforeConvert = leadId => {
    setIsConvertModalOpen(false);
    history.push({
      pathname: getUpdateEntityUrl(leadId),
      state: { status: 'CONVERT' },
    });
  };

  const editBtn = (
    <Button
      key="editBtn"
      onClick={() => {
        editLeadBeforeConvert(lead.getValueBySource('id'));
      }}
    >
      Edit Lead
    </Button>
  );

  return isGranted(CONVERT_LEAD) && lead.getValueBySource('status') === 'OPEN' ? (
    <>
      <Button
        disabled={loading}
        color="secondary"
        onClick={() =>
          isValid(lead) ? history.push(getConvertUrl(lead.getValueBySource('id'))) : setIsConvertModalOpen(true)
        }
      >
        <span>Convert</span>
      </Button>
      <ModalWindow
        isOpen={isConvertModalOpen}
        title="Validation errors"
        buttons={[editBtn]}
        onToggle={() => setIsConvertModalOpen(false)}
      >
        <span>Convert cannot be completed. Required fields are missing. Proceed to ‘Edit Lead’?</span>
      </ModalWindow>
    </>
  ) : null;
};

export default ConvertButton;
