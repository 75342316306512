/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import HandbookSearchResultItem from 'handbook/shared/input/HandbookSearchResults/HandbookSearchResultItem';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';
import Typography from 'uibuilder/Typography';
import { SearchResultsLayoutProps } from 'shared/uibuilder/list/search/SearchWithKeyboardNavigation';

const HandbookSearchResults = (props: SearchResultsLayoutProps) => {
  const { loading, data: { items = [] } = {} } = useListContext();

  return loading ? null : (
    <Box>
      {items.length ? (
        items.map((row: ListItem, index: number) => (
          <ShowContextProvider
            key={row.id}
            value={{
              data: new ShowData(row),
            }}
          >
            <HandbookSearchResultItem {...props} row={row} index={index} />
          </ShowContextProvider>
        ))
      ) : (
        <Typography variant="h5" className="handbook-search-no-results">
          No results were found.
        </Typography>
      )}
    </Box>
  );
};

export default HandbookSearchResults;
