import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import useQualificationService from 'erp/employee/qualification/shared/employeeQualificationService';
import useEmployeeSpecializationService, { EmployeeSpecialization } from '../shared/employeeSpecializationService';
import { useQualificationUrl, useQualificationId } from 'erp/employee/qualification/QualificationRouter';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { HTTP, ROUTE404 } from 'shared/api/const';

const useEmployeeQualificationShowHelper = () => {
  const [loading, setLoading] = useState(true);
  const [employeeQualifications, setEmployeeQualifications] = useState<Nullable<EmployeeSpecialization>>(null);
  const [specializations, setSpecializations] = useState<Nullable<EmployeeSpecialization[]>>(null);
  const employeeId = useEmployeeId() || '';
  const qualificationId = useQualificationId();
  const { getEmployeeQualifications } = useQualificationService();
  const history = useHistory();
  const { getSpecializations } = useEmployeeSpecializationService();
  const { getSingleEntityUrl } = useQualificationUrl();

  useEffect(() => {
    getSpecializations(employeeId).then(data => {
      const employeeSpecializations: EmployeeSpecialization[] = data?.result || [];
      const sortedSpecializations = employeeSpecializations?.sort((a, b) =>
        String(b.primary).localeCompare(String(a.primary)),
      );

      setSpecializations(sortedSpecializations);

      const hasUserSpecializations = sortedSpecializations?.length;

      if (!qualificationId) {
        if (hasUserSpecializations) {
          const primarySpecializationId = sortedSpecializations[0]?.id;

          history.replace(getSingleEntityUrl(primarySpecializationId));
        } else {
          setLoading(false);
        }
      }
    });
    // Suppressed warnings because we only need to call useCallback callback if user change page or cache is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, qualificationId, getSpecializations]);

  useEffect(() => {
    setLoading(true);

    getEmployeeQualifications(employeeId, qualificationId)
      .then(data => {
        setEmployeeQualifications(data);
      })
      .catch(error => {
        setEmployeeQualifications(null);

        if (error.status === HTTP.NOT_FOUND && !specializations) {
          history.push(ROUTE404);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // Suppressed warnings because we only need to call useCallback callback if user change page or cache is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, qualificationId, getEmployeeQualifications]);

  useEffect(() => {
    if (qualificationId && isEmpty(employeeQualifications) && !isEmpty(specializations)) {
      setLoading(true);
    }
  }, [qualificationId, loading, employeeQualifications, specializations]);

  return {
    loading,
    employeeQualifications,
    specializations,
  };
};

export default useEmployeeQualificationShowHelper;
