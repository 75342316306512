/* istanbul ignore file */
import React from 'react';
import { PROGRESS_STATUSES } from 'crm/projectsDashboard/useProjectsDashboardService';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import FailedIcon from '@mui/icons-material/CloseOutlined';
import DelayedIcon from '@mui/icons-material/PriorityHighOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import './ProgressIndicator.scss';

const ProgressIndicator = ({ status }: { status: PROGRESS_STATUSES }) => {
  const getIcon = () => {
    let Icon: Nullable<OverridableComponent<SvgIconTypeMap>> = null;

    switch (status) {
      case PROGRESS_STATUSES.COMPLETED:
        Icon = DoneIcon;
        break;
      case PROGRESS_STATUSES.COMPLETED_WITH_DELAY:
        Icon = DelayedIcon;
        break;
      case PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY:
        Icon = FailedIcon;
        break;
      default:
        Icon = null;
    }

    return Icon ? <Icon /> : null;
  };

  return (
    <div className="project-dashboard-timeline__indicator" data-status={status}>
      <div className="project-dashboard-timeline__indicator-icon">{getIcon()}</div>
    </div>
  );
};

export default ProgressIndicator;
