import DOMPurify from 'dompurify';

const useSanitizeService = () => {
  const getSanitizedData = (html: string): string =>
    DOMPurify.sanitize(html, {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['target'],
    });

  return {
    getSanitizedData,
  };
};

export default useSanitizeService;
