/* istanbul ignore file */
import React from 'react';
import { string, node } from 'prop-types';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useProjectWeeklyReportsService from 'erp/project/weeklyreports/ProjectWeeklyReportService';

export const PROJECT_WEEKLY_REPORT_CONTEXT = 'Project Weekly Report';

const ProjectWeeklyReportContext = ({ permissionToCheck, children }) => {
  const { getPermissions } = useProjectWeeklyReportsService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={PROJECT_WEEKLY_REPORT_CONTEXT}
      idSource="reportId"
    >
      {children}
    </SecurityContextTemplate>
  );
};

ProjectWeeklyReportContext.propTypes = {
  permissionToCheck: string.isRequired,
  children: node.isRequired,
};

export default ProjectWeeklyReportContext;
