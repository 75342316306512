import { EmployeeQualification } from 'erp/employee/qualification/shared/employeeQualificationService';
import { SpecializationLevel } from 'erp/qualification/model/qualificationModelService';

const useEmployeeQualificationPresenter = () => {
  const mapQualifications = (
    employeeQualifications: EmployeeQualification[] = [],
    qualificationsFromModel: SpecializationLevel[] = [],
  ) => {
    return qualificationsFromModel?.map(({ id: modelQualificationId, competencies: modelCompetencies, ...props }) => {
      const newQualification: EmployeeQualification = {
        id: modelQualificationId,
        ...props,
      };

      const employeeQualification = employeeQualifications.find(
        ({ specializationLevelId: employeeQualificationId }) =>
          String(employeeQualificationId) === String(modelQualificationId),
      );

      newQualification.completeness = employeeQualification?.completeness || 0;
      newQualification.id = employeeQualification?.id || modelQualificationId;

      const employeeCompetencies = employeeQualification?.competencies || [];

      newQualification.competencies = modelCompetencies.map(({ id: modelCompetencyId, ...competencyProps }) => {
        const employeeCompetency = employeeCompetencies.find(
          ({ competencyId: employeeCompetencyId }) => employeeCompetencyId === modelCompetencyId,
        );

        return {
          id: modelCompetencyId,
          ...employeeCompetency,
          ...competencyProps,
        };
      });

      return newQualification;
    });
  };

  return {
    mapQualifications,
  };
};

export default useEmployeeQualificationPresenter;
