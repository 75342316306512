/* istanbul ignore file */
import React, { useState } from 'react';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import PresaleRequestForm from 'crm/opportunity/presale/request/form/PresaleRequestForm';
import usePresaleOpportunityService from 'crm/opportunity/presale/request/shared/presaleRequestService';
import BeforeSubmitModal from 'crm/opportunity/presale/request/shared/modal/BeforeSubmitModal';

const CreatePresaleRequestForm = ({ submitFormFunc, onCancel, ...props }: any) => {
  const { getValidationSchema } = usePresaleOpportunityService();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onSubmitAction={() => setModalOpen(true)} onCancel={onCancel}>
        <BeforeSubmitModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        <PresaleRequestForm isCreateForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreatePresaleRequestForm;
