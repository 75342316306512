import React, { useEffect, useState } from 'react';
import useLocationService, { LocationOptionsType } from './locationService';
import Dropdown from '../Dropdown';
import { DefaultDropdownProps } from '../dropdownHelper';
import useInputHelper from '../inputHelper';

type LocationDropdownProps = {
  mapResults?: (location: any) => any;
} & DefaultDropdownProps;

const LocationDropdown = ({ mapResults, ...props }: LocationDropdownProps) => {
  const { findAll } = useLocationService();
  const [options, setOptions] = useState<LocationOptionsType[]>([]);
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  const onChangeCallback = (value: any) => {
    const locationId = value[props.source];
    const location = options.find((data: any) => data.id === locationId);

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        location,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const data = await findAll();
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      {...props}
      placeholder="Select location"
      onChangeCallback={onChangeCallback}
      options={options.map(
        mapResults ||
          ((location: any) => ({
            value: location.id,
            label: location.name,
          })),
      )}
    />
  );
};

export default LocationDropdown;
