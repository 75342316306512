/* istanbul ignore file */

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import isProd from '../isProd';
import isDemoEnv from '../isDemoEnv';

export enum Features {
  ONE_STOP_HISTORY = 'oneStop.history',
  OPPORTUNITY_CLOSED_REASON = 'opportunityClosedReason',
  REQUEST_ID = 'requestId',
  EMPLOYEE_QUALIFICATION = 'employeeQualification',
  SAVE_COMPETENCY_JUSTIFICATION = 'saveCompetencyJustification',
  VIEW_CAREER_REVIEW_INFORMATION = 'viewCareerReviewInformation',
  VIEW_BUDGETS = 'viewBudgets',
  VIEW_BUDGETS_FTE = 'viewBudgetsFte',
  EMAIL_ATTACHMENT = 'emailAttachment',
  INSTANT_FEEDBACK = 'instantFeedback',
  EMPLOYEE_SKILLS = 'employeeSkills',
  VIEW_INTERN_TYPE = 'viewInternType',
  VIEW_INTERNSHIP_TYPE = 'viewInternshipType',
  CREATE_QUEUE = 'createQueue',
  VIEW_ARTIFACT_STORAGE = 'viewArtifactStorage',
  LINK_SOW_WITH_REDMINE_PROJECT = 'linkSowWithRedmineProject',
  PROJECT_STATUSES = 'projectStatuses',
  FORBID_PROJECT_ASSIGNMENTS = 'forbidProjectAssignments',

  SHOW_TIMEZONE_IN_DATETIME_INPUT = 'showTimezoneInDatetimeInput ',

  SYCOM_API = 'syComApi',

  EMPLOYEE_GROUP = 'employeeGroup',
  MY_ACTIVITY_TAB = 'myActivityTab',
  PROJECT_WEEKLY_REPORTS = 'projectWeeklyReports',
  TWO_CONTRACTS = 'twoContracts',
  GROUP_ASSIGNMENTS = 'groupAssignments',
  CANDIDATE_OFFER_COMPENSATION = 'candidateOfferCompensations',
  PERFORMANCE_REPORT = 'performanceReport',
  EMPLOYEE_OFFERS = 'employeeOffers',
  VACANCY_PUBLICATION = 'vacancyPublication',
  SYBERRY_TODAY = 'syberryToday',
  BUDGET_SIDEBAR = 'budgetSidebar',
  ASSIGNMENT_SIDEBAR = 'assignmentSideBar',
  CRM_PROJECTS_DASHBOARD = 'crmProjectsDashboard',
  RESUME_CANDIDATE_DATA_PARSING = 'resumeCandidateDataParsing',
  LEDGER = 'ledger',
  INVOICES = 'invoices',
  CUSTOMER_INVOICES = 'customer_invoices',
  ASSETS = 'assets',
  EXPENSES = 'expenses',
  PAYROLL = 'payroll',
  ASSIGNMENT_IN_THE_PAST_ENABLED = 'assignmentInThePastEnabled',
  FREE_FORM_VACANCY_FORM_FIELD = 'freeFormVacancyFormField',
  PROJECT_INFRASTRUCTURE_PAM = 'projectInfrastructurePam',
  PROJECT_WBS = 'projectWBS',
  PROJECT_SOW = 'projectSOW',
  TRAININGS = 'trainings',
  OFFICES = 'offices',
  CORPORATE_EVENTS = 'corporateEvents',
}

const useFeatureToggle = () => {
  const featureToggles: Dictionary<boolean> = {
    [Features.ONE_STOP_HISTORY]: true,
    [Features.OPPORTUNITY_CLOSED_REASON]: true,
    [Features.REQUEST_ID]: true,
    [Features.EMPLOYEE_QUALIFICATION]: true,
    [Features.SAVE_COMPETENCY_JUSTIFICATION]: true,
    [Features.VIEW_CAREER_REVIEW_INFORMATION]: true,
    [Features.VIEW_BUDGETS]: true,
    [Features.VIEW_BUDGETS_FTE]: true,
    [Features.EMAIL_ATTACHMENT]: true,
    [Features.INSTANT_FEEDBACK]: true,
    [Features.EMPLOYEE_SKILLS]: true,
    [Features.VIEW_INTERN_TYPE]: true,
    [Features.VIEW_INTERNSHIP_TYPE]: true,
    [Features.CREATE_QUEUE]: true,
    [Features.VIEW_ARTIFACT_STORAGE]: true,
    [Features.SHOW_TIMEZONE_IN_DATETIME_INPUT]: true,
    [Features.LINK_SOW_WITH_REDMINE_PROJECT]: true,
    [Features.PROJECT_STATUSES]: true,
    [Features.FORBID_PROJECT_ASSIGNMENTS]: true,
    [Features.SYCOM_API]: true,
    [Features.EMPLOYEE_GROUP]: true,
    [Features.MY_ACTIVITY_TAB]: true,
    [Features.PROJECT_WEEKLY_REPORTS]: true,
    [Features.TWO_CONTRACTS]: true,
    [Features.GROUP_ASSIGNMENTS]: true,
    [Features.CANDIDATE_OFFER_COMPENSATION]: true,
    [Features.PERFORMANCE_REPORT]: true,
    [Features.EMPLOYEE_OFFERS]: false,
    [Features.VACANCY_PUBLICATION]: true,
    [Features.SYBERRY_TODAY]: !isProd(),
    [Features.BUDGET_SIDEBAR]: !isDemoEnv(),
    [Features.ASSIGNMENT_SIDEBAR]: !isDemoEnv(),
    [Features.CRM_PROJECTS_DASHBOARD]: true,
    [Features.RESUME_CANDIDATE_DATA_PARSING]: true,
    [Features.LEDGER]: true,
    [Features.INVOICES]: true,
    [Features.CUSTOMER_INVOICES]: !isProd(),
    [Features.ASSETS]: !isProd(),
    [Features.EXPENSES]: !isProd(),
    [Features.PROJECT_SOW]: !isProd(),
    [Features.PAYROLL]: true,
    [Features.ASSIGNMENT_IN_THE_PAST_ENABLED]: true,
    [Features.FREE_FORM_VACANCY_FORM_FIELD]: true,
    [Features.PROJECT_INFRASTRUCTURE_PAM]: true,
    [Features.PROJECT_WBS]: true,
    [Features.TRAININGS]: true,
    [Features.CORPORATE_EVENTS]: true,
    [Features.OFFICES]: true,
  };

  const isFeatureEnabled = (featureName: string) => {
    return featureToggles[featureName];
  };

  return {
    isFeatureEnabled,
  };
};

export default useFeatureToggle;
