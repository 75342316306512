/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import PurchaseListInput from 'financialAnalytic/purchaseOrder/shared/input/PurchaseListInput';
import PurchaseOrderTypeDropdown from 'financialAnalytic/purchaseOrder/shared/input/PurchaseOrderTypeDropdown';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import useLedgerService from 'financialAnalytic/ledger/useLedgerService';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import CurrencyDropdownWithoutEur, {
  DROPDOWN_CURRENCY_TYPES,
} from 'financialAnalytic/shared/input/CurrencyDropdownWithoutEur';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { PURCHASE_ORDER_TYPES } from '../usePurchaseOrderService';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';

export const { BYN, ...CURRENCY_TYPES } = DROPDOWN_CURRENCY_TYPES || {};

const PurchaseOrderForm = () => {
  const { searchPurchaseOrderAccount } = useLedgerService();

  return (
    <FormSection>
      <FormRow>
        <PurchaseOrderTypeDropdown source="type" label="Type" />
        <TextInput source="title" label="Title" />
        <TextInput source="vendor" label="Vendor" />
        <OfficeDropdown source="office" label="Office" />
      </FormRow>
      <FormRow>
        <AccountInput
          source="account"
          label="Account"
          loadDataMethod={searchPurchaseOrderAccount}
          cacheKey="purchase-order-accounts"
        />
        <CurrencyDropdownWithoutEur source="currency" label="Currency" options={{ ...CURRENCY_TYPES, BYR: 'BYN' }} />
        <ClearAfterUnmountWrapper isVisible={(data: any) => data.type === PURCHASE_ORDER_TYPES.STANDARD}>
          <DateInput source="requiredDeliveryDate" label="Required Delivery Date" />
        </ClearAfterUnmountWrapper>

        <ClearAfterUnmountWrapper
          isVisible={(data: any) =>
            data.type === PURCHASE_ORDER_TYPES.BLANKET || data.type === PURCHASE_ORDER_TYPES.CONTRACT
          }
        >
          <DateInput source="expirationDate" label="Expiration Date" />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <EmployeeInput source="requestor" nameSource="requestorName" label="Requestor" />
      </FormRow>
      <BigFormRow>
        <TextArea source="purchasePurpose" label="Purchase Purpose" />
      </BigFormRow>
      <PurchaseListInput source="purchases" label="Purchases" currencySource="currency" />
    </FormSection>
  );
};

export default PurchaseOrderForm;
