/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import Loading, { LoaderSize } from 'shared/uibuilder/Loading';

const InputLoader = ({ className = '' }: { className?: string }) => (
  <div className={classnames('input-loader', className)}>
    <Loading size={LoaderSize.Small} />
  </div>
);

export default InputLoader;
