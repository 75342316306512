/* istanbul ignore file */
import React from 'react';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { CreateForm } from 'shared/uibuilder/form';
import CreateEmployeeBackgroundCheckBreadCrumbs from 'erp/employee/backgroundcheck/createupdate/Create/Breadcrumbs';
import EmployeeBackgroundCheckForm from 'erp/employee/backgroundcheck/createupdate/EmployeeBackgroundCheckForm';
import { useEmployeeBackgroundChecksUrl } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';
import useEmployeeBackgroundCheckService from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

const CreateEmployeeBackgroundCheck = () => {
  const { getListUrl } = useEmployeeBackgroundChecksUrl();
  const { create, getValidationSchema } = useEmployeeBackgroundCheckService();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Background Check has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        breadcrumbs={<CreateEmployeeBackgroundCheckBreadCrumbs />}
        title="Create: Background Check"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <EmployeeBackgroundCheckForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateEmployeeBackgroundCheck;
