import React, { useEffect } from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import QualificationModelCompetencyField from '../shared/field/QualificationModelCompetencyField';
import CompetencyWeightField from '../shared/field/CompetencyWeightField';
import { BulletedListField } from 'shared/uibuilder/field';
import { Specialization } from 'erp/qualification/model/qualificationModelService';
import Collapse from 'shared/uibuilder/Collapse';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import EmptyQualificationLevelMessage from 'erp/qualification/model/shared/EmptyQualificationLevelMessage';
import useCollapse from 'shared/uibuilder/Collapse/useCollapse';
import CollapseControls from 'shared/uibuilder/Collapse/CollapseControls';
import Typography from 'uibuilder/Typography';
import './SpecializationQualificationModel.scss';

const SpecializationQualificationModel = ({ data }: { data: Specialization }) => {
  const { title, specializationLevels } = data;
  const { expandAll, collapseAll, toggle, isExpanded, areAllItemsExpanded, areAllItemsCollapsed } = useCollapse();
  const specializationsIds = specializationLevels.map(({ id }) => String(id));

  useEffect(() => {
    collapseAll();
  }, [title, collapseAll]);

  const collapseControls = (
    <CollapseControls
      isExpandButtonShown={!areAllItemsExpanded(specializationsIds)}
      isCollapseButtonShown={!areAllItemsCollapsed()}
      expandAll={() => expandAll(specializationsIds)}
      collapseAll={collapseAll}
    />
  );

  return (
    <div className="syberry-qualification-model table-wrapper--md">
      <div className="mt-3 d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
        <Typography variant="h4" className="mb-4">
          {title}
        </Typography>
        <div className="mb-4">{collapseControls}</div>
      </div>
      <div className="mb-3">
        {specializationLevels.map(({ id, title: levelTitle, competencies }) => (
          <Collapse
            key={`qualification-${id}`}
            header={
              <div className="m-0 collapse-title-wrapper">
                <h4 className="collapse-title m-0">{levelTitle}</h4>
              </div>
            }
            isExpanded={isExpanded(String(id))}
            toggle={() => {
              toggle(String(id));
            }}
          >
            {competencies.length ? (
              <ControlledList data={{ items: competencies, totalElements: competencies.length, totalPages: 1 }}>
                <DataGridLayout showTotal={false}>
                  <QualificationModelCompetencyField source="name" label="Competency" width={300} />
                  <CompetencyWeightField source="weight" label="Weight" width={100} />
                  <BulletedListField
                    source="evidence"
                    label="Valid work product evidence or counter-examples"
                    width={350}
                    listClassName="qualification-evidences"
                  />
                </DataGridLayout>
              </ControlledList>
            ) : (
              <EmptyQualificationLevelMessage />
            )}
          </Collapse>
        ))}
      </div>
      <div className="d-flex justify-content-md-end mt-4">{collapseControls}</div>
    </div>
  );
};

export default SpecializationQualificationModel;
