/* istanbul ignore file */
import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useContractsUrl } from 'erp/contracts/ContractsRouter';

const ContractsBreadcrumbs = ({ children }) => {
  const { getListUrl } = useContractsUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Contracts" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

ContractsBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

ContractsBreadcrumbs.defaultProps = {
  children: null,
};

export default ContractsBreadcrumbs;
