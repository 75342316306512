/* istanbul ignore file */
import React from 'react';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { Button } from 'uibuilder/button';
import ParentTasksToggler from 'syberryToday/List/AfterDailyCheckin/Settings/ParentTasksToggler';
import RecurrentlyTasksToggler from 'syberryToday/List/AfterDailyCheckin/Settings/RecurrentlyTasksToggler';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import './Settings.scss';

const SyberryTodaySettings = () => {
  return (
    <DropdownMenu
      popupId="today-settings"
      iconButton={
        <Button className="dropdown-toggle" startIcon={<SettingsIcon />}>
          Settings
        </Button>
      }
    >
      <RecurrentlyTasksToggler />
      <ParentTasksToggler />
    </DropdownMenu>
  );
};

export default SyberryTodaySettings;
