/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import getLabel from 'shared/uibuilder/helper';
import { INDUSTRIES } from 'crm/crmService';

const IndustryField = ({ value: inputValue, source, label }) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField value={value} options={INDUSTRIES} label={getLabel(label, source)} />;
};

IndustryField.defaultProps = {
  value: null,
  source: null,
  label: null,
};

IndustryField.propTypes = {
  value: string,
  source: string,
  label: string,
};

export default IndustryField;
