import React from 'react';
import CreatePageLayout from '../../../../shared/layout/form/CreatePageLayout';
import { CreateForm } from '../../../../shared/uibuilder/form';
import useTrainingsService from '../../useTrainingsService';
import { useTrainingsUrl } from '../../TrainingsRouter';
import TrainingForm from '../TrainingForm';
import CreateTrainingBreadcrumbs from './CreateTrainingBreadcrumbs';

const CreateTraining = () => {
  const { getValidationSchema, create } = useTrainingsService();
  const { getListUrl } = useTrainingsUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Training has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Training" breadcrumbs={<CreateTrainingBreadcrumbs />}>
        <TrainingForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateTraining;
