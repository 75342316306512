import useEmployeeService from 'erp/employee/employeeService';
import useCareerDevelopmentReviewService from 'erp/careerdevelopment/shared/careerDevelopmentReviewService';

const useCareerReviewService = () => {
  const { getById } = useEmployeeService();
  const { getCareerDevelopmentNextReviewDate } = useCareerDevelopmentReviewService();

  const getCareerReviewData = async (employeeId: string) => {
    const [nextReviewData, employeeData] = await Promise.all([
      getCareerDevelopmentNextReviewDate(employeeId),
      getById(employeeId),
    ]);

    return {
      nextReviewDate: nextReviewData?.date,
      careerDevelopmentEvaluationMode: employeeData?.careerDevelopmentEvaluationMode,
    };
  };

  return {
    getCareerReviewData,
  };
};

export default useCareerReviewService;
