/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { Request } from 'oneStop/oneStopService';
import OneStopSearchResults from 'oneStop/List/OneStopSearchResults';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';

type OneStopSearchResultsLayoutProps = {
  setFocusToResults: () => void;
  focusedResultIndex: Nullable<Number>;
  searchResultsRefs: HTMLAnchorElement[];
  onKeyUp: (event: any, index: number) => void;
};

const OneStopSearchResultsLayout = ({
  onKeyUp,
  searchResultsRefs,
  focusedResultIndex,
  setFocusToResults,
}: OneStopSearchResultsLayoutProps) => {
  const { data: { items = [] } = {} } = useListContext();

  const NoResults = () => (
    <Typography variant="h5" className="onestop-search-no-results">
      No results were found.
    </Typography>
  );

  return (
    <Box>
      {items.length ? (
        items.map((row: Request, index: number) => (
          <ShowContextProvider
            key={row.id}
            value={{
              data: new ShowData(row),
            }}
          >
            <OneStopSearchResults
              row={row}
              index={index}
              onKeyUp={onKeyUp}
              focusedResultIndex={focusedResultIndex}
              setFocusToResults={setFocusToResults}
              searchResultsRefs={searchResultsRefs}
            />
          </ShowContextProvider>
        ))
      ) : (
        <NoResults />
      )}
    </Box>
  );
};

export default OneStopSearchResultsLayout;
