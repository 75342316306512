/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleEducationBreadcrumbs from 'erp/employee/Education/shared/SingleEducationBreadcrumbs';

const UpdateEducationBreadcrumbs = () => {
  return (
    <SingleEducationBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleEducationBreadcrumbs>
  );
};

export default UpdateEducationBreadcrumbs;
