/* istanbul ignore file */
import React from 'react';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import useInvoiceService from 'financialAnalytic/invoice/useInvoiceService';
import { SensitiveListElementLayout } from 'shared/uibuilder/sensitiveData';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import InvoicesListDataGrid from 'financialAnalytic/purchaseOrder/shared/field/InvoicesListDataGrid';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import usePurchaseOrderSettings from 'financialAnalytic/purchaseOrder/shared/Settings/usePurchaseOrderSettings';
import PurchaseOrderSettings from 'financialAnalytic/purchaseOrder/shared/Settings';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';

const InvoicesListField = ({
  source,
  isSensitiveDataIsShown = false,
}: {
  source: string;
  isSensitiveDataIsShown?: boolean;
}) => {
  const { areDeliversInvoicesVisible } = usePurchaseOrderSettings();
  const { getSensitiveDataByIds } = useInvoiceService();
  const { getListUrl } = usePurchaseOrderUrl();
  const { getValue } = useFieldHelper({ source });
  let invoices = (getValue() as any) || [];

  if (!areDeliversInvoicesVisible) {
    invoices = invoices.filter((invoice: any) => !invoice.deliveryStatus);
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: { xs: '28px', sm: '30px' },
        }}
      >
        <Typography variant="h4" className="c-title">
          Goods and services
        </Typography>
        <PurchaseOrderSettings />
      </Box>
      <ControlledList
        data={{
          items: invoices.map((invoice: any) => ({ invoice, invoiceId: invoice.id })),
          totalPages: 1,
          totalElements: invoices.length,
        }}
      >
        <SensitiveListElementLayout
          key={`sensitive-${isSensitiveDataIsShown}`}
          sensitiveDataIdSource="invoiceId"
          loadSensitiveDataMethod={getSensitiveDataByIds}
          timerId={getListUrl()}
        >
          <InvoicesListDataGrid isSensitivePurchaseDataIsShown={isSensitiveDataIsShown} />
        </SensitiveListElementLayout>
      </ControlledList>
    </>
  );
};

export default InvoicesListField;
