/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateSkillBasedQueueBreadcrumbs from 'erp/skillbasedqueue/createupdate/Create/Breadcrumbs';
import { useSkillBasedQueueId, useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import useSkillBasedQueueService from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import SkillBasedQueueForm from 'erp/skillbasedqueue/createupdate/SkillBasedQueueForm';

const CreateSkillBasedQueue = () => {
  const { create, getValidationSchema, getById } = useSkillBasedQueueService();
  const { getListUrl } = useSkillBasedQueueUrl();
  const id = useSkillBasedQueueId();
  let getDataFunc;

  if (id) {
    getDataFunc = () => getById(id);
  }

  return (
    <CreateForm
      getDataFunc={getDataFunc}
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Skill Based Queue has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Skill Based Queue" breadcrumbs={<CreateSkillBasedQueueBreadcrumbs />}>
        <SkillBasedQueueForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateSkillBasedQueue;
