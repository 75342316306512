import { useTokenProvider, AuthenticationToken } from 'authentication/DefaultTokenProvider';
import { ApiAuthenticationProvider, AuthHeader } from 'shared/api';

const useApiAuthenticationProvider = (): ApiAuthenticationProvider => {
  const { getToken } = useTokenProvider();

  const getAuthHeader = async (): Promise<AuthHeader> => {
    const accessToken: AuthenticationToken = await getToken();

    return {
      name: 'Authorization',
      value: `Bearer ${accessToken}`,
    };
  };

  return {
    getAuthHeader,
  };
};

export default useApiAuthenticationProvider;
