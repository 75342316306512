/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useRbsUrl } from 'erp/rbs/RbsRouter';

const EditRbsNodeButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useRbsUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditRbsNodeButton;
