import React from 'react';
import { string } from 'prop-types';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getProbationReduction } from 'erp/employee/finance/shared/financeService';

const ProbationPeriodReductionInput = ({ probationAmountSource, baseAmountSource, ...props }) => {
  const { getValue: getProbationAmountValue } = useInputHelper({ source: probationAmountSource });
  const { getValue: getBaseAmountValue } = useInputHelper({ source: baseAmountSource });

  const getFieldValue = () => {
    const baseAmount = getBaseAmountValue();
    const probationAmount = getProbationAmountValue();

    if (!baseAmount || !probationAmount) {
      return '';
    }

    return getProbationReduction(probationAmount, baseAmount);
  };

  return <TextInput value={getFieldValue()} disabled {...props} />;
};

ProbationPeriodReductionInput.propTypes = {
  baseAmountSource: string.isRequired,
  probationAmountSource: string.isRequired,
};

export default ProbationPeriodReductionInput;
