import { useBusinessEngineApi } from 'api';
import { useHistory } from 'react-router-dom';
import useArtifactService from 'artifact/artifactService';
import { splitFamily } from 'erp/employee/employeeService';
import useEmployeeMapper from 'erp/employee/employeeMapper';
import useEducationService from 'erp/employee/Education/shared/educationService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { HTTP, ROUTE403, ROUTE404 } from 'shared/api/const';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';

const RESOURCE_URL = '/external-forms';

export const INITIAL_FORM_DATA = {
  educations: [
    {
      haveDocument: false,
      document: null,
    },
  ],
  familyMembers: [
    {
      name: {
        firstName: '',
        middleName: '',
        lastName: '',
      },
      dateOfBirth: '',
      relation: '',
      phoneNumber: '',
    },
  ],
};

const useBaseExternalFormService = ({ hideForm }: { hideForm?: () => void } = {}) => {
  const history = useHistory();
  const { addMessage } = useMessageService();
  const { normalizeFamily } = useEmployeeMapper();
  const { setArtifactsToStore } = useArtifactService();
  const { mapFromApi, mapEducation } = useEducationService();
  const { sendPostRequest, sendGetRequest, sendPutRequest } = useBusinessEngineApi();

  const findAll = async (employeeAlias: string) => {
    const response = await sendPostRequest(`/employees/${employeeAlias}${RESOURCE_URL}/search`, {
      filter: {
        submittedAt: {
          NOT_NULL: true,
        },
      },
      pageSize: 30,
      pageNumber: 0,
    });

    return response.json();
  };

  const getById = async (formId: string) => {
    const response = await sendGetRequest(`${RESOURCE_URL}/${formId}`);

    return response.json();
  };

  const handleErrorOnGettingData = (error: any) => {
    if (error.status === HTTP.NOT_FOUND) {
      history.push(ROUTE404);
    }

    if (error.status === HTTP.FORBIDDEN) {
      history.push(ROUTE403);
    }

    if (hideForm) {
      hideForm();
    }

    addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
  };

  const getDataById = async (formId: string) => {
    let result;

    try {
      result = await getById(formId);
    } catch (error) {
      handleErrorOnGettingData(error);
    }

    if (result?.artifacts) {
      setArtifactsToStore(result.artifacts);
    }

    const data = result?.info?.data;
    if (data) {
      const { children: familyChildren, other: familyMembers } = getFamily(data);

      return {
        ...data,
        ...getEducations(data),
        ...(familyMembers?.length ? { familyMembers } : {}),
        ...(familyChildren?.length ? { familyChildren } : {}),
      };
    } else {
      return null;
    }
  };

  const getEducations = (data: any) => {
    return data?.educations && data?.educations?.length
      ? { educations: data?.educations?.map((item: any) => mapFromApi(item)) }
      : { educations: INITIAL_FORM_DATA.educations };
  };

  const getFamily = (data: any) => {
    return data?.familyMembers ? splitFamily(data?.familyMembers) : splitFamily(INITIAL_FORM_DATA.familyMembers);
  };

  const setData = async (formId: string, data: any) => {
    const result = { ...data };

    if (result?.educations) {
      result.educations = result.educations.map((item: any) => mapEducation(item, true));
    }

    if (result?.familyMembers) {
      result.familyMembers = [...normalizeFamily(result?.familyMembers), ...normalizeFamily(result?.familyChildren)];
    }

    delete result.haveChildren;

    const response = await sendPutRequest(`${RESOURCE_URL}/${formId}/submit`, { data: result });

    return response.json();
  };

  return {
    findAll,
    getById,
    setData,
    getDataById,
  };
};

export default useBaseExternalFormService;
