import { combineReducers } from 'redux';

import { authenticatedUserReducer } from 'authentication/authenticatedUserService';
import authenticationTokenReducer from 'authentication/tokenprovider/authenticationTokenReducer';
import artifactsReducer from 'artifact/artifactsReducer';
import employeeNamesReducer from 'erp/employee/shared/employeeNamesReducer';
import { oneStopSettingsReducer } from 'oneStop/List/Settings/useOneStopSettings';
import { syberryTodaySettingsReducer } from 'syberryToday/List/AfterDailyCheckin/Settings/useSyberryTodaySettings';
import { journalEntriesSettingsReducer } from 'financialAnalytic/journalEntry/shared/Settings/useJournalEntriesSettings';
import { applicationsSettingsReducer } from 'erp/recruitment/applications/List/GroupedApplicationList/Settings/useApplicationsSettings';
import { purchaseOrderSettingsReducer } from 'financialAnalytic/purchaseOrder/shared/Settings/usePurchaseOrderSettings';
import { payrollReportsSettingsReducer } from 'erp/payroll/generatedPayrolls/shared/Settings/usePayrollReportSettings';
import { syberryTodayProjectsDataReducer } from 'syberryToday/projects/useSyberryTodayProjectService';
import authorizationReducers from 'shared/authorization/redux/authorizationReducers';
import messageReducer from 'shared/message/messageReducer';
import headerMenuReducer from 'shared/uibuilder/menu/HeaderMenu/headerMenuReducer';
import { cacheReducer } from 'shared/cache/cacheService';
import { sendEmailReducer } from 'sendEmail/sendEmailService';
import { salesReducer } from 'crm/crmService';
import { recruitersReducer } from 'erp/candidate/recruiterService';
import { dataTimerReducer } from 'shared/uibuilder/DataTimer';
import { reducer as pageLoadReducer } from 'shared/audit/pageLoad';
import { projectsSettingsReducer } from '../../erp/project/List/filter/ProjectsSettingsFilter/useProjectsSettings';

export default combineReducers({
  auth: authenticatedUserReducer,
  authToken: authenticationTokenReducer,
  artifacts: artifactsReducer,
  employeeNames: employeeNamesReducer,
  oneStopSettings: oneStopSettingsReducer,
  syberryTodaySettings: syberryTodaySettingsReducer,
  journalEntriesSettings: journalEntriesSettingsReducer,
  projectsSettings: projectsSettingsReducer,
  applicationsSettings: applicationsSettingsReducer,
  purchaseOrderSettings: purchaseOrderSettingsReducer,
  payrollReportsSettings: payrollReportsSettingsReducer,
  syberryTodayProjectsData: syberryTodayProjectsDataReducer,
  authorization: authorizationReducers,
  message: messageReducer,
  sales: salesReducer,
  recruiters: recruitersReducer,
  cache: cacheReducer,
  pageLoad: pageLoadReducer,
  sendEmail: sendEmailReducer,
  timers: dataTimerReducer,
  headerMenu: headerMenuReducer,
});
