import React from 'react';
import { getVisibility } from 'shared/uibuilder/helper';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';
import { ShowDataInstance } from 'shared/uibuilder/show/Show';
import { ListItem } from 'shared/uibuilder/list/ListContext';

import MuiTableRow from '@mui/material/TableRow';
import MuiTableCell from '@mui/material/TableCell';

export const renderCell = (data: ListItem, element: ShowDataInstance, className: string) => {
  const { isVisible: initialIsVisible } = element.props;

  const isVisible = getVisibility(data, initialIsVisible);

  return isVisible ? (
    <MuiTableCell className={className}>{React.cloneElement(element)}</MuiTableCell>
  ) : (
    <MuiTableCell />
  );
};

export interface BaseRowProps {
  buttons?: React.ReactElement[];
  rowProps?: Dictionary<string> | ((instance: ShowDataInstance) => Dictionary<string>);
  rowKeySource?: string;
}

export interface RowProps extends BaseRowProps {
  data: ListItem;
  template: React.ReactElement | React.ReactElement[];
}
/**
 * @param data -- data for row
 * @param template -- An array of Feild-like react elements
 * @param buttons -- An array of action buttons to be added to row
 * @param rowProps - either props for <tr> tag or function that accept data and return props or <tr> tag.
 * Allows to highlight the fields somehow.
 * @param rowKeySource - source in data for row key value
 * @returns {*} -- table row build based on template and buttons
 * @constructor
 */
const Row = ({ data, template, buttons = [], rowProps = {}, rowKeySource = 'id' }: RowProps) => {
  const dataInstance = new ShowData(data);
  const props = typeof rowProps === 'function' ? rowProps(dataInstance) : rowProps;

  return (
    <ShowContextProvider
      value={{
        data: dataInstance,
      }}
    >
      <MuiTableRow {...props} key={dataInstance.getValueBySource(rowKeySource)}>
        {React.Children.map(template, item => {
          return renderCell(dataInstance, item, '');
        })}
        {React.Children.map(buttons, button => {
          return renderCell(dataInstance, button, 'list-btn-container');
        })}
      </MuiTableRow>
    </ShowContextProvider>
  );
};

export default Row;
