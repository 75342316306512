/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';
import ListFilterWrapper from './ListFilterWrapper';

interface ListPageLayoutProps {
  header?: React.ReactElement;
  filter?: React.ReactElement;
  actions?: React.ReactElement[];
  children?: React.ReactElement | React.ReactElement[];
  search?: React.ReactElement;
  showTotal?: boolean;
  contextName?: string;
}

const ListPageLayout = ({
  header,
  filter,
  actions,
  children,
  search,
  showTotal = true,
  contextName,
}: ListPageLayoutProps) => {
  return (
    <>
      <ListFilterWrapper filter={filter} search={search} actions={actions} />
      <Paper sx={{ p: 2 }} className="page-content">
        {header}
        <Message messageContext={contextName} />
        {children}
      </Paper>
    </>
  );
};

export default ListPageLayout;
