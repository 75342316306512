/* istanbul ignore file */
// libraries
import React from 'react';
// components
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import CompanyRolesInput from '../shared/inputs/CompanyRolesInput';

const CompanyRolesForm = () => (
  <FormSection title="Company role information">
    <FormRow>
      <CompanyRolesInput label="Company role" source="role" />
    </FormRow>
  </FormSection>
);

export default CompanyRolesForm;
