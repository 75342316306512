/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { ACCOUNT_TYPES } from 'crm/account/accountService';

const AccountTypeField = props => {
  return <EnumField {...props} options={ACCOUNT_TYPES} />;
};

export default AccountTypeField;
