/* istanbul ignore file */
import React from 'react';
import { HtmlField } from 'shared/uibuilder/field/index';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';

import './FeedbackField.scss';

const FeedbackField = (props: any) => (
  <div className="feedback-field">
    <ReplaceUrlWithLink>
      <HtmlField {...props} />
    </ReplaceUrlWithLink>
  </div>
);

export default FeedbackField;
