/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { EMPLOYEE_CONTRACT_ASSIGMENT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';

const ContractAssignmentTypeField = props => {
  return <EnumField {...props} options={EMPLOYEE_CONTRACT_ASSIGMENT_TYPES} />;
};

export default ContractAssignmentTypeField;
