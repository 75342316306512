/* istanbul ignore file */
import useProcessVariablesHelper from 'camunda/monitoring/instance/Show/variables/processVariablesHelper';
import { Button } from 'uibuilder/button';
import React from 'react';

interface Props {
  activeContext: string;
  setActiveContext: (context: string) => void;
}

const VariableContextPanel = ({ activeContext, setActiveContext }: Props) => {
  const { getVariableContexts } = useProcessVariablesHelper();
  const contexts = getVariableContexts();

  return (
    <div className="context-names-container">
      {contexts.map(context => (
        <div className="variable-context-name">
          <Button
            onClick={() => setActiveContext(context.executionId)}
            sx={{ marginBottom: '-10px', marginTop: '5px', marginRight: '10px' }}
            size="small"
            outline={activeContext !== context.executionId}
          >
            {context.title}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default VariableContextPanel;
