import React from 'react';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TrainingInstancesBreadcrumbs from '../shared/TrainingInstancesBreadcrumbs';
import { useTrainingInstancesUrl } from '../TrainingInstancesRouter';

const SingleTrainingInstancesBreadcrumbsItem = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useTrainingInstancesUrl();
  const id = data?.getValueBySource('id');

  return (
    <TrainingInstancesBreadcrumbs>
      <SingleBreadcrumbItem entity="Training instance" link={getSingleEntityUrl(id)} />
      {children}
    </TrainingInstancesBreadcrumbs>
  );
};

export default SingleTrainingInstancesBreadcrumbsItem;
