/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { UPDATE_PRESALE_OUTPUT } from 'crm/opportunity/shared/opportunityService';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import PresaleOutputFields from 'crm/opportunity/presale/output/PresaleOutputTimelineRecordLayout/PresaleOutputFields';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';

const PresaleOutputTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { data } = useShowContext();

  const buttons = [<EditTimelineRecordButton onEdit={onEdit} permissionToCheck={UPDATE_PRESALE_OUTPUT} />];

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <PresaleOutputFields />
        </Collapsible>
      }
      entity={data}
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      actions={[]}
      timelineFooter={() => null}
      {...props}
    />
  );
};

export default PresaleOutputTimelineRecordLayout;
