/* istanbul ignore file */
import EnumField from 'shared/uibuilder/field/EnumField';
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { getSourceOptions } from 'erp/candidate/applications/shared/candidateApplicationService';

const CandidateApplicationSourceField = props => {
  const { getValue, getLabel } = useFieldHelper(props);

  return <EnumField label={getLabel() || 'Source'} value={getValue()} options={getSourceOptions()} />;
};

CandidateApplicationSourceField.propTypes = TextField.propTypes;

CandidateApplicationSourceField.defaultProps = TextField.defaultProps;

export default CandidateApplicationSourceField;
