import React from 'react';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TrainingsBreadcrumbs from '../shared/TrainingsBreadcrumbs';
import { useTrainingsUrl } from '../TrainingsRouter';

const SingleTrainingBreadcrumbsItem = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useTrainingsUrl();
  const id = data?.getValueBySource('id');

  return (
    <TrainingsBreadcrumbs>
      <SingleBreadcrumbItem entity="Training" link={getSingleEntityUrl(id)} />
      {children}
    </TrainingsBreadcrumbs>
  );
};

export default SingleTrainingBreadcrumbsItem;
