import React, { useMemo } from 'react';
import useTrainingsService, { WRITE_TRAINING_DOMAIN } from '../useTrainingsService';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import CreateButton from 'uibuilder/button/CreateButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { BooleanField, ListField, TextField } from 'shared/uibuilder/field';
import List from 'shared/uibuilder/list/List';
import { useTrainingsUrl } from '../TrainingsRouter';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import CompetenciesField from '../shared/fileds/CompetenciesField';
import BooleanFilter from 'shared/uibuilder/list/filter/BooleanFilter';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import LocationFilter from '../shared/filters/LocationFilter';
import CompetenciesFilter from '../shared/filters/CompetenciesFilter';
import ViewTrainingButton from '../shared/buttons/ViewTrainingButton';

const TrainingsList = () => {
  const { search } = useTrainingsService();

  const { getCreateUrl } = useTrainingsUrl();

  const filters = useMemo(
    () => (
      <Filters>
        <FiltersLayout
          quickFilters={[<QuickFilter key="Interval" label="All Internal" state={{ isInternal: { eq: 'YES' } }} />]}
        >
          <BooleanFilter source="isInternal" label="Internal" />
          <LocationFilter source="location.name" label="Location" />
          <CompetenciesFilter source="competencies.name" label="Competencies" />
        </FiltersLayout>
      </Filters>
    ),
    [],
  );

  return (
    <List getDataMethod={search}>
      <ListPageLayout
        filter={filters}
        header={<ListHeader label="Trainings" icon="fa-folder-o" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Training"
            key="create-training"
            permissionToCheck={WRITE_TRAINING_DOMAIN}
          />,
        ]}
        search={<SearchInput label="Name" />}
      >
        <DataGridLayout buttons={[<ViewTrainingButton source="id" />]}>
          <TextField source="name" label="Name" width="10%" />

          <BooleanField source="isInternal" label="Internal" width="10%" />
          <TextField source="location.name" label="Location" isSortable width="10%" />

          <ListField source="competencies" width="30%">
            <CompetenciesField />
          </ListField>

          <TextField source="description" label="Description" width="30%" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default TrainingsList;
