/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { INVOICE_STATUSES_OPTIONS } from 'financialAnalytic/invoice/useInvoiceService';

const InvoiceStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={INVOICE_STATUSES_OPTIONS} label="Status" {...props} />;
};

export default InvoiceStatusDropdown;
