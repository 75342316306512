import React from 'react';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ViewAccountingCycleButton from '../shared/button/ViewAccountingCycleButton';
import useAccountingCycle, { ACCOUNTING_CYCLE_OPTIONS } from '../useAccountingCycle';
import { DateField, SearchLinkField } from 'shared/uibuilder/field';
import { useAccountingCycleUrl } from '../AccountingCycleRouter';
import EnumField from 'shared/uibuilder/field/EnumField';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';

const AccountingCycleList = () => {
  const { search } = useAccountingCycle();
  const { getSingleEntityUrl } = useAccountingCycleUrl();

  return (
    <List getDataMethod={search}>
      <ListPageLayout header={<ListHeader label="Accounting Cycles" icon="fa-folder-o" />}>
        <DataGridLayout buttons={[<ViewAccountingCycleButton source="id" />]}>
          <SearchLinkField source="id" label="ID" url={getSingleEntityUrl} width={140} isSortable />
          <EnumField label="Status" source="status" options={ACCOUNTING_CYCLE_OPTIONS} isSortable />
          <DateField source="closedAt" label="Closed At" isSortable />
          <EmployeeLinkFieldWithNameLoading label="Closed By" source="closedBy" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default AccountingCycleList;
