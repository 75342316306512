import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import RoomTypeDropdown from 'erp/room/shared/input/RoomTypeDropdown';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';

const RoomForm = () => {
  return (
    <>
      <FormSection>
        <FormRow>
          <TextInput source="roomNumber" label="Room Number" />
          <OfficeDropdown source="office" label="Office" />
        </FormRow>
        <FormRow>
          <RoomTypeDropdown source="type" label="Type" />
          <NumberInput source="squareMetersArea" label="Area (m²)" />
        </FormRow>
      </FormSection>
    </>
  );
};

export default RoomForm;
