// services
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useFormValidation from 'shared/uibuilder/form/useFormValidation';

export interface CurrencyInputProps extends DefaultInputPropTypes<string | number> {
  maxDecimal?: number;
  maxInteger?: number;
  allowNegative?: boolean;
  formatInitialValue?: (value?: StringOrNumber) => string;
  autocomplete?: 'off';
  suffix?: string;
  isControlledValue?: boolean;
}

const useCurrencyInputHelper = ({ maxDecimal, maxInteger, ...props }: CurrencyInputProps) => {
  const inputHelper = useInputHelper(props);
  const { getMaxDecimal, getMaxInteger } = useFormValidation(props.source);

  return {
    ...inputHelper,
    getMaxDecimal: () => maxDecimal || getMaxDecimal(),
    getMaxInteger: () => maxInteger || getMaxInteger(),
  };
};

export default useCurrencyInputHelper;
