/* istanbul ignore file */
// libs
import React from 'react';
import { func, shape } from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
// components
import FilePreviewLayout from 'artifact/shared/input/layout/preview/FilePreviewLayout';

const LoadingFilePreviewLayout = ({ loadingArtifactObject, onRemove }) => {
  return (
    <FilePreviewLayout artifactObject={loadingArtifactObject} onRemove={onRemove}>
      {loadingArtifactObject && loadingArtifactObject.loadingProgress ? (
        <LinearProgress
          color="primary"
          variant="determinate"
          value={loadingArtifactObject.loadingProgress}
          className="progress progress-xs"
          sx={{ marginTop: '-5px' }}
        />
      ) : null}
    </FilePreviewLayout>
  );
};

LoadingFilePreviewLayout.propTypes = {
  loadingArtifactObject: shape().isRequired,
  onRemove: func.isRequired,
};

export default LoadingFilePreviewLayout;
