/* istanbul ignore file */
export default {
  status: {
    type: 'enum',
    required: true,
    allowedTransitions: {
      value: {
        '': ['ASSIGNED'],
        NEW: ['ASSIGNED', 'DISQUALIFIED'],
        ASSIGNED: ['OPEN', 'DISQUALIFIED', 'POSTPONED'],
        OPEN: ['DISQUALIFIED', 'POSTPONED'],
        DISQUALIFIED: ['ASSIGNED', 'OPEN', 'POSTPONED'],
      },
    },
  },
  firstName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  lastName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  middleName: {
    type: 'string',
    required: false,
    maxSize: 255,
  },
  requestId: {
    type: 'string',
    required: false,
    maxSize: 255,
  },
  title: {
    type: 'string',
    required: false,
    maxSize: 255,
  },
  linkedInLink: {
    type: 'string',
    required: false,
    maxSize: 128,
  },
  email: {
    type: 'string',
    required: true,
    email: true,
  },
  phoneNumber: {
    type: 'string',
    required: true,
    phone: {
      value: true,
      message: 'Please enter phone number in format xxxxxxxxxxxx, max. 15 characters',
    },
  },
  disqualificationReason: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $status: {
          eq: 'DISQUALIFIED',
        },
      },
    },
    forbidden: {
      value: true,
      when: {
        $status: {
          ne: 'DISQUALIFIED',
        },
      },
    },
  },
  grade: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $status: {
          in: ['OPEN', 'DISQUALIFIED', 'QUALIFIED'],
        },
        $disqualificationReason: {
          ne: 'DUPLICATE',
        },
      },
    },
  },
  companyName: {
    type: 'string',
    required: true,
    maxSize: 64,
  },
  websiteAddress: {
    type: 'string',
    maxSize: 255,
  },
  industry: {
    type: 'enum',
    required: false,
  },
  employeesCount: {
    type: {
      value: 'int',
      message: 'Please enter max 6 digits',
    },
    maxValue: {
      value: 999999,
      message: 'Please enter max 6 digits',
    },
  },
  source: {
    type: 'enum',
    required: true,
  },
  medium: {
    type: 'enum',
    required: true,
  },

  ownerAlias: {
    type: 'string',
    required: {
      value: true,
      when: {
        $status: {
          in: ['ASSIGNED', 'OPEN', 'QUALIFIED', 'POSTPONED'],
        },
      },
    },
  },
  campaign: {
    type: 'string',
    maxSize: 255,
  },
  additionalEmail: {
    maxSize: 255,
    type: 'string',
    email: true,
  },

  address: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      country: {
        type: 'string',
        required: true,
      },
      addressLine1: {
        type: 'string',
        maxSize: 255,
        required: {
          value: true,
          when: {
            $status: {
              eq: 'QUALIFIED',
            },
          },
        },
      },
      city: {
        type: 'string',
        maxSize: 64,

        required: {
          value: true,
          when: {
            $status: {
              eq: 'QUALIFIED',
            },
          },
        },
      },
      state: {
        type: 'string',
      },
      zipCode: {
        type: 'string',
        maxSize: 64,

        required: {
          value: true,
          when: {
            $status: {
              eq: 'QUALIFIED',
            },
          },
        },
      },
    },
  },
  postponedReason: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $status: {
          eq: 'POSTPONED',
        },
      },
    },
  },
  postponedReviewDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $status: {
          eq: 'POSTPONED',
        },
      },
    },
  },
  description: {
    type: 'text',
    required: true,
    maxSize: 65000,
  },
  attachmentsIds: {
    type: 'array',
    maxSize: 15,
  },

  ip: {
    type: 'string',
    maxSize: 64,
  },
  userAgent: {
    type: 'string',
    maxSize: 512,
  },
  httpRefer: {
    type: 'string',
    maxSize: 2048,
  },
  googleAdsClickId: {
    type: 'string',
    maxSize: 255,
  },
  googleAnalystClientId: {
    type: 'string',
    maxSize: 255,
  },
};
