/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { EXPENSE_STATUSES_OPTIONS } from 'financialAnalytic/expenses/useExpenseService';

const ExpenseStatusField = (props: FieldProps) => {
  return <EnumField options={EXPENSE_STATUSES_OPTIONS} {...props} />;
};

export default ExpenseStatusField;
