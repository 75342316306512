import React from 'react';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { Dropdown } from 'shared/uibuilder/form/input';
import { useFilterContext, Filter, FilterCondition } from 'shared/uibuilder/list/filter/FilterContext';

interface EmployeeProbationFilterProps extends DefaultDropdownProps {
  value?: string;
}

const EmployeeProbationFilter = (props: EmployeeProbationFilterProps) => {
  const { source } = props;
  const { filterBy } = useFilterContext();

  const handleChange = (data: Filter = {}) => {
    const value = data[source][FilterCondition.EQ];

    filterBy({
      [source]: {
        [FilterCondition.EQ]: value === 'true',
      },
    });
  };

  return (
    <EqCondition {...props} onChangeCallback={handleChange}>
      <Dropdown {...props} placeholder="Select probation" options={[{ value: 'true', label: 'Active' }]} />
    </EqCondition>
  );
};

export default EmployeeProbationFilter;
