import React from 'react';
import { Button } from 'uibuilder/button';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const DefaultMilestonesTemplate = () => {
  const { getRawOnChangeCallback } = useInputHelper({
    source: 'milestones',
  });

  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const defaultOnClickCallback = () => {
    rawOnChangeCallback({
      milestones: [
        {
          id: 'M0',
          name: 'Contract signed',
          amount: '0',
          completionRate: '0',
        },
        {
          id: 'M1',
          name: '25% completion',
          amount: '',
          completionRate: '25',
        },
        {
          id: 'M2',
          name: '50% completion',
          amount: '',
          completionRate: '50',
        },
        {
          id: 'M3',
          name: '75% completion',
          amount: '',
          completionRate: '75',
        },
        {
          id: 'M4',
          name: '100% completion',
          amount: '',
          completionRate: '100',
        },
        {
          id: 'M5',
          name: 'Acceptance',
          amount: '',
          completionRate: '100',
        },
      ],
    });
  };

  return (
    <Button
      sx={{
        padding: '3px 15px',
      }}
      outline
      key="default-template-btn"
      onClick={defaultOnClickCallback}
    >
      Default template
    </Button>
  );
};

export default DefaultMilestonesTemplate;
