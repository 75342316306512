/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useAssignmentService from 'erp/assignment/shared/assignmentService';

const AssignmentContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useAssignmentService();
  const CONTEXT_NAME = 'ASSIGNMENT_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default AssignmentContext;
