/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useFeedbacksUrl } from 'erp/employee/feedbacks/FeedbacksRouter';

const FeedbacksBreadcrumbs = ({ children }: { children?: React.ReactElement | React.ReactElement[] }) => {
  const { getListUrl } = useFeedbacksUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Feedback" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default FeedbacksBreadcrumbs;
