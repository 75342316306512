import React from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useTrainingsService from 'erp/trainings/useTrainingsService';

type TrainingProgramDropdownProps = {
  label: string;
  source: string;
};

const TrainingProgramDropdown = (props: TrainingProgramDropdownProps) => {
  const { searchAll } = useTrainingsService();

  const loadTrainings = async () => {
    const trainings = await searchAll({});
    return {
      result: trainings,
    };
  };

  return (
    <LoadDropdown
      loadDataMethod={loadTrainings}
      placeholder="Select Training Program"
      mapResults={value => {
        return {
          value: value.id,
          label: value.name,
        };
      }}
      {...props}
    />
  );
};

export default TrainingProgramDropdown;
