import { Plugin } from 'ckeditor5';

export default class ResizableHeight extends Plugin {
  init() {
    const { editor } = this;

    this.editor.on('ready', () => {
      const height = '200px';
      const minHeight = '100px';
      const maxHeight = '1000px';
      const editingView = editor.editing.view;
      const editorMainElement = (editor.ui.view.element as Element).querySelector('.ck.ck-editor__main') as HTMLElement;

      editingView.document.on('focus', () => {
        editorMainElement.classList.add('ck-focused');
        editorMainElement.classList.remove('ck-blurred');
      });

      editingView.document.on('blur', () => {
        editorMainElement.classList.remove('ck-focused');
        editorMainElement.classList.add('ck-blurred');
      });

      if (height) {
        editorMainElement.style.height = height;
        (editor.ui.view.element as Element).classList.add('height-mode');
      }

      (editor.ui.view.element as Element).classList.add('resizable-mode');
      editorMainElement.style.minHeight = minHeight;
      editorMainElement.style.maxHeight = maxHeight;
    });
  }
}
