export default {
  roomNumber: {
    type: 'string',
    required: true,
  },
  office: {
    required: true,
  },
  type: {
    required: true,
  },
  squareMetersArea: {
    type: 'number',
  },
};
