/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const ViewCandidateTimelineButton = props => {
  const { getTimelineUrl } = useCandidateUrl();

  return (
    <LinkButton url={id => getTimelineUrl(id)} key="candidate-timeline" {...props}>
      Timeline
    </LinkButton>
  );
};

export default ViewCandidateTimelineButton;
