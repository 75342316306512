import React from 'react';
import { ButtonWithConfirmation } from 'uibuilder/button';
import useSowService, { MILESTONES_STATUS, WRITE_SOW } from '../../useSowService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

type AchieveMilestoneButtonProps = {
  sowId: string;
  parentShowSetData: (data: any) => void;
};

const AchieveMilestoneButton = ({ sowId, parentShowSetData }: AchieveMilestoneButtonProps) => {
  const { getValue: getMilestoneId } = useFieldHelper({ source: 'id' });
  const { getValue: getMilestoneStatus } = useFieldHelper({ source: 'status' });
  const status = getMilestoneStatus();
  const milestoneId = getMilestoneId();
  const { achieveMilestone, invalidateCache } = useSowService();

  if (status === MILESTONES_STATUS.ACHIEVED) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      permissionToCheck={WRITE_SOW}
      outline
      displayMessage="Are you sure you want to achieve this milestone?"
      submitMethod={() => achieveMilestone(sowId, milestoneId)}
      afterSubmit={{
        successMessage: 'This Milestone has been successfully achieved.',
        errorMessage: "Can't achieve this Milestone.",
        execute: (response: any) => {
          invalidateCache();
          parentShowSetData(response);
        },
      }}
    >
      Achieve
    </ButtonWithConfirmation>
  );
};

export default AchieveMilestoneButton;
