/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import PositionDropdown from 'erp/employee/shared/input/PositionDropdown';

const EmployeePositionFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <PositionDropdown />
    </DropDownFilter>
  );
};

export default EmployeePositionFilter;
