/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeWorkingStatusDropdown from 'erp/employee/shared/input/EmployeeWorkingStatusDropdown';

const EmployeeWorkingStatusFilter = ({ excluded, ...props }) => {
  return (
    <DropDownFilter {...props}>
      <EmployeeWorkingStatusDropdown excluded={excluded} />
    </DropDownFilter>
  );
};

export default EmployeeWorkingStatusFilter;
