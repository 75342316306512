/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const BudgetBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useBudgetUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Budget" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default BudgetBreadcrumbs;
