import { isIOS } from 'shared/uibuilder/helper';

// eslint-disable-next-line import/prefer-default-export
export const copyToClipboard = (str: string, containerSelector?: string) => {
  const textArea = document.createElement('textArea');
  // @ts-ignore
  textArea.value = str;
  textArea.setAttribute('readonly', '');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999px';

  const container = (containerSelector && document.querySelector(containerSelector)) || document.body;
  container.appendChild(textArea);

  if (isIOS()) {
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      // @ts-ignore
      textArea.setSelectionRange(0, 999999);
    }
  } else {
    // @ts-ignore
    textArea.select();
  }

  document.execCommand('copy');
  container.removeChild(textArea);
};
