/* istanbul ignore file */
import React, { useState } from 'react';
import useMailboxConsentService, { REVOKE_CONSENT_PERMISSION } from 'erp/employee/mailboxConsent/mailboxConsentService';
import { ButtonWithConfirmation } from 'uibuilder/button';

const RevokeMailboxConsentButton = () => {
  const { revokeConsent } = useMailboxConsentService();
  const [isVisible, setIsVisible] = useState(true);
  return isVisible ? (
    <ButtonWithConfirmation
      modalConfirmButtonText="Submit"
      displayMessage="If you remove consent for Mailbox Scrapping, the system will not be able to access your mailbox. Do you agree to this?"
      submitMethod={revokeConsent}
      color="secondary"
      outline
      permissionToCheck={REVOKE_CONSENT_PERMISSION}
      afterSubmit={{
        successMessage: 'Successfully revoked consent!',
        execute: () => setIsVisible(false),
      }}
    >
      Revoke Mailbox Consent
    </ButtonWithConfirmation>
  ) : null;
};

export default RevokeMailboxConsentButton;
