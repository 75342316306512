import React from 'react';
import Grid from 'uibuilder/Grid';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { getVisibility } from 'shared/uibuilder/helper';

export interface SectionRowProps {
  children: React.ReactElement | React.ReactElement[];
  colMd?: number;
  colXl?: number;
  colXs?: number;
}

const SectionRow = ({ children, colMd, colXl, colXs }: SectionRowProps) => {
  const { data } = useShowContext();

  return (
    <Grid container spacing={{ xs: 2, md: 4 }} sx={{ marginTop: '0 !important' }}>
      {React.Children.map(children, child => {
        return child && getVisibility(data, child && child.props && child.props.isVisible) ? (
          <Grid className="section-item" item xs={colXs} sm={colMd} xl={colXl}>
            {child}
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};

SectionRow.defaultProps = {
  colXs: 12,
  colMd: 4,
  colXl: 3,
};

export default SectionRow;
