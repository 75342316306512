/* istanbul ignore file */
import React from 'react';
import usePerformanceEvaluationProcessService from 'erp/employee/performanceEvaluation/process/shared/performanceEvaluationProcessService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';

const PerformanceEvaluationDropdown = (props: any) => {
  const { search } = usePerformanceEvaluationProcessService();
  const { formatDate } = useDateService();

  const loadProcesses = async () => {
    return search({
      sort: {
        to: 'DESC',
      },
      pageSize: 10,
      pageNumber: 0,
    });
  };

  const getLabel = (processData: any) => {
    const from = formatDate(processData?.from, DATE_FORMAT.SIMPLE);
    const to = formatDate(processData?.to, DATE_FORMAT.SIMPLE);

    return `${from} - ${to}`;
  };

  return (
    <LoadDropdown
      loadDataMethod={loadProcesses}
      mapResults={process => ({
        value: process.id,
        label: getLabel(process),
      })}
      showLoader
      placeholder="Select period"
      {...props}
    />
  );
};

export default PerformanceEvaluationDropdown;
