/* istanbul ignore file */
import React from 'react';
import usePurchaseOrderService, {
  APPROVE_PURCHASE_ORDER,
  PURCHASE_ORDER_STATUSES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { useParams } from 'react-router-dom';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ApprovePurchaseOrderButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { approvePurchaseOrder, invalidateCache } = usePurchaseOrderService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { data, setData = () => {} } = useShowContext();
  const status = getValue();
  const contextData = data.getData() || {};

  if (status !== PURCHASE_ORDER_STATUSES.PENDING) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to approve this purchase order?"
      submitMethod={() => approvePurchaseOrder(id)}
      afterSubmit={{
        successMessage: 'The Purchase Order has been successfully approved.',
        errorMessage: "Can't approve purchase order.",
        execute: (response: any) => {
          invalidateCache();
          setData({ ...contextData, ...response });
        },
      }}
      permissionToCheck={APPROVE_PURCHASE_ORDER}
      variant="outlined"
    >
      Approve
    </ButtonWithConfirmation>
  );
};

export default ApprovePurchaseOrderButton;
