/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import {
  APPLICATION_TYPE,
  MOBILE_OS,
  PRESALE_REQUEST_TYPE,
} from 'crm/opportunity/presale/request/shared/presaleRequestService';
import TextField from 'shared/uibuilder/field/TextField';
import BooleanOptionField from 'shared/uibuilder/field/BooleanOptionField';
import DateField from 'shared/uibuilder/field/DateField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import {
  showApplicationTypeField,
  showCollaborationExpectationFiled,
  showHasAutomationTestingField,
  showMaterialsField,
  showMobileOSField,
  showPercentTestCoverageField,
  showReplacementReasonField,
  showRequiredFteField,
  showScopeOfWorkField,
  showSystemCompletionField,
  showSystemHostingLocationField,
  showTeamInvolvementField,
  showThirdPartyField,
} from 'crm/opportunity/presale/request/shared/presaleRequestFieldVisibilityHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import HtmlField from 'shared/uibuilder/field/HtmlField';
import { CommaListField } from 'shared/uibuilder/field';

const PresaleRequestFields = () => {
  const { data } = useShowContext();

  return (
    <>
      <EnumField options={PRESALE_REQUEST_TYPE} source="requestType" label="Request Type" />
      {showSystemCompletionField(data) ? (
        <TextField source="systemCompletionProgress" label="System Completion Progress" />
      ) : null}
      {showTeamInvolvementField(data) ? (
        <BooleanOptionField source="hasPreviousTeamInvolvement" label="Previous team involvement" />
      ) : null}
      {showCollaborationExpectationFiled(data) ? (
        <TextField source="collaborationExpectations" label="Collaboration Expectations" />
      ) : null}
      {showSystemHostingLocationField(data) ? (
        <TextField source="systemHostingLocation" label="System Hosting Location" />
      ) : null}
      <DateField source="expectedCompletionAt" label="Expected timeline for completion" />
      {showHasAutomationTestingField(data) ? (
        <BooleanOptionField source="hasAutomatedTesting" label="Automated Testing Coverage" />
      ) : null}
      {showPercentTestCoverageField(data) ? (
        <TextField source="automatedTestingCoverage" label="% of coverage" />
      ) : null}
      {showApplicationTypeField(data) ? (
        <CommaListField
          mapValue={value => APPLICATION_TYPE[value]}
          source="applicationTypes"
          label="Application Types"
        />
      ) : null}
      {showMobileOSField(data) ? (
        <CommaListField mapValue={value => MOBILE_OS[value]} source="mobileOperationSystems" label="Mobile OS" />
      ) : null}
      {showThirdPartyField(data) ? (
        <BooleanOptionField source="hasThirdPartyIntegrations" label="Third-Party Integration" />
      ) : null}
      {showReplacementReasonField(data) ? <TextField source="replacementReason" label="Replacement reason" /> : null}
      {showRequiredFteField(data) ? <TextField source="requiredFte" label="Required FTE" /> : null}
      {showScopeOfWorkField(data) ? <TextField source="scopeOfWork" label="Scope of work" /> : null}
      {showMaterialsField(data) ? <HtmlField source="materials" label="Materials" /> : null}
      <AttachmentsListField source="attachmentIds" label="Attachments" />
    </>
  );
};

export default PresaleRequestFields;
