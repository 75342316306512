/* istanbul ignore file */
import React from 'react';
import { EmployeePerformanceDetailsFieldsProps } from 'erp/employee/salaries/shared/fields/EmployeePerformanceDetailsFields';
import { PERFORMANCE_GRADES_OPTIONS } from 'erp/employee/salaries/shared/salaryService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import MediumSectionRow from 'shared/uibuilder/layout/MediumSectionRow';
import { EnumField, HtmlField } from 'shared/uibuilder/field';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import {
  CUSTOMER_SUCCESS_IMPACT_SECTION,
  ECOSYSTEM_IMPACT_SECTION,
  ROLE_BASED_ESSENTIAL_IMPACT_SECTION,
} from 'erp/employee/salaries/shared/EmployeePerformanceLevelCriteriaTypes';

const criteriaSectionComponent = (source: string, section: any, sectionSource: string) => {
  const fieldSource = `${source}.${sectionSource}.${section.field}`;
  return (
    <ShowSection title={`${section.title}`} titleVariant="h5" key={section.field}>
      <MediumSectionRow>
        <HtmlField source={`${fieldSource}.positive`} label="Positive Details" />
        <HtmlField source={`${fieldSource}.negative`} label="Negative Details" />
      </MediumSectionRow>
    </ShowSection>
  );
};

const EmployeePerformanceLevelsField = ({ source }: EmployeePerformanceDetailsFieldsProps) => {
  const { data } = useShowContext();
  const isVisible = data.getValueBySource(`${source}`);

  return (
    isVisible && (
      <div>
        <ShowSection>
          <EnumField
            options={PERFORMANCE_GRADES_OPTIONS}
            source={`${source}.helpfulnessGrade`}
            label="Helpfulness Grade"
          />
        </ShowSection>
        <ShowSection title="L1 Role-based essential impact">
          {ROLE_BASED_ESSENTIAL_IMPACT_SECTION.map(section =>
            criteriaSectionComponent(source, section, 'roleBasedEssentialImpact'),
          )}
        </ShowSection>
        <ShowSection title="L2 Customer success impact">
          {CUSTOMER_SUCCESS_IMPACT_SECTION.map(section =>
            criteriaSectionComponent(source, section, 'customerSuccessImpact'),
          )}
        </ShowSection>
        <ShowSection title="L3 Ecosystem impact">
          {ECOSYSTEM_IMPACT_SECTION.map(section => criteriaSectionComponent(source, section, 'ecosystemImpact'))}
        </ShowSection>
        <ShowSection title="Personality traits">
          <HtmlField source={`${source}.personalityTraits`} label="" />
        </ShowSection>
      </div>
    )
  );
};

export default EmployeePerformanceLevelsField;
