/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';

const EditLeaveButton = props => {
  const { getUpdateEntityUrl } = useLeaveUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} {...props} />;
};

export default EditLeaveButton;
