import React from 'react';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import { useProjectWeeklyReportsUrl } from '../weeklyreports/ProjectWeeklyReportRouter';
import SingleProjectBreadcrumbs from '../shared/SingleProjectBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const ProjectsWbsDashboardBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useProjectWeeklyReportsUrl();
  const projectWeeklyReportsListLink = getListUrl();

  return (
    <SingleProjectBreadcrumbs>
      <ListBreadcrumbItem entity="WBS Dashboard" link={projectWeeklyReportsListLink} />
      {children}
    </SingleProjectBreadcrumbs>
  );
};

export default ProjectsWbsDashboardBreadcrumbs;
