/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { DAY_TYPES, isWeekend, MetricProps } from 'erp/employee/performanceReport/usePerformanceReportService';
import Tooltip from 'uibuilder/Tooltip';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

interface PerformanceHeatmapCellProps extends MetricProps {
  colored?: boolean;
  className?: string;
}

const PerformanceHeatmapCell = ({
  dayType,
  day,
  metric: { message, value, mark } = {},
  colored = true,
  className,
}: PerformanceHeatmapCellProps) => {
  const today = moment().format(DATE_FORMAT.INPUT_VALUE);
  const currentDate = day ? moment(day) : null;

  return (
    <Tooltip title={message} placement="right">
      <li
        className={classnames(className, { today: day === today })}
        data-datetype={dayType || (isWeekend(currentDate?.day()) ? DAY_TYPES.HOLIDAY : DAY_TYPES.WORKING)}
        data-state={colored ? mark : ''}
      >
        {value}
      </li>
    </Tooltip>
  );
};

export default PerformanceHeatmapCell;
