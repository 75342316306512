/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_ACCOUNT } from 'crm/account/accountService';
import { useAccountUrl } from 'crm/account/index';

const CreateAccountButton = props => {
  const { getCreateUrl } = useAccountUrl();

  return <CreateButton url={getCreateUrl()} permissionToCheck={CREATE_ACCOUNT} label="Create Account" {...props} />;
};

export default CreateAccountButton;
