import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeContractTypeDropdown from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';

const ContractTypeFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <EmployeeContractTypeDropdown />
    </DropDownFilter>
  );
};

export default ContractTypeFilter;
