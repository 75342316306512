import React, { useEffect } from 'react';
import useReconciliationService from 'erp/costaccounting/reconcilation/useReconciliationService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ReconciliationStaticFilters from 'erp/costaccounting/reconcilation/shared/filter/ReconciliationStaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import OfficeFilter from 'financialAnalytic/shared/filter/OfficeFilter';
import ReconciliationStatusFilter from 'erp/costaccounting/reconcilation/shared/filter/ReconciliationStatusFilter';
import NotReconciliatedTransfersLists from './NotReconciliatedTransfersLists';
import UploadFileButton from 'erp/costaccounting/reconcilation/shared/button/UploadFileButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { UpdateListDataAndReloadGroupsProps } from './ReconciliationListDataContext';
import { InputData } from 'shared/uibuilder/list/builder/useListCrud';
import ReconciliationGroupsContents from './ReconciliationGroupsContents';
import TabsWrapper from 'shared/uibuilder/TabsWrapper/TabsWrapper';
import ReconciliationDropdownFilter from '../shared/input/ReconciliationDropdownFilter';

export type ReconciliationListPageContentProps = {
  bankData: InputData;
  bankLoading: boolean;
  davinciData: InputData;
  davinciLoading: boolean;
  reloadData?: () => void;
  isSensitiveDataIsShown?: boolean;
  updateListDataAndReloadGroups?: (data: UpdateListDataAndReloadGroupsProps) => void;
};

const filters = (
  <FiltersLayout>
    <OfficeFilter source="office" label="Office" />
    <ReconciliationStatusFilter source="status" label="Status" />
    <ReconciliationDropdownFilter source="type" label="Type" />
  </FiltersLayout>
);

const ReconciliationListPageContent = ({
  isSensitiveDataIsShown,
  toggleSensitiveDataButton,
  bankData,
  bankLoading,
  davinciData,
  davinciLoading,
  reloadData = () => {},
  reloadSensitiveData,
  updateListDataAndReloadGroups,
}: ReconciliationListPageContentProps & {
  reloadSensitiveData?: () => void;
  toggleSensitiveDataButton?: Nullable<React.ReactElement>;
  updateListDataAndReloadGroups: (data: UpdateListDataAndReloadGroupsProps) => void;
}) => {
  const { getFilterValidationSchema } = useReconciliationService();
  const { data, loading } = useListContext();

  useEffect(() => {
    if (isSensitiveDataIsShown && reloadSensitiveData && !loading && !davinciLoading && !bankLoading) {
      reloadSensitiveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.items, loading, davinciLoading, bankLoading]);

  return (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <ListPageLayout
        header={
          <ReconciliationStaticFilters title={<ListHeader label="Reconciliation" />}>
            {toggleSensitiveDataButton}
            <UploadFileButton execute={reloadData} />
          </ReconciliationStaticFilters>
        }
        filter={filters}
      >
        <TabsWrapper
          tabs={[
            {
              index: 0,
              label: 'Not Reconciliated Transfers',
              content: (
                <NotReconciliatedTransfersLists
                  isSensitiveDataIsShown={isSensitiveDataIsShown}
                  bankData={bankData}
                  bankLoading={bankLoading}
                  davinciData={davinciData}
                  davinciLoading={davinciLoading}
                  updateListDataAndReloadGroups={updateListDataAndReloadGroups}
                />
              ),
            },
            {
              index: 1,
              label: 'Reconciliation Groups',
              content: (
                <ReconciliationGroupsContents
                  bankData={bankData}
                  davinciData={davinciData}
                  isSensitiveDataIsShown={isSensitiveDataIsShown}
                  updateListDataAndReloadGroups={updateListDataAndReloadGroups}
                  reloadData={reloadData}
                />
              ),
            },
          ]}
        />
      </ListPageLayout>
    </Filters>
  );
};

export default ReconciliationListPageContent;
