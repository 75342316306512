/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const OpportunitiesMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Opportunities" icon="compass" {...props} />
);

OpportunitiesMenuItem.displayName = 'Opportunities';

OpportunitiesMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default OpportunitiesMenuItem;
