/* istanbul ignore file */
import React from 'react';
import RbsBreadcrumbs from 'erp/rbs/shared/RbsBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateResponsibilityBreadcrumbs = () => {
  return (
    <RbsBreadcrumbs>
      <CreateBreadcrumbItem entity="Responsibility" />
    </RbsBreadcrumbs>
  );
};

export default CreateResponsibilityBreadcrumbs;
