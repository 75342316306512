/* istanbul ignore file */
const HTTP = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  OK: 200,
  SERVICE_UNAVAILABLE: 503,
};

enum HTTP_METHOD {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

enum CONTENT_TYPE {
  JSON = 'application/json',
  MULTIPART = '', // need to leave it blank to let browser set its own content type
}

const CUSTOM_RESPONSE = {
  USER_IS_NOT_ADDED_TO_PROJECT: 'USER_IS_NOT_ADDED_TO_PROJECT',
  USER_IS_LOCKED_IN_REDMINE: 'USER_IS_LOCKED_IN_REDMINE',
};

const XHR_STATE_READY = 4;

const ROUTE403 = '/access-denied';

const ROUTE404 = '/not-found';

export { CONTENT_TYPE, HTTP, HTTP_METHOD, CUSTOM_RESPONSE, XHR_STATE_READY, ROUTE403, ROUTE404 };
