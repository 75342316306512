import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from 'uibuilder/Typography';
import useAsyncValue from 'shared/asyncHelper';
import useAuthenticationService from 'authentication/authenticationService';

const Page404 = () => {
  const { isAuthenticated } = useAuthenticationService();
  const isUserAuthenticated = useAsyncValue(isAuthenticated);

  return (
    <Container
      sx={{
        justifyContent: 'center',
        minHeight: 'calc(100vh - 90px)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="display0" component="h1" sx={{ float: 'left', marginRight: '30px' }} className="c-title">
        404
      </Typography>
      <div>
        <Typography variant="h4" sx={{ marginBottom: '0.5rem', paddingTop: '0.75rem' }}>
          Page not found.
        </Typography>
        {isUserAuthenticated && (
          <Typography color="neutral.main">
            You can go back to&nbsp;
            <Link to="/">One Stop</Link>
          </Typography>
        )}
      </div>
    </Container>
  );
};

export default Page404;
