/* istanbul ignore file */
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import useMessageService, { ErrorMessage, SuccessMessage, WarningMessage } from 'shared/message/messageService';
import React from 'react';

const useResponseMessageHelper = () => {
  const { addMessage } = useMessageService();

  const handleStatusContext = (statusContext: StatusContext) => {
    const { errors, successes, instructions } = statusContext;
    const statusMessage = (
      <>
        {successes}
        {errors}
        <hr className="mb-0" />
        {instructions}
      </>
    );

    if (errors.length > 0 && successes.length > 0) {
      addMessage(new WarningMessage(statusMessage));
    } else if (errors.length > 0) {
      addMessage(new ErrorMessage(statusMessage));
    } else {
      addMessage(new SuccessMessage(statusMessage));
    }
  };

  return {
    handleStatusContext,
  };
};

export default useResponseMessageHelper;
