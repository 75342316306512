import React from 'react';
import useCorporateEventService from 'financialAnalytic/corporateEvents/useCorporateEventService';
import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateCorporateEventBreadcrumbs from 'financialAnalytic/corporateEvents/createupdate/Create/Breadcrumbs';
import CorporateEventForm from 'financialAnalytic/corporateEvents/createupdate/CorporateEventForm';

const CreateCorporateEventPage = () => {
  const { create, getValidationSchema } = useCorporateEventService();
  const { getSingleEntityUrl } = useCorporateEventUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: ({ id }) => getSingleEntityUrl(id),
        message: 'You are awesome! The Corporate Event has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{}}
    >
      <CreatePageLayout breadcrumbs={<CreateCorporateEventBreadcrumbs />} title="Create: Corporate Event">
        <CorporateEventForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateCorporateEventPage;
