import React from 'react';
import { Link } from 'react-router-dom';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { string, element, oneOfType } from 'prop-types';

const EmployeeLink = ({ id, name }) => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return (
    <>
      {id ? (
        <Link
          to={getSingleEntityUrl(id)}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {name}
        </Link>
      ) : (
        <span>{name}</span>
      )}
    </>
  );
};

EmployeeLink.propTypes = {
  id: string,
  name: oneOfType([string, element]).isRequired,
};

EmployeeLink.defaultProps = {
  id: null,
};

export default EmployeeLink;
