/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useJournalEntryService from 'financialAnalytic/journalEntry/useJournalEntryService';
import { useJournalEntryId, useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import CreateLedgerBreadcrumbs from 'financialAnalytic/journalEntry/createupdate/Create/Breadcrumbs';
import JournalEntryForm from 'financialAnalytic/journalEntry/createupdate/JournalEntryForm';
import { useSearchParameters } from 'shared/routing/helper';

const CreateLedgerPage = () => {
  const { create, getById, getValidationSchema } = useJournalEntryService();
  const { getListUrl } = useJournalEntryUrl();
  const id = useJournalEntryId();
  let getDataFunc;
  const { accountId, currency } = useSearchParameters();

  if (id) {
    getDataFunc = () => getById(id);
  }

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Journal Entry has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        totals: 0,
        transfers: [{ accountId, currency }, {}],
      }}
    >
      <CreatePageLayout breadcrumbs={<CreateLedgerBreadcrumbs />} title="Create: Journal Entry">
        <JournalEntryForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateLedgerPage;
