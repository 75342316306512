/* istanbul ignore file */
import React from 'react';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import EmployeeDropdownFilter from 'erp/employee/List/filter/EmployeeDropdownFilter';

const RecruiterFilter = (props: BaseFilterProps) => {
  const filter = {
    'specification:isRecruiter': {
      eq: true,
    },
  };

  return <EmployeeDropdownFilter filter={filter} {...props} />;
};

export default RecruiterFilter;
