/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import useBudgetService, { ACTIVE_STATUS, CREATE_BUDGET } from 'erp/budget/shared/budgetService';
import { useBudgetUrl } from 'erp/budget/BudgetRouter';
import BudgetListLayout from './BudgetListLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import BudgetStatusFilter from 'erp/budget/shared/filter/BudgetStatusFilter';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';

const BudgetList = () => {
  const { searchRootBudgets } = useBudgetService();
  const { getCreateUrl } = useBudgetUrl();

  const filters = (
    <Filters>
      <FiltersLayout>
        <BudgetStatusFilter source="status" label="Status" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List
      getDataMethod={searchRootBudgets}
      defaultSortOrder="ASC"
      defaultSortField="createdAt"
      defaultFilter={{
        status: { in: [ACTIVE_STATUS] },
      }}
    >
      <ListPageLayout
        header={<ListHeader label="Budgets" icon="fa-coins" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Budget"
            key="create-budget"
            permissionToCheck={CREATE_BUDGET}
          />,
        ]}
        filter={filters}
        search={<SearchInput label="Budget Name" />}
      >
        <BudgetListLayout />
      </ListPageLayout>
    </List>
  );
};

export default BudgetList;
