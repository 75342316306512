/* istanbul ignore file */
import React from 'react';
import { INVOICE_STATUSES_OPTIONS, INVOICE_STATUSES } from 'financialAnalytic/invoice/useInvoiceService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateInvoiceButton from 'financialAnalytic/invoice/shared/button/CreateInvoiceButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import InvoiceLink from 'financialAnalytic/invoice/shared/field/InvoiceLink';
import ViewInvoiceButton from 'financialAnalytic/invoice/shared/button/ViewInvoiceButton';
import EnumField from 'shared/uibuilder/field/EnumField';
import TextField from 'shared/uibuilder/field/TextField';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import DateField from 'shared/uibuilder/field/DateField';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import InvoiceStatusFilter from 'financialAnalytic/invoice/shared/filter/InvoiceStatusFilter';
import MutableInvoiceBadge from 'financialAnalytic/invoice/shared/MutableInvoiceBadge';
import ListPageHeaderWrapper from 'financialAnalytic/shared/ListPageHeaderWrapper';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import BooleanFilter from 'shared/uibuilder/list/filter/BooleanFilter';
import OfficeFilter from 'financialAnalytic/shared/filter/OfficeFilter';
import invoiceFilterValidation from 'financialAnalytic/invoice/List/invoiceFilterValidation';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import RunInvoiceSubTotalUpdateButton from 'financialAnalytic/invoice/shared/button/RunInvoiceSubTotalUpdateButton';
import DownloadInvoicesReportButton from 'financialAnalytic/invoice/shared/button/DownloadInvoicesReportButton';

const InvoiceListPageContent = ({
  isSensitiveDataIsShown,
  toggleSensitiveDataButton,
}: {
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: Nullable<React.ReactElement>;
}) => {
  const filters = (
    <Filters getValidationSchema={() => Promise.resolve(invoiceFilterValidation)}>
      <FiltersLayout
        quickFilters={[
          <QuickFilter label="All Pending Invoices" state={{ status: { in: [INVOICE_STATUSES.PENDING] } }} />,
          <QuickFilter label="All Approved Invoices" state={{ status: { in: [INVOICE_STATUSES.APPROVED] } }} />,
        ]}
      >
        <InvoiceStatusFilter label="Status" source="status" />
        <DateRangeFilter source="invoiceDate" label="Period" />
        <OfficeFilter source="office" label="Office" />
        <BooleanFilter source="isFutureExpense" label="Future Expense" />
        <BooleanFilter source="isInvoiceDocumentWillArriveLater" label="Can be updated" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ListPageLayout
      header={
        <ListPageHeaderWrapper
          header={<ListHeader label="Invoices" />}
          actions={[toggleSensitiveDataButton, <DownloadInvoicesReportButton />, <RunInvoiceSubTotalUpdateButton />]}
        />
      }
      actions={[<CreateInvoiceButton />]}
      filter={filters}
      // TODO: uncomment after fixing search on BE
      // search={<SearchInput label="Name" />}
    >
      <DataGridLayout buttons={[<ViewInvoiceButton source="id" />]}>
        {
          [
            <BadgeField source="id" label="Invoice Number" badges={[<MutableInvoiceBadge />]} width="17%">
              <InvoiceLink source="id" nameSource="invoiceNumber" label="Invoice Number" />
            </BadgeField>,
            <SearchResultField source="name" label="Vendor Name" width="27%" />,
            isSensitiveDataIsShown ? (
              <InvoiceTotalField label="Total" source="subTotal.amount" currencySource="currency" width="10%" />
            ) : null,
            <EnumField label="Status" source="status" options={INVOICE_STATUSES_OPTIONS} width="10%" isSortable />,
            <DateField source="invoiceDate" label="Date" width="10%" isSortable />,
            <LedgerLink source="account" nameSource="account" label="Account" width="22%" />,
            <TextField source="description" width="25%" />,
          ].filter(Boolean) as React.ReactElement[]
        }
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default InvoiceListPageContent;
