/* istanbul ignore file */
import EnumField from 'shared/uibuilder/field/EnumField';
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { CandidateApplicationSourceTypeOptions } from 'erp/candidate/applications/shared/candidateApplicationService';

const CandidateApplicationSourceTypeField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  return (
    <EnumField label={getLabel() || 'Source type'} value={getValue()} options={CandidateApplicationSourceTypeOptions} />
  );
};

export default CandidateApplicationSourceTypeField;
