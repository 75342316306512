/* istanbul ignore file */
import React, { useState } from 'react';
import PresaleRequestForm from 'crm/opportunity/presale/request/form/PresaleRequestForm';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import BeforeSubmitModal from 'crm/opportunity/presale/request/shared/modal/BeforeSubmitModal';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { REQUEST_STATUS } from 'crm/opportunity/presale/request/shared/presaleRequestService';

const UpdatePresaleLayout = ({ onCancel }: { onCancel?: () => void }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data } = useFormContext();
  const requestStatus = data?.requestStatus;

  const onSubmitAction = requestStatus === REQUEST_STATUS.DRAFT ? () => setModalOpen(true) : undefined;

  return (
    <UpdateTimelineItemLayout onCancel={onCancel} onSubmitAction={onSubmitAction}>
      <BeforeSubmitModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <PresaleRequestForm />
    </UpdateTimelineItemLayout>
  );
};

export default UpdatePresaleLayout;
