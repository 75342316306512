import React, { useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import {
  useDateTimeService,
  DEFAULT_FORMAT,
  DATE_FORMAT,
  GREATER_OR_EQUAL,
  LESS_OR_EQUAL,
} from 'shared/uibuilder/dateService';
import { DateRangeFilterLayoutType } from 'shared/uibuilder/list/filter/DateRangeFilter';
import DefaultFilterInputLayout from './DefaultFilterInputLayout';
import FilterActions from './FilterActions';
import './DateRangeFilterLayout.scss';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const DateRangeFilterLayout: DateRangeFilterLayoutType = ({
  source,
  label,
  children,
  leValue,
  geValue,
  onChangeCallback,
  className,
  onResetFilterCallback,
  popperPlacement,
}) => {
  const { filter: listFilter = {} } = useListContext();
  const { getLocalDate } = useDateTimeService();
  const [isMenuOpened, setIsMenuOpen] = useState(false);

  const formatDate = useCallback(
    (value: string, format = DATE_FORMAT.SIMPLE) => {
      return value && getLocalDate(value).isValid() ? getLocalDate(value).format(format) : '';
    },
    [getLocalDate],
  );

  const displayedValue = useMemo(() => {
    let dateValue = '';

    if (geValue && !leValue) {
      dateValue += 'from ';
    }

    dateValue += formatDate(geValue) || '';

    if (geValue && leValue) {
      dateValue += ' - ';
    }

    if (!geValue && leValue) {
      dateValue += 'to ';
    }

    dateValue += formatDate(leValue) || '';

    return dateValue;
  }, [geValue, leValue, formatDate]);

  const dateInputProps = {
    label: '',
    showCalendar: false,
    className: 'filter-datepicker',
  };

  const dateInputs = React.Children.toArray(children) as React.ReactElement[];

  const toggleMenu = () => {
    if (isMenuOpened) {
      resetFilterChanges();
    }

    setIsMenuOpen(prevValue => !prevValue);
  };

  const resetFilterChanges = useCallback(() => {
    const previousFilterValue = listFilter[source] || {};

    onChangeCallback({
      [GREATER_OR_EQUAL]: formatDate(previousFilterValue[GREATER_OR_EQUAL], DEFAULT_FORMAT),
    });
    onChangeCallback({
      [LESS_OR_EQUAL]: formatDate(previousFilterValue[LESS_OR_EQUAL], DEFAULT_FORMAT),
    });
  }, [listFilter, onChangeCallback, formatDate, source]);

  return (
    <DefaultFilterInputLayout
      label={label}
      displayedValue={displayedValue}
      isOpen={isMenuOpened}
      toggleMenu={toggleMenu}
      source={source}
      className={classnames('daterange-button-dropdown', className)}
      onResetFilterCallback={onResetFilterCallback}
      popperPlacement={popperPlacement}
    >
      <div className="daterange-inputs">
        {React.cloneElement(dateInputs[0], dateInputProps)}
        <span className="daterange-separator">—</span>
        {React.cloneElement(dateInputs[2] || dateInputs[1], dateInputProps)}
      </div>
      <FilterActions
        onChangeCallback={() => {
          setIsMenuOpen(false);
        }}
        onResetCallback={toggleMenu}
      />
    </DefaultFilterInputLayout>
  );
};

export default DateRangeFilterLayout;
