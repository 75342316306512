/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_CONTACT } from 'crm/contact/shared/contactService';
import { useContactUrl } from 'crm/contact/ContactRouter';

const CreateContactButton = props => {
  const { getCreateUrl } = useContactUrl();

  return <CreateButton url={getCreateUrl()} permissionToCheck={CREATE_CONTACT} label="Create Contact" {...props} />;
};

export default CreateContactButton;
