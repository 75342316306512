// @ts-ignore
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
import React, { useRef, useState } from 'react';
import './highlights.scss';
import HighlightActivityMarker from 'camunda/monitoring/facade/marker/HighlightActivityMarker';

export interface Marker {
  activityId: string;
  html: string;
  position: {
    top: number;
    left: number;
  };
}

type DiagramProps = {
  className?: string;
};
export interface BpmnDiagramService {
  getDiagramElement: () => HTMLDivElement;
  Diagram: React.FunctionComponent<DiagramProps>;
  loadXml: (xml: string) => Promise<any>; // promise contains bpmn-js warnings
  isLoaded: () => boolean;
  addClass: (activityId: string, className: string) => void;
  addMarker: (props: Marker) => string;
  removeMarker: (markerId: string) => void;
  zoomToElement: (activityId: string) => void;
  addEventListener: (callback: (event: Event) => void) => void;
}

export enum ElementType {
  USER_TASK = 'bpmn:UserTask',
  INTERMEDIATE_CATCH_EVENT = 'bpmn:IntermediateCatchEvent',
}

export interface Element {
  id: string;
  type: ElementType;
}

export enum EventType {
  HOVER = 'element.hover',
  OUT = 'element.out',
  CLICK = 'element.click',
  DOUBLE_CLICK = 'element.dblclick',
  MOUSEDOWN = 'element.mousedown',
  MOUSEUP = 'element.mouseup',
}

export interface Event {
  type: EventType;
  element: Element | null;
}

export interface EventListener {
  id: string;
  handle: (event: Event) => void;
}

const useBpmnJs = (): BpmnDiagramService => {
  const ref = useRef<HTMLDivElement>();
  const Diagram = (props: any) => <div ref={ref} {...props} />;

  const [viewerState, setViewerState] = useState<Nullable<BpmnViewer>>(null);
  let viewer: Nullable<BpmnViewer> = null;

  const getViewer = (): BpmnViewer => {
    let result;
    if (viewerState) {
      result = viewerState;
    } else if (viewer) {
      result = viewer;
    } else {
      viewer = new BpmnViewer({
        container: ref.current,
      });
      setViewerState(viewer);
      result = viewer;
    }
    return result;
  };

  const getCanvas = () => getViewer().get('canvas') as any;
  const getOverlays = () => getViewer().get('overlays') as any;
  const getElementRegistry = () => getViewer().get('elementRegistry') as any;
  const getEventBus = () => getViewer().get('eventBus') as any;

  const [loaded, setLoaded] = useState(false);
  const isLoaded = (): boolean => loaded;
  const loadXml = async (xml: string) => {
    return getViewer()
      .importXML(xml)
      .then(() => {
        getCanvas().zoom('fit-viewport');
        setLoaded(true);
      });
  };

  const addClass = (activityId: string, className: string) => {
    getCanvas().addMarker(activityId, className);
  };

  const addMarker = (props: Marker): string => {
    const { activityId, position, html } = props;
    return getOverlays().add(activityId, {
      position,
      html,
    });
  };

  const removeMarker = (markerId: string) => {
    getOverlays().remove(markerId);
  };

  const zoomToElement = (id: string) => {
    const zoom = () => {
      const bbox = getElementRegistry().get(id);
      const currentViewbox = getCanvas().viewbox();
      const elementMid = {
        x: bbox.x + bbox.width / 2,
        y: bbox.y + bbox.height / 2,
      };
      getCanvas().viewbox({
        x: elementMid.x - currentViewbox.width / 2,
        y: elementMid.y - currentViewbox.height / 2,
        width: currentViewbox.width,
        height: currentViewbox.height,
      });
    };
    const highlightElement = () => {
      const activity = getElementRegistry().get(id);
      const highlightMarkerId = addMarker(new HighlightActivityMarker(id, activity.height, activity.width));
      setTimeout(() => {
        removeMarker(highlightMarkerId);
      }, 2000);
    };

    zoom();
    highlightElement();
  };

  const addEventListener = (callback: any) => {
    const eventBus = getEventBus();
    Object.values(EventType).forEach(type => {
      eventBus.on(type, callback);
    });
  };

  return {
    // @ts-ignore
    getDiagramElement: () => ref.current,
    Diagram,
    loadXml,
    isLoaded,
    addClass,
    addMarker,
    removeMarker,
    zoomToElement,
    addEventListener,
  };
};

export default useBpmnJs;
