import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';

export const OFFICE_PATH = '/offices';
export const RESOURCE_URL = '/offices';

const useOfficeService = () => {
  const { sendGetRequest } = useKernelApi();

  const { ...baseCrud } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const listAll = async () => {
    const response = await sendGetRequest(RESOURCE_URL);
    return response.json();
  };

  return {
    listAll,
    ...baseCrud,
  };
};

export default useOfficeService;
