import React, { useState, useEffect } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import useCustomerInvoiceService from '../../useCustomerInvoiceService';
import { useCustomerInvoiceUrl } from '../../CustomerInvoicesRouter';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import TextField from 'shared/uibuilder/field/TextField';
import { LinkFieldProps } from 'shared/uibuilder/field/LinkField';
import InputLoader from '../../../../shared/uibuilder/InputLoader';

const CorrectedInvoiceLinkField = ({ source, label, ...props }: LinkFieldProps) => {
  const { getValue: getId } = useFieldHelper<string>({ source });
  const { getById } = useCustomerInvoiceService();
  const { getSingleEntityUrl } = useCustomerInvoiceUrl();

  const [linkText, setLinkText] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const id = getId();

  useEffect(() => {
    const fetchInvoiceNumber = async () => {
      if (id) {
        try {
          const invoice = await getById(id);
          setLinkText(invoice ? invoice.invoiceNumber : null);
        } catch (error) {
          setLinkText(null);
        }
      }
      setLoading(false);
    };

    fetchInvoiceNumber();
  }, [id, getById]);

  if (isLoading) {
    return <InputLoader />;
  }

  return id && linkText ? (
    <LinkField {...props} label={label} linkText={linkText} value={getSingleEntityUrl(id)} isInternal={false} />
  ) : (
    <TextField label={label} value={EMPTY_VALUE_INDICATOR} />
  );
};

export default CorrectedInvoiceLinkField;
