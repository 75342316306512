/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Loading from 'shared/uibuilder/Loading';
import Fade from 'uibuilder/Fade';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import '../PerformanceHeatmap.scss';

const PerformanceHeatmapLayout = ({
  filter,
  hasUpdateDate = true,
  children,
}: {
  filter: React.ReactNode;
  children: React.ReactNode;
  hasUpdateDate?: boolean;
}) => {
  const { data, filter: { period: { eq: currentMonth = '' } = {} } = {}, loading } = useListContext();
  const items = data?.items as any;
  const lastUpdated = items?.updatedAt ? moment(items.updatedAt).format(DATE_FORMAT.FULL) : '';
  const today = moment();
  const startOfMonth = moment(currentMonth).startOf('month');
  const endOfMonth = moment(currentMonth).endOf('month');
  const [mapLoading, setMapLoading] = useState(true);

  useEffect(() => {
    setMapLoading(true);
  }, [loading, currentMonth]);

  useEffect(() => {
    setMapLoading(false);
  }, [items]);

  if (loading || mapLoading) {
    return <Loading />;
  }

  return (
    <Fade in={!loading && !mapLoading} timeout={20}>
      <div>
        <div className="performance-heatmap__header">
          <div>
            <b>
              {startOfMonth.format(DATE_FORMAT.FULL_DATE)} -{' '}
              {today.month() === endOfMonth.month()
                ? today.format(DATE_FORMAT.FULL_DATE)
                : endOfMonth.format(DATE_FORMAT.FULL_DATE)}
            </b>
            {hasUpdateDate && <div>Last indicators update: {lastUpdated}</div>}
          </div>
          <div className="performance-heatmap__header-controls">{filter}</div>
        </div>

        {children}
      </div>
    </Fade>
  );
};

export default PerformanceHeatmapLayout;
