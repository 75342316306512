/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useProjectService, { DELETE_PROJECT } from 'erp/project/shared/projectService';
import { useProjectUrl } from 'erp/project/ProjectRouter';

const deleteMessage = 'Do you really want to delete this project?';

const DeleteProjectButton = () => {
  const { id: projectId } = useParams<Dictionary<string>>();
  const { deleteById } = useProjectService();
  const { getListUrl } = useProjectUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(projectId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Project has been successfully deleted.',
        errorMessage: `Can't delete project`,
      }}
      permissionToCheck={DELETE_PROJECT}
      sx={{ marginLeft: '12px' }}
    />
  );
};

export default DeleteProjectButton;
