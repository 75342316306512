/* istanbul ignore file */

import React from 'react';
import { AfterSubmitProps } from 'shared/uibuilder/form/formHelper';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import useEmployeeCareerNextReviewDateService from 'erp/employee/qualification/shared/employeeCareerNextReviewDateService';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput } from 'shared/uibuilder/form/input';

interface ChangeCareerDevelopmentNextReviewDateFormProps {
  closeModal: () => void;
  modalOpen: boolean;
  currentReviewDate: string;
  afterSubmit: AfterSubmitProps<(response: object) => void>;
}

const ChangeCareerDevelopmentNextReviewDateForm = ({
  closeModal,
  modalOpen,
  currentReviewDate,
  afterSubmit,
}: ChangeCareerDevelopmentNextReviewDateFormProps) => {
  const { update } = useEmployeeCareerNextReviewDateService();
  const { addMessage } = useMessageService();

  return (
    <ModalForm
      closeModal={closeModal}
      modalOpen={modalOpen}
      submitFormFunc={data => update(data)}
      afterSubmit={{
        ...afterSubmit,
        execute: response => {
          if (afterSubmit && afterSubmit.execute) {
            afterSubmit.execute(response);
          }
          addMessage(new SuccessMessage('Next Review Date successfully updated'));
        },
      }}
      initialData={{
        date: currentReviewDate,
      }}
    >
      <FormSection>
        <DateInput source="date" label="Next Review Date" />
      </FormSection>
    </ModalForm>
  );
};

export default ChangeCareerDevelopmentNextReviewDateForm;
