import { useBusinessEngineApi } from 'api';
import { ListResponse } from '../uibuilder/list/builder/useListCrud';
import { TimelineItem, TimelineItems } from '../uibuilder/timeline/timelineHelper';
import { ResourceData, ResourceId } from './baseCrudService';

type TimelineService = (props: {
  parentEntityUrl: string;
  apiService?: any;
}) => {
  search: (entityId: ResourceId, request: ResourceData) => Promise<ListResponse<TimelineItems>>;
  create: (entityId: ResourceId, timelineEntityType: string, data: ResourceData) => Promise<Partial<TimelineItem>>;
  getTimelinePageById: (entityId: ResourceId, timelineEntryId: ResourceId) => Promise<ListResponse<TimelineItems>>;
};

const useTimelineService: TimelineService = ({ parentEntityUrl, apiService = null }) => {
  let useService;

  if (!apiService) {
    useService = useBusinessEngineApi;
  } else {
    useService = apiService;
  }

  const { sendPostRequest } = useService();

  const search = async (entityId: ResourceId, request: ResourceData) => {
    const url = `${parentEntityUrl}/timeline/search`.replace(':id', `${entityId}`);

    const response = await sendPostRequest(url, request);
    return response.json();
  };

  const create = async (entityId: ResourceId, timelineEntityType: string, data: ResourceData) => {
    const url = `${parentEntityUrl}/${timelineEntityType}`.replace(':id', `${entityId}`);

    const response = await sendPostRequest(url, data);
    const result = await response.json();
    return result.info || result;
  };

  const getTimelinePageById = async (entityId: ResourceId, timelineEntryId: ResourceId) => {
    const url = `${parentEntityUrl}/timeline/${timelineEntryId}`.replace(':id', `${entityId}`);

    const request = { pageSize: 30, filter: {}, sort: { createdAt: 'DESC' } };

    const response = await sendPostRequest(url, request);

    return response.json();
  };

  return { search, create, getTimelinePageById };
};

export default useTimelineService;
