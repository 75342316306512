import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import Collapse from 'shared/uibuilder/Collapse/index';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout/index';
import QualificationModelCompetencyField from 'erp/qualification/model/shared/field/QualificationModelCompetencyField';
import CompetencyStatusField from 'erp/employee/qualification/shared/field/CompetencyStatusField';
import ColoredCompetencyStatusField from 'erp/employee/qualification/List/field/ColoredCompetencyStatusField';
import EditableCompetencyStatusField from 'erp/employee/qualification/List/field/EditableCompetencyStatusField';
import CompetencyWeightField from 'erp/qualification/model/shared/field/CompetencyWeightField';
import QualificationListHeader from 'erp/employee/qualification/List/QualificationListHeader';
import { EmployeeSpecialization } from '../shared/employeeSpecializationService';
import useEmployeeQualificationCalculation from 'erp/employee/qualification/shared/employeeQualificationCalculation';
import QualificationCollapseHeader from 'erp/employee/qualification/List/QualificationCollapseHeader';
import EmptyQualificationLevelMessage from 'erp/qualification/model/shared/EmptyQualificationLevelMessage';
import useAuthorization from 'shared/authorization/authorizationService';
import { UPDATE_EMPLOYEE_COMPETENCY } from 'erp/employee/employeeService';
import ApplyAllCompetenciesButton from 'erp/employee/qualification/List/button/ApplyAllCompetenciesButton';
import ApplyCompetencyStatusField from 'erp/employee/qualification/List/field/ApplyCompetencyStatusField';
import useCollapse from 'shared/uibuilder/Collapse/useCollapse';
import CollapseControls from 'shared/uibuilder/Collapse/CollapseControls';
import './QualificationsList.scss';
import Box from 'uibuilder/Box';

const WIDTH_OF_COLUMN_WITH_APPLY_BUTTON = 195;
const WIDTH_OF_EMPTY_COLUMN_WITH_APPLY_BUTTON = 5;

const QualificationsList = ({
  data,
  wrapperClassName,
}: {
  wrapperClassName?: string;
  data: Nullable<EmployeeSpecialization>;
}) => {
  const { isGranted } = useAuthorization();
  const hasPermissionToUpdateCompetency = isGranted(UPDATE_EMPLOYEE_COMPETENCY);
  const { calculateWeightForApplyAllCompetencies, hasNotAppliedStatus } = useEmployeeQualificationCalculation();
  const { id: qualificationId = '', qualifications = [] } = data || {};
  const { collapseAll, isExpanded, expandAll, areAllItemsCollapsed, areAllItemsExpanded, toggle } = useCollapse();
  const qualificationsIds = qualifications.map(({ id }) => String(id));

  useEffect(() => {
    collapseAll();
  }, [qualificationId, collapseAll]);

  const collapseControls = (
    <CollapseControls
      isExpandButtonShown={!areAllItemsExpanded(qualificationsIds)}
      isCollapseButtonShown={!areAllItemsCollapsed()}
      expandAll={() => expandAll(qualificationsIds)}
      collapseAll={collapseAll}
    />
  );

  if (isEmpty(data)) {
    return <i className={wrapperClassName}>Employee has no qualifications linked to the Qualification Model.</i>;
  }

  return (
    <div className={wrapperClassName} key={`employee-qualification-${qualificationId}`}>
      <Box className="align-items-center justify-content-md-end" sx={{ display: { lg: 'flex' } }}>
        <QualificationListHeader data={data} />
        <div className="mb-4">{collapseControls}</div>
      </Box>
      <div className="mb-3 pb-1">
        {qualifications.map(qualification => {
          const { id, competencies = [] } = qualification;
          const weightForApplyAllButton = calculateWeightForApplyAllCompetencies(competencies);
          const hasApplyButton =
            !!weightForApplyAllButton || (!weightForApplyAllButton && hasNotAppliedStatus(competencies));
          const widthOfApplyButtonColumn =
            hasPermissionToUpdateCompetency && hasApplyButton
              ? WIDTH_OF_COLUMN_WITH_APPLY_BUTTON
              : WIDTH_OF_EMPTY_COLUMN_WITH_APPLY_BUTTON;

          return (
            <Collapse
              key={`employee-qualification-${qualificationId}-${id}`}
              header={
                <QualificationCollapseHeader
                  widthOfApplyButtonColumn={widthOfApplyButtonColumn}
                  qualification={qualification}
                />
              }
              isExpanded={isExpanded(String(id))}
              toggle={() => {
                toggle(String(id));
              }}
            >
              {competencies.length ? (
                <ControlledList data={{ items: competencies, totalElements: competencies.length, totalPages: 1 }}>
                  <DataGridLayout tableWrapperClassName="employee-competencies-table" showTotal={false}>
                    <QualificationModelCompetencyField source="name" label="Competency" width="100%" />
                    <CompetencyWeightField source="weight" label="Weight" width={90} />
                    <ColoredCompetencyStatusField
                      className="applied-status"
                      label="Applied Status"
                      source="appliedState.status"
                      width={160}
                    >
                      <CompetencyStatusField />
                    </ColoredCompetencyStatusField>
                    <ApplyCompetencyStatusField
                      permissionToCheck={UPDATE_EMPLOYEE_COMPETENCY}
                      source="competencyId"
                      qualificationId={id}
                      label={
                        <ApplyAllCompetenciesButton
                          qualificationId={id}
                          totalWeight={weightForApplyAllButton}
                          competencies={competencies}
                          permissionToCheck={UPDATE_EMPLOYEE_COMPETENCY}
                          isVisible={hasApplyButton}
                        />
                      }
                      width={widthOfApplyButtonColumn}
                    />
                    <EditableCompetencyStatusField
                      qualificationId={id}
                      source="rootState.status"
                      label="Current Status"
                      width={190}
                    />
                  </DataGridLayout>
                </ControlledList>
              ) : (
                <EmptyQualificationLevelMessage />
              )}
            </Collapse>
          );
        })}
      </div>

      <div className="w-100 d-flex align-items-center justify-content-md-end collapse-controls__wrapper mb-5">
        {collapseControls}
      </div>
    </div>
  );
};

export default QualificationsList;
