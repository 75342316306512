/* istanbul ignore file */
import React from 'react';
import { useTokenProvider } from 'authentication/DefaultTokenProvider';
import { copyToClipboard } from 'shared/clipboard';
import { Button, IconButton } from 'uibuilder/button';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import useAuthorization from 'shared/authorization/authorizationService';

const CopyTokenButton = () => {
  const { getToken } = useTokenProvider();
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted('EMPLOYEE_COPY_JWT_TOKEN');

  const onClick = async () => {
    const token = await getToken();

    if (token) {
      copyToClipboard(token);
    }
  };

  if (!hasPermissions) {
    return null;
  }

  return (
    <>
      <Button outline onClick={onClick} sx={{ display: { xs: 'none', md: 'inline-flex' }, ml: '12px' }}>
        Copy jwt
      </Button>
      <IconButton onClick={onClick} sx={{ display: { md: 'none' }, ml: '12px' }}>
        <CopyIcon />
      </IconButton>
    </>
  );
};

export default CopyTokenButton;
