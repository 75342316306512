import React from 'react';
import moment from 'moment';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useDateService } from 'shared/uibuilder/dateService';

export interface DurationBetweenTwoDatesFieldProps {
  dateFromSource?: string;
  dateToSource?: string;
  dateFromValue?: moment.MomentInput;
  dateToValue?: moment.MomentInput;
  label?: string;
}

const DurationBetweenTwoDatesField = ({
  dateFromSource,
  dateToSource,
  dateFromValue,
  dateToValue,
  ...props
}: DurationBetweenTwoDatesFieldProps) => {
  const { getValue: getDateFromValue } = useFieldHelper({ source: dateFromSource, value: dateFromValue });
  const { getValue: getDateToValue } = useFieldHelper({ source: dateToSource, value: dateToValue });
  const { getStringDurationBetweenTwoDates } = useDateService();

  const dateFrom = getDateFromValue();
  const dateTo = getDateToValue();

  const resultValue = getStringDurationBetweenTwoDates(dateFrom, dateTo);

  return <TextField {...props} value={resultValue} />;
};

export default DurationBetweenTwoDatesField;
