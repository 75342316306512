/* istanbul ignore file */
import React from 'react';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewEmployeeButton = props => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-employee" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewEmployeeButton;
