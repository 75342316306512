/* istanbul ignore file */
import React from 'react';
import { MinimizedSidebarStickyMenu } from 'shared/uibuilder/menu';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import useSyberryTodayProjectService from 'syberryToday/projects/useSyberryTodayProjectService';
import useSyberryTodaySettings from 'syberryToday/List/AfterDailyCheckin/Settings/useSyberryTodaySettings';
import { SYBERRY_TODAY_PROJECTS_PATH, SYBERRY_TODAY_PATH } from 'syberryToday/useSyberryTodayService';
import { useSyberryTodayProjectsUrl } from 'syberryToday/projects/SyberryTodayProjectRouter';
import SyberryTodayIcon from '@mui/icons-material/WorkOutlineOutlined';
import SyberryTodayProjectsIcon from '@mui/icons-material/AccountBalanceOutlined';

const SyberryTodayMenu = () => {
  const { isSidebarMenuMinimized, setIsSidebarMenuMinimized } = useSyberryTodaySettings();
  const { projects } = useSyberryTodayProjectService();
  const { getSingleEntityUrl } = useSyberryTodayProjectsUrl();

  return (
    <MinimizedSidebarStickyMenu setIsMinimized={setIsSidebarMenuMinimized} isMinimized={isSidebarMenuMinimized}>
      <SidebarMenuItem exact linkUrl={SYBERRY_TODAY_PATH} name="My day" icon={<SyberryTodayIcon />} />
      <SidebarMenuItem
        linkUrl={SYBERRY_TODAY_PROJECTS_PATH}
        name="My projects"
        icon={<SyberryTodayProjectsIcon />}
        staticItem={!!projects?.length}
        exact
      >
        {projects?.map(({ id, name }: any) => (
          <SidebarMenuItem key={id} linkUrl={getSingleEntityUrl(id)} name={name} />
        ))}
      </SidebarMenuItem>
    </MinimizedSidebarStickyMenu>
  );
};

export default SyberryTodayMenu;
