import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import useEmployeeService from 'erp/employee/employeeService';
import FormSection from 'shared/layout/form/FormSection';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import MentorsInput from 'erp/employee/mentors/shared/input/MentorsInput';
import MentorsBreadcrumbs from 'erp/employee/mentors/MentorsBreadcrumbs';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { TextField } from 'shared/uibuilder/field';

const MentorsPage = () => {
  const { data } = useShowContext();
  const firstName = data.getValueBySource('nameEn.firstName');
  const lastName = data.getValueBySource('nameEn.lastName');

  const { getMentors, updateMentors } = useEmployeeService();
  const { getMentorsUrl } = useEmployeeUrl();

  return (
    <UpdateForm
      submitFormFunc={updateMentors}
      getDataFunc={getMentors}
      afterSubmit={{
        message: 'You are awesome! Mentors have been successfully updated.',
        redirect: getMentorsUrl(),
      }}
    >
      <UpdatePageLayout title="Edit: Employee's mentors" breadcrumbs={<MentorsBreadcrumbs />} menu={<EmployeeMenu />}>
        <FormSection title="">
          <BigFormRow>
            <TextField
              value={`You can specify mentors of ${firstName} ${lastName}. Mentors will get
            all permissions of
            ${firstName} ${lastName}`}
            />
          </BigFormRow>
          <BigFormRow>
            <MentorsInput source="mentors" />
          </BigFormRow>
        </FormSection>
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default MentorsPage;
