/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useEmployeeService from 'erp/employee/employeeService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

export const EMPLOYEE_CONTEXT = 'Employee';

const EmployeeContext = ({ permissionToCheck, children, idSource, id }: SecurityContextCommonProps) => {
  const { getPermissions } = useEmployeeService();
  const currentAlias = useEmployeeId() || id || '';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={() => getPermissions(currentAlias)}
      permissionToCheck={permissionToCheck}
      id={currentAlias}
      idSource={idSource}
      contextName={EMPLOYEE_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default EmployeeContext;
