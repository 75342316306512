/* istanbul ignore file */
import React from 'react';
import useCandidateOfferService from 'erp/candidate/offercompensation/shared/candidateOfferService';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import List from 'shared/uibuilder/list/List';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_CANDIDATE_OFFER } from 'erp/candidate/shared/candidateService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { DateField, TextField } from 'shared/uibuilder/field';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import CandidateOfferListBreadcrumbs from 'erp/candidate/offercompensation/List/Breadcrumbs';
import ViewCandidateOfferButton from 'erp/candidate/offercompensation/shared/buttons/ViewCandidateOfferButton';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import BooleanField from 'shared/uibuilder/field/BooleanField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import OfferOfficeListField from 'erp/candidate/offercompensation/shared/field/OfferOfficeListField';
import OfferContractTypeListField from 'erp/candidate/offercompensation/shared/field/OfferContractTypeListField';

const CandidateOfferList = () => {
  const { search } = useCandidateOfferService();
  const { getCreateUrl } = useCandidateOfferUrl();

  return (
    <ShowPageLayout
      entityHeader={<CandidateEntityHeader />}
      menu={<CandidateMenu />}
      breadcrumbs={<CandidateOfferListBreadcrumbs />}
    >
      <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="updatedAt">
        <RelationListLayout
          header="Offers"
          actions={[
            <CreateButton
              url={getCreateUrl}
              label="Add Offer"
              key="add-compensation"
              permissionToCheck={CREATE_CANDIDATE_OFFER}
            />,
          ]}
        >
          <DataGridLayout isSmallTable buttons={[<ViewCandidateOfferButton source="id" key="view-compensation" />]}>
            <TextField source="id" label="Offer ID" />
            <BooleanField source="isAccepted" label="Accepted" />
            <FieldListLayout source="offerCompensations" label="Compensation Office">
              <OfferOfficeListField />
            </FieldListLayout>
            <FieldListLayout source="offerCompensations" label="Compensation Contract Type">
              <OfferContractTypeListField />
            </FieldListLayout>
            <DateField source="createdAt" label="Created At" isSortable />
            <DateField source="updatedAt" label="Updated At" isSortable />
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default CandidateOfferList;
