/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useVacancyPublicationService, {
  DELETE_VACANCY_PUBLICATION,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';

const deleteMessage = 'Do you really want to delete this vacancy publication?';

const DeleteVacancyPublicationButton = () => {
  const { id: vacancyId } = useParams<{ id: string }>();
  const { deleteById } = useVacancyPublicationService();
  const { getListUrl } = useVacancyPublicationUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(vacancyId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Vacancy Publication has been successfully deleted.',
        errorMessage: `Can't delete Vacancy Publication`,
      }}
      permissionToCheck={DELETE_VACANCY_PUBLICATION}
    />
  );
};

export default DeleteVacancyPublicationButton;
