import FieldBadgeProps from 'shared/uibuilder/badge/FieldBadgeProps';
import Badge from 'shared/uibuilder/badge/Badge';
import React from 'react';

const CustomBadge = (props: FieldBadgeProps) => {
  const { text } = props;
  return (
    <Badge className="custom-badge ml-2" color="primary" {...props}>
      {text}
    </Badge>
  );
};

export default CustomBadge;
