/* istanbul ignore file */
export default {
  from: {
    type: 'date',
    required: true,
  },
  to: {
    type: 'date',
    required: true,
  },
};
