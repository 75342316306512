/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import AccountsBreadcrumbs from 'crm/account/shared/AccountsBreadcrumbs';
import useAccountUrl from './accountUrl';
import { element } from 'prop-types';

const SingleAccountBreadcrumbs = ({ children }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useAccountUrl();
  const accountName = data.getValueBySource('accountName');
  const id = data.getValueBySource('accountId');
  const singleAccountUrl = getSingleEntityUrl(id);

  return (
    <AccountsBreadcrumbs>
      <SingleBreadcrumbItem entity={accountName || id} link={singleAccountUrl} />
      {children}
    </AccountsBreadcrumbs>
  );
};

SingleAccountBreadcrumbs.propTypes = {
  children: element,
};

SingleAccountBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleAccountBreadcrumbs;
