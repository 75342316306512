import React, { useEffect } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import MilestonesListInputWrapper from './MilestonesListInputWrapper';
import { InputList } from 'shared/uibuilder/form/input';

const MilestonesListWrapper = () => {
  const { setSubmitEnabled = () => {} } = useFormContext();
  const { getValue, setFormFieldErrors } = useInputHelper({ source: 'milestones' });
  const milestonesValue = getValue() || [];

  const hasDuplicateIds = (milestones: any) => {
    const ids = milestones.map((milestone: any) => milestone?.id);
    const uniqueIds = new Set(ids);
    return ids.length !== uniqueIds.size;
  };

  useEffect(() => {
    if (hasDuplicateIds(milestonesValue)) {
      setFormFieldErrors(['Milestones must have unique IDs!']);
      setSubmitEnabled(false);
    } else {
      setFormFieldErrors([]);
      setSubmitEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestonesValue]);

  return (
    <InputList source="milestones" maxFields={10} inputTemplate={MilestonesListInputWrapper} addText="Add Milestone" />
  );
};

export default MilestonesListWrapper;
