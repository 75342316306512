// libraries
import React from 'react';
import { useDateTimeService, useDateService } from 'shared/uibuilder/dateService';

interface ShowDateProps {
  format: string;
  dateUTC?: string;
  timezone?: string;
  isDateTime?: boolean;
  className?: string;
}

const ShowDate = ({ dateUTC, timezone, format, isDateTime = true, className }: ShowDateProps) => {
  const { formatDateWithTimezone } = useDateTimeService(timezone);
  const { formatDate } = useDateService();

  if (!dateUTC) return null;

  return (
    <span className={className}>
      {isDateTime ? formatDateWithTimezone(dateUTC, format) : formatDate(dateUTC, format)}
    </span>
  );
};

export default ShowDate;
