// libs
import React from 'react';
import { arrayOf, string } from 'prop-types';
import { childrenAsNodesType } from 'shared/prop-types';
// components
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import SensitiveDataPlug from 'shared/uibuilder/sensitiveData/SensitiveDataPlug';
import ToggleSensitiveDataButton from 'shared/uibuilder/sensitiveData/buttons/ToggleSensitiveDataButton';
// services
import { useSensitiveDataService } from 'shared/uibuilder/sensitiveData';
// styles
import './SensitiveRelationListPageLayout.scss';

const SensitiveUpdatePageLayout = ({ actions, children, timerId, ...other }) => {
  const { isTimerActive, isShown, toggleSensitiveData, isSensitiveDataLoading } = useSensitiveDataService({ timerId });

  const getActions = () => {
    const buttonsArray = [...actions];
    const toggleButton = (
      <ToggleSensitiveDataButton
        key="toggle-sensitive-data"
        isShown={isShown}
        onClick={() => toggleSensitiveData()}
        disabled={isSensitiveDataLoading}
        isTimerActive={isTimerActive}
      />
    );

    buttonsArray.unshift(toggleButton);

    return buttonsArray;
  };

  return (
    <UpdatePageLayout actions={getActions()} {...other}>
      {isShown ? children : <SensitiveDataPlug />}
    </UpdatePageLayout>
  );
};

SensitiveUpdatePageLayout.propTypes = {
  actions: arrayOf(childrenAsNodesType),
  children: childrenAsNodesType,
  timerId: string,
};

SensitiveUpdatePageLayout.defaultProps = {
  actions: [],
  children: null,
  timerId: undefined,
};

export default SensitiveUpdatePageLayout;
