/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import MonthFilter from 'shared/uibuilder/list/filter/MonthFilter';
import StaticFiltersLayout from 'shared/uibuilder/list/filter/layout/StaticFiltersLayout';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import PerformanceHeatmapDaysHeader from 'erp/employee/performanceReport/shared/PerformanceHeatmap/DailyPerformanceHeatmap/PerformanceHeatmapDaysHeader';
import PerformanceHeatmapGraphLayout from 'erp/employee/performanceReport/shared/PerformanceHeatmap/layout/PerformanceHeatmapGraphLayout';
import PerformanceHeatmapLegend, {
  DAYS_LEGEND,
  DAY_MARKS_LEGEND,
} from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapLegend';
import PerformanceHeatmapCell from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapCell';
import PerformanceHeatmapLabels from 'erp/performanceReport/List/PerformanceHeatmapLabels';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import TotalRecords from 'shared/uibuilder/list/layout/TotalRecords';
import PerformanceHeatmapLayout from 'erp/employee/performanceReport/shared/PerformanceHeatmap/layout/PerformanceHeatmapLayout';
import { DAY_MARK } from 'erp/employee/performanceReport/usePerformanceReportService';
import usePerformanceReportService from 'erp/performanceReport/usePerformanceReportService';

const legendTooltips = {
  [DAY_MARK.POSITIVE]: 'All green indicators in the daily performance report',
  [DAY_MARK.NEUTRAL]: 'One and more yellow indicators in the daily performance report',
  [DAY_MARK.NEGATIVE]: 'One and more red indicators in the daily performance report',
};

const PerformanceReportsListHeatmap = () => {
  const { getEmployeePerformanceReportUrl } = useEmployeeUrl();
  const { isFakeId } = usePerformanceReportService();
  const { data, filter: { period: { eq: currentMonth = '' } = {} } = {} } = useListContext();
  const items: any[] = (data?.items as any) || [];
  const metrics: Dictionary<any> = items.reduce(
    (acc, { userId, metrics: itemMetrics }) => ({ ...acc, [userId]: itemMetrics }),
    {},
  );
  const endOfMonth = moment(currentMonth).endOf('month');

  const getItems = (id: string) => {
    const values = metrics[id];

    if (values) {
      return values.map((value: Dictionary<StringOrNumber>) => (
        <PerformanceHeatmapCell
          {...value}
          key={`${id}-${value.date}`}
          metric={{
            ...value,
            value: !isFakeId(id) ? (
              <a
                href={`${getEmployeePerformanceReportUrl(id)}?filter={"period":{"eq":"${currentMonth}"}}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value.value}
              </a>
            ) : (
              <div />
            ),
          }}
          day={value.date as string}
        />
      ));
    }

    return null;
  };

  const filter = (
    <StaticFiltersLayout>
      <MonthFilter label="" source="period" isRequired hasTodayButton todayButtonLabel="Current month" />
    </StaticFiltersLayout>
  );

  const labels: Array<Dictionary<string>> = items.filter(({ metrics: itemMetrics }) => !!itemMetrics?.length);

  return (
    <PerformanceHeatmapLayout filter={filter} hasUpdateDate={false}>
      <PerformanceHeatmapGraphLayout
        hasFixedHeader
        labelComponent={<PerformanceHeatmapLabels labels={labels} />}
        currentMonth={currentMonth}
        labels={labels.map(({ userId }) => userId)}
        metrics={metrics}
        heatmapHeader={<PerformanceHeatmapDaysHeader currentMonth={currentMonth} />}
        getItems={getItems}
        gridTemplateColumns={`repeat(${endOfMonth.date()}, var(--square-size))`}
        legend={
          <>
            <div className="performance-heatmap__pagination">
              <TotalRecords />
            </div>
            <PerformanceHeatmapLegend
              data={[
                DAYS_LEGEND,
                DAY_MARKS_LEGEND.map(legendItem => ({
                  ...legendItem,
                  tooltip: legendTooltips[legendItem.values[0].state],
                })),
              ]}
            />
          </>
        }
      />
    </PerformanceHeatmapLayout>
  );
};

export default PerformanceReportsListHeatmap;
