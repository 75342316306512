import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const formatDuration = (minutes: number): number => {
  return parseFloat((minutes / 60).toFixed(2));
};

interface TrainingDurationFieldProps {
  source: string;
  [key: string]: any;
}

const TrainingDurationField: React.FC<TrainingDurationFieldProps> = ({ source }) => {
  const { getValue } = useFieldHelper({ source });
  const duration = getValue();
  const formattedValue = duration ? formatDuration(Number(duration)) : 0;

  return <TextField value={formattedValue} label="Duration in hours" />;
};

export default TrainingDurationField;
