/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import useSendEmailService from 'sendEmail/sendEmailService';
import SendEmailForm from 'sendEmail/createupdate/SendEmailForm';
import { useSendEmailUrl, useSendEmailId } from 'sendEmail/SendEmailRouter';
import UpdateSendEmailPageLayout from 'sendEmail/createupdate/Update/UpdateSendEmailPageLayout';

const UpdateSendEmail = () => {
  const { getData, updateData, getValidationSchema } = useSendEmailService();
  const emailId = useSendEmailId();
  const { getSingleEntityUrl } = useSendEmailUrl();

  return (
    <UpdateForm
      submitFormFunc={updateData}
      getDataFunc={getData}
      entityId={emailId}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Email has been successfully updated.',
      }}
    >
      <UpdateSendEmailPageLayout>
        <SendEmailForm />
      </UpdateSendEmailPageLayout>
    </UpdateForm>
  );
};

export default UpdateSendEmail;
