/* istanbul ignore file */
import useEmployeeService from 'erp/employee/employeeService';
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import { element } from 'prop-types';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

const ShowEmployee = ({ children }) => {
  const { getById } = useEmployeeService();
  const currentAlias = useEmployeeId();

  return <Show getDataMethod={() => getById(currentAlias)}>{children}</Show>;
};

ShowEmployee.propTypes = {
  children: element.isRequired,
};

export default ShowEmployee;
