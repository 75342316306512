/* istanbul ignore file */
import useStorageService from 'shared/uibuilder/storageService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';
import { AddressType } from 'shared/uibuilder/field/AddressField';

export const POLISH_OFFICES_ID = ['3'];

export const isPolishOffice = (officeId: string) => {
  return POLISH_OFFICES_ID.includes(officeId);
};

export const OFFICE_NAMES: { [key: string]: any } = {
  'Austin-1': 'Austin',
  'Minsk-1': 'Minsk',
  'Krakow-1': 'Krakow',
  'Tbilisi-1': 'Tbilisi',
};

export interface OfficeType {
  id: number;
  name: string;
  address: AddressType;
}

const useOfficeService = () => {
  const { findAll: findAllFromApi } = useBaseCrudService({
    listResourceUrl: '/offices',
    singleResourceUrl: '',
    apiService: useKernelApi,
  });

  const { getDataFromStorage } = useStorageService('offices', findAllFromApi, '1.2');

  return {
    findAll: getDataFromStorage,
  };
};

export default useOfficeService;
