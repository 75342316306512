/* istanbul ignore file */
import React from 'react';
import SingleAssignmentBreadcrumbs from 'erp/assignment/shared/SingleAssignmentBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateAssignmentBreadcrumbs = () => {
  return (
    <SingleAssignmentBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleAssignmentBreadcrumbs>
  );
};

export default UpdateAssignmentBreadcrumbs;
