import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useCorporateEventService from 'financialAnalytic/corporateEvents/useCorporateEventService';
import { useCorporateEventId } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import ViewCorporateEventPageContent from 'financialAnalytic/corporateEvents/Show/ViewCorporateEventPageContent';

const CorporateEventViewPage = () => {
  const { getById } = useCorporateEventService();
  const id = useCorporateEventId();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewCorporateEventPageContent />
    </Show>
  );
};

export default CorporateEventViewPage;
