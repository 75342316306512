/* istanbul ignore file */
import React from 'react';
import EmployeeOfferBreadcrumbs from 'erp/employee/offers/shared/EmployeeOfferBreadcrumbs';
import CreateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/CreateBreadcrumbItem';

const CreateEmployeeOfferBreadcrumbs = () => {
  return (
    <EmployeeOfferBreadcrumbs>
      <CreateBreadcrumbItem entity="Offer" />
    </EmployeeOfferBreadcrumbs>
  );
};

export default CreateEmployeeOfferBreadcrumbs;
