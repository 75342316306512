/* istanbul ignore file */
import React from 'react';
import { EmployeeInput } from 'erp/employee';
import { TextInput } from 'shared/uibuilder/form/input';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import BaseInputLayout from 'uibuilder/layout/form/input/BaseInputLayout';
import classnames from 'classnames';
import './TicketAssigneeSearchField.scss';

interface TicketAssigneeSearchFieldProps {
  sourceAlias: string;
  nameSource: string;
  projectName: string;
  label: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  showInput?: boolean;
  errors?: string[];
}

const TicketAssigneeSearchField = ({
  sourceAlias,
  nameSource,
  isDisabled = false,
  showInput = true,
  projectName,
  isRequired = false,
  label,
  errors = [],
}: TicketAssigneeSearchFieldProps) => {
  const { getValue } = useFieldHelper({ nameSource });
  const employeeName = getValue();

  return (
    <BaseInputLayout
      label={label}
      isRequired={isRequired}
      source={sourceAlias}
      errorMessages={errors}
      className={classnames('assignee-search-field', errors.length > 0 ? 'error-section' : '')}
    >
      {showInput ? (
        <EmployeeInput
          mapResults={(employee: any) => ({
            id: employee.alias,
            text: `${employee.nameEn.firstName} ${employee.nameEn.lastName}`,
          })}
          source={sourceAlias}
          nameSource={nameSource}
          disabled={isDisabled}
          withContractors
          defaultFilter={{
            'specification:memberOfProject': { in: [projectName] },
          }}
        />
      ) : (
        <TextInput source={sourceAlias} value={employeeName} isRequired disabled />
      )}
    </BaseInputLayout>
  );
};

export default TicketAssigneeSearchField;
