/* istanbul ignore file */
import TextInput from 'shared/uibuilder/form/input/TextInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import React from 'react';
import FormInputGroup from 'shared/layout/form/FormInputGroup';
import { ActivityType } from 'crm/shared/timeline/activity/crmActivityService';
import CommaListInput from 'shared/uibuilder/form/input/CommaListInput';
import ActivityTypeInput from 'crm/shared/timeline/activity/form/input/ActivityTypeInput';
import { bool } from 'prop-types';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import CrmNoteWysiwygInput from 'crm/shared/input/CrmNoteWysiwygInput';

const emailListLabelHint = '(enter comma-separated e-mail addresses)';

const ActivityForm = ({ isEditForm }) => (
  <>
    <BigFormRow>
      <ActivityTypeInput source="activityType" isVisible={!isEditForm} />
    </BigFormRow>
    <BigFormRow>
      <TextInput source="subject" />
    </BigFormRow>
    <BigFormRow>
      <CrmNoteWysiwygInput artifactsSource="attachmentsIds" source="description" />
    </BigFormRow>

    <FormInputGroup isVisible={data => ActivityType[data.activityType] === ActivityType.EMAIL}>
      <BigFormRow>
        <TextInput source="senderEmail" label="Sender" />
      </BigFormRow>
      <BigFormRow>
        <CommaListInput source="receiversEmails" label="Receivers" labelHint={emailListLabelHint} />
      </BigFormRow>
      <BigFormRow>
        <CommaListInput source="ccEmails" label="CC" labelHint={emailListLabelHint} />
      </BigFormRow>
      <BigFormRow>
        <CommaListInput source="bccEmails" label="BCC" labelHint={emailListLabelHint} />
      </BigFormRow>
    </FormInputGroup>

    <FormInputGroup isVisible={data => ActivityType[data.activityType] === ActivityType.CALL}>
      <BigFormRow>
        <TextInput source="communicationChannel" />
      </BigFormRow>
    </FormInputGroup>

    <FormInputGroup isVisible={data => ActivityType[data.activityType] === ActivityType.MEETING}>
      <BigFormRow>
        <TextInput source="location" />
      </BigFormRow>
    </FormInputGroup>

    <BigFormRow>
      <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
    </BigFormRow>
  </>
);

ActivityForm.propTypes = {
  isEditForm: bool,
};

ActivityForm.defaultProps = {
  isEditForm: false,
};

export default ActivityForm;
