/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { EMPLOYEE_WORKING_STATUSES } from 'erp/employee/employeeService';

const EmployeeWorkingStatusDropdown = ({ excluded, ...props }) => {
  return (
    <Dropdown
      placeholder="Choose Employee Working Status"
      options={Object.entries(EMPLOYEE_WORKING_STATUSES)
        .filter(status => !excluded || !excluded.includes(status[0]))
        .map(entry => {
          const [value, label] = entry;
          return {
            value,
            label,
          };
        })}
      label="Employee Working Status"
      {...props}
    />
  );
};

export default EmployeeWorkingStatusDropdown;
