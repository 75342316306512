/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import InputLoader from 'shared/uibuilder/InputLoader';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useAccountInput from './useAccountInput';

export interface AccountInputProps extends DefaultInputPropTypes<string> {
  source: string;
  loadDataMethod: () => Promise<any>;
  cacheKey: string;
  shouldDisableParentAccounts?: boolean;
}

const LEVEL_INDENT = 20;
const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    paddingLeft: state.data.level ? `${state.data.level * LEVEL_INDENT + 12}px !important` : provided.paddingLeft,
  }),
};

const AccountInput = (props: AccountInputProps) => {
  const { options, loading, onChangeCallback, filterOption } = useAccountInput(props);

  const formatOptionLabel = (data: any, formatOptionLabelMeta: { context: string }) => (
    <div>{formatOptionLabelMeta.context === 'menu' ? data.name : data.label}</div>
  );

  if (loading) {
    return <InputLoader />;
  }

  return (
    <Dropdown
      placeholder=" "
      {...props}
      styles={customStyles}
      options={options}
      filterOption={filterOption}
      formatOptionLabel={formatOptionLabel}
      onChangeCallback={onChangeCallback}
      className="finance-account-dropdown"
    />
  );
};

export default AccountInput;
