import React, { useMemo } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import NameField from 'shared/uibuilder/field/NameField';
import { DateField, TextField } from 'shared/uibuilder/field';
import EmployeeFamilyMembersLayout from 'erp/employee/shared/layout/EmployeeFamilyMembersLayout';
import { splitFamily } from 'erp/employee/employeeService';

const EmployeeFamilyMembersField = props => {
  const { getValue } = useFieldHelper(props);
  const family = getValue();

  const groupedFamily = useMemo(() => {
    return splitFamily(family);
  }, [family]);

  const getMemberInfo = member => {
    const name = <NameField value={member.name} />;
    const date = <DateField label="" value={member.dateOfBirth} />;
    const relation = <TextField label="" value={member.relation} />;

    return (
      <div key={`${member.name.firstName}-${member.dateOfBirth}`} className="employee-family-member">
        {name}
        ,&nbsp;
        {date}
        ,&nbsp;
        {relation}
      </div>
    );
  };

  const otherMembers = groupedFamily.other.map(member => getMemberInfo(member));
  const childrenMembers = groupedFamily.children.map(member => getMemberInfo(member));

  return <EmployeeFamilyMembersLayout otherMembers={otherMembers} childrenMembers={childrenMembers} />;
};

export default EmployeeFamilyMembersField;
