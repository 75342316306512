/* istanbul ignore file */
import React from 'react';
import { useAssetUrl } from 'financialAnalytic/assets/AssetRouter';
import { UPDATE_ASSET } from 'financialAnalytic/assets/useAssetService';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';

const EditAssetButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useAssetUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} permissionToCheck={UPDATE_ASSET} />;
};

export default EditAssetButton;
