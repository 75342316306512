import React from 'react';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

export const formatDataForMultiSelectInput = data => {
  if (!data) {
    return [];
  }

  return data.map(item => ({
    value: item.name,
    label: item.name,
  }));
};

const SkillsInput = props => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);

  const parentOnChange = getRawOnChangeCallback();

  const onChangeCallback = obj => {
    const mappedArray = obj[getSource()] || [];

    parentOnChange({
      [getSource()]: mappedArray.map(item => item.value),
    });
  };

  const noOptionsMessage = ({ inputValue }) =>
    inputValue.length ? 'Required Skill is not found.' : 'Start typing the name of the skill.';

  const value = getValue() || [];

  const mappedValue = value.map(item => ({ value: item, label: item }));

  return (
    <MultiSelectInput
      {...props}
      onChangeCallback={onChangeCallback}
      mapResults={formatDataForMultiSelectInput}
      value={mappedValue}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default SkillsInput;
