/* istanbul ignore file */
import React from 'react';
import useGeneratedPayrollsService from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/index';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import Box from 'uibuilder/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import useDeviceDetectorService, { MAX_SM_WIDTH } from 'oneStop/deviceDetectorService';
import { Placement } from 'shared/uibuilder/SimpleTooltip';
import RunMigrationButton from 'erp/payroll/generatedPayrolls/shared/button/RunMigrationButton';
import PayrollReportSettings from 'erp/payroll/generatedPayrolls/shared/Settings';
import useAsyncValue from 'shared/asyncHelper';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import usePayrollReportSettings from 'erp/payroll/generatedPayrolls/shared/Settings/usePayrollReportSettings';

const GeneratedPayrollsStaticFilters = ({ title, sx = {} }: { title?: React.ReactNode; sx?: SxProps<Theme> }) => {
  const {
    getFilterValidationSchema,
    getFinanceCompensationPackageCount,
    getKernelCompensationPackageCount,
  } = useGeneratedPayrollsService();
  const financeCompensationPackageCount = useAsyncValue(getFinanceCompensationPackageCount);
  const kernelCompensationPackageCount = useAsyncValue(getKernelCompensationPackageCount);
  const { reloadData } = useListContext();
  const { isLatestReportsVisibleOnly } = usePayrollReportSettings();

  const { isDesktop } = useDeviceDetectorService({
    maxWidth: MAX_SM_WIDTH,
    defaultValue: window.innerWidth > MAX_SM_WIDTH,
  });

  useDidUpdateEffect(() => {
    if (reloadData) {
      reloadData();
    }
  }, [isLatestReportsVisibleOnly]);

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <DateRangeFilter
          key={`generated-payrolls-period-filter-${isDesktop}`}
          popperPlacement={isDesktop ? Placement.BOTTOM_END : Placement.BOTTOM_START}
          label="Period"
          source="period"
          canBeInFuture
        />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Box
      sx={{
        [`@media (min-width: ${MAX_SM_WIDTH}px)`]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '& [data-testId="remove-btn"], & .filters-reset-btn': {
          display: 'none',
        },
        '.button-dropdown__toggle': {
          minHeight: '36px',
          padding: '5px 12px',
        },
        '.filter-item-wrapper': {
          marginRight: 0,
        },
        '.dropdown-toggle': {
          marginLeft: '8px',
          transform: 'translateY(-2px)',
        },
        '.settings__item': {
          minWidth: '310px',
        },
        ...sx,
      }}
    >
      {title}
      <div className="ml-auto mr-2 mb-2">
        {kernelCompensationPackageCount !== null ? (
          <div>Number of kernel compensation packages: {kernelCompensationPackageCount}</div>
        ) : null}
        {financeCompensationPackageCount !== null ? (
          <div>Number of finance compensation packages: {financeCompensationPackageCount}</div>
        ) : null}
      </div>
      <div>
        {/* TODO: Remove after migration */}
        <RunMigrationButton />
        {filters}
        <PayrollReportSettings />
      </div>
    </Box>
  );
};

export default GeneratedPayrollsStaticFilters;
