/* istanbul ignore file */
import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import { TIME_UNITS } from 'shared/uibuilder/form/input/TimeUnitsDropdown';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const AssetDurationField = ({
  timeUnitSource,
  source,
  prefix,
  ...props
}: FieldProps & { timeUnitSource: string; prefix?: string }) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getTimeUnit } = useFieldHelper({ source: timeUnitSource });
  const value = getValue() as any;
  const timeUnit = getTimeUnit() as any;

  return (
    <TextField
      {...props}
      source={source}
      value={`${prefix || ''}${value} ${TIME_UNITS.find(({ value: unitValue }) => unitValue === timeUnit)?.label ||
        ''}`}
    />
  );
};

export default AssetDurationField;
