/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CorporateEventLocationDropdown from 'financialAnalytic/corporateEvents/shared/input/CorporateEventLocationDropdown';

const CorporateEventLocationDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <CorporateEventLocationDropdown {...props} />
    </DropDownFilter>
  );
};

export default CorporateEventLocationDropdownFilter;
