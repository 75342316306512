/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import usePurchaseOrderService, {
  GOODS_AND_SERVICES_DELIVERY_STATUS_OPTIONS,
  PURCHASE_ORDER_STATUSES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import Box from 'uibuilder/Box';
import InvoiceLink from 'financialAnalytic/invoice/shared/field/InvoiceLink';
import ViewInvoiceButton from 'financialAnalytic/invoice/shared/button/ViewInvoiceButton';
import EnumField from 'shared/uibuilder/field/EnumField';
import TextField from 'shared/uibuilder/field/TextField';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import DateField from 'shared/uibuilder/field/DateField';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import MutableInvoiceBadge from 'financialAnalytic/invoice/shared/MutableInvoiceBadge';
import MarkAsDeliveredGoodsAndServicesButton from 'financialAnalytic/invoice/shared/button/MarkAsDeliveredGoodsAndServicesButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const InvoicesListDataGrid = ({
  isSensitiveDataIsLoading,
  isSensitiveDataIsShown,
  isSensitivePurchaseDataIsShown,
  reloadSensitiveData,
}: {
  isSensitiveDataIsLoading?: boolean;
  isSensitiveDataIsShown?: boolean;
  isSensitivePurchaseDataIsShown?: boolean;
  reloadSensitiveData?: () => void;
}) => {
  const { getById } = usePurchaseOrderService();
  const { data, setData = () => {} } = useShowContext();

  return (
    <Box sx={{ minHeight: '200px', position: 'relative', '& .th-buttons:last-child': { width: '170px' } }}>
      <DataGridLayout
        rowKeySource="invoiceId"
        buttons={
          [
            <ViewInvoiceButton source="invoice.id" />,
            data.getValueBySource('status') === PURCHASE_ORDER_STATUSES.APPROVED ||
            data.getValueBySource('status') === PURCHASE_ORDER_STATUSES.PARTIALLY_CLOSED ? (
              <MarkAsDeliveredGoodsAndServicesButton
                deliveryStatusSource="invoice.goodsAndServices.deliveryStatus"
                invoiceIdSource="invoice.id"
                futureExpenseSource="invoice.isFutureExpense"
                invoiceStatusSource="invoice.status"
                afterSubmit={{
                  execute: async (response: any) => {
                    if (isSensitiveDataIsShown && reloadSensitiveData) {
                      await reloadSensitiveData();
                    }
                    if (response.linkedPurchaseOrder.id) {
                      const res = await getById(response.linkedPurchaseOrder.id, true);
                      setData(res);
                    }
                  },
                }}
              />
            ) : null,
          ].filter(Boolean) as React.ReactElement[]
        }
      >
        {
          [
            <BadgeField source="invoice.id" label="Invoice Number" badges={[<MutableInvoiceBadge />]} width="15%">
              <InvoiceLink source="invoice.id" nameSource="invoice.invoiceNumber" label="Invoice Number" />
            </BadgeField>,
            <SearchResultField source="invoice.name" label="Vendor Name" width="25%" />,
            isSensitivePurchaseDataIsShown && isSensitiveDataIsShown && !isSensitiveDataIsLoading ? (
              <InvoiceTotalField
                label="Total"
                source="subTotal.amount"
                currencySource="subTotal.currency"
                width="15%"
              />
            ) : null,
            isSensitivePurchaseDataIsShown && isSensitiveDataIsShown && !isSensitiveDataIsLoading ? (
              <InvoiceTotalField
                label="Delivered amount"
                source="invoiceDeliveredSubTotal.amount"
                currencySource="invoiceDeliveredSubTotal.currency"
                width="25%"
              />
            ) : null,
            <EnumField
              label="Status"
              source="invoice.goodsAndServices.deliveryStatus"
              options={GOODS_AND_SERVICES_DELIVERY_STATUS_OPTIONS}
              width="15%"
            />,
            <DateField source="invoice.goodsAndServices.deliveryDate" label="Delivery Date" width="20%" />,
            <TextField source="invoice.description" width="27%" />,
          ].filter(Boolean) as React.ReactElement[]
        }
      </DataGridLayout>
    </Box>
  ) as any;
};

export default InvoicesListDataGrid;
