/* istanbul ignore file */
import React, { useState, useCallback, useEffect } from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import useApplicationService, {
  APPLICATIONS_STAGES_ORDER,
} from 'erp/recruitment/applications/shared/applicationService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import CollapseControls from 'shared/uibuilder/Collapse/CollapseControls';
import Box from 'uibuilder/Box';
import ApplicationsDataGrid from 'erp/recruitment/applications/List/ApplicationsDataGrid';
import SearchVacancyFilter from 'erp/recruitment/vacancy/List/filter/SearchVacancyFilter';
import ApplicationStatusFilter from 'erp/candidate/applications/shared/filter/ApplicationStatusFilter';
import GroupedApplicationsList from './GroupedApplicationList';
import ApplicationsGroupButton from 'erp/recruitment/applications/shared/button/ApplicationGroupButton';
import useApplicationsSettings from './GroupedApplicationList/Settings/useApplicationsSettings';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import { EmployeeFilter } from 'erp/employee';

const filters = (
  <Filters>
    <FiltersLayout>
      <SearchVacancyFilter label="Vacancy" source="vacancy.alias" />
      <ApplicationStatusFilter label="Status" source="status" />
      <DateTimeRangeFilter source="createdAt" label="Application creation date" />
      <EmployeeFilter source="createdBy.alias" label="Author" />
    </FiltersLayout>
  </Filters>
);

const ApplicationsList = () => {
  const { searchWithFormattedFilters } = useApplicationService();
  const [isGrouped, setIsGrouped] = useState(true);
  const { collapseAll, expandAll, areAllItemsCollapsed, areAllItemsExpanded } = useApplicationsSettings();

  const collapseControls = (
    <CollapseControls
      isExpandButtonShown={!areAllItemsExpanded(APPLICATIONS_STAGES_ORDER)}
      isCollapseButtonShown={!areAllItemsCollapsed()}
      expandAll={() => expandAll(APPLICATIONS_STAGES_ORDER)}
      collapseAll={collapseAll}
    />
  );

  const onClickGroupButton = useCallback(() => {
    setIsGrouped(prev => !prev);
  }, []);

  useEffect(() => {
    if (!isGrouped) {
      collapseAll();
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating isGrouped
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGrouped]);

  const header = (
    <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center', justifyContent: 'space-between' }}>
      <ListHeader label="Applications" />
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: { xs: '12px', sm: 0 } }}>
        <ApplicationsGroupButton isActive={isGrouped} onClick={onClickGroupButton} />
        {isGrouped ? collapseControls : null}
      </Box>
    </Box>
  );

  return (
    <List
      key={`applications-list-${isGrouped}`}
      getDataMethod={params => (isGrouped ? Promise.resolve({ result: [] }) : searchWithFormattedFilters(params))}
      defaultSortOrder={DESC}
      defaultSortField="createdAt"
    >
      <ListPageLayout header={header} filter={filters} search={<SearchInput label="Id, Candidate's Name" />}>
        {isGrouped ? (
          <>
            <div className="mb-3 pb-1">
              {APPLICATIONS_STAGES_ORDER.map((stage, index) => {
                return <GroupedApplicationsList key={`applications-${stage}`} stage={stage} index={index} />;
              })}
            </div>

            <div className="w-100 d-flex align-items-center justify-content-md-end collapse-controls__wrapper mb-5">
              {collapseControls}
            </div>
          </>
        ) : (
          <ApplicationsDataGrid />
        )}
      </ListPageLayout>
    </List>
  );
};

export default ApplicationsList;
