/* istanbul ignore file */
import React, { useState } from 'react';
import useExpenseService, { MARK_AS_PAID_EXPENSE } from 'financialAnalytic/expenses/useExpenseService';
import { getVisibility } from 'shared/uibuilder/helper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { TextArea } from 'shared/uibuilder/form/input';

export type RejectExpenseButtonProps = {
  isVisible: boolean | ((data: any) => boolean);
  ids: string[];
  onSubmit?: () => void;
};

const RejectExpenseButton = ({ ids, isVisible, onSubmit }: RejectExpenseButtonProps) => {
  const { rejectExpense, invalidateCache } = useExpenseService();
  const { data, setData = () => {} } = useShowContext();

  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonErrors, setRejectReasonErrors] = useState<string[]>([]);

  if (!getVisibility(data, isVisible)) {
    return null;
  }

  const onReasonChange = (values: FormFieldsData) => {
    setRejectReason(values.rejectReason);
    setRejectReasonErrors(values.rejectReason ? [] : ['Field is required']);
  };

  const execute = (newExpenseData?: any, shouldUpdateData?: boolean) => {
    invalidateCache();
    if (onSubmit) {
      onSubmit();
    } else if (shouldUpdateData && newExpenseData[0]) {
      setData(newExpenseData[0]);
    }
  };

  return (
    <ButtonWithConfirmation
      displayMessage={`Do you really want to reject ${
        ids.length > 1 ? `these expenses (ids: ${ids.join(', ')})` : `this expense (id: ${ids[0]})`
      }? Please fill out the reason below.`}
      modalChildren={
        <div className="mt-5">
          <TextArea
            source="rejectReason"
            value={rejectReason}
            label="Please, enter the reject reason"
            onChangeCallback={onReasonChange}
            errorMessages={rejectReasonErrors}
          />
        </div>
      }
      submitMethod={() => rejectExpense(ids, rejectReason)}
      afterSubmit={{
        successMessage: `The Expense${ids.length > 1 ? 's have' : ' has'} been successfully rejected.`,
        errorMessage: `Can't reject expense${ids.length > 1 ? 's' : ''}.`,
        execute: (newExpenseData: any) => execute(newExpenseData, true),
        executeOnError: execute,
      }}
      permissionToCheck={MARK_AS_PAID_EXPENSE}
      variant="outlined"
      confirmBtnProps={{ disabled: !rejectReason }}
      closeOnSubmit={false}
      color="error"
    >
      Reject
    </ButtonWithConfirmation>
  );
};

export default RejectExpenseButton;
