import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useEffect, useState } from 'react';
import { Filter, FilterError, FilterErrors, ApplyOptions } from './FilterContext';
import { isEmpty, isArray } from 'lodash';
import { ValidationSchema } from 'validation-schema-library';
import { unFlattenObject } from 'shared/uibuilder/form/formHelper';
import useAsyncValue from 'shared/asyncHelper';
import useValidation from 'shared/validation/validationService';

export const getActiveFilters = (filters: Dictionary<any>) =>
  Object.fromEntries(Object.entries(filters).filter(([_, v]) => !isEmpty(v)));

const useFilter = ({
  isStatic = false,
  getValidationSchemaFunc = () => Promise.resolve({}),
}: {
  isStatic?: boolean;
  getValidationSchemaFunc?: () => Promise<ValidationSchema>;
} = {}) => {
  const validationSchema = useAsyncValue(getValidationSchemaFunc);
  const { filterBy: filterList, filter: listFilter = {}, uncontrolledFilters } = useListContext();
  const [filters, setFilters] = useState<Filter>(listFilter);
  const [errors, setErrors] = useState<FilterErrors>({});
  const { validate } = useValidation({
    data: filters,
    validationSchema,
  });

  const validateFilters = () => {
    const filterErrors = validate() as object;

    setErrors(unFlattenObject(filterErrors) as FilterErrors);
  };

  useEffect(() => {
    if (validationSchema && !isEmpty(validationSchema)) {
      validateFilters();
    }

    // Suppressed warnings because we only need to call useEffect callback after updating filters.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, validationSchema]);

  /**
   * This method insures that only valid filters passed to list context
   * e.g. no empty that might persist due to added filter without selected value
   * @param allFilters
   */
  const getValidFilters = (allFilters: Filter) => {
    const validFilters: Filter = {};

    Object.keys(allFilters).forEach(source => {
      const filter = allFilters[source];
      Object.entries(filter || {}).forEach(entry => {
        const [key, value] = entry;
        if (!value || (isArray(value) && isEmpty(value))) {
          delete filter[key];
        }
      });

      if (filter && Object.keys(filter).length > 0) {
        validFilters[source] = filter;
      }
    });

    return validFilters;
  };

  /**
   * This method applies filters
   * @param filtersToApply - filters which need to apply
   * @param applyOptions - contain saveEmptyFilters that allows save filters with empty values
   */
  const applyFilters = (filtersToApply: Filter, { saveEmptyFilters = true }: ApplyOptions = {}) => {
    const validFilters = getValidFilters(filtersToApply);

    if (saveEmptyFilters) {
      setFilters(filtersToApply);
    } else {
      setFilters(validFilters);
    }

    if (filterList) {
      filterList(validFilters);
    }
  };

  const areFiltersValid = (filterErrors = errors) => {
    return !Object.values(filterErrors).some(
      sourceErrors => sourceErrors && Object.values(sourceErrors).some(error => error?.length > 0),
    );
  };

  const apply = (applyOptions?: ApplyOptions) => {
    if (areFiltersValid()) {
      applyFilters(filters, applyOptions);
    }
  };

  const clear = () => {
    const newFilters = uncontrolledFilters
      ? uncontrolledFilters.reduce(
          (result: Dictionary<any>, source: string) =>
            listFilter[source]
              ? {
                  ...result,
                  [source]: listFilter[source],
                }
              : result,
          {},
        )
      : {};

    setFilters(newFilters);
    setErrors({});
    applyFilters(newFilters);
  };

  const quickFilter = (filter: Filter) => {
    setFilters(filter);
    setErrors({});
    applyFilters(filter);
  };

  const getValue = (source: string, condition: string) => {
    return filters && filters[source] && filters[source][condition] ? filters[source][condition] : null;
  };

  const filterBy = (filter: Filter) => {
    setFilters({
      ...filters,
      ...filter,
    });
  };

  const setFilterErrors = (source: string, condition: string, filterErrors: FilterError) => {
    setErrors(prev => {
      const prevData = prev[source] || {};

      return {
        ...prev,
        [source]: {
          ...prevData,
          [condition]: filterErrors,
        },
      };
    });
  };

  const getFilterErrors = (source: string, condition: string) => (errors[source] && errors[source][condition]) || [];

  const clearFilter = (source: string) => {
    const newFilters = { ...filters };
    delete newFilters[source];
    setErrors(prev => ({
      ...prev,
      [source]: {},
    }));
    setFilters(newFilters);
  };

  const clearAndApplyFilter = (source: string | string[]) => {
    const validFilters = getValidFilters(filters);
    const newFilters = { ...filters };
    const newErrors = { ...errors };
    const sources = isArray(source) ? source : [source];
    const isCurrentValueExist = sources.some(filterSource => !!validFilters[filterSource]);

    sources.forEach(filterSource => {
      delete newFilters[filterSource];
      delete newErrors[filterSource];
    });

    setErrors(newErrors);
    setFilters(newFilters);

    if (isCurrentValueExist && areFiltersValid(newErrors)) {
      applyFilters(newFilters);
    }
  };

  const resetFilter = (source: string) => {
    setFilters({
      ...filters,
      [source]: listFilter[source] || {},
    });
    setErrors(prev => ({
      ...prev,
      [source]: {},
    }));
  };

  const resetAllFilter = (defaultFilters: Filter) => {
    setFilters({
      ...defaultFilters,
    });
    setErrors({});
  };

  return {
    clear,
    apply,
    getValue,
    filterBy,
    filters,
    clearFilter,
    quickFilter,
    errors,
    getFilterErrors,
    setFilterErrors,
    clearAndApplyFilter,
    resetFilter,
    validationSchema,
    isStatic,
    resetAllFilter,
  };
};

export default useFilter;
