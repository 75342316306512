import React from 'react';
import _ from 'lodash';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import useInputHelper from './inputHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import { CommonInputLayoutProps, DefaultInputPropTypes } from 'shared/uibuilder/form/input';

const DEFAULT_PLACEHOLDER = 'Select...';

type Suggestion = {
  id: string | number;
  name: string;
};

export interface MultiSelectInputLayoutProps extends CommonInputLayoutProps<object, Nullable<Option[]>> {
  onLoadOptions: (inputValue: object, callback: (options: Nullable<Option[]>) => void) => void;
  placeholder?: string;
  noOptionsMessage?: (value?: Dictionary<string>) => Nullable<string>;
  menuPortalTarget?: Nullable<HTMLElement>;
  isMulti?: boolean;
}

export type MultiSelectInputLayoutType = ReactComponent<MultiSelectInputLayoutProps>;

interface MultiSelectInputProps extends DefaultInputPropTypes<Option[]> {
  loadSuggestionsMethod: (searchValue: object) => ResultResourceData;
  mapResults?: (data: Suggestion[]) => Option[];
  debounceDelay?: number;
  noOptionsMessage?: (value?: Dictionary<string>) => string;
  menuPortalTarget?: Nullable<HTMLElement>;
  isMulti?: boolean;
}

const MultiSelectInput = ({
  loadSuggestionsMethod,
  mapResults,
  debounceDelay,
  noOptionsMessage,
  menuPortalTarget,
  isMulti,
  ...props
}: MultiSelectInputProps) => {
  const { MultiSelectInputLayout } = useUiTheme<MultiSelectInputLayoutType>();
  const inputHelper = useInputHelper(props);

  const loadOptions = async (inputValue: object, callback: (options: Nullable<Option[]>) => void) => {
    try {
      const data = await loadSuggestionsMethod(inputValue);
      let result;

      if (mapResults) {
        result = mapResults(data);
      } else {
        result = data.map((item: Suggestion) => ({
          value: item.id,
          label: item.name,
        }));
      }

      callback(result);
    } catch {
      callback(null);
    }
  };

  const loadDebounced = _.debounce(loadOptions, debounceDelay);

  const handleLoadOptions = (inputValue: object, callback: (options: Nullable<Option[]>) => void) => {
    if (!inputValue) {
      callback(null);
    }

    loadDebounced(inputValue, callback);
  };

  return (
    <MultiSelectInputLayout
      onLoadOptions={handleLoadOptions}
      noOptionsMessage={noOptionsMessage}
      isVisible={inputHelper.isVisible()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      errorMessages={inputHelper.getErrorMessages()}
      isRequired={inputHelper.getIsRequired()}
      placeholder={inputHelper.getPlaceholder() || DEFAULT_PLACEHOLDER}
      menuPortalTarget={menuPortalTarget}
      helpText={inputHelper.getHelpText()}
      isMulti={isMulti}
    />
  );
};

MultiSelectInput.defaultProps = {
  debounceDelay: 500,
  noOptionsMessage: () => 'Nothing was found',
};

export default MultiSelectInput;
