/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleAssetBreadcrumbs from 'financialAnalytic/assets/shared/SingleAssetBreadcrumbs';

const UpdateAssetBreadcrumbs = () => {
  return (
    <SingleAssetBreadcrumbs>
      <UpdateBreadcrumbItem entity="Asset" />
    </SingleAssetBreadcrumbs>
  );
};

export default UpdateAssetBreadcrumbs;
