/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import { string } from 'prop-types';
import React from 'react';
import useRedmineUrl from 'redmine/useRedmineUrl';

// @ts-ignore
const RedmineUserField = ({ source, nameSource, ...props }) => {
  const { getValue: getId } = useFieldHelper<number>({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getUserUrl } = useRedmineUrl();

  const id = getId();
  const name = getName();
  const value = id && getUserUrl(id);

  return <LinkField {...props} linkText={name} value={value as string} isInternal={false} />;
};

RedmineUserField.propTypes = {
  source: string.isRequired,
  nameSource: string.isRequired,
};

export default RedmineUserField;
