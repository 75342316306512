/* istanbul ignore file */
// @ts-nocheck // Remove after refactoring DataGridLayout component with TS
import React from 'react';
import useOneStopService from 'oneStop/oneStopService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import List from 'shared/uibuilder/list/List';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import TextField from 'shared/uibuilder/field/TextField';
import OneStopRequestStatusFilter from 'myActivity/history/filter/OneStopRequestStatusFilter';
import Filters from 'shared/uibuilder/list/filter/Filters';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { LinkField } from 'shared/uibuilder/field';
import OneStopHistoryRowHighlighter from 'myActivity/history/List/OneStopHistoryRowHighlighter';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import { IsMyActivityEnabled } from 'myActivity/MyActivityRouterHelper';

import './OneStopHistoryList.scss';

export enum RequestStatusKey {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
}

const OneStopHistoryPage = () => {
  const { loadHistoryData } = useOneStopService();

  const openRequestsFilter = (
    <QuickFilter
      key="open-requests"
      label="All Open Requests"
      state={{ status: { in: [RequestStatusKey.NEW, RequestStatusKey.IN_PROGRESS] } }}
    />
  );

  const filters = (
    <Filters>
      <FiltersLayout quickFilters={[openRequestsFilter]}>
        <OneStopRequestStatusFilter label="Status" source="status" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List
      getDataMethod={loadHistoryData}
      defaultSortOrder={DESC}
      defaultSortField="submittedAt"
      defaultFilter={{ status: { in: [RequestStatusKey.NEW, RequestStatusKey.IN_PROGRESS] } }}
    >
      <ListPageLayout
        filter={filters}
        header={<ListHeader label={IsMyActivityEnabled() ? 'One Stop requests' : 'History'} icon="fa-archive" />}
      >
        <OneStopHistoryRowHighlighter>
          <DataGridLayout>
            <LinkField linkSource="taskTrackerIssueLink" source="externalId" label="External ID" />
            <TextField source="serviceName" label="Service" isSortable />
            <TextField source="serviceCategoryName" label="Service category" isSortable />
            <TextField source="subject" label="Subject" />
            <TextField source="status" label="Status" isSortable />
            <FullDateField source="submittedAt" label="Submitted at" isSortable />
          </DataGridLayout>
        </OneStopHistoryRowHighlighter>
      </ListPageLayout>
    </List>
  );
};

export default OneStopHistoryPage;
