import { useScsApi } from 'api';

export const READ_PERFORMANCE_REPORT = 'EMPLOYEE_READ_ALL_PERFORMANCE_REPORTS';
export const PERFORMANCE_REPORTS_PATH = '/performance-report';
const PERFORMANCE_REPORT_URL = '/metrics';

const usePerformanceReportService = () => {
  const { sendPostRequest } = useScsApi();

  const search = async ({ filter: { period, ...filter } = { period: {} }, ...params }: any) => {
    const url = `${PERFORMANCE_REPORT_URL}/${period?.eq}`;
    const response = await sendPostRequest(url, {
      ...params,
      filter,
    });
    const result = await response.json();

    return { result, totalElements: result.length };
  };

  const isFakeId = (id: string) => {
    return id.startsWith('emp');
  };

  return {
    search,
    isFakeId,
  };
};

export default usePerformanceReportService;
