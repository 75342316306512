/* istanbul ignore file */
// libs
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateVacancyBreadcrumbs from 'erp/recruitment/vacancy/createupdate/Update/Breadcrumbs';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import VacancyForm from '../VacancyForm';
import VacancyEntityHeader from 'erp/recruitment/vacancy/shared/VacancyEntityHeader';

const UpdateVacancy = () => {
  const { update, getById, getValidationSchema } = useVacancyService();
  const { getSingleEntityUrl } = useVacancyUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Vacancy has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        entityHeader={<VacancyEntityHeader />}
        title="Edit: Vacancy"
        breadcrumbs={<UpdateVacancyBreadcrumbs />}
      >
        <VacancyForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateVacancy;
