/* istanbul ignore file */
import React from 'react';
import { FieldProps, LinkField } from 'shared/uibuilder/field/index';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const VacancyLinkField = ({ source, nameSource, getLinkText, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useVacancyUrl();

  const id = getId();
  const name = getName();
  const value = id && getSingleEntityUrl(id);
  const linkText = nameSource ? name : id;

  return (
    <LinkField
      {...props}
      linkText={getLinkText ? getLinkText({ id, name }) : linkText}
      value={value as string}
      isInternal={false}
    />
  );
};

export default VacancyLinkField;
