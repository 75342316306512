import moment from 'moment';
import { get } from 'lodash';
import {
  DIVIDER,
  MetricsProps,
  DAYS_IN_WEEK,
  DAY_WORKING_NORM,
  DAY_MARK,
  DAY_TYPES,
} from 'erp/employee/performanceReport/usePerformanceReportService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const MIN_ROWS_WITH_DIVIDER = 2;

const usePerformanceHeatmap = ({
  labels,
  currentMonth,
  metrics,
}: {
  labels?: Array<string>;
  currentMonth: string;
  metrics?: MetricsProps;
}) => {
  const startOfMonth = moment(currentMonth).startOf('month');
  const endOfMonth = moment(currentMonth).endOf('month');

  const numberOfRowsWithData = labels && metrics ? labels.filter(metric => metrics[metric]).length : 0;
  const hasRowWithData = (metric: string) =>
    (metrics && metrics[metric]) || (numberOfRowsWithData >= MIN_ROWS_WITH_DIVIDER && metric === DIVIDER);

  const getWeeksData = () => {
    const result = [];
    const monday = moment(currentMonth)
      .startOf('month')
      .add(6, 'day')
      .startOf('isoWeek');
    const month = moment(currentMonth).month();

    if (monday.date() > DAYS_IN_WEEK) monday.add(DAYS_IN_WEEK, 'd');

    while (month === monday.month()) {
      const endDate = monday.clone().endOf('isoWeek');
      result.push({ start: monday.clone(), end: endDate.month() === month ? endDate : endOfMonth });
      monday.add(DAYS_IN_WEEK, 'd');
    }

    if (startOfMonth.format(DATE_FORMAT.INPUT_VALUE) !== result[0]?.start?.format(DATE_FORMAT.INPUT_VALUE)) {
      result.unshift({ start: startOfMonth.clone(), end: startOfMonth.clone().endOf('isoWeek') });
    }

    return result;
  };

  const getSumByDayType = (weekValues: any[], dayType: DAY_TYPES) =>
    weekValues.filter(value => value?.dayType === dayType).length;

  const getSumByDayMark = (weekValues: any[], dayMark: DAY_MARK) =>
    weekValues.filter(value => value?.metric?.mark === dayMark).length;

  const getSumByField = (data: any[], source: string) => data.reduce((sum, item) => sum + +get(item, source, 0), 0);

  const getWorkingMark = (spentTime: number, numberOfWorkingDays: number) =>
    spentTime >= numberOfWorkingDays * DAY_WORKING_NORM ? DAY_MARK.POSITIVE : DAY_MARK.NEGATIVE;

  const getStatistic = (
    keysObj: Dictionary<string>,
    values: Dictionary<any>,
    calculationFn: (values: any, source: any) => number,
    source?: any,
  ) =>
    Object.values(keysObj).reduce(
      (obj: Dictionary<number>, mark: string) => ({
        ...obj,
        [mark]: calculationFn(values, `${source ? `${source}.` : ''}${mark}`),
      }),
      {},
    );

  return {
    hasRowWithData,
    getWeeksData,
    getStatistic,
    getWorkingMark,
    getSumByField,
    getSumByDayMark,
    getSumByDayType,
  };
};

export default usePerformanceHeatmap;
