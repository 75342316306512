/* istanbul ignore file */
import React, { useCallback } from 'react';
import {
  DoubleValueFormat,
  IntegerValueFormat,
  NumberFieldType,
} from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { Dropdown, TextArea, TextInput } from 'shared/uibuilder/form/input';
import {
  VARIABLE_OBJECT_TYPES,
  VARIABLE_TYPES,
} from 'camunda/monitoring/instance/Show/variables/processVariablesService';

interface VariableValueInputProps {
  source: string;
  title: string;
  isRequired: boolean;
  type: string;
  objectTypeName: string;
  updateValueField: (newValue: any) => void;
}

export enum DateFieldType {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
}

export enum BOOLEAN_VALUE {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}
export const BOOLEAN_VALUE_LABELS = {
  [BOOLEAN_VALUE.TRUE]: 'True',
  [BOOLEAN_VALUE.FALSE]: 'False',
};

const VariableValueInput = ({
  source,
  title,
  isRequired,
  type,
  objectTypeName,
  updateValueField,
}: VariableValueInputProps) => {
  const getOnChangeNumberFieldCallback = useCallback(
    (fieldType: NumberFieldType) => (newFormData: FormFieldsData) => {
      const newValue = newFormData.value || '0';

      if (
        (fieldType === NumberFieldType.DOUBLE && !newValue.match(DoubleValueFormat)) ||
        (fieldType === NumberFieldType.INTEGER && !newValue.match(IntegerValueFormat))
      ) {
        return;
      }

      updateValueField(newValue.match(/^(0+\d+)$/) ? newValue.substring(1) : newFormData.value);
    },
    [updateValueField],
  );

  const booleanField = () => {
    return (
      <Dropdown
        source={source}
        label={title}
        isRequired={isRequired}
        options={Object.entries(BOOLEAN_VALUE_LABELS).map(entry => {
          const [value, label] = entry;
          return {
            value,
            label,
          };
        })}
      />
    );
  };

  const integerValueField = (tooltip?: string) => {
    return (
      <TextInput
        tooltip={tooltip}
        source={source}
        label={title}
        isRequired={isRequired}
        onChangeCallback={getOnChangeNumberFieldCallback(NumberFieldType.INTEGER)}
      />
    );
  };

  const textValueField = (tooltip: string, onChangeCallback?: (newFormData: FormFieldsData) => void) => {
    return (
      <TextInput
        tooltip={tooltip}
        source={source}
        label={title}
        isRequired={isRequired}
        onChangeCallback={onChangeCallback}
      />
    );
  };

  const valueField = () => {
    switch (type) {
      case VARIABLE_TYPES.INTEGER:
        return integerValueField();
      case VARIABLE_TYPES.LONG:
        return integerValueField();
      case VARIABLE_TYPES.DOUBLE:
        return textValueField(
          'Number with two digits after the point',
          getOnChangeNumberFieldCallback(NumberFieldType.DOUBLE),
        );
      case VARIABLE_TYPES.DATE:
        return textValueField('The date and time must be formatted as yyyy-MM-dd');
      case VARIABLE_TYPES.BOOLEAN:
        return booleanField();
      case VARIABLE_TYPES.OBJECT:
        return objectFieldValue();
      default:
        return <TextArea source={source} label={title} isRequired={isRequired} />;
    }
  };

  const objectFieldValue = () => {
    switch (objectTypeName) {
      case VARIABLE_OBJECT_TYPES.CAMUNDA_DATE:
        return textValueField('The date and time must be formatted as yyyy-MM-dd');
      case VARIABLE_OBJECT_TYPES.CAMUNDA_DATETIME:
        return textValueField('The date and time must be formatted as yyyy-MM-dd HH:mm');
      case VARIABLE_OBJECT_TYPES.CANDIDATE:
        return integerValueField('Candidate ID');
      case VARIABLE_OBJECT_TYPES.EMPLOYEE_CONTRACT:
        return integerValueField('Employee Contract ID');
      case VARIABLE_OBJECT_TYPES.LEAVE:
        return integerValueField('Leave ID');
      case VARIABLE_OBJECT_TYPES.EMPLOYEE:
        return textValueField('Employee Alias');
      case VARIABLE_OBJECT_TYPES.NULL_ARTIFACT:
        return <></>;
      case VARIABLE_OBJECT_TYPES.NULL:
        return <></>;
      default:
        return <TextArea source={source} label={title} isRequired={isRequired} />;
    }
  };

  return valueField();
};

export default VariableValueInput;
