/* istanbul ignore file */
import React from 'react';
import { OfferCompensationDetailsFieldProps } from 'erp/candidate/offercompensation/shared/field/OfferCompensationDetailsField';
import ContractTypeField from 'erp/contracts/shared/field/ContractTypeField';

const OfferContractTypeListField = ({ value }: OfferCompensationDetailsFieldProps) => {
  return <ContractTypeField value={value?.contractType} />;
};

export default OfferContractTypeListField;
