/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import PurchaseOrderBreadcrumbs from 'financialAnalytic/purchaseOrder/shared/PurchaseOrderBreadcrumbs';

const CreatePurchaseOrderBreadcrumbs = () => {
  return (
    <PurchaseOrderBreadcrumbs>
      <CreateBreadcrumbItem entity="Purchase Order" />
    </PurchaseOrderBreadcrumbs>
  );
};

export default CreatePurchaseOrderBreadcrumbs;
