/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import useRedmineUrl from 'redmine/useRedmineUrl';
import { LinkFieldProps } from 'shared/uibuilder/field/LinkField';

const ProjectField = ({ source, nameSource, ...props }: LinkFieldProps) => {
  const { getValue: getId } = useFieldHelper<number>({ source });
  const { getValue: getName } = useFieldHelper<number>({ source: nameSource });
  const { getProjectUrl } = useRedmineUrl();

  const id = getId();
  const value = id && getProjectUrl(id);

  return <LinkField {...props} linkText={nameSource ? getName() : id} value={value as string} isInternal={false} />;
};

export default ProjectField;
