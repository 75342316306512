import useRoute from 'shared/routing/useRoute';
import { CREATE_ROOM, READ_ROOM, READ_ROOM_LIST, ROOM_PATH, UPDATE_ROOM } from 'erp/room/useRoomService';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import { Route } from 'shared/routing';
import React from 'react';
import RoomViewPage from 'erp/room/Show';
import RoomListPage from 'erp/room/List';
import CreateRoomPage from 'erp/room/createupdate/Create';
import UpdateRoomPage from 'erp/room/createupdate/Update';
import RoomContext from 'erp/room/RoomContext';

export const useRoomRoute = () => useRoute({ listRoute: ROOM_PATH });

export const useRoomUrl = () => {
  const urls = useEntityUrl({ baseLink: ROOM_PATH });
  const getRoomsByOfficeUrl = (office: any) => `${urls.getListUrl()}?filter={"office.id":{"eq":"${office}"}}`;
  return {
    getRoomsByOfficeUrl,
    ...urls,
  };
};

export const useRoomId = () => useParams<Dictionary<string>>().id;

const RoomRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute } = useRoomRoute();

  return (
    <ProtectedRouter basePath={ROOM_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_ROOM_LIST}>
          <RoomListPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_ROOM}>
          <CreateRoomPage />
        </GlobalProtectedRoute>
        <Route path={updateRoute} exact>
          <RoomContext permissionToCheck={UPDATE_ROOM}>
            <UpdateRoomPage />
          </RoomContext>
        </Route>
        <Route path={singleEntityRoute} exact>
          <RoomContext permissionToCheck={READ_ROOM}>
            <RoomViewPage />
          </RoomContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

RoomRouter.getRouterPath = () => ROOM_PATH;

export default RoomRouter;
