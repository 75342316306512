import React from 'react';
import { useOneStopHistoryRequestId } from 'myActivity/MyActivityRouterHelper';

export enum RequestStatus {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
}

const OneStopHistoryRowHighlighter = ({ children }: { children: React.ReactElement }) => {
  const activeRequestId = useOneStopHistoryRequestId();

  const getRowClassName = (status: RequestStatus, externalId: string) => {
    let className;

    switch (status) {
      case RequestStatus.NEW:
        className = 'table-danger';
        break;
      case RequestStatus.IN_PROGRESS:
        className = 'table-info';
        break;
      case RequestStatus.CLOSED:
        className = 'table-secondary';
        break;
      default:
        className = '';
        break;
    }

    if (externalId === activeRequestId) {
      className += ' active-row';
    }

    return className;
  };

  const getRowProps = (data: any) => {
    const className = getRowClassName(data.status, data.externalId);

    return { className };
  };

  return React.cloneElement(children, { rowProps: getRowProps });
};

export default OneStopHistoryRowHighlighter;
