import React from 'react';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import UploadWbsModal from './UploadWBSModal';
import useProjectsWBSDashboardService from '../useProjectsWBSDashboardService';
import { useListContext } from 'shared/uibuilder/list/ListContext';

export const UPLOAD_WBS_MESSAGE_CONTEXT = 'uploadWBSModalForm';

export type UploadWbsFormProps = {
  closeModal: () => void;
};

const UploadWbsForm = ({ closeModal }: UploadWbsFormProps) => {
  const { reloadData = () => {} } = useListContext();
  const { uploadWBS, getValidationSchema } = useProjectsWBSDashboardService();

  return (
    <CreateForm
      submitFormFunc={uploadWBS}
      messageContext={UPLOAD_WBS_MESSAGE_CONTEXT}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        execute: () => {
          reloadData();
          closeModal();
        },
      }}
    >
      <UploadWbsModal closeModal={closeModal} />
    </CreateForm>
  );
};

export default UploadWbsForm;
