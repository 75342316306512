import React from 'react';
import { any, arrayOf, func, node, oneOfType, string } from 'prop-types';
import useConditionHelper, { ConditionHelperParams } from './conditionHelper';
import { useFilterContext, Filter } from 'shared/uibuilder/list/filter/FilterContext';

type ConditionInnerElementProps = {
  onChangeCallback: (value: any) => void;
  source: string;
  value: Nullable<string | string[]>;
  menuPortalTarget?: Nullable<HTMLElement>;
};

export interface ConditionProps extends ConditionHelperParams {
  children: React.ReactElement<ConditionInnerElementProps>;
  onChangeCallback?: (value: Filter) => void;
}

const Condition = ({ source, children, onChangeCallback: propsParentOnChangeCallback, ...props }: ConditionProps) => {
  const { filterBy } = useFilterContext();
  const { getRawValue, buildValue } = useConditionHelper({ source, ...props });

  const parentOnChangeCallback =
    propsParentOnChangeCallback ||
    (filter => {
      if (filterBy) filterBy(filter);
    });

  const onChangeCallback = (value: any) => {
    parentOnChangeCallback(buildValue(value[source]));
  };

  return React.cloneElement(children, {
    onChangeCallback,
    source,
    value: getRawValue(),
  });
};

Condition.propTypes = {
  conditionType: string.isRequired,
  source: string.isRequired,
  children: node.isRequired,
  onChangeCallback: func,
  value: oneOfType([string, arrayOf(any)]),
};

Condition.defaultProps = {
  value: undefined,
  onChangeCallback: undefined,
};

export default Condition;
