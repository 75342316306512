/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useProjectUrl } from 'erp/project/ProjectRouter';

const EditProjectButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useProjectUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditProjectButton;
