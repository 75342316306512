import useFieldHelper from '../../../../shared/uibuilder/field/fieldHelper';
import { TextField } from '../../../../shared/uibuilder/field';
import React from 'react';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';

const PreviousPaidLeaveBalanceField = props => {
  const { getValue } = useFieldHelper(props);
  const displayedValue = `${getValue()} working days`;
  const { label: labelFromProps, source: sourceFromProps, tooltip: tooltipFromProps } = props;

  return (
    <TextField
      {...props}
      label={
        <>
          {labelFromProps}
          <InputTooltip source={sourceFromProps} message={tooltipFromProps} />
        </>
      }
      value={displayedValue}
    />
  );
};

export default PreviousPaidLeaveBalanceField;
