// libs
import React from 'react';
import LinkButton from './LinkButton';
import useAuthorization from 'shared/authorization/authorizationService';
import AddIcon from '@mui/icons-material/AddOutlined';

export interface CreateButtonProps {
  url: string | ((id: string | number) => string);
  label?: string;
  permissionToCheck?: Nullable<string | string[]>;
  className?: string;
  hasIcon?: boolean;
  openInNewTab?: boolean;
  outline?: boolean;
  variant?: 'outlined' | 'contained' | 'text';
}

const CreateButton = ({
  url,
  label,
  permissionToCheck,
  className,
  hasIcon = true,
  openInNewTab = false,
  outline = false,
  variant = 'text',
  ...props
}: CreateButtonProps) => {
  const { isGranted } = useAuthorization();

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <LinkButton
      {...props}
      url={url}
      className={`create-btn ${className}`}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      target={openInNewTab ? '_blank' : ''}
      variant={outline ? 'outlined' : variant}
      startIcon={hasIcon ? <AddIcon /> : null}
    >
      {label}
    </LinkButton>
  ) : null;
};

CreateButton.defaultProps = {
  label: '',
  permissionToCheck: null,
  className: '',
};

export default CreateButton;
