import React from 'react';
import { DefaultInputPropTypes, SearchInput } from 'shared/uibuilder/form/input';
import useBudgetService, { BudgetSearchFilterProps } from 'erp/budget/shared/budgetService';

export interface BudgetInputProps extends DefaultInputPropTypes<string> {
  initialLabel?: string;
  searchString?: string;
  nameSource?: string;
  mapResults?: (budget: any) => void;
  props?: any;
  filter?: BudgetSearchFilterProps;
}

const BudgetInput = ({ mapResults, searchString = 'name', filter = {}, ...props }: BudgetInputProps) => {
  const { search, getSearchFilter } = useBudgetService();

  const mapResponse = (budget: any) => ({
    id: budget.id,
    text: budget.name,
  });

  return (
    <SearchInput
      defaultFilter={getSearchFilter(filter)}
      {...props}
      searchString={searchString}
      searchRequest={search}
      noResultMessage="No Budgets are found."
      mapResults={mapResults || mapResponse}
    />
  );
};

export default BudgetInput;
