/* istanbul ignore file */
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import React from 'react';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import List from 'shared/uibuilder/list/List';
import { ASC } from 'shared/uibuilder/list/baseListHelper';
import SkillsBreadcrumbs from './SkillsBreadcrumbs';
import AddSkillButton from './button/AddSkillButton';
import RemoveSkillButton from './button/RemoveSkillButton';
import useEmployeeSkillsService, {
  CREATE_EMPLOYEE_SKILLS,
  DELETE_EMPLOYEE_SKILLS,
} from 'erp/employee/skills/EmployeeSkillsService';

const EmployeeSkillsList = () => {
  const { search } = useEmployeeSkillsService();

  return (
    <ShowPageLayout menu={<EmployeeMenu />} entityHeader={<EmployeeEntityHeader />} breadcrumbs={<SkillsBreadcrumbs />}>
      <List getDataMethod={search} defaultSortField="skillName" defaultSortOrder={ASC}>
        <RelationListLayout
          actions={[<AddSkillButton formTitle="Add skill" permissionToCheck={CREATE_EMPLOYEE_SKILLS} />]}
          header="Skills"
        >
          <DataGridLayout
            showTotal={false}
            isSmallTable
            buttons={[
              <RemoveSkillButton source="id" outline showIcon={false} permissionToCheck={DELETE_EMPLOYEE_SKILLS} />,
            ]}
          >
            <TextField source="skillId" label="Skill ID" />
            <TextField source="skillName" label="Skill name" isSortable />
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default EmployeeSkillsList;
