/* istanbul ignore file */

import React from 'react';
import List from 'shared/uibuilder/list/List';
import { Filters, FiltersLayout } from 'shared/uibuilder/list/filter';
import useAuthorization from 'shared/authorization/authorizationService';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import Box from 'uibuilder/Box';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import DateField from 'shared/uibuilder/field/DateField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import useCorporateEventService, {
  READ_CORPORATE_EVENT,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';
import CorporateEventTypeField from 'financialAnalytic/corporateEvents/shared/field/CorporateEventTypeField';
import CorporateEventStatusField from 'financialAnalytic/corporateEvents/shared/field/CorporateEventStatusField';
import CorporateEventLocationField from 'financialAnalytic/corporateEvents/shared/field/CorporateEventLocationField';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import CorporateEventStatusDropdownFilter from 'financialAnalytic/corporateEvents/shared/filter/CorporateEventStatusDropdownFilter';
import CorporateEventLocationDropdownFilter from 'financialAnalytic/corporateEvents/shared/filter/CorporateEventLocationDropdownFilter';
import CorporateEventTypeDropdownFilter from 'financialAnalytic/corporateEvents/shared/filter/CorporateEventTypeDropdownFilter';
import CorporateEventLink from 'financialAnalytic/corporateEvents/shared/field/CorporateEventLink';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import CreateCorporateEventButton from 'financialAnalytic/corporateEvents/shared/button/CreateCorporateEventButton';
import ViewCorporateEventButton from 'financialAnalytic/corporateEvents/shared/button/ViewCorporateEventButton';

const filter = (
  <Filters>
    <FiltersLayout>
      <CorporateEventStatusDropdownFilter label="Status" source="status" />
      <CorporateEventLocationDropdownFilter label="Location" source="location" />
      <CorporateEventTypeDropdownFilter label="Type" source="type" />
      <DateRangeFilter canBeInFuture label="Event date" source="date" />
      <DateTimeRangeFilter label="Created Date" source="createdAt" />
      <DateTimeRangeFilter label="Last Modified Date" source="updatedAt" />
    </FiltersLayout>
  </Filters>
);

const CorporateEventListPage = () => {
  const { search } = useCorporateEventService();
  const { isGranted } = useAuthorization();

  return (
    <List getDataMethod={search} defaultSortField="date" defaultSortOrder="DESC">
      <ListPageLayout
        header={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              h1: {
                mr: 'auto',
              },
              button: {
                marginTop: '10px',
                marginBottom: '20px',
                ml: 1,
              },
            }}
          >
            <ListHeader label="Corporate Events" />
          </Box>
        }
        filter={filter}
        search={<SearchInput label="Name" />}
        actions={[<CreateCorporateEventButton />]}
      >
        <DataGridLayout
          buttons={[<ViewCorporateEventButton source="id" isVisible={isGranted(READ_CORPORATE_EVENT)} />]}
        >
          <CorporateEventLink
            source="id"
            nameSource="name"
            label="Name"
            isClickable={isGranted(READ_CORPORATE_EVENT)}
            isSortable
          />
          <CorporateEventStatusField source="status" label="Status" isSortable />
          <CorporateEventLocationField source="location" label="Location" isSortable />
          <CorporateEventTypeField source="type" label="Type" isSortable />
          <DateField dateFormat={DATE_FORMAT.FULL_DATE} source="date" label="Event date" isSortable />
          <FullDateField source="createdAt" label="Created Date" isSortable />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default CorporateEventListPage;
