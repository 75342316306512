/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import MediumHeading from 'camunda/monitoring/instance/Show/MediumHeading';
import 'camunda/monitoring/instance/Show/token/TokenModification.scss';
import ModificationTokenButton from 'camunda/monitoring/instance/Show/token/shared/ModificationTokenButton';
import useProcessInstanceTokenService, {
  ProcessInstanceTokenAction,
} from 'camunda/monitoring/instance/Show/token/processInstanceTokenService';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import ModificationTokenPositionForm from 'camunda/monitoring/instance/Show/token/ModificationTokenPositionForm';
import CreateForm from 'shared/uibuilder/form/CreateForm';

const ModificationTokenPositions = () => {
  const processInstanceId = useProcessInstanceId();
  const [action, setAction] = useState(ProcessInstanceTokenAction.CREATE);
  const { create, recreate, move, kill } = useProcessInstanceTokenService();

  const showForm = (newAction: ProcessInstanceTokenAction) => () => {
    setAction(newAction);
  };

  const isActive = useCallback(
    (actionName: string) => {
      return actionName === action;
    },
    [action],
  );

  const submitFunc = () => {
    switch (action) {
      case ProcessInstanceTokenAction.CREATE:
        return create;
      case ProcessInstanceTokenAction.RECREATE:
        return recreate;
      case ProcessInstanceTokenAction.MOVE:
        return move;
      case ProcessInstanceTokenAction.KILL:
        return kill;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  };

  const renderTokenButton = (actionName: ProcessInstanceTokenAction, label: string) => (
    <ModificationTokenButton isActive={isActive(actionName)} onClick={showForm(actionName)} label={label} />
  );

  return (
    <>
      <div className="tooltip-wrapper">
        <MediumHeading>Token Modifications: {action}</MediumHeading>
      </div>
      <div className="modifications-menu">
        {renderTokenButton(ProcessInstanceTokenAction.CREATE, 'Create token')}
        {renderTokenButton(ProcessInstanceTokenAction.RECREATE, 'Recreate token')}
        {renderTokenButton(ProcessInstanceTokenAction.MOVE, 'Move token')}
        {renderTokenButton(ProcessInstanceTokenAction.KILL, 'Kill token')}
      </div>

      <div className="modifications-form">
        <div className="modification-form">
          <CreateForm
            submitFormFunc={submitFunc()}
            initialData={{ instanceId: processInstanceId }}
            afterSubmit={{
              message: 'You are awesome! The action was performed correctly!',
            }}
          >
            <ModificationTokenPositionForm action={action} processInstanceId={processInstanceId} />
          </CreateForm>
        </div>
      </div>
    </>
  );
};

export default ModificationTokenPositions;
