/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { func, number, oneOfType, string } from 'prop-types';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const DeleteTimelineRecordButton = ({ permissionToCheck, deleteMethod, entityName, timelineId, id }) => {
  const message = `Do you really want to delete this ${entityName}?`;
  const { deleteFromList } = useListContext();

  return (
    <DeleteButton
      deleteMethod={() => deleteMethod(id)}
      afterDelete={{
        execute: () => deleteFromList(timelineId),
      }}
      displayMessage={message}
      permissionToCheck={permissionToCheck}
      showIcon={false}
      buttonTag={MenuItem}
      className="danger-color"
    />
  );
};

DeleteTimelineRecordButton.propTypes = {
  permissionToCheck: string.isRequired,
  deleteMethod: func.isRequired,
  entityName: string.isRequired,
  id: oneOfType([string, number]).isRequired,
  timelineId: oneOfType([string, number]).isRequired,
};

export default DeleteTimelineRecordButton;
