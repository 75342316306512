import React, { useCallback } from 'react';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useReconciliationService, {
  RECONCILIATION_STATUS_ALIASES,
  RUN_MANUAL_RECONCILIATION,
} from '../../useReconciliationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { UpdateListDataAndReloadGroupsProps } from '../../List/ReconciliationListDataContext';
import { InputData } from 'shared/uibuilder/list/builder/useListCrud';

const ApprovePotentialMatchGroups = ({
  statusSource,
  idSource,
  execute,
  sourceBankTransactions,
  sourceDavinciTransfers,
  bankData,
  davinciData,
}: {
  statusSource: string;
  sourceBankTransactions: string;
  sourceDavinciTransfers: string;
  idSource: string;
  bankData: InputData;
  davinciData: InputData;
  execute: (data: UpdateListDataAndReloadGroupsProps) => void;
}) => {
  const { approveReconciliation } = useReconciliationService();

  const { getValue: getBankTransactions } = useFieldHelper({ source: sourceBankTransactions });
  const { getValue: getDavinciTransfers } = useFieldHelper({ source: sourceDavinciTransfers });
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getValue: getId } = useFieldHelper({ source: idSource });
  const status = getStatus();
  const id = getId();
  const bankTransactions = getBankTransactions() as any;
  const bankDavinciTransfers = getDavinciTransfers() as any;

  const executeCallback = useCallback(() => {
    execute({
      newBankData: {
        items: bankData.items?.filter(
          (item: Dictionary<any>) => !bankTransactions?.find((transaction: any) => transaction.id === item.id),
        ),
        totalPages: 0,
        totalElements: 0,
      },
      newDavinciData: {
        items: davinciData.items?.filter(
          (item: Dictionary<any>) => !bankDavinciTransfers?.find((transfer: any) => transfer.id === item.id),
        ),
        totalPages: 0,
        totalElements: 0,
      },
    });
  }, [bankData.items, bankDavinciTransfers, bankTransactions, davinciData.items, execute]);

  if (status !== RECONCILIATION_STATUS_ALIASES.POTENTIAL_MATCH) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to approve this reconciliation?"
      submitMethod={() => approveReconciliation(id)}
      permissionToCheck={RUN_MANUAL_RECONCILIATION}
      afterSubmit={{
        successMessage: 'The reconciliation has been successfully approved.',
        errorMessage: "Can't approve reconciliation.",
        execute: executeCallback,
      }}
      outline
      color="primary"
    >
      Approve
    </ButtonWithConfirmation>
  );
};

export default ApprovePotentialMatchGroups;
