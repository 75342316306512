/* istanbul ignore file */
import { Redirect } from 'react-router-dom';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import React from 'react';

const ProcessInstanceRedirect = () => {
  const id = useProcessInstanceId();
  const { getHistoryUrl } = useProcessInstanceUrl();
  return <Redirect to={getHistoryUrl(id)} />;
};

export default ProcessInstanceRedirect;
