/* istanbul ignore file */
import React from 'react';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const BACKGROUND_CHECK_RESULTS = {
  PASSED: 'Passed',
  NOT_PASSED: 'Not Passed',
};

const BackgroundCheckResultDropdown = (props: DefaultDropdownProps) => (
  <EnumDropdown {...props} options={BACKGROUND_CHECK_RESULTS} placeholder="Select check result" />
);

export default BackgroundCheckResultDropdown;
