/* istanbul ignore file */
// libs
import React from 'react';
// components
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import ShowLeadPageMenu from 'crm/lead/ShowLeadPageMenu';
import ConvertLeadBreadcrumbs from 'crm/lead/Convert/Breadcrumbs';
import LeadEntityHeader from 'crm/lead/LeadEntityHeader';
import { ACCOUNT_TYPE } from 'crm/account/shared/input/AccountInput';
import ConvertLeadForm from 'crm/lead/Convert/ConvertLeadForm';
// services
import useConvertLeadService from 'crm/lead/Convert/convertLeadService';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { useAccountUrl } from 'crm/account/index';
import { useLeadId } from 'crm/lead/LeadRouter';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DAYS_TO_CLOSE } from 'crm/opportunity/shared/opportunityService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const ConvertLead = () => {
  const { convertLead, getConvertValidationSchema, getInitialData } = useConvertLeadService();
  const { getSingleEntityUrl: getAccountSingleEntityUrl } = useAccountUrl();
  const { getSingleEntityUrl: getOpportunitySingleEntityUrl } = useOpportunityUrl();
  const id = useLeadId();
  const { getCurrentDateTimeInUtc } = useDateService();
  const currentDate = getCurrentDateTimeInUtc();
  const initialCloseDate = currentDate.add(DAYS_TO_CLOSE, 'days').format(DATE_FORMAT.INPUT_VALUE);

  const getDataMethod = async () => {
    const { companyName, firstName, middleName, lastName, title } = await getInitialData();

    return {
      firstName,
      middleName,
      lastName,
      title,
      originalAccountName: companyName,
      accountName: '',
      accountType: ACCOUNT_TYPE.NEW,
      opportunityName: companyName,
      closeDate: initialCloseDate,
    };
  };

  const getUrlForRedirectAfterSubmit = ({ opportunityId, accountId }) => {
    if (opportunityId) {
      return getOpportunitySingleEntityUrl(opportunityId);
    } else {
      return getAccountSingleEntityUrl(accountId);
    }
  };

  return (
    <CreateForm
      getDataFunc={getDataMethod}
      submitFormFunc={data => convertLead(id, data)}
      afterSubmit={{
        redirect: getUrlForRedirectAfterSubmit,
        message: 'You are awesome! The Lead has been successfully converted.',
      }}
      getValidationSchemaFunc={getConvertValidationSchema}
    >
      <CreatePageLayout
        title="Convert into:"
        menu={<ShowLeadPageMenu />}
        breadcrumbs={<ConvertLeadBreadcrumbs />}
        entityHeader={<LeadEntityHeader />}
      >
        <ConvertLeadForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default ConvertLead;
