/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import DeleteButton from 'uibuilder/button/DeleteButton';
// services
import useVacancyService, { DELETE_VACANCY } from 'erp/recruitment/vacancy/shared/vacancyService';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const deleteMessage = 'Do you really want to delete this vacancy?';

const DeleteVacancyButton = () => {
  // @ts-ignore
  const { id: vacancyId } = useParams();
  const { deleteById } = useVacancyService();
  const { getListUrl } = useVacancyUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(vacancyId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Vacancy has been successfully deleted.',
        errorMessage: `Can't delete vacancy`,
      }}
      permissionToCheck={DELETE_VACANCY}
    />
  );
};

export default DeleteVacancyButton;
