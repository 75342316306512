import React from 'react';
import useCorporateEventService, { REFERENCE_TYPES } from 'financialAnalytic/corporateEvents/useCorporateEventService';
import { useCorporateEventUrl } from 'financialAnalytic/corporateEvents/CorporateEventRouter';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateCorporateEventBreadcrumbs from 'financialAnalytic/corporateEvents/createupdate/Update/Breadcrumbs';
import CorporateEventForm from 'financialAnalytic/corporateEvents/createupdate/CorporateEventForm';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import useProjectService from 'erp/project/shared/projectService';
import useServiceService from 'erp/service/shared/serviceService';
import useApplicationService from 'erp/recruitment/applications/shared/applicationService';

const UpdateCorporateEventPage = () => {
  const { update, getUpdateValidationSchema, getById } = useCorporateEventService();
  const { getSingleEntityUrl } = useCorporateEventUrl();
  const { getEmployeeNamesByAliases } = useEmployeeNamesService();
  const { searchAll: searchProjects } = useProjectService();
  const { searchAll: searchServices } = useServiceService();
  const { searchAll: searchApplications } = useApplicationService();

  const getBusinessUnitsByIds = async (projectIds: any[], serviceIds: any[]) => {
    const [projects, services] = await Promise.all([
      searchProjects({
        filter: {
          id: {
            in: projectIds,
          },
        },
      }),
      searchServices({
        filter: {
          id: {
            in: serviceIds,
          },
        },
      }),
    ]);

    projects.forEach((project: any) => {
      // eslint-disable-next-line no-param-reassign
      project.reference = `P${project.id}`;
    });

    services.forEach((service: any) => {
      // eslint-disable-next-line no-param-reassign
      service.reference = `S${service.id}`;
    });

    return [...projects, ...services];
  };

  const getApplicationsByIds = async (ids: any[]) => {
    return searchApplications({
      filter: {
        id: {
          in: ids,
        },
      },
    });
  };

  const getDataFunc = async (id: string) => {
    const { participants: participantAliases, references, ...data } = await getById(id);

    const projectIds = references
      .filter((ref: any) => ref.type === REFERENCE_TYPES.PROJECT)
      // eslint-disable-next-line no-param-reassign
      .map((ref: any) => `${ref.reference}`);
    const serviceIds = references
      .filter((ref: any) => ref.type === REFERENCE_TYPES.COMPANY_SERVICE)
      // eslint-disable-next-line no-param-reassign
      .map((ref: any) => `${ref.reference}`);
    const applicationIds = references
      .filter((ref: any) => ref.type === REFERENCE_TYPES.CANDIDATE_APPLICATION)
      // eslint-disable-next-line no-param-reassign
      .map((ref: any) => `${ref.reference}`);

    return {
      name: data.name,
      description: data.description,
      type: data.type,
      status: data.status,
      location: data.location,
      date: data.date,
      participants: await getEmployeeNamesByAliases(participantAliases),
      businessUnits: await getBusinessUnitsByIds(projectIds, serviceIds),
      candidateApplications: await getApplicationsByIds(applicationIds),
    };
  };

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      afterSubmit={{
        redirect: ({ id }: any) => getSingleEntityUrl(id),
        message: 'You are awesome! The Corporate Event has been successfully updated.',
      }}
      getValidationSchemaFunc={getUpdateValidationSchema}
    >
      <UpdatePageLayout breadcrumbs={<UpdateCorporateEventBreadcrumbs />} title="Edit: Corporate Event">
        <CorporateEventForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateCorporateEventPage;
