/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';

export enum FormRowType {
  DEFAULT = 'default',
  BIG = 'big',
  MEDIUM = 'medium',
}

type FormRowProps = {
  type: FormRowType;
  children: any;
  props: any;
};

const FormRowFactory = ({ type, children, props }: FormRowProps) => {
  switch (type) {
    case FormRowType.DEFAULT:
      return <FormRow {...props}>{children}</FormRow>;
    case FormRowType.BIG:
      return <BigFormRow {...props}>{children}</BigFormRow>;
    case FormRowType.MEDIUM:
      return <MediumFormRow {...props}>{children}</MediumFormRow>;
    default:
      return <></>;
  }
};

export default FormRowFactory;
