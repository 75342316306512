import React from 'react';
import CorporateEventBreadCrumbs from 'financialAnalytic/corporateEvents/shared/CorporateEventBreadCrumbs';
import CreateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/CreateBreadcrumbItem';

const CreateCorporateEventBreadcrumbs = () => {
  return (
    <CorporateEventBreadCrumbs>
      <CreateBreadcrumbItem entity="Corporate Event" />
    </CorporateEventBreadCrumbs>
  );
};

export default CreateCorporateEventBreadcrumbs;
