import React, { useState } from 'react';
import { Filter } from 'shared/uibuilder/list/filter/FilterContext';
import LeadNotificationButton from 'crm/lead/shared/filter/LeadNotificationButton';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const NOTIFICATION_COLOR_HEX = {
  LIGHT_RED: '#FF7F7F',
  BRIGHT_RED: '#EE4B2B',
};

const getLabelDurationForLessThanHour = (minutesDifference: number) => {
  if (minutesDifference < 15) {
    return '< 15 minutes';
  } else if (minutesDifference >= 15 && minutesDifference < 30) {
    return '< 30 minutes';
  } else if (minutesDifference >= 30 && minutesDifference < 45) {
    return '< 45 minutes';
  } else {
    return '< 60 minutes';
  }
};

const NewLeadsNotificationButton = (props: {
  className?: string;
  loadingDataCallback: (count: number) => void;
  onClick: (state: Filter) => void;
  state: Filter;
  loaderClassNames?: string;
  setIsLoadingCallback: (value: boolean) => void;
  sx?: SxProps<Theme>;
}) => {
  const [styles, setStyles] = useState({});
  const { getToday, getMinutesBetween } = useDateService();
  const today = getToday();

  const getLabel = ({ totalElements, result = [] }: { totalElements: number; result: any[] }) => {
    const { createdAt } = result[0] || [];
    const minutesDifference = getMinutesBetween(today, createdAt);
    const hoursDifference = minutesDifference / 60;
    let labelDuration: string;
    const hasSeveralLeads = totalElements > 1;

    if (hoursDifference < 1) {
      labelDuration = getLabelDurationForLessThanHour(minutesDifference);
    } else if (hoursDifference >= 1 && hoursDifference < 24) {
      const flooredHours = Math.floor(hoursDifference);
      const unit = flooredHours === 1 ? 'hour' : 'hours';
      labelDuration = `${flooredHours}+ ${unit}`;

      if (hoursDifference > 9) {
        setStyles({ backgroundColor: NOTIFICATION_COLOR_HEX.LIGHT_RED });
      }
    } else {
      labelDuration = '24+ hours';
      setStyles({ backgroundColor: NOTIFICATION_COLOR_HEX.BRIGHT_RED });
    }

    return `${totalElements} New lead${hasSeveralLeads ? 's have' : ' has'} been unassigned for ${labelDuration}`;
  };

  return (
    <LeadNotificationButton
      getLabel={getLabel}
      color="error"
      styles={{
        ...styles,
        '&:hover': {
          backgroundColor: 'var(--mui-palette-error-dark)',
        },
        '&.Mui-disabled': {
          ...styles,
        },
      }}
      {...props}
    />
  );
};

export default NewLeadsNotificationButton;
