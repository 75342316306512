/* istanbul ignore file */
import React from 'react';
import { useLedgerUrl } from 'financialAnalytic/ledger/LedgerRouter';
import LinkButton from 'uibuilder/button/LinkButton';
import { ButtonProps } from 'uibuilder/button/Button';

const ViewLedgerButton = (props: ButtonProps) => {
  const { getSingleEntityUrl } = useLedgerUrl();

  return (
    <LinkButton url={id => getSingleEntityUrl(String(id).replace(/[/]/g, '_'))} key="view-ledger" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewLedgerButton;
