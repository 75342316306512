/* istanbul ignore file */
import oneStopBudgetReallocationValidation from 'erp/budgetreallocation/createupdate/oneStopBudgetReallocationValidation';
import useCrudService from 'shared/crud';
import { useKernelApi, useBusinessEngineApi } from 'api';

export const BUDGET_REALLOCATION_PATH = '/budgetReallocations';

export const CREATE_BUDGET_REALLOCATION = 'CREATE_BUDGET_REALLOCATION';

const useBudgetReallocationService = () => {
  const singleResourceUrl = `${BUDGET_REALLOCATION_PATH}/:id`;
  const { ...baseCrudRequests } = useCrudService({
    singleResourceUrl,
    listResourceUrl: BUDGET_REALLOCATION_PATH,
    apiService: useKernelApi,
  });

  const getOneStopValidationSchema = () => Promise.resolve(oneStopBudgetReallocationValidation);

  const { sendPostRequest } = useBusinessEngineApi();

  const createBudgetReallocation = async (budgetReallocation: any) => {
    const response = await sendPostRequest(BUDGET_REALLOCATION_PATH, budgetReallocation);
    return response.json();
  };

  return {
    getOneStopValidationSchema,
    ...baseCrudRequests,
    createBudgetReallocation,
  };
};

export default useBudgetReallocationService;
