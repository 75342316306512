/* istanbul ignore file */
import React, { useState } from 'react';
import classnames from 'classnames';
import Box from 'uibuilder/Box';
import Button from 'uibuilder/button/Button';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useSyberryTodayService from 'syberryToday/useSyberryTodayService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import DailyCheckinStepperLayout from 'syberryToday/shared/layout/DailyCheckinStepperLayout';
import Scrollbars from 'uibuilder/Scrollbars';
import ModalWindow from 'shared/uibuilder/ModalWindow';

const DailyCheckinStepper = () => {
  const {
    selectedIssues = [],
    handleNext,
    activeStep = 0,
    handleBack,
    plannedAllocations = {},
    projects = [],
    setIsCheckinPassed,
    setLoading,
  } = useSyberryTodayContext();
  const { confirmDailyCheckin } = useSyberryTodayService();
  const { filter = {}, filterBy = () => {} } = useListContext();
  const todayAllocation: number = Object.values(plannedAllocations).reduce(
    (sum: number, allocation: number) => sum + (+(allocation as number) || 0),
    0,
  );
  const activeProjectId = filter?.projectId?.eq || null;
  const { addMessage } = useMessageService();
  const allocationErrorMessages = todayAllocation > 12 ? ['Sum of planned allocations must not exceed 12 hours'] : [];
  const isButtonDisabled = !selectedIssues.length || !todayAllocation || !!allocationErrorMessages.length;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [confirmationError, setConfirmationError] = useState<Nullable<React.ReactElement[]>>(null);

  const getProjectsWithoutAllocations = () =>
    projects.filter(({ allocation, id }: any) => !!allocation?.weekly?.planned && !plannedAllocations[id]);

  const handleNextStep = async () => {
    if (activeStep < 1) {
      const projectsWithoutAllocations = getProjectsWithoutAllocations();

      if (projectsWithoutAllocations.length) {
        setConfirmationError(projectsWithoutAllocations.map(({ name, id }: any) => <li key={id}>{name}</li>));
        setIsModalOpened(true);
        return;
      }

      handleNext();
    } else {
      try {
        setLoading(true);
        await confirmDailyCheckin();
        setIsCheckinPassed(true);
      } catch (e) {
        addMessage(new ErrorMessage("Can't confirm checkin"));
      } finally {
        setLoading(false);
      }
    }
  };

  const steps = [
    {
      label: 'Pick tasks',
      description: (
        <Scrollbars
          className={classnames('daily-checkin-stepper__projects-list-wrapper', {
            'daily-checkin-stepper__projects-list-wrapper--min-height': projects?.length > 5,
          })}
          maxHeight="calc(100vh - 450px)"
        >
          <Box className="daily-checkin-stepper__projects-list">
            {projects?.map(({ id, name, ...props }: any, i: number) => (
              <Button
                key={id}
                className={classnames('daily-checkin-stepper__project', {
                  active: id === activeProjectId || (!activeProjectId && !i),
                })}
                link
                onClick={() => {
                  filterBy({ projectId: { eq: id } });
                }}
              >
                {name}
              </Button>
            ))}
          </Box>
        </Scrollbars>
      ),
      buttonLabel: 'Confirm task selection',
      isButtonDisabled,
    },
    {
      label: 'Organise tasks',
      buttonLabel: 'Confirm check-in',
      isButtonDisabled,
    },
  ];

  const plannedInfo = [
    {
      label: 'Today’s allocation',
      value: `${+todayAllocation.toFixed(2)}h`,
      errorMessages: allocationErrorMessages,
    },
    {
      label: 'Today’s tasks',
      value: selectedIssues.length,
    },
  ];

  return (
    <>
      <DailyCheckinStepperLayout
        activeStep={activeStep}
        steps={steps}
        handleBack={handleBack}
        handleNext={handleNextStep}
        plannedInfo={plannedInfo}
      />
      <ModalWindow
        isOpen={isModalOpened}
        title="Are you sure?"
        buttons={[
          <Button key="confirmBtn" onClick={() => handleNext()}>
            Yes
          </Button>,
          <Button key="cancelBtn" outline onClick={() => setIsModalOpened(false)}>
            No
          </Button>,
        ]}
        onToggle={() => setIsModalOpened(false)}
        hasCancelButton={false}
      >
        <div>
          There {confirmationError && confirmationError.length <= 1 ? 'is a project' : 'are projects'} without
          allocations for today:
        </div>
        <Box component="ul" sx={{ m: 0, pl: '20px' }}>
          {confirmationError}
        </Box>
        <Box sx={{ mt: '12px' }}>
          Do you really want to set zero allocations for{' '}
          {confirmationError && confirmationError.length <= 1 ? 'it' : 'them'}?
        </Box>
      </ModalWindow>
    </>
  );
};

export default DailyCheckinStepper;
