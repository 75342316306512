import { useListContext } from '../ListContext';
import Loading from '../../Loading';
import React from 'react';

const LoadingListLayout = () => {
  const { loading } = useListContext();

  return loading ? <Loading /> : null;
};

export default LoadingListLayout;
