import { useLocation } from 'react-router-dom';

export const useSearchParameter = (key: string) => {
  const location = useLocation();

  const urlParams = location?.search && new URLSearchParams(location.search);

  return urlParams && urlParams.get(key);
};

/**
 * Returns map search key => search value. If there are multiple values, only last value will be returned.
 *
 * Example: ?test=1&test=2&davinci=3 =>
 * {
 *     "test": "2",
 *     "davinci": "3"
 * }
 *
 */
export const useSearchParameters = (): Dictionary<string> => {
  const location = useLocation();

  const urlParams = location?.search ? new URLSearchParams(location.search) : null;

  const result: Dictionary<string> = {};

  if (urlParams) {
    urlParams.forEach((value, key) => {
      result[key] = value;
    });
  }

  return result;
};

export const useSearchParametersString = () => {
  const location = useLocation();
  return location?.search || '';
};

/**
 * Returns map search key => list of search values.
 *
 * Example: ?test=1&test=2&davinci=3 =>
 * {
 *     "test": ["1", "2"],
 *     "davinci": "3"
 * }
 */
export const useSearchMultiParameters = (): Dictionary<List<string>> => {
  const location = useLocation();

  const urlParams = location?.search ? new URLSearchParams(location.search) : null;

  const result: Dictionary<List<string>> = {};

  if (urlParams) {
    urlParams.forEach((value, key) => {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
    });
  }

  return result;
};
