import useAsyncValue from 'shared/asyncHelper';
import { CROP_SIZES } from 'artifact/const';
import InputLoader from 'shared/uibuilder/InputLoader';
import React, { useState } from 'react';
import { any, func, objectOf, oneOfType, string } from 'prop-types';
import DownloadButton from './DownloadButton';
import FileName from './FileName';

const ImageLayout = ({ value, getThumbnail, handleClick, handleDownload }) => {
  const [loading, setLoading] = useState(false);

  const thumbnail = useAsyncValue(() => getThumbnail(value.id, CROP_SIZES.SIZE_100));

  const onClick = async () => {
    setLoading(true);
    await handleDownload();
    setLoading(false);
  };

  if (!value) {
    return null;
  }

  return (
    <>
      <button onClick={handleClick} type="button">
        {thumbnail ? (
          <>
            <span className="files-list__files-image">
              <img src={thumbnail} alt={value.title} title={value.title} id={value.id} data-testid="artifact-image" />
            </span>
            <FileName title={value.title} />
          </>
        ) : (
          <InputLoader className="files-list__files-loader" />
        )}
      </button>
      <DownloadButton onClick={onClick} loading={loading} />
    </>
  );
};

ImageLayout.propTypes = {
  value: oneOfType([objectOf(any), string]).isRequired,
  handleClick: func,
  getThumbnail: func,
  handleDownload: func,
};

ImageLayout.defaultProps = {
  handleClick: () => {},
  handleDownload: () => {},
  getThumbnail: () => {},
};

export default ImageLayout;
