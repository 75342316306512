/* istanbul ignore file */
import React from 'react';
import LeaveStatusField from 'erp/leave/shared/field/LeaveStatusField';
import { shape } from 'prop-types';

const BalancesLeavesStatusField = ({ value }) => {
  return <LeaveStatusField value={value.status} />;
};

BalancesLeavesStatusField.propTypes = {
  value: shape(),
};

BalancesLeavesStatusField.defaultProps = {
  value: {},
};

export default BalancesLeavesStatusField;
