import pdfIcon from 'shared/images/pdf.svg';
import audioIcon from 'shared/images/audio.svg';
import videoIcon from 'shared/images/video.svg';
import docIcon from 'shared/images/doc.svg';
import csvIcon from 'shared/images/csv.svg';
import defaultIcon from 'shared/images/default.svg';

const BYTES_IN_MB = 1048576;

const CROP_SIZES = {
  SIZE_100: 'crop_100x100',
  SIZE_200: 'crop_200x200',
  SIZE_500: 'crop_500x500',
};

const ARTIFACT_ID = {
  DOCUMENT_SCAN: 1,
  EMPLOYEE_PHOTO: 2,
  ATTACHMENT: 3,
  CONTRACT_SCAN: 4,
  ONE_STOP_ATTACHMENT: 5,
  NOTE_ATTACHMENT: 6,
  FEEDBACK_ATTACHMENT: 7,
  CRM_ATTACHMENT: 8,
  CANDIDATE_RESUME: 9,
  LEAVE_APPLICATION_SCAN: 10,
  CAREER_DEVELOPMENT_REVIEW_ATTACHMENT: 14,
  EMAIL_ATTACHMENT: 16,
  ARTIFACT_STORAGE_FILE: 17,
  BACKGROUND_CHECK_ATTACHMENT: 18,
  OPPORTUNITY_PRESALE_INPUT_ATTACHMENT: 19,
  OPPORTUNITY_PRESALE_OUTPUT_ATTACHMENT: 20,
};

const FILE_MIME = {
  AAC: {
    ext: 'aac',
    mime: ['audio/aac', 'audio/vnd.dlna.adts'],
  },
  DOC: {
    ext: 'doc',
    mime: ['application/msword'],
  },
  DOCX: {
    ext: 'docx',
    mime: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  },
  RTF: {
    ext: 'rtf',
    mime: ['application/rtf'],
  },
  CSV: {
    ext: 'csv',
    mime: ['text/csv'],
  },
  XLS: {
    ext: 'xls',
    mime: ['application/vnd.ms-excel'],
  },
  XLSX: {
    ext: 'xlsx',
    mime: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  },
  JPG: {
    ext: 'jpg',
    mime: ['image/jpg'],
  },
  JPEG: {
    ext: 'jpeg',
    mime: ['image/jpeg'],
  },
  MP3: {
    ext: 'mp3',
    mime: ['audio/mpeg', 'audio/mp3'], // 'audio/mp3' is used in chromium browsers
  },
  WAV: {
    ext: 'wav',
    mime: ['audio/wav'],
  },
  MP4: {
    ext: 'mp4',
    mime: ['video/mp4'],
  },
  MOV: {
    ext: 'mov',
    mime: ['video/quicktime'],
  },
  PDF: {
    ext: 'pdf',
    mime: ['application/pdf'],
  },
  PNG: {
    ext: 'png',
    mime: ['image/png'],
  },
  GIF: {
    ext: 'gif',
    mime: ['image/gif'],
  },
};

/* Connecting mime types to file categories */
const FILE_CATEGORY = {
  PDF: {
    icon: pdfIcon,
    mimeTypes: [...FILE_MIME.PDF.mime],
  },
  AUDIO: {
    icon: audioIcon,
    mimeTypes: [...FILE_MIME.MP3.mime, ...FILE_MIME.AAC.mime, ...FILE_MIME.WAV.mime],
  },
  VIDEO: {
    icon: videoIcon,
    mimeTypes: [...FILE_MIME.MP4.mime, ...FILE_MIME.MOV.mime],
  },
  DOC: {
    icon: docIcon,
    mimeTypes: [...FILE_MIME.DOC.mime, ...FILE_MIME.DOCX.mime, ...FILE_MIME.RTF.mime],
  },
  CSV: {
    icon: csvIcon,
    mimeTypes: [...FILE_MIME.XLS.mime, ...FILE_MIME.XLSX.mime, ...FILE_MIME.CSV.mime],
  },
  DEFAULT: {
    icon: defaultIcon,
    mimeTypes: [],
  },
  IMG: {
    icon: '',
    mimeTypes: [...FILE_MIME.JPG.mime, ...FILE_MIME.JPEG.mime, ...FILE_MIME.PNG.mime, ...FILE_MIME.GIF.mime],
  },
};

/* Connecting artifact types from API to file categories */
const API_FILE_TYPE = {
  image: {
    alias: 'image',
    category: FILE_CATEGORY.IMG,
  },
  pdf: {
    alias: 'pdf',
    category: FILE_CATEGORY.PDF,
  },
  mp3: {
    alias: 'mp3',
    category: FILE_CATEGORY.AUDIO,
  },
  aac: {
    alias: 'aac',
    category: FILE_CATEGORY.AUDIO,
  },
  wav: {
    alias: 'wav',
    category: FILE_CATEGORY.AUDIO,
  },
  docx: {
    alias: 'docx',
    category: FILE_CATEGORY.DOC,
  },
  doc: {
    alias: 'doc',
    category: FILE_CATEGORY.DOC,
  },
  rtf: {
    alias: 'rtf',
    category: FILE_CATEGORY.DOC,
  },
  mp4: {
    alias: 'mp4',
    category: FILE_CATEGORY.VIDEO,
  },
  csv: {
    alias: 'csv',
    category: FILE_CATEGORY.CSV,
  },
  xls: {
    alias: 'xls',
    category: FILE_CATEGORY.CSV,
  },
  xlsx: {
    alias: 'xlsx',
    category: FILE_CATEGORY.CSV,
  },
  default: {
    alias: 'default',
    category: FILE_CATEGORY.DEFAULT,
  },
};

export { BYTES_IN_MB, API_FILE_TYPE, CROP_SIZES, FILE_CATEGORY, FILE_MIME, ARTIFACT_ID };
