/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { APPLICATIONS_STAGES, APPLICATIONS_STAGES_ORDER } from 'erp/recruitment/applications/shared/applicationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import './ApplicationsStageField.scss';

const ApplicationStageField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const activeStage = getValue();
  const i = APPLICATIONS_STAGES_ORDER.findIndex(stage => stage === activeStage);

  return (
    <>
      <EnumField options={APPLICATIONS_STAGES} {...props} />
      <div className="application-stages__wrapper">
        {APPLICATIONS_STAGES_ORDER.map((stage, index) => (
          <div key={stage} className={`stage ${index < i ? 'completed' : ''} ${index === i ? 'in-progress' : ''}`} />
        ))}
      </div>
    </>
  );
};

export default ApplicationStageField;
