import React from 'react';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import useTimelineService from 'shared/crud/timelineService';
import CreateNoteForm from 'erp/employee/Timeline/note/form/CreateNoteForm';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { BigAvatar } from 'artifact';
import ProfileMenuItem from 'uibuilder/menuitem/ProfileMenuItem';
import SidebarMenuSubItem from 'shared/uibuilder/menu/SidebarMenuSubItem';
import TimelineMenuItem from 'uibuilder/menuitem/TimelineMenuItem';
import { BreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import EntityHeader from 'shared/layout/EntityHeader';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import useTestingCrudService from 'integration-tests/testingCrudService';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import { HtmlField } from 'shared/uibuilder/field';
import TimelineRecordFooter from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordFooter';
import MenuItem from '@mui/material/MenuItem';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';

const getValidationSchema = () =>
  Promise.resolve({
    noteText: {
      type: 'string',
      required: true,
      maxSize: 255,
    },
  });

const useMockTimeLineService = () => {
  const { search: searchTimelineItems, create: createTimelineItem, getTimelinePageById } = useTimelineService({
    parentEntityUrl: `mock`,
  });

  return {
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
  };
};

const menu = (
  <SidebarStickyMenu avatar={<BigAvatar source="mainPhotoId" />}>
    <ProfileMenuItem exact linkUrl="">
      <SidebarMenuSubItem name="Main Information" />
      <SidebarMenuSubItem name="Contact Information" />
      <SidebarMenuSubItem name="Skills" />
    </ProfileMenuItem>
    <TimelineMenuItem linkUrl="/timeline" />
  </SidebarStickyMenu>
);

const breadCrumbs = (
  <Breadcrumbs>
    <BreadcrumbItem link="#">John Black</BreadcrumbItem>
    <BreadcrumbItem>Timeline</BreadcrumbItem>
  </Breadcrumbs>
);

const registries = [
  {
    AddForm: CreateNoteForm,
    // eslint-disable-next-line react/prop-types
    UpdateForm: ({ getDataFunc, onCancel, ...props }) => {
      const { update } = useTestingCrudService();

      return (
        <UpdateForm
          getDataFunc={getDataFunc}
          submitFormFunc={update}
          getValidationSchemaFunc={getValidationSchema}
          {...props}
        >
          <UpdateTimelineItemLayout onCancel={onCancel}>
            <BigFormRow>
              <WysiwygInput source="noteText" />
            </BigFormRow>
          </UpdateTimelineItemLayout>
        </UpdateForm>
      );
    },
    isApplicable: item => item.note,
    source: 'note',
    urlPostfix: 'notes',
    // eslint-disable-next-line react/prop-types
    RecordLayout: ({ onEdit, ...props }) => {
      const { data } = useShowContext();
      const id = data.getValueBySource('id');
      const timelineId = data.getValueBySource('timelineId');
      const { deleteById } = useTestingCrudService();

      return (
        <BaseTimelineRecordLayout
          timelineType={TimelineType.NOTE}
          buttons={[
            <MenuItem onClick={onEdit}>Edit</MenuItem>,
            <DeleteTimelineRecordButton
              deleteMethod={deleteById}
              key="add"
              id={id}
              timelineId={timelineId}
              entityName="note"
              permissionToCheck={null}
            />,
          ]}
          fields={<HtmlField source="noteText" label="" />}
          timelineFooter={rest => <TimelineRecordFooter {...rest} isUpdateAuthorHidden />}
          {...props}
        />
      );
    },
    name: 'Note',
    label: 'Note',
  },
];

const entityHeader = <EntityHeader entityTitleSource="name.fullName" />;

const filters = (
  <Filters>
    <FiltersLayout>
      <DropDownFilter source="status" label="Status">
        <Dropdown
          placeholder="Choose Employee Working Status"
          options={Object.entries({
            ACTIVE: 'Active',
            IN_DISMISSAL: 'In Dismissal',
            DISMISSED: 'Dismissed',
            RELOCATION: 'Relocation',
          }).map(entry => {
            const [value, label] = entry;
            return {
              value,
              label,
            };
          })}
          label="Employee Working Status"
        />
      </DropDownFilter>
    </FiltersLayout>
  </Filters>
);

const IntegrationTimelinePage = () => {
  const { createTimelineItem, getTimelinePageById } = useMockTimeLineService();
  const { search, getById } = useTestingCrudService();

  return (
    <Show getDataMethod={() => getById(':id')}>
      <Timeline
        registries={registries}
        getDataMethod={search}
        createTimelineItemMethod={(itemType, request) => createTimelineItem(1, itemType, request)}
        getPageByEntryIdMethod={id => getTimelinePageById(1, id)}
      >
        <TimelinePageLayout menu={menu} breadcrumbs={breadCrumbs} entityHeader={entityHeader} filter={filters}>
          <TimelineLayout noEntriesMessage="No entries in this candidate timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default IntegrationTimelinePage;
