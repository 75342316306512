/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAssetUrl } from 'financialAnalytic/assets/AssetRouter';

const AssetBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useAssetUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Assets" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default AssetBreadcrumbs;
