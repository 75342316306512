/* istanbul ignore file */
import React, { useEffect } from 'react';
import { EXPENSE_SUBTYPES_ALIASES, EXPENSE_TYPES } from 'financialAnalytic/expenses/useExpenseService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const TYPES = EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION];
const TAX_TYPES = EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX];
const ONE_TIME_TYPES = EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT];

const MAIN_CURRENCIES: Dictionary<string> = { USD: 'USD', PLN: 'PLN' };
const EXPANDED_CURRENCIES: Dictionary<string> = { ...MAIN_CURRENCIES, BYN: 'BYN' };

const CURRENCY_TYPES: Dictionary<Dictionary<string>> = {
  [TYPES.MATERNITY_LEAVE]: EXPANDED_CURRENCIES,
  [TYPES.MILITARY_LEAVE]: MAIN_CURRENCIES,
  [TYPES.PAID_LEAVE]: MAIN_CURRENCIES,
  [TYPES.SICK_DAY]: MAIN_CURRENCIES,
  [TYPES.SICK_LEAVE]: EXPANDED_CURRENCIES,
  [TAX_TYPES.FSZN]: EXPANDED_CURRENCIES,
  [TAX_TYPES.BELGOSSTRAH]: EXPANDED_CURRENCIES,
  [ONE_TIME_TYPES.REIMBURSEMENTS]: EXPANDED_CURRENCIES,
};

const ExpenseCurrencyDropdown = ({
  source,
  typeSource,
  ...props
}: DefaultDropdownProps<Dictionary<string>> & { typeSource: string }) => {
  const { getValue: getType } = useInputHelper({ source: typeSource });
  const { getRawOnChangeCallback, getValue } = useInputHelper({ ...props, source });
  const type = getType() as string;
  const value = getValue() as string;
  const options = (type && CURRENCY_TYPES[type]) || MAIN_CURRENCIES;
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  useEffect(() => {
    if (!options[value]) {
      rawOnChangeCallback({
        [source]: null,
      });
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return <EnumDropdown source={source} options={options} placeholder="Select currency" {...props} />;
};

export default ExpenseCurrencyDropdown;
