import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useTrainingInstancesService from '../../useTrainingInstancesService';
import CreateTrainingInstanceBreadcrumbs from './CreateTrainingInstanceBreadcrumbs';
import { useTrainingInstancesUrl } from '../../TrainingInstancesRouter';
import TrainingInstanceForm from '../TrainingInstanceForm';

const CreateTrainingInstance = () => {
  const { getValidationSchema, create } = useTrainingInstancesService();
  const { getListUrl } = useTrainingInstancesUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Training instance has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Training Instance" breadcrumbs={<CreateTrainingInstanceBreadcrumbs />}>
        <TrainingInstanceForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateTrainingInstance;
