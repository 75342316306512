import React, { useEffect, useState } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

import { getAnchor } from 'shared/uibuilder/helper';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import InvalidSectionsMessage from 'uibuilder/form/InvalidSectionsMessage';
import Sticky from 'react-sticky-el';
import './FloatingButtons.scss';
import { bool, func } from 'prop-types';
import { scrollToHash } from 'shared/uibuilder/domUtils';

const isSectionInvalid = (formErrors, fieldSet) => {
  let isInvalid = false;
  if (fieldSet && fieldSet.outerHTML.includes('class="invalid-feedback"')) {
    return true;
  }

  const inputs = fieldSet.querySelectorAll('[name]');
  for (let j = 0; j < inputs.length; j += 1) {
    const name = inputs[j].getAttribute('name');

    const isInvalidInput = Object.entries(formErrors).some(entry => {
      const [source, errors] = entry;
      return source === name && errors && errors.length;
    });

    if (isInvalidInput) {
      isInvalid = true;
    }
  }

  return isInvalid;
};

/**
 * Big crutch. Traverse html dom tree, find sections and invalid inputs inside sections.
 */
export const getInvalidSections = formErrors => {
  const fieldsSets = document.getElementsByTagName('fieldset');

  const invalidSections = [];

  for (let i = 0; i < fieldsSets.length; i += 1) {
    const fieldSet = fieldsSets[i];
    const sectionTitleElement = fieldSet.getElementsByTagName('legend')[0];

    const sectionTitle =
      (sectionTitleElement && sectionTitleElement.innerText) || sectionTitleElement?.textContent || '';

    if (isSectionInvalid(formErrors, fieldSet)) {
      invalidSections.push({
        anchor: getAnchor(sectionTitle),
        title: sectionTitle,
      });
    }
  }

  return invalidSections;
};

const scrollToTheFirstSection = formErrors => {
  const sections = getInvalidSections(formErrors);

  if (sections && sections[0]) {
    const firstErrorSection = sections[0];
    const id = firstErrorSection.anchor;
    scrollToHash(document.getElementById(id));
  }
};

/**
 * @param shouldScrollToErrorAfterLoading [CRUTCH] We have few use cases when we should display errors and scroll
 * to the first error section after form is loaded. Example: lead conversion, employee finalization.
 * If shouldScrollToErrorAfterLoading is true - user will be scrolled to the first section.
 */
const FloatingButtons = ({ shouldScrollToErrorAfterLoading, isCancelButtonVisible, onCancelAction }) => {
  const { formErrors } = useFormContext();
  const [displayInvalidSections, setDisplayInvalidSection] = useState(shouldScrollToErrorAfterLoading);
  const [invalidSections, setInvalidSection] = useState([]);

  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const areErrorsFound = Object.keys(formErrors).length > 0;

    if (!isScrolled && shouldScrollToErrorAfterLoading && areErrorsFound) {
      scrollToTheFirstSection(formErrors);
      setScrolled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollToErrorAfterLoading, formErrors]);

  useEffect(() => {
    setInvalidSection(getInvalidSections(formErrors));
  }, [formErrors]);

  const messageComponent = (
    <InvalidSectionsMessage invalidSections={invalidSections} needToDisplay={displayInvalidSections} />
  );

  return (
    <Sticky mode="bottom" className="sticky-buttons-element">
      <div className="floating-btns">
        <FormButtonsLayout
          additionalOnSubmitAction={() => {
            setDisplayInvalidSection(true);
          }}
          messageComponent={messageComponent}
          isCancelButtonVisible={isCancelButtonVisible}
          onCancelAction={onCancelAction}
        />
      </div>
    </Sticky>
  );
};

FloatingButtons.propTypes = {
  shouldScrollToErrorAfterLoading: bool,
  isCancelButtonVisible: bool,
  onCancelAction: func,
};

FloatingButtons.defaultProps = {
  shouldScrollToErrorAfterLoading: false,
  isCancelButtonVisible: true,
  onCancelAction: null,
};

export default FloatingButtons;
