import { useEffect } from 'react';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService';
import useMeService, { Me } from 'erp/employee/meService';
import useAuthenticationService from 'authentication/authenticationService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useEventBus, { USER_IS_LOGGED_OUT } from 'shared/useEventBus';
import { isLinkExpired } from 'artifact/artifactService';
import useArtifactThumbnailService from 'artifact/artifactThumbnailService';

const DEFAULT_USER_DATA = {
  name: {
    firstName: '',
    lastName: '',
    maidenName: '',
  },
  avatarId: null,
  timezone: DEFAULT_TIMEZONE,
  alias: '',
  avatarThumbnails: {},
};

export interface AuthenticatedUserAction extends Action {
  user?: User;
  timezone?: Timezone;
  avatarThumbnails?: Dictionary<string>;
}

export interface AuthenticatedUserState {
  user: Nullable<User>;
}

export enum AuthenticatedUserActions {
  SAVE_USER = 'SAVE_USER',
  CLEAR_USER = 'CLEAR_USER',
  SET_TIMEZONE = 'SET_TIMEZONE',
  SET_AVATAR_THUMBNAILS = 'SET_AVATAR_THUMBNAILS',
}

const saveUser = (user: User) => ({
  type: AuthenticatedUserActions.SAVE_USER,
  user,
});

const setAvatarThumbnails = (avatarThumbnails: Dictionary<string>) => ({
  type: AuthenticatedUserActions.SET_AVATAR_THUMBNAILS,
  avatarThumbnails,
});

const clearUser = () => ({
  type: AuthenticatedUserActions.CLEAR_USER,
});

const initialState = {
  user: null,
};

export const authenticatedUserReducer = (
  state: AuthenticatedUserState = initialState,
  action: AuthenticatedUserAction,
) => {
  const { user, timezone, avatarThumbnails = {} } = action;

  if (action.type === AuthenticatedUserActions.SAVE_USER) {
    return {
      ...state,
      user,
    };
  }

  if (action.type === AuthenticatedUserActions.CLEAR_USER) {
    return {
      ...initialState,
    };
  }

  if (action.type === AuthenticatedUserActions.SET_TIMEZONE) {
    return {
      ...state,
      user: {
        ...state.user,
        timezone,
      },
    };
  }

  if (action.type === AuthenticatedUserActions.SET_AVATAR_THUMBNAILS) {
    return {
      ...state,
      user: {
        ...state.user,
        avatarThumbnails,
      },
    };
  }

  return state;
};

export type Timezone = string;

export type UserName = {
  firstName: string;
  lastName: string;
  maidenName: string;
};

export interface User {
  name: UserName;
  avatarId: Nullable<string>;
  timezone: Timezone;
  alias: string;
  avatarThumbnails?: Dictionary<string>;
}

export interface AuthenticatedUserService {
  getUserAlias: () => Promise<Nullable<string>>;
  getUserName: () => Promise<Nullable<UserName>>;
  getAvatarId: () => Promise<Nullable<string>>;
  getAvatarUrl: (size: string) => Promise<Nullable<string>>;
  getTimezone: () => Promise<Nullable<Timezone>>;
}

const isPromise = (value: any) => value && value.then && typeof value.then === 'function';

const useAuthenticatedUserService = (): AuthenticatedUserService => {
  const { addEventListener, removeEventListener } = useEventBus();
  const { getThumbnails } = useArtifactThumbnailService();
  const dispatch = useDispatch();
  const { getMe } = useMeService();
  const { addMessage } = useMessageService();
  const { isAuthenticated } = useAuthenticationService();
  const user = useSelector((state: { auth: AuthenticatedUserState }) => state?.auth?.user);
  let dataPromise: Promise<User>;

  const clearUserData = (): void => {
    dispatch(clearUser());
  };

  useEffect(() => {
    addEventListener(USER_IS_LOGGED_OUT, clearUserData);

    return () => {
      removeEventListener(USER_IS_LOGGED_OUT, clearUserData);
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestUserData = async (): Promise<User> => {
    let userData: User;

    try {
      const authUser: Me = await getMe();
      const { alias, timezone, mainPhotoId, nameEn: { firstName = '', lastName = '', maidenName = '' } = {} } =
        authUser?.info || {};
      const artifacts = authUser?.artifacts || {};

      userData = {
        name: {
          firstName,
          lastName,
          maidenName,
        },
        avatarThumbnails: artifacts[mainPhotoId]?.thumbnails || {},
        avatarId: mainPhotoId,
        timezone,
        alias,
      };
    } catch (error) {
      addMessage(new ErrorMessage('Something went wrong. Please, reload the page.'));

      userData = DEFAULT_USER_DATA;
    }

    dispatch(saveUser(userData));

    return userData;
  };

  const loadData = async () => {
    let response;

    if (isPromise(dataPromise)) {
      response = dataPromise;
    } else {
      response = requestUserData();
      dataPromise = response;
    }

    return response.then((result: User) => result);
  };

  const getUser = async (): Promise<Nullable<User>> => {
    const isUserAuthenticated = await isAuthenticated();

    if (!isUserAuthenticated) {
      return Promise.resolve(null);
    } else if (user?.alias) {
      return Promise.resolve(user);
    } else {
      return loadData();
    }
  };

  const getAvatarId = async (): Promise<Nullable<string>> => {
    const userData = await getUser();

    return userData?.avatarId || null;
  };

  const getAvatarUrl = async (size: string): Promise<Nullable<string>> => {
    const userData = await getUser();
    const avatarId = userData?.avatarId || null;

    if (!avatarId) {
      return null;
    }

    const artifact = userData?.avatarThumbnails;
    const url = artifact && artifact[size];

    if (!url || isLinkExpired(url)) {
      const thumbnails = await getThumbnails(avatarId);

      dispatch(setAvatarThumbnails(thumbnails));

      return thumbnails[size];
    } else {
      return url;
    }
  };

  const getUserAlias = async (): Promise<Nullable<string>> => {
    const userData = await getUser();

    return userData?.alias || null;
  };

  const getUserName = async (): Promise<Nullable<UserName>> => {
    const userData = await getUser();

    return userData?.name || null;
  };

  const getTimezone = async (): Promise<Nullable<Timezone>> => {
    const userData = await getUser();

    return userData?.timezone || null;
  };

  return {
    getUserName,
    getAvatarId,
    getUserAlias,
    getTimezone,
    getAvatarUrl,
  };
};

export default useAuthenticatedUserService;
