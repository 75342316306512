/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import PresaleRequestFields from 'crm/opportunity/presale/request/PresaleRequestTimelineRecordLayout/PresaleRequestFields';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import RedmineIssueField from 'redmine/RedmineIssueField';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { UPDATE_PRESALE_REQUEST } from 'crm/opportunity/shared/opportunityService';
import PresaleStatusBadge from 'crm/opportunity/presale/request/shared/field/PresaleStatusBadge';

const PresaleRequestTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { data } = useShowContext();

  const buttons = [<EditTimelineRecordButton onEdit={onEdit} permissionToCheck={UPDATE_PRESALE_REQUEST} />];

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <PresaleRequestFields />
        </Collapsible>
      }
      entity={data}
      timelineType={TimelineType.FEEDBACK}
      buttons={buttons}
      actions={[]}
      timelineFooter={() => (
        <div className="timeline__additional d-flex">
          <span>Redmine ticket:&nbsp;</span>
          <RedmineIssueField source="redmineTicketId" />
          <PresaleStatusBadge statusSource="requestStatus" />
        </div>
      )}
      {...props}
    />
  );
};

export default PresaleRequestTimelineRecordLayout;
