/* istanbul ignore file */
import React from 'react';
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid';

export interface GridProps extends MuiGridProps {}

const Grid = (props: GridProps) => {
  return <MuiGrid {...props} />;
};

export default Grid;
