import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import React from 'react';
import classnames from 'classnames';
import shortid from 'shortid';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { BaseFieldLayoutType, FieldLayoutProps } from 'shared/uibuilder/field';

interface FieldListLayoutProps extends FieldLayoutProps<object[]> {
  children: React.ReactElement;
  source?: string;
  isVisible?: boolean;
  isRowDirection?: boolean;
  hasSeparator?: boolean;
}

const FieldListLayout = ({ children, isRowDirection, hasSeparator = false, ...props }: FieldListLayoutProps) => {
  const { getValue } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const arr = getValue() || [];

  const content = arr.map(val => (
    <p
      key={shortid.generate()}
      className={classnames('field__item-wrapper', { 'field__item-wrapper--with-separator': hasSeparator })}
    >
      {React.cloneElement(children, { value: val, label: '' })}
    </p>
  ));

  const value = !!content.length && content;

  return (
    <BaseFieldLayout
      {...props}
      value={isRowDirection && arr.length ? <div className="row-list-field">{value}</div> : value}
    />
  );
};

export default FieldListLayout;
