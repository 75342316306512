import React from 'react';

type FileNameProps = {
  title: string;
};

const FileName = ({ title }: FileNameProps) => {
  const indexOfStartFormatName = title.lastIndexOf('.');
  const fileName = title.slice(0, indexOfStartFormatName);
  const type = title.slice(indexOfStartFormatName);

  return (
    title && (
      <>
        <span title={title} className="files-list__files-name" data-testid="artifact-name">
          {fileName}
        </span>
        <span className="files-list__files-type" data-testid="artifact-type">
          {type}
        </span>
      </>
    )
  );
};

export default FileName;
