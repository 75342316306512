/* istanbul ignore file */
import React from 'react';
import ProjectBreadcrumbs from 'erp/project/shared/ProjectBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateProjectBreadcrumbs = () => {
  return (
    <ProjectBreadcrumbs>
      <CreateBreadcrumbItem entity="Project" />
    </ProjectBreadcrumbs>
  );
};

export default CreateProjectBreadcrumbs;
