import journalEntryValidation from 'financialAnalytic/journalEntry/createupdate/journalEntryValidation';
import journalEntryFilterValidation from 'financialAnalytic/journalEntry/List/journalEntryFilterValidation';
import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import useStorageService from 'shared/uibuilder/storageService';
import useJournalEntriesSettings from 'financialAnalytic/journalEntry/shared/Settings/useJournalEntriesSettings';
import useSingletonPromise from 'shared/useSingletonPromise';
import useEmployeeService from 'erp/employee/employeeService';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import { CONTENT_TYPE } from 'shared/api/const';

export const READ_JOURNAL_ENTRY_LIST = 'COMPANY_READ_USER_LEDGER';
export const READ_JOURNAL_ENTRY = 'COMPANY_READ_USER_LEDGER';
export const CREATE_JOURNAL_ENTRY = 'COMPANY_POST_LEDGER';
export const JOURNAL_ENTRY_PATH = '/journal-entry';
export const RESOURCE_URL = '/ledger/journal-entries';

const STORAGE_KEY = 'exchange-rates';
const VERSION = '1.1';

export const officeRequiredAccounts = [
  'Assets/Tangible assets/Office facilities',
  'Expenses/Services/Individual equipment',
];
export const employeeRequiredAccounts = ['Liabilities/Payroll', 'Expenses/Payroll', 'Expenses/Finance/Payroll'];
export const employeeNotRequiredAccounts = ['Liabilities/Payroll/Employee expenses/Company payroll liabilities'];
export const projectRequiredAccounts = ['Expenses/Service delivery/Projects'];

export const isRequiredField = (transfers: any[], requiredAccounts: string[]) =>
  transfers.some(
    (transfer: any) => transfer.accountId && requiredAccounts.some(account => transfer.accountId?.startsWith(account)),
  );

const useJournalEntryService = () => {
  const { areStornedJournalEntriesVisible } = useJournalEntriesSettings();
  const { sendPostRequest, sendGetRequest } = useFinanceApi();
  const { getFullName } = useEmployeeService();
  const { getEmployeeNameDataById } = useEmployeeNamesService();

  const { search: initialSearch, getById: initialGetById } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const { getDataFromStorage, setDataToStorage } = useStorageService(STORAGE_KEY, () => ({}), VERSION);

  const search = useSingletonPromise('search-ledger-accounts', async ({ filter = {} }: any = {}) => {
    let result = await initialSearch({
      ...filter,
      period: {
        fromDate: filter?.period?.ge,
        toDate: filter?.period?.le,
      },
      employeeAlias: filter?.employeeAlias?.eq,
      accountsIds: filter?.accountsIds,
      postedBy: filter?.postedBy?.eq,
      offices: filter?.offices?.in,
      includeTransfers: !!filter?.includeTransfers,
    });

    if (!areStornedJournalEntriesVisible) {
      const stornedEntriesIds = result.reduce((acc: string[], item: any) => {
        if (item.revertedOriginalJournalEntryId !== null) {
          acc.push(item.revertedOriginalJournalEntryId);
        }
        return acc;
      }, []);

      result = result.filter(
        (item: any) => item.revertedOriginalJournalEntryId === null && !stornedEntriesIds.includes(item.id),
      );
    }

    return {
      result,
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const searchTransfers = async (params: any) => {
    const journalEntries = await search(params);

    const result = journalEntries.result.flatMap(
      ({ transfers, description }: { description: string; transfers: { accountId: string }[] }) => {
        if (params?.filter?.accountsIds || params?.filter?.businessObjectType) {
          return transfers.reduce((acc: any[], transfer: any) => {
            if (params.filter?.accountsIds?.includes(transfer.accountId) || params.filter?.businessObjectType) {
              acc.push({ ...transfer, description: transfer.description || description });
            }

            return acc;
          }, []);
        }

        return transfers;
      },
    );

    return {
      result,
      totalElements: result.length,
      totalPages: 1,
    };
  };

  const formatJournalEntry = async (journalEntry: Dictionary<any>) => {
    let createdByName = null;
    let employeeName = null;

    try {
      createdByName = await getEmployeeNameDataById(journalEntry.postedBy);

      if (journalEntry?.attributes?.employeeAlias) {
        employeeName = await getEmployeeNameDataById(journalEntry.attributes.employeeAlias);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    // TODO add project name from suggest request after updating on BE
    return {
      id: journalEntry.id,
      asDateOf: journalEntry.asDateOf,
      totals: 0,
      description: journalEntry.description,
      transfers: journalEntry.transfers,
      attributes: {
        ...(journalEntry.attributes || {}),
        employeeName: employeeName ? getFullName(employeeName.nameEn) : '',
      },
      createdAt: journalEntry.postedAt,
      createdByName: createdByName ? getFullName(createdByName.nameEn) : '',
    };
  };

  const mappingRequestData = ({ description, asDateOf, transfers, attributes = {}, project = {} }: Dictionary<any>) => {
    const jeAttributes = { ...attributes };

    let projectAttributes = { ...project };

    if (projectAttributes.projectId === 'no-specific-project') {
      projectAttributes = {};
    }

    return {
      description,
      asDateOf,
      transfers: transfers.map(
        ({ accountId, debit, credit, attributes: transferAttributes = {}, description: transferDescription }: any) => ({
          accountId,
          attributes: { ...projectAttributes, ...jeAttributes, ...transferAttributes },
          description: transferDescription,
          debit: debit || 0,
          credit: credit || 0,
          asDateOf,
        }),
      ),
      attributes: {
        ...jeAttributes,
        ...projectAttributes,
      },
    };
  };

  const getById = async (id: string) => {
    const journalEntry = await initialGetById(id);

    return formatJournalEntry(journalEntry);
  };

  const getJournalEntryByTransferId = async (transferId: string) => {
    const response = await sendGetRequest(`${RESOURCE_URL}/by-transfer/${transferId}`);
    const journalEntry = await response.json();

    return formatJournalEntry(journalEntry);
  };

  const getReversedEntryData = async (id: string) => {
    const data = await getById(id);

    return {
      ...data,
      description: `Storno: ${data.description}`,
      transfers: data.transfers.map((item: any) => ({
        ...item,
        credit: item.debit,
        debit: item.credit,
      })),
    };
  };

  const create = async (data: any) => {
    const dataForRequest = mappingRequestData(data);
    await sendPostRequest(RESOURCE_URL, dataForRequest);
    return {};
  };

  const revokeJournalEntry = async (id: string) => {
    await sendPostRequest(`${RESOURCE_URL}/${id}/storno`);
    return {};
  };

  const update = async (id: string, data: any) => {
    await revokeJournalEntry(id);
    await create(data);
    return {};
  };

  const getExchangeRates = async (date: string) => {
    try {
      const requestedDate = date.trim();
      const savedData = await getDataFromStorage();

      if (savedData[requestedDate]) {
        return savedData[requestedDate];
      }

      const response = await sendGetRequest(`/ledger/exchange-rates?date=${requestedDate}`);
      const result = await response.json();

      setDataToStorage({ ...savedData, [requestedDate]: result });

      return result;
    } catch (e) {
      return {};
    }
  };

  const uploadJournalEntry = async (data: FormData) => {
    const res = await sendPostRequest(`/expenses/upload`, data, CONTENT_TYPE.MULTIPART);
    return res.json();
  };

  const submitListOfJournalEntry = async (data: any[]) => {
    return sendPostRequest(`${RESOURCE_URL}/multiple`, { journalEntries: data });
  };

  const getValidationSchema = () => Promise.resolve(journalEntryValidation);

  const getFilterValidationSchema = () => Promise.resolve(journalEntryFilterValidation);

  return {
    update,
    search,
    getById,
    create,
    getValidationSchema,
    getFilterValidationSchema,
    getExchangeRates,
    getReversedEntryData,
    searchTransfers,
    revokeJournalEntry,
    getJournalEntryByTransferId,
    uploadJournalEntry,
    submitListOfJournalEntry,
    mappingRequestData,
    formatJournalEntry,
  };
};

export default useJournalEntryService;
