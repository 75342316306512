import React, { useEffect, useState } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getWithThousandsSeparator, convertCurrency } from 'shared/uibuilder/amountUtils';
import { get, isEqual } from 'lodash';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import BaseInputErrors from 'uibuilder/layout/form/input/BaseInputErrors';
import './TransferListInputTotal.scss';

const getTotal = (data: any[], source: string, exchangeRates: any = {}) =>
  data.reduce((acc: number, item: any) => {
    const { currency } = item;
    const value = get(item, source) || 0;
    const exchangeRate = exchangeRates?.[currency]?.USD || 1;

    return +acc + +(currency && currency !== 'USD' ? convertCurrency(value, exchangeRate) : +value);
  }, 0);

const TransferListInputTotal = ({ transfersSource, exchangeRatesSource }: any) => {
  const { getValue: getTransfers } = useInputHelper({
    source: transfersSource,
  });
  const { getValue: getExchangeRates } = useInputHelper({
    source: exchangeRatesSource,
  });
  const { getValue, getSource, getRawOnChangeCallback, getErrorMessages, getValidationCallback } = useInputHelper({
    source: 'totals',
  });
  const transfers = (getTransfers() as any) || [];
  const exchangeRates = getExchangeRates() as any;
  const value = getValue() as any;
  const rawOnChangeCallback = getRawOnChangeCallback();
  const validationCallback = getValidationCallback();
  const source = getSource();
  const [lastUsedExchangeRates, setLastUsedExchangeRates] = useState(exchangeRates);
  const debitTotal = getTotal(transfers, 'debit', lastUsedExchangeRates);
  const creditTotal = getTotal(transfers, 'credit', lastUsedExchangeRates);
  const errors = getErrorMessages();

  useEffect(() => {
    if (rawOnChangeCallback && exchangeRates?.date) {
      rawOnChangeCallback({
        [source]: debitTotal !== creditTotal ? null : debitTotal,
      });
    }
    // Suppressed warnings because we only need to call useEffect callback after updating debitTotal and creditTotal.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitTotal, creditTotal]);

  useDidUpdateEffect(() => {
    if (validationCallback) {
      validationCallback();
    }
  }, [value]);

  useEffect(() => {
    if (!isEqual(lastUsedExchangeRates, exchangeRates)) {
      setLastUsedExchangeRates(exchangeRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfers]);

  return (
    <div className="journal-entry-total-wrapper">
      <span className="journal-entry-total-title">Total:</span>
      <div>
        <span className="journal-entry-total-label">Total Debit: </span>
        {getWithThousandsSeparator(debitTotal || 0)}$
      </div>
      <div>
        <span className="journal-entry-total-label">Total Credit: </span>
        {getWithThousandsSeparator(creditTotal || 0)}$
      </div>
      <BaseInputErrors errorMessages={errors} />
    </div>
  );
};

export default TransferListInputTotal;
