/* istanbul ignore file */
import React from 'react';
import useInputHelper from './inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import {
  DefaultInputPropTypes,
  DefaultInputLayoutProps,
  InputLayoutHintProps,
  InputLayoutStateProps,
  ValidationLayoutProps,
} from 'shared/uibuilder/form/input';
import { FormFieldsData } from '../FormContext';

type CheckboxButtonOption = {
  value: string;
  label: string | JSX.Element;
};

export interface CommonCheckboxGroupProps {
  options: CheckboxButtonOption[];
  isColumnDirection?: boolean;
  checkedValues?: string[];
}

export interface CheckboxGroupProps extends CommonCheckboxGroupProps, DefaultInputPropTypes<string | number> {}

export interface CheckboxGroupLayoutProps
  extends CommonCheckboxGroupProps,
    DefaultInputLayoutProps,
    InputLayoutHintProps,
    InputLayoutStateProps,
    ValidationLayoutProps {
  onChangeCallback?: Nullable<(values: FormFieldsData, isFieldInitializing?: boolean) => void>;
  onBlurCallback?: () => void;
  onFocusCallback?: () => void;
}

export type CheckboxGroupLayoutType = ReactComponent<CheckboxGroupLayoutProps>;

const CheckboxGroup = ({ isColumnDirection = false, ...props }: CheckboxGroupProps) => {
  const inputHelper = useInputHelper(props);
  const { CheckboxGroupLayout } = useUiTheme<CheckboxGroupLayoutType>();

  return (
    <CheckboxGroupLayout
      {...props}
      isVisible={inputHelper.isVisible()}
      disabled={inputHelper.getIsDisabled()}
      className={inputHelper.getClassName()}
      isColumnDirection={isColumnDirection}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getRawOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue() as string}
      isRequired={inputHelper.getIsRequired()}
      errorMessages={inputHelper.getErrorMessages()}
    />
  );
};

export default CheckboxGroup;
