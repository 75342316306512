/* istanbul ignore file */
// libraries
import React from 'react';
// components
import { CreateButton } from 'uibuilder/button';
// services
import { useCompanyRolesUrl, UPDATE_EMPLOYEE_COMPANY_ROLES } from '../../shared/companyRolesService';

const AddCompanyRoleToEmployeeButton = () => {
  const { getCreateUrl } = useCompanyRolesUrl();

  return (
    <CreateButton
      variant="contained"
      label="Add role"
      url={getCreateUrl()}
      permissionToCheck={UPDATE_EMPLOYEE_COMPANY_ROLES}
    />
  );
};

export default AddCompanyRoleToEmployeeButton;
