/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { EMPLOYEE_CONTRACT_TYPES } from '../../../employee/contracts/shared/input/EmployeeContractTypeDropdown';

const ContractTypeField = props => {
  return <EnumField {...props} options={EMPLOYEE_CONTRACT_TYPES} />;
};

export default ContractTypeField;
