/* istanbul ignore file */
import { Theme } from '@mui/material/styles';
import { violetTheme } from './violetTheme';

export const themeCreator = (theme: string): Theme => {
  return themeMap[theme];
};

const themeMap: { [key: string]: Theme } = {
  violetTheme,
};

export default themeCreator;
