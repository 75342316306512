import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useTrainingInstancesUrl } from '../../TrainingInstancesRouter';

const ViewTrainingInstancesButton = (props: any) => {
  const { getSingleEntityUrl } = useTrainingInstancesUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-training-entry" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewTrainingInstancesButton;
