import React from 'react';
import { ButtonsSelectInputLayoutType } from 'shared/uibuilder/form/input/ButtonsSelectInput';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }: any) => ({
  marginBottom: '24px',
  marginTop: '8px',
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderLeftColor: theme.vars.palette.neutral[70],
  },
  '& .MuiToggleButton-root': {
    '&.Mui-selected': {
      pointerEvents: 'none',
      color: theme.vars.palette.primary[10],
      background: theme.vars.palette.primary[95],
      paddingLeft: '13px',
      paddingRight: '15px',
    },
    borderColor: theme.vars.palette.neutral[70],
    textTransform: 'none',
    color: theme.vars.palette.neutral[10],
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 500,
    padding: '10px 20px',
  },
}));

/**
 * Layout that displays button (and buttons have keys) and only one button can be selected.
 * @param options - options
 * @param value - selected value
 * @param createOnChangeCallback - function that accept key and return callback
 * (this callback will be called without parameters when user click on the button)
 * @param disabled - boolean value for disabling input if need
 */
const ButtonsSelectInputLayout: ButtonsSelectInputLayoutType = ({
  options = [],
  value,
  createOnChangeCallback,
  disabled,
}) => {
  return (
    <StyledToggleButtonGroup color="secondary" value={value} exclusive>
      {options.map(item => {
        const { label, value: key } = item;

        return (
          <ToggleButton
            data-testid={key}
            key={key}
            value={key}
            aria-label={key.toString()}
            onClick={createOnChangeCallback(key)}
            disabled={disabled}
          >
            {key === value && <DoneIcon sx={{ marginRight: '10px', width: '18px', height: '18px' }} />}
            {label}
          </ToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};

export default ButtonsSelectInputLayout;
