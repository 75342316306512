/* istanbul ignore file */
import React from 'react';
import ProfileMenuItem from 'uibuilder/menuitem/ProfileMenuItem';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import SidebarMenuSubItem from 'shared/uibuilder/menu/SidebarMenuSubItem';
import { bool } from 'prop-types';
import TimelineMenuItem from 'uibuilder/menuitem/TimelineMenuItem';
import useAuthorization from 'shared/authorization/authorizationService';
import { CANDIDATE_CONTEXT } from 'erp/candidate/CandidateContext';
import {
  READ_COMMUNICATION,
  READ_LIST_CANDIDATE_APPLICATIONS,
  READ_LIST_CANDIDATE_OFFER,
  READ_TIMELINE,
} from 'erp/candidate/shared/candidateService';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import useFeatureToggle, { Features } from 'featuretoggle';

const CandidateMenu = ({ isEditForm }) => {
  const { getProfileUrl, getTimelineUrl, getSingleEntityUrl } = useCandidateUrl();

  const { isGranted: globalIsGranted } = useAuthorization();

  const isGranted = permission => globalIsGranted(permission, CANDIDATE_CONTEXT);
  const { isFeatureEnabled } = useFeatureToggle();

  return (
    <>
      <SidebarStickyMenu>
        <ProfileMenuItem linkUrl={`${getProfileUrl()}${isEditForm ? '/edit' : ''}`} exact>
          <SidebarMenuSubItem name="Main Information" />
          <SidebarMenuSubItem name="Contact Information" />
          <SidebarMenuSubItem name="Skills" />
        </ProfileMenuItem>
        <TimelineMenuItem linkUrl={getTimelineUrl()} isVisible={isGranted(READ_TIMELINE)} exact />
        <SidebarMenuItem
          linkUrl={`${getSingleEntityUrl()}/offers`}
          name="Offers"
          icon="briefcase"
          isVisible={isFeatureEnabled(Features.CANDIDATE_OFFER_COMPENSATION) && isGranted(READ_LIST_CANDIDATE_OFFER)}
        />
        <SidebarMenuItem
          linkUrl={`${getSingleEntityUrl()}/communication`}
          name="Communication"
          icon="envelope-o"
          isVisible={isGranted(READ_COMMUNICATION)}
        />
        <SidebarMenuItem
          linkUrl={`${getSingleEntityUrl()}/applications`}
          name="Applications"
          icon="file-o"
          isVisible={isGranted(READ_LIST_CANDIDATE_APPLICATIONS)}
        />
      </SidebarStickyMenu>
    </>
  );
};

CandidateMenu.propTypes = {
  isEditForm: bool,
};

CandidateMenu.defaultProps = {
  isEditForm: false,
};

export default CandidateMenu;
