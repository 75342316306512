/* istanbul ignore file */
import classnames from 'classnames';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';
import { Request } from 'oneStop/oneStopService';
import React from 'react';
import { Link } from 'react-router-dom';
import { SearchResultField } from 'shared/uibuilder/field';
import Typography from 'uibuilder/Typography';

type OneStopSearchResultsProps = {
  row: Request;
  index: number;
  focusedResultIndex: Nullable<Number>;
  searchResultsRefs: HTMLAnchorElement[];
  onKeyUp: (event: any, index: number) => void;
  setFocusToResults: () => void;
};

const OneStopSearchResults = ({
  row,
  index,
  onKeyUp,
  searchResultsRefs,
  focusedResultIndex,
  setFocusToResults,
}: OneStopSearchResultsProps) => {
  const { getSingleEntityUrl } = useOneStopRequestsUrl();

  return (
    <Link
      key={row.id}
      className={classnames('onestop-search-item', { focus: focusedResultIndex === index })}
      to={getSingleEntityUrl(row.id)}
      onKeyUp={event => onKeyUp(event, index)}
      onFocus={setFocusToResults}
      innerRef={link => {
        if (link) {
          // eslint-disable-next-line no-param-reassign
          searchResultsRefs[index] = link;
        }
      }}
    >
      <Typography variant="h2" className="c-title onestop-search-title">
        <SearchResultField source="title" value={(item: any) => `${item.id}. ${item.title}`} />
      </Typography>
      <SearchResultField source="category" value={(item: any) => `Category: ${item.sectionTitle}`} />
      {row.description && (
        <SearchResultField
          source="description"
          value={(item: any) => `Description: ${item.shortDescription || item.description}`}
        />
      )}
    </Link>
  );
};

export default OneStopSearchResults;
