/* istanbul ignore file */
import React from 'react';
import OpportunitiesBreadcrumbs from 'crm/opportunity/shared/OpportunitiesBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateOpportunityBreadcrumbs = () => (
  <OpportunitiesBreadcrumbs>
    <CreateBreadcrumbItem entity="Opportunity" />
  </OpportunitiesBreadcrumbs>
);

export default CreateOpportunityBreadcrumbs;
