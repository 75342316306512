/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import SingleProjectBreadcrumbs from 'erp/project/shared/SingleProjectBreadcrumbs';
import { useProjectWeeklyReportsUrl } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';

const ProjectWeeklyReportsBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useProjectWeeklyReportsUrl();
  const projectWeeklyReportsListLink = getListUrl();

  return (
    <SingleProjectBreadcrumbs>
      <ListBreadcrumbItem entity="Weekly Report" link={projectWeeklyReportsListLink} />
      {children}
    </SingleProjectBreadcrumbs>
  );
};

export default ProjectWeeklyReportsBreadcrumbs;
