/* istanbul ignore file */
import React from 'react';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import { Dropdown } from 'shared/uibuilder/form/input';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const DEFAULT_OPTIONS = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

const BooleanFilter = (props: Omit<DefaultDropdownProps, 'value'>) => {
  return (
    <EqCondition {...props}>
      <Dropdown options={DEFAULT_OPTIONS} placeholder="Select" {...props} />
    </EqCondition>
  );
};

export default BooleanFilter;
