/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ProjectQueues from 'syberryToday/shared/ProjectQueues';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import useSyberryTodayService from 'syberryToday/useSyberryTodayService';
import List from 'shared/uibuilder/list/List';
import { useSyberryTodayProjectsId, useSyberryTodayProjectsUrl } from 'syberryToday/projects/SyberryTodayProjectRouter';
import Alert from 'uibuilder/Alert';
import SyberryTodayMenu from 'syberryToday/shared/SyberryTodayMenu/SyberryTodayMenu';
import ListPageLayoutWithSidebar from 'shared/uibuilder/list/layout/ListPageLayoutWithSidebar';

const SyberryTodayProjectQueues = () => {
  const { isDataLoaded, projects } = useSyberryTodayContext();
  const { getProjectDataWithoutParentAndRecurrently } = useSyberryTodayService();
  const id = useSyberryTodayProjectsId();
  const { getSingleEntityUrl } = useSyberryTodayProjectsUrl();
  const { replace } = useHistory();

  useEffect(() => {
    if (!id && projects[0]?.id) {
      replace(getSingleEntityUrl(projects[0].id));
    }
    // Suppressed warnings because we only need to call useEffect callback after updating projects and id.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, id]);

  if (!isDataLoaded) {
    return null;
  }

  if (!projects?.length && !id) {
    return (
      <ListPageLayoutWithSidebar sidebar={<SyberryTodayMenu />}>
        <Alert severity="info">You don&apos;t have any projects</Alert>
      </ListPageLayoutWithSidebar>
    );
  }

  return (
    <ListPageLayoutWithSidebar sidebar={<SyberryTodayMenu />}>
      <List
        key={id || projects[0].id}
        getDataMethod={() => getProjectDataWithoutParentAndRecurrently(id || projects[0].id)}
      >
        <div className="daily-checkin__wrapper">
          <div className="daily-checkin__content daily-checkin__content--planned">
            <ProjectQueues canAddIssues canEnterAllocations={false} />
          </div>
        </div>
      </List>
    </ListPageLayoutWithSidebar>
  );
};

export default SyberryTodayProjectQueues;
