/* istanbul ignore file */
import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const TimelineRecordFieldLayout = ({ value = null, label }) => {
  return (
    value && (
      <p className="mb-0">
        {label ? <b>{label}: </b> : null}
        {value}
      </p>
    )
  );
};

TimelineRecordFieldLayout.propTypes = {
  value: oneOfType([string, number]),
  label: string,
};

TimelineRecordFieldLayout.defaultProps = {
  value: undefined,
  label: undefined,
};

export default TimelineRecordFieldLayout;
