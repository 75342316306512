/* istanbul ignore file */
import React from 'react';
import VerticalAddressInputLayout from 'uibuilder/layout/form/input/VerticalAddressInputLayout';
import { AddressInput } from 'shared/uibuilder/form/input/index';
import { AddressInputProps } from './AddressInput';

const VerticalAddressInput = (props: AddressInputProps) => {
  return (
    <VerticalAddressInputLayout>
      <AddressInput {...props} />
    </VerticalAddressInputLayout>
  );
};

export default VerticalAddressInput;
