/* istanbul ignore file */
import React from 'react';
import useInvoiceService, { APPROVE_INVOICE, INVOICE_STATUSES } from 'financialAnalytic/invoice/useInvoiceService';
import { useParams } from 'react-router-dom';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ApproveInvoiceButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { approveInvoice, invalidateCache } = useInvoiceService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const status = getValue();

  if (status !== INVOICE_STATUSES.PENDING) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to approve this invoice?"
      submitMethod={() => approveInvoice(id)}
      afterSubmit={{
        successMessage: 'The Invoice has been successfully approved.',
        errorMessage: "Can't approve invoice.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={APPROVE_INVOICE}
      variant="outlined"
    >
      Approve
    </ButtonWithConfirmation>
  );
};

export default ApproveInvoiceButton;
