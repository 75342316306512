/* istanbul ignore file */
import React from 'react';
import { RoutingSwitch, Route } from 'shared/routing';
import { useIntegrationTestRoutes } from 'integration-tests/Router';
import IntegrationShowPage from 'shared/uibuilder/show/__integration-tests__/ShowPage';
import IntegrationTimelinePage from 'shared/uibuilder/timeline/__integration-tests__/TimelinePage';
import IntegrationListPage from 'shared/uibuilder/list/__integration-tests__/ListPage';
import IntegrationCreateFormPage from 'shared/uibuilder/form/__integration-tests__/CreateFormPage';
import IntegrationUpdateFormPage from 'shared/uibuilder/form/__integration-tests__/UpdateFormPage';

const IntegrationCrudRouter = () => {
  const { singleEntityRoute, createRoute, updateRoute, listRoute, timelineRoute } = useIntegrationTestRoutes();

  return (
    <RoutingSwitch>
      <Route path={timelineRoute} component={IntegrationTimelinePage} />
      <Route path={createRoute} exact component={IntegrationCreateFormPage} />
      <Route path={updateRoute} exact component={IntegrationUpdateFormPage} />
      <Route path={listRoute} exact component={IntegrationListPage} />
      <Route path={singleEntityRoute} exact component={IntegrationShowPage} />
    </RoutingSwitch>
  );
};

export default IntegrationCrudRouter;
