/* istanbul ignore file */
import React from 'react';
import VacanciesBreadcrumbs from 'erp/recruitment/vacancy/shared/VacanciesBreadcrumbs';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CreateVacancyBreadcrumbs = () => {
  return (
    <VacanciesBreadcrumbs>
      <CreateBreadcrumbItem entity="Vacancy" />
    </VacanciesBreadcrumbs>
  );
};

export default CreateVacancyBreadcrumbs;
