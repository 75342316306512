/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import {
  RBS_PATH,
  READ_RBS_TREE_NODE,
  CREATE_RBS_TREE_NODE,
  UPDATE_RBS_TREE_NODE,
  READ_RBS_TREE_NODES_LIST,
  CREATE_QUEUE,
} from 'erp/rbs/shared/rbsService';
import RbsList from 'erp/rbs/List';
import ViewRbsNode from 'erp/rbs/Show';
import RbsContext from 'erp/rbs/RbsContext';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CreateRbsNode, UpdateRbsNode } from 'erp/rbs/createupdate';
import useAuthorization from 'shared/authorization/authorizationService';
import { useSearchParameter } from 'shared/routing/helper';

export const useRbsRoute = () => {
  return useRoute({ listRoute: RBS_PATH });
};

export const useRbsUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: RBS_PATH,
  });

  const getCreateUrl = (params?: Dictionary<string>) => {
    const urlParameters = new URLSearchParams(params).toString();

    return `${baseUrls.getCreateUrl()}${urlParameters ? `?${urlParameters}` : ''}`;
  };

  return {
    ...baseUrls,
    getCreateUrl,
  };
};

export const useRbsId = () => {
  const { id } = useParams<Dictionary<string>>();

  return id;
};

const RbsRouter = () => {
  const { listRoute, createRoute, singleEntityRoute, updateRoute } = useRbsRoute();
  const { isGranted } = useAuthorization();
  const parentNodeId = useSearchParameter('parentNodeId');

  return (
    <ProtectedRouter basePath={RBS_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_RBS_TREE_NODES_LIST}>
          <RbsList />
        </GlobalProtectedRoute>
        {parentNodeId ? (
          <Route path={createRoute} exact>
            <RbsContext permissionToCheck={isGranted(CREATE_RBS_TREE_NODE) ? null : CREATE_QUEUE} id={parentNodeId}>
              <CreateRbsNode isGrantedCreateProject={isGranted(CREATE_RBS_TREE_NODE)} />
            </RbsContext>
          </Route>
        ) : (
          <GlobalProtectedRoute path={createRoute} globalPermissionToCheck={CREATE_RBS_TREE_NODE} exact>
            <CreateRbsNode isGrantedCreateProject={isGranted(CREATE_RBS_TREE_NODE)} />
          </GlobalProtectedRoute>
        )}
        <Route path={singleEntityRoute} exact>
          <RbsContext permissionToCheck={READ_RBS_TREE_NODE}>
            <ViewRbsNode />
          </RbsContext>
        </Route>
        <Route path={updateRoute} exact>
          <RbsContext permissionToCheck={UPDATE_RBS_TREE_NODE}>
            <UpdateRbsNode />
          </RbsContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

RbsRouter.getRouterPath = () => RBS_PATH;

export default RbsRouter;
