/* istanbul ignore file */
import React, { memo } from 'react';
import GroupedApplicationsListGroup from 'erp/recruitment/applications/List/GroupedApplicationList/GroupedApplicationsListGroup';
import { useListContext } from 'shared/uibuilder/list/ListContext';

interface GroupedApplicationsListProps {
  index: number;
  stage: string;
}

const GroupedApplicationsList = memo(({ stage, index }: GroupedApplicationsListProps) => {
  const { filter, search } = useListContext();

  return <GroupedApplicationsListGroup stage={stage} index={index} filter={filter} search={search} />;
});

export default GroupedApplicationsList;
