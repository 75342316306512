/* istanbul ignore file */
import React from 'react';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from 'uibuilder/button/Button';
import Check from '@mui/icons-material/Check';
import './Stepper.scss';

const StepIcon = ({ icon, completed, className }: { icon: React.ReactNode; completed: boolean; className: string }) => (
  <div className={className}>{completed ? <Check /> : icon}</div>
);

type StepProps = {
  label: string;
  buttonLabel?: string;
  description?: React.ReactNode;
  isButtonDisabled?: boolean;
};

export interface StepperProps {
  activeStep: number;
  steps: StepProps[];
  handleNext: () => void;
  handleBack: () => void;
}

const Stepper = ({ steps, activeStep, handleNext, handleBack }: StepperProps) => {
  return steps.length ? (
    <MuiStepper activeStep={activeStep} orientation="vertical" className="stepper">
      {steps.map(step => (
        <Step key={step.label}>
          <StepLabel StepIconComponent={StepIcon}>{step.label}</StepLabel>
          <StepContent>
            {step.description}
            <div className="stepper__buttons-wrapper">
              <Button onClick={handleNext} className="stepper__next-button" disabled={step.isButtonDisabled}>
                {step.buttonLabel}
              </Button>
              {activeStep ? (
                <Button className="stepper__back-button" link onClick={handleBack}>
                  Back
                </Button>
              ) : null}
            </div>
          </StepContent>
        </Step>
      ))}
    </MuiStepper>
  ) : null;
};

export default Stepper;
