import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import InterviewsList from 'erp/interview/List';
import { INTERVIEW_PATH, READ_INTERVIEWS_LIST } from 'erp/interview/shared/interviewService';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import Page404 from 'shared/uibuilder/Page404';

export const useInterviewRoute = () => {
  return useRoute({ listRoute: INTERVIEW_PATH });
};

export namespace Interview {
  export const Router = () => {
    const { listRoute } = useInterviewRoute();
    return (
      <ProtectedRouter basePath={INTERVIEW_PATH}>
        <Switch>
          <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_INTERVIEWS_LIST}>
            <InterviewsList />
          </GlobalProtectedRoute>
          <Route component={Page404} />
        </Switch>
      </ProtectedRouter>
    );
  };

  export const getRouterPath = () => INTERVIEW_PATH;
}

export default Interview;
