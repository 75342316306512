/* istanbul ignore file */
import React from 'react';
import useGeneratedPayrollsService, {
  MARK_AS_PAID_PAYROLL_REPORT,
  PAYROLL_REPORT_STATUSES,
} from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import ButtonWithConfirmationDateInput from 'financialAnalytic/shared/button/ButtonWithConfirmationDateInput';
import useAsyncValue from 'shared/asyncHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

const MarkAsPaidPayrollReportButton = ({ statusSource }: { statusSource: string }) => {
  const { markAsPaidPayrollReport } = useGeneratedPayrollsService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { data } = useShowContext();
  const { updateInList = () => {} } = useListContext();
  const status = getValue();
  const contextData = data.getData() || {};
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(getUserAlias);

  if (status !== PAYROLL_REPORT_STATUSES.APPROVED) {
    return null;
  }

  return (
    <ButtonWithConfirmationDateInput
      displayMessage="Do you really want to approve this payroll report? Please, fill out the input below."
      submitMethod={(date: string) => markAsPaidPayrollReport(contextData.id as string, date)}
      afterSubmit={{
        successMessage: 'The payroll report has been successfully marked as paid.',
        errorMessage: "Can't mark this payroll report as paid.",
        execute: () => {
          updateInList({ ...contextData, status: PAYROLL_REPORT_STATUSES.PAID, markedAsPaidBy: userAlias } as ListItem);
        },
      }}
      permissionToCheck={MARK_AS_PAID_PAYROLL_REPORT}
      variant="outlined"
      inputLabel="Date"
      sx={{ whiteSpace: 'nowrap' }}
    >
      Mark as paid
    </ButtonWithConfirmationDateInput>
  );
};

export default MarkAsPaidPayrollReportButton;
