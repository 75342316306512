/* istanbul ignore file */
import React from 'react';
import useAssignmentService, { AssignmentType } from 'erp/assignment/shared/assignmentService';
import AssignmentForm from 'erp/assignment/createupdate/AssignmentForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateAssignmentBreadcrumbs from 'erp/assignment/createupdate/Create/Breadcrumbs';
import { useAssignmentUrl, useAssignmentId } from 'erp/assignment/AssignmentRouter';

const CreateAssignment = () => {
  const { create, getValidationSchema, getAssignmentById } = useAssignmentService();
  const { getListUrl } = useAssignmentUrl();
  const id = useAssignmentId();
  let getDataFunc;

  if (id) {
    getDataFunc = () => getAssignmentById(id);
  }

  return (
    <CreateForm
      getDataFunc={getDataFunc}
      initialData={{
        assignmentType: AssignmentType.INDIVIDUAL_ASSIGNMENT,
      }}
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Assignment has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Assignment" breadcrumbs={<CreateAssignmentBreadcrumbs />}>
        <AssignmentForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateAssignment;
