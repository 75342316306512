import React from 'react';
import {
  AccountsCreationResponse,
  AzureAccountCreationResponse,
  AzureGroupsAssignmentStatus,
  DaVinciAccountModificationStatus,
  DaVinciProfileTarget,
  LicenseAssignmentResponse,
  RedmineAccountCreationStatus,
  RedmineGroupsAssignmentStatus,
} from '../externalAccountsService';
import useMessageService, { ErrorMessage, SuccessMessage, WarningMessage } from 'shared/message/messageService';

export interface StatusContext {
  errors: any[];
  successes: any[];
  instructions: any[];
}

const useCreationResponseHandler = () => {
  const { addMessage } = useMessageService();

  const getAzureUserProfileUrl = (userId: string) => {
    return `https://admin.microsoft.com/AdminPortal/Home#/users/:/UserDetails/${userId}`;
  };
  const getAzureUserLicencesUrl = (userId: string) => {
    return `https://admin.microsoft.com/AdminPortal/Home#/users/:/UserDetails/${userId}/LicensesAndAppserId`;
  };

  const processAzureAccountCreationResponse = (
    accountCreation: AzureAccountCreationResponse,
    groupsAssignment: AzureGroupsAssignmentStatus,
    davinciAccountModification: DaVinciAccountModificationStatus,
    privateNoteUrl: string,
    targetProfile: DaVinciProfileTarget,
    status: StatusContext,
  ) => {
    if (accountCreation.userId) {
      status.successes.push(
        <li key="success-azure-acc">
          Successfully created{' '}
          <a href={getAzureUserProfileUrl(accountCreation.userId)} target="_blank" rel="noopener noreferrer">
            user account in Azure
          </a>{' '}
          (User ID: <b className="select-all-text">{accountCreation.userId}</b>).
          {groupsAssignment.attemptedToModifyGroups &&
            ` Current user groups: ${groupsAssignment.currentJoinedGroups.map(group => `'${group}'`).join(', ')}`}
        </li>,
      );

      status.instructions.push(
        <li key="instruction-privnote">
          Copy{' '}
          <a target="_blank" rel="noopener noreferrer" href={privateNoteUrl}>
            one-time link
          </a>{' '}
          with Office 365 password.
        </li>,
      );

      if (groupsAssignment.errors && groupsAssignment.errors.length > 0) {
        groupsAssignment.errors.forEach(error => status.errors.push(<li key="error-redmine-groups">{error}</li>));
      }
    }

    const hasAccountCreationErrors = accountCreation.errors && accountCreation.errors.length > 0;
    if (hasAccountCreationErrors) {
      status.errors.push(
        <li key="error-azure-acc">
          {accountCreation.errors.join(' ')}
          <br />
          Consider changing <i>Syberry Email</i> in this form or freeing existing <i>Email</i> and <i>Login</i> in Azure
          and try again.
        </li>,
      );
    }

    if (davinciAccountModification.attemptedModifyAccount) {
      if (davinciAccountModification.errors && davinciAccountModification.errors.length > 0) {
        const autoFilledSupportDescription = encodeURIComponent(
          `${
            targetProfile.employeeId
              ? `Employee: ${targetProfile.employeeId}`
              : `Candidate: ${targetProfile.candidateId}`
          }` +
            `\nNew Azure ID: ${accountCreation.userId}` +
            `\nErrors: ${davinciAccountModification.errors.join('\n')}`,
        );
        status.instructions.push(
          <li key="instruction-edit-profile">
            Failed to update DaVinci profile. Please
            <a
              href={`/one-stop/requests/SUP01?description=${autoFilledSupportDescription}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              contact DaVinci support (using this link).
            </a>
          </li>,
        );
      } else {
        status.successes.push(<li key="success-azure-dv">Successfully updated DaVinci profile.</li>);
      }
    }
  };

  const processLicenseAssignmentResponse = (
    userId: string | undefined,
    licenseAssignment: LicenseAssignmentResponse,
    status: StatusContext,
  ) => {
    const hasLicenseAssignmentErrors = licenseAssignment.errors && licenseAssignment.errors.length > 0;
    if (licenseAssignment.attemptedToAssignLicense && !hasLicenseAssignmentErrors) {
      status.successes.push(<li key="success-azure-licence">Office license has been successfully added.</li>);
    }
    if (hasLicenseAssignmentErrors && userId) {
      status.errors.push(
        <li key="error-azure-licence">
          {licenseAssignment.errors.join(' ')}
          <br />
          Consider purchasing new Office 365 license and assigning it manually:{' '}
          <a href={getAzureUserLicencesUrl(userId)} target="_blank" rel="noopener noreferrer">
            assign licence
          </a>{' '}
        </li>,
      );
    }
  };

  const processRedmineAccountCreationResponse = (
    accountCreation: RedmineAccountCreationStatus,
    groupsAssignment: RedmineGroupsAssignmentStatus,
    status: StatusContext,
  ) => {
    if (accountCreation.userId) {
      status.successes.push(
        <li key="success-redmine-acc">
          Successfully created{' '}
          <a href={accountCreation.url} target="_blank" rel="noopener noreferrer">
            user account in Redmine
          </a>{' '}
          (User ID: <b className="select-all-text">{accountCreation.userId}</b>).
          {groupsAssignment.attemptedToModifyGroups &&
            ` Current user groups: ${groupsAssignment.currentJoinedGroups.map(group => `'${group}'`).join(', ')}`}
        </li>,
      );

      if (groupsAssignment.errors && groupsAssignment.errors.length > 0) {
        groupsAssignment.errors.forEach(error => status.errors.push(<li key="error-redmine-groups">{error}</li>));
      }
    } else {
      status.errors.push(
        <li key="error-redmine-acc">
          {accountCreation.errors.join(', ')}
          <br />
          Consider changing <i>Syberry Email</i> in this form or freeing existing <i>Email</i> and <i>Login</i> in
          Redmine and try again.
        </li>,
      );
    }
  };

  const handleCreationResponse = (state: AccountsCreationResponse, targetProfile: DaVinciProfileTarget) => {
    const statusContext: StatusContext = {
      successes: [],
      errors: [],
      instructions: [],
    };

    if (state.createAzureAccountResponse?.accountCreation) {
      processAzureAccountCreationResponse(
        state.createAzureAccountResponse.accountCreation,
        state.createAzureAccountResponse.groupsAssignment,
        state.createAzureAccountResponse.davinciAccountModification,
        state.createAzureAccountResponse.privateNoteUrl,
        targetProfile,
        statusContext,
      );
    }
    if (state.createAzureAccountResponse?.licenseAssignment) {
      processLicenseAssignmentResponse(
        state.createAzureAccountResponse?.accountCreation?.userId,
        state.createAzureAccountResponse?.licenseAssignment,
        statusContext,
      );
    }
    if (state.createRedmineAccountResponse) {
      processRedmineAccountCreationResponse(
        state.createRedmineAccountResponse?.accountCreation,
        state.createRedmineAccountResponse?.groupsAssignment,
        statusContext,
      );
    }

    const { errors, successes, instructions } = statusContext;
    const statusMessage = (
      <>
        {successes}
        {errors}
        <hr className="mb-0" />
        {instructions}
      </>
    );
    if (errors.length > 0 && successes.length > 0) {
      addMessage(new WarningMessage(statusMessage));
    } else if (errors.length > 0) {
      addMessage(new ErrorMessage(statusMessage));
    } else {
      addMessage(new SuccessMessage(statusMessage));
    }
  };

  return {
    handleCreationResponse,
  };
};

export default useCreationResponseHandler;
