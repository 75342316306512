/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import { arrayOf, element, oneOfType } from 'prop-types';

const LeavesBreadcrumbs = ({ children }) => {
  const { getListUrl } = useLeaveUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Leaves" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

LeavesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

LeavesBreadcrumbs.defaultProps = {
  children: null,
};

export default LeavesBreadcrumbs;
