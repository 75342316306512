import React from 'react';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { TRAINING_INSTANCE_STATUS_OPTIONS } from '../../useTrainingInstancesService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const TrainingInstancesStatusFilter = ({ ...props }: BaseFilterProps & { label: string }) => {
  return (
    <DropDownFilter {...props}>
      <EnumDropdown options={TRAINING_INSTANCE_STATUS_OPTIONS} {...props} />
    </DropDownFilter>
  );
};

export default TrainingInstancesStatusFilter;
