import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useHiringUrl } from '../HiringRouter';

const HiringBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useHiringUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Hiring requests" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default HiringBreadcrumbs;
