/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import NoteForm from 'financialAnalytic/assets/Timeline/note/form/NoteForm';
import useAssetNoteService from 'financialAnalytic/assets/Timeline/note/assetNoteService';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';

const CreateNoteForm = ({
  submitFormFunc,
  onCancel,
  ...props
}: {
  onCancel?: () => void;
} & FormTemplateProps) => {
  const { getValidationSchema } = useAssetNoteService();
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateNoteForm;
