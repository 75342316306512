import { useEffect, useState } from 'react';

export type InputData = {
  items: any[];
  totalPages: number;
  totalElements?: number;
};

export interface ListResponse<T> {
  result: T[];
  pageNumber: number;
  totalPages: number;
}

interface CrudHelperParams {
  onRemove?: (params: any) => void;
  inputData?: InputData;
}

const defaultData = {
  items: [],
  totalPages: 0,
  totalElements: 0,
};

const useListCrud = ({ onRemove, inputData }: CrudHelperParams = {}) => {
  const [data, setData] = useState<InputData>(inputData || defaultData);

  useEffect(() => {
    if (inputData) {
      setData(inputData);
    }
  }, [inputData]);

  const deleteFromList = (id: StringOrNumber) => {
    setData(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== id),
    }));
    if (typeof onRemove === 'function') {
      onRemove(id);
    }
  };

  const updateInList = (updatedEntity: any) => {
    setData(prev => ({
      ...prev,
      items: prev.items.map(item => {
        if (updatedEntity.id === item.id) {
          return updatedEntity;
        } else {
          return item;
        }
      }),
    }));
  };

  const addToList = (entity: any) => {
    setData(prev => ({
      ...prev,
      items: [entity, ...prev.items],
    }));
  };

  return {
    data,
    setData,
    deleteFromList,
    updateInList,
    addToList,
  };
};

export default useListCrud;
