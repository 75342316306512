import React from 'react';
import TextInput from './TextInput';
import useUiTheme from 'shared/uibuilder/useUiTheme';

export interface NameInputProps {
  source: string;
  firstNameLabel?: string;
  lastNameLabel?: string;
  middleNameLabel?: string;
  maidenNameLabel?: string;
  label?: string;
  isVisible?: boolean;
}

export type NameInputLayoutType = ReactComponent<{
  label: string;
  children: React.ReactElement[];
}>;

/**
 * Component is used to display the controls for the form for name entering.
 */
const NameInput = ({
  source,
  firstNameLabel,
  lastNameLabel,
  middleNameLabel,
  maidenNameLabel,
  label = '',
  isVisible = true,
}: NameInputProps) => {
  const { NameInputLayout } = useUiTheme<NameInputLayoutType>();

  const firstNameSource = `${source}.firstName`;
  const lastNameSource = `${source}.lastName`;
  const middleNameSource = `${source}.middleName`;
  const maidenNameSource = `${source}.maidenName`;

  return (
    isVisible && (
      <NameInputLayout label={label}>
        <TextInput source={firstNameSource} label={firstNameLabel} data-testid="firstName" />
        <TextInput source={lastNameSource} label={lastNameLabel} data-testid="lastName" />
        <TextInput source={middleNameSource} label={middleNameLabel} data-testid="middleName" />
        <TextInput source={maidenNameSource} label={maidenNameLabel} data-testid="maidenName" />
      </NameInputLayout>
    )
  );
};

export default NameInput;
