import React, { useEffect, useState } from 'react';
import { Button } from 'uibuilder/button';
import { Filter } from 'shared/uibuilder/list/filter/FilterContext';
import useLeadService from 'crm/lead/shared/leadService';
import InputLoader from 'shared/uibuilder/InputLoader';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const LeadNotificationButton = ({
  getLabel,
  state,
  styles = {},
  isLoading: initialIsLoading = false,
  onClick: clickHandler,
  loadingDataCallback,
  sx,
  loaderClassNames,
  setIsLoadingCallback,
  ...props
}: {
  getLabel: (data: any) => string;
  state: Filter;
  className?: string;
  styles?: Dictionary<any>;
  isLoading?: boolean;
  color?: BasicColors;
  loadingDataCallback: (count: number) => void;
  onClick: (state: Filter) => void;
  loaderClassNames?: string;
  sx?: SxProps<Theme>;
  setIsLoadingCallback: (value: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [label, setLabel] = useState('');
  const { search } = useLeadService() as any;

  const loadData = async () => {
    const response = (await search({
      filter: state,
      sort: { updatedAt: 'ASC' },
      pageSize: 1,
    })) as any;

    setIsLoading(false);
    setIsLoadingCallback(false);
    setLabel(getLabel(response));

    loadingDataCallback(response.totalElements || 0);
  };

  useEffect(() => {
    if (!initialIsLoading) {
      setIsLoadingCallback(true);
      loadData();
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIsLoading]);

  const onClick = () => {
    clickHandler(state);
  };

  if (isLoading) {
    return <InputLoader className={loaderClassNames} />;
  }

  return (
    <Button
      outline
      disableFocusRipple
      onClick={onClick}
      variant="contained"
      type="button"
      sx={{
        ...styles,
        width: '100%',
        color: 'var(--mui-palette-text-primary)',
        borderColor: 'var(--mui-palette-text-primary)',
        '&:hover': {
          color: 'var(--mui-palette-neutral-99)',
          ...(styles['&:hover'] || {}),
        },
        '&.Mui-disabled': {
          color: 'var(--mui-palette-text-primary)',
          borderColor: 'var(--mui-palette-text-primary)',
          pointerEvents: 'initial',
          cursor: 'not-allowed',
          ...(styles['&.Mui-disabled'] || {}),
        },
        ...sx,
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default LeadNotificationButton;
