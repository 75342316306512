/* istanbul ignore file */
import React from 'react';
import { NumberInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

interface CompletenessInputProps {
  label?: string;
  source: string;
  isDisabled: boolean | ((values: FormFieldsData) => boolean);
}

const CompletenessInput = ({ label, isDisabled, ...props }: CompletenessInputProps) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper<Object>(props);
  const onChangeCallback = getRawOnChangeCallback();
  const source = getSource();
  const rowId = source.substring(source.lastIndexOf('.') + 1);
  const listSource = source.substring(0, source.lastIndexOf('.'));

  const requirement = getValue() || {};

  const completenessSource = `${source}.completeness`;

  const completenessOnChangeCallback = (obj: any) => {
    if (onChangeCallback) {
      onChangeCallback({
        [source]: {
          ...requirement,
          completeness: obj[completenessSource],
        },
      });
    }
  };

  return (
    <NumberInput
      source={completenessSource}
      errorSource={`${listSource}[${rowId}].completeness`}
      label={label}
      onChangeCallback={completenessOnChangeCallback}
      disabled={isDisabled}
    />
  );
};

export default CompletenessInput;
