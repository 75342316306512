/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';
import AccountViewPage from '.';
import { useAccountId } from '../AccountRouter';
import useAccountService from '../accountService';

const ViewAccountPage = () => {
  const id = useAccountId();
  const { getById, getOriginalName } = useAccountService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout
        loadSensitiveDataMethod={getOriginalName}
        sensitiveDataId={id}
        sensitiveDataIdSource="accountId"
      >
        <AccountViewPage />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default ViewAccountPage;
