/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import TrainingsBreadcrumbs from '../../shared/TrainingsBreadcrumbs';

const CreateTrainingBreadcrumbs = () => {
  return (
    <TrainingsBreadcrumbs>
      <CreateBreadcrumbItem entity="Training" />
    </TrainingsBreadcrumbs>
  );
};

export default CreateTrainingBreadcrumbs;
