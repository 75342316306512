import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { arrayOf, element, string } from 'prop-types';
import usePageTitle from 'pageTitle/pageTitle';

const PageTitle = ({ children, allowedSubParts, specificSubParts }) => {
  const location = useLocation();
  const { setPageTitle } = usePageTitle();

  const pathParts = useMemo(() => {
    let result = location.pathname.split('/');
    result.shift();

    /*
    if url has more than 1 part, for example: /one-stop/LGL03, and we need to display this name
    we need to filter for allowed sub-parts and get name (LGL03)
    result will be: LGL03 - DaVinci
    */
    result = result.length > 1 ? result.filter(part => !allowedSubParts.find(item => item === part)) : result;

    /*
    if url start with this specific part, for example: /qualification-models/latest
    we need display this part in title
    result will be: Qualification Model - DaVinci
    */
    result[0] = specificSubParts.some(item => result.includes(item)) ? result.shift() : result[0];

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /*
    when path has only one part (for example /one-stop, /employees, on these pages we dont have breadcrumbs,
    so we need to generate page title from this part.
   */
  useEffect(() => {
    if (pathParts.length === 1) {
      const nameParts = pathParts[0].split('-');
      let result = '';

      if (nameParts.length > 1) {
        result = nameParts.reduce((res, item) => `${res} ${item}`, '');
      } else {
        [result] = nameParts;
      }

      setPageTitle(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParts]);

  return children;
};

PageTitle.propTypes = {
  children: element,
  allowedSubParts: arrayOf(string),
  specificSubParts: arrayOf(string),
};

PageTitle.defaultProps = {
  children: null,
  allowedSubParts: [],
  specificSubParts: [],
};

export default PageTitle;
