/* istanbul ignore file */
import React, { useEffect } from 'react';
import Show from 'shared/uibuilder/show/Show';
import usePurchaseOrderService from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { usePurchaseOrderId, usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import { SensitiveShowElementLayout } from 'shared/uibuilder/sensitiveData';
import ViewPurchaseOrderPageContent from './ViewPurchaseOrderPageContent';

const PurchaseOrderViewPage = () => {
  const { getById, getSensitiveDataByIds, invalidateCache } = usePurchaseOrderService();
  const id = usePurchaseOrderId();
  const { getListUrl } = usePurchaseOrderUrl();

  useEffect(() => {
    return () => {
      invalidateCache();
    };

    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Show getDataMethod={() => getById(id)}>
      <SensitiveShowElementLayout
        loadSensitiveDataMethod={() => getSensitiveDataByIds([id])}
        sensitiveDataId={id}
        sensitiveDataIdSource="id"
        timerId={getListUrl()}
      >
        <ViewPurchaseOrderPageContent />
      </SensitiveShowElementLayout>
    </Show>
  );
};

export default PurchaseOrderViewPage;
