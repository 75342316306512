/* istanbul ignore file */
import React from 'react';
import { FILTER_TABS, FilterSection } from 'oneStop/oneStopService';
import OneStopDefaultFilterItem, { SectionProps } from 'oneStop/List/OneStopDefaultFilterItem';

type OneStopFilterLayoutProps = {
  sections: FilterSection[];
  sectionProps: SectionProps;
};

type RecentFilterItemProps = {
  section: FilterSection;
};

const OneStopFilterLayout = ({ sections, sectionProps }: OneStopFilterLayoutProps) => {
  const RecentFilterItem = ({ section }: RecentFilterItemProps) => {
    const classes = 'mb-2 text-info';

    return <OneStopDefaultFilterItem isIconVisible classes={classes} section={section} sectionProps={sectionProps} />;
  };

  return (
    <ul className="onestop-categories nav-tabs">
      {sections.map((section: FilterSection) => {
        const { id } = section;
        if (id === FILTER_TABS.RECENT) {
          return <RecentFilterItem key={id} section={section} />;
        } else {
          return <OneStopDefaultFilterItem key={id} sectionProps={sectionProps} section={section} />;
        }
      })}
    </ul>
  );
};

export default OneStopFilterLayout;
