/* istanbul ignore file */
import EntityHeader from 'shared/layout/EntityHeader';
import React from 'react';
import useEmployeeService, {
  EMPLOYEE_TYPES_ALIAS,
  READ_EMPLOYEE_INITIALIZATION_STATE,
  READ_EMPLOYEE_MAIN_PERSONAL_INFO,
} from 'erp/employee/employeeService';
import NewBadge from 'shared/uibuilder/badge/NewBadge';
import { EmailField } from 'shared/uibuilder/field';
import EmployeePositionField from 'erp/employee/shared/field/EmployeePositionField';
import useAuthorization from 'shared/authorization/authorizationService';
import { EMPLOYEE_CONTEXT } from 'erp/employee/EmployeeContext';
import ContractorBadge from 'shared/uibuilder/badge/ContractorBadge';
import ActiveProbationBadge from 'shared/uibuilder/badge/ActiveProbationBadge';
import InternBadge from 'shared/uibuilder/badge/InternBange';

const EmployeeEntityHeader = () => {
  const { getFullName, checkIsNewEmployee } = useEmployeeService();
  const { isGranted: globalIsGranted } = useAuthorization();
  const isGranted = permission => globalIsGranted(permission, EMPLOYEE_CONTEXT);

  return (
    <EntityHeader
      entityTitle={employee =>
        getFullName({
          firstName: employee.getValueBySource('nameEn.firstName'),
          lastName: employee.getValueBySource('nameEn.lastName'),
          maidenName: employee.getValueBySource('nameEn.maidenName'),
        })
      }
      badges={employee => [
        ...(checkIsNewEmployee(employee.getValueBySource('initializationState')) &&
        isGranted(READ_EMPLOYEE_INITIALIZATION_STATE) &&
        employee.getValueBySource('employeeType') !== EMPLOYEE_TYPES_ALIAS.INTERN
          ? [<NewBadge />]
          : [null]),
        ...(employee.getValueBySource('employeeType') === EMPLOYEE_TYPES_ALIAS.CONTRACTOR
          ? [<ContractorBadge />]
          : [null]),
        ...(employee.getValueBySource('employeeType') === EMPLOYEE_TYPES_ALIAS.INTERN ? [<InternBadge />] : [null]),
        ...(employee.getValueBySource('probation.onProbation') ? [<ActiveProbationBadge />] : [null]),
      ]}
      hasAuthor={false}
    >
      <EmployeePositionField source="positionId" label="" className="entity-header__employee-position" />
      {isGranted(READ_EMPLOYEE_MAIN_PERSONAL_INFO) && (
        <EmailField source="workingEmail" className="entity-header__employee-email" label="" />
      )}
    </EntityHeader>
  );
};

export default EmployeeEntityHeader;
