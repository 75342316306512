/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useLedgerUrl } from 'financialAnalytic/ledger/LedgerRouter';

const LedgerBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useLedgerUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Ledger" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default LedgerBreadcrumbs;
