import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_LOAD_ADD_REQUEST, PAGE_LOAD_START, RequestType, PageLoadState } from 'shared/audit/pageLoad';

const pathBlackList = ['/thumbnails', '/visit', '/audit/roles', '/audit/permissions', 'camunda/metrics'];

const shouldBeExcluded = (requestUrl: string) => {
  return pathBlackList.some(path => requestUrl.includes(path));
};

const usePageLoad = () => {
  const dispatch = useDispatch();
  const { startTime, endTime, counterActive } = useSelector((state: { pageLoad: PageLoadState }) => state.pageLoad);

  const loadInProgress = startTime && !endTime;

  const start = () => {
    dispatch({ type: PAGE_LOAD_START });
  };

  const addRequest = (request: RequestType) => {
    if (shouldBeExcluded(request.name)) return null;

    return dispatch({ type: PAGE_LOAD_ADD_REQUEST, request });
  };

  const initObserver = () => {
    const handleRequsets = (list: PerformanceObserverEntryList) => {
      // eslint-disable-next-line
      for (const entry of list.getEntries() as PerformanceResourceTiming[]) {
        if (entry.initiatorType === 'fetch') {
          addRequest({
            name: entry.name,
            responseEnd: window.performance.timing.navigationStart + entry.responseEnd,
            startTime: window.performance.timing.navigationStart + entry.startTime,
          });
        }
      }
    };

    return new PerformanceObserver(handleRequsets);
  };

  const getLoadTime = useCallback(() => {
    if (loadInProgress || (!startTime && !endTime)) return null;

    return counterActive ? endTime - startTime : 0;
  }, [loadInProgress, startTime, endTime, counterActive]);

  return { addRequest, initObserver, start, getLoadTime };
};

export default usePageLoad;
