/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import VacancyStatusDropdown from 'erp/recruitment/vacancy/shared/input/VacancyStatusDropdown';

const VacancyStatusFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <VacancyStatusDropdown />
    </DropDownFilter>
  );
};

export default VacancyStatusFilter;
