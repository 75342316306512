/* istanbul ignore file */
import React from 'react';
import { Request } from 'oneStop/oneStopService';
import OneStopRequest from 'oneStop/List/OneStopRequest';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useOneStopSettings from 'oneStop/List/Settings/useOneStopSettings';
import Fade from 'uibuilder/Fade';

const OneStopListLayout = () => {
  const { areDescriptionsVisible } = useOneStopSettings();
  const { data: { items = [] } = {} } = useListContext();

  return (
    <div className="onestop-requests-container">
      {items.length > 0 ? (
        items
          .filter(item => item && item.id)
          .map((item: Request) => (
            <OneStopRequest key={item.id} isDescriptionVisible={areDescriptionsVisible} {...item} />
          ))
      ) : (
        <Fade>
          <div className="onestop-no-requests">There are no requests sent for the last 30 days.</div>
        </Fade>
      )}
    </div>
  );
};

export default OneStopListLayout;
