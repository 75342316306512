/* istanbul ignore file */
import React from 'react';
import { TextField, FieldProps } from 'shared/uibuilder/field/index';
import ProjectLink from 'erp/project/shared/field/ProjectLink';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';

const ProjectLinkField = ({ source, nameSource, permissionToCheck, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { isGranted } = useAuthorization();

  const name = getName();
  const value = name ? (
    <ProjectLink isClickable={isGranted(permissionToCheck)} name={name} id={getId()} {...props} />
  ) : null;

  return <TextField value={value} {...props} />;
};

export default ProjectLinkField;
