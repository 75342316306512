import React from 'react';
import CustomerInvoiceSowIdDropdown from '../inputs/CustomerInvoiceSowIdDropdown';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';

const SowIdFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <CustomerInvoiceSowIdDropdown {...props} />
    </DropDownFilter>
  );
};

export default SowIdFilter;
