/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useLedgerUrl } from 'financialAnalytic/ledger/LedgerRouter';
import LedgerBreadcrumbs from 'financialAnalytic/ledger/shared/LedgerBreadcrumbs';
import Box from 'uibuilder/Box';
import { updateCurrencyName } from 'shared/uibuilder/amountUtils';

const SingleLedgerBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useLedgerUrl();
  const id = data.getValueBySource('id');
  const displayedName = id.split('/').map((item: string, i: number) => (
    <React.Fragment key={item}>
      {i ? (
        <Box
          component="span"
          sx={{
            marginLeft: '8px',
            marginRight: '8px',
            display: 'inline-block',
          }}
        >
          /
        </Box>
      ) : null}
      {updateCurrencyName(item)}
    </React.Fragment>
  ));

  return (
    <LedgerBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getSingleEntityUrl(id)} />
      {children}
    </LedgerBreadcrumbs>
  );
};

export default SingleLedgerBreadcrumbs;
