/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';

const OfficeDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <OfficeDropdown />
    </DropDownFilter>
  );
};

export default OfficeDropdownFilter;
