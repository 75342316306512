/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useProjectWeeklyReportsUrl } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';

interface ProjectWeeklyReportLinkProps {
  id: StringOrNumber;
  isClickable?: boolean;
  projectId: string;
  fromDate: string;
  toDate: string;
}

const ProjectWeeklyReportLink = ({
  id,
  isClickable = true,
  projectId,
  fromDate,
  toDate,
}: ProjectWeeklyReportLinkProps) => {
  const { formatDate } = useDateService();
  const { getSingleEntityLink } = useProjectWeeklyReportsUrl();
  const linkText = `Weekly Report: ${formatDate(fromDate, DATE_FORMAT.FULL_DATE)} - ${formatDate(
    toDate,
    DATE_FORMAT.FULL_DATE,
  )}`;

  return (
    <>
      {isClickable ? (
        <Link to={getSingleEntityLink(id.toString(), projectId)} target="_blank">
          {linkText}
        </Link>
      ) : (
        <span>{linkText}</span>
      )}
    </>
  );
};

export default ProjectWeeklyReportLink;
