/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { EXPENSE_STATUSES_OPTIONS } from 'financialAnalytic/expenses/useExpenseService';

const ExpenseStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={EXPENSE_STATUSES_OPTIONS} label="Status" {...props} />;
};

export default ExpenseStatusDropdown;
