/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import PositionDropdown from './PositionDropdown';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { SIMPLE_QUALIFICATION } from 'crm/opportunity/presale/output/shared/presaleOutputService';

interface RequiredResourcesInputTemplateProps {
  source: string;
}

const RequiredResourcesInputTemplate = ({ source, ...props }: RequiredResourcesInputTemplateProps) => {
  return (
    <FormRow>
      <PositionDropdown source={`${source}.positionId`} label="Position" placeholder="Choose Position..." />
      <EnumDropdown
        options={SIMPLE_QUALIFICATION}
        source={`${source}.qualification`}
        label="Qualification"
        placeholder="Select..."
      />
      <NumberInput source={`${source}.requiredFte`} label="FTE, %" />
    </FormRow>
  );
};

export default RequiredResourcesInputTemplate;
