import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useSowUrl } from '../../SowRouter';
import { WRITE_SOW } from '../../useSowService';

const EditSowButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useSowUrl();

  return <EditButton permissionToCheck={WRITE_SOW} path={() => getUpdateEntityUrl()} key="edit-sow" {...props} />;
};

export default EditSowButton;
