/* istanbul ignore file */
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import { useSensitiveDataContext } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const ReconciliationAmountField = ({ source, idSource, ...props }: FieldProps) => {
  const sensitiveDataContext = useSensitiveDataContext();
  const { getValue } = useFieldHelper({ source: idSource });
  const amount = sensitiveDataContext.getValueByIdAndSource(source, getValue()) || 0;

  return (
    <InvoiceTotalField
      {...props}
      source={source}
      currencySource="currency"
      formatCurrency={() => 'USD'}
      formatAmount={() => amount}
    />
  );
};

export default ReconciliationAmountField;
