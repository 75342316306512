/* istanbul ignore file */
import React, { useRef } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { DIVIDER, MetricsProps } from 'erp/employee/performanceReport/usePerformanceReportService';
import Box from 'uibuilder/Box';
import PerformanceHeatmapLabels from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapLabels';
import Scrollbars from 'uibuilder/Scrollbars';
import Alert from 'uibuilder/Alert';
import usePerformanceHeatmap from 'erp/employee/performanceReport/shared/PerformanceHeatmap/usePerformanceHeatmap';
import PerformanceHeatmapFixedHeaderDecorator from 'erp/employee/performanceReport/shared/PerformanceHeatmap/layout/PerformanceHeatmapFixedHeaderDecorator';

interface PerformanceHeatmapGraphLayoutProps {
  labels: Array<string>;
  currentMonth: string;
  metrics: MetricsProps;
  heatmapHeader: React.ReactNode;
  labelComponent?: React.ReactNode;
  legend: React.ReactNode;
  getItems: (metric: string) => React.ReactNode;
  gridTemplateColumns: string;
  className?: string;
  hasFixedHeader?: boolean;
}

const PerformanceHeatmapGraphLayout = ({
  className,
  heatmapHeader,
  legend,
  getItems,
  gridTemplateColumns,
  labelComponent,
  hasFixedHeader = false,
  ...props
}: PerformanceHeatmapGraphLayoutProps) => {
  const { labels, metrics } = props;
  const { hasRowWithData } = usePerformanceHeatmap(props);
  const scrollableNodeRef = useRef();

  if (isEmpty(metrics)) {
    return (
      <Alert color="info" className="mt-10">
        No metrics found
      </Alert>
    );
  }

  return (
    <>
      <div className="performance-heatmap__wrapper">
        {labelComponent || <PerformanceHeatmapLabels labels={labels.filter(hasRowWithData)} />}

        <Scrollbars className="performance-heatmap" scrollableNodeProps={{ ref: scrollableNodeRef }}>
          <div className="performance-heatmap__inner">
            {hasFixedHeader ? (
              <PerformanceHeatmapFixedHeaderDecorator scrollableNodeRef={scrollableNodeRef}>
                {heatmapHeader}
              </PerformanceHeatmapFixedHeaderDecorator>
            ) : (
              heatmapHeader
            )}

            {labels.map(metric =>
              hasRowWithData(metric) ? (
                <Box
                  component="ul"
                  sx={{
                    gridTemplateColumns,
                  }}
                  className={classnames(className, 'performance-heatmap__days', { divider: metric === DIVIDER })}
                >
                  {getItems(metric)}
                </Box>
              ) : null,
            )}
            {heatmapHeader}
          </div>
        </Scrollbars>
      </div>
      {legend}
    </>
  );
};

export default PerformanceHeatmapGraphLayout;
