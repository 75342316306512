import { useKernelApi } from 'api';

export const READ_WORKING_HOURS_REPORT = 'READ_WORKING_HOURS_REPORT';
export const PAYROLL_PERIOD_FORMAT = 'yyyy-MM';

const useWorkingHoursService = () => {
  const { sendGetRequest } = useKernelApi();

  const getReport = async ({ dateFrom, dateTo, employeeAlias }) => {
    const result = await sendGetRequest(`/employees/${employeeAlias}/working-hours?from=${dateFrom}&to=${dateTo}`);
    return result;
  };

  return {
    getReport,
  };
};

export default useWorkingHoursService;
