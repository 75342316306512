/* istanbul ignore file */
// libs
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'uibuilder/button';
// styles
import './HorizontalMenu.scss';

export interface HorizontalMenuItemProps {
  linkUrl: string;
  name: string | React.ReactElement;
  exact?: boolean;
  isVisible?: boolean;
}

const HorizontalMenuItem = ({ linkUrl, name, isVisible = true, exact = false }: HorizontalMenuItemProps) =>
  isVisible ? (
    <Button
      component={NavLink}
      key={linkUrl}
      to={linkUrl}
      color="neutralLight"
      className="horizontal-menu-btn"
      activeClassName="horizontal-menu-btn--active"
      exact={exact}
    >
      {name}
    </Button>
  ) : null;

export default HorizontalMenuItem;
