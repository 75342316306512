/* istanbul ignore file */
import { useEffect } from 'react';
import googleAnalytics from 'react-ga';
import { useLocation } from 'react-router-dom';

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

    if (googleAnalyticsId) {
      googleAnalytics.initialize(googleAnalyticsId);
    }
  }, []);

  useEffect(() => {
    googleAnalytics.pageview(location.pathname + location.search);
  }, [location]);
};

export default useGoogleAnalytics;
