/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import VacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationBreadcrumbs';

const CreateVacancyPublicationBreadcrumbs = () => {
  return (
    <VacancyPublicationBreadcrumbs>
      <CreateBreadcrumbItem entity="Vacancy Publication" />
    </VacancyPublicationBreadcrumbs>
  );
};

export default CreateVacancyPublicationBreadcrumbs;
