import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import DocumentInput from '../DocumentInput';

const IdentityDocumentInput = props => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const identityDocument = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DocumentInput value={identityDocument} source={`${source}`} />;
};

export default IdentityDocumentInput;
