import React from 'react';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { CUSTOMER_INVOICE_STATUSES_OPTIONS } from '../../useCustomerInvoiceService';

const CustomerInvoiceStatusFilter = ({ source, ...props }: BaseFilterProps & { label: string }) => {
  return (
    <DropDownFilter {...props} source={source}>
      <EnumDropdown source={source} options={CUSTOMER_INVOICE_STATUSES_OPTIONS} {...props} />
    </DropDownFilter>
  );
};

export default CustomerInvoiceStatusFilter;
