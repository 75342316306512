import React from 'react';
import { RadioButtonGroup } from 'shared/uibuilder/form/input/index';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { RadioButtonGroupProps } from './RadioButtonGroup';
import { FormFieldsData } from '../FormContext';

const optionsAlias: Dictionary<string> = {
  yes: 'Yes',
  no: 'No',
};

export const BOOLEAN_RADIUS_GROUP_OPTIONS = Object.keys(optionsAlias).map(key => ({
  value: optionsAlias[key],
  label: optionsAlias[key],
}));

const BooleanRadiosGroup = ({ source, ...props }: RadioButtonGroupProps) => {
  const { getOnChangeCallback, getValue } = useInputHelper({ ...props, source });

  const onChange = getOnChangeCallback();
  const displayedValue = getValue() ? optionsAlias.yes : optionsAlias.no;

  const handleChange = (data: FormFieldsData) => {
    onChange({ target: { value: data[source] === optionsAlias.yes } });
  };

  return (
    <RadioButtonGroup
      {...props}
      options={BOOLEAN_RADIUS_GROUP_OPTIONS}
      onChangeCallback={handleChange}
      source={source}
      value={displayedValue}
    />
  );
};

export default BooleanRadiosGroup;
