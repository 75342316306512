import { useAuthenticationApi } from 'api';
import { APPLICATION_URL } from 'ApplicationRouter';
import { AZURE_CALLBACK_URL } from 'authentication/AuthenticationRouter';

export const RESOURCE_URL = '/oauth/azure/token';

type GetTokenResponseData = {
  ssid?: string;
  accessToken?: string;
  error?: any;
  message?: string;
};

type RefreshTokenResponseData = {
  accessToken: string;
};

export interface AzureApiService {
  revokeToken: (ssid: string) => void;
  refreshToken: (ssid: string) => Promise<RefreshTokenResponseData>;
  getTokenByAccessCode: (accessCode: string) => Promise<GetTokenResponseData>;
}

const useAzureApiService = (): AzureApiService => {
  const { sendPostRequest, sendGetRequest, sendDeleteRequest } = useAuthenticationApi();

  const getTokenByAccessCode = async (accessCode: string): Promise<GetTokenResponseData> => {
    const data = { code: accessCode, redirectLink: `${APPLICATION_URL}${AZURE_CALLBACK_URL}` };
    const response = await sendPostRequest(RESOURCE_URL, data);
    return response.json();
  };

  const refreshToken = async (ssid: string): Promise<RefreshTokenResponseData> => {
    const response = await sendGetRequest(`${RESOURCE_URL}/${ssid}`);
    return response.json();
  };

  const revokeToken = async (ssid: string): Promise<void> => {
    await sendDeleteRequest(`${RESOURCE_URL}/${ssid}`);
  };

  return {
    revokeToken,
    refreshToken,
    getTokenByAccessCode,
  };
};

export default useAzureApiService;
