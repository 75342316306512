/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import OneStopPageLayout from 'uibuilder/layout/form/OneStopPageLayout';
import CareerDevelopmentReviewForm from 'erp/careerdevelopment/createupdate/CareerDevelopmentReviewForm';
import useCareerDevelopmentReviewService from 'erp/careerdevelopment/shared/careerDevelopmentReviewService';
import { CustomRequestFormProps } from 'oneStop/CustomRequestForm';

const OneStopCareerDevelopmentReview = ({ openModal }: CustomRequestFormProps) => {
  const { create, getOneStopValidationSchema } = useCareerDevelopmentReviewService();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        execute: response => {
          // @ts-ignore
          openModal(response.message);
        },
      }}
      getValidationSchemaFunc={getOneStopValidationSchema}
    >
      <OneStopPageLayout>
        <CareerDevelopmentReviewForm />
      </OneStopPageLayout>
    </CreateForm>
  );
};

export default OneStopCareerDevelopmentReview;
