// prettier-ignore
export const US_AREA_CODE = [
  205, 251, 256, 334, 938, 907, 480,
  520, 602, 623, 928, 479, 501, 870,
  209, 213, 279, 310, 323, 403, 408,
  415, 424, 442, 510, 530, 559, 562,
  619, 626, 628, 650, 657, 661, 669,
  707, 714, 747, 760, 805, 818, 820,
  831, 858, 909, 916, 925, 949, 951,
  303, 719, 720, 970, 203, 475, 860,
  959, 302, 239, 305, 321, 352, 386,
  407, 561, 727, 754, 772, 786, 813,
  850, 863, 904, 941, 954, 229, 404,
  470, 478, 678, 706, 762, 770, 912,
  808, 208, 986, 217, 224, 309, 312,
  331, 618, 630, 708, 773, 815, 847,
  872, 219, 260, 317, 463, 574, 765,
  812, 930, 319, 515, 563, 641, 712,
  316, 620, 785, 913, 270, 364, 502,
  606, 859, 225, 318, 337, 504, 985,
  207, 240, 301, 410, 443, 667, 339,
  351, 413, 508, 617, 774, 781, 857,
  978, 231, 248, 269, 313, 517, 586,
  616, 734, 810, 906, 947, 989, 218,
  320, 507, 612, 651, 763, 952, 228,
  601, 662, 769, 314, 417, 573, 636,
  660, 816, 406, 308, 402, 531, 702,
  725, 775, 603, 201, 551, 609, 640,
  732, 848, 856, 862, 908, 973, 505,
  575, 212, 315, 332, 347, 516, 518,
  585, 607, 631, 646, 680, 716, 718,
  838, 845, 914, 917, 929, 934, 252,
  336, 704, 743, 828, 910, 919, 980,
  984, 701, 216, 220, 234, 330, 380,
  419, 440, 513, 567, 614, 740, 937,
  405, 539, 580, 918, 458, 503, 541,
  971, 215, 223, 267, 272, 412, 445,
  484, 570, 610, 717, 724, 814, 878,
  401, 803, 843, 854, 864, 605, 423,
  615, 629, 731, 865, 901, 931, 210,
  214, 254, 281, 325, 346, 361, 409,
  430, 432, 469, 512, 682, 713, 726,
  737, 806, 817, 830, 832, 903, 915,
  936, 940, 956, 972, 979, 385, 435,
  801, 802, 276, 434, 540, 571, 703,
  757, 804, 206, 253, 360, 425, 509,
  564, 202, 304, 681, 262, 414, 534,
  608, 715, 920, 307,
];

export const BY_AREA_EXTERNAL_CODES = [
  37513,
  37514,
  37515,
  37516,
  37517,
  37518,
  37520,
  37521,
  37522,
  37523,
  37524,
  37525,
  37529,
  37533,
  37544,
];

export const isPhoneStartsWithByCode = phone => {
  return !!BY_AREA_EXTERNAL_CODES.find(code => phone.startsWith(code));
};

export const isPhoneStartsWithUsCode = phone => {
  return !!US_AREA_CODE.find(code => phone.startsWith(`1${code}`));
};

// prettier-ignore
const BY_AREA_CODE = [
  ...BY_AREA_EXTERNAL_CODES,
  '013', '014', '015', '016',
  '017', '018', '020', '021',
  '022', '023', '024', '025',
  '029', '033', '044',
];

export const applyPhoneSchema = value => {
  const isUsPhoneNumber =
    US_AREA_CODE.filter(
      code =>
        value &&
        ((value.startsWith(code) && value.length === 10) || (value.startsWith(`1${code}`) && value.length === 11)),
    ).length > 0;
  const isByPhoneNumber =
    BY_AREA_CODE.filter(
      code =>
        value && ((value.startsWith(code) && value.length === 12) || (value.startsWith(code) && value.length === 10)),
    ).length > 0;

  if (isByPhoneNumber) {
    if (value.length === 12) {
      const match = value.match(/(\d{3})(\d{2})(\d{3})(\d{4})/);
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    if (value.length === 10) {
      const match = value.match(/(\d{1})(\d{2})(\d{3})(\d{4})/);
      return `+375 (${match[2]}) ${match[3]}-${match[4]}`;
    }
  }

  if (isUsPhoneNumber) {
    if (value.length === 10) {
      const match = value.match(/(\d{3})(\d{3})(\d{4})/);
      return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
    if (value.length === 11) {
      const match = value.match(/(\d{1})(\d{3})(\d{3})(\d{4})/);
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
  }

  if (!value) {
    return '';
  }

  return value;
};

export const splitPhone = phoneNumber => {
  return phoneNumber
    ? {
        phone: phoneNumber && `${phoneNumber.split('ext')[0]}`,
        extension:
          phoneNumber && phoneNumber.includes('ext') && `${phoneNumber.substring(phoneNumber.indexOf('ext') + 3)}`,
      }
    : undefined;
};

export const buildPhone = phoneObject => {
  const { phone, extension } = phoneObject;

  if (extension) {
    return `${phone}ext${extension}`;
  }
  return phone;
};

export const getUnformattedPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return '';
  }

  return String(phoneNumber).replace(/\D+/g, '');
};
