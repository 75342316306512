/* istanbul ignore file */
export default {
  completedAssessments: {
    type: 'string',
    required: {
      value: true,
    },
    maxSize: 65000,
  },
  attachmentsIds: {
    type: 'array',
    maxSize: 1,
  },
  earlyStartReason: {
    required: {
      value: true,
      when: {
        $isEarlyStarted: {
          eq: true,
        },
      },
    },
    maxSize: 65000,
  },
};
