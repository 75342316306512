import React from 'react';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { buildPhone, getUnformattedPhoneNumber, splitPhone } from 'shared/uibuilder/phoneUtils';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { FormFieldsData } from '../FormContext';

interface PhoneInputProps extends DefaultInputPropTypes<string> {
  hasExtension: boolean;
}

interface PhoneInputLayoutProps {
  phoneInput: React.ReactElement;
  extensionInput?: React.ReactElement;
  hasExtension?: boolean;
}

export type PhoneInputLayoutType = ReactComponent<PhoneInputLayoutProps>;

const PhoneInput = ({ hasExtension = false, ...props }: PhoneInputProps) => {
  const { PhoneInputLayout } = useUiTheme<PhoneInputLayoutType>();

  const inputHelper = useInputHelper(props);
  const { source } = props;

  const phoneObject = splitPhone(inputHelper.getValue()) || {
    phone: '',
    extension: '',
  };

  const phone = phoneObject && phoneObject.phone;
  const extension = phoneObject && phoneObject.extension;

  const phoneSource = `${source}`;
  const extensionSource = `${source}-ext`;

  /**
   * Handles callback of subinputs, merges it and call onChange event of the context or parent input.
   * @param eventValue
   */
  const onChangeCallback = (eventValue: FormFieldsData) => {
    const parentOnChangeCallback = inputHelper.getRawOnChangeCallback();

    if (eventValue[phoneSource] !== undefined) {
      phoneObject.phone = getUnformattedPhoneNumber(eventValue[phoneSource]);
    } else if (eventValue[extensionSource] !== undefined) {
      phoneObject.extension = eventValue[extensionSource];
    }

    if (parentOnChangeCallback) {
      parentOnChangeCallback({
        [source]: buildPhone(phoneObject),
      });
    }
  };

  const phoneInput = (
    <TextInput {...props} value={phone || ''} source={phoneSource} onChangeCallback={onChangeCallback} />
  );

  const extensionInput = (
    <TextInput
      {...props}
      value={extension || ''}
      source={extensionSource}
      onChangeCallback={onChangeCallback}
      label="Extension"
    />
  );

  return <PhoneInputLayout phoneInput={phoneInput} extensionInput={extensionInput} hasExtension={hasExtension} />;
};

export default PhoneInput;
