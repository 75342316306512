/* istanbul ignore file */
import React from 'react';
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewEducationButton = props => {
  const { getSingleEntityUrl } = useEducationUrl();

  return (
    <LinkButton url={getSingleEntityUrl} outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewEducationButton;
