import { APPEND_ARTIFACTS, CLEAR_ARTIFACTS, REMOVE_ARTIFACTS } from './artifactService';

const initialState = {};

const artifactsReducer = (state = initialState, action) => {
  if (action.type === APPEND_ARTIFACTS) {
    return {
      ...state,
      ...action.artifacts,
    };
  }

  if (action.type === REMOVE_ARTIFACTS) {
    const newState = { ...state };
    action.artifactIds.forEach(artifactId => {
      if (newState[artifactId]) {
        delete newState[artifactId];
      }
    });

    return {
      ...newState,
    };
  }

  if (action.type === CLEAR_ARTIFACTS) {
    return {
      ...initialState,
    };
  }

  return state;
};

export default artifactsReducer;
