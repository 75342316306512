/* istanbul ignore file */
import React from 'react';
import PlannedIssues from 'syberryToday/shared/PlannedIssues';
import useSyberryTodayService from 'syberryToday/useSyberryTodayService';
import useSyberryTodaySettings from 'syberryToday/List/AfterDailyCheckin/Settings/useSyberryTodaySettings';
import Settings from 'syberryToday/List/AfterDailyCheckin/Settings';
import List from 'shared/uibuilder/list/List';
import ListPageLayoutWithSidebar from 'shared/uibuilder/list/layout/ListPageLayoutWithSidebar';
import SyberryTodayProvider from 'syberryToday/SyberryTodayProvider';
import SyberryTodayMenu from 'syberryToday/shared/SyberryTodayMenu/SyberryTodayMenu';

const AfterDailyCheckin = () => {
  const { getPlannedTasks, getParentTasks, getRecurrentlyTasks } = useSyberryTodayService();
  const { areParentTasksVisible, areRecurrentlyTasksVisible } = useSyberryTodaySettings();

  return (
    <ListPageLayoutWithSidebar actions={[<Settings />]} sidebar={<SyberryTodayMenu />}>
      <div className="daily-checkin__wrapper w-100 d-block">
        <div className="daily-checkin__content daily-checkin__content--planned">
          <List getDataMethod={getPlannedTasks}>
            <SyberryTodayProvider>
              <PlannedIssues emptyAlertMessage="There are no tasks for today" />
            </SyberryTodayProvider>
          </List>
        </div>

        {areRecurrentlyTasksVisible && (
          <div className="daily-checkin__content daily-checkin__content--planned">
            <List getDataMethod={getRecurrentlyTasks}>
              <SyberryTodayProvider storageId="recurrently-tasks">
                <PlannedIssues
                  title="Recurrent tasks"
                  canPlan={false}
                  emptyAlertMessage="There are no recurrent tasks"
                />
              </SyberryTodayProvider>
            </List>
          </div>
        )}

        {areParentTasksVisible && (
          <div className="daily-checkin__content daily-checkin__content--planned">
            <List getDataMethod={getParentTasks}>
              <SyberryTodayProvider storageId="parent-tasks">
                <PlannedIssues title="Parent tasks" canPlan={false} emptyAlertMessage="There are no parent tasks" />
              </SyberryTodayProvider>
            </List>
          </div>
        )}
      </div>
    </ListPageLayoutWithSidebar>
  );
};

export default AfterDailyCheckin;
