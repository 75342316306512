/* istanbul ignore file */
import React from 'react';
import ViewModeToggle from 'shared/uibuilder/ViewModeToggle/ViewModeToggle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';

const CACHE_NAME = 'heatmap-type';

export enum PerformanceHeatmapType {
  DAILY = 'Daily',
  MONTHLY = 'Monthly',
}

const PerformanceHeatmapTypeIcon = {
  [PerformanceHeatmapType.DAILY]: <CalendarIcon sx={{ fontSize: '18px !important' }} />,
  [PerformanceHeatmapType.MONTHLY]: <CalendarMonthIcon />,
};

interface RbsListViewModeProps {
  initialMode?: string;
  onModeChange?: (mode: PerformanceHeatmapType) => void;
}

const PerformanceHeatmapTypeButton = ({ initialMode, onModeChange = () => {} }: RbsListViewModeProps) => {
  return (
    <ViewModeToggle
      cacheName={CACHE_NAME}
      modes={PerformanceHeatmapType}
      icons={PerformanceHeatmapTypeIcon}
      defaultMode={PerformanceHeatmapType.DAILY}
      onModeChange={onModeChange}
      initialMode={initialMode}
    />
  );
};

export default PerformanceHeatmapTypeButton;
