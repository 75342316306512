import React, { useEffect, useState } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface EntityHeaderFieldProps {
  children: React.ReactElement;
}

const EntityHeaderField = ({ children }: EntityHeaderFieldProps) => {
  const { getValue } = useFieldHelper(children.props);
  const [displayedValue, setDisplayedValue] = useState<Nullable<string>>(null);

  useEffect(() => {
    const value = getValue() || ' ';
    setDisplayedValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.cloneElement(children, { value: displayedValue });
};

export default EntityHeaderField;
