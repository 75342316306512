/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { BooleanField, DateField, FullDateField, NumberField } from 'shared/uibuilder/field';
import { EmployeeLinkField } from 'erp/employee';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import EditBudgetButton from 'erp/budget/List/button/EditBudgetButton';
import { CREATE_BUDGET, UPDATE_BUDGET } from 'erp/budget/shared/budgetService';
import BudgetSearchLinkField from '../shared/BudgetSearchLinkField';
import TableBodyWithGrouping from 'shared/uibuilder/list/layout/DataGridLayout/TableBodyWithGrouping';
import ArchiveBudgetButton from 'erp/budget/List/button/ArchiveBudgetButton';
import CopyBudgetForNextYearButton from 'erp/budget/List/button/CopyBudgetForNextYearButton';

const BudgetListLayout = () => {
  return (
    <DataGridLayout
      tableWrapperClassName="budget-list"
      buttons={[
        <EditBudgetButton key="edit-budget" source="id" outline permissionToCheck={UPDATE_BUDGET} />,
        <ArchiveBudgetButton
          key="archive-budget"
          source="id"
          nameSource="name"
          valueSource="archived"
          outline
          permissionToCheck={UPDATE_BUDGET}
        />,
        <CopyBudgetForNextYearButton
          source="id"
          nameSource="name"
          permissionToCheck={CREATE_BUDGET}
          isVisible={(data: any) => !!data.periodFrom}
        />,
      ]}
      tableBodyComponent={props => (
        <TableBodyWithGrouping {...props} childrenSource="childBudgets" toggleSource="name" />
      )}
    >
      <BudgetLinkField label="ID" source="id" nameSource="id" isSortable width={70} />
      <BudgetSearchLinkField source="name" idSource="id" label="Name" width={250} isSortable />
      <EmployeeLinkField
        source="ownerId"
        sortSource="ownerNameEn"
        nameSource="ownerName"
        label="Owner"
        width={150}
        isSortable
      />
      <DateField source="periodFrom" label="Start Date" isSortable width={130} />
      <DateField source="softPeriodFrom" label="Soft Start Date" isSortable width={130} />
      <DateField source="softPeriodTo" label="Soft End Date" isSortable width={130} />
      <DateField source="periodTo" label="End Date" isSortable width={130} />
      <NumberField source="maxFte" label="Max FTE, people" width={100} displayZeroValue={false} />
      <BooleanField source="archived" label="Archived" width={80} />
      <FullDateField source="updatedAt" label="Last Modified Date" isSortable width={160} />
    </DataGridLayout>
  );
};

export default BudgetListLayout;
