/* istanbul ignore file */
import React from 'react';
import VerticalAddressInputLayout from 'uibuilder/layout/form/input/VerticalAddressInputLayout';

interface VerticalCopyableInputProps {
  input: any;
}

const VerticalCopyableInput = ({ ...props }: VerticalCopyableInputProps) => {
  const { input } = props;
  return <VerticalAddressInputLayout>{input}</VerticalAddressInputLayout>;
};

export default VerticalCopyableInput;
