import React, { useEffect, useState } from 'react';
import { BeforeSubmitModalProps } from 'crm/opportunity/presale/request/shared/modal/BeforeSubmitModal';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import { Button } from 'uibuilder/button';
import PresaleOutputInputs from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton/PresaleOutputInputs';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';

const BeforeSubmitContractRequestModal = ({ modalOpen, setModalOpen }: BeforeSubmitModalProps) => {
  const id = useOpportunityId();
  const { addMessage } = useMessageService();
  const { submitForm = () => {} } = useFormContext();
  const { getLastPresaleOutput, updateOutput } = usePresaleOutputService();
  const [outputFormErrors, setOutputFormErrors] = useState<Dictionary<string[]>>({});
  const [outputFormData, setOutputFormData] = useState<Nullable<Dictionary<any>>>(null);

  const handleComplete = async () => {
    if (outputFormData) {
      try {
        await updateOutput(id, outputFormData.id, outputFormData);
      } catch (e) {
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
        return;
      }
    }
    submitForm();
    setModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const lastOutput = await getLastPresaleOutput(id);
      setOutputFormData(lastOutput);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, id]);

  const submit = (
    <Button data-testid="btn-success" key="modal-submit-button" onClick={handleComplete}>
      Submit
    </Button>
  );

  return (
    <>
      <ModalWindow
        modalSize="lg"
        isOpen={modalOpen}
        title="Please ensure that the Team Structure and Expected Project Start Date are up to date."
        hasCancelButton={false}
        buttons={[submit]}
        onToggle={() => {
          setModalOpen(false);
        }}
      >
        <span>
          Check the Team Structure and Expected Project Start Date. If the data is not up to date, please update it
          using the form below and click the Submit button.
        </span>
        <div className="mt-5">
          <PresaleOutputInputs
            setFormErrors={setOutputFormErrors}
            setFormData={setOutputFormData}
            formData={outputFormData}
            formErrors={outputFormErrors}
          />
        </div>
      </ModalWindow>
    </>
  );
};

export default BeforeSubmitContractRequestModal;
