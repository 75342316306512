/* istanbul ignore file */
import React from 'react';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import { CREATE_PURCHASE_ORDER } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import CreateButton from 'uibuilder/button/CreateButton';

const CreatePurchaseOrderButton = () => {
  const { getCreateUrl } = usePurchaseOrderUrl();

  return (
    <CreateButton
      url={getCreateUrl()}
      label="Add Purchase Order"
      key="create-purchase-order"
      permissionToCheck={CREATE_PURCHASE_ORDER}
    />
  );
};

export default CreatePurchaseOrderButton;
