import React from 'react';
import { RbsNodeType, isBackupOwner } from '../rbsService';
import CreateRbsSubnodeButton from 'erp/rbs/shared/button/CreateRbsSubnodeButton';
import EditRbsSubNodeButton from 'erp/rbs/shared/button/EditRbsSubNodeButton';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import useAuthorization from 'shared/authorization/authorizationService';

const RbsNodeActionButton = ({
  permissionToCheck,
  hasEditButtonsOnly = false,
}: {
  permissionToCheck: Nullable<string>;
  hasEditButtonsOnly?: boolean;
}) => {
  const { getValue: getNodeType } = useFieldHelper({ source: 'nodeType' });
  const { getValue: getId } = useFieldHelper({ source: 'id' });
  const { getValue: getName } = useFieldHelper({ source: 'name' });
  const { getValue: getAttributes } = useFieldHelper({ source: 'attributes' });
  const { getValue: getParentAttributes } = useFieldHelper({ source: 'parentNode' });
  const nodeType = getNodeType();
  const id = getId();
  const { owner = {}, secondOwner = {} } = getAttributes() || ({} as any);
  const { attributes: parentAttributes = {} } = getParentAttributes() || ({} as any);
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());

  const isParentNodeOwner =
    nodeType === RbsNodeType.QUEUE &&
    (parentAttributes?.owner?.alias === userAlias ||
      parentAttributes?.secondOwner?.alias === userAlias ||
      isBackupOwner((getParentAttributes() || ({} as any)).attributes, userAlias));
  const isCurrentNodeOwner =
    owner?.alias === userAlias ||
    secondOwner?.alias === userAlias ||
    isBackupOwner(getAttributes() || ({} as any), userAlias);
  const isOwner = isCurrentNodeOwner || isParentNodeOwner;
  const { isGranted } = useAuthorization();
  const checkPermission = permissionToCheck && isGranted(permissionToCheck);
  const hasPermission = isOwner ? true : !permissionToCheck || checkPermission;

  if (!hasPermission || (nodeType !== RbsNodeType.PROJECT && !isOwner)) return null;

  if (hasEditButtonsOnly) {
    return checkPermission || nodeType === RbsNodeType.QUEUE ? (
      <EditRbsSubNodeButton id={id as string} color="info" outline />
    ) : null;
  }

  return nodeType === RbsNodeType.PROJECT ? (
    <CreateRbsSubnodeButton id={id} name={getName()} isSmall outline hasAccessToCreate={isOwner} />
  ) : (
    <EditRbsSubNodeButton id={id as string} color="info" outline />
  );
};

export default RbsNodeActionButton;
