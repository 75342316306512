import FormRow, { FormRowProps } from 'shared/layout/form/FormRow';
import React from 'react';

const BigFormRow = ({ children, ...props }: FormRowProps) => {
  return (
    <FormRow colMd={12} colXl={12} {...props}>
      {children}
    </FormRow>
  );
};

export default BigFormRow;
