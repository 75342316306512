/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { OPPORTUNITY_TYPE } from 'crm/opportunity/shared/opportunityService';

const OpportunityTypeDropdown = props => {
  return (
    <Dropdown
      placeholder="Choose Opportunity Type"
      options={Object.entries(OPPORTUNITY_TYPE).map(entry => {
        const [value, label] = entry;

        return {
          value,
          label,
        };
      })}
      label="Opportunity Type"
      {...props}
    />
  );
};

export default OpportunityTypeDropdown;
