/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field/index';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';

export const CURRENCIES: Dictionary<string> = {
  USD: '$',
  BYN: 'Br.',
  BYR: 'Br.',
  PLN: 'zł.',
  EUR: '€',
};

interface CurrencyFieldProps extends FieldProps {
  currencySource: string;
  emptyValue?: string;
}

const CurrencyField = ({ source, currencySource, emptyValue, ...props }: CurrencyFieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getCurrency } = useFieldHelper({ source: currencySource });
  const value = getValue() as any;
  const currency = getCurrency() as any;

  return (
    <TextField
      {...props}
      source={source}
      value={value ? `${getWithThousandsSeparator(value)}${CURRENCIES[currency] || ''}` : emptyValue}
    />
  );
};

export default CurrencyField;
