/* istanbul ignore file */
import React from 'react';
import CandidateOfferBreadcrumbs from 'erp/candidate/offercompensation/shared/CandidateOfferBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useCandidateOfferId, useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';

const SingleCandidateOfferBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useCandidateOfferUrl();
  const candidateOfferId = useCandidateOfferId();

  return (
    <CandidateOfferBreadcrumbs>
      <SingleBreadcrumbItem entity={candidateOfferId} link={getSingleEntityUrl(candidateOfferId)} />
      {children}
    </CandidateOfferBreadcrumbs>
  );
};

export default SingleCandidateOfferBreadcrumbs;
