/* istanbul ignore file */
import React, { useState } from 'react';
import { Request } from 'oneStop/oneStopService';
import OneStopModal from 'oneStop/Create/OneStopModal';
import CustomRequestFormFactory from 'oneStop/CustomRequestForm';
import { ModalData } from 'oneStop/Create/OneStopModalLayout';
import OneStopRequestLayout from 'oneStop/Create/OneStopRequestLayout';
import SanitizedHtml from '../../shared/security/SanitizedHtml';

type CustomRequestFormWrapperProps = {
  request: Request;
};

const CustomRequestFormWrapper = ({ request }: CustomRequestFormWrapperProps) => {
  const [modalData, setModalData] = useState<Nullable<ModalData>>(null);

  const openModal = (content: string | React.ReactElement, title?: string) => {
    setModalData({ isOpen: true, content, title, requestId: request.id, requestType: request.type });
  };

  return (
    <>
      <OneStopRequestLayout request={request}>
        <CustomRequestFormFactory
          form={request.form}
          openModal={message => openModal(<SanitizedHtml html={message} />)}
        />
      </OneStopRequestLayout>
      <OneStopModal data={modalData} />
    </>
  );
};

export default CustomRequestFormWrapper;
