import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import rbsValidation from 'erp/rbs/createupdate/rbsValidation';
import useCacheService from 'shared/cache/cacheService';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import { TooltipNameData } from './tree/RbsTree';
import { TreeNodeProps } from 'shared/uibuilder/Tree/treeHelpers';
import useProjectsSettings from 'erp/project/List/filter/ProjectsSettingsFilter/useProjectsSettings';
import { cloneDeep } from 'lodash';

export const RBS_PATH = '/responsibilities';
export const READ_RBS_TREE_NODE = 'READ';
export const READ_RBS_TREE_NODES_LIST = 'RBS_TREE_NODE_READ_LIST';
export const FE_READ = 'RBS_TREE_NODE_FE_READ';
export const CREATE_RBS_TREE_NODE = 'RBS_TREE_NODE_CREATE';
export const CREATE_QUEUE = 'CREATE_QUEUE';
export const UPDATE_RBS_TREE_NODE = 'UPDATE';
export const DELETE_RBS_TREE_NODE = 'DELETE';

export enum RbsNodeType {
  PROJECT = 'PROJECT',
  QUEUE = 'QUEUE',
}
export const RbsNodeTypeLabels: Dictionary<string> = {
  [RbsNodeType.PROJECT]: 'Subproject',
  [RbsNodeType.QUEUE]: 'Queue',
};

export const hasAccessToEditAction = (data: TreeNodeProps, currentUserAlias: Nullable<string>) => {
  const { nodeType, parentNode } = data;
  const currentNodeOwnerAlias = getOwnerAlias(data);
  const parentNodeOwnerAlias = getOwnerAlias((parentNode as TreeNodeProps) || {});
  const parentNodeSecondOwnerAlias = getSecondOwnerAlias((parentNode as TreeNodeProps) || {});

  return (
    nodeType === RbsNodeType.QUEUE &&
    (isNodeOwner(currentNodeOwnerAlias, currentUserAlias) ||
      isNodeOwner(parentNodeOwnerAlias, currentUserAlias) ||
      isNodeOwner(parentNodeSecondOwnerAlias, currentUserAlias) ||
      isBackupOwner(data.attributes || ({} as any), currentUserAlias) ||
      isBackupOwner((parentNode as TreeNodeProps).attributes || ({} as any), currentUserAlias))
  );
};

export const hasAccessToCreateAction = (data: TreeNodeProps, currentUserAlias: Nullable<string>) => {
  const { nodeType } = data;
  const alias = getOwnerAlias(data);
  const secondOwnerAlias = getSecondOwnerAlias(data);

  return (
    nodeType === RbsNodeType.PROJECT &&
    (isNodeOwner(alias, currentUserAlias) ||
      isNodeOwner(secondOwnerAlias, currentUserAlias) ||
      isBackupOwner(data.attributes || ({} as any), currentUserAlias))
  );
};

export const hasAccessToAnyAction = (
  hasPermission: boolean,
  data: TreeNodeProps,
  currentUserAlias: Nullable<string>,
) => {
  return (
    hasPermission || hasAccessToCreateAction(data, currentUserAlias) || hasAccessToEditAction(data, currentUserAlias)
  );
};

const getOwnerAlias = (node: TreeNodeProps) => {
  const { attributes: { owner } = {} } = node;
  const { alias } = (owner as TooltipNameData) || {};

  return alias;
};

const getSecondOwnerAlias = (node: TreeNodeProps) => {
  const { attributes: { secondOwner } = {} } = node;
  const { alias } = (secondOwner as TooltipNameData) || {};

  return alias;
};

const getBackupsForOwners = (attributes: Dictionary<string | Dictionary<string> | Dictionary<string>[]>) => {
  const { backupsForOwners = [] } = attributes;
  const backups = (backupsForOwners as TooltipNameData[]) || [];

  return backups.map((backup: TooltipNameData) => backup.alias);
};

export const isBackupOwner = (
  attributes: Dictionary<string | Dictionary<string> | Dictionary<string>[]>,
  currentUserAlias: Nullable<string>,
) => {
  const backupOwners = getBackupsForOwners(attributes);

  return backupOwners.includes(currentUserAlias || '');
};

export const isNodeOwner = (ownerAlias: string | undefined, currentUserAlias: Nullable<string>) => {
  return ownerAlias === currentUserAlias;
};

interface RbsNode {
  id: number;
  name: string;
  parentNode: RbsNode | null;
  children: RbsNode[] | null;
  nodeType: string;
  isPublicService: boolean;
  isArchived: boolean;
  createdAt: string | null;
  updatedAt: string;
}

const useRbsService = () => {
  const { areArchivedProjectsVisible } = useProjectsSettings();
  const { invalidateCache } = useCacheService('/rbs');
  const { getById, update: parentUpdate, ...baseCrudRequests } = useCrudService({
    singleResourceUrl: '/rbs/:id',
    listResourceUrl: '/rbs',
    apiService: useKernelApi,
  });

  const { create: parentCreate } = useCrudService({
    singleResourceUrl: '/rbs/node',
    listResourceUrl: '/rbs/node',
    apiService: useKernelApi,
  });

  const getValidationSchema = () => Promise.resolve(rbsValidation);

  const update = async (id: ResourceId, request: ResourceData) => {
    const entityData = await parentUpdate(id, request);
    invalidateCache();
    return entityData;
  };

  const create = async (request: ResourceData) => {
    const entityData = await parentCreate(request);
    invalidateCache();
    return entityData;
  };

  const filterArchivedNodes = (nodes: RbsNode[], isArchived: boolean): RbsNode[] => {
    return nodes
      .map(node => {
        const updatedNode: RbsNode = {
          ...node,
          children: node.children ? filterArchivedNodes(node.children, isArchived) : null,
        };

        if (updatedNode.isArchived === isArchived || (updatedNode.children && updatedNode.children.length > 0)) {
          return updatedNode;
        }

        return null;
      })
      .filter((node): node is RbsNode => node !== null);
  };

  const getRootNodes = async () => {
    const response = await getById('root');
    const res = cloneDeep(response);

    return {
      ...res,
      result: filterArchivedNodes(res.result as RbsNode[], areArchivedProjectsVisible),
    };
  };

  return {
    ...baseCrudRequests,
    getById,
    getRootNodes,
    create,
    getValidationSchema,
    update,
  };
};

export default useRbsService;
