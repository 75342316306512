/* istanbul ignore file */
import React from 'react';
import { TextField, FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useDateTimeService } from 'shared/uibuilder/dateService';

interface StatusPeriodFieldProps extends FieldProps {
  toSource: string;
}

const StatusPeriodField = ({ toSource, ...props }: StatusPeriodFieldProps) => {
  const { getValue: getFromDate, getLabel } = useFieldHelper(props);
  const { getValue: getToDate } = useFieldHelper({ source: toSource });
  const { formatDateWithFullDateFormat } = useDateTimeService();

  const dateRangeText = `${formatDateWithFullDateFormat(getFromDate())} - ${formatDateWithFullDateFormat(getToDate())}`;

  return (
    <div className="d-flex">
      <b className="d-inline-block mr-1">{getLabel()}:</b>
      <TextField {...props} label="" value={dateRangeText} />
    </div>
  );
};

export default StatusPeriodField;
