/* istanbul ignore file */
import { EnumField } from 'shared/uibuilder/field';
import React from 'react';
import { CLOSED_REASON_LIST } from 'crm/opportunity/shared/opportunityService';

const ClosedReasonField = (props: any) => {
  return <EnumField {...props} options={CLOSED_REASON_LIST} />;
};

export default ClosedReasonField;
