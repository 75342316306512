import React from 'react';
import Alert from 'uibuilder/Alert';
import useMessageService from './messageService';
import { MessageProps } from './messageReducer';
import SanitizedHtml from '../security/SanitizedHtml';

const Message = ({ messageContext }: { messageContext?: string }) => {
  const { getMessages, cleanMessage } = useMessageService(messageContext);

  const messages = getMessages();

  const createAlert = (message?: MessageProps) => {
    let toggle;

    if (message?.closeable !== false) {
      toggle = () => cleanMessage(message);
    }

    const content =
      typeof message?.content === 'string' ? (
        <SanitizedHtml html={message.content} /> // eslint-disable-line react/no-danger
      ) : (
        message?.content
      );

    return message ? (
      <Alert key={message?.content.toString()} severity={message?.status as any} onClose={toggle}>
        {content}
      </Alert>
    ) : null;
  };

  return messages?.length > 0 ? <>{messages.map(m => createAlert(m))}</> : null;
};

export default Message;
