import useArtifactService from '../artifactService';
import { isIOS, isSafari } from 'shared/uibuilder/helper';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

const availablePreviewTypes = ['pdf', 'rtf', 'doc', 'docx'];

const useArtifactPreviewHelper = () => {
  const { downloadArtifactPreview, downloadInvoiceArtifactPreview } = useArtifactService();
  const { addMessage } = useMessageService();

  // TODO: Temporary preview excluded from ios. Need to allow on ios after implementation pdf preview
  const isPreviewAvailable = (type: string): boolean => !isIOS() && availablePreviewTypes.includes(type);

  // TODO: tmp method, must be removed after implementation pdf preview
  const displayPdf = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    if (isSafari()) {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const popUp = window.open(reader.result);

          if (popUp) {
            popUp.document.write(
              `<html><body style="margin: 0"><iframe src="${reader.result}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe></body></html>`,
            );
            popUp.focus();
          } else {
            addMessage(
              new ErrorMessage(
                'Pop-up Blocker is enabled! Please, disable your pop-up blocker (Safari > Preferences > Websites > Pop-up Windows)',
              ),
            );
          }
        }
      };
      reader.readAsDataURL(blob);
    } else {
      window.open(`${url}#toolbar=0`, '_blank');
    }
    window.URL.revokeObjectURL(url);
  };

  const openPreview = async (id: string): Promise<void> => {
    const blob = await downloadArtifactPreview(id);
    displayPdf(blob);
  };

  const openPreviewForInvoice = async (id: string): Promise<void> => {
    const blob = await downloadInvoiceArtifactPreview(id);
    displayPdf(blob);
  };

  return {
    isPreviewAvailable,
    openPreview,
    openPreviewForInvoice,
  };
};

export default useArtifactPreviewHelper;
