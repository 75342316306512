// libs
import React, { useMemo } from 'react';
// components
import { TextField, FieldProps } from 'shared/uibuilder/field';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
// services
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

export interface ListFieldProps extends FieldProps<string[]> {
  children: React.ReactElement;
}

const ListField = ({ children, ...props }: ListFieldProps) => {
  const { getValue } = useFieldHelper(props);
  const value = getValue();

  const resultValue = useMemo(() => {
    if (value && value.length) {
      return value.map((val, index) =>
        React.cloneElement(children, { value: val, label: '', isLast: value.length - 1 === index }),
      );
    } else {
      return EMPTY_VALUE_INDICATOR;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <TextField {...props} value={resultValue} isColumnDirection={resultValue.length > 1} className="list-field" />;
};

export default ListField;
