/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps } from 'shared/uibuilder/field';
import { ASSET_STATUS_OPTIONS } from '../../useAssetService';

const AssetStatusField = (props: FieldProps) => {
  return <EnumField options={ASSET_STATUS_OPTIONS} {...props} />;
};

export default AssetStatusField;
