/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { arrayOf, element, oneOfType } from 'prop-types';
import { useLeadUrl } from 'crm/lead/LeadRouter';

const LeadsBreadcrumbs = ({ children }) => {
  const { getListUrl } = useLeadUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Leads" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

LeadsBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

LeadsBreadcrumbs.defaultProps = {
  children: null,
};

export default LeadsBreadcrumbs;
