import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_CUSTOMER_INVOICE } from '../../useCustomerInvoiceService';
import { useCustomerInvoiceUrl } from '../../CustomerInvoicesRouter';

type CreateCustomerInvoiceButtonProps = {
  url?: string;
  outline?: boolean;
  variant?: 'outlined' | 'contained' | 'text';
  isVisible: boolean | ((data: any) => boolean);
};

const CreateCustomerInvoiceButton = ({ url, ...props }: CreateCustomerInvoiceButtonProps) => {
  const { getCreateUrl } = useCustomerInvoiceUrl();

  return (
    <CreateButton
      url={url || getCreateUrl()}
      label="Add Customer invoice"
      key="create-customer-invoice"
      permissionToCheck={CREATE_CUSTOMER_INVOICE}
      {...props}
    />
  );
};

export default CreateCustomerInvoiceButton;
