/* istanbul ignore file */
import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import './index.scss';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';

const AdditionalVacancyQuestionList = (props: InputListProps) => {
  return <InputList {...props} maxFields={10} />;
};

export default AdditionalVacancyQuestionList;
