/* istanbul ignore file */
import emailFormValidation from 'erp/candidate/communication/email/form/emailFormValidation';

const useEmailCommunicationService = () => {
  return {
    getValidationSchema: () => Promise.resolve(emailFormValidation),
  };
};

export default useEmailCommunicationService;
