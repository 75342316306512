/* istanbul ignore file */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_EMPLOYEE_TIMELINE } from 'erp/employee/employeeService';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

const EmployeeIdPage = () => {
  const { getTimelineUrl, getSummaryUrl, getProfileUrl } = useEmployeeUrl();
  const { isGranted } = useAuthorization();
  const history = useHistory();
  const id = useEmployeeId();
  const { getUserAlias } = useAuthenticatedUserService();

  useEffect(() => {
    (async () => {
      const userAlias = await getUserAlias();

      if (id === userAlias) {
        history.replace(getProfileUrl(id));
      } else if (isGranted(READ_EMPLOYEE_TIMELINE)) {
        history.replace(getTimelineUrl(id));
      } else {
        history.replace(getSummaryUrl(id));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default EmployeeIdPage;
