import React from 'react';
import Condition, { ConditionProps } from './Condition';
import useConditionHelper from './conditionHelper';
import { FilterCondition } from '../FilterContext';

export const IN_CONDITION_TYPE = FilterCondition.IN;

const InCondition = ({ children, onChangeCallback: parentOnchangeCallback, ...conditionProps }: ConditionProps) => {
  const { getValueFromCondition, buildValue } = useConditionHelper({
    ...conditionProps,
    conditionType: IN_CONDITION_TYPE,
  });

  const onChangeCallback = (value: any) => {
    const rawValue = getValueFromCondition(value);

    const result = buildValue(Array.isArray(rawValue) ? rawValue : [rawValue]);

    if (parentOnchangeCallback) {
      parentOnchangeCallback(result);
    }
  };

  return (
    <Condition {...conditionProps} conditionType={IN_CONDITION_TYPE} onChangeCallback={onChangeCallback}>
      {children}
    </Condition>
  );
};

export default InCondition;
