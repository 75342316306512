import useConfiguration from 'configurationService';

const useRedmineUrl = () => {
  const { redmineUrl } = useConfiguration();

  const getRedmineUrl = () => redmineUrl;
  const getIssueUrl = (id: number) => `${redmineUrl}/issues/${id}`;
  const getUserUrl = (id: number) => `${redmineUrl}/users/${id}`;
  const getProjectUrl = (id: number) => `${redmineUrl}/projects/${id}`;

  return {
    getRedmineUrl,
    getIssueUrl,
    getUserUrl,
    getProjectUrl,
  };
};

export default useRedmineUrl;
