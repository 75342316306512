/* istanbul ignore file */
import React from 'react';
import { func, node } from 'prop-types';
import { PrepopulationProvider } from 'erp/employee/prepopulation/PrepopulationContext';
import usePrepopulation from 'erp/employee/prepopulation/prepopulationHelper';

const Prepopulation = ({ children, mapResponse }) => {
  const contextValue = usePrepopulation({ mapResponse });

  return <PrepopulationProvider value={contextValue}>{children}</PrepopulationProvider>;
};

Prepopulation.propTypes = {
  children: node.isRequired,
  mapResponse: func,
};

Prepopulation.defaultProps = {
  mapResponse: undefined,
};

export default Prepopulation;
