/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useHandbookUrl } from 'handbook/HandbookRouter';
import { HandbookItemStructure } from 'handbook/handbookStructureService';
import * as domUtils from 'shared/uibuilder/domUtils';
import { HashLink } from 'react-router-hash-link';

const HandbookSubpagesListLayout = ({ pageStructure }: { pageStructure: HandbookItemStructure }) => {
  const { getListUrl, getSingleEntityUrl } = useHandbookUrl();

  const getLink = (pagePathname: string, pageTitle: string) => {
    if (pagePathname.startsWith('/') && pagePathname.includes('#')) {
      return (
        <HashLink to={`${getListUrl()}${pagePathname}`} scroll={(el: HTMLElement) => domUtils.scrollToHash(el)}>
          {pageTitle}
        </HashLink>
      );
    } else {
      return <Link to={getSingleEntityUrl(pagePathname.slice(1))}>{pageTitle}</Link>;
    }
  };

  const renderTableOfContent = (data: HandbookItemStructure[]) => {
    return data.map(({ pagePathname, subPages, pageTitle }) => (
      <li key={pagePathname}>
        {getLink(pagePathname, pageTitle)}

        {subPages?.length ? <ul>{renderTableOfContent(subPages)}</ul> : null}
      </li>
    ));
  };

  return (
    <>
      <h2 className="subpage-title">{pageStructure.pageTitle} pages</h2>
      <ul>{renderTableOfContent(pageStructure.subPages || [])}</ul>
    </>
  );
};

export default HandbookSubpagesListLayout;
