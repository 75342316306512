/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useCrmService from 'crm/crmService';
import OwnerDropdown from 'crm/shared/input/OwnerDropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

interface CrmOwnerDropdownInputProps {
  source: string;
  label: string;
  canUserChange: boolean;
  dropdownPlaceholder: string;
}

const CrmOwnerDropdownInput = ({ label, dropdownPlaceholder, canUserChange, ...props }: CrmOwnerDropdownInputProps) => {
  const { getUserAlias } = useAuthenticatedUserService();
  const { getRawOnChangeCallback, getSource } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();
  const { getActiveSalesManagers } = useCrmService();
  const [sales, setSales] = useState([]);
  const [currentUserAlias, setCurrentUserAlias] = useState<Nullable<string>>(null);

  useEffect(() => {
    (async () => {
      if (!canUserChange) {
        const currentAlias = await getUserAlias();
        setCurrentUserAlias(currentAlias);
      }
      try {
        const sms = await getActiveSalesManagers();
        setSales(sms);
      } catch (e) {
        setSales([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OwnerDropdown
      label={label}
      source={getSource()}
      placeholder={dropdownPlaceholder}
      sales={sales}
      onChangeCallback={rawOnChangeCallback}
      disabled={!canUserChange}
      value={currentUserAlias}
    />
  );
};

export default CrmOwnerDropdownInput;
