/* istanbul ignore file */
import { DefaultDropdownLayoutProps } from 'shared/uibuilder/form/input/Dropdown';
import { CSSObjectWithLabel } from 'react-select';

const getDropdownLayoutCommonProps = (props: DefaultDropdownLayoutProps<any[]>) => {
  const { errorMessages } = props;

  const hasError = errorMessages && errorMessages.length > 0;
  const invalidClass = hasError ? 'is-invalid' : '';
  const selectClassName = `dropdown-container ${invalidClass}`;
  const classNamePrefix = 'dropdown';

  const styles = {
    menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 100 }),
  };

  return {
    hasError,
    invalidClass,
    selectClassName,
    styles,
    classNamePrefix,
  };
};

export default getDropdownLayoutCommonProps;
