import momentTimezone from 'moment-timezone';
import { FilterCondition, useFilterContext } from './FilterContext';
import moment, { Moment } from 'moment';
import { DEFAULT_TIMEZONE, useDateTimeService, DEFAULT_FORMAT } from 'shared/uibuilder/dateService';
import { useEffect, useState } from 'react';
import useFilterValidation from 'shared/uibuilder/list/filter/useFilterValidation';

export const FILTER_DATE_ERROR = 'Please choose valid date in past';

const useDateRangeFilter = (dateFormat: string, dateTimeFormat: string, canBeInFuture: boolean, source: string) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const { getTimezone, getDateInCurrentTimezone } = useDateTimeService();
  const { getFilterErrors, setFilterErrors } = useFilterContext();

  const { maxDate: maxGeDate, minDate: minGeDate } = useFilterValidation(`${source}.ge`);
  const { maxDate: maxLeDate, minDate: minLeDate } = useFilterValidation(`${source}.le`);

  useEffect(() => {
    (async () => {
      if (getTimezone) {
        const userTimezone = await getTimezone();
        setTimezone(userTimezone);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMaxDate = () => {
    return canBeInFuture ? null : momentTimezone.tz(moment(), timezone);
  };

  const validateAndShowErrorMessages = (inputName: FilterCondition, inputValue: string) => {
    const date = momentTimezone.tz(inputValue, timezone);
    const isValid =
      !inputValue ||
      (date.isValid() &&
        (canBeInFuture || date.isSameOrBefore(getMaxDate())) &&
        date.creationData().format === dateFormat);
    const showErrors = !isValid;
    if (showErrors) {
      setFilterErrors(source, inputName, [FILTER_DATE_ERROR]);
    } else {
      setFilterErrors(source, inputName, []);
    }
    return showErrors;
  };

  const fromApiToFrontFormat = (apiTextDate: string) => {
    const isValidDateTimeFormat = momentTimezone(apiTextDate, dateTimeFormat, true).isValid();

    if (isValidDateTimeFormat) {
      return getDateInCurrentTimezone(apiTextDate).format(dateFormat);
    }

    return apiTextDate;
  };

  const getErrors = (key: FilterCondition) => {
    return getFilterErrors(source, key);
  };

  const localDate = (date: string) => momentTimezone.tz(date, timezone);

  const formatDateFormValidationSchema = (date?: Nullable<string | Moment>) => {
    return !date || typeof date === 'string'
      ? ''
      : getDateInCurrentTimezone(date.format(dateTimeFormat)).format(DEFAULT_FORMAT);
  };

  const getMaxGeDate = () => formatDateFormValidationSchema(maxGeDate());

  const getMinGeDate = () => formatDateFormValidationSchema(minGeDate());

  const getMaxLeDate = () => formatDateFormValidationSchema(maxLeDate());

  const getMinLeDate = () => formatDateFormValidationSchema(minLeDate());

  return {
    validateAndShowErrorMessages,
    fromApiToFrontFormat,
    getErrors,
    getMaxDate,
    localDate,
    getMaxGeDate,
    getMinGeDate,
    getMaxLeDate,
    getMinLeDate,
  };
};

export default useDateRangeFilter;
