/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField from 'shared/uibuilder/field/LinkField';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import { FieldProps } from 'shared/uibuilder/field/index';

interface InvoiceLinkProps extends FieldProps {
  nameSource: string;
}

const InvoiceLink = ({ source, nameSource, ...props }: InvoiceLinkProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useInvoiceUrl();
  const id = getValue();
  const name = getName();

  return <LinkField linkText={name || id} isInternal value={getSingleEntityUrl(id)} {...props} />;
};

export default InvoiceLink;
