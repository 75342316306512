/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  READ_EXPENSE,
  EXPENSE_PATH,
  READ_EXPENSE_LIST,
  CREATE_EXPENSE,
} from 'financialAnalytic/expenses/useExpenseService';
import ExpenseList from 'financialAnalytic/expenses/List';
import ExpenseViewPage from 'financialAnalytic/expenses/Show';
import ExpenseCreatePage from 'financialAnalytic/expenses/createupdate/Create';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import UploadExpensesPage from './uploadExpenses/UploadExpensesPage';

export const useExpenseRoute = () => {
  const routers = useRoute({ listRoute: EXPENSE_PATH });

  return {
    uploadExpensesRoute: `${routers.listRoute}/upload-expenses`,
    ...routers,
  };
};

export const useExpenseUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: EXPENSE_PATH,
  });

  return baseUrls;
};

export const useExpenseId = () => useParams<Dictionary<string>>().id;

const ExpenseRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, uploadExpensesRoute } = useExpenseRoute();

  return (
    <ProtectedRouter basePath={EXPENSE_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_EXPENSE_LIST}>
          <ExpenseList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_EXPENSE}>
          <ExpenseCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={uploadExpensesRoute} exact globalPermissionToCheck={CREATE_EXPENSE}>
          <UploadExpensesPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_EXPENSE}>
          <ExpenseViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

ExpenseRouter.getRouterPath = () => EXPENSE_PATH;

export default ExpenseRouter;
