import { useKernelApi } from 'api/index';
import { ResourceData } from 'shared/crud/baseCrudService';

export const FEEDBACKS_I_SENT = 'EMPLOYEE_FEEDBACK_READ_SENT_FEEDBACKS';

const useEmployeeFeedbacksService = () => {
  const { sendPostRequest } = useKernelApi();

  const search = async (request: ResourceData) => {
    const url = `/feedbacks/history/search`;

    const response = await sendPostRequest(url, request);
    return response.json();
  };

  return {
    search,
  };
};

export default useEmployeeFeedbacksService;
