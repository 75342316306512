/* istanbul ignore file */
export default {
  role: {
    type: 'string',
    required: {
      value: true,
      message: 'Please select role',
    },
  },
};
