/* istanbul ignore file */
import React, { useEffect } from 'react';
import useProcessDefinitionService from 'camunda/monitoring/definition/processDefinitionService';
import List from 'shared/uibuilder/list/List';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { NumberField } from 'shared/uibuilder/field';
import ProcessDefinitionLinkField from 'camunda/monitoring/definition/shared/ProcessDefinitionLinkField';
import IncidentsCount from 'camunda/monitoring/definition/List/IncidentsCount';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import useProcessInstanceService from 'camunda/monitoring/instance/processInstanceService';

const ProcessDefinitionListPage = () => {
  const { search } = useProcessDefinitionService();
  const { cacheProcessKeys } = useProcessInstanceService();

  useEffect(() => {
    cacheProcessKeys();
  }, [cacheProcessKeys]);

  return (
    <List getDataMethod={search}>
      <ListPageLayout header={<ListHeader label="Definitions" icon="fa-file-text" />}>
        <IncidentsCount />
        <DataGridLayout showTotal={false} isSmallTable>
          <ProcessDefinitionLinkField source="key" />
          <NumberField source="activeInstancesCount" label="Active instances" />
          <NumberField source="activeIncidentsCount" label="Incidents" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ProcessDefinitionListPage;
