/* istanbul ignore file */
import React from 'react';
import ShowDate from 'shared/uibuilder/ShowDate';
import { PrepopulationForm } from 'erp/employee/prepopulation/PrepopulationContext';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

type PrepopulateFormDataLayoutProps = {
  form: PrepopulationForm;
};

const PrepopulateFormDataLayout = ({ form }: PrepopulateFormDataLayoutProps) => {
  const { id, name, updatedAt } = form;

  return (
    <>
      <div className="external-form__title">
        <b>Form name:</b> {name}
      </div>
      <div className="external-form__updated">
        <b>Form submission: </b>
        ID {id} updated at <ShowDate dateUTC={updatedAt} format={DATE_FORMAT.SIMPLE} />
      </div>
    </>
  );
};

export default PrepopulateFormDataLayout;
