/* istanbul ignore file */
import React from 'react';
import Chip from '@mui/material/Chip';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface BadgeProps {
  className?: string;
  color?: BasicColors;
  children?: React.ReactNode;
  isVisible?: boolean | ((data: any) => boolean);
  id?: string;
  sx?: SxProps<Theme>;
}

const Badge = ({ children, isVisible = true, color, sx = {}, ...props }: BadgeProps) => {
  return isVisible ? (
    <Chip
      sx={{ marginLeft: '3px', borderRadius: '4px', ...sx }}
      {...props}
      size="small"
      label={children}
      color={color as any}
    />
  ) : null;
};

export default Badge;
