import React from 'react';
import UpdateCustomerInvoiceBreadcrumbs from './UpdateCustomerInvoiceBreadcrumbs';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import { UpdateForm } from 'shared/uibuilder/form';
import useCustomerInvoiceService from '../../useCustomerInvoiceService';
import { useCustomerInvoiceUrl } from '../../CustomerInvoicesRouter';
import CustomerInvoiceForm from '../CustomerInvoiceForm';

const UpdateCustomerInvoicePage = () => {
  const { getValidationSchema, getById, update, getSensitiveDataByIds } = useCustomerInvoiceService();
  const { getSingleEntityUrl } = useCustomerInvoiceUrl();

  const getDataFunc = async (id: string) => {
    const data = await getById(id);
    const sensitiveData = await getSensitiveDataByIds([id]);

    return {
      ...data,
      ...sensitiveData[id],
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Invoice has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <UpdatePageLayout breadcrumbs={<UpdateCustomerInvoiceBreadcrumbs />} title="Edit: Сustomer Invoice">
        <CustomerInvoiceForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateCustomerInvoicePage;
