import React from 'react';
import useCompetencyService from '../competencyService';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import SingleCompetencyBreadcrumbs from '../shared/SingleCompetencyBreadcrumbs';
import CompetencyEntityHeader from '../shared/CompetencyEntityHeader';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import CompetencyAreasField from '../shared/field/CompetencyAreasField';
import { HtmlField, BulletedListField } from 'shared/uibuilder/field';
import CompetencyMenu from 'erp/qualification/competency/shared/CompetencyMenu';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import { useCompetencyId } from '../CompetencyRouter';

const CompetencyViewPage = () => {
  const id = useCompetencyId();
  const { getById } = useCompetencyService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        breadcrumbs={<SingleCompetencyBreadcrumbs />}
        entityHeader={<CompetencyEntityHeader />}
        menu={<CompetencyMenu />}
      >
        <div className="view-single-entry view-single-entry--list">
          <BigSectionRow>
            <BulletedListField source="evidence" label="Valid work product evidence or counter-examples" />
          </BigSectionRow>

          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField source="details.requirements" label="Requirements" />
            </ReplaceUrlWithLink>
          </BigSectionRow>

          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField source="details.theory" label="Recommended theory" />
            </ReplaceUrlWithLink>
          </BigSectionRow>

          <BigSectionRow>
            <CompetencyAreasField source="areas" label="Competency areas" />
          </BigSectionRow>
        </div>
      </ShowPageLayout>
    </Show>
  );
};

export default CompetencyViewPage;
