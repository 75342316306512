/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EmployeesBreadcrumbs from 'erp/employee/shared/EmployeesBreadcrumbs';

const CreateEmployeeBreadcrumbs = () => {
  return (
    <EmployeesBreadcrumbs>
      <CreateBreadcrumbItem entity="Employee" />
    </EmployeesBreadcrumbs>
  );
};

export default CreateEmployeeBreadcrumbs;
