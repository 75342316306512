/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import ReconciliationStatusDropdown from 'erp/costaccounting/reconcilation/shared/input/ReconciliationStatusDropdown';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';

const ReconciliationStatusFilter = (props: Omit<ConditionProps, 'children'> & { label: string }) => {
  return (
    <DropDownFilter {...props}>
      <ReconciliationStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default ReconciliationStatusFilter;
