import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ButtonDropdown from 'shared/uibuilder/form/input/ButtonDropdown';
import { FiltersListLayoutProps } from '../index';
import './ListFiltersListLayout.scss';
import useFiltersListHelper from '../useFiltersListHelper';
import AddIcon from '@mui/icons-material/AddOutlined';

const ListFiltersListLayout = ({ selectedFilters = [], filters, toggleFilter }: FiltersListLayoutProps) => {
  const { getFilterOptions } = useFiltersListHelper({ filters });
  const options = getFilterOptions();
  const [isMenuShown, setIsMenuShown] = useState(false);

  const toggleMenu = () => setIsMenuShown(!isMenuShown);

  return (
    <>
      {selectedFilters
        .filter(item => item.selected && !item.disabled)
        .map(({ label, element, id, source }) => {
          return (
            <div className="filter-item-wrapper" key={`filter${source}`}>
              {React.cloneElement(element, {
                onRemoveCallback: () => toggleFilter(id),
                label,
                source,
                key: source,
              })}
            </div>
          );
        })}
      {!!options.length && (
        <div className="add-filter-button__wrapper">
          <ButtonDropdown
            label={
              <>
                <AddIcon sx={{ fontSize: '20px', marginRight: '8px' }} />
                Add Filter
              </>
            }
            className="add-filter-button"
            toggleMenu={toggleMenu}
            isOpen={isMenuShown}
          >
            {options.map(({ value, label, disabled }) => (
              <MenuItem
                key={value}
                onClick={() => {
                  toggleFilter(value);
                  toggleMenu();
                }}
                className="button-dropdown__menu-item"
                disabled={disabled}
              >
                {label}
              </MenuItem>
            ))}
          </ButtonDropdown>
        </div>
      )}
    </>
  );
};

export default ListFiltersListLayout;
