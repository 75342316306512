/* istanbul ignore file */
import React, { useMemo } from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_QUEUE, CREATE_RBS_TREE_NODE } from 'erp/rbs/shared/rbsService';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Data from 'shared/uibuilder/Data';

const CreateRbsSubnodeButton = ({
  isSmall,
  id,
  name,
  isVisible = true,
  outline = false,
  hasIcon = false,
  hasAccessToCreate = false,
}: {
  id?: string;
  name?: string;
  isSmall?: boolean;
  outline?: boolean;
  hasIcon?: boolean;
  isVisible?: boolean | ((data: Data) => boolean);
  hasAccessToCreate?: boolean | ((data: Data) => boolean);
}) => {
  const { getCreateUrl } = useRbsUrl();
  const { getValue } = useFieldHelper({
    source: 'id',
  });
  const { getValue: getName } = useFieldHelper({ source: 'name' });
  const parentNodeId = id || getValue();
  const parentNodeName = name || getName();
  const { data } = useShowContext();

  const isButtonVisible = useMemo(() => (typeof isVisible === 'function' ? isVisible(data) : isVisible), [
    isVisible,
    data,
  ]);
  const permissionToCheck = hasAccessToCreate ? null : [CREATE_RBS_TREE_NODE, CREATE_QUEUE];

  return isButtonVisible ? (
    <CreateButton
      url={`${getCreateUrl({ parentNodeId, parentNodeName })}`}
      label="Add subproject"
      key="create-rbs-node"
      permissionToCheck={permissionToCheck}
      hasIcon={hasIcon}
      openInNewTab
      outline={outline}
      variant="contained"
    />
  ) : null;
};

export default CreateRbsSubnodeButton;
