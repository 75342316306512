/* istanbul ignore file */
import { useBusinessEngineApi, useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import useSingletonPromise from 'shared/useSingletonPromise';
import { SearchParams } from 'shared/uibuilder/list/ListContext';
import { CandidateApplicationStatus } from 'erp/candidate/applications/shared/candidateApplicationService';

export const APPLICATION_PATH = '/candidates-applications';

export const READ_APPLICATION = 'CANDIDATE_APPLICATION_READ_LIST';
// TODO: Update after updating BE
export const GROUP_APPLICATION = 'CANDIDATE_APPLICATION_READ_LIST';
export const CHANGE_APPLICATION_STATUS = 'CANDIDATE_APPLICATION_FE_UPDATE';

export const APPLICATIONS_STAGES_ALIASES = {
  UNKNOWN: 'UNKNOWN',
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  CHECKLIST_0: 'CHECKLIST_0',
  CANDIDATE_CONTACTED: 'CANDIDATE_CONTACTED',
  CHECKLIST_1: 'CHECKLIST_1',
  PRE_SCREEN_SCHEDULED: 'PRE_SCREEN_SCHEDULED',
  PRE_SCREEN_PASSED: 'PRE_SCREEN_PASSED',
  INTERVIEW_SCHEDULED: 'INTERVIEW_SCHEDULED',
  INTERVIEW_PASSED: 'INTERVIEW_PASSED',
  POSITIVE_DECISION: 'POSITIVE_DECISION',
  OFFER_IS_READY: 'OFFER_IS_READY',
  OFFER_IS_MADE: 'OFFER_IS_MADE',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  PREBOADING_SCHEDULED: 'PREBOADING_SCHEDULED',
};

export const APPLICATIONS_STAGES = {
  [APPLICATIONS_STAGES_ALIASES.UNKNOWN]: 'Unknown stage',
  [APPLICATIONS_STAGES_ALIASES.APPLICATION_SUBMITTED]: 'Application created',
  [APPLICATIONS_STAGES_ALIASES.CHECKLIST_0]: 'Checklist 0 passed',
  [APPLICATIONS_STAGES_ALIASES.CANDIDATE_CONTACTED]: 'Candidate contacted',
  [APPLICATIONS_STAGES_ALIASES.CHECKLIST_1]: 'Checklist 1 passed',
  [APPLICATIONS_STAGES_ALIASES.PRE_SCREEN_SCHEDULED]: 'Pre-screen scheduled',
  [APPLICATIONS_STAGES_ALIASES.PRE_SCREEN_PASSED]: 'Pre-screen passed',
  [APPLICATIONS_STAGES_ALIASES.INTERVIEW_SCHEDULED]: 'Interviews scheduled',
  [APPLICATIONS_STAGES_ALIASES.INTERVIEW_PASSED]: 'Interviews passed',
  [APPLICATIONS_STAGES_ALIASES.POSITIVE_DECISION]: 'Positive decision made',
  [APPLICATIONS_STAGES_ALIASES.OFFER_IS_READY]: 'Offer is ready',
  [APPLICATIONS_STAGES_ALIASES.OFFER_IS_MADE]: 'Offer is made',
  [APPLICATIONS_STAGES_ALIASES.OFFER_ACCEPTED]: 'Offer accepted',
  [APPLICATIONS_STAGES_ALIASES.PREBOADING_SCHEDULED]: 'Preboarding scheduled',
};

export const APPLICATIONS_STAGES_ORDER = [
  APPLICATIONS_STAGES_ALIASES.UNKNOWN,
  APPLICATIONS_STAGES_ALIASES.APPLICATION_SUBMITTED,
  APPLICATIONS_STAGES_ALIASES.CHECKLIST_0,
  APPLICATIONS_STAGES_ALIASES.CANDIDATE_CONTACTED,
  APPLICATIONS_STAGES_ALIASES.CHECKLIST_1,
  APPLICATIONS_STAGES_ALIASES.PRE_SCREEN_SCHEDULED,
  APPLICATIONS_STAGES_ALIASES.PRE_SCREEN_PASSED,
  APPLICATIONS_STAGES_ALIASES.INTERVIEW_SCHEDULED,
  APPLICATIONS_STAGES_ALIASES.INTERVIEW_PASSED,
  APPLICATIONS_STAGES_ALIASES.POSITIVE_DECISION,
  APPLICATIONS_STAGES_ALIASES.OFFER_IS_READY,
  APPLICATIONS_STAGES_ALIASES.OFFER_IS_MADE,
  APPLICATIONS_STAGES_ALIASES.OFFER_ACCEPTED,
  APPLICATIONS_STAGES_ALIASES.PREBOADING_SCHEDULED,
];

export const COMMUNICATION_CHANNEL_ALIASES = {
  MESSENGER: 'MESSENGER',
  EMAIL: 'EMAIL',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
};

export const COMMUNICATION_CHANNEL = {
  [COMMUNICATION_CHANNEL_ALIASES.MESSENGER]: 'Messenger',
  [COMMUNICATION_CHANNEL_ALIASES.EMAIL]: 'Email',
  [COMMUNICATION_CHANNEL_ALIASES.SOCIAL_MEDIA]: 'Social Media',
};

export const APPLICATION_STAGE_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  NEW: 'NEW',
};

export const addStage = (data: any[], index?: number) => {
  const stagesKeys = Object.keys(APPLICATIONS_STAGES_ALIASES);

  const resultData = data.map((item: any) => {
    const { stageData } = item;
    let stageIndex: number;
    if (item?.stage) {
      stageIndex = item?.stagePosition;
    } else if (typeof index === 'number') {
      stageIndex = index;
    } else {
      stageIndex = 0;
    }

    // TODO: Remove mock data after updating BE
    return {
      ...item,
      stage: stagesKeys[stageIndex],
      progress: APPLICATIONS_STAGES_ORDER.filter(value => value !== APPLICATIONS_STAGES_ALIASES.UNKNOWN).map(
        (stage, i) => {
          let status = APPLICATION_STAGE_STATUS.COMPLETED;

          if (i === stageIndex) {
            status = APPLICATION_STAGE_STATUS.IN_PROGRESS;
          }

          if (i > stageIndex) {
            status = APPLICATION_STAGE_STATUS.NEW;
          }

          return {
            status,
            stepNumber: i,
            stepName: stage,
            stageData: {
              ...stageData,
              numberOfFollowups: Math.floor(Math.random() * 5),
              prescreenDate: '2024-04-09 13:57:03',
            },
          };
        },
      ),
      currency: 'USD',
      score: null,
      completedAt:
        item?.candidateApplication?.status === CandidateApplicationStatus.PROCESSED ||
        item.status === CandidateApplicationStatus.PROCESSED
          ? item?.candidateApplication?.updatedAt || item.updatedAt
          : null,
    };
  });

  return resultData;
};

const useApplicationService = () => {
  const { search: initialSearch, ...baseCrudRequests } = useCrudService({
    singleResourceUrl: '/candidates/applications/:id',
    listResourceUrl: '/candidates/applications',
    apiService: useKernelApi,
  });

  const { search: stagesSearch } = useCrudService({
    singleResourceUrl: '',
    listResourceUrl: '/candidate-applications',
    apiService: useBusinessEngineApi,
  });

  const { sendPostRequest } = useBusinessEngineApi();

  const searchForStage = async (stagePosition: number, params: SearchParams) => {
    const url = `/candidate-applications/stages/${stagePosition}/search`;
    const response = await sendPostRequest(url, params);

    return response.json();
  };

  // TODO: Update this function and UI testing and adding stages to BE. Now it uses random stages and grouping for applications data
  const search = useSingletonPromise('search-applications', async ({ groupedBy, ...params }: any) => {
    if (typeof groupedBy !== 'number') {
      const result = await stagesSearch(params);

      return {
        ...result,
        result: addStage(result.result),
      };
    }

    const result = await searchForStage(groupedBy, params);

    return {
      ...result,
      result: addStage(result.result, groupedBy),
    };
  });

  const searchForCandidate = (id: string) => async (params: any) => {
    return search({
      ...params,
      filter: {
        ...params.filter,
        'candidate.id': {
          eq: id,
        },
      },
    });
  };

  const searchWithFormattedFilters = (initialParams: any) => {
    const params = { ...initialParams };

    if (params.filter['vacancy.alias']) {
      params.filter['vacancy.alias'] = {
        in: params.filter['vacancy.alias']?.in.map((item: string) => item.split(' ')[0]),
      };
    }
    if (params.sort) {
      const newSort: any = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in params.sort) {
        if (Object.hasOwn(params.sort, key)) {
          const newKey = key.replace('candidateApplication.', '');
          newSort[newKey] = params.sort[key];
        }
      }
      params.sort = newSort;
    }

    return search(params);
  };

  return { ...baseCrudRequests, search, searchWithFormattedFilters, searchForCandidate };
};

export default useApplicationService;
