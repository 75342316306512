/* istanbul ignore file */
import React from 'react';
import { useEmployeeSalaryId, useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import EmployeeSalaryBreadcrumbs from 'erp/employee/salaries/shared/EmployeeSalaryBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const ShowSingleBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useEmployeeSalaryUrl();
  const employeeSalaryId = useEmployeeSalaryId();

  return (
    <EmployeeSalaryBreadcrumbs>
      <SingleBreadcrumbItem entity={employeeSalaryId} link={getSingleEntityUrl(employeeSalaryId)} />
      {children}
    </EmployeeSalaryBreadcrumbs>
  );
};

export default ShowSingleBreadcrumbs;
