import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import AccountingCycleHeader from '../shared/AccountingCycleHeader';
import AccountingCycleBreadcrumbsItem from './AccountingCycleBreadcrumbsItem';
import CloseAccountingCycleButton from '../shared/button/CloseAccountingCycleButton';
import { BaseFieldLayoutType, EnumField, TextField } from 'shared/uibuilder/field';
import {
  ACCOUNTING_CYCLE_EVENTS,
  ACCOUNTING_CYCLE_EVENTS_OPTIONS,
  ACCOUNTING_CYCLE_OPTIONS,
  ACCOUNTING_CYCLE_STATUS,
  AccountingCycleEventType,
  AccountingCycleType,
} from '../useAccountingCycle';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import DateField from 'shared/uibuilder/field/DateField';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import PerformPayrollDistributionButton from 'erp/payroll/payrollDistribution/shared/button/PerformPayrollDistributionButton';
import { LinkButton } from 'uibuilder/button';
import ComputeBonusesButton from 'financialAnalytic/expenses/shared/button/ComputeBonusesButton';
import PerformAssetCostProratingButton from 'financialAnalytic/assets/shared/button/PerformAssetCostProratingButton';
import AccrueRevenueButton from '../shared/button/AccrueRevenueButton';

const ViewAccountingCycleContent = () => {
  const { getValue: getEvents } = useFieldHelper<AccountingCycleEventType[]>({ source: 'events' });
  const { getValue: getId } = useFieldHelper<string>({ source: 'id' });
  const events = getEvents();
  const id = getId();
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const renderButton = (event: keyof typeof ACCOUNTING_CYCLE_EVENTS, disabled: boolean) => {
    switch (event) {
      case ACCOUNTING_CYCLE_EVENTS.PAYROLL_DISTRIBUTION:
        return (
          <PerformPayrollDistributionButton
            initialValue={id}
            disabledInput
            outline
            startIcon={null}
            disabled={disabled}
          />
        );
      case ACCOUNTING_CYCLE_EVENTS.ACCRUE_REVENUE:
        return <AccrueRevenueButton initialValue={id} disabledInput outline startIcon={null} disabled={disabled} />;
      case ACCOUNTING_CYCLE_EVENTS.COST_PRORATING:
        return <PerformAssetCostProratingButton disabledInput initialValue={id} disabled={disabled} />;
      case ACCOUNTING_CYCLE_EVENTS.RECONCILIATION:
        return (
          <LinkButton disabled={disabled} outline color="primary" url="/reconciliation">
            Reconciliation Page
          </LinkButton>
        );
      case ACCOUNTING_CYCLE_EVENTS.COMPUTE_PERFORMANCE_BASED_BONUS:
        return <ComputeBonusesButton disabled={disabled} disabledInput />;
      default:
        return <></>;
    }
  };

  return (
    <ShowPageLayout
      entityHeader={<AccountingCycleHeader />}
      breadcrumbs={<AccountingCycleBreadcrumbsItem />}
      headerButtons={[<CloseAccountingCycleButton />]}
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField source="id" label="Cycle" />
          <EnumField source="status" label="Status" options={ACCOUNTING_CYCLE_OPTIONS} />
        </SectionRow>

        <>
          {Object.values(ACCOUNTING_CYCLE_EVENTS).map(event => {
            const eventData = events?.find((item: AccountingCycleEventType) => item?.eventType === event);
            return (
              <SectionRow key={event}>
                <BaseFieldLayout label="Event Type" value={ACCOUNTING_CYCLE_EVENTS_OPTIONS[event]} />
                <BaseFieldLayout
                  label="Triggered By"
                  value={<EmployeeLinkFieldWithNameLoading externalId={eventData?.triggeredBy || null} />}
                />
                <FullDateField label="Сompleted At" value={eventData?.createdAt || null} />
                {renderButton(event as keyof typeof ACCOUNTING_CYCLE_EVENTS, !!eventData?.createdAt)}
              </SectionRow>
            );
          })}
        </>

        <SectionRow>
          <EmployeeLinkFieldWithNameLoading
            isVisible={(data: AccountingCycleType) => data?.status === ACCOUNTING_CYCLE_STATUS.CLOSE}
            label="Closed By"
            source="closedBy"
          />
          <DateField
            isVisible={(data: AccountingCycleType) => data?.status === ACCOUNTING_CYCLE_STATUS.CLOSE}
            source="closedAt"
            label="Closed AT"
          />
        </SectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewAccountingCycleContent;
