import React, { useEffect } from 'react';
import LoadMultiSelectInput from 'shared/uibuilder/form/input/LoadMultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useTeamService from 'erp/rbs/shared/teamService';
import { useFormContext, FormFieldsData } from 'shared/uibuilder/form/FormContext';

interface TeamInputProps {
  source: string;
  label?: string;
  nameSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  props?: any;
  isCreateForm?: boolean;
}

const TeamInput = ({ nameSource = 'fullName', isCreateForm, ...props }: TeamInputProps) => {
  const { data = {}, isDirty } = useFormContext();
  const { search } = useTeamService();
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const source = getSource();

  const parentOnChange = getRawOnChangeCallback() || (() => {});

  const onChangeCallback = (obj: any) => {
    const mappedArray = obj[source] || [];

    parentOnChange({
      [source]: mappedArray.map((item: any) => ({
        id: item.value,
        [nameSource]: item.label,
      })),
    });
  };

  const value: any = getValue() || [];

  const mappedValue = value.map((item: any) => ({ value: item.id, label: item[nameSource] }));

  const noOptionsMessage = () => (value?.length ? 'No more team members found' : 'Team members are not found');

  useEffect(() => {
    if (isDirty) {
      parentOnChange({
        [source]: [],
      });
    }
    // Suppressed warnings because we only need to call useEffect callback only if data.parentNodeId is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.parentNodeId]);

  const formatDataForMultiSelectInput = (selectData: any[]) => {
    if (!selectData) {
      return [];
    }

    return selectData.map(item => ({
      value: item.id,
      label: item[nameSource],
    }));
  };

  return (
    <LoadMultiSelectInput
      {...props}
      loadSuggestionsMethod={() => search(isDirty || isCreateForm ? data.parentNodeId : data.id)}
      onChangeCallback={onChangeCallback}
      mapResults={formatDataForMultiSelectInput}
      value={mappedValue}
      noOptionsMessage={noOptionsMessage}
      deps={[data.parentNodeId]}
    />
  );
};

export default TeamInput;
