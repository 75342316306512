/* istanbul ignore file */
import { Switch } from 'react-router-dom';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import React from 'react';
import EmployeeFeedbacksList from './feedbackList';
import { FEEDBACKS_ABOUT_ME } from './EmployeeFeedbacksService';

const FEEDBACKS_PATH = 'feedbacks';

export const useFeedbacksRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}/${FEEDBACKS_PATH}`,
  });
};

export const useFeedbacksUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();
  const listUrl = `${employeeLink}/${FEEDBACKS_PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${employeeLink}/${FEEDBACKS_PATH}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

const FeedbacksRouter = () => {
  const { listRoute } = useFeedbacksRoute();

  return (
    <Switch>
      <ProtectedRoute path={`${listRoute}`} permissionToCheck={FEEDBACKS_ABOUT_ME} exact>
        <EmployeeFeedbacksList />
      </ProtectedRoute>

      <Route component={Page404} />
    </Switch>
  );
};

export default FeedbacksRouter;
