/* istanbul ignore file */
// libs
import React from 'react';
import classnames from 'classnames';
// styles
import './HorizontalMenu.scss';

interface HorizontalMenuProps {
  children: React.ReactNode;
  className?: string;
}

const HorizontalMenu = ({ children, className }: HorizontalMenuProps) => {
  return <div className={classnames('horizontal-menu-container mt-0', className)}>{children}</div>;
};

export default HorizontalMenu;
