// libs
import React from 'react';
import { arrayOf, string } from 'prop-types';
import { childrenAsNodesType } from 'shared/prop-types';
// components
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import SensitiveDataPlug from 'shared/uibuilder/sensitiveData/SensitiveDataPlug';
import ToggleSensitiveDataButton from 'shared/uibuilder/sensitiveData/buttons/ToggleSensitiveDataButton';
// services
import { useSensitiveDataService } from 'shared/uibuilder/sensitiveData';
// styles
import './SensitiveRelationListPageLayout.scss';

const SensitiveShowPageLayout = ({ buttons, children, timerId, ...other }) => {
  const { isTimerActive, isShown, toggleSensitiveData, isSensitiveDataLoading } = useSensitiveDataService({ timerId });

  const getButtons = () => {
    const buttonsArray = [...buttons];
    const toggleButton = (
      <ToggleSensitiveDataButton
        key="toggle-sensitive-data"
        isShown={isShown}
        onClick={() => toggleSensitiveData()}
        disabled={isSensitiveDataLoading}
        isTimerActive={isTimerActive}
      />
    );

    buttonsArray.unshift(toggleButton);

    return buttonsArray;
  };

  return (
    <ShowPageLayout buttons={getButtons()} {...other}>
      {isShown ? children : <SensitiveDataPlug />}
    </ShowPageLayout>
  );
};

SensitiveShowPageLayout.propTypes = {
  buttons: arrayOf(childrenAsNodesType),
  children: childrenAsNodesType,
  timerId: string,
};

SensitiveShowPageLayout.defaultProps = {
  buttons: [],
  children: null,
  timerId: undefined,
};

export default SensitiveShowPageLayout;
