/* istanbul ignore file */
import React, { useEffect } from 'react';
// eslint-disable-next-line
import type from '@mui/material/themeCssVarsAugmentation';
import ScrollToTop from 'shared/uibuilder/ScrollToTop';
import SidebarNav from 'shared/layout/SidebarNav';
import DefaultFooter from 'layout/Default/DefaultFooter';
import DefaultHeader from 'layout/Default/DefaultHeader';
import AppBar from '@mui/material/AppBar';
import Box from 'uibuilder/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { DRAWER_WIDTH } from 'shared/layout/AppSidebarNav';
import { AppSidebarProvider } from 'shared/layout/AppSidebarNav/AppSidebarNavContext';
import useAuthenticationService from 'authentication/authenticationService';
import useAsyncValue from 'shared/asyncHelper';
import { Link, useLocation } from 'react-router-dom';
import { ONE_STOP_PATH } from 'oneStop/OneStopRouterHelper';
import Logo from 'images/logo.svg';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { USER_SETTINGS_PATH } from 'user/Settings/UserSettingsRouter';
// @ts-ignore
import { getTimezoneWidget } from '@timezone-change-widget/timezone-change-widget';
import '@timezone-change-widget/timezone-change-widget/build/styles.css';

const DefaultLayout = ({ children }: { children?: React.ReactChild }) => {
  const { isAuthenticated } = useAuthenticationService();
  const isUserAuthenticated = useAsyncValue(isAuthenticated);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { getUserTimezone } = useDateService();
  const userTimezone = getUserTimezone();
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (isUserAuthenticated) {
      getTimezoneWidget(userTimezone, USER_SETTINGS_PATH);
    }
  }, [userTimezone, isUserAuthenticated, pathname]);

  if (isUserAuthenticated === null) {
    return null;
  }

  return (
    <Box sx={{ overflowX: 'hidden', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <AppSidebarProvider value={{ mobileOpen, handleDrawerToggle }}>
          <AppBar
            elevation={0}
            position="fixed"
            sx={{
              left: {
                xs: 0,
                lg: isUserAuthenticated ? DRAWER_WIDTH : 0,
              },
              minHeight: { sm: isUserAuthenticated ? '68px' : '50px' },
              justifyContent: 'center',
              width: {
                lg: isUserAuthenticated ? `calc(100vw - ${DRAWER_WIDTH})` : '100%',
              },
            }}
          >
            <Toolbar>
              {isUserAuthenticated && (
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: '12px', display: { lg: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box sx={{ display: { xs: 'block', md: 'none' }, marginRight: '20px' }}>
                <Link to={ONE_STOP_PATH}>
                  <img src={Logo} alt="Syberry logo" width={75} />
                </Link>
              </Box>
              <DefaultHeader />
            </Toolbar>
          </AppBar>
          {isUserAuthenticated ? <SidebarNav /> : null}
        </AppSidebarProvider>
        <Box
          component="main"
          className="animated fadeIn"
          sx={{
            flex: '1 1 auto',
            minWidth: 0,
            p: '17px 16px',
            marginTop: { xs: isUserAuthenticated ? '60px' : '50px', sm: '68px' },
          }}
        >
          {children}
        </Box>
      </Box>
      <DefaultFooter />
      <ScrollToTop />
    </Box>
  );
};

DefaultLayout.defaultProps = {
  children: '',
};

export default DefaultLayout;
