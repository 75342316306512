/* istanbul ignore file */
import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useArtifactDownloadHelper from 'artifact/shared/artifactDownloadHelper';
import InputLoader from 'shared/uibuilder/InputLoader';

type DownloadArtifactButtonProps = {
  source: string;
};

const DownloadArtifactButton = (props: DownloadArtifactButtonProps) => {
  const { getValue } = useFieldHelper<any>(props);
  const { download } = useArtifactDownloadHelper();
  const file = getValue();
  const [loading, setLoading] = useState(false);

  const onClick = async (e: any) => {
    e.currentTarget.blur();
    setLoading(true);
    await download(file?.id, file?.title);
    setLoading(false);
  };

  return file ? (
    <Button {...props} onClick={onClick} outline>
      Download
      {loading && <InputLoader className="files-list__files-loader ml-2" />}
    </Button>
  ) : null;
};

export default DownloadArtifactButton;
