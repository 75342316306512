import React, { useState, useEffect } from 'react';
import useContactService from 'crm/contact/shared/contactService';
import { Dropdown } from 'shared/uibuilder/form/input';
import { oneOfType, string, number } from 'prop-types';

export const PAGE_SIZE = 30;

const AccountContactsInput = ({ accountId, ...props }) => {
  const [contacts, setContacts] = useState([]);
  const { search } = useContactService();

  useEffect(() => {
    const loadContacts = async () => {
      const { result } = await search({
        filter: {
          'account.alias': { eq: accountId },
        },
        pageSize: PAGE_SIZE,
      });

      const mappedContacts = result.map(contact => ({
        label: `${contact.firstName} ${contact.lastName}`,
        value: contact.id,
      }));

      setContacts(mappedContacts);
    };

    if (accountId) {
      loadContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return <Dropdown options={contacts} {...props} />;
};

AccountContactsInput.propTypes = {
  accountId: oneOfType([string, number]),
};

AccountContactsInput.defaultProps = {
  accountId: null,
};

export default AccountContactsInput;
