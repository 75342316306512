/* istanbul ignore file */
import React, { useMemo, useRef } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import QuickFiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/QuickFiltersLayout';

const QuickFilters = ({ children }: { children: React.ReactElement[] }) => {
  const { data = {} } = useFormContext();
  const menuRef = useRef<HTMLDivElement>(null);
  const activeElement = menuRef?.current?.querySelector('.button-dropdown__menu-item:disabled');

  // Suppressed warnings because we should update value if form data were updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => activeElement?.textContent || '', [activeElement, data]);

  return (
    <div ref={menuRef} className="mb-3">
      <QuickFiltersLayout displayedValue={value}>{children}</QuickFiltersLayout>
    </div>
  );
};

export default QuickFilters;
