import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';
import { CurrencyInput, NumberInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

type MilestonesListInputsProps = {
  source: string;
  value: any;
};

const MilestonesListInputs = ({ ...props }: MilestonesListInputsProps) => {
  const { getSource } = useInputHelper(props);
  const source = getSource();

  return (
    <FormRow>
      <TextInput source={`${source}.id`} label="ID" />
      <TextInput source={`${source}.name`} label="Name" />
      <CurrencyInput source={`${source}.amount`} label="Amount" suffix={CURRENCIES.USD} maxDecimal={2} />

      <NumberInput source={`${source}.completionRate`} label="Completion Rate, %" />
    </FormRow>
  );
};

export default MilestonesListInputs;
