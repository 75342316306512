/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { usePurchaseOrderUrl } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';

const PurchaseOrderBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = usePurchaseOrderUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Purchase Orders" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default PurchaseOrderBreadcrumbs;
