/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewRbsNodePageMenu from 'erp/rbs/Show/ViewRbsNodePageMenu';
import RbsEntityHeader from 'erp/rbs/shared/RbsEntityHeader';
import SingleRbsNodeBreadcrumbs from 'erp/rbs/shared/SingleRbsNodeBreadcrumbs';
import EditRbsNodeButton from 'erp/rbs/shared/button/EditRbsNodeButton';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import DeleteRbsNodeButton from 'erp/rbs/shared/button/DeleteRbsNodeButton';
import CreateRbsSubnodeButton from 'erp/rbs/shared/button/CreateRbsSubnodeButton';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import { TextField, HtmlField } from 'shared/uibuilder/field';
import useRbsService, { UPDATE_RBS_TREE_NODE, RbsNodeType } from 'erp/rbs/shared/rbsService';
import RbsTreeField from 'erp/rbs/shared/field/RbsTreeField';
import { useRbsId } from 'erp/rbs/RbsRouter';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import TeamEmployeeLinkField from 'erp/rbs/shared/field/TeamEmployeeLinkField';
import { READ_PROJECT } from '../../project/shared/projectService';
import ProjectLinkField from '../../project/shared/field/ProjectLinkField';
import ProjectField from '../../../redmine/ProjectField';

const ViewRbsNode = () => {
  const id = useRbsId();
  const { getById } = useRbsService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        buttons={[
          <EditRbsNodeButton
            key="edit-rbs"
            icon={<i className="fa fa-edit" />}
            permissionToCheck={UPDATE_RBS_TREE_NODE}
          />,
          <DeleteRbsNodeButton isVisible={data => !data?.getValueBySource('children')} key="delete-rbs" />,
        ]}
        menu={<ViewRbsNodePageMenu />}
        breadcrumbs={<SingleRbsNodeBreadcrumbs />}
        entityHeader={<RbsEntityHeader />}
      >
        <ShowSection title="Responsibility Information">
          <SectionRow>
            <TextField source="name" />
            <TextField source="parentNode.name" label="Parent Node" />
            <EmployeeLinkField source="attributes.owner.alias" nameSource="attributes.owner.nameEn" label="Owner" />
            <EmployeeLinkField
              source="attributes.secondOwner.alias"
              nameSource="attributes.secondOwner.nameEn"
              label="Second Owner"
              isVisible={(data: any) => data?.getValueBySource('nodeType') === RbsNodeType.PROJECT}
            />
          </SectionRow>
          <BigSectionRow>
            <FieldListLayout source="attributes.team" label="Team" isRowDirection>
              <TeamEmployeeLinkField />
            </FieldListLayout>
          </BigSectionRow>
          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField source="attributes.goal" label="Goal" />
            </ReplaceUrlWithLink>
          </BigSectionRow>
        </ShowSection>
        <ShowSection
          title="Project Information"
          isVisible={data => data?.getValueBySource('nodeType') === RbsNodeType.PROJECT}
        >
          <SectionRow>
            <ProjectLinkField
              permissionToCheck={READ_PROJECT}
              label="Project"
              source="attributes.project.id"
              nameSource="attributes.project.name"
            />
            <ProjectField source="attributes.alias" nameSource="attributes.alias" label="Redmine Project Alias" />
            <TextField source="attributes.project.sowId" />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Subprojects">
          <div style={{ minHeight: '54px', transform: 'translateY(-71px)' }}>
            <RbsTreeField className="responsibility-tree-field-header">
              <CreateRbsSubnodeButton
                hasIcon
                outline={false}
                isVisible={data => data?.getValueBySource('nodeType') === RbsNodeType.PROJECT}
              />
            </RbsTreeField>
          </div>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewRbsNode;
