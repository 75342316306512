/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import EmployeeAccountsCreationForm from './externalaccounts/creation/EmployeeAccountsCreationForm';
import AccountCreationContext from './externalaccounts/AccountCreationContext';
import { RUN_COMMAND_PERMISSION } from './externalaccounts/externalAccountsService';
import InternAccountsCreationForm from './externalaccounts/creation/InternAccountsCreationForm';
import EmployeeAccountsDeprovisionForm from './externalaccounts/deprovision/EmployeeAccountsDeprovisionForm';
import InternAccountsDeprovisionForm from './externalaccounts/deprovision/InternAccountsDeprovisionForm';

export const PRIVILEGED_ROUTES_PATH = '/privileged';
export const CREATE_USER_ACCOUNTS_PATH = '/privileged/employees/:employeeId/external-accounts';
export const CREATE_CANDIDATE_ACCOUNTS_PATH = '/privileged/candidates/:candidateId/external-accounts';
export const DEPROVISION_CANDIDATE_ACCOUNTS_PATH = '/privileged/candidates/:candidateId/external-accounts/deprovision';
export const DEPROVISION_USER_ACCOUNTS_PATH = '/privileged/employees/:employeeId/external-accounts/deprovision';

export const usePrivilegedEmployeeId = () => useParams<Dictionary<string>>().employeeId;
export const usePrivilegedCandidateId = () => (useParams<Dictionary<string>>().candidateId as unknown) as number;

const PrivilegedRouter = () => (
  <ProtectedRouter basePath={PRIVILEGED_ROUTES_PATH}>
    <Switch>
      <Route path={CREATE_USER_ACCOUNTS_PATH} exact>
        <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
          <EmployeeAccountsCreationForm />
        </AccountCreationContext>
      </Route>

      <Route path={DEPROVISION_USER_ACCOUNTS_PATH} exact>
        <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
          <EmployeeAccountsDeprovisionForm />
        </AccountCreationContext>
      </Route>

      <Route path={CREATE_CANDIDATE_ACCOUNTS_PATH} exact>
        <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
          <InternAccountsCreationForm />
        </AccountCreationContext>
      </Route>

      <Route path={DEPROVISION_CANDIDATE_ACCOUNTS_PATH} exact>
        <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
          <InternAccountsDeprovisionForm />
        </AccountCreationContext>
      </Route>

      <Route component={Page404} />
    </Switch>
  </ProtectedRouter>
);

PrivilegedRouter.getRouterPath = () => PRIVILEGED_ROUTES_PATH;

export default PrivilegedRouter;
