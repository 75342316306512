/* istanbul ignore file */
import React from 'react';
import SingleLeavesBreadcrumbs from 'erp/leave/shared/SingleLeavesBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateLeaveBreadcrumbs = () => {
  return (
    <SingleLeavesBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleLeavesBreadcrumbs>
  );
};

export default UpdateLeaveBreadcrumbs;
