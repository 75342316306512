/* istanbul ignore file */
import React from 'react';
import { get } from 'lodash';
import LedgerTransfersList from 'financialAnalytic/ledger/shared/field/LedgerTransfersList';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import moment from 'moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const TransfersListField = ({
  businessObjectType,
  businessObjectId,
  startDateSource,
  endDateSource,
  hasTransferIdField,
}: {
  businessObjectType: string;
  businessObjectId: string;
  startDateSource?: string;
  endDateSource?: string;
  hasTransferIdField?: boolean;
}) => {
  const { data: showContextData } = useShowContext();
  const defaultFilter: Dictionary<any> = {};
  const status = get(showContextData, 'status');

  if (startDateSource) {
    const startDateValue = moment(get(showContextData, startDateSource) || '');
    const endDateValue =
      endDateSource && get(showContextData, endDateSource)
        ? moment(get(showContextData, endDateSource))
        : startDateValue;
    const startDate = startDateValue.isAfter(endDateValue) ? endDateValue : startDateValue;
    const endDate = startDateValue.isBefore(endDateValue) ? endDateValue : startDateValue;
    const periodStart = moment(startDate)
      .startOf('month')
      .format(DATE_FORMAT.API_ONLY_DATE);
    const periodEnd = moment(endDate)
      .endOf('month')
      .format(DATE_FORMAT.API_ONLY_DATE);

    defaultFilter.period = {
      ge: periodStart,
      le: periodEnd,
    };
  }

  return (
    <LedgerTransfersList
      key={`transfers-${status}-${businessObjectId}-${businessObjectType}`}
      title="Transfers"
      filter={{
        businessObjectType,
        businessObjectId,
        includeTransfers: true,
      }}
      defaultFilter={defaultFilter}
      hasAccountLinkField
      hasTransferIdField={hasTransferIdField}
    />
  );
};

export default TransfersListField;
