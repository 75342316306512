import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { RECONCILIATION_TYPES_OPTIONS } from '../../useReconciliationService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

type ReconciliationDropdownFilterProps = {
  source: string;
  label: string;
};

const ReconciliationDropdownFilter = (props: ReconciliationDropdownFilterProps) => {
  return (
    <DropDownFilter {...props}>
      <EnumDropdown {...props} options={RECONCILIATION_TYPES_OPTIONS} />
    </DropDownFilter>
  );
};

export default ReconciliationDropdownFilter;
