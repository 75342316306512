import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { TogglerInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import ModalWindow from 'shared/uibuilder/ModalWindow';

const IsFinalChangesToggle = ({ source }: { source: string }) => {
  const { getValue, getRawOnChangeCallback } = useInputHelper({ source });
  const [isConfirmationShown, setIsConfirmationShown] = useState(false);
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});
  const value = getValue();

  const onShareToggleClick = (data: Dictionary<boolean>) => {
    if (data[source]) {
      setIsConfirmationShown(true);
    } else {
      hideConfirmation();
      rawOnChangeCallback({
        [source]: data[source],
      });
    }
  };

  const hideConfirmation = () => {
    setIsConfirmationShown(false);
  };

  const onConfirm = () => {
    hideConfirmation();
    rawOnChangeCallback({
      [source]: true,
    });
  };

  const confirmBtn = (
    <Button key="modal-confirm-button" onClick={onConfirm}>
      Confirm
    </Button>
  );

  return (
    <Box
      sx={{
        marginTop: '-3px',
        marginBottom: '-6px',
      }}
    >
      <TogglerInput
        source={source}
        value={!!value}
        checkedValue="Yes"
        uncheckedValue="No"
        label="Are final changes?"
        onChangeCallback={onShareToggleClick}
        isControlledInput
        className="share-feedback-toggle"
      />
      <ModalWindow
        isOpen={isConfirmationShown}
        title="Are you sure?"
        buttons={[confirmBtn]}
        onToggle={hideConfirmation}
      >
        Once the form is submitted, you cannot change the invoice document
      </ModalWindow>
    </Box>
  );
};

export default IsFinalChangesToggle;
