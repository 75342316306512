/* eslint-disable react/no-danger */
import React from 'react';
import { isArray, isObject } from 'lodash';
import { joinElements } from 'shared/uibuilder/helper';
import SanitizedHtml from 'shared/security/SanitizedHtml';
import useCustomHtmlTagHelper from 'uibuilder/customHtmlTagsHelper';

export type ErrorMessage = string | string[];

export interface BaseInputErrorsProps {
  errorMessages?: Nullable<ErrorMessage[]>;
  errorMessageMapper?: (error: ErrorMessage) => string | string[];
}

const formatError = (error: string) => {
  return error.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$1&#8209;$2&#8209;$3');
};

const BaseInputErrors = ({ errorMessages = [], errorMessageMapper }: BaseInputErrorsProps) => {
  const { mapTags } = useCustomHtmlTagHelper();
  if (!errorMessages || !errorMessages.length) {
    return null;
  }

  let displayedErrors: (React.ReactElement | ErrorMessage)[] = errorMessages;

  if (errorMessageMapper) {
    displayedErrors = errorMessages.map(errorMessageMapper);
  }

  displayedErrors = displayedErrors.map(error =>
    typeof error === 'string' ? (
      <SanitizedHtml wrapperTag="span" className="form-error" html={formatError(mapTags(error))} key={error} />
    ) : (
      <span className="form-error" key={isArray(error) || !isObject(error) ? error.toString() : error.key}>
        {error}
      </span>
    ),
  );

  return <>{joinElements(displayedErrors, '')}</>;
};

export default BaseInputErrors;
