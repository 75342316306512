import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useSyberryTodayService, {
  getSyberryTodayError,
  READ_SYBERRY_TODAY_PROJECTS,
} from 'syberryToday/useSyberryTodayService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useAuthorization from 'shared/authorization/authorizationService';
import useFeatureToggle, { Features } from 'featuretoggle';

export const SET_PROJECTS = 'SET_ST_PROJECTS';

export type ProjectsData = {
  [key: string]: any;
};

export const setProjectsData = (payload: any) => ({
  type: SET_PROJECTS,
  payload,
});

export interface SyberryTodayProjectsDataState {
  projects: Nullable<any[]>;
}

const initialState = {
  projects: null,
};

export const syberryTodayProjectsDataReducer = (state = initialState, action: any) => {
  if (action.type === SET_PROJECTS) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const useSyberryTodayProjects = () => {
  const dispatch = useDispatch();
  const syberryTodayProjectsData = useSelector(
    (state: { syberryTodayProjectsData: SyberryTodayProjectsDataState }) => state?.syberryTodayProjectsData,
  );
  const { projects }: ProjectsData = syberryTodayProjectsData || {};
  const { getProjects } = useSyberryTodayService();
  const { addMessage } = useMessageService();
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted(READ_SYBERRY_TODAY_PROJECTS);
  const { isFeatureEnabled } = useFeatureToggle();

  const updateProjectsData = (updatedProjectsData: ProjectsData) => {
    dispatch(
      setProjectsData({
        ...syberryTodayProjectsData,
        ...updatedProjectsData,
      }),
    );
  };

  const loadProjects = async () => {
    try {
      const { result = [] } = await getProjects();

      updateProjectsData({ projects: result });
    } catch (e) {
      const errorMessage = await getSyberryTodayError(e, "Can't load projects");

      addMessage(new ErrorMessage(errorMessage));

      updateProjectsData({ projects: [] });
    }
  };

  useEffect(() => {
    if (!projects && hasPermissions && isFeatureEnabled(Features.SYBERRY_TODAY)) {
      loadProjects();
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    projects,
  };
};

export default useSyberryTodayProjects;
