import React from 'react';
import { CORPORATE_EVENT_TYPES } from 'financialAnalytic/corporateEvents/useCorporateEventService';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import CorporateEventStatusDropdown from 'financialAnalytic/corporateEvents/shared/input/CorporateEventStatusDropdown';
import CorporateEventLocationDropdown from 'financialAnalytic/corporateEvents/shared/input/CorporateEventLocationDropdown';
import CorporateEventTypeDropdown from 'financialAnalytic/corporateEvents/shared/input/CorporateEventTypeDropdown';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import EmployeesInput from 'financialAnalytic/corporateEvents/shared/input/EmployeesInput';
import BusinessUnitsInput from 'financialAnalytic/corporateEvents/shared/input/BusinessUnitsInput';
import ApplicationsInput from 'financialAnalytic/corporateEvents/shared/input/ApplicationsInput';

const CorporateEventForm = () => {
  const isCandidateApplicationsSectionVisible = (type: string) => type === CORPORATE_EVENT_TYPES.RECRUITING_EVENT;
  const isBusinessUnitsSectionVisible = (type: string) => type === CORPORATE_EVENT_TYPES.TEAM_EVENT;

  return (
    <>
      <FormSection title="Corporate Event Details">
        <FormRow>
          <TextInput source="name" />
        </FormRow>
        <FormRow>
          <TextArea source="description" />
        </FormRow>
        <FormRow>
          <CorporateEventStatusDropdown source="status" label="Status" placeholder="Select Status" />
          <CorporateEventLocationDropdown source="location" label="Location" placeholder="Select Location" />
          <CorporateEventTypeDropdown source="type" label="Type" placeholder="Select Type" />
        </FormRow>
        <FormRow>
          <DateInput source="date" label="Event date" placeholder="Select Event Date" />
        </FormRow>
      </FormSection>
      <FormSection title="Participants">
        <EmployeesInput source="participants" label="Participants" />
      </FormSection>
      <FormSection title="Candidate Applications" isVisible={data => isCandidateApplicationsSectionVisible(data.type)}>
        <ApplicationsInput source="candidateApplications" label="Candidate Applications" />
      </FormSection>
      <FormSection title="Business Units" isVisible={data => isBusinessUnitsSectionVisible(data.type)}>
        <BusinessUnitsInput source="businessUnits" label="Business Units" />
      </FormSection>
    </>
  );
};

export default CorporateEventForm;
