/* istanbul ignore file */
import React from 'react';
import { any, element, objectOf } from 'prop-types';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import { ResourceData } from '../../crud/baseCrudService';
import ShowData from 'shared/uibuilder/show/ShowData';

type TimelineRecordWrapperProps = {
  entity: ResourceData;
  children: React.ReactElement;
};

const TimelineRecordWrapper = ({ entity, children }: TimelineRecordWrapperProps) => {
  const dataInstance = new ShowData(entity);

  return (
    <ShowContextProvider
      value={{
        data: dataInstance,
      }}
    >
      {children}
    </ShowContextProvider>
  );
};

TimelineRecordWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  entity: objectOf(any).isRequired,
  children: element.isRequired,
};

export default TimelineRecordWrapper;
