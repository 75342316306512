/* istanbul ignore file */
import React from 'react';
import { SubmitProps } from 'shared/useSubmit';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';

const errorMessage = "Can't set this specialization as primary";
const displayMessage = 'Do you really want to set this qualification as primary?';

interface SetPrimaryQualificationButtonProps extends SubmitProps {
  permissionToCheck?: string;
}

const SetPrimaryQualificationButton = (props: SetPrimaryQualificationButtonProps) => {
  return (
    <ButtonWithConfirmation {...props} displayMessage={displayMessage} afterSubmit={{ errorMessage }}>
      Set as Primary
    </ButtonWithConfirmation>
  );
};

export default SetPrimaryQualificationButton;
