/* istanbul ignore file */
import React from 'react';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import SingleOpportunityBreadcrumbs from 'crm/opportunity/shared/SingleOpportunityBreadcrumbs';
import BreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/BreadcrumbItem';

const PresaleOpportunityTimelineBreadcrumbs = () => {
  const id = useOpportunityId();
  const { getPresaleTimelineUrl } = useOpportunityUrl();

  return (
    <SingleOpportunityBreadcrumbs>
      <BreadcrumbItem link={getPresaleTimelineUrl(id)}>Operations</BreadcrumbItem>
    </SingleOpportunityBreadcrumbs>
  );
};

export default PresaleOpportunityTimelineBreadcrumbs;
