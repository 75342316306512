/* istanbul ignore file */
import oneStopLeaveValidation from './oneStopLeaveValidation';

export default {
  employeeId: {
    type: 'string',
    required: {
      value: true,
      message: 'Please select value from result list',
    },
  },
  status: {
    type: 'enum',
    required: true,
    allowedTransitions: {
      value: {
        '': ['IN_APPROVAL', 'APPROVED', 'REGISTERED', 'REJECTED'],
        IN_APPROVAL: ['APPROVED', 'REGISTERED', 'REJECTED'],
        APPROVED: ['IN_APPROVAL', 'REGISTERED', 'REJECTED'],
        REGISTERED: ['REJECTED'],
        REJECTED: [],
      },
    },
  },
  extendedEndDate: {
    type: 'date',
    minDate: {
      value: '$endDate',
      message: 'Please choose date further than End Date',
    },
    maxDate: '$allowedExtensionDate',
  },
  applicationScansIds: {
    type: 'array',
    maxSize: 5,
  },
  ...oneStopLeaveValidation,
};
