import { useKernelApi } from 'api';
import { EMPLOYEE_TYPES_ALIAS, mapSortingParameters } from 'erp/employee/employeeService';
import { ResourceData } from '../../shared/crud/baseCrudService';

export const RESOURCE_URL = '/employees/suggestions';

/**
 * Employee suggestions service.
 *
 * */
const useEmployeeSuggestionsService = () => {
  const { sendPostRequest } = useKernelApi();

  const baseSearch = async ({ pageNumber = 0, sort = { 'nameEn.lastName': 'ASC' }, ...params }: ResourceData = {}) => {
    const request = {
      ...params,
      sort: mapSortingParameters(sort),
      pageSize: 30,
      pageNumber,
    };
    const response = await sendPostRequest(`${RESOURCE_URL}/search`, request);
    return response.json();
  };
  const search = async ({ filter = {}, ...params }: ResourceData = {}) => {
    const request = {
      ...params,
      filter: {
        archivedStatus: {
          eq: 'ACTIVE',
        },
        employeeType: {
          ne: EMPLOYEE_TYPES_ALIAS.CONTRACTOR,
        },
        ...filter,
      },
    };
    return baseSearch(request);
  };
  const advancedSearch = async ({ filter = {}, ...params } = {}) => {
    return baseSearch({
      ...params,
      type: 'advanced',
      filterGroup: filter,
    });
  };
  const searchWithContractors = async ({ filter = {}, ...params }: ResourceData = {}) => {
    const request = {
      ...params,
      filter: {
        archivedStatus: {
          eq: 'ACTIVE',
        },
        ...filter,
      },
    };
    return baseSearch(request);
  };

  const searchAll = async (params = {}) => {
    let totalPages = 0;
    let currentPage = 0;
    let result: Dictionary<string>[] = [];

    do {
      // eslint-disable-next-line no-await-in-loop
      const data = await search({
        ...params,
        pageNumber: currentPage,
      });
      // eslint-disable-next-line no-await-in-loop

      currentPage += 1;
      totalPages = data.totalPages;
      result = [...result, ...(data.result || [])];
    } while (currentPage < totalPages);

    return result;
  };

  return {
    search,
    advancedSearch,
    searchAll,
    searchWithContractors,
  };
};

export default useEmployeeSuggestionsService;
