import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import SowBreadcrumbs from '../../shared/SowBreadcrumbs';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Typography from 'uibuilder/Typography';
import CreateSowButton from '../../shared/buttons/CreateSowButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const EmptySowPage = () => {
  const { data } = useShowContext();
  const sowId = data.getValueBySource('sowId');

  return (
    <ShowPageLayout
      entityHeader={<ProjectEntityHeader />}
      menu={<ViewProjectPageMenu />}
      breadcrumbs={<SowBreadcrumbs />}
    >
      <ShowSection
        justifyContent="space-between"
        title="No data available"
        actions={[sowId ? <CreateSowButton /> : null]}
      >
        <Typography>There`s nothing here now. You can add SOW or come back later.</Typography>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default EmptySowPage;
