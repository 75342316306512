/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CandidateApplicationStatusDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationStatusDropdown';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';

const ApplicationStatusFilter = (props: Omit<ConditionProps, 'children'> & { label: string }) => {
  return (
    <DropDownFilter {...props}>
      <CandidateApplicationStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default ApplicationStatusFilter;
