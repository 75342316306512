/* istanbul ignore file */
import React from 'react';
import SingleCandidateBreadcrumbs from 'erp/candidate/shared/SingleCandidateBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const CandidateOfferListBreadcrumbs = () => {
  return (
    <SingleCandidateBreadcrumbs>
      <ListBreadcrumbItem entity="Offers" />
    </SingleCandidateBreadcrumbs>
  );
};

export default CandidateOfferListBreadcrumbs;
