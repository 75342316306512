/* istanbul ignore file */
import React from 'react';
import { useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const EmployeeSalaryBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useEmployeeSalaryUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Salaries" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeSalaryBreadcrumbs;
