import React from 'react';
import useRedmineUrl from 'redmine/useRedmineUrl';
import { LinkField } from 'shared/uibuilder/field';

type RedmineIssueListFieldProps = {
  id: number;
};

const RedmineIssueListField = ({ id, ...props }: RedmineIssueListFieldProps) => {
  const { getIssueUrl } = useRedmineUrl();

  const value = id && getIssueUrl(id);

  return <LinkField {...props} linkText={id} value={value as string} isInternal={false} />;
};

export default RedmineIssueListField;
