import reconciliationFilterValidation from 'erp/costaccounting/reconcilation/List/reconciliationFilterValidation';
import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import { CONTENT_TYPE } from 'shared/api/const';
import { uniq } from 'lodash';

export const READ_RECONCILIATION = 'COMPANY_GET_RECONCILED_GROUPS';
export const REJECT_RECONCILIATION = 'COMPANY_REJECT_RECONCILED_GROUP';
export const RUN_MANUAL_RECONCILIATION = 'COMPANY_PERFORM_RECONCILIATION';
export const UPLOAD_BANK_STATEMENT = 'COMPANY_PERFORM_RECONCILIATION';
export const RECONCILIATION_PATH = '/reconciliation';
export const RESOURCE_URL = '/accounts/reconciliation';
export const RECONCILE_RESOURCE_URL = '/accounts/reconcile';

export const RECONCILIATION_STATUS_ALIASES = {
  RECONCILED: 'RECONCILED',
  POTENTIAL_MATCH: 'POTENTIAL_MATCH',
  NOT_RECONCILED: 'NOT_RECONCILED',
};

export const RECONCILIATION_TYPES = {
  BANK_TRANSACTION_ID_RECONCILIATION: 'BANK_TRANSACTION_ID_RECONCILIATION',
  DATE_AND_AMOUNT_RECONCILIATION: 'DATE_AND_AMOUNT_RECONCILIATION',
  PAYROLL_RECONCILIATION: 'PAYROLL_RECONCILIATION',
  MANUAL_RECONCILIATION: 'MANUAL_RECONCILIATION',
};

export const RECONCILIATION_TYPES_OPTIONS = {
  [RECONCILIATION_TYPES.BANK_TRANSACTION_ID_RECONCILIATION]: 'Bank Transaction ID',
  [RECONCILIATION_TYPES.DATE_AND_AMOUNT_RECONCILIATION]: 'Date and Amount',
  [RECONCILIATION_TYPES.PAYROLL_RECONCILIATION]: 'Payroll',
  [RECONCILIATION_TYPES.MANUAL_RECONCILIATION]: 'Manual',
};

export const RECONCILIATION_STATUS = {
  [RECONCILIATION_STATUS_ALIASES.RECONCILED]: 'Reconciled',
  [RECONCILIATION_STATUS_ALIASES.POTENTIAL_MATCH]: 'Potential match',
  [RECONCILIATION_STATUS_ALIASES.NOT_RECONCILED]: 'Not Reconciled',
};

const useReconciliationService = () => {
  const { sendPostRequest } = useFinanceApi();
  const { search: initialSearch } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const search = async ({ filter, ...searchRequest }: any = {}) => {
    const { period = {}, ...restFilters } = filter || {};

    return initialSearch({
      searchRequest: { ...searchRequest, filter: restFilters },
      dateRange: {
        fromDate: period.ge,
        toDate: period.le,
      },
    });
  };

  const searchAvailableJournalEntries = async ({ filter }: any = {}) => {
    const { period = {}, office = [] } = filter || {};

    const response = await sendPostRequest(`${RESOURCE_URL}/available-journal-entries/search`, {
      dateRange: {
        fromDate: period.ge,
        toDate: period.le,
      },
      offices: office?.in || [],
    });

    const result = await response.json();

    return { result };
  };

  const searchAvailableBankTransactions = async ({ filter = {} }: any = {}) => {
    const { period = {}, office = [] } = filter || {};

    const response = await sendPostRequest(`${RESOURCE_URL}/available-bank-transactions/search`, {
      dateRange: {
        fromDate: period.ge,
        toDate: period.le,
      },
      offices: office?.in || [],
    });

    const result = await response.json();

    return { result };
  };

  const uploadBankStatement = async (data: any = {}) => {
    const formData = new FormData();
    formData.append('file', data.file[0].file);
    formData.append('office', data.office);
    formData.append('fromDate', data.fromDate);
    formData.append('toDate', data.toDate);

    return sendPostRequest('/account-statement/upload', formData, CONTENT_TYPE.MULTIPART);
  };

  const reconciliate = async (data: any = {}) => {
    return sendPostRequest(RECONCILE_RESOURCE_URL, data);
  };

  const rejectReconciliation = async (id: string) => {
    return sendPostRequest(`${RESOURCE_URL}/${id}/reject`);
  };

  const approveReconciliation = async (id: string) => {
    return sendPostRequest(`${RESOURCE_URL}/${id}/approve`);
  };

  const getFilterValidationSchema = () => Promise.resolve(reconciliationFilterValidation);

  const getDavinciTransfersSubTotal = async (ids: string[]) => {
    if (!ids.length) {
      return Promise.resolve({});
    }

    const result = await sendPostRequest(`${RESOURCE_URL}/davinci-transfers/sub-total`, uniq(ids));

    return result.json();
  };

  const getBankTransactionsSubTotal = async (ids: string[]) => {
    if (!ids.length) {
      return Promise.resolve({});
    }

    const result = await sendPostRequest(`${RESOURCE_URL}/bank-transactions/sub-total`, uniq(ids));

    return result.json();
  };

  const getSensitiveDataByIds = async ([bankTransactionIds, davinciTransferIds]: string[][]) => {
    const [bankTransactionSubTotals, davinciTransfersSubTotal] = await Promise.all([
      getBankTransactionsSubTotal(bankTransactionIds),
      getDavinciTransfersSubTotal(davinciTransferIds),
    ]);

    return Promise.resolve({
      bankTransactionSubTotals,
      davinciTransfersSubTotal,
    });
  };

  return {
    search,
    uploadBankStatement,
    reconciliate,
    rejectReconciliation,
    approveReconciliation,
    searchAvailableJournalEntries,
    searchAvailableBankTransactions,
    getFilterValidationSchema,
    getSensitiveDataByIds,
  };
};

export default useReconciliationService;
