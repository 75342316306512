import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import { getVisibility } from 'shared/uibuilder/helper';

interface BadgeFieldProps extends FieldProps {
  badges: React.ReactElement | React.ReactElement[];
  children?: React.ReactElement | React.ReactElement[];
  isSortable?: boolean;
  style?: React.CSSProperties;
}

const BadgeField = ({ badges, children, ...props }: BadgeFieldProps) => {
  const { getData } = useFieldHelper();

  const renderBadge = (badge: React.ReactElement) => {
    const {
      props: { isVisible },
    } = badge;

    if (getVisibility(getData(), isVisible)) {
      return React.cloneElement(badge, {
        ...badge.props,
        key: badge.props.id,
      });
    }

    return null;
  };

  return (
    <div className="badge-field" style={props.style}>
      {children && React.Children.map(children, child => React.cloneElement(child, props))}
      {badges && Array.isArray(badges) && badges.map(renderBadge)}
    </div>
  );
};

export default BadgeField;
