/* istanbul ignore file */
import React from 'react';
import AccountPageLayout from './AccountPageLayout';
import { RelatedContactsList } from 'crm/contact';
import AccountRelatedContactsBreadcrumbs from 'crm/account/Show/AccountRelatedContactsBreadcrumbs';

const AccountRelatedContacts = () => (
  <AccountPageLayout breadcrumbs={<AccountRelatedContactsBreadcrumbs />}>
    <RelatedContactsList />
  </AccountPageLayout>
);

export default AccountRelatedContacts;
