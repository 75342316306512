import { useKernelApi } from 'api';
import useAuthenticationService from 'authentication/authenticationService';

export type Permission = string;

const COMPANY_PERMISSIONS_PATH = '/companies/1/permissions';

const UPDATE = 'UPDATE';
const READ = 'READ';
const DELETE = 'DELETE';

const IGNORED_PERMISSIONS = [READ, UPDATE, DELETE];

const useDavinciAuthorizationService = () => {
  const { sendGetRequest } = useKernelApi();
  const { isAuthenticated } = useAuthenticationService();

  const getCompanyPermissions = async () => {
    const permissions = await sendGetRequest(COMPANY_PERMISSIONS_PATH);
    return permissions.json();
  };

  // ignore global READ, UPDATE, DELETE permissions
  const getGlobalPermissions = async () => {
    const isUserAuthenticated = await isAuthenticated();

    if (isUserAuthenticated) {
      const permissions = await getCompanyPermissions();
      const filtered = permissions.filter((permission: Permission) => !IGNORED_PERMISSIONS.includes(permission));

      return { json: () => Promise.resolve(filtered) };
    } else {
      return { json: () => Promise.resolve(null) };
    }
  };

  return {
    getGlobalPermissions,
  };
};

export default useDavinciAuthorizationService;
