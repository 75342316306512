/* istanbul ignore file */
import React from 'react';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';
import { CREATE_EXPENSE } from 'financialAnalytic/expenses/useExpenseService';
import CreateButton from 'uibuilder/button/CreateButton';

const CreateExpenseButton = () => {
  const { getCreateUrl } = useExpenseUrl();

  return (
    <CreateButton url={getCreateUrl()} label="Add Expense" key="create-expense" permissionToCheck={CREATE_EXPENSE} />
  );
};

export default CreateExpenseButton;
