import React, { useState, useEffect } from 'react';
import { HTTP } from 'shared/api/const';
import Message from 'shared/message/Message';
import Loading from 'shared/uibuilder/Loading';
import { APPLICATION_URL } from 'ApplicationRouter';
import { AuthFailReason } from 'api/unauthenticatedRequestHandler';
import useAzureApiService from 'authentication/azure/azureApiService';
import useAzureAuthenticator from 'authentication/azure/azureAuthenticator';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { useAzureAccessCode, useAzureAccessError } from 'authentication/AuthenticationRouter';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';

const DEFAULT_ERROR_MESSAGE = 'Error occurred.';
const NOT_REGISTERED_ERROR_MESSAGE = 'User is not found in the system.';
const PARAMETERS_MISMATCH_ERROR_MESSAGE =
  'Authentication error. Authentication parameters mismatch. Please contact the system administrator.';

const AzureCallbackPage = () => {
  const [isErrorOccurred, setIsErrorOccurred] = useState<boolean>(false);
  const accessCode = useAzureAccessCode();
  const accessError = useAzureAccessError();
  const { addMessage } = useMessageService();
  const { authenticate } = useAzureAuthenticator();
  const { getTokenByAccessCode } = useAzureApiService();
  const { getListUrl: getOneStopRequestsUrl } = useOneStopRequestsUrl();

  const handleAuthenticationError = async (error: any) => {
    let errorMessage = DEFAULT_ERROR_MESSAGE;

    if (error.status === HTTP.UNAUTHORIZED) {
      const { reason } = await error.json();

      if (reason === AuthFailReason.INVALID) {
        errorMessage = PARAMETERS_MISMATCH_ERROR_MESSAGE;
      } else if (reason === AuthFailReason.NOT_REGISTERED) {
        errorMessage = NOT_REGISTERED_ERROR_MESSAGE;
      }
    }

    addMessage(new ErrorMessage(errorMessage));
    setIsErrorOccurred(true);
  };

  const authenticateByAzure = async () => {
    try {
      const response = await getTokenByAccessCode(encodeURIComponent(accessCode as string));
      const { ssid = '', accessToken: token = '', error = false, message = '' } = await response;

      if (ssid && token) {
        const authToken = { token, ssid };

        await authenticate(authToken);
        window.location.assign(getOneStopRequestsUrl());
      } else if (error) {
        addMessage(new ErrorMessage(message));
        setIsErrorOccurred(true);
      }
    } catch (error) {
      await handleAuthenticationError(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (accessError) {
        window.location.assign(APPLICATION_URL || '');
      } else if (accessCode) {
        await authenticateByAzure();
      } else {
        addMessage(new ErrorMessage('Something went wrong'));
        setIsErrorOccurred(true);
      }
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isErrorOccurred ? <Message /> : <Loading />;
};

export default AzureCallbackPage;