/* istanbul ignore file */
import { FieldProps, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import ProjectLink from 'erp/project/shared/field/ProjectLink';
import React from 'react';
import { READ_PROJECT } from 'erp/project/shared/projectService';
import ServiceLink from 'erp/service/shared/field/ServiceLink';
import { AssignmentTargetType } from 'erp/assignment/target/shared/assignmentTargetService';
import { READ_SERVICE } from 'erp/service/shared/serviceService';

const getTargetLink = (type: string, name: string, isGranted: Function, getId: Function, props: any) => {
  switch (type) {
    case AssignmentTargetType.PROJECT.toString():
      return <ProjectLink isClickable={isGranted(READ_PROJECT)} name={name} id={getId()} {...props} />;
    case AssignmentTargetType.SERVICE.toString():
      return <ServiceLink isClickable={isGranted(READ_SERVICE)} name={name} id={getId()} {...props} />;
    default:
      return null;
  }
};

const AssignmentTargetLinkField = ({ source, nameSource, typeSource, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getType } = useFieldHelper({ source: typeSource });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { isGranted } = useAuthorization();

  const name = getName();
  const type = getType();

  const value = name ? getTargetLink(type, name, isGranted, getId, props) : null;

  return <TextField value={value} {...props} />;
};

export default AssignmentTargetLinkField;
