/* istanbul ignore file */
import React from 'react';
import JournalEntrySettings from 'financialAnalytic/journalEntry/shared/Settings';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import Box from 'uibuilder/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import useDeviceDetectorService, { MAX_SM_WIDTH, MAX_MD_WIDTH } from 'oneStop/deviceDetectorService';
import { Placement } from 'shared/uibuilder/SimpleTooltip';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useJournalEntriesSettings from 'financialAnalytic/journalEntry/shared/Settings/useJournalEntriesSettings';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';

const JournalEntryStaticFilters = ({
  title,
  sx = {},
  isInListHeaderWrapper = false,
}: {
  title?: React.ReactNode;
  sx?: SxProps<Theme>;
  isInListHeaderWrapper?: boolean;
}) => {
  const { reloadData } = useListContext();
  const { areStornedJournalEntriesVisible } = useJournalEntriesSettings();

  const { isDesktop } = useDeviceDetectorService({
    maxWidth: MAX_SM_WIDTH,
    defaultValue: window.innerWidth > MAX_SM_WIDTH,
  });

  const filters = (
    <FiltersLayout>
      <DateRangeFilter
        key={`journal-entry-period-filter-${isDesktop}`}
        popperPlacement={isDesktop ? Placement.BOTTOM_END : Placement.BOTTOM_START}
        label="Period"
        source="period"
        canBeInFuture
      />
    </FiltersLayout>
  );

  useDidUpdateEffect(() => {
    if (reloadData) {
      reloadData();
    }
  }, [areStornedJournalEntriesVisible]);

  return (
    <Box
      sx={{
        [`@media (min-width: ${isInListHeaderWrapper ? MAX_MD_WIDTH : MAX_SM_WIDTH}px)`]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '& [data-testId="remove-btn"], & .filters-reset-btn': {
          display: 'none',
        },
        '.button-dropdown__toggle': {
          minHeight: '36px',
          padding: '5px 12px',
        },
        '.filter-item-wrapper': {
          marginRight: 0,
        },
        '.dropdown-toggle': {
          marginLeft: '8px',
          transform: 'translateY(-2px)',
        },
        ...sx,
      }}
    >
      {title}
      <Box
        sx={
          isInListHeaderWrapper
            ? {
                [`@media (min-width: ${MAX_MD_WIDTH}px)`]: {
                  marginLeft: '20px',
                },
              }
            : undefined
        }
      >
        {filters}
        <JournalEntrySettings />
      </Box>
    </Box>
  );
};

export default JournalEntryStaticFilters;
