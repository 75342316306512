/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';
import ListFilterWrapper from './ListPageLayout/ListFilterWrapper';
import Box from 'uibuilder/Box';

interface ListPageLayoutProps {
  header?: React.ReactElement;
  filter?: React.ReactElement;
  actions?: React.ReactElement[];
  children?: React.ReactElement | React.ReactElement[];
  search?: React.ReactElement;
  sidebar?: React.ReactElement;
}

const ListPageLayoutWithSidebar = ({ header, filter, actions, children, search, sidebar }: ListPageLayoutProps) => {
  return (
    <>
      <ListFilterWrapper filter={filter} search={search} actions={actions} />
      <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
        <Box
          sx={{
            position: 'relative',
            flex: 'auto 0 0',
            marginRight: { xs: 0, md: '16px' },
            marginBottom: { xs: '16px', md: 0 },
          }}
        >
          {sidebar}
        </Box>
        <Box sx={{ position: 'relative', flex: 'auto 1 1' }}>
          <Paper sx={{ p: 2 }} className="page-content">
            {header}
            <Message />
            {children}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ListPageLayoutWithSidebar;
