/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';

const ViewEmployeeContractButton = props => {
  const { getSingleEntityUrl } = useEmployeeContractUrl();

  return (
    <LinkButton {...props} outline url={getSingleEntityUrl}>
      View
    </LinkButton>
  );
};

export default ViewEmployeeContractButton;
