/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import PerformanceEvaluationDropdown from 'erp/employee/performanceEvaluation/process/shared/input/PerformanceEvaluationDropdown';
import EmployeePerformanceInput from 'erp/employee/performanceEvaluation/employeePerformance/shared/input/EmployeePerformanceInput';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import EmployeePerformanceLevelInput from '../shared/input/EmployeePerformanceLevelInput';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';

const EmployeePerformanceForm = ({ isCreateForm }: { isCreateForm?: boolean }) => {
  const { data = {} } = useFormContext();
  const hasPerformanceCriteria = !!data.performanceCriteria;

  const performanceCriteriaComponent =
    isCreateForm || !hasPerformanceCriteria ? (
      <EmployeePerformanceLevelInput source="performanceLevelCriteria" />
    ) : (
      <EmployeePerformanceInput source="performanceCriteria" />
    );

  return (
    <FormSection title="Employee Performance Information">
      <FormRow>
        <PerformanceEvaluationDropdown
          label="Related Career Review Period"
          source="relatedProcessId"
          errorSource="relatedProcess"
        />
      </FormRow>
      {performanceCriteriaComponent}
      <BigFormRow>
        <NoteAttachmentUploaderInput label="Attachments" source="attachmentIds" />
      </BigFormRow>
    </FormSection>
  );
};

export default EmployeePerformanceForm;
