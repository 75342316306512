import { LoadingParams, SearchParams } from '../ListContext';

const searchFieldSource = 'specification:search';

const getLoadingParams = (params: SearchParams = {}, pageSize: number = 30): LoadingParams => {
  const result: LoadingParams = {
    pageSize,
    pageNumber: params.page || 0,
  };

  const { sort, filter, search } = params;

  if (search) {
    result.filter = {
      [searchFieldSource]: {
        eq: search,
      },
    };
  }

  if (filter) {
    result.filter = result.filter ? { ...result.filter, ...filter } : { ...filter };
  }

  if (sort?.field && sort.order) {
    result.sort = {
      [sort.field]: sort.order,
    };
  }

  return result;
};

export default getLoadingParams;
