import React from 'react';
import { useWasteId } from '../WasteRouter';
import useWasteService from '../useWasteService';
import Show from 'shared/uibuilder/show/Show';
import ViewWastePageContent from './ViewWastePageContent';

const WasteViewPage = () => {
  const { getById } = useWasteService();
  const id = useWasteId();
  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewWastePageContent />
    </Show>
  );
};

export default WasteViewPage;
