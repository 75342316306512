/* istanbul ignore file */
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button } from 'uibuilder/button';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useHistory } from 'react-router';
import { useArtifactStorageUrl } from '../ArtifactStorageRouter';
import './ArtifactStorageBreadcrumbs.scss';

const ArtifactStorageBreadcrumbs = ({
  breadcrumbs: initialBreadcrumbs,
}: {
  breadcrumbs: { alias: string; name: string }[];
}) => {
  const { loading } = useListContext();
  const { push } = useHistory();
  const { getListUrl } = useArtifactStorageUrl();
  const breadcrumbs = [...initialBreadcrumbs];

  if (!breadcrumbs.length || loading) {
    return null;
  }

  breadcrumbs.unshift({ alias: 'Root', name: 'Root' });

  return (
    <Breadcrumbs component="nav" className="artifact-storage-breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) =>
        index === breadcrumbs.length - 1 ? (
          <span key={breadcrumb.alias} className="breadcrumb-item active">
            {breadcrumb.name || breadcrumb.alias}
          </span>
        ) : (
          <Button
            key={breadcrumb.alias}
            className="breadcrumb-item"
            onClick={(e: any) => {
              e.stopPropagation();

              const path = breadcrumbs.slice(1, index + 1).map(({ alias }) => alias);

              push(path.length ? `${getListUrl()}/${path.join('/').toLowerCase()}` : getListUrl());
            }}
            link
          >
            {breadcrumb.name || breadcrumb.alias}
          </Button>
        ),
      )}
    </Breadcrumbs>
  );
};

export default ArtifactStorageBreadcrumbs;
