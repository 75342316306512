/* istanbul ignore file */
import { func } from 'prop-types';
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import NoteForm from 'erp/candidate/Timeline/note/shared/form/NoteForm';

const UpdateNoteForm = ({ service, getDataFunc, onCancel, ...props }) => {
  const { getValidationSchema, update } = service();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

UpdateNoteForm.propTypes = {
  service: func.isRequired,
  getDataFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default UpdateNoteForm;
