/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { replaceUrlWithLink } from 'shared/uibuilder/ReplaceUrlWithLink/autoLinkerHelper';

const ReplaceUrlWithLinkChild = ({ child }: { child: React.ReactElement }) => {
  const { getValue } = useFieldHelper(child.props);

  return React.cloneElement(child, {
    value: replaceUrlWithLink(getValue()),
  });
};

export default ReplaceUrlWithLinkChild;
