/* istanbul ignore file */
import { HashLink } from 'react-router-hash-link';
import React from 'react';
import { useLocation } from 'react-router-dom';
import * as domUtils from 'shared/uibuilder/domUtils';
import { getAnchor } from 'shared/uibuilder/helper';

interface SidebarMenuSubItemProps {
  hash?: string;
  name: string;
  isVisible?: boolean;
}

const SidebarMenuSubItem = ({ hash: propsHash, name, isVisible = true }: SidebarMenuSubItemProps) => {
  const { pathname } = useLocation();

  const hash = propsHash || getAnchor(name);

  return isVisible ? (
    <HashLink
      key={hash}
      className="left-menu-subitem"
      to={`${pathname}#${hash}`}
      scroll={(el: HTMLElement) => domUtils.scrollToHash(el)}
    >
      {name}
    </HashLink>
  ) : null;
};

export default SidebarMenuSubItem;
