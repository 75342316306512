/* istanbul ignore file */
import React from 'react';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import { CREATE_JOURNAL_ENTRY } from 'financialAnalytic/journalEntry/useJournalEntryService';
import CreateButton from 'uibuilder/button/CreateButton';

const CreateJournalEntryButton = ({
  urlParams = '',
  variant,
}: {
  urlParams?: string;
  variant?: 'outlined' | 'contained' | 'text';
}) => {
  const { getCreateUrl } = useJournalEntryUrl();

  return (
    <CreateButton
      url={`${getCreateUrl()}${urlParams}`}
      label="Add Journal entry"
      key="create-journal-entry"
      permissionToCheck={CREATE_JOURNAL_ENTRY}
      variant={variant}
    />
  );
};

export default CreateJournalEntryButton;
