/* istanbul ignore file */
import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import CurrencyField from 'shared/uibuilder/field/CurrencyField';
import JournalEntryTransferField from 'financialAnalytic/journalEntry/shared/field/JournalEntryTransferField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import 'financialAnalytic/journalEntry/List/JournalEntryListPage.scss';

const JournalEntryTransfersList = ({ source }: { source: string }) => {
  const { getValue } = useFieldHelper({ source });
  const transfers = (getValue() as any) || [];

  return (
    <ControlledList data={{ items: transfers, totalPages: 1, totalElements: transfers.length }}>
      <DataGridLayout tableWrapperClassName="journal-entry-table-wrapper">
        <LedgerLink source="accountId" nameSource="accountId" dateSource="asDateOf" label="Account" width="25%" />
        <JournalEntryTransferField source="id" label="Transfer ID" width="20%" />
        <CurrencyField source="debit" currencySource="currency" label="Debit" width="20%" emptyValue=" " />
        <CurrencyField source="credit" currencySource="currency" label="Credit" width="20%" emptyValue=" " />
        <TextField source="description" label="Description" width="25%" />
      </DataGridLayout>
    </ControlledList>
  );
};

export default JournalEntryTransfersList;
