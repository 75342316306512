/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import {
  EXPENSE_TYPES_OPTIONS,
  EXPENSE_TYPES,
  CREATE_PERFORMANCE_BASED_BONUS,
} from 'financialAnalytic/expenses/useExpenseService';
import useAuthorization from 'shared/authorization/authorizationService';

const ExpenseTypeDropdown = (props: DefaultInputPropTypes<string>) => {
  const { isGranted } = useAuthorization();
  const options = { ...EXPENSE_TYPES_OPTIONS };

  if (!isGranted(CREATE_PERFORMANCE_BASED_BONUS)) {
    delete options[EXPENSE_TYPES.PERFORMANCE_BASED_BONUS];
  }

  return <EnumDropdown options={options} placeholder="Select Type" {...props} />;
};

export default ExpenseTypeDropdown;
