/* istanbul ignore file */
import React from 'react';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { Dropdown } from 'shared/uibuilder/form/input';
import { ACTIVE_STATUS, ARCHIVED_STATUS } from 'erp/budget/shared/budgetService';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';

interface BudgetStatusFilterProps extends DefaultDropdownProps {
  value?: string;
}

const BudgetStatusFilter = (props: BudgetStatusFilterProps) => {
  return (
    <DropDownFilter {...props}>
      <Dropdown
        {...props}
        placeholder="Select status"
        options={[
          { value: ACTIVE_STATUS, label: 'Active' },
          { value: ARCHIVED_STATUS, label: 'Archived' },
        ]}
      />
    </DropDownFilter>
  );
};

export default BudgetStatusFilter;
