/* istanbul ignore file */
import React from 'react';
import { EditButtonProps } from 'uibuilder/button/EditButton';
import LinkButton from 'uibuilder/button/LinkButton';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';

interface PublishVacancyButtonProps extends EditButtonProps {
  isVisible?: (value: any) => boolean;
  width?: number;
}

const PublishVacancyButton = ({ permissionToCheck, ...props }: PublishVacancyButtonProps) => {
  const { getCreateUrl } = useVacancyPublicationUrl();
  const { getValue } = useFieldHelper(props);
  const { isGranted } = useAuthorization();

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <LinkButton {...props} url={() => getCreateUrl(getValue() as string)}>
      Publish
    </LinkButton>
  ) : null;
};

export default PublishVacancyButton;
