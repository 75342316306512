export default {
  sections: [
    {
      type: 'default',
      title: 'First section',
      rows: [
        {
          type: 'default',
          fields: [
            {
              type: 'text',
              source: 'textInput',
              label: 'Text input',
              tooltip: 'Test tooltip',
              isRequired: true,
            },
            {
              type: 'dropdown',
              source: 'dropdown',
              label: 'Dropdown',
              tooltip: 'Test tooltip',
              options: [
                {
                  label: 'PHP Software Engineer',
                  value: 'PHP_DEV',
                },
                {
                  label: '.NET Software Engineer',
                  value: 'NET_DEV',
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          type: 'default',
          fields: [
            {
              type: 'date',
              source: 'dateInput',
              label: 'Date input',
              tooltip: 'Test tooltip',
            },
            {
              type: 'datetime',
              source: 'datetimeInput',
              label: 'DateTime input',
            },
          ],
        },
        {
          type: 'big',
          fields: [
            {
              type: 'textarea',
              source: 'textArea',
              label: 'TextArea input',
            },
          ],
        },
      ],
    },
    {
      type: 'default',
      title: 'Second section',
      rows: [
        {
          type: 'medium',
          fields: [
            {
              type: 'number',
              source: 'numberInput',
              label: 'Number input',
            },
            {
              type: 'employee',
              source: 'employeeID',
              label: 'Employee',
            },
            {
              type: 'candidate',
              source: 'candidateID',
              label: 'Candidate Name',
            },
          ],
        },
        {
          type: 'medium',
          fields: [
            {
              type: 'employee_photo',
              source: 'photoIds',
              label: 'Employee photo',
            },
            {
              type: 'candidate_resume',
              source: 'candidateResume',
              label: 'Candidate resume',
            },
            {
              type: 'one_stop_request_attachment',
              source: 'one_stop_request_attachment',
              label: 'Attached files',
            },
          ],
        },
      ],
    },
  ],
};
