import React, { useState, useEffect } from 'react';
import ProjectInput from 'erp/project/shared/input/ProjectInput';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import { useFilterContext, FilterCondition, BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import useProjectService from 'erp/project/shared/projectService';
import InputLoader from 'shared/uibuilder/InputLoader';

interface ProjectFilterProps extends BaseFilterProps {
  label?: string;
}

const setProjectLabel = (searchSuggestions: any, projectId: string, setLabel: any, setProjectIdLoaded: any) => {
  (async () => {
    const response = await searchSuggestions({
      filter: {
        id: {
          eq: projectId,
        },
      },
    });
    const procject = response && response.result && !!response.result.length && response.result[0];
    setLabel(procject.name);
    setProjectIdLoaded(true);
  })();
};

const ProjectFilter = ({ label: filterLabel, source, ...props }: ProjectFilterProps) => {
  const { filterBy, getValue } = useFilterContext();
  const { search } = useProjectService();

  const [label, setLabel] = useState('');
  const [projectIdLoaded, setProjectIdLoaded] = useState(false);

  useEffect(() => {
    const projectId = getValue(source, FilterCondition.EQ);

    if (projectId) {
      setProjectLabel(search, projectId, setLabel, setProjectIdLoaded);
    } else {
      setProjectIdLoaded(true);
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCallback = (filter: any) => {
    filterBy(filter);
  };

  return projectIdLoaded ? (
    <EqCondition {...props} source={source} onChangeCallback={onChangeCallback}>
      <ProjectInput initialLabel={label} source={source} label={filterLabel} searchString="name" />
    </EqCondition>
  ) : (
    <InputLoader />
  );
};

export default ProjectFilter;
