/* istanbul ignore file */
import React from 'react';
import EmployeeSalaryBreadcrumbs from 'erp/employee/salaries/shared/EmployeeSalaryBreadcrumbs';
import CreateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/CreateBreadcrumbItem';

const CreateSalaryBreadcrumbs = () => {
  return (
    <EmployeeSalaryBreadcrumbs>
      <CreateBreadcrumbItem entity="Salary" />
    </EmployeeSalaryBreadcrumbs>
  );
};

export default CreateSalaryBreadcrumbs;
