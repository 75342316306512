import { CONTENT_TYPE, HTTP_METHOD } from './const';
import { ResourceData } from 'shared/crud/baseCrudService';
import { RequestOptions } from 'shared/api/xhr';
import { AuthHeader } from 'shared/api';

/**
 * Method to handle specified responses (e.g. authorization rejection or access token expiration)
 * @param {Promise} response -- response got from a fetch() call
 * @returns {Promise}
 */
export const handleResponse = async (response: Response) => {
  if (response.ok || (response.status >= 200 && response.status < 300)) {
    return Promise.resolve(response);
  }

  return Promise.reject(response);
};

type RequestParams = {
  method: HTTP_METHOD;
  data: ResourceData;
  contentType: CONTENT_TYPE;
  eventTarget: Nullable<AbortSignal>;
};

/**
 * Method for creating initializing object
 * @param headers -- request headers that will be extended
 * @param params -- object with other parameters
 * @returns {Object}
 */
export const initializeRequest = (
  headers: Headers,
  { method, data, contentType, eventTarget }: RequestParams,
): RequestOptions => {
  const init: RequestOptions = {
    method,
    headers,
    cache: 'default',
  };

  if (method !== HTTP_METHOD.GET && data) {
    init.body = contentType === CONTENT_TYPE.JSON ? JSON.stringify(data) : data;
  }

  if (eventTarget instanceof AbortSignal) {
    init.signal = eventTarget;
  }

  return init;
};

/**
 * Method that creates headers object
 * @param headers -- array of [name: value] pairs
 * @param contentType
 * @returns {Headers}
 */
export const createHeaders: (headers: AuthHeader[], contentType: CONTENT_TYPE) => Headers = (
  headers = [],
  contentType,
) => {
  const requestHeaders = new Headers();

  if (contentType) {
    requestHeaders.append('Content-Type', contentType);
  }

  if (headers && headers.length) {
    headers.forEach(header => {
      requestHeaders.append(header.name, header.value);
    });
  }

  return requestHeaders;
};
