import { isArray } from 'lodash';
import { ListItem } from 'shared/uibuilder/list/ListContext';

export const getMaxTreeDepth = (items: ListItem | ListItem[]): number => {
  if (isArray(items)) {
    return Math.max(0, ...(items.map(getMaxTreeDepth) || []));
  }
  return items?.name ? 1 + Math.max(0, ...(items.children?.map(getMaxTreeDepth) || [])) : 0;
};

export default {
  getMaxTreeDepth,
};
