/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { OPPORTUNITY_STAGES } from 'crm/opportunity/shared/opportunityService';

const OpportunityStageDropdown = props => {
  return <EnumDropdown {...props} options={OPPORTUNITY_STAGES} />;
};

export default OpportunityStageDropdown;
