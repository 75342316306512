/* istanbul ignore file */
// libs
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import VacancyPublicationForm from 'erp/recruitment/vacancy/publication/createupdate/VacancyPublicationForm';
import useVacancyPublicationService from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';
import VacancyPublicationEntityHeader from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationEntityHeader';
import UpdateVacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/createupdate/Update/Breadcrumbs';

const UpdateVacancyPublication = () => {
  const { updateVacancy, getVacancyById, getValidationSchema } = useVacancyPublicationService();
  const { getSingleEntityUrl } = useVacancyPublicationUrl();

  return (
    <UpdateForm
      submitFormFunc={updateVacancy}
      getDataFunc={getVacancyById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Vacancy Publication has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Vacancy Publication"
        breadcrumbs={<UpdateVacancyPublicationBreadcrumbs />}
        entityHeader={<VacancyPublicationEntityHeader />}
      >
        <VacancyPublicationForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateVacancyPublication;
