/* istanbul ignore file */
import React from 'react';
import EmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/shared/EmployeePerformanceBreadcrumbs';
import UpdateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateEmployeePerformanceBreadcrumbs = () => {
  return (
    <EmployeePerformanceBreadcrumbs>
      <UpdateBreadcrumbItem entity="Performance Result" />
    </EmployeePerformanceBreadcrumbs>
  );
};

export default UpdateEmployeePerformanceBreadcrumbs;
