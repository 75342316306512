/* istanbul ignore file */
import React from 'react';
import useCandidateOfferService from 'erp/candidate/offercompensation/shared/candidateOfferService';
import { useCandidateOfferId, useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import { UpdateForm } from 'shared/uibuilder/form';
import { SensitiveUpdatePageLayout } from 'shared/uibuilder/sensitiveData';
import UpdateCandidateOfferBreadcrumbs from 'erp/candidate/offercompensation/createupdate/Update/UpdateBreadcrumbs';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import CandidateOfferForm from 'erp/candidate/offercompensation/createupdate/CandidateOfferForm';

const UpdateCandidateOffer = () => {
  const { update, getById, getValidationSchema } = useCandidateOfferService();
  const offerId = useCandidateOfferId();
  const { getListUrl, getSingleEntityUrl } = useCandidateOfferUrl();
  const listUrl = getListUrl();
  const updatedEntityUrl = getSingleEntityUrl(offerId);

  return (
    <UpdateForm
      getDataFunc={() => getById(offerId)}
      submitFormFunc={update}
      afterSubmit={{
        redirect: updatedEntityUrl,
        message: 'You are awesome! The Offer has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      entityId={offerId}
    >
      <SensitiveUpdatePageLayout
        title="Edit: Offer"
        breadcrumbs={<UpdateCandidateOfferBreadcrumbs />}
        entityHeader={<CandidateEntityHeader />}
        menu={<CandidateMenu />}
        timerId={listUrl}
        actions={[]}
      >
        <CandidateOfferForm />
      </SensitiveUpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateCandidateOffer;
