/* istanbul ignore file */
import React from 'react';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import { CreateForm } from 'shared/uibuilder/form';
import AssignmentForm from 'erp/assignment/createupdate/AssignmentForm';
import CreateEmployeeAssignmentBreadCrumbs from 'erp/employee/assignments/createupdate/Create/Breadcrumbs';
import { useEmployeeAssignmentId, useEmployeeAssignmentUrl } from 'erp/employee/assignments/EmployeeAssignmentsRouter';
import useAssignmentService, { AssignmentType } from 'erp/assignment/shared/assignmentService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

const CreateEmployeeAssignment = () => {
  const { getListUrl } = useEmployeeAssignmentUrl();
  const { create, getValidationSchema, getAssignmentById } = useAssignmentService();
  const employeeId = useEmployeeId();
  const id = useEmployeeAssignmentId();
  let getDataFunc;

  if (id) {
    getDataFunc = () => getAssignmentById(id);
  }

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={getDataFunc}
      initialData={{
        employeeId,
        assignmentType: AssignmentType.INDIVIDUAL_ASSIGNMENT,
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Assignment has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        breadcrumbs={<CreateEmployeeAssignmentBreadCrumbs />}
        title="Create: Assignment"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <AssignmentForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateEmployeeAssignment;
