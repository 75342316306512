/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import List from 'shared/uibuilder/list/List';
import useGeneratedPayrollsService from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout/index';
import ApprovePayrollReportButton from 'erp/payroll/generatedPayrolls/shared/button/ApprovePayrollReportButton';
import MarkAsPaidPayrollReportButton from 'erp/payroll/generatedPayrolls/shared/button/MarkAsPaidPayrollReportButton';
import { DATE_FORMAT } from 'shared/uibuilder/dateService/index';
import GeneratedPayrollsStaticFilters from 'erp/payroll/generatedPayrolls/List/GeneratedPayrollsStaticFilters';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout/index';
import PayrollReportsStatusField from 'erp/payroll/generatedPayrolls/shared/field/PayrollReportsStatusField';
import PayrollReportsOfficeField from 'erp/payroll/generatedPayrolls/shared/field/PayrollReportsOfficeField';
import TextField from 'shared/uibuilder/field/TextField';
import './GeneratedPayrollListPage.scss';
import CreateButton from 'uibuilder/button/CreateButton';
import { usePayrollUrl } from 'erp/payroll/PayrollRouter';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import RejectPayrollReportButton from 'erp/payroll/generatedPayrolls/shared/button/RejectPayrollReportButton';
import CancelPayrollReportPaymentButton from 'erp/payroll/generatedPayrolls/shared/button/CancelPayrollReportPaymentButton';

const CancelButtons = () => (
  <>
    <CancelPayrollReportPaymentButton statusSource="status" />
    <RejectPayrollReportButton statusSource="status" />
  </>
);

const GeneratedPayrollListPage = () => {
  const { getListUrl } = usePayrollUrl();
  const { getGeneratedReports } = useGeneratedPayrollsService();
  const currentMonthStart = moment()
    .startOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);
  const currentMonthEnd = moment()
    .endOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);

  return (
    <List
      getDataMethod={getGeneratedReports}
      defaultFilter={{
        period: {
          ge: currentMonthStart,
          le: currentMonthEnd,
        },
      }}
    >
      <ListPageLayout
        header={<GeneratedPayrollsStaticFilters title={<ListHeader label="Payroll Reports" />} />}
        actions={[<CreateButton url={getListUrl()} label="Generate" />]}
      >
        <DataGridLayout
          buttons={[
            <ApprovePayrollReportButton statusSource="status" />,
            <MarkAsPaidPayrollReportButton statusSource="status" />,
            <CancelButtons />,
          ]}
          tableWrapperClassName="payroll-reports-table-wrapper"
        >
          <TextField source="id" label="ID" width="22%" />
          <TextField source="period" />
          <PayrollReportsStatusField source="status" />
          <PayrollReportsOfficeField source="offices" />
          <EmployeeLinkFieldWithNameLoading source="approvedBy" />
          <EmployeeLinkFieldWithNameLoading source="markedAsPaidBy" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default GeneratedPayrollListPage;
