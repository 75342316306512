import { useEffect, useState } from 'react';

const useAsyncValue = <T = any>(getAsyncValueFunc: () => Promise<T>, deps: any[] = []) => {
  const [value, setValue] = useState<Nullable<T>>(null);

  useEffect(() => {
    if (getAsyncValueFunc) {
      getAsyncValueFunc().then(val => setValue(val));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return value;
};

export default useAsyncValue;
