import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';
import { get } from 'lodash';
import Box from 'uibuilder/Box';

const COUNTRIES = ['BY', 'PL', 'US'];

export const getAmount = (data: Dictionary<number>, source: string) =>
  `${getWithThousandsSeparator(get(data, source, 0))}${CURRENCIES[source]}`;

const BalanceField = ({ source, nameSource, isShownOneValue, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getValue: getCurrencies } = useFieldHelper({ source: 'currencies' });
  const { getValue: getCurrency } = useFieldHelper({ source: 'currency' });
  const balanceMap = getValue();
  const name = getName() as string;
  const currencies = (getCurrencies() as any) || {};
  const hasOneCurrency = CURRENCIES[name] || COUNTRIES.includes(name) || currencies.length === 1;
  let currency = getCurrency() as string;

  if (Object.keys(currencies).length === 1 && !currency) {
    [currency] = Object.keys(currencies);
  }

  let displayedValue: any = null;

  const getAmountValue = (valueCurrency: string) =>
    (!hasOneCurrency && currencies[valueCurrency]) || currency === valueCurrency
      ? getAmount(balanceMap as any, valueCurrency)
      : '';

  if (balanceMap) {
    displayedValue = (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isShownOneValue && currency ? '' : 'repeat(3, 1fr)',
          gridColumnGap: '20px',
        }}
      >
        <div>{getAmountValue('USD')}</div>
        <div>{getAmountValue('PLN')}</div>
        <div>{getAmountValue('BYR') || getAmountValue('BYN')}</div>
      </Box>
    );
  }

  return <TextField {...props} source={source} value={displayedValue} />;
};

export default BalanceField;
