/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import { ListItem } from 'shared/uibuilder/list/ListContext';

interface EditSkillBasedQueueButtonProps extends EditButtonProps {
  isVisible?: (data: ListItem) => boolean;
}

const EditSkillBasedQueueButton = (props: EditSkillBasedQueueButtonProps) => {
  const { getUpdateEntityUrl } = useSkillBasedQueueUrl();

  return <EditButton {...props} path={getUpdateEntityUrl} />;
};

export default EditSkillBasedQueueButton;
