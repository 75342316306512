/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useJournalEntryService from 'financialAnalytic/journalEntry/useJournalEntryService';
import { useJournalEntryUrl, useJournalEntryId } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import ReverseLedgerBreadcrumbs from 'financialAnalytic/journalEntry/createupdate/Reverse/Breadcrumbs';
import JournalEntryForm from 'financialAnalytic/journalEntry/createupdate/JournalEntryForm';

const ReverseLedgerPage = () => {
  const { revokeJournalEntry, getReversedEntryData, getValidationSchema } = useJournalEntryService();
  const { getListUrl } = useJournalEntryUrl();
  const id = useJournalEntryId();

  return (
    <CreateForm
      submitFormFunc={() => revokeJournalEntry(id)}
      getDataFunc={() => getReversedEntryData(id)}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Journal Entry has been successfully reversed.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        transfers: [{}, {}],
      }}
    >
      <CreatePageLayout breadcrumbs={<ReverseLedgerBreadcrumbs />} title="Storno: Journal Entry">
        <JournalEntryForm isDisabled />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default ReverseLedgerPage;
