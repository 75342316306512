/* istanbul ignore file */
import React from 'react';
import { ActivityType } from 'crm/shared/timeline/activity/crmActivityService';
import ButtonsSelectInput from 'shared/uibuilder/form/input/ButtonsSelectInput';

const ActivityTypeInput = props => {
  return (
    <ButtonsSelectInput
      {...props}
      options={Object.entries(ActivityType).map(entry => {
        const [value, label] = entry;

        return {
          label,
          value,
        };
      })}
    />
  );
};

export default ActivityTypeInput;
