import { Children } from 'react';
import { useBusinessEngineApi } from 'api';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { CamundaInputProps } from 'shared/uibuilder/form/input/CamundaInput';

export const MESSAGE_EVENT_PATH = (processInstanceId: string) => `/processes/${processInstanceId}/messageEvent`;

interface MessageEventVariable {
  name: string;
  type: string;
  value: string;
}

interface PublishMessageEventRequest {
  eventName: string;
  variables?: MessageEventVariable[];
}

interface PublishMessageEventProps {
  processInstanceId: string;
  request: PublishMessageEventRequest;
}

interface MessageEventService {
  publishMessage: (request: PublishMessageEventProps) => Promise<any>;
  getVariables: <T>(props: GetVariablesProps<T>) => Promise<MessageEventVariable[]>;
}

type InitialFieldDefinitionGetter<T> = (
  data: FormFieldsData,
) => Promise<{
  value: ((data: FormFieldsData) => Promise<T>) | T;
  type: string;
}>;

export type InitialDataType<T> = { [key: string]: InitialFieldDefinitionGetter<T> };

interface GetVariablesProps<T> {
  formFields: React.ReactElement<CamundaInputProps> | React.ReactElement<CamundaInputProps>[];
  formFieldsData: FormFieldsData;
  initialData?: InitialDataType<T>;
}

const useMessageEventService = (): MessageEventService => {
  const { sendPostRequest } = useBusinessEngineApi();

  const publishMessage = async ({ processInstanceId, request }: PublishMessageEventProps) => {
    const response = await sendPostRequest(MESSAGE_EVENT_PATH(processInstanceId), request);
    return response.json();
  };

  const getVariables = async <T>({ formFields, formFieldsData, initialData = {} }: GetVariablesProps<T>) => {
    const variables: MessageEventVariable[] = Children.map(formFields, child => {
      const name = child.props.source;
      const type = child.props.camundaType;
      const value = formFieldsData[name];
      return {
        name,
        type,
        value,
      };
    });
    const fieldsNotProvidedByForm = Object.keys(initialData).filter(
      fieldName => !variables.some(({ name }) => name === fieldName),
    );
    const promises = fieldsNotProvidedByForm.map(async fieldName => {
      const initialFieldDefinitionGetter = initialData[fieldName];
      const { value: valueProp, type } = await initialFieldDefinitionGetter(formFieldsData);
      let value;
      if (typeof valueProp === 'function') {
        // @ts-ignore
        value = await valueProp(formFieldsData);
      } else {
        value = valueProp;
      }
      variables.push({ name: fieldName, type, value });
    });

    await Promise.all(promises);

    return variables;
  };

  return {
    publishMessage,
    getVariables,
  };
};

export default useMessageEventService;
