/* istanbul ignore file */
import React, { ReactNode } from 'react';
import { ListBreadcrumbItem, Breadcrumbs, BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useProcessDefinitionUrl } from 'camunda/monitoring/definition';

const DEFAULT_SOURCE = 'key';

interface Props {
  source?: string;
  children: ReactNode;
}
const ProcessDefinitionBreadcrumbs = ({ children, source = DEFAULT_SOURCE }: Props) => {
  const { data } = useShowContext();
  const definitionId = data && source && data.getValueBySource(source);

  const { getListUrl, getSingleEntityUrl } = useProcessDefinitionUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Process definitions" link={getListUrl()} />
      <BreadcrumbItem link={getSingleEntityUrl(definitionId)}>{definitionId}</BreadcrumbItem>
      {children}
    </Breadcrumbs>
  );
};

export default ProcessDefinitionBreadcrumbs;
