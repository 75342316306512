/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import useBaseCrudService from 'shared/crud/baseCrudService';
import schema from './validationSchema';

const useTestingCrudService = () => {
  const base = useBaseCrudService({ listResourceUrl: '/crud-api', singleResourceUrl: '/crud-api/:id' });

  const getValidationSchema = () => Promise.resolve(schema);

  return {
    ...base,
    getValidationSchema,
  };
};

export default useTestingCrudService;
