/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import RelatedList from 'shared/uibuilder/list/RelatedList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { TextField, EmailField, PhoneField } from 'shared/uibuilder/field';
import EditContactButton from 'crm/contact/shared/button/EditContactButton';
import ContactLinkField from 'crm/contact/shared/field/ContactLinkField';
import useContactService, { CREATE_CONTACT } from '../shared/contactService';
import CreateButton from 'uibuilder/button/CreateButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useContactUrl } from 'crm/contact/ContactRouter';

const RelatedContactsList = () => {
  const { data: accountData } = useShowContext();
  const accountName = accountData.getValueBySource('accountName');
  const { id } = useParams();
  const { search } = useContactService();
  const { getCreateUrl } = useContactUrl();

  const getDataMethod = async params => {
    return search({
      filter: {
        'account.alias': { eq: id },
      },
      ...params,
    });
  };

  return (
    <RelatedList getDataMethod={getDataMethod}>
      <RelationListLayout
        header="Contacts"
        actions={[
          <CreateButton
            url={`${getCreateUrl({
              accountId: id,
              accountName,
            })}`}
            variant="contained"
            label="Create Contact"
            key="create-opportunity"
            permissionToCheck={CREATE_CONTACT}
          />,
        ]}
      >
        <DataGridLayout buttons={[<EditContactButton outline source="id" />]} isSmallTable showTotal={false}>
          <ContactLinkField
            linkText={data => `${data.getValueBySource('firstName')} ${data.getValueBySource('lastName')}`}
            idSource="id"
            label="Contact Name"
          />
          <TextField source="title" label="Title" width={400} />
          <EmailField source="emails[0]" label="Email" />
          <PhoneField source="phoneNumber" label="Phone" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

export default RelatedContactsList;
