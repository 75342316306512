/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import Fade from 'uibuilder/Fade';
import Loading from 'shared/uibuilder/Loading';
import SmallLoader from 'shared/uibuilder/SmallLoader';

interface LoadingDecoratorProps {
  children: React.ReactNode;
  isSmallLoader?: boolean;
  loading?: boolean;
  items?: any[];
  deps: any[];
  saveRenderedContent?: boolean;
}

const LoadingDecorator = ({
  children,
  isSmallLoader = false,
  loading = false,
  items = [],
  deps,
  saveRenderedContent,
}: LoadingDecoratorProps) => {
  const [dataLoading, setDataLoading] = useState(true);
  const isLoading = loading || dataLoading;
  const hasContent = saveRenderedContent || !dataLoading;
  const loader = isSmallLoader ? <SmallLoader /> : <Loading hasBackground />;

  useEffect(() => {
    if (loading) {
      setDataLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setDataLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return (
    <>
      {isLoading ? loader : null}
      <Fade
        in={!isLoading || (isLoading && hasContent)}
        timeout={20}
        className={isLoading && !hasContent ? 'visually-hidden' : ''}
      >
        <div>{children}</div>
      </Fade>
    </>
  );
};

export default LoadingDecorator;
