/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useCrmService from 'crm/crmService';
import PresaleCoordinatorDropdown from 'crm/opportunity/presale/output/shared/input/PresaleCoordinatorDropdown';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

interface PresaleCoordinatorInputProps {
  source: string;
  label: string;
  placeholder: string;
}

const getInitialAliasByCurrentUser = (currentUserAlias: Nullable<string>, coordinators: any[]) => {
  return coordinators.find(crd => crd.alias === currentUserAlias) ? currentUserAlias : null;
};

const PresaleCoordinatorInput = ({ ...props }: PresaleCoordinatorInputProps) => {
  const { getUserAlias } = useAuthenticatedUserService();
  const { data, setFormData, isDirty } = useFormContext();
  const { getActivePresaleCoordinators } = useCrmService();
  const [coordinators, setCoordinators] = useState<any>([]);
  const [currentUserAlias, setCurrentUserAlias] = useState<Nullable<string>>(null);

  useEffect(() => {
    (async () => {
      const currentAlias = await getUserAlias();
      setCurrentUserAlias(currentAlias);
      try {
        const crd = await getActivePresaleCoordinators();
        setCoordinators(crd);
      } catch (e) {
        setCoordinators([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const coordinatorFromForm = data?.relatedPresaleCoordinatorAlias;
  const initialUserAlias = !coordinatorFromForm
    ? getInitialAliasByCurrentUser(currentUserAlias, coordinators)
    : coordinatorFromForm;

  useEffect(() => {
    if (setFormData && !isDirty) {
      setFormData({
        ...data,
        relatedPresaleCoordinatorAlias: initialUserAlias,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUserAlias]);

  return (
    <PresaleCoordinatorDropdown coordinators={coordinators} initialCoordinatorAlias={initialUserAlias} {...props} />
  );
};

export default PresaleCoordinatorInput;
