import { useFinanceApi } from 'api';
import useSingletonPromise from 'shared/useSingletonPromise';

export const READ_LEDGER_LIST = 'COMPANY_READ_LEDGER';
export const READ_LEDGER = 'COMPANY_READ_LEDGER';
export const LEDGER_PATH = '/ledger';
export const RESOURCE_URL = '/ledger/accounts';

export const BUSINESS_OBJECT_TYPES = {
  INVOICE: 'INVOICE',
  EMPLOYEE_EXPENSE: 'EMPLOYEE_EXPENSE',
  ASSET: 'ASSET',
  PURCHASE_ORDER: 'PURCHASE_ORDERS',
};

const fillCurrencies = (data: any[]) => {
  return data.map(item => {
    const children: any[] = item.children ? fillCurrencies(item.children) : item.children;

    const currencies: Dictionary<boolean> = children.reduce((acc: Dictionary<boolean>, child: any) => {
      return {
        ...acc,
        ...child.currencies,
      };
    }, {});

    if (item.currency) {
      currencies[item.currency] = true;
    }

    return {
      ...item,
      currencies,
      children,
    };
  });
};

const useLedgerService = () => {
  const { sendGetRequest } = useFinanceApi();

  const search = useSingletonPromise('search-ledger-accounts', async () => {
    const response = (await sendGetRequest(RESOURCE_URL)) || [];
    const result = await response.json();

    return {
      result: fillCurrencies(result),
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const getById = async (id: string) => {
    const response = await sendGetRequest(`${RESOURCE_URL}/id?accountId=${id.replace(/[_]/g, '/')}`);

    return response.json();
  };

  const searchInvoiceAccount = useSingletonPromise('search-invoice-accounts', async () => {
    const response = (await sendGetRequest('/procurement/invoices/accounts')) || [];
    const result = await response.json();

    return {
      result: fillCurrencies(result),
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const searchEmployeeExpensesAccount = useSingletonPromise('search-employee-expenses-accounts', async () => {
    const response = (await sendGetRequest('/employee-expenses/accounts')) || [];
    const result = await response.json();

    return {
      result: fillCurrencies(result),
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const searchJournalEntriesAccount = useSingletonPromise('search-journal-entries-accounts', async () => {
    const response = (await sendGetRequest('/ledger/journal-entries/accounts')) || [];
    const result = await response.json();

    return {
      result: fillCurrencies(result),
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const searchPurchaseOrderAccount = useSingletonPromise('search-purchase-order-accounts', async () => {
    const response = (await sendGetRequest('/procurement/invoices/accounts')) || [];
    const result = await response.json();

    return {
      result: fillCurrencies(result),
      totalElements: result.length,
      totalPages: 1,
    };
  });

  return {
    search,
    getById,
    searchInvoiceAccount,
    searchEmployeeExpensesAccount,
    searchJournalEntriesAccount,
    searchPurchaseOrderAccount,
  };
};

export default useLedgerService;
