/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CandidateStatusDropdown from 'erp/candidate/shared/input/CandidateStatusDropdown';

const CandidateStatusFilter = props => {
  return (
    <DropDownFilter {...props}>
      <CandidateStatusDropdown />
    </DropDownFilter>
  );
};

export default CandidateStatusFilter;
