/* istanbul ignore file */
// libs
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
// components
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
// services
import EmployeesListPage from 'erp/employee/List';
import {
  ASSIGN_MENTOR,
  CREATE_EMPLOYEE,
  EDIT_EMPLOYEE_PAGE_PERMISSIONS,
  GET_EMPLOYEES_LIST,
  MY_PAGE_PARAMETER,
  READ_EDUCATIONS_LIST,
  READ_EMPLOYEE_COMPENSATION_PACKAGES,
  READ_EMPLOYEE_CONTRACTS,
  READ_EMPLOYEE_PERMISSION,
  READ_EMPLOYEE_TIMELINE,
  READ_LIST_EMPLOYEE_SALARY,
} from 'erp/employee/employeeService';
import CreateEmployeePage from 'erp/employee/createupdate/Create';
import useRoute from 'shared/routing/useRoute';
import { AssessmentRouter } from 'erp/employee/assessment';
import EmployeeContext from 'erp/employee/EmployeeContext';
import ShowEmployee from 'erp/employee/shared/ShowEmployee';
import useEntityUrl from 'shared/routing/useEntityUrl';
import EmployeeViewPage from 'erp/employee/Show';
import EmployeeViewProfilePage from 'erp/employee/Show/Profile';
import EmployeeContractsRouter from 'erp/employee/contracts/EmployeeContractsRouter';
import LeaveBalanceRouter from 'erp/employee/leavebalance/LeaveBalancesRouter';
import EducationRouter from 'erp/employee/Education/EducationRouter';
import FinanceRouter from 'erp/employee/finance/FinanceRouter';
import { READ_LEAVES_BALANCES } from 'erp/employee/leavebalance/shared/balancesService';
import UpdateEmployeeProfile from 'erp/employee/createupdate/Update';
import EmployeeTimelinePage from 'erp/employee/Timeline';
import MentorsPage from 'erp/employee/mentors/MentorsPage';
import { READ_ACCESS_LOG } from 'erp/employee/AccessLog/accessLogService';
import AccessLogPage from 'erp/employee/AccessLog';
import EmployeeIdPage from 'erp/employee/EmployeeIdPage';
import { useAuthenticationProvider } from 'authentication/AuthenticationProvider';
import SkillsRouter from './skills/SkillsRouter';
import { READ_EMPLOYEE_SKILLS } from './skills/EmployeeSkillsService';
import FeedbacksRouter from './feedbacks/FeedbacksRouter';
import { FEEDBACKS_ABOUT_ME } from './feedbacks/EmployeeFeedbacksService';
import EmployeeAssignmentsRouter from './assignments/EmployeeAssignmentsRouter';
import { READ } from '../../crm/crmService';
import { READ_BACKGROUND_CHECK_LIST } from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';
import EmployeeBackgroundChecksRouter from './backgroundcheck/EmployeeBackgroundChecksRouter';
import EmployeePerformanceReportRouter from './performanceReport/PerformanceReportRouter';
import SalaryRouter from './salaries/SalaryRouter';
import EmployeeOfferRouter from './offers/EmployeeOfferRouter';
import EmployeePerformanceRouter from './performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import { READ_EMPLOYEE_ASSIGNMENTS } from 'erp/assignment/shared/assignmentService';
import CompanyRolesRouter from './companyRoles/CompanyRolesRouter';
import { READ_EMPLOYEE_COMPANY_ROLES } from './companyRoles/shared/companyRolesService';
import { NewQualificationRouter } from './newQualification';
import QualificationRouter from './qualification/QualificationRouter';

export const EMPLOYEES_PATH = '/employees';
export const MY_PAGE_PATH = `${EMPLOYEES_PATH}/me`;

export const useEmployeeRoute = () => {
  const routers = useRoute({ listRoute: EMPLOYEES_PATH });

  return {
    ...routers,
    updateRoute: `${routers.singleEntityRoute}/profile/edit`,
    mentorsRoute: `${routers.singleEntityRoute}/mentors`,
    summaryRoute: `${routers.singleEntityRoute}/summary`,
    educationRoute: `${routers.singleEntityRoute}/education`,
    financeRouter: `${routers.singleEntityRoute}/finance`,
    skillsRouter: `${routers.singleEntityRoute}/skills`,
    feedbacksRouter: `${routers.singleEntityRoute}/feedbacks`,
    assignmentsRouter: `${routers.singleEntityRoute}/assignments`,
    backgroundChecksRouter: `${routers.singleEntityRoute}/backgroundChecks`,
    performanceReportRouter: `${routers.singleEntityRoute}/performance-report`,
    salariesRouter: `${routers.singleEntityRoute}/salaries`,
    offersRouter: `${routers.singleEntityRoute}/offers`,
    performanceResultsRouter: `${routers.singleEntityRoute}/performanceData`,
    companyRolesRouter: `${routers.singleEntityRoute}/roles`,
  };
};

export const useEmployeeUrl = () => {
  const { getSingleEntityUrl: getBaseSingleEntityUrl, ...rest } = useEntityUrl({
    baseLink: EMPLOYEES_PATH,
    editPostfix: 'profile/edit',
  });
  const urlId = useParams<Dictionary<string>>().id;
  const { getUserAlias } = useAuthenticationProvider();
  const userAlias = getUserAlias();

  const getSingleEntityUrl = (id?: string) => {
    const isMyPage = urlId === MY_PAGE_PARAMETER && userAlias === id;
    const currentId = isMyPage ? MY_PAGE_PARAMETER : id;

    return getBaseSingleEntityUrl(currentId);
  };

  const getProfileUrl = (id: string) => `${getSingleEntityUrl(id)}/profile`;

  const getProfileEditUrl = (id: string) => `${getSingleEntityUrl(id)}/profile/edit`;

  const getTimelineUrl = (id: string) => `${getSingleEntityUrl(id)}/timeline`;

  const getMentorsUrl = (id: string) => `${getSingleEntityUrl(id)}/mentors`;

  const getAccessLogUrl = (id: string) => `${getSingleEntityUrl(id)}/logs`;

  const getTimelineSingleEntityUrl = (employeeId: string, timelineId: string) =>
    `${getTimelineUrl(employeeId)}/${timelineId}`;

  const getSummaryUrl = (id: string) => `${getSingleEntityUrl(id)}/summary`;

  const getEmployeeAssignmentsUrl = (id: string) => `${getSingleEntityUrl(id)}/assignments`;

  const getEmployeeBackgroundChecksUrl = (id: string) => `${getSingleEntityUrl(id)}/backgroundChecks`;

  const getEmployeePerformanceReportUrl = (id: string) => `${getSingleEntityUrl(id)}/performance-report`;

  return {
    ...rest,
    getSingleEntityUrl,
    getProfileUrl,
    getProfileEditUrl,
    getTimelineUrl,
    getTimelineSingleEntityUrl,
    getMentorsUrl,
    getAccessLogUrl,
    getSummaryUrl,
    getEmployeeAssignmentsUrl,
    getEmployeeBackgroundChecksUrl,
    getEmployeePerformanceReportUrl,
  };
};

export const useEmployeeId = () => {
  const urlId = useParams<Dictionary<string>>().id;
  const { getUserAlias } = useAuthenticationProvider();
  const userAlias = getUserAlias();

  return urlId === MY_PAGE_PARAMETER ? userAlias : urlId;
};

const EmployeesRouter = () => {
  const {
    listRoute,
    singleEntityRoute,
    createRoute,
    mentorsRoute,
    updateRoute,
    timelineRoute,
    summaryRoute,
    educationRoute,
    financeRouter,
    assignmentsRouter,
    backgroundChecksRouter,
    performanceReportRouter,
    salariesRouter,
    offersRouter,
    performanceResultsRouter,
    companyRolesRouter,
  } = useEmployeeRoute();

  return (
    <ProtectedRouter basePath={EMPLOYEES_PATH}>
      <Switch>
        <Route path={`${singleEntityRoute}/qualifications`}>
          <EmployeeContext>
            <ShowEmployee>
              <NewQualificationRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/old-qualifications`}>
          <EmployeeContext>
            <ShowEmployee>
              <QualificationRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/assessments`}>
          <EmployeeContext>
            <ShowEmployee>
              <AssessmentRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={mentorsRoute}>
          <EmployeeContext permissionToCheck={ASSIGN_MENTOR}>
            <ShowEmployee>
              <MentorsPage />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/leaves`}>
          <EmployeeContext permissionToCheck={READ_LEAVES_BALANCES}>
            <ShowEmployee>
              <LeaveBalanceRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/skills`}>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_SKILLS}>
            <ShowEmployee>
              <SkillsRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/feedbacks`}>
          <EmployeeContext permissionToCheck={FEEDBACKS_ABOUT_ME}>
            <ShowEmployee>
              <FeedbacksRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={GET_EMPLOYEES_LIST}>
          <EmployeesListPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_EMPLOYEE}>
          <CreateEmployeePage />
        </GlobalProtectedRoute>

        <Route path={singleEntityRoute} exact>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_PERMISSION}>
            <EmployeeIdPage />
          </EmployeeContext>
        </Route>

        <Route path={summaryRoute} exact>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_PERMISSION}>
            <EmployeeViewPage />
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/profile`} exact>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_PERMISSION}>
            <EmployeeViewProfilePage />
          </EmployeeContext>
        </Route>

        <Route path={updateRoute} exact>
          <EmployeeContext permissionToCheck={EDIT_EMPLOYEE_PAGE_PERMISSIONS}>
            <ShowEmployee>
              <UpdateEmployeeProfile />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/contracts`}>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_CONTRACTS}>
            <ShowEmployee>
              <EmployeeContractsRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={timelineRoute} exact>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_TIMELINE}>
            <ShowEmployee>
              <EmployeeTimelinePage />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={`${singleEntityRoute}/logs`}>
          <EmployeeContext permissionToCheck={READ_ACCESS_LOG}>
            <ShowEmployee>
              <AccessLogPage />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={educationRoute}>
          <EmployeeContext permissionToCheck={READ_EDUCATIONS_LIST}>
            <ShowEmployee>
              <EducationRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={financeRouter}>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_COMPENSATION_PACKAGES}>
            <ShowEmployee>
              <FinanceRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={salariesRouter}>
          <EmployeeContext permissionToCheck={READ_LIST_EMPLOYEE_SALARY}>
            <ShowEmployee>
              <SalaryRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={offersRouter}>
          <EmployeeContext permissionToCheck={READ}>
            <ShowEmployee>
              <EmployeeOfferRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={assignmentsRouter}>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_ASSIGNMENTS}>
            <ShowEmployee>
              <EmployeeAssignmentsRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={backgroundChecksRouter}>
          <EmployeeContext permissionToCheck={READ_BACKGROUND_CHECK_LIST}>
            <ShowEmployee>
              <EmployeeBackgroundChecksRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={performanceReportRouter}>
          <EmployeeContext permissionToCheck={READ}>
            <ShowEmployee>
              <EmployeePerformanceReportRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={performanceResultsRouter}>
          <EmployeeContext permissionToCheck={READ}>
            <ShowEmployee>
              <EmployeePerformanceRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route path={companyRolesRouter}>
          <EmployeeContext permissionToCheck={READ_EMPLOYEE_COMPANY_ROLES}>
            <ShowEmployee>
              <CompanyRolesRouter />
            </ShowEmployee>
          </EmployeeContext>
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

EmployeesRouter.getRouterPath = () => EMPLOYEES_PATH;

export default EmployeesRouter;
