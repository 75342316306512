/* istanbul ignore file */
import React from 'react';
import RelationInput from 'erp/employee/shared/input/RelationInput';
import ChildRelationInput from 'erp/employee/shared/input/ChildrenInput/ChildRelationInput';

const ChildInput = props => {
  return <RelationInput relationInput={ChildRelationInput} {...props} />;
};

export default ChildInput;
