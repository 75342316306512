import { Authenticator } from 'authentication/authenticator';
import useAzureAuthenticator from 'authentication/azure/azureAuthenticator';
import useAuthenticationTokenRepository, {
  AuthenticationToken,
} from 'authentication/tokenprovider/authenticationTokenRepository';

export interface AuthenticationService {
  isAuthenticated: () => Promise<boolean>;
  getCurrentAuthenticator: () => Promise<Nullable<Authenticator>>;
}

const useAuthenticationService = (): AuthenticationService => {
  const { getToken } = useAuthenticationTokenRepository();
  const azureAuthenticator = useAzureAuthenticator();

  const isAuthenticated = async (): Promise<boolean> => {
    const authToken: AuthenticationToken = await getToken();

    return !!authToken;
  };

  const getCurrentAuthenticator = async (): Promise<Nullable<Authenticator>> => {
    const isUserAuthenticated = await isAuthenticated();

    return isUserAuthenticated ? azureAuthenticator : null;
  };

  return {
    isAuthenticated,
    getCurrentAuthenticator,
  };
};

export default useAuthenticationService;
