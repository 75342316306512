/* istanbul ignore file */
import React from 'react';
import useCandidateOfferService from 'erp/candidate/offercompensation/shared/candidateOfferService';
import { CreateForm } from 'shared/uibuilder/form';
import CreateCandidateOfferBreadcrumbs from 'erp/candidate/offercompensation/createupdate/Create/CreateBreadcrumbs';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import CandidateOfferForm from 'erp/candidate/offercompensation/createupdate/CandidateOfferForm';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CreateCandidateOfferPage = () => {
  const { create, getValidationSchema } = useCandidateOfferService();
  const candidateId = useCandidateId();
  const { getTimelineUrl } = useCandidateUrl();

  return (
    <CreateForm
      initialData={{
        offerCompensations: [],
      }}
      submitFormFunc={create}
      afterSubmit={{
        redirect: getTimelineUrl(candidateId),
        message: 'You are awesome! The offer has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        title="Create: Offer"
        breadcrumbs={<CreateCandidateOfferBreadcrumbs />}
        entityHeader={<CandidateEntityHeader />}
        menu={<CandidateMenu />}
      >
        <CandidateOfferForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateCandidateOfferPage;
