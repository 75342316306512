/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const SkillBasedQueueBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useSkillBasedQueueUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="SkillBasedQueue" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default SkillBasedQueueBreadcrumbs;
