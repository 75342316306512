import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import React, { useState } from 'react';
import useHiringService from 'erp/recruitment/hiring/useHiringService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import HiringRequestField from 'erp/recruitment/vacancy/shared/field/HiringRequestField';

const HiringRequestListField = () => {
  const { getByIds } = useHiringService();
  const { data } = useShowContext();

  const [hiringRequests, setHiringRequests] = useState([]);

  (async () => {
    setHiringRequests(await getByIds(data.getValueBySource('hiringRequestIds')));
  })();

  return (
    <FieldListLayout value={hiringRequests} hasSeparator>
      <HiringRequestField />
    </FieldListLayout>
  );
};

export default HiringRequestListField;
