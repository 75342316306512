import React from 'react';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ProjectsArchivedToggler from './ProjectsArchivedToggler';

const ProjectsSettings = () => {
  return (
    <DropdownMenu
      popupId="journal-entry-settings"
      iconButton={
        <IconButton className="dropdown-toggle">
          <SettingsIcon />
        </IconButton>
      }
    >
      <ProjectsArchivedToggler />
    </DropdownMenu>
  );
};

export default ProjectsSettings;
