/* istanbul ignore file */
import React from 'react';
import ProjectIssue from 'syberryToday/shared/ProjectIssue';
import Button from 'uibuilder/button/Button';
import AddIcon from '@mui/icons-material/AddOutlined';
import useProjectQueue from 'syberryToday/shared/ProjectQueue/useProjectQueue';
import ProjectQueueLayout from 'syberryToday/shared/layout/ProjectQueueLayout';
import { BACKLOG_CATEGORIES } from '../../useSyberryTodayService';
import useRedmineUrl from 'redmine/useRedmineUrl';

interface ProjectQueueProps {
  projectId: StringOrNumber;
  queueId: StringOrNumber;
  data: any;
  title: string;
  subtitle?: string;
  canAddIssues?: boolean;
  sortable?: boolean;
}

const DEFAULT_QUEUE_TITLE = 'No queue';

const ProjectQueue = ({
  projectId,
  data,
  title,
  subtitle,
  canAddIssues,
  sortable = true,
  queueId,
}: ProjectQueueProps) => {
  const {
    updateInList,
    moveItem,
    items,
    moveToTop,
    selectedIssues,
    saveUpdatesIssuePositions,
    removeFromList,
  } = useProjectQueue({
    data,
    projectId,
    queueId,
  });
  const { getProjectUrl } = useRedmineUrl();

  return (
    <ProjectQueueLayout
      title={title || DEFAULT_QUEUE_TITLE}
      buttons={[
        canAddIssues && (
          <Button
            outline
            className="add-task-button"
            startIcon={<AddIcon />}
            component="a"
            href={`${getProjectUrl(items[0].project.id)}/issues/new?issue[custom_queue_id]=${queueId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Add task
          </Button>
        ),
      ]}
      subtitle={subtitle}
      id={`today-column-${projectId}`}
      counterValue={items.length}
    >
      {items.map((item: any, i: number) => {
        const isItemSelected = selectedIssues.includes(item.id);
        const previousIssues = items.slice(0, i);
        const arePreviousIssuesSelected = previousIssues.every(({ id }: any) => selectedIssues.includes(id));
        const arePreviousIssuesSelectedOrBlocked = previousIssues.every(
          ({ id, backlogCategory }: any) =>
            selectedIssues.includes(id) || backlogCategory === BACKLOG_CATEGORIES.BLOCKED,
        );

        return (
          <ProjectIssue
            key={item.id}
            item={item}
            index={i}
            moveItem={moveItem}
            canPlan={(() => {
              if (!queueId) {
                return true;
              }

              // Can plan/unplan issues if
              // It is first blocked issue
              // It is first unselected issue
              // It is last selected issue
              if (isItemSelected) {
                return items.findLastIndex(({ id }) => selectedIssues.includes(id)) === i;
              }

              return (
                !i ||
                (item.backlogCategory === BACKLOG_CATEGORIES.BLOCKED
                  ? arePreviousIssuesSelected &&
                    items.findIndex(({ backlogCategory }) => backlogCategory === BACKLOG_CATEGORIES.BLOCKED) === i
                  : arePreviousIssuesSelectedOrBlocked)
              );
            })()}
            moveToTop={!i || (!isItemSelected && arePreviousIssuesSelected && queueId) ? null : moveToTop}
            sortable={sortable}
            updateInList={updateInList}
            removeFromList={removeFromList}
            onIssueDrop={callback => saveUpdatesIssuePositions(item.id, callback)}
          />
        );
      })}
    </ProjectQueueLayout>
  );
};

export default ProjectQueue;
