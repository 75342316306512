/* istanbul ignore file */
import React from 'react';
import Typography from 'uibuilder/Typography';
import Paper from 'uibuilder/Paper';
import './OneStopCreatePageLayout.scss';

type CreatePageLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  id?: string;
};

const OneStopCreatePageLayout = ({ children, id }: CreatePageLayoutProps) => {
  return (
    <Paper className="page-content onestop-page-content">
      <Typography variant="h1" sx={{ marginBottom: '30px' }} className="c-title">
        One Stop: Create Request
      </Typography>
      <div id={`request-${id}`}>{children}</div>
    </Paper>
  );
};

export default OneStopCreatePageLayout;
