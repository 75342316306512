import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { get } from 'lodash';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';
import 'financialAnalytic/journalEntry/shared/input/TransferListInputTotal.scss';

const getTotal = (data: any[]) =>
  data.reduce((acc: number, item: any) => {
    const amount = get(item, 'cost.amount') || 0;
    const quantity = get(item, 'quantity') || 1;

    return +acc + +quantity * amount;
  }, 0);

const PurchaseListInputTotal = ({
  purchasesSource,
  currencySource,
}: {
  purchasesSource: string;
  currencySource: string;
}) => {
  const { getValue: getPurchases } = useInputHelper({
    source: purchasesSource,
  });
  const { getValue: getCurrency } = useInputHelper({
    source: currencySource,
  });
  const purchases = (getPurchases() as any) || [];
  const currency = getCurrency() as any;
  const total = getTotal(purchases);

  return (
    <div className="journal-entry-total-wrapper">
      <span className="journal-entry-total-title">Total:</span>
      <div />
      <div />
      <div>
        <span className="journal-entry-total-label">Total: </span>
        {getWithThousandsSeparator(total || 0)}
        {currency ? CURRENCIES[currency] : ''}
      </div>
    </div>
  );
};

export default PurchaseListInputTotal;
