import React, { useEffect, useMemo, useState } from 'react';
import { ButtonDropdown } from 'shared/uibuilder/form/input';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import isEqual from 'lodash/isEqual';
import { getActiveFilters } from 'shared/uibuilder/list/filter/useFilter';
import classnames from 'classnames';
import FilterIcon from '@mui/icons-material/FilterListOutlined';
import './QuickFiltersLayout.scss';

const QuickFiltersLayout = ({
  children,
  displayedValue,
}: {
  children?: React.ReactElement[];
  displayedValue?: string;
}) => {
  const { filters = {} } = useFilterContext();
  const [isMenuShown, setIsMenuShown] = useState(false);

  const toggleMenu = () => setIsMenuShown(!isMenuShown);

  const value = useMemo(() => {
    const activeFilters = getActiveFilters(filters);
    const activeValue = React.Children.toArray(children).find(({ props: { state = {} } = {} }: any) =>
      isEqual(activeFilters, state),
    ) as React.ReactElement;

    if (activeValue) {
      return activeValue?.props.label;
    } else {
      return '';
    }
  }, [children, filters]);

  const filterValue = displayedValue || value;

  useEffect(() => {
    setIsMenuShown(false);
  }, [value, displayedValue]);

  return children ? (
    <div className={classnames('quick-filter-button', { applied: !!filterValue })}>
      <ButtonDropdown
        label={
          <>
            <FilterIcon sx={{ mr: '8px', fontSize: '20px' }} />
            <span>Quick Filters{filterValue ? ':' : ''}</span>
          </>
        }
        displayedValue={filterValue}
        toggleMenu={toggleMenu}
        isOpen={isMenuShown}
      >
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            className: classnames(child.props.className, 'button-dropdown__menu-item'),
          }),
        )}
      </ButtonDropdown>
    </div>
  ) : null;
};

export default QuickFiltersLayout;
