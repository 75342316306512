/* istanbul ignore file */
// libs
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import OpportunityEntityHeader from 'crm/opportunity/shared/OpportunityEntityHeader';
import UpdateOpportunityBreadcrumbs from 'crm/opportunity/createupdate/UpdateOpportunity/Breadcrumbs';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';
import OpportunityForm from 'crm/opportunity/createupdate/OpportunityForm';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const UpdateOpportunity = () => {
  const { update, getById, getValidationSchema } = useOpportunityService();
  const { getSingleEntityUrl } = useOpportunityUrl();

  const getDataMethod = async opportunityId => {
    const data = await getById(opportunityId);

    return {
      ...data,
      accountCodeName: data.accountName,
      accountName: data.hasAccountCodeName ? data.accountName : null,
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataMethod}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Opportunity has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Opportunity"
        breadcrumbs={<UpdateOpportunityBreadcrumbs />}
        entityHeader={<OpportunityEntityHeader />}
      >
        <OpportunityForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateOpportunity;
