/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import { useServiceUrl } from 'erp/service/ServiceRouter';

const ServiceBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getListUrl } = useServiceUrl();
  const serviceListLink = getListUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Service" link={serviceListLink} />
      {children}
    </Breadcrumbs>
  );
};

export default ServiceBreadcrumbs;
