/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import UploadFileForm from 'erp/costaccounting/reconcilation/shared/UploadFileForm';
import { UPLOAD_BANK_STATEMENT } from 'erp/costaccounting/reconcilation/useReconciliationService';
import { ReactComponent as Icon } from 'artifact/images/upload.svg';

const UploadFileButton = ({ execute }: { execute: () => void }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const closeModal = () => {
    setModalOpen(false);
  };

  return isGranted(UPLOAD_BANK_STATEMENT) ? (
    <>
      <Button
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
        startIcon={<Icon />}
        sx={{
          'svg path': {
            fill: 'var(--mui-palette-primary-contrastText)',
            opacity: 0.9,
          },
        }}
      >
        Upload statement
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title="Upload statement"
        onToggle={closeModal}
        hasCancelButton={false}
      >
        <UploadFileForm closeModal={closeModal} modalOpen={modalOpen} execute={execute} />
      </ModalWindow>
    </>
  ) : null;
};

export default UploadFileButton;
