/* istanbul ignore file */
export default {
  accountName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'NEW',
        },
      },
      message: 'Please fill this field',
    },
  },
  originalAccountName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'NEW',
        },
      },
      message: 'Please fill this field',
    },
  },
  existingAccountName: {
    type: 'string',
    required: {
      value: true,
      when: {
        $accountType: {
          eq: 'EXISTING',
        },
      },
      message: 'Please choose an account from the list',
    },
  },
  firstName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      message: 'Please fill this field',
    },
  },
  title: {
    type: 'string',
    maxSize: 255,
    required: {
      value: false,
    },
  },
  middleName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: false,
    },
  },
  lastName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      message: 'Please fill this field',
    },
  },
  opportunityName: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please fill this field',
    },
  },
  closeDate: {
    type: 'date',
    minDate: {
      value: '$TODAY',
      message: 'Please choose date in future',
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
    },
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please select the date',
    },
  },
  amount: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createOpportunity: {
          ne: true,
        },
      },
      message: 'Please fill this field',
    },
  },
};
