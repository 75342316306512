/* istanbul ignore file */
import crmNoteValidation from 'crm/shared/timeline/note/form/crmNoteValidation';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

export const UPDATE_CRM_NOTE_PERMISSION = 'CREATE_NOTE';
export const DELETE_CRM_NOTE_PERMISSION = 'CREATE_NOTE';

const useCrmNoteService = () => {
  const { deleteById, update } = useBaseCrudService({
    listResourceUrl: '/crm/notes',
    singleResourceUrl: '/crm/notes/:id',
    apiService: useKernelApi,
  });

  return {
    getValidationSchema: () => Promise.resolve(crmNoteValidation),
    deleteById,
    update,
  };
};

export default useCrmNoteService;
