/* istanbul ignore file */
import React, { useMemo } from 'react';
import CandidatesBreadcrumbs from 'erp/candidate/shared/CandidatesBreadcrumbs';
import { SingleBreadcrumbItem, UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const UpdateCandidateBreadcrumbs = () => {
  const { id } = useParams();
  const {
    data: {
      name: { fullName },
    },
  } = useFormContext();
  const { getSingleEntityUrl } = useCandidateUrl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedName = useMemo(() => fullName, []);

  return (
    <CandidatesBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={getSingleEntityUrl(id)} />
      <UpdateBreadcrumbItem />
    </CandidatesBreadcrumbs>
  );
};

export default UpdateCandidateBreadcrumbs;
