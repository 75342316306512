import React from 'react';
import { Dropdown } from 'shared/uibuilder/form/input';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

export interface EnumDropdownProps extends Omit<DefaultDropdownProps, 'options'> {
  options: Dictionary<string>;
}

const EnumDropdown = ({ options, placeholder, ...props }: EnumDropdownProps) => {
  return (
    <Dropdown
      {...props}
      placeholder={placeholder || 'Select status'}
      options={Object.keys(options).map(key => ({
        value: key,
        label: options[key],
      }))}
    />
  );
};

export default EnumDropdown;
