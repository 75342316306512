/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import { UrlParams } from 'shared/routing/useEntityUrl';
import Page404 from 'shared/uibuilder/Page404';
import EF1CreatePage from 'externalform/ef1/create/EF1CreatePage';
import PublicLayout from 'layout/Public';
import { Router, Route } from 'shared/routing';

export const EXTERNAL_FORM_ID = 'formId';
export const EXTERNAL_FORMS_PATH = '/external-forms';

export const useExternalFormId = () => {
  const urlParams: UrlParams = useParams();

  return urlParams[EXTERNAL_FORM_ID];
};

const ExternalFormRouter: Router = () => {
  const createRoute = `${EXTERNAL_FORMS_PATH}/:${EXTERNAL_FORM_ID}`;

  return (
    <Route path={EXTERNAL_FORMS_PATH}>
      <Switch>
        <Route path={createRoute} exact>
          <PublicLayout>
            <EF1CreatePage />
          </PublicLayout>
        </Route>

        <Route component={Page404} />
      </Switch>
    </Route>
  );
};

ExternalFormRouter.getRouterPath = () => EXTERNAL_FORMS_PATH;

export default ExternalFormRouter;
