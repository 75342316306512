/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { PURCHASE_ORDER_STATUSES_OPTIONS } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';

const PurchaseOrderStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={PURCHASE_ORDER_STATUSES_OPTIONS} label="Status" {...props} />;
};

export default PurchaseOrderStatusDropdown;
