/* istanbul ignore file */
import React from 'react';
import useGeneratedPayrollsService, {
  APPROVE_PAYROLL_REPORT,
  PAYROLL_REPORT_STATUSES,
} from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import ButtonWithConfirmationDateInput from 'financialAnalytic/shared/button/ButtonWithConfirmationDateInput';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import Box from 'uibuilder/Box';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TextField from 'shared/uibuilder/field/TextField';
import List from 'shared/uibuilder/list/List';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import { isEqual } from 'lodash';
import useAsyncValue from 'shared/asyncHelper';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import TotalFieldDecorator from 'erp/payroll/generatedPayrolls/shared/field/TotalFieldDecorator';

const ApprovePayrollReportButton = ({ statusSource }: { statusSource: string }) => {
  const { approvePayrollReport, getGrossSalary } = useGeneratedPayrollsService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { data } = useShowContext();
  const { updateInList = () => {}, data: listData } = useListContext();
  const status = getValue();
  const contextData = data.getData() || {};
  const hasApprovedReport = listData?.items?.some(
    (item: any) =>
      (item.status === PAYROLL_REPORT_STATUSES.APPROVED || item.status === PAYROLL_REPORT_STATUSES.PAID) &&
      item.period === contextData.period &&
      isEqual(item.offices, contextData.offices),
  );
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(getUserAlias);

  const getDataMethod = async () => {
    const result = await getGrossSalary(contextData.id);
    const totalWorkingHours = result.reduce((acc: number, { workingHours }: any) => acc + workingHours, 0);
    const totalGrossSalary = result.reduce(
      (acc: Dictionary<number>, { currency, grossSalary }: any) => ({
        ...acc,
        [currency]: (acc[currency] || 0) + grossSalary,
      }),
      {},
    );

    if (result.length) {
      result.push({
        totalLabel: 'Total',
        totalWorkingHours,
        totalGrossSalary,
      });
    }

    return {
      result,
      totalElements: result.length,
      totalPages: 1,
    };
  };

  if (status !== PAYROLL_REPORT_STATUSES.PENDING || hasApprovedReport) {
    return null;
  }

  return (
    <ButtonWithConfirmationDateInput
      modalSize="sm"
      modalChildren={
        <Box
          sx={{
            background: 'var(--mui-palette-background-paper)',
            borderRadius: '4px',
            margin: '20px 0 8px',
            padding: '4px 12px 16px',
            position: 'relative',
            minHeight: '100px',
            '.table-pagination-wrapper': {
              display: 'none',
            },
          }}
        >
          <List getDataMethod={getDataMethod}>
            <DataGridLayout showTotal={false} tableWrapperClassName="payroll-approve-table">
              <TotalFieldDecorator source="totalLabel" label="Employee">
                <EmployeeLinkField source="employeeAlias" nameSource="employeeName" />
              </TotalFieldDecorator>
              <TotalFieldDecorator source="totalWorkingHours" label="Working Hours">
                <TextField source="workingHours" />
              </TotalFieldDecorator>
              <TotalFieldDecorator source="totalGrossSalary" label="Gross Salary">
                <InvoiceTotalField source="grossSalary" currencySource="currency" />
              </TotalFieldDecorator>
            </DataGridLayout>
          </List>
        </Box>
      }
      displayMessage="Do you really want to approve this payroll report? Please, check data and fill out the input below."
      submitMethod={(date: string) => approvePayrollReport(contextData.id as string, date)}
      afterSubmit={{
        successMessage: 'The payroll report has been successfully approved.',
        errorMessage: "Can't approve this payroll report.",
        execute: () => {
          updateInList({ ...contextData, status: PAYROLL_REPORT_STATUSES.APPROVED, approvedBy: userAlias } as ListItem);
        },
      }}
      permissionToCheck={APPROVE_PAYROLL_REPORT}
      variant="outlined"
      inputLabel="Date"
    >
      Approve
    </ButtonWithConfirmationDateInput>
  );
};

export default ApprovePayrollReportButton;
