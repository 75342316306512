import React from 'react';
import { get } from 'lodash';
import TextInput from './TextInput';

import useUiTheme from 'shared/uibuilder/useUiTheme';
import CountryDropdown from 'shared/uibuilder/form/input/CountryDropdown';
import UsStateDropdown from 'shared/uibuilder/form/input/UsStateDropdown';
import { FormFieldsData } from '../FormContext';

export interface AddressInputProps {
  source: string;
  countryLabel?: string;
  stateLabel?: string;
  cityLabel?: string;
  addressLine1Label?: string;
  addressLine2Label?: string;
  zipLabel?: string;
  hasAddressLine2?: boolean;
  className?: TypeOrFunction<string>;
  children?: React.ReactNode;
}

type AddressInputLayoutType = ReactComponent<any>;

/**
 * Component is used to display the controls for the form for address entering.
 *
 * @param source
 * @param countryLabel custom country label
 * @param stateLabel custom state label
 * @param cityLabel custom city label
 * @param addressLine1Label custom address line 1 label
 * @param addressLine2Label custom address line 2 label
 * @param hasAddressLine2 if true will be additional input for address line 2
 * @param zipLabel custom zip label
 * @param className className
 * @param children
 * @returns {*}
 * @constructor
 */
const AddressInput = ({
  source,
  countryLabel,
  stateLabel,
  cityLabel,
  addressLine1Label,
  zipLabel,
  hasAddressLine2 = false,
  addressLine2Label,
  className = '',
  children,
}: AddressInputProps) => {
  const { AddressInputLayout } = useUiTheme<AddressInputLayoutType>();

  const addressLine1Source = `${source}.addressLine1`;
  const addressLine2Source = `${source}.addressLine2`;
  const citySource = `${source}.city`;
  const stateSource = `${source}.state`;
  const zipSource = `${source}.zipCode`;
  const countrySource = `${source}.country`;

  const showIfUsSelected = (data: FormFieldsData) => get(data, countrySource) === 'United States';

  return (
    <AddressInputLayout>
      <CountryDropdown data-testid="country" label={countryLabel} source={countrySource} className={className} />
      <UsStateDropdown
        isVisible={showIfUsSelected}
        data-testid="state"
        label={stateLabel}
        source={stateSource}
        className={className}
      />
      <TextInput data-testid="city" label={cityLabel} source={citySource} className={className} />
      <TextInput
        data-testid="addressLine1"
        label={addressLine1Label}
        source={addressLine1Source}
        className={className}
      />
      {hasAddressLine2 && (
        <TextInput
          data-testid="addressLine2"
          label={addressLine2Label}
          source={addressLine2Source}
          className={className}
        />
      )}
      <TextInput data-testid="zip" label={zipLabel} source={zipSource} className={className} />
      {children}
    </AddressInputLayout>
  );
};

export default AddressInput;
