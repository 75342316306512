/* istanbul ignore file */
import React from 'react';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_NEW_COMPETENCY_LIST } from 'erp/qualification/newCompetency/newCompetencyService';
import NewQualificationModelViewPage from 'erp/qualification/newCompetency/Show';

export const NEW_QM_PATH = '/qualification-models';

export const useNewQualificationModelRoute = () => {
  return useRoute({ listRoute: NEW_QM_PATH });
};

export const useNewQualificationModelUrl = () => {
  return useEntityUrl({
    baseLink: NEW_QM_PATH,
  });
};

const NewCompetencyRouter = () => {
  const { listRoute } = useNewQualificationModelRoute();

  return (
    <ProtectedRouter basePath={NEW_QM_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_NEW_COMPETENCY_LIST}>
          <NewQualificationModelViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

NewCompetencyRouter.getRouterPath = () => NEW_QM_PATH;

export default NewCompetencyRouter;
