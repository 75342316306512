import useApplicationSettings from 'shared/uibuilder/applicationSettingsContext';
import { getCapitalizedString } from 'shared/uibuilder/helper';

const usePageTitle = () => {
  const { defaultPageTitle } = useApplicationSettings();

  const setPageTitle = title => {
    document.title = `${title ? `${getCapitalizedString(title)} - ` : ''}${defaultPageTitle}`;
  };

  const mapHtmlElementsForPageTitle = (elements = []) =>
    elements.reduce((sum, item) => `${item.textContent}${sum ? ` - ${sum}` : ''}`, '');

  return {
    mapHtmlElementsForPageTitle,
    setPageTitle,
  };
};

export default usePageTitle;
