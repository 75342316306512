import { useBusinessEngineApi } from 'api';
import { ListResponse } from 'shared/uibuilder/list/builder/useListCrud';
import useCrudService from 'shared/crud';
import useCacheService from 'shared/cache/cacheService';
import { PROCESS_KEY_CACHE } from 'camunda/monitoring/instance/List/ProcessKeyFilter';
import useProcessDefinitionService from 'camunda/monitoring/definition/processDefinitionService';
import { ListData } from 'shared/uibuilder/list/builder/useListDataLoading';

export const READ_INSTANCES_LIST = 'READ_INSTANCES_LIST';

export interface ActivityInstance extends ShortActivityInstance {
  startDateTime: string;
  endDateTime: string;
  dueDate: string;
}

export interface ShortActivityInstance {
  executionId: string;
  activityId: string;
  multiInstanceBody: boolean;
}

export interface Variable {
  name: string;
  type: string;
  value: any;
  executionId: string;
}

export interface Incident {
  activityId: string;
  executionId: string;
  timestamp: string;
}

export interface ProcessStarter {
  name: string;
  alias: string;
}

// https://docs.camunda.org/manual/7.6/reference/rest/history/process-instance/get-process-instance/#result
export enum ProcessState {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  COMPLETED = 'COMPLETED',
  EXTERNALLY_TERMINATED = 'EXTERNALLY_TERMINATED',
  INTERNALLY_TERMINATED = 'INTERNALLY_TERMINATED',
}

export const PROCESS_STATE_CAPTIONS = {
  [ProcessState.ACTIVE]: 'Active',
  [ProcessState.SUSPENDED]: 'Suspended',
  [ProcessState.COMPLETED]: 'Completed',
  [ProcessState.EXTERNALLY_TERMINATED]: 'Externally terminated',
  [ProcessState.INTERNALLY_TERMINATED]: 'Internally terminated',
};

export interface ShortProcessInstance {
  id: string;
  businessKey: string;
  processKey: string;
  processVersion: number;
  state: ProcessState;
  startDateTime: string;
  endDateTime: string;
  incidentCount: number;
}

export interface ProcessInstance extends ShortProcessInstance {
  processKey: string;
  xml: string;
  parentProcessId: string;
  processStarter: ProcessStarter;
  activities: List<ActivityInstance>;
  incidents: List<Incident>;
  runtimeActivities: List<ShortActivityInstance>;
}

export interface IssueAssignee {
  id: string;
  name: string;
}

export interface Issue {
  id: string;
  subject: string;
  assignee: IssueAssignee;
  createdOn: string;
}

const RESOURCE_URL = '/camunda/davinci-api/instances';

const useProcessInstanceService = () => {
  const { sendGetRequest } = useBusinessEngineApi();

  const getById = async (instanceId: string): Promise<ProcessInstance> => {
    const response = await sendGetRequest(`${RESOURCE_URL}/${instanceId}`);
    return response.json();
  };

  const getProcessIssues = async (instanceId: string): Promise<ListResponse<Issue>> => {
    const response = await sendGetRequest(`/issues/process-instance/${instanceId}`);
    const issues = await response.json();
    return {
      totalPages: 1,
      pageNumber: 1,
      result: issues,
    };
  };

  const getProcessVariables = async (instanceId: string): Promise<List<Variable>> => {
    const response = await sendGetRequest(`${RESOURCE_URL}/${instanceId}/variables`);
    const variables: List<Variable> = await response.json();

    const isObject = (value: any) => typeof value === 'object' && value !== null;

    return variables.map(variable => {
      const { name, type, executionId } = variable;
      let value;
      if (isObject(variable.value)) {
        value = JSON.stringify(variable.value);
      } else {
        value = variable.value?.toString() || 'null';
      }
      return { name, value, type, executionId };
    });
  };

  const getSubprocesses = async (instanceId: string): Promise<ListResponse<ShortProcessInstance>> => {
    const response = await sendGetRequest(`${RESOURCE_URL}/${instanceId}/subprocesses`);
    return response.json();
  };

  const getSupportIssues = async (instanceId: string): Promise<ListResponse<Issue>> => {
    const response = await sendGetRequest(`/issues/process-instance/${instanceId}/support`);
    return response.json();
  };

  const { search: crudSearch } = useCrudService({
    listResourceUrl: RESOURCE_URL,
    singleResourceUrl: `${RESOURCE_URL}/:id`,
  });

  const search = async (request: any): Promise<ListData> => {
    const result = await crudSearch(request);
    return result;
  };

  const { getValue, addToCache } = useCacheService(PROCESS_KEY_CACHE);
  const { getProcessKeys } = useProcessDefinitionService();
  const processKeys = getValue(PROCESS_KEY_CACHE);

  const cacheProcessKeys = async () => {
    if (!processKeys) {
      const keys = await getProcessKeys();
      addToCache(PROCESS_KEY_CACHE, keys);
    }
  };

  return {
    getById,
    getProcessIssues,
    getProcessVariables,
    getSubprocesses,
    getSupportIssues,
    search,
    cacheProcessKeys,
  };
};

export default useProcessInstanceService;
