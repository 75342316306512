/* istanbul ignore file */
import React, { useCallback } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import { ProjectStatusWorkItemType } from '../../ProjectStatusesService';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import AddListItemButton from 'erp/project/weeklyreports/shared/button/AddListItemButton';
import PlannedWorkItemInputRow, { PlannedWorkItemType } from '../../shared/input/PlannedWorkItemInputRow';

const ProjectPlanningStatusForm = () => {
  const { data: formData = {}, setFormData = (value: FormFieldsData) => {}, formErrors = {} } = useFormContext();

  const updateTickets = useCallback(
    (newValue: any) => {
      setFormData({
        ...formData,
        tickets: [...newValue],
      });
    },
    [formData, setFormData],
  );

  const addTicket = useCallback(() => {
    const { tickets } = formData;

    tickets.push({
      id: null,
      ticketName: '',
      assigneeAlias: null,
      assigneeName: null,
      projectStatusTicketType: ProjectStatusWorkItemType.PLANNED,
    });

    updateTickets(tickets);
  }, [updateTickets, formData]);

  const getErrorMessages = useCallback(
    (fieldSource: string) => {
      return formErrors[fieldSource];
    },
    [formErrors],
  );

  const tooltipForWorkItems = (
    <>
      <p>
        Provide a list of planned work items for the weekly cycle. These can include tickets from Redmine, Jira, or any
        other system that you use to work with clients.
      </p>
      <p>
        <b>Work item</b> should contain a concise title that accurately conveys the nature of the work to be done in the
        ticket
      </p>
      <p>
        <b>Assignee</b> is an employee responsible for delivering the scope of work item at the end of the weekly cycle
      </p>
      <p>
        <u>
          IMPORTANT: These work items will be included in the weekly report to the client after submitting the planned
          record.
        </u>
      </p>
    </>
  );

  const isHighlightSection = () => {
    return !!getErrorMessages('tickets') || formData.tickets.length === 0;
  };

  return (
    <>
      <FormRow>
        <DateInput source="from" label="Cycle period from" disabled />
        <DateInput source="to" label="Cycle period to" disabled />
      </FormRow>
      <FormSection
        title="Planned work items"
        titleVariant="h6"
        isLargeTooltip
        titleStyle={{ display: 'flex' }}
        tooltipMessage={tooltipForWorkItems}
        subtitle="Should be at least one planned work item"
        subtitleClass={isHighlightSection() ? 'required-section' : ''}
      >
        {formData.tickets.map((ticket: PlannedWorkItemType, id: number) => {
          return (
            <PlannedWorkItemInputRow
              data={ticket}
              id={id}
              formData={formData}
              getErrorMessages={getErrorMessages}
              updateTickets={updateTickets}
            />
          );
        })}
        <AddListItemButton onClickCallback={addTicket} />
      </FormSection>

      <MediumFormRow>
        <TextInput
          source="recordLink"
          label="Link to the planning record"
          tooltip="	The record for the planning call is optional."
        />
      </MediumFormRow>
    </>
  );
};

export default ProjectPlanningStatusForm;
