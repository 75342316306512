/* istanbul ignore file */
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import React from 'react';
import FeedbacksBreadcrumbs from './FeedbacksBreadcrumbs';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import useEmployeeFeedbacksService from 'erp/employee/feedbacks/EmployeeFeedbacksService';
import useFeedbackAboutMeRegistry from 'erp/employee/feedbacks/FeedbackAboutMeRegistry';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';

const EmployeeFeedbacksList = () => {
  const { search } = useEmployeeFeedbacksService();
  const feedbackRegistry = useFeedbackAboutMeRegistry();

  return (
    <Timeline registries={[feedbackRegistry]} getDataMethod={request => search(request)}>
      <TimelinePageLayout
        menu={<EmployeeMenu />}
        breadcrumbs={<FeedbacksBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        title="Feedback about my work"
        hasAddForm={false}
      >
        <TimelineLayout noEntriesMessage="No feedback about my work yet." />
      </TimelinePageLayout>
    </Timeline>
  );
};

export default EmployeeFeedbacksList;
