/* istanbul ignore file */
import React from 'react';
import useBudgetService from 'erp/budget/shared/budgetService';
import BudgetForm from 'erp/budget/createupdate/BudgetForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateBudgetBreadcrumbs from 'erp/budget/createupdate/Create/Breadcrumbs';
import { useBudgetUrl, useBudgetId } from 'erp/budget/BudgetRouter';

const CreateBudget = () => {
  const { create, getValidationSchema, getById } = useBudgetService();
  const { getListUrl } = useBudgetUrl();
  const id = useBudgetId();
  let getDataFunc;

  if (id) {
    getDataFunc = () => getById(id);
  }

  return (
    <CreateForm
      getDataFunc={getDataFunc}
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Budget has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Budget" breadcrumbs={<CreateBudgetBreadcrumbs />}>
        <BudgetForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateBudget;
