/* istanbul ignore file */
import React from 'react';
import { TextInput, NumberInput, CurrencyInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import Grid from 'uibuilder/Grid';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

const PurchaseInput = ({ className, disabled, currencySource, ...props }: any) => {
  const { getValue: getCurrency } = useInputHelper({ source: currencySource });
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback() as any;

  const purchase = getValue() || ({} as any);
  const source = getSource();
  const currency = (getCurrency() as string) || '';

  const onChangeCallback = (value: any) => {
    rawOnChangeCallback({
      [source]: {
        ...purchase,
        ...getLastSource(value),
      },
    });
  };

  const onAmountChangeCallback = (value: any) => {
    rawOnChangeCallback({
      [source]: {
        ...purchase,
        cost: {
          amount: Object.values(value)[0],
        },
      },
    });
  };

  return (
    <Grid container spacing={2} className="journal-entries-purchase-row">
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <TextInput
          source={`${source}.name`}
          label="Name"
          value={purchase.name}
          onChangeCallback={onChangeCallback}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <CurrencyInput
          source={`${source}.cost.amount`}
          label="Unit Price"
          onChangeCallback={onAmountChangeCallback}
          disabled={disabled}
          suffix={CURRENCIES[currency]}
          maxDecimal={2}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <NumberInput
          source={`${source}.quantity`}
          value={purchase.quantity}
          label="Quantity"
          onChangeCallback={onChangeCallback}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <CurrencyInput
          source={`${source}.total`}
          value={(purchase.quantity || 1) * (purchase.cost?.amount || 0)}
          label="Total Price"
          disabled
          suffix={CURRENCIES[currency]}
          maxDecimal={2}
          isControlledValue
        />
      </Grid>
    </Grid>
  );
};

export default PurchaseInput;
