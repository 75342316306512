import { TextArea } from '../../../../shared/uibuilder/form/input';
import NonStrictRulesHandler from '../../../shared/rules/NonStrictRulesHandler';
import React from 'react';
import { NON_STRICT_RULES } from './employeeContractsService';

const ContractNotStrictRulesHandler = ({ errorMap }) => {
  return (
    <NonStrictRulesHandler
      title="Contract violates the following rules:"
      nonStrictRules={NON_STRICT_RULES}
      errorMap={errorMap}
    >
      <p>
        Please return to contract creation form and change the contract details so that it does not violate the rules or
        create contract with rules violated.
      </p>
      <p>
        If you need to create the contract without changes in its details, please specify a reason for the rules
        violation.
      </p>

      <TextArea source="comment" label="" />
    </NonStrictRulesHandler>
  );
};

export default ContractNotStrictRulesHandler;
