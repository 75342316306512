/* istanbul ignore file */
import CreateEmailForm from 'erp/candidate/communication/email/form/CreateEmailForm';
import EmailTimelineRecordLayout from 'erp/candidate/communication/email/EmailTimelineRecordLayout';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { SEND_EMAIL } from 'erp/candidate/shared/candidateService';

const useEmailCommunicationItem = () => ({
  AddForm: CreateEmailForm,
  isApplicable: (item: TimelineItem) => item,
  source: '',
  urlPostfix: 'email',
  RecordLayout: EmailTimelineRecordLayout,
  name: 'New Email',
  hasFullData: true,
  permissionToCheck: SEND_EMAIL,
});

export default useEmailCommunicationItem;
