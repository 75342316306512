/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useContractsService from 'erp/contracts/shared/contractsService';

const ContractsContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useContractsService();
  const CONTEXT_NAME = 'Contracts_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default ContractsContext;
