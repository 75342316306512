import React from 'react';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';

type EmployeeLinkTrainingInstancesFieldProps = {
  value?: { alias: string };
  label?: string;
};

const EmployeeLinkTrainingInstancesField = ({ value, label }: EmployeeLinkTrainingInstancesFieldProps) => {
  return <EmployeeLinkFieldWithNameLoading label={label} externalId={value?.alias || ''} />;
};

export default EmployeeLinkTrainingInstancesField;
