/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field';
import { updateCurrencyName } from 'shared/uibuilder/amountUtils';

const LedgerAccountField = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });

  return <TextField {...props} source={source} value={updateCurrencyName(getValue())} />;
};

export default LedgerAccountField;
