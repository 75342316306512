/* istanbul ignore file */
import { any, func, objectOf, oneOfType, string, bool } from 'prop-types';
import React from 'react';
import ImageLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/ImageLayout';
import DocumentLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/DocumentLayout';
import useUiTheme from 'shared/uibuilder/useUiTheme';

const AttachmentFieldLayout = ({
  value,
  handleClick,
  handleOpenPreview,
  getThumbnail,
  isDownloadAvailable,
  handleDownload,
}) => {
  const { ArtifactImageLayout = ImageLayout, ArtifactDocumentLayout = DocumentLayout } = useUiTheme();

  return value && value.type === 'image' ? (
    <ArtifactImageLayout
      value={value}
      getThumbnail={getThumbnail}
      handleDownload={handleDownload}
      handleClick={handleClick}
      isDownloadAvailable={isDownloadAvailable}
    />
  ) : (
    <ArtifactDocumentLayout
      value={value}
      handleDownload={handleClick}
      handleOpenPreview={handleOpenPreview}
      isDownloadAvailable={isDownloadAvailable}
    />
  );
};

AttachmentFieldLayout.propTypes = {
  value: oneOfType([objectOf(any), string]).isRequired,
  handleClick: func,
  handleDownload: func,
  handleOpenPreview: func,
  getThumbnail: func,
  isDownloadAvailable: bool,
};

AttachmentFieldLayout.defaultProps = {
  handleClick: () => {},
  handleDownload: () => {},
  handleOpenPreview: () => {},
  getThumbnail: () => Promise.resolve(''),
  isDownloadAvailable: true,
};

export default AttachmentFieldLayout;
