import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import ReactCountryFlag from 'react-country-flag';
import React from 'react';
import { COUNTRIES } from 'shared/uibuilder/form/input/CountryDropdown';
import { Tooltip } from '@mui/material';

interface CountryFlagFieldProps extends FieldProps {
  hasTooltip?: boolean;
}

const CountryFlagField = ({ source, hasTooltip = false, ...props }: CountryFlagFieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const value = getValue();
  const currentCountry = COUNTRIES.find(country => country.name === value);
  const currentCountryCode = currentCountry ? currentCountry.code : null;
  const flagComponent = currentCountryCode ? (
    <ReactCountryFlag countryCode={currentCountryCode} style={{ width: '2em', height: '2em' }} svg />
  ) : null;
  if (!flagComponent) {
    return null;
  }
  return hasTooltip ? (
    <Tooltip title={value}>
      <span>{flagComponent}</span>
    </Tooltip>
  ) : (
    flagComponent
  );
};

export default CountryFlagField;
