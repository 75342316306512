import React from 'react';
import { useParams } from 'react-router-dom';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useCustomerInvoiceService, {
  CUSTOMER_INVOICE_STATUSES,
  REJECT_CUSTOMER_INVOICE,
} from '../../useCustomerInvoiceService';
import DeleteButton from '../../../../uibuilder/button/DeleteButton';
import { useShowContext } from '../../../../shared/uibuilder/show/ShowContext';

const RejectCustomerInvoiceButton = ({
  statusSource,
  isVisible,
}: {
  statusSource: string;
  isVisible?: (data: any) => boolean;
}) => {
  const { id } = useParams<Dictionary<string>>();
  const { rejectCustomerInvoice, invalidateCache } = useCustomerInvoiceService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();
  const { setData = () => {} } = useShowContext();

  if (status !== CUSTOMER_INVOICE_STATUSES.PENDING) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage="Do you really want to reject this customer invoice?"
      deleteMethod={() => rejectCustomerInvoice(id)}
      permissionToCheck={REJECT_CUSTOMER_INVOICE}
      buttonText="Reject"
      color="error"
      afterDelete={{
        successMessage: 'The Customer invoice has been successfully rejected.',
        errorMessage: "Can't reject Customer invoice.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
    />
  );
};

export default RejectCustomerInvoiceButton;
