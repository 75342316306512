import { useSelector, useDispatch } from 'react-redux';
import { size } from 'lodash';

export const SET_SETTING = 'SET_APPLICATIONS_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_SETTING,
  payload,
});

export interface ApplicationsSettingState {
  collapseStates: Dictionary<boolean>;
}

const initialState = {
  collapseStates: {},
};

export const applicationsSettingsReducer = (state = initialState, action: any) => {
  if (action.type === SET_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const useApplicationsSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { applicationsSettings: ApplicationsSettingState }) => state?.applicationsSettings,
  );
  const { collapseStates }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const collapseAll = () => {
    updateSettings({
      collapseStates: {},
    });
  };

  const expandAll = (blocksIds: string[]) => {
    const newStates: Dictionary<boolean> = {};

    blocksIds.forEach(id => {
      newStates[id] = true;
    });

    updateSettings({
      collapseStates: newStates,
    });
  };

  const toggle = (blockId: string) => {
    updateSettings({
      collapseStates: {
        ...collapseStates,
        [blockId]: !collapseStates[blockId],
      },
    });
  };

  const areAllItemsExpanded = (blocksIds: string[]): boolean => {
    const numberOfBlocks = blocksIds.length;

    return size(collapseStates) === numberOfBlocks && Object.values(collapseStates).every(value => value);
  };

  const areAllItemsCollapsed = (): boolean => {
    return size(collapseStates) === 0 || Object.values(collapseStates).every(value => !value);
  };

  return {
    toggle,
    collapseStates,
    collapseAll,
    expandAll,
    areAllItemsCollapsed,
    areAllItemsExpanded,
  };
};

export default useApplicationsSettings;
