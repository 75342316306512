/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import OpportunityStageDropdown from 'crm/opportunity/shared/input/OpportunityStageDropdown';

const OpportunityStageFilter = props => {
  return (
    <DropDownFilter {...props}>
      <OpportunityStageDropdown />
    </DropDownFilter>
  );
};

export default OpportunityStageFilter;
