/* istanbul ignore file */
import React, { MutableRefObject } from 'react';
import SimpleBar from 'simplebar-react';
import './Scrollbars.scss';

export interface ScrollbarsProps {
  children: React.ReactNode;
  maxHeight?: string;
  className?: string;
  scrollableNodeProps?: { ref: MutableRefObject<HTMLElement | undefined> };
}

const Scrollbars = ({ children, maxHeight, className, scrollableNodeProps }: ScrollbarsProps) => {
  return (
    <div className={className}>
      <SimpleBar style={{ maxHeight }} scrollableNodeProps={scrollableNodeProps}>
        {children}
      </SimpleBar>
    </div>
  );
};

export default Scrollbars;
