/* istanbul ignore file */
import { SET_HEADER_MENU, HeaderMenuItemProps } from 'shared/uibuilder/menu/HeaderMenu/headerMenuService';

// eslint-disable-next-line import/prefer-default-export
export const setHeaderMenuData = (menuData: HeaderMenuItemProps[], pathname: string) => ({
  type: SET_HEADER_MENU,
  payload: {
    menuData,
    pathname,
  },
});
