/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { EMPLOYEE_CONTRACT_POSITIONS } from 'erp/employee/contracts/shared/input/EmployeeContractPositionDropdown';

const ContractPositionField = props => {
  return <EnumField {...props} options={EMPLOYEE_CONTRACT_POSITIONS} />;
};

export default ContractPositionField;
