import React from 'react';
import Box from 'uibuilder/Box';
import shortid from 'shortid';
import usePerformanceHeatmap from 'erp/employee/performanceReport/shared/PerformanceHeatmap/usePerformanceHeatmap';

const PerformanceHeatmapWeeksHeader = ({ currentMonth }: { currentMonth: string }) => {
  const { getWeeksData } = usePerformanceHeatmap({ currentMonth });
  const heatmapHeaders = getWeeksData();

  return (
    <Box
      component="ul"
      className="performance-heatmap__months"
      sx={{
        gridTemplateColumns: `repeat(${heatmapHeaders.length}, var(--week-square-size)) var(--month-square-gap) auto`,
        gridGap: 'var(--week-square-gap)',
      }}
    >
      {heatmapHeaders.map(header => (
        <li key={shortid.generate()}>
          {header.start.date() === header.end.date()
            ? header.end.format('D MMM')
            : `${header.start.date()} - ${header.end.format('D MMM')}`}
        </li>
      ))}
      <li />
      <li>Month</li>
    </Box>
  );
};

export default PerformanceHeatmapWeeksHeader;
