import React from 'react';
import { useAccountingCycleUrl } from '../../AccountingCycleRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewAccountingCycleButton = (props: any) => {
  const { getSingleEntityUrl } = useAccountingCycleUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-фccounting-сycle-entry" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewAccountingCycleButton;
