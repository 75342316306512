/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import {
  notStaffingPredicate,
  PROGRESS_FIELDS_KEYS,
  PROGRESS_FIELDS_LABELS,
  PROGRESS_STATUSES,
} from 'crm/projectsDashboard/useProjectsDashboardService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { findLast, orderBy } from 'lodash';
import useDateService from 'shared/uibuilder/dateService/useDateService';

const ProjectStageField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const progressData = getValue() as any;

  const currentStep =
    progressData.find(
      (item: any) =>
        [PROGRESS_STATUSES.IN_PROGRESS, PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY].includes(item.status) &&
        notStaffingPredicate(item.stepName),
    ) ||
    findLast(
      orderBy(progressData, ['stepNumber'], ['asc']),
      item => item.status !== PROGRESS_STATUSES.NOT_STARTED && notStaffingPredicate(item.stepName),
    );

  const { getHoursBetween, getCurrentDateTimeInUtc } = useDateService();
  const isHighlighted = [PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY, PROGRESS_STATUSES.COMPLETED_WITH_DELAY].includes(
    currentStep.status,
  );
  const fromDate = currentStep.planDate;
  const toDate = currentStep.factDate ? currentStep.factDate : getCurrentDateTimeInUtc();
  const gap = Math.round(getHoursBetween(toDate, fromDate) / 24);
  const highlightedTitle = `The Step was overdue by ${gap} days.`;

  const isFinished =
    currentStep.stepName === PROGRESS_FIELDS_KEYS.MVP &&
    [PROGRESS_STATUSES.COMPLETED_WITH_DELAY, PROGRESS_STATUSES.COMPLETED].includes(currentStep.status);

  const headerElement = isFinished ? (
    <TextField {...props} value="Completed" />
  ) : (
    <EnumField options={PROGRESS_FIELDS_LABELS} {...props} value={currentStep?.stepName} />
  );

  return (
    <>
      {headerElement}
      {isHighlighted ? (
        <i className="fa fa-exclamation-circle ml-2 expire-soon-badge" title={highlightedTitle} />
      ) : null}
    </>
  );
};

export default ProjectStageField;
