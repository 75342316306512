const projectWeeklyReportValidation = {
  from: {
    type: 'date',
    required: true,
  },
  to: {
    type: 'date',
    required: true,
  },
  corporateModel: {
    type: 'boolean',
    required: true,
  },
  reportArtifactId: {
    type: 'array',
    maxSize: 1,
    required: {
      value: false,
      when: {
        $newStatus: {
          in: ['NEW', 'IN_PROGRESS'],
        },
        $report: {
          notEmpty: true,
        },
      },
    },
  },
  weeklyReportData: {
    achievements: {
      type: 'array',
      required: true,
      forEach: {
        id: {
          type: 'number',
          required: true,
        },
        description: {
          type: 'string',
          required: true,
        },
      },
    },
    plannedItems: {
      type: 'array',
      forEach: {
        id: {
          type: 'number',
          required: true,
        },
        description: {
          type: 'string',
          required: true,
        },
      },
    },
    milestones: {
      type: 'array',
      forEach: {
        id: {
          type: 'number',
          required: true,
        },
        description: {
          type: 'string',
          required: true,
        },
        plannedDate: {
          required: true,
          type: 'date',
        },
        currentlyPlannedDate: {
          required: false,
          type: 'date',
        },
        comments: {
          type: 'string',
          required: false,
        },
        endDate: {
          required: false,
          type: 'date',
        },
      },
    },
    discussions: {
      type: 'array',
      forEach: {
        description: {
          type: 'string',
          required: true,
        },
        initiatedDate: {
          required: true,
          type: 'date',
        },
        closedDate: {
          required: false,
          type: 'date',
        },
      },
    },
    changeRequests: {
      type: 'array',
      forEach: {
        id: {
          type: 'number',
          required: true,
        },
        description: {
          type: 'string',
          required: true,
        },
        status: {
          type: 'enum',
          required: true,
        },
        createdDate: {
          type: 'date',
          required: true,
        },
        closedDate: {
          type: 'date',
          required: false,
        },
      },
    },
    risks: {
      type: 'array',
      forEach: {
        id: {
          type: 'number',
          required: true,
        },
        description: {
          type: 'string',
          required: true,
        },
        impact: {
          type: 'enum',
          required: true,
        },
        probability: {
          type: 'enum',
          required: true,
        },
        createdDate: {
          type: 'date',
          required: true,
        },
      },
    },
    customerDependencies: {
      type: 'array',
      forEach: {
        description: {
          type: 'string',
          required: true,
        },
        name: {
          type: 'string',
          required: true,
        },
        createdDate: {
          type: 'date',
          required: true,
        },
        waitingDate: {
          type: 'date',
          required: true,
        },
        closedDate: {
          type: 'date',
          required: false,
        },
      },
    },
  },
};

export default projectWeeklyReportValidation;
