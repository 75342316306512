/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import {
  RECONCILIATION_STATUS_ALIASES,
  RECONCILIATION_STATUS,
} from 'erp/costaccounting/reconcilation/useReconciliationService';
import { FieldProps } from 'shared/uibuilder/field/index';

const ReconciliationStatusBadge = ({ value: inputValue, source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || getValue();

  const getColor = () => {
    switch (value) {
      case RECONCILIATION_STATUS_ALIASES.RECONCILED:
        return 'success';
      case RECONCILIATION_STATUS_ALIASES.POTENTIAL_MATCH:
        return 'primary';
      case RECONCILIATION_STATUS_ALIASES.NOT_RECONCILED:
        return 'error';
      default:
        return 'error';
    }
  };

  return (
    <CustomBadge
      text={RECONCILIATION_STATUS[value]}
      color={getColor()}
      sx={{
        textTransform: 'uppercase',
      }}
      {...props}
    />
  );
};

export default ReconciliationStatusBadge;
