import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'uibuilder/Alert';
import { CreateForm } from 'shared/uibuilder/form/index';
import CandidateForm from 'erp/candidate/extension/createupdate/Create/CandidateForm';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useCandidateFromExtensionService from 'erp/candidate/extension/candidateFromExtensionService';
import { Checkbox } from 'shared/uibuilder/form/input/index';
import getDuplicatesWarning from 'erp/candidate/shared/candidateDuplicateWarningBuilder';

const CreateCandidateFromLinkedInPage = () => {
  const {
    hasPermissions,
    isParsingFinished,
    parsedProfileData,
    sendMessageToExtension,
    getValidationSchema,
    createCandidate,
    duplicates,
  } = useCandidateFromExtensionService();
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isDuplicateConfirmed, setIsDuplicateConfirmed] = useState(true);
  const { addMessage } = useMessageService();
  const warning = useMemo(() => getDuplicatesWarning(duplicates), [duplicates]);

  useEffect(() => {
    setIsDuplicateConfirmed(!duplicates.length);
  }, [duplicates]);

  useEffect(() => {
    // we don't add redirect to 403 because we should send response to extension if user don't have permissions (it is sent in service)
    if (!hasPermissions && isFormVisible) {
      setIsFormVisible(false);
      addMessage(
        new ErrorMessage(
          (
            <>
              You do not have permission to create a candidate. Submit a request to
              <Link to="/one-stop/requests/SUP01" className="ml-2">
                DaVinci support
              </Link>
            </>
          ),
        ),
      );
    }
  }, [hasPermissions, addMessage, isFormVisible]);

  return (
    <>
      {!!warning && (
        <Alert severity="warning">
          {warning?.content}
          Please, ensure that
          {duplicates.length === 1 ? ' this candidate is not duplicate' : ' these candidates are not duplicates'} and
          confirm it:
          <div className="mb-0 confirmation-checkbox">
            <Checkbox
              source="isNotDuplicate"
              label="I confirm it is not duplicate"
              onChangeCallback={() => {
                setIsDuplicateConfirmed(prevValue => !prevValue);
              }}
              value={isDuplicateConfirmed}
            />
          </div>
        </Alert>
      )}
      <CreateForm
        submitFormFunc={createCandidate}
        initialData={{
          additionalContacts: [],
        }}
        afterSubmit={{
          execute: data => {
            setIsFormVisible(false);
            sendMessageToExtension({ candidateId: (data as Dictionary<string>).id });
          },
          message: 'You are awesome! The Candidate has been successfully created.',
        }}
        getValidationSchemaFunc={getValidationSchema}
      >
        {isFormVisible ? (
          <CandidateForm
            isParsingFinished={isParsingFinished}
            parsedProfileData={parsedProfileData}
            isSubmitDisabled={!isDuplicateConfirmed}
          />
        ) : (
          <></>
        )}
      </CreateForm>
    </>
  );
};

export default CreateCandidateFromLinkedInPage;
