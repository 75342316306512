import { useContactUrl } from 'crm/contact/ContactRouter';
import React from 'react';
import { joinElements } from 'shared/uibuilder/helper';

const CONTACT_ERROR_PREFIX = 'The contacts with such emails already exist';

const useErrorMap = () => {
  const { getSingleEntityUrl } = useContactUrl();

  return {
    errorsMap: {
      [CONTACT_ERROR_PREFIX]: (...ids) => {
        const links = ids.map(id => {
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a target="_blank" href={getSingleEntityUrl(id)}>
              {id}
            </a>
          );
        });
        return (
          <p className="email-list-error">
            {CONTACT_ERROR_PREFIX}: {joinElements(links, ', ')}
          </p>
        );
      },
    },
  };
};

export default useErrorMap;
