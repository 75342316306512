/* istanbul ignore file */
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useValidation from 'shared/validation/validationService';

/**
 * Hook which provides form validation components.
 * @param fieldSource - source of the field that needs to be validated
 */
const useFormValidation = (fieldSource = '') => {
  const { data = null, validationSchema = null, isCreateRequest = true, fieldsExceptions = null } = useFormContext();

  const validationMethods = useValidation({ data, validationSchema, isCreateRequest, fieldsExceptions, fieldSource });

  return {
    ...validationMethods,
  };
};

export default useFormValidation;
