import useAuthenticationService from 'authentication/authenticationService';
import { Authenticator } from 'authentication/authenticator';
import { UnauthenticatedRequestHandler } from 'shared/api';

export enum AuthFailReason {
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

const useUnauthenticatedRequestHandler = (): UnauthenticatedRequestHandler => {
  const { isAuthenticated, getCurrentAuthenticator } = useAuthenticationService();

  const handleUnauthenticated = async (
    error: any,
    handleError: (error: any) => void,
    callRequest: () => void,
  ): Promise<Nullable<void>> => {
    const authenticator: Nullable<Authenticator> = await getCurrentAuthenticator();
    const isUserAuthenticated = await isAuthenticated();

    if (isUserAuthenticated) {
      let reason: string;

      try {
        const data = await error.json();
        reason = data.reason;
      } catch (e) {
        const data = await JSON.parse(error.response);
        reason = data.reason;
      }

      switch (reason) {
        case AuthFailReason.EXPIRED: {
          if (authenticator) {
            try {
              await authenticator.refreshToken();
            } catch (e) {
              await authenticator.logout();
            }
          }

          return callRequest();
        }
        case AuthFailReason.INVALID:
        default: {
          if (authenticator) {
            await authenticator.logout();
          }
        }
      }

      return null;
    } else {
      return handleError(error);
    }
  };

  return {
    handleUnauthenticated,
  };
};

export default useUnauthenticatedRequestHandler;
