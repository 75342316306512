import React, { useEffect, useState, useMemo } from 'react';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import TimeUnitsDropdown from 'shared/uibuilder/form/input/TimeUnitsDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import MultiInputWrapperLayout from 'uibuilder/layout/form/input/MultiInputWrapperLayout';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useAssetService from 'financialAnalytic/assets/useAssetService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';

const DEFAULT_FUNCTION = () => {};

const AssetUsefulLifeInput = ({
  source,
  label,
  categorySource,
  typeSource,
  disabled,
  ...props
}: DefaultInputPropTypes<any> & { categorySource: string; typeSource: string }) => {
  const { getBusinessRules } = useAssetService();
  const { getValue: getCategory } = useInputHelper({ source: categorySource });
  const { getValue: getType } = useInputHelper({ source: typeSource });
  const { getRawOnChangeCallback } = useInputHelper({ source });
  const [businessRules, setBusinessRules] = useState<any[]>([]);
  const { setSubmitEnabled = DEFAULT_FUNCTION, setFormErrors = DEFAULT_FUNCTION, formErrors = {} } = useFormContext();
  const category = getCategory();
  const type = getType();
  const rawOnChangeCallback = getRawOnChangeCallback();

  const businessRule: Nullable<any> = useMemo(
    () =>
      businessRules.find(
        ({ name }: { name: string }) => name === `${type}_USEFUL_LIFE` || name === `${category}_USEFUL_LIFE`,
      ),
    [category, type, businessRules],
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        setSubmitEnabled(false);
        const result = await getBusinessRules();

        setBusinessRules(result);
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setSubmitEnabled(true);
      }
    };

    loadData();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [source]: businessRule?.defaultValue || { timeUnit: 'MONTHS' },
      });
      setFormErrors({ ...formErrors, [`${source}.amount`]: [], [`${source}.timeUnit`]: [] });
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating business rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessRule]);

  const canBeOverrided = !businessRule?.overrides?.includes('CUSTOM_USEFUL_LIFE');
  const disabledFiled = disabled || (!!businessRule?.defaultValue && !canBeOverrided);

  return (
    <MultiInputWrapperLayout
      label={label as any}
      inputSources={[`${source}.amount`, `${source}.timeUnit`]}
      gridTemplateColumns="1fr 130px"
      isRequired
    >
      <NumberInput source={`${source}.amount`} {...props} disabled={disabledFiled} />
      <TimeUnitsDropdown source={`${source}.timeUnit`} excludedUnits={['DAYS', 'WEEKS']} disabled={disabledFiled} />
    </MultiInputWrapperLayout>
  );
};

export default AssetUsefulLifeInput;
