import React from 'react';
import { EnumField, ListField, TextField } from 'shared/uibuilder/field';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import VacancyPublicationOptionField from 'erp/contracts/shared/field/VacancyPublicationOptionField';
import {
  VACANCY_QUESTION_TYPES,
  VACANCY_QUESTION_TYPES_ALIASES,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';

const VacancyPublicationDetailsField = ({ value = {} }: { value?: any }) => {
  const formFieldType = value.type;
  const isOptionsVisible = formFieldType === VACANCY_QUESTION_TYPES_ALIASES.OPTIONS;
  return (
    <SectionRow>
      <TextField value={value.label} label="Label" />
      <TextField value={value.fieldName} label="Field Name" />
      <EnumField options={VACANCY_QUESTION_TYPES} value={formFieldType} label="Field Type" />
      <ListField value={value.options} label="Non-relevant Options" isVisible={isOptionsVisible}>
        <VacancyPublicationOptionField />
      </ListField>
    </SectionRow>
  );
};

export default VacancyPublicationDetailsField;
