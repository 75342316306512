/* istanbul ignore file */
import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field/index';
import BudgetLink from 'erp/budget/shared/field/BudgetLink';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

const BudgetLinkField = ({ source, nameSource, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });

  const value = getId() ? <BudgetLink name={getName()} id={getId()} {...props} /> : EMPTY_VALUE_INDICATOR;

  return <TextField value={value} {...props} />;
};

export default BudgetLinkField;
