import useEntityUrl from 'shared/routing/useEntityUrl';
import { useProjectUrl } from 'erp/project/ProjectRouter';

const STATUSES_PATH = 'statuses';

const useStatusesUrl = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const projectLink = getSingleEntityUrl();
  const listUrl = `${projectLink}/${STATUSES_PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${projectLink}/${STATUSES_PATH}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

export default useStatusesUrl;
