import React, { useEffect } from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useFormContext, FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useArtifactStorageService, { ArtifactFileType } from 'artifactStorage/shared/artifactStorageService';
import { mapArtifactMetaInfo } from '../mapArtifactInformationFunctions';

interface ArtifactSowInputProps {
  source: string;
  label?: string;
  nameSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;
  showMetaInfo?: boolean;
  disabled?: boolean;
  props?: any;
}

const ArtifactSowInput = ({
  nameSource = 'fullName',
  showMetaInfo = false,
  disabled = false,
  ...props
}: ArtifactSowInputProps) => {
  const { data = {}, isDirty } = useFormContext();
  const { search } = useArtifactStorageService();
  const { getSource, getRawOnChangeCallback } = useInputHelper(props);
  const source = getSource();

  const parentOnChange = getRawOnChangeCallback() || (() => {});

  useEffect(() => {
    if (isDirty && !disabled) {
      parentOnChange({
        [source]: null,
      });
    }
    // Suppressed warnings because we only need to call useEffect callback only if data.relatedAccount is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.relatedAccount, data.accountId]);

  const mapResult = (item: any) => {
    return {
      value: item.file.id,
      label: `${item.file.artifact.title} ${showMetaInfo ? mapArtifactMetaInfo(item.file.metaInformation) : ''}`,
    };
  };

  const loadDataMethod = async () => {
    if (disabled && data.sow) {
      return Promise.resolve({
        result: [
          {
            file: data.sow,
          },
        ],
      });
    }
    return data.relatedAccount || data.accountId
      ? search({
          filter: {
            path: ['Contracts', `Account:${data.relatedAccount || data.accountId}`, 'Type:statement_of_work'],
            searchType: ArtifactFileType.STATEMENT_OF_WORK,
          },
        })
      : Promise.resolve({ result: [] });
  };

  return (
    <LoadDropdown
      {...props}
      placeholder="Select SOW"
      loadDataMethod={loadDataMethod}
      mapResults={mapResult}
      deps={[data.relatedAccount, data.accountId]}
      disabled={disabled && data.sow}
      showLoader
    />
  );
};

export default ArtifactSowInput;
