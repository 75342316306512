import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import getLabel from 'shared/uibuilder/helper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import InputLoader from 'shared/uibuilder/InputLoader';
// @ts-ignore
import { saveAs } from 'file-saver';

interface AttachmentsListFieldProps {
  source: string;
  value: Dictionary<any>;
  label?: string;
  isDownloadAvailable?: boolean;
  isPhotos?: boolean;
}

const AttachmentsListFieldWithExternalPhotos = ({
  source,
  value: inputValue,
  label: propsLabel,
  isDownloadAvailable = true,
  isPhotos = true,
}: AttachmentsListFieldProps) => {
  const { AttachmentsListLayout, BaseFieldLayout } = useUiTheme();
  const { data } = useShowContext();
  const label = getLabel(propsLabel, source);
  const attachments = inputValue || (data && data.getValueBySource(source));

  return attachments ? (
    <AttachmentsListLayout
      attachments={attachments}
      label={label}
      downloadFile={saveAs}
      getThumbnail={(src: string) => Promise.resolve(src)}
      openPreview={() => {}}
      isDownloadAvailable={isDownloadAvailable}
      isPhotos={isPhotos}
    />
  ) : (
    <BaseFieldLayout label="" value={<InputLoader />} />
  );
};

export default AttachmentsListFieldWithExternalPhotos;
