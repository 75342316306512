/* istanbul ignore file */
import React, { useEffect } from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useEmployeeOfferService from 'erp/employee/offers/shared/employeeOfferService';
import { useEmployeeOfferId, useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import { useDispatch } from 'react-redux';
import { clearTimer } from 'shared/uibuilder/DataTimer';

const EmployeeOfferContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useEmployeeOfferService();
  const CONTEXT_NAME = 'EmployeeOffer_CONTEXT';
  const offerId = useEmployeeOfferId();
  const { getListUrl } = useEmployeeOfferUrl();
  const listUrl = getListUrl();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearTimer(listUrl));
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SecurityContextTemplate
      contextName={CONTEXT_NAME}
      getPermissionsFunc={() => getPermissions(offerId)}
      permissionToCheck={permissionToCheck}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default EmployeeOfferContext;
