/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { number, oneOfType, string } from 'prop-types';

const CandidateLink = ({ name, id }) => {
  const { getSingleEntityUrl } = useCandidateUrl();

  return (
    <Link to={getSingleEntityUrl(id)} target="_blank">
      {name || id}
    </Link>
  );
};

CandidateLink.propTypes = {
  id: oneOfType([string, number]).isRequired,
  name: string,
};

CandidateLink.defaultProps = {
  name: undefined,
};

export default CandidateLink;
