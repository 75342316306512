import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';

const BooleanField = (props: FieldProps<boolean>) => {
  const { getValue } = useFieldHelper<boolean>(props);
  const displayedValue = getValue() ? 'Yes' : 'No';

  return <TextField {...props} value={displayedValue} />;
};

export default BooleanField;
