import React from 'react';
import { AccountDeprovisionStatus, DeprovisionAccountsResponse, DeprovisionStatus } from '../externalAccountsService';
import useMessageService, { ErrorMessage, SuccessMessage, WarningMessage } from 'shared/message/messageService';
import { StatusContext } from '../creation/accountsCreationResponseHandler';

const useDeprovisionResponseHandler = () => {
  const { addMessage } = useMessageService();

  const processAccountDeprovisionResponse = (
    accountDeprovision: AccountDeprovisionStatus,
    accountType: string,
    status: StatusContext,
    deprovisionInstructions?: any,
  ) => {
    if (accountDeprovision.status === DeprovisionStatus.OK) {
      status.successes.push(<li key="success-deprovision">Successfully deprovisioned {accountType}.</li>);
    } else if (accountDeprovision.status === DeprovisionStatus.NOT_FOUND) {
      status.successes.push(<li key="success-deprovision">{accountType} was not found.</li>);
    } else if (accountDeprovision.status === DeprovisionStatus.ERROR) {
      status.errors.push(
        <li key="error-deprovision">
          Failed to deprovision {accountType}. {accountDeprovision.errors.join(' ')}
        </li>,
      );
      status.instructions.push(
        <li key="instruction-deprovision">
          Deprovision {accountType} manually. {deprovisionInstructions}
        </li>,
      );
    }
  };

  const handleDeprovisionResponse = (state: DeprovisionAccountsResponse) => {
    const statusContext: StatusContext = {
      successes: [],
      errors: [],
      instructions: [],
    };

    processAccountDeprovisionResponse(state.azureDeprovisionStatus, 'Azure account', statusContext);

    processAccountDeprovisionResponse(
      state.redmineDeprovisionStatus,
      'Redmine account',
      statusContext,
      'Block account and change email to random one.',
    );

    processAccountDeprovisionResponse(
      state.gitlabDeprovisionStatus,
      'GitLab account',
      statusContext,
      'Block account and change email to random one.',
    );

    processAccountDeprovisionResponse(
      state.employeeDeprovisionStatus,
      'employee DaVinci profile',
      statusContext,
      <>
        Please contact DaVinci support to delete <i>Azure ID</i>, <i>Email</i> and set initialization state to{' '}
        <i>NEW</i> in profile.
      </>,
    );

    processAccountDeprovisionResponse(
      state.candidateDeprovisionStatus,
      'candidate DaVinci profile',
      statusContext,
      <>
        Please contact DaVinci support to delete <i>Azure ID</i>, <i>Email</i> and set <i>Is it a student?</i> flag to{' '}
        <i>No</i> in profile.
      </>,
    );

    const { errors, successes, instructions } = statusContext;
    const statusMessage = (
      <>
        {successes}
        {errors}
        <hr className="mb-0" />
        {instructions}
      </>
    );
    if (errors.length > 0 && successes.length > 0) {
      addMessage(new WarningMessage(statusMessage));
    } else if (errors.length > 0) {
      addMessage(new ErrorMessage(statusMessage));
    } else {
      addMessage(new SuccessMessage(statusMessage));
    }
  };

  return {
    handleDeprovisionResponse,
  };
};

export default useDeprovisionResponseHandler;
