/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { competencyStatusesLabels } from 'erp/employee/qualification/shared/employeeQualificationService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

interface CompetencyStatusDropdownProps extends DefaultDropdownProps {
  disabledValue: string;
}
const CompetencyStatusDropdown = ({ disabledValue, ...props }: CompetencyStatusDropdownProps) => {
  return (
    <Dropdown
      {...props}
      options={Object.entries(competencyStatusesLabels).map(([value, label]) => ({
        value,
        label,
        disabled: value === disabledValue,
      }))}
      placeholder="Select status"
    />
  );
};

export default CompetencyStatusDropdown;
