import { useEffect, useMemo, useState } from 'react';
import getLoadingParams from 'shared/uibuilder/list/builder/listBuilderHelper';
import { LoadingParams, SearchParams } from 'shared/uibuilder/list/ListContext';
import resetParameters from 'shared/uibuilder/list/builder/resetParameters';
import { InputData } from './useListCrud';

export type ListData = {
  result: Dictionary<any>[];
  totalPages?: number;
  totalElements?: number;
};

export type GetDataMethodType<T = LoadingParams> = (loadParams: T) => Promise<Nullable<ListData>>;

interface ListLoadingHelperParams {
  getDataMethod?: GetDataMethodType;
  loadDataOnInit?: boolean;
  params?: SearchParams;
  pageSize?: number;
  resetParamsToDefault?: () => void;
}

const useListDataLoading = ({
  getDataMethod = () => Promise.resolve(null),
  loadDataOnInit,
  params = {},
  pageSize = 30,
  resetParamsToDefault,
}: ListLoadingHelperParams) => {
  const [data, setData] = useState<InputData>({
    items: [],
    totalPages: 0,
    totalElements: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isComponentUnmounted, setIsComponentUnmounted] = useState(false);

  const loadData = async (loadParams: LoadingParams, isNeedToUpdateLoading = true) => {
    if (isNeedToUpdateLoading) {
      setLoading(true);
    }

    const response = await getDataMethod(loadParams);

    if (isNeedToUpdateLoading && !isComponentUnmounted) {
      setLoading(false);
    }

    return response;
  };

  const loadingParams = useMemo(() => getLoadingParams(params, pageSize), [pageSize, params]);

  const handleLoad = async () => {
    const response = await loadData(loadingParams);
    if (!isComponentUnmounted) {
      setData({
        items: response?.result || [],
        totalPages: response?.totalPages || 0,
        totalElements: response?.totalElements || 0,
      });
    }
  };

  const handleSetData = (newData: InputData) => {
    if (!isComponentUnmounted) {
      setData(newData);
    }
  };

  useEffect(() => {
    if (loadDataOnInit) {
      handleLoad();
    }

    return () => {
      setIsComponentUnmounted(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return resetParameters({
    loadData,
    loading,
    setLoading,
    data,
    resetParamsToDefault,
    handleLoad,
    handleSetData,
  });
};

export default useListDataLoading;
