// libs
import { useState, useEffect } from 'react';
// services
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import useArtifactService, { getMbFromBytes } from 'artifact/artifactService';
import { unFlattenObject } from 'shared/uibuilder/form/formHelper';
import { getHumanReadableDataSize } from 'shared/fileHelper';

const useArtifactInputHelper = props => {
  const [artifactTypesObject, setArtifactTypesObject] = useState(null);
  const inputHelper = useInputHelper(props);
  const { getMaxElements } = useFormValidation(props.source);
  const { getArtifactTypeObject } = useArtifactService();

  useEffect(() => {
    (async () => {
      const res = await getArtifactTypeObject(props.artifactTypeId);
      setArtifactTypesObject(res);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collectionOnChangeCallback = inputHelper.getCollectionOnChangeCallback();

  const getArtifactType = () => props.artifactTypeId;

  const addArtifactToContext = id => {
    collectionOnChangeCallback(prev => {
      return unFlattenObject({ [inputHelper.getSource()]: [...prev, id] });
    });
  };

  const removeArtifactFromContext = id => {
    const source = inputHelper.getSource();

    collectionOnChangeCallback(prev => {
      const newValue = prev.filter(artifactId => {
        return artifactId !== id;
      });

      return unFlattenObject({ [source]: newValue });
    });
  };

  const getAcceptExtensions = () => {
    return props.fileExtensions || (artifactTypesObject && artifactTypesObject.fileExtensions);
  };

  const getMaxMb = () => {
    const maxMb = getMbFromBytes(props.maximumSize || (artifactTypesObject && artifactTypesObject.maximumSize));

    return maxMb;
  };

  const getTooltip = () => inputHelper.getTooltip();

  const getHelpText = () => {
    const extensions = getAcceptExtensions();
    const maxElements = getMaxElements();

    const elementsText = `${maxElements ? `Up to ${getMaxElements()} file${+maxElements === 1 ? '' : 's'}` : ''}`;
    const extensionsText = `${extensions ? `${elementsText ? ' ' : ''}(${extensions})` : ''}`;
    const fileSizeText = `${elementsText || extensionsText ? ', m' : 'M'}ax ${getHumanReadableDataSize(
      props.maximumSize || (artifactTypesObject && artifactTypesObject.maximumSize),
    )} ${+maxElements === 1 ? '' : 'each'}`;

    return elementsText + extensionsText + fileSizeText;
  };

  return {
    ...inputHelper,
    getAcceptExtensions,
    getArtifactType,
    addArtifactToContext: props.addArtifactToContext || addArtifactToContext,
    removeArtifactFromContext: props.removeArtifactFromContext || removeArtifactFromContext,
    getTooltip,
    getHelpText,
    getMaxMb,
    getMaxElements,
    getArtifactTypeObject,
  };
};

export default useArtifactInputHelper;
