/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import { useVacancyId } from 'erp/recruitment/vacancy/VacancyRouter';
import HiringRequestListField from 'erp/recruitment/vacancy/shared/field/HiringRequestListField';
import ShowVacancyPageLayout from 'erp/recruitment/vacancy/Show/ShowVacancyPageLayout';

const ViewVacancyHiringRequests = () => {
  const id = useVacancyId();
  const { getById } = useVacancyService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowVacancyPageLayout>
        <ShowSection title="Hiring Requests">
          <HiringRequestListField />
        </ShowSection>
      </ShowVacancyPageLayout>
    </Show>
  );
};

export default ViewVacancyHiringRequests;
