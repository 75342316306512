/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import ArtifactUploaderInput from 'artifact/shared/input/ArtifactUploaderInput';

const DocumentScanUploaderInput = props => {
  return <ArtifactUploaderInput {...props} artifactTypeId={ARTIFACT_ID.DOCUMENT_SCAN} />;
};

export default DocumentScanUploaderInput;
