import React, { useState } from 'react';
import { StyledMenuList } from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button';
import MoreHorizIcon from '@mui/icons-material/MoreHorizOutlined';
import {
  INAPPROPRIATE_FLAGGING_LABEL,
  CANCEL_INAPPROPRIATE_FLAGGING_LABEL,
} from 'erp/employee/Timeline/feedback/shared/button/FeedbackFlagAsInappropriateButton';
import TimelineRecordAnchor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAnchor';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

interface TimelineRecordActionsProps {
  buttons?: React.ReactChild[];
  actions?: React.ReactChild[];
  timelineId: StringOrNumber;
  basePathname?: string;
}

const CLOSE_MODAL_BUTTONS = ['Cancel', '×'];
const NON_TOGGLE_DROPDOWN_BUTTONS = [INAPPROPRIATE_FLAGGING_LABEL, 'Delete', 'Submit'];
const MODAL_INTERNAL_CLASSES = ['modal-content', 'modal-body', 'form-check', 'flag-dropdown', 'input-group'];

const TimelineRecordActions = ({ buttons, actions, timelineId, basePathname }: TimelineRecordActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownHidden, setIsDropdownHidden] = useState(false);
  const id = `timeline-actions-${timelineId}`;

  const toggleDropdown = ({ target: { offsetParent, innerText } }: any) => {
    const text = innerText?.replace(/\n/gi, '');
    const isCancelFlagging = text === CANCEL_INAPPROPRIATE_FLAGGING_LABEL;
    const isCloseModalButton = CLOSE_MODAL_BUTTONS.includes(text);
    const isDropdownModalOpen = NON_TOGGLE_DROPDOWN_BUTTONS.includes(text);
    const isClickOnDropdownModal = MODAL_INTERNAL_CLASSES.some(className =>
      offsetParent?.className.includes(className),
    );
    const isClickOutsideModal = isDropdownHidden && !isClickOnDropdownModal;
    const isCloseModalButtonClick = isClickOnDropdownModal && isCloseModalButton;
    const shouldToggleDropdown = !isClickOnDropdownModal && !isDropdownModalOpen && !isCancelFlagging;

    if (isDropdownModalOpen || isClickOutsideModal || isCloseModalButtonClick) {
      setIsDropdownHidden(!isDropdownHidden);
    }

    if (isCloseModalButtonClick || shouldToggleDropdown) {
      setIsDropdownOpen(!isDropdownOpen);

      if (isDropdownModalOpen) {
        setAnchorEl(null);
      }
    }
  };

  const getButtonDropdown = () => {
    return (
      <div className="actions-dropdown">
        <IconButton
          id={id}
          className="toggle-dropdown-button"
          aria-controls={isDropdownOpen ? id : undefined}
          aria-haspopup="true"
          aria-expanded={isDropdownOpen ? 'true' : undefined}
          onClick={e => {
            if (isDropdownOpen) return;

            setAnchorEl(e.currentTarget);
            toggleDropdown(e);
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Popper
          open={isDropdownOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
          disablePortal
          sx={{ zIndex: 1200 }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                elevation={3}
                className={`dropdown-menu__wrapper actions-dropdown-menu ${isDropdownHidden ? 'is-hidden' : ''}`}
              >
                <ClickAwayListener onClickAway={toggleDropdown}>
                  <StyledMenuList
                    autoFocusItem={false}
                    id={id}
                    aria-labelledby="composition-button"
                    onClick={toggleDropdown}
                  >
                    {buttons}
                  </StyledMenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  return (
    <div className="actions-wrapper">
      {actions?.length ? actions : null}
      {buttons?.length ? getButtonDropdown() : null}
      <TimelineRecordAnchor timelineId={timelineId} basePathname={basePathname} />
    </div>
  );
};

TimelineRecordActions.defaultProps = {
  actions: null,
  buttons: null,
  basePathname: '',
};

export default TimelineRecordActions;
