/* istanbul ignore file */
import React from 'react';
import { ButtonProps } from 'uibuilder/button/Button';
import LinkButton from 'uibuilder/button/LinkButton';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';

interface CopySkillBasedQueueButtonProps extends ButtonProps {
  value?: StringOrNumber;
  permissionToCheck?: string;
}

const CopySkillBasedQueueButton = ({ icon, permissionToCheck, ...props }: CopySkillBasedQueueButtonProps) => {
  const { getCopyEntityUrl } = useSkillBasedQueueUrl();
  const { getValue: getId } = useFieldHelper(props);
  const id = getId();
  const { isGranted } = useAuthorization();

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <LinkButton {...props} url={getCopyEntityUrl(id)} startIcon={icon}>
      Copy
    </LinkButton>
  ) : null;
};

export default CopySkillBasedQueueButton;
