/* istanbul ignore file */

import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';
import Filters from 'shared/uibuilder/list/filter/Filters';
import StaticFiltersLayout from 'shared/uibuilder/list/filter/layout/StaticFiltersLayout';
import FileList from 'shared/uibuilder/list/FileList';
import useAuditService from 'audit/shared/auditService';
import DateTimeRangeFilter from '../../../shared/uibuilder/list/filter/DateTimeRangeFilter';
import ArtifactsAuditManual from './manual';

const ArtifactsAuditList = () => {
  const { getArtifactsConfigurationReport } = useAuditService();

  const filters = (
    <Filters>
      <StaticFiltersLayout actionButtonName="Download">
        <DateTimeRangeFilter label="Date Of Access" source="dateOfAccess" />
      </StaticFiltersLayout>
    </Filters>
  );

  return (
    <FileList getDataMethod={getArtifactsConfigurationReport}>
      <ListPageLayout
        showTotal={false}
        header={<ListHeader label="Artifacts Access Audit" icon="fa-file" />}
        filter={filters}
      >
        <ArtifactsAuditManual />
      </ListPageLayout>
    </FileList>
  );
};

export default ArtifactsAuditList;
