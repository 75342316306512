/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField } from 'shared/uibuilder/field';
import DurationBetweenTwoDatesField, {
  DurationBetweenTwoDatesFieldProps,
} from 'shared/uibuilder/field/DurationBetweenTwoDatesField';

const ContractDurationField = (props: DurationBetweenTwoDatesFieldProps) => {
  const { getValue: getIsIndefiniteValue } = useFieldHelper({ source: 'indefinite', value: false });
  const indefinite = getIsIndefiniteValue();

  return indefinite ? <TextField value="∞" {...props} /> : <DurationBetweenTwoDatesField {...props} />;
};

export default ContractDurationField;
