/* istanbul ignore file */
import React from 'react';
import useArtifactStorageService from 'artifactStorage/shared/artifactStorageService';
import ArtifactStorageForm from 'artifactStorage/createupdate/ArtifactStorageForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateArtifactStorageBreadcrumbs from 'artifactStorage/createupdate/Create/Breadcrumbs';
import { useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';

const CreateArtifact = () => {
  const { create, getValidationSchema } = useArtifactStorageService();
  const { getSingleEntityUrl } = useArtifactStorageUrl();

  const redirect = (response: any) => {
    return getSingleEntityUrl(response.id);
  };

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect,
        message: 'You are awesome! The artifact has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Artifact" breadcrumbs={<CreateArtifactStorageBreadcrumbs />}>
        <ArtifactStorageForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateArtifact;
