import React, { useState, useEffect } from 'react';
import { useFilterContext, Filter } from 'shared/uibuilder/list/filter/FilterContext';
import { differenceBy, isEmpty, cloneDeep } from 'lodash';

export interface FilterItem {
  id: number;
  element: React.ReactElement;
  selected: boolean;
  label: string;
  source: string;
  labelInDropdown?: string;
  disabledInput?: boolean | ((filters: Filter) => boolean);
  disabled?: boolean;
}

const DEFAULT_FUNCTIONS = () => {};

const useFiltersLayoutHelper = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  const {
    isStatic,
    apply,
    clear: clearFilters = DEFAULT_FUNCTIONS,
    clearFilter = DEFAULT_FUNCTIONS,
    filters,
    filterBy = DEFAULT_FUNCTIONS,
    resetAllFilter = DEFAULT_FUNCTIONS,
  } = useFilterContext();

  const items: FilterItem[] = [];

  /**
   * Transform childrens to filter items
   */
  React.Children.forEach(children, (child, index) => {
    const { label, source, labelInDropdown, disabled } = child.props;

    items.push({
      id: index,
      element: child,
      selected: false,
      label,
      source,
      labelInDropdown,
      disabledInput: disabled,
    });
  });

  const [filterItems, setFilterItems] = useState(items);
  const [selectedFilterItems, setSelectedFilterItems] = useState(items);
  const [lastAppliedFilters, setLastAppliedFilters] = useState({ filters, selectedFilterItems });

  useEffect(() => {
    if (isStatic) {
      const notSelectedFilters: Dictionary<object> = {};

      filterItems.forEach(({ source, selected }) => {
        if (!selected && !filters[source]) {
          notSelectedFilters[source] = {};
        }
      });

      if (!isEmpty(notSelectedFilters)) {
        filterBy(notSelectedFilters);
      }
    }
    // Suppressed warnings because we only need to call useEffect if filterItems is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  /**
   * Update filters  visibility (selected and disabled props)
   * based on filterItem.source key in filter context
   */
  const updateFiltersVisibility = (allFilters: FilterItem[]) =>
    allFilters.map(item => ({
      ...item,
      selected: !!filters[item.source],
      disabled: typeof item.disabledInput === 'function' ? item.disabledInput(filters) : item.disabledInput,
    }));

  /**
   * Get only selected and not disabled items
   */
  const getVisibleFilterItems = (allFilters: FilterItem[]) =>
    allFilters.filter(({ selected, disabled }) => selected && !disabled);

  useEffect(() => {
    const newFilterItems = updateFiltersVisibility(filterItems);

    setFilterItems(newFilterItems);

    setSelectedFilterItems(previousSelectedItems => {
      const newSelectedFilterItems = getVisibleFilterItems(updateFiltersVisibility(previousSelectedItems));
      const allSelectedFilterItems = getVisibleFilterItems(newFilterItems);

      const quickFilters = differenceBy(allSelectedFilterItems, newSelectedFilterItems, 'source');

      return [...newSelectedFilterItems, ...quickFilters];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  /**
   * Show or hide filter
   * @param {number} index - index of filter item
   */
  const toggleElement = (index: number) => {
    const filter = filterItems[index];
    const isSelected = !filter.selected;
    if (!isSelected) {
      clearFilter(filter.source);
    } else {
      setSelectedFilterItems(prevFilters => [...prevFilters, { ...filter, selected: true }]);

      filterBy({
        [filter.source]: {},
      });
    }
  };

  /**
   * Remove all selected filters
   */
  const clear = () => {
    const newFilters = filterItems.map(item => ({
      ...item,
      selected: false,
    }));

    setFilterItems(newFilters);
    setSelectedFilterItems([]);
    clearFilters();
  };

  /**
   * Return filter items
   * @return {array}
   */
  const getFilterItems = () => filterItems;

  /**
   * Return only selected filter items
   * @return {array}
   */
  const getSelectedFilterItems = () => selectedFilterItems;

  const resetFilterData = () => {
    setSelectedFilterItems(lastAppliedFilters.selectedFilterItems);
    resetAllFilter(lastAppliedFilters.filters);
  };

  return {
    getFilterItems,
    getSelectedFilterItems,
    toggleElement,
    apply: (args: any) => {
      setLastAppliedFilters(cloneDeep({ filters, selectedFilterItems }));
      apply(args);
    },
    clear,
    isStatic,
    resetFilterData,
  };
};

export default useFiltersLayoutHelper;
