/* istanbul ignore file */
import React from 'react';
import { string } from 'prop-types';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useCustomHtmlTagsHelper from 'uibuilder/customHtmlTagsHelper';
import HtmlWithArtifactFieldDecorator from 'artifact/shared/field/HtmlWithArtifactFieldDecorator';

const HtmlFieldLayoutWithTags = ({ value, label }) => {
  const { BaseFieldLayout } = useUiTheme();
  const { mapTags } = useCustomHtmlTagsHelper();

  return (
    <HtmlWithArtifactFieldDecorator>
      <BaseFieldLayout label={label} value={mapTags(value)} />
    </HtmlWithArtifactFieldDecorator>
  );
};

HtmlFieldLayoutWithTags.propTypes = {
  value: string,
  label: string,
};

HtmlFieldLayoutWithTags.defaultProps = {
  value: undefined,
  label: undefined,
};

export default HtmlFieldLayoutWithTags;
