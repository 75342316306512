/* istanbul ignore file */
import React from 'react';
import { FieldProps, LinkField } from 'shared/uibuilder/field/index';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';

const VacancyPublicationLinkField = ({ source, nameSource, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useVacancyPublicationUrl();

  const id = getId();
  const value = id && getSingleEntityUrl(id);

  return <LinkField {...props} linkText={nameSource ? getName() : id} value={value as string} isInternal={false} />;
};

export default VacancyPublicationLinkField;
