/* istanbul ignore file */

import { AfterSubmitProps } from 'shared/uibuilder/form/formHelper';
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { Button } from 'uibuilder/button';
import EditIcon from '@mui/icons-material/EditOutlined';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ChangeCareerDevelopmentNextReviewDateForm from 'erp/employee/qualification/createupdate/Update/ChangeCareerDevelopmentNextReviewDateForm';

const ChangeCareerDevelopmentNextReviewDateButton = ({
  permissionToCheck,
  currentReviewDate,
  afterSubmit,
}: {
  permissionToCheck?: string;
  currentReviewDate: string;
  afterSubmit: AfterSubmitProps<(response: object) => void>;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const closeModal = () => setModalOpen(false);

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <>
      <Button
        size="small"
        outline
        className="edit-competency-status-btn edit-evaluation-mode-btn"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
      >
        <EditIcon />
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title="Change the career development next review date"
        onToggle={closeModal}
        hasCancelButton={false}
        className="qualification-modal"
      >
        <ChangeCareerDevelopmentNextReviewDateForm
          modalOpen={modalOpen}
          closeModal={closeModal}
          currentReviewDate={currentReviewDate}
          afterSubmit={afterSubmit}
        />
      </ModalWindow>
    </>
  ) : null;
};

export default ChangeCareerDevelopmentNextReviewDateButton;
