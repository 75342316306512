import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import MilestonesListInputs from './MilestonesListInputs';

const MilestonesListInputWrapper = (props: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const milestones: any = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback!({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MilestonesListInputs value={milestones} source={source} />;
};

export default MilestonesListInputWrapper;
