import React from 'react';
import WasteBreadcrumbs from '../shared/WasteBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useWasteUrl from '../useWasteUrl';

const SingleWasteBreadcrumbsItem = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useWasteUrl();
  const id = data?.getValueBySource('id');

  return (
    <WasteBreadcrumbs>
      <SingleBreadcrumbItem entity="Waste info" link={getSingleEntityUrl(id)} />
      {children}
    </WasteBreadcrumbs>
  );
};

export default SingleWasteBreadcrumbsItem;
