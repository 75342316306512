/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useInvoiceService, { INVOICE_CLASSIFICATION } from 'financialAnalytic/invoice/useInvoiceService';
import { useInvoiceUrl, useInvoiceId } from 'financialAnalytic/invoice/InvoiceRouter';
import CreateInvoiceBreadcrumbs from 'financialAnalytic/invoice/createupdate/Create/Breadcrumbs';
import InvoiceForm from 'financialAnalytic/invoice/createupdate/InvoiceForm';

const CreateInvoicePage = () => {
  const { create, getValidationSchema, getById } = useInvoiceService();
  const { getSingleEntityUrl } = useInvoiceUrl();
  let getDataFunc;
  const id = useInvoiceId();

  if (id) {
    getDataFunc = async () => {
      const data = await getById(id);

      return {
        invoiceNumber: data.invoiceNumber,
        invoiceDate: data.invoiceDate,
        office: data.office,
        name: data.name,
        isFutureExpense: data.isFutureExpense,
        account: data.account,
        references: data.references,
        description: data.description,
        requisites: data.requisites,
        isPurchaseClassifiedAsAsset: data.isPurchaseClassifiedAsAsset
          ? INVOICE_CLASSIFICATION.ASSET
          : INVOICE_CLASSIFICATION.OTHER,
      };
    };
  }

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: data => getSingleEntityUrl(data.id),
        message: 'You are awesome! The Invoice has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        subTotal: {
          currency: 'USD',
        },
      }}
    >
      <CreatePageLayout breadcrumbs={<CreateInvoiceBreadcrumbs />} title="Create: Invoice">
        <InvoiceForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateInvoicePage;
