/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import {
  DELETE_EMPLOYEE_COMMON_SKILL,
  useEmployeeCommonSkillsService,
} from 'erp/employee/commonSkills/shared/employeeCommonSkillsService';
import useAuthorization from 'shared/authorization/authorizationService';

const DeleteEmployeeCommonSKillButton = ({ source, ...props }: any) => {
  const { isGranted } = useAuthorization();
  const { deleteEmployeeCommSkill } = useEmployeeCommonSkillsService();
  const { getValue } = useFieldHelper({ source });
  const id = getValue();

  if (!isGranted(DELETE_EMPLOYEE_COMMON_SKILL)) {
    return null;
  }

  return (
    <DeleteButton
      deleteMethod={() => deleteEmployeeCommSkill(id)}
      displayMessage="Do you really want to delete this employee common skill experience?"
      afterDelete={{
        successMessage: 'The Employee Common Skill experience has been successfully deleted.',
      }}
      permissionToCheck={DELETE_EMPLOYEE_COMMON_SKILL}
    />
  );
};

export default DeleteEmployeeCommonSKillButton;
