/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useCompetencyService from './competencyService';

const CompetencyContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useCompetencyService();
  const COMPETENCY_CONTEXT = 'Competency';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={COMPETENCY_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default CompetencyContext;
