import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import BaseInputLayout, { COMMON_INPUT_LAYOUT_DEFAULT_PROPS, getBaseInputLayoutProps } from './BaseInputLayout';
import { Checkbox } from 'shared/uibuilder/form/input';
import { CheckboxGroupLayoutType } from 'shared/uibuilder/form/input/CheckboxGroup';
import FormGroup from '@mui/material/FormGroup';

const CheckboxGroupLayout: CheckboxGroupLayoutType = ({
  options,
  value,
  onChangeCallback,
  onFocusCallback,
  onBlurCallback,
  source,
  isColumnDirection,
  checkedValues: initialCheckedValues = [],
  className,
  ...props
}) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(initialCheckedValues);

  useEffect(() => {
    if (!isEqual(initialCheckedValues, checkedValues) && onBlurCallback) {
      onBlurCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValues.length]);

  const onChange = (itemValue: string) => {
    if (onFocusCallback) {
      onFocusCallback();
    }

    if (onChangeCallback) {
      let newActiveValue = [];

      if (checkedValues.includes(itemValue)) {
        newActiveValue = checkedValues.filter(currentValue => currentValue !== itemValue);
      } else {
        newActiveValue = [...checkedValues, itemValue];
      }

      setCheckedValues(newActiveValue);

      onChangeCallback({
        [source]: newActiveValue,
      });
    }
  };

  return (
    <BaseInputLayout
      {...getBaseInputLayoutProps({ ...props, source })}
      className={classNames(className, { 'checkbox-form-group__column': isColumnDirection })}
    >
      <FormGroup row={!isColumnDirection}>
        {options.map(item => (
          <Checkbox
            key={item.value}
            value={checkedValues.includes(item.value)}
            label={item.label}
            source={item.value}
            onChangeCallback={() => onChange(item.value)}
            fullWidth={false}
          />
        ))}
      </FormGroup>
    </BaseInputLayout>
  );
};

CheckboxGroupLayout.defaultProps = {
  ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  isColumnDirection: false,
};

export default CheckboxGroupLayout;
