import React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from 'uibuilder/button/Button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

export interface LinkButtonProps extends ButtonProps {
  children: React.ReactNode | React.ReactNode[];
  url: string | ((id: string | number) => string);
  value?: string | number;
  target?: string;
}

const LinkButton = ({ children, url: initialUrl, ...props }: LinkButtonProps) => {
  const { getValue } = useFieldHelper(props);
  let url = initialUrl;

  if (typeof initialUrl === 'function') {
    url = initialUrl(getValue());
  }

  return (
    <Button component={Link} to={url} {...props}>
      {children}
    </Button>
  );
};

LinkButton.defaultProps = {
  value: '',
};

export default LinkButton;
