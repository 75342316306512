/* istanbul ignore file */
// libs
import React from 'react';
import { string, bool, node } from 'prop-types';
// components
import BaseInputLayout from 'uibuilder/layout/form/input/BaseInputLayout';
// styles
import './FileUploadLayout.scss';

const FileUploadLayout = ({
  label,
  tooltip,
  previews,
  isRequired,
  fileUploder,
  error,
  disabled,
  helpText,
  source,
  isVisible,
}) => {
  return (
    <BaseInputLayout
      label={label}
      isRequired={isRequired}
      errorMessages={error}
      inputWrapper="div"
      tooltip={tooltip}
      source={source}
      isVisible={isVisible}
    >
      <div className="upload-block">
        {previews}
        {fileUploder}
      </div>
      {!disabled && <span className="help-block">{helpText}</span>}
    </BaseInputLayout>
  );
};

FileUploadLayout.propTypes = {
  previews: node.isRequired,
  label: string.isRequired,
  helpText: string.isRequired,
  isRequired: bool,
  fileUploder: node || null,
  error: string,
  disabled: bool,
  tooltip: string,
  source: string,
};

FileUploadLayout.defaultProps = {
  isRequired: false,
  error: null,
  disabled: false,
  tooltip: null,
  source: undefined,
  fileUploder: null,
};

export default FileUploadLayout;
