/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import EmployeeContext from 'erp/employee/EmployeeContext';
import UserSettings from 'user/Settings/createupdate/Update';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';

export const USER_SETTINGS_PATH = '/settings';

const UserSettingsRouter: Router = () => {
  const [userAlias, setUserAlias] = useState<Nullable<string>>(null);
  const { getUserAlias } = useAuthenticatedUserService();

  useEffect(() => {
    (async () => {
      const alias = await getUserAlias();
      setUserAlias(alias);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProtectedRouter basePath={USER_SETTINGS_PATH}>
      <Switch>
        <Route path={USER_SETTINGS_PATH} exact>
          <EmployeeContext id={userAlias || ''}>
            <UserSettings />
          </EmployeeContext>
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

UserSettingsRouter.getRouterPath = () => USER_SETTINGS_PATH;

export default UserSettingsRouter;
