/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import { HIRING_STATUS } from '../../useHiringService';

type HiringStatusFieldProps = {
  value?: any;
  source: string;
  label: string;
  isSortable?: boolean;
  width?: number;
};

const HiringStatusField = ({ value: inputValue, source, ...props }: HiringStatusFieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || getValue();

  const getColor = () => {
    let result: BasicColors = 'neutralLight';
    if (value === HIRING_STATUS.OPEN) {
      result = 'success';
    } else if (value === HIRING_STATUS.FAILED) {
      result = 'error';
    }

    return result;
  };

  return <CustomBadge text={value} color={getColor()} {...props} />;
};

export default HiringStatusField;
