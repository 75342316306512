import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { Checkbox, TextArea } from 'shared/uibuilder/form/input';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import LocationDropdown from 'shared/uibuilder/form/input/LocationDropdown/LocationDropdown';
import CompetenciesDropdown from '../shared/inputs/CompetenciesDropdown';
import TrainingDurationInput from './TrainingDurationInput';

const TrainingForm = () => {
  return (
    <FormSection title="Main information">
      <FormRow>
        <Checkbox source="isInternal" label="Internal?" className="checkbox-form-group--with-margin" />
      </FormRow>
      <FormRow>
        <TextInput source="name" label="Name" />
        <TrainingDurationInput source="durationInMinutes" />
        <LocationDropdown source="location.id" label="Location" />
      </FormRow>

      <FormRow>
        <CompetenciesDropdown source="competencies" label="Competencies" />
      </FormRow>

      <BigFormRow>
        <TextArea source="description" />
      </BigFormRow>
    </FormSection>
  );
};

export default TrainingForm;
