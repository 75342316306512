/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout/index';
import { DateField } from 'shared/uibuilder/field/index';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import ListRowSelectCheckbox from 'shared/uibuilder/form/input/ListRowSelectCheckbox';
import Box from 'uibuilder/Box';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import Loading from 'shared/uibuilder/Loading/index';
import { useSensitiveDataContext } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';
import ReconciliationAmountField from 'erp/costaccounting/reconcilation/shared/field/ReconciliationAmountField';

export const calculateTotal = (items: number[]) => items?.reduce((acc: number, item: number) => acc + (item || 0), 0);

const ReconciliationTransfersList = ({
  label,
  onRowSelection,
  items,
  loading,
  children,
  dateSource,
  amountSource,
  idSource,
  isSensitiveDataIsShown,
  setTotalSelectedBankTransaction,
  totalSelectedBankTransaction,
}: {
  label: string;
  dateSource: string;
  amountSource: string;
  idSource: string;
  items: Dictionary<any>[];
  loading: boolean;
  onRowSelection: (ids: string[]) => void;
  children?: React.ReactElement | React.ReactElement[];
  isSensitiveDataIsShown?: boolean;
  setTotalSelectedBankTransaction?: React.Dispatch<React.SetStateAction<number | undefined>>;
  totalSelectedBankTransaction?: number;
}) => {
  const [checkedRowIds, setCheckedRowIds] = useState<string[]>([]);
  const sensitiveDataContext = useSensitiveDataContext();

  const getAmounts = (data: Dictionary<any>[]) =>
    data.map(item => sensitiveDataContext.getValueByIdAndSource(amountSource, item[idSource]) || 0) || [];

  const total = calculateTotal(getAmounts(items));
  const totalSelected = calculateTotal(
    getAmounts(items?.filter((item: Dictionary<any>) => checkedRowIds.includes(item[idSource])) || []),
  );

  const handleRowSelection = ({ checked, ...values }: FormFieldsData) => {
    const id = values[idSource];

    if (checked) {
      setCheckedRowIds(prev => [...prev, id]);
    } else {
      setCheckedRowIds(prev => prev.filter(item => item !== id));
    }
  };

  const calculatePercentage = (totalSelectedDavinci: number, totalSelectedValue: number) => {
    if (totalSelectedDavinci === 0) {
      return 0;
    }
    const percentage = (totalSelectedValue / totalSelectedDavinci) * 100;
    return Math.round(percentage);
  };

  useEffect(() => {
    onRowSelection(checkedRowIds);
    // Suppressed warnings because we only need to call useEffect callback if checkedRowIds is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRowIds]);

  useEffect(() => {
    if (setTotalSelectedBankTransaction) {
      setTotalSelectedBankTransaction(totalSelected);
    }
  }, [totalSelected, setTotalSelectedBankTransaction]);

  useEffect(() => {
    if (items) {
      setCheckedRowIds([]);
    }
  }, [items]);

  return (
    <ControlledList
      data={{
        items: items || [],
        totalElements: items?.length || 0,
        totalPages: 1,
      }}
      loading={loading}
    >
      <Box className="w-100" sx={{ '.MuiCheckbox-root': { p: '2px' }, 'th:first-child, td: first-child': { pr: 0 } }}>
        <h5>{label}</h5>
        {loading ? (
          <Box sx={{ position: 'relative', minHeight: '50px', width: '100%', mt: 1 }}>
            <Loading />
          </Box>
        ) : (
          <>
            <Box sx={{ maxHeight: '550px', overflowY: 'auto', '.table-pagination-wrapper': { display: 'none' } }}>
              <DataGridLayout isSmallTable showTotal={false}>
                {
                  [
                    <ListRowSelectCheckbox
                      getDataSource={idSource}
                      source="checkbox"
                      label=""
                      onChangeCallback={handleRowSelection}
                      width="40px"
                    />,
                    <DateField source={dateSource} label="Date" width={105} />,
                    isSensitiveDataIsShown ? (
                      <ReconciliationAmountField source={amountSource} idSource={idSource} label="Amount" width={130} />
                    ) : null,
                    children,
                  ].filter(Boolean) as React.ReactElement[]
                }
              </DataGridLayout>
            </Box>
            {isSensitiveDataIsShown && items.length ? (
              <Box sx={{ mt: 2, fontWeight: '500' }}>Total: {getWithThousandsSeparator(total)}$</Box>
            ) : null}
            {isSensitiveDataIsShown && items.length && checkedRowIds.length ? (
              <Box sx={{ mt: 0, fontWeight: '500' }}>
                Total for selected items: {getWithThousandsSeparator(totalSelected)}${' '}
                {totalSelectedBankTransaction
                  ? `(${calculatePercentage(totalSelectedBankTransaction, totalSelected)}%)`
                  : ''}
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </ControlledList>
  );
};

export default ReconciliationTransfersList;
