/* istanbul ignore file */
import React from 'react';
import Page404 from 'shared/uibuilder/Page404';
import { RoutingSwitch, Router, Route } from 'shared/routing';
import { CamundaMetricsRouter } from 'camunda/metrics';
import { ProcessInstanceRouter } from 'camunda/monitoring/instance';
import { ProcessDefinitionRouter } from 'camunda/monitoring/definition';

export const CAMUNDA_PATH = '/processes';

const CamundaRouter: Router = () => (
  <RoutingSwitch>
    <ProcessInstanceRouter />
    <ProcessDefinitionRouter />
    <CamundaMetricsRouter />
    <Route component={Page404} />
  </RoutingSwitch>
);

CamundaRouter.getRouterPath = () => CAMUNDA_PATH;

export default CamundaRouter;
