/* istanbul ignore file */
import React from 'react';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import LinkField from 'shared/uibuilder/field/LinkField';

const ArtifactStorageHashTooltip = () => {
  return (
    <InputTooltip
      message={
        <>
          <p className="mb-1">The hash is encoded with the CRC-32 algorithm with a decimal value.</p>
          <p className="mb-1">The CRC-32 algorithm converts a variable length string into an 8 character string.</p>
          <p className="mb-0">
            For decoding, you can use online tools, for instance,
            <LinkField linkText="SimplyCalc" value="https://simplycalc.com/crc32-file.php" isInternal={false} /> (just
            upload the file and compare the received decimal value with the value in the column).
          </p>
        </>
      }
      source="file.artifact.hash"
      size="medium"
      sx={{ fontSize: '15px', margin: '0 0 -3px 2px' }}
    />
  );
};

export default ArtifactStorageHashTooltip;
