/* istanbul ignore file */
import React from 'react';

import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { BadgeField, SearchLinkField, SearchResultField } from 'shared/uibuilder/field';
import VacancyStatusField from 'erp/recruitment/vacancy/shared/field/VacancyStatusField';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import useAuthorization from 'shared/authorization/authorizationService';
import EditVacancyPublicationButton from 'erp/recruitment/vacancy/publication/shared/button/EditVacancyPublicationButton';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import useVacancyPublicationService, {
  PUBLISH_VACANCY,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import VacancyPublicationLinkField from 'erp/recruitment/vacancy/publication/shared/field/VacancyPublicationLinkField';

const VacancyPublicationList = () => {
  const { search } = useVacancyPublicationService();
  const { getSingleEntityUrl: getVacancyUrl } = useVacancyUrl();
  const { isGranted } = useAuthorization();

  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="updatedAt">
      <ListPageLayout
        header={<ListHeader label="Vacancy Publications" icon="fa-ship" />}
        search={<SearchInput label="Vacancy Name or ID" />}
        defaultSort={{ field: 'updatedAt', order: 'DESC' }}
      >
        <DataGridLayout
          buttons={[
            <EditVacancyPublicationButton
              key="edit-vacancy-publication"
              source="id"
              outline
              isVisible={vacancy => {
                return isGranted(PUBLISH_VACANCY) || vacancy.status !== 'OPEN';
              }}
              permissionToCheck=""
            />,
          ]}
        >
          <VacancyPublicationLinkField source="id" label="ID" nameSource="id" />
          <SearchLinkField source="vacancy.alias" url={id => getVacancyUrl(id)} value="" label="Vacancy ID" />
          <SearchResultField source="name" isSortable />
          <VacancyStatusField source="status" label="Status" isSortable />
          <BadgeField
            badges={[<CustomBadge id="draftVacancy" text="SYCOM" />]}
            label="Publication Platform"
            source="name"
            isSortable
          />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default VacancyPublicationList;
