import React, { useEffect } from 'react';
import useProjectService from 'erp/project/shared/projectService';
import useServiceService from 'erp/service/shared/serviceService';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const BusinessUnitsInput = (props: any) => {
  const { searchAll: searchProjects } = useProjectService();
  const { searchAll: searchServices } = useServiceService();
  const { getValue, initializeValue } = useInputHelper(props);

  const getBusinessUnitSuggestions = async (query: any) => {
    const [projects, services] = await Promise.all([
      searchProjects({
        filter: {
          'specification:realNameOrCodeName': {
            ct: query,
          },
        },
      }),
      searchServices({
        filter: {
          'specification:companyServiceName': {
            ct: query,
          },
        },
      }),
    ]);

    projects.forEach((project: any) => {
      // eslint-disable-next-line no-param-reassign
      project.reference = `P${project.id}`;
    });
    services.forEach((service: any) => {
      // eslint-disable-next-line no-param-reassign
      service.reference = `S${service.id}`;
    });

    return [...projects, ...services];
  };

  const mapEntry = (item: any) => ({
    value: item.reference,
    label: item.name,
  });

  const formatData = (selectData: any[]) => selectData.map(mapEntry);

  const rawValue = (getValue() || []) as any[];
  const value = rawValue.map((item: any) => (item.value ? item : mapEntry(item)));

  useEffect(() => {
    initializeValue(value);
  }, [initializeValue, value]);

  return (
    <MultiSelectInput
      {...props}
      value={value}
      loadSuggestionsMethod={getBusinessUnitSuggestions}
      mapResults={formatData}
    />
  );
};

export default BusinessUnitsInput;
