/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import useWasteUrl from 'erp/costaccounting/waste/useWasteUrl';

const ViewWasteButton = (props: any) => {
  const { getSingleEntityUrl } = useWasteUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-waste-entry" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewWasteButton;
