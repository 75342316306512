/* istanbul ignore file */
import React from 'react';
import EntityHeader from 'shared/layout/EntityHeader';
import { updateCurrencyName } from 'shared/uibuilder/amountUtils';

const LedgerHeader = () => (
  <EntityHeader
    entityTitleSource="id"
    entityTitle={data => updateCurrencyName(data.getValueBySource('id').replace(/[/]/g, ' / '))}
  />
);

export default LedgerHeader;
