/* istanbul ignore file */
import React, { useMemo } from 'react';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import Box from 'uibuilder/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import useDeviceDetectorService, { MAX_SM_WIDTH } from 'oneStop/deviceDetectorService';
import { Placement } from 'shared/uibuilder/SimpleTooltip';

const ReconciliationStaticFilters = ({
  title,
  sx = {},
  children,
}: {
  title?: React.ReactNode;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}) => {
  const { isDesktop } = useDeviceDetectorService({
    maxWidth: MAX_SM_WIDTH,
    defaultValue: window.innerWidth > MAX_SM_WIDTH,
  });

  const filters = useMemo(
    () => (
      <FiltersLayout>
        <DateRangeFilter
          key={`reconciliation-period-filter-${isDesktop}`}
          popperPlacement={isDesktop ? Placement.BOTTOM_END : Placement.BOTTOM_START}
          label="Period"
          source="period"
          canBeInFuture
        />
      </FiltersLayout>
    ),
    [isDesktop],
  );

  return (
    <Box
      sx={{
        '@media (min-width: 1360px)': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '.button-dropdown__toggle': {
          minHeight: '36px',
          padding: '5px 12px',
        },
        '.dropdown-toggle': {
          marginLeft: '8px',
          transform: 'translateY(-2px)',
        },
        '& [data-testId="remove-btn"], & .filters-reset-btn': {
          display: 'none',
        },
        ...sx,
      }}
    >
      {title}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: 1,
          margin: '5px 0 15px',
          '@media (min-width: 1360px)': {
            justifyContent: 'flex-end',
          },
          '> *': {
            margin: '5px 0',
            '&.filter-item-wrapper': {
              marginRight: 0,
              marginBottom: '5px',
            },
          },
        }}
      >
        {children}
        {filters}
      </Box>
    </Box>
  );
};

export default ReconciliationStaticFilters;
