/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayoutWithoutMenu from 'shared/layout/ShowPageLayoutWithoutMenu';
import useNewCompetencyService from 'erp/qualification/newCompetency/newCompetencyService';
import { LinkField } from 'shared/uibuilder/field';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import LinkIcon from '@mui/icons-material/LaunchOutlined';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';
import NewQualificationModel from 'erp/qualification/newCompetency/Show/NewQualificationModel';

const NewQualificationModelViewPage = () => {
  const { getCompetencies } = useNewCompetencyService();

  return (
    <Show getDataMethod={getCompetencies}>
      <ShowPageLayoutWithoutMenu>
        <Typography variant="h1" sx={{ marginBottom: '42px' }} className="c-title">
          Syberry Qualification Model
        </Typography>
        {/* TODO: update link here after updating QM in policy and remove hidden prop */}
        <Box sx={{ marginBottom: '42px', a: { display: 'flex', alignItems: 'center' } }} hidden>
          <Typography variant="h2" className="c-title" sx={{ marginBottom: '18px' }}>
            Policy
          </Typography>
          <LinkField
            linkText="Syberry Qualification Policy"
            isInternal={false}
            icon={LinkIcon}
            value="https://git.syberry.com/syberry-policies/production/-/blob/master/qualification-model.md"
          />
        </Box>
        <ShowSection title="Qualification requirements" titleVariant="h2">
          <NewQualificationModel />
        </ShowSection>
      </ShowPageLayoutWithoutMenu>
    </Show>
  );
};

export default NewQualificationModelViewPage;
