import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-precise-range-plugin';

const useCountDown = ({
  targetDate,
  shouldCountFromTargetDate = false,
  stopDatetime,
}: {
  targetDate: string;
  shouldCountFromTargetDate?: boolean;
  stopDatetime?: string;
}) => {
  const countDownDate = moment(targetDate);

  const [countDown, setCountDown] = useState(null);

  const getTime = () => {
    const endDate = stopDatetime ? moment(stopDatetime) : moment();

    const preciseDiff = shouldCountFromTargetDate
      ? (moment as any).preciseDiff(endDate, countDownDate, true)
      : (moment as any).preciseDiff(countDownDate, endDate, true);

    setCountDown({
      ...preciseDiff,
      years: 0,
      months: preciseDiff.months + preciseDiff.years * 12,
      isExpired: !preciseDiff.firstDateWasLater,
    });
  };

  useEffect(() => {
    getTime();

    const interval = setInterval(getTime, 1000);

    return () => clearInterval(interval);
    // Suppressed warnings because we only need to call useEffect callback only after updating targetDate.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  return countDown;
};

export default useCountDown;
