import { useBusinessEngineApi } from '../../../api';

export const READ_CAMUNDA_METRICS_REPORT = 'READ_CAMUNDA_METRICS_REPORT';

const useCamundaMetricsService = () => {
  const { sendGetRequest } = useBusinessEngineApi();

  const getReport = async ({ filters }: { filters: any }) => {
    const from: string = filters.processStartDate?.ge;
    const to: string = filters.processStartDate?.le;

    const params: Dictionary<string> = {};

    if (from) {
      params.dateFrom = from;
    }
    if (to) {
      params.dateTo = to;
    }

    const searchParams = new URLSearchParams(params);

    const result = await sendGetRequest(`/camunda/metrics?${searchParams.toString()}`);
    return result;
  };

  return {
    getReport,
  };
};

export default useCamundaMetricsService;
