/* istanbul ignore file */
import React from 'react';
import { CheckboxGroup } from 'shared/uibuilder/form/input';
import { CheckboxGroupLayoutType } from 'shared/uibuilder/list/filter/CheckboxGroupFilter';

const CheckboxGroupFilterLayout: CheckboxGroupLayoutType = props => {
  return <CheckboxGroup {...props} />;
};

export default CheckboxGroupFilterLayout;
