/* istanbul ignore file */
import { READ_PROJECT_STATUS_LIST, StatusTypes } from 'erp/project/statuses/ProjectStatusesService';
import UpdateProjectDemoStatusForm from './form/UpdateProjectDemoStatusForm';
import StatusDemoRecordLayout from './layout/StatusDemoRecordLayout';

const useProjectDemoStatusRegistry = () => ({
  UpdateForm: UpdateProjectDemoStatusForm,
  isApplicable: item => item.projectStatusType === StatusTypes.DEMO,
  source: 'status',
  urlPostfix: 'status',
  RecordLayout: StatusDemoRecordLayout,
  name: 'Project Status',
  hasFullData: true,
  permissionToCheck: READ_PROJECT_STATUS_LIST,
});

export default useProjectDemoStatusRegistry;
