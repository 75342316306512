/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { DateField, TextField, BadgeField } from 'shared/uibuilder/field';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import CandidateApplicationListBreadcrumbs from 'erp/candidate/applications/List/Breadcrumbs';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import { CandidateApplicationStatus } from 'erp/candidate/applications/shared/candidateApplicationService';
import CandidateApplicationSourceField from 'erp/candidate/shared/field/CandidateApplicationSourceField';
import CandidateApplicationSourceTypeField from 'erp/candidate/applications/shared/field/CandidateApplicationSourceTypeField';
import CandidateApplicationStatusBadge from 'erp/candidate/applications/shared/badge/CandidateApplicationStatusBadge';
import useApplicationService, { GROUP_APPLICATION } from 'erp/recruitment/applications/shared/applicationService';
import RowWithDetailsPanel from 'shared/uibuilder/list/layout/DataGridLayout/RowWithDetailsPanel';
import { DAYS_COLOR_BORDER } from 'erp/recruitment/applications/List/ApplicationsDataGrid';
import useAuthorization from 'shared/authorization/authorizationService';
import ProgressField from 'erp/recruitment/applications/shared/field/ProgressField';
import TimeInProgressField from 'erp/recruitment/applications/shared/field/TimeInProgressField';
import LastTouchApplicationField from 'erp/recruitment/applications/shared/field/LastTouchApplicationField';
import ApplicationCandidateScoreBadge from 'erp/recruitment/applications/shared/badge/ApplicationCandidateScoreBadge';
import ApplicationStageField from 'erp/recruitment/applications/shared/field/ApplicationStageField';
import { EmployeeFilter, EmployeeLinkField } from 'erp/employee';
import { Filters, FiltersLayout } from 'shared/uibuilder/list/filter';
import { useCandidateId } from 'erp/candidate/CandidateRouter';

const CandidateApplicationsList = () => {
  const { searchForCandidate } = useApplicationService();
  const parentCandidateId = useCandidateId();
  const { isGranted } = useAuthorization();
  const canGroupApplications = isGranted(GROUP_APPLICATION);

  const filters = (
    <Filters>
      <FiltersLayout>
        <EmployeeFilter source="createdBy.alias" label="Author" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ShowPageLayout
      entityHeader={<CandidateEntityHeader />}
      menu={<CandidateMenu />}
      breadcrumbs={<CandidateApplicationListBreadcrumbs />}
    >
      <List getDataMethod={searchForCandidate(parentCandidateId)} defaultSortOrder={DESC} defaultSortField="createdAt">
        <RelationListLayout header="Applications" filter={filters}>
          <DataGridLayout
            isSmallTable
            tableRowComponent={RowWithDetailsPanel}
            tableWrapperClassName="applications-table-wrapper"
          >
            {
              [
                canGroupApplications ? <ProgressField source="progress" label=" " isDetailsPanel width={40} /> : null,
                <TextField source="candidateApplication.id" label="ID" isSortable />,
                <VacancyLinkField
                  source="candidateApplication.vacancyAlias"
                  nameSource="candidateApplication.vacancyName"
                  label="Vacancy"
                  getLinkText={({ id, name }: { id: string; name?: string }) => `${id} ${name}`}
                />,
                canGroupApplications ? (
                  <BadgeField
                    badges={[<ApplicationCandidateScoreBadge source="score" label="Match score" isSortable />]}
                    label="Match score"
                  />
                ) : null,
                <BadgeField
                  badges={[
                    <CandidateApplicationStatusBadge source="candidateApplication.status" label="Status" isSortable />,
                  ]}
                  label="Status"
                  width={95}
                />,
                <DateField source="candidateApplication.createdAt" label="Created At" isSortable />,
                <EmployeeLinkField
                  source="candidateApplication.createdById"
                  nameSource="candidateApplication.createdByName"
                  label="Author"
                  isSortable
                />,

                <TimeInProgressField
                  elapsedTimeSource="candidateApplication.createdAt"
                  completedAtSource="completedAt"
                  label="Time to hire"
                />,
                <LastTouchApplicationField
                  label="Last Touch"
                  source="candidateApplication.lastTouchAt"
                  statusSource="candidateApplication.status"
                  colorBorders={DAYS_COLOR_BORDER}
                  skipStates={[CandidateApplicationStatus.PROCESSED]}
                />,
                <DateField source="completedAt" label="Completed At" isSortable />,
                <CandidateApplicationSourceTypeField
                  source="candidateApplication.sourceType"
                  label="Source type"
                  isSortable
                />,
                <CandidateApplicationSourceField source="candidateApplication.source" isSortable />,
                canGroupApplications ? <ApplicationStageField source="stage" label="Stage" width={165} /> : null,
              ].filter(Boolean) as React.ReactElement[]
            }
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default CandidateApplicationsList;
