import React, { useState, useEffect } from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { getVisibility } from 'shared/uibuilder/helper';

const useFiltersVisibilityHelper = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  const { filter: listFilter = {}, uncontrolledFilters } = useListContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isApplied, setIsApplied] = useState(false);

  /**
   * isApplied -- depends on actually applied filters,
   * listContext contains information if
   * filters were actually applied to search
   */
  useEffect(() => {
    const filters = { ...listFilter };

    uncontrolledFilters?.forEach(source => {
      delete filters[source];
    });

    const isFiltersExist = Object.keys(filters).length > 0;

    setIsApplied(isFiltersExist);
    // Suppressed warnings because we only need to call useEffect callback only after listFilter changing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFilter]);

  /**
   * Collapse or expand the filter block
   */
  const toggleFiltersDisplaying = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Return only visible filters
   * @return {array}
   */
  const getFilteredChildren = () =>
    (React.Children.toArray(children) as React.ReactElement[]).filter(child => {
      const { isVisible: initialIsVisible } = child.props;

      return getVisibility({}, initialIsVisible);
    });

  return {
    toggleFiltersDisplaying,
    getFilteredChildren,
    isApplied,
    isOpen,
  };
};

export default useFiltersVisibilityHelper;
