import { useEffect } from 'react';
import { FilterItem } from 'shared/uibuilder/list/filter/layout/useFiltersLayoutHelper';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

const useFiltersListHelper = ({ filters }: { filters: FilterItem[] }) => {
  const { clearFilter } = useFilterContext();

  /**
   * Return filter options for unselected filters
   * @return {array}
   */
  const getFilterOptions = () =>
    filters
      .filter(item => !item.selected)
      .map(item => {
        const { id, label, labelInDropdown, disabled } = item;

        return {
          value: id,
          label: labelInDropdown || label,
          disabled,
        };
      });

  useEffect(() => {
    filters.forEach(item => {
      if (item.selected && item.disabled) {
        clearFilter(item.source);
      }
    });
  }, [filters, clearFilter]);

  return {
    getFilterOptions,
  };
};

export default useFiltersListHelper;
