/* istanbul ignore file */
import React from 'react';
import { useEmployeeSalaryUrl } from 'erp/employee/salaries/SalaryRouter';
import EditButton from 'uibuilder/button/EditButton';

const EditSalaryButton = ({ ...props }) => {
  const { getUpdateEntityUrl } = useEmployeeSalaryUrl();

  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditSalaryButton;
