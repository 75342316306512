/* istanbul ignore file */
import React from 'react';

import useAccessLogService from 'erp/employee/AccessLog/accessLogService';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import CandidateAccessLogBreadCrumbs from 'erp/employee/AccessLog/CandidateAccessLogBreadCrumbs';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import { CreateForm } from 'shared/uibuilder/form';
import FormRow from 'shared/layout/form/FormRow';
import SubmitFormButton from 'erp/employee/AccessLog/SubmitFormButton';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import AccessLogManual from 'erp/employee/AccessLog/AccessLogManual';

const AccessLogPage = () => {
  const { getValidationSchema, requestLogCsv } = useAccessLogService();

  const onFormResponseForm = response => {
    const contentDisposition = response.headers.get('content-disposition');
    response.blob().then(blob => {
      const objectUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = objectUrl;
      // eslint-disable-next-line prefer-destructuring
      anchor.download = /filename="(.*)"/g.exec(contentDisposition)[1];
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
  };
  return (
    <ShowPageLayout breadcrumbs={<CandidateAccessLogBreadCrumbs />} menu={<EmployeeMenu />}>
      <CreateForm
        submitFormFunc={data => requestLogCsv(data.dateFrom, data.dateTo)}
        afterSubmit={{ execute: data => onFormResponseForm(data) }}
        getValidationSchemaFunc={getValidationSchema}
      >
        <FormRow>
          <DateInput label="Start date" source="dateFrom" />
          <DateInput label="End date" source="dateTo" />
        </FormRow>
        <SubmitFormButton>DOWNLOAD</SubmitFormButton>
      </CreateForm>
      <AccessLogManual />
    </ShowPageLayout>
  );
};

export default AccessLogPage;
