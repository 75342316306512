/* istanbul ignore file */
import React, { useState } from 'react';
import useContractRequestService from 'crm/opportunity/presale/contract/shared/contractRequestService';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import ContractRequestForm from 'crm/opportunity/presale/contract/form/ContractRequestForm';
import BeforeSubmitContractRequestModal from 'crm/opportunity/presale/contract/shared/modal/BeforeSubmitContractRequestModal';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import useAsyncValue from 'shared/asyncHelper';

const CreateContractRequestForm = ({ submitFormFunc, onCancel, ...props }: any) => {
  const { getValidationSchema } = useContractRequestService();
  const [modalOpen, setModalOpen] = useState(false);
  const id = useOpportunityId();
  const { getLastPresaleOutput } = usePresaleOutputService();
  const lastPresaleOutput = useAsyncValue(() => getLastPresaleOutput(id));
  const onSubmitAction = lastPresaleOutput ? () => setModalOpen(true) : undefined;

  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onSubmitAction={onSubmitAction} onCancel={onCancel}>
        <BeforeSubmitContractRequestModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        <ContractRequestForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateContractRequestForm;
