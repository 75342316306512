/* istanbul ignore file */
import React from 'react';
import './EmployeeFamilyMembersLayout.scss';
import { arrayOf, element } from 'prop-types';

const EmployeeFamilyMembersLayout = ({ otherMembers, childrenMembers }) => {
  return (
    <>
      {!!otherMembers.length && <div>{otherMembers}</div>}
      {!!childrenMembers.length && (
        <div className="employee-children">
          <b>Children</b>
          {childrenMembers}
        </div>
      )}
    </>
  );
};

EmployeeFamilyMembersLayout.propTypes = {
  otherMembers: arrayOf(element).isRequired,
  childrenMembers: arrayOf(element).isRequired,
};

export default EmployeeFamilyMembersLayout;
