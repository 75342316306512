/* istanbul ignore file */
import React from 'react';
import useRbsService, { RbsNodeType } from 'erp/rbs/shared/rbsService';
import RbsForm from 'erp/rbs/createupdate/RbsForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateResponsibilityBreadcrumbs from 'erp/rbs/createupdate/Create/Breadcrumbs';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import { useSearchParameters } from 'shared/routing/helper';
import FormFloatButtons from 'erp/rbs/createupdate/FormFloatButtons';

const getInitialNodeType = (isGrantedCreateProject: boolean) => {
  return isGrantedCreateProject ? RbsNodeType.PROJECT : RbsNodeType.QUEUE;
};

const CreateRbsNode = ({ isGrantedCreateProject = false }: { isGrantedCreateProject?: boolean }) => {
  const { create, getValidationSchema } = useRbsService();
  const { getListUrl, getSingleEntityUrl } = useRbsUrl();
  const initialDataFromSearch = useSearchParameters();

  return (
    <CreateForm
      initialData={{
        ...initialDataFromSearch,
        hasParentNode: !initialDataFromSearch.isRootNode,
        nodeType: getInitialNodeType(isGrantedCreateProject),
      }}
      submitFormFunc={create}
      afterSubmit={{
        redirect: (data: any) => (data?.id ? getSingleEntityUrl(data.id) : getListUrl()),
        message: 'You are awesome! The responsibility has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        title="Create: Responsibility"
        breadcrumbs={<CreateResponsibilityBreadcrumbs />}
        buttons={<FormFloatButtons />}
      >
        <RbsForm isCreateForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateRbsNode;
