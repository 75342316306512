/* istanbul ignore file */
import React from 'react';
import { func, oneOfType, string } from 'prop-types';
import ShowDate from 'shared/uibuilder/ShowDate';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const TimelineRecordHeader = ({ actionName = '' }) => {
  const { data: entity } = useShowContext();
  const createdAt = entity.getValueBySource('createdAt');
  const createdById = entity.getValueBySource('createdById');
  const createdByName = entity.getValueBySource('createdByName');

  const resultActionName = typeof actionName === 'function' ? actionName(entity.getData()) : actionName;

  return (
    <>
      <div className="timeline__card-header mr-5 pr-3">
        <div className="timeline__employee-action">
          <div className="timeline__card-datetime d-inline-block">
            <ShowDate dateUTC={createdAt} format={DATE_FORMAT.FULL} />
          </div>
          <TimelineRecordAuthor id={createdById} name={createdByName} /> {resultActionName}
        </div>
      </div>
    </>
  );
};

TimelineRecordHeader.propTypes = {
  actionName: oneOfType([string, func]).isRequired,
};

export default TimelineRecordHeader;
