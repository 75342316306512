/* istanbul ignore file */
import React, { useState } from 'react';
import Grid from 'uibuilder/Grid';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import TextButton from 'uibuilder/button/TextButton';
import TicketAssigneeSearchField from './TicketAssigneeSearchField';

export type PlannedWorkItemType = {
  id: number;
  ticketName: string;
  assigneeName: string;
  assigneeAlias: string;
};

interface PlannedWorkItemInputRowProps {
  data: PlannedWorkItemType;
  id: number;
  formData: any;
  updateTickets: (newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

const PlannedWorkItemInputRow = ({
  data,
  id,
  formData,
  getErrorMessages,
  updateTickets,
}: PlannedWorkItemInputRowProps) => {
  const [rowData, setRowData] = useState(data);
  const source = `tickets[${id}]`;
  const enumErrorSource = `tickets.${id}`;
  const projectName = formData.project?.name;

  const onChangeFieldCallback = (fieldName: string) => (newFormData: FormFieldsData) => {
    const newData = {
      ...rowData,
      assigneeAlias: data.assigneeAlias,
      assigneeName: data.assigneeName,
      [`${fieldName}`]: newFormData[`${source}.${fieldName}`],
    };

    setRowData({ ...newData });
    const listData = formData.tickets;
    listData[id] = { ...newData };

    updateTickets(listData);
  };

  const removeRow = () => {
    const listData = formData.tickets;
    listData.splice(id, 1);

    updateTickets(listData);
  };

  return (
    <Grid container spacing={2} style={id === 0 ? { alignItems: 'flex-end' } : {}}>
      <Grid item xs={12} md={6}>
        <TextButton
          source={`${source}.ticketName`}
          label={id === 0 ? 'Work item (name or link)' : ''}
          value={rowData.ticketName}
          onChangeCallback={onChangeFieldCallback('ticketName')}
          isRequired
          title="Work item (name or link)"
          errors={getErrorMessages(`${source}.ticketName`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TicketAssigneeSearchField
          isRequired
          isDisabled={rowData.assigneeAlias === null && rowData.assigneeName !== null}
          label={id === 0 ? 'Assignee' : ''}
          sourceAlias={`${enumErrorSource}.assigneeAlias`}
          nameSource={`${enumErrorSource}.assigneeName`}
          projectName={projectName}
          errors={getErrorMessages(`${source}.assignee`)}
        />
      </Grid>
      <Grid item xs={12} md={1} style={{ maxWidth: '10%' }}>
        <RemoveListItemButton isVisible onClickCallback={removeRow} isFirstRow={id === 0} />
      </Grid>
    </Grid>
  );
};

export default PlannedWorkItemInputRow;
