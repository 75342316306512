/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import './ProjectQueueLayout.scss';

interface ProjectQueueLayoutProps {
  children: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  buttons?: React.ReactNode[];
  id?: string;
  className?: string;
  counterValue?: StringOrNumber;
}

const ProjectQueueLayout = ({
  children,
  title,
  buttons,
  subtitle,
  id,
  className,
  counterValue,
}: ProjectQueueLayoutProps) => {
  return (
    <div className={classnames('project-queue__wrapper', className)} id={id}>
      <div className="project-queue__header">
        <div>
          <h2 className="project-queue__title">{title}</h2>
          {counterValue && <span className="project-queue__issues-counter">{counterValue}</span>}
        </div>
        <h3 className="project-queue__subtitle">{subtitle}</h3>
        {buttons}
      </div>

      <div className="project-queue">{children}</div>
    </div>
  );
};

export default ProjectQueueLayout;
