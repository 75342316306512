/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useEmployeeSalaryService from 'erp/employee/salaries/shared/salaryService';
import { useEmployeeSalaryId } from 'erp/employee/salaries/SalaryRouter';

const EmployeeSalaryContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useEmployeeSalaryService();
  const CONTEXT_NAME = 'EmployeeSalary_CONTEXT';
  const employeeSalaryId = useEmployeeSalaryId();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={() => getPermissions(employeeSalaryId)}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default EmployeeSalaryContext;
