/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

export interface VacancyDropdownProps {
  source: string;
  label?: string;
  showLoader?: boolean;
  placeholder?: string;
  idAsValue?: boolean;
}

const VacancyDropdown = (props: VacancyDropdownProps) => {
  const { searchAll } = useVacancyService();
  const { data: formData } = useFormContext();
  const [lastOption, setLastOption] = useState();
  const { idAsValue } = props;

  const mapResults = (vacancy: ResultResourceData) => ({
    value: idAsValue ? vacancy.id : vacancy.alias,
    label: `${vacancy.alias} ${vacancy.name}`,
  });

  const loadVacancies = async () => {
    const vacancies = await searchAll({});

    return {
      result: vacancies,
    };
  };

  const vacancyAlias = formData?.vacancyAlias;
  useEffect(() => {
    if (vacancyAlias != null) {
      setLastOption(vacancyAlias);
    }
  }, [vacancyAlias]);

  return (
    <LoadDropdown
      loadDataMethod={loadVacancies}
      mapResults={mapResults}
      placeholder="Select the vacancy"
      value={lastOption}
      {...props}
    />
  );
};

export default VacancyDropdown;
