/* istanbul ignore file */
import React from 'react';
import { PROCESS_STATE_CAPTIONS } from 'camunda/monitoring/instance/processInstanceService';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const ProcessStateFilter = (props: any) => {
  return (
    <EqCondition {...props}>
      <EnumDropdown {...props} placeholder="Choose Process State" options={PROCESS_STATE_CAPTIONS} />
    </EqCondition>
  );
};

export default ProcessStateFilter;
