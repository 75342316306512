/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FeedbackAttachmentUploaderInput from 'artifact/shared/input/FeedbackAttachmentUploaderInput';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import FeedbackRelationshipDropdown, {
  FEEDBACK_RELATIONSHIP_ALIAS,
} from 'erp/employee/Timeline/feedback/form/FeedbackRelationshipDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import CalendarRightDateInput from 'erp/employee/Timeline/feedback/shared/input/CalendarRightDateInput';
import OnBehalfOfInput from 'erp/employee/Timeline/feedback/shared/input/OnBehalfOfInput';
import TimelineFeedbackWysiwygInput from 'erp/employee/shared/input/TimelineFeedbackWysiwygInput';

const FeedbackForm = () => {
  return (
    <>
      <FormRow colMd={12} colXl={6}>
        <TimelineFeedbackWysiwygInput artifactsSource="attachmentsIds" source="feedbackText" label="Feedback text" />
        <BigFormRow>
          <FormRow colMd={6} colXl={6}>
            <DateInput source="relatedPeriodStart" label="Related period from" />
            <CalendarRightDateInput source="relatedPeriodEnd" label="Related period to" />
          </FormRow>
          <OnBehalfOfInput
            onBehalfOfSource="onBehalfOf"
            onBehalfOfEmployeeSource="onBehalfOfEmployee"
            onBehalfOfEmployeeNameSource="onBehalfOfEmployeeName"
            label="On behalf of"
          />
          <FeedbackRelationshipDropdown source="relationship" />
          <ClearAfterUnmountWrapper isVisible={data => data.relationship !== FEEDBACK_RELATIONSHIP_ALIAS.NO_RELATION}>
            <TextInput source="relatedClient" label="Related Client" />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={data => data.relationship !== FEEDBACK_RELATIONSHIP_ALIAS.NO_RELATION}>
            <TextInput source="relatedProject" label="Related Project" />
          </ClearAfterUnmountWrapper>
        </BigFormRow>
      </FormRow>
      <BigFormRow>
        <FeedbackAttachmentUploaderInput label="Feedback files" source="attachmentsIds" />
      </BigFormRow>
    </>
  );
};

export default FeedbackForm;
