/* istanbul ignore file */
import React, { useState } from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import useArtifactStorageService, {
  UPLOAD_ARTIFACT_STORAGE_FILES,
} from 'artifactStorage/shared/artifactStorageService';
import { useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';
import ArtifactStorageListLayout from './ArtifactStorageListLayout';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';

const ArtifactStorageList = () => {
  const { getListData } = useArtifactStorageService();
  const { getCreateUrl } = useArtifactStorageUrl();
  const [canReloadData, setCanReloadData] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const getData = async (params: any) => {
    const response = await getListData(params);

    setCanReloadData(true);
    setBreadcrumbs(response.path);

    return response;
  };

  return (
    <List getDataMethod={getData}>
      <ListPageLayout
        header={<ListHeader label="Artifacts Storage" icon="fa-file" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Add file to storage"
            key="create-artifact-storage"
            permissionToCheck={UPLOAD_ARTIFACT_STORAGE_FILES}
          />,
        ]}
        search={<SearchInput label="Name" cleanUpValueWhenChangedFilters />}
      >
        <ArtifactStorageListLayout canReloadData={canReloadData} breadcrumbs={breadcrumbs} />
      </ListPageLayout>
    </List>
  );
};

export default ArtifactStorageList;
