import useAzureApiService from 'authentication/azure/azureApiService';
import { useAuthenticationUrl } from 'authentication/AuthenticationRouter';
import { Authenticator } from 'authentication/authenticator';
import useAuthenticationTokenRepository, {
  AuthenticationToken,
} from 'authentication/tokenprovider/authenticationTokenRepository';
import useEventBus, { USER_IS_LOGGED_OUT } from 'shared/useEventBus';

const useAzureAuthenticator = (): Authenticator => {
  const { dispatchEvent } = useEventBus();
  const { getAzureLogoutUrl } = useAuthenticationUrl();
  const { refreshToken: refreshAccessToken, revokeToken } = useAzureApiService();
  const { getToken, saveToken, clearToken } = useAuthenticationTokenRepository();

  const authenticate = async (authToken: AuthenticationToken): Promise<void> => {
    await saveToken(authToken);
  };

  const refreshToken = async (): Promise<void> => {
    const { ssid } = await getToken();

    try {
      const response = await refreshAccessToken(ssid);
      const { accessToken = '' } = response;

      if (accessToken) {
        const authenticationToken: AuthenticationToken = {
          token: accessToken,
          ssid,
        };

        await saveToken(authenticationToken);
      } else {
        throw new Error("Error occurred. Can't refresh token.");
      }
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const logout = async (): Promise<void> => {
    const { ssid } = await getToken();

    try {
      await revokeToken(ssid);
    } catch (error) {
      // Need to handle errors when revoking a token. Due to a long fix, qd #265211 was created to get rid of console.log.
      // eslint-disable-next-line no-console
      console.log(error);
    }

    await clearToken();
    dispatchEvent(USER_IS_LOGGED_OUT, {});
    window.location.assign(getAzureLogoutUrl());
  };

  return {
    authenticate,
    refreshToken,
    logout,
  };
};

export default useAzureAuthenticator;
