/* istanbul ignore file */
import React from 'react';
import { string, node } from 'prop-types';
import useArtifactService from 'artifact/artifactService';
import TmpAccessAwareSecurityContext from 'security/TmpAccessAwareSecurityContext';

export const ARTIFACT_CONTEXT = 'Artifact';

const ArtifactContext = ({ permissionToCheck, children, idSource }) => {
  return (
    <TmpAccessAwareSecurityContext
      useEntityService={useArtifactService}
      permissionToCheck={permissionToCheck}
      idSource={idSource}
      contextName={ARTIFACT_CONTEXT}
    >
      {children}
    </TmpAccessAwareSecurityContext>
  );
};

ArtifactContext.propTypes = {
  permissionToCheck: string,
  children: node.isRequired,
  idSource: string,
};

ArtifactContext.defaultProps = {
  permissionToCheck: null,
  idSource: undefined,
};

export default ArtifactContext;
