/* istanbul ignore file */
export default {
  description: {
    type: 'string',
  },
  relatedPresaleCoordinatorAlias: {
    type: 'enum',
    required: true,
  },
  sharepointLink: {
    type: 'string',
    link: true,
    required: true,
  },
  expectedProjectStart: {
    type: 'date',
    required: 'true',
    minDate: {
      value: '$TODAY',
      message: 'Please choose date in future',
    },
  },
  requiredResources: {
    type: 'array',
    required: true,
    forEach: {
      type: 'object',
      properties: {
        positionId: {
          type: 'enum',
          required: true,
        },
        qualification: {
          type: 'enum',
          required: true,
        },
        requiredFte: {
          type: 'int',
          minValue: 0,
          required: true,
        },
      },
    },
  },
};
