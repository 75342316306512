// libs
import React from 'react';
// components
import { TextField, FieldProps } from 'shared/uibuilder/field';
// services
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const capitalize = (text: string) => text && text[0].toUpperCase() + text.slice(1).toLowerCase();

const CapitalizedField = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const value = getValue();

  return <TextField value={capitalize(value)} {...props} />;
};

export default CapitalizedField;
