import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface Props {
  keySource?: string;
  versionSource?: string;
  keyValue?: string;
  versionValue?: string;
  [otherOptions: string]: unknown;
}
const ProcessDefinitionField = ({ keySource, versionSource, keyValue, versionValue, ...props }: Props) => {
  const { getValue: getKey } = useFieldHelper({ source: keySource, value: keyValue });
  const { getValue: getVersion } = useFieldHelper({ source: versionSource, value: versionValue });

  const value = `${getKey()} - v${getVersion()}`;

  return <TextField {...props} value={value} />;
};

export default ProcessDefinitionField;
