/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, CreateBreadcrumbItem, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';

const CreateArtifactStorageBreadcrumbs = () => {
  const { getListUrl } = useArtifactStorageUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Artifact Storage" link={getListUrl()} />
      <CreateBreadcrumbItem entity="Artifact" />
    </Breadcrumbs>
  );
};

export default CreateArtifactStorageBreadcrumbs;
