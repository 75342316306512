/* istanbul ignore file */
import React, { useMemo } from 'react';
import { ListProvider } from 'shared/uibuilder/list/ListContext';
import useListCrud from 'shared/uibuilder/list/builder/useListCrud';
import { LIST_PAGE_SIZE } from 'shared/uibuilder/list/List';
import { useListDataLoading, useListDataUpdating, useListParams } from 'shared/uibuilder/list/builder';
import { SimpleListProps } from './SimpleList';

export interface SimpleListWithSortingProps extends SimpleListProps {
  defaultSortField?: string;
  defaultSortOrder?: string;
}

const SimpleListWithSorting = ({
  getDataMethod,
  children,
  pageSize = LIST_PAGE_SIZE,
  onRemove,
  defaultSortField,
  defaultSortOrder,
}: SimpleListWithSortingProps) => {
  const {
    params: { sort },
    sortBy,
  } = useListParams({ defaultSortField, defaultSortOrder });
  const params = useMemo(
    () => ({
      sort,
    }),
    [sort],
  );

  const { loading, loadData } = useListDataLoading({ getDataMethod, pageSize });
  const { data } = useListDataUpdating({ loadData, params });
  const listCrud = useListCrud({ onRemove, inputData: data });

  const contextValue = {
    ...listCrud,
    loading,
    sortBy,
    sort,
  };

  return <ListProvider value={contextValue}>{children}</ListProvider>;
};

export default SimpleListWithSorting;
