/* istanbul ignore file */
import EntityHeader from 'shared/layout/EntityHeader';
import React from 'react';
import { useArtifactStorageId } from 'artifactStorage/ArtifactStorageRouter';

const ArtifactStorageFileHeader = () => {
  const id = useArtifactStorageId();
  return <EntityHeader entityTitle={`Artifact Storage File: ${id}`} />;
};

export default ArtifactStorageFileHeader;
