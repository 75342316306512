import React from 'react';
import ShowDate from 'shared/uibuilder/ShowDate';
import { TextField } from 'shared/uibuilder/field';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_CONVERSION_STATUS } from 'crm/lead/shared/leadService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const ConversionField = () => {
  const { data } = useShowContext();

  const date = <ShowDate dateUTC={data.getValueBySource('conversionTime')} format={DATE_FORMAT.FULL} />;

  let message;

  switch (data.getValueBySource('conversionStatus')) {
    case LEAD_CONVERSION_STATUS.CONVERSION_DONE:
      message = (
        <>
          Sent successfully to Google Ads (conversionName= `{data.getValueBySource('conversionName')}`, conversionTime=`
          {date}`)
        </>
      );
      break;
    case LEAD_CONVERSION_STATUS.CONVERSION_FAILED:
      message = (
        <>
          Conversion submission to Google Ads FAILED on {date}. Error: {data.getValueBySource('conversionError')}
        </>
      );
      break;
    default:
      message = null;
  }
  return <TextField label="Conversion" value={message} />;
};

export default ConversionField;
