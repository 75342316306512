/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useFinanceUrl } from 'erp/employee/finance/FinanceRouter';

const EditFinanceButton = props => {
  const { getUpdateEntityUrl } = useFinanceUrl();
  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditFinanceButton;
