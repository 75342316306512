/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useEntityUrl from 'shared/routing/useEntityUrl';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import { Route } from 'shared/routing';
import EmployeeAssignmentsListPage from 'erp/employee/assignments/List';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import CreateEmployeeAssignment from 'erp/employee/assignments/createupdate/Create';
import { CREATE_ASSIGNMENT, UPDATE_ASSIGNMENT } from 'erp/assignment/shared/assignmentService';
import UpdateEmployeeAssignment from 'erp/employee/assignments/createupdate/Update';

export const useEmployeeAssignmentUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  const baseUrls = useEntityUrl({
    baseLink: `${getSingleEntityUrl()}/assignments`,
  });

  const getListUrl = (id?: any) => `${getSingleEntityUrl(id)}/assignments`;

  const getCopyEntityUrl = (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/copy`;

  return {
    ...baseUrls,
    getCopyEntityUrl,
    getListUrl,
  };
};

export const useEmployeeAssignmentRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  const routers = useRoute({
    singleEntityRoute: `${employeeRoute}/assignments/:assignmentId`,
    listRoute: `${employeeRoute}/assignments`,
  });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
  };
};

export const useEmployeeAssignmentId = () => {
  return useParams<Dictionary<string>>().assignmentId;
};

const EmployeeAssignmentsRouter = () => {
  const { listRoute, createRoute, updateRoute, copyEntityRoute } = useEmployeeAssignmentRoute();

  return (
    <Switch>
      <Route exact path={listRoute}>
        <EmployeeAssignmentsListPage />
      </Route>

      <ProtectedRoute exact path={createRoute} permissionToCheck={CREATE_ASSIGNMENT}>
        <CreateEmployeeAssignment />
      </ProtectedRoute>

      <ProtectedRoute exact path={updateRoute} permissionToCheck={UPDATE_ASSIGNMENT}>
        <UpdateEmployeeAssignment />
      </ProtectedRoute>

      <ProtectedRoute exact path={copyEntityRoute} permissionToCheck={CREATE_ASSIGNMENT}>
        <CreateEmployeeAssignment />
      </ProtectedRoute>

      <Route component={Page404} />
    </Switch>
  );
};

export default EmployeeAssignmentsRouter;
