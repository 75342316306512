/* istanbul ignore file */
import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import FinanceBreadcrumbs from 'erp/employee/finance/shared/FinanceBreadcrumbs';
import { useFinanceId, useFinanceUrl } from 'erp/employee/finance/FinanceRouter';

const SingleFinanceBreadcrumbs = ({ children }) => {
  const { getSingleEntityUrl } = useFinanceUrl();
  const id = useFinanceId();

  return (
    <FinanceBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </FinanceBreadcrumbs>
  );
};

SingleFinanceBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleFinanceBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleFinanceBreadcrumbs;
