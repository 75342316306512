/* istanbul ignore file */
import React from 'react';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import './SearchResultLayout.scss';

interface SearchResultImageLayoutType {
  value: Dictionary<string>;
  handleClick?: () => void;
  getThumbnail?: () => void;
}

const SearchResultImageLayout = ({
  value,
  getThumbnail = () => {},
  handleClick = () => {},
}: SearchResultImageLayoutType) => {
  return value ? (
    <button key={value.id} onClick={handleClick} type="button">
      <SearchResultField value={value.title} isRowClickable={false} />
    </button>
  ) : null;
};

export default SearchResultImageLayout;
