/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';

const VacancyPublicationLink = ({ id, name }: { id: StringOrNumber; name: string }) => {
  const { getSingleEntityUrl } = useVacancyPublicationUrl();

  return (
    <Link to={getSingleEntityUrl(id)} target="_blank">
      {name}
    </Link>
  );
};

export default VacancyPublicationLink;
