/* istanbul ignore file */
import React from 'react';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewExpenseButton = (props: any) => {
  const { getSingleEntityUrl } = useExpenseUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-expense" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewExpenseButton;
