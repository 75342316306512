import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import React from 'react';
import CorporateEventTypeDropdown from 'financialAnalytic/corporateEvents/shared/input/CorporateEventTypeDropdown';

const CorporateEventTypeDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <CorporateEventTypeDropdown {...props} />
    </DropDownFilter>
  );
};

export default CorporateEventTypeDropdownFilter;
