/* istanbul ignore file */
export default {
  invoiceNumber: {
    type: 'string',
    required: true,
  },
  sowId: {
    type: 'number',
    required: true,
  },
  name: {
    type: 'string',
    required: true,
  },

  invoiceSum: {
    type: 'object',
    _divisible: false,
    properties: {
      amount: {
        type: 'string',
        required: true,
      },
      currency: {
        type: 'string',
        required: true,
      },
    },
  },

  invoiceDate: {
    type: 'date',
    required: true,
  },

  correctedInvoiceId: {
    type: 'string',
    required: {
      value: true,
      when: {
        _or_: [
          {
            '$invoiceCorrectionSum.currency': {
              notEmpty: true,
            },
          },
          {
            '$invoiceCorrectionSum.amount': {
              notEmpty: true,
            },
          },
        ],
      },
    },
  },
  invoiceCorrectionSum: {
    type: 'object',
    _divisible: false,
    properties: {
      amount: {
        type: 'string',
        required: {
          value: true,
          when: {
            _or_: [
              {
                $correctedInvoiceId: {
                  notEmpty: true,
                },
              },
              {
                '$invoiceCorrectionSum.currency': {
                  notEmpty: true,
                },
              },
            ],
          },
        },
      },
      currency: {
        type: 'string',
        required: {
          value: true,
          when: {
            _or_: [
              {
                $correctedInvoiceId: {
                  notEmpty: true,
                },
              },
              {
                '$invoiceCorrectionSum.amount': {
                  notEmpty: true,
                },
              },
            ],
          },
        },
      },
    },
  },

  description: {
    type: 'string',
    maxSize: 1024,
  },
};
