/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TimeInProgressField from 'crm/projectsDashboard/shared/field/TimeInProgressField';
import ProgressField from 'crm/projectsDashboard/shared/field/ProgressField';
import CompanyLinkField from 'crm/projectsDashboard/shared/field/CompanyLinkField';
import ProjectDashboardLegend from 'crm/projectsDashboard/shared/ProjectsDashboardLegend';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import ListLoadingDecorator from 'shared/uibuilder/list/layout/ListLoadingDecorator';
import TextField from 'shared/uibuilder/field/TextField';
import DataGridInfiniteScrollPagingHandler from 'shared/uibuilder/list/layout/DataGridInfiniteScrollPagingHandler';
import './ProjectsDashboardsDataGrid.scss';
import RowWithDetailsPanel from 'shared/uibuilder/list/layout/DataGridLayout/RowWithDetailsPanel';
import ProjectStageField from 'crm/projectsDashboard/shared/field/ProjectStageField';
import { EmployeeLinkField } from 'erp/employee';
import { notStaffingPredicate, PROGRESS_STATUSES } from 'crm/projectsDashboard/useProjectsDashboardService';
import { findLast, orderBy } from 'lodash';

const ProjectsDashboardsDataGrid = () => {
  const { data: { items = [] } = {}, loading } = useListContext();

  return (
    <>
      <DataGridInfiniteScrollPagingHandler>
        <DataGridLayout
          tableWrapperClassName="projects-dashboard-table"
          rowKeySource="leadId"
          tableRowComponent={RowWithDetailsPanel}
          rowProps={(item: Dictionary<string>) => {
            const progressSteps = (item?.progress as unknown) as any[];
            const currentStep =
              progressSteps?.find(
                (step: any) =>
                  PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY === step.status && notStaffingPredicate(step.stepName),
              ) ||
              findLast(
                orderBy(progressSteps, ['stepNumber'], ['asc']),
                step => step.status !== PROGRESS_STATUSES.NOT_STARTED && notStaffingPredicate(item.stepName),
              );
            const isHighlighted = [
              PROGRESS_STATUSES.IN_PROGRESS_WITH_DELAY,
              PROGRESS_STATUSES.COMPLETED_WITH_DELAY,
            ].includes(currentStep.status);

            return { className: isHighlighted ? 'highlighted' : '' };
          }}
        >
          <ProgressField isDetailsPanel label=" " source="progress" width={40} />
          <CompanyLinkField source="companyName" label="ID" />
          <TextField source="opportunityName" label="Opportunity name" />
          <TextField source="accountCodeName" label="Account name" />
          <EmployeeLinkField source="ownerAlias" nameSource="ownerName" label="Owner" />
          <ProjectStageField source="progress" label="Current State" width={205} />
          <TimeInProgressField
            source="leadCreationDate"
            customerActivityDurationSource="customerActivityDuration"
            label="Time elapsed"
            shouldCountFromTargetDate
            progressSource="progress"
            width={200}
          />
          <TimeInProgressField
            source="targetDate"
            customerActivityDurationSource="customerActivityDuration"
            label="Time remaining"
            progressSource="progress"
            width={200}
          />
        </DataGridLayout>
      </DataGridInfiniteScrollPagingHandler>
      <ListLoadingDecorator items={items} loading={loading}>
        {!!items.length && <ProjectDashboardLegend rowDirection />}
      </ListLoadingDecorator>
    </>
  );
};

export default ProjectsDashboardsDataGrid;
