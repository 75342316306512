import React, { useState } from 'react';
// @ts-ignore
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Grid from 'uibuilder/Grid';
import Collapse from '@mui/material/Collapse';
import Button from 'uibuilder/button/Button';
import Box from 'uibuilder/Box';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import './ListFilterWrapper.scss';

interface ListFilterWrapperProps {
  filter?: React.ReactElement;
  actions?: React.ReactElement[];
  search?: React.ReactElement;
}

const ListFilterWrapper = ({ filter, search, actions }: ListFilterWrapperProps) => {
  const { filter: listFilter = {}, loading, uncontrolledFilters } = useListContext();

  const filters = { ...listFilter };

  uncontrolledFilters?.forEach(source => {
    delete filters[source];
  });

  const hasFilters = !isEmpty(filters);

  const [isOpen, setIsOpen] = useState(hasFilters);

  useDidUpdateEffect(() => {
    if (hasFilters && !isOpen) {
      setIsOpen(true);
    }
  }, [listFilter]);

  const toggleFiltersDisplaying = () => {
    setIsOpen(!isOpen);
  };

  if (!filter && !search && (!actions || actions?.every((action: any) => !action))) {
    return null;
  }

  return (
    <>
      <Grid className="list-top-btns" container sx={{ pointerEvents: loading ? 'none' : '' }}>
        <Grid item className="list-top-btns__filters">
          {search}
          {!!filter && (
            <Button
              className={classnames('list-filter-toggle', { open: isOpen || hasFilters })}
              variant="text"
              startIcon={<FilterIcon />}
              onClick={toggleFiltersDisplaying}
            >
              Filter
            </Button>
          )}
        </Grid>
        <Grid item className="list-top-btns__actions">
          {actions}
        </Grid>
      </Grid>
      {!!filter && (
        <Collapse in={isOpen} timeout={400}>
          <Box sx={{ paddingTop: '3px', paddingBottom: '5px' }}>{filter}</Box>
        </Collapse>
      )}
    </>
  );
};

export default ListFilterWrapper;
