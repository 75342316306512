/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const EmployeeOfferBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useEmployeeOfferUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Offers" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeOfferBreadcrumbs;
