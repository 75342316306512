import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { useProjectRoute, useProjectUrl } from '../ProjectRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { READ_SOW, WRITE_SOW } from './useSowService';
import ViewSowProject from './show/ViewSowProject';
import ShowProject from '../statuses/shared/ShowProject';
import EditSow from './createupdate/update/EditSow';
import EmptySowPage from './show/empty/EmptySowPage';
import CreateSow from './createupdate/create/CreateSow';
import ProjectContext from '../ProjectContext';

export const PATH = '/sow';

export const useSowRoute = () => {
  const { singleEntityRoute: projectRoute } = useProjectRoute();
  const routs = useRoute({
    listRoute: `${projectRoute}${PATH}`,
    singleEntityRoute: `${projectRoute}${PATH}`,
  });

  return {
    ...routs,
    emptyEntityRoute: `${projectRoute}${PATH}/empty`,
  };
};

export const useSowUrl = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const projectLink = getSingleEntityUrl();
  const listUrl = `${projectLink}${PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${projectLink}${PATH}`,
  });

  const getListUrl = () => listUrl;
  const getUpdateEntityUrl = () => `${listUrl}/edit`;
  const getEmptyEntityUrl = () => `${listUrl}/empty`;

  return {
    ...baseUrls,
    getListUrl,
    getUpdateEntityUrl,
    getEmptyEntityUrl,
  };
};

const SowRouter = () => {
  const { listRoute, updateRoute, emptyEntityRoute, createRoute } = useSowRoute();

  return (
    <Switch>
      <Route path={listRoute} exact>
        <ProjectContext permissionToCheck={READ_SOW}>
          <ViewSowProject />
        </ProjectContext>
      </Route>

      <Route path={createRoute} exact>
        <ProjectContext permissionToCheck={WRITE_SOW}>
          <ShowProject>
            <CreateSow />
          </ShowProject>
        </ProjectContext>
      </Route>

      <Route path={updateRoute} exact>
        <ProjectContext permissionToCheck={WRITE_SOW}>
          <ShowProject>
            <EditSow />
          </ShowProject>
        </ProjectContext>
      </Route>

      <Route path={emptyEntityRoute} exact>
        <ProjectContext permissionToCheck={READ_SOW}>
          <ShowProject>
            <EmptySowPage />
          </ShowProject>
        </ProjectContext>
      </Route>
      <Route component={Page404} />
    </Switch>
  );
};

export default SowRouter;
