/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { func, string } from 'prop-types';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import {
  UPDATE_CANDIDATE_NOTE_PERMISSION,
  READ_CANDIDATE_NOTES_PERMISSION,
} from 'erp/candidate/Timeline/note/note/candidateNoteService';
import { Collapsible, HtmlField } from 'shared/uibuilder/field';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import CandidateNoteTag from 'erp/candidate/Timeline/note/shared/NoteTimelineRecordLayout/CandidateNoteTag';

const NoteTimelineRecordLayout = ({ onEdit, actionName, ...props }) => {
  const buttons = [
    <EditTimelineRecordButton permissionToCheck={UPDATE_CANDIDATE_NOTE_PERMISSION} key="edit" onEdit={onEdit} />,
  ];

  return (
    <BaseTimelineRecordLayout
      permissionToCheck={READ_CANDIDATE_NOTES_PERMISSION}
      actionName={actionName}
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      fields={
        <Collapsible>
          <HtmlField source="noteText" label="" />
        </Collapsible>
      }
      timelineFooter={rest => (
        <div className="d-flex mt-3 justify-content-between align-items-center feedback-timeline-footer">
          <CandidateNoteTag {...rest} />
        </div>
      )}
      {...props}
    />
  );
};

NoteTimelineRecordLayout.propTypes = {
  onEdit: func.isRequired,
  permissionToCheck: string.isRequired,
  actionName: string.isRequired,
};

export default NoteTimelineRecordLayout;
