import React from 'react';
import classnames from 'classnames';
import './LinearProgress.scss';

const MIN_VALUE = 0;
export const MAX_VALUE = 100;

export const calculateProgress = (value: number, maxValue: number) => (value * 100) / maxValue;

export const normalise = (value: number, maxValue: number) => {
  const progress = calculateProgress(value, maxValue);

  if (progress > MAX_VALUE) {
    return MAX_VALUE;
  }

  if (progress < MIN_VALUE) {
    return MIN_VALUE;
  }

  return progress;
};

const LinearProgress = ({ maxValue, currentValue, className }: any) => {
  const progress = normalise(currentValue, maxValue);

  return (
    <span className={classnames('linear-progress', className)} role="progressbar">
      <span className="linear-progress__bar" style={{ width: `${progress}%` }} />
    </span>
  );
};

export default LinearProgress;
