/* istanbul ignore file */
import React from 'react';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';

const SimpleFieldLayout: BaseFieldLayoutType = ({ label, value }) => {
  return (
    <>
      {!!label && `${label}: `}
      {value}
    </>
  );
};

export default SimpleFieldLayout;
