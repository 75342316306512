/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox, DateInput, NumberInput, TextInput } from 'shared/uibuilder/form/input';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import PositionDropdown from 'erp/employee/shared/input/PositionDropdown';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import AssignmentBudgetsDropdown from 'erp/assignment/shared/input/AssignmentBudgetsDropdown';
import useFeatureToggle, { Features } from 'featuretoggle';
import AssignmentTypeInput from 'erp/assignment/shared/input/AssignmentTypeInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { AssignmentType } from 'erp/assignment/shared/assignmentService';
import EmployeeGroupDropdown from 'erp/group/shared/input/EmployeeGroupDropdown';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import AssignmentTargetInput from 'erp/assignment/target/shared/input/AssignmentTargetInput';
import DropdownInputOnlyBoolean from '../../../shared/uibuilder/form/input/DropdownInputOnlyBoolean';

const isIndividualAssignment = (formData: any) => {
  return formData.assignmentType === AssignmentType.INDIVIDUAL_ASSIGNMENT;
};

const isGroupAssignment = (formData: any) => {
  return formData.assignmentType === AssignmentType.GROUP_ASSIGNMENT;
};

const AssignmentForm = ({ isCreateForm }: { isCreateForm?: boolean }) => {
  const { data: formData, isDirty } = useFormContext();
  const [hasInitialEmployeeId, setHasInitialEmployeeId] = useState(false);
  const [hasInitialEmployeeGroup, setHasInitialEmployeeGroup] = useState(false);
  const { isFeatureEnabled } = useFeatureToggle();
  const budgetFteEnabled = isFeatureEnabled(Features.VIEW_BUDGETS_FTE);
  const isGroupAssignmentsEnabled = isFeatureEnabled(Features.GROUP_ASSIGNMENTS);

  useEffect(() => {
    if (!isDirty && formData?.employeeId) {
      setHasInitialEmployeeId(true);
    }
    if (!isDirty && formData?.employeeGroupId) {
      setHasInitialEmployeeGroup(true);
    }
  }, [formData, isDirty]);

  return (
    <FormSection title="Assignment information">
      <MediumFormRow>
        <AssignmentTypeInput
          source="assignmentType"
          isVisible={isGroupAssignmentsEnabled && !hasInitialEmployeeId && !hasInitialEmployeeGroup && isCreateForm}
        />
      </MediumFormRow>
      <FormRow>
        <NotDismissedEmployeeInput source="ownerId" label="Owner" nameSource="ownerName" />
        <ClearAfterUnmountWrapper isVisible={isIndividualAssignment}>
          <NotDismissedEmployeeInput
            source="employeeId"
            label="Employee"
            nameSource="employeeName"
            includeInterns
            disabled={hasInitialEmployeeId || !isCreateForm}
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={isGroupAssignment}>
          <EmployeeGroupDropdown
            source="employeeGroupId"
            label="Service"
            placeholder="Select service"
            errorSource="employeeGroup"
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={isIndividualAssignment}>
          <DropdownInputOnlyBoolean placeholder="Bench" source="bench" label="Bench" />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <AssignmentTargetInput
          mapResults={(target: any) => {
            return {
              id: target.id,
              text: target.displayName,
              target,
            };
          }}
          source="target"
          label="Target"
          nameSource="displayName"
          errorSource="rbsTreeNode"
        />
        <TextInput
          source="target.budgetInfo.defaultBudget.name"
          label="Target's Default Budget"
          disabled
          isVisible={assignment => !!assignment?.target?.budgetInfo?.defaultBudget}
        />
        <AssignmentBudgetsDropdown
          source="budgetId"
          label="Budget"
          nameSource="budget.name"
          searchString="name"
          helpText="Specify budget if it differs from the default target budget"
          isVisible={budgetFteEnabled}
        />
      </FormRow>
      <FormRow>
        <ClearAfterUnmountWrapper isVisible={isIndividualAssignment}>
          <PositionDropdown source="positionId" label="Position" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={isIndividualAssignment}>
          <div style={{ marginTop: 20 }}>
            <Checkbox source="backup" label="Backup" />
          </div>
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <ClearAfterUnmountWrapper isVisible={isIndividualAssignment}>
          <NotDismissedEmployeeInput
            source="backupForEmployeeId"
            label="Backup For"
            nameSource="backupForEmployeeName"
            errorSource="backupFor"
            isVisible={data => data.backup}
            includeInterns
          />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <DateInput source="from" disabled={!isCreateForm && new Date(formData?.from) < new Date()} />
        <DateInput source="to" />
      </FormRow>
      <FormRow>
        <NumberInput source="minPercent" />
        <NumberInput source="maxPercent" />
      </FormRow>
    </FormSection>
  );
};

export default AssignmentForm;
