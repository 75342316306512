import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import { ResourceData } from '../../crud/baseCrudService';

type CalculatedUrl = string | ((arg?: ResourceData) => string);

const calculateUrl = (url: CalculatedUrl, data?: ResourceData) => {
  if (typeof url === 'function') {
    return url(data);
  }

  return url;
};

const isChanged = (fields: ResourceData, response: ResourceData) => {
  return Object.keys(fields).reduce((result, key) => {
    return result || fields[key] !== response[key];
  }, false);
};

/**
 * @param message -- message to display after redirect
 * @param unchangedUrl -- redirect target if @fields weren't changed
 * @param changedUrl -- redirect target if any of @fields were changed
 * @param fields -- an object with keys matching keys from @response param
 * passed to {redirect} method. Each value from fields object will be compared to
 * value from {redirect} call parameter
 * @returns {{redirect: redirect}}
 */
const useRelatedRedirect = ({
  message,
  unchangedUrl,
  changedUrl,
  fields,
}: {
  message: string;
  unchangedUrl: CalculatedUrl;
  changedUrl: CalculatedUrl;
  fields: ResourceData;
}) => {
  const { addMessageAndRedirect } = useMessageService();

  return {
    redirect: (response: ResourceData) => {
      const successMessage = new SuccessMessage(message);
      let url;

      if (isChanged(fields, response)) {
        url = calculateUrl(changedUrl, response);
      } else {
        url = calculateUrl(unchangedUrl, response);
      }

      addMessageAndRedirect(successMessage, url);
    },
  };
};

export default useRelatedRedirect;
