/* istanbul ignore file */
import React from 'react';
import MarkAsPaidExpenseButton, {
  MarkAsPaidExpenseButtonProps,
} from 'financialAnalytic/expenses/shared/button/MarkAsPaidExpenseButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const MarkAsPaidExpenseListButton = (props: MarkAsPaidExpenseButtonProps) => {
  const { reloadData } = useListContext();

  return <MarkAsPaidExpenseButton {...props} onSubmit={reloadData} />;
};

export default MarkAsPaidExpenseListButton;
