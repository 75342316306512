/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';
import TextField from 'shared/uibuilder/field/TextField';
import { SearchResultFieldProps } from 'shared/uibuilder/field/SearchResultField';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';

type ExpenseLinkProps = SearchResultFieldProps & {
  nameSource: string;
  isClickable?: boolean;
};

const ExpenseLink = ({ source, nameSource, isClickable = true, ...props }: ExpenseLinkProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useExpenseUrl();
  const id = getValue();
  const name = getName();

  return isClickable ? (
    <SearchLinkField value={name || id} isInternal url={getSingleEntityUrl(id)} {...props} />
  ) : (
    <TextField source={source} value={name || id} />
  );
};

export default ExpenseLink;
