/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { ASSET_CONDITIONS } from 'financialAnalytic/assets/useAssetService';

const AssetConditionDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown placeholder="Select Condition" options={ASSET_CONDITIONS} {...props} />;
};

export default AssetConditionDropdown;
