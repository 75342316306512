/* istanbul ignore file */
import React from 'react';
import { useEmployeePerformanceId } from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import Show from 'shared/uibuilder/show/Show';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import ShowEmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/Show/ShowEmployeePerformanceBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import DateField from 'shared/uibuilder/field/DateField';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import EmployeePerformanceField from 'erp/employee/performanceEvaluation/employeePerformance/shared/field/EmployeePerformanceField';
import EditEmployeePerformanceButton from 'erp/employee/performanceEvaluation/employeePerformance/shared/button/EditEmployeePerformanceButton';
import { DELETE, UPDATE } from 'crm/crmService';
import DeleteEmployeePerformanceButton from 'erp/employee/performanceEvaluation/employeePerformance/shared/button/DeleteEmployeePerformanceButton';
import EmployeePerformanceLevelsField from 'erp/employee/performanceEvaluation/employeePerformance/shared/field/EmployeePerformanceLevelsField';

const ShowEmployeePerformancePage = () => {
  const employeePerformanceId = useEmployeePerformanceId();
  const { getById } = useEmployeePerformanceService();

  return (
    <ShowPageLayout
      headerButtons={[
        <EditEmployeePerformanceButton
          key="edit-performance"
          icon={<i className="fa fa-edit" />}
          permissionToCheck={UPDATE}
        />,
        <DeleteEmployeePerformanceButton key="delete-performance" permissionToCheck={DELETE} />,
      ]}
      menu={<EmployeeMenu />}
      breadcrumbs={<ShowEmployeePerformanceBreadcrumbs />}
      entityHeader={<EmployeeEntityHeader />}
    >
      <Show getDataMethod={() => getById(employeePerformanceId)}>
        <ShowSection title="Common Performance Information">
          <SectionRow>
            <EmployeeLinkField nameSource="resourceManagerName" source="resourceManagerId" label="Author" />
            <DateField source="relatedProcess.from" label="Period From" />
            <DateField source="relatedProcess.to" label="Period To" />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Performance Evaluation Result">
          <EmployeePerformanceLevelsField source="performanceLevelCriteria" />
          <EmployeePerformanceField source="performanceCriteria" />
        </ShowSection>
        <ShowSection title="Attachments">
          <BigSectionRow>
            <AttachmentsListField source="attachmentIds" label="" />
          </BigSectionRow>
        </ShowSection>
      </Show>
    </ShowPageLayout>
  );
};

export default ShowEmployeePerformancePage;
