import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBusinessEngineApi } from 'api';
import useCrudService from 'shared/crud';
import { HTTP, ROUTE404 } from 'shared/api/const';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import sendEmailValidation from 'sendEmail/createupdate/sendEmailValidation';

export const RESOURCE_URL = '/emails';

interface Email {
  attachmentIds?: string[];
  ccs?: string[];
  content?: string;
  recipient?: string;
  sender?: string;
  subject?: string;
  taskName?: string;
}

enum SendEmailActions {
  SAVE_EMAIL = 'SAVE_EMAIL',
  CLEAR_EMAIL = 'CLEAR_EMAIL',
}

interface EmailData {
  id: string;
  content: Email;
}

export interface SendEmailAction extends Action {
  id: string;
  content: Email;
}

export interface SendEmailState {
  emails: EmailData[];
}

const saveEmail = (id: string, content: string) => ({
  type: SendEmailActions.SAVE_EMAIL,
  id,
  content,
});

const clearEmail = (id: string) => ({
  type: SendEmailActions.CLEAR_EMAIL,
  id,
});

const initialState = {
  emails: [],
};

export const sendEmailReducer = (state: SendEmailState = initialState, action: SendEmailAction) => {
  const { id, content } = action;

  if (action.type === SendEmailActions.SAVE_EMAIL) {
    const newState = { ...state };
    const email = { id, content };

    const index = newState.emails.findIndex(item => item.id === email.id);

    if (index > -1) {
      newState.emails[index] = email;
    } else {
      newState.emails.push(email);
    }

    return {
      ...newState,
    };
  }

  if (action.type === SendEmailActions.CLEAR_EMAIL) {
    const newState = { ...state };
    const index = newState.emails.findIndex(item => item.id === action.id);

    newState.emails.splice(index, 1);

    return {
      ...newState,
    };
  }

  return state;
};

const useSendEmailService = () => {
  const emails = useSelector((state: { sendEmail: SendEmailState }) => state?.sendEmail?.emails);
  const { sendPostRequest, sendDeleteRequest } = useBusinessEngineApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addMessage } = useMessageService();
  const { getById: initialGetById } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
  });

  const getById = async (id: string) => {
    try {
      const response = await initialGetById(id);
      const isEmptyCc = response?.ccs?.length === 1 && response?.ccs[0] === '';

      if (isEmptyCc) {
        response.ccs = [];
      }

      dispatch(saveEmail(id, response));

      return response;
    } catch (error) {
      if ((error as Response).status === HTTP.NOT_FOUND) {
        history.push(ROUTE404);
      } else {
        addMessage(new ErrorMessage("Can't get email data due to server error"));
      }

      return null;
    }
  };

  const getEmailContentFromStore = (id: string) => {
    const data = emails.filter((item: EmailData) => item.id === id);

    return data.length ? data[0].content : null;
  };

  const getData = async (id: string) => {
    const currentEmail = getEmailContentFromStore(id);

    if (currentEmail) {
      return currentEmail;
    } else {
      return getById(id);
    }
  };

  const updateData = (id: string, emailData: any) => {
    dispatch(saveEmail(id, emailData));

    return Promise.resolve({});
  };

  const sendEmail = async (id: string) => {
    try {
      let sendEmailData: Email = {};
      const currentEmail = getEmailContentFromStore(id);

      if (currentEmail) {
        sendEmailData = { ...currentEmail };
      }

      delete sendEmailData.taskName;

      await sendPostRequest(`${RESOURCE_URL}/${id}/send`, sendEmailData);
    } catch (error) {
      addMessage(new ErrorMessage('Something went wrong.'));

      throw error;
    }
  };

  const undoSending = async (id: string) => {
    await sendDeleteRequest(`${RESOURCE_URL}/${id}/undo`);
  };

  const clearData = async (id: string) => {
    dispatch(clearEmail(id));
  };

  const getValidationSchema = () => Promise.resolve(sendEmailValidation);

  return {
    getData,
    clearData,
    sendEmail,
    updateData,
    undoSending,
    getValidationSchema,
  };
};

export default useSendEmailService;
