/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { TextField, FieldProps } from 'shared/uibuilder/field/index';
import { AssignmentTypeLabels } from '../assignmentService';

const AssignmentTypeField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);

  return <TextField {...props} value={AssignmentTypeLabels[getValue()]} />;
};

export default AssignmentTypeField;
