import { CROP_SIZES } from 'artifact/const';

const useConvertingTagsHelper = () => {
  const replaceImagesWithArtifactTag = images => {
    images.forEach(image => {
      const artifact = document.createElement('artifact');
      artifact.id = image.getAttribute('data-artifact-id');

      image.parentNode.replaceChild(artifact, image);
    });
  };

  const loadAllThumbnails = async (artifacts, asyncGetThumbnail) => {
    const getThumbnailsMap = async id => {
      const thumbnail = await asyncGetThumbnail(id, CROP_SIZES.SIZE_100);
      return {
        id,
        thumbnail,
      };
    };
    const thumbnails = await Promise.all(Array.from(artifacts).map(artifact => getThumbnailsMap(artifact.id)));

    return thumbnails;
  };

  const replaceArtifactWithImageTag = async (artifacts, getArtifactsDataByIds, asyncGetThumbnail) => {
    const thumbnails = await loadAllThumbnails(artifacts, asyncGetThumbnail);
    const getThumbnail = id => thumbnails.find(map => map.id === id).thumbnail;

    const artifactsData = await getArtifactsDataByIds(Array.from(artifacts).map(artifact => artifact.id));

    artifacts.forEach(artifact => {
      const thumbnailUrl = getThumbnail(artifact.id);
      const artifactData = artifactsData.find(a => a.id === artifact.id) || {
        thumbnails: {},
      };
      const image = document.createElement('img');
      image.alt = artifactData.title || 'not-found';
      image.src = thumbnailUrl;
      image.setAttribute('data-artifact-id', artifactData.id);

      artifact.parentNode.replaceChild(image, artifact);
    });
  };

  return {
    replaceImagesWithArtifactTag,
    replaceArtifactWithImageTag,
  };
};

export default useConvertingTagsHelper;
