/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';
import { getVisibility } from 'shared/uibuilder/helper';
import SendEmailHeader from 'sendEmail/shared/SendEmailHeader';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

import './SendFormPage.scss';

type SendFormPageLayoutProps = {
  buttons?: React.ReactElement[];
  children: React.ReactElement | React.ReactElement[];
};

const SendFormPageLayout = ({ buttons, children }: SendFormPageLayoutProps) => {
  const { data } = useShowContext();
  const taskName = data.getValueBySource('taskName') || {};

  return (
    <Paper className="page-content">
      <SendEmailHeader taskName={taskName} />

      <Message />
      {data ? (
        <div className="list-page-top-block">
          <section className="mail-preview">
            {React.Children.map(children, child => {
              const { isVisible: isVisibleProp } = child.props;

              const isVisible = getVisibility(data, isVisibleProp);

              return isVisible ? child : null;
            })}
          </section>
          {buttons &&
            buttons.map((button: React.ReactElement) => {
              const { isVisible: isVisibleProp } = button.props;

              const isVisible = getVisibility(data, isVisibleProp);

              return isVisible ? button : null;
            })}
        </div>
      ) : null}
    </Paper>
  );
};

SendFormPageLayout.defaultProps = {
  buttons: undefined,
};

export default SendFormPageLayout;
