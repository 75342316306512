/* istanbul ignore file */
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

interface LastTouchCrmFieldProps extends FieldProps {
  statusSource: string;
  colorBorders: DaysBorderForColor;
  skipStates: string[];
  exceedDaysForStale?: number;
}

export const DEFAULT_TIME_UNITS = {
  HOUR: 'hour',
  DAY: 'day',
};

export const BADGE_COLOR_HEX = {
  WHITE: '#fff',
  YELLOW: '#ffe406',
  LIGHT_RED: '#FF7F7F',
  BRIGHT_RED: '#EE4B2B',
  GREEN: '#24A148',
};

export const isStatusAllowed = (status: string, skipStates: string[]) => {
  return !skipStates.includes(status);
};

export const getValueWithUnit = (value: any, defaultUnit: string) => {
  const rawResult = `${value} ${defaultUnit}`;
  return value === 1 ? rawResult : `${rawResult}s`;
};

export interface DaysBorderForColor {
  greenBorder: number;
  yellowBorder: number;
  lightRedBorder: number;
}

export const getBadgeColor = (days: number, borders: DaysBorderForColor) => {
  const { greenBorder, yellowBorder, lightRedBorder } = borders;
  let color;
  if (days <= 0) {
    color = BADGE_COLOR_HEX.WHITE;
  } else if (days > 0 && days < greenBorder) {
    color = BADGE_COLOR_HEX.GREEN;
  } else if (days >= greenBorder && days < yellowBorder) {
    color = BADGE_COLOR_HEX.YELLOW;
  } else if (days >= yellowBorder && days < lightRedBorder) {
    color = BADGE_COLOR_HEX.LIGHT_RED;
  } else {
    color = BADGE_COLOR_HEX.BRIGHT_RED;
  }
  return color;
};

const LastTouchCrmField = ({
  source,
  statusSource,
  colorBorders,
  skipStates,
  exceedDaysForStale,
  ...props
}: LastTouchCrmFieldProps) => {
  const { getValue: getLastModified } = useFieldHelper({ source });
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getHoursBetween, getMomentDateObjAsUtc, getCurrentDateTimeInUtc } = useDateService();

  const status = getStatus();
  const isNeedCalculate = isStatusAllowed(status, skipStates);

  const lastModified = getMomentDateObjAsUtc(getLastModified());
  const currentDateTime = getCurrentDateTimeInUtc();
  const hoursBetween = getHoursBetween(currentDateTime, lastModified, DATE_FORMAT.FULL);
  const roundHoursValue = isNeedCalculate ? Math.round(hoursBetween) : -1;
  const days = isNeedCalculate ? roundHoursValue / 24 : -1;
  const color = getBadgeColor(days, colorBorders);

  let resultValue;

  if (days === -1) {
    resultValue = EMPTY_VALUE_INDICATOR;
  } else if (days >= 0 && days < 1) {
    resultValue = getValueWithUnit(roundHoursValue, DEFAULT_TIME_UNITS.HOUR);
  } else {
    const roundDays = Math.round(days);
    resultValue =
      exceedDaysForStale && roundDays > exceedDaysForStale
        ? 'STALE'
        : getValueWithUnit(roundDays, DEFAULT_TIME_UNITS.DAY);
  }

  return (
    <Badge className="custom-badge ml-2" sx={{ height: '2em', width: 70, backgroundColor: color }} {...props}>
      {resultValue}
    </Badge>
  );
};

export default LastTouchCrmField;
