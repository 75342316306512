/* istanbul ignore file */
import React from 'react';
import SinglePurchaseOrderBreadcrumbs from 'financialAnalytic/purchaseOrder/shared/SinglePurchaseOrderBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdatePurchaseOrderBreadcrumbs = () => {
  return (
    <SinglePurchaseOrderBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SinglePurchaseOrderBreadcrumbs>
  );
};

export default UpdatePurchaseOrderBreadcrumbs;
