/* istanbul ignore file */
import React from 'react';
import Condition, { ConditionProps } from './Condition';
import { node } from 'prop-types';
import { FilterCondition } from '../FilterContext';

export const EQ_CONDITION_TYPE = 'eq';

const EqCondition = ({ children, ...conditionProps }: ConditionProps) => {
  return (
    <Condition {...conditionProps} conditionType={FilterCondition.EQ}>
      {children}
    </Condition>
  );
};

EqCondition.propTypes = {
  children: node.isRequired,
};

export default EqCondition;
