/* istanbul ignore file */
import { Button } from 'uibuilder/button';
import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';

const loaderContainer = (
  <div className="d-flex justify-content-center">
    <SmallLoader />
  </div>
);

export type LoadMoreProps = {
  children?: React.ReactNode;
};

const LoadMore = ({ children }: LoadMoreProps) => {
  const {
    hasMoreData = () => false,
    hasLessData = () => false,
    loadNextPage,
    loadPreviousPage,
    isLoadingNextPage,
    isLoadingPrevPage,
  }: TimelineContextData = useListContext();

  return (
    <div>
      {isLoadingPrevPage && loaderContainer}
      {hasLessData() && (
        <Button onClick={loadPreviousPage} className="d-block mb-2 mx-auto">
          Load Previous Page
        </Button>
      )}
      {children}
      {hasMoreData() && (
        <Button onClick={loadNextPage} className="d-block mx-auto">
          Load Next Page
        </Button>
      )}
      {isLoadingNextPage && loaderContainer}
    </div>
  );
};

export default LoadMore;
