/* istanbul ignore file */
import EntityHeader from 'shared/layout/EntityHeader';
import React from 'react';

const ProjectEntityHeader = ({ entityTitle }: { entityTitle?: string }) => {
  const props = entityTitle ? { entityTitle } : { entityTitleSource: 'name' };

  return <EntityHeader {...props} />;
};

export default ProjectEntityHeader;
