/* istanbul ignore file */
import React, { useContext } from 'react';
import { any, element, func, objectOf } from 'prop-types';

/**
 * T must extends ReactComponent
 */
export interface ApplicationTheme<T extends ReactComponent<any>> {
  [index: string]: T;
}

interface ApplicationConfig {
  defaultPageTitle: string;
  getTimezone: () => string;
  theme: ApplicationTheme<any>;
}

const ApplicationSettingsContext = React.createContext<Partial<ApplicationConfig>>({});

export const ApplicationSettingsProvider = ApplicationSettingsContext.Provider;

const useApplicationSettings = () => useContext(ApplicationSettingsContext);

// @ts-ignore
export const ApplicationSettings = ({ theme, getTimezone, children }) => {
  return (
    <ApplicationSettingsProvider
      value={{
        theme,
        getTimezone,
        defaultPageTitle: 'DaVinci',
      }}
    >
      {children}
    </ApplicationSettingsProvider>
  );
};

ApplicationSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: objectOf(any).isRequired,
  getTimezone: func,
  children: element.isRequired,
};

ApplicationSettings.defaultProps = {
  getTimezone: () => undefined,
};

export default useApplicationSettings;
