/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { EDUCATION_OPTIONS } from 'erp/employee/Education/shared/educationService';

const EducationTypeField = props => {
  return <EnumField {...props} options={EDUCATION_OPTIONS} />;
};

EducationTypeField.propTypes = EnumField.propTypes;

export default EducationTypeField;
