import ShowPageLayout from 'shared/layout/ShowPageLayout';
import React from 'react';
import RoomHeader from 'erp/room/shared/RoomHeader';
import SingleRoomBreadcrumbs from 'erp/room/shared/SingleRoomBreadcrumbs';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import TextField from 'shared/uibuilder/field/TextField';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import AssignEmployeeToWorkplaceButton from 'erp/room/shared/buttons/AssignEmployeeToWorkplaceButton';
import useWorkplaceService, { READ_WORKPLACE_LIST } from 'erp/room/workplace/useWorkplaceService';
import EditRoomButton from 'erp/room/shared/buttons/EditRoomButton';
import DeleteRoomButton from 'erp/room/shared/buttons/DeleteRoomButton';
import DeleteWorkplaceButton from 'erp/room/shared/buttons/DeleteWorkplaceButton';
import UnassignWorkplaceButton from 'erp/room/shared/buttons/UnassignWorkplaceButton';
import CreateWorkplaceButton from 'erp/room/shared/buttons/CreateWorkplaceButton';
import useAuthorization from 'shared/authorization/authorizationService';
import RoomTypeField from 'erp/room/shared/field/RoomTypeField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { ROOM_TYPE } from 'erp/room/useRoomService';

const ViewRoomPageContent = () => {
  const { data } = useShowContext();
  const { searchWorkplacesForRoom } = useWorkplaceService();
  const { isGranted } = useAuthorization();

  const isMeetingRoom = () => data.getValueBySource('type') === ROOM_TYPE.MEETING_ROOM;

  return (
    <ShowPageLayout
      entityHeader={<RoomHeader />}
      breadcrumbs={<SingleRoomBreadcrumbs />}
      headerButtons={[<EditRoomButton />, <DeleteRoomButton />]}
      hasSeparateSections
    >
      <ShowSection title="Room Details">
        <SectionRow>
          <TextField source="roomNumber" label="Room Number" />
          <OfficeField source="office.name" label="Office" />
          <RoomTypeField label="Room Type" source="type" />
          <TextField source="squareMetersArea" label="Area" />
        </SectionRow>
      </ShowSection>

      <List getDataMethod={searchWorkplacesForRoom} defaultSortField="workplaceNumber" defaultSortOrder="ASC">
        <ShowSection
          isVisible={isGranted(READ_WORKPLACE_LIST) && !isMeetingRoom()}
          title="Workplaces"
          actions={[<CreateWorkplaceButton />]}
        >
          <DataGridLayout
            buttons={[<AssignEmployeeToWorkplaceButton />, <UnassignWorkplaceButton />, <DeleteWorkplaceButton />]}
          >
            <TextField label="Workplace" source="displayName" />
            <EmployeeLinkField nameSource="employee.nameEn" source="employee" />
          </DataGridLayout>
        </ShowSection>
      </List>
    </ShowPageLayout>
  );
};

export default ViewRoomPageContent;
