/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { TEMPLATE_TYPES } from 'crm/opportunity/presale/contract/shared/contractRequestService';

const TemplateTypeInput = ({ source, label }: { source: string; label: string }) => {
  return <EnumDropdown options={TEMPLATE_TYPES} source={source} placeholder="Select..." label={label} />;
};

export default TemplateTypeInput;
