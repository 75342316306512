/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const ContactsMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Contacts" icon="group" {...props} />
);

ContactsMenuItem.displayName = 'Contacts';

ContactsMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default ContactsMenuItem;
