import { useKernelApi } from 'api';

export const VIEW_PROJECTS_DASHBOARD = 'COMPANY_VIEW_PROJECTS_DASHBOARD';
export const PROJECTS_DASHBOARD_PATH = '/crm/projects-dashboard';
export const PROJECTS_DASHBOARD_URL = '/projects/dashboard';

export enum PROGRESS_STATUSES {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_PROGRESS_WITH_DELAY = 'IN_PROGRESS_WITH_DELAY',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_DELAY = 'COMPLETED_WITH_DELAY',
}

export enum PROGRESS_FIELDS_KEYS {
  LEAD_GENERATION = 'LEAD_GENERATION',
  LEAD_CONVERTED = 'LEAD_CONVERTED',
  PRESALE = 'PRESALE',
  PROPOSAL = 'PROPOSAL',
  STAFFING = 'STAFFING',
  CONTRACT = 'CONTRACT',
  SIGNING = 'SIGNING',
  STARTING = 'STARTING',
  DISCOVERY = 'DISCOVERY',
  AFTER_DISCOVERY_OPPORTUNITY_CREATED = 'AFTER_DISCOVERY_OPPORTUNITY_CREATED',
  AFTER_DISCOVERY_PRESALE = 'AFTER_DISCOVERY_PRESALE',
  AFTER_DISCOVERY_PROPOSAL = 'AFTER_DISCOVERY_PROPOSAL',
  AFTER_DISCOVERY_STAFFING = 'AFTER_DISCOVERY_STAFFING',
  AFTER_DISCOVERY_CONTRACT = 'AFTER_DISCOVERY_CONTRACT',
  AFTER_DISCOVERY_SIGNING = 'AFTER_DISCOVERY_SIGNING',
  AFTER_DISCOVERY_STARTING = 'AFTER_DISCOVERY_STARTING',
  FIRST_CYCLE = 'FIRST_CYCLE',
  MVP = 'MVP',
}

export const PROGRESS_FIELDS_ORDER = [
  PROGRESS_FIELDS_KEYS.LEAD_GENERATION,
  PROGRESS_FIELDS_KEYS.LEAD_CONVERTED,
  PROGRESS_FIELDS_KEYS.PRESALE,
  PROGRESS_FIELDS_KEYS.PROPOSAL,
  PROGRESS_FIELDS_KEYS.STAFFING,
  PROGRESS_FIELDS_KEYS.CONTRACT,
  PROGRESS_FIELDS_KEYS.SIGNING,
  PROGRESS_FIELDS_KEYS.STARTING,
  PROGRESS_FIELDS_KEYS.DISCOVERY,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_OPPORTUNITY_CREATED,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_PRESALE,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_PROPOSAL,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_STAFFING,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_CONTRACT,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_SIGNING,
  PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_STARTING,
  PROGRESS_FIELDS_KEYS.FIRST_CYCLE,
  PROGRESS_FIELDS_KEYS.MVP,
];

export const PROGRESS_FIELDS_LABELS = {
  [PROGRESS_FIELDS_KEYS.LEAD_GENERATION]: 'Lead generated',
  [PROGRESS_FIELDS_KEYS.LEAD_CONVERTED]: 'Opportunity created',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_OPPORTUNITY_CREATED]: 'Opportunity created',
  [PROGRESS_FIELDS_KEYS.PRESALE]: 'Presale',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_PRESALE]: 'Presale',
  [PROGRESS_FIELDS_KEYS.PROPOSAL]: 'Proposal',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_PROPOSAL]: 'Proposal',
  [PROGRESS_FIELDS_KEYS.STAFFING]: 'Staffing',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_STAFFING]: 'Staffing',
  [PROGRESS_FIELDS_KEYS.CONTRACT]: 'Contract',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_CONTRACT]: 'Contract',
  [PROGRESS_FIELDS_KEYS.SIGNING]: 'Signing',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_SIGNING]: 'Signing',
  [PROGRESS_FIELDS_KEYS.STARTING]: 'Starting',
  [PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_STARTING]: 'Starting',
  [PROGRESS_FIELDS_KEYS.FIRST_CYCLE]: '1st cycle',
  [PROGRESS_FIELDS_KEYS.MVP]: 'MVP',
  [PROGRESS_FIELDS_KEYS.DISCOVERY]: 'Discovery',
};

export const notStaffingPredicate = (stepName: string) => {
  return stepName !== PROGRESS_FIELDS_KEYS.STAFFING && stepName !== PROGRESS_FIELDS_KEYS.AFTER_DISCOVERY_STAFFING;
};

const useProjectsDashboardService = () => {
  const { sendPostRequest } = useKernelApi();

  const search = async (request: any) => {
    const resp = await sendPostRequest(PROJECTS_DASHBOARD_URL, request);
    return resp.json();
  };

  return {
    search,
  };
};

export default useProjectsDashboardService;
