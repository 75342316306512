/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form/index';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import useAssetService from 'financialAnalytic/assets/useAssetService';
import { useAssetUrl } from 'financialAnalytic/assets/AssetRouter';
import UpdateAssetBreadcrumbs from 'financialAnalytic/assets/createupdate/Update/Breadcrumbs';
import AssetForm from 'financialAnalytic/assets/createupdate/AssetForm';

const UpdateAssetPage = () => {
  const { update, getUpdateValidationSchema, getById } = useAssetService();
  const { getSingleEntityUrl } = useAssetUrl();

  const getDataFunc = async (id: string) => {
    const data = await getById(id);

    return {
      category: data.category,
      type: data.type,
      status: data.status,
      office: data.office,
      name: data.name,
      serialNumber: data.serialNumber,
      condition: data.condition,
      location: data.location,
      characteristics: data.characteristics,
      attribution: data.attribution,
      cost: {
        ...data.costInPurchaseCurrency,
        currency: data.costInPurchaseCurrency.currency === 'BYR' ? 'BYN' : data.costInPurchaseCurrency.currency,
      },
      customUsefulLifePeriod: data.usefulLifePeriod,
      purchaseDate: data.purchaseDate,
      validityStartDate: data.validityStartDate,
      validityEndDate: data.validityEndDate,
      warrantyEndDate: data.warrantyEndDate,
      subscriptionDetails: data.subscriptionDetails,
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: ({ inventoryNumber }: any) => getSingleEntityUrl(inventoryNumber),
        message: 'You are awesome! The Asset has been successfully updated.',
      }}
      getValidationSchemaFunc={getUpdateValidationSchema}
      initialData={{
        customUsefulLifePeriod: {
          timeUnit: 'MONTHS',
        },
      }}
    >
      <UpdatePageLayout breadcrumbs={<UpdateAssetBreadcrumbs />} title="Edit: Asset">
        <AssetForm isEditForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateAssetPage;
