/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import { useSkillsUrl } from 'erp/employee/skills/SkillsRouter';
import CreateSkillsBreadcrumbs from './Breadcrumbs';
import BigFormRow from 'shared/layout/form/BigFormRow';
import useEmployeeSkillsService from 'erp/employee/skills/EmployeeSkillsService';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import List from 'shared/uibuilder/list/List';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { ASC } from 'shared/uibuilder/list/baseListHelper';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';

const AddSkillsPage = () => {
  const { create, searchByNameForEmployee, searchExistingSkillsForEmployee } = useEmployeeSkillsService();
  const { getListUrl } = useSkillsUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Skills has been successfully added.',
      }}
    >
      <CreatePageLayout
        title="Skills"
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
        breadcrumbs={<CreateSkillsBreadcrumbs />}
      >
        <BigFormRow>
          <SkillsInput source="skillNames" label="Skills" loadSuggestionsMethod={searchByNameForEmployee} />
        </BigFormRow>
        <div className="pt-3 mb-6" />
        <BigFormRow>
          <List getDataMethod={searchExistingSkillsForEmployee} defaultSortField="name" defaultSortOrder={ASC}>
            <RelationListLayout header="Existing skills">
              <DataGridLayout isSmallTable>
                <TextField source="name" label="Skill name" isSortable />
              </DataGridLayout>
            </RelationListLayout>
          </List>
        </BigFormRow>
      </CreatePageLayout>
    </CreateForm>
  );
};

export default AddSkillsPage;
