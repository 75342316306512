/* istanbul ignore file */
import React from 'react';
import { Button } from '@mui/material';
import 'camunda/monitoring/instance/Show/token/TokenModification.scss';

interface ModificationTokenButtonProps {
  isActive: boolean;
  onClick: () => void;
  label: string;
}

const ModificationTokenButton = ({ isActive, onClick, label }: ModificationTokenButtonProps) => {
  return (
    <Button className={`modification-item ${isActive ? 'active-item' : ''}`} onClick={onClick}>
      {label}
    </Button>
  );
};

export default ModificationTokenButton;
