/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import CreateCandidateFromLinkedInPage from 'erp/candidate/extension/createupdate/Create';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import PageLayout from 'erp/candidate/extension/layout/PageLayout';
import CandidateViewPage from 'erp/candidate/extension/Show';
import useRoute from 'shared/routing/useRoute';
import CandidateContext from 'erp/candidate/CandidateContext';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const EXTENSION_PATH = '/create-candidate-from-extension';

export const useExtensionRoute = () => useRoute({ listRoute: EXTENSION_PATH });

export const useExtensionUrl = () =>
  useEntityUrl({
    baseLink: EXTENSION_PATH,
  });

const ExtensionRouter: Router = () => {
  const { singleEntityRoute } = useExtensionRoute();

  return (
    <ProtectedRouter basePath={EXTENSION_PATH}>
      <Switch>
        <Route path={EXTENSION_PATH} exact>
          <PageLayout>
            <CreateCandidateFromLinkedInPage />
          </PageLayout>
        </Route>

        <Route path={singleEntityRoute} exact>
          <PageLayout>
            <CandidateContext permissionToCheck="READ">
              <CandidateViewPage />
            </CandidateContext>
          </PageLayout>
        </Route>

        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

ExtensionRouter.getRouterPath = () => EXTENSION_PATH;

export default ExtensionRouter;
