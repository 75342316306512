/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const SummaryMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Summary" icon="info-circle" {...props} />
);

SummaryMenuItem.displayName = 'Summary';

SummaryMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default SummaryMenuItem;
