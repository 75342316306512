/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import Button from 'uibuilder/button/Button';
import AddIcon from '@mui/icons-material/Add';
import { UPDATE_PROCESS_VARIABLES_LIST } from 'camunda/monitoring/instance/Show/variables/processVariablesService';
import useAuthorization from 'shared/authorization/authorizationService';
import AddProcessVariableModalForm from 'camunda/monitoring/instance/Show/variables/shared/AddProcessVariableModalForm';

const AddProcessVariableButton = () => {
  const { isGranted } = useAuthorization();

  const isButtonHidden = !isGranted(UPDATE_PROCESS_VARIABLES_LIST);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  return isButtonHidden ? null : (
    <>
      <Button onClick={openCloseIssueForm}>
        <AddIcon />
        Add Variable
      </Button>
      {isFormOpen && <AddProcessVariableModalForm closeModal={closeModal} />}
    </>
  );
};

export default AddProcessVariableButton;
