/* istanbul ignore file */
import React from 'react';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_EMPLOYEE } from 'erp/employee/employeeService';

const CreateEmployeeButton = () => {
  const { getCreateUrl } = useEmployeeUrl();

  return (
    <CreateButton
      url={getCreateUrl()}
      label="Create Employee"
      key="create-employee"
      permissionToCheck={CREATE_EMPLOYEE}
    />
  );
};

export default CreateEmployeeButton;
