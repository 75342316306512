/* istanbul ignore file */
import React from 'react';
import { OPPORTUNITY_STAGES_ALIASES } from 'crm/opportunity/shared/opportunityService';
import { ONE_STOP_PATH } from 'oneStop/OneStopRouterHelper';
import { Link } from 'react-router-dom';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const getGuidanceForSuccess = (presaleLink: string) => ({
  [OPPORTUNITY_STAGES_ALIASES.NEW]: (
    <>
      <Link to={presaleLink} target="_blank">
        Fill in presale request
      </Link>{' '}
      with all collected information
    </>
  ),
  [OPPORTUNITY_STAGES_ALIASES.PRESALE]: (
    <>
      <b>Presale coordinator</b>
      <ul>
        <li>Analyze opportunities and provided information.</li>
        <li>
          If information is lacking, determine required details and coordinate with the opportunity owner for necessary
          information.
        </li>
        <li>Request resources for proposal preparation if required.</li>
        <li>Prepare a proposal.</li>
      </ul>
      <b>Opportunity owner</b>
      <ul>
        <li>Send proposal to the customer.</li>
      </ul>
    </>
  ),
  [OPPORTUNITY_STAGES_ALIASES.PROPOSAL]: (
    <>
      <ul>
        <li>Negotiation</li>
        <li>Make amendments to the proposal if required</li>
        <li>Receive verbal confirmation from the customer</li>
        <li>Coordinate contract form with the customer (Syberry template or customer template)</li>
      </ul>
      <b>Syberry template:</b>
      <ul>
        <li>Send New customer form to the customer</li>
        <li>Receive filled-out form from the customer</li>
        <li>Send contract preparation request</li>
      </ul>
      <b>Customer template:</b>
      <ul>
        <li>Recive customer contract template</li>
        <li>Send contract preparation request</li>
      </ul>
    </>
  ),
  [OPPORTUNITY_STAGES_ALIASES.CONTRACT]: (
    <ul>
      <li>Contract preparation</li>
      <li>Contract review</li>
      <li>Send contract to the customer</li>
    </ul>
  ),
  [OPPORTUNITY_STAGES_ALIASES.SIGNING]: (
    <ul>
      <li>Review contract by the customer</li>
      <li>Sign contract by the customer</li>
      <li>Send signed contract</li>
    </ul>
  ),
  [OPPORTUNITY_STAGES_ALIASES.STARTING]: (
    <Link to={`${ONE_STOP_PATH}/PM03`} target="_blank">
      Send project start request
    </Link>
  ),
});

const GuidanceForSuccessField = ({ activeStep }: { activeStep: string }) => {
  const id = useOpportunityId();
  const presaleLink = useOpportunityUrl().getPresaleTimelineUrl(id);
  const GUIDANCE_FOR_SUCCESS = getGuidanceForSuccess(presaleLink);

  return GUIDANCE_FOR_SUCCESS[activeStep] ? (
    <div>
      <h4>Guidance for Success</h4>
      <div>{GUIDANCE_FOR_SUCCESS[activeStep]}</div>
    </div>
  ) : (
    <div />
  );
};

export default GuidanceForSuccessField;
