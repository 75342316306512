// libs
import React, { useEffect, useState } from 'react';
// components
import FileUploadLayout from 'artifact/shared/input/layout/preview/FileUploadLayout';
import FileUploader from 'artifact/shared/input/ArtifactUploaderInput/FileUploader';
import FilePreviewListLayout from 'artifact/shared/input/layout/list/FilePreviewListLayout';
// services
import useArtifactInputHelper from './artifactsInputHelper';
import useArtifactValidationService from './artifactValidationService';
import { bool } from 'prop-types';

const concatErrors = (errors1, errors2) => {
  const toArr = errors => errors || [];
  return [...toArr(errors1), ...toArr(errors2)].filter(v => v);
};

const ArtifactUploaderInput = ({ canAddFromClipboard, disabled, uploadFile, ...props }) => {
  const [errorMsg, setErrorMsg] = useState(null);

  const {
    getLabel,
    getValue,
    getAcceptExtensions,
    addArtifactToContext,
    initializeValue,
    removeArtifactFromContext,
    getTooltip,
    getHelpText,
    getSource,
    getMaxElements,
    getArtifactType,
    getMaxMb,
    getIsRequired,
    getErrorMessages,
    getClearValidationCallback,
    isVisible,
  } = useArtifactInputHelper(props);

  const clearValidation = getClearValidationCallback();

  const artifactsIdsList = getValue() || [];

  /*
   * On create page input should have [] value by default.
   */
  useEffect(() => {
    if (!getValue()) {
      initializeValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { validate } = useArtifactValidationService({
    previewsCount: artifactsIdsList.length,
    source: getSource(),
    maxMb: getMaxMb(),
    fileTypes: getAcceptExtensions(),
  });
  const isAllowedToAddMoreFiles =
    (!getMaxElements() && getMaxElements() !== 0) || getMaxElements() - artifactsIdsList.length > 0;

  return (
    <FileUploadLayout
      label={getLabel()}
      error={concatErrors([errorMsg], getErrorMessages())}
      isRequired={getIsRequired()}
      tooltip={getTooltip()}
      source={getSource()}
      helpText={getHelpText()}
      disabled={disabled}
      isVisible={isVisible()}
      previews={
        <FilePreviewListLayout
          artifactsIdsList={artifactsIdsList}
          onRemove={id => removeArtifactFromContext(id)}
          disabled={disabled}
        />
      }
      fileUploder={
        isAllowedToAddMoreFiles ? (
          <FileUploader
            disabled={disabled}
            canAddFromClipboard={canAddFromClipboard}
            artifactTypeId={getArtifactType()}
            uploadCallback={(id, file) => {
              addArtifactToContext(id, file);
              clearValidation();
            }}
            acceptExtensions={getAcceptExtensions()}
            validateFunc={validate}
            validationCallback={error => {
              setErrorMsg(error);
            }}
            source={getSource()}
            uploadFile={uploadFile}
          />
        ) : null
      }
    />
  );
};

ArtifactUploaderInput.propTypes = {
  canAddFromClipboard: bool,
  disabled: bool,
};

ArtifactUploaderInput.defaultProps = {
  canAddFromClipboard: false,
  disabled: false,
};

export default ArtifactUploaderInput;
