/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { EMPLOYEE_WORKPLACE_LOCATION } from 'erp/employee/employeeService';

const EmployeeWorkplaceDropdown = props => (
  <Dropdown
    placeholder="Choose Employee Workplace Location"
    options={Object.entries(EMPLOYEE_WORKPLACE_LOCATION).map(([value, label]) => ({ value, label }))}
    label="Workplace"
    {...props}
  />
);

export default EmployeeWorkplaceDropdown;
