import React from 'react';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import CreateButton from 'uibuilder/button/CreateButton';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import EditButton from 'uibuilder/button/EditButton';
import { EmailField, TextField } from 'shared/uibuilder/field';
import List from 'shared/uibuilder/list/List';
import useTestingCrudService from 'integration-tests/testingCrudService';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';

const filters = (
  <Filters>
    <FiltersLayout>
      <DropDownFilter source="status" label="Status">
        <Dropdown
          placeholder="Choose Employee Working Status"
          options={Object.entries({
            ACTIVE: 'Active',
            IN_DISMISSAL: 'In Dismissal',
            DISMISSED: 'Dismissed',
            RELOCATION: 'Relocation',
          }).map(entry => {
            const [value, label] = entry;
            return {
              value,
              label,
            };
          })}
          label="Employee Working Status"
        />
      </DropDownFilter>
    </FiltersLayout>
  </Filters>
);

const IntegrationListPage = () => {
  const { search } = useTestingCrudService();

  return (
    <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="id" pageSize={10}>
      <ListPageLayout
        isSearchFormEnabled
        searchFormLabel="Entity ID"
        header={<ListHeader label="Entities" icon="fa-address-book" />}
        filter={filters}
        actions={[<CreateButton url="#" label="Create Entity" key="create-entity" />]}
        search={<SearchInput label="Entity ID" placeholder="Search..." />}
      >
        <DataGridLayout
          buttons={[<EditButton source="id" permissionToCheck={null} isVisible={data => data.canEdit} />]}
        >
          <TextField source="id" label="ID" isSortable />
          <TextField source="name" />
          <TextField source="username" />
          <EmailField source="email" />
          <TextField source="address.city" label="City" />
          <TextField source="address.street" label="Street" />
          <TextField source="address.zipcode" label="Zipcode" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default IntegrationListPage;
