/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useHandbookUrl } from 'handbook/HandbookRouter';
import { SearchResultField } from 'shared/uibuilder/field/index';
import { SearchResultsLayoutProps } from 'shared/uibuilder/list/search/SearchWithKeyboardNavigation';
import { ListItem, useListContext } from 'shared/uibuilder/list/ListContext';
import Typography from 'uibuilder/Typography';

interface HandbookSearchResultItemProps extends SearchResultsLayoutProps {
  row: ListItem;
  index: number;
}

const HandbookSearchResultItem = ({
  row,
  index,
  onKeyUp,
  searchResultsRefs,
  focusedResultIndex,
  setFocusToResults,
}: HandbookSearchResultItemProps) => {
  const { searchBy = () => {} } = useListContext();
  const { getSingleEntityUrl } = useHandbookUrl();
  const path = row.pagePathname.startsWith('/') ? row.pagePathname.slice(1) : row.pagePathname;
  const numberOfAdditionalResults =
    (row.searchResults?.numberOfMatches || 0) - (row.searchResults?.firstResult?.numberOfMatches || 0);

  const clearSearch = () => {
    searchBy('');
  };

  return (
    <Link
      key={row.pagePathname}
      className={classnames('handbook-search-item', { focus: focusedResultIndex === index })}
      to={getSingleEntityUrl(path)}
      onKeyUp={event => {
        onKeyUp(event, index);
        clearSearch();
      }}
      onClick={clearSearch}
      onFocus={setFocusToResults}
      innerRef={link => {
        if (link) {
          // eslint-disable-next-line no-param-reassign
          searchResultsRefs[index] = link;
        }
      }}
    >
      <Typography variant="h2" className="handbook-search-title c-title">
        <SearchResultField source="pageTitle" />
      </Typography>
      {row.searchResults?.firstResult?.data && (
        <>
          <SearchResultField strict source="searchResults" value={row.searchResults?.firstResult?.data} />
          {numberOfAdditionalResults ? (
            <i>{` (+ ${numberOfAdditionalResults} match${numberOfAdditionalResults > 1 ? 'es' : ''})`}</i>
          ) : null}
        </>
      )}
    </Link>
  );
};

export default HandbookSearchResultItem;
