const convertArrayToObject = (array: string[]) => {
  const initialValue = {};

  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item]: true,
    }),
    initialValue,
  );
};

export default convertArrayToObject;
