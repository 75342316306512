/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import usePerformanceReportService from '../usePerformanceReportService';

const PerformanceHeatmapLabels = ({ labels }: { labels: Array<Dictionary<string>> }) => {
  const { getTimelineUrl } = useEmployeeUrl();
  const { isFakeId } = usePerformanceReportService();

  return (
    <Box
      component="ul"
      className="performance-heatmap__labels"
      sx={{
        gridTemplateRows: `repeat(${labels.length}, var(--square-size))`,
      }}
    >
      {labels.map(({ userId, userName }) => (
        <li key={userId} title={userName}>
          {!isFakeId(userId) ? (
            <a href={getTimelineUrl(userId)} target="_blank" rel="noopener noreferrer">
              {userName}
            </a>
          ) : (
            <p>{userName}</p>
          )}
        </li>
      ))}
    </Box>
  );
};

export default PerformanceHeatmapLabels;
