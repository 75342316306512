import { useBusinessEngineApi } from 'api/index';
import INSTANT_FEEDBACK_VALIDATION from './createupdate/InstantFeedbackValidation';

export const RESOURCE_URL = '/instant-feedbacks';

const useInstantFeedbackService = () => {
  const { sendPostRequest } = useBusinessEngineApi();

  const create = async (data: any) => {
    if (
      !data.feedbackGrades ||
      !Object.entries(data.feedbackGrades as Dictionary<any>).filter(([key, value]) => value && !!value.scale).length
    ) {
      // eslint-disable-next-line no-throw-literal
      throw 'At least one criterion should be selected';
    }
    const requestData = {
      ...data,
      feedbackGrades: data.feedbackGrades
        ? Object.entries(data.feedbackGrades as Dictionary<any>).reduce((feedbackGrades, [key, value]) => {
            const result: Dictionary<any> = { ...feedbackGrades };

            if (value && value.scale) {
              result[key] = value;
            }

            return result;
          }, {})
        : {},
    };
    const response = await sendPostRequest(`/employees/${requestData.employeeId}${RESOURCE_URL}`, requestData);
    return response.json();
  };

  const getValidationSchema = () => Promise.resolve(INSTANT_FEEDBACK_VALIDATION);

  return {
    getValidationSchema,
    create,
  };
};

export default useInstantFeedbackService;
