import React, { forwardRef } from 'react';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useAccountingCycle, {
  ACCOUNTING_CYCLE_STATUS,
  AccountingCycleEventType,
  CLOSE_ACCOUNTING_CYCLE,
} from '../../useAccountingCycle';
import { useAccountingCycleId } from '../../AccountingCycleRouter';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Button, { ButtonProps } from 'uibuilder/button/Button';
import Tooltip from 'uibuilder/Tooltip';

const CloseAccountingCycleButton = () => {
  const id = useAccountingCycleId();
  const { closeAccountingCycle } = useAccountingCycle();
  const { getValue } = useFieldHelper({ source: 'status' });
  const { getValue: getEvents } = useFieldHelper<AccountingCycleEventType[]>({ source: 'events' });

  const events = getEvents();
  const status = getValue();

  const isDisabledClose = events.length < 4;

  const { setData = () => {} } = useShowContext();

  if (status === ACCOUNTING_CYCLE_STATUS.CLOSE) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      modalConfirmButtonText="Close"
      displayMessage="Do you really want to close this Accounting Cycle?"
      submitMethod={() => closeAccountingCycle(id)}
      afterSubmit={{
        successMessage: 'The Accounting Cycle has been successfully closed.',
        errorMessage: "Can't approve Accounting Cycle.",
        execute: (data: any) => {
          setData(data);
        },
      }}
      permissionToCheck={CLOSE_ACCOUNTING_CYCLE}
      variant="outlined"
      disabled={isDisabledClose}
      buttonTag={forwardRef(({ children, ...props }: ButtonProps, ref) => (
        <Tooltip title={isDisabledClose && 'To close the accounting cycle, all events must be completed.'}>
          <span>
            <Button {...props} ref={ref}>
              {children}
            </Button>
          </span>
        </Tooltip>
      ))}
      confirmBtnProps={{
        disabled: false,
      }}
    >
      Close Accounting Cycle
    </ButtonWithConfirmation>
  );
};

export default CloseAccountingCycleButton;
