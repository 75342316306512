import React from 'react';
import {
  FEEDBACK_RELATIONSHIP,
  FEEDBACK_RELATIONSHIP_ALIAS,
} from 'erp/employee/Timeline/feedback/form/FeedbackRelationshipDropdown';
import { DateField } from 'shared/uibuilder/field';
import TimelineRecordFooter from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordFooter';
import FeedbackInappropriateReason from 'erp/employee/Timeline/feedback/shared/button/FeedbackInappropriateReason';
import { shape } from 'prop-types';
import { READ_EMPLOYEE_TIMELINE } from '../../../../employeeService';
import useAuthorization from '../../../../../../shared/authorization/authorizationService';
import { FEEDBACKS_I_SENT } from '../../../../../../myActivity/feedback/useSentFeedbacksService';

const FeedbackFooter = ({ entity }) => {
  const relatedPeriodStart = entity.getValueBySource('relatedPeriodStart');
  const relatedPeriodEnd = entity.getValueBySource('relatedPeriodEnd');
  const relationship = entity.getValueBySource('relationship');
  const relatedClient = entity.getValueBySource('relatedClient');
  const relatedProject = entity.getValueBySource('relatedProject');
  const isSharedWithEmployee = entity.getValueBySource('isSharedWithEmployee');
  const { isGranted } = useAuthorization();

  let additionalInfo;

  if (
    !relatedPeriodStart &&
    !relatedPeriodEnd &&
    (!relationship || relationship === FEEDBACK_RELATIONSHIP_ALIAS.NO_RELATION) &&
    !relatedClient &&
    !relatedProject
  ) {
    additionalInfo = null;
  } else {
    additionalInfo = (
      <div className="timeline__additional">
        {relatedPeriodStart && relatedPeriodEnd && (
          <div className="d-flex">
            <span>Related period:&nbsp;</span>
            <DateField value={relatedPeriodStart} />
            &nbsp;-&nbsp;
            <DateField value={relatedPeriodEnd} />
          </div>
        )}
        {relationship && relationship !== FEEDBACK_RELATIONSHIP_ALIAS.NO_RELATION && (
          <div>
            <span>Relationship: </span>
            {FEEDBACK_RELATIONSHIP[relationship]}
          </div>
        )}
        {relatedClient && (
          <div>
            <span>Related client: </span>
            {relatedClient}
          </div>
        )}
        {relatedProject && (
          <div>
            <span>Related project: </span>
            {relatedProject}
          </div>
        )}
        {isGranted(READ_EMPLOYEE_TIMELINE) || isGranted(FEEDBACKS_I_SENT) ? (
          <div>
            <span>Feedback is shared with employee: </span>
            {isSharedWithEmployee ? 'Yes' : 'No'}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <>
      {additionalInfo}
      <div className="d-flex mt-3 justify-content-between align-items-center feedback-timeline-footer">
        <TimelineRecordFooter isUpdateAuthorHidden noTopIndent entity={entity} />
        <FeedbackInappropriateReason entity={entity} />
      </div>
    </>
  );
};

FeedbackFooter.propTypes = {
  entity: shape().isRequired,
};

export default FeedbackFooter;
