import React, { useState } from 'react';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import WorkplaceForm from 'erp/room/workplace/WorkplaceForm';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useWorkplaceService, { CREATE_WORKPLACE } from 'erp/room/workplace/useWorkplaceService';
import { useRoomId } from 'erp/room/RoomRouter';
import useAuthorization from 'shared/authorization/authorizationService';

const CreateWorkplaceButton = () => {
  const { reloadData = () => {} } = useListContext();
  const { create, getValidationSchema } = useWorkplaceService();
  const { isGranted } = useAuthorization();
  const id = useRoomId();

  const submit = async ({ ...data }) => {
    return create({
      roomId: id,
      ...data,
    });
  };

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      {isGranted(CREATE_WORKPLACE) ? (
        <Button onClick={openModal} variant="text" startIcon={<i className="fa fa-plus" />}>
          Create workplace
        </Button>
      ) : (
        <></>
      )}

      <ModalWindow backdrop="static" isOpen={modalOpen} onToggle={closeModal} title="Create workplace" hasCancelButton>
        <ModalForm
          closeModal={closeModal}
          modalOpen={modalOpen}
          submitFormFunc={submit}
          afterSubmit={{
            execute: () => {
              reloadData();
            },
            message: 'You are awesome! Workplace has been created successfully.',
          }}
          getValidationSchemaFunc={getValidationSchema}
        >
          <WorkplaceForm />
        </ModalForm>
      </ModalWindow>
    </>
  );
};

export default CreateWorkplaceButton;
