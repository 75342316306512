/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import JournalEntryBreadcrumbs from 'financialAnalytic/journalEntry/shared/JournalEntryBreadcrumbs';

const UpdateLedgerBreadcrumbs = () => {
  return (
    <JournalEntryBreadcrumbs>
      <UpdateBreadcrumbItem entity="Journal Entry" />
    </JournalEntryBreadcrumbs>
  );
};

export default UpdateLedgerBreadcrumbs;
