/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import VacancyWysiwygInput from 'erp/recruitment/vacancy/shared/input/VacancyWysiwygInput';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import useVacancyPublicationService, {
  VACANCY_PUBLICATION_PLATFORMS,
  VACANCY_PUBLICATION_STATUSES,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import { useSearchParameter } from 'shared/routing/helper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import FormRow from 'shared/layout/form/FormRow';
import Loading from 'shared/uibuilder/Loading';
import { TextInput } from 'shared/uibuilder/form/input';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import AdditionalVacancyQuestionList from './AdditionalVacancyQuestionList';
import BigFormRow from 'shared/layout/form/BigFormRow';
import AdditionalVacancyQuestion from './AdditionalVacancyQuestion';
import { COUNTRIES } from 'shared/uibuilder/form/input/CountryDropdown';
import useFeatureToggle, { Features } from 'featuretoggle';
import CountyOfResidencesEnumDropdown from 'shared/uibuilder/form/input/CountyOfResidencesEnumDropdown';
import MinimalAndBetterEnglishLevelEnumDropdown from 'erp/recruitment/vacancy/publication/shared/input/MinimalAndBetterEnglishLevelEnumDropdown';

interface VacancyPublicationFormProps {
  isCreateForm?: boolean;
}

export const COUNTRY_OF_RESIDENCE = COUNTRIES.filter(country => country.name !== 'Unknown').reduce(
  (prev, curr) => ({ ...prev, [curr.name.toUpperCase()]: curr.name }),
  {},
);

export const ALL_DROP_DOWN_COUNTRIES = {
  ALL: 'All',
  EU: 'EU',
  ...COUNTRY_OF_RESIDENCE,
};

export const ENGLISH_LEVEL_OPTIONS = {
  BEGINNER: 'Beginner',
  ELEMENTARY: 'Elementary',
  INTERMEDIATE: 'Intermediate',
  'UPPER INTERMEDIATE': 'Upper Intermediate',
  ADVANCED: 'Advanced',
  PROFICIENCY: 'Proficiency',
};

const VacancyPublicationForm = ({ isCreateForm = false }: VacancyPublicationFormProps) => {
  const { onChangeCallback } = useFormContext();
  const vacancyAlias = useSearchParameter('vacancyAlias');
  const { constructVacancyPublicationDescription } = useVacancyPublicationService();
  const [loading, setLoading] = useState(true);
  const { isFeatureEnabled } = useFeatureToggle();

  useEffect(() => {
    (async () => {
      if (onChangeCallback && vacancyAlias && isCreateForm) {
        const vacancyDescription = await constructVacancyPublicationDescription(vacancyAlias);
        onChangeCallback({ ...vacancyDescription }, true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, [vacancyAlias]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormSection title="Vacancy Publication">
        <FormRow>
          <EnumDropdown
            source="type"
            label="Publication Platform"
            options={VACANCY_PUBLICATION_PLATFORMS}
            placeholder="Choose platform"
          />
          <EnumDropdown
            source="status"
            label="Status"
            options={VACANCY_PUBLICATION_STATUSES}
            placeholder="Choose Status"
          />
        </FormRow>
        <FormRow>
          <MinimalAndBetterEnglishLevelEnumDropdown
            source="englishLevels"
            label="Relevant English Levels"
            options={ENGLISH_LEVEL_OPTIONS}
            multiple
            placeholder="Choose minimal relevant english level"
            isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
            helpText="Choose minimal relevant english level and higher levels will be selected automatically.
            To remove the level - start from the lowest"
          />
          <CountyOfResidencesEnumDropdown
            source="countryOfResidences"
            label="Relevant Country Of Residence"
            options={ALL_DROP_DOWN_COUNTRIES}
            multiple
            placeholder="Choose relevant country"
            isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
          />
        </FormRow>
        <BigFormRow>
          <AdditionalVacancyQuestionList
            source="formFields"
            inputTemplate={AdditionalVacancyQuestion}
            addText="Add question"
            isVisible={isFeatureEnabled(Features.VACANCY_PUBLICATION)}
          />
        </BigFormRow>
        {isCreateForm && loading ? (
          <Loading />
        ) : (
          <>
            <MediumFormRow>
              <TextInput source="name" />
            </MediumFormRow>
            <VacancyWysiwygInput source="description" label="Public Description" />
          </>
        )}
      </FormSection>
    </>
  );
};

export default VacancyPublicationForm;
