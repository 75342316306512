/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import usePresaleOpportunityService from 'crm/opportunity/presale/request/shared/presaleRequestService';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import UpdatePresaleLayout from 'crm/opportunity/presale/request/shared/layout/UpdatePresaleLayout';

const UpdatePresaleRequestForm = ({ getDataFunc, onCancel, ...props }: any) => {
  const opportunityId = useOpportunityId();
  const { updatePresaleRequest, getValidationSchema } = usePresaleOpportunityService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={(id, values) => updatePresaleRequest(opportunityId, id, values)}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdatePresaleLayout onCancel={onCancel} />
    </UpdateForm>
  );
};

export default UpdatePresaleRequestForm;
