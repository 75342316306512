/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import TemplateTypeInput from 'crm/opportunity/presale/contract/shared/input/TemplateTypeInput';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import BooleanRadiosGroup, { BOOLEAN_RADIUS_GROUP_OPTIONS } from 'shared/uibuilder/form/input/BooleanRadiosGroup';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';

const ContractRequestForm = () => {
  const { data } = useFormContext();
  const templateType = data?.templateType;
  const attachmentLabel =
    templateType === 'SYBERRY' ? 'Attach the completed customer form' : 'Attach customer contract template';
  return (
    <FormSection>
      <FormRow>
        <TemplateTypeInput label="Contract Template Type" source="templateType" />
      </FormRow>
      <BigFormRow>
        <WysiwygInput source="descriptionText" label="Description" />
        <ClearAfterUnmountWrapper isVisible={formData => formData?.templateType === 'SYBERRY'}>
          <BooleanRadiosGroup
            options={BOOLEAN_RADIUS_GROUP_OPTIONS}
            source="isCustomerDetailsUnchanged"
            label="The new customer form provided earlier, and  the customer’s details have not changed"
          />
        </ClearAfterUnmountWrapper>
        <CrmAttachmentUploaderInput label={attachmentLabel} source="attachmentIds" maxElements={15} />
      </BigFormRow>
    </FormSection>
  );
};

export default ContractRequestForm;
