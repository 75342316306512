import { HTTP } from 'shared/api/const';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import { InputData } from './useListCrud';

type resetParametersProps = {
  loadData: (loadParams: LoadingParams, isNeedToUpdateLoading?: boolean) => Promise<any>;
  resetParamsToDefault?: () => void;
  loading: boolean;
  setLoading: (state: boolean) => void;
  data: InputData;
  handleLoad?: () => Promise<void>;
  handleSetData: (newData: InputData) => void;
};

// To prevent timelines and lists from crashing when using incorrect URL parameters
const resetParameters = ({ loadData, resetParamsToDefault, ...props }: resetParametersProps) => {
  const resetParams = () => {
    if (resetParamsToDefault) {
      resetParamsToDefault();
    }
  };

  const loadDataMethod = async (loadParams: LoadingParams, isNeedToUpdateLoading = true) => {
    let response;

    try {
      response = await loadData(loadParams, isNeedToUpdateLoading);
    } catch (error) {
      const isNeedToResetParams = [HTTP.INTERNAL_SERVER_ERROR, HTTP.BAD_REQUEST].includes((error as Response).status);

      if (isNeedToResetParams) {
        resetParams();
      }

      response = {};
    }

    return response;
  };

  return {
    loadData: loadDataMethod,
    ...props,
  };
};

export default resetParameters;
