/* istanbul ignore file */
import React from 'react';
import SingleContactsBreadcrumbs from 'crm/contact/shared/SingleContactsBreadcrumbs';
import { NotesBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const ContactTimelineBreadcrumbs = () => {
  return (
    <SingleContactsBreadcrumbs>
      <NotesBreadcrumbItem />
    </SingleContactsBreadcrumbs>
  );
};

export default ContactTimelineBreadcrumbs;
