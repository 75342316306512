/* istanbul ignore file */
import React from 'react';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import calculateAge from 'shared/ageUtils';

const CandidateAgeInput = props => {
  const { getValue, getLabel } = useInputHelper(props);

  return <TextInput value={calculateAge(getValue()) || 0} label={getLabel()} disabled />;
};

export default CandidateAgeInput;
