/* istanbul ignore file */
import React from 'react';
import useGeneratedPayrollsService, {
  READ_PAYROLL_REPORTS,
} from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';

// TODO: Remove after migration
const RunMigrationButton = props => {
  const { runMigration } = useGeneratedPayrollsService();

  return (
    <ButtonWithConfirmation
      submitMethod={() => runMigration()}
      displayMessage="Are you sure you want to tun migration? This action cannot be undone."
      permissionToCheck={READ_PAYROLL_REPORTS}
      afterSubmit={{
        successMessage: 'The migration was run successful',
        failureMessage: "Can't run migration.",
      }}
      {...props}
      color="error"
      className="mr-2"
    >
      Run Migration
    </ButtonWithConfirmation>
  );
};

export default RunMigrationButton;
