/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useCostAccountingService, { MappingMetricsType } from 'erp/costaccounting/shared/costAccountingService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import useMetricsHelper from 'erp/costaccounting/shared/metricsHelper';
import Loading from 'shared/uibuilder/Loading';
import MappingMetrics from 'erp/costaccounting/dashboard/MappingMetrics';
import MappingMetricsTypeInput from 'erp/costaccounting/dashboard/MappingMetricsTypeInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import MediumFormRow from 'shared/layout/form/MediumFormRow';

interface MappingMetricsProps {
  fromDateSource: string;
  toDateSource: string;
}

const MappingMetricsWrapper = ({ fromDateSource, toDateSource }: MappingMetricsProps) => {
  const { getMappingMetrics } = useCostAccountingService();
  const { isDateValid } = useMetricsHelper();
  const { getValue: getFromDate } = useInputHelper({ source: fromDateSource });
  const { getValue: getToDate } = useInputHelper({ source: toDateSource });
  const fromDate = getFromDate() as string;
  const toDate = getToDate() as string;

  const [metricsVisible, setMetricsVisible] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [metricsData, setMetricsData] = useState({
    mappedItemsCount: 0,
    totalItemsCount: 0,
    mappedHoursSum: 0,
    totalHoursSum: 0,
  });

  useEffect(() => {
    const bothDatesValid = isDateValid(fromDate) && isDateValid(toDate);
    setMetricsVisible(bothDatesValid);
    if (bothDatesValid) {
      setMetricsLoading(true);
      getMappingMetrics({ fromDate, toDate }).then(response => {
        setMetricsData(response);
        setMetricsLoading(false);
      });
    }
  }, [fromDate, toDate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!metricsVisible) {
    return (
      <div>
        <BigSectionRow>
          <span>Please ensure that you fill out the form above accurately in order to view the mapping metrics.</span>
        </BigSectionRow>
      </div>
    );
  }

  if (metricsLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <MediumFormRow>
        <MappingMetricsTypeInput source="metricsType" />
      </MediumFormRow>
      <MediumFormRow>
        <ClearAfterUnmountWrapper isVisible={data => data.metricsType === MappingMetricsType.METRICS_BY_RECORDS}>
          <MappingMetrics
            current={metricsData.mappedItemsCount}
            expected={metricsData.totalItemsCount}
            measurementName="Records"
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={data => data.metricsType === MappingMetricsType.METRICS_BY_HOURS}>
          <MappingMetrics
            current={metricsData.mappedHoursSum}
            expected={metricsData.totalHoursSum}
            measurementName="Hours"
          />
        </ClearAfterUnmountWrapper>
      </MediumFormRow>
    </div>
  );
};

export default MappingMetricsWrapper;
