/* istanbul ignore file */
import React from 'react';
import Typography from 'uibuilder/Typography';
import { useParams } from 'react-router-dom';
import { DOWNLOAD_ARTIFACT } from 'artifact/artifactService';
import ShowPageLayoutWithoutMenu from 'shared/layout/ShowPageLayoutWithoutMenu';
import useAuthorization from 'shared/authorization/authorizationService';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';

const ArtifactHeader = () => {
  return (
    <Typography variant="h1" className="c-title">
      Artifact
    </Typography>
  );
};

const ShowArtifactPage = () => {
  const { id } = useParams();
  const { isGranted } = useAuthorization();
  const isDownloadAvailable = isGranted(DOWNLOAD_ARTIFACT);

  return (
    <ShowPageLayoutWithoutMenu entityHeader={<ArtifactHeader />}>
      <AttachmentsListField label="" value={[id]} isDownloadAvailable={isDownloadAvailable} />
    </ShowPageLayoutWithoutMenu>
  );
};

export default ShowArtifactPage;
