import React from 'react';
import { ROOM_TYPE_OPTIONS } from 'erp/room/useRoomService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';

const RoomTypeDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={ROOM_TYPE_OPTIONS} placeholder="Select type" {...props} />;
};

export default RoomTypeDropdown;
