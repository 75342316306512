import React, { useState } from 'react';
import { Button, IconButton } from 'uibuilder/button';
import { FEEDBACK_ABOUT_EMPLOYEE } from 'oneStop/OneStopRouterHelper';
import InstantFeedbackModalForm from './InstantFeedbackForm';
import FeedbackIcon from '@mui/icons-material/ChatOutlined';

const InstantFeedbackButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openFeedbackForm = () => setIsFormOpen(true);

  const closeFeedbackForm = () => setIsFormOpen(false);

  return (
    <>
      <Button
        className="instance-feedback-button"
        onClick={openFeedbackForm}
        sx={{ display: { xs: 'none', md: 'inline-flex' }, ml: '20px' }}
      >
        <span>{FEEDBACK_ABOUT_EMPLOYEE}</span>
      </Button>
      <IconButton className="instance-feedback-button" onClick={openFeedbackForm} sx={{ display: { md: 'none' } }}>
        <FeedbackIcon className="feedback-icon" />
      </IconButton>
      {isFormOpen && <InstantFeedbackModalForm closeFeedbackForm={closeFeedbackForm} />}
    </>
  );
};

export default InstantFeedbackButton;
