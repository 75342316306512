import { useBusinessEngineApi } from 'api';
import CLOSE_PROCESS_ISSUE_VALIDATION from 'camunda/monitoring/instance/Show/issues/shared/CloseProcessIssueValidation';

const UNUSED_TICKETS_RESOURCE_URL = '/issues/close';

const useProcessIssueService = () => {
  const { sendPutRequest } = useBusinessEngineApi();

  const closeTicket = async (request: any) => {
    const response = await sendPutRequest(UNUSED_TICKETS_RESOURCE_URL, request);
    return response;
  };

  const getValidationSchema = () => Promise.resolve(CLOSE_PROCESS_ISSUE_VALIDATION);

  return {
    closeTicket,
    getValidationSchema,
  };
};

export default useProcessIssueService;
