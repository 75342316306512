/* istanbul ignore file */
import React from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useCandidateApplicationService, {
  CandidateApplicationStatus,
} from 'erp/candidate/applications/shared/candidateApplicationService';

export interface CandidateApplicationDropdownProps {
  source: string;
  label?: string;
  showLoader?: boolean;
  placeholder?: string;
  showCompleted?: boolean;
}

const CandidateApplicationDropdown = (props: CandidateApplicationDropdownProps) => {
  const { searchAllForCandidateId } = useCandidateApplicationService();
  const { data: formData } = useFormContext();

  const { showCompleted } = props;

  const mapResults = (candidateApplication: ResultResourceData) => ({
    value: candidateApplication.id,
    label: `For ${candidateApplication.vacancyAlias} ${candidateApplication.vacancyName} (application id: ${candidateApplication.id})`,
    disabled:
      !candidateApplication.vacancyAlias ||
      (!showCompleted && candidateApplication.status === CandidateApplicationStatus.PROCESSED),
  });

  const candidateId = formData?.candidateId;
  const loadCandidateApplications = async () => {
    const candidateApplications = await searchAllForCandidateId(candidateId, {});

    return {
      result: candidateApplications,
    };
  };

  return (
    <LoadDropdown
      loadDataMethod={loadCandidateApplications}
      mapResults={mapResults}
      placeholder="Select the vacancy"
      deps={[candidateId]}
      filterOption={option => !option.data.disabled}
      {...props}
    />
  );
};

export default CandidateApplicationDropdown;
