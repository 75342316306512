/* istanbul ignore file */
import React from 'react';
import GlobalPermissionsLoader from 'shared/authorization/GlobalPermissionsLoader';
import useDavinciAuthorizationService from 'authorization/davinciAuthorizationService';
import { useAuthenticationProvider } from 'authentication/AuthenticationProvider';

const GlobalPermissionsProvider = ({ children }: { children: React.ReactChild }) => {
  const { getGlobalPermissions } = useDavinciAuthorizationService();
  const { isAuthenticated } = useAuthenticationProvider();

  return (
    <GlobalPermissionsLoader getGlobalPermissions={getGlobalPermissions} isUserAuthenticated={isAuthenticated}>
      {children}
    </GlobalPermissionsLoader>
  );
};

export default GlobalPermissionsProvider;
