/* istanbul ignore file */
import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleServiceBreadcrumbs from 'erp/service/shared/SingleServiceBreadcrumbs';

const UpdateServiceBreadcrumbs = () => {
  return (
    <SingleServiceBreadcrumbs>
      <UpdateBreadcrumbItem />
    </SingleServiceBreadcrumbs>
  );
};

export default UpdateServiceBreadcrumbs;
