/* istanbul ignore file */
import React from 'react';
import { IconButton } from 'uibuilder/button';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ArrowIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const IndicatorsContainer = (props: any) => {
  const {
    hasValue,
    selectProps: { value: selectValue },
  } = props;

  return (
    <>
      {hasValue && selectValue.value && <ClearIndicator {...props} />}
      <DropdownIndicator />
    </>
  );
};

export const DropdownIndicator = ({ sx = {} }: { sx?: SxProps<Theme> }) => {
  return (
    <IconButton className="dropdown__indicator" sx={{ padding: '2px', marginRight: '-2px', ...sx }}>
      <ArrowIcon sx={{ fill: '#716e72', width: '0.9em', height: '0.9em' }} />
    </IconButton>
  );
};

export const ClearIndicator = (props: any) => {
  const {
    selectProps: { onChange, onBlur },
  } = props;

  const onClick = (e: any) => {
    e.stopPropagation();
    onChange(null, 'clear');
    onBlur();
  };

  return (
    <IconButton
      onMouseDown={onClick}
      onTouchEnd={onClick}
      className="dropdown__clear-indicator"
      sx={{ opacity: 0, padding: '5px', marginRight: '-2px', '@media (hover: none)': { opacity: 1 } }}
    >
      <CloseIcon sx={{ fill: '#0000008a', fontSize: '18px' }} />
    </IconButton>
  );
};
