/* istanbul ignore file */
import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import EducationInput from 'erp/employee/Education/shared/input/EducationInput';
import './EducationListInput.scss';

export const DEFAULT_EDUCATION_VALUE = {
  haveDocument: false,
  document: null,
};

const EducationListInput = props => {
  return (
    <div className="education-list-input">
      <InputList
        addText="Add education"
        deleteText="Delete education"
        inputTemplate={EducationInput}
        defaultValue={DEFAULT_EDUCATION_VALUE}
        shouldUseFlatObject={false}
        simpleLayout={false}
        {...props}
      />
    </div>
  );
};

export default EducationListInput;
