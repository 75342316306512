/* istanbul ignore file */
import React, { useMemo } from 'react';
import MuiLink from '@mui/material/Link';
import Typography from 'uibuilder/Typography';
import { BreadcrumbItemProps } from 'shared/uibuilder/BreadCrumbs';

const BreadcrumbItem = ({ tag, link = '', active, children }: BreadcrumbItemProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedValue = useMemo(() => children, []);

  return active ? (
    <Typography component={tag as any} className="c-title" sx={{ fontWeight: '500' }}>
      {displayedValue}
    </Typography>
  ) : (
    <MuiLink component={tag as any} underline="hover" to={link} color="neutral.50">
      {displayedValue}
    </MuiLink>
  );
};

BreadcrumbItem.defaultProps = {
  tag: 'span',
  active: true,
  link: '',
};

export default BreadcrumbItem;
