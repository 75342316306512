/* istanbul ignore file */
// libs
import React from 'react';
import Sticky from 'react-sticky-el';
import Scrollbars from 'uibuilder/Scrollbars';
// styles
import './SidebarMenu.scss';

export interface SidebarStickyMenuProps {
  children: React.ReactNode;
  avatar?: React.ReactElement;
  disabled?: boolean;
}

const SidebarStickyMenu = ({ avatar, children, disabled = false }: SidebarStickyMenuProps) => {
  const isMobileDevice = () => {
    return window.innerWidth <= 767;
  };

  return (
    <>
      {avatar && <div className="menu-photo mb-3">{avatar}</div>}
      <Sticky
        disabled={isMobileDevice() || disabled}
        stickyClassName="sticky-menu"
        boundaryElement=".page-content"
        hideOnBoundaryHit={false}
        topOffset={-100}
        bottomOffset={40}
      >
        <Scrollbars className="sticky-sidebar-menu tabs-container">
          <ul>{children}</ul>
        </Scrollbars>
      </Sticky>
    </>
  );
};

export default SidebarStickyMenu;
