/* istanbul ignore file */
import React from 'react';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button';
import OneStopDescriptionToggler from 'oneStop/List/Settings/OneStopDescriptionToggler';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import './Settings.scss';

const OneStopSettings = () => {
  return (
    <DropdownMenu
      popupId="onestop-settings"
      iconButton={
        <IconButton className="dropdown-toggle">
          <SettingsIcon />
        </IconButton>
      }
    >
      <OneStopDescriptionToggler />
    </DropdownMenu>
  );
};

export default OneStopSettings;
