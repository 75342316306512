/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { func } from 'prop-types';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { Collapsible, HtmlField } from 'shared/uibuilder/field';
import { UPDATE_EMPLOYEE_NOTE } from 'erp/employee/Timeline/note/employeeNoteService';
import TimelineRecordFooter from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordFooter';
import EmployeeNoteTag from './EmployeeNoteTag';
import ReplaceUrlWithLink from '../../../../../../shared/uibuilder/ReplaceUrlWithLink';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';

const EmployeeNoteTimelineRecordLayout = ({ onEdit, ...props }) => {
  const buttons = [<EditTimelineRecordButton permissionToCheck={UPDATE_EMPLOYEE_NOTE} key="edit" onEdit={onEdit} />];

  return (
    <BaseTimelineRecordLayout
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      fields={
        <Collapsible>
          <ReplaceUrlWithLink>
            <HtmlField source="noteText" label="" />
          </ReplaceUrlWithLink>
        </Collapsible>
      }
      timelineFooter={rest => (
        <div className="d-flex mt-3 justify-content-between align-items-center feedback-timeline-footer">
          <TimelineRecordFooter isUpdateAuthorHidden />
          <EmployeeNoteTag {...rest} />
        </div>
      )}
      {...props}
    />
  );
};

EmployeeNoteTimelineRecordLayout.propTypes = {
  onEdit: func.isRequired,
};

export default EmployeeNoteTimelineRecordLayout;
