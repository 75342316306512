// libs
import React from 'react';
import { func, arrayOf, bool, string } from 'prop-types';
// components
import FilePreviewLayout from 'artifact/shared/input/layout/preview/FilePreviewLayout';
// services
import useArtifactService from 'artifact/artifactService';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';

import './FilePreviewListLayout.scss';

/**
 * Artifact
 * @param artifactsIdsList - list of artifacts id's
 * @param onRemove(id) - function that removes artifact from the context
 * @constructor
 */

const FilePreviewListLayout = ({ artifactsIdsList, onRemove, disabled }) => {
  const { getArtifactsDataByIds } = useArtifactService();

  const artifactsObjects = useAsyncValue(() => getArtifactsDataByIds(artifactsIdsList), [artifactsIdsList]);

  if (!artifactsIdsList?.length) {
    return null;
  }

  return artifactsObjects ? (
    artifactsObjects.map(artifactObject => {
      return (
        <FilePreviewLayout
          key={artifactObject.id}
          onRemove={() => onRemove(artifactObject.id)}
          artifactObject={artifactObject}
          disabled={disabled}
        />
      );
    })
  ) : (
    <div className="file-preview__loading">
      <InputLoader />
    </div>
  );
};

FilePreviewListLayout.propTypes = {
  artifactsIdsList: arrayOf(string).isRequired,
  onRemove: func.isRequired,
  disabled: bool,
};

FilePreviewListLayout.defaultProps = {
  disabled: false,
};

export default FilePreviewListLayout;
