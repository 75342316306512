import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useAssetService, { ASSET_STATUS, DISPOSE_ASSET } from '../../useAssetService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const DisposeAssetButton = () => {
  const { data, setData = () => {} } = useShowContext();
  const assignmentDetails = data.getValueBySource('assignmentDetails');
  const inventoryNumber = data.getValueBySource('inventoryNumber');
  const status = data.getValueBySource('status');
  const { disposeAsset } = useAssetService();

  if (
    assignmentDetails !== null ||
    (status !== ASSET_STATUS.WRITE_OFF_AFTER_PURCHASE && status !== ASSET_STATUS.WRITE_OFF_AFTER_COST_PRORATING)
  ) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage="Do you really want to dispose this Asset?"
      deleteMethod={() => disposeAsset(inventoryNumber)}
      color="error"
      key="delete-asset"
      buttonText="Dispose"
      permissionToCheck={DISPOSE_ASSET}
      afterDelete={{
        execute: (newData: any) => {
          setData(newData);
        },
        successMessage: 'The Asset has been successfully deleted.',
        errorMessage: `Can't dispose Asset.`,
      }}
    />
  );
};

export default DisposeAssetButton;
