export default {
  type: {
    type: 'enum',
    required: true,
  },
  description: {
    type: 'string',
    required: true,
    maxSize: 50000,
  },
  status: {
    type: 'enum',
    required: true,
  },
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  englishLevels: {
    type: 'enum',
    required: true,
  },
  countryOfResidences: {
    type: 'enum',
    required: true,
  },
  formFields: {
    type: 'array',
    required: false,
    forEach: {
      type: 'object',
      properties: {
        value: {
          type: 'string',
          required: true,
          maxSize: 255,
        },
        type: {
          type: 'enum',
          required: true,
        },
        options: {
          type: 'array',
          required: {
            value: true,
            when: {
              $type: {
                eq: 'OPTIONS',
              },
            },
          },
          forEach: {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                required: true,
                maxSize: 255,
              },
              value: {
                type: 'enum',
                required: true,
              },
            },
          },
        },
      },
    },
  },
};
