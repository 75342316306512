/* istanbul ignore file */
import React, { useMemo } from 'react';
import Grid from 'uibuilder/Grid';
import { DateInput } from 'shared/uibuilder/form/input';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import TextButton from 'uibuilder/button/TextButton';
import useInputRow from './useInputRow';

export type DiscussionType = {
  description: string;
  fromPreviousReport: boolean;
  initiatedDate: string;
  closedDate: string;
  comments: string;
};

interface DiscussionInputRowProps {
  data: DiscussionType;
  id: number;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

export const getDiscussionSource = (id: number) => {
  return `weeklyReportData.discussions[${id}]`;
};

const DiscussionInputRow = ({ data, id, formData, updateFormData, getErrorMessages }: DiscussionInputRowProps) => {
  const source = getDiscussionSource(id);
  const { getOnChangeCallback, removeRow } = useInputRow({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'discussions',
  });

  const isDisabledSpecific = useMemo(() => {
    return data.fromPreviousReport && !!data.closedDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.fromPreviousReport, id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextButton
          source={`${source}.description`}
          label={id === 0 ? 'Description' : ''}
          value={data.description}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('description')}
          isRequired
          title="Description"
          errors={getErrorMessages(`${source}.description`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.initiatedDate`}
          label={id === 0 ? 'Initiated date' : ''}
          value={data.initiatedDate}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('initiatedDate')}
          isRequired
          minDate={formData.firstReport ? null : formData.from}
          maxDate={formData.firstReport ? null : formData.to}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextButton
          source={`${source}.comments`}
          label={id === 0 ? 'Comments' : ''}
          value={data.comments}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('comments')}
          title="Comments"
          errors={getErrorMessages(`${source}.comments`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.closedDate`}
          label={id === 0 ? 'Closed date' : ''}
          value={data.closedDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('closedDate')}
          minDate={data.initiatedDate}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <RemoveListItemButton isVisible={!data.fromPreviousReport} onClickCallback={removeRow} isFirstRow={id === 0} />
      </Grid>
    </Grid>
  );
};

export default DiscussionInputRow;
