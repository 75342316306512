/* istanbul ignore file */
import React from 'react';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { SummaryMenuItem } from 'uibuilder/menuitem';
import { useRbsUrl } from 'erp/rbs/RbsRouter';

const ViewRbsNodePageMenu = () => {
  const { getSingleEntityUrl } = useRbsUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl()} exact />
    </SidebarStickyMenu>
  );
};

export default ViewRbsNodePageMenu;
