/* istanbul ignore file */
import React from 'react';
import { string, node } from 'prop-types';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useAccountService from 'crm/account/accountService';

export const ACCOUNT_CONTEXT = 'Account';

const AccountContext = ({ permissionToCheck, children }) => {
  const { getPermissions } = useAccountService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={ACCOUNT_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

AccountContext.propTypes = {
  permissionToCheck: string.isRequired,
  children: node.isRequired,
};

export default AccountContext;
