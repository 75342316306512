import React from 'react';
import SowBreadcrumbs from '../../shared/SowBreadcrumbs';
import UpdateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateSowBreadcrumbs = () => {
  return (
    <SowBreadcrumbs>
      <UpdateBreadcrumbItem entity="SOW" />
    </SowBreadcrumbs>
  );
};

export default UpdateSowBreadcrumbs;
