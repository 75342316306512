/* istanbul ignore file */
import EntityHeader from 'shared/layout/EntityHeader';
import React from 'react';
import { ExcludedFromBalanceBadge } from 'erp/leave';

const LeaveEntityHeader = () => {
  return (
    <EntityHeader
      entityTitleSource="id"
      badges={data => (data.getValueBySource('isExcludedFromBalance') ? <ExcludedFromBalanceBadge /> : null)}
    />
  );
};

export default LeaveEntityHeader;
