/* istanbul ignore file */
import React from 'react';
import Data from 'shared/uibuilder/Data';
import { NOTE_TAG_ALIAS } from 'erp/candidate/Timeline/shared/input/CandidateNoteTagDropdown';

type CandidateNoteTagProps = {
  entity: Data;
};

const CandidateNoteTag = ({ entity }: CandidateNoteTagProps) => {
  const tag: keyof typeof NOTE_TAG_ALIAS = entity.getValueBySource('tag');
  const displayedTag = NOTE_TAG_ALIAS[tag];

  if (!displayedTag) {
    return null;
  }

  return (
    <div className="note-tag">
      <i className="fa fa-star mr-1" aria-hidden />
      {displayedTag}
    </div>
  );
};

export default CandidateNoteTag;
