/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import Message from 'shared/message/Message';
import FloatingButtons from 'uibuilder/form/FloatingButtons';

const UserSettingsLayout = ({ children }: { children: React.ReactNode }) => (
  <Paper sx={{ paddingBottom: 3, '& > h1': { marginBottom: '36px' } }} className="page-content" elevation={0}>
    <ListHeader label="Settings" />
    <Message />
    {children}
    <FloatingButtons shouldScrollToErrorAfterLoading />
  </Paper>
);

export default UserSettingsLayout;
