/* istanbul ignore file */
import React from 'react';
import { CREATE_ASSET } from 'financialAnalytic/assets/useAssetService';
import { useAssetUrl } from 'financialAnalytic/assets/AssetRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkButton from 'uibuilder/button/LinkButton';

const CopyAssetButton = (props: { source?: string }) => {
  const { getCopyEntityUrl } = useAssetUrl();
  const { getValue: getId } = useFieldHelper(props);
  const id = getId();
  const { isGranted } = useAuthorization();

  return isGranted(CREATE_ASSET) ? (
    <LinkButton outline {...props} url={getCopyEntityUrl(id)}>
      Copy
    </LinkButton>
  ) : null;
};

export default CopyAssetButton;
