/* istanbul ignore file */
import React from 'react';
import Tooltip from 'uibuilder/Tooltip';
import FormSection from 'shared/layout/form/FormSection';
import { AddressInput } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import MaritalStatusInput from 'erp/employee/shared/input/MaritalStatusInput';
import ChildrenInput from 'erp/employee/shared/input/ChildrenInput';
import FamilyMemberListInput from 'erp/employee/shared/input/FamilyMemberListInput';
import MilitaryInput from 'erp/employee/shared/input/MilitaryInput';
import FamilyMemberInput from 'erp/employee/shared/input/FamilyMemberInput';
import EducationListInput from 'erp/employee/Education/shared/input/EducationListInput';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import 'externalform/ef1/EF1Form.scss';

const HOME_ADDRESS_TOOLTIP = 'Residential address';

interface EF1FormProps {
  isNotPolishOffice: any;
}

const EF1Form = ({ isNotPolishOffice }: EF1FormProps) => (
  <>
    <FormSection title="Contact information">
      <h3 className="home-address-title">
        Home address
        <Tooltip title={HOME_ADDRESS_TOOLTIP} placement="right" id="address-tooltip">
          <InfoIcon id="address-tooltip" />
        </Tooltip>
      </h3>
      <AddressInput hasAddressLine2 source="homeAddress" />
    </FormSection>

    <FormSection title="Family">
      <FormRow>
        <MaritalStatusInput source="maritalStatus" />
      </FormRow>
      <BigFormRow>
        <p className="mb-2 form-section-tip">
          Please provide information about close relatives (i.e. parents, spouse) below:
        </p>
        <FamilyMemberListInput source="familyMembers" inputTemplate={FamilyMemberInput} addText="Add family member" />
      </BigFormRow>
      <BigFormRow>
        <ChildrenInput source="familyChildren" />
      </BigFormRow>
    </FormSection>

    <FormSection title="Military information" isVisible={isNotPolishOffice}>
      <MilitaryInput source="militaryRecord" />
    </FormSection>

    <FormSection title="Education">
      <EducationListInput source="educations" inputProps={{ isAdditionalInfoVisible: false }} />
    </FormSection>
  </>
);

export default EF1Form;
