/* istanbul ignore file */
import { func } from 'prop-types';
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import useProjectStatusesService from 'erp/project/statuses/ProjectStatusesService';
import ProjectPlanningStatusForm from 'erp/project/statuses/planning/form/ProjectPlanningStatusForm';

const UpdateProjectPlanningStatusForm = ({ getDataFunc, onCancel, ...props }) => {
  const { getDemoValidationSchema, update } = useProjectStatusesService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getDemoValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ProjectPlanningStatusForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

UpdateProjectPlanningStatusForm.propTypes = {
  getDataFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default UpdateProjectPlanningStatusForm;
