import vacancyPublicationValidation from 'erp/recruitment/vacancy/publication/createupdate/vacancyPublicationValidation';
import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import { camelCase } from 'lodash';

export const VACANCY_PUBLICATION_PATH = '/vacancy-publications';
export const VACANCY_PUBLICATION_API = '/vacancies/publications';

export const READ_VACANCY_PUBLICATION_LIST = 'VACANCY_PUBLICATION_READ_LIST';
export const READ_VACANCY_PUBLICATION = 'READ';
export const CREATE_VACANCY_PUBLICATION = 'VACANCY_PUBLICATION_CREATE';
export const UPDATE_VACANCY_PUBLICATION = 'UPDATE';
export const DELETE_VACANCY_PUBLICATION = 'DELETE';
export const PUBLISH_VACANCY = 'VACANCY_PUBLICATION_PUBLISH';

export const SYCOM_VACANCY_PUBLICATION_PLATFORM = 'SYCOM';
export const VACANCY_PUBLICATION_PLATFORMS = { [SYCOM_VACANCY_PUBLICATION_PLATFORM]: 'SYCOM' };
export const VACANCY_PUBLICATION_STATUSES = { DRAFT: 'Draft', OPEN: 'Open', CLOSED: 'Closed' };
export const VACANCY_QUESTION_TYPES_ALIASES = {
  OPTIONS: 'OPTIONS',
  FREE_FORM: 'FREE_FORM',
};
export const VACANCY_QUESTION_TYPES = {
  OPTIONS: 'Options',
  FREE_FORM: 'Free Answer',
};

const useVacancyPublicationService = () => {
  const { getById } = useVacancyService();
  const singleResourceUrl = `${VACANCY_PUBLICATION_API}/:id`;
  const { ...baseCrudRequests } = useCrudService({
    singleResourceUrl,
    listResourceUrl: VACANCY_PUBLICATION_API,
    apiService: useKernelApi,
  });

  const getValidationSchema = () => Promise.resolve(vacancyPublicationValidation);

  const mapToVacancyPublication = (formData: any) => {
    const data = { ...formData };

    if (data.formFields) {
      data.formFields = formData.formFields.map((formField: any) => ({
        ...formField,
        fieldName: camelCase(formField.value),
        label: formField.value,
      }));
    }

    return data;
  };

  const mapVacancyPublicationToFormData = (vacancy: any) => {
    const formFields = vacancy.formFields.map((formField: any) => ({ ...formField, value: formField.label }));

    return { ...vacancy, formFields };
  };

  const updateVacancy = async (id: string, formData: any) => {
    const vacancyPublication = mapToVacancyPublication(formData);

    return baseCrudRequests.update(id, vacancyPublication);
  };

  const createVacancy = async (formData: any) => {
    const vacancyPublication = mapToVacancyPublication(formData);

    return baseCrudRequests.create(vacancyPublication);
  };

  const getVacancyById = async (id: string) => {
    const vacancy = await baseCrudRequests.getById(id);

    return mapVacancyPublicationToFormData(vacancy);
  };

  const constructVacancyPublicationDescription = async (alias: string) => {
    const vacancy = await getById(alias);

    return {
      vacancyAlias: alias,
      name: vacancy.name,
    };
  };

  return {
    getVacancyById,
    createVacancy,
    updateVacancy,
    getValidationSchema,
    ...baseCrudRequests,
    constructVacancyPublicationDescription,
  };
};

export default useVacancyPublicationService;
