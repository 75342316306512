import useCachedCrudService from 'shared/crud/cachedCrudServiceDecorator';
import useBaseCrudService, { ResourceData, ResourceId, ResultResourceData } from 'shared/crud/baseCrudService';
import { SearchParams } from '../uibuilder/list/ListContext';

export type CrudService = {
  create: (data: ResourceData) => ResultResourceData;
  deleteById: (id: ResourceId) => Promise<void>;
  findAll: () => ResultResourceData;
  getById: (id: ResourceId, ignoreCache?: boolean) => ResultResourceData;
  getPermissions: (id: ResourceId) => any;
  search: (request: any) => ResultResourceData;
  searchAll: (params: SearchParams) => ResultResourceData;
  update: (id: ResourceId, data: ResourceData) => ResultResourceData;
};

export interface CrudServiceProviderParams {
  listResourceUrl: string;
  singleResourceUrl: string;
  apiService?: any;
}

export type CrudServiceProvider = (params: CrudServiceProviderParams) => CrudService;
export type CrudServiceProviderDecorator<T> = (
  crudService: CrudServiceProvider,
  params: CrudServiceProviderParams,
) => T & CrudService;

const useCrudService = ({ listResourceUrl, singleResourceUrl, apiService = null }: CrudServiceProviderParams) => {
  return useCachedCrudService(useBaseCrudService, { listResourceUrl, singleResourceUrl, apiService });
};

export default useCrudService;
