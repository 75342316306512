// libs
import React, { useState, useEffect } from 'react';
// components
import CurrencyInputLayout from 'uibuilder/layout/form/input/CurrencyInputLayout';
// services
import useCurrencyInputHelper, { CurrencyInputProps } from './currencyInputHelper';
import { getWithThousandsSeparator } from 'shared/uibuilder/amountUtils';
import { CommonInputLayoutProps } from './index';

const currencyToNum = (currency: string) => {
  const m = currency.split(',').join('');
  return currency && currency.length ? Number(m).toFixed(2) : currency;
};

export type ChangeEventType = React.ChangeEvent<HTMLInputElement>;

export interface CurrencyInputLayoutProps extends CommonInputLayoutProps<ChangeEventType> {
  onChangeCallback: (e: ChangeEventType) => void;
  decimalLimit: number;
  integerLimit: number;
  allowNegative?: boolean;
  autocomplete?: 'off';
  suffix?: string;
}

const CurrencyInput = ({ formatInitialValue, isControlledValue, ...props }: CurrencyInputProps) => {
  const {
    getLabel,
    getValue,
    getSource,
    getRawOnChangeCallback,
    getMaxDecimal,
    getMaxInteger,
    getIsRequired,
    getErrorMessages,
    getValidationCallback,
    getClearValidationCallback,
    getIsDisabled,
    getClassName,
    getTooltip,
    isVisible,
  } = useCurrencyInputHelper(props);
  const [value, setValue] = useState('');
  const contextValue = getValue();

  useEffect(() => {
    if (formatInitialValue) {
      setValue(formatInitialValue(contextValue));
    } else {
      setValue((contextValue as string) && getWithThousandsSeparator(Number(contextValue).toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isControlledValue || currencyToNum(String(value || '')) === currencyToNum(String(contextValue || ''))) {
      return;
    }

    setValue(
      (contextValue as string) &&
        Number(contextValue)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue]);

  const onChangeCallback = (eventValue: React.ChangeEvent<HTMLInputElement>) => {
    const source = getSource();
    const rawValue = eventValue.target.value;

    const rawOnChangeCallback = getRawOnChangeCallback();

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [source]: currencyToNum(rawValue),
      });
    }

    setValue(rawValue);
  };

  return (
    <CurrencyInputLayout
      {...props}
      label={getLabel() || ''}
      tooltip={getTooltip()}
      className={getClassName()}
      errorMessages={getErrorMessages()}
      isRequired={getIsRequired()}
      disabled={getIsDisabled()}
      isVisible={isVisible()}
      decimalLimit={getMaxDecimal()}
      integerLimit={getMaxInteger()}
      onChangeCallback={onChangeCallback}
      value={value}
      onBlurCallback={getValidationCallback()}
      onFocusCallback={getClearValidationCallback()}
    />
  );
};

export default CurrencyInput;
