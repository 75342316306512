import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { EmailField } from 'shared/uibuilder/field';

const ContactEmailField = props => {
  const { getValue, getLabel } = useFieldHelper(props);

  const value = getValue();
  const firstEmail = value && value.length ? value[0] : null;

  return <EmailField label={getLabel()} value={firstEmail} />;
};

export default ContactEmailField;
