import {
  validate,
  buildResolveVariableFunc,
  resolveSchemaByPath,
  getVariableContext,
  getConstraintValue,
  ValidationConstraint,
  ValidationSchema,
} from 'validation-schema-library';
import useAuthorization from 'shared/authorization/authorizationService';
import useFeatureToggle from '../../featuretoggle';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import { FieldsExceptions } from 'validation-schema-library/build/validation/utils';

/**
 * Hook which provides validation components.
 * @param data
 * @param validationSchema
 * @param isCreateRequest - if true => particle request will be forbidden
 * @param fieldsExceptions - map with values resolving exceptions. For example. {'status': 'QUALIFIED'} will
 mean that $status variable will always be resolved as 'QUALIFIED'
 * @param fieldSource - source of the field that needs to be validated
 */
const useValidation = ({
  data = null,
  validationSchema = null,
  isCreateRequest = true,
  fieldsExceptions = null,
  fieldSource = '',
}: {
  data?: Nullable<FormFieldsData>;
  validationSchema?: Nullable<ValidationSchema>;
  isCreateRequest?: boolean;
  fieldsExceptions?: Nullable<FieldsExceptions>;
  fieldSource?: string;
} = {}) => {
  const { getPermissions } = useAuthorization();
  const { isFeatureEnabled } = useFeatureToggle();

  const variableSchema = fieldSource && resolveSchemaByPath(validationSchema, fieldSource);

  const getValueBySchema = (schema: ValidationConstraint) => {
    const context = getVariableContext(data, validationSchema, fieldSource);

    const resolveVariable = buildResolveVariableFunc(
      data,
      fieldsExceptions,
      getPermissions(),
      isCreateRequest,
      context,
      isFeatureEnabled,
    );

    const resultSchema = variableSchema && variableSchema[schema];
    return getConstraintValue(resultSchema, resolveVariable).value;
  };

  return {
    minDate: () => {
      return getValueBySchema(ValidationConstraint.MIN_DATE);
    },
    maxDate: () => {
      return getValueBySchema(ValidationConstraint.MAX_DATE);
    },
    isRequired: () => {
      return getValueBySchema(ValidationConstraint.REQUIRED);
    },
    isVisible: () => {
      return getValueBySchema(ValidationConstraint.VISIBLE);
    },
    isDisabled: () => {
      return getValueBySchema(ValidationConstraint.DISABLED);
    },
    getAllowedTransitions: () => {
      return getValueBySchema(ValidationConstraint.ALLOWED_TRANSITIONS);
    },
    getMaxElements: () => {
      return getValueBySchema(ValidationConstraint.MAX_SIZE);
    },
    getMinElements: () => {
      return getValueBySchema(ValidationConstraint.MIN_SIZE);
    },
    getMaxDecimal: () => {
      return getValueBySchema(ValidationConstraint.MAX_DECIMAL);
    },
    getMaxInteger: () => {
      return getValueBySchema(ValidationConstraint.MAX_INTEGER);
    },
    validate: () => {
      const errors = validate({
        schema: validationSchema,
        fullObject: data,
        isCreateRequest,
        source: fieldSource,
        exceptions: fieldsExceptions,
        permissions: getPermissions(),
        isFeatureEnabled,
      });

      if (fieldSource) {
        return errors[fieldSource];
      }

      return errors;
    },
  };
};

export default useValidation;
