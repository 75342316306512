/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const EditRbsSubNodeButton = ({
  id,
  permissionToCheck,
  color = 'secondary',
  outline,
}: {
  id?: string;
  permissionToCheck?: Nullable<string | string[]>;
  color?: BasicColors;
  outline?: boolean;
}) => {
  const { getUpdateEntityUrl } = useRbsUrl();
  const { getValue } = useFieldHelper({
    source: 'id',
  });
  const { isGranted } = useAuthorization();
  const path = getUpdateEntityUrl(id || getValue());

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <LinkButton
      url={path}
      color={color}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      target="_blank"
      outline={outline}
    >
      Edit
    </LinkButton>
  ) : null;
};

export default EditRbsSubNodeButton;
