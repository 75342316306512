import { useKernelApi } from 'api';

export const EMPLOYEE_GROUP_PATH = '/groups';

export enum GroupType {
  RBS_PUBLIC_SERVICE = 'RBS_PUBLIC_SERVICE',
}

export const GroupTypeLabels: Dictionary<string> = {
  [GroupType.RBS_PUBLIC_SERVICE]: 'Public Service',
};

const useEmployeeGroupService = () => {
  const { sendGetRequest } = useKernelApi();
  const getAll = async () => {
    const response = await sendGetRequest(`${EMPLOYEE_GROUP_PATH}`);

    return response.json();
  };

  return {
    getAll,
  };
};

export default useEmployeeGroupService;
