import useAuthorization from 'shared/authorization/authorizationService';
import useCorporateEventService, {
  UPDATE_CORPORATE_EVENT,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import CorporateEventHeader from 'financialAnalytic/corporateEvents/shared/CorporateEventHeader';
import SingleCorporateEventBreadcrumbs from 'financialAnalytic/corporateEvents/shared/SingleCorporateEventBreadcrumbs';
import EditCorporateEventButton from 'financialAnalytic/corporateEvents/shared/button/EditCorporateEventButton';
import CorporateEventMenu from 'financialAnalytic/corporateEvents/shared/CorporateEventMenu';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import React from 'react';
import CandidateLinkField from 'erp/candidate/shared/field/CandidateLinkField';
import NameField from 'shared/uibuilder/field/NameField';
import EmailField from 'shared/uibuilder/field/EmailField';

const ViewCorporateEventApplicationsPage = () => {
  const { isGranted } = useAuthorization();
  const { searchReferencedApplications } = useCorporateEventService();

  return (
    <ShowPageLayout
      entityHeader={<CorporateEventHeader />}
      breadcrumbs={<SingleCorporateEventBreadcrumbs />}
      headerButtons={[<EditCorporateEventButton isVisible={isGranted(UPDATE_CORPORATE_EVENT)} />]}
      menu={<CorporateEventMenu />}
      hasSeparateSections
    >
      <ShowSection title="Candidate Applications">
        <List getDataMethod={searchReferencedApplications} defaultSortField="id" defaultSortOrder="ASC">
          <DataGridLayout>
            <TextField source="candidateApplication.id" label="ID" isSortable />
            <VacancyLinkField
              source="candidateApplication.vacancyAlias"
              nameSource="candidateApplication.vacancyName"
              label="Vacancy"
              getLinkText={({ id, name }: { id: string; name?: string }) => `${id} ${name}`}
            />
            <CandidateLinkField label="Candidate ID" source="candidateApplication.candidateId" />
            <NameField label="Candidate Name" source="candidateApplication.candidate.name" />
            <EmailField label="Candidate Email" source="candidateApplication.candidate.personalEmail" />
          </DataGridLayout>
        </List>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewCorporateEventApplicationsPage;
