/* istanbul ignore file */
import React from 'react';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import useReconciliationService from 'erp/costaccounting/reconcilation/useReconciliationService';
import BigFormRow from 'shared/layout/form/BigFormRow';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import UploadInputWithoutArtifactID from 'artifact/shared/input/UploadInputWithoutArtifactID';
import Box from 'uibuilder/Box';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import Typography from 'uibuilder/Typography';

interface UploadFileFormProps {
  closeModal: () => void;
  execute: () => void;
  modalOpen: boolean;
}

const getValidationSchema = () =>
  Promise.resolve({
    office: {
      type: 'string',
      required: true,
    },
    file: {
      type: 'array',
      required: true,
      maxSize: 1,
    },
  });

const UploadFileForm = ({ closeModal, modalOpen, execute }: UploadFileFormProps) => {
  const { addMessage } = useMessageService();
  const { uploadBankStatement } = useReconciliationService();
  const { filter } = useListContext();
  const { formatDate } = useDateService();

  return (
    <ModalForm
      closeModal={closeModal}
      modalOpen={modalOpen}
      submitFormFunc={data =>
        uploadBankStatement({ ...data, fromDate: filter?.period?.ge, toDate: filter?.period?.le })
      }
      afterSubmit={{
        execute: () => {
          addMessage(new SuccessMessage('The Bank statement has been successfully uploaded.'));
          execute();
        },
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <Box sx={{ '& .upload-block': { minHeight: { xs: '50px', sm: '100px' } } }}>
        <FormSection>
          <Typography mb="10px">{`This action trigger automatic reconciliation for the current period (${formatDate(
            filter?.period?.ge,
            DATE_FORMAT.SIMPLE,
          )?.trim()} - ${formatDate(filter?.period?.le, DATE_FORMAT.SIMPLE)?.trim()}).`}</Typography>
          <BigFormRow>
            <OfficeDropdown source="office" label="Office" />
          </BigFormRow>
          <BigFormRow>
            <UploadInputWithoutArtifactID
              label="Documents"
              source="file"
              canAddFromClipboard={false}
              disabled={false}
            />
          </BigFormRow>
        </FormSection>
      </Box>
    </ModalForm>
  );
};

export default UploadFileForm;
