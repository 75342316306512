/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import AccountTypeDropdown from 'crm/account/shared/input/AccountTypeDropdown';

const AccountTypeFilter = props => {
  return (
    <DropDownFilter {...props}>
      <AccountTypeDropdown />
    </DropDownFilter>
  );
};

export default AccountTypeFilter;
