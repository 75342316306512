/* istanbul ignore file */
import React, { useContext } from 'react';

const DEFAULT_FUNCTION = () => {};

export interface SyberryTodayContextData {
  selectIssue: (id: number) => void;
  unSelectIssue: (id: number) => void;
  selectedIssues: number[];
  handleNext: () => void;
  activeStep: number;
  handleBack: () => void;
  setIsCheckinPassed: (value: boolean) => void;
  isDataLoaded: boolean;
  updatePlannedAllocation: (id: number, value: number) => void;
  plannedAllocations: Dictionary<number>;
  projects: any[];
  isCheckinPassed: Nullable<boolean>;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const defaultContext = {
  selectIssue: DEFAULT_FUNCTION,
  unSelectIssue: DEFAULT_FUNCTION,
  selectedIssues: [],
  handleNext: DEFAULT_FUNCTION,
  activeStep: 0,
  handleBack: DEFAULT_FUNCTION,
  isDataLoaded: false,
  updatePlannedAllocation: DEFAULT_FUNCTION,
  setIsCheckinPassed: DEFAULT_FUNCTION,
  plannedAllocations: {},
  projects: [],
  isCheckinPassed: null,
  loading: false,
  setLoading: DEFAULT_FUNCTION,
};

const SyberryTodayContext = React.createContext<SyberryTodayContextData>(defaultContext);

export const SyberryTodayProvider = SyberryTodayContext.Provider;

export const useSyberryTodayContext = () => useContext(SyberryTodayContext);

export default SyberryTodayContext;
