/* istanbul ignore file */
import React from 'react';
import { EmployeeLink } from 'erp/employee';
import NameField from 'shared/uibuilder/field/NameField';
import useAsyncValue from 'shared/asyncHelper';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import InputLoader from 'shared/uibuilder/InputLoader/index';

const EmployeeLinkWithNameLoading = ({ id, defaultValue }: { id: string; defaultValue?: string }) => {
  const { getEmployeeNameDataById } = useEmployeeNamesService();

  const employeeName = useAsyncValue(() => (id ? getEmployeeNameDataById(id) : Promise.resolve({})), [id]);

  return employeeName === null ? (
    <InputLoader />
  ) : (
    <EmployeeLink
      name={employeeName?.nameEn ? <NameField value={employeeName?.nameEn} /> : defaultValue || id}
      id={id}
    />
  );
};

export default EmployeeLinkWithNameLoading;
