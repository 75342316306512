/* istanbul ignore file */
import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidatesBreadcrumbs = ({ children }) => {
  const { getListUrl } = useCandidateUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Candidates" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

CandidatesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

CandidatesBreadcrumbs.defaultProps = {
  children: null,
};

export default CandidatesBreadcrumbs;
