import React, { useEffect } from 'react';
import useApplicationService from 'erp/recruitment/applications/shared/applicationService';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const ApplicationsInput = (props: any) => {
  const { searchAll: searchApplications } = useApplicationService();
  const { getValue, initializeValue } = useInputHelper(props);

  const getApplicationsSuggestions = async (query: any) => {
    return searchApplications({
      filter: {
        'specification:search': {
          eq: query,
        },
      },
    });
  };

  const mapEntry = (item: any) => ({
    value: item.id,
    label: `${item.vacancy.alias} ${item.vacancy.name} / ${item.candidate.name.firstName} ${item.candidate.name.lastName}`,
  });

  const formatData = (selectData: any[]) => selectData.map(mapEntry);

  const rawValue = (getValue() || []) as any[];
  const value = rawValue.map((item: any) => (item.value ? item : mapEntry(item)));

  useEffect(() => {
    initializeValue(value);
  }, [initializeValue, value]);

  return (
    <MultiSelectInput
      {...props}
      value={value}
      loadSuggestionsMethod={getApplicationsSuggestions}
      mapResults={formatData}
    />
  );
};

export default ApplicationsInput;
