import React from 'react';
import {
  ACTIVE_STATUS,
  EMPLOYEE_TYPES_ALIAS,
  IN_DISMISSAL_STATUS,
  RELOCATION_STATUS,
} from 'erp/employee/employeeService';
import { EmployeeInput } from 'erp/employee';
import { EmployeeInputProps } from 'erp/employee/shared/input/EmployeeInput';

interface NotDismissedEmployeeInputProps extends EmployeeInputProps {
  includeContractors?: boolean;
  includeInterns?: boolean;
  errorSource?: string;
}

const NotDismissedEmployeeInput = ({
  includeContractors,
  includeInterns,
  ...props
}: NotDismissedEmployeeInputProps) => {
  return (
    <EmployeeInput
      defaultFilter={{
        workingStatus: { in: [ACTIVE_STATUS, IN_DISMISSAL_STATUS, RELOCATION_STATUS] },
        employeeType: {
          in: [
            includeContractors ? EMPLOYEE_TYPES_ALIAS.CONTRACTOR : null,
            includeInterns ? EMPLOYEE_TYPES_ALIAS.INTERN : null,
            EMPLOYEE_TYPES_ALIAS.EMPLOYEE,
          ].filter(item => !!item),
        },
      }}
      {...props}
    />
  );
};

export default NotDismissedEmployeeInput;
