import React from 'react';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import { isPhoneStartsWithUsCode, isPhoneStartsWithByCode } from 'shared/uibuilder/phoneUtils';

const CandidateSearchInput = props => {
  const decorateValue = value => {
    let result = value.replace(/[+)(]/g, '');

    if (/^[\d- ]+$/.test(result)) {
      result = result.replace(/[ -]/g, '');

      if (result.length === 12 && isPhoneStartsWithByCode(result)) {
        result = result.substring(5, result.length);
      }

      if (result.length === 11 && isPhoneStartsWithUsCode(result)) {
        result = result.substring(4, result.length);
      }
    }

    return result;
  };

  return (
    <SearchInput
      valueDecorator={decorateValue}
      tooltipMessage="When searching by phone, extra characters and the international code will be automatically deleted and results will be displayed without formatting."
      {...props}
    />
  );
};

export default CandidateSearchInput;
