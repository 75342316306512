/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import EnumField from 'shared/uibuilder/field/EnumField';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';

const PositionEnumField = ({ ...props }: FieldProps) => {
  const [options, setOptions] = useState<Dictionary<string>>({});
  const { getPositionOptions } = usePresaleOutputService();

  useEffect(() => {
    (async () => {
      const positions = await getPositionOptions();

      setOptions(positions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EnumField options={options} {...props} />;
};

export default PositionEnumField;
