/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import AssignLeadOwnerForm from 'crm/lead/shared/input/AssignLeadOwnerForm';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { CHANGE_OWNER } from 'crm/crmService';

const AssignLeadOwnerButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: lead } = useShowContext();
  const { isGranted } = useAuthorization();

  const closeModal = () => {
    setModalOpen(false);
  };

  const currentLeadStatus = lead.getValueBySource('status');
  const isCorrectStatus = currentLeadStatus === 'NEW' || currentLeadStatus === '';
  const isButtonVisible = isCorrectStatus && isGranted(CHANGE_OWNER);

  return isButtonVisible ? (
    <>
      <Button
        className="ml-2"
        outline
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
      >
        Assign Lead Owner
      </Button>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title="Assign Lead Owner"
        onToggle={closeModal}
        hasCancelButton={false}
        className="qualification-modal"
      >
        <AssignLeadOwnerForm closeModal={closeModal} modalOpen={modalOpen} isGranted={isButtonVisible} />
      </ModalWindow>
    </>
  ) : null;
};

export default AssignLeadOwnerButton;
