/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useEmployeeContractsService, {
  DELETE_EMPLOYEE_CONTRACT,
} from 'erp/employee/contracts/shared/employeeContractsService';
import { useEmployeeContractId, useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';
import useCacheService from 'shared/cache/cacheService';
import { EMPLOYEES_PATH } from 'erp/employee/EmployeesRouter';

const DeleteEmployeeContractButton = props => {
  const { getListUrl } = useEmployeeContractUrl();
  const { deleteById } = useEmployeeContractsService();
  const id = useEmployeeContractId();
  const { invalidateCache } = useCacheService(EMPLOYEES_PATH);

  const handleDelete = () => deleteById(id);

  return (
    <DeleteButton
      {...props}
      displayMessage="Do you really want to delete this contract?"
      deleteMethod={handleDelete}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The contract has been successfully deleted',
        errorMessage: `Can't delete contract`,
        execute: () => {
          invalidateCache();
        },
      }}
      permissionToCheck={DELETE_EMPLOYEE_CONTRACT}
    />
  );
};

export default DeleteEmployeeContractButton;
