/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { arrayOf, element, oneOfType } from 'prop-types';
import { useContactUrl } from 'crm/contact/ContactRouter';

const ContactsBreadcrumbs = ({ children }) => {
  const { getListUrl } = useContactUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Contacts" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

ContactsBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

ContactsBreadcrumbs.defaultProps = {
  children: null,
};

export default ContactsBreadcrumbs;
