/* istanbul ignore file */
import React from 'react';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import { UpdateForm } from 'shared/uibuilder/form';
import AssignmentForm from 'erp/assignment/createupdate/AssignmentForm';
import UpdateEmployeeAssignmentBreadCrumbs from 'erp/employee/assignments/createupdate/Update/Breadcrumbs';
import useAssignmentService from 'erp/assignment/shared/assignmentService';
import { useEmployeeAssignmentId, useEmployeeAssignmentUrl } from 'erp/employee/assignments/EmployeeAssignmentsRouter';

const UpdateEmployeeAssignment = () => {
  const { update, getValidationSchema, getAssignmentById } = useAssignmentService();
  const { getListUrl } = useEmployeeAssignmentUrl();
  const id = useEmployeeAssignmentId();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getAssignmentById}
      entityId={id}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Assignment has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Assignment"
        breadcrumbs={<UpdateEmployeeAssignmentBreadCrumbs />}
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
      >
        <AssignmentForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateEmployeeAssignment;
