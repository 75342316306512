import React from 'react';
import { FieldProps } from 'shared/uibuilder/field/index';
import ApplyCompetencyButton from 'erp/employee/qualification/List/button/ApplyCompetencyButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useEmployeeQualificationService, {
  CompetencyStatus,
} from 'erp/employee/qualification/shared/employeeQualificationService';
import { isAcceptedStatus } from 'erp/employee/qualification/shared/employeeQualificationCalculation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useQualificationId } from 'erp/employee/qualification/QualificationRouter';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

const errorMessage = "Can't apply this competency";

interface ApplyCompetencyStatusFieldProps extends FieldProps {
  qualificationId: Nullable<StringOrNumber>;
  permissionToCheck?: string;
}

const ApplyCompetencyStatusField = ({
  source,
  qualificationId,
  permissionToCheck,
}: ApplyCompetencyStatusFieldProps) => {
  const { applyCompetencies } = useEmployeeQualificationService();
  const specializationId = useQualificationId();
  const employeeId = useEmployeeId() || '';

  const data = useShowContext()?.data;
  const { getValue } = useFieldHelper({ source });

  const appliedStatus = data.getValueBySource('appliedState.status') || CompetencyStatus.NOT_CONFIRMED;
  const currentStatus = data.getValueBySource('rootState.status') || CompetencyStatus.NOT_CONFIRMED;
  const weight = data.getValueBySource('weight');
  const competencyId = getValue();
  const isApplyButtonVisible = appliedStatus !== currentStatus;

  return (
    <>
      {isApplyButtonVisible && !!qualificationId ? (
        <ApplyCompetencyButton
          outline
          submitMethod={() =>
            applyCompetencies({ employeeId, specializationId, qualificationId, competenciesIds: [competencyId] })
          }
          displayMessage="Do you really want to apply this competency?"
          permissionToCheck={permissionToCheck}
          afterSubmit={{ errorMessage }}
        >
          Apply
          {appliedStatus === CompetencyStatus.NOT_CONFIRMED || currentStatus === CompetencyStatus.NOT_CONFIRMED
            ? ` (${isAcceptedStatus(currentStatus) ? '+' : '-'}${weight}%)`
            : ''}
        </ApplyCompetencyButton>
      ) : null}
    </>
  );
};

export default ApplyCompetencyStatusField;
