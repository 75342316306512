import { useKernelApi } from 'api';
import usePresaleOpportunityService from 'crm/opportunity/presale/request/shared/presaleRequestService';
import { ResourceId } from 'shared/crud/baseCrudService';
import { OPPORTUNITY_PATH } from 'crm/opportunity/OpportunityRouter';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import useContractRequestService from 'crm/opportunity/presale/contract/shared/contractRequestService';

export const READ_PRESALE_TIMELINE = 'READ_PRESALE_TIMELINE';

export const PRESALE_ITEMS_TYPE = {
  PRESALE_REQUEST: 'presale-requests',
  PRESALE_OUTPUT: 'presale-outputs',
  CONTRACT_REQUEST: 'contract-requests',
};

const usePresaleTimelineService = () => {
  const { sendPostRequest } = useKernelApi();
  const { createPresaleRequest } = usePresaleOpportunityService();
  const { createOutput } = usePresaleOutputService();
  const { create } = useContractRequestService();

  const searchPresaleItems = async (opportunityId: ResourceId, request: any) => {
    const response = await sendPostRequest(`${OPPORTUNITY_PATH}/${opportunityId}/presale-timeline/search`, request);

    return response.json();
  };

  const getPresalePageById = async (opportunityId: ResourceId, presaleItemId: ResourceId) => {
    const request = { pageSize: 30, filter: {}, sort: { createdAt: 'DESC' } };
    const response = await sendPostRequest(
      `${OPPORTUNITY_PATH}/${opportunityId}/presale-timeline/search/${presaleItemId}`,
      request,
    );

    return response.json();
  };

  const createPresaleTimelineItem = async (itemType: string, opportunityId: ResourceId, request: any) => {
    if (itemType === PRESALE_ITEMS_TYPE.PRESALE_REQUEST) {
      return createPresaleRequest(opportunityId, request);
    }
    if (itemType === PRESALE_ITEMS_TYPE.PRESALE_OUTPUT) {
      return createOutput(opportunityId, request);
    }
    if (itemType === PRESALE_ITEMS_TYPE.CONTRACT_REQUEST) {
      return create(opportunityId, request);
    }
    return null;
  };

  return {
    searchPresaleItems,
    getPresalePageById,
    createPresaleTimelineItem,
  };
};

export default usePresaleTimelineService;
