import React from 'react';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useHiringService, {
  HIRING_REQUEST_UPDATE,
  HiringRequest,
  POSITION_STATUS,
  RequestPosition,
} from '../../useHiringService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

type CloseHiringPositionButtonProps = {
  allShowData: HiringRequest;
  parentShowSetData: (data: HiringRequest) => void;
  isVisible?: boolean;
};

const CloseHiringPositionButton: React.FC<CloseHiringPositionButtonProps> = ({ allShowData, parentShowSetData }) => {
  const { closePosition } = useHiringService();
  const { data } = useShowContext();
  const positionData: RequestPosition = data.getData() as RequestPosition;

  if (positionData.positionStatus !== POSITION_STATUS.OPEN) return null;

  const handleClosePosition = async () => {
    const updatedData = {
      ...allShowData,
      requestPositions: allShowData.requestPositions.map(position =>
        position.id === positionData.id ? { ...position, positionStatus: POSITION_STATUS.CLOSED } : position,
      ),
    } as HiringRequest;

    const response = await closePosition(updatedData);
    parentShowSetData(response);
  };

  return (
    <ButtonWithConfirmation
      permissionToCheck={HIRING_REQUEST_UPDATE}
      displayMessage="Do you really want to close this Position?"
      submitMethod={handleClosePosition}
      afterSubmit={{
        successMessage: 'Position closed successfully!',
        errorMessage: "Position couldn't be closed!",
      }}
    >
      Close
    </ButtonWithConfirmation>
  );
};

export default CloseHiringPositionButton;
