/* istanbul ignore file */
import React from 'react';
import EntityHeader from 'shared/layout/EntityHeader';
import { TextField } from 'shared/uibuilder/field';

const LeadEntityHeader = () => (
  <EntityHeader
    entityTitle={data =>
      `${data.getValueBySource('firstName')} ${data.getValueBySource('middleName') || ''} ${data.getValueBySource(
        'lastName',
      )}`
    }
  >
    <TextField source="title" label="" />
  </EntityHeader>
);

export default LeadEntityHeader;
