/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field/index';
import { isAcceptedStatus } from 'erp/employee/qualification/shared/employeeQualificationCalculation';
import { CompetencyStatus } from 'erp/employee/qualification/shared/employeeQualificationService';
import './ColoredCompetencyStatusField.scss';

export interface ColoredCompetencyStatusFieldProps extends FieldProps {
  className?: string;
  children: React.ReactElement;
}

const ColoredCompetencyStatusField = ({ children, className, source, ...props }: ColoredCompetencyStatusFieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const status = getValue() || CompetencyStatus.NOT_CONFIRMED;

  return (
    <span className={classnames(className, 'colored-status', { 'accepted-status': isAcceptedStatus(status) })}>
      {React.cloneElement(children, { ...props, source })}
    </span>
  );
};

export default ColoredCompetencyStatusField;
