/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import usePayrollDistributionService, {
  PERFORM_EXPENSE_DISTRIBUTION,
} from 'erp/payroll/payrollDistribution/usePayrollDistributionService';
import moment from 'moment';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DateInput } from 'shared/uibuilder/form/input';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { ButtonProps } from 'uibuilder/button/Button';

const PerformPayrollDistributionButton = ({ disabledInput = false, initialValue = '', ...props }: ButtonProps) => {
  const { performExpenseDistribution } = usePayrollDistributionService();
  const [dateErrors, setDateErrors] = useState<string[]>([]);
  const { getToday } = useDateService();
  const { filter, reloadData = () => {} } = useListContext();
  const currentFilterValue = filter?.period?.eq;
  const currentMonth = getToday().endOf('months');
  const [date, setDate] = useState(initialValue || currentFilterValue || currentMonth);

  const onDateChange = (values: FormFieldsData) => {
    setDate(values.date);
    const isValid = moment(values.date, DATE_FORMAT.API_ONLY_MONTH, true).isValid();

    if (!values.date) {
      setDateErrors(['Field is required']);
    } else if (!isValid) {
      setDateErrors(['Please, choose the correct date']);
    } else if (moment(values.date).isAfter(currentMonth)) {
      setDateErrors(["Please, choose a date before or today's date"]);
    } else {
      setDateErrors([]);
    }
  };

  useEffect(() => {
    if (currentFilterValue) {
      onDateChange({ date: currentFilterValue });
    }
    if (initialValue) {
      onDateChange({ date: initialValue });
    }
    // Suppressed warnings because we only need to call useEffect callback if currentFilterValue is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilterValue, initialValue]);

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to perform payroll distribution? Please, fill out the input below."
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="date"
            label="Period"
            onChangeCallback={onDateChange}
            errorMessages={dateErrors}
            isRequired
            dateFormat={DATE_FORMAT.API_ONLY_MONTH}
            value={
              date && moment(date, DATE_FORMAT.API_ONLY_MONTH, true).isValid()
                ? moment(date).format(DATE_FORMAT.API_ONLY_MONTH)
                : (date as any)
            }
            maxDate={currentMonth}
            disabled={disabledInput}
          />
        </div>
      }
      submitMethod={() => performExpenseDistribution(moment(date).format(DATE_FORMAT.API_ONLY_MONTH))}
      confirmBtnProps={{ disabled: !!dateErrors.length }}
      permissionToCheck={PERFORM_EXPENSE_DISTRIBUTION}
      afterSubmit={{
        successMessage:
          'Performing of payroll distribution has been successfully started. Please, check this page after some time.',
        errorMessage: "Can't start performing of payroll distribution.",
        execute: () => {
          reloadData();
        },
      }}
      startIcon={<PlayArrowOutlinedIcon />}
      {...props}
    >
      Perform Payroll Distribution
    </ButtonWithConfirmation>
  );
};

export default PerformPayrollDistributionButton;
