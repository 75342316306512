/* istanbul ignore file */
// libraries
import React from 'react';
// components
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { EnumField, TextField } from 'shared/uibuilder/field';
import { EmployeeDomainStatusLabels } from 'erp/employee/domains/shared/employeeDomainsService';
import AddEmployeeDomainButton from 'erp/employee/domains/shared/button/AddEmployeeDomainButton';
import AddDomainButton from 'erp/employee/domains/domain/button/AddDomainButton';
import EditEmployeeDomainButton from 'erp/employee/domains/shared/button/EditEmployeeDomainButton';
import DeleteEmployeeDomainButton from 'erp/employee/domains/shared/button/DeleteEmployeeDomainButton';
import useEmployeeDomainsShowHelper from 'erp/employee/domains/shared/employeeDomainsShowHelper';
import ControlledList from 'shared/uibuilder/list/ControlledList';

const EmployeeDomainsList = () => {
  const { currentData } = useEmployeeDomainsShowHelper();

  return (
    <ControlledList data={currentData}>
      <RelationListLayout header="Domains" actions={[<AddDomainButton />, <AddEmployeeDomainButton />]}>
        <DataGridLayout
          showTotal={false}
          buttons={[<EditEmployeeDomainButton source="id" />, <DeleteEmployeeDomainButton source="id" />]}
        >
          <TextField source="domain.name" label="Domain area" with={150} />
          <EnumField source="knowledge" label="Knowledge" options={EmployeeDomainStatusLabels} />
          <EnumField source="comprehension" label="Comprehension" options={EmployeeDomainStatusLabels} />
          <EnumField source="application" label="Application" options={EmployeeDomainStatusLabels} />
          <EnumField source="analysis" label="Analysis" options={EmployeeDomainStatusLabels} />
          <EnumField source="synthesis" label="Synthesis" options={EmployeeDomainStatusLabels} />
          <EnumField source="evaluation" label="Evaluation" options={EmployeeDomainStatusLabels} />
        </DataGridLayout>
      </RelationListLayout>
    </ControlledList>
  );
};

export default EmployeeDomainsList;
