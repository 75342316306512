/* istanbul ignore file */
import CreateNoteForm from 'financialAnalytic/assets/Timeline/note/form/CreateNoteForm';
import NoteTimelineRecordLayout from 'financialAnalytic/assets/Timeline/note/NoteTimelineRecordLayout/index';
import { CREATE_ASSET_NOTE_PERMISSION } from './assetNoteService';

const useAssetNoteItem = () => ({
  AddForm: CreateNoteForm as any,
  isApplicable: (item: any) => item,
  source: '',
  urlPostfix: 'note',
  hasFullData: true,
  RecordLayout: NoteTimelineRecordLayout,
  name: 'Note',
  permissionToCheck: CREATE_ASSET_NOTE_PERMISSION,
});

export default useAssetNoteItem;
