/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const CANDIDATE_TIMELINE_RECORDS_ALIAS = {
  NOTE_CREATED: 'NOTE_CREATED',
  RESUME_ADDED: 'RESUME_ADDED',
};

const candidateTimelineRecords = {
  NOTE_CREATED: 'Note',
  RESUME_ADDED: 'Resume',
};

const CandidateTimelineRecordTypeDropdown = (props: any) => {
  return <EnumDropdown {...props} options={candidateTimelineRecords} />;
};

export default CandidateTimelineRecordTypeDropdown;
