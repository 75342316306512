import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const LeaveDurationField = ({ value: inputValue, source, ...props }) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });
  const duration = getValue();
  let value = '';

  if (duration) {
    const postfix = duration > 1 ? 'days' : 'day';
    value = `${duration} ${postfix}`;
  }

  return <TextField value={value} source={source} {...props} />;
};

LeaveDurationField.propTypes = {
  value: oneOfType([string, number]),
  source: string,
};

LeaveDurationField.defaultProps = {
  value: null,
  source: null,
};

export default LeaveDurationField;
