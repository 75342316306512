/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { get, isEmpty } from 'lodash';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import Box from 'uibuilder/Box';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useJournalEntryService from 'financialAnalytic/journalEntry/useJournalEntryService';
import InputLoader from 'shared/uibuilder/InputLoader';

const DEFAULT_EXCHANGE_RATE = '1.00';
const DEFAULT_FUNCTION = () => {};

const ExchangeRateFormField = ({ dateSource }: { dateSource: string }) => {
  const { setSubmitEnabled = DEFAULT_FUNCTION } = useFormContext();
  const { getRawOnChangeCallback, getSource, getValue } = useInputHelper({ source: 'exchangeRates' });
  const { data } = useFormContext();
  const { formatDate, getToday } = useDateService();
  const { getExchangeRates } = useJournalEntryService();
  const today = getToday();
  const date = get(data, dateSource);
  const source = getSource();
  const value = getValue() as any;
  const onChangeCallback = getRawOnChangeCallback();
  const [loading, setLoading] = useState(true);
  const [hasExchangeRates, setHasExchangeRates] = useState(true);

  const loadData = async () => {
    try {
      setSubmitEnabled(false);
      setLoading(true);
      const requestDate = date || formatDate(today, DATE_FORMAT.API_ONLY_DATE);
      const result = await getExchangeRates(date || formatDate(today, DATE_FORMAT.API_ONLY_DATE));

      if (onChangeCallback) {
        onChangeCallback({
          [source]: {
            date: requestDate,
            ...result,
          },
        });
        setHasExchangeRates(!isEmpty(result));
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
      setSubmitEnabled(true);
    }
  };

  useEffect(() => {
    loadData();
    // Suppressed warnings because we only need to call useEffect callback after updating date.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Box
      sx={{
        paddingBottom: '20px',
      }}
    >
      <b>Exchange rate for {formatDate(date || today, DATE_FORMAT.FULL_DATE)}:</b>
      {loading && (
        <div>
          <InputLoader />
        </div>
      )}

      {!loading && !hasExchangeRates && (
        <div className="form-error">
          There are no exchange rates for {formatDate(date || today, DATE_FORMAT.FULL_DATE)}
        </div>
      )}

      {!loading && hasExchangeRates && (
        <>
          <div>1$ = {value?.PLN?.USD || DEFAULT_EXCHANGE_RATE}zł.</div>
          <div>1$ = {value?.BYN?.USD || value?.BYR?.USD || DEFAULT_EXCHANGE_RATE}Br</div>
        </>
      )}
    </Box>
  );
};

export default ExchangeRateFormField;
