import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useWorkplaceService from 'erp/room/workplace/useWorkplaceService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const UnassignWorkplaceButton = () => {
  const { getValue: getId } = useFieldHelper<string>({ source: 'id' });
  const { unassignWorkplace } = useWorkplaceService();
  const { reloadData } = useListContext();

  return (
    <DeleteButton
      buttonText="Unassign"
      deleteMethod={() => unassignWorkplace(getId())}
      displayMessage="Do you really want to unassign this workplace?"
      afterDelete={{
        execute: reloadData,
        successMessage: 'The Workplace has been successfully unassigned.',
        errorMessage: `Can't unassign workplace.`,
      }}
    />
  );
};

export default UnassignWorkplaceButton;
