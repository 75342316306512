/* istanbul ignore file */
import React from 'react';
import { startCase } from 'lodash';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import InvoiceLink from 'financialAnalytic/invoice/shared/field/InvoiceLink';
import ExpenseLink from 'financialAnalytic/expenses/shared/field/ExpenseLink';
import AssetLink from 'financialAnalytic/assets/shared/field/AssetLink';
import { BUSINESS_OBJECT_TYPES } from 'financialAnalytic/ledger/useLedgerService';

const JournalEntryBussinessObjectField = ({ source, typeSource }: FieldProps & { typeSource: string }) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getType } = useFieldHelper({ source: typeSource });
  const type = getType();
  let value: any = getValue();
  const label = startCase(type.replace(/_/g, ' ').toLowerCase());

  switch (type) {
    case BUSINESS_OBJECT_TYPES.INVOICE:
      value = <InvoiceLink source={source} nameSource={source as string} />;
      break;

    case BUSINESS_OBJECT_TYPES.EMPLOYEE_EXPENSE:
      value = <ExpenseLink source={source} nameSource={source as string} isClickable />;
      break;

    case BUSINESS_OBJECT_TYPES.ASSET:
      value = <AssetLink source={source} nameSource={source as string} isClickable />;
      break;

    default:
      value = getValue();
  }

  if (!value) {
    return null;
  }

  return <TextField source={source} value={value} label={label} />;
};

export default JournalEntryBussinessObjectField;
