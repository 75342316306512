/* istanbul ignore file */
import React from 'react';
import useEmployeeOfferService from 'erp/employee/offers/shared/employeeOfferService';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import EmployeeOfferForm from 'erp/employee/offers/createupdate/EmployeeOfferForm';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateEmployeeOfferBreadcrumbs from 'erp/employee/offers/createupdate/Create/CreateBreadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const CreateEmployeeOfferPage = () => {
  const { create, getValidationSchema } = useEmployeeOfferService();
  const employeeId = useEmployeeId();
  const { getTimelineUrl } = useEmployeeUrl();

  return (
    <CreateForm
      initialData={{
        offerCompensations: [],
      }}
      submitFormFunc={create}
      afterSubmit={{
        redirect: getTimelineUrl(employeeId || ''),
        message: 'You are awesome! The offer has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        title="Create: Offer"
        breadcrumbs={<CreateEmployeeOfferBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        menu={<EmployeeMenu />}
      >
        <EmployeeOfferForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateEmployeeOfferPage;
