import React, { useCallback, useState } from 'react';
import { SubmitProps } from 'shared/useSubmit';
import QualificationCompletenessToggler from 'erp/employee/qualification/List/button/QualificationCompletenessToggler';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';

export interface QualificationAchivementToggleButtonProps extends SubmitProps {
  permissionToCheck?: string;
  completeness?: number;
}

const ChangeQualificationCompletenessButton = ({
  submitMethod,
  afterSubmit,
  permissionToCheck,
  completeness,
}: QualificationAchivementToggleButtonProps) => {
  const [newCompletenessValue, setNewCompletenessValue] = useState(completeness);

  const ButtonTag = ({ onClick }: { onClick?: () => void }) => (
    <QualificationCompletenessToggler
      completeness={newCompletenessValue}
      onChangeCallback={(value: number) => {
        if (onClick) {
          onClick();
        }
        setNewCompletenessValue(value);
      }}
    />
  );

  const resetCompletenessValue = useCallback(() => {
    setNewCompletenessValue(completeness);
  }, [completeness]);

  return (
    <ButtonWithConfirmation
      afterSubmit={{
        errorMessage: "Can't change the percentage of the assigned qualification",
        executeOnError: resetCompletenessValue,
      }}
      modalConfirmButtonText="Change"
      submitMethod={() => submitMethod(newCompletenessValue)}
      permissionToCheck={permissionToCheck}
      buttonTag={ButtonTag}
      onToggleCallback={resetCompletenessValue}
      displayMessage="Do you really want to change the percentage of the assigned qualification?"
    />
  );
};

export default ChangeQualificationCompletenessButton;
