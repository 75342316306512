import React from 'react';
import { BadgeField, FieldProps } from 'shared/uibuilder/field';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface ContractCategoryFieldProps extends FieldProps {}

const ContractCategoryField = (props: ContractCategoryFieldProps) => {
  const { getValue } = useFieldHelper(props);
  const isPrimary = !getValue();

  return (
    <BadgeField
      badges={[
        <CustomBadge color="success" isVisible={isPrimary} text="Primary" />,
        <CustomBadge color="secondary" isVisible={!isPrimary} text="Secondary" />,
      ]}
      width={100}
      {...props}
    />
  );
};

export default ContractCategoryField;
