/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useParams } from 'react-router-dom';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';

const StartInterviewButton = () => {
  // @ts-ignore
  const { id: candidateId } = useParams();
  const { getSingleEntityUrl } = useOneStopRequestsUrl();
  const url = getSingleEntityUrl(`REC01?candidateId=${candidateId}&from=/candidates/${candidateId}/profile`);

  return (
    <LinkButton url={url} className="btn-success">
      Start Interview
    </LinkButton>
  );
};

export default StartInterviewButton;
