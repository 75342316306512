/* istanbul ignore file */
import React from 'react';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useAssetService, { UPDATE_ASSET_ASSIGNMENT } from 'financialAnalytic/assets/useAssetService';

const ReturnToStorageAssetButton = () => {
  const { data, setData = () => {} } = useShowContext();
  const assetId = data.getValueBySource('inventoryNumber');
  const assignmentDetails = data.getValueBySource('assignmentDetails');
  const hasOwner = !!assignmentDetails?.currentOwner;
  const { updateAssignmentDetails } = useAssetService();

  if (!hasOwner) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      permissionToCheck={UPDATE_ASSET_ASSIGNMENT}
      displayMessage="Do you really want to return this asset to storage?"
      submitMethod={() => updateAssignmentDetails(assetId, { assignmentDetails: null })}
      afterSubmit={{
        successMessage: 'The Asset has been successfully returned to storage.',
        errorMessage: "Can't return this asset to storage.",
        execute: (response: any) => {
          setData(response);
        },
      }}
      outline
      className="ml-3"
      color="error"
    >
      Return to Storage
    </ButtonWithConfirmation>
  );
};

export default ReturnToStorageAssetButton;
