/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import VacancyPublicationList from 'erp/recruitment/vacancy/publication/List';
import VacancyPublicationContext from 'erp/recruitment/vacancy/publication/VacancyPublicationContext';
import { CreateVacancyPublication, UpdateVacancyPublication } from 'erp/recruitment/vacancy/publication/createupdate';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import {
  CREATE_VACANCY_PUBLICATION,
  READ_VACANCY_PUBLICATION,
  READ_VACANCY_PUBLICATION_LIST,
  UPDATE_VACANCY_PUBLICATION,
  VACANCY_PUBLICATION_PATH,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import ViewVacancyPublication from 'erp/recruitment/vacancy/publication/Show';

export const useVacancyPublicationRoute = () => {
  return useRoute({ listRoute: VACANCY_PUBLICATION_PATH });
};

export const useVacancyPublicationUrl = () => {
  const { getCreateUrl: baseGetCreateUrl, ...baseUrls } = useEntityUrl({
    baseLink: VACANCY_PUBLICATION_PATH,
  });
  const getCreateUrl = (vacancyId: string) => `${baseGetCreateUrl()}?vacancyAlias=${vacancyId}`;

  return {
    ...baseUrls,
    getCreateUrl,
  };
};

export const useVacancyPublicationId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id;
};

const VacancyPublicationRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute } = useVacancyPublicationRoute();

  return (
    <ProtectedRouter basePath={VACANCY_PUBLICATION_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_VACANCY_PUBLICATION_LIST}>
          <VacancyPublicationList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_VACANCY_PUBLICATION}>
          <CreateVacancyPublication />
        </GlobalProtectedRoute>
        <Route path={singleEntityRoute} exact>
          <VacancyPublicationContext permissionToCheck={READ_VACANCY_PUBLICATION}>
            <ViewVacancyPublication />
          </VacancyPublicationContext>
        </Route>
        <Route path={updateRoute} exact>
          <VacancyPublicationContext permissionToCheck={[UPDATE_VACANCY_PUBLICATION]}>
            <UpdateVacancyPublication />
          </VacancyPublicationContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

VacancyPublicationRouter.getRouterPath = () => VACANCY_PUBLICATION_PATH;

export default VacancyPublicationRouter;
