import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { UPDATE, DELETE } from 'crm/crmService';
import useLeadService, { LEAD_GRADE, CONVERT_LEAD } from 'crm/lead/shared/leadService';
import useMessageService, { SuccessMessage, ErrorMessage } from 'shared/message/messageService';
import useAuthorization from 'shared/authorization/authorizationService';

const disqualificationParams = {
  status: 'DISQUALIFIED',
  disqualificationReason: 'SPAM',
  grade: LEAD_GRADE.D.alias,
};

const statusesForDisqualification = ['OPEN', 'NEW'];

const disqualificationMessage = 'The Lead has been disqualified.';

const MarkAsSpamButton = () => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { data: lead, setData: updateLeadData } = useShowContext();
  const { update } = useLeadService();
  const { isGranted } = useAuthorization();
  const { addMessage } = useMessageService();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const disqualify = async id => {
    setLoading(true);

    try {
      const data = await update(id, {
        ...lead.getData(),
        ...disqualificationParams,
      });

      updateLeadData(data && data);

      setLoading(false);

      addMessage(new SuccessMessage(disqualificationMessage));
    } catch (error) {
      setLoading(false);
      addMessage(new ErrorMessage("Can't disqualify lead."));
    }
  };

  const shouldRenderSpamButton =
    lead &&
    lead.getValueBySource('disqualificationReason') !== 'SPAM' &&
    statusesForDisqualification.some(item => item === lead.getValueBySource('status')) &&
    (isGranted(CONVERT_LEAD) || isGranted(UPDATE) || isGranted(DELETE));

  const disqualifyBtn = (
    <Button
      color="primary"
      key="disqualifyBtn"
      onClick={async () => {
        closeModal();
        await disqualify(lead.getValueBySource('id'));
      }}
    >
      Disqualify Lead
    </Button>
  );

  return shouldRenderSpamButton ? (
    <>
      <Button
        className="ml-2"
        color="error"
        outline
        onClick={() => {
          openModal();
        }}
        disabled={loading}
      >
        SPAM
      </Button>

      <ModalWindow isOpen={modalOpen} title="Are you sure?" onToggle={closeModal} buttons={[disqualifyBtn]}>
        <span>Do you really want to disqualify this lead?</span>
      </ModalWindow>
    </>
  ) : null;
};

export default MarkAsSpamButton;
