/* istanbul ignore file */
import React from 'react';
import useGeneratedPayrollsService, {
  CANCEL_PAYROLL_REPORT_PAYMENT,
  PAYROLL_REPORT_STATUSES,
} from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const CancelPayrollReportPaymentButton = ({ statusSource }: { statusSource: string }) => {
  const { cancelPayrollReportPayment } = useGeneratedPayrollsService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { updateInList = () => {} } = useListContext();
  const status = getValue();
  const { data } = useShowContext();
  const contextData = data.getData() || {};

  if (status !== PAYROLL_REPORT_STATUSES.PAID) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage="Do you really want to cancel payment for this report?"
      deleteMethod={() => cancelPayrollReportPayment(contextData.id)}
      afterDelete={{
        successMessage: 'The payment has been successfully cancelled.',
        errorMessage: "Can't cancel payment for this report.",
        execute: () => {
          updateInList({ ...contextData, status: PAYROLL_REPORT_STATUSES.APPROVED } as ListItem);
        },
      }}
      permissionToCheck={CANCEL_PAYROLL_REPORT_PAYMENT}
      buttonText="Cancel payment"
      color="error"
      sx={{ whiteSpace: 'nowrap' }}
    />
  );
};

export default CancelPayrollReportPaymentButton;
