/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { ASSET_STATUS_OPTIONS } from 'financialAnalytic/assets/useAssetService';

const AssetStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={ASSET_STATUS_OPTIONS} {...props} />;
};

export default AssetStatusDropdown;
