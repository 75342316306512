/* istanbul ignore file */
import React from 'react';
import OpportunitiesBreadcrumbs from 'crm/opportunity/shared/OpportunitiesBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { element } from 'prop-types';

const SingleOpportunityBreadcrumbs = ({ children }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useOpportunityUrl();
  const opportunityName = data.getValueBySource('name');
  const id = data.getValueBySource('id');
  const singleOpportunityUrl = getSingleEntityUrl(id);

  return (
    <OpportunitiesBreadcrumbs>
      <SingleBreadcrumbItem entity={opportunityName} link={singleOpportunityUrl} />
      {children}
    </OpportunitiesBreadcrumbs>
  );
};

SingleOpportunityBreadcrumbs.propTypes = {
  children: element,
};

SingleOpportunityBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleOpportunityBreadcrumbs;
