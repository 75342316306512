import React from 'react';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import { BaseRowProps } from './Row';
import CollapsibleRow from 'shared/uibuilder/list/layout/DataGridLayout/CollapsibleRow';
import { CollapsibleRowStorageProps } from './collapsibleRowService';

export interface TableBodyWithGroupingProps extends BaseRowProps {
  children: React.ReactElement | React.ReactElement[];
  childrenSource?: string;
  toggleSource?: string;
  toggleProp?: string;
  collapsibleRowStorageProps?: CollapsibleRowStorageProps;
  CustomChildTemplate?: React.ReactElement | React.ReactElement[];
}

const TableBodyWithGrouping = ({
  children,
  rowProps = {},
  buttons = [],
  rowKeySource = 'id',
  childrenSource,
  toggleSource,
  toggleProp,
  collapsibleRowStorageProps,
  CustomChildTemplate,
}: TableBodyWithGroupingProps) => {
  const { data: { items = [] } = {} } = useListContext();

  const getRows = (data: any[], level = 0): any[] => {
    return data.map((item: ListItem) => (
      <CollapsibleRow
        key={`row-${item[rowKeySource]}-${level}`}
        data={item}
        template={children}
        buttons={buttons}
        rowProps={rowProps}
        rowKeySource={rowKeySource}
        toggleSource={toggleSource}
        toggleProp={toggleProp}
        level={level}
        collapsibleRowStorageProps={collapsibleRowStorageProps}
      >
        {childrenSource && item[childrenSource]?.length
          ? CustomChildTemplate || getRows(item[childrenSource], level + 1)
          : null}
      </CollapsibleRow>
    ));
  };

  return <tbody>{getRows(items)}</tbody>;
};

export default TableBodyWithGrouping;
