import { useCallback } from 'react';
import { useKernelApi } from 'api';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { NewCompetencyType } from 'erp/qualification/newCompetency/newCompetencyService';
import useCacheService from 'shared/cache/cacheService';
import { ResourceData } from 'shared/crud/baseCrudService';

const CACHE_NAME = 'employee-new-qualification';

export const READ_NEW_COMPETENCY_LIST_URL = '/qualification-models';
export const READ_NEW_COMPETENCY_LIST = 'READ_EMPLOYEE_NEW_QUALIFICATION_MODEL';

export enum EmployeeNewCompetencyLevelStatus {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  STARTED = 'STARTED',
  ACHIEVED = 'ACHIEVED',
}

export const EmployeeNewCompetencyLevelStatusLabels: Dictionary<string> = {
  [EmployeeNewCompetencyLevelStatus.NOT_CONFIRMED]: 'Not confirmed',
  [EmployeeNewCompetencyLevelStatus.STARTED]: 'Started',
  [EmployeeNewCompetencyLevelStatus.ACHIEVED]: 'Achieved',
};

export interface EmployeeNewCompetency {
  id: number;
  name: string;
  type: NewCompetencyType;
  levels: EmployeeNewCompetencyLevel[];
}

export interface EmployeeNewCompetencyLevel {
  id: number;
  levelNumber: number;
  indicator: string;
  assessmentGuidelines: string;
  status: EmployeeNewCompetencyLevelStatus | null;
}

const useEmployeeNewCompetencyService = () => {
  const { sendGetRequest, sendPutRequest } = useKernelApi();
  const currentAlias = useEmployeeId();
  const { addToCache, getValue, cache } = useCacheService(CACHE_NAME);

  const getCacheId = (employeeId: Nullable<string>) => `employee-${employeeId}-new-qualification-model`;

  const getEmployeeCompetencies = useCallback(
    async () => {
      const cacheId = getCacheId(currentAlias);
      const cachedValue = getValue(cacheId);

      if (cachedValue) {
        return cachedValue;
      } else {
        const response = await sendGetRequest(`/employees/${currentAlias}/new-competencies`);
        const json = await response.json();

        addToCache(cacheId, json.result);

        return json.result;
      }
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  const updateCompetencyLevel = async ({
    employeeId,
    competencyLevelId,
    formData,
  }: {
    employeeId: StringOrNumber;
    competencyLevelId: StringOrNumber;
    formData: ResourceData;
  }) => {
    const url = `/employees/${employeeId}/competency-level/${competencyLevelId}`;
    const response = await sendPutRequest(url, formData);
    const result = await response.json();

    updateCompetencyLevelInCache(result);

    return result;
  };

  const updateCompetencyLevelInCache = (data: any) => {
    const cacheId = getCacheId(currentAlias);
    const cachedValue = getValue(cacheId) || {};

    const resultValue = [] as EmployeeNewCompetency[];
    cachedValue.forEach((competency: EmployeeNewCompetency) => {
      const resultLevels = competency.levels?.map((level: EmployeeNewCompetencyLevel) => {
        if (level.id === data.competencyLevelId) {
          return {
            ...level,
            status: data.status,
          };
        } else {
          return level;
        }
      });
      resultValue.push({ ...competency, levels: [...resultLevels] });
    });

    addToCache(cacheId, resultValue);
  };

  return {
    getEmployeeCompetencies,
    updateCompetencyLevel,
  };
};

export default useEmployeeNewCompetencyService;
