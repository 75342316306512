/* istanbul ignore file */
import React from 'react';
import { element } from 'prop-types';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';

const BalancesLeavesRowColumnLayout = ({ children, ...props }) => {
  return (
    <div className="balances-leaves-column">
      <br />
      <FieldListLayout {...props}>{children}</FieldListLayout>
    </div>
  );
};

BalancesLeavesRowColumnLayout.propTypes = {
  children: element.isRequired,
};

export default BalancesLeavesRowColumnLayout;
