/* istanbul ignore file */
import React from 'react';
import useCrmActivityItem from 'crm/shared/timeline/activity/crmActivityRegistry';
import useCrmNoteItem from 'crm/shared/timeline/note/crmNoteRegistry';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import CrmTimelineEntryTypeFilter from 'crm/shared/timeline/filter/CrmTimelineEntryTypeFilter';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import { useAccountId } from 'crm/account/AccountRouter';
import useAccountService from 'crm/account/accountService';
import ShowAccountPageMenu from 'crm/account/Show/ShowAccountPageMenu';
import AccountEntityHeader from 'crm/account/shared/AccountEntityHeader';
import AccountTimelineBreadcrumbs from 'crm/account/Timeline/Breadcrumbs';

const AccountTimelinePage = () => {
  const accountId = useAccountId();
  const {
    getById,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    getFilterValidationSchema,
  } = useAccountService();

  const activityRegistry = useCrmActivityItem();
  const noteRegistry = useCrmNoteItem();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <CrmTimelineEntryTypeFilter label="Entry Type" source="type" />
        <DateTimeRangeFilter label="Created date" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );
  return (
    <Show getDataMethod={() => getById(accountId)}>
      <Timeline
        registries={[noteRegistry, activityRegistry]}
        getDataMethod={request => searchTimelineItems(accountId, request)}
        createTimelineItemMethod={(itemType, request) => createTimelineItem(accountId, itemType, request)}
        getPageByEntryIdMethod={id => getTimelinePageById(accountId, id)}
      >
        <TimelinePageLayout
          menu={<ShowAccountPageMenu />}
          breadcrumbs={<AccountTimelineBreadcrumbs />}
          entityHeader={<AccountEntityHeader />}
          filter={filters}
        >
          <TimelineLayout noEntriesMessage="No entries in this account timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default AccountTimelinePage;
