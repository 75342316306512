/* istanbul ignore file */
const EQUIPMENT_CATEGORIES = ['INDIVIDUAL_EQUIPMENT', 'OFFICE_EQUIPMENT', 'OFFICE_FURNITURE'];
const INTANGIBLE_ASSETS = ['COMPANY_INTANGIBLE_ASSETS', 'COST_CENTER_INTANGIBLE_ASSETS'];
const NOT_INTANGIBLE_ASSETS = [...EQUIPMENT_CATEGORIES, 'COST_CENTER_EQUIPMENT'];
const SUBSCRIPTION_TYPES = ['SUBSCRIPTION'];

export const editFormValidationSchema = {
  category: {
    type: 'string',
    required: true,
  },
  type: {
    type: 'string',
    required: true,
  },
  office: {
    type: 'string',
    required: {
      value: true,
      when: {
        $category: {
          in: NOT_INTANGIBLE_ASSETS,
        },
      },
    },
  },
  attribution: {
    type: 'object',
    _divisible: false,
    properties: {
      balanceAccountId: {
        type: 'string',
        required: true,
      },
      expenseAccountId: {
        type: 'string',
        required: true,
      },
    },
  },
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  serialNumber: {
    type: 'string',
    maxSize: 255,
  },
  characteristics: {
    type: 'string',
    maxSize: 1024,
  },
  condition: {
    type: 'string',
    required: {
      value: true,
      when: {
        $category: {
          in: NOT_INTANGIBLE_ASSETS,
        },
      },
    },
  },
  cost: {
    type: 'object',
    _divisible: false,
    properties: {
      amount: {
        type: 'string',
        required: true,
      },
      currency: {
        type: 'string',
        required: true,
      },
    },
  },
  customUsefulLifePeriod: {
    type: 'object',
    _divisible: false,
    properties: {
      amount: {
        type: 'number',
        required: true,
      },
      timeUnit: {
        type: 'string',
        required: true,
      },
    },
  },
  purchaseDate: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$TODAY',
      message: 'Please choose date not earlier than today',
    },
  },
  validityStartDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $category: {
          in: INTANGIBLE_ASSETS,
        },
      },
    },
    maxDate: {
      value: '$validityEndDate',
      message: 'Please choose date earlier than Validity End Date',
    },
  },
  validityEndDate: {
    type: 'date',
    minDate: {
      value: '$validityStartDate',
      message: 'Please choose date further than Validity Start Date',
    },
  },
  warrantyEndDate: {
    type: 'date',
    minDate: {
      value: '$purchaseDate',
      message: 'Please choose date further than Purchase Date',
    },
  },

  subscriptionDetails: {
    type: 'object',
    _divisible: false,
    properties: {
      isAutoRenew: {
        type: 'boolean',
        required: {
          value: true,
          when: {
            $type: {
              in: SUBSCRIPTION_TYPES,
            },
          },
        },
      },
      nextPaymentDate: {
        type: 'date',
        required: {
          value: true,
          when: {
            $type: {
              in: SUBSCRIPTION_TYPES,
            },
            '$subscriptionDetails.isAutoRenew': {
              eq: true,
            },
          },
        },
      },
      expiryDate: {
        type: 'date',
        required: {
          value: true,
          when: {
            $type: {
              in: SUBSCRIPTION_TYPES,
            },
            '$subscriptionDetails.isAutoRenew': {
              eq: false,
            },
          },
        },
      },
      billingCycle: {
        type: 'object',
        _divisible: false,
        properties: {
          amount: {
            type: 'number',
            required: {
              value: true,
              when: {
                $type: {
                  in: SUBSCRIPTION_TYPES,
                },
              },
            },
            maxValue: 40,
          },
          timeUnit: {
            type: 'string',
            required: {
              value: true,
              when: {
                $type: {
                  in: SUBSCRIPTION_TYPES,
                },
              },
            },
          },
        },
      },
    },
  },

  location: {
    type: 'string',
    maxSize: 255,
  },
};

export default {
  ...editFormValidationSchema,
  inventoryNumber: {
    type: 'string',
    required: true,
    regex: {
      value: '^[MKAG]{1}\\d{8}$',
      message: 'Must start with M, K, A or G and contain 8 digits',
    },
  },

  assignmentDetails: {
    type: 'object',
    _divisible: false,
    properties: {
      currentOwner: {
        type: 'string',
      },
      provisionDate: {
        type: 'date',
        required: {
          value: true,
          when: {
            '$assignmentDetails.currentOwner': {
              notEmpty: true,
            },
          },
        },
        maxDate: {
          value: '$TODAY',
          message: 'Please choose date not earlier than today',
        },
      },
    },
  },

  note: {
    type: 'object',
    _divisible: false,
    properties: {
      content: {
        type: 'string',
        maxSize: 1024,
      },
    },
  },
};
