import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { CLOSED_REASON } from 'crm/opportunity/shared/opportunityService';
import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

interface Props extends DefaultDropdownProps {
  type: 'PRIMARY' | 'SECONDARY';
  stageSource: string;
}

const ClosedReasonDropdown = ({ type, stageSource, ...props }: Props) => {
  const { getValue: getStage } = useInputHelper({ source: stageSource });
  const stage = (getStage() as any) as 'CLOSED_WON' | 'CLOSED_LOST';
  const options = CLOSED_REASON[type][stage];

  return (
    <Dropdown
      placeholder="Choose Closed Reason"
      options={Object.entries(options).map(entry => {
        const [value, label] = entry;

        return {
          value,
          label,
        };
      })}
      label="Closed Reason"
      {...props}
    />
  );
};

export default ClosedReasonDropdown;
