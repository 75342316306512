/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewAssignmentPageMenu from 'erp/assignment/Show/ViewAssignmentPageMenu';
import AssignmentEntityHeader from 'erp/assignment/shared/AssignmentEntityHeader';
import SingleAssignmentBreadcrumbs from 'erp/assignment/shared/SingleAssignmentBreadcrumbs';
import EditAssignmentButton from 'erp/assignment/shared/button/EditAssignmentButton';
import CopyAssignmentButton from 'erp/assignment/shared/button/CopyAssignmentButton';
import DeleteAssignmentButton from 'erp/assignment/shared/button/DeleteAssignmentButton';
import { BooleanField, DateField, PercentField } from 'shared/uibuilder/field';
import { EmployeeLinkField } from 'erp/employee';
import useAssignmentService, { CREATE_ASSIGNMENT, UPDATE_ASSIGNMENT } from 'erp/assignment/shared/assignmentService';
import { useAssignmentId } from 'erp/assignment/AssignmentRouter';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import AssigneeField from 'erp/assignment/shared/field/AssigneeField';
import PositionInAssignmentField from 'erp/assignment/shared/field/PositionInAssignmentField';
import AssignmentTypeField from 'erp/assignment/shared/field/AssignmentTypeField';
import AssignmentTargetLinkField from 'erp/assignment/target/shared/field/AssignmentTargetLinkField';

const ViewAssignment = () => {
  const id = useAssignmentId();
  const { getById } = useAssignmentService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        headerButtons={[
          <EditAssignmentButton key="edit-assignment" permissionToCheck={UPDATE_ASSIGNMENT} />,
          <CopyAssignmentButton key="copy-assignment" permissionToCheck={CREATE_ASSIGNMENT} />,
          <DeleteAssignmentButton key="delete-assignment" />,
        ]}
        menu={<ViewAssignmentPageMenu />}
        breadcrumbs={<SingleAssignmentBreadcrumbs />}
        entityHeader={<AssignmentEntityHeader />}
        hasSeparateSections
      >
        <ShowSection title="Assignment Information">
          <SectionRow>
            <AssignmentTargetLinkField
              label="Target"
              source="target.id"
              nameSource="target.displayName"
              typeSource="target.type"
            />
            <EmployeeLinkField source="ownerId" nameSource="ownerName" label="Owner" />
            <AssigneeField source="assignmentType" label="Assignee" />
            <PositionInAssignmentField label="Position" source="assignmentType" />
            <EmployeeLinkField source="backupForEmployeeId" nameSource="backupForEmployeeName" label="Backup For" />
            <BooleanField source="bench" label="Bench" />
          </SectionRow>
          <SectionRow>
            <DateField source="from" label="Start Date" />
            <DateField source="to" label="End Date" />
            <PercentField source="minPercent" label="Min Percent" />
            <PercentField source="maxPercent" label="Max Percent" />
          </SectionRow>
          <SectionRow>
            <AssignmentTypeField source="assignmentType" label="Assignment type" />
            <EmployeeLinkField source="resourceManagerId" nameSource="resourceManagerName" label="Resource Manager" />
            <BudgetLinkField label="Budget" source="budgetId" nameSource="budget.name" />
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewAssignment;
