/* istanbul ignore file */
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { MODIFY_INSTANCE_TOKEN_POSITIONS } from 'camunda/monitoring/instance/Show/token/processInstanceTokenService';
import { RESOLVE_PROCESS_INCIDENT } from 'camunda/monitoring/instance/Show/incident/processIncidentService';
import HealingIcon from '@mui/icons-material/Healing';

const ProcessInstanceMenu = () => {
  const { isGranted } = useAuthorization();
  const isTokenModificationsAvailable = isGranted(MODIFY_INSTANCE_TOKEN_POSITIONS);
  const isProcessIncidentsAvailable = isGranted(RESOLVE_PROCESS_INCIDENT);

  const id = useProcessInstanceId();
  const {
    getHistoryUrl,
    getIssuesUrl,
    getVariablesUrl,
    getSubprocessesUrl,
    getSupportIssuesUrl,
    getTokenModificationsUrl,
    getIncidentsUrl,
  } = useProcessInstanceUrl();

  return (
    <SidebarStickyMenu>
      <SidebarMenuItem linkUrl={`${getHistoryUrl(id)}`} name="History" icon="history" />
      <SidebarMenuItem linkUrl={`${getIssuesUrl(id)}`} name="Issues" icon="user-o" />
      <SidebarMenuItem linkUrl={`${getVariablesUrl(id)}`} name="Variables" icon="bars" />
      <SidebarMenuItem linkUrl={`${getSubprocessesUrl(id)}`} name="Subprocesses" icon="code-fork" />
      <SidebarMenuItem linkUrl={`${getSupportIssuesUrl(id)}`} name="Support Requests" icon="ambulance" />
      {isTokenModificationsAvailable && (
        <SidebarMenuItem linkUrl={`${getTokenModificationsUrl(id)}`} name="Token Modifications" icon="circle" />
      )}
      {isProcessIncidentsAvailable && (
        <SidebarMenuItem linkUrl={`${getIncidentsUrl(id)}`} name="Active Incidents" icon={<HealingIcon />} />
      )}
    </SidebarStickyMenu>
  );
};

export default ProcessInstanceMenu;
