import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useOneStopService, { FILTER_TABS, RequestType } from 'oneStop/oneStopService';
import { useOneStopRequestsUrl, IsOneStopHistoryEnabled } from 'oneStop/OneStopRouterHelper';
import { useOneStopHistoryUrl } from 'myActivity/MyActivityRouterHelper';
import OneStopModalLayout, { ModalData } from 'oneStop/Create/OneStopModalLayout';

type OneStopModalProps = {
  data: Nullable<ModalData>;
  isError?: boolean;
  isOneStop?: boolean;
  handleMessageModalClose?: () => void;
};

const OneStopModal = ({ data, isError, isOneStop, handleMessageModalClose }: OneStopModalProps) => {
  const [modalData, setModalData] = useState<Nullable<ModalData>>(data);
  const history = useHistory();
  const { getListUrl } = useOneStopRequestsUrl();
  const { getListUrl: getHistoryListUrl } = useOneStopHistoryUrl();
  const { invalidateCache } = useOneStopService();

  useEffect(() => {
    setModalData(data);
  }, [data]);

  const modalConfirmHandler = () => {
    const { requestId = null, requestType = null } = modalData || {};

    setModalData(null);

    if (handleMessageModalClose && !isError) {
      handleMessageModalClose();
    }

    if (isOneStop && !isError) {
      invalidateCache();

      let path;

      const isRequestInHistory = requestType == null || requestType === RequestType.TYPE_TASK_TRACKER_ISSUE;
      // process requests are not in the history
      if (IsOneStopHistoryEnabled() && isRequestInHistory) {
        path = `${getHistoryListUrl()}`;

        if (requestId) {
          path += `/${requestId}`;
        }
      } else {
        path = `${getListUrl()}?filter={"section":{"eq":"${FILTER_TABS.RECENT}"}}`;
      }

      history.push(path);
    }
  };

  return <OneStopModalLayout modalData={modalData} modalConfirmHandler={modalConfirmHandler} />;
};

OneStopModal.defaultProps = {
  isError: false,
  isOneStop: true,
  handleMessageModalClose: null,
};

export default OneStopModal;
