/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import WysiwygInput from 'shared/uibuilder/form/input/WysiwygInput';
import WysiwygInputPasteArtifactDecorator from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator';
import { string } from 'prop-types';

const ResumeWysiwygInput = ({ artifactsSource, ...props }) => {
  return (
    <WysiwygInputPasteArtifactDecorator artifactsSource={artifactsSource} artifactTypeId={ARTIFACT_ID.CANDIDATE_RESUME}>
      <WysiwygInput height={100} {...props} />
    </WysiwygInputPasteArtifactDecorator>
  );
};

ResumeWysiwygInput.propTypes = {
  artifactsSource: string.isRequired,
};

export default ResumeWysiwygInput;
