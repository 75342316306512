/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import { useCandidateId } from 'erp/candidate/CandidateRouter';
import useCandidateService from 'erp/candidate/shared/candidateService';
import useCandidateCommunicationService from 'erp/candidate/communication/shared/candidateCommunicationService';
import useEmailCommunicationItem from 'erp/candidate/communication/email/emailCommunicationRegistry';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import CandidateCommunicationBreadcrumbs from 'erp/candidate/communication/Breadcrumbs';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';

const CandidateTimelinePage = () => {
  const candidateId = useCandidateId();
  const { getById } = useCandidateService();
  const { searchCommunicationItems, getCommunicationPageById, createEmail } = useCandidateCommunicationService();

  const emailRegistry = useEmailCommunicationItem() as any;

  return (
    <Show getDataMethod={() => getById(candidateId)}>
      <Timeline
        registries={[emailRegistry]}
        getDataMethod={request => searchCommunicationItems(candidateId, request)}
        createTimelineItemMethod={(itemType, request) => createEmail(candidateId, request)}
        getPageByEntryIdMethod={() => getCommunicationPageById(candidateId)}
      >
        <TimelinePageLayout
          menu={<CandidateMenu />}
          breadcrumbs={<CandidateCommunicationBreadcrumbs />}
          entityHeader={<CandidateEntityHeader />}
          title="Communication"
        >
          <TimelineLayout noEntriesMessage="No entries in this candidate communication yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default CandidateTimelinePage;
