/* istanbul ignore file */
import { createContext, useContext } from 'react';

const SensitiveDataContext = createContext();
const SensitiveDataContextProvider = SensitiveDataContext.Provider;

const useSensitiveDataContext = () => {
  const context = useContext(SensitiveDataContext);
  return context || {};
};

export { SensitiveDataContext, useSensitiveDataContext, SensitiveDataContextProvider };
