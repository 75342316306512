import { useEffect, useState } from 'react';
import { useProcessInstanceId } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import useProcessInstanceService, {
  ActivityInstance,
  Variable,
} from 'camunda/monitoring/instance/processInstanceService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

export interface VariableContext {
  executionId: string;
  title: string;
}

interface Context {
  executionId: string;
  variables: List<Variable>;
}

const useProcessVariablesHelper = () => {
  const { getProcessVariables } = useProcessInstanceService();
  const [contexts, setContexts] = useState<List<Context>>([]);
  const processId: string = useProcessInstanceId();

  useEffect(() => {
    getProcessVariables(processId).then(variables => {
      const globalContext = {
        executionId: processId,
        variables: variables.filter(variable => variable.executionId === processId),
      };
      const contextsList = Array.from(
        new Set(variables.filter(variable => variable.executionId !== processId).map(variable => variable.executionId)),
      ).map(executionId => ({
        executionId,
        variables: variables.filter(variable => variable.executionId === executionId),
      }));
      setContexts([globalContext, ...contextsList]);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getExecutionVariables = (executionId: string) => {
    const variables = contexts.find(context => context.executionId === executionId)?.variables || [];
    return {
      totalElements: variables.length,
      totalPages: 1,
      pageNumber: 1,
      result: variables,
    };
  };

  const { data } = useShowContext();
  const buildTitle = (executionId: string) => {
    const activityId = data
      .getValueBySource('activities')
      ?.find((activity: ActivityInstance) => activity.executionId === executionId)?.activityId;
    return `${activityId ? `${activityId}:` : ''}${executionId}`;
  };

  const getVariableContexts = (): List<VariableContext> => {
    const globalContext = {
      executionId: processId,
      title: 'Global',
    };
    const otherContexts = contexts
      .filter(context => context.executionId !== processId)
      .map(context => {
        const { executionId } = context;
        return {
          executionId,
          title: buildTitle(executionId),
        };
      });

    return [globalContext, ...otherContexts];
  };

  return {
    getExecutionVariables,
    getVariableContexts,
  };
};

export default useProcessVariablesHelper;
