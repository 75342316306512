/* istanbul ignore file */
import React from 'react';
import { CurrencyInput } from 'shared/uibuilder/form/input';
import { CurrencyInputProps } from 'shared/uibuilder/form/input/currencyInputHelper';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';

interface TransferCurrencyInputProps extends Omit<CurrencyInputProps, 'onChangeCallback'> {
  currencySource: string;
  allowNegative?: boolean;
}

const InvoiceTotalInput = ({ currencySource, ...props }: TransferCurrencyInputProps) => {
  const { getValue } = useInputHelper({
    source: currencySource,
  });
  const currency = getValue() as any;

  return <CurrencyInput {...props} maxDecimal={2} suffix={currency ? CURRENCIES[currency] : ''} />;
};

export default InvoiceTotalInput;
