/* istanbul ignore file */
// libs
import React, { RefObject } from 'react';
import LinkIcon from '@mui/icons-material/LinkOutlined';
// components
import Tooltip from 'uibuilder/Tooltip';
// services
import { copyToClipboard } from 'shared/clipboard';
import './Anchor.scss';

const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  copyToClipboard(e.currentTarget.href);
};

export interface AnchorProps {
  link: string;
  id: string;
  classes?: string;
  children: React.ReactNode | React.ReactNode[];
  linkRef?: RefObject<HTMLAnchorElement>;
}

const Anchor = ({ link, id, classes, children, linkRef }: AnchorProps) => (
  <Tooltip id={id} arrow placement="right" title={children}>
    <a href={link} onClick={handleAnchorClick} id={id} className={`copy-anchor ${classes}`} ref={linkRef}>
      <LinkIcon />
    </a>
  </Tooltip>
);

Anchor.defaultProps = {
  classes: '',
  linkRef: {},
};

export default Anchor;
