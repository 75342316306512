import moment from 'moment';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import React from 'react';

const HOURS_IN_DAY = 24;

const shouldRenderPastDueMark = entity => {
  const activityDate = entity.getValueBySource('activityDate') ? moment(entity.getValueBySource('activityDate')) : null;
  const createdAtDate = entity.getValueBySource('createdAt') ? moment(entity.getValueBySource('createdAt')) : null;

  if (activityDate && createdAtDate) {
    const diff = createdAtDate.diff(activityDate, 'hours');
    if (diff > HOURS_IN_DAY) {
      return true;
    }
  }

  return false;
};

const PastDueMark = () => {
  const { data: entity } = useShowContext();

  return shouldRenderPastDueMark(entity) ? (
    <div className="timeline-expired-activity">
      <i className="fa fa-exclamation-circle" title="Activity added too late" />
    </div>
  ) : null;
};

export default PastDueMark;
