import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';

const EmployeesInput = (props: any) => {
  const { searchAll: searchEmployeeSuggestions } = useEmployeeSuggestionsService();
  const { getValue, initializeValue } = useInputHelper(props);

  const getEmployeeSuggestions = async (query: any) => {
    return searchEmployeeSuggestions({
      filter: {
        searchString: {
          ct: query,
        },
      },
    });
  };

  const mapEntry = (item: any) => ({
    value: item.alias,
    label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''} (${item.workingEmail ?? ' — '})`,
  });

  const formatData = (selectData: any[]) => selectData.map(mapEntry);

  const rawValue = (getValue() || []) as any[];
  const value = rawValue.map((item: any) => (item.value ? item : mapEntry(item)));

  useEffect(() => {
    initializeValue(value);
  }, [initializeValue, value]);

  return (
    <MultiSelectInput {...props} value={value} loadSuggestionsMethod={getEmployeeSuggestions} mapResults={formatData} />
  );
};

export default EmployeesInput;
