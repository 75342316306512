/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  READ_PURCHASE_ORDER,
  PURCHASE_ORDER_PATH,
  READ_PURCHASE_ORDER_LIST,
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import PurchaseOrderList from 'financialAnalytic/purchaseOrder/List';
import PurchaseOrderViewPage from 'financialAnalytic/purchaseOrder/Show';
import PurchaseOrderCreatePage from 'financialAnalytic/purchaseOrder/createupdate/Create';
import UpdatePurchaseOrderPage from 'financialAnalytic/purchaseOrder/createupdate/Update';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const usePurchaseOrderRoute = () => {
  const routers = useRoute({ listRoute: PURCHASE_ORDER_PATH });

  return { ...routers, copyEntityRoute: `${routers.singleEntityRoute}/copy` };
};

export const usePurchaseOrderUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: PURCHASE_ORDER_PATH,
  });

  return { ...baseUrls, getCopyEntityUrl: (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/copy` };
};

export const usePurchaseOrderId = () => useParams<Dictionary<string>>().id;

const PurchaseOrderRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, copyEntityRoute, updateRoute } = usePurchaseOrderRoute();

  return (
    <ProtectedRouter basePath={PURCHASE_ORDER_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_PURCHASE_ORDER_LIST}>
          <PurchaseOrderList />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_PURCHASE_ORDER}>
          <PurchaseOrderCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={copyEntityRoute} exact globalPermissionToCheck={CREATE_PURCHASE_ORDER}>
          <PurchaseOrderCreatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={UPDATE_PURCHASE_ORDER}>
          <UpdatePurchaseOrderPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={READ_PURCHASE_ORDER}>
          <PurchaseOrderViewPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

PurchaseOrderRouter.getRouterPath = () => PURCHASE_ORDER_PATH;

export default PurchaseOrderRouter;
