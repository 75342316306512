/* istanbul ignore file */
import React, { useState } from 'react';
import { METRICS } from 'erp/employee/performanceReport/usePerformanceReportService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import PerformanceHeatmapGraph from './DailyPerformanceHeatmap/PerformanceHeatmapGraph';
import PerformanceHeatmapMonthlyGraph from './MonthlyPerformanceHeatmap/PerformanceHeatmapMonthlyGraph';
import PerformanceHeatmapLayout from 'erp/employee/performanceReport/shared/PerformanceHeatmap/layout/PerformanceHeatmapLayout';
import PerformanceHeatmapTypeButton, {
  PerformanceHeatmapType,
} from 'erp/employee/performanceReport/shared/PerformanceHeatmapTypeButton';

const PerformanceHeatmap = ({ labels, filter }: { labels: Array<METRICS | 'DIVIDER'>; filter: React.ReactNode }) => {
  const { data, filter: { period: { eq: currentMonth = '' } = {} } = {} } = useListContext();
  const items = data?.items as any;
  const metrics = items?.metrics || {};
  const [activeMode, setActiveMode] = useState<PerformanceHeatmapType>();
  const graph =
    activeMode === PerformanceHeatmapType.DAILY ? (
      <PerformanceHeatmapGraph labels={labels} currentMonth={currentMonth} metrics={metrics} />
    ) : (
      <PerformanceHeatmapMonthlyGraph labels={labels} currentMonth={currentMonth} metrics={metrics} />
    );

  return (
    <PerformanceHeatmapLayout
      filter={
        <>
          {filter}
          <PerformanceHeatmapTypeButton initialMode={activeMode} onModeChange={newMode => setActiveMode(newMode)} />
        </>
      }
    >
      {activeMode ? graph : null}
    </PerformanceHeatmapLayout>
  );
};

export default PerformanceHeatmap;
