/* istanbul ignore file */
import React from 'react';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { BadgeField, DateField, ListColumnTotalField, PercentField, SearchLinkField } from 'shared/uibuilder/field';
import { EmployeeLinkField } from 'erp/employee';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import { CREATE_ASSIGNMENT } from 'erp/assignment/shared/assignmentService';
import CopyAssignmentButton from 'erp/assignment/shared/button/CopyAssignmentButton';
import EditAssignmentButton from 'erp/assignment/shared/button/EditAssignmentButton';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import AssigneeField from 'erp/assignment/shared/field/AssigneeField';
import PositionInAssignmentField from 'erp/assignment/shared/field/PositionInAssignmentField';
import DeleteAssignmentFromListButton from 'erp/assignment/shared/button/DeleteAssignmentFromListButton';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import BenchBadge from 'erp/employee/assignments/shared/field/BenchBadge';
import AssignmentTargetLinkField from 'erp/assignment/target/shared/field/AssignmentTargetLinkField';

export const getMinPercentLabel = (totalMinPercent: number) => (
  <span className="text-left d-inline-block">
    <span>Min Percent</span>
    <span className="d-block">(Total: {totalMinPercent}%)</span>
  </span>
);

export const getMaxPercentLabel = (totalMaxPercent: number) => (
  <span className="text-left d-inline-block">
    <span>Max Percent</span>
    <span className="d-block">(Total: {totalMaxPercent}%)</span>
  </span>
);

const AssignmentListLayout = () => {
  const { getSingleEntityUrl } = useAssignmentUrl();
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(getUserAlias);

  return (
    <DataGridLayout
      tableWrapperClassName="assignment-table-wrapper"
      buttons={[
        <CopyAssignmentButton source="id" permissionToCheck={CREATE_ASSIGNMENT} outline />,
        <EditAssignmentButton
          key="edit-assignment"
          source="id"
          outline
          isVisible={(data: ListItem) => data.canEdit}
          permissionToCheck=""
        />,
        <DeleteAssignmentFromListButton key="delete-assignment" userAlias={userAlias} />,
      ]}
    >
      <BadgeField
        badges={[<BenchBadge isVisible={assignment => assignment.bench} />]}
        source="id"
        label="ID"
        isSortable
        style={{ display: 'inline-flex' }}
      >
        <SearchLinkField url={getSingleEntityUrl} width={70} />
      </BadgeField>
      <AssignmentTargetLinkField
        label="Target"
        source="target.id"
        nameSource="target.displayName"
        typeSource="target.type"
        width={130}
      />
      <EmployeeLinkField
        source="ownerId"
        sortSource="ownerNameEn"
        nameSource="ownerName"
        label="Owner"
        isSortable
        width={130}
      />
      <AssigneeField source="assignmentType" label="Assignee" width={130} />
      <EmployeeLinkField
        source="resourceManagerId"
        nameSource="resourceManagerName"
        label="Resource Manager"
        width={140}
      />
      <PositionInAssignmentField label="Position" source="assignmentType" width={120} />
      <EmployeeLinkField
        source="backupForEmployeeId"
        nameSource="backupForEmployeeName"
        label="Backup For"
        isSortable
        width={130}
      />
      <DateField source="from" label="Start Date" isSortable width={100} />
      <DateField source="to" label="End Date" isSortable width={100} />
      <BudgetLinkField label="Budget" source="budget.id" nameSource="budget.name" width={140} />
      <PercentField
        source="minPercent"
        label={<ListColumnTotalField source="minPercent" value={getMinPercentLabel} />}
        isSortable
        width={120}
      />
      <PercentField
        source="maxPercent"
        label={<ListColumnTotalField source="maxPercent" value={getMaxPercentLabel} />}
        isSortable
        width={120}
      />
    </DataGridLayout>
  );
};

export default AssignmentListLayout;
