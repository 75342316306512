import React, { useMemo } from 'react';
import { WRITE_TRAINING_DOMAIN } from '../../useTrainingsService';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import List from 'shared/uibuilder/list/List';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import CreateButton from 'uibuilder/button/CreateButton';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { useTrainingInstancesUrl } from '../TrainingInstancesRouter';
import useTrainingInstancesService, {
  TRAINING_INSTANCE_STATUS_OPTIONS,
  TRAINING_INSTANCE_STATUSES,
} from '../useTrainingInstancesService';
import LinkTrainingField from '../shared/fields/LinkTrainingField';
import EnumField from 'shared/uibuilder/field/EnumField';
import DateField from 'shared/uibuilder/field/DateField';
import TrainingInstancesStatusFilter from '../shared/filter/TrainingInstancesStatusFilter';
import { EmployeeFilter } from 'erp/employee';
import ViewTrainingInstancesButton from '../shared/Buttons/ViewTrainingInstancesButton';

const TrainingInstancesList = () => {
  const { search } = useTrainingInstancesService();

  const { getCreateUrl } = useTrainingInstancesUrl();

  const filters = useMemo(
    () => (
      <Filters>
        <FiltersLayout
          quickFilters={[
            <QuickFilter
              key="status"
              label="All draft"
              state={{ status: { in: [TRAINING_INSTANCE_STATUSES.DRAFT] } }}
            />,
          ]}
        >
          <TrainingInstancesStatusFilter source="status" label="Status" />
          <EmployeeFilter label="Mentors" source="mentors" />
          <EmployeeFilter label="Students" source="students" />
        </FiltersLayout>
      </Filters>
    ),
    [],
  );

  return (
    <List getDataMethod={search}>
      <ListPageLayout
        filter={filters}
        header={<ListHeader label="Training Instances" icon="fa-folder-o" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Training Instance"
            key="create-training-instances"
            permissionToCheck={WRITE_TRAINING_DOMAIN}
          />,
        ]}
        search={<SearchInput label="Training Program" />}
      >
        <DataGridLayout buttons={[<ViewTrainingInstancesButton source="id" />]}>
          <LinkTrainingField source="training" label="Training Program" width="20%" />
          <EnumField isSortable options={TRAINING_INSTANCE_STATUS_OPTIONS} source="status" label="Status" />
          <DateField isSortable source="startDate" label="Start Date" />
          <DateField isSortable source="endDate" label="End Date" />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default TrainingInstancesList;
