import React from 'react';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import CorporateEventBreadCrumbs from 'financialAnalytic/corporateEvents/shared/CorporateEventBreadCrumbs';

const UpdateCorporateEventBreadcrumbs = () => {
  return (
    <CorporateEventBreadCrumbs>
      <UpdateBreadcrumbItem entity="Corporate Event" />
    </CorporateEventBreadCrumbs>
  );
};

export default UpdateCorporateEventBreadcrumbs;
