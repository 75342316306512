import useEmployeeContractsService from '../shared/employeeContractsService';
import useAsyncValue from '../../../../shared/asyncHelper';

const useGetAllContracts = employeeAlias => {
  const { getAll } = useEmployeeContractsService();

  const contracts = useAsyncValue(async () => getAll(employeeAlias));
  const result = contracts ? contracts.result : [];

  return result;
};
export default useGetAllContracts;
