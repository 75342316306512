/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeContractsListBreadCrumbs from 'erp/employee/contracts/shared/ContractsBreadcrumbs';
import { RelatedContractsList } from 'erp/contracts';

const EmployeeContractsList = () => {
  return (
    <ShowPageLayout
      menu={<EmployeeMenu />}
      entityHeader={<EmployeeEntityHeader />}
      breadcrumbs={<EmployeeContractsListBreadCrumbs />}
    >
      <RelatedContractsList />
    </ShowPageLayout>
  );
};

export default EmployeeContractsList;
