/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import CareerDevelopmentModeDropdown from 'erp/employee/qualification/shared/input/CareerDevelopmentModeDropdown';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import useEmployeeService from 'erp/employee/employeeService';
import { AfterSubmitProps } from 'shared/uibuilder/form/formHelper';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';

interface ChangeCareerDevelopmentEvaluationModeFormProps {
  closeModal: () => void;
  modalOpen: boolean;
  currentCareerDevelopmentMode: string;
  afterSubmit: AfterSubmitProps<(response: object) => void>;
}

const ChangeCareerDevelopmentEvaluationModeForm = ({
  closeModal,
  modalOpen,
  currentCareerDevelopmentMode,
  afterSubmit,
}: ChangeCareerDevelopmentEvaluationModeFormProps) => {
  const employeeId = useEmployeeId() || '';
  const { update, getCareerReviewValidationSchema } = useEmployeeService();
  const { addMessage } = useMessageService();

  return (
    <ModalForm
      getValidationSchemaFunc={getCareerReviewValidationSchema}
      modalOpen={modalOpen}
      closeModal={closeModal}
      submitFormFunc={data => update(employeeId, data)}
      afterSubmit={{
        ...afterSubmit,
        execute: response => {
          if (afterSubmit && afterSubmit.execute) {
            afterSubmit.execute(response);
          }

          addMessage(new SuccessMessage('Evaluation mode successfully updated'));
        },
      }}
      initialData={{
        careerDevelopmentEvaluationMode: currentCareerDevelopmentMode,
      }}
    >
      <FormSection>
        <CareerDevelopmentModeDropdown
          label="Evaluation mode"
          source="careerDevelopmentEvaluationMode"
          placeholder={currentCareerDevelopmentMode ? '' : 'Select evaluation mode'}
          disabledValue={currentCareerDevelopmentMode}
          className="mb-6"
        />
      </FormSection>
    </ModalForm>
  );
};

export default ChangeCareerDevelopmentEvaluationModeForm;
