/* istanbul ignore file */
import React from 'react';
import BigFormRow from 'shared/layout/form/BigFormRow';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';

interface VerticalAddressInputLayoutProps {
  children: React.ReactElement;
}

const VerticalAddressInputLayout = ({ children }: VerticalAddressInputLayoutProps) => {
  return (
    <UiThemeOverrider
      overriddenLayouts={{
        AddressInputLayout: BigFormRow,
      }}
    >
      {children}
    </UiThemeOverrider>
  );
};

export default VerticalAddressInputLayout;
