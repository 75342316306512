/* istanbul ignore file */
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { BOOLEAN_OPTIONS } from 'shared/uibuilder/form/input/BooleanDropdownOptionInput';
import TextField from './TextField';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

const BooleanOptionField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);

  const fieldValue = getValue();

  const optionValue = BOOLEAN_OPTIONS.find(option => option.value === fieldValue);

  const displayedValue = optionValue ? optionValue.label : EMPTY_VALUE_INDICATOR;

  return <TextField value={displayedValue} {...props} />;
};

export default BooleanOptionField;
