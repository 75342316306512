import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import workplaceValidation from 'erp/room/workplace/shared/workplaceValidation';

export const READ_WORKPLACE_LIST = 'WORKPLACE_READ_LIST';
export const READ_WORKPLACE = 'WORKPLACE_READ';
export const CREATE_WORKPLACE = 'WORKPLACE_CREATE';
export const UPDATE_WORKPLACE = 'WORKPLACE_UPDATE';
export const DELETE_WORKPLACE = 'WORKPLACE_DELETE';

export const WORKPLACE_PATH = '/workplaces';
export const RESOURCE_URL = '/workplaces';

const useWorkplaceService = () => {
  const { data } = useShowContext();

  const { sendPostRequest, sendDeleteRequest } = useKernelApi();

  const { search: baseSearch, getById: initialGetById, update: initialUpdate, ...baseCrud } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const searchWorkplacesForRoom = async () => {
    const searchRequest = {
      filter: {
        'room.id': {
          eq: data.getValueBySource('id'),
        },
      },
    };
    const workplaces = await baseSearch(searchRequest);
    workplaces.result.forEach((workplace: any) => {
      // eslint-disable-next-line no-param-reassign
      workplace.displayName = `${data.getValueBySource('roomNumber')}-${workplace.workplaceNumber}`;
    });
    return workplaces;
  };

  const assignWorkplace = async (id: string, employee: string) => {
    const response = await sendPostRequest(`/workplaces/${id}/employee?employeeAlias=${employee}`);
    return response.json();
  };

  const getValidationSchema = () => Promise.resolve(workplaceValidation);

  const unassignWorkplace = async (id: string) => {
    await sendDeleteRequest(`/workplaces/${id}/employee`);
  };

  return {
    searchWorkplacesForRoom,
    assignWorkplace,
    unassignWorkplace,
    getValidationSchema,
    ...baseCrud,
  };
};

export default useWorkplaceService;
