/* istanbul ignore file */
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import 'erp/costaccounting/dashboard/MappingMetrics.scss';
import CircularProgressWithLabel from 'uibuilder/CircularProgressWithLabel';
import useMetricsHelper from 'erp/costaccounting/shared/metricsHelper';

interface MappingMetricsProps {
  current: number;
  expected: number;
  measurementName: string;
}

const MappingMetrics = ({ current, expected, measurementName }: MappingMetricsProps) => {
  const { calculatePercentage, calculateReversePercentage, subtractNumbers } = useMetricsHelper();
  const data = {
    mappedCount: current,
    mappedPercentage: calculatePercentage(current, expected, 1),
    unmappedCount: subtractNumbers(expected, current),
    unmappedPercentage: calculateReversePercentage(current, expected, 1),
    totalCount: expected,
  };

  return (
    <div className="mapping-metrics-wrapper">
      <CircularProgressWithLabel
        value={data.mappedPercentage}
        getColorFunc={value => {
          if (value < 95) return 'error';
          if (value < 99) return 'warning';
          return 'success';
        }}
      />
      <div className="mapping-metrics-inner">
        <SectionRow>
          <TextField label={`Mapped ${measurementName}`} value={data.mappedCount} />
          <TextField label={`Mapped ${measurementName}, %`} value={data.mappedPercentage} />
        </SectionRow>
        <SectionRow>
          <TextField label={`Unmapped ${measurementName}`} value={data.unmappedCount} />
          <TextField label={`Unmapped ${measurementName}, %`} value={data.unmappedPercentage} />
        </SectionRow>
        <SectionRow>
          <TextField label={`Total ${measurementName}`} value={data.totalCount} />
        </SectionRow>
      </div>
    </div>
  );
};

export default MappingMetrics;
