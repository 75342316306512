import React from 'react';
import NameDescriptionInput from 'erp/recruitment/vacancy/shared/input/NameDescriptionInput';

interface VacancyRequirementInputProps {
  source: string;
}

const VacancyRequirementInput = ({ source, ...props }: VacancyRequirementInputProps) => {
  return <NameDescriptionInput nameLabel="Competency" source={source} {...props} />;
};

export default VacancyRequirementInput;
