/* istanbul ignore file */
import React, { useMemo } from 'react';
import Grid from 'uibuilder/Grid';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import TextButton from 'uibuilder/button/TextButton';
import useInputRow from './useInputRow';

export type CustomerDependencyType = {
  description: string;
  name: string;
  fromPreviousReport: boolean;
  createdDate: string;
  waitingDate: string;
  closedDate: string;
};

interface CustomerDependencyInputRowProps {
  data: CustomerDependencyType;
  id: number;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

export const getCustomerDependencySource = (id: number) => {
  return `weeklyReportData.customerDependencies[${id}]`;
};

const CustomerDependencyInputRow = ({
  data,
  id,
  formData,
  updateFormData,
  getErrorMessages,
}: CustomerDependencyInputRowProps) => {
  const source = getCustomerDependencySource(id);
  const { getOnChangeCallback, removeRow } = useInputRow({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'customerDependencies',
  });

  const isDisabledSpecific = useMemo(() => {
    return data.fromPreviousReport && !!data.closedDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.fromPreviousReport, id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <TextButton
          source={`${source}.description`}
          label={id === 0 ? 'Description' : ''}
          value={data.description}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('description')}
          isRequired
          title="Description"
          errors={getErrorMessages(`${source}.description`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextInput
          source={`${source}.name`}
          label={id === 0 ? 'Name' : ''}
          value={data.name}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('name')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.createdDate`}
          label={id === 0 ? 'Created date' : ''}
          value={data.createdDate}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('createdDate')}
          isRequired
          minDate={formData.firstReport ? null : formData.from}
          maxDate={formData.firstReport ? null : formData.to}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.waitingDate`}
          label={id === 0 ? 'Waiting date' : ''}
          value={data.waitingDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('waitingDate')}
          isRequired
          minDate={data.createdDate}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.closedDate`}
          label={id === 0 ? 'Closed date' : ''}
          value={data.closedDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('closedDate')}
          minDate={data.createdDate}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <RemoveListItemButton isVisible={!data.fromPreviousReport} onClickCallback={removeRow} isFirstRow={id === 0} />
      </Grid>
    </Grid>
  );
};

export default CustomerDependencyInputRow;
