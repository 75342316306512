import { useBusinessEngineApi } from 'api';

const RESOURCE_URL = '/camunda/davinci-api/incidents';

const useIncidentService = () => {
  const { sendGetRequest } = useBusinessEngineApi();

  const getIncidentsInfo = async () => {
    const incidentsInfo = await sendGetRequest(`${RESOURCE_URL}/info`);
    const json = await incidentsInfo.json();
    return json;
  };

  const getTotalCount = async (): Promise<number> => {
    const { incidentCount } = await getIncidentsInfo();
    return incidentCount;
  };

  return {
    getTotalCount,
  };
};

export default useIncidentService;
