/* istanbul ignore file */
import React from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { TextField } from 'shared/uibuilder/field';
import { getHumanReadableDataSize } from 'shared/fileHelper';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

type ArtifactStorageSizeFieldProps = {
  label: string;
  source: string;
  isVisible?: boolean | ((values: FormFieldsData) => boolean);
};

const ArtifactStorageSizeField = ({ source, ...props }: ArtifactStorageSizeFieldProps) => {
  const { getValue } = useFieldHelper({ source });

  return <TextField {...props} source={source} value={getHumanReadableDataSize(getValue())} />;
};

export default ArtifactStorageSizeField;
