import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';
import { useAssetId, useAssetUrl } from '../../AssetRouter';

const AllNotesButton = () => {
  const id = useAssetId();
  const { getTimelineUrl } = useAssetUrl();

  return (
    <LinkButton
      url={getTimelineUrl(id)}
      outline
      startIcon={
        <i
          style={{
            fontSize: '1.2em',
          }}
          className="fa fa-sticky-note"
          aria-hidden
        />
      }
    >
      <span>View all notes</span>
    </LinkButton>
  );
};

export default AllNotesButton;
