import React from 'react';
import { BudgetInputProps } from 'erp/budget/shared/input/BudgetInput';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';

export interface AssignmentBudgetsDropdownProps extends BudgetInputProps {
  isVisible: boolean;
}

const AssignmentBudgetsDropdown = ({ isVisible, ...props }: AssignmentBudgetsDropdownProps) => {
  const { data, onChangeCallback } = useFormContext();

  const extractAvailableBudgets = () => {
    const budgets = data?.target?.budgetInfo?.availableBudgets || [];

    return budgets.map((budget: any) => ({ label: budget.name, value: budget.id }));
  };

  const onBudgetChange = ({ budgetId }: FormFieldsData) => {
    let budgetIdToSet = budgetId;
    let budgetToSet;
    if (!budgetId) {
      budgetIdToSet = data?.target?.budgetInfo?.defaultBudget?.id;
      budgetToSet = data?.target?.budgetInfo?.defaultBudget;
    } else {
      budgetToSet = data?.target?.budgetInfo?.availableBudgets?.filter((b: any) => `${b.id}` === budgetId)[0];
    }
    if (onChangeCallback)
      onChangeCallback({
        budgetId: budgetIdToSet,
        budget: budgetToSet,
      });
  };

  return data?.target ? (
    <ClearAfterUnmountWrapper>
      <Dropdown
        onChangeCallback={onBudgetChange}
        placeholder="Choose budget"
        options={extractAvailableBudgets()}
        isVisible={assignment =>
          (!!assignment?.target?.budgetInfo?.defaultBudget?.id || !!assignment?.target?.budgetInfo?.availableBudgets) &&
          isVisible
        }
        {...props}
      />
    </ClearAfterUnmountWrapper>
  ) : (
    <></>
  );
};

export default AssignmentBudgetsDropdown;
