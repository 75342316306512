const UNIX_START_YER = 1970;

/**
 * Calculates age by the given date of birth (string)
 */
const calculateAge = (date: string): number => {
  const diff = Date.now() - new Date(date).getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - UNIX_START_YER);
};

export default calculateAge;
