/* istanbul ignore file */
import React from 'react';
import { useEmployeeContractUrl } from './EmployeeContractsRouter';

const useContractUrl = () => {
  const { getSingleEntityUrl } = useEmployeeContractUrl();

  const getContractUrl = contractId => {
    return (
      <>
        {' '}
        <a href={`${getSingleEntityUrl(contractId)}`} target="_blank" rel="noopener noreferrer">
          {contractId}
        </a>
      </>
    );
  };

  return {
    getContractUrl,
  };
};

// We use ignoreParams to change message for contract view page to ignore error parameters
const useContractsErrorMap = (ignoreParams = false) => {
  const { getContractUrl } = useContractUrl();

  return {
    'CONTRACT-BR-1': ignoreParams ? (
      <>Gap between contracts is more than 1 day</>
    ) : (
      contractId => <>Gap between contract {getContractUrl(contractId)} and the new one is more than 1 day</>
    ),
    'CONTRACT-BR-2': contractIds => (
      <>This contract dates overlap contract {getContractUrl(contractIds)}, please change contract dates</>
    ),
    'CONTRACT-BR-3': <>Probation period can not be set for contract renewal</>,
  };
};

export default useContractsErrorMap;
