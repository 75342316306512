import * as PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Page403 from './Page403';

import Loading from 'shared/uibuilder/Loading';
import useSecurityReactContext, { SecurityProvider } from './react-context/SecurityReactContext';
import convertArrayToObject from 'shared/authorization/utils';
import { Permission, PermissionsMap } from './authorizationService';
import { ResourceId } from '../crud/baseCrudService';
import { UrlParams } from '../routing/useEntityUrl';

export interface SecurityContextCommonProps {
  children: React.ReactNode;
  id?: ResourceId;
  idSource?: string;
  permissionToCheck?: Nullable<Permission | Permission[]>;
}

interface SecurityContextTemplateProps extends SecurityContextCommonProps {
  contextName: string;
  getPermissionsFunc: (id: ResourceId) => Permission[];
}

const defaultIdParam = 'id';

function checkPermission(
  permissionToCheck: Nullable<Permission | Permission[]>,
  contextPermissions: Nullable<PermissionsMap>,
) {
  const permissionsArray = Array.isArray(permissionToCheck) ? permissionToCheck : [permissionToCheck];

  return (
    !permissionToCheck ||
    (contextPermissions && permissionsArray.some(permission => contextPermissions[permission as string]))
  );
}

/**
 * The component contains the common logic of security contexts. Technically, this component adds
 * permissions defined in the scope of specific entity to global permissions.
 *
 * In the application security model there are permissions defined on different levels. Some
 * permissions are defined globally, the others are defined and applied for specific entity.
 * Each level on which permissions are defined is called Context. There are the following levels of
 * permissions defined in current application:
 * - Global
 * - Employee
 * - Lead
 * - etc.
 *
 *
 * @param children child component
 * @param id id of entity may be passed as a prop, by default it's read from uri
 * @param idSource if indicated value from useParams() with this property will be taken
 * @param getPermissionsFunc the promise that returns permissions
 * @param permissionToCheck if user has no this permission - 403 page is displayed
 */
const SecurityContextTemplate = ({
  children,
  contextName,
  id,
  idSource,
  getPermissionsFunc,
  permissionToCheck = [],
}: SecurityContextTemplateProps) => {
  const parentContext = useSecurityReactContext() || {};

  const [contextPermissions, setContextPermissions] = useState<Nullable<PermissionsMap>>(null);

  // is HTTP request is sent in current moment
  const [isLoading, setIsLoading] = useState(false);
  // if state is initialized
  const [isInitialized, setIsInitialized] = useState(false);

  const urlParams: UrlParams = useParams();
  const paramsId = urlParams[idSource || defaultIdParam];

  const entityId = id || paramsId;

  const loadPermissions = useCallback(
    async (idOfEntity: ResourceId) => {
      if (!isInitialized && !isLoading) {
        setIsLoading(true);

        try {
          const permissions = await getPermissionsFunc(idOfEntity);
          setContextPermissions(convertArrayToObject(permissions));
          setIsLoading(false);
        } catch (error) {
          if ((error as Response).status === 404) {
            // @ts-ignore
            setContextPermissions([]);
            setIsLoading(false);
          } else {
            throw new Error('Error occurred. Please, reload the page');
          }
        } finally {
          setIsInitialized(true);
        }
      }
    },
    [isLoading, isInitialized, getPermissionsFunc],
  );

  useEffect(() => {
    if (!contextPermissions) {
      loadPermissions(entityId);
    }
  }, [contextPermissions, entityId, loadPermissions]);

  useEffect(() => {
    setIsInitialized(false);
    setContextPermissions(null);
  }, [entityId]);

  const context = {
    ...parentContext,
    currentContext: contextName,
    [contextName]: contextPermissions,
  };

  const hasPermission = checkPermission(permissionToCheck, contextPermissions);

  if (isInitialized && !hasPermission) {
    return <Page403 />;
  }

  if (isLoading || !isInitialized) {
    return <Loading />;
  }

  return <SecurityProvider value={context}>{children}</SecurityProvider>;
};

SecurityContextTemplate.propTypes = {
  children: PropTypes.element,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idSource: PropTypes.string,
  getPermissionsFunc: PropTypes.func.isRequired,
  permissionToCheck: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  contextName: PropTypes.string.isRequired,
};

SecurityContextTemplate.defaultProps = {
  id: null,
  idSource: null,
  children: null,
  permissionToCheck: null,
};

export default SecurityContextTemplate;
