/* istanbul ignore file */
import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import Tooltip from 'uibuilder/Tooltip';
import { copyToClipboard } from 'shared/clipboard';

interface Props {
  id: string;
  value: string;
  children: any;
  className?: string;
  isIcon?: boolean;
}

const STYLE = {
  outline: 'none',
  boxShadow: 'none',
  padding: 0,
};

const CopyToClipboardButton = ({ id, value, children, className, isIcon = true }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = () => {
    copyToClipboard(value);
    if (!isIcon) {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    }
  };

  const wrapperClass = `d-inline-block${className ? ` ${className}` : ''}`;
  const buttonText = isCopied ? <>Copied!</> : children;

  return (
    <div className={wrapperClass}>
      <Button
        type="button"
        onClick={onClick}
        style={isIcon ? STYLE : {}}
        link={!!isIcon}
        outline
        color="neutral"
        sx={{ minWidth: '1px' }}
      >
        {isIcon ? (
          <Tooltip title={children} placement="right" id={id}>
            <LinkIcon id={id} aria-label="Copy to clipboard" sx={{ fontSize: '21px' }} />
          </Tooltip>
        ) : (
          buttonText
        )}
      </Button>
    </div>
  );
};

export default CopyToClipboardButton;
