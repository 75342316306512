/* istanbul ignore file */
import React from 'react';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import { string } from 'prop-types';

const VacancyWysiwygInput = ({ ...props }) => {
  const editorConfig = ['bold', '|', 'bulletedList', 'numberedList', '|', 'heading'];

  return <WysiwygInput editorConfig={editorConfig} {...props} />;
};

VacancyWysiwygInput.propTypes = {
  artifactsSource: string.isRequired,
};

export default VacancyWysiwygInput;
