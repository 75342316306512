/* istanbul ignore file */
import React from 'react';
import './ProjectAllocation.scss';

type Allocation = {
  label: string;
  value: React.ReactNode;
};

interface ProjectAllocationLayoutProps {
  hasAllocation?: boolean;
  projectId: StringOrNumber;
  projectName: string;
  allocations: Allocation[];
}

const ProjectAllocationLayout = ({
  projectId,
  projectName,
  allocations,
  hasAllocation,
}: ProjectAllocationLayoutProps) => {
  if (!projectId) {
    return null;
  }

  return (
    <div className="daily-chickin__project-allocation">
      <h3 className="project-allocation__title">{projectName}</h3>
      {hasAllocation ? (
        allocations.map(({ label, value }) => (
          <div key={label} className="project-allocation">
            <div className="project-allocation__label">{label}:</div>
            <div className="project-allocation__value">{value}</div>
          </div>
        ))
      ) : (
        <div>
          There is no time allocation on the project. Submit{' '}
          <a href="https://davinci.syberry.com/one-stop/requests/RM04" target="_blank" rel="noopener noreferrer">
            RM04 request
          </a>{' '}
          to discuss it with your RM
        </div>
      )}
    </div>
  );
};

export default ProjectAllocationLayout;
