/* istanbul ignore file */
import React from 'react';
import { node, string } from 'prop-types';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useServiceService from 'erp/service/shared/serviceService';

export const SERVICE_CONTEXT = 'Service';

const ServiceContext = ({ permissionToCheck, children }) => {
  const { getPermissions } = useServiceService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={SERVICE_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

ServiceContext.propTypes = {
  permissionToCheck: string.isRequired,
  children: node.isRequired,
};

export default ServiceContext;
