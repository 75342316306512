/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { TextField, EnumField, DateField } from 'shared/uibuilder/field';
import { CUSTOMER_INVOICE_STATUSES_OPTIONS, CUSTOMER_INVOICE_STATUSES } from '../useCustomerInvoiceService';
import InvoiceTotalField from '../../invoice/shared/field/InvoiceTotalField';
import RejectCustomerInvoiceButton from '../shared/buttons/RejectCustomerInvoiceButton';
import CancelPaymentCustomerInvoiceButton from '../shared/buttons/CancelPaymentCustomerInvoiceButton';
import MarkAsPaidCustomerInvoiceButton from '../shared/buttons/MarkAsPaidCustomerInvoiceButton';
import EditCustomerInvoiceButton from '../shared/buttons/EditCustomerInvoiceButton';
import SingleCustomerInvoiceBreadcrumbs from '../shared/SingleCustomerInvoiceBreadcrumbs';
import CorrectedInvoiceLinkField from '../shared/fields/CorrectedInvoiceLinkField';
import CustomerInvoiceHeader from '../shared/CustomerInvoiceHeader';

const ViewCustomerInvoicePageContent = ({
  isSensitiveDataIsShown = false,
  toggleSensitiveDataButton = <></>,
}: {
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: React.ReactElement;
}) => {
  return (
    <ShowPageLayout
      entityHeader={<CustomerInvoiceHeader />}
      breadcrumbs={<SingleCustomerInvoiceBreadcrumbs />}
      headerButtons={[
        toggleSensitiveDataButton,
        <MarkAsPaidCustomerInvoiceButton
          statusSource="status"
          isVisible={(data: any) => data.getValueBySource('status') === CUSTOMER_INVOICE_STATUSES.PENDING}
        />,
        <EditCustomerInvoiceButton
          statusSource="status"
          isVisible={(data: any) => data.getValueBySource('status') === CUSTOMER_INVOICE_STATUSES.PENDING}
        />,
        <RejectCustomerInvoiceButton
          statusSource="status"
          isVisible={(data: any) => data.getValueBySource('status') === CUSTOMER_INVOICE_STATUSES.PENDING}
        />,
        <CancelPaymentCustomerInvoiceButton
          statusSource="status"
          isVisible={(data: any) => data.getValueBySource('status') === CUSTOMER_INVOICE_STATUSES.PAID}
        />,
      ]}
      hasSeparateSections
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField label="name" source="name" />
          <TextField label="SOW ID" source="sowId" />
          <EnumField label="Status" source="status" options={CUSTOMER_INVOICE_STATUSES_OPTIONS} />
          <DateField label="Date" source="invoiceDate" />
        </SectionRow>

        <SectionRow>
          <TextField label="Invoice Number" source="invoiceNumber" />
          <InvoiceTotalField
            label="Invoice Sum"
            source="invoiceSum.amount"
            currencySource="invoiceSum.currency"
            isVisible={isSensitiveDataIsShown}
          />
        </SectionRow>

        <SectionRow>
          <CorrectedInvoiceLinkField label="Corrected Invoice Number" source="correctedInvoiceId" />

          <InvoiceTotalField
            label="Correction Sum"
            source="invoiceCorrectionSum.amount"
            currencySource="invoiceCorrectionSum.currency"
            isVisible={isSensitiveDataIsShown}
          />
        </SectionRow>

        <BigSectionRow>
          <TextField label="Description" source="description" />
        </BigSectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewCustomerInvoicePageContent;
