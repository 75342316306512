import React from 'react';
import Alert from 'uibuilder/Alert';
import useTimelineRegistryHelper from 'shared/uibuilder/timeline/timelineRegistryHelper';
import TimelineRecord from 'shared/uibuilder/timeline/TimelineRecordWrapper';
import Loading from 'shared/uibuilder/Loading';
import TimelinePagingHandler from 'shared/uibuilder/timeline/paging/TimelinePagingHandler';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineItem, TimelineItemId } from 'shared/uibuilder/timeline/timelineHelper';

import './Timeline.scss';
import { string } from 'prop-types';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Data from 'shared/uibuilder/Data';

const DEFAULT_FUNCTION = () => {};

interface TimelineLayoutProps {
  noEntriesMessage: string;
}

const TimelineLayout = ({ noEntriesMessage }: TimelineLayoutProps) => {
  const {
    data: { items = [] } = {},
    registries,
    currentEditingId,
    setCurrentEditingId = DEFAULT_FUNCTION,
    updateInList = DEFAULT_FUNCTION,
    wrapItem = () => ({}),
    loading,
    addForm,
    isHighlighted = () => false,
  }: TimelineContextData = useListContext();
  const { getRegistry } = useTimelineRegistryHelper(registries || []);
  const { data: parentContext } = useShowContext();

  const clearEditing = () => setCurrentEditingId(null);

  const renderUpdateForm = (
    UpdateFormTemplate: React.ReactElement,
    timelineItemEntity: TimelineItem,
    source: string,
    hasFullData: boolean,
    additionalAfterUpdateSubmit?: (arg: TimelineItem, parentContext?: Data) => void,
  ) => (
    // @ts-ignore
    <UpdateFormTemplate
      entityId={timelineItemEntity?.id}
      afterSubmit={{
        execute: (id: TimelineItemId, data: TimelineItem) => {
          updateInList(timelineItemEntity?.timelineId || null, hasFullData ? data : wrapItem(data, source));
          clearEditing();
          if (additionalAfterUpdateSubmit) {
            additionalAfterUpdateSubmit(data, parentContext);
          }
        },
      }}
      key={`form-${timelineItemEntity?.timelineId}`}
      getDataFunc={() => timelineItemEntity}
      onCancel={clearEditing}
    />
  );

  const renderLayout = (
    UpdateForm: React.ReactElement,
    RecordLayout: React.ReactElement,
    timelineItemEntity: TimelineItem,
    source: string,
    hasFullData: boolean,
    additionalAfterUpdateSubmit?: (arg: TimelineItem, parentContext?: Data) => void,
  ) => (
    // @ts-ignore
    <RecordLayout
      updateComponent={renderUpdateForm(
        UpdateForm,
        timelineItemEntity,
        source,
        hasFullData,
        additionalAfterUpdateSubmit,
      )}
      isHighlighted={isHighlighted(timelineItemEntity?.timelineId)}
      isEditing={currentEditingId === timelineItemEntity?.timelineId}
      onEdit={() => setCurrentEditingId(timelineItemEntity?.timelineId || null)}
      key={`record-${timelineItemEntity?.timelineId}`}
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {!items.length && !addForm && (
        <Alert severity="info" className="mt-3">
          {noEntriesMessage}
        </Alert>
      )}

      <ul className="timeline">
        {addForm}

        <TimelinePagingHandler>
          {items.map((item: TimelineItem) => {
            const {
              UpdateForm = <div />,
              source = '',
              RecordLayout = <div />,
              // @ts-ignore
              hasFullData = false,
              additionalAfterUpdateSubmit,
            } = getRegistry(item) || {};
            // @ts-ignore
            const timelineItemEntity = hasFullData ? item : item && item[source];

            return (
              <React.Fragment key={timelineItemEntity.id}>
                <TimelineRecord entity={timelineItemEntity} key={`wrapper-${timelineItemEntity.timelineId}`}>
                  {renderLayout(
                    UpdateForm,
                    RecordLayout,
                    timelineItemEntity,
                    source,
                    hasFullData,
                    additionalAfterUpdateSubmit,
                  )}
                </TimelineRecord>
              </React.Fragment>
            );
          })}
        </TimelinePagingHandler>
      </ul>
    </>
  );
};

TimelineLayout.propTypes = {
  noEntriesMessage: string,
};

TimelineLayout.defaultProps = {
  noEntriesMessage: 'No entries in this timeline yet.',
};

export default TimelineLayout;
