import { useBusinessEngineApi, useKernelApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';
import { OPPORTUNITY_PATH } from 'crm/opportunity/OpportunityRouter';
import presaleRequestValidation from 'crm/opportunity/presale/request/form/presaleRequestValidation';
import {
  showApplicationTypeField,
  showCollaborationExpectationFiled,
  showHasAutomationTestingField,
  showMaterialsField,
  showMobileOSField,
  showPercentTestCoverageField,
  showReplacementReasonField,
  showRequiredFteField,
  showScopeOfWorkField,
  showSystemCompletionField,
  showSystemHostingLocationField,
  showTeamInvolvementField,
  showThirdPartyField,
} from './presaleRequestFieldVisibilityHelper';

export const PRESALE_REQUEST_TYPE_ALIAS = {
  EXISTING_SYSTEM_DEVELOPMENT_COMPLETION: 'EXISTING_SYSTEM_DEVELOPMENT_COMPLETION',
  NEW_CREATION: 'NEW_CREATION',
  MODERNIZATION_OF_OUTDATED: 'MODERNIZATION_OF_OUTDATED',
  EXPANSION_OF_EXISTING_SYSTEM: 'EXPANSION_OF_EXISTING_SYSTEM',
  DEFECT_FIXING: 'DEFECT_FIXING',
  CONTRACT_EXTENSION: 'CONTRACT_EXTENSION',
};

export const PRESALE_REQUEST_TYPE = {
  [PRESALE_REQUEST_TYPE_ALIAS.EXISTING_SYSTEM_DEVELOPMENT_COMPLETION]: 'Existing system development completion',
  [PRESALE_REQUEST_TYPE_ALIAS.NEW_CREATION]: 'New product/system creation',
  [PRESALE_REQUEST_TYPE_ALIAS.MODERNIZATION_OF_OUTDATED]: 'Modernization of outdated system',
  [PRESALE_REQUEST_TYPE_ALIAS.EXPANSION_OF_EXISTING_SYSTEM]: 'Expansion of existing system functionality',
  [PRESALE_REQUEST_TYPE_ALIAS.DEFECT_FIXING]: 'Existing system defects fixing',
  [PRESALE_REQUEST_TYPE_ALIAS.CONTRACT_EXTENSION]: 'Contract extension',
};

export const APPLICATION_TYPE_ALIAS = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
};

export const APPLICATION_TYPE = {
  [APPLICATION_TYPE_ALIAS.WEB]: 'Web',
  [APPLICATION_TYPE_ALIAS.MOBILE]: 'Mobile',
};

export const MOBILE_OS_ALIAS = {
  APPLE: 'APPLE',
  ANDROID: 'ANDROID',
};

export const MOBILE_OS = {
  [MOBILE_OS_ALIAS.APPLE]: 'Apple',
  [MOBILE_OS_ALIAS.ANDROID]: 'Android',
};

export const REQUEST_STATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
};

const usePresaleOpportunityService = () => {
  const { sendPostRequest: sendEnginePost, sendPutRequest: sendEnginePut } = useBusinessEngineApi();
  const { sendGetRequest: sendKernelGet } = useKernelApi();

  const createPresaleRequest = async (opportunityId: ResourceId, request: any) => {
    const response = await sendEnginePost(`${OPPORTUNITY_PATH}/${opportunityId}/presale-requests`, request);

    return response.json();
  };

  const updatePresaleRequest = async (opportunityId: ResourceId, presaleId: ResourceId, request: any) => {
    const response = await sendEnginePut(`${OPPORTUNITY_PATH}/${opportunityId}/presale-requests/${presaleId}`, request);

    return response.json();
  };

  const getLastPresaleRequest = async (opportunityId: ResourceId) => {
    const response = await sendKernelGet(`${OPPORTUNITY_PATH}/${opportunityId}/presale-requests/last`);

    return response.json();
  };

  const mapInitialData = (data: any) => {
    return data
      ? {
          requestType: data.requestType,
          expectedCompletionAt: data.expectedCompletionAt,
          systemCompletionProgress: showSystemCompletionField(data) ? data.systemCompletionProgress : null,
          hasPreviousTeamInvolvement: showTeamInvolvementField(data) ? data.hasPreviousTeamInvolvement : null,
          hasAutomatedTesting: showHasAutomationTestingField(data) ? data.hasAutomatedTesting : null,
          automatedTestingCoverage: showPercentTestCoverageField(data) ? data.automatedTestingCoverage : null,
          applicationTypes: showApplicationTypeField(data) ? data.applicationTypes : null,
          mobileOperationSystems: showMobileOSField(data) ? data.mobileOperationSystems : null,
          hasThirdPartyIntegrations: showThirdPartyField(data) ? data.hasThirdPartyIntegrations : null,
          requiredFte: showRequiredFteField(data) ? data.requiredFte : null,
          collaborationExpectations: showCollaborationExpectationFiled(data) ? data.collaborationExpectations : null,
          systemHostingLocation: showSystemHostingLocationField(data) ? data.systemHostingLocation : null,
          replacementReason: showReplacementReasonField(data) ? data.replacementReason : null,
          scopeOfWork: showScopeOfWorkField(data) ? data.scopeOfWork : null,
          materials: showMaterialsField(data) ? data.materials : null,
        }
      : {};
  };

  return {
    createPresaleRequest,
    updatePresaleRequest,
    getLastPresaleRequest,
    mapInitialData,
    getValidationSchema: () => Promise.resolve(presaleRequestValidation),
  };
};

export default usePresaleOpportunityService;
