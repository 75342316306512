import React from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';

interface BudgetDropdownProps extends DefaultDropdownProps {
  loadDataMethod: () => ResultResourceData;
  showLoader?: boolean;
}

const BudgetDropdown = (props: BudgetDropdownProps) => {
  return (
    <LoadDropdown
      mapResults={budget => ({
        value: budget.id,
        label: budget.name,
      })}
      {...props}
    />
  );
};

export default BudgetDropdown;
