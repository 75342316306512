/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EmailField, TextField } from 'shared/uibuilder/field/index';
import React from 'react';
import useCandidateService from 'erp/candidate/shared/candidateService';
import { Link, useParams } from 'react-router-dom';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import NameField from 'shared/uibuilder/field/NameField';
import PhoneField from 'shared/uibuilder/field/PhoneField';
import AdditionalContactsField from 'erp/shared/field/AdditionalContactsField';
import { BigAvatar } from 'artifact/index';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { useExtensionRoute } from 'erp/candidate/extension/ExtensionRouter';
import { Button } from 'uibuilder/button';
import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined';
import './Show.scss';

const CandidateViewPage = () => {
  const { getProfileUrl } = useCandidateUrl();
  const { listRoute } = useExtensionRoute();
  const { getById } = useCandidateService();
  const { id } = useParams<Dictionary<string>>();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        entityHeader={<CandidateEntityHeader />}
        breadcrumbs={
          <div>
            <Button component={Link} to={listRoute} startIcon={<ArrowBackIcon />} sx={{ marginRight: '10px' }}>
              Back to form
            </Button>

            <Button component="a" href={getProfileUrl(id)} target="_blank" rel="noreferrer">
              Open candidate profile
            </Button>
          </div>
        }
      >
        <div className="menu-photo">
          <BigAvatar source="mainPhotoId" />
        </div>
        <ShowSection title="Main Information">
          <SectionRow colMd={6}>
            <TextField label="Full Name" value={<NameField source="name" />} />
          </SectionRow>
        </ShowSection>

        <ShowSection title="Contact Information">
          <SectionRow colMd={6}>
            <PhoneField source="personalPhoneNumber" />
            <EmailField source="personalEmail" label="Personal Email Address" />
            <PhoneField source="otherPhoneNumber" />
            <AdditionalContactsField source="additionalContacts" />
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default CandidateViewPage;
