/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem, UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import AssessmentsBreadcrumbs from 'erp/employee/assessment/shared/AssessmentsBreadcrumbs';
import { useAssessmentId, useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';

const UpdateAssessmentBreadcrumbs = () => {
  const { getListUrl } = useAssessmentUrl();
  const assessmentId = useAssessmentId();

  return (
    <AssessmentsBreadcrumbs>
      <SingleBreadcrumbItem entity={assessmentId} link={getListUrl()} />
      <UpdateBreadcrumbItem />
    </AssessmentsBreadcrumbs>
  );
};

export default UpdateAssessmentBreadcrumbs;
