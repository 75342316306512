/* istanbul ignore file */
import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { AfterSubmitProps } from 'shared/uibuilder/form/formHelper';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { ValidationSchema } from 'validation-schema-library';

type ModalFormButtonProps = {
  color?: BasicColors;
  label?: string;
  getValidationSchemaFunc?: () => Promise<ValidationSchema>;
  isVisible?: boolean;
  outline?: boolean;
  formTitle: React.ReactChild;
  afterSubmit?: AfterSubmitProps<(response: object) => void>;
  submitFormFunc: (values: FormFieldsData) => Promise<object>;
  initialData?: FormFieldsData;
  handleDirty?: boolean;
  children: React.ReactElement | React.ReactElement[];
};

const ModalFormButton = ({
  isVisible = true,
  formTitle,
  afterSubmit,
  getValidationSchemaFunc,
  submitFormFunc,
  initialData,
  children,
  label,
  color = 'primary',
  outline,
  handleDirty = true,
}: ModalFormButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);

  let result;
  if (isVisible) {
    result = (
      <>
        <Button
          outline={outline}
          color={color}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            setModalOpen(true);
            (e.target as HTMLElement).blur();
          }}
        >
          {label}
        </Button>
        <ModalWindow
          backdrop="static"
          isOpen={modalOpen}
          title={formTitle}
          onToggle={closeModal}
          hasCancelButton
          className="form-modal"
        >
          <ModalForm
            getValidationSchemaFunc={getValidationSchemaFunc}
            modalOpen={modalOpen}
            closeModal={closeModal}
            handleDirty={handleDirty}
            submitFormFunc={submitFormFunc}
            afterSubmit={afterSubmit}
            initialData={initialData}
          >
            {children}
          </ModalForm>
        </ModalWindow>
      </>
    );
  }
  return <>{result}</>;
};

export default ModalFormButton;
