/* istanbul ignore file */
import React from 'react';
import { useSearchParameter } from '../../shared/routing/helper';
import useLeavesErrorMap from './createupdate/errorHandlers';
import CategorizedBusinessRulesListField from './shared/field/CategorizedBusinessRulesListField';

const BalancesListPage = () => {
  const violatedRulesParam = useSearchParameter('rules');
  const violatedRules = violatedRulesParam ? JSON.parse(violatedRulesParam) : [];
  return <CategorizedBusinessRulesListField value={violatedRules} errorMap={useLeavesErrorMap()} />;
};

export default BalancesListPage;
