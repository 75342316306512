/* istanbul ignore file */
import { APPEND_EMPLOYEE_NAMES, CLEAR_EMPLOYEE_NAMES, REMOVE_EMPLOYEE_NAMES } from './employeeNamesService';

export const appendEmployeeNames = (employeeNames: any) => ({
  type: APPEND_EMPLOYEE_NAMES,
  employeeNames,
});

export const removeEmployeeNames = (employeeNamesAliases: string[]) => ({
  type: REMOVE_EMPLOYEE_NAMES,
  employeeNamesAliases,
});

export const clearEmployeeNames = () => ({
  type: CLEAR_EMPLOYEE_NAMES,
});
