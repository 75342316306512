import useStorageService from 'shared/uibuilder/storageService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

const usePositionService = () => {
  const { findAll: findAllFromApi } = useBaseCrudService({
    listResourceUrl: '/positions',
    apiService: useKernelApi,
  });

  const { getDataFromStorage } = useStorageService('positions', findAllFromApi, '1.17');

  const findPositionById = async id => {
    const positions = await getDataFromStorage();
    return positions.find(position => position.id === id) || {};
  };

  const findPositionByAlias = async alias => {
    const positions = await getDataFromStorage();
    return positions.find(position => position.alias === alias) || {};
  };

  return {
    findAll: getDataFromStorage,
    findPositionById,
    findPositionByAlias,
  };
};

export default usePositionService;
