/* istanbul ignore file */
import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EducationBreadcrumbs from 'erp/employee/Education/shared/EducationBreadcrumbs';
import { useEducationId, useEducationUrl } from 'erp/employee/Education/EducationRouter';

const SingleEducationBreadcrumbs = ({ children }) => {
  const { getSingleEntityUrl } = useEducationUrl();
  const id = useEducationId();

  return (
    <EducationBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </EducationBreadcrumbs>
  );
};

SingleEducationBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

SingleEducationBreadcrumbs.defaultProps = {
  children: null,
};

export default SingleEducationBreadcrumbs;
