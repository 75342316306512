/* istanbul ignore file */
import React from 'react';
import {
  useEmployeePerformanceId,
  useEmployeePerformanceUrl,
} from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import EmployeePerformanceBreadcrumbs from 'erp/employee/performanceEvaluation/employeePerformance/shared/EmployeePerformanceBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';

const ShowEmployeePerformanceBreadcrumbs = ({ children }: any) => {
  const performanceId = useEmployeePerformanceId();
  const { getSingleEntityUrl } = useEmployeePerformanceUrl();

  return (
    <EmployeePerformanceBreadcrumbs>
      <SingleBreadcrumbItem entity={performanceId} link={getSingleEntityUrl(performanceId)}>
        {children}
      </SingleBreadcrumbItem>
    </EmployeePerformanceBreadcrumbs>
  );
};

export default ShowEmployeePerformanceBreadcrumbs;
