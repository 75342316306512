/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import List from 'shared/uibuilder/list/List';
import usePerformanceReportService from 'erp/performanceReport/usePerformanceReportService';
import PerformanceReportsListHeatmap from './PerformanceReportsListHeatmap';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import EmployeeTypeFilter from 'erp/employee/List/filter/EmployeeTypeFilter';
import ResourcePoolFilter from 'erp/employee/List/filter/ResourcePoolFilter';
import EmployeePositionFilter from 'erp/employee/List/filter/EmployeePositionFilter';
import EmployeeProbationFilter from 'erp/employee/List/filter/EmployeeProbationFilter';
import OfficeDropdownFilter from 'erp/contracts/List/filter/OfficeDropdownFilter';
import EmployeeWorkingStatusFilter from 'erp/employee/List/filter/EmployeeWorkingStatusFilter';
import useAuthorization from 'shared/authorization/authorizationService';
import { DISMISSED_STATUS } from 'erp/employee/employeeService';
import PerformanceMetricFilter from 'erp/performanceReport/List/filter/PerformanceMetricFilter';

const PerformanceReportsListPage = () => {
  const { search } = usePerformanceReportService();
  const { isGranted } = useAuthorization();
  const currentMonth = moment().format(DATE_FORMAT.API_ONLY_MONTH);

  const filters = (
    <FiltersLayout>
      <EmployeeWorkingStatusFilter source="workingStatus" label="Working Status" excluded={[DISMISSED_STATUS]} />
      <EmployeeTypeFilter source="employeeType" label="Type" />
      <ResourcePoolFilter
        source="resourcePool.id"
        label="Resource Pool"
        isVisible={isGranted('EMPLOYEE_FILTER_PERFORMANCE_REPORTS')}
      />
      <EmployeePositionFilter source="position.id" label="Position" />
      <EmployeeProbationFilter source="specification:hasActiveProbation" label="Probation" />
      <OfficeDropdownFilter source="specification:office" label="Current Office" />
      <PerformanceMetricFilter source="metrics" label="Metric" />
    </FiltersLayout>
  );

  return (
    <List
      getDataMethod={search}
      defaultFilter={{
        period: {
          eq: currentMonth,
        },
      }}
      uncontrolledFilters={['period']}
    >
      <Filters>
        <ListPageLayout header={<ListHeader label="Performance report" />} filter={filters}>
          <PerformanceReportsListHeatmap />
        </ListPageLayout>
      </Filters>
    </List>
  );
};

export default PerformanceReportsListPage;
