/* istanbul ignore file */
import React from 'react';
import { EnumField } from 'shared/uibuilder/field';
import { EMPLOYEE_TYPES } from 'erp/employee/employeeService';

const EmployeeTypeField = props => {
  return <EnumField {...props} options={EMPLOYEE_TYPES} />;
};

export default EmployeeTypeField;
