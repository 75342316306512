export interface CriteriaDetails {
  positive: string;
  negative: string;
}

export interface RoleBasedEssentialImpactDetails {
  processCompliance: CriteriaDetails;
  security: CriteriaDetails;
  productivity: CriteriaDetails;
  learning: CriteriaDetails;
}

export interface CustomerSuccessImpactDetails {
  clientOutcomes: CriteriaDetails;
}

export interface EcosystemImpactDetails {
  organizationOutcomes: CriteriaDetails;
}

export interface PerformanceLevelCriteriaDetails {
  roleBasedEssentialImpact: RoleBasedEssentialImpactDetails;
  customerSuccessImpact: CustomerSuccessImpactDetails;
  ecosystemImpact: EcosystemImpactDetails;
  helpfulnessGrade: string;
  personalityTraits: string;
}

export interface LevelCriteriaSection {
  field: string;
  title: string;
}

export const ROLE_BASED_ESSENTIAL_IMPACT_SECTION: LevelCriteriaSection[] = [
  {
    field: 'processCompliance',
    title: 'Process compliance',
  },
  {
    field: 'security',
    title: 'Security',
  },
  {
    field: 'productivity',
    title: 'Productivity (velocity and quality)',
  },
  { field: 'learning', title: 'Learning' },
];

export const CUSTOMER_SUCCESS_IMPACT_SECTION: LevelCriteriaSection[] = [
  {
    field: 'clientOutcomes',
    title: 'Client outcomes',
  },
];

export const ECOSYSTEM_IMPACT_SECTION: LevelCriteriaSection[] = [
  {
    field: 'organizationOutcomes',
    title: 'Organization outcomes',
  },
];
