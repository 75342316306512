export const USER_IS_LOGGED_OUT = 'userIsLoggedOut';

const useEventBus = () => {
  const addEventListener = (event: string, callback: (data: any) => void) => {
    document.addEventListener(event, (e: any) => callback(e.detail));
  };

  const dispatchEvent = (event: string, data: any) => {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  };

  const removeEventListener = (event: string, callback: (data: any) => void) => {
    document.removeEventListener(event, callback);
  };

  return {
    addEventListener,
    dispatchEvent,
    removeEventListener,
  };
};

export default useEventBus;
