/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { useEmployeeAssignmentUrl } from 'erp/employee/assignments/EmployeeAssignmentsRouter';
import { CREATE_ASSIGNMENT } from 'erp/assignment/shared/assignmentService';

const CreateEmployeeAssignmentButton = () => {
  const { getCreateUrl } = useEmployeeAssignmentUrl();

  return (
    <CreateButton
      label="Create Assignment"
      variant="contained"
      url={getCreateUrl()}
      permissionToCheck={CREATE_ASSIGNMENT}
    />
  );
};

export default CreateEmployeeAssignmentButton;
