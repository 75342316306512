const hiringFilterValidation = {
  targetDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: {
          value: 'targetDate.le',
          message: 'Please choose date before or at "to" date',
        },
      },
      le: {
        type: 'date',
        minDate: {
          value: 'targetDate.ge',
          message: 'Please choose date after or at "from" date',
        },
      },
    },
  },
  expirationDate: {
    type: 'object',
    properties: {
      ge: {
        type: 'date',
        maxDate: {
          value: 'expirationDate.le',
          message: 'Please choose date before or at "to" date',
        },
      },
      le: {
        type: 'date',
        minDate: {
          value: 'expirationDate.ge',
          message: 'Please choose date after or at "from" date',
        },
      },
    },
  },
};

export default hiringFilterValidation;
