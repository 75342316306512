/* istanbul ignore file */
import React from 'react';
import Typography from 'uibuilder/Typography';

const MediumHeading = ({ children }: any) => {
  return (
    <div>
      <Typography variant="h2" className="c-title d-flex align-items-start">
        {children}
      </Typography>
    </div>
  );
};

export default MediumHeading;
