import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useRbsSuggestionsService from 'erp/rbs/rbsTreeNodeSuggestionsService';
import { ResourceData } from '../../../../shared/crud/baseCrudService';

export interface RbsInputProps {
  source: string;
  label?: string;
  initialLabel?: string;
  searchString?: string;
  nameSource?: string;
  mapResults?: (project: any) => void;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;
  disabled?: boolean | ((data: FormFieldsData) => boolean);
  props?: any;
}

const RbsNodeInput = ({ mapResults, ...props }: RbsInputProps) => {
  const { search } = useRbsSuggestionsService();

  const mapResponse = (rbsNode: any) => ({
    id: rbsNode.id,
    text: rbsNode.name,
  });

  return (
    <SearchInput
      {...props}
      searchString="name"
      searchRequest={searchParams =>
        search({
          ...searchParams,
          filter: { ...(searchParams as ResourceData).filter, nodeType: { eq: 'RbsTreeProject' } },
        })
      }
      noResultMessage="No responsibilities are found."
      mapResults={mapResults || mapResponse}
    />
  );
};

export default RbsNodeInput;
