/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import { string } from 'prop-types';

const ProfileMenuItem = ({ linkUrl, ...props }) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Profile" icon="user-secret" {...props} />
);

ProfileMenuItem.displayName = 'Profile';

ProfileMenuItem.propTypes = {
  linkUrl: string.isRequired,
};

export default ProfileMenuItem;
