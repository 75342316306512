/* istanbul ignore file */
import React from 'react';
import Badge, { BadgeProps } from 'shared/uibuilder/badge/Badge';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const MutableInvoiceBadge = (props: BadgeProps) => {
  const { data } = useShowContext();

  if (!data.getValueBySource('isInvoiceDocumentWillArriveLater')) {
    return null;
  }

  return (
    <Badge
      sx={{
        ml: 0,
        '&:not(:first-child)': {
          ml: 1,
        },
      }}
      color="primary"
      {...props}
    >
      Can be updated
    </Badge>
  );
};

export default MutableInvoiceBadge;
