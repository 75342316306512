/* istanbul ignore file */
// libs
import React from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { AddressInput, TextInput, PhoneInput, TextArea } from 'shared/uibuilder/form/input';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import AccountNameInput from 'crm/account/shared/input/AccountNameInput';
import EmailListInput from 'shared/uibuilder/form/input/EmailListInput';
// constants
import './ContactForm.scss';

const ContactForm = () => {
  return (
    <>
      <FormSection title="Contact information">
        <FormRow>
          <TextInput source="firstName" />
          <TextInput source="middleName" />
          <TextInput source="lastName" />
          <AccountNameInput label="Account name" source="accountId" />
        </FormRow>

        <FormRow>
          <PhoneInput label="Phone 1" source="phoneNumber" hasExtension />
          <PhoneInput label="Phone 2" source="mobilePhoneNumber" />
          <TextInput label="LinkedIn" source="linkedInLink" />
          <TextInput source="title" />
        </FormRow>

        <FormRow>
          <EmailListInput label="Email" source="emails" />
        </FormRow>
      </FormSection>

      <FormSection title="Address information">
        <AddressInput source="address" addressLine1Label="Street" zipLabel="Zip/Postal Code" />
      </FormSection>

      <FormSection title="Description">
        <MediumFormRow>
          <TextArea label="Original Lead Description" source="description" />
        </MediumFormRow>
        <BigFormRow>
          <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default ContactForm;
