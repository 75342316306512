import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useBudgetService, { BudgetSearchFilterProps } from 'erp/budget/shared/budgetService';
import { cloneDeep } from 'lodash';

interface BudgetsInputProps {
  source: string;
  isVisible?: boolean;
  filter?: BudgetSearchFilterProps;
}

const BudgetsInput = ({ filter = {}, ...props }: BudgetsInputProps) => {
  const { search, getSearchFilter } = useBudgetService();
  const { getValue, initializeValue } = useInputHelper<Array<any>>(props);

  const mapEntry = (item: any) => ({
    value: item.id,
    label: item.name,
  });

  const formatDataForMultiSelectInput = (data: Array<any>) => {
    if (!data) {
      return [];
    }
    return data.map(mapEntry);
  };

  const getSuggestions = async (query: object) => {
    const filterCopy = cloneDeep(filter);
    filterCopy.name = query;
    const response = await search({ filter: getSearchFilter(filterCopy) });

    return response.result;
  };

  const rawValue: Array<any> = getValue() || [];

  const value = rawValue.map(item => (item.value ? item : mapEntry(item)));

  useEffect(() => {
    initializeValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noOptionsMessage = (input?: Dictionary<string>) => {
    return input?.inputValue?.length ? 'Required Budget is not found.' : 'Start typing the name of the Budget.';
  };

  return (
    <MultiSelectInput
      {...props}
      value={value}
      loadSuggestionsMethod={getSuggestions}
      mapResults={formatDataForMultiSelectInput}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default BudgetsInput;
