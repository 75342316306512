import React from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { getFullName } from './ownerHelper';
import useInputHelper from '../../../shared/uibuilder/form/input/inputHelper';

/**
 * Dropdown for crm entity owner.
 * @param sales - list of sales managers.
 * @param props props to be passed to dropdown.
 */
const OwnerDropdown = ({ sales, ...props }) => {
  const { getLabel, getOnChangeCallback } = useInputHelper(props);

  return (
    <Dropdown
      label={getLabel()}
      options={sales.map(employee => ({
        value: employee.alias,
        label: employee.nameEn && getFullName(employee.nameEn),
        disabled: !employee.alias,
      }))}
      onChangeCallback={getOnChangeCallback()}
      {...props}
    />
  );
};

OwnerDropdown.propTypes = {
  label: string,
  sales: arrayOf(shape()).isRequired,
  onChangeCallback: func,
};

OwnerDropdown.defaultProps = {
  onChangeCallback: null,
  label: null,
};

export default OwnerDropdown;
