import { isArray, isObject } from 'lodash';

const removeEmptyValuesFromObject = (object: Dictionary<unknown>): Dictionary<unknown> => {
  const result: Dictionary<unknown> = {};

  Object.entries(object).forEach(([key, value]) => {
    if (!object[key]) {
      return;
    }

    if (isObject(value) && !isArray(value)) {
      result[key] = removeEmptyValuesFromObject(value as Dictionary<unknown>);
      return;
    }

    if (typeof object[key] !== 'string' && !(object[key] instanceof String)) {
      result[key] = value;
      return;
    }

    const stringValue = (object[key] as string).trim();

    if (stringValue) {
      result[key] = stringValue;
    }
  });

  return result;
};

export default removeEmptyValuesFromObject;
