/* istanbul ignore file */
export default {
  type: {
    required: true,
  },
  providerName: {
    type: 'string',
    required: true,
    maxSize: 128,
  },
  graduationYear: {
    type: 'string',
    regex: {
      value: /^\d{4}$/i,
      message: 'Please enter a year in the format YYYY',
    },
  },
  specialization: {
    type: 'string',
    required: true,
    maxSize: 128,
  },
  assignedQualification: {
    type: 'string',
    maxSize: 128,
  },
  additionalInformation: {
    type: 'string',
    maxSize: 128,
  },
  document: {
    type: 'object',
    properties: {
      type: {
        required: {
          value: true,
          when: {
            '$.haveDocument': {
              eq: true,
            },
          },
        },
      },
      number: {
        required: {
          value: true,
          when: {
            '$.haveDocument': {
              eq: true,
            },
          },
        },
        maxSize: {
          value: 24,
          when: {
            '$.haveDocument': {
              eq: true,
            },
          },
        },
      },
      dateOfIssue: {
        required: {
          value: true,
          when: {
            '$.haveDocument': {
              eq: true,
            },
          },
        },
        type: 'date',
        maxDate: {
          value: '$NOW',
          message: 'Must be in the past',
        },
      },
      scansIds: {
        type: 'array',
        maxSize: 5,
        when: {
          '$.haveDocument': {
            eq: true,
          },
        },
      },
    },
  },
};
