/* istanbul ignore file */
import FeedbackRecordLayout from './List/FeedbackRecordLayout';

const useFeedbackRegistry = () => ({
  isApplicable: item => item.feedback,
  source: 'feedback',
  urlPostfix: 'feedbacks',
  RecordLayout: FeedbackRecordLayout,
  name: 'Feedback',
});

export default useFeedbackRegistry;
