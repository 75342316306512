/* istanbul ignore file */
import React from 'react';
// components
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import CompanyRolesBreadCrumbs from '../shared/CompanyRolesBreadCrumbs';
import RelatedCompanyRolesList from './RelatedCompanyRolesList';

const CompanyRolesList = () => (
  <ShowPageLayout
    menu={<EmployeeMenu />}
    entityHeader={<EmployeeEntityHeader />}
    breadcrumbs={<CompanyRolesBreadCrumbs />}
  >
    <RelatedCompanyRolesList />
  </ShowPageLayout>
);

export default CompanyRolesList;
