import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useCandidateApplicationService from 'erp/candidate/applications/shared/candidateApplicationService';

const useCandidateApplicationDuplicatesHelper = (onDuplicatesFail: () => void) => {
  const { searchByCandidateIdAndVacancyAlias } = useCandidateApplicationService();
  const { data: formData } = useFormContext();
  const [isCandidateApplicationExist, setIsCandidateApplicationExist] = useState(false);

  // timeout for when the user stopped modifying form content regarding the candidate application
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchApplications = useCallback(
    debounce(1000, (id: string, alias: string) => {
      searchByCandidateIdAndVacancyAlias(id, alias)
        .then(applicationsResponse => {
          const result = applicationsResponse?.result;
          setIsCandidateApplicationExist(!!result.length);
        })
        .catch(() => {
          setIsCandidateApplicationExist(false);
          onDuplicatesFail();
        });
    }),
    [],
  );

  const vacancyAlias = formData?.vacancyAlias;
  const candidateId = formData?.candidateId;
  useEffect(() => {
    if (candidateId && vacancyAlias) {
      searchApplications(candidateId, vacancyAlias);
    } else {
      setIsCandidateApplicationExist(false);
    }
  }, [searchApplications, candidateId, vacancyAlias, setIsCandidateApplicationExist]);

  return {
    isCandidateApplicationExist,
  };
};

export default useCandidateApplicationDuplicatesHelper;
