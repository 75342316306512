/* istanbul ignore file */
import React from 'react';
import { CREATE_JOURNAL_ENTRY } from 'financialAnalytic/journalEntry/useJournalEntryService';
import { useJournalEntryUrl } from 'financialAnalytic/journalEntry/JournalEntryRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import LinkButton from 'uibuilder/button/LinkButton';

const ReverseJournalEntryButton = () => {
  const { getReverseEntityUrl } = useJournalEntryUrl();
  const { isGranted } = useAuthorization();

  return isGranted(CREATE_JOURNAL_ENTRY) ? (
    <LinkButton outline url={getReverseEntityUrl()}>
      Storno
    </LinkButton>
  ) : null;
};

export default ReverseJournalEntryButton;
