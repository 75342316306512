/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import { arrayOf, element, oneOfType } from 'prop-types';

const VacanciesBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useVacancyUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Vacancy" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

VacanciesBreadcrumbs.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

VacanciesBreadcrumbs.defaultProps = {
  children: null,
};

export default VacanciesBreadcrumbs;
