/* istanbul ignore file */
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import React, { useEffect } from 'react';
import Grid from 'uibuilder/Grid';
import { TextInput } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const VACANCY_OPTIONS = {
  RELEVANT: 'Relevant',
  NON_RELEVANT: 'Non-relevant',
};

const AdditionalVacancyQuestionOption = (props: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const onChangeCallback = getRawOnChangeCallback() || (() => {});

  const additionalQuestion: Dictionary<any> = getValue() || {};

  useEffect(() => {
    if (!getValue()) {
      onChangeCallback({
        [getSource()]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeSource = `${getSource()}.type`;
  const valueSource = `${getSource()}.value`;

  const onChange = (obj: Dictionary<string>) => {
    onChangeCallback({
      [getSource()]: {
        ...additionalQuestion,
        ...obj,
      },
    });
  };

  const typeOnChangeCallback = (obj: Dictionary<string>) => {
    onChange({
      type: obj[typeSource],
    });
  };

  const valueOnChangeCallback = (obj: Dictionary<string>) => {
    onChange({
      value: obj[valueSource],
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          source={valueSource}
          label="Option value"
          value={additionalQuestion.value}
          onChangeCallback={valueOnChangeCallback}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <EnumDropdown
          source={typeSource}
          label="Type"
          onChangeCallback={typeOnChangeCallback}
          options={VACANCY_OPTIONS}
          placeholder="Select type"
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalVacancyQuestionOption;
