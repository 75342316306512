/* istanbul ignore file */
import React from 'react';
import { useInvoiceUrl } from 'financialAnalytic/invoice/InvoiceRouter';
import LinkButton from 'uibuilder/button/LinkButton';

const ViewInvoiceButton = (props: any) => {
  const { getSingleEntityUrl } = useInvoiceUrl();

  return (
    <LinkButton url={getSingleEntityUrl} key="view-journal-entry" outline {...props}>
      View
    </LinkButton>
  );
};

export default ViewInvoiceButton;
