/* istanbul ignore file */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import RoleAssignmentsList from 'audit/List/roles';
import PermissionsConfigurationList from 'audit/List/permissions';
import { READ_SECURITY_AUDIT_LOG } from 'audit/shared/auditService';
import ArtifactsAuditList from './List/artifact';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const AUDIT_PATH = '/audit';
export const ROLES_AUDIT_PATH = '/audit/roles';
export const PERMISSIONS_AUDIT_PATH = '/audit/permissions';
export const ARTIFACTS_AUDIT_PATH = '/audit/artifacts';

const AuditRouter = () => (
  <ProtectedRouter basePath={AUDIT_PATH}>
    <Switch>
      <GlobalProtectedRoute path={ROLES_AUDIT_PATH} exact globalPermissionToCheck={READ_SECURITY_AUDIT_LOG}>
        <RoleAssignmentsList />
      </GlobalProtectedRoute>

      <GlobalProtectedRoute path={PERMISSIONS_AUDIT_PATH} exact globalPermissionToCheck={READ_SECURITY_AUDIT_LOG}>
        <PermissionsConfigurationList />
      </GlobalProtectedRoute>

      <GlobalProtectedRoute path={ARTIFACTS_AUDIT_PATH} exact globalPermissionToCheck={READ_SECURITY_AUDIT_LOG}>
        <ArtifactsAuditList />
      </GlobalProtectedRoute>

      <Route component={Page404} />
    </Switch>
  </ProtectedRouter>
);

AuditRouter.getRouterPath = () => AUDIT_PATH;

export default AuditRouter;
