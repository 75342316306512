import React from 'react';
import Breadcrumbs from 'uibuilder/Breadcrumbs';
import ListBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/ListBreadcrumbItem';
import { useOfficeUrl } from 'erp/office/OfficeRouter';

const OfficeBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useOfficeUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Offices" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default OfficeBreadcrumbs;
