/* istanbul ignore file */
import React, { useEffect } from 'react';
import useIssuesDragAndDrop from 'syberryToday/shared/ProjectIssue/useIssueDragAndDrop';
import ProjectIssueLayout from 'syberryToday/shared/layout/ProjectIssueLayout';
import ProjectIssueContextMenu from 'syberryToday/shared/ProjectIssueContextMenu';
import useProjectIssue from 'syberryToday/shared/ProjectIssue/useProjectIssue';
import useDeviceDetectorService from 'oneStop/deviceDetectorService';

const ITEM_TYPE = 'project-issue';

interface ProjectIssueProps {
  item: any;
  index: number;
  moveToTop?: Nullable<(dragIndex: number, id: number) => void>;
  showProjectName?: boolean;
  canPlan?: boolean;
  sortable?: boolean;
  projectName?: string;
  subProjectName?: string;
  updateInList: (entity: any) => void;
  removeFromList: (entityId: number) => void;
  moveItem?: (dragIndex: number, hoverIndex: number) => void;
  onIssueDrop?: (callback?: () => void) => void;
}

const ProjectIssue = ({
  item,
  index,
  moveItem,
  sortable = true,
  moveToTop,
  updateInList,
  onIssueDrop,
  removeFromList,
  ...props
}: ProjectIssueProps) => {
  const { isDesktop } = useDeviceDetectorService();
  const { isSelected, onPlanTask, unSelectIssueBtnClick } = useProjectIssue({ item, updateInList });
  const { customQueue, project } = item;

  const { ref, handlerId, isDragging, initDragAndDrop } = useIssuesDragAndDrop({
    item,
    index,
    moveItem,
    type: `${ITEM_TYPE}-${project.id}-${customQueue?.id || 'no-queue'}-${customQueue?.id ? isSelected : true}`,
    onIssueDrop,
  });
  const draggingOpacity = isDesktop ? 0 : 0.4;

  useEffect(() => {
    if (sortable) {
      initDragAndDrop();
    }
  }, [sortable, initDragAndDrop]);

  return (
    <ProjectIssueContextMenu key={item.id} item={item} updateInList={updateInList} removeFromList={removeFromList}>
      <div
        ref={ref}
        style={{ zIndex: 1, position: 'relative', opacity: isDragging ? draggingOpacity : 1 }}
        data-handler-id={handlerId}
      >
        <ProjectIssueLayout
          {...props}
          item={item}
          index={index}
          moveToTop={moveToTop}
          onPlanTask={onPlanTask}
          unSelectIssue={unSelectIssueBtnClick}
          isSelected={isSelected}
          updateInList={updateInList}
          sortable={sortable}
        />
      </div>
    </ProjectIssueContextMenu>
  );
};

export default ProjectIssue;
