/* istanbul ignore file */
import React from 'react';
import CandidateOfferBreadcrumbs from 'erp/candidate/offercompensation/shared/CandidateOfferBreadcrumbs';
import { UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateCandidateOfferBreadcrumbs = () => {
  return (
    <CandidateOfferBreadcrumbs>
      <UpdateBreadcrumbItem entity="Offer" />
    </CandidateOfferBreadcrumbs>
  );
};

export default UpdateCandidateOfferBreadcrumbs;
