/* istanbul ignore file */
import React from 'react';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';

interface ArtifactStorageTypeDropdownProps {
  source: string;
  isVisible?: boolean;
  options: Option[];
}

const ArtifactStorageTypeDropdown = ({ options, ...props }: ArtifactStorageTypeDropdownProps) => {
  return <Dropdown {...props} options={options} placeholder="Choose Artifact Type" />;
};

export default ArtifactStorageTypeDropdown;
