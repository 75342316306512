/* istanbul ignore file */
import React from 'react';
import { TextField, HtmlField, Collapsible } from 'shared/uibuilder/field';
import ListFieldLayout from 'uibuilder/layout/field/ListFieldLayout';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';

const EmailFields = () => {
  return (
    <>
      <TextField source="fromEmail" label="From" />
      <TextField source="toEmails" label="To" />

      <div className="mt-2">
        <Collapsible>
          <UiThemeOverrider
            overriddenLayouts={{
              BaseFieldLayout: ListFieldLayout,
            }}
          >
            <HtmlField source="body" />
          </UiThemeOverrider>
        </Collapsible>
      </div>
    </>
  );
};

export default EmailFields;
