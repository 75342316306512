import React from 'react';
import { isObject } from 'lodash';
import { Link } from 'react-router-dom';
import { WarningMessage } from 'shared/message/messageService';
import { CandidateDuplicate } from 'erp/candidate/shared/candidateService';
import { ADDITIONAL_CONTACTS_OPTIONS } from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

const FIELD_LABELS: Dictionary<string> = {
  ...ADDITIONAL_CONTACTS_OPTIONS,
  personalPhoneNumber: 'Personal Phone Number',
  personalEmail: 'Personal Email Address',
};

const getFieldLabels = (matches: Dictionary<number | Dictionary<number>>): string[] => {
  const labels: string[] = [];

  Object.entries(matches).forEach(([key, value]) => {
    if (isObject(value)) {
      labels.push(...getFieldLabels(value));
    }

    labels.push(value ? FIELD_LABELS[key] || '' : '');
  });

  return labels.filter(label => !!label);
};

const getMatchedFields = (matches: Dictionary<number | Dictionary<number>>) => {
  const labels = getFieldLabels(matches);

  return labels.length ? `(on ${labels.join(', ')}) ` : null;
};

const getCandidateData = (duplicate: CandidateDuplicate, openInNewTab: boolean) => {
  const firstName = duplicate.candidate.name.firstName || '';
  const middleName = duplicate.candidate.name.middleName || '';
  const lastName = duplicate.candidate.name.lastName || '';
  const maidenName = duplicate.candidate.name.maidenName ? `(${duplicate.candidate.name.maidenName})` : '';
  const duplicateId = duplicate.candidate.id ? `(id: ${duplicate.candidate.id})` : '';

  const name = `${firstName} ${middleName} ${lastName} ${maidenName} ${duplicateId} `;
  const target = openInNewTab ? '_blank' : '_self';

  return (
    <>
      <Link target={target} to={duplicate.url}>
        {name.replace(/  +/g, ' ')}
      </Link>
      <span className="ml-1">{getMatchedFields(duplicate.score)}</span>
    </>
  );
};

const getWarningContent = (duplicates: Array<CandidateDuplicate>, openInNewTab: boolean) => {
  const isPlural = duplicates.length > 1;

  return (
    <>
      {duplicates.length} potential duplicate{isPlural ? 's have' : ' has'} been found:<span> </span>
      <ul style={{ paddingLeft: 15 }}>
        {duplicates.map(duplicate => (
          <li key={duplicate.candidate.id}>{getCandidateData(duplicate, openInNewTab)}</li>
        ))}
      </ul>
    </>
  );
};

const getDuplicatesWarning = (duplicates: Array<CandidateDuplicate>, openInNewTab: boolean = false) => {
  if (duplicates.length === 0) {
    return null;
  }

  const content = getWarningContent(duplicates, openInNewTab);

  return new WarningMessage(content);
};

export default getDuplicatesWarning;
