/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import BaseInputLayout from 'uibuilder/layout/form/input/BaseInputLayout';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import './MultiInputWrapperLayout.scss';

const MultiInputWrapperLayout = ({
  label,
  inputSources,
  children,
  gridTemplateColumns,
  isRequired,
}: {
  label?: string;
  inputSources: string[];
  children: React.ReactNode;
  gridTemplateColumns: string;
  isRequired?: boolean;
}) => {
  const { formErrors = {} } = useFormContext();

  const errorMessages = inputSources.flatMap(source => formErrors[source] || []);

  return (
    <BaseInputLayout
      label={label as any}
      source={inputSources[0]}
      errorMessages={errorMessages}
      isRequired={isRequired}
      className="multi-input__wrapper"
    >
      <Box
        sx={{
          gridTemplateColumns,
        }}
        className="multi-input__inner"
      >
        {children}
      </Box>
    </BaseInputLayout>
  );
};

export default MultiInputWrapperLayout;
