/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import SkillBasedQueueForm from 'erp/skillbasedqueue/createupdate/SkillBasedQueueForm';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateSkillBasedQueueBreadcrumbs from 'erp/skillbasedqueue/createupdate/Update/Breadcrumbs';
import useSkillBasedQueueService from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';

const UpdateSkillBasedQueue = () => {
  const { update, getById, getValidationSchema } = useSkillBasedQueueService();
  const { getSingleEntityUrl } = useSkillBasedQueueUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getById}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
        message: 'You are awesome! The Skill Based Queue has been successfully saved.',
      }}
    >
      <UpdatePageLayout title="Edit: Skill Based Queue" breadcrumbs={<UpdateSkillBasedQueueBreadcrumbs />}>
        <SkillBasedQueueForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateSkillBasedQueue;
