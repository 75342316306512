export default {
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  identifier: {
    type: 'string',
    required: true,
  },
  parentIdentifier: {
    type: 'string',
    required: {
      value: true,
      when: {
        $createParent: {
          eq: true,
        },
      },
    },
  },
  existedTeamsWebUrl: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateTeamsGroup: {
          in: [null, undefined, false],
        },
      },
    },
  },
  existedGitlabWebUrl: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateGitlabGroup: {
          in: [null, undefined, false],
        },
      },
    },
  },
  existedSharepointWebUrl: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateSharepoint: {
          in: [null, undefined, false],
        },
      },
    },
  },
  teamsProjectCodeName: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateTeamsGroup: {
          eq: true,
        },
      },
    },
  },
  gitlabGroupProjectCodeName: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateGitlabGroup: {
          eq: true,
        },
      },
    },
  },
  gitlabGroupAccountAlias: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateGitlabGroup: {
          eq: true,
        },
      },
    },
  },
  sharepointProjectCodeName: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isNeedToCreateSharepoint: {
          eq: true,
        },
      },
    },
  },
};
