import { useKernelApi } from 'api/index';
import { useProjectId } from 'erp/project/ProjectRouter';

export const READ_PROJECT_DASHBOARD = 'READ_PROJECT_DASHBOARD';

export enum ProjectDashboardCycleStatus {
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
}

const useProjectDashboardService = () => {
  const { sendPostRequest } = useKernelApi();
  const id = useProjectId();

  const search = async (request: any) => {
    const response = await sendPostRequest(`/project/${id}/dashboard`, request);
    return response.json();
  };

  return {
    search,
  };
};

export default useProjectDashboardService;
