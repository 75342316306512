/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { CORPORATE_EVENT_STATUSES_OPTIONS } from 'financialAnalytic/corporateEvents/useCorporateEventService';

const CorporateEventStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={CORPORATE_EVENT_STATUSES_OPTIONS} {...props} />;
};

export default CorporateEventStatusDropdown;
