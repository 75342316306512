import { useKernelApi } from 'api';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import useCrudService from 'shared/crud';
import { cloneDeep } from 'lodash';
import serviceValidation from 'erp/service/createupdate/serviceValidation';
import {
  Role,
  SERVICE_ENGINEER_POSTFIX,
  SERVICE_MANAGER_POSTFIX,
} from 'erp/employee/companyRoles/shared/companyRolesService';

export const SERVICE_PATH = '/company-service';

export const READ_SERVICE = 'READ';
export const READ_SERVICES_LIST = 'COMPANY_SERVICE_READ_LIST';
export const CREATE_SERVICE = 'COMPANY_SERVICE_CREATE';
export const UPDATE_SERVICE = 'COMPANY_SERVICE_UPDATE';
export const DELETE_SERVICE = 'COMPANY_SERVICE_DELETE';

const useServiceService = () => {
  const singleResourceUrl = `${SERVICE_PATH}/:id`;
  const { sendPutRequest, sendPostRequest, sendGetRequest, sendDeleteRequest } = useKernelApi();
  const { search: baseSearch, update: initialUpdate, ...baseCrudRequests } = useCrudService({
    singleResourceUrl,
    listResourceUrl: SERVICE_PATH,
    apiService: useKernelApi,
  });

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter['specification:companyServiceName'] = {
        ct: searchString,
      };
    }

    if (searchParams.filter && searchParams.filter?.name) {
      searchParams.filter['specification:companyServiceName'] = searchParams.filter.name;
      delete searchParams.filter?.name;
    }

    return baseSearch(searchParams);
  };

  const getValidationSchema = () => {
    return Promise.resolve(serviceValidation);
  };

  const mapService = ({ availableBudgets, rbsTreeNodeName, rbsTreeNodeId, ...service }: any) => {
    return {
      ...service,
      rbsTreeNode: {
        id: rbsTreeNodeId,
      },
      availableBudgetsIds: availableBudgets ? availableBudgets.map((budget: any) => budget.value) : [],
    };
  };

  const createService = async (service: any) => {
    const response = await sendPostRequest(SERVICE_PATH, mapService(service));
    return response.json();
  };

  const update = async (id: ResourceId, service: any) => {
    return initialUpdate(id, mapService(service));
  };

  const isServiceRole = (role: Role) =>
    role.endsWith(SERVICE_ENGINEER_POSTFIX) || role.endsWith(SERVICE_MANAGER_POSTFIX);

  const getServiceRolePath = (role: Role) => (role.endsWith(SERVICE_ENGINEER_POSTFIX) ? 'engineers' : 'manager');

  const getServiceAddRoleDto = (role: Role, employeeAlias: Nullable<string>) =>
    role.endsWith(SERVICE_ENGINEER_POSTFIX) ? { engineersIds: [employeeAlias] } : { managerId: employeeAlias };

  const getServiceAliasFromRole = (role: Role) => role.substring(0, role.lastIndexOf('_'));

  const addServiceRoleToEmployee = async (role: Role, employeeAlias: Nullable<string>) => {
    const serviceAlias = getServiceAliasFromRole(role);
    const serviceId = await getServiceIdByAlias(serviceAlias);
    const serviceUrl = `/services/${serviceId}/${getServiceRolePath(role)}`;
    const addRoleDto = getServiceAddRoleDto(role, employeeAlias);

    return role.endsWith(SERVICE_MANAGER_POSTFIX)
      ? sendPutRequest(serviceUrl, addRoleDto)
      : sendPostRequest(serviceUrl, addRoleDto);
  };

  const removeServiceRoleFromEmployee = async (role: Role, employeeAlias: Nullable<string>) => {
    const serviceAlias = getServiceAliasFromRole(role);
    const serviceId = await getServiceIdByAlias(serviceAlias);
    const serviceUrl = `/services/${serviceId}/${getServiceRolePath(role)}/${employeeAlias}`;

    return sendDeleteRequest(serviceUrl);
  };

  /**
   * Returns service ID by its alias.
   *
   * @param serviceAlias service alias (ex: PROCESS_SUPPORT, RFX_MARKETING)
   */
  const getServiceIdByAlias = async (serviceAlias: string): Promise<number> => {
    const response = await sendGetRequest(`/services/${serviceAlias}`);
    const serviceDto = await response.json();

    return serviceDto.id;
  };

  return {
    getValidationSchema,
    createService,
    ...baseCrudRequests,
    search,
    update,
    getServiceIdByAlias,
    isServiceRole,
    getServiceRolePath,
    getServiceAddRoleDto,
    getServiceAliasFromRole,
    addServiceRoleToEmployee,
    removeServiceRoleFromEmployee,
  };
};

export default useServiceService;
