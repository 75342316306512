/* istanbul ignore file */
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import React, { createContext, useState } from 'react';
import { themeCreator } from './base';

interface themeValue {
  (themeName: string): void;
}

export const ThemeContext = createContext<themeValue | undefined>(undefined);

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const curThemeName = localStorage.getItem('appTheme') || 'violetTheme';

  const [themeName, _setThemeName] = useState(curThemeName);

  const theme = themeCreator(themeName);

  const setThemeName = (name: string): void => {
    localStorage.setItem('appTheme', name);
    _setThemeName(name);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <CssVarsProvider theme={theme}>{children}</CssVarsProvider>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;
