import React from 'react';
import { EmployeeLinkField } from 'erp/employee';
import { DateField, TextField } from 'shared/uibuilder/field';
import SectionRow from 'shared/uibuilder/layout/MediumSectionRow';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import ArtifactStorageHashTooltip from './ArtifactStorageHashTooltip';
import ArtifactStorageFileField from './field/ArtifactStorageFileField';
import ArtifactStorageSizeField from './field/ArtifactStorageSizeField';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface ArtifactStorageFileInformationSectionProps {
  source: string;
  title: string;
  prevSource: string;
  nameSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;

  props?: any;
}

enum KeysOfInformationToDisplay {
  STATEMENT_OF_WORK_NUMBER,
  MSA_NUMBER,
  CHANGE_REQUEST_NUMBER,
}

const ArtifactStorageFileInformationSection = ({
  title,
  isVisible,
  prevSource,
  ...props
}: ArtifactStorageFileInformationSectionProps) => {
  const { getValue } = useFieldHelper<any>(props);
  const file = getValue();

  const getNumber = () => {
    return file.metaInformation
      .filter((info: { key: string; value: string }) => Object.keys(KeysOfInformationToDisplay).includes(info.key))
      .map((info: { value: any }) => ({
        label: 'Number',
        value: info.value,
      }))[0];
  };

  const number = file ? getNumber() : null;

  return (
    <ShowSection title={title} isVisible={isVisible}>
      <SectionRow>
        <ArtifactStorageFileField label="Name" source={`${prevSource}.artifact`} />
        {number && <TextField label={number.label} value={number.value} />}
        <TextField
          source={`${prevSource}.artifact.hash`}
          label={
            <>
              Hash <ArtifactStorageHashTooltip />
            </>
          }
        />
        <ArtifactStorageSizeField source={`${prevSource}.artifact.size`} label="Size" />
        <DateField source={`${prevSource}.createdAt`} label="Created At" />
        <EmployeeLinkField
          nameSource={`${prevSource}.createdByName`}
          source={`${prevSource}.createdById`}
          label="Created By"
        />
      </SectionRow>
    </ShowSection>
  );
};

export default ArtifactStorageFileInformationSection;
