import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper, { defaultFieldPropsDefault } from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';

interface EnumFieldProps extends FieldProps {
  options: Dictionary<string>;
}

const EnumField = ({ options, ...props }: EnumFieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  const displayValue = options[getValue()];

  return <TextField value={displayValue} label={getLabel()} />;
};

EnumField.defaultProps = defaultFieldPropsDefault;

export default EnumField;
