import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import InvoiceTotalInput from '../../invoice/shared/input/InvoiceTotalInput';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import CustomerInvoiceSowIdDropdown from '../shared/inputs/CustomerInvoiceSowIdDropdown';
import CorrectedInvoiceIdDropdown from '../shared/inputs/CorrectedInvoiceIdDropdown';

const CustomerInvoiceForm = () => {
  return (
    <FormSection title="Main information">
      <FormRow>
        <TextInput source="name" label="Name" />
        <CustomerInvoiceSowIdDropdown source="sowId" label="SOW ID" />
        <DateInput label="Date" source="invoiceDate" />
      </FormRow>

      <FormRow>
        <TextInput source="invoiceNumber" label="Invoice Number" />
        <InvoiceTotalInput source="invoiceSum.amount" currencySource="invoiceSum.currency" label="Invoice Amount" />
        <CurrencyDropdown
          label="Invoice Currency"
          source="invoiceSum.currency"
          placeholder=""
          options={{ USD: CURRENCY_TYPES.USD }}
        />
      </FormRow>

      <FormRow>
        <CorrectedInvoiceIdDropdown
          source="correctedInvoiceId"
          label="Invoice for correction"
          sourceAmount="invoiceCorrectionSum.amount"
          sourceCurrency="invoiceCorrectionSum.currency"
        />
        <InvoiceTotalInput
          allowNegative
          source="invoiceCorrectionSum.amount"
          currencySource="invoiceCorrectionSum.currency"
          label="Correction Amount"
        />
        <CurrencyDropdown
          label="Correction Currency"
          source="invoiceCorrectionSum.currency"
          placeholder="Select a correction currency"
          options={{ USD: CURRENCY_TYPES.USD }}
        />
      </FormRow>

      <BigFormRow>
        <TextArea source="description" />
      </BigFormRow>
    </FormSection>
  );
};

export default CustomerInvoiceForm;
