/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useRbsService, { DELETE_RBS_TREE_NODE } from 'erp/rbs/shared/rbsService';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const deleteMessage = 'Do you really want to delete this Node?';

interface DeleteRbsNodeButtonProps {
  isVisible?: (data: any) => boolean;
}

const DeleteRbsNodeButton = ({ isVisible }: DeleteRbsNodeButtonProps) => {
  const { id: nodeId } = useParams<Dictionary<string>>();
  const { deleteById } = useRbsService();
  const { getListUrl } = useRbsUrl();
  const { data } = useShowContext();

  return isVisible && isVisible(data) ? (
    <DeleteButton
      deleteMethod={() => deleteById(nodeId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Node has been successfully deleted.',
        errorMessage: `Can't delete the Node`,
      }}
      permissionToCheck={DELETE_RBS_TREE_NODE}
    />
  ) : null;
};

export default DeleteRbsNodeButton;
