/* istanbul ignore file */
const positiveNumberRegEx = /^\d{1,6}?$/;

export default {
  name: {
    type: 'string',
    required: {
      value: true,
      when: {
        $aliasPrefix: {
          eq: 'OTH',
        },
      },
    },
    maxSize: 255,
  },
  aliasPrefix: {
    type: 'enum',
    required: true,
  },
  dueDate: {
    type: 'enum',
    required: true,
  },
  competencyLevel: {
    type: 'enum',
    required: true,
  },
  type: {
    type: 'enum',
    required: true,
  },
  openPositions: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $type: {
          in: ['RECURRING', 'ONETIME'],
        },
      },
    },
  },
  status: {
    type: 'enum',
    required: true,
  },
  vacancyDetails: {
    type: 'array',
    required: true,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        office: {
          type: 'int',
          required: true,
        },

        assignmentType: {
          type: 'array',
          required: true,
        },
        relocationOption: {
          type: 'enum',
          required: true,
        },
        hasProbation: {
          type: 'enum',
          required: true,
        },
        contractType: {
          type: 'array',
          required: true,
        },
        workplaceLocation: {
          type: 'array',
          required: true,
        },
        workingSchedule: {
          type: 'enum',
          required: true,
        },
      },
    },
  },
  expectedCompensation: {
    type: 'array',
    required: false,
    maxSize: 10,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        office: {
          type: 'enum',
          required: true,
        },
        contractType: {
          type: 'enum',
          required: true,
        },
        valueFrom: {
          type: 'int',
          required: true,
          maxValue: {
            value: '$.valueTo',
            message: "Minimal value can't be greater than maximal",
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits)',
          },
        },
        valueTo: {
          type: 'int',
          required: true,
          minValue: {
            value: '$.valueFrom',
            message: "Maximal value can't be less than minimal",
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits)',
          },
        },
        currency: {
          type: 'enum',
          required: true,
        },
      },
    },
  },
  requestedById: {
    type: 'string',
    required: true,
  },
  description: {
    type: 'object',
    required: true,
    properties: {
      responsibilities: {
        type: 'array',
        required: true,
        forEach: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              required: true,
              maxSize: 255,
            },
            description: {
              type: 'string',
              required: false,
            },
          },
        },
      },
      requirements: {
        type: 'array',
        required: true,
        forEach: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              required: true,
              maxSize: 255,
            },
            description: {
              type: 'string',
              required: false,
            },
          },
        },
      },
      optionalRequirements: {
        type: 'array',
        required: false,
        forEach: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              required: true,
              maxSize: 255,
            },
            description: {
              type: 'string',
              required: false,
            },
          },
        },
      },
      personality: {
        type: 'array',
        required: false,
        forEach: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              required: true,
              maxSize: 255,
            },
            description: {
              type: 'string',
              required: false,
            },
          },
        },
      },
      projectText: {
        type: 'string',
        required: false,
        maxSize: 5000,
      },
    },
  },
};
