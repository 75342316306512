/* istanbul ignore file */
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';
import resumeValidation from 'erp/candidate/Timeline/resume/form/resumeValidation';

export const READ_CANDIDATE_RESUMES = 'READ_CANDIDATE_RESUMES';

export const CREATE_CANDIDATE_RESUME = 'CREATE_CANDIDATE_RESUME';

export const DISPLAY_RESUME_DELETE_BUTTON_PERMISSION = 'FE_DELETE_CANDIDATE_RESUME';

export const DISPLAY_RESUME_UPDATE_BUTTON_PERMISSION = 'FE_UPDATE_CANDIDATE_RESUME';

export enum ResumeType {
  STAND_ALONE = 'STAND_ALONE',
  APPLICATION_RESUME = 'APPLICATION_RESUME',
}

/**
 * Candidate resume service.
 */
const useResumeService = () => {
  const { deleteById, update } = useBaseCrudService({
    singleResourceUrl: `/resumes/:id`,
    listResourceUrl: ``,
    apiService: useKernelApi,
  });
  const { sendPostRequest } = useKernelApi();

  const parseCandidateDataFromResume = async (uuid: string) => {
    const response = await sendPostRequest(`/candidates/resumes/parse/${uuid}`);
    const result = await response.json();
    return result.info || result;
  };

  const getValidationSchema = () => Promise.resolve(resumeValidation);

  return {
    deleteById,
    update,
    getValidationSchema,
    parseCandidateDataFromResume,
  };
};

export default useResumeService;
