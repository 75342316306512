/* istanbul ignore file */
import assetNoteValidation from 'financialAnalytic/assets/Timeline/note/form/assetNoteValidation';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useFinanceApi } from 'api';
import { useAssetId } from 'financialAnalytic/assets/AssetRouter';

export const CREATE_ASSET_NOTE_PERMISSION = 'COMPANY_CREATE_ASSET_NOTE';
export const DELETE_ASSET_NOTE_PERMISSION = 'COMPANY_DELETE_ASSET_NOTE';

const useAssetNoteService = () => {
  const inventoryNumber = useAssetId();

  const { deleteById, update } = useBaseCrudService({
    listResourceUrl: `/asset/${inventoryNumber}/note`,
    singleResourceUrl: `/asset/${inventoryNumber}/note/:id`,
    apiService: useFinanceApi,
  });

  return {
    getValidationSchema: () => Promise.resolve(assetNoteValidation),
    deleteById,
    update,
  };
};

export default useAssetNoteService;
