import React, { useEffect, useState } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { splitForApi } from 'shared/uibuilder/form/input/CommaListInput';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import TextInput from 'shared/uibuilder/form/input/TextInput';

const TicketIdsInput = (props: DefaultInputPropTypes<string[]>) => {
  const inputHelper = useInputHelper(props);

  const [value, setValue] = useState('');

  useEffect(() => {
    const contextValue = inputHelper.getValue();
    setValue(contextValue ? contextValue.join(', ') : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getSource, getRawOnChangeCallback } = inputHelper;

  const onChangeCallback = (object: FormFieldsData) => {
    const source = getSource();
    let rawValue = object[source];
    const rawOnChangeCallback = getRawOnChangeCallback();

    rawValue = rawValue.replace(/[^0-9, ]/g, '');

    setValue(rawValue);

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [source]: splitForApi(rawValue),
      });
    }
  };

  return <TextInput {...props} onChangeCallback={onChangeCallback} value={value} />;
};

export default TicketIdsInput;
