/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import { DateField, TextField, CapitalizedField } from 'shared/uibuilder/field/index';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout/index';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout/index';
import FeedbackField from 'erp/employee/assessment/shared/input/FeedbackField';
import AddAssessmentButton from 'erp/employee/assessment/List/button/AddAssessmentButton';
import EditAssessmentButton from 'erp/employee/assessment/List/button/EditAssesmentButton';
import AssessmentsBreadcrumbs from 'erp/employee/assessment/shared/AssessmentsBreadcrumbs';
import DeleteAssessmentButton from 'erp/employee/assessment/List/button/DeleteAssessmentButton';
import useAssessmentService, { CREATE_ASSESSMENT } from 'erp/employee/assessment/assessmentService';
import Typography from 'uibuilder/Typography';

const AssessmentsListPage = () => {
  const { search } = useAssessmentService();

  return (
    <ShowPageLayout
      menu={<EmployeeMenu />}
      entityHeader={<EmployeeEntityHeader />}
      breadcrumbs={<AssessmentsBreadcrumbs />}
    >
      <Typography variant="h1" component="h2" className="c-title" sx={{ marginBottom: '30px' }}>
        Communication
      </Typography>
      <List getDataMethod={search} defaultSortField="assessmentDate" defaultSortOrder={DESC}>
        <RelationListLayout
          actions={[<AddAssessmentButton key="create-assessments" />]}
          headerVariant="h2"
          header="Assessments"
        >
          <DataGridLayout
            isSmallTable
            showTotal={false}
            buttons={[
              <EditAssessmentButton source="id" outline permissionToCheck={CREATE_ASSESSMENT} />,
              <DeleteAssessmentButton source="id" outline permissionToCheck={CREATE_ASSESSMENT} />,
            ]}
          >
            <DateField source="assessmentDate" isSortable />
            <CapitalizedField source="assignmentLanguage" />
            <TextField source="levelAssignment" isSortable />
            <TextField source="assessorName" label="Assessor" isSortable />
            <FeedbackField source="feedbackDetails" width={250} />
            <AttachmentsListField source="filesIds" label="Document Scans" width={250} />
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default AssessmentsListPage;
