/* istanbul ignore file */

export default {
  instanceId: {
    type: 'string',
    required: true,
  },
  name: {
    type: 'string',
    required: true,
  },
  value: {
    type: 'string',
    required: {
      value: false,
      when: {
        $type: {
          eq: 'OBJECT',
        },
        $objectTypeName: {
          in: ['NULL', 'NULL_ARTIFACT'],
        },
      },
    },
  },
  type: {
    type: 'string',
    required: true,
  },
  objectTypeName: {
    type: 'string',
    when: {
      $type: {
        eq: 'OBJECT',
      },
    },
  },
};
