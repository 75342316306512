/* istanbul ignore file */
import React from 'react';
import useEmployeeOfferService from 'erp/employee/offers/shared/employeeOfferService';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import ListEmployeeOfferBreadcrumbs from 'erp/employee/offers/List/ListEmployeeOfferBreadcrumbs';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { DateField, TextField } from 'shared/uibuilder/field';
import List from 'shared/uibuilder/list/List';
import { CREATE_EMPLOYEE_OFFER } from 'erp/employee/employeeService';
import ViewEmployeeOfferButton from '../shared/buttons/ViewEmployeeOfferButton';

const EmployeeOfferList = () => {
  const { search } = useEmployeeOfferService();
  const { getCreateUrl } = useEmployeeOfferUrl();

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<ListEmployeeOfferBreadcrumbs />}
    >
      <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="updatedAt">
        <RelationListLayout
          header="Offers"
          actions={[
            <CreateButton
              url={getCreateUrl}
              label="Add Offer"
              key="add-compensation"
              permissionToCheck={CREATE_EMPLOYEE_OFFER}
            />,
          ]}
        >
          <DataGridLayout isSmallTable buttons={[<ViewEmployeeOfferButton source="id" key="view-offer" />]}>
            <TextField source="id" label="Offer ID" />
            <DateField source="createdAt" label="Created At" isSortable />
            <DateField source="updatedAt" label="Updated At" isSortable />
          </DataGridLayout>
        </RelationListLayout>
      </List>
    </ShowPageLayout>
  );
};

export default EmployeeOfferList;
