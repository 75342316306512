import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import useProcessInstanceService from 'camunda/monitoring/instance/processInstanceService';
import React, { ReactNode } from 'react';
import useBpmnJs from 'camunda/monitoring/facade/useBpmnJs';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ProcessInstanceEntityHeader from 'camunda/monitoring/instance/shared/header';
import ProcessInstanceBreadcrumbs from 'camunda/monitoring/instance/shared/ProcessInstanceBreadcrumbs';
import ProcessDiagram from 'camunda/monitoring/facade/diagram/ProcessDiagram';
import ProcessInstanceMenu from 'camunda/monitoring/instance/shared/ProcessInstanceMenu';
import { useLocation } from 'react-router';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import TerminateProcessButton from 'camunda/monitoring/instance/Show/shared/button/TerminateProcessButton';

interface Props {
  children: ReactNode;
}

const ProcessInstanceShowPage = ({ children }: Props) => {
  const id = useProcessInstanceId();
  const { getById } = useProcessInstanceService();
  const bpmnJs = useBpmnJs();

  const {
    getHistoryUrl,
    getIssuesUrl,
    getVariablesUrl,
    getSubprocessesUrl,
    getTokenModificationsUrl,
    getIncidentsUrl,
  } = useProcessInstanceUrl();
  const location = useLocation();
  const BreadcrumbMenuItem = () => {
    let breadcrumbText;
    switch (location.pathname) {
      case getHistoryUrl(id):
        breadcrumbText = 'History';
        break;
      case getIssuesUrl(id):
        breadcrumbText = 'Issues';
        break;
      case getVariablesUrl(id):
        breadcrumbText = 'Variables';
        break;
      case getSubprocessesUrl(id):
        breadcrumbText = 'Subprocesses';
        break;
      case getTokenModificationsUrl(id):
        breadcrumbText = 'Token Modifications';
        break;
      case getIncidentsUrl(id):
        breadcrumbText = 'Incidents';
        break;
      default:
        breadcrumbText = null;
    }
    return breadcrumbText ? <BreadcrumbItem>{breadcrumbText}</BreadcrumbItem> : <></>;
  };

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        entityHeader={<ProcessInstanceEntityHeader />}
        menu={<ProcessInstanceMenu />}
        breadcrumbs={
          <ProcessInstanceBreadcrumbs>
            <BreadcrumbMenuItem />
          </ProcessInstanceBreadcrumbs>
        }
        headerButtons={[<TerminateProcessButton />]}
      >
        <ProcessDiagram instanceId={id} bpmnJs={bpmnJs} />
        <div className="mt-10">
          {/* @ts-ignore */}
          {React.Children.map(children, child => React.cloneElement(child, { bpmnJs }))}
        </div>
      </ShowPageLayout>
    </Show>
  );
};

export default ProcessInstanceShowPage;
