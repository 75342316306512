import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import EnumField from 'shared/uibuilder/field/EnumField';
import { CORPORATE_EVENT_LOCATIONS_OPTIONS } from 'financialAnalytic/corporateEvents/useCorporateEventService';

const CorporateEventLocationField = (props: FieldProps) => {
  return <EnumField options={CORPORATE_EVENT_LOCATIONS_OPTIONS} {...props} />;
};

export default CorporateEventLocationField;
