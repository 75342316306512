/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const NOTE_TAG_ALIAS = {
  OFFER_NOTE: 'Offer',
};
const CandidateNoteTagDropdown = (props: any) => {
  return <EnumDropdown {...props} options={NOTE_TAG_ALIAS} />;
};

export default CandidateNoteTagDropdown;
