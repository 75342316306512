export default {
  name: {
    type: 'string',
    required: true,
    maxSize: 64,
  },
  ownerId: {
    type: 'string',
    required: true,
  },
  parentId: {
    type: 'string',
    required: false,
  },
  periodFrom: {
    type: 'date',
    maxDate: {
      value: '$periodTo',
      message: "Start date can't be later than end date",
    },
  },
  periodTo: {
    type: 'date',
    minDate: {
      value: '$periodFrom',
      message: "End date can't be earlier than start date",
    },
  },
  softPeriodTo: {
    type: 'date',
    minDate: {
      value: '$periodFrom',
      message: "Soft end date can't be earlier than start date",
    },
    maxDate: {
      value: '$periodTo',
      message: "Soft end date can't be later than end date",
    },
  },
  softPeriodFrom: {
    type: 'date',
    maxDate: {
      value: '$softPeriodTo',
      message: "Soft start date can't be later than soft end date",
    },
  },
  purpose: {
    type: 'string',
    maxSize: 1024,
  },
  maxFtePercent: {
    maxValue: 999999.99,
    minValue: 0,
  },
};
