/* istanbul ignore file */
import React from 'react';
import RelatedList from 'shared/uibuilder/list/RelatedList';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { TextField, FullDateField, EnumField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import useProcessIncidentService, {
  ProcessIncidentTypeLabels,
} from 'camunda/monitoring/instance/Show/incident/processIncidentService';
import 'camunda/monitoring/instance/Show/incident/IncidentTable.scss';
import LongTextField from 'shared/uibuilder/field/LongTextField';
import ResolveBusinessIncidentButton from './shared/button/ResolveBusinessIncidentButton';

const IncidentsTable = () => {
  const { getIncidents } = useProcessIncidentService();

  const getDataMethod = async () => {
    const incidents = await getIncidents();
    return incidents;
  };

  return (
    <RelatedList getDataMethod={getDataMethod}>
      <RelationListLayout header="Active Incidents" actions={[]}>
        <DataGridLayout
          showTotal={false}
          tableWrapperClassName="formatted-incident-list"
          buttons={[<ResolveBusinessIncidentButton sourceId="id" />]}
          isSmallTable
        >
          <TextField source="id" label="Id" />
          <FullDateField source="incidentTimestamp" label="Timestamp" />
          <EnumField source="incidentType" label="Type" options={ProcessIncidentTypeLabels} />
          <TextField label="Activity ID" source="activityId" />
          <LongTextField source="incidentMessage" label="Message" title="Message" />
        </DataGridLayout>
      </RelationListLayout>
    </RelatedList>
  );
};

export default IncidentsTable;
