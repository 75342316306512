/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import TextField from 'shared/uibuilder/field/TextField';
import DateField from 'shared/uibuilder/field/DateField';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import List from 'shared/uibuilder/list/List';
import JournalEntryLink from 'financialAnalytic/journalEntry/shared/field/JournalEntryLink';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import useJournalEntryService from 'financialAnalytic/journalEntry/useJournalEntryService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import Typography from 'uibuilder/Typography';
import JournalEntryStaticFilters from 'financialAnalytic/journalEntry/shared/filter/JournalEntryStaticFilters';
import CurrencyField from 'shared/uibuilder/field/CurrencyField';
import Box from 'uibuilder/Box';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import JournalEntryTransferField from 'financialAnalytic/journalEntry/shared/field/JournalEntryTransferField';

const LedgerTransfersList = ({
  title,
  filter = {},
  defaultFilter = {},
  hasAccountLinkField,
  hasTransferIdField,
}: {
  title?: string;
  filter?: Dictionary<any>;
  defaultFilter?: Dictionary<any>;
  hasAccountLinkField?: boolean;
  hasTransferIdField?: boolean;
}) => {
  const { searchTransfers, getFilterValidationSchema } = useJournalEntryService();
  const currentMonthStart = moment()
    .startOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);
  const currentMonthEnd = moment()
    .endOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);

  return (
    <Box sx={{ minHeight: '200px', position: 'relative' }}>
      <List
        getDataMethod={data => searchTransfers({ ...data, filter: { ...data.filter, ...filter } })}
        defaultFilter={{
          period: {
            ge: currentMonthStart,
            le: currentMonthEnd,
          },
          ...defaultFilter,
        }}
      >
        <>
          <Filters getValidationSchema={getFilterValidationSchema}>
            <JournalEntryStaticFilters
              title={
                title && (
                  <Typography variant="h4" className="c-title">
                    {title}
                  </Typography>
                )
              }
              sx={{
                marginBottom: { xs: '28px', sm: '30px' },
                '.filter-item-wrapper': {
                  margin: 0,
                },
              }}
            />
          </Filters>
          <DataGridLayout>
            {
              [
                <JournalEntryLink source="journalEntryId" label="Journal Entry #" width="20%" />,
                hasAccountLinkField && (
                  <LedgerLink source="accountId" nameSource="accountId" label="Account" width="30%" />
                ),
                hasTransferIdField && <JournalEntryTransferField source="id" label="Transfer ID" width="30%" />,
                <CurrencyField source="debit" currencySource="currency" label="Debit" width="20%" />,
                <CurrencyField source="credit" currencySource="currency" label="Credit" width="20%" />,
                <TextField source="description" label="Description" width="30%" />,
                <DateField source="asDateOf" label="Date" width="15%" />,
              ].filter(Boolean) as React.ReactElement[]
            }
          </DataGridLayout>
        </>
      </List>
    </Box>
  );
};

export default LedgerTransfersList;
