/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const AssessmentsBreadcrumbs = ({ children }: { children?: React.ReactElement | React.ReactElement[] }) => {
  const { getListUrl } = useAssessmentUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Assessments" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

AssessmentsBreadcrumbs.defaultProps = {
  children: null,
};

export default AssessmentsBreadcrumbs;
