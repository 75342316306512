import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { Button } from 'uibuilder/button';
import React from 'react';

interface PeriodQuickFilterProps {
  fromValue: string;
  toValue: string;
  label: string;
}

const PeriodQuickFilter = ({ label, fromValue, toValue }: PeriodQuickFilterProps) => {
  const { data: { dateFrom, dateTo } = {}, onChangeCallback } = useFormContext();

  const onClick = () => {
    const data = {
      dateFrom: fromValue,
      dateTo: toValue,
    };

    if (onChangeCallback) {
      onChangeCallback(data, false);
    }
  };

  return (
    <Button
      onClick={onClick}
      type="button"
      className="button-dropdown__menu-item"
      disabled={dateFrom === fromValue && dateTo === toValue}
    >
      {label}
    </Button>
  );
};

export default PeriodQuickFilter;
