/* istanbul ignore file */
import React, { useState } from 'react';
import usePurchaseOrderService, {
  LINK_INVOICE_TO_PURCHASE_ORDER,
  PURCHASE_ORDER_STATUSES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import PurchaseOrderDropdown from 'financialAnalytic/purchaseOrder/shared/input/PurchaseOrderDropdown';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const LinkInvoiceToPurchaseOrderButton = ({
  statusSource,
  isVisible,
  officeSource,
}: {
  statusSource: string;
  officeSource: string;
  isVisible?: any;
}) => {
  const { linkInvoiceToPurchaseOrder, invalidateCache } = usePurchaseOrderService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { getValue: getOffice } = useFieldHelper({ source: officeSource });
  const status = getValue();
  const office = getOffice();
  const [purchaseOrderIdErrors, setPurchaseOrderIdErrors] = useState<string[]>([]);
  const [purchaseOrderId, setPurchaseOrderId] = useState('');
  const { data: showData, setData = () => {} } = useShowContext();
  const invoiceId = showData.getValueBySource('id');

  if (status !== PURCHASE_ORDER_STATUSES.PENDING) {
    return null;
  }

  const onPurchaseOrderIdChange = (values: FormFieldsData) => {
    setPurchaseOrderId(values.purchaseOrderId);

    if (values.purchaseOrderId) {
      setPurchaseOrderIdErrors([]);
    } else {
      setPurchaseOrderIdErrors(['Field is required']);
    }
  };

  return (
    <ButtonWithConfirmation
      displayMessage=""
      modalChildren={
        <PurchaseOrderDropdown
          source="purchaseOrderId"
          officeData={office}
          value={purchaseOrderId as any}
          label="Please, choose purchase order"
          onChangeCallback={onPurchaseOrderIdChange}
          errorMessages={purchaseOrderIdErrors}
          isRequired
        />
      }
      submitMethod={() => linkInvoiceToPurchaseOrder(purchaseOrderId, invoiceId)}
      afterSubmit={{
        successMessage: 'The Invoice has been successfully linked to purchase order.',
        errorMessage: "Can't link this invoice to purchase order",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={LINK_INVOICE_TO_PURCHASE_ORDER}
      variant="outlined"
      confirmBtnProps={{ disabled: !!purchaseOrderIdErrors.length || !purchaseOrderId }}
    >
      Link to Purchase Order
    </ButtonWithConfirmation>
  );
};

export default LinkInvoiceToPurchaseOrderButton;
