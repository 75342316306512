/* istanbul ignore file */

import React from 'react';
import 'audit/shared/Manual.css';

const ArtifactsAuditManual = () => (
  <div className="manual-container">
    <p>
      Current functionality allows to get access log for artifacts accessed by pre-signed link. You can limit the
      selection by filling in the filter. Or leave it empty if you do not want to limit it.
    </p>
    <p>The generated report contains the following columns:</p>
    <table className="manual-table">
      <tbody>
        <tr>
          <td>
            <b>Column</b>
          </td>
          <td>
            <b>Description</b>
          </td>
        </tr>
        <tr>
          <td>Artifact ID</td>
          <td>Id of the artifact.</td>
        </tr>
        <tr>
          <td>Artifact Title</td>
          <td>Name of the artifact file.</td>
        </tr>
        <tr>
          <td>User id</td>
          <td>Id of the user who accessed the artifact.</td>
        </tr>
        <tr>
          <td>User name</td>
          <td>Name of the user who accessed the artifact.</td>
        </tr>
        <tr>
          <td>User IP</td>
          <td>IP of the user who accessed the artifact.</td>
        </tr>
        <tr>
          <td>Date of access</td>
          <td>Timestamp when artifact was accessed (UTC).</td>
        </tr>
        <tr>
          <td>Access details</td>
          <td>Additional information: which pre-signed link was used (personal or public)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>Please contact DaVinci team if you want to troubleshoot this configuration.</p>
  </div>
);

export default ArtifactsAuditManual;
