/* istanbul ignore file */
export default {
  description: {
    type: 'string',
    required: true,
  },
  asDateOf: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$TODAY',
      message: 'Please choose current date or date in past',
    },
  },
  transfers: {
    type: 'array',
    required: true,
    minSize: 2,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        accountId: {
          type: 'string',
          required: true,
        },
        debit: {
          type: 'string',
          required: {
            value: true,
            when: {
              '$.credit': {
                empty: true,
              },
            },
          },
        },
        credit: {
          type: 'string',
          required: {
            value: true,
            when: {
              '$.debit': {
                empty: true,
              },
            },
          },
        },
        description: {
          type: 'string',
        },
      },
    },
  },
  attributes: {
    type: 'object',
    _divisible: false,
    properties: {
      office: {
        type: 'string',
        required: {
          value: true,
          when: {
            $officeIsRequired: {
              eq: true,
            },
          },
        },
      },
      employeeAlias: {
        type: 'string',
        required: {
          value: true,
          when: {
            $employeeAliasIsRequired: {
              eq: true,
            },
          },
        },
      },
    },
  },
  project: {
    type: 'object',
    _divisible: false,
    properties: {
      projectId: {
        type: 'string',
        required: {
          value: true,
          when: {
            $projectIsRequired: {
              eq: true,
            },
          },
        },
      },
    },
  },
  totals: {
    type: 'number',
    required: {
      value: true,
      message: 'Debit and Credit must be equal',
    },
  },
};
