// @ts-nocheck // Remove after refactoring TextInput file with TS
import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import Grid from 'uibuilder/Grid';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormSection from 'shared/layout/form/FormSection';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { CommaListInput, TextInput, WysiwygInput } from 'shared/uibuilder/form/input';
import EmailAttachmentUploaderInput from '../../artifact/shared/input/EmailAttachmentUploaderInput';
import { ARTIFACT_ID } from '../../artifact/const';
import AddIcon from '@mui/icons-material/AddOutlined';
import useFeatureToggle, { Features } from 'featuretoggle';

const SendEmailForm = () => {
  const { isFeatureEnabled } = useFeatureToggle();

  const {
    data: { ccs },
  } = useFormContext();
  const [isCcInputVisible, setIsCcInputVisible] = useState(!!ccs?.length);

  const showCcInput = () => {
    setIsCcInputVisible(true);
  };

  return (
    <FormSection>
      <Grid container spacing={2}>
        <Grid item md={12} xl={3}>
          <BigFormRow>
            <TextInput source="sender" label="From" disabled />
            <TextInput source="recipient" label="To" disabled />
            {isCcInputVisible ? (
              <CommaListInput source="ccs" label="CC (enter comma-separated e-mail addresses)" />
            ) : (
              <Button type="button" size="sm" className="mb-2" onClick={showCcInput} startIcon={<AddIcon />}>
                Add CC
              </Button>
            )}
            <TextInput source="subject" />
          </BigFormRow>
        </Grid>
        <Grid item md={12} xl={9}>
          <BigFormRow className="mail-edit">
            <WysiwygInput source="content" label="" />
          </BigFormRow>
        </Grid>
        <Grid item md={12} xl={9}>
          <BigFormRow>
            <EmailAttachmentUploaderInput
              label=""
              source="attachmentIds"
              artifactTypeId={ARTIFACT_ID.EMAIL_ATTACHMENT}
              isVisible={isFeatureEnabled(Features.EMAIL_ATTACHMENT)}
            />
          </BigFormRow>
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default SendEmailForm;
