import React from 'react';

import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';

export interface AddressType {
  country?: string;
  state?: string;
  city?: string;
  addressLine1?: string;
  addressLine2?: string;
  zipCode?: string;
}

type AddressFieldLayoutType = ReactComponent<any>;

const isNeedToDisplayState = (addressValue: AddressType) => addressValue.country === 'United States';

interface AddressFieldProps extends FieldProps<AddressType> {
  children?: React.ReactElement;
}
const AddressField = ({ children, ...props }: AddressFieldProps) => {
  const { getValue } = useFieldHelper<AddressType>(props);

  const { AddressFieldLayout } = useUiTheme<AddressFieldLayoutType>();

  const addressValue = getValue() || {};

  return (
    <AddressFieldLayout>
      <TextField label="Country" value={addressValue.country} />
      {isNeedToDisplayState(addressValue) ? <TextField label="State" value={addressValue.state} /> : null}
      <TextField label="City" value={addressValue.city} />
      <TextField label="Street" value={addressValue.addressLine1} />
      <TextField label="Zip/Postal code" value={addressValue.zipCode} />
      {children}
    </AddressFieldLayout>
  );
};

export default AddressField;
