const REGEXP_LINKS_WITH_HREF_WITHOUT_TARGET = /(<\s*a)(\s+(?=[^>]*?href\s*=)(?![^>]*?target\s*=)[^>]*?>)/gm;
const REGEXP_LINK_RELS = /rel\s*=\s*['"].*?['"]/gm;

/**
 * Adds target='_blank' (to open link in new tab) for links with no 'target' attribute but with 'href'.
 * Also setting rel='noopener noreferrer' not to expose 'window.opener.location' to target site.
 * @param htmlText - text with or without HTML tags
 */
const setBlankTargetsIfNotSpecified = (htmlText?: string) => {
  return htmlText
    ? htmlText.replace(REGEXP_LINKS_WITH_HREF_WITHOUT_TARGET, (link: string) => {
        const linkWithoutRelAttributes = link.replace(REGEXP_LINK_RELS, '');
        return linkWithoutRelAttributes.replace(
          REGEXP_LINKS_WITH_HREF_WITHOUT_TARGET,
          "$1 target='_blank' rel='nofollow noopener noreferrer' $2",
        );
      })
    : '';
};

export default setBlankTargetsIfNotSpecified;
