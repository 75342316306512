/* istanbul ignore file */
import BaseTimelineItemFormLayout, {
  BaseTimelineItemFormLayoutProps,
} from 'shared/uibuilder/timeline/layout/form/BaseTimelineItemFormLayout';
import React from 'react';

const UpdateTimelineItemLayout = ({ children, onCancel, onSubmitAction }: BaseTimelineItemFormLayoutProps) => (
  <BaseTimelineItemFormLayout isCreateForm={false} onCancel={onCancel} onSubmitAction={onSubmitAction}>
    {children}
  </BaseTimelineItemFormLayout>
);

export default UpdateTimelineItemLayout;
