/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import useReconciliationService from 'erp/costaccounting/reconcilation/useReconciliationService';
import ReconciliationListDataContext from 'erp/costaccounting/reconcilation/List/ReconciliationListDataContext';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import moment from 'moment';

const ReconciliationListPage = () => {
  const { search } = useReconciliationService();
  const currentMonthStart = moment()
    .startOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);
  const currentMonthEnd = moment()
    .endOf('month')
    .format(DATE_FORMAT.API_ONLY_DATE);

  return (
    <List
      getDataMethod={search}
      defaultFilter={{
        period: {
          ge: currentMonthStart,
          le: currentMonthEnd,
        },
      }}
      uncontrolledFilters={['period']}
      shouldSaveUncontrolledFilters
    >
      <ReconciliationListDataContext />
    </List>
  );
};

export default ReconciliationListPage;
