/* istanbul ignore file */
import React, { useMemo } from 'react';
import Grid from 'uibuilder/Grid';
import { DateInput, TextInput } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { ImpactLabel, ProbabilityLabel } from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import RemoveListItemButton from 'erp/project/weeklyreports/shared/button/RemoveListItemButton';
import TextButton from 'uibuilder/button/TextButton';
import useInputRowWithSpecificId from './useInputRowWithSpecificId';

export type RiskType = {
  id: number;
  description: string;
  createdDate: string;
  probability: string;
  impact: string;
  fromPreviousReport: boolean;
  comments: string;
  closedDate: string;
};

interface RiskInputRowProps {
  data: RiskType;
  id: number;
  formData: any;
  updateFormData: (source: string, newFormData: any) => void;
  getErrorMessages: (fieldSource: string) => string[];
}

export const getRiskSource = (id: number) => {
  return `weeklyReportData.risks[${id}]`;
};

const RiskInputRow = ({ data, id, formData, updateFormData, getErrorMessages }: RiskInputRowProps) => {
  const source = getRiskSource(id);
  const { getOnChangeCallback, removeRow } = useInputRowWithSpecificId({
    data,
    source,
    formData,
    id,
    updateFormData,
    listSource: 'risks',
  });

  const isDisabledSpecific = useMemo(() => {
    return data.fromPreviousReport && !!data.closedDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.fromPreviousReport, data.id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={1}>
        <TextInput
          source={`${source}.id`}
          label={id === 0 ? 'ID' : ''}
          value={data.id.toString()}
          disabled={id !== 0 || !formData.firstReport}
          onChangeCallback={getOnChangeCallback('id')}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '13.5%' }}>
        <TextButton
          source={`${source}.description`}
          label={id === 0 ? 'Description' : ''}
          value={data.description}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('description')}
          isRequired
          title="Description"
          errors={getErrorMessages(`${source}.description`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.createdDate`}
          label={id === 0 ? 'Created date' : ''}
          value={data.createdDate}
          disabled={data.fromPreviousReport}
          onChangeCallback={getOnChangeCallback('createdDate')}
          isRequired
          minDate={formData.firstReport ? null : formData.from}
          maxDate={formData.firstReport ? null : formData.to}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '13%' }}>
        <EnumDropdown
          source={`${source}.probability`}
          label={id === 0 ? 'Probability' : ''}
          value={data.probability}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('probability')}
          options={ProbabilityLabel}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '13%' }}>
        <EnumDropdown
          source={`${source}.impact`}
          label={id === 0 ? 'Impact' : ''}
          value={data.impact}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('impact')}
          options={ImpactLabel}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ maxWidth: '13.5%' }}>
        <TextButton
          source={`${source}.comments`}
          label={id === 0 ? 'Strategy' : ''}
          value={data.comments}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('comments')}
          title="Strategy"
          errors={getErrorMessages(`${source}.comments`)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <DateInput
          source={`${source}.closedDate`}
          label={id === 0 ? 'Closed date' : ''}
          value={data.closedDate}
          disabled={isDisabledSpecific}
          onChangeCallback={getOnChangeCallback('closedDate')}
          minDate={data.createdDate}
        />
      </Grid>
      <Grid item xs={12} md={1} style={{ maxWidth: '5%' }}>
        <RemoveListItemButton isVisible={!data.fromPreviousReport} onClickCallback={removeRow} isFirstRow={id === 0} />
      </Grid>
    </Grid>
  );
};

export default RiskInputRow;
