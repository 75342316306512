/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LinkField } from 'shared/uibuilder/field';
import { useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';

const ProcessInstanceLinkField = (props: any) => {
  const { getValue } = useFieldHelper(props);
  const { getSingleEntityUrl } = useProcessInstanceUrl();

  const id = getValue();
  const { label = id } = props;
  const url = getSingleEntityUrl(id);
  const { isInternal = true } = props;

  return <LinkField value={url} linkText={label} isInternal={isInternal} />;
};

export default ProcessInstanceLinkField;
