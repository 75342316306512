/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { FilterSection } from 'oneStop/oneStopService';
import useDeviceDetectorService from 'oneStop/deviceDetectorService';
import OneStopFilterLayout from 'oneStop/List/OneStopFilterLayout';

type OneStopFilterProps = {
  requestsList: React.ReactChild;
  getSections: () => Promise<FilterSection[]>;
};

const OneStopFilter = ({ getSections, requestsList }: OneStopFilterProps) => {
  const [sections, setSections] = useState<FilterSection[]>([]);
  const { isDesktop } = useDeviceDetectorService();
  const { filter, filterBy } = useListContext();
  const activeFilterId = filter?.section?.eq || null;

  useEffect(() => {
    (async () => {
      const data = await getSections();
      setSections(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryClick = (id: string) => {
    // @ts-ignore
    filterBy({
      section: {
        eq: id,
      },
    });
  };

  const sectionProps = {
    handleCategoryClick,
    requestsList,
    isDesktop,
    activeFilterId,
  };

  return <OneStopFilterLayout sections={sections} sectionProps={sectionProps} />;
};

export default OneStopFilter;
