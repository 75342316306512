import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import PeriodQuickFilter from './PeriodQuickFilter';

const firstDayOfPrevMonth = moment()
  .subtract(1, 'months')
  .startOf('month')
  .format(DATE_FORMAT.API_ONLY_DATE);

const now = moment().format(DATE_FORMAT.API_ONLY_DATE);

const PreviousAndCurrentMonthsQuickFilter = () => (
  <PeriodQuickFilter fromValue={firstDayOfPrevMonth} toValue={now} label="Previous and current months" />
);

export default PreviousAndCurrentMonthsQuickFilter;
