/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import useCacheService from 'shared/cache/cacheService';
import InputLoader from 'shared/uibuilder/InputLoader';

export const PROCESS_KEY_CACHE = 'processKeys';

const ProcessKeyFilter = (props: any) => {
  const [options, setOptions] = useState<List<string>>();
  const { getValue } = useCacheService(PROCESS_KEY_CACHE);
  const keys = getValue(PROCESS_KEY_CACHE);
  useEffect(() => {
    setOptions(keys);
  }, [keys]);

  return options ? (
    <DropDownFilter {...props}>
      <Dropdown
        placeholder="Choose Process Key"
        options={
          options
            ? options.map((processKey: string) => ({
                value: processKey,
                label: processKey,
              }))
            : []
        }
        label="Process Key"
        {...props}
      />
    </DropDownFilter>
  ) : (
    <InputLoader />
  );
};

export default ProcessKeyFilter;
