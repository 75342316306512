/* istanbul ignore file */
import useBaseCrudService from 'shared/crud/baseCrudService';
import employeeNoteValidation from 'erp/employee/Timeline/note/form/employeeNoteValidation';
import { useKernelApi } from 'api';

export const CREATE_EMPLOYEE_NOTE = 'CREATE_NOTE';
export const UPDATE_EMPLOYEE_NOTE = 'UPDATE_NOTE';

const useEmployeeNoteService = () => {
  const { update } = useBaseCrudService({
    listResourceUrl: '/notes',
    singleResourceUrl: '/notes/:id',
    apiService: useKernelApi,
  });

  return {
    getValidationSchema: () => Promise.resolve(employeeNoteValidation),
    update,
  };
};

export default useEmployeeNoteService;
