import React from 'react';
import Grid, { GridProps } from 'uibuilder/Grid';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { getVisibility } from 'shared/uibuilder/helper';

export interface FormRowProps extends GridProps {
  children: React.ReactElement | React.ReactElement[];
  colMd?: number;
  colXl?: number;
}

const FormRow = ({ children, colMd, colXl, ...props }: FormRowProps) => {
  const { data: formData } = useFormContext();

  return (
    <Grid {...props} container spacing={2}>
      {React.Children.toArray(children)
        .filter(child => child)
        .map((child, index) => {
          // @ts-ignore
          let { isVisible } = child.props;
          isVisible = getVisibility(formData, isVisible);

          return (
            isVisible && (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} md={colMd} xl={colXl} key={index}>
                {child}
              </Grid>
            )
          );
        })}
    </Grid>
  );
};

FormRow.defaultProps = {
  colMd: 6,
  colXl: 3,
};

export default FormRow;
