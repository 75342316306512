/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { func } from 'prop-types';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import useResumeService, {
  DISPLAY_RESUME_DELETE_BUTTON_PERMISSION,
  DISPLAY_RESUME_UPDATE_BUTTON_PERMISSION,
  READ_CANDIDATE_RESUMES,
} from 'erp/candidate/Resume/shared/resumeService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';

const ResumeTimelineRecordLayout = ({ onEdit, ...props }) => {
  const { deleteById } = useResumeService();
  const { data } = useShowContext();

  const buttons = [
    <EditTimelineRecordButton permissionToCheck={DISPLAY_RESUME_UPDATE_BUTTON_PERMISSION} key="edit" onEdit={onEdit} />,
    <DeleteTimelineRecordButton
      permissionToCheck={DISPLAY_RESUME_DELETE_BUTTON_PERMISSION}
      deleteMethod={deleteById}
      key="add"
      id={data.getValueBySource('id')}
      timelineId={data.getValueBySource('timelineId')}
      entityName="resume"
    />,
  ];

  return (
    <BaseTimelineRecordLayout
      permissionToCheck={READ_CANDIDATE_RESUMES}
      timelineType={TimelineType.RESUME}
      artifactsSource="resumeArtifactIds"
      buttons={buttons}
      {...props}
    />
  );
};

ResumeTimelineRecordLayout.propTypes = {
  onEdit: func.isRequired,
};

export default ResumeTimelineRecordLayout;
