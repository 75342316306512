/* istanbul ignore file */
import React, { useEffect } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import CompletenessInput from 'erp/employee/salaries/shared/inputs/CompletenessInput';
import { EmployeeSpecialization } from 'erp/employee/qualification/shared/employeeSpecializationService';

interface EmployeeQualificationDetailsInputTemplateProps {
  source: string;
  specializations: Nullable<EmployeeSpecialization[]>;
  isDisabled: boolean | ((values: FormFieldsData) => boolean);
}

const EmployeeQualificationDetailsInputTemplate = ({
  specializations,
  source,
  isDisabled,
  ...props
}: EmployeeQualificationDetailsInputTemplateProps) => {
  const { data, isDirty } = useFormContext();
  const { getRawOnChangeCallback } = useInputHelper({ source, ...props });
  const branchId = data?.specializationDetails?.specializationBranchId;
  const rawOnChangeCallback = getRawOnChangeCallback();
  const qualifications =
    specializations?.find(({ specializationBranchId }) => specializationBranchId === branchId)?.qualifications || [];

  useEffect(() => {
    if (!isDirty) {
      return;
    }
    if (rawOnChangeCallback) {
      if (branchId) {
        rawOnChangeCallback({
          [source]: qualifications,
        });
      } else {
        rawOnChangeCallback({
          [`${source}`]: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  if (!data?.type) {
    return null;
  }

  if (specializations === null) {
    return null;
  }

  if (!branchId) {
    return null;
  }

  return (
    <>
      <b className="d-block mb-2">Completeness, %:</b>
      <FormRow>
        {qualifications.map(({ id, title }, i) => (
          <CompletenessInput key={id} source={`${source}.${i}`} label={title} isDisabled={isDisabled} />
        ))}
      </FormRow>
    </>
  );
};

export default EmployeeQualificationDetailsInputTemplate;
