import React, { useMemo } from 'react';
import moment from 'moment';
import CountDownTimer from 'shared/uibuilder/CountDownTimer';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import './TimeInProgressField.scss';
import { PROGRESS_FIELDS_KEYS } from 'crm/projectsDashboard/useProjectsDashboardService';

interface TimeInProgressFieldProps {
  source: string;
  customerActivityDurationSource: string;
  shouldCountFromTargetDate?: boolean;
  progressSource: string;

  [key: string]: any;
}

const TimeInProgressField = ({
  source,
  customerActivityDurationSource,
  progressSource,
  shouldCountFromTargetDate = false,
}: TimeInProgressFieldProps) => {
  const { getValue: getTime } = useFieldHelper({ source });
  const { getValue: getCustomerActivityDuration } = useFieldHelper({ source: customerActivityDurationSource });
  const { getValue: getProgress } = useFieldHelper({ source: progressSource });
  const customerActivityDuration = +getCustomerActivityDuration() || 0;
  const timerValue = getTime();
  const shortTimerValue = useMemo(
    () =>
      moment(timerValue)
        .add(customerActivityDuration, 'days')
        .format(DATE_FORMAT.API),
    [timerValue, customerActivityDuration],
  );

  const progress = (getProgress() as unknown) as any[];
  const lastStep = progress.find(step => step.stepName === PROGRESS_FIELDS_KEYS.MVP);
  const lastStepFactDate = lastStep?.factDate;

  return (
    <div className="project-dashboard-timer">
      <span className="project-dashboard-timer__label">With customer confirmation</span>
      <CountDownTimer
        targetDate={timerValue}
        shouldCountFromTargetDate={shouldCountFromTargetDate}
        stopDatetime={lastStepFactDate}
      />

      <span className="project-dashboard-timer__label">Without customer confirmation</span>
      <CountDownTimer
        targetDate={shortTimerValue}
        shouldCountFromTargetDate={shouldCountFromTargetDate}
        stopDatetime={lastStepFactDate}
      />
    </div>
  );
};

export default TimeInProgressField;
