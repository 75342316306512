/* istanbul ignore file */
import React from 'react';

import useLeadService from 'crm/lead/shared/leadService';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import LeadForm from 'crm/lead/createupdate/LeadForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateLeadBreadcrumbs from 'crm/lead/createupdate/Create/Breadcrumbs';

const CreateLead = () => {
  const { create, getValidationSchema } = useLeadService();
  const { getListUrl } = useLeadUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        status: 'ASSIGNED',
        source: 'ORGANIC_SEARCH',
        address: {
          country: 'United States',
        },
        medium: 'WEB_FORM',
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Lead has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Lead" breadcrumbs={<CreateLeadBreadcrumbs />}>
        <LeadForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateLead;
