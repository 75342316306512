/* istanbul ignore file */
import React from 'react';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { get } from 'lodash';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

interface RelatedDiscoveryOpportunityInputDropdownProps extends DefaultDropdownProps {
  accountAliasSource: string;
}

const RelatedDiscoveryOpportunityInputDropdown = ({
  accountAliasSource,
  ...props
}: RelatedDiscoveryOpportunityInputDropdownProps) => {
  const { data } = useFormContext();
  const { search } = useOpportunityService() as any;

  const accountId = get(data, accountAliasSource);
  const currentOppId = get(data, 'id', null);

  const searchValues = {
    accountId,
    currentOpportunityId: currentOppId,
  };

  const searchRequest = {
    filter: {
      'specification:availableDiscoveryOpportunitiesSpecification': {
        eq: searchValues,
      },
    },
  };

  const loadOpportunities = async () => {
    return search(searchRequest);
  };

  return (
    <LoadDropdown
      loadDataMethod={loadOpportunities}
      mapResults={opp => ({
        value: opp.id,
        label: opp.name,
      })}
      showLoader
      placeholder="Select Opportunity"
      deps={[accountId]}
      {...props}
    />
  );
};

export default RelatedDiscoveryOpportunityInputDropdown;
