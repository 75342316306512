/* istanbul ignore file */
export default {
  requestType: {
    type: 'enum',
    required: true,
  },
  systemCompletionProgress: {
    type: 'int',
    minValue: 0,
    maxValue: 100,
    required: false,
  },
  hasPreviousTeamInvolvement: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $requestType: {
          in: ['EXISTING_SYSTEM_DEVELOPMENT_COMPLETION', 'MODERNIZATION_OF_OUTDATED'],
        },
      },
    },
  },
  collaborationExpectations: {
    type: 'string',
    required: {
      value: true,
      when: {
        $hasPreviousTeamInvolvement: {
          eq: 'YES',
        },
      },
    },
  },
  expectedCompletionAt: {
    type: 'date',
    required: true,
  },
  hasAutomatedTesting: {
    type: 'enum',
    required: false,
  },
  automatedTestingCoverage: {
    type: 'int',
    minValue: 0,
    maxValue: 100,
    required: false,
  },
  applicationTypes: {
    type: 'array',
    required: {
      value: true,
      when: {
        $requestType: {
          eq: 'NEW_CREATION',
        },
      },
    },
  },
  mobileOperationSystems: {
    type: 'array',
    required: {
      value: true,
      when: {
        _and_: [
          {
            $applicationTypes: {
              ne: null,
            },
          },
          {
            $applicationTypes: {
              ct: 'MOBILE',
            },
          },
        ],
      },
    },
  },
  hasThirdPartyIntegrations: {
    type: 'enum',
    required: false,
  },
  requiredFte: {
    type: 'int',
    required: {
      value: true,
      when: {
        $requestType: {
          eq: 'CONTRACT_EXTENSION',
        },
      },
    },
  },
  systemHostingLocation: {
    type: 'string',
    required: false,
  },
  replacementReason: {
    type: 'string',
    required: false,
  },
  scopeOfWork: {
    type: 'string',
    required: {
      value: true,
      when: {
        $requestType: {
          eq: 'CONTRACT_EXTENSION',
        },
      },
    },
  },
  materials: {
    type: 'string',
    required: {
      value: true,
      when: {
        $requestType: {
          ne: 'CONTRACT_EXTENSION',
        },
      },
    },
  },
  attachmentIds: {
    type: 'array',
    required: false,
    maxSize: 15,
  },
};
