/* istanbul ignore file */
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_SYBERRY_TODAY, SYBERRY_TODAY_PATH } from 'syberryToday/useSyberryTodayService';
import SyberryTodayList from 'syberryToday/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { SyberryTodayProjectProjectsRouter } from 'syberryToday/projects';

export const useSyberryTodayRoute = () => useRoute({ listRoute: SYBERRY_TODAY_PATH });

export const useSyberryTodayUrl = () =>
  useEntityUrl({
    baseLink: SYBERRY_TODAY_PATH,
  });

export const useSyberryTodayId = () => useParams<Dictionary<string>>().id;

const SyberryTodayRouter = () => {
  const { listRoute } = useSyberryTodayRoute();

  return (
    <ProtectedRouter basePath={SYBERRY_TODAY_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_SYBERRY_TODAY}>
          <SyberryTodayList />
        </GlobalProtectedRoute>
        <SyberryTodayProjectProjectsRouter />
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

SyberryTodayRouter.getRouterPath = () => SYBERRY_TODAY_PATH;

export default SyberryTodayRouter;
