import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { DateInput } from 'shared/uibuilder/form/input';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import InvoiceTotalInput from 'financialAnalytic/invoice/shared/input/InvoiceTotalInput';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';

export const { BYN, ...DROPDOWN_CURRENCY_TYPES } = CURRENCY_TYPES || {};

const OtherExpensesWasteRow = () => {
  return (
    <FormRow>
      <ClearAfterUnmountWrapper>
        <InvoiceTotalInput
          source="otherWasteDetails.cost.amount"
          currencySource="otherWasteDetails.cost.currency"
          label="Amount"
        />
      </ClearAfterUnmountWrapper>

      <ClearAfterUnmountWrapper>
        <CurrencyDropdown
          options={{ ...DROPDOWN_CURRENCY_TYPES, BYR: 'BYR' }}
          source="otherWasteDetails.cost.currency"
          label="Currency"
        />
      </ClearAfterUnmountWrapper>

      <ClearAfterUnmountWrapper>
        <DateInput isRequired source="otherWasteDetails.issueDate" label="Issue Date" />
      </ClearAfterUnmountWrapper>
    </FormRow>
  );
};

export default OtherExpensesWasteRow;
