/* istanbul ignore file */
import React from 'react';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { WysiwygInput } from 'shared/uibuilder/form/input';

const NoteForm = () => (
  <BigFormRow>
    <WysiwygInput source="content" label="Note Text" />
  </BigFormRow>
);

export default NoteForm;
