/* istanbul ignore file */
import React from 'react';
import usePurchaseOrderService, {
  CLOSE_PURCHASE_ORDER,
  GOODS_AND_SERVICES_DELIVERY_STATUS,
  PURCHASE_ORDER_STATUSES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { useParams } from 'react-router-dom';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ClosePurchaseOrderButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { closePurchaseOrder, invalidateCache } = usePurchaseOrderService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { data, setData = () => {} } = useShowContext();
  const status = getValue();
  const contextData = data.getData() || {};

  if (
    status !== PURCHASE_ORDER_STATUSES.PARTIALLY_CLOSED ||
    !contextData?.invoices?.every(
      (invoice: any) => invoice?.goodsAndServices?.deliveryStatus === GOODS_AND_SERVICES_DELIVERY_STATUS.DELIVERED,
    )
  ) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to close this purchase order?"
      submitMethod={() => closePurchaseOrder(id)}
      afterSubmit={{
        successMessage: 'The Purchase Order has been successfully closed.',
        errorMessage: "Can't close purchase order.",
        execute: (response: any) => {
          invalidateCache();
          setData({ ...contextData, ...response });
        },
      }}
      permissionToCheck={CLOSE_PURCHASE_ORDER}
      color="error"
      outline
    >
      Close
    </ButtonWithConfirmation>
  );
};

export default ClosePurchaseOrderButton;
