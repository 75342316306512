/* istanbul ignore file */
const positiveNumberRegEx = /^((?!^0+$|0+[.]0+$)0|[1-9]\d{0,5})(?:[.]\d{1,2})?$/;

export default {
  type: {
    type: 'enum',
    required: {
      value: true,
      message: 'Please select compensation type',
    },
  },
  effectiveDateFrom: {
    type: 'date',
    required: {
      value: true,
      message: 'Please select the date',
    },
    maxDate: {
      value: '$effectiveDateTo',
      message: "Effective from date can't be later than effective to date",
    },
  },
  effectiveDateTo: {
    type: 'date',
    minDate: {
      value: '$effectiveDateFrom',
      message: "Effective to date can't be earlier than effective from date",
    },
  },
  baseAmountType: {
    type: 'enum',
    required: {
      value: true,
      message: 'Please select base amount type',
    },
  },
  grossAmount: {
    type: 'string',
    required: {
      value: true,
      message: 'Please fill this field',
    },
    minValue: {
      value: '$grossProbationAmount',
      message: "Base amount value can't be less than probation period value",
    },
    regex: {
      value: positiveNumberRegEx,
      message: 'Must be a positive number (max 6 digits and 2 decimals)',
    },
  },
  currency: {
    type: 'enum',
    required: {
      value: true,
      message: 'Please select a currency',
    },
  },
  probationReduction: {
    type: 'float',
    maxInteger: 3,
    maxDecimal: 2,
  },
  grossProbationAmount: {
    type: 'string',
    maxValue: {
      value: '$grossAmount',
      message: "Probation period value can't be greater than base amount value",
    },
    regex: {
      value: positiveNumberRegEx,
      message: 'Must be a positive number (max 6 digits and 2 decimals)',
    },
  },
  additionalExpenses: {
    type: 'string',
    maxSize: {
      value: 128,
      message: 'Maximum 128 symbols',
    },
  },
  firstDay: {
    type: 'date',
    minDate: {
      value: '$effectiveDateFrom',
      message: 'First day of base value application must be in range of effective dates',
    },
    maxDate: {
      value: '$effectiveDateTo',
      message: 'First day of base value application must be in range of effective dates',
    },
  },
  scansIds: {
    type: 'array',
    maxSize: 5,
  },
  officeId: {
    type: 'string',
    required: {
      value: true,
      message: 'Please select an office',
      when: {
        '$_FEATURE[twoContracts]': {
          eq: true,
        },
      },
    },
  },
};
