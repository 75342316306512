/* istanbul ignore file */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { number, oneOfType, string } from 'prop-types';
import Anchor from 'shared/uibuilder/Anchor';

type TimelineRecordAnchorProps = {
  timelineId: string | number;
  basePathname?: string;
};

const TimelineRecordAnchor = ({ timelineId, basePathname }: TimelineRecordAnchorProps) => {
  const { pathname } = useLocation();

  const pathnameWithoutEntryId = basePathname || pathname.replace(/\/\d+$/, '');
  const link = `${pathnameWithoutEntryId}/${timelineId}`;

  return (
    <Anchor id={`anchor-entry${timelineId}`} link={link} classes="timeline-anchor">
      Copy link
    </Anchor>
  );
};

TimelineRecordAnchor.propTypes = {
  timelineId: oneOfType([string, number]).isRequired,
  basePathname: string,
};

TimelineRecordAnchor.defaultValues = {
  basePathname: '',
};

export default TimelineRecordAnchor;
