/* istanbul ignore file */
import React from 'react';
import SectionRow, { SectionRowProps } from 'shared/uibuilder/layout/SectionRow';

const MediumSectionRow = ({ children, ...props }: SectionRowProps) => {
  return (
    <SectionRow colMd={4} colXl={6} {...props}>
      {children}
    </SectionRow>
  );
};

export default MediumSectionRow;
