import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import Collapse from '@mui/material/Collapse';
import Box from 'uibuilder/Box';
import { TogglerInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const ShareInstantFeedbackToggle = ({ source }: { source: string }) => {
  const { getValue, getRawOnChangeCallback } = useInputHelper({ source });
  const [isConfirmationShown, setIsConfirmationShown] = useState(false);
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});
  const value = getValue();

  const onShareToggleClick = ({ isSharedWithEmployee }: Dictionary<boolean>) => {
    if (isSharedWithEmployee) {
      setIsConfirmationShown(true);
    } else {
      hideConfirmation();
      rawOnChangeCallback({
        [source]: isSharedWithEmployee,
      });
    }
  };

  const hideConfirmation = () => {
    setIsConfirmationShown(false);
  };

  const onConfirm = () => {
    hideConfirmation();
    rawOnChangeCallback({
      [source]: true,
    });
  };

  return (
    <Box
      sx={{
        marginTop: '-11px',
        marginBottom: '-6px',
      }}
    >
      <TogglerInput
        source={source}
        value={!!value}
        checkedValue="Yes"
        uncheckedValue="No"
        label="Share my name and my feedback with colleague"
        onChangeCallback={onShareToggleClick}
        isControlledInput
        className="share-feedback-toggle"
      />
      <Collapse
        in={isConfirmationShown}
        sx={{
          '.MuiCollapse-wrapperInner > div': {
            margin: '0',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          },
          '.MuiCollapse-wrapperInner > div:nth-child(2)': { margin: '10px 0 30px' },
          '.MuiCollapse-wrapperInner button': { minWidth: '100px' },
        }}
      >
        <div>Are you sure you want to share your name and feedback with colleague?</div>
        <div>
          <Button key="confirmBtn" sx={{ marginRight: '10px' }} onClick={onConfirm}>
            Yes
          </Button>
          <Button key="cancelBtn" outline onClick={hideConfirmation}>
            No
          </Button>
        </div>
      </Collapse>
    </Box>
  );
};

export default ShareInstantFeedbackToggle;
