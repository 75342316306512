/* istanbul ignore file */
import React from 'react';
import CreateButton from 'uibuilder/button/CreateButton';
import { CREATE_RBS_TREE_NODE } from 'erp/rbs/shared/rbsService';
import { useRbsUrl } from 'erp/rbs/RbsRouter';
import { useListContext } from 'shared/uibuilder/list/ListContext';

const CreateRbsNodeButton = () => {
  const { getCreateUrl } = useRbsUrl();
  const { data } = useListContext();

  return (
    <CreateButton
      url={getCreateUrl(data?.items?.length ? {} : { isRootNode: 'true' })}
      label="Create responsibility"
      key="create-rbs-node"
      permissionToCheck={CREATE_RBS_TREE_NODE}
      openInNewTab
    />
  );
};

export default CreateRbsNodeButton;
