import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import OfficeHeader from 'erp/office/shared/OfficeHeader';
import SingleOfficeBreadcrumbs from 'erp/office/shared/SingleOfficeBreadcrumbs';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import TextField from 'shared/uibuilder/field/TextField';
import AddressField from 'shared/uibuilder/field/AddressField';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import ViewOfficeRoomsButton from 'erp/office/shared/ViewOfficeRoomsButton';

const ViewOfficePageContent = () => {
  return (
    <ShowPageLayout
      entityHeader={<OfficeHeader />}
      breadcrumbs={<SingleOfficeBreadcrumbs />}
      headerButtons={[<ViewOfficeRoomsButton />]}
      hasSeparateSections
    >
      <ShowSection title="Office Details">
        <SectionRow>
          <TextField source="name" label="Name" />
          <TextField source="squareMetersArea" label="Area" />
        </SectionRow>
        <BigSectionRow>
          <AddressField source="address" label="Address" width={150} />
        </BigSectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewOfficePageContent;
