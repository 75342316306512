/* istanbul ignore file */
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { CandidateApplicationStatusOptions } from 'erp/candidate/applications/shared/candidateApplicationService';

const CandidateApplicationStatusDropdown = (props: any) => {
  return (
    <EnumDropdown placeholder="Choose Application Status" options={CandidateApplicationStatusOptions} {...props} />
  );
};

export default CandidateApplicationStatusDropdown;
