import React, { useState } from 'react';
import { HTTP } from 'shared/api/const';
import OneStopModal from 'oneStop/Create/OneStopModal';
import { ModalData } from 'oneStop/Create/OneStopModalLayout';
import OneStopErrorMessage from 'oneStop/Create/OneStopErrorMessage';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import SanitizedHtml from '../../shared/security/SanitizedHtml';
import { RequestType } from '../oneStopService';

const REQUEST_ID_REGEXP = />#[0-9]*</;
const DAVINCI_PROCESS_ID_HEADER = 'davinci-process-id';
const FORBIDDEN_ERROR_MESSAGE = 'You have no Redmine authorization to perform this action.';
const INTERNAL_SERVER_ERROR_MESSAGE =
  'Something went wrong when processing your request and it has not been submitted.';

type RequestContext = {
  type?: RequestType;
};

type OneStopModalDecoratorProps = {
  isOneStop?: boolean;
  children: React.ReactElement;
  submitHandler?: () => void;
  handleMessageModalClose?: () => void;
  messageContext?: string;
  requestContext?: RequestContext;
};

const OneStopModalDecorator = ({
  children,
  isOneStop,
  submitHandler,
  handleMessageModalClose,
  messageContext,
  requestContext,
}: OneStopModalDecoratorProps) => {
  const [isError, setIsError] = useState(false);
  const [modalData, setModalData] = useState<Nullable<ModalData>>(null);
  const { addMessage } = useMessageService(messageContext);

  const openModal = (content: string | React.ReactElement, title?: string, requestId?: string | null) => {
    setModalData({ isOpen: true, content, title, requestId, requestType: requestContext?.type });
  };

  const getRequestId = (message: string) => {
    const match = message.match(REQUEST_ID_REGEXP);

    return match && match[0].slice(2, match[0].length - 1);
  };

  const handleFormSubmit = (response: any) => {
    const message = <SanitizedHtml html={response.message} />;
    const title = '';
    const requestId = getRequestId(response.message);

    setIsError(false);
    openModal(message, title, requestId);

    if (submitHandler) {
      submitHandler();
    }
  };

  const showModalWithSubmissionError = (submitErrors: any, errorMessage: string) => {
    const title = 'Ooops...';
    const currentProcessId = submitErrors.headers.get(DAVINCI_PROCESS_ID_HEADER);
    const message = <OneStopErrorMessage message={errorMessage} processId={currentProcessId} />;

    setIsError(true);
    openModal(message, title);
  };

  const handleErrorsOnSubmit = (submitErrors: any) => {
    switch (submitErrors.status) {
      case HTTP.FORBIDDEN:
        showModalWithSubmissionError(submitErrors, FORBIDDEN_ERROR_MESSAGE);
        break;
      case HTTP.INTERNAL_SERVER_ERROR:
        showModalWithSubmissionError(submitErrors, INTERNAL_SERVER_ERROR_MESSAGE);
        break;
      default:
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        handleErrorsOnSubmit,
        afterSubmit: { execute: handleFormSubmit },
      })}
      <OneStopModal
        data={modalData}
        isError={isError}
        isOneStop={isOneStop}
        handleMessageModalClose={handleMessageModalClose}
      />
    </>
  );
};

OneStopModalDecorator.defaultProps = {
  isOneStop: true,
  submitHandler: undefined,
  handleMessageModalClose: undefined,
  messageContext: undefined,
};

export default OneStopModalDecorator;
