/* istanbul ignore file */
import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';

const RequiredResourcesListInput = ({ inputTemplate, ...props }: InputListProps) => {
  return <InputList inputTemplate={inputTemplate} {...props} defaultValue={{}} />;
};

export default RequiredResourcesListInput;
