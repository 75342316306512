import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setHeaderMenuData } from 'shared/uibuilder/menu/HeaderMenu/headerMenuActions';
import { HeaderMenuState } from 'shared/uibuilder/menu/HeaderMenu/headerMenuReducer';

export const SET_HEADER_MENU = 'SET_HEADER_MENU';

export interface HeaderMenuItemProps {
  name: string;
  url: string;
  exact?: boolean;
  isVisible?: boolean;
  children?: { name: string; isVisible?: boolean; hash?: string }[];
}

const useHeaderMenuService = () => {
  const dispatch = useDispatch();
  const menuContainer = useSelector((state: { headerMenu: HeaderMenuState[] }) => state.headerMenu, shallowEqual);
  const { pathname } = useLocation();

  const addMenuData = (menuData: HeaderMenuItemProps[]) => {
    dispatch(setHeaderMenuData(menuData, pathname));
  };

  const getMenuData = (): HeaderMenuItemProps[] => {
    const data = menuContainer?.filter(m => m?.pathname === pathname) || [];

    return data[0]?.menuData || [];
  };

  return {
    addMenuData,
    getMenuData,
  };
};

export default useHeaderMenuService;
