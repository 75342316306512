/* istanbul ignore file */
import React from 'react';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import ResumeWysiwygInput from 'erp/candidate/shared/input/ResumeWysiwygInput';

const ResumeForm = () => {
  return (
    <>
      <BigFormRow>
        <ResumeWysiwygInput artifactsSource="resumeArtifactIds" source="description" />
      </BigFormRow>
      <BigFormRow>
        <CandidateResumeUploaderInput label="Candidate resume" source="resumeArtifactIds" />
      </BigFormRow>
    </>
  );
};

export default ResumeForm;
