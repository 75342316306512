/* istanbul ignore file */
// @ts-nocheck // Remove after refactoring TextInput, DateInput, TextArea components with TS
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormSection from 'shared/layout/form/FormSection';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import AttachmentUploaderInput from 'artifact/shared/input/AttachmentUploaderInput';
import AssignmentLanguageDropdown from 'erp/employee/assessment/shared/input/AssignmentLanguageDropdown';

const AssessmentForm = () => (
  <FormSection title="Assessment information">
    <FormRow>
      <TextInput source="assessorName" label="Assessor" />
      <AssignmentLanguageDropdown source="assignmentLanguage" label="Language" />
      <TextInput source="levelAssignment" label="Formal Level Assignment" />
      <DateInput label="Assessment Date" source="assessmentDate" />
    </FormRow>
    <BigFormRow>
      <TextArea source="feedbackDetails" label="Feedback Details" />
    </BigFormRow>
    <BigFormRow>
      <AttachmentUploaderInput label="Document Scans" source="filesIds" />
    </BigFormRow>
  </FormSection>
);

export default AssessmentForm;
