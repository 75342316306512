import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { appendEmployeeNames, removeEmployeeNames, clearEmployeeNames } from './employeeNamesActions';
import useEmployeeNamesInfoReceiver from 'erp/employee/shared/employeeNameInfoReceiver';
import moment from 'moment';

export const APPEND_EMPLOYEE_NAMES = 'APPEND_EMPLOYEE_NAMES';
export const REMOVE_EMPLOYEE_NAMES = 'REMOVE_EMPLOYEE_NAMES';
export const CLEAR_EMPLOYEE_NAMES = 'CLEAR_EMPLOYEE_NAMES';

const NAME_EXPIRES_IN_SECS = 60 * 60 * 24 * 5; // 5 days

export const isEmployeeNamesExpired = (data: Dictionary<any>) => {
  if (!data?.updatedAt) {
    return true;
  }

  const creationDate = moment(data.updatedAt);
  const expiryDate = creationDate.add(NAME_EXPIRES_IN_SECS, 'seconds');
  const currentDate = moment();

  return currentDate > expiryDate;
};

const useEmployeeNamesService = () => {
  const { collectEmployeeNamesData } = useEmployeeNamesInfoReceiver();

  const dispatch = useDispatch();
  const employeeNames = useSelector((state: { employeeNames: any }) => state.employeeNames, shallowEqual);

  const setEmployeeNamesToStore = (employeeNamesData: any) => dispatch(appendEmployeeNames(employeeNamesData));

  const removeEmployeeNamesFromStore = (aliases: string[]) => dispatch(removeEmployeeNames(aliases));

  const getEmployeeNamesByAliases = async (aliases: string[]) => {
    const missed = aliases.filter(alias => !employeeNames[alias] || isEmployeeNamesExpired(employeeNames[alias]));

    let missedEmployeeNames: Dictionary<any> = {};
    if (missed.length) {
      missedEmployeeNames = await collectEmployeeNamesData(missed);
    }

    setEmployeeNamesToStore(missedEmployeeNames);

    return (
      aliases
        .map(alias => employeeNames[alias] || missedEmployeeNames[alias])
        .map(a => Object.freeze(a))
        .filter(name => name !== undefined) || []
    );
  };

  const getEmployeeNameDataById = async (employeeAlias?: string) => {
    if (!employeeAlias) {
      return Promise.resolve({});
    }

    const result = await getEmployeeNamesByAliases([employeeAlias]);
    return result[0];
  };

  const clearEmployeeNamesFromStore = () => dispatch(clearEmployeeNames());

  return {
    getEmployeeNamesByAliases,
    getEmployeeNameDataById,
    setEmployeeNamesToStore,
    removeEmployeeNamesFromStore,
    clearEmployeeNamesFromStore,
  };
};

export default useEmployeeNamesService;
