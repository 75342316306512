/* istanbul ignore file */
import React from 'react';
import DesktopMenu from 'shared/uibuilder/menu/HeaderMenu/HeaderMenuItems/DesktopMenu';
import useDeviceDetectorService, { MAX_LG_WIDTH } from 'oneStop/deviceDetectorService';

const HeaderMenuItems = () => {
  const { isDesktop } = useDeviceDetectorService({ maxWidth: MAX_LG_WIDTH, defaultValue: false });

  return isDesktop ? <DesktopMenu /> : null;
};

export default HeaderMenuItems;
