/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { element } from 'prop-types';

const EmployeeProfileBreadcrumbs = ({ children }) => {
  const id = useEmployeeId();
  const { getProfileUrl } = useEmployeeUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <BreadcrumbItem link={getProfileUrl(id)}>Profile</BreadcrumbItem>
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

EmployeeProfileBreadcrumbs.propTypes = {
  children: element,
};

EmployeeProfileBreadcrumbs.defaultProps = {
  children: null,
};

export default EmployeeProfileBreadcrumbs;
