import React, { useState } from 'react';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import { ProjectDropdownProps } from 'erp/project/shared/input/ProjectDropdown';
import useProjectService from 'erp/project/shared/projectService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';

const JournalEntryProjectDropdown = ({
  loadDataMethod,
  mapResults: mapResultsFromProps,
  disabled,
  ...props
}: ProjectDropdownProps) => {
  const { searchAllSuggestions } = useProjectService();
  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();
  const [loadedProjects, setLoadedProjects] = useState<any[]>([]);

  const loadProjectSuggestions = async () => {
    const projects = await searchAllSuggestions({});

    setLoadedProjects(projects);

    projects.unshift({
      id: 'no-specific-project',
      projectName: 'No specific project',
    });

    return {
      result: projects,
    };
  };

  const onChangeCallback = (values: any) => {
    const projectId = values[props.source];
    const projectData = projectId ? loadedProjects.find(data => data.id === projectId) : {};

    if (rawOnChangeCallback) {
      rawOnChangeCallback({ project: { projectId, accountId: projectData.accountId, sowId: projectData.sowId } });
    }
  };

  return (
    <LoadDropdown
      label="Project"
      placeholder="Select project"
      loadDataMethod={loadProjectSuggestions}
      onChangeCallback={onChangeCallback}
      isVisible={(data: any) => data.projectIsRequired}
      mapResults={(project: ResultResourceData) => ({
        value: project.id,
        accountId: project.accountId,
        sowId: project.sowId,
        label: `${project.projectName} ${project.sowId ? `(SOW Number: ${project.sowId})` : ''}`,
      })}
      {...props}
    />
  );
};

export default JournalEntryProjectDropdown;
