/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import BaseAmountTypeDropdown from 'erp/employee/finance/shared/inputs/BaseAmountTypeDropdown';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import { Checkbox, Dropdown, TextArea, TextInput } from 'shared/uibuilder/form/input';
import useEmployeeSpecializationService, {
  EmployeeSpecialization,
} from 'erp/employee/qualification/shared/employeeSpecializationService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import EmployeeQualificationDetailsInputTemplate from 'erp/employee/salaries/shared/inputs/EmployeeQualificationDetailsInputTemplate';
import { SALARY_TYPE, SALARY_TYPE_ALIAS } from 'erp/employee/salaries/shared/salaryService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import EmployeePerformanceDetailsInput from 'erp/employee/salaries/shared/inputs/EmployeePerformanceDetailsInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CareerDevelopmentReviewDropdown from 'erp/careerdevelopment/shared/input/CareerDevelopmentReviewDropdown';
import useEmployeePerformanceService from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';

const currentTooltipMessage =
  'Please describe any achievements or goals the employee has attained in free form, if applicable.';

const targetTooltipMessage = 'Please describe any goals set for the employee in free form, if applicable.';

const getTooltipMessage = (formData: any) => {
  if (formData?.type === SALARY_TYPE_ALIAS.CURRENT) {
    return currentTooltipMessage;
  } else if (formData?.type === SALARY_TYPE_ALIAS.TARGET) {
    return targetTooltipMessage;
  }
  return 'Please, select Salary Type.';
};

const SalaryForm = ({ isCreateForm = false }: { isCreateForm?: boolean }) => {
  const [specializations, setSpecializations] = useState<Nullable<EmployeeSpecialization[]>>(null);
  const [specializationsAsOptions, setSpecializationsAsOptions] = useState<Option[]>([]);
  const employeeId = useEmployeeId() || '';
  const { getSpecializations } = useEmployeeSpecializationService();
  const { data: formData, setFormData, isDirty } = useFormContext();
  const initialPerformance = formData
    ? { ...formData.performanceDetails, helpfulnessGrade: formData?.helpfulnessGrade }
    : null;
  const [existingPerformance, setExistingPerformance] = useState<Nullable<any>>(initialPerformance);
  const { getPerformanceCriteriaByCareerReview } = useEmployeePerformanceService();
  const relatedProcessId = formData?.relatedProcessId;

  useEffect(() => {
    getSpecializations(employeeId).then(data => {
      const employeeSpecializations: EmployeeSpecialization[] = data?.result || [];

      setSpecializations(employeeSpecializations);

      const formattedSpec = employeeSpecializations.map(spec => {
        return {
          value: spec.specializationBranchId,
          label: spec.title,
        };
      });
      setSpecializationsAsOptions(formattedSpec);
    });

    // Suppressed warnings because we only need to call useCallback callback if user change page or cache is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, getSpecializations]);

  useEffect(() => {
    (async () => {
      if (!isDirty) {
        return;
      }
      if (relatedProcessId) {
        try {
          const criteria = await getPerformanceCriteriaByCareerReview(relatedProcessId);
          setExistingPerformance(criteria);
        } catch (e) {
          setExistingPerformance(null);
        }
      } else {
        setExistingPerformance(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedProcessId]);

  useEffect(() => {
    if (setFormData) {
      setFormData({
        ...formData,
        helpfulnessGrade: existingPerformance?.helpfulnessGrade,
        performanceDetails: existingPerformance?.helpfulnessGrade != null ? null : existingPerformance,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingPerformance]);

  return (
    <>
      <FormSection title="Salary Finance Information">
        <FormRow>
          <CareerDevelopmentReviewDropdown label="Related Career Review Period" source="relatedProcessId" />
        </FormRow>
        <FormRow>
          <EnumDropdown
            options={SALARY_TYPE}
            source="type"
            placeholder="Select type"
            label="Salary Type"
            isVisible={isCreateForm}
          />
          <BaseAmountTypeDropdown source="baseAmountType" label="Base Amount Type" />
          <CurrencyDropdown source="currency" label="Currency" />
          <TextInput source="grossAmount" label="Base Amount Gross" />
        </FormRow>
      </FormSection>
      <FormSection title="Employee Qualification Information">
        <FormRow>
          <Checkbox source="modelBased" label="Is Qualification Model Applicable?" defaultValue />
        </FormRow>
        <FormRow>
          <ClearAfterUnmountWrapper isVisible={data => data.modelBased}>
            <Dropdown
              label="Employee Specialization"
              source="specializationDetails.specializationBranchId"
              options={specializationsAsOptions}
              placeholder="Choose specialization"
              defaultValue=""
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
        <div>
          <EmployeeQualificationDetailsInputTemplate
            source="specializationDetails.qualifications"
            specializations={specializations}
            isDisabled={data => data.type === SALARY_TYPE_ALIAS.CURRENT}
          />
        </div>
        <BigFormRow>
          <ClearAfterUnmountWrapper isVisible={data => !data.modelBased}>
            <TextArea
              label="Achievement and Goal Summary"
              source="qualificationDescription"
              tooltip={getTooltipMessage(formData)}
            />
          </ClearAfterUnmountWrapper>
        </BigFormRow>
      </FormSection>
      <FormSection
        title="Employee Performance Details"
        subtitle="Performance Details are automatically populated when a Related Career Review Period is selected"
      >
        <EmployeePerformanceDetailsInput source="performanceDetails" isCreateForm={isCreateForm} />
      </FormSection>
    </>
  );
};

export default SalaryForm;
