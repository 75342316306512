import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const useIssuesDragAndDrop = ({
  item,
  index,
  moveItem = () => {},
  type,
  onIssueDrop = () => {},
}: {
  type: string;
  item: any;
  index: number;
  moveItem?: (dragIndex: number, hoverIndex: number) => void;
  onIssueDrop?: (callback?: () => void) => void;
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor: any) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {
      onIssueDrop();
    },
    hover(hoverItem: any, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = hoverItem.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (ref.current as any)?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line
      hoverItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => {
      return { ...item, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const initDragAndDrop = () => {
    drag(drop(ref));
  };

  return {
    ref,
    initDragAndDrop,
    isDragging,
    handlerId,
  };
};

export default useIssuesDragAndDrop;
