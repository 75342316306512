/* istanbul ignore file */
import React from 'react';
import { useEmployeePerformanceUrl } from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceRouter';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const EmployeePerformanceBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useEmployeePerformanceUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Performance Results" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeePerformanceBreadcrumbs;
