import React from 'react';

type CompetenciesFieldProps = {
  value?: { name: string };
  isLast?: boolean;
};

const CompetenciesField = ({ value, isLast }: CompetenciesFieldProps) => {
  return (
    <>
      {value?.name}
      {isLast ? '' : ', '}
    </>
  );
};

export default CompetenciesField;
