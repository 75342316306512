/* istanbul ignore file */
export default {
  type: {
    type: 'enum',
    required: true,
  },
  sickDayReason: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'SICK_DAY',
        },
      },
    },
  },
  startDate: {
    type: 'date',
    required: true,
    maxDate: {
      value: '$endDate',
      message: 'Please choose date earlier than End Date',
    },
  },
  endDate: {
    type: 'date',
    required: true,
    minDate: {
      value: '$startDate',
      message: 'Please choose date further than Start Date',
    },
  },
  rulesViolationReason: {
    required: {
      value: true,
      when: {
        $ignoreNotStrictRules: {
          eq: true,
        },
      },
    },
  },
  signatureOption: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $type: {
          in: ['PAID_LEAVE', 'COMPENSATION', 'UNPAID_LEAVE', 'SICK_DAY'],
        },
        '$_FEATURE[leave.signatureOption]': {
          eq: true,
        },
      },
    },
  },
  electronicOptionReason: {
    type: 'string',
    required: {
      value: true,
      when: {
        $signatureOption: {
          eq: 'ELECTRONIC',
        },
      },
    },
    maxSize: 1000,
  },
  electronicOptionAgreement: {
    type: 'boolean',
    required: {
      value: true,
      when: {
        $signatureOption: {
          eq: 'ELECTRONIC',
        },
      },
    },
    forbidden: {
      value: true,
      when: {
        $electronicOptionAgreement: {
          ne: true,
        },
        $signatureOption: {
          eq: 'ELECTRONIC',
        },
      },
      message: `
            You need to agree to sign documents remotely or arrive at the office for signing documents 
            physically within 2 business days after leave approval. If you have any issues with this requirement, 
            please <a href="/one-stop/SUP01" target="_blank">submit a support request</a>.
      `,
    },
  },
};
