/* istanbul ignore file */
import { Marker } from 'camunda/monitoring/facade/useBpmnJs';
import './markers.scss';

class AbstractTokenMarker implements Marker {
  activityId: string;

  html: string;

  position: {
    top: number;
    left: number;
  };

  constructor(activityId: string, tokensCount: number, className: string, position: { top: number; left: number }) {
    this.activityId = activityId;
    this.html = `<span class="${className}">${tokensCount}</span>`;
    this.position = position;
  }
}

export default AbstractTokenMarker;
