import { useEffect, useState } from 'react';
import moment from 'moment';
import { singletonHook } from 'react-singleton-hook';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

const initState = { hookLoading: true };

const WAITING_TIMEOUT = 100;

/**
 * Singleton hook that collects employee names.
 *
 * It doesn't send a request immediately and wait some
 * time and collect employee aliases from multiple
 * components.
 */
const useEmployeeNamesInfoReceiverImpl = () => {
  const { searchWithContractors } = useEmployeeSuggestionsService();

  const [promisesTimeout, setPromisesTimeout] = useState<number | boolean>(false);
  const [promises, setPromises] = useState<any[]>([]);
  const [employeeNames, setEmployeeNames] = useState<any[]>([]);

  useEffect(() => {
    if (promisesTimeout) clearTimeout(promisesTimeout as number);

    if (promises.length && employeeNames.length) {
      const newTimeout = setTimeout(async () => {
        const updatedAt = moment().format(DATE_FORMAT.API);
        // @ts-ignore
        const uniqueEmployeeNames = [...new Set(employeeNames)];
        const info = await searchWithContractors({
          pageSize: uniqueEmployeeNames.length,
          filter: {
            alias: { in: uniqueEmployeeNames },
          },
          pageNumber: 0,
        });
        promises.forEach(p =>
          p.resolve(
            info.result.reduce(
              (acc: Dictionary<any>, result: any) => ({ ...acc, [result.alias]: { ...result, updatedAt } }),
              {},
            ),
          ),
        );

        setPromisesTimeout(false);
        setEmployeeNames([]);
        setPromises([]);
      }, WAITING_TIMEOUT);
      setPromisesTimeout(newTimeout as any);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promises, employeeNames]);

  const collectEmployeeNamesData = async (initialEmployeeAliases: any[]) => {
    const employeeAliases = initialEmployeeAliases.filter((id: any) => id && id !== 'undefined');

    if (!employeeAliases || !employeeAliases.length) {
      return Promise.resolve({});
    }

    const promise = new Promise((resolve, reject) => setPromises((old: any) => [...old, { resolve, reject }]));

    setEmployeeNames((old: any) => [...old, ...employeeAliases]);

    return promise;
  };

  return {
    collectEmployeeNamesData,
  };
};

const useEmployeeNamesInfoReceiver = singletonHook(initState as any, useEmployeeNamesInfoReceiverImpl as any);

export default useEmployeeNamesInfoReceiver;
