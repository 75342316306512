import React, { useState } from 'react';
import Sticky from 'react-sticky-el';
import { useHistory } from 'react-router-dom';
import { ERROR_WITHOUT_SECTION } from 'uibuilder/form/InvalidSectionsMessage';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';

type FormListButtonsLayoutProps = {
  submitAction: () => void;
  isSubmitEnabled: boolean;
  isShowError: boolean;
};

const FormListButtonsLayout = ({ submitAction, isSubmitEnabled, isShowError }: FormListButtonsLayoutProps) => {
  const history = useHistory();
  const [length] = useState(history.length - 1);

  const onCancel = () => {
    history.go(length - history.length);
  };

  return (
    <Sticky mode="bottom" className="sticky-buttons-element">
      <div className="floating-btns">
        <Box className="form-buttons-wrapper">
          <div className="btn-area">
            <Button data-testid="submit-button" type="submit" onClick={submitAction} disabled={!isSubmitEnabled}>
              Submit
            </Button>
            <Button data-testid="cancel-btn" outline type="button" onClick={onCancel}>
              Cancel
            </Button>
          </div>
          {isShowError && (
            <div className="form-errors">
              <div>{ERROR_WITHOUT_SECTION}</div>
            </div>
          )}
        </Box>
      </div>
    </Sticky>
  );
};

export default FormListButtonsLayout;
