import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import BACKGROUND_CHECK_VALIDATION from 'erp/employee/backgroundcheck/createupdate/employeeBackgroundCheckValidation';

export const CREATE_BACKGROUND_CHECK = 'CREATE_BACKGROUND_CHECK';
export const READ_BACKGROUND_CHECK = 'READ_BACKGROUND_CHECK';
export const UPDATE_BACKGROUND_CHECK = 'UPDATE_BACKGROUND_CHECK';
export const DELETE_BACKGROUND_CHECK = 'DELETE_BACKGROUND_CHECK';
export const READ_BACKGROUND_CHECK_LIST = 'READ_BACKGROUND_CHECK_LIST';

export const RESOURCE_URL = '/backgroundChecks';

const useEmployeeBackgroundCheckService = () => {
  const { sendGetRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const resourceUrl = `${EMPLOYEES_RESOURCE_URL}/${employeeId}${RESOURCE_URL}`;

  const baseCrudOperations = useBaseCrudService({
    singleResourceUrl: `${resourceUrl}/:id`,
    listResourceUrl: resourceUrl,
    apiService: useKernelApi,
  });

  const getSearchParams = (request: LoadingParams) => {
    const params = [];
    params.push(`pageSize=${encodeURIComponent(request.pageSize)}`);
    params.push(`pageNumber=${encodeURIComponent(request.pageNumber)}`);

    if (request.sort && Object.keys(request.sort).length > 0) {
      const [sortKey, sortValue = ''] = Object.entries(request.sort)[0];
      params.push(`${encodeURIComponent(`sort[${sortKey}]`)}=${encodeURIComponent(sortValue)}`);
    }

    return params.join('&');
  };

  const search = async (request: any) => {
    const url = `${resourceUrl}?${getSearchParams(request)}`;
    const response = await sendGetRequest(url);

    return response.json();
  };

  const getValidationSchema = () => Promise.resolve(BACKGROUND_CHECK_VALIDATION);

  return {
    ...baseCrudOperations,
    search,
    getValidationSchema,
  };
};

export default useEmployeeBackgroundCheckService;
