/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import OneStopFilter from 'oneStop/List/OneStopFilter';
import OneStopListLayout from 'oneStop/List/OneStopListLayout';
import OneStopListPageLayout from 'oneStop/List/OneStopListPageLayout';
import useOneStopService, { FILTER_TABS } from 'oneStop/oneStopService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';

import './OneStopList.scss';

const OneStopList = () => {
  const { search, getSections } = useOneStopService();

  const requestsList = <OneStopListLayout />;
  const filter = <OneStopFilter getSections={getSections} requestsList={requestsList} />;

  return (
    <List getDataMethod={search} defaultFilter={{ section: { eq: FILTER_TABS.RECENT } }}>
      <OneStopListPageLayout
        filter={filter}
        requestsList={requestsList}
        header={<ListHeader label="New Request" icon="fa-plus-square font-3xl" />}
      />
    </List>
  );
};

export default OneStopList;
