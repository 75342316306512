import { useState, useCallback } from 'react';

const classesTriggerToFocus = ['ck-button', 'cke_button__table'];

const useModalWindow = () => {
  const [isFocusEnable, setIsFocusEnable] = useState(false);

  const disableEnforceFocus = useCallback(() => {
    setIsFocusEnable(false);
  }, []);

  const enableEnforceFocus = useCallback(() => {
    setIsFocusEnable(true);
  }, []);

  const onModalContentClick = (e: any) => {
    const isTriggerElementClicked = classesTriggerToFocus.some(
      className =>
        typeof e.target.className === 'string' &&
        ((e.target.className && e.target.className.includes(className)) ||
          e.target.parentNode.className.includes(className)),
    );

    if (isTriggerElementClicked) {
      enableEnforceFocus();
    } else {
      disableEnforceFocus();
    }
  };

  return {
    isFocusEnable,
    onModalContentClick,
  };
};

export default useModalWindow;
