/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';

const EditAssessmentButton = (props: any) => {
  const { getUpdateEntityUrl } = useAssessmentUrl();

  return <EditButton path={getUpdateEntityUrl} key="edit-assessment" {...props} />;
};

export default EditAssessmentButton;
