import React from 'react';
import { useShowContext } from '../show/ShowContext';
import getLabel, { getValue } from '../helper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { FieldProps, FieldLayoutProps } from 'shared/uibuilder/field';
import setBlankTargetsIfNotSpecified from '../../htmlHelper';

export type HtmlFieldLayoutType = ReactComponent<FieldLayoutProps>;

const HtmlField = ({ source = '', value, label }: FieldProps<object | string | number>) => {
  const { data } = useShowContext();
  const { HtmlFieldLayout } = useUiTheme<HtmlFieldLayoutType>();

  const fieldValue = getValue(value, (src: string) => data && data.getValueBySource(src), source).replace(
    /(\r\n|\n|\r)/gm,
    '<br />',
  );
  const formattedFieldValue = setBlankTargetsIfNotSpecified(fieldValue);

  const labelValue = getLabel(label, source);

  return <HtmlFieldLayout label={labelValue} value={formattedFieldValue} />;
};

export default HtmlField;
