/* istanbul ignore file */
import React, { useState } from 'react';
import { ButtonWithConfirmation } from 'uibuilder/button';
import useMailboxConsentService, {
  PROVIDE_CONSENT_PERMISSION,
} from 'erp/employee/mailboxConsent/mailboxConsentService';

const ProvideMailboxConsentButton = () => {
  const { provideConsent } = useMailboxConsentService();
  const [isVisible, setIsVisible] = useState(true);
  return isVisible ? (
    <ButtonWithConfirmation
      modalConfirmButtonText="Submit"
      displayMessage="By providing consent to Mailbox Scrapping, you agree that the system will access your corporate mailbox. DaVinci will receive your emails and correlate them with business objects. Do you agree to this?"
      submitMethod={provideConsent}
      color="secondary"
      permissionToCheck={PROVIDE_CONSENT_PERMISSION}
      outline
      afterSubmit={{
        successMessage: 'Successfully provided consent!',
        execute: () => setIsVisible(false),
      }}
    >
      Provide Mailbox Consent
    </ButtonWithConfirmation>
  ) : null;
};

export default ProvideMailboxConsentButton;
