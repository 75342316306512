import React, { Dispatch, SetStateAction } from 'react';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';
import { ShowDataInstance } from 'shared/uibuilder/show/Show';

interface ControlledShowProps {
  children: React.ReactNode | React.ReactElement;
  data: Nullable<object>;
  setData?: Dispatch<SetStateAction<ShowDataInstance>>;
}

const ControlledShow = ({ children, data, setData }: ControlledShowProps) => {
  return (
    <ShowContextProvider
      value={{
        data: new ShowData(data || {}),
        setData,
      }}
    >
      {children}
    </ShowContextProvider>
  );
};

export default ControlledShow;
