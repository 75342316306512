/* istanbul ignore file */
import React, { useEffect } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { LEAD_DISQUALIFICATION_REASON } from 'crm/lead/shared/leadService';
import useDropdownHelper from 'shared/uibuilder/form/input/dropdownHelper';

const DisqualificationReasonDropdown = props => {
  const { getRawOnChangeCallback, getSource } = useDropdownHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  /*
     When DisqualificationReasonDropdown is not mounted - value should be null
  */
  useEffect(() => {
    return () =>
      rawOnChangeCallback({
        [getSource()]: null,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      placeholder="Choose Disqualification Reason"
      options={Object.entries(LEAD_DISQUALIFICATION_REASON).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label="Disqualification Reason"
      {...props}
    />
  );
};

export default DisqualificationReasonDropdown;
