/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import FilterInputDecorator from 'shared/uibuilder/list/filter/FilterInputDecorator';
import EmployeeTimelineRecordTypeDropdown from 'erp/employee/Timeline/shared/input/EmployeeTimelineRecordTypeDropdown';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

const EmployeeTimelineRecordTypeFilter = ({ cleanableFilters = [], source, ...props }) => {
  const { filterBy } = useFilterContext();

  const onChangeCallback = filter => {
    const filters = {
      ...filter,
    };

    cleanableFilters.forEach(clearableFilter => {
      filters[clearableFilter] = {};
    });

    filterBy(filters);
  };

  return (
    <DropDownFilter {...props} source={source} isSingle onChangeCallback={onChangeCallback}>
      <FilterInputDecorator cleanableFilters={[...cleanableFilters, source]}>
        <EmployeeTimelineRecordTypeDropdown />
      </FilterInputDecorator>
    </DropDownFilter>
  );
};

export default EmployeeTimelineRecordTypeFilter;
