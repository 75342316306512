import { useFinanceApi } from 'api';
import { downloadFile } from 'shared/fileHelper';

export const MAPPING_METRICS_URL = '/mapping/metrics';

export const READ_COST_CENTER_REPORT = 'COMPANY_READ_COST_CENTER_REPORT';

export enum MappingMetricsType {
  METRICS_BY_RECORDS = 'METRICS_BY_RECORDS',
  METRICS_BY_HOURS = 'METRICS_BY_HOURS',
}

export const MappingMetricsTypeLabels: Dictionary<string> = {
  [MappingMetricsType.METRICS_BY_RECORDS]: 'Records',
  [MappingMetricsType.METRICS_BY_HOURS]: 'Hours',
};

const useCostAccountingService = () => {
  const { sendGetRequest, sendPostRequest } = useFinanceApi();

  const sendGetRequestWithSearchParams = async (baseUrl: string, data: any) => {
    const urlSearchParams = new URLSearchParams(data).toString();
    const urlAppendix = urlSearchParams ? `?${urlSearchParams}` : '';
    const url = baseUrl + urlAppendix;
    return sendGetRequest(url);
  };

  const getMappingMetrics = async (data: any) => {
    const response = await sendGetRequestWithSearchParams(MAPPING_METRICS_URL, data);
    return response.json();
  };

  const getCostReport = async ({ from, to }: any) => {
    const response = await sendPostRequest('/reporting/cost-report/csv', {
      period: {
        fromDate: from,
        toDate: to,
      },
    });

    return downloadFile(response, decodeURI('cost-report.csv'), true);
  };

  return {
    getMappingMetrics,
    getCostReport,
  };
};

export default useCostAccountingService;
