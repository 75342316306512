import React, { Fragment } from 'react';
import { WarningMessage } from 'shared/message/messageService';
import { LeadDuplicate } from './leadDuplicateService';
import Tooltip from 'uibuilder/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const MERGE_TOOLTIP_TEXT =
  'To merge duplicates you may copy the data manually to one Lead ' +
  'and disqualify the duplicates with the reason: Duplicate. ';

const ACCESS_TOOLTIP_TEXT =
  "You don't have access to this Lead. You may ask your manager to review the duplicates or give you access.";

const getAccessTooltip = (duplicateId: number) => {
  const tooltipId = `id${duplicateId}`;
  return (
    <Tooltip title={ACCESS_TOOLTIP_TEXT} placement="bottom-start" id={tooltipId}>
      <InfoIcon className="tooltip-icon lead-tooltip-icon" id={tooltipId} role="presentation" />
    </Tooltip>
  );
};

const getMergeTooltip = () => {
  const id = 'mergeTooltip';
  return (
    <Tooltip title={MERGE_TOOLTIP_TEXT} placement="bottom-start" id={id}>
      <a id={id} href="#/">
        What to do with duplicates?
      </a>
    </Tooltip>
  );
};

const getLinks = (duplicates: Array<LeadDuplicate>) => {
  const sorted = duplicates.sort((d1, d2) => (d1.accessGranted ? 0 : 1) - (d2.accessGranted ? 0 : 1));
  return (
    <>
      {sorted.map((d, i) => (
        <Fragment key={d.id}>
          <a target="_blank" rel="noreferrer" href={d.accessGranted ? d.url : undefined}>
            {d.id}
          </a>
          {!d.accessGranted && getAccessTooltip(d.id)}
          {i !== sorted.length - 1 ? <span>, </span> : null}
        </Fragment>
      ))}
    </>
  );
};

const getSpamWarningContent = (spamDuplicates: Array<LeadDuplicate>) => {
  const isPlural = spamDuplicates.length > 1;

  return (
    <>
      {spamDuplicates.length} potential duplicate{isPlural ? 's' : ''} disqualified as SPAM:
      <span> </span>
      {getLinks(spamDuplicates)}
    </>
  );
};

const getNonSpamWarningContent = (nonSpamDuplicates: Array<LeadDuplicate>) => {
  const isPlural = nonSpamDuplicates.length > 1;

  return (
    <>
      {nonSpamDuplicates.length} potential duplicate{isPlural ? 's' : ''}:<span> </span>
      {getLinks(nonSpamDuplicates)}
    </>
  );
};

const getDuplicatesWarning = (duplicates: Array<LeadDuplicate>): WarningMessage | null => {
  if (duplicates.length === 0) {
    return null;
  }

  const spamDuplicates = duplicates.filter(d => d.spam);
  const nonSpamDuplicates = duplicates.filter(d => !d.spam);

  const hasSpam = spamDuplicates.length > 0;
  const hasNonSpam = nonSpamDuplicates.length > 0;

  const content = (
    <>
      {hasNonSpam && getNonSpamWarningContent(nonSpamDuplicates)}
      {hasNonSpam && hasSpam && <br />}
      {hasSpam && getSpamWarningContent(spamDuplicates)}
      <br />
      {getMergeTooltip()}
    </>
  );

  return new WarningMessage(content);
};

export default getDuplicatesWarning;
