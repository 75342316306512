import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useWasteService, { WASTE_STATUSES, APPROVE_WASTE } from '../../useWasteService';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';

const ApproveWasteButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { approveWaste, getAccounts } = useWasteService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const [sourceAccountId, setSourceAccountId] = useState<any>();
  const [targetAccountId, setTargetAccountId] = useState<any>();
  const status = getValue();

  if (status !== WASTE_STATUSES.REGISTERED) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to approve this waste?"
      submitMethod={() => approveWaste(id, { sourceAccountId, targetAccountId })}
      afterSubmit={{
        successMessage: 'The Waste has been successfully approved.',
        errorMessage: "Can't approve waste.",
        execute: (data: any) => {
          setData(data);
        },
      }}
      permissionToCheck={APPROVE_WASTE}
      variant="outlined"
      modalChildren={
        <>
          <AccountInput
            onChangeCallback={(data: any) => setSourceAccountId(data.sourceAccountId)}
            value={sourceAccountId}
            source="sourceAccountId"
            label="Source Account"
            placeholder="Select Source Account"
            loadDataMethod={getAccounts}
            cacheKey="source-accounts"
            isRequired
          />
          <AccountInput
            onChangeCallback={(data: any) => setTargetAccountId(data.targetAccountId)}
            value={targetAccountId}
            source="targetAccountId"
            label="Target Account"
            placeholder="Select Target Account"
            loadDataMethod={getAccounts}
            cacheKey="target-accounts"
            isRequired
          />
        </>
      }
      confirmBtnProps={{
        disabled: !sourceAccountId || !targetAccountId,
      }}
    >
      Approve
    </ButtonWithConfirmation>
  );
};

export default ApproveWasteButton;
