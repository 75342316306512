/* istanbul ignore file */
import React from 'react';
import { useProjectId, useProjectUrl } from 'erp/project/ProjectRouter';
import useRedmineProjectService from '../../redmineProjectService';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import RedmineProjectForm from '../RedmineProjectForm';

const CreateRedmineProject = () => {
  const { getPrefilledData, create, getValidationSchema } = useRedmineProjectService();
  const { getSingleEntityUrl } = useProjectUrl();
  const projectId = useProjectId();

  return (
    <CreateForm
      submitFormFunc={create}
      getDataFunc={async () => getPrefilledData(projectId)}
      afterSubmit={{
        redirect: getSingleEntityUrl(),
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Redmine Project">
        <RedmineProjectForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateRedmineProject;
