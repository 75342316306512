/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import ArtifactStorageBreadcrumbs from 'artifactStorage/shared/ArtifactStorageBreadcrumbs';
import { ArtifactType } from 'artifactStorage/shared/artifactStorageService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import ArtifactStorageFoldersListLayout from './ArtifactStorageFoldersListLayout';
import ArtifactStorageFilesListLayout from './ArtifactStorageFilesListLayout';

interface ArtifactStorageListLayoutProps {
  canReloadData: boolean;
  breadcrumbs?: { alias: string; name: string }[];
}

const ArtifactStorageListLayout = ({ canReloadData, breadcrumbs }: ArtifactStorageListLayoutProps) => {
  const {
    data = {},
    reloadData = () => {},
    search,
    searchBy = () => {},
    loading,
    setLoading = () => {},
    filterBy = () => {},
    filter,
  } = useListContext();
  const { pathname, search: locationFilters } = useLocation();
  const type = (data.items && data.items[0]?.type) || ArtifactType.FOLDER;

  const cleanFilters = () => {
    if (search) {
      searchBy('');
    }
    if (filter && !locationFilters) {
      filterBy({});
    }
  };

  useEffect(() => {
    if (loading || !canReloadData) return;

    setLoading(true);
    if (search || (filter && !locationFilters)) {
      cleanFilters();
    } else {
      reloadData();
    }
    // Suppressed warnings because we only need to call useEffect callback if pathname was updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <ArtifactStorageBreadcrumbs breadcrumbs={breadcrumbs || []} />
      {type === ArtifactType.FOLDER ? <ArtifactStorageFoldersListLayout /> : <ArtifactStorageFilesListLayout />}
    </>
  );
};

export default ArtifactStorageListLayout;
