import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { CommonInputLayoutProps, BaseChangeEvent, DefaultInputPropTypes } from 'shared/uibuilder/form/input';

export type NumberInputLayoutType = ReactComponent<CommonInputLayoutProps<BaseChangeEvent, number>>;

/**
 * Input is used for entering of number value.
 *
 * @param props.inputLabel label
 * @param props.source source
 * @param props.validationCallback called each time user selects the value
 * @param props.inputValue value to display
 * @param props.inputOnChangeCallback callback will be called when the value of component is changed *
 * @param props.isVisibleInput defined if the input should be displayed
 *
 * @returns {*}
 * @constructor
 */
const NumberInput = (props: DefaultInputPropTypes<number>) => {
  const inputHelper = useInputHelper(props);
  const { NumberInputLayout } = useUiTheme<NumberInputLayoutType>();

  return (
    <NumberInputLayout
      isVisible={inputHelper.isVisible()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      errorMessages={inputHelper.getErrorMessages()}
      isRequired={inputHelper.getIsRequired()}
      labelHint={inputHelper.getLabelHint()}
      helpText={inputHelper.getHelpText()}
      disabled={inputHelper.getIsDisabled()}
      tooltip={inputHelper.getTooltip()}
      className={inputHelper.getClassName()}
    />
  );
};

NumberInput.defaultProps = {
  isVisible: true,
};

export default NumberInput;
