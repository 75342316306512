import React, { useMemo } from 'react';
import TextField from '../../../../../shared/uibuilder/field/TextField';
import useFieldHelper from '../../../../../shared/uibuilder/field/fieldHelper';

type StatisticHiring = {
  thisWeek: number;
  previousWeek: number;
  total: number;
};

type StatisticHiringFieldProps = {
  label: string | React.ReactElement;
  source: string;
  width?: string;
};

const StatisticHiringField: React.FC<StatisticHiringFieldProps> = ({ source, ...props }) => {
  const { getValue } = useFieldHelper<StatisticHiring>({ source });

  const statistic = useMemo(() => getValue() || {}, [getValue]);

  const value = useMemo(() => {
    return `${statistic.thisWeek} / ${statistic.previousWeek} / ${statistic.total}`;
  }, [statistic]);

  return <TextField {...props} value={value} />;
};

export default StatisticHiringField;
