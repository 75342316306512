import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

import EmploymentHistoryFieldLayout from '../layout/EmploymentHistoryFieldLayout';

const EmploymentHistoryField = ({ source, value, label }) => {
  const { getValue, getLabel } = useFieldHelper({ source, value, label });

  const current = getValue()?.current;
  const previous = getValue()?.previous;

  return <EmploymentHistoryFieldLayout label={getLabel()} current={current} previous={previous} />;
};

export default EmploymentHistoryField;
