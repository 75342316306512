/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import PresaleOutputForm from 'crm/opportunity/presale/output/form/PresaleOutputForm';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';

const UpdatePresaleOutputForm = ({ getDataFunc, onCancel, ...props }: any) => {
  const opportunityId = useOpportunityId();
  const { updateOutput, getValidationSchema } = usePresaleOutputService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={(id, values) => updateOutput(opportunityId, id, values)}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <PresaleOutputForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdatePresaleOutputForm;
