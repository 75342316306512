/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import useOfficeService from 'erp/employee/office/officeService';

const PayrollReportsOfficeField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const value = getValue() as any;
  const { findAll } = useOfficeService();
  const [options, setOptions] = useState<Dictionary<string>>({});

  useEffect(() => {
    (async () => {
      const offices = await findAll();
      const data = offices.reduce(
        (acc: Dictionary<string>, office: any) => ({
          ...acc,
          [office.id.toString()]: office.name,
        }),
        {},
      );
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextField
      {...props}
      value={value.map((officeId: string) => options[officeId] || '')?.join(', ') || EMPTY_VALUE_INDICATOR}
    />
  );
};

export default PayrollReportsOfficeField;
