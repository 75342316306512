/* istanbul ignore file */
import React from 'react';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import BackgroundCheckAttachmentUploaderInput from 'artifact/shared/input/BackgroundCheckAttachmentUploaderInput';
import { DateInput } from 'shared/uibuilder/form/input';

import BackgroundCheckResultDropdown from '../shared/input/BackgroundCheckResultDropdown';

const EmployeeBackgroundCheckForm = () => {
  return (
    <FormSection title="Background Check information">
      <FormRow>
        <DateInput source="performedAt" label="Check Date" />
        <BackgroundCheckResultDropdown source="result" label="Result" />
      </FormRow>
      <BigFormRow>
        <BackgroundCheckAttachmentUploaderInput label="Check Reports" source="attachmentsIds" />
      </BigFormRow>
    </FormSection>
  );
};

export default EmployeeBackgroundCheckForm;
