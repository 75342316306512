/* istanbul ignore file */

export default {
  status: {
    type: 'string',
    required: true,
  },
  justification: {
    type: 'string',
    required: {
      value: true,
      when: {
        '$_FEATURE[saveCompetencyJustification]': {
          eq: true,
        },
      },
    },
    maxSize: 65000,
  },
  attachmentsIds: {
    type: 'array',
    maxSize: 5,
  },
};
