/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Typography from 'uibuilder/Typography';
import WysiwygInput from 'shared/uibuilder/form/input/WysiwygInput';
import useCandidateService from 'erp/candidate/shared/candidateService';
import statusChangeReasonNoteValidation from 'erp/candidate/applications/status/statusChangeReasonNoteValidation';
import useCandidateApplicationService, {
  CandidateApplicationStatus,
} from 'erp/candidate/applications/shared/candidateApplicationService';
import OneStopButtons from 'uibuilder/layout/form/OneStopButtonsLayout';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Message from 'shared/message/Message';

type StatusChangeReasonFormProps = {
  candidateId: string;
  candidateApplicationId: string;
  isModalOpen: boolean;
  closeFeedbackForm: () => void;
  status: CandidateApplicationStatus;
};

const StatusChangeReasonModalForm = ({
  candidateId,
  candidateApplicationId,
  isModalOpen,
  closeFeedbackForm,
  status,
}: StatusChangeReasonFormProps) => {
  const { createTimelineItem } = useCandidateService();
  const { partialUpdate } = useCandidateApplicationService();
  const { reloadData = () => {} } = useListContext();

  const title = (
    <Typography variant="h3" sx={{ marginBottom: '20px' }}>
      Specify the reason for the status change
    </Typography>
  );

  const onSubmit = async (data: any) => {
    await partialUpdate(candidateApplicationId, { status });
    await createTimelineItem(candidateId, 'notes', data);
  };
  return (
    <CreateForm
      submitFormFunc={onSubmit}
      afterSubmit={{
        execute: () => {
          closeFeedbackForm();
          reloadData();
        },
        message: 'You are awesome! The Candidate Application status has been successfully updated.',
      }}
      getValidationSchemaFunc={() => Promise.resolve(statusChangeReasonNoteValidation)}
    >
      <ModalWindow
        key="modal-not-strict"
        modalSize="sm"
        backdrop="static"
        keyboard={false}
        isOpen={isModalOpen}
        hasCancelButton={false}
        title={title}
        onToggle={closeFeedbackForm}
      >
        <Message />
        <WysiwygInput label="Timeline comment" labelHint="(Will be added to candidate timeline)" source="noteText" />
        <OneStopButtons onCancelAction={closeFeedbackForm} isFixed={false} />
      </ModalWindow>
    </CreateForm>
  );
};

export default StatusChangeReasonModalForm;
