/* istanbul ignore file */
import React from 'react';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import BackgroundChecksList from 'erp/employee/backgroundcheck/List/BackgroundChecksList';
import EmployeeBackgroundCheckBreadCrumbs from 'erp/employee/backgroundcheck/shared/EmployeeBackgroundCheckBreadcrumbs';

const EmployeeBackgroundChecksList = () => {
  return (
    <ShowPageLayout
      menu={<EmployeeMenu />}
      entityHeader={<EmployeeEntityHeader />}
      breadcrumbs={<EmployeeBackgroundCheckBreadCrumbs />}
    >
      <BackgroundChecksList />
    </ShowPageLayout>
  );
};

export default EmployeeBackgroundChecksList;
