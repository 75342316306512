import { useState } from 'react';
import { useSyberryTodayContext } from 'syberryToday/SyberryTodayContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useSyberryTodayService, { getSyberryTodayError } from 'syberryToday/useSyberryTodayService';
import { HTTP } from 'shared/api/const';

const useSkipDailyCheckinModal = () => {
  const { setLoading, setIsCheckinPassed } = useSyberryTodayContext();
  const { skipDailyCheckin, postponeDailyCheckin } = useSyberryTodayService();
  const { addMessage } = useMessageService();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const confirmAction = async (submitFunction: any, defaultErrorMessage: string) => {
    try {
      onClose();
      setLoading(true);

      await submitFunction();

      setIsCheckinPassed(true);
    } catch (e) {
      if ((e as any).status !== HTTP.UNAUTHORIZED) {
        const errorMessage = await getSyberryTodayError(e, defaultErrorMessage);

        addMessage(new ErrorMessage(errorMessage));
      }
    } finally {
      setLoading(false);
    }
  };

  const onSkipCheckin = () => {
    confirmAction(skipDailyCheckin, "Can't skip daily-checkin");
  };

  const onPostponeCheckin = () => {
    confirmAction(postponeDailyCheckin, "Can't postpone daily-checkin");
  };

  return {
    onSkipCheckin,
    onPostponeCheckin,
    onOpen,
    onClose,
    isOpen,
  };
};

export default useSkipDailyCheckinModal;
