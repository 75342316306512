/* istanbul ignore file */
import React from 'react';
import EditButton from 'uibuilder/button/EditButton';
import { useAccountUrl } from 'crm/account/index';

const EditAccountButton = props => {
  const { getUpdateEntityUrl } = useAccountUrl();

  return (
    <EditButton url={id => getUpdateEntityUrl(id)} {...props}>
      Edit
    </EditButton>
  );
};

export default EditAccountButton;
