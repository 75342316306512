export default {
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  parentNodeId: {
    type: 'string',
    required: {
      value: true,
      when: [
        {
          $parentNodeName: {
            notEmpty: true,
          },
        },
        {
          $hasParentNode: {
            notEmpty: true,
          },
        },
      ],
    },
  },
  attributes: {
    type: 'object',
    properties: {
      goal: {
        type: 'text',
        required: false,
        maxSize: 65000,
      },
      owner: {
        type: 'object',
        properties: {
          alias: {
            type: 'string',
            required: false,
          },
        },
      },
      secondOwner: {
        type: 'object',
        properties: {
          alias: {
            type: 'string',
            required: false,
          },
        },
      },
    },
  },
};
