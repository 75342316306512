/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import Sticky from 'react-sticky-el';
import './PerformanceHeatmapFixedHeaderDecorator.scss';

interface PerformanceHeatmapFixedHeaderDecoratorProps {
  children: React.ReactNode;
  scrollableNodeRef: React.MutableRefObject<any>;
}

const PerformanceHeatmapFixedHeaderDecorator = ({
  children,
  scrollableNodeRef,
}: PerformanceHeatmapFixedHeaderDecoratorProps) => {
  const [headerWidth, setHeaderWidth] = useState(null);
  const header = useRef<Nullable<HTMLDivElement>>(null);

  const updateScrollPosition = () => {
    if (header.current) {
      header.current.scrollLeft = scrollableNodeRef.current.scrollLeft;
    }
  };

  const updateHeaderWidth = () => {
    const container = scrollableNodeRef.current;

    if (container) {
      setHeaderWidth(container.getBoundingClientRect()?.width);
    }
  };

  useEffect(() => {
    const container = scrollableNodeRef.current;

    updateHeaderWidth();

    if (container) {
      container.addEventListener('scroll', updateScrollPosition);
    }

    return () => {
      container?.removeEventListener('scroll', updateScrollPosition);
    };
    // Suppressed warnings because we only need to call useCallback callback if scrollableNodeRef is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableNodeRef]);

  useEffect(() => {
    window.addEventListener('resize', updateHeaderWidth);

    return () => {
      window.removeEventListener('resize', updateHeaderWidth);
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sticky
      boundaryElement=".performance-heatmap__inner"
      hideOnBoundaryHit={false}
      topOffset={-64}
      className="performance-heatmap__sticky-header"
      onFixedToggle={() => {
        setTimeout(() => updateScrollPosition(), 15);
      }}
    >
      <div className="performance-heatmap-sticky-header__background" style={{ width: headerWidth || '' }} />
      <div ref={header} className="performance-heatmap-sticky-header__content" style={{ width: headerWidth || '' }}>
        {children}
      </div>
    </Sticky>
  );
};

export default PerformanceHeatmapFixedHeaderDecorator;
